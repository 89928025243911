import type { ApprovalAnnotationProps } from '../ApprovalAnnotation.types';
import type { ApprovalAnnotationData } from './useApprovalAnnotationData.types';
import type { GlobalState } from 'shared/reducers';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { doesCurrentUserHaveRole, getUserId } from 'shared/features/user/user.selectors';
import { ORG_ADMIN, TEAM_ADMIN, WORKFLOW_ADMIN } from 'constants/UserRoles';
import {
  areAllUsersActive,
  getActiveOrganizationMembersById,
  getActiveOrganizationMembersIds
} from 'shared/features/organizations/organizations.selectors';

export const useApprovalAnnotationData = (
  props: Pick<
    ApprovalAnnotationProps,
    'approversIds' | 'assigneeId' | 'backupApproversIds' | 'isSubmitDisabled'
  >
): ApprovalAnnotationData => {
  const reduxState = useSelector<GlobalState, ApprovalAnnotationData['reduxState']>(state => ({
    // TODO Uncomment when organizations.selectors.js will be converted to .ts
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    isAssigneeActive: getActiveOrganizationMembersById([props.assigneeId])(state).length > 0,
    isAssignee: getUserId(state) === props.assigneeId,
    currentUserId: getUserId(state),
    areInitialApproversExpired:
      // TODO Uncomment when organizations.selectors.js will be converted to .ts
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getActiveOrganizationMembersById(props.approversIds)(state).length === 0,
    isAdmin: doesCurrentUserHaveRole(state, [ORG_ADMIN, TEAM_ADMIN, WORKFLOW_ADMIN])
  }));

  const formattedData: ApprovalAnnotationData['formattedData'] = useMemo(() => {
    const isApprover =
      reduxState.isAssignee && props.approversIds.includes(reduxState.currentUserId);
    const approversIds =
      reduxState.areInitialApproversExpired && reduxState.isAdmin
        ? props.backupApproversIds
        : props.approversIds;

    return {
      isApprover,
      approversIds
    };
  }, [
    props.approversIds,
    props.backupApproversIds,
    reduxState.currentUserId,
    reduxState.isAssignee,
    reduxState.areInitialApproversExpired,
    reduxState.isAdmin
  ]);

  return {
    reduxState,
    formattedData
  };
};
