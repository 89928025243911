import type { CreateSentenceNoteModalProps } from './CreateSentenceNoteModal.types';
import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as common from 'common/index';

import { useCreateSentenceNoteModalData, useCreateSentenceNoteModalHandlers } from './hooks';

import './_create-sentence-note-modal.scss';

export const CreateSentenceNoteModal: FC<CreateSentenceNoteModalProps> = ({
  isObligation,
  isCreating,
  onCreate,
  onClose
}) => {
  const { localState, localActions, formattedData } = useCreateSentenceNoteModalData();

  const handlers = useCreateSentenceNoteModalHandlers({
    props: { onCreate },
    formattedData
  });

  return (
    <uiLib.Modal
      title={`Add ${isObligation ? 'obligation' : 'annotation'}`}
      isOpen
      onClose={onClose}
      withFooter
      primaryButtonType={uiLib.BUTTON_TYPES.PRIMARY}
      primaryButtonText="Add"
      secondaryButtonType={uiLib.BUTTON_TYPES.LINK}
      secondaryButtonText="Close"
      isPrimaryButtonDisabled={formattedData.isCreateButtonDisabled || isCreating}
      onSubmit={handlers.handleSentenceNoteCreate}
      classNames={{
        container: 'create-sentence-note-modal',
        content: 'create-sentence-note-modal__container'
      }}
    >
      <>
        {isCreating && <uiLib.Loader withoutBackground={false} />}
        <uiLib.LabeledField label="Add to Collection">
          <common.BucketSelect value={localState.bucket} onChange={localActions.setBucket} />
        </uiLib.LabeledField>
      </>
    </uiLib.Modal>
  );
};
