import { useState, useEffect, useRef, useCallback } from 'react';
import autosize from 'autosize';
import classnames from 'classnames';
import SearchIcon from '@material-ui/icons/Search';
import { ClickAwayListener } from '@compliance.ai/web-components';
import { connect, useDispatch } from 'react-redux';
import queryString from 'utils/query-string';
import { AdvancedSearchBuilder } from './elements';
import { safe_mixpanel_track } from 'utils/analytics';
import { toggleAdvancedSearchFilter as reduxToggleAdvancedSearchFilter } from 'shared/features/view/view.actions';
import { getCategories } from 'shared/features/documents/documents.selectors';
import { parseQueryToJsonLogic } from 'utils/advancedSearch/advancedSearch.helpers';
import * as images from 'shared/images';
import { useLocation, useNavigate } from 'react-router-dom';
import { getOrganizationLabels } from 'shared/features/organizations/organizations.selectors';

import './_advanced-search-field.scss';

export const AdvancedSearchField = ({ alerts, current_view, queryState }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const parsedQuery = queryString.parse(location.search);

  const [searchValue, setSearchValue] = useState(parsedQuery.advanced_search || '');
  const [active, setActiveStatus] = useState(false);
  const [conceptValues, setConceptValues] = useState([]);
  const ref = useRef(null);
  const dispatch = useDispatch();

  const toggleAdvancedSearchFilter = useCallback(() => {
    return dispatch(reduxToggleAdvancedSearchFilter());
  }, [dispatch]);

  const clickListener = useCallback(e => {
    if (ref.current && ref.current.contains(e.target)) {
      return setActiveStatus(true);
    }
    return setActiveStatus(false);
  }, []);

  useEffect(() => {
    const newParsedQuery = queryString.parse(location.search);
    if (newParsedQuery.advanced_search) {
      setSearchValue(newParsedQuery.advanced_search);
    }
  }, [location.search]);

  useEffect(() => {
    autosize(document.getElementById('advanced-search-textarea'));
    document.addEventListener('click', clickListener);
    return () => {
      document.removeEventListener('click', clickListener);
    };
  }, [clickListener]);

  const encodeQueryData = data => {
    const ret = [];
    for (const d in data) {
      if (Object.prototype.hasOwnProperty.call(data, d)) {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
      }
    }
    return ret.join('&');
  };

  const getUrlParams = () => {
    const params = {
      limit: 20,
      order: 'desc',
      search_sort: 'publication_date',
      advanced_search: searchValue
    };
    return '?' + encodeQueryData(params);
  };

  const doSearch = () => {
    setActiveStatus(false);
    safe_mixpanel_track(`Search - Advanced Search – Advanced Search`, {
      eventCategory: 'Search',
      eventAction: 'Advanced Search Click',
      eventLabel: 'Advanced Search'
    });
    navigate(`/content?${getUrlParams()}`);
  };

  const handleKeyDown = event => {
    event.stopPropagation();
    if (event.keyCode === 13) {
      event.preventDefault();
      doSearch();
    }
  };

  const toggleBuilder = () => {
    setConceptValues([]);
    safe_mixpanel_track(`Search - Advanced Search – Builder Opened`, {
      eventCategory: 'Search',
      eventAction: 'Advanced Search Click',
      eventLabel: 'Builder Opened'
    });
    toggleAdvancedSearchFilter();
  };

  const handleConceptSelect = concepts => {
    setConceptValues(concepts);
  };

  const { editAlertId, displayAdvancedSearchFilter } = current_view;
  const currentAlert =
    editAlertId && alerts.user_alerts && alerts.user_alerts.find(alert => alert.id === editAlertId);

  return (
    <div
      className="advanced-search-field__container"
      id="advanced-search-field"
      data-testid="advanced-search-field"
    >
      <div className="advanced-search-field__search-menu">
        <div
          onClick={toggleBuilder}
          className="advanced-search-field__filter-icon-outter-span ignore-react-onclickoutside"
        >
          <img src={images.misc.iconBuilder} alt="icon" />
          <span>{'Filter'}</span>
        </div>
        <textarea
          ref={ref}
          id="advanced-search-textarea"
          className={classnames('advanced-search-field__search-input', {
            'advanced-search-field__search-input--is-active': active
          })}
          placeholder={`Example (jurisdiction=US-CA AND agency != SEC and topic = Lending) OR (doc_type = Enforcement)`}
          onChange={event => setSearchValue(event.target.value)}
          onKeyDown={event => handleKeyDown(event)}
          value={searchValue}
          data-testid="advanced-search-input-field"
        />
        <SearchIcon
          className="advanced-search-field__search-icon"
          onClick={doSearch}
          data-testid="advanced-search-search-icon"
        />
      </div>
      {displayAdvancedSearchFilter && (
        <ClickAwayListener
          onClickAway={() => toggleAdvancedSearchFilter()}
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
        >
          <div className="advanced-search-field__query-builder-wrapper">
            <AdvancedSearchBuilder
              jsonLogicQuery={parseQueryToJsonLogic(searchValue, queryState)}
              setSearchValue={setSearchValue}
              doSearch={doSearch}
              currentAlert={currentAlert}
              getUrlParams={getUrlParams}
              handleConceptSelect={handleConceptSelect}
              conceptValues={conceptValues}
            />
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

AdvancedSearchField.propTypes = {};

const mapStateToProps = state => {
  return {
    current_view: state.current_view,
    alerts: state.alerts,
    queryState: {
      categories: getCategories(state),
      labels: getOrganizationLabels(state)
    }
  };
};

export default connect(mapStateToProps, {})(AdvancedSearchField);
