import type { PublisherProps } from './Publisher.types';

import { DOCUMENT_CATEGORIES } from 'constants/DocumentConstants';
import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';
import { usePublisherData } from './hooks';

export const Publisher = ({ document }: PublisherProps) => {
  const { reduxState } = usePublisherData({ document });

  if (reduxState.categoryName === DOCUMENT_CATEGORIES.WHITEPAPER) {
    return (
      <>
        <FieldName>Publisher</FieldName>
        <FieldValue>{document.publisher ?? ''}</FieldValue>
      </>
    );
  }

  return null;
};
