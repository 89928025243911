import type * as types from './TranslateFieldsStage.types';

import lodash from 'lodash';

/**
 *
 * This function travels through each file and collects
 * unique field values in order to make less API requests
 *
 */
export const mergeAllMetadataUniqueValues = <
  M = Parameters<types.TranslateFieldsStage['run']>[0]['allFilesInitialMetadata']
>(
  metadata: M
): Record<string, unknown[]> => {
  return Object.entries(metadata as Record<string, unknown>).reduce(
    (mergedDocFields, [fileName, docFieldValues]) => {
      return Object.entries(docFieldValues as Record<string, unknown>).reduce(
        (mergedDocFields, [docField, values]) => {
          const mergedDocFieldValues = mergedDocFields[docField as keyof typeof mergedDocFields];
          const metadataValues = Array.isArray(values) ? values : [values];

          return {
            ...mergedDocFields,
            [docField]: [
              ...new Set(
                Array.isArray(mergedDocFieldValues)
                  ? [...mergedDocFieldValues, ...metadataValues]
                  : metadataValues
              )
            ]
          };
        },
        mergedDocFields
      );
    },
    {}
  );
};

export const makeAPICalls = async ({
  metadataUniqueValues,
  asyncTranslationsConfig
}: {
  metadataUniqueValues: ReturnType<typeof mergeAllMetadataUniqueValues>;
  asyncTranslationsConfig: types.AsyncTranslationsConfig;
}) => {
  return (await Promise.all(
    Object.entries(metadataUniqueValues).flatMap(([metadataKey, metadataValues]) => {
      return metadataValues.flatMap(metadataValue =>
        (async () => {
          const config = asyncTranslationsConfig?.[metadataKey];

          if (!config) {
            return [];
          }

          const response = await config.onFetch({
            limit: 1,
            offset: 0,
            [config.searchByParam]: metadataValue
          });

          const fieldValue = lodash.get(response, config.responseValuePath);

          if (!fieldValue) {
            return [];
          }

          return [
            metadataKey,
            {
              metadataValue: metadataValue,
              translatedValue: fieldValue
            }
          ];
        })()
      );
    })
  )) as [
    metadataKey: string,
    result?: {
      metadataValue: unknown;
      translatedValue: unknown;
    }
  ][];
};
