import { Link } from 'react-router-dom';
import { Modal, Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import RestrictBubble from 'components/RestrictBubble';
import * as routes from 'constants/Routes';
import { useViewReduxActions } from 'shared/features/view/hooks';
import { RestrictModalIconItems, RestrictModalActionButton } from './elements';
import { useRestrictModalData } from './hooks';
import './_restrict-modal.scss';

export const RestrictModal = () => {
  const { formattedData, reduxState } = useRestrictModalData();

  const viewReduxActions = useViewReduxActions();

  if (!formattedData.modalData) {
    return null;
  }

  return (
    <Modal
      className="restrict-modal__container"
      isOpen
      onClose={viewReduxActions.hideRestrictModal}
      childContainerClassName="restrict-modal-child__container"
    >
      <div className="restrict-modal__header">
        <RestrictBubble />
        <h4>{formattedData.modalData.title}</h4>
      </div>

      <div className="restrict-modal__body">
        <div className="restrict-modal__text">{formattedData.modalData.text}</div>
        <RestrictModalIconItems items={formattedData.modalData.boxes} />
      </div>

      <div className="restrict-modal__footer">
        {reduxState.isAuthenticated && (
          <RestrictModalActionButton
            title={formattedData.modalData.button.authenticated.text}
            restrictionType={formattedData.restrictionType}
          />
        )}

        {!reduxState.isAuthenticated && (
          <>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              href={formattedData.modalData.button.unauthenticated.link}
              target="_blank"
            >
              {formattedData.modalData.button.unauthenticated.text}
            </Button>
            <div className="restrict-modal__footer-login">
              Already have an account? <Link to={routes.login}>Login</Link>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
