import moment from 'moment';

import {
  RECEIVE_DOC_TASKS_BY_DATE,
  CLEAR_DOC_TASKS,
  RECEIVE_ALL_WORKFLOWS
} from './docTasks.actions';

export const getInitialDocTasksByDateState = () => ({ formattedDocTasks: [] });

export const docTasksByDate = (state = getInitialDocTasksByDateState(), action) => {
  switch (action.type) {
    case RECEIVE_DOC_TASKS_BY_DATE: {
      const formattedDocTasks = action.response.doc_tasks_by_date.doc_tasks.map(
        ({
          id: doc_task_id,
          workflow_id,
          workflow_name,
          deadline,
          name: task_name,
          properties,
          task_status: status,
          doc_id,
          assignee_email: email
        }) => ({
          doc_task_id,
          workflow_id,
          workflow_name,
          start: moment(deadline),
          end: moment(deadline),
          allDay: true,
          status,
          doc_id,
          doc_title: properties.document?.title,
          task_name,
          deadline,
          email
        })
      );

      return {
        ...state,
        formattedDocTasks
      };
    }
    case CLEAR_DOC_TASKS:
      return getInitialDocTasksByDateState();
    default:
      return state;
  }
};

export const getInitialAllWorkflowsState = () => ({ items: [] });

export const allWorkflows = (state = getInitialAllWorkflowsState(), action) => {
  switch (action.type) {
    case RECEIVE_ALL_WORKFLOWS:
      return {
        ...state,
        items: action.response.all_workflows.map(({ name, id, deleted }) => ({
          label: deleted ? `(deleted) ${name}` : name,
          value: id
        }))
      };
    default:
      return state;
  }
};
