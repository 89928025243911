import type { RenameBucketModalProps } from '../RenameBucketModal.types';
import type { RenameBucketModalData } from './useRenameBucketModalData.types';

import * as bucketsConstants from 'shared/features/buckets/buckets.constants';
import * as actionsHelpers from 'shared/features/buckets/buckets.actions.helpers';
import * as actionsConstants from 'shared/features/buckets/buckets.actions.constants';
import * as errorUtils from 'utils/errors';

import { useMutation } from '@tanstack/react-query';
import { useBucketsReduxActions } from 'shared/features/buckets/hooks';

export const useRenameBucketModalMutations = ({
  props,
  localState
}: {
  props: Pick<
    RenameBucketModalProps,
    'onModalClose' | 'onModalActionSuccess' | 'bucket' | 'onModalActionStart'
  >;
  localState: RenameBucketModalData['localState'];
}) => {
  const bucketReduxActions = useBucketsReduxActions();

  const renameBucket = useMutation<void, Error, void>({
    onMutate: () => {
      props.onModalActionStart?.();
      props.onModalClose();
    },
    mutationFn: async () => {
      if (!props.bucket?.id) {
        return;
      }

      if (localState.bucketName === bucketsConstants.DEFAULT_BUCKET_NAME) {
        props.onModalClose();

        actionsHelpers.showValidationErrorNotification({
          message: actionsConstants.BUCKETS_ERRORS_MESSAGES.BUCKET_NAME_VALIDATION
        });

        return;
      }

      await bucketReduxActions.modifyBucket({
        id: props.bucket.id,
        params: { name: localState.bucketName }
      });
    },
    onSuccess: props.onModalActionSuccess,
    onError: errorUtils.logReactQueryError
  });

  return {
    renameBucket
  };
};
