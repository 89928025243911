import { createSelector } from '@reduxjs/toolkit';
import { IRRELEAVANT_META_CATEGORIES } from 'constants/JurisdictionAnalyzer';
import lodash from 'lodash';
import { componentNames as WIDGET_COMPONENT_NAMES } from 'constants/ComponentsNames';
import {
  ALERT_WIDGET_DOCUMENTS_INITIAL_STATE,
  CCPA_WIDGET_DOCUMENTS_INITIAL_STATE
} from './documents.reducer';
import { DOC_TYPES_REDUCER_NAME } from './documents.constants';
import { alphabeticallyByLabel } from '../../../utils/sort';

import * as uiLib from '@compliance.ai/web-components';

export const getDocTypesReducer = state => state[DOC_TYPES_REDUCER_NAME];
export const getDocumentsReducer = state => state.documents;

export const getCurrentDocumentReducer = state => state.current_document;

export const getDocTypes = createSelector(
  getDocTypesReducer,
  docTypesReducer => docTypesReducer.docTypes
);

export const getDocTypesOptions = createSelector(getDocTypes, docTypes => {
  if (Array.isArray(docTypes)) {
    return docTypes.map(({ id, name }) => ({
      value: id,
      label: name
    }));
  }

  return [];
});

export const getDocTypesUpdatesNotification = createSelector(
  getDocTypesReducer,
  docTypesReducer => docTypesReducer.docTypesUpdatesNotification
);

export const getMetacategories = createSelector(
  getDocTypes,
  docTypes => docTypes.cai_metacategories
);

export const getMetacategoriesList = createSelector(getDocTypes, docTypes => {
  return Object.values(docTypes.cai_metacategories);
});

export const getCategories = createSelector(getDocTypes, docTypes => docTypes.cai_categories);

export const getCategoriesList = createSelector(getDocTypes, docTypes => {
  return Object.values(docTypes.cai_categories);
});

export const getCategoryIdsByNames = createSelector(
  [getCategoriesList, (state, categoryNames) => categoryNames],
  (categories, categoryNames) => {
    return categories.flatMap(category => {
      if (categoryNames.includes(category.name) && category.is_active) {
        return [category.id];
      }

      return [];
    });
  }
);

export const getCategoriesIdsToNamesMap = createSelector(getCategoriesList, docTypes => {
  return docTypes.reduce((docTypes, docType) => {
    return {
      ...docTypes,
      [docType.id]: docType.name
    };
  }, {});
});

export const getCategoriesNamesToIdsMap = createSelector(getCategoriesList, docTypes => {
  return docTypes.reduce((docTypes, docType) => {
    return {
      ...docTypes,
      [docType.name]: docType.id
    };
  }, {});
});

export const getCategoryNameById = createSelector(
  [getCategoriesIdsToNamesMap, (_, categoryId) => categoryId],
  (idsToNamesMap, categoryId) => {
    return idsToNamesMap[categoryId];
  }
);

export const formatCategoriesBelongingToMetacategoryByField = (metacategories, field) => {
  const formattedCategories = {};

  Object.keys(metacategories).forEach(metacategoryID => {
    const categories = metacategories[metacategoryID].categories || [];
    categories.forEach(category => {
      formattedCategories[category[field]] = category;
    });
  });

  return formattedCategories;
};

export const formatCategoriesByField = (categories, field) => {
  const formattedCategories = {};

  Object.keys(categories).forEach(categoryID => {
    const category = categories[categoryID] || {};
    formattedCategories[category[field]] = category;
  });

  return formattedCategories;
};

export const getCategoriesByID = createSelector(getDocTypes, docTypes => {
  const { cai_categories: categories } = docTypes;
  return formatCategoriesByField(categories, 'id');
});

export const getCategoriesByName = createSelector(getDocTypes, docTypes => {
  const { cai_categories: categories } = docTypes;
  return formatCategoriesByField(categories, 'name');
});

export const getCategoryIDByName = (categories, name) => {
  return Number(
    Object.keys(categories).filter(
      categoryID =>
        categories[categoryID].name === name &&
        categories[categoryID].surface_in_filter &&
        categories[categoryID].is_active
    )[0]
  );
};

export const sanitizeCategoryName = (categoryName = '') => {
  return categoryName.split('___')[0];
};

export const getCategoryNameByID = (categories, id) => {
  let category = categories[id];

  if (category?.all_active_ids?.length) {
    category = categories[category.all_active_ids[0]];
  }

  return sanitizeCategoryName(category?.name);
};

export const getDocumentCategoryName = createSelector(
  [getCategories, (state, categoryId) => categoryId],
  (categories, categoryId) => {
    return getCategoryNameByID(categories, categoryId);
  }
);

const byCategoryName = (a, b) => a.label.localeCompare(b.label);

export const formatCategory = category => ({
  id: category.id,
  name: category.name,
  label: category.name,
  value: category.id
});

export const formatMetaCategories = metacategories => {
  if (!metacategories) {
    return [];
  }

  return Object.entries(metacategories)
    .map(([id, metacategory]) => ({
      label: metacategory.name,
      options: metacategory.categories.map(formatCategory).sort(byCategoryName)
    }))
    .sort(byCategoryName);
};

export const getMetaCategoryOptions = createSelector(getDocTypes, docTypes => {
  const { cai_metacategories: metacategories } = docTypes;
  return formatMetaCategories(metacategories);
});

export const getMetaCategoryOptionsByNames = createSelector(
  getMetaCategoryOptions,
  (state, metaCategories, valueKey) => ({
    metaCategories,
    valueKey
  }),
  (groupedDocTypes, { metaCategories, valueKey }) => {
    const filteredGroupedDocTypes = !metaCategories?.length
      ? groupedDocTypes
      : groupedDocTypes.filter(group => metaCategories.includes(group.label));

    return filteredGroupedDocTypes.map(optionsGroup => {
      return {
        ...optionsGroup,
        options: optionsGroup.options.map(option => {
          return {
            ...option,
            value: option[valueKey]
          };
        })
      };
    });
  }
);

export const getCategoriesIdsByMetacategories = metacategories =>
  createSelector(getDocTypes, docTypes => {
    return formatMetaCategories(docTypes.cai_metacategories).reduce((categories, group) => {
      if (metacategories.includes(group.label)) {
        return [...categories, ...group.options.map(o => o.value)];
      }

      return categories;
    }, []);
  });

export const getJurisdictionAnalyzerMetaCategoryOptions = createSelector(getDocTypes, docTypes => {
  const { cai_metacategories: metacategories } = docTypes;
  const metacategoriesForJA = {};

  Object.entries(metacategories).forEach(([metacategoryID, metacategory]) => {
    if (!IRRELEAVANT_META_CATEGORIES.includes(metacategory.name)) {
      metacategoriesForJA[metacategoryID] = metacategory;
    }
  });
  return formatMetaCategories(metacategoriesForJA);
});

export const getAllCategoriesIDsForMetacategoryName = (metacategoryName, metacategories) => {
  const metacategory = Object.keys(metacategories).filter(
    metacategoryID => metacategories[metacategoryID].name === metacategoryName
  );

  if (!metacategory.length) {
    return null;
  }

  const categories = metacategories[metacategory[0]].categories.map(category => category.id);

  return categories;
};

export const getAllCategoriesNamesForMetacategoryName = (metacategoryName, metacategories) => {
  const metacategory = Object.keys(metacategories).filter(
    metacategoryID => metacategories[metacategoryID].name === metacategoryName
  );

  if (!metacategory.length) {
    return null;
  }

  const categories = metacategories[metacategory[0]].categories.map(category => category.name);

  return categories;
};

export const extractCategoriesFromMetacategories = metacategories => {
  const allCategories = [];

  Object.keys(metacategories).forEach(metacategoryID => {
    metacategories[metacategoryID].categories.forEach(category => {
      allCategories.push(category);
    });
  });

  return allCategories;
};

export const getAllCategoriesBelongingToAMetacategory = createSelector(getDocTypes, docTypes => {
  const { cai_metacategories: metacategories } = docTypes;

  return extractCategoriesFromMetacategories(metacategories);
});

export const getCurrentDocumentFetchingState = createSelector(
  getCurrentDocumentReducer,
  currentDocumentReducer => {
    return currentDocumentReducer.isFetching;
  }
);
export const getCurrentDocument = createSelector(
  getCurrentDocumentReducer,
  currentDocumentReducer => {
    return currentDocumentReducer.doc?.document;
  }
);

export const getDocumentDetailsReducer = state => state.document_details;

export const getDocumentDetailsDocuments = createSelector(
  getDocumentDetailsReducer,
  documentDetailsReducer => documentDetailsReducer.documents,
  {
    memoizeOptions: {
      maxSize: 0
    }
  }
);

export const getDocumentDetailsByDocumentId = documentId =>
  createSelector(
    getDocumentDetailsDocuments,
    documentDetailsDocuments => {
      return documentDetailsDocuments[documentId];
    },
    {
      memoizeOptions: {
        maxSize: 0
      }
    }
  );

export const getDocumentRelatedDocuments = documentId =>
  createSelector(getDocumentDetailsByDocumentId(documentId), document => {
    return lodash.get(document, 'related_documents', []);
  });

export const areThereAnyRelatedDocuments = createSelector(
  (state, documentId) => getDocumentRelatedDocuments(documentId)(state),
  relatedDocuments => relatedDocuments.length > 0
);

export const getDocumentSummaries = documentId =>
  createSelector(getDocumentDetailsByDocumentId(documentId), document => {
    return lodash.get(document, 'summaries', []);
  });

export const areThereAnyDocumentSummaries = createSelector(
  (state, documentId) => getDocumentSummaries(documentId)(state),
  summaries => summaries.length > 0
);

export const doesDocumentHasSentences = documentId =>
  createSelector(getDocumentDetailsByDocumentId(documentId), document => {
    return lodash.get(document, 'has_sentences', false);
  });

export const getDocumentDetailsReadyState = createSelector(
  getDocumentDetailsReducer,
  documentDetailsReducer => documentDetailsReducer.isReady
);
export const getDocumentDetailsFetchingState = createSelector(
  getDocumentDetailsReducer,
  documentDetailsReducer => documentDetailsReducer.isFetching
);
export const getRelatedCount = createSelector(
  getDocumentDetailsReducer,
  documentDetailsReducer => documentDetailsReducer.related_count
);
export const getRelatedDocumentCount = document =>
  createSelector(getRelatedCount, relatedCount => relatedCount[document?.id]);

export const getCommentsCount = createSelector(
  getDocumentDetailsReducer,
  documentDetailsReducer => documentDetailsReducer.comments_count
);
export const getDocumentsCommentsCount = document =>
  createSelector(getCommentsCount, commentsCount => commentsCount[document?.id]);

export const getSavedFoldersCounts = createSelector(
  getDocumentDetailsReducer,
  documentDetailsReducer => documentDetailsReducer.saved_folders_count
);

export const getUSStateReducer = state => state.us_state;

export const getCCPAWidgetDocuments = widgetId =>
  createSelector(getDocumentsReducer, documentsReducer => {
    return (
      documentsReducer[WIDGET_COMPONENT_NAMES.CCPA][widgetId] || CCPA_WIDGET_DOCUMENTS_INITIAL_STATE
    );
  });

export const getAlertWidgetDocuments = widgetId =>
  createSelector(getDocumentsReducer, documentsReducer => {
    return (
      documentsReducer[WIDGET_COMPONENT_NAMES.ALERT][widgetId] ||
      ALERT_WIDGET_DOCUMENTS_INITIAL_STATE
    );
  });
export const getAlertWidgetDocumentsReadyState = widgetId =>
  createSelector(getAlertWidgetDocuments(widgetId), documentsData => {
    return documentsData.isReady;
  });

export const getDocumentsToDiffArray = createSelector(getDocumentsReducer, documentsReducer => {
  return documentsReducer.documentsToDiff || [];
});

export const getSortedDocumentsToDiff = createSelector(getDocumentsToDiffArray, documentsToDiff => {
  return [...documentsToDiff].sort((doc1, doc2) => {
    return uiLib.isAfter(
      uiLib.formatDate(doc1.publication_date, {
        format: uiLib.DATE_FORMATS.API_DATE,
        passedDateFormat: uiLib.DATE_FORMATS.API_DATE
      }),
      uiLib.formatDate(doc2.publication_date, {
        format: uiLib.DATE_FORMATS.API_DATE,
        passedDateFormat: uiLib.DATE_FORMATS.API_DATE
      })
    )
      ? 1
      : -1;
  });
});

export const getDocumentsToDiffIds = createSelector(getDocumentsToDiffArray, documentsToDiff => {
  return documentsToDiff.map(({ id }) => id);
});

export const getSearchResultsRelevanceReducer = state => state.search_results_relevance;
export const getSearchResultsRelevanceResults = createSelector(
  getSearchResultsRelevanceReducer,
  searchResultsRelevanceReducer => {
    return searchResultsRelevanceReducer.results;
  }
);
export const getSearchResultsRelevanceFetchingState = createSelector(
  getSearchResultsRelevanceReducer,
  searchResultsRelevanceReducer => {
    return searchResultsRelevanceReducer.isFetching;
  }
);

export const getRecentActivityReducer = state => state.recent_activity;

export const getAgenciesWithNewDocs = createSelector(
  getRecentActivityReducer,
  recentActivityReducer =>
    recentActivityReducer.document_stats.map(newAgency => {
      return newAgency.agency_id;
    })
);

export const getEnforcementsReducer = state => state.enforcements;
export const getEnforcementsLoadingState = createSelector(
  getEnforcementsReducer,
  enforcementsReducer => {
    return enforcementsReducer.isLoading;
  }
);
export const getAggregatedEnforcements = createSelector(
  getEnforcementsReducer,
  enforcementsReducer => {
    return enforcementsReducer.aggregateEnforcements;
  }
);

export const getFullDocumentsReducer = state => state.documents_full;

export const getGoogleSearchResultsReducer = state => state.googleSearchResults;

export const getGoogleSearchResults = createSelector(
  getGoogleSearchResultsReducer,
  googleSearchResultsReducer => {
    return googleSearchResultsReducer.googleSearchResults?.items ?? [];
  }
);

export const getGoogleSearchResultsTotalCount = createSelector(
  getGoogleSearchResultsReducer,
  googleSearchResultsReducer => {
    return (
      parseInt(
        googleSearchResultsReducer.googleSearchResults.searchInformation?.totalResults,
        10
      ) || 0
    );
  }
);

export const getGoogleSearchResultsFetchingState = createSelector(
  getGoogleSearchResultsReducer,
  googleSearchResultsReducer => {
    return googleSearchResultsReducer.isFetching;
  }
);

export const getGoogleSearchResultsReadyState = createSelector(
  getGoogleSearchResultsReducer,
  googleSearchResultsReducer => {
    return googleSearchResultsReducer.isReady;
  }
);

export const getGroupedLegacyDocTypesOptions = createSelector(
  getDocTypesReducer,
  docTypesReducer => {
    const metaCategoriesObject = lodash.get(docTypesReducer, ['docTypes', 'metacategory_dic'], {});

    return Object.keys(metaCategoriesObject).map(metaCategory => {
      return {
        label: metaCategory,
        options: metaCategoriesObject[metaCategory]
          .map(appCategory => {
            return { label: appCategory, value: appCategory };
          })
          .sort(alphabeticallyByLabel)
      };
    });
  }
);
