import type { DeleteBucketModalProps } from '../DeleteBucketModal.types';

import * as errorUtils from 'utils/errors';

import { useMutation } from '@tanstack/react-query';
import { useBucketsReduxActions } from 'shared/features/buckets/hooks';

export const useDeleteBucketModalMutations = ({
  props
}: {
  props: Pick<
    DeleteBucketModalProps,
    'bucket' | 'onModalActionSuccess' | 'onModalClose' | 'onModalActionStart'
  >;
}) => {
  const bucketReduxActions = useBucketsReduxActions();

  const deleteBucket = useMutation<void, Error, void>({
    onMutate: () => {
      props.onModalActionStart?.();
      props.onModalClose();
    },
    mutationFn: async () => {
      if (props.bucket?.id) {
        await bucketReduxActions.deleteBucket(props.bucket.id);
      }
    },
    onSuccess: props.onModalActionSuccess,
    onError: errorUtils.logReactQueryError
  });

  return {
    deleteBucket
  };
};
