import type { DocumentDetailsRightPanelConfigHandlers } from './useDocumentDetailsRightPanelConfigHandlers.types';

import * as analytics from 'utils/analytics';

import { useViewReduxActions } from 'shared/features/view/hooks';
import { useObligationsReduxActions } from 'shared/features/obligations/hooks';
import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';
import { useDocumentDetailsRightPanelConfigQueries } from './useDocumentDetailsRightPanelConfigQueries';

export const useDocumentDetailsRightPanelConfigHandlers = ({
  queries
}: {
  queries: ReturnType<typeof useDocumentDetailsRightPanelConfigQueries>;
}): DocumentDetailsRightPanelConfigHandlers => {
  const viewReduxActions = useViewReduxActions();
  const obligationsReduxActions = useObligationsReduxActions();
  const rightPanelReduxActions = useRightPanelReduxActions();

  const handleRestrictedTabClick: DocumentDetailsRightPanelConfigHandlers['handleRestrictedTabClick'] = title => () => {
    analytics.safe_analytics('default', 'Non-auth', 'Open restrict modal', title);
    viewReduxActions.showRestrictModal('', title, '');
  };

  const handleTabClick: DocumentDetailsRightPanelConfigHandlers['handleTabClick'] = ({
    tab,
    tabKey
  }) => () => {
    obligationsReduxActions.deselectSentences();
    rightPanelReduxActions.changeDocumentDetailsActiveTab({
      key: tabKey
    });

    analytics.safe_analytics(
      'Doc Details – ' + `View ${tab.label}`,
      'Doc Details',
      `View ${tab.label}`,
      queries.fetchDocumentDetails.data?.document?.title
    );
  };

  return {
    handleRestrictedTabClick,
    handleTabClick
  };
};
