import type { DocumentsTableSettingsData } from './useDocumentsTableSettingsData.types';
import type { DocumentsTableSettingsProps } from '../DocumentsTableSettings.types';

import { DOCUMENTS_TABLE_SETTINGS, ROW_COUNT_OPTIONS } from '../DocumentsTableSettings.constants';
import { useMemo } from 'react';

export const useDocumentsTableSettingsData = (
  props: Pick<DocumentsTableSettingsProps, 'value' | 'rowsCountOptions'>
): DocumentsTableSettingsData => {
  const formattedData: DocumentsTableSettingsData['formattedData'] = useMemo(() => {
    const rowsCountOptions = Array.isArray(props.rowsCountOptions)
      ? props.rowsCountOptions
      : ROW_COUNT_OPTIONS;

    const value = {
      [DOCUMENTS_TABLE_SETTINGS.ROWS_COUNT]:
        rowsCountOptions.find(o => o.value === props.value[DOCUMENTS_TABLE_SETTINGS.ROWS_COUNT]) ??
        null
    };

    return {
      value
    };
  }, [props.rowsCountOptions, props.value]);

  return {
    formattedData
  };
};
