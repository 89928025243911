import { memo, useCallback } from 'react';
import { TaskNodeLinkPoints } from './elements';
import { Tooltip } from 'common';
import { useTaskNodeData } from './hooks/useTaskNodeData';
import PropTypes from 'prop-types';
import { useFormattedTaskDate } from '../../hooks';
import './_task-node.scss';

export const TEST_IDS = {
  CONTAINER: 'task-node-container'
};

export const TaskNode = memo(({ data: { task, workflow } }) => {
  const { formattedData } = useTaskNodeData({ task, workflow });
  const { formattedTask } = formattedData;

  const { date, dateLabel } = useFormattedTaskDate({
    daysCount: formattedTask.daysCount,
    dateType: formattedTask.dateType
  });

  const renderDependentTask = useCallback(
    (dependentTask, index) => {
      const isLast = index === formattedData.dependentTasks.length - 1;

      return (
        <>
          <a className="task-node__dependent-task-name">{dependentTask.name}</a>
          {' is '}
          <span className="task-node__dependent-task-required-selections">
            {dependentTask.requiredSelectionsValues.join(', ')}
          </span>
          {!isLast && (formattedTask.isDependentOnAll ? ' and ' : ' or ')}
        </>
      );
    },
    [formattedData.dependentTasks.length, formattedTask.isDependentOnAll]
  );

  return (
    <>
      <TaskNodeLinkPoints taskId={task.id} />
      <div data-testid={TEST_IDS.CONTAINER} className="task-node__container">
        <span className="task-node__caption">
          <Tooltip wrapperClassName="task-node__caption-tooltip-wrapper" title={formattedTask.name}>
            {formattedTask.name}
          </Tooltip>
        </span>
        <p className="task-node__info--is-date">
          {date} {dateLabel}
        </p>
        <p className="task-node__info">
          Teams:
          <Tooltip wrapperClassName="task-node__info-tooltip-wrapper" title={formattedTask.teams}>
            {formattedTask.teams}
          </Tooltip>
        </p>
        <p className="task-node__info">
          Assigned to:
          <Tooltip
            wrapperClassName="task-node__info-tooltip-wrapper"
            title={formattedTask.assignee}
          >
            {formattedTask.assignee}
          </Tooltip>
        </p>
        {formattedData.hasDependencies && (
          <div className="task-node__dependencies-info-wrapper">
            {formattedTask.isRelevant ? 'Relevant if ' : 'Not relevant if '}
            {formattedData.dependentTasks.map(renderDependentTask)}
          </div>
        )}
      </div>
    </>
  );
});

export const taskPropTypes = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  teams: PropTypes.array,
  dependencies: PropTypes.arrayOf(
    PropTypes.shape({
      dependent_task_id: PropTypes.number,
      dependency_dropdown_selections: PropTypes.arrayOf(PropTypes.number)
    })
  ),
  relevant_on_dependency_check: PropTypes.bool,
  dependent_on_all: PropTypes.bool,
  assignee: PropTypes.number
});

export const workflowPropTypes = PropTypes.shape({
  tasks: PropTypes.arrayOf(taskPropTypes),
  annotation_settings: PropTypes.shape({
    dropdown_options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        value: PropTypes.string
      })
    )
  })
});

TaskNode.propTypes = {
  data: PropTypes.shape({
    task: taskPropTypes,
    workflow: workflowPropTypes
  }).isRequired
};
