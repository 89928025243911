import { useMemo } from 'react';
import { DEFAULT_TYPES } from 'constants/DefaultSources';
import { CUSTOM_FILTER_OPTIONS_KEYS, FILTER_KEY } from 'constants/PrimaryFilter';
import {
  formatAgencyOptions,
  formatJurisdictionsOptions,
  formatMainstreamNewsOptions
} from './useFilterOptions.helpers';
import {
  formatDefaultConcepts,
  formatDefaultCategories,
  formatDefaultEnforcementsCategories,
  formatDefaultTopics
} from './useFilterDefaultOptions.helpers';
import { useSelector } from 'react-redux';
import {
  getRealFollowedNewsSources,
  getRealFollowedTopics,
  getFollowedCategories,
  getFollowedConcepts,
  getFollowedAgencies,
  getFollowedNonCountryJurisdictions
} from 'shared/features/defaults/defaults.selectors';
import { getDefaultTopics } from 'shared/features/sources/sources.selectors';
import { getConceptOptions } from 'shared/features/concepts/concepts.selectors';
import { getMetacategories, getCategories } from 'shared/features/documents/documents.selectors';

export const useFilterDefaultOptions = (defaultsType = DEFAULT_TYPES.MY_DEFAULTS) => {
  const reduxState = useSelector(state => ({
    followedAgencies: getFollowedAgencies(defaultsType)(state),
    followedJurisdictions: getFollowedNonCountryJurisdictions(defaultsType)(state),
    followedMainstreamNewsSources: getRealFollowedNewsSources(defaultsType)(state),
    followedTopics: getRealFollowedTopics(defaultsType)(state),
    followedCategories: getFollowedCategories(defaultsType)(state),
    metaCategoryObject: getMetacategories(state),
    conceptOptions: getConceptOptions(state),
    followedConcepts: getFollowedConcepts(defaultsType)(state),
    defaultTopics: getDefaultTopics(state),
    allCategories: getCategories(state)
  }));

  const agencyOptions = useMemo(() => {
    return formatAgencyOptions(reduxState.followedAgencies);
  }, [reduxState.followedAgencies]);

  const jurisdictionOptions = useMemo(() => {
    return formatJurisdictionsOptions(reduxState.followedJurisdictions);
  }, [reduxState.followedJurisdictions]);

  const mainstreamNewsSourcesOptions = useMemo(() => {
    return formatMainstreamNewsOptions(reduxState.followedMainstreamNewsSources);
  }, [reduxState.followedMainstreamNewsSources]);

  const topicsOptions = useMemo(() => {
    return formatDefaultTopics({
      defaultTopics: reduxState.defaultTopics,
      followedTopics: reduxState.followedTopics
    });
  }, [reduxState.followedTopics, reduxState.defaultTopics]);

  const docTypesOptions = useMemo(() => {
    return formatDefaultCategories({
      categories: reduxState.followedCategories,
      allCategories: reduxState.allCategories
    });
  }, [reduxState.followedCategories, reduxState.allCategories]);

  const enforcementsDocTypesOptions = useMemo(() => {
    return formatDefaultEnforcementsCategories({
      metaCategoriesObject: reduxState.metaCategoryObject,
      followedCategories: reduxState.followedCategories
    });
  }, [reduxState.followedCategories, reduxState.metaCategoryObject]);

  const conceptOptions = useMemo(() => {
    return formatDefaultConcepts(reduxState.followedConcepts, reduxState.conceptOptions);
  }, [reduxState.conceptOptions, reduxState.followedConcepts]);

  return {
    [FILTER_KEY.AGENCIES]: agencyOptions,
    [FILTER_KEY.JURISDICTIONS]: jurisdictionOptions,
    [FILTER_KEY.MAINSTREAM_NEWS_SOURCES]: mainstreamNewsSourcesOptions,
    [FILTER_KEY.TOPICS]: topicsOptions,
    [FILTER_KEY.DOC_TYPES]: docTypesOptions,
    [FILTER_KEY.CONCEPTS]: conceptOptions,
    [CUSTOM_FILTER_OPTIONS_KEYS.ENFORCEMENTS_CATEGORY]: enforcementsDocTypesOptions
  };
};
