import type { RelatedDocumentsPanelProps } from '../RelatedDocumentsPanel.types';
import type {
  DocumentFromResponse,
  RelatedAlertFromResponse
} from 'shared/features/documents/documents.types';

import * as documentsApi from 'shared/features/documents/documents.api';
import * as relatedDocsConstants from 'constants/RelatedDocuments';
import * as queryKeys from 'constants/QueryKeys';
import * as errorUtils from 'utils/errors';

import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useRelatedDocumentsPanelMutations = ({
  props
}: {
  props: Pick<RelatedDocumentsPanelProps, 'originalDocID'>;
}) => {
  const queryClient = useQueryClient();

  const deleteRelatedDocument = useMutation<void, Error, DocumentFromResponse['id']>({
    mutationFn: relatedDocId =>
      documentsApi.api_deleteOrganizationRelatedDocuments(props.originalDocID, {
        [relatedDocsConstants.RELATED_DOCUMENT_REQUEST_KEYS.RELATED_DOC_IDS]: [relatedDocId]
      }),
    onError: errorUtils.logReactQueryError,
    onSuccess: () => {
      queryClient.invalidateQueries(
        queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.DOCUMENTS][
          queryKeys.QUERY_SECTIONS.DOCUMENT_DETAILS
        ](Number(props.originalDocID))
      );
    }
  });

  const deleteRelatedAlert = useMutation<void, Error, RelatedAlertFromResponse['id']>({
    mutationFn: relatedDocAlertId =>
      documentsApi.api_deleteOrganizationRelatedDocumentAlerts(props.originalDocID, {
        [relatedDocsConstants.RELATED_DOCUMENT_REQUEST_KEYS.RELATED_DOC_ALERT_IDS]: [
          relatedDocAlertId
        ]
      }),
    onError: errorUtils.logReactQueryError,
    onSuccess: () => {
      queryClient.invalidateQueries(
        queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.RELATED_ALERTS][
          queryKeys.QUERY_SECTIONS.DOCUMENT_DETAILS
        ](Number(props.originalDocID))
      );
    }
  });

  return {
    deleteRelatedDocument,
    deleteRelatedAlert
  };
};
