import type { DocumentTaskFromResponse } from 'shared/features/workflow/workflow.types';
import type { OrganizationMemberFromResponse } from 'shared/features/organizations/organizations.types';

import * as workflowConstants from 'shared/features/workflow/workflow.constants';
import * as workflowHelpers from 'shared/features/workflow/workflow.helpers';
import * as emptyValue from 'constants/EmptyValue';

export const formatApprover = ({
  documentTask,
  orgMembers
}: {
  documentTask: DocumentTaskFromResponse;
  orgMembers: OrganizationMemberFromResponse[];
}) => {
  if (
    [
      workflowConstants.APPROVAL_ANNOTATION_STATUSES.INITIAL,
      workflowConstants.APPROVAL_ANNOTATION_STATUSES.REJECTED
    ].includes(
      workflowHelpers.normalizeApprovalStatus(
        documentTask.approval_status
      ) as workflowConstants.APPROVAL_ANNOTATION_STATUSES
    )
  ) {
    return emptyValue.EMPTY_VALUE.DASH;
  }

  return (
    orgMembers.find(({ id }) => documentTask.assignee === id)?.email ?? emptyValue.EMPTY_VALUE.DASH
  );
};
