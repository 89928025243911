import type { SentenceNotesQueryData } from './useProgressiveSentenceNotesQueries.types';
import type { ProgressiveSentenceNotesArgs } from './useProgressiveSentenceNotes.types';
import type { ProgressiveSentenceNotesData } from './useProgressiveSentenceNotesData.types';

import * as queryKeys from 'constants/QueryKeys';
import * as errorUtils from 'utils/errors';
import * as helpers from './useProgressiveSentenceNotesQueries.helpers';

import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useSentenceNotesReduxActions } from 'shared/features/sentenceNotes/hooks';

export const useProgressiveSentenceNotesQueries = ({
  args,
  localState,
  localActions
}: {
  args: Pick<
    ProgressiveSentenceNotesArgs,
    'documentId' | 'type' | 'sentenceNoteId' | 'onFetchComplete'
  >;
  localState: ProgressiveSentenceNotesData['localState'];
  localActions: ProgressiveSentenceNotesData['localActions'];
}) => {
  const sentenceNotesReduxActions = useSentenceNotesReduxActions();

  const fetchSystemGeneratedSentenceNotes = useQuery({
    enabled: false,
    queryKey: queryKeys.COMPLEX_QUERY_KEY_GETTERS[
      queryKeys.QUERY_KEYS.SYSTEM_GENERATED_SENTENCE_NOTES
    ][queryKeys.QUERY_SECTIONS.RIGHT_PANEL](args.documentId),
    queryFn: () =>
      sentenceNotesReduxActions.fetchSystemGeneratedSentenceNotes({
        documentId: args.documentId
      }),
    cacheTime: 0,
    staleTime: 0,
    retry: false,
    refetchOnWindowFocus: false,
    onError: errorUtils.logReactQueryError
  });

  const fetchSentenceNoteById = useQuery({
    enabled: Boolean(!localState.hasFetchedSingleSentenceNote && args.sentenceNoteId),
    queryKey: queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.SENTENCE_NOTES][
      queryKeys.QUERY_SECTIONS.RIGHT_PANEL
    ]({
      id: args.sentenceNoteId
    }),
    queryFn: async () => {
      const response = await sentenceNotesReduxActions.getSentenceNotes({
        params: {
          id: args.sentenceNoteId
        }
      });

      localActions.setHasFetchedSingleSentenceNote(true);

      return response;
    },
    cacheTime: 0,
    staleTime: 0,
    retry: false,
    refetchOnWindowFocus: false,
    onError: errorUtils.logReactQueryError
  });

  const fetchSentenceNotes = useInfiniteQuery<SentenceNotesQueryData, Error>({
    enabled:
      localState.hasFetchedSingleSentenceNote ||
      (!localState.hasFetchedSingleSentenceNote && !args.sentenceNoteId),
    queryKey: queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.SENTENCE_NOTES][
      queryKeys.QUERY_SECTIONS.RIGHT_PANEL
    ](
      helpers.getRequestParams({
        args: args,
        prevPageParams: {}
      })
    ),
    queryFn: async ({ pageParam }) => {
      const params = helpers.getRequestParams({
        args: args,
        prevPageParams: pageParam
      });

      const response = await sentenceNotesReduxActions.getSentenceNotes({
        params
      });

      localActions.setIsInitialFetchOfList(false);
      localActions.setTotalCount(response.total);

      return {
        nextParams: helpers.getNextPageRequestParams({
          prevPageParams: pageParam,
          response: response
        })
      };
    },
    cacheTime: 0,
    staleTime: 0,
    retry: false,
    refetchOnWindowFocus: false,
    getNextPageParam: lastPage => lastPage.nextParams,
    onSuccess: helpers.getSystemGeneratedSentenceNotes({
      args: args,
      events: {
        onFetch: fetchSystemGeneratedSentenceNotes.refetch
      }
    }),
    onError: errorUtils.logReactQueryError
  });

  return {
    fetchSystemGeneratedSentenceNotes,
    fetchSentenceNotes,
    fetchSentenceNoteById
  };
};
