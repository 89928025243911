import type { ReactNode } from 'react';

export enum RELATED_DOCUMENT_KEYS {
  DOCUMENTS_GROUP_BY = 'documents_group_by',
  RELATED_DOC_IDS = 'related_doc_ids',
  RELATED_DOCS = 'related_docs',
  RELATED_DOC_ALERTS = 'related_doc_alerts',
  RELATION_TYPE = 'relation_type',
  TOTAL_COUNT = 'total_count'
}

export enum RELATED_DOCUMENT_RELATION_TYPES {
  PREMIUM_CONTENT = 'premium content',
  DOCUMENTS = 'documents',
  LANGUAGE = 'language',
  TRANSLATION = 'translation',
  ORGANIZATION = 'organization',
  ORGANIZATION_ALERT = 'organization_alert'
}

export const RELATED_DOCUMENT_CHICLET_TITLES: Record<RELATED_DOCUMENT_RELATION_TYPES, ReactNode> = {
  [RELATED_DOCUMENT_RELATION_TYPES.PREMIUM_CONTENT]: 'Related Premium Content',
  [RELATED_DOCUMENT_RELATION_TYPES.DOCUMENTS]: 'Related Documents',
  [RELATED_DOCUMENT_RELATION_TYPES.LANGUAGE]: 'Official Languages',
  [RELATED_DOCUMENT_RELATION_TYPES.TRANSLATION]: 'View Auto-Translation',
  [RELATED_DOCUMENT_RELATION_TYPES.ORGANIZATION]: 'Docs Related by Org',
  [RELATED_DOCUMENT_RELATION_TYPES.ORGANIZATION_ALERT]: null
};

export const MAX_RELATED_DOCUMENT_ALERTS_COUNT = 5;
export const RELATED_DOCUMENT_ALERTS_TOOLTIP_TITLE = `You can add up to MAX_RELATED_DOCUMENT_ALERTS_COUNT alerts. Remove an alert if you wish to add a different one.`;

export const RELATED_DOCUMENT_REQUEST_KEYS = {
  RELATED_DOC_IDS: 'related_doc_ids',
  RELATED_DOC_ALERT_IDS: 'related_doc_alert_ids'
};

export const SHOW_MORE_RELATED_DOCUMENTS_BUTTON_TEXT = 'See more';
