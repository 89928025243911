import { ADD_ERROR, CLEAR_ERRORS } from './actions';

const error = (state, action) => {
  switch (action.type) {
    case ADD_ERROR:
      state = state || [];
      return [...state, action.error];
    default:
      return state;
  }
};

export const errors = (state = {}, action) => {
  switch (action.type) {
    case ADD_ERROR:
      return {
        ...state,
        [action.component]: error(state[action.component], action)
      };
    case CLEAR_ERRORS: {
      if (action.component) {
        const new_state = { ...state };
        delete new_state[action.component];

        return new_state;
      }
      return {};
    }
    default:
      return state;
  }
};
