import * as reduxToolkit from '@reduxjs/toolkit';
import * as constants from './rightPanel.constants';
import * as actions from './rightPanel.actions';

export const INITIAL_STATE = {
  isOpen: false,
  type: null,
  isClosable: false,
  props: {},
  overlay: {
    isOpen: false,
    type: null,
    refreshTrigger: false
  },
  documentDetails: {
    activeTabKey: null,
    expandedRelatedDocsType: null,
    labelsRefreshTrigger: false,
    tasksRefreshTrigger: false
  }
};

export const { reducer } = reduxToolkit.createSlice({
  name: constants.REDUCER_NAME,
  initialState: INITIAL_STATE,
  extraReducers: {
    [actions.openRightPanel.type]: (state, action) => {
      state.isOpen = true;
      state.type = action.payload.type ?? INITIAL_STATE.type;
      state.props = action.payload.props ?? INITIAL_STATE.props;
      state.isClosable = action.payload.isClosable ?? INITIAL_STATE.isClosable;

      state.documentDetails.activeTabKey =
        action.payload.props?.activeTabKey ?? constants.DOCUMENT_DETAILS_TAB_KEY.DETAILS;
      state.documentDetails.expandedRelatedDocsType =
        action.payload.props?.expandedRelatedDocsType ?? null;

      if (action.payload.props?.shouldOpenRightPanelWithOverlay) {
        state.overlay.isOpen = true;
        state.overlay.type = action.payload.overlayType ?? constants.OVERLAY_TYPES.PDF;
      }

      if (
        state.overlay.isOpen &&
        action.payload.type !== constants.RIGHT_PANEL_TYPES.DOCUMENT_DETAILS
      ) {
        state.overlay.isOpen = false;
      }
    },
    [actions.closeRightPanel.type]: state => {
      Object.keys(state).forEach(stateKey => {
        state[stateKey] = INITIAL_STATE[stateKey];
      });
    },
    [actions.changeDocumentDetailsActiveTab.type]: (state, action) => {
      const { key, section } = action.payload;

      state.documentDetails.activeTabKey = key;
      state.documentDetails.expandedRelatedDocsType = section;
    },
    [actions.changeSentenceNoteId.type]: (state, action) => {
      const { id } = action.payload;

      state.props.sentenceNoteId = id;
    },
    [actions.triggerRightPanelLabelsRefresh.type]: state => {
      state.documentDetails.labelsRefreshTrigger = !state.documentDetails.labelsRefreshTrigger;
    },
    [actions.triggerRightPanelTasksRefresh.type]: state => {
      state.documentDetails.tasksRefreshTrigger = !state.documentDetails.tasksRefreshTrigger;
    },
    [actions.triggerOverlayRefresh.type]: state => {
      state.overlay.refreshTrigger = !state.overlay.refreshTrigger;
    },
    [actions.openOverlay.type]: (state, action) => {
      state.overlay.isOpen = true;
      state.overlay.type = action.payload;
    },
    [actions.closeOverlay.type]: state => {
      state.overlay = INITIAL_STATE.overlay;
    }
  }
});
