import type { FC } from 'react';
import type { RenameBucketModalProps } from './RenameBucketModal.types';

import * as uiLib from '@compliance.ai/web-components';

import {
  useRenameBucketModalData,
  useRenameBucketModalHandlers,
  useRenameBucketModalMutations
} from './hooks';

import './_rename-bucket-modal.scss';

export const RenameBucketModal: FC<RenameBucketModalProps> = ({
  onModalClose,
  onModalActionStart,
  onModalActionSuccess,
  bucket
}) => {
  const { localState, localActions, formattedData } = useRenameBucketModalData({
    props: {
      bucket
    }
  });

  const handlers = useRenameBucketModalHandlers({
    localActions
  });

  const mutations = useRenameBucketModalMutations({
    props: {
      bucket,
      onModalClose,
      onModalActionStart,
      onModalActionSuccess
    },
    localState
  });

  return (
    <uiLib.Modal
      title="Rename collection"
      isOpen
      onClose={onModalClose}
      withFooter
      primaryButtonType={uiLib.BUTTON_TYPES.PRIMARY}
      primaryButtonText="Rename"
      secondaryButtonType={uiLib.BUTTON_TYPES.LINK}
      secondaryButtonText="Cancel"
      isPrimaryButtonDisabled={formattedData.isRenameBucketButtonDisabled}
      onSubmit={mutations.renameBucket.mutate}
      classNames={{
        container: 'rename-bucket-modal',
        content: 'rename-bucket-modal__container',
        title: 'rename-bucket-modal__title'
      }}
    >
      <uiLib.LabeledField
        label={
          <uiLib.Typography className="rename-bucket-modal__input-label">
            Type to rename the collection
          </uiLib.Typography>
        }
      >
        <uiLib.TextField
          value={localState.bucketName}
          placeholder="Enter a new collection name"
          onChange={handlers.handleBucketNameChange}
        />
      </uiLib.LabeledField>
    </uiLib.Modal>
  );
};
