import { api_fetchTopicThresholdOptions } from './thresholds.api';

export const RECEIVE_TOPIC_THRESHOLD_OPTIONS = 'RECEIVE_TOPIC_THRESHOLD_OPTIONS';

const receiveTopicThresholdOptions = (topicThresholdOptions, defaultKey) => ({
  type: RECEIVE_TOPIC_THRESHOLD_OPTIONS,
  topicThresholdOptions,
  defaultKey
});

export const fetchTopicThresholdOptions = () => async dispatch => {
  try {
    const response = await api_fetchTopicThresholdOptions();
    dispatch(receiveTopicThresholdOptions(response.topic_threshold_options, response.default_key));
  } catch (error) {
    // pass
  }
};
