import type { GlobalState } from 'shared/reducers';
import type { CategoriesSelectData } from './useCategoriesSelectData.types';
import type { CategoriesSelectProps } from '../CategoriesSelect.types';

import { CATEGORIES_SELECT_VALUE_KEYS } from '../CategoriesSelect.constants';

import { getMetaCategoryOptionsByNames } from 'shared/features/documents/documents.selectors';

import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const useCategoriesSelectData = ({
  value,
  exclusiveMetaCategories,
  valueKey
}: CategoriesSelectProps): CategoriesSelectData => {
  const reduxState = useSelector<GlobalState, CategoriesSelectData['reduxState']>(state => ({
    options: getMetaCategoryOptionsByNames(
      state as never,
      exclusiveMetaCategories as never,
      (valueKey ?? CATEGORIES_SELECT_VALUE_KEYS.ID) as never
    )
  }));

  const formattedData = useMemo(
    () => ({
      value: reduxState.options
        .flatMap(({ options }) => options)
        .filter(o => value?.includes(o.value))
    }),
    [reduxState.options, value]
  );

  return {
    reduxState,
    formattedData
  };
};
