import type { SelectOption } from '@compliance.ai/web-components';

import { WORKFLOW_TYPE } from 'shared/features/workflow/workflow.constants';

export const ALERTS_COUNT_PER_REQUEST = 20;

export const WORKFLOW_TYPE_SELECT_OPTIONS: SelectOption<WORKFLOW_TYPE>[] = [
  {
    label: 'Entire document',
    value: WORKFLOW_TYPE.DOCUMENT
  },
  {
    label: 'Obligation',
    value: WORKFLOW_TYPE.OBLIGATION
  }
];
