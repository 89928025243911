import type * as storeTypes from 'store';
import type * as actionsTypes from '../sentenceNotes.actions.types';

import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import * as reduxToolkit from '@reduxjs/toolkit';
import * as actions from '../sentenceNotes.actions';

export const useSentenceNotesReduxActions = () => {
  const dispatch = useDispatch<storeTypes.StoreDispatch>();

  const clearSentenceNotesReducer = useCallback(
    (...args: Parameters<typeof actions.clearSentenceNotesReducer>) => {
      dispatch(actions.clearSentenceNotesReducer(...args));
    },
    [dispatch]
  );

  const toggleSentenceNote = useCallback(
    (...args: Parameters<typeof actions.toggleSentenceNote>) => {
      dispatch(actions.toggleSentenceNote(...args));
    },
    [dispatch]
  );

  const createSentenceNote = useCallback(
    async (...args: Parameters<typeof actions.createSentenceNote>) => {
      const result = await dispatch(actions.createSentenceNote(...args));
      return reduxToolkit.unwrapResult(
        (result as unknown) as Parameters<typeof reduxToolkit.unwrapResult>[0]
      ) as actionsTypes.CreateSentenceNoteReturn;
    },
    [dispatch]
  );

  const getSentenceNotes = useCallback(
    async (...args: Parameters<typeof actions.getSentenceNotes>) => {
      const result = await dispatch(actions.getSentenceNotes(...args));
      return reduxToolkit.unwrapResult(
        (result as unknown) as Parameters<typeof reduxToolkit.unwrapResult>[0]
      ) as actionsTypes.GetSentenceNotesReturn;
    },
    [dispatch]
  );

  const fetchSystemGeneratedSentenceNotes = useCallback(
    async (...args: Parameters<typeof actions.fetchSystemGeneratedSentenceNotes>) => {
      const result = await dispatch(actions.fetchSystemGeneratedSentenceNotes(...args));
      return reduxToolkit.unwrapResult(
        (result as unknown) as Parameters<typeof reduxToolkit.unwrapResult>[0]
      ) as actionsTypes.FetchSystemGeneratedSentenceNotesReturn;
    },
    [dispatch]
  );

  const modifySentenceNote = useCallback(
    async (...args: Parameters<typeof actions.modifySentenceNote>) => {
      const result = await dispatch(actions.modifySentenceNote(...args));
      return reduxToolkit.unwrapResult(
        (result as unknown) as Parameters<typeof reduxToolkit.unwrapResult>[0]
      ) as actionsTypes.ModifySentenceNoteReturn;
    },
    [dispatch]
  );

  const deleteSentenceNote = useCallback(
    async (...args: Parameters<typeof actions.deleteSentenceNote>) => {
      const result = await dispatch(actions.deleteSentenceNote(...args));
      return reduxToolkit.unwrapResult(
        (result as unknown) as Parameters<typeof reduxToolkit.unwrapResult>[0]
      ) as actionsTypes.DeleteSentenceNoteReturn;
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      toggleSentenceNote,
      createSentenceNote,
      clearSentenceNotesReducer,
      getSentenceNotes,
      fetchSystemGeneratedSentenceNotes,
      modifySentenceNote,
      deleteSentenceNote
    }),
    [
      toggleSentenceNote,
      createSentenceNote,
      clearSentenceNotesReducer,
      getSentenceNotes,
      fetchSystemGeneratedSentenceNotes,
      modifySentenceNote,
      deleteSentenceNote
    ]
  );
};
