import * as regex from 'constants/Regex';
import * as uiLib from '@compliance.ai/web-components';
import * as constants from './MapToStateMetadataStage.constants';

export const parseValue = (data: unknown): string[] => {
  if (!['string', 'number'].includes(typeof data)) {
    return [];
  }

  const stringifiedData = String(data);
  const possibleDate = stringifiedData.replace(' GMT', '');

  if (uiLib.isDateValidToFormat(possibleDate, constants.UNCONVENTIONAL_DATE_FORMAT)) {
    return [
      uiLib.formatDate(possibleDate, {
        format: uiLib.getLocalDateFormat(),
        passedTimezone: uiLib.TIMEZONES.UTC,
        timezone: uiLib.TIMEZONES.UTC,
        shouldKeepLocalTime: true
      })
    ];
  }

  if (
    stringifiedData.length &&
    stringifiedData[0] === '[' &&
    stringifiedData[stringifiedData.length - 1] === ']'
  ) {
    return stringifiedData
      .slice(stringifiedData.indexOf('[') + 1, stringifiedData.lastIndexOf(']'))
      .split(regex.COMMA_WITH_OR_WITHOUT_SPACE)
      .map(item => item.slice(1, -1));
  }

  return [stringifiedData];
};
