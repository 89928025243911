import {
  api_fetchAgencyInfo,
  api_fetchAgencyEnforcementsByMonth,
  api_fetchAgencies
} from './agencies.api';

export const REQUEST_AGENCIES = 'REQUEST_AGENCIES';
export const RECEIVE_AGENCIES = 'RECEIVE_AGENCIES';

function requestAgencies(following) {
  return {
    type: REQUEST_AGENCIES,
    following
  };
}

export function receiveAgencies(json, following) {
  return {
    type: RECEIVE_AGENCIES,
    agencies: json.agencies,
    following
  };
}

export function fetchAgencyInfo(agencyId) {
  return function doFetch() {
    return api_fetchAgencyInfo(agencyId);
  };
}

export function fetchAllAgencies() {
  return async dispatch => {
    dispatch(requestAgencies());

    const response = await api_fetchAgencies();

    dispatch(receiveAgencies(response));

    return response;
  };
}

export function fetchAgencyEnforcementsByMonth(params) {
  return function doFetch() {
    return api_fetchAgencyEnforcementsByMonth(params);
  };
}
