import type { DocumentTimelineData } from './useDocumentTimelineData.types';
import type { GlobalState } from 'shared/reducers';

import * as rightPanelConstants from 'shared/features/rightPanel/rightPanel.constants';
import * as documentSelectors from 'shared/features/documents/documents.selectors';
import * as authSelectors from 'shared/features/auth/auth.selectors';
import * as rightPanelSelectors from 'shared/features/rightPanel/rightPanel.selectors';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const useDocumentTimelineData = (): DocumentTimelineData => {
  const [overlayType, setOverlayType] = useState(rightPanelConstants.OVERLAY_TYPES.PDF);

  const localState: DocumentTimelineData['localState'] = {
    overlayType
  };

  const localActions: DocumentTimelineData['localActions'] = useMemo(
    () => ({
      setOverlayType
    }),
    []
  );

  const reduxState: DocumentTimelineData['reduxState'] = useSelector<
    GlobalState,
    DocumentTimelineData['reduxState']
  >(state => ({
    documentsToDiff: documentSelectors.getDocumentsToDiffArray(state),
    overlayType: rightPanelSelectors.getOverlayType(state),
    isOverlayOpen: rightPanelSelectors.getOverlayOpenState(state),
    isAuthenticated: authSelectors.getUserAuthenticatedState(state)
  }));

  return {
    localState,
    localActions,
    reduxState
  };
};
