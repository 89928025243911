import PropTypes from 'prop-types';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';
import * as constants from './LabelDetails.constants';

import { useOrganizationLabelsReduxActions } from 'shared/features/labels/hooks';
import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';
import {
  useLabelDetailsData,
  useLabelDetailsHandlers,
  useLabelDetailsLifecycle,
  useLabelDetailsTableConfig,
  useLabelDetailsModalHandlers,
  useLabelDetailsModalData
} from './hooks';

import './_label-details.scss';

export const LabelDetails = ({ labelId }) => {
  const { reduxState, localState, localActions } = useLabelDetailsData();

  const organizationLabelsReduxActions = useOrganizationLabelsReduxActions();
  const rightPanelReduxActions = useRightPanelReduxActions();

  const { modalState, modalActions, modalFormattedData } = useLabelDetailsModalData();

  const {
    handleAuthorsModalShow,
    handleAuthorsModalHide,
    handleLabelsMoveModalShow,
    handleLabelsMoveModalHide,
    handleLabelsRemoveModalShow,
    handleLabelsRemoveModalHide
  } = useLabelDetailsModalHandlers({ modalActions });

  const tableConfig = useLabelDetailsTableConfig();

  const {
    handleLabelFetch,
    handleFilterSave,
    handleDataFetch,
    handleDocumentsMoveConfirm,
    handleDocumentsRemoveConfirm,
    handleSelectAllClick,
    handleTriggerChange,
    handleSelectedDocumentsIdsChange
  } = useLabelDetailsHandlers({
    props: {
      labelId,
      handleAuthorsModalShow
    },
    localState: { ...localState, ...modalState },
    localActions,
    reduxState,
    modalActions,
    organizationLabelsReduxActions,
    rightPanelReduxActions
  });

  useLabelDetailsLifecycle({
    onLabelFetch: handleLabelFetch,
    onTriggerChange: handleTriggerChange
  });

  return (
    <div className="label-details__container">
      <uiLib.ErrorBoundary>
        <h2 className="label-details__title">
          {localState.labelName}
          {/**
               Adding &nbsp; in order to prevent layout movement
               **/}
          &nbsp;
        </h2>
        <uiLib.Table
          customActionsElements={
            <div className="label-details__custom-actions-wrapper">
              {localState.isLabelEditable && (
                <>
                  <uiLib.ActionButton
                    tooltip="Delete"
                    isDisabled={modalFormattedData.isActionButtonDisabled}
                    onClick={handleLabelsRemoveModalShow}
                  >
                    <uiLib.IconDelete />
                  </uiLib.ActionButton>
                  <uiLib.ActionButton
                    tooltip="Move to"
                    isDisabled={modalFormattedData.isActionButtonDisabled}
                    onClick={handleLabelsMoveModalShow}
                  >
                    <uiLib.IconMove />
                  </uiLib.ActionButton>
                </>
              )}
              <elements.LabelDetailsFilters onSave={handleFilterSave} />
            </div>
          }
          onSelectAllClick={handleSelectAllClick}
          rowKey={constants.TABLE_FIELDS.DOCUMENT_ID}
          columns={tableConfig.columns}
          shouldShowRefreshButton
          shouldUpdateQueryParams
          shouldRenderToolbar
          onDataFetch={handleDataFetch}
          reloadTrigger={localState.refreshTrigger}
          areRowsSelectable={localState.isLabelEditable}
          onSelectedRowKeysChange={handleSelectedDocumentsIdsChange}
          classNames={{
            container: 'label-details__table-outer-container',
            tableContainer: 'label-details__table-inner-container'
          }}
        />
        {modalState.isAuthorsModalOpen && (
          <elements.LabelAuthorsModal
            onClose={handleAuthorsModalHide}
            authors={modalState.rowAuthors}
          />
        )}
        {modalState.isLabelsRemoveModalOpen && (
          <elements.LabelsRemoveModal
            onClose={handleLabelsRemoveModalHide}
            onDelete={handleDocumentsRemoveConfirm}
          />
        )}
        {modalState.isLabelsMoveModalOpen && (
          <elements.LabelsMoveModal
            onClose={handleLabelsMoveModalHide}
            label={localState.label}
            updateLabel={localActions.setLabel}
            onConfirm={handleDocumentsMoveConfirm}
          />
        )}
      </uiLib.ErrorBoundary>
    </div>
  );
};

LabelDetails.propTypes = {
  labelId: PropTypes.number.isRequired
};
