import PropTypes from 'prop-types';
import { Modal } from 'common';
import { Table } from '@compliance.ai/web-components';
import { TABLE_FIELDS, API_KEYS } from '../../LabelDetails.constants';
import './_lable-authors-modal.scss';

const columns = [
  {
    title: 'Added by',
    dataKey: TABLE_FIELDS.USER_EMAIL,
    sortKey: API_KEYS.USER_EMAIL,
    defaultSort: true
  },
  {
    title: 'Date added',
    dataKey: TABLE_FIELDS.ADDED_AT
  }
];

export const LabelAuthorsModal = ({ authors, onClose }) => {
  return (
    <Modal
      className="label-authors-modal"
      width="400px"
      title="Multiple labels"
      open
      onClose={onClose}
    >
      <Table
        shouldRenderToolbar={false}
        shouldUpdateQueryParams={false}
        columns={columns}
        rows={authors}
      />
    </Modal>
  );
};

LabelAuthorsModal.propTypes = {
  authors: PropTypes.array.isRequired,
  onClose: PropTypes.func
};
