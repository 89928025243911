import { RESTRICTION_MODAL_ICON_TYPES } from '../../RestrictModal.constants';

export const RestrictModalIconItems = ({ items = [] }) => {
  return (
    <div className="restrict-modal__box-items">
      {items.map(({ icon, text }) => {
        const { type, src, className } = icon;
        const iconComponent =
          type === RESTRICTION_MODAL_ICON_TYPES.IMAGE ? (
            <img src={src} className={className} alt={text} />
          ) : (
            <icon className={className} alt={text} />
          );

        return (
          <div key={text} className="restrict-modal__box-item">
            <div className="restrict-modal__box-item--icon">{iconComponent}</div>
            <div className="restrict-modal__box-item--text">{text}</div>
          </div>
        );
      })}
    </div>
  );
};
