import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CommentInfo } from './elements';
import RichTextEditor from '../../../../../RichTextEditor';
import './_comment.scss';

const Comment = ({
  comment,
  isReadOnly,
  onSave,
  onCancelClick,
  onEditClick,
  onDeleteClick,
  isEditableByAuthor
}) => {
  return (
    <div
      key={comment.id}
      className={classnames('comment__container', {
        'comment__container--paddingless': !isReadOnly && comment.isRootComment
      })}
    >
      {isReadOnly && (
        <CommentInfo
          comment={comment}
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
          isEditableByAuthor={isEditableByAuthor}
        />
      )}
      <RichTextEditor
        richtext={comment.richtext}
        onSave={richtext => onSave(richtext, comment)}
        isReadOnly={isReadOnly}
        placeholder={'Add a comment...'}
        onCancel={onCancelClick}
        className={classnames('comment__text-editor', {
          'comment__text-editor--bordered': !isReadOnly && !comment.isRootComment
        })}
      />
    </div>
  );
};

export default Comment;

Comment.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.number,
    richtext: PropTypes.string,
    userId: PropTypes.number,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    repliedTo: PropTypes.number,
    commentThreadId: PropTypes.number
  }).isRequired,
  isReadOnly: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  isEditable: PropTypes.bool
};

Comment.defaultProps = {
  isReadOnly: false,
  isEditableByAuthor: true
};
