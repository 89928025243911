import type { WorkflowsSelectProps } from '../WorkflowsSelect.types';
import type { WorkflowsSelectHandlers } from './useWorkflowsSelectHandlers.types';

import { useCallback } from 'react';
import { WORKFLOWS_PER_REQUEST_COUNT } from '../WorkflowsSelect.constants';
import { useWorkflowsApi } from 'shared/features/workflow/hooks';
import { formatWorkflowOptions } from './useWorkflowsSelectHandlers.helpers';

export const useWorkflowsSelectHandlers = ({
  props
}: {
  props: Pick<WorkflowsSelectProps, 'shouldShowDeletedWorkflows' | 'showAllWorkflowsOption'>;
}): WorkflowsSelectHandlers => {
  const workflowsApi = useWorkflowsApi();

  const handleWorkflowsFetch: WorkflowsSelectHandlers['handleWorkflowsFetch'] = useCallback(
    async inputValue => {
      try {
        const requests = [
          workflowsApi.fetchWorkflows({
            limit: WORKFLOWS_PER_REQUEST_COUNT,
            offset: 0,
            name: inputValue
          })
        ];

        if (props.shouldShowDeletedWorkflows) {
          requests.push(
            workflowsApi.fetchWorkflows({
              limit: WORKFLOWS_PER_REQUEST_COUNT,
              offset: 0,
              name: inputValue,
              deleted: true
            })
          );
        }

        const responses = await Promise.all(requests);

        return formatWorkflowOptions({
          workflowsResponse: responses[0],
          deletedWorkflowsResponse: responses[1] ?? { count: 0, results: [] },
          showAllWorkflowsOption: props.showAllWorkflowsOption
        });
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    [props.shouldShowDeletedWorkflows, props.showAllWorkflowsOption, workflowsApi]
  );

  return {
    handleWorkflowsFetch
  };
};
