import { REQUEST_AGENCIES, RECEIVE_AGENCIES } from './agencies.actions';

export const getInitialAgenciesState = () => ({
  isFetching: false,
  isReady: false,
  items: []
});

export const agencies = (state = getInitialAgenciesState(), action) => {
  switch (action.type) {
    case REQUEST_AGENCIES:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };

    case RECEIVE_AGENCIES: {
      const { agencies: items } = action;

      return {
        ...state,
        isFetching: false,
        isReady: true,
        items
      };
    }

    default:
      return state;
  }
};
