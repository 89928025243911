import type { GlobalState } from '../../../../reducers';
import type { DocumentDetailsTabAvailabilityData } from './useDocumentDetailsTabsAvailabilityData.types';

import * as authUtils from 'utils/authorization';
import * as rightPanelSelectors from '../../rightPanel.selectors';
import * as authSelectors from '../../../auth/auth.selectors';
import * as documentSelectors from '../../../documents/documents.selectors';
import * as viewSelectors from '../../../view/view.selectors';
import * as expertConnectionSelectors from '../../../expertConnection/expertConnection.selectors';

import { useSelector } from 'react-redux';
import { useDocumentDetailsTabsAvailability } from './useDocumentDetailsTabsAvailability';

export const useDocumentDetailsTabsAvailabilityData = ({
  documentId
}: Pick<
  Parameters<typeof useDocumentDetailsTabsAvailability>[0],
  'documentId'
>): DocumentDetailsTabAvailabilityData => {
  const reduxState = useSelector<GlobalState, DocumentDetailsTabAvailabilityData['reduxState']>(
    state => ({
      isDiffView: rightPanelSelectors.isDiffView(state),
      isSummaryPage: rightPanelSelectors.isSummaryPage(state),
      isAuthenticated: authSelectors.getUserAuthenticatedState(state),
      doesDocumentHasSentences: documentSelectors.doesDocumentHasSentences(documentId)(state),
      isMobile: viewSelectors.isMobileView(state),
      // TODO Uncomment when document selectors will be moved to TS
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      areThereAnyExperts: expertConnectionSelectors.areThereAnyExperts(state, documentId),
      // TODO Uncomment when document selectors will be moved to TS
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      areThereAnyRelatedDocuments: documentSelectors.areThereAnyRelatedDocuments(state, documentId),
      // TODO Uncomment when document selectors will be moved to TS
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      areThereAnySummaries: documentSelectors.areThereAnyDocumentSummaries(state, documentId)
    })
  );

  const formattedData: DocumentDetailsTabAvailabilityData['formattedData'] = {
    isLiteTeamUser: authUtils.isLiteTeamUser(),
    isPowerOrTeamUser: authUtils.isPowerOrTeamUser(),
    isPowerOrTeamOrProUser: authUtils.isPowerOrTeamOrProUser()
  };

  return {
    reduxState,
    formattedData
  };
};
