import { createSelector } from '@reduxjs/toolkit';
import { REDUCER_NAME } from './languages.constants';

export const getLanguagesReducer = state => state[REDUCER_NAME];

export const getLanguages = createSelector(getLanguagesReducer, languagesReducer => {
  return languagesReducer?.languages ?? [];
});

export const getLanguagesReadyState = createSelector(getLanguagesReducer, languagesReducer => {
  return languagesReducer.isReady;
});

export const getLanguagesOptions = createSelector(getLanguages, languages => {
  if (Array.isArray(languages)) {
    return languages
      .map(language => {
        return { label: language.name, value: language.id };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  return [];
});

export const getLanguagesIdsToNamesMap = createSelector(getLanguagesOptions, languagesOptions => {
  return languagesOptions.reduce((map, language) => {
    return {
      ...map,
      [language.value]: language.label
    };
  }, {});
});

export const getLanguagesNamesToIdsMap = createSelector(getLanguagesOptions, languagesOptions => {
  return languagesOptions.reduce((map, language) => {
    return {
      ...map,
      [language.label]: language.value
    };
  }, {});
});
