import type { DocumentsTableHandlers } from './useDocumentsTableHandlers.types';

import { useDidUpdate } from '@compliance.ai/web-components';
import { useEffect } from 'react';

export const useDocumentsTableLifecycle = ({
  onRequestParamsChange,
  onTableSettingsInitialLoad,
  onSortStateToUrlReflection,
  onRouteChange
}: {
  onRequestParamsChange: DocumentsTableHandlers['handleRequestParamsChange'];
  onTableSettingsInitialLoad: DocumentsTableHandlers['handleTableSettingsInitialLoad'];
  onSortStateToUrlReflection: DocumentsTableHandlers['handleSortStateToUrlReflection'];
  onRouteChange: DocumentsTableHandlers['handleRouteChange'];
}) => {
  useEffect(() => {
    onTableSettingsInitialLoad();
  }, [onTableSettingsInitialLoad]);
  useDidUpdate(onRequestParamsChange, [onRequestParamsChange]);
  useEffect(onSortStateToUrlReflection, [onSortStateToUrlReflection]);
  useEffect(onRouteChange, [onRouteChange]);
};
