import type { TaskCommentsAnnotationProps } from './TaskCommentsAnnotation.types';

import { LabeledField } from '@compliance.ai/web-components';
import { WORKFLOW_ANNOTATION_TYPES } from 'shared/features/workflow/workflow.constants';
import { Comments } from 'common/index';
import { useDocumentTaskAnnotations } from 'shared/features/workflow/hooks';
import { useTaskCommentsHandlers } from './hooks';

export const TaskCommentsAnnotation = ({
  documentTask,
  commentThreads,
  areRepliesEnabled,
  isThreadCreationEnabled,
  onCommentThreadCreate,
  onCommentCreate,
  onCommentEdit,
  onCommentDelete
}: TaskCommentsAnnotationProps) => {
  const annotation = useDocumentTaskAnnotations(documentTask)[WORKFLOW_ANNOTATION_TYPES.TEXT];

  const handlers = useTaskCommentsHandlers({
    props: {
      documentTask,
      onCommentThreadCreate,
      onCommentCreate,
      onCommentEdit,
      onCommentDelete
    }
  });

  if (!annotation.isAvailable && !annotation.isRequired) {
    return null;
  }

  const annotationCommentThreads = Array.isArray(commentThreads) ? commentThreads : [];

  return (
    <LabeledField label={annotation.label} isContrast>
      <Comments
        commentThreads={annotationCommentThreads}
        onThreadCreation={handlers.handleCommentThreadCreation}
        onCommentDeletion={handlers.handleCommentDeletion}
        onCommentCreation={handlers.handleCommentCreation}
        onCommentEdit={handlers.handleCommentEdit}
        areRepliesEnabled={areRepliesEnabled}
        isThreadCreationEnabled={isThreadCreationEnabled}
      />
    </LabeledField>
  );
};
