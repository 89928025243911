import type { WorkflowDiagramModalHandlers } from './useWorkflowDiagramModalHandlers.types';
import type { WorkflowDiagramModalProps } from '../WorkflowDiagramModalProps.types';
import type { WorkflowDiagramModalLocalActions } from './useWorkflowDiagramModalData.types';

import { useCallback } from 'react';
import { useWorkflowsApi } from 'shared/features/workflow/hooks';
import { logError } from 'utils/errors';

export const useWorkflowDiagramModalHandlers = ({
  props,
  localActions
}: {
  props: Pick<WorkflowDiagramModalProps, 'workflowId'>;
  localActions: WorkflowDiagramModalLocalActions;
}): WorkflowDiagramModalHandlers => {
  const workflowsApi = useWorkflowsApi();

  const handleWorkflowFetch = useCallback(async () => {
    try {
      if (props.workflowId) {
        localActions.setIsLoading(true);

        const workflow = await workflowsApi.fetchWorkflow(props.workflowId);

        localActions.setWorkflow(workflow);
      }
    } catch (e) {
      logError(e as Error);
    } finally {
      localActions.setIsLoading(false);
    }
  }, [localActions, props.workflowId, workflowsApi]);

  return {
    handleWorkflowFetch
  };
};
