import type {FC} from "react";
import type {NoContentProps} from "./NoContent.types";

import pluralize from "pluralize";

import * as uiLib from '@compliance.ai/web-components';

import './_no-content.scss';

export const NoContent: FC<NoContentProps> = ({ type }) => {
  return (
    <uiLib.Typography
      type={uiLib.TYPOGRAPHY_TYPES.BODY1}
      shouldRenderParagraph
      className="workflow-summary-no-content__container"
      alignment={uiLib.TYPOGRAPHY_ALIGNMENT.CENTER}
    >
      No {type ? pluralize(type, 2) : 'data'}
    </uiLib.Typography>
  )
}