import { useMemo, useState } from 'react';

export const useWidgetHeaderOptionsMenuData = () => {
  const [isOptionsDropdownOpen, setOptionsDropdownOpenState] = useState(false);

  const localState = {
    isOptionsDropdownOpen
  };

  const localActions = useMemo(
    () => ({
      setOptionsDropdownOpenState
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
