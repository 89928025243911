import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'utils/query-string';
import { useQueryParams } from 'utils/hooks';
import { SUPPORTED_QUERY_PARAMS } from 'constants/PrimaryFilter';

export const useAdditionalFiltersHandlers = ({ props, localState, localActions }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useQueryParams();

  const handleOpen = () => {
    localActions.setOpenState(true);
  };

  const handleClose = () => {
    localActions.setOpenState(false);
  };

  const handleRowsCountChange = option => {
    localActions.setOpenState(false);
    props.onRowsCountChange(option);
  };

  const handleMinScoreChange = value => {
    localActions.setMinScore(value);
  };

  const handleMinScoreSubmit = () => {
    localActions.setOpenState(false);

    const currentMinScore = queryParams[SUPPORTED_QUERY_PARAMS.OVERRIDE_MIN_SCORE];

    if (localState.minScore !== currentMinScore) {
      navigate(
        `${location.pathname}?${queryString.stringify({
          ...queryParams,
          [SUPPORTED_QUERY_PARAMS.OVERRIDE_MIN_SCORE]: localState.minScore
        })}`
      );
    }
  };

  const handleTopicThresholdChange = ({ value }) => {
    localActions.setOpenState(false);
    localActions.setTopicThreshold(value);

    navigate(
      `${location.pathname}?${queryString.stringify({
        ...queryParams,
        [SUPPORTED_QUERY_PARAMS.TOPIC_THRESHOLD_KEY]: value
      })}`
    );
  };

  return {
    handleOpen,
    handleClose,
    handleRowsCountChange,
    handleMinScoreChange,
    handleMinScoreSubmit,
    handleTopicThresholdChange
  };
};
