import lodash from 'lodash';
import {
  REQUEST_SOURCES,
  RECEIVE_SOURCES,
  REQUEST_POPULAR_SOURCES,
  RECEIVE_POPULAR_SOURCES
} from './sources.actions';
import { FOLLOWING_MAINSTREAM_NEWS } from '../entities/entities.actions';

export const getInitialPopularSourcesState = () => ({
  isFetching: false,
  isReady: false,
  popular_sources: []
});

export const popular_sources = (state = getInitialPopularSourcesState(), action) => {
  switch (action.type) {
    case REQUEST_POPULAR_SOURCES:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_POPULAR_SOURCES:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        popular_sources: action.popular_sources
      };
    default:
      return state;
  }
};

export const getInitialSourcesState = () => ({
  isFetching: false,
  isReady: false,
  sources: {
    defaultMainstreamNewsSources: [],
    defaultTopics: [],
    activeTopics: [],
    defaultMainstreamNewsSourcesObj: {},
    defaultTopicsObj: {},
    activeTopicsObj: {}
  }
});

export const sources = (state = getInitialSourcesState(), action) => {
  switch (action.type) {
    case REQUEST_SOURCES:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_SOURCES: {
      const { activeTopics, defaultMainstreamNewsSources, defaultTopics } = action.sources;

      const activeTopicsObj = activeTopics.reduce((obj, topic) => {
        obj[topic.id] = topic;
        return obj;
      }, {});

      const defaultMainstreamNewsSourcesObj = defaultMainstreamNewsSources.reduce(
        (obj, newsSource) => {
          obj[newsSource.id] = newsSource;
          return obj;
        },
        {}
      );

      const defaultTopicsObj = defaultTopics.reduce((obj, topic) => {
        obj[topic.id] = topic;
        return obj;
      }, {});

      return {
        ...state,
        isFetching: false,
        isReady: true,
        sources: {
          activeTopics,
          activeTopicsObj,
          defaultMainstreamNewsSources,
          defaultMainstreamNewsSourcesObj,
          defaultTopics,
          defaultTopicsObj
        }
      };
    }
    case FOLLOWING_MAINSTREAM_NEWS: {
      const { mainstreamNews } = action;
      const copyDefaultMainstreamNewsSourcesObj = lodash.cloneDeep(
        state.sources.defaultMainstreamNewsSourcesObj
      );
      mainstreamNews.forEach(({ entity_id, following }) => {
        copyDefaultMainstreamNewsSourcesObj[entity_id].following = following;
      });
      return {
        ...state,
        sources: {
          ...state.sources,
          defaultMainstreamNewsSources: Object.values(copyDefaultMainstreamNewsSourcesObj),
          defaultMainstreamNewsSourcesObj: copyDefaultMainstreamNewsSourcesObj
        }
      };
    }
    default:
      return state;
  }
};
