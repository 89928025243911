import {
  CREATE_WORKFLOW_MOLD,
  DELETE_WORKFLOW_MOLD,
  REQUEST_WORKFLOW_MOLDS,
  RECEIVE_WORKFLOW_MOLDS,
  UPDATE_WORKFLOW
} from './workflowMold.actions';

export const WFM_INITIAL_STATE = {
  isFetching: false,
  isReady: false,
  items: []
};

export const workflowMolds = (state = WFM_INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_WORKFLOW_MOLDS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case UPDATE_WORKFLOW:
      return {
        ...state,
        items: state.items.map(template => {
          if (template.id === action.payload.id) {
            template = action.payload;
          }
          return template;
        })
      };
    case RECEIVE_WORKFLOW_MOLDS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.payload
      };
    case CREATE_WORKFLOW_MOLD:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: [...state.items, action.payload]
      };
    case DELETE_WORKFLOW_MOLD:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.payload
      };
    default:
      return state;
  }
};
