import * as uiLib from '@compliance.ai/web-components';

export const getCheckboxType = ({
  isCheckedOldDoc,
  isCheckedNewDoc
}: {
  isCheckedOldDoc: boolean;
  isCheckedNewDoc: boolean;
}): uiLib.CHECKBOX_TYPE => {
  switch (true) {
    case isCheckedOldDoc: {
      return uiLib.CHECKBOX_TYPE.ERROR;
    }

    case isCheckedNewDoc: {
      return uiLib.CHECKBOX_TYPE.SUCCESS;
    }

    default: {
      return uiLib.CHECKBOX_TYPE.CONTRAST;
    }
  }
};
