import React from 'react';
import PropTypes from 'prop-types';
import { WorkflowMoldOptionsDropdown } from './elements';
import { Tooltip, TOOLTIP_TYPES } from '@compliance.ai/web-components';
import './_workflow-mold-header.scss';

const WorkflowMoldHeader = ({
  editNameMode,
  id,
  name,
  saveTemplateName,
  setEditNameMode,
  setName,
  setShowDeleteModalStatus,
  share_scope
}) => {
  return (
    <div className="workflow-mold-header">
      {editNameMode ? (
        <input
          className="workflow-mold-header__input"
          value={name}
          onChange={e => setName(e.target.value)}
        />
      ) : (
        <Tooltip
          type={TOOLTIP_TYPES.SECONDARY}
          title={name}
          wrapperClassName="workflow-mold-header__tooltip-wrapper"
        >
          {name}
        </Tooltip>
      )}
      <WorkflowMoldOptionsDropdown
        id={id}
        share_scope={share_scope}
        editNameMode={editNameMode}
        setShowDeleteModalStatus={setShowDeleteModalStatus}
        setEditNameMode={setEditNameMode}
        saveTemplateName={saveTemplateName}
      />
    </div>
  );
};

WorkflowMoldHeader.propTypes = {
  editNameMode: PropTypes.bool.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string,
  saveTemplateName: PropTypes.func.isRequired,
  setEditNameMode: PropTypes.func.isRequired,
  setName: PropTypes.func.isRequired,
  setShowDeleteModalStatus: PropTypes.func.isRequired,
  share_scope: PropTypes.string
};

WorkflowMoldHeader.defaultProps = {
  name: '',
  share_scope: ''
};

export default React.memo(WorkflowMoldHeader);
