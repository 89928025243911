import type { FC } from 'react';
import type { CommentsCellProps } from './CommentsCell.types';

import { Comments } from '../../../../../../../../Comments';

import * as emptyValue from 'constants/EmptyValue';

import './_comments-cell.scss';

export const CommentsCell: FC<CommentsCellProps> = ({ cellValue }) => {
  if (!cellValue?.length) {
    return <>{emptyValue.EMPTY_VALUE.DASH}</>;
  }

  return (
    <div className="workflow-summary-comments-cell__container">
      <Comments
        onThreadCreation={() => {}}
        onCommentDeletion={() => {}}
        onCommentEdit={() => {}}
        commentThreads={cellValue}
        onCommentCreation={() => {}}
        areRepliesEnabled={false}
        areCommentsEditableByAuthor={false}
        isThreadCreationEnabled={false}
      />
    </div>
  );
};
