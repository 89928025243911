import type { WorkflowSummaryProps } from '../WorkflowSummary.types';
import type { GlobalState } from 'shared/reducers';
import type { WorkflowSummaryData } from './useWorkflowSummaryData.types';

import * as documentSelectors from 'shared/features/documents/documents.selectors';
import * as helpers from './useWorkflowSummaryData.helpers';
import * as sentenceNotesConstants from 'shared/features/sentenceNotes/sentenceNotes.constants';

import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as constants from '../WorkflowSummary.constants';

export const useWorkflowSummaryData = ({
  documentTasks,
  documentId
}: Pick<WorkflowSummaryProps, 'documentTasks' | 'documentId'>): WorkflowSummaryData => {
  const componentToPrintRef = useRef(null);

  const refs: WorkflowSummaryData['refs'] = {
    componentToPrint: componentToPrintRef
  };

  const [isReadyToBePrinted, setReadyToBePrintedState] = useState(false);
  const [isPreparingForPrint, setIsPreparingForPrint] = useState(false);
  const [isSentenceNotesFetchCompleted, setIsSentenceNotesFetchCompleted] = useState(
    Object.fromEntries(
      Object.values(sentenceNotesConstants.SENTENCE_NOTE_TYPE).map(type => [type, false])
    ) as WorkflowSummaryData['localState']['isSentenceNotesFetchCompleted']
  );
  const [isSectionExpanded, setIsSectionExpanded] = useState(
    Object.fromEntries(
      Object.values(constants.SECTION_TYPE).map(section => [section, false])
    ) as WorkflowSummaryData['localState']['isSectionExpanded']
  );
  const [isSectionExpandAnimationCompleted, setIsSectionExpandAnimationCompleted] = useState(
    Object.fromEntries(
      Object.values(constants.SECTION_TYPE).map(section => [section, false])
    ) as WorkflowSummaryData['localState']['isSectionExpandAnimationCompleted']
  );

  const localState: WorkflowSummaryData['localState'] = {
    isReadyToBePrinted,
    isPreparingForPrint,
    isSentenceNotesFetchCompleted,
    isSectionExpanded,
    isSectionExpandAnimationCompleted
  };

  const localActions: WorkflowSummaryData['localActions'] = useMemo(
    () => ({
      setReadyToBePrintedState,
      setIsPreparingForPrint,
      setIsSentenceNotesFetchCompleted,
      setIsSectionExpanded,
      setIsSectionExpandAnimationCompleted
    }),
    []
  );

  const reduxState = useSelector<GlobalState, WorkflowSummaryData['reduxState']>(state => {
    return {
      doesDocumentHasSentences: documentSelectors.doesDocumentHasSentences(documentId)(state)
    };
  });

  const formattedData: WorkflowSummaryData['formattedData'] = useMemo(
    () => ({
      documentTitle: helpers.getDocumentTitle(documentTasks[0])
    }),
    [documentTasks]
  );

  return {
    refs,
    localState,
    localActions,
    reduxState,
    formattedData
  };
};
