import type { ConceptsSelectProps } from './ConceptsSelect.types';
import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';

import { useConceptsSelectData, useConceptsSelectHandlers } from './hooks';

export const ConceptsSelect: FC<ConceptsSelectProps> = props => {
  const { reduxState, formattedData } = useConceptsSelectData({
    value: props.value,
    extraOptions: props.extraOptions
  });

  const handlers = useConceptsSelectHandlers({
    props,
    reduxState
  });

  return (
    <uiLib.GroupSelect
      {...props}
      onChange={handlers.handleChange}
      options={formattedData.options}
      value={formattedData.value}
    />
  );
};
