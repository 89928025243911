/**
 *
 * Tries to find dependent task
 *
 */
export const findDependency = ({ task, workflowTask }) => {
  return task.dependencies.find(dependencyTasks => {
    return dependencyTasks.dependent_task_id === workflowTask.id;
  });
};

/**
 *
 * Returns required dependencies values
 *
 */
export const getRequiredSelectionsValues = ({ dependency, workflowTask }) => {
  if (!Array.isArray(workflowTask.dropdowns) || !workflowTask.dropdowns?.length) {
    return [];
  }

  // It checks the 1st dropdown for dependency check for now
  return workflowTask.dropdowns[0].dropdown_options.reduce(
    (requiredSelectionsValues, { id, dropdown_text }) => {
      if (dependency.dependency_dropdown_selections.includes(id)) {
        return [...requiredSelectionsValues, dropdown_text];
      }
      return requiredSelectionsValues;
    },
    []
  );
};

/**
 *
 * Returns a list of task's dependent tasks
 *
 */
export const formatDependentTasks = task => (dependentTasks, workflowTask) => {
  const dependency = findDependency({ task, workflowTask });

  if (!dependency) {
    return dependentTasks;
  }

  const requiredSelectionsValues = getRequiredSelectionsValues({
    dependency,
    workflowTask
  });

  const dependentTask = {
    id: workflowTask.id,
    name: workflowTask.name,
    requiredSelectionsValues: requiredSelectionsValues
  };

  return [...dependentTasks, dependentTask];
};

/**
 *
 * Returns a task assignee
 *
 */
export const formatTaskAssignee = ({ task, workflowTasks, orgMembers }) => {
  const { assignee, is_dependent_on_assignee, dependent_on_assignee_task_id } = task;

  if (!is_dependent_on_assignee && assignee) {
    return orgMembers.find(({ id }) => id === assignee)?.email ?? 'Unknown';
  }

  if (is_dependent_on_assignee && dependent_on_assignee_task_id) {
    const dependentOnTask = workflowTasks.find(task => task.id === dependent_on_assignee_task_id);

    if (dependentOnTask) {
      return `Dependent on ${dependentOnTask.name}`;
    }
  }

  return 'n/a';
};
