import type { FieldProps } from './Field.types';

import { LabeledField } from '@compliance.ai/web-components';
import { ClickAwayListener } from '@material-ui/core';
import classnames from 'classnames';
import { useFieldData } from './hooks';
import './_Field.scss';

export const Field = ({ label, value, isEditable, editableValue, classNames }: FieldProps) => {
  const { localState, localActions } = useFieldData();

  return (
    <ClickAwayListener onClickAway={() => localActions.setIsEditable(false)}>
      <div
        onClick={() => isEditable && localActions.setIsEditable(true)}
        className={classnames('document-task-item-field', classNames?.container, {
          'document-task-item-field--is-editable': localState.isEditable,
          'document-task-item-field--can-be-editable': isEditable
        })}
      >
        <LabeledField label={label} isContrast>
          <div className={classnames('document-task-item-field__value', classNames?.value)}>
            {localState.isEditable ? editableValue : value}
          </div>
        </LabeledField>
      </div>
    </ClickAwayListener>
  );
};
