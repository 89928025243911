import * as documentPropertiesConstants from 'constants/DocumentProperties';
import * as constants from '../ExportModal.constants';
import { useMemo, useCallback } from 'react';

export const useExportModalHandlers = ({ props, localState, localActions, formattedData }) => {
  const handleSelectAll = useCallback(() => {
    let selectedColumns = formattedData.basicColumns;

    if (localState.exportMode === documentPropertiesConstants.SENTENCES_KEY) {
      selectedColumns = [...formattedData.basicColumns, ...constants.SENTENCES_COLUMNS];
    } else if (localState.exportMode === documentPropertiesConstants.ANNOTATIONS_KEY) {
      selectedColumns = [...formattedData.basicColumns, ...constants.ANNOTATIONS_COLUMNS];
    }

    localActions.setSelectedColumns(selectedColumns);
  }, [formattedData.basicColumns, localState.exportMode, localActions]);

  const handleClearAll = useCallback(() => {
    localActions.setSelectedColumns([]);
  }, [localActions]);

  const handleColumnSelectChange = useCallback(
    column => isSelected => {
      let selectedColumns = localState.selectedColumns.filter(col => col !== column);

      if (isSelected) {
        selectedColumns = [...selectedColumns, column];
      }

      localActions.setSelectedColumns(selectedColumns);
    },
    [localState, localActions]
  );

  const handleExportModeChange = useCallback(
    exportMode => isChecked => {
      if (isChecked) {
        let selectedColumns = [...localState.selectedColumns].filter(
          column =>
            !Boolean(
              [
                ...constants.SENTENCES_COLUMNS,
                ...constants.ANNOTATIONS_COLUMNS,
                documentPropertiesConstants.ANNOTATION_SINGLE_FILE
              ].includes(column)
            )
        );

        if (exportMode === documentPropertiesConstants.SENTENCES_KEY) {
          selectedColumns = [...selectedColumns, ...constants.SENTENCES_COLUMNS];
        } else if (exportMode === documentPropertiesConstants.ANNOTATIONS_KEY) {
          selectedColumns = [...selectedColumns, ...constants.ANNOTATIONS_COLUMNS];
        }

        localActions.setSelectedColumns(selectedColumns);
        localActions.setExportMode(exportMode);
      }
    },
    [localState, localActions]
  );

  const handleExport = useCallback(() => {
    if (!formattedData.isExportDisabled) {
      const { contentType, onExport, onClose } = props;

      let apiColumns = formattedData.apiColumns;
      if (localState.exportMode) {
        apiColumns = [...apiColumns, documentPropertiesConstants.API_KEYS[localState.exportMode]];
      }
      onExport([contentType, apiColumns]);
      onClose();
    }
  }, [props, localState, formattedData]);

  return useMemo(
    () => ({
      handleSelectAll,
      handleClearAll,
      handleColumnSelectChange,
      handleExportModeChange,
      handleExport
    }),
    [
      handleSelectAll,
      handleColumnSelectChange,
      handleClearAll,
      handleExportModeChange,
      handleExport
    ]
  );
};
