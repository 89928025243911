import { api_fetchGroupedDocTasksCountByCreationDate } from '../../docTasks/docTasks.api';
import { TO_DO, PAST_DUE, DONE } from 'constants/DocTasks';

export const REQUEST_TASK_VELOCITY_DATA = 'REQUEST_TASK_VELOCITY_DATA';
export const RECEIVE_TASK_VELOCITY_DATA = 'RECEIVE_TASK_VELOCITY_DATA';
export const REJECT_TASK_VELOCITY_DATA = 'REJECT_TASK_VELOCITY_DATA';

const requestTaskVelocityData = widgetId => ({
  type: REQUEST_TASK_VELOCITY_DATA,
  meta: {
    widgetId
  }
});

const receiveTaskVelocityData = (widgetId, payload) => ({
  type: RECEIVE_TASK_VELOCITY_DATA,
  meta: {
    widgetId
  },
  payload
});

const rejectTaskVelocityData = widgetId => ({
  type: REJECT_TASK_VELOCITY_DATA,
  meta: {
    widgetId
  }
});

export const fetchTaskVelocityData = (
  widgetId,
  { intervalStartDate, intervalEndDate, shouldHideBlockedTasks = true, assigneeId, workflowId }
) => async dispatch => {
  try {
    dispatch(requestTaskVelocityData(widgetId));

    const params = {
      hide_blocked_tasks: shouldHideBlockedTasks,
      from_date: intervalEndDate,
      to_date: intervalStartDate,
      obligation_super_tasks_only: false,
      assignee_id: assigneeId,
      workflow_id: workflowId
    };

    const todoTasksRequest = api_fetchGroupedDocTasksCountByCreationDate({
      ...params,
      status: TO_DO
    });
    const pastDueTasksRequest = api_fetchGroupedDocTasksCountByCreationDate({
      ...params,
      status: PAST_DUE
    });
    const doneTasksRequest = api_fetchGroupedDocTasksCountByCreationDate({
      ...params,
      status: DONE
    });

    const [todoTasksResponse, pastDueTasksResponse, doneTasksResponse] = await Promise.all([
      todoTasksRequest,
      pastDueTasksRequest,
      doneTasksRequest
    ]);

    dispatch(
      receiveTaskVelocityData(widgetId, {
        todoTasksResponse,
        pastDueTasksResponse,
        doneTasksResponse
      })
    );
  } catch {
    dispatch(rejectTaskVelocityData(widgetId));
  }
};
