import type { CategoriesSelectProps } from './CategoriesSelect.types';
import type { GroupSelectProps } from '@compliance.ai/web-components';

import { GroupSelect } from '@compliance.ai/web-components';
import { useCategoriesSelectData } from './hooks';

export const CategoriesSelect = (props: CategoriesSelectProps) => {
  const { reduxState, formattedData } = useCategoriesSelectData(props);

  return (
    <GroupSelect
      {...props}
      onChange={props.onChange as GroupSelectProps['onChange']}
      options={reduxState.options}
      value={formattedData.value}
    />
  );
};
