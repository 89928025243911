import { api_getDocumentsPost } from '../documents/documents.api';

export const CHANGE_DOCUMENT_VIEW = 'CHANGE_DOCUMENT_VIEW';
export const SET_FIRST_TIMELINE_VIEW = 'SET_FIRST_TIMELINE_VIEW';
export const CHANGE_SEARCH_PARAMS = 'CHANGE_SEARCH_PARAMS';
export const CHANGE_SELECTED_ITEM = 'CHANGE_SELECTED_ITEM';
export const CHANGE_BULK_SELECTED_ITEM = 'CHANGE_BULK_SELECTED_ITEM';
export const SET_DOCS_TO_SELECT = 'SET_DOCS_TO_SELECT';
export const SET_MOBILE = 'SET_MOBILE';
export const CLEAR_SELECTED_ITEMS = 'CLEAR_SELECTED_ITEMS';
export const CLEAR_BULK_SELECTED_ITEMS = 'CLEAR_BULK_SELECTED_ITEMS';
export const INITIATE_PENDING_ACTION = 'INITIATE_PENDING_ACTION';
export const COMPLETE_PENDING_ACTION = 'COMPLETE_PENDING_ACTION';
export const OPEN_OVERLAY = 'OPEN_OVERLAY';
export const CLOSE_OVERLAY = 'CLOSE_OVERLAY';
export const CHANGE_EXPAND_STATUS = 'CHANGE_EXPAND_STATUS';
export const SAVE_UNBOOKMARKED_DOCUMENT = 'SAVE_UNBOOKMARKED_DOCUMENT';
export const UPDATE_FOLDER_MODAL_STATUS = 'UPDATE_FOLDER_MODAL_STATUS';
export const OPEN_SOURCE_SELECTION = 'OPEN_SOURCE_SELECTION';
export const CLOSE_SOURCE_SELECTION = 'CLOSE_SOURCE_SELECTION';
export const OPEN_WARNING_MODAL = 'OPEN_WARNING_MODAL';
export const CLOSE_WARNING_MODAL = 'CLOSE_WARNING_MODAL';
export const CLEAR_DOC_REF = 'CLEAR_DOC_REF';
export const DISPLAY_FILTERS = 'DISPLAY_FILTERS';
export const HIDE_FILTERS = 'HIDE_FILTERS';
export const TOGGLE_FILTERS = 'TOGGLE_FILTERS';
export const HIGHLIGHT_SEARCH = 'HIGHLIGHT_SEARCH';
export const ADD_BANNER = 'ADD_BANNER';
export const SHOW_RESTRICT_MODAL = 'SHOW_RESTRICT_MODAL';
export const HIDE_RESTRICT_MODAL = 'HIDE_RESTRICT_MODAL';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const SET_TASK_VIEW = 'SET_TASK_VIEW';
export const SET_ACTIVE_TASK = 'SET_ACTIVE_TASK';
export const ADD_NAVIGATE_ON_CLOSE_DOCUMENT = 'ADD_NAVIGATE_ON_CLOSE_DOCUMENT';
export const REMOVE_NAVIGATE_ON_CLOSE_DOCUMENT = 'REMOVE_NAVIGATE_ON_CLOSE_DOCUMENT';
export const ADD_EDIT_ALERT = 'ADD_EDIT_ALERT';
export const REMOVE_EDIT_ALERT = 'REMOVE_EDIT_ALERT';
export const ADD_VIEW_ALERT = 'ADD_VIEW_ALERT';
export const REMOVE_VIEW_ALERT = 'REMOVE_VIEW_ALERT';
export const SHOW_PREVIEW_ALERT = 'SHOW_PREVIEW_ALERT';
export const HIDE_PREVIEW_ALERT = 'HIDE_PREVIEW_ALERT';
export const OPEN_FILTER = 'OPEN_FILTER';
export const CLOSE_FILTER = 'CLOSE_FILTER';
export const TOGGLE_FILTER = 'TOGGLE_FILTER';
export const OPEN_ADVANCED_SEARCH_FILTER = 'OPEN_ADVANCED_SEARCH_FILTER';
export const CLOSE_ADVANCED_SEARCH_FILTER = 'CLOSE_ADVANCED_SEARCH_FILTER';
export const TOGGLE_ADVANCED_SEARCH_FILTER = 'TOGGLE_ADVANCED_SEARCH_FILTER';
export const SHOW_SAVE_ALERT_SUCCESS_MODAL = 'SHOW_SAVE_ALERT_SUCCESS_MODAL';
export const HIDE_SAVE_ALERT_SUCCESS_MODAL = 'HIDE_SAVE_ALERT_SUCCESS_MODAL';
export const SET_SELECTED_ITEMS = 'SET_SELECTED_ITEMS';
export const SET_SIMPLE_FETCH_DOCUMENTS_STATUS = 'SET_SIMPLE_FETCH_DOCUMENTS_STATUS';

//update the UI on the status of the various modal menus used in folder-view
//used to make sure events occur only if folder modal menu is open/closed
export function updateFolderModalStatus(status) {
  return {
    type: UPDATE_FOLDER_MODAL_STATUS,
    status
  };
}

export function changeDocumentView(page, id) {
  return {
    type: CHANGE_DOCUMENT_VIEW,
    page,
    id
  };
}

export function openWarningModal(modal) {
  return {
    type: OPEN_WARNING_MODAL,
    modal
  };
}

export function closeWarningModal() {
  return {
    type: CLOSE_WARNING_MODAL
  };
}

export function changeSearchParams(params) {
  return {
    type: CHANGE_SEARCH_PARAMS,
    params
  };
}

export function changeExpandStatus(dir, refs) {
  return {
    type: CHANGE_EXPAND_STATUS,
    dir,
    ...refs
  };
}

export function changeSelectedItem(doc, value) {
  return {
    type: CHANGE_SELECTED_ITEM,
    doc,
    value
  };
}

export function changeBulkSelectedItem(value, bulk) {
  return {
    type: CHANGE_BULK_SELECTED_ITEM,
    value,
    bulk
  };
}

export function setSelectedItems(docs) {
  return {
    type: SET_SELECTED_ITEMS,
    docs
  };
}

export function clearSelectedItems() {
  return {
    type: CLEAR_SELECTED_ITEMS
  };
}

export function clearBulkSelectedItems() {
  return {
    type: CLEAR_BULK_SELECTED_ITEMS
  };
}

export function initiatePendingAction(data) {
  return {
    type: INITIATE_PENDING_ACTION,
    data
  };
}

export function completePendingAction() {
  return {
    type: COMPLETE_PENDING_ACTION
  };
}

export function setFirstTimelineView(value) {
  return {
    type: SET_FIRST_TIMELINE_VIEW,
    value
  };
}

export function setDocsToSelect(docs) {
  return {
    type: SET_DOCS_TO_SELECT,
    docs_to_select: docs
  };
}

export function saveUnBookmarkedDocument(id) {
  return {
    type: SAVE_UNBOOKMARKED_DOCUMENT,
    id
  };
}

export function showFilters() {
  return { type: DISPLAY_FILTERS };
}

export function hideFilters() {
  return { type: HIDE_FILTERS };
}

export function toggleFilters() {
  return { type: TOGGLE_FILTERS };
}

export function setMobile(bool) {
  return {
    type: SET_MOBILE,
    bool
  };
}

export function highlightSearch() {
  return {
    type: HIGHLIGHT_SEARCH
  };
}

export function addBanner(banner_type, banner_status, content = {}, suppressCloseButton = false) {
  return {
    type: ADD_BANNER,
    banner_type,
    banner_status,
    content,
    suppressCloseButton
  };
}

export function hideRestrictModal() {
  return {
    type: HIDE_RESTRICT_MODAL
  };
}

export function showRestrictModal(type, title, text) {
  return {
    type: SHOW_RESTRICT_MODAL,
    modal_type: type,
    title,
    text
  };
}

export function setSearchTerm(search_term) {
  return {
    type: SET_SEARCH_TERM,
    search_term
  };
}

export function setTaskView(workflowView, taskDetails) {
  return {
    type: SET_TASK_VIEW,
    workflowView,
    taskDetails
  };
}

export function setActiveTask(docTaskId, workflowId, userId) {
  return {
    type: SET_ACTIVE_TASK,
    docTaskId,
    workflowId,
    userId
  };
}

export const resetTaskView = () => dispatch => {
  dispatch(setTaskView(null, null));
  dispatch(setActiveTask(null, null, null));
};

export function addNavigateOnCloseDocument(routerParams) {
  return {
    type: ADD_NAVIGATE_ON_CLOSE_DOCUMENT,
    routerParams
  };
}

export function removeNavigateOnCloseDocument() {
  return {
    type: REMOVE_NAVIGATE_ON_CLOSE_DOCUMENT
  };
}

export function addEditAlert(alert_id, alert_name) {
  return {
    type: ADD_EDIT_ALERT,
    alert_id,
    alert_name
  };
}

export function removeEditAlert() {
  return {
    type: REMOVE_EDIT_ALERT
  };
}

export function addViewAlert(alert_id, alert_name) {
  return {
    type: ADD_VIEW_ALERT,
    alert_id,
    alert_name
  };
}

export function removeViewAlert() {
  return {
    type: REMOVE_VIEW_ALERT
  };
}

export function showPreviewAlert() {
  return {
    type: SHOW_PREVIEW_ALERT
  };
}

export function hidePreviewAlert() {
  return {
    type: HIDE_PREVIEW_ALERT
  };
}

export function openFilter() {
  return {
    type: OPEN_FILTER
  };
}

export function closeFilter() {
  return {
    type: CLOSE_FILTER
  };
}

export function toggleFilter() {
  return {
    type: TOGGLE_FILTER
  };
}

export function openAdvancedSearchFilter() {
  return {
    type: OPEN_ADVANCED_SEARCH_FILTER
  };
}

export function closeAdvancedSearchFilter() {
  return {
    type: CLOSE_ADVANCED_SEARCH_FILTER
  };
}

export function toggleAdvancedSearchFilter() {
  return {
    type: TOGGLE_ADVANCED_SEARCH_FILTER
  };
}

export function showSaveAlertSuccessModal(modal_type, alert_name) {
  return {
    type: SHOW_SAVE_ALERT_SUCCESS_MODAL,
    modal_type,
    alert_name
  };
}

export function hideSaveAlertSuccessModal() {
  return {
    type: HIDE_SAVE_ALERT_SUCCESS_MODAL
  };
}

function setSimpleFetchDocumentsStatus(status) {
  return {
    type: SET_SIMPLE_FETCH_DOCUMENTS_STATUS,
    status
  };
}

export function fetchDocumentsToSelectedDocs(params = {}, rnToken = null) {
  return function doFetch(dispatch) {
    dispatch(setSimpleFetchDocumentsStatus(true));
    return api_getDocumentsPost(params, rnToken).then(response => {
      dispatch(setSelectedItems(response.documents));
      dispatch(setSimpleFetchDocumentsStatus(false));
      return response;
    });
  };
}
