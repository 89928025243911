import type { NormalizedBucket } from './buckets.actions.types';
import type { LabelFromResponse } from '../labels/labels.api.types';

import * as uiLib from '@compliance.ai/web-components';
import * as analyticsUtils from 'utils/analytics';
import * as constants from './buckets.constants';

export const getBucketId = (bucket: NormalizedBucket | LabelFromResponse['bucket']) =>
  bucket?.id ?? null;

export const getBucketName = (bucket: NormalizedBucket | LabelFromResponse['bucket']) =>
  bucket?.name ?? constants.DEFAULT_BUCKET_NAME;

export const formatBucketOption = (bucket: NormalizedBucket | LabelFromResponse['bucket']) => ({
  label: getBucketName(bucket),
  value: getBucketId(bucket)
});

export const sendBucketsAnalytics = ({
  action,
  eventLabel
}: {
  action: string;
  eventLabel?: string;
}) => {
  analyticsUtils.sendAnalytics({
    category: 'Management Hub',
    action: `Folders ${action}`,
    label: eventLabel || 'Folders'
  });
};

export const sendBucketLabelsAnalytics = ({
  action,
  eventLabel
}: {
  action: string;
  eventLabel?: string;
}) => {
  analyticsUtils.sendAnalytics({
    category: 'Management Hub',
    action: `Labels ${action}`,
    label: eventLabel || 'Labels'
  });
};

export const sendBucketWorkflowAnalytics = ({ action }: { action: string }) => {
  analyticsUtils.sendAnalytics({
    category: 'Management Hub',
    action: `Workflows ${action}`,
    label: 'Workflows'
  });
};

export const sendBucketSentenceNotesAnalytics = ({
  tabName,
  action,
  eventLabel
}: {
  tabName: string;
  action: string;
  eventLabel?: string;
}) => {
  const capitalizedName = uiLib.capitalize(tabName);
  analyticsUtils.sendAnalytics({
    category: 'Management Hub',
    action: `${capitalizedName} ${action}`,
    label: eventLabel || capitalizedName
  });
};

export const sendBucketDocumentsAnalytics = ({ action }: { action: string }) => {
  analyticsUtils.sendAnalytics({
    category: 'Management Hub',
    action: `Documents ${action}`,
    label: 'Documents'
  });
};
