import type { DocumentsSortProps } from './DocumentsSort.types';
import type * as react from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './DocumentsSort.constants';
import * as selectUtils from 'utils/select-utils';

import { useDocumentsHandlers } from './hooks';

import './_documents-sort.scss';

export const DocumentsSort: react.FC<DocumentsSortProps> = ({
  sort,
  sortOptions,
  onSortChange
}) => {
  const handlers = useDocumentsHandlers({
    props: {
      sort,
      onSortChange
    }
  });

  return (
    <uiLib.Dropdown
      triggerContent={
        <uiLib.ActionButton>
          <uiLib.IconSort />
        </uiLib.ActionButton>
      }
      dropdownContent={
        <>
          <uiLib.LabeledField label="Sort by">
            <uiLib.Select
              value={selectUtils.getValuesFromOptions({
                value: sort.sortType,
                options: sortOptions ?? []
              })}
              options={sortOptions}
              onChange={handlers.handleSortTypeChange}
              isClearable={false}
            />
          </uiLib.LabeledField>
          <uiLib.LabeledField label="Sort order">
            <uiLib.Select
              value={selectUtils.getValuesFromOptions({
                value: sort.sortOrder,
                options: constants.SORT_ORDER_OPTIONS
              })}
              options={constants.SORT_ORDER_OPTIONS}
              onChange={handlers.handleSortOrderChange}
              isClearable={false}
            />
          </uiLib.LabeledField>
        </>
      }
      className="documents-sort__content"
    />
  );
};
