import lodash from 'lodash';
import { IconLinkArrow, IconLabelImportant, IconLock } from '@compliance.ai/web-components';
import { userHasDocumentAccess } from 'utils/documents/documentsAccess';
import * as alertUtils from 'utils/alert-utils';
import { RELATED_DOCUMENT_KEYS, RELATED_DOCUMENT_RELATION_TYPES } from 'constants/RelatedDocuments';

export const formatPremiumDocumentOptions = relatedDocs => {
  if (relatedDocs) {
    const relatedDocsGroupByProvider = lodash.groupBy(
      relatedDocs[RELATED_DOCUMENT_KEYS.RELATED_DOCS],
      relatedDocs[RELATED_DOCUMENT_KEYS.DOCUMENTS_GROUP_BY]
    );

    return lodash.sortBy(Object.keys(relatedDocsGroupByProvider)).reduce((options, provider) => {
      const title = (
        <div className="premium--header__container">
          <IconLabelImportant className="premium--header__icon" /> {provider}
        </div>
      );

      const optionsByProvider = relatedDocsGroupByProvider[provider].map((document, index) => {
        const isLocked = !userHasDocumentAccess({ document });
        const label = isLocked ? (
          <div className="premium--content__container">
            <IconLock className="premium--content__icon" /> {document.title}
          </div>
        ) : (
          document.title
        );

        return {
          title: index === 0 ? title : undefined,
          icon: <IconLinkArrow />,
          label,
          value: document.doc_id
        };
      });

      return [...options, ...optionsByProvider];
    }, []);
  }

  return [];
};

export const formatRelatedDoumentOptions = relatedDocs => {
  if (relatedDocs) {
    return relatedDocs[RELATED_DOCUMENT_KEYS.RELATED_DOCS].map(doc => ({
      icon: <IconLinkArrow />,
      label: doc.title,
      value: doc.doc_id
    }));
  }

  return [];
};

export const formatOfficialLaguagesDoumentOptions = relatedDocs => {
  if (relatedDocs) {
    // TODO:: `"lannguages" || ` will be removed once data is changed
    const documentsGroupByKey =
      'languages' || relatedDocs[RELATED_DOCUMENT_KEYS.DOCUMENTS_GROUP_BY];
    const relatedDocumentsGroups = relatedDocs[RELATED_DOCUMENT_KEYS.RELATED_DOCS].reduce(
      (groups, relatedDoc) => {
        const group = relatedDoc[documentsGroupByKey];

        if (group) {
          const doc = { id: relatedDoc.doc_id, title: relatedDoc.title };

          if (Array.isArray(group)) {
            for (const language of group) {
              if (language.name) {
                if (language.name in groups) {
                  groups[language.name] = [...groups[language.name], doc];
                } else {
                  groups[language.name] = [doc];
                }
              }
            }
          } else {
            if (group in groups) {
              groups[group] = [...groups[group], doc];
            } else {
              groups[group] = [doc];
            }
          }
        }

        return groups;
      },
      {}
    );

    return lodash.sortBy(Object.keys(relatedDocumentsGroups)).reduce((options, language) => {
      const optionsByLanguage = lodash
        .sortBy(relatedDocumentsGroups[language], 'title')
        .map((document, index) => {
          return {
            title: index === 0 ? language : undefined,
            icon: <IconLinkArrow />,
            label: document.title,
            value: document.id
          };
        });

      return [...options, ...optionsByLanguage];
    }, []);
  }

  return [];
};

export const formatAutoTranslatedDocumentOptions = relatedDocs => {
  if (relatedDocs) {
    return lodash
      .sortBy(
        relatedDocs[RELATED_DOCUMENT_KEYS.RELATED_DOCS],
        `${[RELATED_DOCUMENT_KEYS.DOCUMENTS_GROUP_BY]}.0.name`
      )
      .map(doc => ({
        title: doc[relatedDocs[RELATED_DOCUMENT_KEYS.DOCUMENTS_GROUP_BY]][0]['name'],
        icon: <IconLinkArrow />,
        label: doc.title,
        value: doc.doc_id
      }));
  }

  return [];
};

export const formatOrganizationRelatedDocumentOptions = relatedDocs => {
  if (relatedDocs) {
    return relatedDocs[RELATED_DOCUMENT_KEYS.RELATED_DOCS].map(doc => ({
      icon: <IconLinkArrow />,
      label: doc.doc_title,
      value: doc.doc_id
    }));
  }

  return [];
};

export const formatOrganizationRelatedDoumentAlertOptions = relatedDocAlerts => {
  if (relatedDocAlerts) {
    return relatedDocAlerts[RELATED_DOCUMENT_KEYS.RELATED_DOC_ALERTS].map(alert => {
      const alertPath = alertUtils.getSavedSearchPath(alert.search_args);
      const alertQuery = alertUtils.getAlertURLParams(alert.id, alert.search_args);

      return {
        icon: <IconLinkArrow />,
        label: `${alert.name} (${alert.docs_count})`,
        value: alert.id,
        url: alertUtils.formatAlertUrl(alertPath, alertQuery)
      };
    });
  }

  return [];
};
