import { createSelector } from '@reduxjs/toolkit';
import { SUPPORTED_QUERY_PARAMS } from 'constants/PrimaryFilter';
import { getCurrentUserProperties } from 'shared/features/user/user.selectors';
import { isShowingAlert } from 'shared/features/view/view.selectors';

export const getThresholdsReducer = state => state.thresholds;

export const getThresholdTopics = createSelector(getThresholdsReducer, thresholdsReducer => {
  return thresholdsReducer?.topics;
});
export const getTopicThresholdOptions = createSelector(getThresholdTopics, topics => {
  return topics?.topicThresholdOptions || [];
});
export const getTopicThresholdSelectOptions = createSelector(
  getTopicThresholdOptions,
  topicThresholdOptions => {
    return topicThresholdOptions.map(option => ({
      label: option,
      value: option
    }));
  }
);
export const getTopicThresholdDefaultKey = createSelector(getThresholdTopics, topics => {
  return topics?.defaultKey;
});

export const getInitialTopicThresholdKey = (queryParams = {}) =>
  createSelector(
    isShowingAlert,
    getCurrentUserProperties,
    getTopicThresholdDefaultKey,
    (isShowingAlert, currentUserProperties, topicThresholdDefaultKey) => {
      const topicThresholdFromQueryParams = queryParams[SUPPORTED_QUERY_PARAMS.TOPIC_THRESHOLD_KEY];
      const topicThresholdFromUserProps = currentUserProperties.topicThreshold;

      if (isShowingAlert) {
        return topicThresholdFromQueryParams || topicThresholdDefaultKey;
      }

      return (
        topicThresholdFromQueryParams || topicThresholdFromUserProps || topicThresholdDefaultKey
      );
    }
  );
