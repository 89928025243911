import type { CFRsData } from './useCFRsData.types';
import type { CFRsProps } from '../CFRs.types';

import lodash from 'lodash';
import { useMemo } from 'react';

export const useCFRsData = ({ document }: Pick<CFRsProps, 'document'>): CFRsData => {
  const formattedData: CFRsData['formattedData'] = useMemo(() => {
    const CFRs = lodash.map(document.cfr_parts || [], 'cite').sort();

    return {
      CFRs
    };
  }, [document.cfr_parts]);

  return {
    formattedData
  };
};
