import PropTypes from 'prop-types';
import Select from 'react-select-3';
import AsyncSelect from 'react-select-3/async';
import CreatableSelect from 'react-select-3/creatable';
import AsyncCreatableSelect from 'react-select-3/async-creatable';
import { useSelectV2CustomElements, useSelectV2Data, useSelectV2Handlers } from './hooks';
import { SELECT_STYLES } from './SelectV2.styles';

const DEFAULT_MENU_HEIGHT = 300;

const SelectV2 = props => {
  const {
    // Common props
    styles,
    className,
    classNamePrefix,
    isDisabled,
    isClearable,
    isSearchable,
    isLoading,
    isMulti,
    isOpen,
    value,
    options,
    onChange,
    onInputChange,
    withCheckboxes,
    withTooltips,
    tooltipTitleRenderer,
    hideSelectedOptions,
    filterOption,
    defaultValue,
    placeholder,
    noOptionsMessage,
    maxMenuHeight,
    customComponents,
    menuPlacement,
    defaultInputValue,
    inputValue,
    onMenuClose,
    closeMenuOnSelect,

    //Creatable select props
    isCreatable,
    onCreateOption,

    // Async select props
    isAsync,
    loadOptions,
    shouldCacheFetchedOptions,
    shouldFetchDefaultOptions
  } = props;

  const { localState, localActions, formattedData } = useSelectV2Data({
    value,
    inputValue,
    options
  });

  const { handleOnChange, handleOnInputChange, handleOnMenuClose } = useSelectV2Handlers({
    props: {
      isMulti,
      closeMenuOnSelect,
      onChange,
      onInputChange,
      onMenuClose
    },
    localActions
  });

  const components = useSelectV2CustomElements({
    withCheckboxes,
    withTooltips,
    tooltipTitleRenderer,
    customComponents
  });

  const selectProps = {
    styles,
    className,
    classNamePrefix,
    defaultValue,
    isDisabled,
    isLoading,
    isClearable,
    isMulti,
    isSearchable,
    isOpen,
    value: formattedData.value,
    options,
    onChange: handleOnChange,
    onInputChange: handleOnInputChange,
    hideSelectedOptions,
    filterOption,
    placeholder,
    components,
    noOptionsMessage,
    menuPlacement,
    maxMenuHeight: maxMenuHeight || DEFAULT_MENU_HEIGHT,
    defaultInputValue,
    inputValue: localState.input,
    onMenuClose: handleOnMenuClose,
    closeMenuOnSelect
  };

  const asyncSelectProps = {
    loadOptions,
    cacheOptions: shouldCacheFetchedOptions,
    defaultOptions: shouldFetchDefaultOptions
  };

  const creatableSelectProps = {
    onCreateOption
  };

  if (isAsync) {
    if (isCreatable) {
      return (
        <AsyncCreatableSelect {...selectProps} {...asyncSelectProps} {...creatableSelectProps} />
      );
    }

    return <AsyncSelect {...selectProps} {...asyncSelectProps} />;
  }

  if (isCreatable) {
    return <CreatableSelect {...selectProps} {...creatableSelectProps} />;
  }

  return <Select {...selectProps} />;
};

export default SelectV2;

SelectV2.propTypes = {
  styles: PropTypes.shape({}),
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  isOpen: PropTypes.bool,
  isAsync: PropTypes.bool,
  shouldCacheFetchedOptions: PropTypes.bool,
  shouldFetchDefaultOptions: PropTypes.bool,
  withCheckboxes: PropTypes.bool,
  withTooltips: PropTypes.bool,
  tooltipTitleRenderer: PropTypes.func,
  hideSelectedOptions: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any.isRequired,
        label: PropTypes.any.isRequired
      })
    ),
    PropTypes.arrayOf(PropTypes.string.isRequired)
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.any.isRequired
    })
  ),
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  filterOption: PropTypes.func,
  defaultValue: PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.any.isRequired
  }),
  loadOptions: PropTypes.func,
  placeholder: PropTypes.string,
  customComponents: PropTypes.shape({}),
  noOptionsMessage: PropTypes.func,
  maxMenuHeight: PropTypes.number,
  menuPlacement: PropTypes.oneOf(['auto', 'top', 'bottom']),
  defaultInputValue: PropTypes.string,
  inputValue: PropTypes.string,
  isCreatable: PropTypes.bool,
  onCreateOption: PropTypes.func,
  onMenuClose: PropTypes.func,
  closeMenuOnSelect: PropTypes.bool
};

SelectV2.defaultProps = {
  styles: SELECT_STYLES.DEFAULT,
  className: 'select-v2',
  classNamePrefix: 'select-v2',
  isDisabled: false,
  isClearable: true,
  isSearchable: true,
  isLoading: false,
  isMulti: false,
  isAsync: false,
  shouldCacheFetchedOptions: true,
  shouldFetchDefaultOptions: false,
  withCheckboxes: false,
  withTooltips: false,
  tooltipTitleRenderer: undefined,
  hideSelectedOptions: true,
  isOpen: undefined,
  value: undefined,
  options: [],
  filterOption: undefined,
  defaultValue: undefined,
  placeholder: 'Select...',
  customComponents: undefined,
  noOptionsMessage: undefined,
  maxMenuHeight: undefined,
  menuPlacement: 'auto',
  defaultInputValue: undefined,
  inputValue: undefined,
  isCreatable: false,
  onCreateOption: undefined,
  onMenuClose: undefined,
  closeMenuOnSelect: true
};
