import type { WidgetProperties } from './widgets.types';
import type { SelectOption } from '@compliance.ai/web-components';

export enum WIDGET_PROPERTIES_KEYS {
  NAME = 'name',
  EDITABLE = 'editable',
  TIMEFRAME = 'timeframe',
  TIMEFRAME_UNIT = 'timeframeUnit',
  JURISDICTIONS = 'jurisdictions',
  DOC_TYPES = 'docTypes',
  AGENCIES = 'agencies',
  IS_DEFAULT_FILTER_TOGGLED = 'isDefaultFilterToggled',
  CONCEPT_IDS = 'conceptIds',
  ENFORCEMENT_ACTIONS_VALUE_TYPE = 'valueType',
  ENFORCEMENT_ACTIONS_VIEW_TYPES = 'viewType',
  CURRENT_ALERT_ID = 'current_alert_id',
  NEWS_AND_PREMIUM_CONTENT = 'news',
  REGULATION = 'regulation',
  REGULATION_TYPE = 'regulationType',
  TOPICS = 'topics',
  ALERT_SEARCH_ARGS = 'alertSearchArgs',
  IS_HIDDEN_WHEN_WIDGET_IS_DEFAULT = 'isHiddenWhenWidgetIsDefault',
  POLICY_WIDGET_X_AXIS_TYPE = 'policyWidgetXAxisType',
  POLICY_WIDGET_COMPARISON_ATTRIBUTES = 'policyWidgetComparisonAttributes',
  POLICY_WIDGET_LEGEND_NAMES = 'policyWidgetLegendNames',
  POLICY_WIDGET_EMAIL = 'policyWidgetEmail',
  SEE_ALL = 'see_all',
  /**
   *
   * Deprecated properties
   *
   */
  WIDTH = 'width',
  EDITABLE_WIDTH = 'editableWidth',
  COMPONENT = 'component'
}

export enum WIDGET_UNITS_OF_TIME {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month'
}

export enum ENFORCEMENT_ACTIONS_VIEW_TYPES {
  SNAPSHOT = 'Snapshot',
  TIME_COMPARISON = 'Time Comparison'
}

export enum ENFORCEMENT_ACTIONS_VALUE_TYPES {
  DOCUMENTS = '# of Documents',
  PENALTY_AMOUNTS = 'Penalty Amounts'
}

export const ENFORCEMENT_ACTIONS_VIEW_TYPE_OPTIONS = Object.values(
  ENFORCEMENT_ACTIONS_VIEW_TYPES
).map(valueType => ({
  label: valueType,
  value: valueType
}));

export const ENFORCEMENT_ACTIONS_VALUE_TYPE_OPTIONS = Object.values(
  ENFORCEMENT_ACTIONS_VALUE_TYPES
).map(valueType => ({
  label: valueType,
  value: valueType
}));

export enum WIDGET_REGULATION_TYPES {
  REGULATION = 'Regulation',
  ACT = 'Act'
}

export const WIDGET_LEGACY_DEFAULT_CUSTOMIZATION_KEY = 'DEFAULT';

export enum POLICY_WIDGET_X_AXIS_TYPES {
  AGENCY = 'agency_ids',
  TOPIC = 'topics',
  CONCEPT = 'concept_tags'
}

export enum POLICY_WIDGET_COMPARISON_ATTRIBUTES {
  TOPIC = 'topics',
  CONCEPT = 'concept_tags',
  CITATION = 'official_id'
}

export const POLICY_WIDGET_X_AXIS_TYPES_OPTIONS: SelectOption<POLICY_WIDGET_X_AXIS_TYPES>[] = [
  {
    value: POLICY_WIDGET_X_AXIS_TYPES.AGENCY,
    label: 'Agency'
  }
  // TODO Uncomment when BE will support these fields
  // {
  //   value: POLICY_WIDGET_X_AXIS_TYPES.TOPIC,
  //   label: 'Topic'
  // },
  // {
  //   value: POLICY_WIDGET_X_AXIS_TYPES.CONCEPT,
  //   label: 'Concept'
  // }
];

export const POLICY_WIDGET_COMPARISON_ATTRIBUTES_OPTIONS: SelectOption<
  POLICY_WIDGET_COMPARISON_ATTRIBUTES
>[] = [
  {
    value: POLICY_WIDGET_COMPARISON_ATTRIBUTES.TOPIC,
    label: 'Topic'
  },
  {
    value: POLICY_WIDGET_COMPARISON_ATTRIBUTES.CONCEPT,
    label: 'Concept'
  },
  {
    value: POLICY_WIDGET_COMPARISON_ATTRIBUTES.CITATION,
    label: 'Citation'
  }
];

export const WIDGET_DEFAULT_PROPERTIES: WidgetProperties = {
  [WIDGET_PROPERTIES_KEYS.NAME]: '',
  [WIDGET_PROPERTIES_KEYS.EDITABLE]: false,
  [WIDGET_PROPERTIES_KEYS.TIMEFRAME]: 7,
  [WIDGET_PROPERTIES_KEYS.TIMEFRAME_UNIT]: WIDGET_UNITS_OF_TIME.DAY,
  [WIDGET_PROPERTIES_KEYS.JURISDICTIONS]: [],
  [WIDGET_PROPERTIES_KEYS.DOC_TYPES]: [],
  [WIDGET_PROPERTIES_KEYS.AGENCIES]: [],
  [WIDGET_PROPERTIES_KEYS.IS_DEFAULT_FILTER_TOGGLED]: false,
  [WIDGET_PROPERTIES_KEYS.CONCEPT_IDS]: [],
  [WIDGET_PROPERTIES_KEYS.ENFORCEMENT_ACTIONS_VALUE_TYPE]:
    ENFORCEMENT_ACTIONS_VALUE_TYPES.DOCUMENTS,
  [WIDGET_PROPERTIES_KEYS.ENFORCEMENT_ACTIONS_VIEW_TYPES]: ENFORCEMENT_ACTIONS_VIEW_TYPES.SNAPSHOT,
  [WIDGET_PROPERTIES_KEYS.CURRENT_ALERT_ID]: null,
  [WIDGET_PROPERTIES_KEYS.NEWS_AND_PREMIUM_CONTENT]: [],
  [WIDGET_PROPERTIES_KEYS.REGULATION]: null,
  [WIDGET_PROPERTIES_KEYS.REGULATION_TYPE]: WIDGET_REGULATION_TYPES.REGULATION,
  [WIDGET_PROPERTIES_KEYS.TOPICS]: [],
  [WIDGET_PROPERTIES_KEYS.ALERT_SEARCH_ARGS]: null,
  [WIDGET_PROPERTIES_KEYS.IS_HIDDEN_WHEN_WIDGET_IS_DEFAULT]: false,
  [WIDGET_PROPERTIES_KEYS.POLICY_WIDGET_X_AXIS_TYPE]: POLICY_WIDGET_X_AXIS_TYPES.AGENCY,
  [WIDGET_PROPERTIES_KEYS.POLICY_WIDGET_COMPARISON_ATTRIBUTES]: [
    POLICY_WIDGET_COMPARISON_ATTRIBUTES.CONCEPT,
    POLICY_WIDGET_COMPARISON_ATTRIBUTES.CITATION,
    POLICY_WIDGET_COMPARISON_ATTRIBUTES.TOPIC
  ],
  [WIDGET_PROPERTIES_KEYS.POLICY_WIDGET_LEGEND_NAMES]: ['', ''],
  [WIDGET_PROPERTIES_KEYS.POLICY_WIDGET_EMAIL]: false,
  /**
   *
   * Deprecated properties
   *
   */
  [WIDGET_PROPERTIES_KEYS.WIDTH]: 1,
  [WIDGET_PROPERTIES_KEYS.EDITABLE_WIDTH]: false,
  [WIDGET_PROPERTIES_KEYS.COMPONENT]: null
};

export const WIDGET_TIMEFRAME_UNITS_OPTIONS: { label: string; value: WIDGET_UNITS_OF_TIME }[] = [
  {
    label: 'Monthly',
    value: WIDGET_UNITS_OF_TIME.MONTH
  },
  {
    label: 'Weekly',
    value: WIDGET_UNITS_OF_TIME.WEEK
  }
];

export enum WIDGET_COMPONENT_NAMES {
  TIMELINE = 'dashTimeline',
  TOPICS = 'dashTopics',
  ENFORCEMENT_GRAPH = 'dashEnforcementGraph',
  ENFORCEMENT_GRAPH_2 = 'dashEnforcementGraph2',
  NEWS = 'dashNews',
  APPROACHING_DEADLINES = 'dashApproachingDeadlines',
  WHITE_PAPERS = 'dashWhitepapers',
  COMMON_SEARCHES = 'dashCommonSearches',
  CCPA = 'dashCCPA',
  COVID = 'dashCovid',
  REGULATION = 'dashRegulation',
  TASK_VELOCITY = 'dashTaskVelocity',
  ALERT = 'dashAlert',
  LABELS = 'dashLabelsWidget',
  POLICY = 'dashPolicy'
}
