import type { ManageBucketAccessProps } from '../ManageBucketAccess.types';

import * as errorsUtils from 'utils/errors';

import * as rulesApi from 'shared/features/rules/rules.api';
import * as helpers from './useManageBucketAccessQueries.helpers';

import { COMPLEX_QUERY_KEY_GETTERS, QUERY_KEYS, QUERY_SECTIONS } from 'constants/QueryKeys';
import { SHARE_BUCKET_RULES_LIMIT } from '../../../ShareBucketModal.constants';

import { useQuery } from '@tanstack/react-query';

export const useManageBucketAccessQueries = ({
  props
}: {
  props: Pick<ManageBucketAccessProps, 'bucket'>;
}) => {
  const bucketAccessRulesQuery = useQuery<
    Awaited<ReturnType<typeof rulesApi.fetchRules>>,
    Error,
    ReturnType<ReturnType<typeof helpers.formatBucketAccessRulesRows>>
  >({
    queryKey: COMPLEX_QUERY_KEY_GETTERS[QUERY_KEYS.RULES][QUERY_SECTIONS.BUCKETS_ACCESS_TABLE](
      props.bucket?.id ?? null
    ),
    queryFn: () =>
      rulesApi.fetchRules({
        bucket_id: Number(props.bucket?.id),
        limit: SHARE_BUCKET_RULES_LIMIT
      }),
    select: helpers.formatBucketAccessRulesRows({
      bucket: props.bucket
    }),
    enabled: Boolean(props.bucket?.id),
    onError: errorsUtils.logReactQueryError,
    refetchOnWindowFocus: false
  });

  return {
    bucketAccessRulesQuery
  };
};
