import type { GlobalState } from 'shared/reducers';
import type { ApproversSelectData } from './useApproversSelectData.types';
import type { ApproversSelectProps } from '../ApproversSelect.types';
import lodash from 'lodash';

import { getActiveOrganizationMembersOptionsByIds } from 'shared/features/organizations/organizations.selectors';

import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';

export const useApproversSelectData = (
  props: Pick<ApproversSelectProps, 'approversIds' | 'isSubmitDisabled' | 'primaryApprover'>
): ApproversSelectData => {
  const [selectedApprover, setSelectedApprover] = useState<
    ApproversSelectData['localState']['selectedApprover']
  >(null);

  const localState: ApproversSelectData['localState'] = {
    selectedApprover
  };

  const localActions: ApproversSelectData['localActions'] = useMemo(
    () => ({
      setSelectedApprover
    }),
    []
  );

  const reduxState = useSelector<GlobalState, ApproversSelectData['reduxState']>(state => ({
    // TODO Remove ts-ignore when organizations.selectors.js will be converted to .ts
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    approversOptions: getActiveOrganizationMembersOptionsByIds(state, props.approversIds),
    primaryApproverActive: !lodash.isEmpty(
      // TODO Remove ts-ignore when organizations.selectors.js will be converted to .ts
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getActiveOrganizationMembersOptionsByIds(state, [props.primaryApprover])
    )
  }));

  const formattedData: ApproversSelectData['formattedData'] = useMemo(() => {
    return {
      isSubmitDisabled:
        props.isSubmitDisabled ||
        !reduxState.approversOptions.length ||
        (reduxState.approversOptions.length > 1 &&
          !localState.selectedApprover &&
          !props.primaryApprover &&
          reduxState.primaryApproverActive)
    };
  }, [
    props.isSubmitDisabled,
    reduxState.approversOptions.length,
    localState.selectedApprover,
    props.primaryApprover,
    reduxState.primaryApproverActive
  ]);

  return {
    localState,
    localActions,
    reduxState,
    formattedData
  };
};
