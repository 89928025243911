import { api_getWhitePaperDocuments, api_getAgenciesActivitiesGlanceDocs } from './dashboard.api';
import { api_getDocumentsPost } from '../documents/documents.api';

export const REQUEST_NEWS_DOCS = 'REQUEST_NEWS_DOCS';
export const RECEIVE_NEWS_DOCS = 'RECEIVE_NEWS_DOCS';
export const FETCH_WHITEPAPER_DOCS = 'FETCH_WHITEPAPER_DOCS';
export const FETCH_AGENCIES_ACTIVITIES_GLANCE_DOCS = 'FETCH_AGENCIES_ACTIVITIES_GLANCE_DOCS';

export function fetchNewsDocs(params) {
  return function fetch(dispatch) {
    dispatch({ type: REQUEST_NEWS_DOCS });
    return api_getDocumentsPost(params).then(res => {
      dispatch({ type: RECEIVE_NEWS_DOCS, payload: res.documents });
    });
  };
}

export function fetchWhitePaperDocs({
  published_from,
  published_to,
  cai_category,
  skip_cai_category
}) {
  return function fetch(dispatch) {
    return api_getWhitePaperDocuments({
      published_from,
      published_to,
      cai_category,
      skip_cai_category
    }).then(res => {
      dispatch({ type: FETCH_WHITEPAPER_DOCS, payload: res.documents });
    });
  };
}

export function fetchAgenciesActivitiesGlanceDocs({
  agency_id,
  widgetId,
  cai_category,
  timeframe
}) {
  return function fetch(dispatch) {
    return api_getAgenciesActivitiesGlanceDocs({ agency_id, cai_category, timeframe }).then(res => {
      dispatch({ type: FETCH_AGENCIES_ACTIVITIES_GLANCE_DOCS, payload: { res, widgetId } });
    });
  };
}
