import type { RegulationHandlers } from './useRegulationHandlers.types';
import type { RegulationProps } from '../Regulation.types';
import type { RegulationData } from './useRegulationData.types';

import { useCallback } from 'react';
import { WIDGET_PROPERTIES_KEYS } from 'shared/features/widgets/widgets.constants';

export const useRegulationHandlers = ({
  props,
  prevProps
}: {
  props: RegulationProps;
  prevProps: RegulationData['prevProps'];
}): RegulationHandlers => {
  const { onChange } = props;

  const handleRegulationTypeChange: RegulationHandlers['handleRegulationTypeChange'] = useCallback(() => {
    /**
     *
     * Reset selected options for current select when regulation type changes
     *
     */
    if (
      props.properties[WIDGET_PROPERTIES_KEYS.REGULATION_TYPE] !==
      prevProps.properties[WIDGET_PROPERTIES_KEYS.REGULATION_TYPE]
    ) {
      onChange(null);
    }
  }, [onChange, prevProps.properties, props.properties]);

  return {
    handleRegulationTypeChange
  };
};
