import type { TopicsProps } from './Topics.types';

import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';
import { TopicsListItem } from 'common/index';

export const Topics = ({ document, shouldRenderForEmail }: TopicsProps) => {
  if (!document.topics?.length) {
    return null;
  }

  return (
    <>
      <FieldName>Topics</FieldName>
      <FieldValue>
        {shouldRenderForEmail
          ? document.topics.map(topic => topic.name).join(', ')
          : document.topics.map(topic => (
              <TopicsListItem key={topic.id} document={document} topic={topic} rightPanel />
            ))}
      </FieldValue>
    </>
  );
};
