import type { GlobalState } from 'shared/reducers';
import type { AppNavigationData, AppNavigationLocalState } from './useAppNavigationData.types';

import * as permissionsConstants from 'constants/Permissions';
import * as plansConstants from 'constants/Plans';
import * as navBarConstants from './useAppNavigationNavBarConfig.constants';
import * as defaultsSelectors from 'shared/features/defaults/defaults.selectors';
import * as defaultSourcesConstants from 'constants/DefaultSources';
import * as actionBarFilterConstants from 'constants/ActionBarFilter';
import * as actionsBarConstants from 'constants/ActionBar';
import * as filtersSelectors from 'shared/features/filters/filters.selectors';
import * as thresholdsSelectors from 'shared/features/thresholds/thresholds.selectors';
import * as userSelectors from 'shared/features/user/user.selectors';
import * as viewSelectors from 'shared/features/view/view.selectors';
import * as agenciesSelectors from 'shared/features/agencies/agencies.selectors';
import * as jurisdictionsSelectors from 'shared/features/jurisdictions/jurisdictions.selectors';
import * as documentsSelectors from 'shared/features/documents/documents.selectors';
import * as topicsSelectors from 'shared/features/topics/topics.selectors';
import * as entitiesSelectors from 'shared/features/entities/entities.selectors';
import * as rightPanelSelectors from 'shared/features/rightPanel/rightPanel.selectors';

import queryString from 'utils/query-string';
import auth from 'utils/auth';

import { usePermissions, usePlans } from 'utils/hooks';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export const useAppNavigationData = (): AppNavigationData => {
  const plans: Record<string, unknown> = usePlans();
  const permissions = usePermissions();
  const location = useLocation();

  const [isNavBarOpened, setIsNavBarOpened] = useState<AppNavigationLocalState['isNavBarOpened']>(
    false
  );
  const [selectedNavBarItemLabel, setSelectedNavBarItemLabel] = useState<
    AppNavigationLocalState['selectedNavBarItemLabel']
  >(null);
  const [isIframeMouseEventsDisabled, setIframeMouseEventsDisabled] = useState<
    AppNavigationLocalState['isIframeMouseEventsDisabled']
  >(false);

  const localState: AppNavigationData['localState'] = {
    isNavBarOpened,
    selectedNavBarItemLabel,
    isIframeMouseEventsDisabled
  };

  const localActions: AppNavigationData['localActions'] = useMemo(
    () => ({
      setIsNavBarOpened,
      setSelectedNavBarItemLabel,
      setIframeMouseEventsDisabled
    }),
    []
  );

  const reduxState: AppNavigationData['reduxState'] = useSelector<
    GlobalState,
    AppNavigationData['reduxState']
  >(state => ({
    currentUser: userSelectors.getCurrentUserReducer(state),
    currentView: viewSelectors.getCurrentView(state),
    agencies: agenciesSelectors.getAgenciesReducer(state),
    jurisdictions: jurisdictionsSelectors.getJurisdictionsReducer(state),
    docTypes: documentsSelectors.getDocTypesReducer(state),
    notifications: state.notifications,
    topics: topicsSelectors.getTopicsReducer(state),
    entities: entitiesSelectors.getEntitiesReducer(state),
    defaults: {
      followedAgenciesIds: defaultsSelectors.getFollowedAgenciesIds(
        defaultSourcesConstants.DEFAULT_TYPES.MY_DEFAULTS
      )(state),
      followedTopicsIds: defaultsSelectors.getFollowedTopicsIds(
        defaultSourcesConstants.DEFAULT_TYPES.MY_DEFAULTS
      )(state),
      followedNewsSources: defaultsSelectors.getRealFollowedNewsSourcesIds(
        defaultSourcesConstants.DEFAULT_TYPES.MY_DEFAULTS
      )(state),
      followedConceptIds: defaultsSelectors.getFollowedConceptIds(
        defaultSourcesConstants.DEFAULT_TYPES.MY_DEFAULTS
      )(state),
      isFetching: defaultsSelectors.getDefaultsFetchingStatus(
        defaultSourcesConstants.DEFAULT_TYPES.MY_DEFAULTS
      )(state)
    },
    actionBarFilterValues: {
      rowsCount: filtersSelectors.getActionBarFilterParamValue(
        actionBarFilterConstants.FILTER_KEY.ROWS_COUNT,
        actionsBarConstants.DEFAULT_LIMIT
      )(state)
    },
    categories: documentsSelectors.getCategories(state as never),
    topicThresholdKey: thresholdsSelectors.getInitialTopicThresholdKey(
      queryString.parse(location.search)
    )(state),

    rightPanel: {
      type: rightPanelSelectors.getRightPanelType(state as never),
      isOpen: rightPanelSelectors.getRightPanelOpenState(state as never),
      isClosable: rightPanelSelectors.getRightPanelIsClosable(state as never)
    }
  }));

  const formattedData: AppNavigationData['formattedData'] = useMemo(() => {
    const restrictedByPlans = plans[plansConstants.PLANS.SERVICE_CONNECTOR]
      ? [
          navBarConstants.NAV_BAR_ITEM_LABELS.DEFAULT_FILTER,
          navBarConstants.NAV_BAR_ITEM_LABELS.NEWS,
          navBarConstants.NAV_BAR_ITEM_LABELS.INSIGHTS,
          navBarConstants.NAV_BAR_ITEM_LABELS.FOLDERS,
          navBarConstants.NAV_BAR_ITEM_LABELS.RESOURCES
        ]
      : [];

    const restrictedByAuth = [
      ...(auth.loggedIn()
        ? []
        : [
            navBarConstants.NAV_BAR_ITEM_LABELS.FOLDERS,
            navBarConstants.NAV_BAR_ITEM_LABELS.NOTIFICATIONS
          ])
    ];

    const restrictedByPermissions = [
      ...(permissions[permissionsConstants.ACCESS_TASKS] && !plans[plansConstants.PLANS.PRO]
        ? []
        : [navBarConstants.NAV_BAR_ITEM_LABELS.TASKS])
    ];

    return {
      restrictedNavBarItems: [
        ...new Set([...restrictedByPlans, ...restrictedByAuth, ...restrictedByPermissions])
      ]
    };
  }, [plans, permissions]);

  return {
    localState,
    localActions,
    reduxState,
    formattedData
  };
};
