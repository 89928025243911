import { fetch } from 'utils/api';
import { getErrorMessageFromResponse } from './auth.helpers';
import * as cognitoUtils from 'utils/cognito-utils';
import { userPoolId, clientId, appUrl } from 'shared/config';
import {
  COGNITO_AUTH_QUERY_PARAMS,
  COGNITO_TOKEN_URL,
  EXPECTED_ERROR_MESSAGES
} from './auth.constants';
import * as routes from 'constants/Routes';
import axios from 'axios';
import { getCorporateLoginJWTToken, removeCorporateLoginJWTToken } from 'utils/localStorage-utils';

export const authenticate = async ({ email, password }) => {
  try {
    return await authenticateUsingCognito({ email, password });
  } catch (e) {
    const errorMessage = getErrorMessageFromResponse(e);

    if (errorMessage === EXPECTED_ERROR_MESSAGES.USER_DOES_NOT_EXIST) {
      return await authenticateUsingApi({ email, password });
    }

    throw new Error(errorMessage);
  }
};

export const authenticateUsingCognito = async ({ email, password }) => {
  try {
    const { accessToken, idToken, refreshToken } = await cognitoUtils.signIn({
      username: email,
      password: password
    });

    const { jwt_token: JWTToken } = await fetch({
      url: '/auth_cognito_jwt_token',
      method: 'POST',
      params: {
        token: idToken,
        user_pool_id: userPoolId,
        app_client_id: clientId
      }
    });

    return {
      JWTToken,
      accessToken,
      idToken,
      refreshToken
    };
  } catch (e) {
    throw new Error(getErrorMessageFromResponse(e));
  }
};

export const authenticateUsingApi = async ({ email, password }) => {
  try {
    const { jwt_token } = await fetch({
      url: '/login',
      method: 'POST',
      params: {
        email,
        password
      }
    });

    return {
      JWTToken: jwt_token,
      accessToken: null,
      idToken: null,
      refreshToken: null
    };
  } catch (e) {
    throw new Error(getErrorMessageFromResponse(e));
  }
};

export const exchangeCodeForTokens = async code => {
  const urlSearchParams = new URLSearchParams();

  const redirectUri = `${appUrl}${routes.authRedirect}`;

  urlSearchParams.append(COGNITO_AUTH_QUERY_PARAMS.GRANT_TYPE, 'authorization_code');
  urlSearchParams.append(COGNITO_AUTH_QUERY_PARAMS.CLIENT_ID, clientId);
  urlSearchParams.append(COGNITO_AUTH_QUERY_PARAMS.REDIRECT_URI, redirectUri);
  urlSearchParams.append(COGNITO_AUTH_QUERY_PARAMS.CODE, code);

  /**
   *
   * Note: Axios used here because our regular api helper will append Authorization header
   *
   */
  const {
    access_token: accessToken,
    id_token: idToken,
    refresh_token: refreshToken
  } = await axios.post(COGNITO_TOKEN_URL, urlSearchParams, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });

  return {
    accessToken,
    idToken,
    refreshToken
  };
};

export const authenticateUsingCognitoCode = async ({ code, orgInvitationToken }) => {
  try {
    const tokens = await exchangeCodeForTokens(code);

    const { accessToken, idToken, refreshToken } = await cognitoUtils.signInUsingTokens(tokens);

    const { jwt_token: JWTToken } = await fetch({
      url: '/auth_cognito_jwt_token',
      method: 'POST',
      params: {
        token: idToken,
        user_pool_id: userPoolId,
        app_client_id: clientId,
        org_invitation_token: orgInvitationToken
      }
    });

    return {
      JWTToken,
      accessToken,
      idToken,
      refreshToken
    };
  } catch (e) {
    throw new Error(getErrorMessageFromResponse(e));
  }
};

export const signUp = async ({ email, password, orgInvitationToken }) => {
  try {
    return await fetch({
      url: '/activate_cognito',
      method: 'POST',
      params: {
        email: email,
        password: password,
        org_invitation_token: orgInvitationToken
      }
    });
  } catch (e) {
    throw new Error(getErrorMessageFromResponse(e));
  }
};

export const confirmRegisteredUser = async ({ email, code }) => {
  try {
    await cognitoUtils.confirmRegisteredUser({
      username: email,
      code: code
    });
  } catch (e) {
    throw new Error(getErrorMessageFromResponse(e));
  }
};

export const refreshSession = async () => {
  try {
    /**
     *
     * TODO Figure out how to keep user corporate login session without usage of localStorage
     *
     */
    const corporateLoginJWTToken = getCorporateLoginJWTToken();

    if (corporateLoginJWTToken) {
      return {
        JWTToken: corporateLoginJWTToken,
        accessToken: null,
        idToken: null,
        refreshToken: null
      };
    }

    const { accessToken, idToken, refreshToken } = await cognitoUtils.refreshSession();

    const { jwt_token: JWTToken } = await fetch({
      url: '/auth_cognito_jwt_token',
      method: 'POST',
      params: {
        token: idToken,
        user_pool_id: userPoolId,
        app_client_id: clientId
      }
    });

    return {
      JWTToken,
      accessToken,
      idToken,
      refreshToken
    };
  } catch (e) {
    throw new Error(getErrorMessageFromResponse(e));
  }
};

export const sendPasswordResetEmail = async ({ email }) => {
  try {
    await cognitoUtils.passwordReset(email);
  } catch (e) {
    throw new Error(getErrorMessageFromResponse(e));
  }
};

export const confirmNewPassword = async ({ email, verificationCode, newPassword }) => {
  try {
    await cognitoUtils.confirmNewPassword({ email, verificationCode, newPassword });
  } catch (e) {
    throw new Error(getErrorMessageFromResponse(e));
  }
};

export const changePassword = async ({ oldPassword, newPassword }) => {
  try {
    await cognitoUtils.changePassword({ oldPassword, newPassword });
  } catch (e) {
    throw new Error(getErrorMessageFromResponse(e));
  }
};

export const signOut = async () => {
  try {
    /**
     *
     * TODO Figure out how to keep user corporate login session without usage of localStorage
     *
     */
    removeCorporateLoginJWTToken();

    await cognitoUtils.signOut();
  } catch (e) {
    const errorMessage = getErrorMessageFromResponse(e);

    if (errorMessage === EXPECTED_ERROR_MESSAGES.CURRENT_USER_IS_NOT_DETECTED) {
      return;
    }

    throw new Error(errorMessage);
  }
};

export const getExternalApiToken = async params => {
  try {
    const { jwt_token: approvedAppToken, redirect_url: approvedAppUrl } = await fetch({
      url: '/external_application/approve_application',
      method: 'GET',
      params
    });

    return {
      approvedAppToken,
      approvedAppUrl
    };
  } catch (e) {
    throw new Error(getErrorMessageFromResponse(e));
  }
};

export const generateExternalApiToken = async () => {
  try {
    const { jwt_token: approvedAppToken } = await fetch({
      url: '/external_application/api_token',
      method: 'GET'
    });

    return {
      approvedAppToken
    };
  } catch (e) {
    throw new Error(getErrorMessageFromResponse(e));
  }
};
