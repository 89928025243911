import type { FC } from 'react';
import type { DropdownCellProps } from './DropdownCell.types';

import * as emptyValue from 'constants/EmptyValue';
import * as uiLib from '@compliance.ai/web-components';

import { useDropdownCellData } from './hooks';

import './_dropdown-cell.scss';

export const DropdownCell: FC<DropdownCellProps> = ({ cellValue }) => {
  const { formattedData } = useDropdownCellData({ cellValue });

  if (!cellValue.dropdowns?.length) {
    return <>{emptyValue.EMPTY_VALUE.DASH}</>;
  }

  return (
    <>
      {formattedData.dropdowns.map(dropdown => {
        return (
          <uiLib.LabeledField
            key={dropdown.id}
            label={dropdown.label}
            labelClassName="workflow-summary-dropdown-cell__title"
          >
            {dropdown.selectedValues}
          </uiLib.LabeledField>
        );
      })}
    </>
  );
};
