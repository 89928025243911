import type { DocketTimelineItemHandlers } from './useDocumentTimelineItemHandlers.types';
import type { DocumentTimelineItemProps } from '../DocumentTimelineItem.types';

import * as rightPanelConstants from 'shared/features/rightPanel/rightPanel.constants';

import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';

export const useDocumentTimelineItemHandlers = ({
  props
}: {
  props: Pick<DocumentTimelineItemProps, 'document' | 'onDocumentDiff'>;
}): DocketTimelineItemHandlers => {
  const rightPanelReduxActions = useRightPanelReduxActions();

  const handleDocumentDiff: DocketTimelineItemHandlers['handleDocumentDiff'] = () => {
    props.onDocumentDiff?.();
  };

  const handleClick: DocketTimelineItemHandlers['handleClick'] = () => {
    rightPanelReduxActions.openRightPanel({
      type: rightPanelConstants.RIGHT_PANEL_TYPES.DOCUMENT_DETAILS,
      props: {
        documentId: props.document.id,
        activeTabKey: rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.DETAILS,
        shouldOpenRightPanelWithOverlay: true
      }
    });
  };

  return {
    handleDocumentDiff,
    handleClick
  };
};
