import type { RegulationsSelectSelectData } from './useRegulationsSelectData.types';
import type { RegulationsSelectProps } from '../RegulationsSelect.types';
import type { GlobalState } from 'shared/reducers';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getRegulationsOptions,
  getRegulationsReadyState
} from 'shared/features/regulations/regulations.selectors';
import { getValuesFromOptions } from 'utils/select-utils';

export const useRegulationsSelectData = ({
  value,
  isMulti
}: RegulationsSelectProps): RegulationsSelectSelectData => {
  const reduxState = useSelector<GlobalState, RegulationsSelectSelectData['reduxState']>(state => ({
    options: getRegulationsOptions(state as never),
    isReady: getRegulationsReadyState(state)
  }));

  const formattedData: RegulationsSelectSelectData['formattedData'] = useMemo(
    () => ({
      value: getValuesFromOptions({
        isMulti,
        options: reduxState.options,
        value
      })
    }),
    [isMulti, reduxState.options, value]
  );

  return {
    reduxState,
    formattedData
  };
};
