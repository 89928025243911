import type { FirstLoginJoyrideHandlers } from './useFirstLoginExperienceHandlers.types';
import type { FirstLoginJoyrideData } from './useFirstLoginExperienceData.types';

import * as errorUtils from 'utils/errors';

import { useCallback } from 'react';
import { useUserReduxActions } from 'shared/features/user/hooks';
import { useOrgProfileReduxActions } from 'shared/features/organizationProfile/hooks';

export const useFirstLoginExperienceHandlers = ({
  localState,
  localActions,
  reduxState
}: {
  localState: FirstLoginJoyrideData['localState'];
  localActions: FirstLoginJoyrideData['localActions'];
  reduxState: FirstLoginJoyrideData['reduxState'];
}) => {
  const orgProfileReduxActions = useOrgProfileReduxActions();
  const userReduxActions = useUserReduxActions();

  const handleUpdateCurrentUser: FirstLoginJoyrideHandlers['handleUpdateCurrentUser'] = useCallback(
    data => {
      return userReduxActions.updateCurrentUser(reduxState.currentUser.email, data);
    },
    [reduxState.currentUser.email, userReduxActions]
  );

  const handleGetOrganizationProfile: FirstLoginJoyrideHandlers['handleGetOrganizationProfile'] = useCallback(async () => {
    try {
      if (reduxState.isAuthenticated && localState.hasManageOrgProfilePermission) {
        await orgProfileReduxActions.getOrgProfile();
      }
      localActions.setIsInitialized(true);
    } catch (e) {
      if (reduxState.isAuthenticated) {
        localActions.setIsInitialized(true);
      } else {
        localActions.setIsInitialized(false);
      }
      errorUtils.logError(e as Error);
    }
  }, [
    reduxState.isAuthenticated,
    localState.hasManageOrgProfilePermission,
    orgProfileReduxActions,
    localActions
  ]);

  const handleClose = useCallback(() => {
    handleUpdateCurrentUser({ is_first_login: false });
  }, [handleUpdateCurrentUser]);

  return {
    handleUpdateCurrentUser,
    handleGetOrganizationProfile,
    handleClose
  };
};
