import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  NODE_LINK_POINT_POSITIONS,
  NODE_LINK_POINT_TYPES
} from '../../../../WorkflowDiagramTool.constants';
import { TaskNodeLinkPoint } from '../TaskNodeLinkPoint';

export const TaskNodeLinkPoints = memo(({ taskId, onConnect }) => {
  const renderHandle = useCallback(
    ({ type, position }) => {
      return (
        <TaskNodeLinkPoint
          key={`${taskId}-${type}-${position}`}
          id={`${taskId}-${type}-${position}`}
          type={type}
          position={position}
          onConnect={onConnect}
        />
      );
    },
    [onConnect, taskId]
  );

  return (
    <>
      {Object.values(NODE_LINK_POINT_POSITIONS).map(position =>
        renderHandle({
          type: NODE_LINK_POINT_TYPES.SOURCE,
          position: position
        })
      )}
      {Object.values(NODE_LINK_POINT_POSITIONS).map(position =>
        renderHandle({
          type: NODE_LINK_POINT_TYPES.TARGET,
          position: position
        })
      )}
    </>
  );
});

TaskNodeLinkPoints.propTypes = {
  taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onConnect: PropTypes.func
};
