import type { FC } from 'react';
import type { CommentsSectionProps } from './CommentsSection.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './CommentsSection.constants';

import { useCommentsSectionData } from './hooks';

export const CommentsSection: FC<CommentsSectionProps> = ({ documentTasks, classNames }) => {
  const { formattedData } = useCommentsSectionData({ documentTasks });

  return (
    <uiLib.Table
      rowKey={constants.TABLE_FIELDS.ID}
      shouldRenderToolbar={false}
      columns={constants.TABLE_COLUMNS}
      rows={formattedData.rows}
      classNames={{
        container: classNames?.tableOuterContainer,
        headerCell: classNames?.headerCell,
        cell: classNames?.cell,
        tableContainer: classNames?.tableContainer
      }}
    />
  );
};
