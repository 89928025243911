import React from 'react';
import moment from 'moment';

import { getCurrencySign } from 'utils/agency';

//This guys doesn't even seem to be getting rendered ever?
//Not sure how it is working cause doesn't look like document is even defined
class DocumentListItemEnforcements extends React.PureComponent {
  render() {
    const { checkboxColumn, rowClassName, handleClick } = this.props;
    let respondents = null;
    let monetary_penalty = null;
    let violation = null;
    if (Object.prototype.hasOwnProperty.call(document, 'enforcement')) {
      respondents = document.enforcement.respondents;
      monetary_penalty = document.enforcement.monetary_penalty;
      if (Object.prototype.hasOwnProperty.call(document.enforcement, 'violation')) {
        violation = document.enforcement.violation.replace(/__V__/g, '');
      }
    }
    const { publication_date } = document;
    const date = moment(publication_date).format('MM/DD/YYYY');
    let penaltyOrTBD = monetary_penalty === null ? 'TBD' : monetary_penalty;
    if (typeof penaltyOrTBD === 'number') {
      penaltyOrTBD = penaltyOrTBD.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      const agency = document.agencies[0];
      const jurisdiction = agency ? agency.jurisdiction : '';
      penaltyOrTBD = getCurrencySign(jurisdiction) + penaltyOrTBD;
    }
    const respondentOrTBD = respondents === null ? 'TBD' : respondents;
    return (
      <tr className={rowClassName} onClick={handleClick}>
        {checkboxColumn}
        <td className="enforcement-explorer-column">
          <p>
            <strong>{document.agencies.map(agency => agency.short_name).join(', ')}</strong>
          </p>
          <p>
            <strong>{'Penalties: ' + penaltyOrTBD}</strong>
          </p>
          <p>
            {'Published: '}
            <strong>{date}</strong>
          </p>
          <p>
            <strong>{'Respondent: ' + respondentOrTBD}</strong>
          </p>
          {violation && <p>{'Violation: ' + violation}</p>}
        </td>
      </tr>
    );
  }
}

export default DocumentListItemEnforcements;
