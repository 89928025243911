import React from 'react';
import * as uiLib from '@compliance.ai/web-components';
import * as localStorageUtils from 'utils/localStorage-utils';

export default class CookieBanner extends React.Component {
  acceptCookies = () => {
    localStorageUtils.safelySetLocalStorageData('acceptedCookies', true);
    this.forceUpdate();
  };

  render() {
    if (this.props.loggedIn || localStorage.getItem('acceptedCookies')) {
      return null;
    }

    return (
      <div className="cookie-banner">
        <div className="container">
          <div className="col-text">
            <h2>We value your privacy</h2>
            <p>
              We use your data to provide and improve the Service. By using the Service, you agree
              to the collection and use of information in accordance with our privacy policy. We use
              cookies and similar tracking technologies to track the activity on our Service and
              hold certain information. You can instruct your browser to refuse all cookies or to
              indicate when a cookie is being sent. However, if you do not accept cookies, you may
              not be able to use some portions of our Service. Learn more about our &nbsp;
              <a href="/legal">Privacy and Cookie policies here</a>.
            </p>
          </div>
          <div className="col-action">
            <uiLib.Button
              type={uiLib.BUTTON_TYPES.PRIMARY}
              onClick={this.acceptCookies}
              className="accept-button"
            >
              <span className="accept-button__label">I Accept</span>
            </uiLib.Button>
          </div>
        </div>
      </div>
    );
  }
}
