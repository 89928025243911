import type { AlertPreviewBannerProps } from '../AlertPreviewBanner.types';
import type { AlertPreviewBannerData } from './useAlertPreviewBannerData.types';

import * as errorUtils from 'utils/errors';
import * as uiLib from '@compliance.ai/web-components';
import * as alertsApi from 'shared/features/alerts/alerts.api';

import { useMutation } from '@tanstack/react-query';
import { useViewReduxActions } from 'shared/features/view/hooks';
import { useLocation } from 'react-router-dom';

export const useAlertPreviewBannerMutations = ({
  props,
  reduxState
}: {
  props: Pick<AlertPreviewBannerProps, 'alertParams'>;
  reduxState: AlertPreviewBannerData['reduxState'];
}) => {
  const location = useLocation();

  const viewReduxActions = useViewReduxActions();

  const updateAlert = useMutation<void, Error, unknown>({
    mutationFn: async () => {
      await alertsApi.api_updateAlert(reduxState.editAlertId, {
        search_args: {
          ...props.alertParams,
          /**
           * Required to open the correct docs page (news/enforcements/timeline)
           */
          pathname: location.pathname
        }
      });
    },
    onSettled: () => {
      viewReduxActions.hidePreviewAlert();
    },
    onError: errorUtils.logReactQueryError,
    onSuccess: () => {
      uiLib.showNotification({
        type: uiLib.ALERT_TYPES.SUCCESS,
        message: 'The alert was updated'
      });
    }
  });

  return {
    updateAlert
  };
};
