import * as uiLib from '@compliance.ai/web-components';

// TODO TO BE REMOVED! This is a legacy file and it will be removed at some point

export const today = uiLib.formatDate(new Date(), {
  format: uiLib.DATE_FORMATS.AMERICAN_DATE,
  timezone: uiLib.TIMEZONES.UTC,
  passedTimezone: uiLib.getLocalTimezone()
});
export const threeMonthsAgo = uiLib.formatDate(
  uiLib.getDateBeforeDate({
    date: new Date(),
    amount: 3,
    unitOfTime: uiLib.DATE_UNITS.MONTH
  }),
  {
    format: uiLib.DATE_FORMATS.AMERICAN_DATE,
    timezone: uiLib.TIMEZONES.UTC,
    passedTimezone: uiLib.getLocalTimezone()
  }
);

export const sixMonthsAgo = uiLib.formatDate(
  uiLib.getDateBeforeDate({
    date: new Date(),
    amount: 6,
    unitOfTime: uiLib.DATE_UNITS.MONTH
  }),
  {
    format: uiLib.DATE_FORMATS.AMERICAN_DATE,
    timezone: uiLib.TIMEZONES.UTC,
    passedTimezone: uiLib.getLocalTimezone()
  }
);

export const yearAgo = uiLib.formatDate(
  uiLib.getDateBeforeDate({
    date: new Date(),
    amount: 1,
    unitOfTime: uiLib.DATE_UNITS.YEAR
  }),
  {
    format: uiLib.DATE_FORMATS.AMERICAN_DATE,
    timezone: uiLib.TIMEZONES.UTC,
    passedTimezone: uiLib.getLocalTimezone()
  }
);

export const twoYearsAgo = uiLib.formatDate(
  uiLib.getDateBeforeDate({
    date: new Date(),
    amount: 2,
    unitOfTime: uiLib.DATE_UNITS.YEAR
  }),
  {
    format: uiLib.DATE_FORMATS.AMERICAN_DATE,
    timezone: uiLib.TIMEZONES.UTC,
    passedTimezone: uiLib.getLocalTimezone()
  }
);

export const LocalDateFormat = uiLib.getLocalDateFormat();
