import type { RulesViolatedProps } from '../RulesViolated.types';
import type { RulesViolatedData } from './useRulesViolatedData.types';

import * as documentConstants from 'shared/features/documents/documents.constants';

import { useMemo } from 'react';

export const useRulesViolatedData = ({
  props
}: {
  props: RulesViolatedProps;
}): RulesViolatedData => {
  const formattedData: RulesViolatedData['formattedData'] = useMemo(() => {
    const rules = props.document.enforcement?.violated_rules;
    const rulesArray = Array.isArray(rules) ? rules : [rules];

    return {
      violatedRules: !rules ? '' : rulesArray.join(', '),
      isRestricted: rulesArray.includes(documentConstants.FIELD_VALUES.RESTRICTED)
    };
  }, [props.document.enforcement?.violated_rules]);

  return { formattedData };
};
