import {
  changeDocumentView as reduxChangeDocumentView,
  showRestrictModal as reduxShowRestrictModal,
  hideRestrictModal as reduxHideRestrictModal,
  setTaskView as reduxSetTaskView,
  setActiveTask as reduxSetActiveTask,
  openFilter as reduxOpenFilter,
  addEditAlert as reduxAddEditAlert,
  addViewAlert as reduxAddViewAlert,
  fetchDocumentsToSelectedDocs as reduxFetchDocumentsToSelectedDocs,
  clearSelectedItems as reduxClearSelectedItems,
  hideSaveAlertSuccessModal as reduxHideSaveAlertSuccessModal,
  closeFilter as reduxCloseFilter,
  showSaveAlertSuccessModal as reduxShowSaveAlertSuccessModal,
  toggleAdvancedSearchFilter as reduxToggleAdvancedSearchFilter,
  openAdvancedSearchFilter as reduxOpenAdvancedSearchFilter,
  closeAdvancedSearchFilter as reduxCloseAdvancedSearchFilter,
  hidePreviewAlert as reduxHidePreviewAlert,
  removeViewAlert as reduxRemoveViewAlert,
  removeEditAlert as reduxRemoveEditAlert,
  resetTaskView as reduxResetTaskView
} from '../view.actions';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

export const useViewReduxActions = () => {
  const dispatch = useDispatch();

  const changeDocumentView = useCallback(
    (page, id) => {
      dispatch(reduxChangeDocumentView(page, id));
    },
    [dispatch]
  );

  const showRestrictModal = useCallback(
    (type, title, text) => {
      dispatch(reduxShowRestrictModal(type, title, text));
    },
    [dispatch]
  );

  const hideRestrictModal = useCallback(() => {
    dispatch(reduxHideRestrictModal());
  }, [dispatch]);

  const setTaskView = useCallback(
    (workflowView, taskDetails) => {
      dispatch(reduxSetTaskView(workflowView, taskDetails));
    },
    [dispatch]
  );

  const setActiveTask = useCallback(
    (docTaskId, workflowId, userId) => {
      dispatch(reduxSetActiveTask(docTaskId, workflowId, userId));
    },
    [dispatch]
  );

  const openFilter = useCallback(() => {
    dispatch(reduxOpenFilter());
  }, [dispatch]);

  const closeFilter = useCallback(() => {
    dispatch(reduxCloseFilter());
  }, [dispatch]);

  const addEditAlert = useCallback(
    (alertId, alertName) => {
      dispatch(reduxAddEditAlert(alertId, alertName));
    },
    [dispatch]
  );

  const addViewAlert = useCallback(
    (alertId, alertName) => {
      dispatch(reduxAddViewAlert(alertId, alertName));
    },
    [dispatch]
  );

  const fetchDocumentsToSelectedDocs = useCallback(
    async (...args) => {
      await dispatch(reduxFetchDocumentsToSelectedDocs(...args));
    },
    [dispatch]
  );

  const clearSelectedItems = useCallback(() => {
    dispatch(reduxClearSelectedItems());
  }, [dispatch]);

  const hideSaveAlertSuccessModal = useCallback(() => {
    dispatch(reduxHideSaveAlertSuccessModal());
  }, [dispatch]);

  const showSaveAlertSuccessModal = useCallback(
    (modalType, alertName) => {
      return dispatch(reduxShowSaveAlertSuccessModal(modalType, alertName));
    },
    [dispatch]
  );

  const toggleAdvancedSearchFilter = useCallback(() => {
    return dispatch(reduxToggleAdvancedSearchFilter());
  }, [dispatch]);

  const openAdvancedSearchFilter = useCallback(() => {
    return dispatch(reduxOpenAdvancedSearchFilter());
  }, [dispatch]);

  const closeAdvancedSearchFilter = useCallback(() => {
    return dispatch(reduxCloseAdvancedSearchFilter());
  }, [dispatch]);

  const hidePreviewAlert = useCallback(() => {
    return dispatch(reduxHidePreviewAlert());
  }, [dispatch]);

  const removeViewAlert = useCallback(() => {
    return dispatch(reduxRemoveViewAlert());
  }, [dispatch]);

  const removeEditAlert = useCallback(() => {
    return dispatch(reduxRemoveEditAlert());
  }, [dispatch]);

  const resetTaskView = useCallback(() => {
    return dispatch(reduxResetTaskView());
  }, [dispatch]);

  return useMemo(
    () => ({
      changeDocumentView,
      showRestrictModal,
      hideRestrictModal,
      setTaskView,
      setActiveTask,
      openFilter,
      closeFilter,
      addEditAlert,
      addViewAlert,
      fetchDocumentsToSelectedDocs,
      clearSelectedItems,
      hideSaveAlertSuccessModal,
      showSaveAlertSuccessModal,
      toggleAdvancedSearchFilter,
      openAdvancedSearchFilter,
      closeAdvancedSearchFilter,
      hidePreviewAlert,
      removeViewAlert,
      removeEditAlert,
      resetTaskView
    }),
    [
      changeDocumentView,
      showRestrictModal,
      hideRestrictModal,
      setTaskView,
      setActiveTask,
      openFilter,
      closeFilter,
      addEditAlert,
      addViewAlert,
      fetchDocumentsToSelectedDocs,
      clearSelectedItems,
      hideSaveAlertSuccessModal,
      showSaveAlertSuccessModal,
      toggleAdvancedSearchFilter,
      openAdvancedSearchFilter,
      closeAdvancedSearchFilter,
      hidePreviewAlert,
      removeViewAlert,
      removeEditAlert,
      resetTaskView
    ]
  );
};
