import type { FirstLoginJoyrideData } from './useFirstLoginExperienceData.types';

import * as routeConstants from 'constants/Routes';
import * as joyrideConstants from '../firstLoginJoyride.constants';
import * as helpers from './useFirstLoginExperienceConfig.helpers';

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useFirstLoginExperienceConfig = ({
  localState,
  reduxState
}: {
  localState: FirstLoginJoyrideData['localState'];
  reduxState: FirstLoginJoyrideData['reduxState'];
}) => {
  const location = useLocation();

  const cardsList = useMemo(() => {
    if (localState.hasManageOrgProfilePermission) {
      if (reduxState.isOrgProfileSaved || localState.hasBeenRedirectedToAdmin) {
        // filtered out for management hub since left panel is not visible there
        if (
          location.pathname.startsWith(routeConstants.sources) ||
          location.pathname.startsWith(routeConstants.managementHub)
        ) {
          return joyrideConstants.FIRST_LOGIN_JOYRIDE.BASE_ADMIN;
        }
        // Show tooltips for Org Admin.
        return joyrideConstants.FIRST_LOGIN_JOYRIDE.ADMIN;
      }

      return joyrideConstants.FIRST_LOGIN_JOYRIDE.ADMIN_NO_PROFILE;
    }

    // Show tooltips for a Generic User.
    return joyrideConstants.FIRST_LOGIN_JOYRIDE.GENERIC_USER;
  }, [localState, reduxState, location.pathname]);

  const shouldRunJoyride = useMemo(() => helpers.checkConditions({ localState, reduxState }), [
    localState,
    reduxState
  ]);

  return {
    cardsList: cardsList.sort((a, b) => a.order - b.order),
    shouldRunJoyride
  };
};
