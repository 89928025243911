import {
  CHANGE_CURRENT_STEP,
  CHANGE_ORG_PROFILE,
  RECEIVE_PRODUCT_SERVICES,
  RECEIVE_SUGGESTED_COMPANIES,
  REJECT_PRODUCT_SERVICES,
  REJECT_SUGGESTED_COMPANIES,
  REQUEST_PRODUCT_SERVICES,
  REQUEST_SUGGESTED_COMPANIES,
  RESET_SUGGESTED_COMPANIES,
  REQUEST_ORGANIZATION_PROFILE,
  RECEIVE_ORGANIZATION_PROFILE
} from './organizationProfile.actions';

export const INITIAL_STATE = {
  isFetching: false,
  isReady: false,
  orgProfile: {
    name: '',
    website: '',
    thoroughfare: '',
    premise: '',
    locality: '',
    administrativeArea: '',
    country: '',
    postalCode: '',
    primaryJurisdiction: null,
    jurisdictions: [],
    proposedBusinesses: {},
    businesses: {},
    regulators: {}
  },
  currentStep: 0,
  productServices: {
    isFetching: false,
    results: []
  },
  suggestedCompanies: {
    isFetching: false,
    results: []
  },
  orgProfileSaved: false
};

export const organizationProfile = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_ORG_PROFILE: {
      return {
        ...state,
        orgProfile: action.payload.orgProfile
      };
    }

    case CHANGE_CURRENT_STEP: {
      return {
        ...state,
        currentStep: action.payload.step
      };
    }

    case REQUEST_SUGGESTED_COMPANIES: {
      return {
        ...state,
        suggestedCompanies: {
          ...state.suggestedCompanies,
          isFetching: true
        }
      };
    }

    case RECEIVE_SUGGESTED_COMPANIES: {
      return {
        ...state,
        suggestedCompanies: {
          ...state.suggestedCompanies,
          results: action.payload.companies,
          isFetching: false
        }
      };
    }

    case REJECT_SUGGESTED_COMPANIES: {
      return {
        ...state,
        suggestedCompanies: {
          ...state.suggestedCompanies,
          isFetching: false
        }
      };
    }

    case RESET_SUGGESTED_COMPANIES: {
      return {
        ...state,
        suggestedCompanies: INITIAL_STATE.suggestedCompanies
      };
    }

    case REQUEST_PRODUCT_SERVICES: {
      return {
        ...state,
        productServices: {
          ...state.productServices,
          isFetching: true
        }
      };
    }

    case RECEIVE_PRODUCT_SERVICES: {
      return {
        ...state,
        productServices: {
          ...state.productServices,
          results: action.payload.productServices,
          isFetching: false
        }
      };
    }

    case REJECT_PRODUCT_SERVICES: {
      return {
        ...state,
        productServices: {
          ...state.productServices,
          isFetching: false
        }
      };
    }

    case REQUEST_ORGANIZATION_PROFILE: {
      return {
        ...state,
        isFetching: true
      };
    }

    case RECEIVE_ORGANIZATION_PROFILE: {
      const { orgProfile, orgProfileSaved } = action.payload;

      return {
        ...state,
        orgProfile,
        orgProfileSaved,
        isFetching: false,
        isReady: true
      };
    }

    default: {
      return state;
    }
  }
};
