import type { WorkflowDiagramToolData } from './useWorkflowDiagramToolData.types';

import { useMemo, useRef, useState } from 'react';
import { useEdgesState, useNodesState } from 'reactflow';

export const useWorkflowDiagramToolData = (): WorkflowDiagramToolData => {
  const diagramRef = useRef<HTMLDivElement>(null);

  const refs: WorkflowDiagramToolData['refs'] = {
    diagramRef
  };

  const [diagramInstance, setDiagramInstance] = useState<
    WorkflowDiagramToolData['localState']['diagramInstance']
  >(null);
  const [isReadyForPrint, setIsReadyForPrint] = useState<
    WorkflowDiagramToolData['localState']['isReadyForPrint']
  >(false);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const localState: WorkflowDiagramToolData['localState'] = {
    diagramInstance,
    nodes,
    edges,
    isReadyForPrint
  };

  const localActions: WorkflowDiagramToolData['localActions'] = useMemo(
    () => ({
      setDiagramInstance,
      setNodes,
      setEdges,
      setIsReadyForPrint,
      onNodesChange,
      onEdgesChange
    }),
    [onEdgesChange, onNodesChange, setEdges, setNodes]
  );

  return {
    refs,
    localState,
    localActions
  };
};
