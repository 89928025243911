import { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Loader, LOADER_LEGACY_TYPES } from 'common';
import { getErrorAlertMessage } from 'utils/errors';
import './_loading-error-indicator.scss';

export const TEST_IDS = {
  CONTAINER: 'loading-error-indicator-container-test-id',
  LOADER: 'loading-error-indicator-spinner-test-id',
  ERROR: 'loading-error-indicator-error-test-id'
};

const LoadingErrorIndicator = ({ isLoading, error, loaderProps, className, dataTestId }) => {
  const errorAlert = useMemo(() => getErrorAlertMessage(error), [error]);

  if (!(isLoading || errorAlert)) {
    return null;
  }

  return (
    <div
      data-testid={dataTestId || TEST_IDS.CONTAINER}
      className={classnames(className, 'loading_error_indicator__container')}
    >
      <Loader dataTestId={TEST_IDS.LOADER} {...loaderProps} />
      {errorAlert && (
        <div data-testid={TEST_IDS.ERROR} className="loading_error_indicator__alert-wrapper">
          <h3 className="loading_error_indicator__alert__title">{errorAlert}</h3>
          <p className="loading_error_indicator__alert__content">
            Please retry your search or refresh the page and we will try again.
          </p>
        </div>
      )}
    </div>
  );
};

export default LoadingErrorIndicator;

LoadingErrorIndicator.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.shape({
    type: PropTypes.string,
    status: PropTypes.number,
    message: PropTypes.string
  }),
  loaderProps: PropTypes.shape({
    legacy: PropTypes.bool,
    containerClassName: PropTypes.string,
    spinnerClassName: PropTypes.string,
    legacyType: PropTypes.oneOf(Object.values(LOADER_LEGACY_TYPES))
  }),
  className: PropTypes.string,
  dataTestId: PropTypes.string
};

LoadingErrorIndicator.defaultProps = {
  isLoading: false,
  error: null,
  loaderProps: {
    legacy: true,
    containerClassName: '',
    spinnerClassName: '',
    legacyType: LOADER_LEGACY_TYPES.LIGHT
  },
  className: '',
  dataTestId: ''
};
