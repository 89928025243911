import type { DocumentsResponse } from 'shared/features/documents/documents.types';
import type { DocumentTimelineItem } from '../DocumentTimeline.types';
import type { DocumentTimelineData } from './useDocumentTimelineData.types';

import lodash from 'lodash';
import * as constants from '../DocumentTimeline.constants';
import * as uiLib from '@compliance.ai/web-components';
import * as env from 'shared/config';

import pluralize from 'pluralize';

export const getTimePassedFromDate = (documentDate: string): string => {
  const daysDiff = uiLib.getDiffBetweenDates({
    dateA: new Date(),
    dateB: documentDate,
    unitOfTime: uiLib.DATE_UNITS.DAY
  });

  const monthsDiff = uiLib.getDiffBetweenDates({
    dateA: new Date(),
    dateB: documentDate,
    unitOfTime: uiLib.DATE_UNITS.MONTH
  });

  const yearsDiff = uiLib.getDiffBetweenDates({
    dateA: new Date(),
    dateB: documentDate,
    unitOfTime: uiLib.DATE_UNITS.YEAR
  });

  if (daysDiff < 0) {
    return 'Upcoming';
  }

  if (daysDiff === 0) {
    return 'Today';
  }

  if (daysDiff > 0 && monthsDiff === 0) {
    return `${daysDiff} ${pluralize('Day', daysDiff)}`;
  }

  if (monthsDiff > 0 && yearsDiff === 0) {
    return `${monthsDiff} ${pluralize('Month', monthsDiff)}`;
  }

  return `${yearsDiff} ${pluralize('Year', yearsDiff)}`;
};

export const formatDocumentGroups = (
  response: DocumentsResponse
): Record<DocumentTimelineItem['timePassedSinceDate'], DocumentTimelineItem[]> => {
  const documentsForTimeline = response.documents.flatMap(document => {
    return Object.values(constants.DOCUMENT_TIMELINE_SORT_OPTION).flatMap(sortOption => {
      if (lodash.get(document, sortOption)) {
        return [
          {
            document: document,
            sortDate: lodash.get(document, sortOption),
            sortedBy: sortOption,
            timePassedSinceDate: getTimePassedFromDate(lodash.get(document, sortOption)),
            isDiffable: Boolean(
              document.has_sentences &&
                sortOption === constants.DOCUMENT_TIMELINE_SORT_OPTION.PUBLICATION_DATE &&
                !constants.BLACKLISTED_FOR_DIFFING_CATEGORIES.includes(document.cai_category_name)
            )
          }
        ];
      }

      return [];
    });
  });

  const sortedDocumentsForTimeline = lodash
    .sortBy(documentsForTimeline, 'sortDate')
    .reverse()
    .map((documentTimelineItem, i) => {
      return {
        ...documentTimelineItem,

        /**
         *
         * 'Today' means that it's a document version that is the most recent for today
         *
         */
        timePassedSinceDate: i === 0 ? 'Today' : documentTimelineItem.timePassedSinceDate
      };
    });

  return lodash.groupBy(sortedDocumentsForTimeline, 'timePassedSinceDate');
};

export const formatData = ({ reduxState }: { reduxState: DocumentTimelineData['reduxState'] }) => (
  response: DocumentsResponse
): {
  groups: Record<DocumentTimelineItem['timePassedSinceDate'], DocumentTimelineItem[]>;
  diffableItems: DocumentTimelineItem[];
  isDiffingEnabled: boolean;
} => {
  const groups = formatDocumentGroups(response);
  const diffableItems = Object.values(groups).flatMap(documentTimelineItems => {
    return documentTimelineItems.flatMap(documentTimelineItem => {
      if (documentTimelineItem.isDiffable) {
        return [documentTimelineItem];
      }

      return [];
    });
  });

  const isDiffingEnabled = Boolean(
    env.diffingEnabled && reduxState.isAuthenticated && diffableItems.length > 1
  );

  return {
    groups,
    diffableItems,
    isDiffingEnabled
  };
};
