import PropTypes from 'prop-types';
import classnames from 'classnames';

import * as uiLib from '@compliance.ai/web-components';

import './_suggestions-menu.scss';

const SuggestionsMenu = ({ suggestions, className, onSelect, onClickOutside }) => {
  return (
    <uiLib.ClickAwayListener onClickAway={onClickOutside} mouseEvent={'onMouseDown'}>
      <div className={classnames(className, 'suggestions-menu__container')}>
        {suggestions.map(suggestion => (
          <div
            key={suggestion.key}
            onClick={e => onSelect(suggestion, e)}
            className="suggestions-menu__item"
          >
            {suggestion.name}
          </div>
        ))}
      </div>
    </uiLib.ClickAwayListener>
  );
};

export default SuggestionsMenu;

SuggestionsMenu.propTypes = {
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      item: PropTypes.object
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  onClickOutside: PropTypes.func.isRequired,
  className: PropTypes.string
};
