import { createSelector } from '@reduxjs/toolkit';
import lodash from 'lodash';
import { AGENCY_TYPE } from './agencies.constants';
import { byName, formatAgenciesOptions } from './agencies.helpers';
import { alphabetically } from 'utils/sort';
import {
  getAllCountries,
  getAllJurisdictionsExceptCountries,
  getSubregions
} from 'shared/features/jurisdictions/jurisdictions.selectors';

/**
 *
 * Agencies reducer selectors
 */
export const getAgenciesReducer = state => state.agencies;

/**
 *
 *  Agencies common selectors
 *
 */
export const getAgenciesReadyStatus = createSelector(getAgenciesReducer, agenciesReducer => {
  return agenciesReducer.isReady;
});

/**
 *
 * Regular agencies selectors
 */
export const getAgenciesList = createSelector(getAgenciesReducer, agenciesReducer =>
  lodash.orderBy(agenciesReducer.items, byName)
);

export const getAgenciesOptions = createSelector(getAgenciesList, agencies => {
  return formatAgenciesOptions(agencies);
});

export const getAgenciesOptionsByKey = createSelector(
  [getAgenciesOptions, (state, valueKey) => valueKey],
  (options, valueKey) => {
    return options.map(option => {
      return {
        ...option,
        value: option[valueKey]
      };
    });
  }
);

export const getAgenciesObj = createSelector(getAgenciesList, agencies => {
  return agencies.reduce((obj, agency) => {
    obj[agency.id] = agency;
    return obj;
  }, {});
});

export const getAgenciesIdsToShortNamesMap = createSelector(getAgenciesList, agencies => {
  return agencies.reduce((obj, agency) => {
    obj[agency.id] = agency.short_name;
    return obj;
  }, {});
});

export const getAgenciesShortNamesToIdsMap = createSelector(getAgenciesList, agencies => {
  return agencies.reduce((obj, agency) => {
    obj[agency.short_name] = agency.id;
    return obj;
  }, {});
});

export const getAgenciesMappedByCountries = createSelector(
  getAgenciesList,
  getAllCountries,
  getAllJurisdictionsExceptCountries,
  (agencies, allCountries, allSubregions) => {
    return allCountries.reduce((agenciesMappedByCountries, country) => {
      const countrySubregions = allSubregions.filter(({ parent_id }) => parent_id === country.id);
      const countryAndSubregionsIds = [country.id, ...countrySubregions.map(({ id }) => id)];

      const countryOrSubregionAgencies = agencies.filter(({ jurisdiction_id }) => {
        return countryAndSubregionsIds.includes(jurisdiction_id);
      });

      const updatedCountryAgencies = [
        ...(agenciesMappedByCountries[country.id] || []),
        ...countryOrSubregionAgencies
      ];

      return {
        ...agenciesMappedByCountries,
        [country.id]: lodash.uniqBy(updatedCountryAgencies, 'id')
      };
    }, {});
  }
);

export const getPopularAgencies = createSelector(getAgenciesList, agenciesList =>
  agenciesList.filter(agency => agency.popular)
);

export const getCountryAgencies = countryId =>
  createSelector(getAgenciesList, agencies => {
    return agencies.filter(agency => {
      return agency.type !== AGENCY_TYPE.STATE && agency.jurisdiction_id === countryId;
    });
  });

export const getPopularCountryAgencies = countryId =>
  createSelector(getCountryAgencies(countryId), countryAgencies => {
    return countryAgencies.filter(agency => agency.popular);
  });

export const getNonPopularCountryAgencies = countryId =>
  createSelector(getCountryAgencies(countryId), countryAgencies => {
    return countryAgencies.filter(agency => !agency.popular);
  });

export const getCountrySubregionsAgencies = countryId =>
  createSelector(getSubregions(countryId), getAgenciesList, (subregions, agencies) => {
    return subregions
      .reduce((subregionAgencies, subregion) => {
        const filteredSubregionAgencies = agencies.filter(
          agency => agency.jurisdiction_id === subregion.id
        );
        return [...subregionAgencies, ...filteredSubregionAgencies];
      }, [])
      .sort(alphabetically);
  });

export const getCountrySubregionsAgenciesIds = countryId =>
  createSelector(getCountrySubregionsAgencies(countryId), countrySubregionsAgencies => {
    return countrySubregionsAgencies.map(({ id }) => id);
  });

export const getSubregionAgencies = ({ countryId, subregionId }) =>
  createSelector(getCountrySubregionsAgencies(countryId), subregionsAgencies => {
    return subregionsAgencies.filter(({ jurisdiction_id }) => jurisdiction_id === subregionId);
  });

export const getSubregionAgenciesIds = ({ countryId, subregionId }) =>
  createSelector(getSubregionAgencies({ countryId, subregionId }), subregionAgencies => {
    return subregionAgencies.map(({ id }) => id);
  });
