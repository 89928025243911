import type { PlainTextData } from './usePlainTextData.types';

import { ASYNC_DATA_CONFIG } from '../PlainText.constants';

export const INITIAL_STATE: PlainTextData['localState'] = {
  asyncIdsToNamesMaps: Object.keys(ASYNC_DATA_CONFIG).reduce(
    (initialValues, advancedSearchKey) => ({
      ...initialValues,
      [advancedSearchKey]: []
    }),
    {} as PlainTextData['localState']['asyncIdsToNamesMaps']
  ),
  plainText: '',
  isPlainTextSet: false
};
