import type { DocumentTaskFromResponse } from 'shared/features/workflow/workflow.types';
import type { OrganizationMemberFromResponse } from 'shared/features/organizations/organizations.types';

import * as emptyValue from 'constants/EmptyValue';

export const formatAssignee = ({
  documentTask,
  orgMembers
}: {
  documentTask: DocumentTaskFromResponse;
  orgMembers: OrganizationMemberFromResponse[];
}) => {
  const email = orgMembers.find(({ id }) => id === documentTask.assignee)?.email;

  return email ?? emptyValue.EMPTY_VALUE.DASH;
};
