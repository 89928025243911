import { LabeledField } from '../index';
import { TextField, Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import DefaultModal from '../Modal';
import { useCorporateLoginData, useCorporateLoginHandlers } from './hooks';
import PropTypes from 'prop-types';
import LoadingOverlay from '../LoadingOverlay';
import './_corporate-login.scss';

export const TEST_ID = {
  BUTTON: 'corporate-login-button-continue',
  CONTAINER: 'corporate-modal-content-container',
  MODAL_BUTTON: 'corporate-modal-button',
  MODAL: 'corporate-modal'
};

export const PLACEHOLDER = 'Enter your Corporate Email here';

export const CorporateLogin = ({
  activationUrl,
  orgInvitationToken,
  onError,
  buttonText,
  showWindow,
  isLegacy,
  isDisabled
}) => {
  const { localState, localActions } = useCorporateLoginData();

  const {
    handleLegacyCorporateLogin,
    handleCorporateLogin,
    handleButtonClick,
    handleEmailChange,
    handleModalClose
  } = useCorporateLoginHandlers({
    props: {
      showWindow,
      activationUrl,
      orgInvitationToken,
      onError
    },
    localState,
    localActions
  });

  return (
    <>
      <Button
        type={BUTTON_TYPES.SECONDARY}
        onClick={handleButtonClick}
        dataTestId={TEST_ID.BUTTON}
        isDisabled={isDisabled}
        className="corporate-login__button"
      >
        <VpnKeyIcon className="corporate-login__key-icon" />
        {buttonText}
      </Button>
      <DefaultModal
        open={localState.isOpen}
        onClose={handleModalClose}
        dataTestId={TEST_ID.MODAL}
        childContainerClassName="corporate-login__modal-children"
        title="Corporate Login"
        maxWidth="440px"
        padding="300px 0px 150px 0px"
      >
        <LoadingOverlay isLoading={localState.isLoading}>
          <div className="corporate-login__content-container" data-testid={TEST_ID.CONTAINER}>
            <div className="corporate-login__email-input">
              <LabeledField label="Corporate Email">
                <TextField
                  value={localState.email}
                  onChange={handleEmailChange}
                  placeholder={PLACEHOLDER}
                />
              </LabeledField>
            </div>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={isLegacy ? handleLegacyCorporateLogin : handleCorporateLogin}
              dataTestId={TEST_ID.MODAL_BUTTON}
              className="corporate-login__login-button"
            >
              Continue
            </Button>
          </div>
        </LoadingOverlay>
      </DefaultModal>
    </>
  );
};

CorporateLogin.propTypes = {
  buttonText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  activationUrl: PropTypes.bool,
  orgInvitationToken: PropTypes.string,
  onError: PropTypes.func,
  showWindow: PropTypes.func,
  isLegacy: PropTypes.bool,
  isDisabled: PropTypes.bool
};
CorporateLogin.defaultProps = {
  buttonText: 'Corporate Login',
  activationUrl: false,
  orgInvitationToken: '',
  onError: () => {},
  showWindow: () => {},
  isLegacy: false,
  isDisabled: false
};
