import { SELECT_ACTION_NAMES } from 'common/LabelsTable/elements/LabelEditModal/LabelEditModal.constants';
import { useCallback } from 'react';

export const useLabelsMoveModalHandlers = ({ props: { updateLabel } }) => {
  const handleOptionChange = useCallback(
    option => {
      if (option) {
        updateLabel(option);
      }
    },
    [updateLabel]
  );

  const handleInputChange = useCallback(
    (inputString, action) => {
      if (action.action === SELECT_ACTION_NAMES.INPUT_CHANGE) {
        updateLabel(inputString);
      }
    },
    [updateLabel]
  );

  return {
    handleOptionChange,
    handleInputChange
  };
};
