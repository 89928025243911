import type { AddRelatedDocumentsModalProps } from '../AddRelatedDocumentsModal.types';
import type { AddRelatedDocumentsModalData } from './useAddRelatedDocumentsModalData.types';

import * as documentsApi from 'shared/features/documents/documents.api';
import * as relatedDocsConstants from 'constants/RelatedDocuments';
import * as queryKeys from 'constants/QueryKeys';
import * as errorUtils from 'utils/errors';

import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useAddRelatedDocumentsModalMutations = ({
  props,
  localState
}: {
  props: Pick<AddRelatedDocumentsModalProps, 'documentId' | 'onClose'>;
  localState: AddRelatedDocumentsModalData['localState'];
}) => {
  const queryClient = useQueryClient();

  const addRelatedDocuments = useMutation({
    mutationKey: [],
    mutationFn: () =>
      documentsApi.api_addOrganizationRelatedDocuments(props.documentId, {
        [relatedDocsConstants.RELATED_DOCUMENT_REQUEST_KEYS.RELATED_DOC_IDS]:
          localState.relatedDocIds
      }),
    onSettled: props.onClose,
    onError: errorUtils.logReactQueryError,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.DOCUMENTS][
          queryKeys.QUERY_SECTIONS.DOCUMENT_DETAILS
        ](Number(props.documentId))
      });
    }
  });

  return {
    addRelatedDocuments
  };
};
