import type * as reducerTypes from './orgDocumentsMetadata.reducer.types';

import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import * as constants from './orgDocumentsMetadata.constants';
import * as reducerHelpers from './orgDocumentsMetadata.reducer.helpers';
import * as actions from './orgDocumentsMetadata.actions';

export const INITIAL_STATE: reducerTypes.OrgDocumentsMetadataState = {
  importId: null,
  activeStepKey: constants.UPLOAD_STEPS_KEYS.UPLOAD_DATA,
  activeMappingsTabKey: constants.MAPPINGS_TAB_KEYS.DOCS_FIELD_MAPPING,
  isNextStepDisabled: true,
  isDraft: false,
  metadata: {},
  defaultFields: [],
  filenames: [],
  file: null,
  selectedBucketIds: [],
  attributeTagMaps: [
    {
      attribute: null,
      tags: [],
      tagValues: {}
    }
  ],
  confirmationConfig: null,
  nextStepTrigger: false,
  prevStepTrigger: false,
  selectedCAICapabilities: reducerHelpers.getInitialCAICapabilitiesMap()
};

export const reducer = createReducer(INITIAL_STATE, builder => {
  builder.addCase(actions.setImportId.type, (state, action) => {
    if (actions.setImportId.match(action)) {
      state.importId = action.payload;
    }
  });

  builder.addCase(actions.setActiveStepKey.type, (state, action) => {
    if (actions.setActiveStepKey.match(action)) {
      state.activeStepKey = action.payload;
    }
  });

  builder.addCase(actions.setActiveMappingsTabKey.type, (state, action) => {
    if (actions.setActiveMappingsTabKey.match(action)) {
      state.activeMappingsTabKey = action.payload;
    }
  });

  builder.addCase(actions.setIsNextStepDisabled.type, (state, action) => {
    if (actions.setIsNextStepDisabled.match(action)) {
      state.isNextStepDisabled = action.payload;
    }
  });

  builder.addCase(actions.setIsDraft.type, (state, action) => {
    if (actions.setIsDraft.match(action)) {
      state.isDraft = action.payload;
    }
  });

  builder.addCase(actions.setMetadata.type, (state, action) => {
    if (actions.setMetadata.match(action)) {
      state.metadata = action.payload;
    }
  });

  builder.addCase(actions.setMetadataField.type, (state, action) => {
    if (actions.setMetadataField.match(action)) {
      Object.entries(state.metadata).forEach(([_, metadata]) => {
        metadata[action.payload.key].mappedCaiDocField = action.payload.mappedCaiDocField;
      });

      state.defaultFields = state.defaultFields.filter(
        field => field.key !== action.payload.mappedCaiDocField
      );
    }
  });

  builder.addCase(actions.setConfirmation.type, (state, action) => {
    if (actions.setConfirmation.match(action)) {
      state.confirmationConfig = action.payload;
    }
  });

  builder.addCase(actions.setMetadataForFiles.type, (state, action) => {
    if (actions.setMetadataForFiles.match(action)) {
      Object.entries(action.payload).forEach(([fileName, metadata]) => {
        state.metadata[fileName] = metadata;
      });
    }
  });

  builder.addCase(actions.setFileNames.type, (state, action) => {
    if (actions.setFileNames.match(action)) {
      state.filenames = action.payload;
    }
  });

  builder.addCase(actions.setSelectedCAICapabilities.type, (state, action) => {
    if (actions.setSelectedCAICapabilities.match(action)) {
      state.selectedCAICapabilities = action.payload;
    }
  });

  builder.addCase(actions.setNextStepTrigger.type, (state, action) => {
    if (actions.setNextStepTrigger.match(action)) {
      state.nextStepTrigger = !state.nextStepTrigger;
    }
  });

  builder.addCase(actions.setPrevStepTrigger.type, (state, action) => {
    if (actions.setPrevStepTrigger.match(action)) {
      state.prevStepTrigger = !state.prevStepTrigger;
    }
  });

  builder.addCase(actions.setDefaultFields.type, (state, action) => {
    if (actions.setDefaultFields.match(action)) {
      state.defaultFields = action.payload;
    }
  });

  builder.addCase(actions.setBucketIds.type, (state, action) => {
    if (actions.setBucketIds.match(action)) {
      state.selectedBucketIds = action.payload;
    }
  });

  builder.addCase(actions.setMetadataAttributeTagMapAttribute.type, (state, action) => {
    if (actions.setMetadataAttributeTagMapAttribute.match(action)) {
      state.attributeTagMaps = state.attributeTagMaps.map((attributeTagMap, index) => {
        if (index !== action.payload.index) {
          return attributeTagMap;
        }
        return {
          attribute: action.payload.attribute,
          tags: [],
          tagValues: {}
        };
      });
    }
  });

  builder.addCase(actions.setMetadataAttributeTagMapTags.type, (state, action) => {
    if (actions.setMetadataAttributeTagMapTags.match(action)) {
      state.attributeTagMaps = state.attributeTagMaps.map((attributeTagMap, index) => {
        if (index !== action.payload.index) {
          return attributeTagMap;
        }
        return {
          ...attributeTagMap,
          tags: action.payload.tags,
          tagValues: Object.fromEntries(
            Object.entries(attributeTagMap.tagValues).map(
              ([orgDocumentMetadataValue, attributeTagMapValues]) => {
                return [
                  orgDocumentMetadataValue,
                  Object.fromEntries(
                    Object.entries(attributeTagMapValues).filter(([key]) =>
                      action.payload.tags.includes(key as constants.CAI_METADATA_TAG_VALUES_KEYS)
                    )
                  )
                ];
              }
            )
          )
        };
      });
    }
  });

  builder.addCase(actions.setMetadataAttributeTagMapTagValue.type, (state, action) => {
    if (actions.setMetadataAttributeTagMapTagValue.match(action)) {
      state.attributeTagMaps = state.attributeTagMaps.map((attributeTagMap, index) => {
        if (index !== action.payload.index) {
          return attributeTagMap;
        }
        return {
          ...attributeTagMap,
          tagValues: {
            ...attributeTagMap.tagValues,
            [action.payload.data]: {
              ...attributeTagMap.tagValues[action.payload.data],
              [action.payload.tag]: action.payload.tagValue
            }
          }
        };
      });
    }
  });

  builder.addCase(actions.addNewMetadataAttributeTagMap.type, (state, action) => {
    if (actions.addNewMetadataAttributeTagMap.match(action)) {
      state.attributeTagMaps = [
        ...state.attributeTagMaps,
        {
          attribute: null,
          tags: [],
          tagValues: {}
        }
      ];
    }
  });

  builder.addCase(actions.removeMetadataAttributeTagMap.type, (state, action) => {
    if (actions.removeMetadataAttributeTagMap.match(action)) {
      state.attributeTagMaps = state.attributeTagMaps.filter(
        (attributeTagMap, index) => index !== action.payload.index
      );
    }
  });

  builder.addCase(actions.setInitialState.type, (state, action) => {
    if (actions.setInitialState.match(action)) {
      state.importId = INITIAL_STATE.importId;
      state.activeStepKey = INITIAL_STATE.activeStepKey;
      state.isNextStepDisabled = INITIAL_STATE.isNextStepDisabled;
      state.metadata = INITIAL_STATE.metadata;
      state.confirmationConfig = INITIAL_STATE.confirmationConfig;
      state.selectedCAICapabilities = INITIAL_STATE.selectedCAICapabilities;
      state.activeMappingsTabKey = INITIAL_STATE.activeMappingsTabKey;
      state.prevStepTrigger = INITIAL_STATE.nextStepTrigger;
      state.nextStepTrigger = INITIAL_STATE.nextStepTrigger;
      state.defaultFields = INITIAL_STATE.defaultFields;
      state.attributeTagMaps = INITIAL_STATE.attributeTagMaps;
      state.file = INITIAL_STATE.file;
      state.filenames = INITIAL_STATE.filenames;
      state.selectedBucketIds = INITIAL_STATE.selectedBucketIds;
    }
  });
});
