import { defaultCFRID } from 'shared/config';
import lodash from 'lodash';

export const defaultJurisdictions = ['US', 'UK'];

/*
FIXME: As of 01/12, defaultStateAgencies are being used through out the application in following way:

(1) When new user signs up for the application they are presented with all 50 states listed below

(2) When the user selects to follow states with a parent_id (ie. avaliable with data on the app refered to as 'id' below)
    they are combined with the federal agencies ids they've selected and stored in the user_agency table as was the norm

(3) When the user selects a state without a parent_id (ie. no data on the app) this relation is saved to the user_follow_entity
    table with the entity_id being the generic ES id provided on the jurisdictions table in ES (note - this has no relation to the
    parent_id mentioned above or the admin_code_id need for the state navigator feature)

Ideally we'd remove all the hardcoded data provided on this file and move it to a table in the backend with ES. For the defaultFederalAgencies above
this can already be done, as it is mostly used for back up in case the ES agencies table happens to be missing some value needed in the frontend. For the
defaultStateAgencies below however, this will require some updating of the jurisdictions table in ES. As of today, the table is missing: parent_ids, a
field for state, a field for the admin_code_id, and a field for fin_related_doc_ids.

If these are not added somewhere on the table, the below will be necessary to keep the frontend functioning properly.

In addition, to help standardize the state relations even further all selected states should be placed in to the user_follow_entity table. Once this is done
all the places where the agencies store is used needs to have the states store added to get access to the user's followed states.

*/
/*
  XXX: Temp flag to use when state data is needed on integration site but not production
  let HIDDEN_STATE_ID = null;
  let HIDDEN_STATE_CODE_ID = null;

  // STATE_DATA_ENABLED was removed.
  if (import.meta.env.STATE_DATA_ENABLED) {
    HIDDEN_STATE_ID = ;
    HIDDEN_STATE_CODE_ID = ;
}
*/

// If adding a new jursidiction here, please add them to constants/Jurisidctions
export const defaultStateAgencies = [
  // XXX update when these are added to the system

  /* n.b - to be added when data is avaliable
  {
    name: 'US-Code',
    id: null,
    short_name: 'US-Code',
    state: 'United States',
    type: 'united states',
    admin_code_id: null,
    statute_id: null,
    jurisdiction_id: null,
    following: null,
    country: 'US'
  },*/
  {
    name: 'California',
    id: 100003,
    admin_code_id: 1468291,
    statute_id: 3079392,
    jurisdiction_id: 6,
    short_name: 'US-CA',
    state: 'CA',
    type: 'california',
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Delaware',
    type: 'delaware',
    short_name: 'US-DE',
    state: 'DE',
    id: 200000,
    admin_code_id: 1616179,
    statute_id: 5671254,
    jurisdiction_id: 10,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Texas',
    type: 'texas',
    short_name: 'US-TX',
    state: 'TX',
    id: 300000,
    admin_code_id: 1509185,
    statute_id: 3043229,
    jurisdiction_id: 48,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Connecticut',
    id: 800000,
    short_name: 'US-CT',
    state: 'CT',
    type: 'connecticut',
    admin_code_id: 3039350,
    statute_id: 1468721,
    jurisdiction_id: 9,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Washington',
    type: 'washington',
    short_name: 'US-WA',
    state: 'WA',
    id: 1100000,
    admin_code_id: 1596970,
    jurisdiction_id: 53,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Illinois',
    type: 'illinois',
    short_name: 'US-IL',
    state: 'IL',
    id: 1200000,
    admin_code_id: 1597052,
    statute_id: 3036109,
    jurisdiction_id: 17,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Indiana',
    type: 'indiana',
    short_name: 'US-IN',
    state: 'IN',
    id: 1700000,
    admin_code_id: 1680478,
    statute_id: 5671304,
    jurisdiction_id: 18,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Pennsylvania',
    type: 'pennsylvania',
    short_name: 'US-PA',
    state: 'PA',
    id: 1300000,
    admin_code_id: 2390290,
    statute_id: 3148117,
    jurisdiction_id: 42,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Michigan',
    type: 'michigan',
    short_name: 'US-MI',
    state: 'MI',
    id: 2200000,
    admin_code_id: 2136292,
    statute_id: 5671425,
    jurisdiction_id: 26,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Minnesota',
    type: 'minnesota',
    short_name: 'US-MN',
    state: 'MN',
    id: 2500000,
    admin_code_id: 2169882,
    statute_id: 5671724,
    jurisdiction_id: 27,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'New Jersey',
    type: 'new_jersey',
    short_name: 'US-NJ',
    state: 'NJ',
    id: null,
    admin_code_id: null,
    jurisdiction_id: 34,
    active: null,
    external_link: 'http://www.lexisnexis.com/hottopics/njcode/',
    following: null,
    country: 'US'
  },
  {
    name: 'Ohio',
    type: 'ohio',
    short_name: 'US-OH',
    state: 'OH',
    id: 4400000,
    admin_code_id: 3280261,
    statute_id: 3280252,
    jurisdiction_id: 39,
    active: true,
    external_link: 'http://codes.ohio.gov/oac/',
    following: null,
    country: 'US'
  },
  {
    name: 'Tennessee',
    type: 'tennessee',
    short_name: 'US-TN',
    state: 'TN',
    id: 2000000,
    admin_code_id: 2084784,
    jurisdiction_id: 47,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Wisconsin',
    type: 'wisconsin',
    short_name: 'US-WI',
    state: 'WI',
    id: 2100000,
    admin_code_id: 2085368,
    jurisdiction_id: 55,
    active: true,
    following: null,
    country: 'US'
  },
  {
    name: 'Washington DC',
    type: 'washington_dc',
    short_name: 'US-DC',
    state: 'DC',
    id: 2700000,
    admin_code_id: 2214985,
    jurisdiction_id: 11,
    active: true,
    following: null,
    country: 'US'
  }
];
if (window.location.host.includes('integration') || window.location.host.includes('localhost')) {
  defaultStateAgencies.push({
    name: 'Alabama',
    type: 'alabama',
    short_name: 'US-AL',
    state: 'AL',
    id: 3500000,
    admin_code_id: 13633059,
    statute_id: 2428530,
    jurisdiction_id: 1,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Arizona',
    type: 'arizona',
    short_name: 'US-AZ',
    state: 'AZ',
    id: 3300000,
    admin_code_id: 14025145,
    statute_id: 12252217,
    jurisdiction_id: 4,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Florida',
    type: 'florida',
    short_name: 'US-FL',
    state: 'FL',
    id: 500000,
    admin_code_id: 1377737,
    statute_id: 14179523,
    jurisdiction_id: 12,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Georgia',
    type: 'georgia',
    short_name: 'US-GA',
    state: 'GA',
    id: 4500000,
    admin_code_id: 10455761,
    statute_id: 3352568,
    jurisdiction_id: 13,
    active: true,
    external_link: 'http://rules.sos.ga.gov/gac/',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Guam',
    id: 8000001,
    admin_code_id: 4851940,
    jurisdiction_id: 57,
    short_name: 'US-GU',
    state: 'GU',
    type: 'guam',
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Hawaii',
    type: 'hawaii',
    short_name: 'US-HI',
    state: 'HI',
    id: 4100000,
    admin_code_id: 14092049,
    statute_id: 13090682,
    jurisdiction_id: 15,
    active: false,
    external_link: 'http://ltgov.hawaii.gov/the-office/administrative-rules/',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Idaho',
    type: 'idaho',
    short_name: 'US-ID',
    state: 'ID',
    id: 3200000,
    admin_code_id: 2350440,
    statute_id: 12452722,
    jurisdiction_id: 16,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Iowa',
    type: 'iowa',
    short_name: 'US-IA',
    state: 'IA',
    id: 1800000,
    admin_code_id: 2056707,
    statute_id: 12293632,
    jurisdiction_id: 19,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Kentucky',
    type: 'kentucky',
    short_name: 'US-KY',
    state: 'KY',
    id: 2600000,
    admin_code_id: 2205330,
    statute_id: 13504070,
    jurisdiction_id: 21,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Louisiana',
    type: 'louisiana',
    short_name: 'US-LA',
    state: 'LA',
    id: null,
    admin_code_id: 13013119,
    statute_id: 13075599,
    jurisdiction_id: 22,
    active: null,
    external_link: 'http://www.doa.la.gov/Pages/osr/lac/books.aspx',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Maine',
    type: 'maine',
    short_name: 'US-ME',
    state: 'ME',
    id: 3600000,
    admin_code_id: 13610217,
    statute_id: 12633186,
    jurisdiction_id: 23,
    active: null,
    external_link: 'http://www.maine.gov/sos/cec/rules/rules.html',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Montana',
    type: 'montana',
    short_name: 'US-MT',
    state: 'MT',
    id: 3100000,
    admin_code_id: 2330398,
    statute_id: 12892129,
    jurisdiction_id: 30,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Nebraska',
    type: 'nebraska',
    short_name: 'US-NE',
    state: 'NE',
    id: 2300000,
    admin_code_id: 2094078,
    statute_id: 13192847,
    jurisdiction_id: 31,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'New Hampshire',
    type: 'new_hampshire',
    short_name: 'US-NH',
    state: 'NH',
    id: 3400000,
    admin_code_id: 2351048,
    statute_id: 12633217,
    jurisdiction_id: 33,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'New York',
    type: 'new_york',
    short_name: 'US-NY',
    state: 'NY',
    id: 400000,
    admin_code_id: 1468292,
    statute_id: 13911674,
    jurisdiction_id: 36,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'North Carolina',
    type: 'north_carolina',
    short_name: 'US-NC',
    state: 'NC',
    id: 1600000,
    admin_code_id: 1658361,
    statute_id: 10878273,
    jurisdiction_id: 37,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Oklahoma',
    type: 'oklahoma',
    short_name: 'US-OK',
    state: 'OK',
    id: 2400000,
    admin_code_id: 2096122,
    statute_id: 12587636,
    jurisdiction_id: 40,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Rhode Island',
    type: 'rhode_island',
    short_name: 'US-RI',
    state: 'RI',
    id: 4200000,
    admin_code_id: 14006391,
    statute_id: 9667888,
    jurisdiction_id: 44,
    active: true,
    external_link: 'http://sos.ri.gov/rules/',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'South Carolina',
    type: 'south_carolina',
    short_name: 'US-SC',
    state: 'SC',
    id: 3000000,
    admin_code_id: 2346423,
    statute_id: 13598415,
    jurisdiction_id: 45,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Utah',
    type: 'utah',
    short_name: 'US-UT',
    state: 'UT',
    id: 1900000,
    admin_code_id: 2063009,
    statute_id: 13455737,
    jurisdiction_id: 49,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Vermont',
    type: 'vermont',
    short_name: 'US-VT',
    state: 'VT',
    id: 3700000,
    admin_code_id: 13562036,
    statute_id: 12995847,
    jurisdiction_id: 50,
    active: false,
    external_link: 'http://www.lexisnexis.com/hottopics/codeofvtrules/',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Virginia',
    type: 'virginia',
    short_name: 'US-VA',
    state: 'VA',
    id: 1500000,
    admin_code_id: 1616868,
    statute_id: 11822813,
    jurisdiction_id: 51,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Arkansas',
    type: 'arkansas',
    short_name: 'US-AR',
    state: 'AR',
    id: null,
    admin_code_id: 13726651,
    jurisdiction_id: 5,
    active: null,
    external_link: 'http://www.lexisnexis.com/hottopics/arcode/Default.asp',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Wyoming',
    type: 'wyoming',
    short_name: 'US-WY',
    state: 'WY',
    id: null,
    admin_code_id: 13791357,
    statute_id: 13629806,
    jurisdiction_id: 56,
    active: null,
    external_link: 'https://rules.wyo.gov/Search.aspx?mode=1',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Alaska',
    type: 'alaska',
    short_name: 'US-AK',
    state: 'AK',
    id: 4300000,
    statute_id: 13993058,
    admin_code_id: 13630497,
    jurisdiction_id: 2,
    active: true,
    external_link:
      'http://www.legis.state.ak.us/basis/folioproxy.asp?url=http://wwwjnu01.legis.state.ak.us/cgi-bin/folioisa.dll/aac/query=[JUMP:%27Title3Chap02%27]/doc/{@1}?firsthit',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Massachusetts',
    type: 'massachusetts',
    short_name: 'US-MA',
    state: 'MA',
    id: 600000,
    admin_code_id: 1616137,
    statute_id: 13574303,
    jurisdiction_id: 25,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Missouri',
    type: 'missouri',
    short_name: 'US-MO',
    state: 'MO',
    id: 1400000,
    admin_code_id: 1681738,
    statute_id: 13902037,
    jurisdiction_id: 29,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'West Virgina',
    type: 'west_virgina',
    short_name: 'US-WV',
    state: 'WV',
    id: 3900000,
    statute_id: 13790130,
    admin_code_id: null,
    jurisdiction_id: 54,
    active: false,
    external_link: 'http://apps.sos.wv.gov/adlaw/csr/',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Colorado',
    type: 'colorado',
    short_name: 'US-CO',
    state: 'CO',
    id: 700000,
    statute_id: 14178951,
    admin_code_id: 1405155,
    jurisdiction_id: 8,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Maryland',
    type: 'maryland',
    short_name: 'US-MD',
    state: 'MD',
    id: 4000000,
    statute_id: 11895611,
    admin_code_id: 11169645,
    jurisdiction_id: 24,
    active: false,
    external_link: 'http://www.dsd.state.md.us/COMAR/subtitle_chapters/Titles.aspx',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Nevada',
    type: 'nevada',
    short_name: 'US-NV',
    state: 'NV',
    id: 1000000,
    statute_id: 14027910,
    admin_code_id: 1482618,
    jurisdiction_id: 32,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'South Dakota',
    type: 'south_dakota',
    short_name: 'US-SD',
    state: 'SD',
    id: 2900000,
    statute_id: 13524899,
    admin_code_id: 2241826,
    jurisdiction_id: 46,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'North Dakota',
    type: 'north_dakota',
    short_name: 'US-ND',
    state: 'ND',
    id: 2800000,
    statute_id: 14018520,
    admin_code_id: 2238807,
    jurisdiction_id: 38,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Oregon',
    id: 900000,
    short_name: 'US-OR',
    state: 'OR',
    type: 'oregon',
    statute_id: 14105098,
    admin_code_id: 1471760,
    jurisdiction_id: 41,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'New Mexico',
    type: 'new_mexico',
    short_name: 'US-NM',
    state: 'NM',
    id: null,
    admin_code_id: 13120463,
    jurisdiction_id: 35,
    active: null,
    external_link: 'http://164.64.110.239/nmac/_titles.htm',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Kansas',
    type: 'kansas',
    short_name: 'US-KS',
    state: 'KS',
    id: null,
    statute_id: 14118498,
    admin_code_id: null,
    jurisdiction_id: 20,
    active: null,
    external_link: 'http://www.kssos.org/pubs/pubs_kar.aspx',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Mississippi',
    type: 'mississippi',
    short_name: 'US-MS',
    state: 'MS',
    id: 3800000,
    admin_code_id: 14175036,
    jurisdiction_id: 28,
    active: false,
    external_link: 'http://www.sos.ms.gov/adminsearch/default.aspx',
    following: null,
    country: 'US'
  });
} else {
  defaultStateAgencies.push({
    name: 'Alabama',
    type: 'alabama',
    short_name: 'US-AL',
    state: 'AL',
    id: 3500000,
    admin_code_id: 9230541,
    statute_id: 2428530,
    jurisdiction_id: 1,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Arkansas',
    type: 'arkansas',
    short_name: 'US-AR',
    state: 'AR',
    id: null,
    admin_code_id: null,
    jurisdiction_id: 5,
    active: null,
    external_link: 'http://www.lexisnexis.com/hottopics/arcode/Default.asp',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Arizona',
    type: 'arizona',
    short_name: 'US-AZ',
    state: 'AZ',
    id: 3300000,
    admin_code_id: 9256363,
    statute_id: 8945378,
    jurisdiction_id: 4,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Florida',
    type: 'florida',
    short_name: 'US-FL',
    state: 'FL',
    id: 500000,
    admin_code_id: 1377737,
    jurisdiction_id: 12,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Georgia',
    type: 'georgia',
    short_name: 'US-GA',
    state: 'GA',
    id: 4500000,
    admin_code_id: 8416364,
    statute_id: 3352568,
    jurisdiction_id: 13,
    active: true,
    external_link: 'http://rules.sos.ga.gov/gac/',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Hawaii',
    type: 'hawaii',
    short_name: 'US-HI',
    state: 'HI',
    id: 4100000,
    admin_code_id: null,
    jurisdiction_id: 15,
    active: false,
    external_link: 'http://ltgov.hawaii.gov/the-office/administrative-rules/',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Idaho',
    type: 'idaho',
    short_name: 'US-ID',
    state: 'ID',
    id: 3200000,
    admin_code_id: 2350440,
    statute_id: 8942738,
    jurisdiction_id: 16,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Iowa',
    type: 'iowa',
    short_name: 'US-IA',
    state: 'IA',
    id: 1800000,
    statute_id: 9242696,
    admin_code_id: 2056707,
    jurisdiction_id: 19,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Kentucky',
    type: 'kentucky',
    short_name: 'US-KY',
    state: 'KY',
    id: 2600000,
    admin_code_id: 2205330,
    statute_id: 8907645,
    jurisdiction_id: 21,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Louisiana',
    type: 'louisiana',
    short_name: 'US-LA',
    state: 'LA',
    id: null,
    statute_id: 8939072,
    jurisdiction_id: 22,
    active: null,
    external_link: 'http://www.doa.la.gov/Pages/osr/lac/books.aspx',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Maine',
    type: 'maine',
    short_name: 'US-ME',
    state: 'ME',
    id: 3600000,
    admin_code_id: null,
    statute_id: 8938584,
    jurisdiction_id: 23,
    active: null,
    external_link: 'http://www.maine.gov/sos/cec/rules/rules.html',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Montana',
    type: 'montana',
    short_name: 'US-MT',
    state: 'MT',
    id: 3100000,
    admin_code_id: 2330398,
    statute_id: 8937630,
    jurisdiction_id: 30,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Nebraska',
    type: 'nebraska',
    short_name: 'US-NE',
    state: 'NE',
    id: 2300000,
    admin_code_id: 2094078,
    statute_id: 8945939,
    jurisdiction_id: 31,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'New Hampshire',
    type: 'new_hampshire',
    short_name: 'US-NH',
    state: 'NH',
    id: 3400000,
    admin_code_id: 2351048,
    statute_id: 8946071,
    jurisdiction_id: 33,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'New York',
    type: 'new_york',
    short_name: 'US-NY',
    state: 'NY',
    id: 400000,
    admin_code_id: 1468292,
    jurisdiction_id: 36,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'North Carolina',
    type: 'north_carolina',
    short_name: 'US-NC',
    state: 'NC',
    id: 1600000,
    statute_id: 8440351,
    admin_code_id: 1658361,
    jurisdiction_id: 37,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Oklahoma',
    type: 'oklahoma',
    short_name: 'US-OK',
    state: 'OK',
    id: 2400000,
    admin_code_id: 2096122,
    statute_id: 8935225,
    jurisdiction_id: 40,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Rhode Island',
    type: 'rhode_island',
    short_name: 'US-RI',
    state: 'RI',
    id: 4200000,
    admin_code_id: 8955282,
    statute_id: 7131858,
    jurisdiction_id: 44,
    active: true,
    external_link: 'http://sos.ri.gov/rules/',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'South Carolina',
    type: 'south_carolina',
    short_name: 'US-SC',
    state: 'SC',
    id: 3000000,
    admin_code_id: 2346423,
    statute_id: 8938035,
    jurisdiction_id: 45,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Utah',
    type: 'utah',
    short_name: 'US-UT',
    state: 'UT',
    id: 1900000,
    statute_id: 9129580,
    admin_code_id: 2063009,
    jurisdiction_id: 49,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Vermont',
    type: 'vermont',
    short_name: 'US-VT',
    state: 'VT',
    id: 3700000,
    admin_code_id: null,
    statute_id: 8934824,
    jurisdiction_id: 50,
    active: false,
    external_link: 'http://www.lexisnexis.com/hottopics/codeofvtrules/',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Virginia',
    type: 'virginia',
    short_name: 'US-VA',
    state: 'VA',
    id: 1500000,
    admin_code_id: 1616868,
    jurisdiction_id: 51,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Wyoming',
    type: 'wyoming',
    short_name: 'US-WY',
    state: 'WY',
    id: null,
    admin_code_id: 9240335,
    statute_id: 9239650,
    jurisdiction_id: 56,
    active: null,
    external_link: 'https://rules.wyo.gov/Search.aspx?mode=1',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Alaska',
    type: 'alaska',
    short_name: 'US-AK',
    state: 'AK',
    id: 4300000,
    statute_id: 9257870,
    admin_code_id: 9239826,
    jurisdiction_id: 2,
    active: true,
    external_link:
      'http://www.legis.state.ak.us/basis/folioproxy.asp?url=http://wwwjnu01.legis.state.ak.us/cgi-bin/folioisa.dll/aac/query=[JUMP:%27Title3Chap02%27]/doc/{@1}?firsthit',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Massachusetts',
    type: 'massachusetts',
    short_name: 'US-MA',
    state: 'MA',
    id: 600000,
    admin_code_id: 1616137,
    statute_id: 3148127,
    jurisdiction_id: 25,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Missouri',
    type: 'missouri',
    short_name: 'US-MO',
    state: 'MO',
    id: 1400000,
    admin_code_id: 1681738,
    statute_id: 3276391,
    jurisdiction_id: 29,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'West Virgina',
    type: 'west_virgina',
    short_name: 'US-WV',
    state: 'WV',
    id: 3900000,
    admin_code_id: null,
    jurisdiction_id: 54,
    active: false,
    external_link: 'http://apps.sos.wv.gov/adlaw/csr/',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Colorado',
    type: 'colorado',
    short_name: 'US-CO',
    state: 'CO',
    id: 700000,
    admin_code_id: 1405155,
    jurisdiction_id: 8,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Maryland',
    type: 'maryland',
    short_name: 'US-MD',
    state: 'MD',
    id: 4000000,
    admin_code_id: null,
    jurisdiction_id: 24,
    active: false,
    external_link: 'http://www.dsd.state.md.us/COMAR/subtitle_chapters/Titles.aspx',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Nevada',
    type: 'nevada',
    short_name: 'US-NV',
    state: 'NV',
    id: 1000000,
    admin_code_id: 1482618,
    jurisdiction_id: 32,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'South Dakota',
    type: 'south_dakota',
    short_name: 'US-SD',
    state: 'SD',
    id: 2900000,
    admin_code_id: 2241826,
    jurisdiction_id: 46,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'North Dakota',
    type: 'north_dakota',
    short_name: 'US-ND',
    state: 'ND',
    id: 2800000,
    admin_code_id: 2238807,
    jurisdiction_id: 38,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Oregon',
    id: 900000,
    short_name: 'US-OR',
    state: 'OR',
    type: 'oregon',
    admin_code_id: 1471760,
    jurisdiction_id: 41,
    active: true,
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'New Mexico',
    type: 'new_mexico',
    short_name: 'US-NM',
    state: 'NM',
    id: null,
    admin_code_id: null,
    jurisdiction_id: 35,
    active: null,
    external_link: 'http://164.64.110.239/nmac/_titles.htm',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Kansas',
    type: 'kansas',
    short_name: 'US-KS',
    state: 'KS',
    id: null,
    admin_code_id: null,
    jurisdiction_id: 20,
    active: null,
    external_link: 'http://www.kssos.org/pubs/pubs_kar.aspx',
    following: null,
    country: 'US'
  });
  defaultStateAgencies.push({
    name: 'Mississippi',
    type: 'mississippi',
    short_name: 'US-MS',
    state: 'MS',
    id: 3800000,
    admin_code_id: null,
    jurisdiction_id: 28,
    active: false,
    external_link: 'http://www.sos.ms.gov/adminsearch/default.aspx',
    following: null,
    country: 'US'
  });
}

const defaultPubLaws = [
  {
    name: '115th Congress (2017 – 2018)',
    official_id: 'Public Law 115',
    short_name: 'Publaw 115',
    publaw_id: 2089813
  },
  {
    name: '114th Congress (2015 – 2016)',
    official_id: 'Public Law 114',
    short_name: 'Publaw 114',
    publaw_id: 2089811
  },
  {
    name: '113th Congress (2013 – 2014)',
    official_id: 'Public Law 113',
    short_name: 'Publaw 113',
    publaw_id: 2089809
  },
  {
    name: '112th Congress (2011 – 2012)',
    official_id: 'Public Law 112',
    short_name: 'Publaw 112',
    publaw_id: 2089807
  },
  {
    name: '111th Congress (2009 – 2010)',
    official_id: 'Public Law 111',
    short_name: 'Publaw 111',
    publaw_id: 2089805
  },
  {
    name: '110th Congress (2007 – 2008)',
    official_id: 'Public Law 110',
    short_name: 'Publaw 110',
    publaw_id: 2089803
  },
  {
    name: '109th Congress (2004 – 2006)',
    official_id: 'Public Law 109',
    short_name: 'Publaw 109',
    publaw_id: 2089801
  },
  {
    name: '108th Congress (2003 – 2004)',
    official_id: 'Public Law 108',
    short_name: 'Publaw 108',
    publaw_id: 2089799
  },
  {
    name: '107th Congress (2001 – 2002)',
    official_id: 'Public Law 107',
    short_name: 'Publaw 107',
    publaw_id: 2089797
  },
  {
    name: '106th Congress (1999 – 2000)',
    official_id: 'Public Law 106',
    short_name: 'Publaw 106',
    publaw_id: 2089795
  },
  {
    name: '105th Congress (1997 – 1998)',
    official_id: 'Public Law 105',
    short_name: 'Publaw 105',
    publaw_id: 2089793
  },
  {
    name: '104th Congress (1995 – 1996)',
    official_id: 'Public Law 104',
    short_name: 'Publaw 104',
    publaw_id: 2089791
  }
];

// Temporarily adding to integration so data team can test.
// Data currently does not exist on the production ES database
// so we don't want this on staging or production.
// (And of course, long term none of this should be hardcoded.)
if (window.location.host.includes('integration')) {
  defaultPubLaws.unshift(
    {
      name: '117th Congress (2020 – 2021)',
      official_id: 'Public Law 117',
      short_name: 'Publaw 117',
      publaw_id: 9172962
    },
    {
      name: '116th Congress (2019 – 2020)',
      official_id: 'Public Law 116',
      short_name: 'Publaw 116',
      publaw_id: 3514346
    }
  );
} else {
  // for pro
  defaultPubLaws.unshift(
    {
      name: '117th Congress (2020 – 2021)',
      official_id: 'Public Law 117',
      short_name: 'Publaw 117',
      publaw_id: 7062348
    },
    {
      name: '116th Congress (2019 – 2020)',
      official_id: 'Public Law 116',
      short_name: 'Publaw 116',
      publaw_id: 3733331
    }
  );
}

export { defaultPubLaws };
const hostLocation = window.location.host;

const getFINRARulesHeadID = () => {
  if (hostLocation.includes('integration') || hostLocation.includes('localhost')) {
    return 6622956;
  } else {
    return 5742146;
  }
};

const getNachaGuidelinesHeadID = () => {
  if (hostLocation.includes('integration') || hostLocation.includes('localhost')) {
    return 18642689;
  } else {
    return 10150240;
  }
};

const getNachaOperatingRulesHeadID = () => {
  if (hostLocation.includes('integration') || hostLocation.includes('localhost')) {
    return 18644182;
  } else {
    return 10149801;
  }
};

const getFannieSellingGuideHeadId = () => {
  if (hostLocation.includes('integration') || hostLocation.includes('localhost')) {
    return 8269293;
  } else {
    return 6438489;
  }
};

const getHMRCManualsHeadId = () => {
  if (hostLocation.includes('integration') || hostLocation.includes('localhost')) {
    return 8314525;
  } else {
    return null;
  }
};

const getFreddieServicingGuideHeadID = () => {
  if (hostLocation.includes('integration') || hostLocation.includes('localhost')) {
    return 6104852;
  } else {
    return null;
  }
};

const getNAICGuideHeadID = () => {
  if (hostLocation.includes('integration') || hostLocation.includes('localhost')) {
    return 9650594;
  } else {
    return 7115847;
  }
};

const defaultOther = [
  {
    name: 'FINRA Rules',
    head_id: getFINRARulesHeadID()
  },
  {
    name: 'Nacha Operating Guidelines',
    head_id: getNachaGuidelinesHeadID()
  },
  {
    name: 'Nacha Operating Rules',
    head_id: getNachaOperatingRulesHeadID()
  },
  {
    name: 'Fannie Selling Guide',
    head_id: getFannieSellingGuideHeadId()
  },
  {
    name: 'HMRC Manuals',
    head_id: getHMRCManualsHeadId()
  },
  {
    name: 'Freddie Servicing Guide',
    head_id: getFreddieServicingGuideHeadID()
  },
  {
    name: 'NAIC Model Laws & Regulations',
    head_id: getNAICGuideHeadID()
  }
];

const filteredDefaultOther = lodash.filter(defaultOther, 'head_id');

export { filteredDefaultOther as defaultOther };

export const defaultPubLawsObj = defaultPubLaws.reduce((mem, item) => {
  mem[item.publaw_id] = item;
  return mem;
}, {});

const getDefaultUSCodeId = () => {
  if (hostLocation.includes('integration') || hostLocation.includes('localhost')) {
    return 1708777;
  } else {
    return 11314513;
  }
};

export const defaultUSCode = {
  name: 'US Code',
  id: getDefaultUSCodeId()
};

export const defaultCFR = {
  name: 'CFR',
  id: defaultCFRID
};

// TODO: this list must be kept in sync with server
export const defaultTopics = [
  { id: 1, label: 'Lending', description: null },
  { id: 2, label: 'BSA/AML', description: 'Bank Secrecy Act/Anti-Money Laundering' },
  { id: 3, label: 'Mortgage Lending', description: null },
  { id: 4, label: 'Crowdfunding', description: null },
  { id: 5, label: 'FCPA', description: 'Foreign Corrupt Practices Act' },
  { id: 6, label: 'Credit', description: null },
  { id: 7, label: 'Deposits', description: null },
  { id: 8, label: 'Bank Operations', description: null },
  { id: 9, label: 'Insurance', description: null },
  { id: 10, label: 'Privacy', description: null },
  { id: 11, label: 'Securities', description: null },
  { id: 12, label: 'Trust', description: null },
  { id: 13, label: 'Payments', description: null },
  { id: 14, label: 'Cybersecurity', description: null },
  { id: 15, label: 'Leasing', description: null },
  { id: 16, label: 'Debt Collection', description: null },
  { id: 17, label: 'Commercial Lending', description: null },
  { id: 18, label: 'Consumer Lending', description: null },
  {
    id: 19,
    label: 'Payday Loans',
    description:
      'A payday loan (aka “small dollar loan” or “deferred deposit transaction” and ' +
      '“deferred presentment transaction” or “payday advance”) is a short-term loan, often for $500 or less; repayment is typically ' +
      'due on the borrower’s next payday. When a borrower takes out a payday loan, he typically has to give the lender access to his ' +
      'checking account or write a post-dated check for the full balance that the lender can deposit when the loan is due.'
  }
];
