import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getInitialTopicThresholdKey,
  getTopicThresholdSelectOptions
} from 'shared/features/thresholds/thresholds.selectors';
import {
  getCurrentUserProperties,
  getCurrentUserRoles,
  getUser,
  getUserEmail
} from 'shared/features/user/user.selectors';
import { getActionBarFilterParams } from 'shared/features/filters/filters.selectors';
import { useQueryParams } from 'utils/hooks';
import { getInitialMinScore } from './useAdditionalFiltersData.helpers';
import { getUserAuthenticatedState } from 'shared/features/auth/auth.selectors';

export const useAdditionalFiltersData = () => {
  const queryParams = useQueryParams();

  const reduxState = useSelector(state => ({
    isAuthenticated: getUserAuthenticatedState(state),
    currentUser: getUser(state),
    currentUserEmail: getUserEmail(state),
    currentUserProperties: getCurrentUserProperties(state),
    currentUserRoles: getCurrentUserRoles(state),
    topicThresholdSelectOptions: getTopicThresholdSelectOptions(state),
    actionBarFilterParams: getActionBarFilterParams(state),
    initialTopicThresholdKey: getInitialTopicThresholdKey(queryParams)(state)
  }));

  const [isOpen, setOpenState] = useState(false);
  const [minScore, setMinScore] = useState(getInitialMinScore(queryParams));
  const [topicThreshold, setTopicThreshold] = useState(reduxState.initialTopicThresholdKey);

  const localState = {
    isOpen,
    minScore,
    topicThreshold
  };

  const localActions = useMemo(
    () => ({
      setOpenState,
      setMinScore,
      setTopicThreshold
    }),
    []
  );

  return {
    localState,
    localActions,
    reduxState
  };
};
