export const DOC_TYPES_REDUCER_NAME = 'docTypes';

export const FIELD_VALUES = {
  RESTRICTED: 'RESTRICTED'
};

export const MOCK_PERMISSIONS = {
  // No one except the owner can see the doc
  24440532: {
    owner_id: 981,
    permissions: {
      user_read_access: [],
      user_write_access: [],
      group_write_access: [],
      group_read_access: [],
      user_permission_write_access: [],
      group_permission_write_access: []
    }
  },
  // Owner and 2 users can see the doc
  24440527: {
    owner_id: 981,
    permissions: {
      user_read_access: [1149, 1150],
      user_write_access: [],
      group_write_access: [],
      group_read_access: [],
      user_permission_write_access: [],
      group_permission_write_access: []
    }
  },
  // Any user can see the doc
  24440524: {
    owner_id: 981,
    permissions: {
      user_read_access: [-1],
      user_write_access: [],
      group_write_access: [],
      group_read_access: [],
      user_permission_write_access: [],
      group_permission_write_access: []
    }
  },
  // Only team members can see the doc
  24440597: {
    owner_id: null,
    permissions: {
      user_read_access: [],
      user_write_access: [],
      group_write_access: [],
      group_read_access: [79],
      user_permission_write_access: [],
      group_permission_write_access: []
    }
  },
  // Owner, 1 specific user and users from the team can see the doc
  24440518: {
    owner_id: 981,
    permissions: {
      user_read_access: [1149],
      user_write_access: [],
      group_write_access: [],
      group_read_access: [79],
      user_permission_write_access: [],
      group_permission_write_access: []
    }
  }
};

export enum DOC_METADATA_FIELD {
  RESPONDENTS = 'Respondents',
  PENALTY = 'Penalty',
  VIOLATION = 'Violation',
  RULES_VIOLATED = 'Rules Violated',
  AGENCY = 'Agency',
  SOURCE = 'Source',
  AUTHOR = 'Author',
  PUBLISHER = 'Publisher',
  CITATION = 'Citation',
  DOCUMENT_ID = 'DocumentId',
  FIRM = 'Firm',
  LOCATION = 'Location',
  PUBLICATION_DATE = 'PublicationDate',
  TOPICS = 'Topics',
  CONCEPTS = 'Concepts',
  LABELS = 'Labels',
  COMMENTER = 'Commenter',
  LAST_COMMENT = 'LastComment',
  DOCKETS = 'Dockets',
  CFRs = 'CFRs',
  APPLICABILITY_DATE = 'ApplicabilityDate',
  IMPLEMENTATION_DATE = 'ImplementationDate',
  KEY_DATES = 'KeyDates',
  DOCUMENT_TYPE = 'DocumentType',
  ADDED_TO_COMPLIANCE_AI = 'AddedToComplianceAi',
  EITL_STATUS = 'EitlStatus'
}

export enum DOCUMENT_UPDATE_REQUEST_STATUSES {
  IN_PROGRESS = 1,
  FAILED = 2,
  COMPLETED = 3
}
