import { fetch } from 'utils/api';

export function api_getExpertConnectionData() {
  return fetch({
    url: '/expert_connection',
    dataType: 'PremiumContent',
    method: 'GET'
  });
}

export function api_getExperts() {
  return fetch({
    url: '/expert_connection/experts',
    dataType: 'PremiumContent',
    method: 'GET'
  });
}

export function api_getAreasOfExpertise() {
  return fetch({
    url: '/expert_connection/areas_of_expertise',
    dataType: 'PremiumContent',
    method: 'GET'
  });
}
