import type { CommentsSectionData } from './useCommentsSectionData.types';
import type { CommentsSectionProps } from '../CommentsSection.types';

import * as constants from '../CommentsSection.constants';
import * as commentsHelpers from "shared/features/comments/comments.helpers";

import { useMemo } from 'react';

export const useCommentsSectionData = ({
  documentTasks
}: Pick<CommentsSectionProps, 'documentTasks'>): CommentsSectionData => {
  const formattedData: CommentsSectionData['formattedData'] = useMemo(() => {
    const rows = documentTasks.map((documentTask, i) => {
      return {
        [constants.TABLE_FIELDS.ID]: documentTask.id,
        [constants.TABLE_FIELDS.STEP]: i + 1,
        [constants.TABLE_FIELDS.NAME]: documentTask.name,
        [constants.TABLE_FIELDS.COMMENTS]: commentsHelpers.normalizeCommentThreads(
          documentTask.comment_threads
        )
      };
    });

    return {
      rows
    };
  }, [documentTasks]);

  return {
    formattedData
  };
};
