import type { useDocumentSummaryTabData } from './useDocumentSummaryTabData';
import type { DocumentSummaryTabHandlers } from './useDocumentSummaryTabHandlers.types';

import lodash from 'lodash';

import { useDocumentsReduxActions } from 'shared/features/documents/hooks';

export const useDocumentSummaryTabHandlers = ({
  localActions,
  reduxState
}: {
  localActions: ReturnType<typeof useDocumentSummaryTabData>['localActions'];
  reduxState: ReturnType<typeof useDocumentSummaryTabData>['reduxState'];
}) => {
  const reduxActions = useDocumentsReduxActions();

  const handleToggleMetadataEdit: DocumentSummaryTabHandlers['handleToggleMetadataEdit'] = isOpen => () => {
    localActions.setIsEditingMetadata(isOpen);
  };

  const handleBuildResourceCodeTree: DocumentSummaryTabHandlers['handleBuildResourceCodeTree'] = async (
    docId,
    path = [docId]
  ) => {
    if (!reduxState.USState.codes[docId]) {
      const docFolder = await reduxActions.fetchResourceCode(docId);
      if (!docFolder) {
        return;
      }

      const selectedState = docFolder[0].document;

      if (selectedState.parent) {
        path.push(selectedState.parent.id);
        handleBuildResourceCodeTree(selectedState.parent.id, path);
      } else {
        const orderedPath = lodash.reverse(path);

        localActions.setPath(orderedPath as never[]);
      }
    } else {
      const selectedState = reduxState.USState.codes[docId];

      if (selectedState.parent) {
        path.push(selectedState.parent.id);
        handleBuildResourceCodeTree(selectedState.parent.id, path);
      } else {
        const orderedPath = lodash.reverse(path);

        localActions.setPath(orderedPath as never[]);
      }
    }
  };

  return {
    handleToggleMetadataEdit,
    handleBuildResourceCodeTree
  };
};
