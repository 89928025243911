import { useMemo, useState } from 'react';
import { ALERT_TYPES } from '@compliance.ai/web-components';
import { ErrorAlertMessage } from '../elements';

export const useAuthFormAlertData = () => {
  const [message, setMessage] = useState(null);

  const localActions = useMemo(() => {
    const resetMessage = () => {
      setMessage(null);
    };

    const setSuccessMessage = message => {
      setMessage({
        type: ALERT_TYPES.SUCCESS,
        title: 'Success',
        content: message
      });
    };

    const setErrorMessage = message => {
      setMessage({
        type: ALERT_TYPES.ERROR,
        title: null,
        content: message
      });
    };

    const setErrorMessageByType = messageType => {
      setErrorMessage(<ErrorAlertMessage type={messageType} />);
    };

    return {
      resetMessage,
      setSuccessMessage,
      setErrorMessage,
      setErrorMessageByType
    };
  }, []);

  return {
    localState: {
      message
    },
    localActions
  };
};
