import STYLE_VARIABLES from '@compliance.ai/styles';

export const LEGEND = [
  {
    label: 'System-generated (Likely)',
    color: STYLE_VARIABLES.SECONDARY_COLOR_10
  },
  {
    label: 'System-generated',
    color: STYLE_VARIABLES.PRIMARY_COLOR_2
  },
  {
    label: 'User-generated',
    color: STYLE_VARIABLES.PRIMARY_COLOR_1
  }
];
