import type { ComponentProps } from 'react';
import type { AccountMenuData } from './useAccountMenuData.types';
import type { AccountMenuHandlers } from './useAccountMenuHandlers.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from '../AccountMenu.constants';

import { useMemo } from 'react';
import { usePermissions, useSKUs } from 'utils/hooks';

export const useAccountMenuOptions = ({
  reduxState,
  events
}: {
  reduxState: AccountMenuData['reduxState'];
  events: {
    onLogOut: AccountMenuHandlers['handleLogOut'];
  };
}): ComponentProps<typeof uiLib.List>['options'] => {
  const permissions = usePermissions();
  const skus = useSKUs();

  return useMemo(() => {
    return Object.entries(constants.ACCOUNT_MENU_OPTIONS_CONFIG).flatMap(
      ([optionKey, optionCallback]) => {
        const option = optionCallback({
          permissions,
          skus,
          reduxState,
          events
        });

        if (option.isHidden) {
          return [];
        }

        return {
          ...option,
          value: optionKey
        };
      }
    );
  }, [events, permissions, reduxState, skus]);
};
