import type { CitationProps } from './Citation.types';

import { DOCUMENT_CATEGORIES } from 'constants/DocumentConstants';
import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';
import { CitationContent } from './elements';
import { useCitationData, useCitationHandlers } from './hooks';
import { useResourceDocumentCheck } from 'utils/hooks';

export const Citation = ({
  path,
  document,
  shouldRenderForEmail,
  onResourceTreeBuild
}: CitationProps) => {
  const isResourceDocument = useResourceDocumentCheck(document);

  const { reduxState, formattedData } = useCitationData({ path, document });

  const handlers = useCitationHandlers({
    props: {
      onResourceTreeBuild
    },
    reduxState,
    formattedData
  });

  if (isResourceDocument && !shouldRenderForEmail) {
    return (
      <>
        <FieldName>Citation</FieldName>
        <FieldValue>
          <CitationContent
            document={document}
            onClick={handlers.handleCitationClick}
            shouldRenderOfficialId
          />
        </FieldValue>
      </>
    );
  }

  if (
    [DOCUMENT_CATEGORIES.CFR, DOCUMENT_CATEGORIES.CFR_NAVIGATION].includes(
      reduxState.categoryName as DOCUMENT_CATEGORIES
    )
  ) {
    return (
      <>
        <FieldName>Citation</FieldName>
        <FieldValue>
          <CitationContent
            document={document}
            onClick={handlers.handleCitationClick}
            shouldRenderOfficialId
          />
        </FieldValue>
      </>
    );
  }

  if (Array.isArray(document.citation_ids)) {
    return (
      <>
        <FieldName>Citation</FieldName>
        <FieldValue>
          <CitationContent document={document} onClick={handlers.handleCitationClick} />
        </FieldValue>
      </>
    );
  }

  return null;
};
