import type { DocumentTaskDropdownFromResponse } from 'shared/features/workflow/workflow.types';

import * as workflowHelpers from 'shared/features/workflow/workflow.helpers';

export const formatSelectedDropdownOptionLabels = (
  dropdownOptions: DocumentTaskDropdownFromResponse['dropdown_options']
) => {
  return (
    workflowHelpers
      .formatNestedSelectedDropdownOptionLabels(dropdownOptions)
      /**
       * Adding a vertical space between the selected options
       * ensures that user will be able to distinguish separate options even on a small screen
       */
      .join('\n\n')
  );
};
