import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import queryString from 'utils/query-string';
import { withHistory } from 'utils/hooks';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import DiffIcon from '@material-ui/icons/VerticalSplit';
import LockIcon from '@material-ui/icons/Lock';
import { markDocumentAsBookmarked } from 'shared/features/documents/documents.actions';
import { changeSelectedItem, saveUnBookmarkedDocument } from 'shared/features/view/view.actions';
import auth from 'utils/auth';
import { safe_analytics } from 'utils/analytics';
import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import RestrictBubble from 'components/RestrictBubble';
import { restrict } from 'components/Restrict';
import { userCanAccessDocument, getDocumentRestriction } from 'utils/documents/documentsAccess';
import DocumentListItemEnforcements from './DocumentListItemEnforcements';
import DocumentListItemContent from './DocumentListItemContent';
import { enforcementExplorer } from 'constants/Routes';
import { RESTRICTED_ACTIONS } from 'constants/Restrictions';
import { RIGHT_PANEL_TYPES } from 'shared/features/rightPanel/rightPanel.constants';
import { withRightPanelReduxActions } from 'shared/features/rightPanel/hooks';
import { getRightPanelDocumentId } from 'shared/features/rightPanel/rightPanel.selectors';

export class DocumentListItem extends React.Component {
  state = {
    checked: this.props.current_view.selected_items[this.props.document.id] !== undefined
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.current_view.selected_items[this.props.document.id] !==
      prevProps.current_view.selected_items[this.props.document.id]
    ) {
      this.setState({
        checked: this.props.current_view.selected_items[this.props.document.id] !== undefined
      });
    }
  }

  navigateSummary(id) {
    this.props.openRightPanel({
      type: RIGHT_PANEL_TYPES.DOCUMENT_DETAILS,
      props: {
        documentId: id
      }
    });
  }

  handleClick(event) {
    event.preventDefault();
    if (
      event.target &&
      ((event.target.classList && event.target.classList.contains('clickable')) ||
        event.target.tagName === 'INPUT')
    ) {
      return;
    }
    const document = this.props.document;

    if (event.type === 'click') {
      this.navigateSummary(document.id);
    }
  }

  renderDiffIcon(document) {
    const userHasAccess = userCanAccessDocument({ document });
    const documentAccess =
      userHasAccess && getDocumentRestriction({ document, action: RESTRICTED_ACTIONS.DIFF });

    if (document.has_sentences && !documentAccess) {
      return <LockIcon className="lockIcon" />;
    }

    if (document.has_sentences) {
      return <DiffIcon className="diffIcon grey" />;
    }

    return null;
  }

  render() {
    const { search, pathname } = this.props.location;
    const parsedQuery = queryString.parse(search);
    const { document, rightPanelDocumentId } = this.props;

    //if the document has NOT been read and the read filter is on return null
    if (!document.read && parsedQuery.read === true) {
      return null;
    }
    //if the document has been read and the unread filter is on return null
    if (document.read && parsedQuery.read === false) {
      return null;
    }
    // XXX clean up and remove unnecessary features
    const row_classes = {
      read_document: document.read,
      'document-row': true,
      selected: rightPanelDocumentId === this.props.document.id,
      today: this.props.today
    };
    const bookmark_icon = document.bookmarked ? (
      <BookmarkIcon className="bookmark-icon" />
    ) : (
      <BookmarkBorderIcon className="bookmark-icon" />
    );
    const handleCheck = () => {
      if (!this.props.current_view.selected_items[document.id]) {
        this.navigateSummary(document.id);
      }
      this.props.changeSelectedItem(document, this.state.checked);
    };
    const handleCheckClick = () => {
      this.setState(state => ({ checked: !state.checked }), handleCheck);
    };

    const handleBookmarked = e => {
      if (document.bookmarked) {
        safe_analytics(
          'Doc Action – Unbookmark document',
          'Doc Action',
          'Unbookmark document',
          document.title
        );
        if (parsedQuery.bookmarked === 'true') {
          this.props.saveUnBookmarkedDocument(document.id);
        }
      } else {
        safe_analytics(
          'Doc Action – Bookmark document',
          'Doc Action',
          'Bookmark document',
          document.title
        );
      }
      this.props.markDocumentAsBookmarked(document.id, !document.bookmarked);
    };

    const checkboxColumn = (
      <td onClick={e => this.handleClick(e)}>
        <div className="controls">
          <div>
            <input
              type="checkbox"
              className="text-center"
              checked={this.state.checked}
              onChange={handleCheckClick}
              inline="true"
            />
            <CheckBoxOutlineBlankIcon className="unchecked clickable" onClick={handleCheckClick} />
            <CheckBoxIcon className="checked clickable" onClick={handleCheckClick} />
          </div>
          <div className="bookmarkBubble">
            <Button
              type={BUTTON_TYPES.LINK}
              className="bookmark clickable"
              aria-hidden="true"
              onClick={restrict(handleBookmarked, this, 'Bookmark Document')}
            >
              <span className="bookmark-text">{bookmark_icon}</span>
              {!auth.loggedIn() ? <RestrictBubble small docListItem /> : null}
            </Button>
          </div>
          {this.renderDiffIcon(document)}
        </div>
      </td>
    );

    if (pathname === enforcementExplorer) {
      return (
        <DocumentListItemEnforcements
          checkboxColumn={checkboxColumn}
          rowClassName={classNames(row_classes, this.props.extra_classes)}
          handleClick={e => this.handleClick(e)}
        />
      );
    }

    return (
      <DocumentListItemContent
        checkboxColumn={checkboxColumn}
        rowClassName={classNames(row_classes, this.props.extra_classes)}
        handleClick={e => this.handleClick(e)}
        location={this.props.location}
        document={this.props.document}
        app_view={this.props.app_view}
        isPremiumContent={this.props.isPremiumContent}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    documents: state.documents,
    current_view: state.current_view,
    current_user: state.current_user,
    rightPanelDocumentId: getRightPanelDocumentId(state)
  };
};

export default connect(mapStateToProps, {
  changeSelectedItem,
  markDocumentAsBookmarked,
  saveUnBookmarkedDocument
})(withHistory(withRightPanelReduxActions(DocumentListItem)));
