export const ADD_ERROR = 'ADD_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export function clearErrors(component) {
  return {
    type: CLEAR_ERRORS,
    component
  };
}

export function addError(error, component) {
  return {
    type: ADD_ERROR,
    component,
    error
  };
}
