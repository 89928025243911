import {
  api_fetchOrganization,
  api_fetchAllOrganization,
  api_updateOrganization,
  api_addOrganizationMember,
  api_updateOrganizationMember,
  api_fetchTeams,
  api_updateTeam,
  api_deleteTeam,
  api_updateTeamMember,
  api_addTeam,
  api_addTeamMember,
  api_deleteTeamMember,
  api_fetchAllTeamMembers,
  api_inviteToOrg,
  api_removeOrgInvitation,
  api_fetchOrganizationLabelsOptions
} from './organizations.api';
import { logError } from 'utils/errors';
import * as config from 'shared/config';
import * as helpers from './organizations.helpers';

export const REQUEST_ORGANIZATION = 'REQUEST_ORGANIZATION';
export const RECEIVE_ORGANIZATION = 'RECEIVE_ORGANIZATION';
export const RECEIVE_ORGANIZATION_LABELS = 'RECIEVE_ORGANIZATION_LABELS';
export const REQUEST_ALLORGANIZATION = 'REQUEST_ALLORGANIZATION';
export const RECEIVE_ALLORGANIZATION = 'RECEIVE_ALLORGANIZATION';
export const REQUEST_UPDATE_ORGANIZATION = 'REQUEST_UPDATE_ORGANIZATION';
export const RECEIVE_UPDATE_ORGANIZATION = 'RECEIVE_UPDATE_ORGANIZATION';
export const REQUEST_TEAMS = 'REQUEST_TEAMS';
export const RECEIVE_TEAMS = 'RECEIVE_TEAMS';
export const REQUEST_ADD_TEAMS = 'REQUEST_ADD_TEAMS';
export const RECEIVE_ADD_TEAMS = 'RECEIVE_ADD_TEAMS';
export const REQUEST_ADD_TEAM_MEMBER = 'REQUEST_ADD_TEAM_MEMBER';
export const RECEIVE_ADD_TEAM_MEMBER = 'RECEIVE_ADD_TEAM_MEMBER';
export const REQUEST_ALL_TEAM_MEMBERS = 'REQUEST_ALL_TEAM_MEMBERS';
export const RECEIVE_ALL_TEAM_MEMBERS = 'RECEIVE_ALL_TEAM_MEMBERS';
export const REQUEST_ADD_ORGANIZATION_MEMBER = 'REQUEST_ADD_ORGANIZATION_MEMBER';
export const RECEIVE_ADD_ORGANIZATION_MEMBER = 'RECEIVE_ADD_ORGANIZATION_MEMBER';
export const REQUEST_UPDATE_ORGANIZATION_MEMBER = 'REQUEST_UPDATE_ORGANIZATION_MEMBER';
export const RECEIVE_UPDATE_ORGANIZATION_MEMBER = 'RECEIVE_UPDATE_ORGANIZATION_MEMBER';
export const REQUEST_INVITE_TO_ORG = 'REQUEST_INVITE_TO_ORG';
export const RECEIVE_INVITE_TO_ORG = 'RECEIVE_INVITE_TO_ORG';
export const REQUEST_REMOVE_TEAM_MEMBER = 'REQUEST_REMOVE_TEAM_MEMBER';
export const RECEIVE_REMOVE_TEAM_MEMBER = 'RECEIVE_REMOVE_TEAM_MEMBER';
export const REQUEST_CHANGE_TEAM = 'REQUEST_CHANGE_TEAM';
export const RECEIVE_CHANGE_TEAM = 'RECEIVE_CHANGE_TEAM';
export const REQUEST_DELETE_TEAM = 'REQUEST_DELETE_TEAM';
export const RECEIVE_DELETE_TEAM = 'RECEIVE_DELETE_TEAM';
export const REQUEST_UPDATE_TEAM_MEMBER = 'REQUEST_UPDATE_TEAM_MEMBER';
export const RECEIVE_UPDATE_TEAM_MEMBER = 'RECEIVE_UPDATE_TEAM_MEMBER';

function requestOrganization() {
  return {
    type: REQUEST_ORGANIZATION
  };
}

function receiveOrganization(data) {
  return {
    type: RECEIVE_ORGANIZATION,
    organization: data.organization
  };
}

export const fetchOrganizationLabelsOptions = () => async dispatch => {
  const orgLabelsOptions = await api_fetchOrganizationLabelsOptions();

  dispatch(receiveOrganizationLabels(orgLabelsOptions));

  return orgLabelsOptions;
};

export const receiveOrganizationLabels = data => ({
  type: RECEIVE_ORGANIZATION_LABELS,
  payload: data
});

export const fetchOrganization = orgId => async dispatch => {
  dispatch(requestOrganization());

  const response = await api_fetchOrganization(orgId);
  dispatch(
    receiveOrganization(
      config.shouldFetchNewOrgRequest ? helpers.formatNewOrgResponse(response) : response
    )
  );

  return response;
};

function requestAllOrganization() {
  return {
    type: REQUEST_ALLORGANIZATION
  };
}

function receiveAllOrganization(json) {
  return {
    type: RECEIVE_ALLORGANIZATION,
    organizations: json.organizations
  };
}

export function fetchAllOrganization() {
  return function dofetch(dispatch) {
    dispatch(requestAllOrganization());
    return api_fetchAllOrganization().then(response => {
      dispatch(receiveAllOrganization(response));
      return response;
    });
  };
}

function requestUpdateOrganization() {
  return {
    type: REQUEST_UPDATE_ORGANIZATION
  };
}

function receiveUpdateOrganization(response) {
  return {
    type: RECEIVE_UPDATE_ORGANIZATION,
    organization: response.organization
  };
}

export function updateOrganization(orgId, data) {
  return function dopost(dispatch) {
    dispatch(requestUpdateOrganization(data));
    return api_updateOrganization(orgId, data).then(response => {
      dispatch(
        receiveUpdateOrganization(
          config.shouldFetchNewOrgRequest ? helpers.formatNewOrgResponse(response) : response
        )
      );
      return response;
    });
  };
}

export const updateOrganizationV2 = (orgId, data) => async dispatch => {
  try {
    dispatch(requestUpdateOrganization(data));

    const response = await api_updateOrganization(orgId, data);

    dispatch(
      receiveUpdateOrganization(
        config.shouldFetchNewOrgRequest ? { organization: response } : response
      )
    );

    return response;
  } catch (e) {
    logError(e);

    throw e;
  }
};

function requestAddrganizationMember() {
  return {
    type: REQUEST_ADD_ORGANIZATION_MEMBER
  };
}

function receiveAddrganizationMember() {
  return {
    type: RECEIVE_ADD_ORGANIZATION_MEMBER
  };
}

export function addOrganizationMember(orgId, params) {
  return function dopost(dispatch) {
    dispatch(requestAddrganizationMember());
    return api_addOrganizationMember(orgId, params).then(response => {
      dispatch(receiveAddrganizationMember());
      return response;
    });
  };
}

function requestUpdateOrganizationMember() {
  return {
    type: REQUEST_UPDATE_ORGANIZATION_MEMBER
  };
}

function receiveUpdateOrganizationMember() {
  return {
    type: RECEIVE_UPDATE_ORGANIZATION_MEMBER
  };
}

export function updateOrganizationMember(orgId, userId, params) {
  return function dopost(dispatch) {
    dispatch(requestUpdateOrganizationMember());
    return api_updateOrganizationMember(orgId, userId, params).then(response => {
      dispatch(receiveUpdateOrganizationMember());
      return response;
    });
  };
}

function requestTeams() {
  return {
    type: REQUEST_TEAMS
  };
}

function receiveTeams(data) {
  return {
    type: RECEIVE_TEAMS,
    teams: data.teams
  };
}

export function fetchTeams() {
  return function dofetch(dispatch) {
    dispatch(requestTeams());
    return api_fetchTeams().then(response => {
      dispatch(receiveTeams(response));
      return response;
    });
  };
}

function requestChangeTeam() {
  return {
    type: REQUEST_CHANGE_TEAM
  };
}

function receiveChangeTeam() {
  return {
    type: RECEIVE_CHANGE_TEAM
  };
}

export function changeTeam(teamId, name, groupingType) {
  return function dofetch(dispatch) {
    dispatch(requestChangeTeam());
    return api_updateTeam(teamId, { name, grouping_type: groupingType }).then(response => {
      dispatch(receiveChangeTeam());
      return response;
    });
  };
}

function requestDeleteTeam() {
  return {
    type: REQUEST_DELETE_TEAM
  };
}

function receiveDeleteTeam() {
  return {
    type: RECEIVE_DELETE_TEAM
  };
}

export function deleteTeam(teamId) {
  return function dofetch(dispatch) {
    dispatch(requestDeleteTeam());
    return api_deleteTeam(teamId).then(response => {
      dispatch(receiveDeleteTeam());
      return response;
    });
  };
}

function requestUpdateTeamMember() {
  return {
    type: REQUEST_UPDATE_TEAM_MEMBER
  };
}

function receiveUpdateTeamMember() {
  return {
    type: RECEIVE_UPDATE_TEAM_MEMBER
  };
}

export function updateTeamMember(teamId, userId, team_user_roles) {
  return function dofetch(dispatch) {
    dispatch(requestUpdateTeamMember());
    return api_updateTeamMember(teamId, userId, { team_user_roles }).then(response => {
      dispatch(receiveUpdateTeamMember());
      return response;
    });
  };
}

function requestAddTeams() {
  return {
    type: REQUEST_ADD_TEAMS
  };
}

function receiveAddTeams() {
  return {
    type: RECEIVE_ADD_TEAMS
  };
}

export function addTeam(name, groupingType) {
  return function dopost(dispatch) {
    dispatch(requestAddTeams());
    return api_addTeam({ name, grouping_type: groupingType }).then(response => {
      dispatch(receiveAddTeams());
      return response;
    });
  };
}

function requestAddTeamMember() {
  return {
    type: REQUEST_ADD_TEAM_MEMBER
  };
}

function receiveAddTeamMember() {
  return {
    type: RECEIVE_ADD_TEAM_MEMBER
  };
}

export function addTeamMember(teamId, user_id, team_user_roles = []) {
  return function dopost(dispatch) {
    dispatch(requestAddTeamMember());
    return api_addTeamMember(teamId, { user_id, team_user_roles }).then(response => {
      dispatch(receiveAddTeamMember());
      return response;
    });
  };
}

function requestRemoveTeamMember() {
  return {
    type: REQUEST_REMOVE_TEAM_MEMBER
  };
}

function receiveRemoveTeamMember() {
  return {
    type: RECEIVE_REMOVE_TEAM_MEMBER
  };
}

export function removeTeamMember(teamId, userId) {
  return function doDelete(dispatch) {
    dispatch(requestRemoveTeamMember());
    return api_deleteTeamMember(teamId, userId).then(response => {
      dispatch(receiveRemoveTeamMember());
      return response;
    });
  };
}

function requestAllTeamMembers() {
  return {
    type: REQUEST_ALL_TEAM_MEMBERS
  };
}

function recieveAllTeamMembers(data) {
  return {
    type: RECEIVE_ALL_TEAM_MEMBERS,
    team_members: data.team_members
  };
}

export function fetchAllTeamMembers(teamId) {
  return function dofetch(dispatch) {
    dispatch(requestAllTeamMembers());
    return api_fetchAllTeamMembers(teamId).then(response => {
      dispatch(recieveAllTeamMembers(response));
      return response;
    });
  };
}

function requestInviteToOrg() {
  return {
    type: REQUEST_INVITE_TO_ORG
  };
}

function receiveInviteToOrg() {
  return {
    type: RECEIVE_INVITE_TO_ORG
  };
}

export function inviteToOrg(params, rnToken = null) {
  return function doFetch(dispatch) {
    dispatch(requestInviteToOrg());
    return api_inviteToOrg(params.org_id, params, rnToken).then(response => {
      dispatch(receiveInviteToOrg());
      return response;
    });
  };
}

export function removeOrgInvitation(params, rnToken = null) {
  return function doFetch(dispatch) {
    return api_removeOrgInvitation(params.org_id, params.org_invitation_id, rnToken);
  };
}
