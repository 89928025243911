import type { FC } from 'react';
import type { DocumentTimelineItemProps } from './DocumentTimelineItem.types';

import * as uiLib from '@compliance.ai/web-components';

import classnames from 'classnames';

import { useDocumentTimelineItemData, useDocumentTimelineItemHandlers } from './hooks';

import './_document-timeline-item.scss';

export const DocumentTimelineItem: FC<DocumentTimelineItemProps> = ({
  document,
  date,
  dateType,
  onDocumentDiff,
  shouldShowDetails,
  shouldShowCheckbox,
  isDiffable,
  classNames
}) => {
  const { reduxState, formattedData } = useDocumentTimelineItemData({
    document
  });

  const handlers = useDocumentTimelineItemHandlers({
    props: {
      document,
      onDocumentDiff
    }
  });

  return (
    <div className="docket-timeline-item__container">
      <div className="docket-timeline-item__left-side">
        {!shouldShowCheckbox && (formattedData.isCheckedNewDoc || formattedData.isCheckedOldDoc) && (
          <div
            className={classnames({
              'docket-timeline-item__color-box': true,
              'docket-timeline-item__color-box--is-old-doc': formattedData.isCheckedOldDoc,
              'docket-timeline-item__color-box--is-new-doc': formattedData.isCheckedNewDoc
            })}
          />
        )}
        {shouldShowCheckbox && isDiffable && (
          <uiLib.Checkbox
            type={formattedData.checkboxType}
            tooltipProps={{
              title: 'Unselect a document first.',
              type: uiLib.TOOLTIP_TYPES.SECONDARY,
              isDisabled: formattedData.isCheckboxTooltipDisabled,
              placement: uiLib.TOOLTIP_POSITION.BOTTOM
            }}
            isDisabled={formattedData.isCheckboxDisabled}
            isChecked={formattedData.isCheckedNewDoc || formattedData.isCheckedOldDoc}
            onChange={handlers.handleDocumentDiff}
            classNames={{
              checkbox: 'docket-timeline-item__checkbox'
            }}
          />
        )}
      </div>
      <div
        className={classnames(classNames?.rightSide, {
          'docket-timeline-item__rigth_side': true,
          'docket-timeline-item__rigth_side--is-selected':
            formattedData.isCheckedOldDoc || formattedData.isCheckedNewDoc
        })}
      >
        <header className="docket-timeline-item__right-side-header">
          <uiLib.Typography
            type={uiLib.TYPOGRAPHY_TYPES.BODY2}
            className="docket-timeline-item__sort-type"
          >
            {dateType}
          </uiLib.Typography>
          <uiLib.Typography
            type={uiLib.TYPOGRAPHY_TYPES.BODY2}
            className="docket-timeline-item__sort-date"
            isContrast
          >
            {uiLib.formatDate(date, {
              format: uiLib.getLocalDateFormat(),
              passedTimezone: uiLib.TIMEZONES.UTC,
              timezone: uiLib.TIMEZONES.UTC,
              shouldKeepLocalTime: true
            })}
          </uiLib.Typography>
        </header>
        {shouldShowDetails && (
          <>
            <main className="docket-timeline-item__right-side-content">
              <uiLib.Typography type={uiLib.TYPOGRAPHY_TYPES.BODY2}>
                {formattedData.agencyShortNames}
              </uiLib.Typography>
              <uiLib.Typography type={uiLib.TYPOGRAPHY_TYPES.BODY2} shouldAddBottomMargin>
                {reduxState.categoryName}
              </uiLib.Typography>
              <uiLib.Typography type={uiLib.TYPOGRAPHY_TYPES.CAPTION} isContrast>
                {document.title}
              </uiLib.Typography>
            </main>
            <footer className="docket-timeline-item__right-side-footer">
              <uiLib.Button
                type={uiLib.BUTTON_TYPES.LINK_CONTRAST}
                onClick={handlers.handleClick}
                endIcon={<uiLib.IconArrowRight />}
              >
                See Details
              </uiLib.Button>
            </footer>
          </>
        )}
      </div>
    </div>
  );
};
