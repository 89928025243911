import type { Field } from 'react-awesome-query-builder';
import type {
  AgenciesSelectProps,
  AuthorsSelectProps,
  AutosuggestionSelectProps,
  CategoriesSelectProps,
  JurisdictionsSelectProps,
  LabelsSelectProps,
  LegacyDocTypesSelectProps,
  NewsAndPremiumContentSelectProps,
  RegulationsSelectProps,
  TopicsSelectProps,
  EitlLabelsSelectProps,
  ConceptsSelectProps
} from 'common';

import * as searchConstants from 'shared/features/search/search.constants';

import { MY_ORG_TASKS, MY_TASKS } from 'shared/features/filters/filters.constants';
import { JURISDICTIONS_SELECT_VALUE_KEYS } from 'common/JurisdictionsSelect';
import { AGENCIES_SELECT_VALUE_KEYS } from 'common/AgenciesSelect';
import { NEWS_AND_PREMIUM_CONTENT_SELECT_VALUE_KEYS } from 'common/NewsAndPremiumContentSelect';
import { AUTHORS_SELECT_VALUE_KEYS } from 'common/AuthorsSelect/AuthorsSelect.constants';
import * as env from '../shared/config';

export enum ADVANCED_SEARCH_KEYS {
  ACT = 'act',
  ACT_ID = 'act_id',
  AGENCY = 'agency',
  AGENCY_ID = 'agency_id',
  AUTHOR = 'author',
  AUTHOR_ID = 'author_id',
  AUTO_TRANSLATED_DOCUMENT = 'autotranslated',
  BANK = 'bank',
  BANK_ID = 'bank_id',
  BUSINESS = 'business',
  CAI_CATEGORY = 'cai_category',
  CAI_CATEGORY_LEGACY = 'category',
  DOC_TYPE_LEGACY = 'doc_type',
  CITATION = 'citation',
  CITATION_ID = 'citation_id',
  COMMENTS_CLOSE_DATE = 'comments_close_date',
  COMMENTS_CLOSE_FROM = 'comments_close_from',
  COMMENTS_CLOSE_TO = 'comments_close_to',
  EFFECTIVE_DATE = 'effective_date',
  EFFECTIVE_FROM = 'effective_from',
  EFFECTIVE_TO = 'effective_to',
  DOC_ID = 'doc_id',
  DOCKET_ID = 'docket_id',
  FULL_TEXT = 'full_text',
  HAS_PARENT = 'has_parent',
  JURISDICTION = 'jurisdiction',
  LABEL = 'label',
  LANGUAGE_ID = 'language_id',
  LOCATION_ID = 'location_id',
  LOCATION_TITLE = 'location_title',
  MONETARY_PENALTY = 'monetary_penalty',
  MONETARY_PENALTY_MAX = 'monetary_penalty_mas',
  MONETARY_PENALTY_MIN = 'monetary_penalty_min',
  NEWS_SOURCE = 'news_source',
  NEWS_SOURCE_ID = 'news_source_id',
  PUBLICATION_DATE = 'publication_date',
  HAS_UNOFFICIAL_PUB_DATE = 'has_unofficial_publication_date',
  PUBLISHED_ON = 'published_on',
  PUBLISHED_FROM = 'published_from',
  PUBLISHED_TO = 'published_to',
  REGULATION = 'regulation',
  REGULATION_ID = 'regulation_id',
  RESPONDENT = 'respondent',
  TEXT = 'text',
  TITLE = 'title',
  TOPIC = 'topic',
  TOPIC_ID = 'topic_id',
  VIOLATION = 'violation',
  HAS_INDIVIDUAL_RESPONDENT = 'has_individual_respondent',
  HAS_ENTITY_RESPONDENT = 'has_entity_respondent',
  ADDED_DATE = 'added_date',
  ADDED_FROM = 'added_from',
  ADDED_TO = 'added_to',
  UPDATED_DATE = 'updated_date',
  UPDATED_FROM = 'updated_from',
  UPDATED_TO = 'updated_to',
  MY_TASKS = 'my_tasks',
  MY_ORG_TASKS = 'my_organization_tasks',
  DIFFING_AVAILABLE = 'diffing_available',
  OBLIGATIONS = 'obligations',
  PREMIUM_CONTENT = 'premium_content',
  CONCEPT = 'concept',
  DOC_PROPERTY = 'doc_property',
  EXACT_MATCH_TEXT = 'exact_match_text',
  EXACT_MATCH_TITLE = 'exact_match_title',
  IS_ORGANIZATION_DOCUMENT = 'organization_documents_only',
  AUTO_SUMMARY_AVAILABLE = 'auto_summary_available',
  CONCEPT_ID = 'concept_id',
  EITL_LABEL_ID = 'eitl_label_id',
  HAS_EITL_LABELS = 'has_eitl_labels',
  EXCLUDE_FEDERAL_REGISTER = 'exclude_federal_register'
}

export const QUOTES_ARRAY = ['"', '“', '”'];

export enum QUERY_INPUT_TYPES {
  NUMBER = 'number',
  TEXT_BASIC = 'textBasic',
  BOOLEAN = 'boolean',
  DATE = 'date',
  RELATIVE_DATES = 'customRelativeDates',
  AUTOSUGGESTION = 'autosuggestion',
  JURISDICTION = 'jurisdiction',
  REGULATION = 'regulation',
  AUTHOR = 'author',
  TOPIC = 'topic',
  LABEL = 'label',
  NEWS_SOURCE = 'newsSource',
  AGENCY = 'agency',
  LANGUAGE = 'language',
  LEGACY_DOC_TYPES = 'docTypes',
  CATEGORIES = 'categories',
  EITL_LABEL = 'eitlLabel',
  CONCEPTS = 'concepts'
}

export enum QUERY_CONJUNCTIONS {
  AND = 'AND',
  OR = 'OR'
}

export enum QUERY_OPERATORS {
  EQUAL = 'equal',
  GREATER_OR_EQUAL = 'greater_or_equal',
  LESS_OR_EQUAL = 'less_or_equal',
  LESS = 'less',
  GRATER = 'greater',
  NOT_EQUAL = 'not_equal',
  SELECT_EQUALS = 'select_equals',
  MULTISELECT_EQUALS = 'multiselect_equals',
  MULTISELECT_NOT_EQUALS = 'multiselect_not_equals',
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  TOMORROW = 'tomorrow',
  PAST = 'past',
  NEXT = 'next'
}

export const DND_CLASSNAME = 'query-builder';

export const FIELDS: Partial<Record<
  ADVANCED_SEARCH_KEYS,
  Omit<Field, 'fieldSettings'> & {
    operators?: QUERY_OPERATORS[];
    fieldSettings?: Record<string, unknown>;
  }
>> = {
  [ADVANCED_SEARCH_KEYS.ACT]: {
    label: 'Act',
    type: QUERY_INPUT_TYPES.AUTOSUGGESTION,
    fieldSettings: {
      category: searchConstants.SEARCH_CATEGORIES.ACTS
    } as AutosuggestionSelectProps
  },
  [ADVANCED_SEARCH_KEYS.ACT_ID]: {
    label: 'Act ID',
    type: QUERY_INPUT_TYPES.NUMBER,
    fieldSettings: {
      min: 1
    }
  },
  [ADVANCED_SEARCH_KEYS.AGENCY]: {
    label: 'Agency',
    type: QUERY_INPUT_TYPES.AGENCY,
    fieldSettings: {
      isMulti: true,
      valueKey: AGENCIES_SELECT_VALUE_KEYS.SHORT_NAME,
      shouldCloseMenuOnSelect: false
    } as AgenciesSelectProps
  },
  [ADVANCED_SEARCH_KEYS.AGENCY_ID]: {
    label: 'Agency ID',
    type: QUERY_INPUT_TYPES.NUMBER,
    fieldSettings: {
      min: 1
    }
  },
  [ADVANCED_SEARCH_KEYS.CONCEPT_ID]: {
    label: 'Concept ID',
    type: QUERY_INPUT_TYPES.NUMBER,
    fieldSettings: {
      min: 1
    }
  },
  ...(env.shouldShowEitlStatus && {
    [ADVANCED_SEARCH_KEYS.HAS_EITL_LABELS]: {
      label: 'Has EITL Labels',
      type: QUERY_INPUT_TYPES.BOOLEAN
    }
  }),
  [ADVANCED_SEARCH_KEYS.AUTO_SUMMARY_AVAILABLE]: {
    label: 'Auto Summary Available',
    type: QUERY_INPUT_TYPES.BOOLEAN
  },
  [ADVANCED_SEARCH_KEYS.AUTO_TRANSLATED_DOCUMENT]: {
    label: 'Auto Translated Document',
    type: QUERY_INPUT_TYPES.BOOLEAN
  },
  [ADVANCED_SEARCH_KEYS.HAS_PARENT]: {
    label: 'Has Parent Document',
    type: QUERY_INPUT_TYPES.BOOLEAN
  },
  [ADVANCED_SEARCH_KEYS.BANK]: {
    label: 'Business',
    type: QUERY_INPUT_TYPES.AUTOSUGGESTION,
    fieldSettings: {
      category: searchConstants.SEARCH_CATEGORIES.BANKS
    } as AutosuggestionSelectProps
  },
  [ADVANCED_SEARCH_KEYS.BANK_ID]: {
    label: 'Bank ID',
    type: QUERY_INPUT_TYPES.NUMBER,
    fieldSettings: {
      min: 1
    }
  },
  [ADVANCED_SEARCH_KEYS.BUSINESS]: {
    label: 'Business ID',
    type: QUERY_INPUT_TYPES.NUMBER,
    fieldSettings: {
      min: 1
    }
  },
  [ADVANCED_SEARCH_KEYS.CAI_CATEGORY_LEGACY]: {
    label: 'Category (Legacy)',
    type: QUERY_INPUT_TYPES.LEGACY_DOC_TYPES,
    fieldSettings: {
      shouldCloseMenuOnSelect: false
    } as LegacyDocTypesSelectProps
  },
  [ADVANCED_SEARCH_KEYS.DOC_TYPE_LEGACY]: {
    label: 'Category (Legacy)',
    type: QUERY_INPUT_TYPES.LEGACY_DOC_TYPES,
    fieldSettings: {
      shouldCloseMenuOnSelect: false
    } as LegacyDocTypesSelectProps
  },
  [ADVANCED_SEARCH_KEYS.CITATION]: {
    label: 'Citation',
    type: QUERY_INPUT_TYPES.TEXT_BASIC
  },
  [ADVANCED_SEARCH_KEYS.CITATION_ID]: {
    label: 'Citation ID',
    type: QUERY_INPUT_TYPES.TEXT_BASIC
  },
  [ADVANCED_SEARCH_KEYS.COMMENTS_CLOSE_DATE]: {
    label: 'Comments Closed Date',
    type: QUERY_INPUT_TYPES.DATE,
    operators: [
      QUERY_OPERATORS.GRATER,
      QUERY_OPERATORS.GREATER_OR_EQUAL,
      QUERY_OPERATORS.LESS_OR_EQUAL,
      QUERY_OPERATORS.LESS,
      QUERY_OPERATORS.EQUAL,
      QUERY_OPERATORS.TODAY,
      QUERY_OPERATORS.YESTERDAY,
      QUERY_OPERATORS.TOMORROW,
      QUERY_OPERATORS.PAST,
      QUERY_OPERATORS.NEXT
    ]
  },
  [ADVANCED_SEARCH_KEYS.COMMENTS_CLOSE_FROM]: {
    label: 'Comments Closed From',
    type: QUERY_INPUT_TYPES.DATE,
    operators: [QUERY_OPERATORS.EQUAL]
  },
  [ADVANCED_SEARCH_KEYS.COMMENTS_CLOSE_TO]: {
    label: 'Comments Closed To',
    type: QUERY_INPUT_TYPES.DATE,
    operators: [QUERY_OPERATORS.EQUAL]
  },
  [ADVANCED_SEARCH_KEYS.CONCEPT]: {
    label: 'Concept',
    type: QUERY_INPUT_TYPES.CONCEPTS,
    fieldSettings: {
      shouldCloseMenuOnSelect: false
    } as Partial<ConceptsSelectProps>
  },
  [ADVANCED_SEARCH_KEYS.CAI_CATEGORY]: {
    label: 'Document Type',
    type: QUERY_INPUT_TYPES.CATEGORIES,
    fieldSettings: {
      shouldCloseMenuOnSelect: false
    } as CategoriesSelectProps
  },
  [ADVANCED_SEARCH_KEYS.EFFECTIVE_DATE]: {
    label: 'Effective Date',
    type: QUERY_INPUT_TYPES.DATE,
    operators: [
      QUERY_OPERATORS.GRATER,
      QUERY_OPERATORS.GREATER_OR_EQUAL,
      QUERY_OPERATORS.LESS_OR_EQUAL,
      QUERY_OPERATORS.LESS,
      QUERY_OPERATORS.EQUAL,
      QUERY_OPERATORS.TODAY,
      QUERY_OPERATORS.YESTERDAY,
      QUERY_OPERATORS.TOMORROW,
      QUERY_OPERATORS.PAST,
      QUERY_OPERATORS.NEXT
    ]
  },
  [ADVANCED_SEARCH_KEYS.EFFECTIVE_FROM]: {
    label: 'Effective From',
    type: QUERY_INPUT_TYPES.DATE,
    operators: [QUERY_OPERATORS.EQUAL]
  },
  [ADVANCED_SEARCH_KEYS.EFFECTIVE_TO]: {
    label: 'Effective To',
    type: QUERY_INPUT_TYPES.DATE,
    operators: [QUERY_OPERATORS.EQUAL]
  },
  [ADVANCED_SEARCH_KEYS.EXCLUDE_FEDERAL_REGISTER]: {
    label: 'Exclude Federal Register',
    type: QUERY_INPUT_TYPES.BOOLEAN
  },
  [ADVANCED_SEARCH_KEYS.DIFFING_AVAILABLE]: {
    label: 'Diffing Available',
    type: QUERY_INPUT_TYPES.BOOLEAN
  },
  [ADVANCED_SEARCH_KEYS.DOC_ID]: {
    label: 'Document ID',
    type: QUERY_INPUT_TYPES.NUMBER,
    fieldSettings: {
      min: 1
    }
  },
  [ADVANCED_SEARCH_KEYS.DOCKET_ID]: {
    label: 'Docket ID',
    type: QUERY_INPUT_TYPES.TEXT_BASIC,
    fieldSettings: {
      min: 1
    }
  },
  [ADVANCED_SEARCH_KEYS.FULL_TEXT]: {
    label: 'Full Text',
    type: QUERY_INPUT_TYPES.TEXT_BASIC
  },
  [ADVANCED_SEARCH_KEYS.JURISDICTION]: {
    label: 'Jurisdiction',
    type: QUERY_INPUT_TYPES.JURISDICTION,
    fieldSettings: {
      isMulti: true,
      valueKey: JURISDICTIONS_SELECT_VALUE_KEYS.SHORT_NAME,
      shouldCloseMenuOnSelect: false
    } as JurisdictionsSelectProps
  },
  [ADVANCED_SEARCH_KEYS.LABEL]: {
    label: 'Label',
    type: QUERY_INPUT_TYPES.LABEL,
    fieldSettings: {
      isMulti: true,
      shouldCloseMenuOnSelect: false
    } as LabelsSelectProps
  },
  ...(env.shouldShowEitlStatus && {
    [ADVANCED_SEARCH_KEYS.EITL_LABEL_ID]: {
      label: 'EITL Label',
      type: QUERY_INPUT_TYPES.EITL_LABEL,
      fieldSettings: {
        isMulti: true,
        shouldCloseMenuOnSelect: false
      } as EitlLabelsSelectProps
    }
  }),
  [ADVANCED_SEARCH_KEYS.LANGUAGE_ID]: {
    label: 'Language',
    type: QUERY_INPUT_TYPES.LANGUAGE
  },
  [ADVANCED_SEARCH_KEYS.LOCATION_ID]: {
    label: 'Location ID',
    type: QUERY_INPUT_TYPES.NUMBER,
    fieldSettings: {
      min: 1
    }
  },
  [ADVANCED_SEARCH_KEYS.LOCATION_TITLE]: {
    label: 'Location Title',
    type: QUERY_INPUT_TYPES.TEXT_BASIC
  },
  [ADVANCED_SEARCH_KEYS.MONETARY_PENALTY]: {
    label: 'Monetary Penalty',
    type: QUERY_INPUT_TYPES.NUMBER,
    fieldSettings: {
      min: 0
    },
    operators: [
      QUERY_OPERATORS.GREATER_OR_EQUAL,
      QUERY_OPERATORS.LESS_OR_EQUAL,
      QUERY_OPERATORS.EQUAL,
      QUERY_OPERATORS.NOT_EQUAL,
      QUERY_OPERATORS.LESS,
      QUERY_OPERATORS.GRATER
    ]
  },
  [ADVANCED_SEARCH_KEYS.MONETARY_PENALTY_MAX]: {
    label: 'Monetary Penalty Max',
    type: QUERY_INPUT_TYPES.NUMBER,
    fieldSettings: {
      min: 0
    }
  },
  [ADVANCED_SEARCH_KEYS.MONETARY_PENALTY_MIN]: {
    label: 'Monetary Penalty Min',
    type: QUERY_INPUT_TYPES.NUMBER,
    fieldSettings: {
      min: 0
    }
  },
  [ADVANCED_SEARCH_KEYS.MY_TASKS]: {
    label: MY_TASKS,
    type: QUERY_INPUT_TYPES.BOOLEAN
  },
  [ADVANCED_SEARCH_KEYS.MY_ORG_TASKS]: {
    label: MY_ORG_TASKS,
    type: QUERY_INPUT_TYPES.BOOLEAN
  },
  [ADVANCED_SEARCH_KEYS.NEWS_SOURCE]: {
    label: 'News & Premium Content',
    type: QUERY_INPUT_TYPES.NEWS_SOURCE,
    fieldSettings: {
      isMulti: true,
      shouldCloseMenuOnSelect: false,
      valueKey: NEWS_AND_PREMIUM_CONTENT_SELECT_VALUE_KEYS.NAME
    } as NewsAndPremiumContentSelectProps
  },
  [ADVANCED_SEARCH_KEYS.NEWS_SOURCE_ID]: {
    label: 'News Source ID',
    type: QUERY_INPUT_TYPES.NUMBER,
    fieldSettings: {
      min: 1
    }
  },
  [ADVANCED_SEARCH_KEYS.OBLIGATIONS]: {
    label: 'Obligations',
    type: QUERY_INPUT_TYPES.BOOLEAN
  },
  [ADVANCED_SEARCH_KEYS.PREMIUM_CONTENT]: {
    label: 'Premium Content',
    type: QUERY_INPUT_TYPES.BOOLEAN
  },
  [ADVANCED_SEARCH_KEYS.PUBLICATION_DATE]: {
    label: 'Publication Date',
    type: QUERY_INPUT_TYPES.DATE,
    operators: [
      QUERY_OPERATORS.GRATER,
      QUERY_OPERATORS.GREATER_OR_EQUAL,
      QUERY_OPERATORS.LESS_OR_EQUAL,
      QUERY_OPERATORS.LESS,
      QUERY_OPERATORS.EQUAL,
      QUERY_OPERATORS.TODAY,
      QUERY_OPERATORS.YESTERDAY,
      QUERY_OPERATORS.TOMORROW,
      QUERY_OPERATORS.PAST
    ]
  },
  [ADVANCED_SEARCH_KEYS.HAS_UNOFFICIAL_PUB_DATE]: {
    label: 'Include Unofficial Publication Date',
    type: QUERY_INPUT_TYPES.BOOLEAN
  },
  [ADVANCED_SEARCH_KEYS.PUBLISHED_ON]: {
    label: 'Published On',
    type: QUERY_INPUT_TYPES.DATE,
    operators: [
      QUERY_OPERATORS.GRATER,
      QUERY_OPERATORS.GREATER_OR_EQUAL,
      QUERY_OPERATORS.LESS_OR_EQUAL,
      QUERY_OPERATORS.LESS,
      QUERY_OPERATORS.EQUAL,
      QUERY_OPERATORS.TODAY,
      QUERY_OPERATORS.YESTERDAY,
      QUERY_OPERATORS.TOMORROW,
      QUERY_OPERATORS.PAST
    ]
  },
  [ADVANCED_SEARCH_KEYS.PUBLISHED_FROM]: {
    label: 'Published From',
    type: QUERY_INPUT_TYPES.DATE,
    operators: [QUERY_OPERATORS.EQUAL]
  },
  [ADVANCED_SEARCH_KEYS.PUBLISHED_TO]: {
    label: 'Published To',
    type: QUERY_INPUT_TYPES.DATE,
    operators: [QUERY_OPERATORS.EQUAL]
  },
  [ADVANCED_SEARCH_KEYS.REGULATION]: {
    label: 'Regulation',
    type: QUERY_INPUT_TYPES.REGULATION,
    fieldSettings: {
      isMulti: true,
      shouldCloseMenuOnSelect: false
    } as RegulationsSelectProps
  },
  [ADVANCED_SEARCH_KEYS.REGULATION_ID]: {
    label: 'Regulation ID',
    type: QUERY_INPUT_TYPES.NUMBER,
    fieldSettings: {
      min: 1
    }
  },
  [ADVANCED_SEARCH_KEYS.RESPONDENT]: {
    label: 'Respondent',
    type: QUERY_INPUT_TYPES.TEXT_BASIC
  },
  [ADVANCED_SEARCH_KEYS.TEXT]: {
    label: 'Text',
    type: QUERY_INPUT_TYPES.TEXT_BASIC
  },
  [ADVANCED_SEARCH_KEYS.EXACT_MATCH_TEXT]: {
    label: 'Exact match text',
    type: QUERY_INPUT_TYPES.TEXT_BASIC
  },
  [ADVANCED_SEARCH_KEYS.TITLE]: {
    label: 'Title',
    type: QUERY_INPUT_TYPES.TEXT_BASIC
  },
  [ADVANCED_SEARCH_KEYS.EXACT_MATCH_TITLE]: {
    label: 'Exact match title',
    type: QUERY_INPUT_TYPES.TEXT_BASIC
  },
  [ADVANCED_SEARCH_KEYS.TOPIC]: {
    label: 'Topic',
    type: QUERY_INPUT_TYPES.TOPIC,
    fieldSettings: {
      isMulti: true,
      shouldCloseMenuOnSelect: false
    } as TopicsSelectProps
  },
  [ADVANCED_SEARCH_KEYS.TOPIC_ID]: {
    label: 'Topic ID',
    type: QUERY_INPUT_TYPES.NUMBER,
    fieldSettings: {
      min: 1
    }
  },
  [ADVANCED_SEARCH_KEYS.VIOLATION]: {
    label: 'Violation',
    type: QUERY_INPUT_TYPES.TEXT_BASIC
  },
  [ADVANCED_SEARCH_KEYS.HAS_INDIVIDUAL_RESPONDENT]: {
    label: 'Has Individual Respondent',
    type: QUERY_INPUT_TYPES.BOOLEAN
  },
  [ADVANCED_SEARCH_KEYS.HAS_ENTITY_RESPONDENT]: {
    label: 'Has Entity Respondent',
    type: QUERY_INPUT_TYPES.BOOLEAN
  },
  [ADVANCED_SEARCH_KEYS.ADDED_DATE]: {
    label: 'Added to Compliance.ai',
    type: QUERY_INPUT_TYPES.DATE,
    operators: [
      QUERY_OPERATORS.GRATER,
      QUERY_OPERATORS.GREATER_OR_EQUAL,
      QUERY_OPERATORS.LESS_OR_EQUAL,
      QUERY_OPERATORS.LESS,
      QUERY_OPERATORS.EQUAL,
      QUERY_OPERATORS.TODAY,
      QUERY_OPERATORS.YESTERDAY,
      QUERY_OPERATORS.TOMORROW,
      QUERY_OPERATORS.PAST
    ]
  },
  [ADVANCED_SEARCH_KEYS.ADDED_FROM]: {
    label: 'Added to Compliance.ai From',
    type: QUERY_INPUT_TYPES.DATE,
    operators: [QUERY_OPERATORS.EQUAL]
  },
  [ADVANCED_SEARCH_KEYS.ADDED_TO]: {
    label: 'Added to Compliance.ai To',
    type: QUERY_INPUT_TYPES.DATE,
    operators: [QUERY_OPERATORS.EQUAL]
  },
  [ADVANCED_SEARCH_KEYS.UPDATED_DATE]: {
    label: 'Updated in Compliance.ai',
    type: QUERY_INPUT_TYPES.DATE,
    operators: [
      QUERY_OPERATORS.GRATER,
      QUERY_OPERATORS.GREATER_OR_EQUAL,
      QUERY_OPERATORS.LESS_OR_EQUAL,
      QUERY_OPERATORS.LESS,
      QUERY_OPERATORS.EQUAL,
      QUERY_OPERATORS.TODAY,
      QUERY_OPERATORS.YESTERDAY,
      QUERY_OPERATORS.TOMORROW,
      QUERY_OPERATORS.PAST
    ]
  },
  [ADVANCED_SEARCH_KEYS.UPDATED_FROM]: {
    label: 'Updated in Compliance.ai From',
    type: QUERY_INPUT_TYPES.DATE,
    operators: [QUERY_OPERATORS.EQUAL]
  },
  [ADVANCED_SEARCH_KEYS.UPDATED_TO]: {
    label: 'Updated in Compliance.ai To',
    type: QUERY_INPUT_TYPES.DATE,
    operators: [QUERY_OPERATORS.EQUAL]
  }
};

export const BYOC_FIELDS = {
  [ADVANCED_SEARCH_KEYS.AUTHOR]: {
    label: 'Author',
    type: QUERY_INPUT_TYPES.AUTHOR,
    fieldSettings: {
      isMulti: true,
      isAsync: true,
      shouldCloseMenuOnSelect: false
    } as AuthorsSelectProps
  },
  [ADVANCED_SEARCH_KEYS.AUTHOR_ID]: {
    label: 'Author ID',
    type: QUERY_INPUT_TYPES.NUMBER,
    fieldSettings: {
      min: 1
    }
  },
  [ADVANCED_SEARCH_KEYS.IS_ORGANIZATION_DOCUMENT]: {
    label: 'Organization Document',
    type: QUERY_INPUT_TYPES.BOOLEAN
  }
};

export enum QUERY_TRANSLATION_TYPES {
  IN = 'in',
  EXACT = 'exact',
  IN_OR_EXACT = 'inOrExact',
  DATE_WITH_MODIFIERS = 'dateWithModifiers',
  TEXT_WITH_MODIFIERS = 'textWithModifiers',
  DOC_PROPERTIES = 'docProperties',
  EITL_LABELS = 'eitlLabels'
}
