import React from 'react';
import { taskPanelDefaultSummaryTextLength as defaultSummaryTextLength } from 'constants/Render';
import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import { FieldName } from '../Metadata/elements';

export class SummaryContent extends React.Component {
  state = {
    expandedSummary: false,
    summaryLen: defaultSummaryTextLength
  };

  showMoreSummary(summaryLength) {
    if (this.state.expandedSummary) {
      this.setState({ summaryLen: defaultSummaryTextLength, expandedSummary: false });
    } else {
      this.setState({ summaryLen: summaryLength, expandedSummary: true });
    }
  }

  render() {
    const { text } = this.props;
    const { expandedSummary, summaryLen } = this.state;
    const summarySuffix = '...';

    // replace some gnarly quote chars with real quote chars that appear in some parts of the data
    // FIXME: this is better resolved upstream
    const formattedSummary = text.replace(/(&ldquo;|&rdquo;)/g, '"');

    return (
      <section>
        <FieldName className="document-summary__label">Summary</FieldName>
        {formattedSummary && (
          <div className="summary">
            <p>
              {formattedSummary.length > summaryLen && !expandedSummary
                ? `${formattedSummary.slice(0, summaryLen - summarySuffix.length)}${summarySuffix}`
                : formattedSummary}
            </p>
            {formattedSummary.length > defaultSummaryTextLength && (
              <div className="read-more-summary">
                <Button
                  type={BUTTON_TYPES.LINK_CONTRAST}
                  onClick={() => this.showMoreSummary(formattedSummary.length)}
                >
                  {expandedSummary ? 'See Less' : 'See More'}
                </Button>
              </div>
            )}
          </div>
        )}
      </section>
    );
  }
}
