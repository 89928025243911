import { DEFAULT_TYPES } from 'constants/DefaultSources';
import { getFollowedAgenciesIds } from 'shared/features/defaults/defaults.selectors';
import { api_fetchStatistics } from './analytics.api';
import { getUserAuthenticatedState } from '../auth/auth.selectors';

export const REQUEST_STATISTICS = 'REQUEST_STATISTICS';
export const RECEIVE_STATISTICS = 'RECEIVE_STATISTICS';
function requestStatistics() {
  return {
    type: REQUEST_STATISTICS
  };
}

function receiveStatistics(json) {
  return {
    type: RECEIVE_STATISTICS,
    statistics: json
  };
}

export const fetchStatistics = data => async (dispatch, getState) => {
  const isAuthenticated = getUserAuthenticatedState(getState());

  if (!isAuthenticated) {
    data.agency_id = getFollowedAgenciesIds(DEFAULT_TYPES.MY_DEFAULTS)(getState());
  }

  dispatch(requestStatistics());

  const response = await api_fetchStatistics(data);

  dispatch(receiveStatistics(response));

  return response;
};
