import type { DashboardPDFOverlayData } from './useDashboardPDFOverlayData.types';
import type { DashboardPDFOverlayHandlers } from './useDashboardPDFOverlayHandlers.types';

import * as pdfObject from 'pdfobject';
import * as constants from '../DashboardPDFOverlay.constants';

import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';
import { useCallback } from 'react';

export const useDashboardPDFOverlayHandlers = ({
  reduxState
}: {
  reduxState: DashboardPDFOverlayData['reduxState'];
}): DashboardPDFOverlayHandlers => {
  const rightPanelReduxActions = useRightPanelReduxActions();

  const handlePDFLoad: DashboardPDFOverlayHandlers['handlePDFLoad'] = useCallback(() => {
    // Dummy condition to make linter happy about hook deps
    if (reduxState.overlayRefreshTrigger) {
    }

    pdfObject.embed(reduxState.documentPDFUrl, constants.DASHBOARD_PDF_OVERLAY);
  }, [reduxState.documentPDFUrl, reduxState.overlayRefreshTrigger]);

  const handleOverlayClose: DashboardPDFOverlayHandlers['handleOverlayClose'] = () => {
    if (reduxState.shouldCloseRightPanelOnOverlayClose) {
      rightPanelReduxActions.closeRightPanel();
    } else {
      rightPanelReduxActions.closeOverlay();
    }
  };

  return {
    handlePDFLoad,
    handleOverlayClose
  };
};
