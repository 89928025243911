import { useDispatch } from 'react-redux';
import { selectSentences as reduxSelectSentences } from '../obligations.actions';
import { useCallback, useMemo } from 'react';

export const useObligationsReduxActions = () => {
  const dispatch = useDispatch();

  const selectSentences = useCallback(
    (firstSentenceId, lastSentenceId, startIndex, endIndex) => {
      dispatch(reduxSelectSentences(firstSentenceId, lastSentenceId, startIndex, endIndex));
    },
    [dispatch]
  );

  const deselectSentences = useCallback(() => {
    dispatch(reduxSelectSentences(null, null));
  }, [dispatch]);

  return useMemo(
    () => ({
      selectSentences,
      deselectSentences
    }),
    [deselectSentences, selectSentences]
  );
};
