import type { graphlib } from 'dagre';
import type { ElementPosition, HandleCoordinates } from './WorkflowDiagramTool.types';
import type {
  DetailedWorkflowFromResponse,
  WorkflowTaskFromResponse
} from 'shared/features/workflow/workflow.types';
import type { ReactNode } from 'react';

import dagre from 'dagre';

import * as reactFlow from 'reactflow';
import * as constants from './WorkflowDiagramTool.constants';
import * as elements from './elements';

/**
 * FIXME Currently, nodes width and height are hardcoded.
 * TODO Change NODE_GRAPH_WIDTH and NODE_GRAPH_HEIGHT to calculated dimensions from lib's state
 */

/**
 *
 * Initializes Dagre graph and returns it's instance.
 * This graph is being used to set initial nodes layout any
 * without complex calculations.
 *
 */
export const initializeGraph = (
  diagramElements: (reactFlow.Edge | reactFlow.Node)[]
): graphlib.Graph => {
  const graph = new dagre.graphlib.Graph();

  graph.setDefaultEdgeLabel(() => ({}));
  graph.setGraph({ rankdir: constants.GRAPH_DIRECTIONS.FROM_TOP_TO_BOTTOM });

  diagramElements.forEach(element => {
    if (reactFlow.isNode(element)) {
      graph.setNode(element.id, {
        width: constants.NODE_GRAPH_WIDTH,
        height: constants.NODE_GRAPH_HEIGHT
      });
    } else {
      graph.setEdge(element.source, element.target);
    }
  });

  dagre.layout(graph);

  return graph;
};

/**
 *
 * Updates node's position based on it's dimensions and position in Dagre graph
 *
 */
export const updatePositionBasedOnGraph = (dagreGraph: graphlib.Graph) => (
  element: reactFlow.Edge | reactFlow.Node
) => {
  if (reactFlow.isNode(element)) {
    const nodeWithPosition = dagreGraph.node(element.id);

    return {
      ...element,
      position: {
        x: nodeWithPosition.x - constants.NODE_GRAPH_WIDTH / 2,
        y: nodeWithPosition.y - constants.NODE_GRAPH_HEIGHT / 2
      }
    };
  }

  return element;
};

/**
 *
 * Set's closest source and target handles for the graph edges.
 * By default, the library set's them as TOP and BOTTOM.
 *
 */
export const updateEdgesHandles = (
  graphPositionedNodes: reactFlow.Node[],
  graphPositionedEdges: reactFlow.Edge[]
) => (node: reactFlow.Node) => {
  const sourceNodeEdges = graphPositionedEdges.filter(
    edge => Number(node.id) === Number(edge.source)
  );

  return sourceNodeEdges.map(edge => {
    const targetNode = graphPositionedNodes.find(
      n => Number(n.id) === Number(edge.target)
    ) as reactFlow.Node;
    const sourceNode = graphPositionedNodes.find(
      n => Number(n.id) === Number(edge.source)
    ) as reactFlow.Node;

    const targetHandle = getClosestTargetHandle({
      targetNode,
      sourceNode
    });

    const sourceHandle = getClosestSourceHandle({
      targetNode,
      sourceNode
    });

    return {
      ...edge,
      targetHandle: `${targetNode.id}-${constants.NODE_LINK_POINT_TYPES.TARGET}-${targetHandle}`,
      sourceHandle: `${sourceNode.id}-${constants.NODE_LINK_POINT_TYPES.SOURCE}-${sourceHandle}`
    };
  });
};

/**
 *
 * Gets node edge handles coords
 *
 */
export const getHandlesCoordinates = (node: reactFlow.Node): HandleCoordinates => {
  const topHandleCoordinates = {
    x: constants.NODE_GRAPH_WIDTH / 2 + node.position.x,
    y: node.position.y
  };

  const rightHandleCoordinates = {
    x: constants.NODE_GRAPH_WIDTH + node.position.x,
    y: constants.NODE_GRAPH_HEIGHT / 2 + node.position.y
  };

  const bottomHandleCoordinates = {
    x: constants.NODE_GRAPH_WIDTH / 2 + node.position.x,
    y: constants.NODE_GRAPH_HEIGHT + node.position.y
  };

  const leftHandleCoordinates = {
    x: node.position.x,
    y: constants.NODE_GRAPH_HEIGHT / 2 + node.position.y
  };

  return {
    top: topHandleCoordinates,
    right: rightHandleCoordinates,
    bottom: bottomHandleCoordinates,
    left: leftHandleCoordinates
  };
};

/**
 *
 * Gets closest handle from given coords
 *
 */
export const getClosestHandleFromPosition = ({
  position,
  handleCoords
}: {
  position: ElementPosition;
  handleCoords: HandleCoordinates;
}) => {
  const vectorLengthToTop = calculateLengthBetweenPoints(handleCoords.top, position);
  const vectorLengthToRight = calculateLengthBetweenPoints(handleCoords.right, position);
  const vectorLengthToBottom = calculateLengthBetweenPoints(handleCoords.bottom, position);
  const vectorLengthToLeft = calculateLengthBetweenPoints(handleCoords.left, position);

  const vectorLengths = [
    vectorLengthToTop,
    vectorLengthToRight,
    vectorLengthToBottom,
    vectorLengthToLeft
  ];

  if (vectorLengthToTop === Math.min(...vectorLengths)) {
    return constants.NODE_LINK_POINT_POSITIONS.TOP;
  }

  if (vectorLengthToRight === Math.min(...vectorLengths)) {
    return constants.NODE_LINK_POINT_POSITIONS.RIGHT;
  }

  if (vectorLengthToBottom === Math.min(...vectorLengths)) {
    return constants.NODE_LINK_POINT_POSITIONS.BOTTOM;
  }

  if (vectorLengthToLeft === Math.min(...vectorLengths)) {
    return constants.NODE_LINK_POINT_POSITIONS.LEFT;
  }

  return constants.NODE_LINK_POINT_POSITIONS.TOP;
};

/**
 *
 * Retrieves a center point of a given node
 *
 */
export const getCenterOfNode = (node: reactFlow.Node) => {
  return {
    x: constants.NODE_GRAPH_WIDTH / 2 + node.position.x,
    y: constants.NODE_GRAPH_HEIGHT / 2 + node.position.y
  };
};

/**
 *
 * Retrieves position of the closest target node handle
 *
 */
export const getClosestTargetHandle = ({
  sourceNode,
  targetNode
}: {
  sourceNode: reactFlow.Node;
  targetNode: reactFlow.Node;
}): constants.NODE_LINK_POINT_POSITIONS => {
  return getClosestHandleFromPosition({
    position: getCenterOfNode(sourceNode),
    handleCoords: getHandlesCoordinates(targetNode)
  });
};

/**
 *
 * Retrieves position of the closest source node handle
 *
 */
export const getClosestSourceHandle = ({
  sourceNode,
  targetNode
}: {
  sourceNode: reactFlow.Node;
  targetNode: reactFlow.Node;
}) => {
  const closestTargetHandle = getClosestTargetHandle({
    targetNode,
    sourceNode
  });
  const targetHandlesCoordinates = getHandlesCoordinates(targetNode);
  const targetHandlePositions = {
    [constants.NODE_LINK_POINT_POSITIONS.TOP]: targetHandlesCoordinates.top,
    [constants.NODE_LINK_POINT_POSITIONS.RIGHT]: targetHandlesCoordinates.right,
    [constants.NODE_LINK_POINT_POSITIONS.BOTTOM]: targetHandlesCoordinates.bottom,
    [constants.NODE_LINK_POINT_POSITIONS.LEFT]: targetHandlesCoordinates.left
  };
  const sourceHandlesCoordinates = getHandlesCoordinates(sourceNode);

  return getClosestHandleFromPosition({
    position: targetHandlePositions[closestTargetHandle],
    handleCoords: sourceHandlesCoordinates
  });
};

/**
 *
 * Finds length between two points
 *
 */
export const calculateLengthBetweenPoints = (
  pointA: ElementPosition,
  pointB: ElementPosition
): number => {
  return Math.sqrt(Math.pow(pointB.x - pointA.x, 2) + Math.pow(pointB.y - pointA.y, 2));
};

/**
 *
 * Set's initial positioning for the diagram elements
 *
 */
export const getLayoutedElements = (
  diagramElements: (reactFlow.Edge | reactFlow.Node)[]
): {
  nodes: reactFlow.Node[];
  edges: reactFlow.Edge[];
} => {
  const graph = initializeGraph(diagramElements);
  const graphPositionedElements = diagramElements.map(updatePositionBasedOnGraph(graph));

  const graphPositionedNodes: reactFlow.Node[] = graphPositionedElements.filter(reactFlow.isNode);
  const graphPositionedEdges: reactFlow.Edge[] = graphPositionedElements.filter(reactFlow.isEdge);

  return {
    /**
     * updateEdgesHandles is required to set closest source and target handles for
     * the graph edges. By default, the library set's them as TOP and BOTTOM.
     */
    edges: graphPositionedNodes.flatMap(
      updateEdgesHandles(graphPositionedNodes, graphPositionedEdges)
    ),
    nodes: graphPositionedNodes
  };
};

/**
 *
 * Converts workflow steps into diagram nodes
 *
 */
export const formatTasksForDiagram = (workflow: DetailedWorkflowFromResponse): reactFlow.Node[] => {
  return workflow.tasks.map(task => {
    return {
      id: `${task.id}`,
      type: constants.NODE_TYPES.TASK,
      position: constants.NODE_INITIAL_POSITION,
      data: {
        task,
        workflow
      }
    };
  });
};

/**
 *
 * Creates a direct dependency edge ID based on task and dependent task
 *
 */
export const formatDirectDependencyEdgeId = ({
  dependentTask,
  task
}: {
  dependentTask: WorkflowTaskFromResponse;
  task: WorkflowTaskFromResponse;
}): string => {
  return `${dependentTask.id}-${task.id}`;
};

/**
 *
 * Determines if task has any direct dependencies and returns
 * formatted diagram edges
 *
 */
export const formatTaskDirectDependenciesLinks = ({
  task,
  tasks
}: {
  task: WorkflowTaskFromResponse;
  tasks: WorkflowTaskFromResponse[];
}): reactFlow.Edge[] => {
  if (!Array.isArray(task.dependencies)) {
    return [];
  }

  return task.dependencies
    .map(dependency => {
      const dependentTask = tasks.find(task => {
        return task.id === dependency.dependent_task_id;
      });

      if (!dependentTask) {
        return null;
      }

      return {
        id: formatDirectDependencyEdgeId({ dependentTask, task }),
        source: `${dependentTask.id}`,
        target: `${task.id}`,
        label: null,
        labelShowBg: false,
        markerStart: {
          type: reactFlow.MarkerType.ArrowClosed
        }
      };
    })
    .filter(Boolean) as reactFlow.Edge[];
};

/**
 *
 * Creates a date dependency edge ID based on task and dependent task
 *
 */
export const formatDateDependencyEdgeId = ({
  dependentTask,
  task
}: {
  dependentTask: WorkflowTaskFromResponse;
  task: WorkflowTaskFromResponse;
}): string => {
  return `${dependentTask.id}-${task.id}-date`;
};

/**
 *
 * Determines if task has any date dependencies with other task and returns
 * formatted diagram edges
 *
 */
export const formatTaskDateDependencyLink = ({
  task,
  tasks
}: {
  task: WorkflowTaskFromResponse;
  tasks: WorkflowTaskFromResponse[];
}): reactFlow.Edge[] => {
  const doesDateDependsOnAnotherTask = Boolean(task.task_date_dependency);

  if (!doesDateDependsOnAnotherTask) {
    return [];
  }

  const dateDependencyTask = tasks.find(workflowTask => {
    return workflowTask.id === task.task_date_dependency?.task_id;
  });

  if (!dateDependencyTask) {
    return [];
  }

  return [
    {
      id: formatDateDependencyEdgeId({
        dependentTask: dateDependencyTask,
        task: task
      }),
      source: `${dateDependencyTask.id}`,
      target: `${task.id}`,
      label: (
        <elements.TaskEdgeLabel
          daysCount={task.task_date_dependency?.offset ? -task.task_date_dependency.offset : 0}
          dateType={constants.DATE_TYPES.TASK_DATE_DEPENDENCY}
        />
      ),
      labelShowBg: false,
      markerStart: {
        type: reactFlow.MarkerType.ArrowClosed
      }
    }
  ];
};

/**
 *
 * Finds an edges in the list of edges by another edge's source and target
 *
 */
export const findByEqualSourceAndTarget = ({
  edge,
  edges
}: {
  edge: reactFlow.Edge;
  edges: reactFlow.Edge[];
}) => {
  return edges.find(e => isEqualSourceAndTarget(e, edge));
};

/**
 *
 * Checks of edges have same source and target
 *
 */
export const isEqualSourceAndTarget = (edge1: reactFlow.Edge, edge2: reactFlow.Edge) => {
  return (
    Number(edge1.source) === Number(edge2.source) && Number(edge1.target) === Number(edge2.target)
  );
};

/**
 *
 * Merges direct dependencies link labels with the date ones if the source and target for
 * such links are the same.
 *
 */
export const mergeLinks = ({
  taskDirectDependenciesLinks,
  taskDateDependenciesLinks
}: {
  taskDirectDependenciesLinks: reactFlow.Edge[];
  taskDateDependenciesLinks: reactFlow.Edge[];
}) => {
  return taskDirectDependenciesLinks.reduce((mergedLinks, directDependencyLink) => {
    const existingDateDependencyLink = findByEqualSourceAndTarget({
      edge: directDependencyLink,
      edges: taskDateDependenciesLinks
    });

    if (!existingDateDependencyLink) {
      return [...mergedLinks, directDependencyLink];
    }

    const linksWithRemovedDateDependency = mergedLinks.filter(
      link => !isEqualSourceAndTarget(existingDateDependencyLink, link)
    );

    const linkWithMergedLabels = {
      ...directDependencyLink,
      label: (
        <elements.TaskEdgeLabelsCombiner
          labels={[directDependencyLink.label, existingDateDependencyLink.label] as ReactNode[]}
        />
      )
    };

    return [...linksWithRemovedDateDependency, linkWithMergedLabels];
  }, taskDateDependenciesLinks);
};

/**
 *
 * Converts workflow steps dependencies into diagram edges
 *
 */
export const formatTasksLinksForDiagram = (tasks: WorkflowTaskFromResponse[]) => {
  return tasks.reduce((tasksLinks, task) => {
    const taskDirectDependenciesLinks = formatTaskDirectDependenciesLinks({
      task,
      tasks
    });

    const taskDateDependenciesLinks = formatTaskDateDependencyLink({
      task,
      tasks
    });

    const taskDependenciesLinks = mergeLinks({
      taskDirectDependenciesLinks,
      taskDateDependenciesLinks
    });

    return [...tasksLinks, ...taskDependenciesLinks];
  }, [] as reactFlow.Edge[]);
};
