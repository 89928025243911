import classnames from 'classnames';
import { Switch } from '@compliance.ai/web-components';
import { restrict } from 'components/Restrict';
import RestrictBubble from 'components/RestrictBubble';
import { useSearchFieldTypeSwitchData, useSearchFieldTypeSwitchHandlers } from './hooks';
import './_search-field-type-switch.scss';

export const TEST_IDS = {
  CONTAINER: 'container',
  BASIC_LABEL: 'basic-label',
  SWITCH: 'search-field-switch',
  ADVANCED_LABEL: 'advanced-label',
  INFO_ICON: 'info-icon'
};

export const SearchFieldTypeSwitch = ({ isAdvancedSearch, onSwitch }) => {
  const { reduxState } = useSearchFieldTypeSwitchData();

  const handlers = useSearchFieldTypeSwitchHandlers({
    props: {
      isAdvancedSearch,
      onSwitch
    }
  });

  return (
    <div data-testid={TEST_IDS.CONTAINER} className="search-field-type-switch__container">
      <label
        data-testid={TEST_IDS.BASIC_LABEL}
        className={classnames('search-field-type-switch__basic-search-label', {
          'search-field-type-switch__basic-search-label--is-active': !isAdvancedSearch
        })}
      >
        Basic
      </label>
      <Switch
        isChecked={isAdvancedSearch}
        onChange={restrict(handlers.handleSwitchClick, this, 'Advanced Search')}
        className="search-field-type-switch__switch"
        dataTestId={TEST_IDS.SWITCH}
      />
      {!reduxState.isAuthenticated ? <RestrictBubble fixed advancedSearchSwitch /> : null}
      <label
        data-testid={TEST_IDS.ADVANCED_LABEL}
        className={classnames('search-field-type-switch__advanced-search-label', {
          'search-field-type-switch__advanced-search-label--is-active': isAdvancedSearch
        })}
      >
        Advanced
      </label>
      <div
        className={classnames('search-field-type-switch__info-icon', {
          'search-field-type-switch__info-icon--is-active': isAdvancedSearch
        })}
        onClick={handlers.handleRedirectToSupport}
        data-testid={TEST_IDS.INFO_ICON}
      >
        <i className="fa fa-info-circle fa-fw" />
      </div>
    </div>
  );
};
