import type {
  DeleteRelatedDocumentsModalData,
  DeleteRelatedDocumentsModalLocalState
} from './useDeleteRelatedDocumentsModalData.types';
import type { DeleteRelatedDocumentsModalProps } from '../DeleteRelatedDocumentsModal.types';
import type { GlobalState } from 'shared/reducers';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDocumentDetailsFetchingState } from 'shared/features/documents/documents.selectors';
import { ORGANIZATION_RELATED_DOCS_BY_DOC_ID_QUERY_KEY } from '../DeleteRelatedDocumentsModal.constants';

export const useDeleteRelatedDocumentsModalData = ({
  props
}: {
  props: Pick<DeleteRelatedDocumentsModalProps, 'documentId'>;
}): DeleteRelatedDocumentsModalData => {
  const [relatedDocIds, setRelatedDocIds] = useState<
    DeleteRelatedDocumentsModalLocalState['relatedDocIds']
  >([]);
  const [requestParams, setRequestParams] = useState<
    DeleteRelatedDocumentsModalLocalState['requestParams']
  >({});

  const localState: DeleteRelatedDocumentsModalData['localState'] = {
    relatedDocIds,
    requestParams
  };

  const localActions: DeleteRelatedDocumentsModalData['localActions'] = useMemo(
    () => ({
      setRelatedDocIds,
      setRequestParams
    }),
    []
  );

  const formattedData: DeleteRelatedDocumentsModalData['formattedData'] = useMemo(
    () => ({
      requestParams: {
        [ORGANIZATION_RELATED_DOCS_BY_DOC_ID_QUERY_KEY]: props.documentId,
        ...requestParams
      }
    }),
    [props, requestParams]
  );

  const reduxState = useSelector<GlobalState, DeleteRelatedDocumentsModalData['reduxState']>(
    state => ({
      isFetching: getDocumentDetailsFetchingState(state)
    })
  );

  return {
    localState,
    localActions,
    formattedData,
    reduxState
  };
};
