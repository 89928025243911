import type { DocumentsSortProps } from '../DocumentsSort.types';
import type { DocumentsHandlers } from './useDocumentsHandlers.types';

export const useDocumentsHandlers = ({
  props
}: {
  props: Pick<DocumentsSortProps, 'onSortChange' | 'sort'>;
}): DocumentsHandlers => {
  const handleSortTypeChange: DocumentsHandlers['handleSortTypeChange'] = option => {
    if (!Array.isArray(option)) {
      props.onSortChange({
        ...props.sort,
        sortType: option?.value
      });
    }
  };

  const handleSortOrderChange: DocumentsHandlers['handleSortOrderChange'] = option => {
    if (!Array.isArray(option)) {
      props.onSortChange({
        ...props.sort,
        sortOrder: option?.value
      });
    }
  };

  return {
    handleSortTypeChange,
    handleSortOrderChange
  };
};
