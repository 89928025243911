import type { DocumentsFiltersData } from './useDocumentsFiltersData.types';

import { useMemo, useState } from 'react';
import { DOCUMENTS_FILTERS_DEFAULT_VALUES } from '../DocumentsFilters.constants';

export const useDocumentsFiltersData = (): DocumentsFiltersData => {
  const [values, setValues] = useState(DOCUMENTS_FILTERS_DEFAULT_VALUES);
  const [searchValue, setSearchValue] = useState('');
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [advancedSearch, setAdvancedSearch] = useState('');

  const localState: DocumentsFiltersData['localState'] = {
    values,
    searchValue,
    isAdvancedSearch,
    isDialogOpen,
    advancedSearch
  };

  const localActions: DocumentsFiltersData['localActions'] = useMemo(
    () => ({
      setValues,
      setSearchValue,
      setIsAdvancedSearch,
      setIsDialogOpen,
      setAdvancedSearch
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
