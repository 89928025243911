import { useSelector } from 'react-redux';
import { getTaskDetailsDocId } from 'shared/features/view/view.selectors';
import { getPremiumContentSourceById } from 'shared/features/premiumContent/premiumContent.selectors';
import { getCategories, getCategoryNameByID } from 'shared/features/documents/documents.selectors';
import { getSourceNames } from './useDocumentLinkData.helpers';
import {
  checkPDFAvailability,
  checkSourceLinkAvailability,
  documentIsPremiumContent,
  userCanAccessDocument
} from 'utils/documents/documentsAccess';
import { getUserEmail } from 'shared/features/user/user.selectors';

export const useDocumentLinkData = ({ document }) => {
  const reduxState = useSelector(state => ({
    taskDetailsDocId: getTaskDetailsDocId(state),
    currentUserEmail: getUserEmail(state),
    premiumContentSource: getPremiumContentSourceById(document?.mainstream_news?.news_source?.id)(
      state
    ),
    categories: getCategories(state)
  }));

  const isPremiumContent = documentIsPremiumContent({ document });

  const documentCategory = getCategoryNameByID(reduxState.categories, document.cai_category_id);

  const premiumContentSourceId = reduxState.premiumContentSource?.id;
  const premiumContentSourceURL = reduxState.premiumContentSource?.url_home;
  const premiumContentSourceAuthType = reduxState.premiumContentSource?.auth_schema.type;
  const premiumContentSourceName = getSourceNames({
    documentCategory,
    document
  });

  const canAccessPCDocument = userCanAccessDocument({ document });
  const canShowPDFButton = checkPDFAvailability(document);
  const canShowSourceLink = checkSourceLinkAvailability(document);
  const documentId = document.id;

  return {
    reduxState,
    formattedData: {
      isPremiumContent,
      canAccessPCDocument,
      premiumContentSourceId,
      premiumContentSourceURL,
      premiumContentSourceAuthType,
      premiumContentSourceName,
      canShowPDFButton,
      canShowSourceLink,
      documentId
    }
  };
};
