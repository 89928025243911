import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getJWTToken } from 'shared/features/auth/auth.selectors';

export const FileLink = ({ href, children }) => {
  const JWTToken = useSelector(getJWTToken);

  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={href ? `${href}?access_token=${JWTToken}` : ''}
    >
      {children}
    </a>
  );
};

FileLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node
};
