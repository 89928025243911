import type { DeleteRelatedDocumentsModalHandlers } from './useDeleteRelatedDocumentsModalHandlers.types';
import type { DeleteRelatedDocumentsModalLocalActions } from './useDeleteRelatedDocumentsModalData.types';

export const useDeleteRelatedDocumentsModalHandlers = ({
  localActions
}: {
  localActions: DeleteRelatedDocumentsModalLocalActions;
}): DeleteRelatedDocumentsModalHandlers => {
  const handleSelectedDocumentsChange: DeleteRelatedDocumentsModalHandlers['handleSelectedDocumentsChange'] = ids => {
    localActions.setRelatedDocIds(ids.map(Number));
  };

  const handleFiltersChange: DeleteRelatedDocumentsModalHandlers['handleFiltersChange'] = requestParams => {
    localActions.setRequestParams(requestParams);
  };

  return {
    handleSelectedDocumentsChange,
    handleFiltersChange
  };
};
