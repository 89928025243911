import type { DocumentFromResponse } from 'shared/features/documents/documents.types';

export const getSource = ({ document }: { document: DocumentFromResponse }) => {
  if (document.mainstream_news?.news_source?.name) {
    return document.mainstream_news.news_source.name;
  } else if (document.spider_name === 'custom_fed_api_docs') {
    return 'Federal Register';
  }

  return '';
};
