import type { DocumentLabelsProps } from '../DocumentLabels.types';
import type { ComponentProps } from 'react';

import * as errorUtils from 'utils/errors';
import * as common from 'common/index';
import * as documentNotesApi from 'shared/features/documentNotes/documentNotes.api';

import { useMutation } from '@tanstack/react-query';
import { useOrganizationLabelsReduxActions } from 'shared/features/labels/hooks';
import { useDocumentLabelsQueries } from './useDocumentLabelsQueries';

export const useDocumentLabelsMutations = ({
  props,
  queries
}: {
  props: Pick<DocumentLabelsProps, 'docId'>;
  queries: ReturnType<typeof useDocumentLabelsQueries>;
}) => {
  const labelsReduxActions = useOrganizationLabelsReduxActions();

  const createLabel = useMutation<
    void,
    Error,
    Parameters<ComponentProps<typeof common.LabelInput>['createNewLabel']>[0]
  >({
    mutationFn: async option => {
      await documentNotesApi.updateDocumentNoteLabel({
        bucket_id: null,
        document_note_id: queries.fetchDocumentNote.data?.documentNote?.id ?? null,
        label_id: option.value,
        label_name: option.label as string,
        document_id: props.docId,
        new_document_note: !queries.fetchDocumentNote.data?.documentNote?.id
      });

      labelsReduxActions.triggerLabelsDetailsRefresh();
    },
    onSuccess: () => queries.fetchDocumentNote.refetch(),
    onError: errorUtils.logReactQueryError
  });

  const removeLabel = useMutation<
    void,
    Error,
    Parameters<ComponentProps<typeof common.LabelInput>['removeLabel']>[0]
  >({
    mutationFn: async labelId => {
      if (queries.fetchDocumentNote.data?.documentNote) {
        await documentNotesApi.removeDocumentNoteLabel({
          document_note_id: queries.fetchDocumentNote.data?.documentNote?.id,
          label_id: labelId
        });

        labelsReduxActions.triggerLabelsDetailsRefresh();
      }
    },
    onSuccess: () => queries.fetchDocumentNote.refetch(),
    onError: errorUtils.logReactQueryError
  });

  return {
    createLabel,
    removeLabel
  };
};
