import type { WorkflowSummaryHandlers } from './useWorkflowSummaryHandlers.types';
import type { WorkflowSummaryProps } from '../WorkflowSummary.types';
import type { WorkflowSummaryData } from './useWorkflowSummaryData.types';

import * as uiLib from '@compliance.ai/web-components';

import { useCallback } from 'react';
import { usePrint } from '@compliance.ai/web-components';

export const useWorkflowSummaryHandlers = ({
  props,
  refs,
  localState,
  localActions,
  reduxState
}: {
  props: Pick<WorkflowSummaryProps, 'workflow_name'>;
  refs: WorkflowSummaryData['refs'];
  localState: WorkflowSummaryData['localState'];
  localActions: WorkflowSummaryData['localActions'];
  reduxState: WorkflowSummaryData['reduxState'];
}): WorkflowSummaryHandlers => {
  const printSummary = usePrint({
    bodyClass: 'workflow-summary__pdf-body',
    content: () => refs.componentToPrint.current,
    documentTitle: `${props.workflow_name} - ${uiLib.formatDate(new Date(), {
      passedTimezone: uiLib.getLocalTimezone(),
      timezone: uiLib.getLocalTimezone()
    })}`
  });

  const handleSectionToggle: WorkflowSummaryHandlers['handleSectionToggle'] = sectionType => isExpanded => {
    localActions.setIsSectionExpanded(sections => ({
      ...sections,
      [sectionType]: isExpanded
    }));
    localActions.setIsSectionExpandAnimationCompleted(sections => ({
      ...sections,
      [sectionType]: !isExpanded ? false : sections[sectionType]
    }));
  };

  const handleSectionExpandAnimationComplete: WorkflowSummaryHandlers['handleSectionExpandAnimationComplete'] = sectionType => () => {
    localActions.setIsSectionExpandAnimationCompleted(sections => ({
      ...sections,
      [sectionType]: true
    }));
  };

  const handlePrintPreparation: WorkflowSummaryHandlers['handlePrintPreparation'] = () => {
    localActions.setIsPreparingForPrint(true);
    localActions.setIsSectionExpanded(
      sections =>
        Object.fromEntries(
          Object.entries(sections).map(([section]) => [section, true])
        ) as typeof localState['isSectionExpanded']
    );

    if (!reduxState.doesDocumentHasSentences) {
      localActions.setIsSectionExpandAnimationCompleted(
        sections =>
          Object.fromEntries(
            Object.entries(sections).map(([section]) => [section, true])
          ) as typeof localState['isSectionExpandAnimationCompleted']
      );

      localActions.setIsSentenceNotesFetchCompleted(
        sentenceNoteTypes =>
          Object.fromEntries(
            Object.entries(sentenceNoteTypes).map(([type]) => [type, true])
          ) as typeof localState['isSentenceNotesFetchCompleted']
      );
    }
  };

  const handlePrintableContentReady: WorkflowSummaryHandlers['handlePrintableContentReady'] = useCallback(() => {
    if (
      localState.isPreparingForPrint &&
      Object.values(localState.isSectionExpandAnimationCompleted).every(Boolean) &&
      Object.values(localState.isSentenceNotesFetchCompleted).every(Boolean)
    ) {
      localActions.setIsPreparingForPrint(false);
      localActions.setReadyToBePrintedState(true);
    }
  }, [
    localActions,
    localState.isPreparingForPrint,
    localState.isSectionExpandAnimationCompleted,
    localState.isSentenceNotesFetchCompleted
  ]);

  const handlePrint: WorkflowSummaryHandlers['handlePrint'] = useCallback(() => {
    if (localState.isReadyToBePrinted && refs.componentToPrint.current) {
      printSummary();
      localActions.setReadyToBePrintedState(false);
    }
  }, [localState.isReadyToBePrinted, refs.componentToPrint, printSummary, localActions]);

  const handleSentenceNotesFetchCompletion: WorkflowSummaryHandlers['handleSentenceNotesFetchCompletion'] = type => () => {
    localActions.setIsSentenceNotesFetchCompleted(isSentenceNotesFetchCompleted => ({
      ...isSentenceNotesFetchCompleted,
      [type]: true
    }));
  };

  return {
    handleSectionToggle,
    handleSectionExpandAnimationComplete,
    handlePrint,
    handlePrintableContentReady,
    handlePrintPreparation,
    handleSentenceNotesFetchCompletion
  };
};
