import type { GlobalState } from '../shared/reducers';
import type { Documents } from '../components/Documents';

import type * as react from 'react';

import * as userSelectors from '../shared/features/user/user.selectors';
import * as defaultsSelectors from '../shared/features/defaults/defaults.selectors';
import * as defaultSourcesConstants from './DefaultSources';
import * as documentsSelectors from '../shared/features/documents/documents.selectors';

import { DOCUMENT_CATEGORIES } from './DocumentConstants';
import { SUPPORTED_QUERY_PARAMS } from 'constants/PrimaryFilter';
import { NAV_BAR_ITEM_SECTIONS } from 'common/AppNavigation/hooks/useAppNavigationNavBarConfig.constants';

export const FIELDS_TO_REMOVE_ON_TAB_CHANGE: Array<
  typeof SUPPORTED_QUERY_PARAMS[keyof typeof SUPPORTED_QUERY_PARAMS]
> = [
  SUPPORTED_QUERY_PARAMS.CAI_CATEGORY,
  SUPPORTED_QUERY_PARAMS.AGENCY_ID,
  SUPPORTED_QUERY_PARAMS.NEWS_SOURCE_ID,
  SUPPORTED_QUERY_PARAMS.LANGUAGE_ID,
  SUPPORTED_QUERY_PARAMS.VIEW,
  SUPPORTED_QUERY_PARAMS.CONCEPT_ID
];

enum NEWS_TABS {
  ALL_NEWS = 'allNews',
  MAINSTREAM_NEWS = 'mainstreamNews',
  AGENCY_NEWS = 'agencyNews'
}

export const NEWS_PAGE_TABS: react.ComponentProps<typeof Documents>['tabs'] = [
  {
    title: 'All News',
    tabKey: NEWS_TABS.ALL_NEWS,
    requestParamsSelector: (state: GlobalState) => ({
      // TODO Uncomment when documentsSelectors will be converted to TS
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [SUPPORTED_QUERY_PARAMS.CAI_CATEGORY]: documentsSelectors.getCategoryIdsByNames(state, [
        DOCUMENT_CATEGORIES.NEWS,
        DOCUMENT_CATEGORIES.MAINSTREAM_NEWS
      ]),
      [SUPPORTED_QUERY_PARAMS.AGENCY_ID]: defaultsSelectors.getFollowedAgenciesIds(
        defaultSourcesConstants.DEFAULT_TYPES.MY_DEFAULTS
      )(state),
      [SUPPORTED_QUERY_PARAMS.NEWS_SOURCE_ID]: defaultsSelectors.getRealFollowedNewsSourcesIds(
        defaultSourcesConstants.DEFAULT_TYPES.MY_DEFAULTS
      )(state),
      [SUPPORTED_QUERY_PARAMS.LANGUAGE_ID]: userSelectors.getCurrentLanguageId(state),
      [SUPPORTED_QUERY_PARAMS.VIEW]: NAV_BAR_ITEM_SECTIONS.NEWS
    }),
    fieldsToRemoveOnTabChange: FIELDS_TO_REMOVE_ON_TAB_CHANGE
  },
  {
    title: 'Agency News',
    tabKey: NEWS_TABS.AGENCY_NEWS,
    requestParamsSelector: (state: GlobalState) => ({
      // TODO Uncomment when documentsSelectors will be converted to TS
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [SUPPORTED_QUERY_PARAMS.CAI_CATEGORY]: documentsSelectors.getCategoryIdsByNames(state, [
        DOCUMENT_CATEGORIES.NEWS
      ]),
      [SUPPORTED_QUERY_PARAMS.AGENCY_ID]: defaultsSelectors.getFollowedAgenciesIds(
        defaultSourcesConstants.DEFAULT_TYPES.MY_DEFAULTS
      )(state),
      [SUPPORTED_QUERY_PARAMS.LANGUAGE_ID]: userSelectors.getCurrentLanguageId(state),
      [SUPPORTED_QUERY_PARAMS.VIEW]: NAV_BAR_ITEM_SECTIONS.NEWS
    }),
    fieldsToRemoveOnTabChange: FIELDS_TO_REMOVE_ON_TAB_CHANGE
  },
  {
    title: 'Mainstream News',
    tabKey: NEWS_TABS.MAINSTREAM_NEWS,
    requestParamsSelector: (state: GlobalState) => ({
      // TODO Uncomment when documentsSelectors will be converted to TS
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [SUPPORTED_QUERY_PARAMS.CAI_CATEGORY]: documentsSelectors.getCategoryIdsByNames(state, [
        DOCUMENT_CATEGORIES.MAINSTREAM_NEWS
      ]),
      [SUPPORTED_QUERY_PARAMS.NEWS_SOURCE_ID]: defaultsSelectors.getRealFollowedNewsSourcesIds(
        defaultSourcesConstants.DEFAULT_TYPES.MY_DEFAULTS
      )(state),
      [SUPPORTED_QUERY_PARAMS.LANGUAGE_ID]: userSelectors.getCurrentLanguageId(state),
      [SUPPORTED_QUERY_PARAMS.VIEW]: NAV_BAR_ITEM_SECTIONS.NEWS
    }),
    fieldsToRemoveOnTabChange: FIELDS_TO_REMOVE_ON_TAB_CHANGE
  }
];
