import { useEffect } from 'react';
import { useDidMount, useWillUnmount } from '@compliance.ai/web-components';
import { DocumentTimelineHandlers } from './useDocumentTimelineHandlers.types';

export const useDocumentTimelineLifecycle = ({
  onOverlayOpenOnMount,
  onOverlayChange,
  onUnmount
}: {
  onOverlayOpenOnMount: DocumentTimelineHandlers['handleOverlayOpenOnMount'];
  onOverlayChange: DocumentTimelineHandlers['handleOverlayChange'];
  onUnmount: DocumentTimelineHandlers['handleUnmount'];
}) => {
  useDidMount(onOverlayOpenOnMount);

  useEffect(onOverlayChange, [onOverlayChange]);

  useWillUnmount(onUnmount);
};
