import type { DocumentFromResponse } from '../../../documents/documents.types';

import * as rightPanelConstants from '../../rightPanel.constants';

import { useDocumentDetailsTabsAvailabilityData } from './useDocumentDetailsTabsAvailabilityData';

export const useDocumentDetailsTabsAvailability = ({
  documentId,
  areAnnotationsAvailable,
  areObligationsAvailable,
  canUserAccessDocument,
  isDiffAvailable,
  isUserAuthenticated,
  documentHasVersions,
  documentHasDockets
}: {
  documentId: DocumentFromResponse['id'] | null;
  areAnnotationsAvailable: boolean;
  areObligationsAvailable: boolean;
  canUserAccessDocument: boolean;
  isDiffAvailable: boolean;
  isUserAuthenticated: boolean;
  documentHasVersions: boolean;
  documentHasDockets: boolean;
}): Record<rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY, boolean> => {
  const { reduxState, formattedData } = useDocumentDetailsTabsAvailabilityData({
    documentId
  });

  return {
    [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.DETAILS]: !reduxState.isDiffView,
    [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.DOCKET_TIMELINE]:
      !reduxState.isDiffView && !formattedData.isLiteTeamUser && documentHasDockets,
    [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.VERSIONS]:
      !reduxState.isDiffView && !formattedData.isLiteTeamUser && documentHasVersions,
    [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.EXPERT_CONNECTION]:
      !reduxState.isDiffView && !formattedData.isLiteTeamUser && reduxState.areThereAnyExperts,
    [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.RELATED_DOCUMENTS]:
      !reduxState.isDiffView &&
      !formattedData.isLiteTeamUser &&
      reduxState.isAuthenticated &&
      reduxState.areThereAnyRelatedDocuments,
    [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.AUTO_SUMMARY]:
      !reduxState.isDiffView &&
      !formattedData.isLiteTeamUser &&
      isUserAuthenticated &&
      canUserAccessDocument &&
      reduxState.areThereAnySummaries,
    [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.TASKS]:
      !reduxState.isDiffView &&
      (formattedData.isPowerOrTeamUser || !reduxState.isAuthenticated) &&
      Boolean(documentId),
    [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.ANNOTATIONS]:
      !reduxState.isDiffView &&
      !formattedData.isLiteTeamUser &&
      areAnnotationsAvailable &&
      reduxState.doesDocumentHasSentences &&
      formattedData.isPowerOrTeamOrProUser &&
      canUserAccessDocument &&
      !reduxState.isMobile,
    [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.OBLIGATIONS]:
      !reduxState.isDiffView &&
      !formattedData.isLiteTeamUser &&
      areObligationsAvailable &&
      reduxState.doesDocumentHasSentences &&
      formattedData.isPowerOrTeamOrProUser &&
      canUserAccessDocument &&
      !reduxState.isMobile,
    [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.DIFF]:
      reduxState.isDiffView && isDiffAvailable && canUserAccessDocument
  };
};
