import type { MomentInput } from 'moment';

import { useSelector } from 'react-redux';
import { getUserTimezone } from 'shared/features/user/user.selectors';
import { isValidDate, formatDateWithTimezone } from 'utils/date-utils';
import { TABLE_COLUMN_DATE_FIELD_FORMAT, DEFAULT_EMPTY_COLUMN } from '../../AlertsTable.constants';

export const AlertsTableDateColumn = (date: unknown) => {
  const timezone = useSelector(getUserTimezone);

  if (!date || !isValidDate(date as MomentInput)) {
    return <>{DEFAULT_EMPTY_COLUMN}</>;
  }

  return (
    <>
      {formatDateWithTimezone(
        date as MomentInput,
        String(timezone),
        TABLE_COLUMN_DATE_FIELD_FORMAT
      )}
    </>
  );
};
