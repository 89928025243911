import type { FC } from 'react';
import type { EitlStatusProps } from './EitlStatus.types';

import * as constants from './EitlStatus.constants';
import * as uiLib from '@compliance.ai/web-components';

import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';

import { useEitlStatusData } from './hooks';

export const EitlStatus: FC<EitlStatusProps> = ({ document }) => {
  const { formattedData } = useEitlStatusData({
    props: {
      document
    }
  });

  if (!formattedData.shouldRenderComponent) {
    return null;
  }

  return (
    <>
      <FieldName>EITL Status</FieldName>
      <FieldValue>
        <uiLib.Chiclets
          options={formattedData.eitlLabelsOptions}
          isContrast
          isStatic
          minVisibleItemsCount={constants.MIN_VISIBLE_ITEMS_COUNT}
          isOptionClickable={false}
        />
      </FieldValue>
    </>
  );
};
