import type { EnforcementFilterHandlers } from './useEnforcementFilterHandlers.types';
import type { EnforcementFilterData } from './useEnforcementFilterData.types';

import * as constants from '../EnforcementFilter.constants';
import * as analytics from 'utils/analytics';
import * as rightPanelConstants from 'shared/features/rightPanel/rightPanel.constants';
import * as routes from 'constants/Routes';
import * as defaultViewConstants from 'common/Filter/DefaultFiltersViewSelect/DefaultFiltersViewSelect.constants';
import * as handlersHelpers from './useEnforcementFilterHandlers.helpers';

import queryString from 'utils/query-string';

import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useEnforcementFilterReduxActions } from './useEnforcementFilterReduxActions';
import { useHistory } from 'utils/hooks';
import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';

export const useEnforcementFilterHandlers = ({
  reduxState,
  localActions,
  localState,
  formattedData
}: {
  reduxState: EnforcementFilterData['reduxState'];
  localActions: EnforcementFilterData['localActions'];
  localState: EnforcementFilterData['localState'];
  formattedData: EnforcementFilterData['formattedData'];
}): EnforcementFilterHandlers => {
  const location = useLocation();
  const history = useHistory();
  const reduxActions = useEnforcementFilterReduxActions();
  const rightPanelReduxActions = useRightPanelReduxActions();

  const handleGetOrgDefaults: EnforcementFilterHandlers['handleGetOrgDefaults'] = useCallback(async () => {
    const defaultsOwner = defaultViewConstants.DEFAULT_FILTERS_VIEW_OPTIONS.ORG_DEFAULTS;

    if (!formattedData.doOrgDefaultsExist) {
      localActions.setIsLoading(true);

      const defaultsData = await reduxActions.fetchDefaults({
        defaultsOwner,
        orgId: reduxState.current_user?.user?.org_id
      });

      return handlersHelpers.prepareDefaultFilterOptions({ defaultsData, reduxState });
    }
    return handlersHelpers.prepareDefaultFilterOptions({
      defaultsData: {
        defaultTopics: reduxState.sources.sources.defaultTopics,
        followedAgencies: reduxState.defaults[defaultsOwner].followedAgencies,
        realFollowedTopics: reduxState.defaults[defaultsOwner].realFollowedTopics,
        followedConcepts: reduxState.defaults[defaultsOwner].followedConcepts
      },
      reduxState
    });
  }, [formattedData.doOrgDefaultsExist, localActions, reduxActions, reduxState]);

  const handleGetTeamDefaults: EnforcementFilterHandlers['handleGetTeamDefaults'] = useCallback(
    async teamId => {
      const existingTeamDefaults = reduxState.defaults.teamDefaultsMap[teamId];

      if (existingTeamDefaults) {
        return handlersHelpers.prepareDefaultFilterOptions({
          defaultsData: existingTeamDefaults,
          reduxState
        });
      }
      localActions.setIsLoading(true);

      const defaultsData = await reduxActions.fetchDefaults({
        defaultsOwner: defaultViewConstants.DEFAULT_FILTERS_VIEW_OPTIONS.TEAM_DEFAULTS,
        teamId
      });

      return handlersHelpers.prepareDefaultFilterOptions({ defaultsData, reduxState });
    },
    [localActions, reduxActions, reduxState]
  );

  const handleSetFollowedSources: EnforcementFilterHandlers['handleSetFollowedSources'] = useCallback(
    async selectedView => {
      let filterParams = {};

      switch (selectedView) {
        case null: {
          filterParams = {
            [constants.FILTER_KEY.FEDERAL_SOURCE]: [],
            [constants.FILTER_KEY.TOPIC_ID]: [],
            [constants.FILTER_KEY.CONCEPTS]: []
          };
          break;
        }

        case defaultViewConstants.DEFAULT_FILTERS_VIEW_OPTIONS.MY_DEFAULTS: {
          const followedTopics = reduxState.topics.followed_topics.filter(topic => topic.following);
          const defaultsValue = handlersHelpers.prepareDefaultFilterOptions({
            defaultsData: {
              defaultTopics: reduxState.sources.sources.defaultTopics,
              followedAgencies: reduxState.followedAgencies,
              realFollowedTopics: followedTopics,
              followedConcepts:
                reduxState.defaults[defaultViewConstants.DEFAULT_FILTERS_VIEW_OPTIONS.MY_DEFAULTS]
                  .followedConcepts
            },
            reduxState
          });
          filterParams = {
            [constants.FILTER_KEY.FEDERAL_SOURCE]: defaultsValue.agencies,
            [constants.FILTER_KEY.TOPIC_ID]: defaultsValue.topics,
            [constants.FILTER_KEY.CONCEPTS]: defaultsValue.concepts
          };
          break;
        }

        case defaultViewConstants.DEFAULT_FILTERS_VIEW_OPTIONS.ORG_DEFAULTS: {
          const defaultsValue = await handleGetOrgDefaults();

          filterParams = {
            [constants.FILTER_KEY.FEDERAL_SOURCE]: defaultsValue.agencies,
            [constants.FILTER_KEY.TOPIC_ID]: defaultsValue.topics,
            [constants.FILTER_KEY.CONCEPTS]: defaultsValue.concepts
          };
          break;
        }

        default: {
          const defaultsValue = await handleGetTeamDefaults(selectedView as string);

          filterParams = {
            [constants.FILTER_KEY.FEDERAL_SOURCE]: defaultsValue.agencies,
            [constants.FILTER_KEY.TOPIC_ID]: defaultsValue.topics,
            [constants.FILTER_KEY.CONCEPTS]: defaultsValue.concepts
          };
          break;
        }
      }

      localActions.setFilterParams(prevFilterParams => ({
        ...prevFilterParams,
        ...filterParams
      }));
      localActions.setIsLoading(false);
    },
    [handleGetOrgDefaults, handleGetTeamDefaults, localActions, reduxState]
  );

  const handleSaveFilter: EnforcementFilterHandlers['handleSaveFilter'] = useCallback(() => {
    const { user } = reduxState.current_user;

    reduxActions.updateCurrentUser(user.email, {
      ...user,
      properties: {
        ...user.properties,
        enforcementFilter: {
          ...localState.filterParams,
          defaultView: localState.defaultView
        }
      }
    });
  }, [localState.defaultView, localState.filterParams, reduxActions, reduxState.current_user]);

  const handleAlertNameChange: EnforcementFilterHandlers['handleAlertNameChange'] = name => {
    localActions.setNewAlertName(name);
  };

  const handleClearAll: EnforcementFilterHandlers['handleClearAll'] = () => {
    localActions.setDefaultView(null);

    localActions.setFilterParams(
      constants.DEFAULT_STATE as EnforcementFilterData['localState']['filterParams']
    );
  };

  const handleFilterValueChange: EnforcementFilterHandlers['handleFilterValueChange'] = field => value => {
    localActions.setFilterParams(prevFilterParams => ({
      ...prevFilterParams,
      [field]: value as string | object | unknown[]
    }));
    if (localState.filterParams[field] !== value) {
      localActions.setDefaultView(null);
    }
  };

  const handleDateFilterValueChange: EnforcementFilterHandlers['handleDateFilterValueChange'] = (
    field,
    value
  ) => {
    localActions.setFilterParams(prevFilterParams => ({ ...prevFilterParams, [field]: value }));
    if (localState.filterParams[field] !== value) {
      localActions.setDefaultView(null);
    }
  };

  const handleSetFilterValueError: EnforcementFilterHandlers['handleSetFilterValueError'] = (
    field,
    error
  ) => {
    localActions.setErrors(prevErrors => ({
      ...prevErrors,
      [field]: error
    }));
  };

  const handleRouteWithParams: EnforcementFilterHandlers['handleRouteWithParams'] = shouldClearAlert => {
    handleSaveFilter();
    reduxActions.closeFilter();

    if (
      shouldClearAlert &&
      (reduxState.current_view.editAlertId || reduxState.current_view.viewAlertId)
    ) {
      reduxActions.removeViewAlert();
      reduxActions.removeEditAlert();
    }

    analytics.safe_analytics(
      'default',
      'Enforcement Filter',
      'Click on Search',
      formattedData.analyticsData
    );

    rightPanelReduxActions.openRightPanel({
      type: rightPanelConstants.RIGHT_PANEL_TYPES.SEARCH_RESULTS
    });

    history.push(`${routes.enforcementExplorer}?${formattedData.urlParams}`);
  };

  const handleUpdateAlert: EnforcementFilterHandlers['handleUpdateAlert'] = async () => {
    const alert_id = reduxState.current_view.editAlertId;
    const name = localState.newAlertName || reduxState.current_view.alertName;
    const { pathname } = location;
    const parsedQuery = queryString.parse(formattedData.urlParams);

    handleRouteWithParams(false);

    await reduxActions.updateAlert(alert_id, {
      search_args: parsedQuery,
      pathname,
      name
    });
    reduxActions.addEditAlert(alert_id, name);
    reduxActions.addViewAlert(alert_id, name);
    reduxActions.showSaveAlertSuccessModal('modify', name);
  };

  const handleDefaultsViewSelect: EnforcementFilterHandlers['handleDefaultsViewSelect'] = useCallback(
    view => {
      localActions.setDefaultView(view);
      handleSetFollowedSources(view);
    },
    [handleSetFollowedSources, localActions]
  );

  return {
    handleSetFollowedSources,
    handleSaveFilter,
    handleAlertNameChange,
    handleClearAll,
    handleFilterValueChange,
    handleSetFilterValueError,
    handleDateFilterValueChange,
    handleUpdateAlert,
    handleRouteWithParams,
    handleDefaultsViewSelect,
    handleGetOrgDefaults,
    handleGetTeamDefaults
  };
};
