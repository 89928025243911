import { useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactJoyride from 'react-joyride';
import { JoyrideTooltipComponent } from './elements';

const STYLES = { options: { zIndex: 9999 } };
const FLOATER_PROPS = { styles: { floater: { zIndex: 9999 } } };

export const Joyride = ({ steps, onFinish, onSkip, ...restProps }) => {
  const tooltipComponent = useCallback(
    props => <JoyrideTooltipComponent onFinish={onFinish} onSkip={onSkip} {...props} />,
    [onFinish, onSkip]
  );

  return (
    <ReactJoyride
      steps={steps}
      spotlightPadding={0}
      tooltipComponent={tooltipComponent}
      floaterProps={FLOATER_PROPS}
      styles={STYLES}
      disableOverlayClose
      {...restProps}
    />
  );
};

Joyride.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      target: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired
    })
  ).isRequired,
  onFinish: PropTypes.func,
  onSkip: PropTypes.func
};
