import type * as types from './ConditionalFieldsStage.types';

import * as emptyValues from 'constants/EmptyValue';
import * as helpers from './ConditionalFieldsStage.helpers';

export class ConditionalFieldsStage implements types.ConditionalFieldsStage {
  readonly conditionalFieldsMap: types.ConditionalFieldsStage['conditionalFieldsMap'];
  readonly isReversed: types.ConditionalFieldsStage['isReversed'];

  constructor(args: {
    conditionalFieldsMap: types.ConditionalFieldsStage['conditionalFieldsMap'];
    isReversed?: types.ConditionalFieldsStage['isReversed'];
  }) {
    this.conditionalFieldsMap = args.conditionalFieldsMap;
    this.isReversed = args.isReversed ?? false;
  }

  public getReversedConditionalFields: types.ConditionalFieldsStage['getReversedConditionalFields'] = metadata => {
    /**
     *
     * Get reversed field values
     *
     */
    const reversedFields: typeof metadata = metadata.flatMap(
      ([metadataKey, metadataValue], _, processedMetadata) => {
        const reversedConditionalField = helpers.getReversedConditionalField({
          metadataKey: metadataKey,
          conditionalFieldsMap: this.conditionalFieldsMap
        });

        if (reversedConditionalField && metadataValue === false) {
          const reversedFieldValues = helpers.getReversedConditionalFieldValues({
            reversedConditionalField: reversedConditionalField,
            metadata: processedMetadata
          });

          if (!reversedFieldValues.length) {
            return [[reversedConditionalField, [emptyValues.EMPTY_VALUE.NA]]];
          }
        }

        return [];
      }
    );

    /**
     *
     * Filter the initial set of data by the formatted fields
     * This step is required in order to avoid entry duplicates
     *
     */
    const nonReservedFields: typeof metadata = metadata.flatMap(([metadataKey, metadataValue]) => {
      const reservedFieldEntry = reversedFields.find(
        ([reversedField]) => reversedField === metadataKey
      );

      if (reservedFieldEntry) {
        return [];
      }

      return [[metadataKey, metadataValue]];
    });

    /**
     *
     * Join the results
     *
     */
    return [...reversedFields, ...nonReservedFields];
  };

  public getConditionalFields: types.ConditionalFieldsStage['getConditionalFields'] = metadata => {
    const conditionalFields: typeof metadata = Object.entries(this.conditionalFieldsMap).flatMap(
      ([conditionalMetadataKey, conditionalMetadataField]) => {
        const [metadataKey, metadataValue] =
          metadata.find(([metadataKey]) => metadataKey === conditionalMetadataKey) ?? [];

        if (!conditionalMetadataField) {
          return [];
        }

        if (Array.isArray(metadataValue) && metadataValue.length) {
          return [[conditionalMetadataField, false]];
        }

        if (!Array.isArray(metadataValue) && metadataValue) {
          return [[conditionalMetadataField, false]];
        }

        return [[conditionalMetadataField, true]];
      }
    );

    return [...metadata, ...conditionalFields];
  };

  public run: types.ConditionalFieldsStage['run'] = async args => {
    if (this.isReversed) {
      return this.getReversedConditionalFields(args.metadata);
    } else {
      return this.getConditionalFields(args.metadata);
    }
  };
}
