export enum EDITABLE_FIELDS_KEYS {
  STATUS = 'status',
  DEADLINE = 'deadline',
  ASSIGNEE = 'assignee',
  ATTACHMENTS = 'attachments',
  ANNOTATIONS_ASSIGNEE = 'annotationAssignee',
  ANNOTATION_DATE = 'annotationDate',
  COMMENTS = 'comments'
}
export const MAX_ALLOWED_ATTACHMENT_FILES = 3;
export const MAX_ALLOWED_ATTACHMENT_FILE_SIZE = 100000000;
export const ATTACHMENT_FILES_ALLOWED_EXTENSIONS = [
  '.pdf',
  '.doc',
  '.docx',
  '.txt',
  '.rtf',
  '.ppt',
  '.csv',
  '.png',
  '.jpeg',
  '.tiff',
  '.pptx',
  '.eml',
  '.gsheet',
  '.gdoc',
  '.xlsx',
  '.xls',
  '.msg'
];

export const ANNOTATION_REQUIREMENT_KEYS = {
  REQUIRED: 'required',
  OPTIONAL: 'optional',
  NONE: 'none'
};
export const DEPENDENT_ON_TASK = 'dependent_on_task';
