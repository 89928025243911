import type { AutosuggestionSelectHandlers } from './useAutosuggestionSelectHandlers.types';

import { useEffect } from 'react';

export const useAutosuggestionSelectLifecycle = ({
  onValuePropChange
}: {
  onValuePropChange: AutosuggestionSelectHandlers['handleValuePropChange'];
}) => {
  useEffect(() => {
    onValuePropChange();
  }, [onValuePropChange]);
};
