import type { SentenceNoteTextHandlers } from './useSentenceNoteTextHandlers.types';
import type { SentenceNoteTextData } from './useSentenceNoteTextData.types';

export const useSentenceNoteTextHandlers = ({
  localActions
}: {
  localActions: SentenceNoteTextData['localActions'];
}): SentenceNoteTextHandlers => {
  const handleExpandToggle: SentenceNoteTextHandlers['handleExpandToggle'] = isExpanded => () => {
    localActions.setIsExpanded(isExpanded);
  };

  return {
    handleExpandToggle
  };
};
