import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import './_tooltip-component.scss';

export const JoyrideTooltipComponent = ({
  index,
  step,
  backProps,
  closeProps,
  skipProps,
  primaryProps,
  tooltipProps,
  isLastStep,
  onFinish,
  onSkip
}) => (
  <div className="joyride-tooltip-component" {...tooltipProps}>
    {step.title && <div className="title">{step.title}</div>}
    <div className="content">{step.content}</div>
    <div className="footer">
      {index > 0 && (
        <Button className="secondary-button" type={BUTTON_TYPES.LINK} {...backProps}>
          Back
        </Button>
      )}
      {isLastStep ? (
        <Button
          className="primary-button"
          type={BUTTON_TYPES.LINK}
          {...closeProps}
          onClick={e => {
            onFinish(e);
            closeProps.onClick(e);
          }}
        >
          Got it
        </Button>
      ) : (
        <>
          <Button className="primary-button" type={BUTTON_TYPES.LINK} {...primaryProps}>
            Next
          </Button>
          <Button
            className="secondary-button"
            type={BUTTON_TYPES.LINK}
            {...skipProps}
            onClick={e => {
              onSkip(e);
              skipProps.onClick(e);
            }}
          >
            Skip tips
          </Button>
        </>
      )}
    </div>
  </div>
);
