import type { FC } from 'react';
import type { CommentsProps } from './Comments.types';

import * as common from 'common/index';
import * as uiLib from '@compliance.ai/web-components';
import * as permissionsConstants from 'constants/Permissions';
import * as commentsHelpers from 'shared/features/comments/comments.helpers';

import { usePermissions } from 'utils/hooks';
import { useCommentsMutations } from './hooks';

import './_comments.scss';

export const Comments: FC<CommentsProps> = ({ sentenceNoteId, commentThreads, isEditable }) => {
  const permissions = usePermissions();

  const mutations = useCommentsMutations({
    props: {
      sentenceNoteId
    }
  });

  return (
    <uiLib.LabeledField label="Comments" isContrast>
      <div className="sentence-note-comments__container">
        <common.Comments
          isLoading={mutations.isLoading}
          commentThreads={commentsHelpers.normalizeCommentThreads(commentThreads)}
          onThreadCreation={mutations.createCommentThread.mutate}
          onCommentCreation={mutations.createComment.mutate}
          onCommentDeletion={mutations.deleteComment.mutate}
          onCommentEdit={mutations.editComment.mutate}
          areRepliesEnabled={isEditable && permissions[permissionsConstants.CREATE_COMMENTS]}
          isThreadCreationEnabled={isEditable && permissions[permissionsConstants.CREATE_COMMENTS]}
        />
      </div>
    </uiLib.LabeledField>
  );
};
