import * as documentConstants from '../../../shared/features/documents/documents.constants';
import * as elements from './elements';

export const COMPONENTS = {
  [documentConstants.DOC_METADATA_FIELD.RESPONDENTS]: elements.Respondents,
  [documentConstants.DOC_METADATA_FIELD.PENALTY]: elements.Penalty,
  [documentConstants.DOC_METADATA_FIELD.VIOLATION]: elements.Violation,
  [documentConstants.DOC_METADATA_FIELD.RULES_VIOLATED]: elements.RulesViolated,
  [documentConstants.DOC_METADATA_FIELD.AGENCY]: elements.Agency,
  [documentConstants.DOC_METADATA_FIELD.SOURCE]: elements.Source,
  [documentConstants.DOC_METADATA_FIELD.AUTHOR]: elements.Author,
  [documentConstants.DOC_METADATA_FIELD.FIRM]: elements.Firm,
  [documentConstants.DOC_METADATA_FIELD.PUBLISHER]: elements.Publisher,
  [documentConstants.DOC_METADATA_FIELD.DOCUMENT_ID]: elements.DocumentId,
  [documentConstants.DOC_METADATA_FIELD.DOCUMENT_TYPE]: elements.DocumentType,
  [documentConstants.DOC_METADATA_FIELD.LOCATION]: elements.Location,
  [documentConstants.DOC_METADATA_FIELD.PUBLICATION_DATE]: elements.PublicationDate,
  [documentConstants.DOC_METADATA_FIELD.ADDED_TO_COMPLIANCE_AI]: elements.AddedToComplianceAi,
  [documentConstants.DOC_METADATA_FIELD.KEY_DATES]: elements.KeyDates,
  [documentConstants.DOC_METADATA_FIELD.IMPLEMENTATION_DATE]: elements.ImplementationDate,
  [documentConstants.DOC_METADATA_FIELD.APPLICABILITY_DATE]: elements.ApplicabilityDate,
  [documentConstants.DOC_METADATA_FIELD.DOCKETS]: elements.Dockets,
  [documentConstants.DOC_METADATA_FIELD.CITATION]: elements.Citation,
  [documentConstants.DOC_METADATA_FIELD.CFRs]: elements.CFRs,
  [documentConstants.DOC_METADATA_FIELD.COMMENTER]: elements.Commenter,
  [documentConstants.DOC_METADATA_FIELD.LAST_COMMENT]: elements.LastComment,
  [documentConstants.DOC_METADATA_FIELD.TOPICS]: elements.Topics,
  [documentConstants.DOC_METADATA_FIELD.CONCEPTS]: elements.Concepts,
  [documentConstants.DOC_METADATA_FIELD.EITL_STATUS]: elements.EitlStatus,
  [documentConstants.DOC_METADATA_FIELD.LABELS]: elements.Labels
};
