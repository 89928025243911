import type * as apiTypes from './documents.apiV2.types';

import * as primaryFilterConstants from 'constants/PrimaryFilter';

import { fetch } from 'fetch';

export const fetchRelatedDocumentsAggregation: apiTypes.FetchRelatedDocumentsAggregation = async params => {
  return fetch({
    url: '/related_documents_aggregation',
    method: 'POST',
    params
  });
};

export const fetchAvailableDocumentUpdateFields: apiTypes.FetchAvailableDocumentUpdateFields = async () => {
  return fetch({
    url: '/organizations/organization_documents/avaliable_update_fields',
    method: 'GET'
  });
};

export const updateDocumentMetadata: apiTypes.UpdateDocumentMetadata = async (
  documentId,
  params
) => {
  return fetch({
    url: `/organizations/organization_documents/update/${documentId}`,
    method: 'POST',
    params
  });
};

export const fetchDocumentMetadataUpdateStatus: apiTypes.FetchDocumentMetadataUpdateStatus = async commandId => {
  return fetch({
    url: `/organizations/organization_documents/update_status/${commandId}`,
    method: 'GET'
  });
};

export const fetchOrganizationRelatedDocumentAlerts: apiTypes.FetchOrganizationRelatedDocumentAlerts = documentId => {
  return fetch({
    url: `/organization_related_document_alerts/${documentId}`,
    dataType: 'RelatedDocuments',
    method: 'GET'
  });
};

export const fetchDocumentVersionsCount: apiTypes.FetchDocumentVersionsCount = async documentVersionDocketId => {
  if (!documentVersionDocketId) {
    return 0;
  }

  const response = await fetch({
    url: `/documents_post`,
    dataType: 'Document Versions',
    method: 'POST',
    params: {
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.LIMIT]: '0',
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.OFFSET]: '0',
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.DOCUMENT_VERSION_LATEST]: false,
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS
        .DOCUMENT_VERSION_DOCKET_ID]: documentVersionDocketId
    }
  });

  return (response as {
    count: number;
  }).count;
};

export const fetchDocumentDocketsCount: apiTypes.FetchDocumentDocketDocumentsCount = async docketIds => {
  if (!docketIds.length) {
    return 0;
  }

  const response = await fetch({
    url: `/documents_post`,
    dataType: 'Document Dockets',
    method: 'POST',
    params: {
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.LIMIT]: '0',
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.OFFSET]: '0',
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.DOCUMENT_VERSION_LATEST]: false,
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.DOCKET_ID]: docketIds
    }
  });

  return (response as {
    count: number;
  }).count;
};

export const syncAllDocumentUpdateRequests: apiTypes.SyncAllDocumentUpdateRequests = async doc_id => {
  return fetch({
    url: `/organizations/organization_documents/${doc_id}/update_requests/sync_all`,
    method: 'GET'
  });
};
