import type { AppSwitchProps } from './AppSwitch.types';
import type { ReactElement } from 'react';

import { ROUTES_WITH_AUTH_CHECK, ROUTES_WITHOUT_AUTH_CHECK } from '../../router/Router.config';
import App from 'components/App';
import PropTypes from 'prop-types';
import { AuthCheck } from 'components/Authentication';
import { flattenRoutes, isPathInRoutes } from './AppSwitch.helpers';

import { useLocation } from 'react-router-dom';

const AppSwitch = ({ children }: AppSwitchProps): ReactElement => {
  const location = useLocation();

  const flatRoutes = flattenRoutes(ROUTES_WITHOUT_AUTH_CHECK);

  const isRouteWithoutAuthCheck = isPathInRoutes(location.pathname, flatRoutes);

  if (isRouteWithoutAuthCheck) {
    return children as ReactElement;
  }

  if (isPathInRoutes(location.pathname, ROUTES_WITH_AUTH_CHECK)) {
    return (
      <AuthCheck>
        <App>{children}</App>
      </AuthCheck>
    );
  }

  return children as ReactElement;
};

AppSwitch.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

export default AppSwitch;
