import type { PenaltyProps } from './Penalty.types';

import { RestrictedFieldName, RESTRICTION_TYPES } from '../RestrictedFieldName';
import lodash from 'lodash';
import { FIELD_VALUES } from 'shared/features/documents/documents.constants';
import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';
import { usePenaltyData } from './hooks';

export const Penalty = ({ document }: PenaltyProps) => {
  const { reduxState } = usePenaltyData({ document });

  if (document.enforcement?.monetary_penalty === FIELD_VALUES.RESTRICTED) {
    return (
      <>
        <FieldName>
          <RestrictedFieldName restrictionType={RESTRICTION_TYPES.ENFORCEMENTS}>
            Penalty
          </RestrictedFieldName>
        </FieldName>
        <FieldValue>Restricted</FieldValue>
      </>
    );
  }

  if (
    document.enforcement?.monetary_penalty &&
    lodash.isNumber(document.enforcement?.monetary_penalty) &&
    document.enforcement?.monetary_penalty >= 0
  ) {
    return (
      <>
        <FieldName>Penalty</FieldName>
        <FieldValue>
          {reduxState.categoryName === 'Final notices'
            ? document.enforcement.monetary_penalty.toLocaleString('en-GB', {
                style: 'currency',
                currency: 'GBP'
              })
            : document.enforcement.monetary_penalty.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
              })}
        </FieldValue>
      </>
    );
  }

  return null;
};
