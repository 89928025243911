import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getCurrentUserReducer,
  getCurrentUserReadyStatus,
  getUserDashboardLayouts
} from 'shared/features/user/user.selectors';
import lodash from 'lodash';
import { ErrorBoundary } from '@compliance.ai/web-components';
import { applyDimensionsToLayout, sortAndConvertToOptions } from '../Dashboard.helpers';
import { getRightPanelOpenState } from 'shared/features/rightPanel/rightPanel.selectors';
import { getUserAuthenticatedState } from 'shared/features/auth/auth.selectors';

export const useDashboardData = ({ layoutComponentMapping, componentDimensions }) => {
  const reduxState = useSelector(state => ({
    currentUser: getCurrentUserReducer(state),
    userDashboardLayouts: getUserDashboardLayouts(state),
    isCurrentUserReady: getCurrentUserReadyStatus(state),
    isAuthenticated: getUserAuthenticatedState(state),
    isRightPanelOpen: getRightPanelOpenState(state)
  }));

  const [isLoading, setLoadingState] = useState(
    reduxState.isAuthenticated && !reduxState.isCurrentUserReady
  );
  const [isUserLayoutUsed, setUserLayoutUsedState] = useState(false);
  const [components, setLayoutComponents] = useState([]);
  const [layout, setLayout] = useState({
    lg: [],
    sm: []
  });

  const layoutWithDimensions = useMemo(() => {
    return applyDimensionsToLayout({ layout, componentDimensions });
  }, [componentDimensions, layout]);

  const sortedSelectedLayoutComponents = useMemo(() => {
    return sortAndConvertToOptions({ layoutComponentMapping });
  }, [layoutComponentMapping]);

  const gridElements = useMemo(
    () =>
      components
        .filter(componentName => lodash.has(layoutComponentMapping, componentName))
        .map(componentName => (
          <div key={componentName} className="widget-container">
            <ErrorBoundary>{layoutComponentMapping[componentName].component}</ErrorBoundary>
          </div>
        )),
    [components, layoutComponentMapping]
  );

  const localActions = useMemo(
    () => ({
      setLoadingState,
      setLayoutComponents,
      setLayout,
      setUserLayoutUsedState
    }),
    []
  );

  return {
    reduxState,
    formattedData: {
      sortedSelectedLayoutComponents,
      gridElements
    },
    localState: {
      isLoading,
      isUserLayoutUsed,
      components,
      layout: layoutWithDimensions
    },
    localActions
  };
};
