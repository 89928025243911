import type {
  WorkflowTaskFromResponse,
  WorkflowTaskAnnotationSettingsFromResponse
} from 'shared/features/workflow/workflow.types';

import { WORKFLOW_ANNOTATION_SETTINGS_KEYS } from 'shared/features/workflow/workflow.constants';

export const WORKFLOW_MODIFY_FUNC_DEBOUNCE_TIME = 150;

export const TASK_DEFAULT_ANNOTATION_SETTINGS: WorkflowTaskAnnotationSettingsFromResponse = {
  [WORKFLOW_ANNOTATION_SETTINGS_KEYS.ASSIGNEE_OPTIONS]: [],
  [WORKFLOW_ANNOTATION_SETTINGS_KEYS.APPROVERS_OPTIONS]: [],
  [WORKFLOW_ANNOTATION_SETTINGS_KEYS.DATEFIELD_LABEL]: null,
  [WORKFLOW_ANNOTATION_SETTINGS_KEYS.TYPES]: [],
  [WORKFLOW_ANNOTATION_SETTINGS_KEYS.REQUIREMENT]: []
};

export const TASK_DEFAULT_CONFIG = {
  name: '',
  description: '',
  due_days_before_doc_date: { publication_date: 0 },
  annotation_settings: TASK_DEFAULT_ANNOTATION_SETTINGS,
  teams: [],
  dependencies: [],
  task_date_dependency: null,
  relevant_on_dependency_check: true,
  dependent_on_all: true
} as Partial<WorkflowTaskFromResponse>;
