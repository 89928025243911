import type { AdvancedSearchBuilderHandlers } from './useAdvancedSearchBuilderHandlers.types';

import { useEffect } from 'react';
import { useDidMount } from 'utils/hooks';

export const useAdvancedSearchBuilderLifecycles = ({
  onDidMount,
  onUpdateSearchValue
}: {
  onDidMount: AdvancedSearchBuilderHandlers['handleDidMount'];
  onUpdateSearchValue: AdvancedSearchBuilderHandlers['handleUpdateSearchValue'];
}) => {
  useDidMount(() => {
    onDidMount();
  });

  useEffect(onUpdateSearchValue, [onUpdateSearchValue]);
};
