export const REDUCER_NAME = 'documentFields';

export enum EXPECTED_DOC_FIELDS {
  CAI_CATEGORY_ID = 'cai_category_id',
  TITLE = 'title',
  FILE_NAME = 'file_name',
  PUBLICATION_DATE = 'publication_date',
  SUMMARY_TEXT = 'summary_text',
  JURISDICTION = 'jurisdiction',
  PDF_URL = 'pdf_url',
  AGENCY_IDS = 'agency_ids',
  WEB_URL = 'web_url',
  CFR = 'cfr',
  CITATION = 'citation',
  DOCUMENT_LABELS = 'document_labels',
  FULL_TEXT = 'full_text',
  TOPICS = 'topics',
  CONCEPT = 'concept',
  ALERT = 'alert',
  DOC_URL = 'doc_url',
  DOCX_URL = 'docx_url',
  EFFECTIVE_DATE = 'effective_date',
  COMMENTS_CLOSE_DATE = 'comments_close_date',
  RESPONDENT = 'respondent',
  MONETARY_PENALTY = 'monetary_penalty',
  VIOLATION = 'violation',
  DOCKET = 'docket',
  DOCKET_TYPE = 'docket_type',
  DOCKET_DETAILS = 'docket_details',
  RELATED_PRO_DOC_IDS = 'related_pro_doc_ids',
  RELATED_DOC_IDS = 'related_doc_ids',
  LANGUAGE = 'language',
  AUTHOR = 'author'
}

export const DOC_FIELDS_THAT_SUPPORT_MULTIPLE_VALUES: EXPECTED_DOC_FIELDS[] = [
  EXPECTED_DOC_FIELDS.CAI_CATEGORY_ID,
  EXPECTED_DOC_FIELDS.JURISDICTION,
  EXPECTED_DOC_FIELDS.DOCUMENT_LABELS,
  EXPECTED_DOC_FIELDS.TOPICS,
  EXPECTED_DOC_FIELDS.CONCEPT,
  EXPECTED_DOC_FIELDS.ALERT,
  EXPECTED_DOC_FIELDS.RELATED_PRO_DOC_IDS,
  EXPECTED_DOC_FIELDS.RELATED_DOC_IDS,
  EXPECTED_DOC_FIELDS.LANGUAGE
];

export enum INTERNAL_DOC_METADATA_FIELDS {
  SURFACE_CAI_CONCEPT = 'surface_cai_concept',
  SURFACE_CAI_TOPIC = 'surface_cai_topic',
  OWNER_ID = 'owner_id',
  PERMISSIONS = 'permissions',
  HAS_OBLIGATIONS = 'has_obligations',
  HAS_SENTENCES = 'has_sentences'
}

export const CONDITIONAL_PIPELINE_DOC_FIELDS = {
  [EXPECTED_DOC_FIELDS.CONCEPT]: INTERNAL_DOC_METADATA_FIELDS.SURFACE_CAI_CONCEPT,
  [EXPECTED_DOC_FIELDS.TOPICS]: INTERNAL_DOC_METADATA_FIELDS.SURFACE_CAI_TOPIC
};
