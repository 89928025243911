import type { RestrictedFieldNameProps } from './RestrictedFieldName.types';

import { RestrictBubble } from 'components/RestrictBubble';
import { useRestrictedFieldNameHandlers } from './hooks';
import './_restricted-field-name.scss';

export const RestrictedFieldName = ({ restrictionType, children }: RestrictedFieldNameProps) => {
  const handlers = useRestrictedFieldNameHandlers({
    props: {
      restrictionType
    }
  });

  return (
    <span className="restricted-field-name__container">
      {children}
      <RestrictBubble
        className="restricted-field-name__icon"
        onClick={handlers.handleRestrictionIconClick}
      />
    </span>
  );
};
