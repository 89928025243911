import type { SendByEmailProps } from '../SendByEmail.types';
import type { GlobalState } from 'shared/reducers';

import * as authSelectors from 'shared/features/auth/auth.selectors';
import * as authorizationUtils from 'utils/authorization';

import { useSelector } from 'react-redux';

export const useSendByEmailData = ({
  documentIds,
  enabled
}: Pick<SendByEmailProps, 'documentIds' | 'enabled'>) => {
  const reduxState = useSelector<GlobalState, { isAuthenticated: boolean }>(state => ({
    isAuthenticated: authSelectors.getUserAuthenticatedState(state)
  }));

  const formattedData = {
    isDisabled: !enabled || !documentIds.length,
    isRestricted: !reduxState.isAuthenticated || authorizationUtils.isLiteTeamUser()
  };

  return {
    formattedData
  };
};
