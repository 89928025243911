import PropTypes from 'prop-types';
import CheckIcon from '@material-ui/icons/Check';
import classnames from 'classnames';
import { useListHandlers } from './hooks';
import './_list.scss';

export const List = ({
  options,
  selectedOptions,
  onOptionClick,
  isMulti,
  className,
  dataTestId
}) => {
  const handlers = useListHandlers({
    props: {
      selectedOptions,
      isMulti,
      onOptionClick
    }
  });

  return (
    <ul className={classnames('list', className)}>
      {options.map((option, i) => {
        const isOptionSelected = selectedOptions.some(({ value }) => value === option.value);

        return (
          <li
            key={`${i}-${option.value}`}
            className={classnames('list__item', {
              'list__item--is-single-selected': !isMulti && isOptionSelected,
              disabled: option.isDisabled
            })}
            onClick={option.isDisabled ? undefined : handlers.handleOptionClick(option)}
          >
            <div className="list__item-aligner">
              {option.label}
              {isMulti && (
                <CheckIcon
                  className={classnames('list__item-check-icon', {
                    'list__item-check-icon--is-selected': isOptionSelected
                  })}
                />
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

List.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      value: PropTypes.any,
      isDisabled: PropTypes.bool
    })
  ).isRequired,
  isMulti: PropTypes.bool,
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      value: PropTypes.any
    })
  ),
  onOptionClick: PropTypes.func.isRequired,
  dataTestId: PropTypes.string
};

List.defaultProps = {
  isMulti: false,
  selectedOptions: [],
  dataTestId: undefined
};
