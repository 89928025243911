import type { AgencyProps } from './Agency.types';

import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';
import { useAgencyData } from './hooks';

export const Agency = ({ document }: AgencyProps) => {
  const { formattedData } = useAgencyData({ document });

  if (formattedData.agencies) {
    return (
      <>
        <FieldName>Agency</FieldName>
        <FieldValue isExpandable>{formattedData.agencies}</FieldValue>
      </>
    );
  }

  return null;
};
