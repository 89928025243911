import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';
import {
  addDocumentToWorkflow as reduxAddDocumentToWorkflow,
  fetchTasks as reduxFetchTasks,
  fetchDocumentsByIds as reduxFetchDocumentsByIds,
  setLockManageTaskOperations as reduxSetLockManageTaskOperations,
  toggleDocTaskRefetchTrigger as reduxToggleDocTaskRefetchTrigger
} from '../../workflow.actions';

export const useWorkflowReduxActions = () => {
  const dispatch = useDispatch();

  const addDocumentToWorkflow = useCallback(
    async ({ workflowId, docTasks }) => {
      return await dispatch(reduxAddDocumentToWorkflow({ workflowId, docTasks }));
    },
    [dispatch]
  );

  const fetchTasks = useCallback(
    async docId => {
      return await dispatch(reduxFetchTasks({ doc_id: docId }));
    },
    [dispatch]
  );

  const fetchDocumentsByIds = useCallback(
    async docIds => {
      return await dispatch(reduxFetchDocumentsByIds(docIds));
    },
    [dispatch]
  );

  const setLockManageTaskOperations = useCallback(
    shouldLock => {
      return dispatch(reduxSetLockManageTaskOperations(shouldLock));
    },
    [dispatch]
  );

  const toggleDocTaskRefetchTrigger = useCallback(() => {
    return dispatch(reduxToggleDocTaskRefetchTrigger());
  }, [dispatch]);

  return useMemo(
    () => ({
      addDocumentToWorkflow,
      fetchTasks,
      fetchDocumentsByIds,
      setLockManageTaskOperations,
      toggleDocTaskRefetchTrigger
    }),
    [
      addDocumentToWorkflow,
      fetchTasks,
      fetchDocumentsByIds,
      setLockManageTaskOperations,
      toggleDocTaskRefetchTrigger
    ]
  );
};
