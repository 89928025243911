import PropTypes from 'prop-types';

import * as uiLib from '@compliance.ai/web-components';

import './_date-picker.scss';

function DatePickerSharedComponent({
  value,
  format,
  placeholder,
  showCalendarIcon,
  onChange,
  disabled,
  label,
  className,
  styleType
}) {
  const handleChange = date => {
    const updatedDate = uiLib.isValidDate(date) ? date : null;
    onChange(updatedDate);
  };

  return (
    <>
      {label && <div className={'date-picker__label'}>{label}</div>}
      <uiLib.DatePicker
        styleType={styleType}
        value={value}
        format={format}
        placeholder={placeholder}
        shouldShowCalendarIcon={showCalendarIcon}
        onChange={handleChange}
        isDisabled={disabled}
        className={className}
      />
    </>
  );
}

DatePickerSharedComponent.propTypes = {
  styleType: PropTypes.oneOf(Object.values(uiLib.TEXTFIELD_STYLE_TYPES)),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  format: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  showCalendarIcon: PropTypes.bool
};

export default DatePickerSharedComponent;
