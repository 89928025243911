import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateCurrentUser as reduxUpdateCurrentUser } from 'shared/features/user/user.actions';
import { updateAlert as reduxUpdateAlert } from 'shared/features/alerts/alerts.actions';
import {
  addEditAlert as reduxAddEditAlert,
  addViewAlert as reduxAddViewAlert,
  closeFilter as reduxCloseFilter,
  removeEditAlert as reduxRemoveEditAlert,
  removeViewAlert as reduxRemoveViewAlert,
  showSaveAlertSuccessModal as reduxShowSaveAlertSuccessModal,
  toggleFilter as reduxToggleFilter
} from 'shared/features/view/view.actions';
import { fetchAutoComplete as reduxFetchAutoComplete } from 'shared/features/search/search.actions';
import { fetchActs as reduxFetchActs } from 'shared/features/acts/acts.actions';
import {
  setJurisdictionFilter as reduxSetJurisdictionFilter,
  resetJurisdictionFilter as reduxResetJurisdictionFilter,
  toggleJurisdictionFilterDefaultFilters as reduxToggleJurisdictionFilterDefaultFilters,
  setJurisdictionFilterErrors as reduxSetJurisdictionFilterErrors,
  clearJurisdictionFilter as reduxclearJurisdictionFilter
} from 'shared/features/filters/filters.actions';

import { FILTER_KEY } from 'constants/JurisdictionAnalyzer';
import {
  mergeOptionsByUniqueness,
  getUnsetAsyncParams
} from '../JurisdictionAnalyzerFilter.helpers';
import { useLocation } from 'react-router-dom';

export const useJurisdictionReduxActions = reduxState => {
  const dispatch = useDispatch();
  const location = useLocation();

  const updateCurrentUser = useCallback(
    (email, user) => {
      return dispatch(reduxUpdateCurrentUser(email, user));
    },
    [dispatch]
  );

  const updateAlert = useCallback(
    (alertId, alertData) => {
      dispatch(reduxUpdateAlert(alertId, alertData));
    },
    [dispatch]
  );

  const addEditAlert = useCallback(
    (alertId, alertName) => {
      dispatch(reduxAddEditAlert(alertId, alertName));
    },
    [dispatch]
  );

  const addViewAlert = useCallback(
    (alertId, alertName) => {
      dispatch(reduxAddViewAlert(alertId, alertName));
    },
    [dispatch]
  );

  const showSaveAlertSuccessModal = useCallback(
    alertName => {
      dispatch(reduxShowSaveAlertSuccessModal('modify', alertName));
    },
    [dispatch]
  );

  const removeEditAlert = useCallback(() => {
    dispatch(reduxRemoveEditAlert());
  }, [dispatch]);

  const removeViewAlert = useCallback(() => {
    dispatch(reduxRemoveViewAlert());
  }, [dispatch]);

  const closeFilter = useCallback(() => {
    dispatch(reduxCloseFilter());
  }, [dispatch]);

  const toggleFilter = useCallback(() => {
    dispatch(reduxToggleFilter());
  }, [dispatch]);

  const clearJurisdictionFilter = useCallback(() => {
    dispatch(reduxclearJurisdictionFilter());
  }, [dispatch]);

  const fetchAutoComplete = useCallback(
    (searchString, autosuggestType) => {
      return dispatch(reduxFetchAutoComplete(searchString, autosuggestType));
    },
    [dispatch]
  );

  const setJurisdictionFilter = useCallback(
    filterParams => {
      dispatch(reduxSetJurisdictionFilter(filterParams));
    },
    [dispatch]
  );

  const resetJurisdictionFilter = useCallback(() => {
    dispatch(reduxResetJurisdictionFilter());
  }, [dispatch]);

  const toggleJurisdictionFilterDefaultFilters = useCallback(
    isToggledOn => {
      dispatch(reduxToggleJurisdictionFilterDefaultFilters(isToggledOn));
    },
    [dispatch]
  );

  const setJurisdictionFilterErrors = useCallback(
    errors => {
      dispatch(reduxSetJurisdictionFilterErrors(errors));
    },
    [dispatch]
  );

  const fetchActs = useCallback(async () => {
    // Filter out values that already exist
    const unsetAsyncFilterParams = getUnsetAsyncParams({
      reduxState,
      filterKey: FILTER_KEY.ACT_ID,
      queryParamKey: FILTER_KEY.ACT_ID,
      location
    });

    if (!unsetAsyncFilterParams.length) {
      return;
    }

    const response = await dispatch(reduxFetchActs(unsetAsyncFilterParams));

    const filterCurrentValue = reduxState.filterParams[FILTER_KEY.ACT_ID];
    const filterResponseValue = response.acts.map(item => ({
      value: item.id,
      label: item.name
    }));
    const filterValue = mergeOptionsByUniqueness(filterCurrentValue, filterResponseValue);

    setJurisdictionFilter({ [FILTER_KEY.ACT_ID]: filterValue });
  }, [dispatch, location, reduxState, setJurisdictionFilter]);

  return {
    updateCurrentUser,
    updateAlert,
    addEditAlert,
    addViewAlert,
    showSaveAlertSuccessModal,
    removeEditAlert,
    removeViewAlert,
    closeFilter,
    toggleFilter,
    fetchAutoComplete,
    fetchActs,
    setJurisdictionFilter,
    resetJurisdictionFilter,
    toggleJurisdictionFilterDefaultFilters,
    setJurisdictionFilterErrors,
    clearJurisdictionFilter
  };
};
