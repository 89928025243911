import type { WorkflowModalProps } from './WorkflowModal.types';

import pluralize from 'pluralize';

import * as uiLib from '@compliance.ai/web-components';
import { WorkflowsSelect } from '../../../../WorkflowsSelect';
import * as numberUtils from 'utils/number';

import { useWorkflowModalData, useWorkflowModalHandlers, useWorkflowModalLifecycle } from './hooks';

import './_workflow-modal.scss';

export const WorkflowModal = ({
  documents,
  isOpen,
  onClose,
  onActionStart
}: WorkflowModalProps) => {
  const { localState, localActions, formattedData } = useWorkflowModalData();

  const handlers = useWorkflowModalHandlers({
    props: {
      documents,
      onClose,
      onActionStart
    },
    localState,
    localActions,
    formattedData
  });

  useWorkflowModalLifecycle({
    onDataFetch: handlers.handleDataFetch
  });

  return (
    <uiLib.Modal
      title="Add Workflow"
      isOpen={isOpen}
      onClose={onClose}
      dataTestId="workflow-modal"
      secondaryButtonText="Cancel"
      primaryButtonText="Create Tasks"
      onSubmit={handlers.handleDocTasksCreation}
      withFooter
      isPrimaryButtonDisabled={formattedData.isCreateTasksButtonDisabled}
      classNames={{
        content: 'workflow-modal__container',
        footer: 'workflow-modal__footer'
      }}
    >
      <>
        <p className="workflow-modal__docs-count">
          {numberUtils.formatNumberWithCommas(documents.length)}{' '}
          {pluralize('document', documents.length)} selected.
        </p>
        <uiLib.LabeledField label="Select workflow">
          <WorkflowsSelect
            selectedWorkflow={localState.selectedWorkflowOption}
            onChange={handlers.handleWorkflowPick}
            isLoading={localState.isLoading}
          />
        </uiLib.LabeledField>
      </>
    </uiLib.Modal>
  );
};
