import type { SelectOption } from '@compliance.ai/web-components';
import type { PrimaryFilterProps } from '../PrimaryFilter.types';
import type { PrimaryFilterData } from './usePrimaryFilterData.types';
import type { PrimaryFilterHandlers } from './usePrimaryFilterHandlers.types';

import * as errorUtils from 'utils/errors';
import * as constants from 'constants/PrimaryFilter';
import * as helpers from '../PrimaryFilter.helpers';
import * as rightPanelConstants from 'shared/features/rightPanel/rightPanel.constants';
import * as primaryFilterConstants from '../PrimaryFilter.constants';
import * as defaultViewConstants from 'common/Filter/DefaultFiltersViewSelect/DefaultFiltersViewSelect.constants';
import * as handlersHelpers from './usePrimaryFilterHandlers.helpers';

import auth from 'utils/auth';

import { useHistory } from 'utils/hooks';
import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';
import { usePrimaryFilterReduxActions } from './usePrimaryFilterReduxActions';
import { useFilterDefaultOptions } from 'shared/features/filters/hooks';
import { MutableRefObject, useCallback } from 'react';
import { batch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setAnonymousUserFilter } from 'utils/localStorage-utils';

export const usePrimaryFilterHandlers = ({
  props,
  reduxState,
  reduxActions,
  localActions,
  formattedData,
  refs
}: {
  props: PrimaryFilterProps;
  reduxState: PrimaryFilterData['reduxState'];
  reduxActions: ReturnType<typeof usePrimaryFilterReduxActions>;
  localActions: PrimaryFilterData['localActions'];
  formattedData: PrimaryFilterData['formattedData'];
  refs: {
    reduxStateRef: MutableRefObject<PrimaryFilterData['reduxState']>;
    reduxActionsRef: MutableRefObject<ReturnType<typeof usePrimaryFilterReduxActions>>;
  };
}): PrimaryFilterHandlers => {
  const history = useHistory();
  const location = useLocation();
  const rightPanelReduxActions = useRightPanelReduxActions();
  const filterDefaultOptions = useFilterDefaultOptions();
  const orgFilterDefaultOptions = useFilterDefaultOptions(
    defaultViewConstants.DEFAULT_FILTERS_VIEW_OPTIONS.ORG_DEFAULTS
  );

  const handleRefsUpdated: PrimaryFilterHandlers['handleRefsUpdated'] = useCallback(() => {
    refs.reduxStateRef.current = reduxState;
    refs.reduxActionsRef.current = reduxActions;
  }, [reduxActions, reduxState, refs.reduxActionsRef, refs.reduxStateRef]);

  const handleGetOrgDefaults: PrimaryFilterHandlers['handleGetOrgDefaults'] = useCallback(async () => {
    if (!formattedData.doOrgDefaultsExist) {
      localActions.setAreViewDefaultsLoading(true);

      const defaultsData = await reduxActions.fetchDefaults({
        defaultsOwner: defaultViewConstants.DEFAULT_FILTERS_VIEW_OPTIONS.ORG_DEFAULTS,
        orgId: reduxState.current_user?.user?.org_id
      });

      return handlersHelpers.prepareDefaultFilterOptions({ defaultsData, reduxState });
    }
    return orgFilterDefaultOptions;
  }, [
    formattedData.doOrgDefaultsExist,
    localActions,
    orgFilterDefaultOptions,
    reduxActions,
    reduxState
  ]);

  const handleGetTeamDefaults: PrimaryFilterHandlers['handleGetTeamDefaults'] = useCallback(
    async teamId => {
      const existingTeamDefaults = reduxState.defaults.teamDefaultsMap[teamId];

      if (existingTeamDefaults) {
        return handlersHelpers.prepareDefaultFilterOptions({
          defaultsData: existingTeamDefaults,
          reduxState
        });
      }
      localActions.setAreViewDefaultsLoading(true);

      const defaultsData = await reduxActions.fetchDefaults({
        defaultsOwner: defaultViewConstants.DEFAULT_FILTERS_VIEW_OPTIONS.TEAM_DEFAULTS,
        teamId
      });

      return handlersHelpers.prepareDefaultFilterOptions({ defaultsData, reduxState });
    },
    [localActions, reduxActions, reduxState]
  );

  const handleDefaultsViewSelect: PrimaryFilterHandlers['handleDefaultsViewSelect'] = useCallback(
    async selectedView => {
      let filterParams = {};
      reduxActions.setPrimaryFilterDefaultView(selectedView);

      switch (selectedView) {
        case null: {
          filterParams = handlersHelpers.getPrimaryFilterValues({
            filterDefaultOptions: primaryFilterConstants.EMPTY_DEFAULT_FILTER_PARAMS,
            filterParams: reduxState.filterParams,
            isNewsPage: formattedData.isNewsPage
          });
          break;
        }
        case defaultViewConstants.DEFAULT_FILTERS_VIEW_OPTIONS.MY_DEFAULTS: {
          filterParams = handlersHelpers.getPrimaryFilterValues({
            filterDefaultOptions,
            filterParams: reduxState.filterParams,
            isNewsPage: formattedData.isNewsPage
          });
          break;
        }

        case defaultViewConstants.DEFAULT_FILTERS_VIEW_OPTIONS.ORG_DEFAULTS: {
          const orgDefaultOptions = await handleGetOrgDefaults();

          filterParams = handlersHelpers.getPrimaryFilterValues({
            filterDefaultOptions: orgDefaultOptions,
            filterParams: reduxState.filterParams,
            isNewsPage: formattedData.isNewsPage
          });

          break;
        }

        default: {
          const teamDefaultOptions = await handleGetTeamDefaults(selectedView as number);

          filterParams = handlersHelpers.getPrimaryFilterValues({
            filterDefaultOptions: teamDefaultOptions,
            filterParams: reduxState.filterParams,
            isNewsPage: formattedData.isNewsPage
          });
          break;
        }
      }

      reduxActions.setPrimaryFilter(filterParams);
      localActions.setAreViewDefaultsLoading(false);
    },
    [
      filterDefaultOptions,
      formattedData.isNewsPage,
      handleGetOrgDefaults,
      handleGetTeamDefaults,
      localActions,
      reduxActions,
      reduxState.filterParams
    ]
  );

  const handleInitialFiltersLoad: PrimaryFilterHandlers['handleInitialFiltersLoad'] = useCallback(() => {
    const isAuthenticatedUserDataReady =
      reduxState.organization.isReady &&
      reduxState.current_user.isReady &&
      reduxState.current_user.user.id;

    const isUserDataReady = !auth.loggedIn() || isAuthenticatedUserDataReady;

    if (
      isUserDataReady &&
      reduxState.areAuthorsReady &&
      reduxState.agencies.isReady &&
      reduxState.sources.isReady &&
      reduxState.regulations.isReady &&
      reduxState.entities.isReady &&
      reduxState.topics.isReady &&
      reduxState.jurisdictions.isReady &&
      reduxState.conceptsData.isReady &&
      !reduxState.isFilterReady
    ) {
      const initialState = helpers.getFilterState({
        location,
        reduxState
      });
      const { defaultView, ...filtersInitialState } = initialState as Record<string, unknown>;

      batch(() => {
        // For the banks and acts, the data is not loaded by default, so get the IDs from the URL and
        // load them from the API
        reduxActions.fetchActs();
        reduxActions.fetchBanks();
        reduxActions.fetchLabels();

        if (defaultView) {
          handleDefaultsViewSelect(defaultView as string);
        } else {
          reduxActions.setPrimaryFilter(filtersInitialState);
        }

        reduxActions.togglePrimaryFilterReadyState(true);
      });
    }
  }, [handleDefaultsViewSelect, location, reduxActions, reduxState]);

  const handleChange: PrimaryFilterHandlers['handleChange'] = (
    filterValue,
    filterKey,
    isSingle = false
  ) => {
    let filterValueToSave = filterValue;

    // Document properties is single-select for now due to concerns about
    // elasticsearch clause-count for "task" options.
    if (isSingle && Array.isArray(filterValue) && filterValue.length) {
      filterValueToSave = [filterValue[filterValue.length - 1]];
    }

    if (reduxState.filterParams[filterKey] !== filterValue) {
      reduxActions.setPrimaryFilterDefaultView(null);
    }
    reduxActions.setPrimaryFilter({
      [filterKey]: filterValueToSave
    });
  };

  const handleErrorChange: PrimaryFilterHandlers['handleErrorChange'] = (errorKey, errorValue) => {
    reduxActions.setPrimaryFilterErrors({
      [errorKey]: errorValue
    });
  };

  const handleOptionsRequest: PrimaryFilterHandlers['handleOptionsRequest'] = filterKey => async inputValue => {
    try {
      if (!inputValue || inputValue.length < 3) {
        return [];
      }

      const response = await reduxActions.fetchAutoComplete(inputValue, filterKey);

      return response.results.map((item: { name: string; id: number }) => ({
        label: item.name,
        value: item.id
      }));
    } catch (e) {
      errorUtils.logError(e as Error);

      return [];
    }
  };

  const handleFiltersSubmit: PrimaryFilterHandlers['handleFiltersSubmit'] = ({
    shouldClearAlert
  }) => {
    helpers.applyFilters({
      reduxState,
      reduxActions,
      shouldClearAlert,
      history,
      location: history.location,
      searchQuery: props.searchQuery,
      currentView: props.currentView
    });

    rightPanelReduxActions.openRightPanel({
      type: rightPanelConstants.RIGHT_PANEL_TYPES.SEARCH_RESULTS
    });

    reduxActions.closeOverlay();
  };

  const handleClearAll: PrimaryFilterHandlers['handleClearAll'] = async () => {
    localActions.setCleared(true);
    reduxActions.setPrimaryFilterDefaultView(null);
    await reduxActions.clearPrimaryFilter();
    localActions.setCleared(false);
  };

  const handleEitlLabelsChange: PrimaryFilterHandlers['handleEitlLabelsChange'] = options => {
    const eitlLabelsIds = (options as SelectOption[])?.map(({ value }) => value);
    const isNoLabelsOptionSelected = eitlLabelsIds.some(labelId => labelId === null);

    handleChange(eitlLabelsIds, constants.FILTER_KEY.EITL_LABELS);

    if (isNoLabelsOptionSelected) {
      handleChange(false, constants.FILTER_KEY.HAS_EITL_LABELS);
    } else {
      handleChange(null, constants.FILTER_KEY.HAS_EITL_LABELS);
    }
  };

  const handleWillUnmount: PrimaryFilterHandlers['handleWillUnmount'] = useCallback(() => {
    // Refs are required to have actual values instead of stale ones
    const reduxState = refs.reduxStateRef.current;
    const reduxActions = refs.reduxActionsRef.current;

    const filterParams = {
      ...reduxState.filterParams,
      defaultView: reduxState.selectedDefaultView
    };

    // Save filter only if there is no pending request
    if (reduxState.isFilterReady && reduxState.current_user.isReady) {
      reduxActions.updateCurrentUser(reduxState.current_user.user.email, {
        ...reduxState.current_user.user,
        properties: {
          ...reduxState.current_user.user.properties,
          filter: filterParams
        }
      });
    }

    // Save filter in local storage for anonymous user
    if (reduxState.isFilterReady && !auth.loggedIn()) {
      setAnonymousUserFilter(filterParams);
    }

    reduxActions.resetPrimaryFilter();
  }, [refs.reduxActionsRef, refs.reduxStateRef]);

  return {
    handleChange,
    handleErrorChange,
    handleOptionsRequest,
    handleFiltersSubmit,
    handleClearAll,
    handleEitlLabelsChange,
    handleDefaultsViewSelect,
    handleGetOrgDefaults,
    handleGetTeamDefaults,
    handleInitialFiltersLoad,
    handleRefsUpdated,
    handleWillUnmount
  };
};
