import { FILTER_KEY } from 'constants/PrimaryFilter';
import {
  ADVANCED_SEARCH_KEYS,
  QUERY_CONJUNCTIONS,
  QUERY_TRANSLATION_TYPES
} from 'constants/AdvancedSearch';

export const BASIC_FILTERS_TO_ADVANCED_SEARCH_CONFIG: Partial<Record<
  FILTER_KEY,
  {
    key: ADVANCED_SEARCH_KEYS;
    translation: QUERY_TRANSLATION_TYPES;
    conjunction: QUERY_CONJUNCTIONS;
    translationOptions?:
      | {
          startDateKey: ADVANCED_SEARCH_KEYS;
          endDateKey: ADVANCED_SEARCH_KEYS;
          secondaryCheckboxKey?: ADVANCED_SEARCH_KEYS;
        }
      | {
          exactMatchKey: ADVANCED_SEARCH_KEYS;
        }
      | {
          shouldUseLabelsAsValues?: boolean;
        };
  }
>> = {
  [FILTER_KEY.PUB_DATE]: {
    key: ADVANCED_SEARCH_KEYS.PUBLICATION_DATE,
    translation: QUERY_TRANSLATION_TYPES.DATE_WITH_MODIFIERS,
    conjunction: QUERY_CONJUNCTIONS.AND,
    translationOptions: {
      startDateKey: ADVANCED_SEARCH_KEYS.PUBLISHED_FROM,
      endDateKey: ADVANCED_SEARCH_KEYS.PUBLISHED_TO,
      secondaryCheckboxKey: ADVANCED_SEARCH_KEYS.HAS_UNOFFICIAL_PUB_DATE
    }
  },
  [FILTER_KEY.EFFECTIVE_DATE]: {
    key: ADVANCED_SEARCH_KEYS.EFFECTIVE_DATE,
    translation: QUERY_TRANSLATION_TYPES.DATE_WITH_MODIFIERS,
    conjunction: QUERY_CONJUNCTIONS.OR,
    translationOptions: {
      startDateKey: ADVANCED_SEARCH_KEYS.EFFECTIVE_FROM,
      endDateKey: ADVANCED_SEARCH_KEYS.EFFECTIVE_TO
    }
  },
  [FILTER_KEY.COMMENTS_CLOSE_DATE]: {
    key: ADVANCED_SEARCH_KEYS.COMMENTS_CLOSE_DATE,
    translation: QUERY_TRANSLATION_TYPES.DATE_WITH_MODIFIERS,
    conjunction: QUERY_CONJUNCTIONS.OR,
    translationOptions: {
      startDateKey: ADVANCED_SEARCH_KEYS.COMMENTS_CLOSE_FROM,
      endDateKey: ADVANCED_SEARCH_KEYS.COMMENTS_CLOSE_TO
    }
  },
  [FILTER_KEY.ADDED_DATE]: {
    key: ADVANCED_SEARCH_KEYS.ADDED_DATE,
    translation: QUERY_TRANSLATION_TYPES.DATE_WITH_MODIFIERS,
    conjunction: QUERY_CONJUNCTIONS.AND,
    translationOptions: {
      startDateKey: ADVANCED_SEARCH_KEYS.ADDED_FROM,
      endDateKey: ADVANCED_SEARCH_KEYS.ADDED_TO
    }
  },
  [FILTER_KEY.UPDATED_DATE]: {
    key: ADVANCED_SEARCH_KEYS.UPDATED_DATE,
    translation: QUERY_TRANSLATION_TYPES.DATE_WITH_MODIFIERS,
    conjunction: QUERY_CONJUNCTIONS.AND,
    translationOptions: {
      startDateKey: ADVANCED_SEARCH_KEYS.UPDATED_FROM,
      endDateKey: ADVANCED_SEARCH_KEYS.UPDATED_TO
    }
  },
  [FILTER_KEY.TEXT]: {
    key: ADVANCED_SEARCH_KEYS.TEXT,
    translation: QUERY_TRANSLATION_TYPES.TEXT_WITH_MODIFIERS,
    conjunction: QUERY_CONJUNCTIONS.AND,
    translationOptions: {
      exactMatchKey: ADVANCED_SEARCH_KEYS.EXACT_MATCH_TEXT
    }
  },
  [FILTER_KEY.BANKS]: {
    key: ADVANCED_SEARCH_KEYS.BANK,
    translation: QUERY_TRANSLATION_TYPES.IN_OR_EXACT,
    conjunction: QUERY_CONJUNCTIONS.AND
  },
  [FILTER_KEY.AGENCIES]: {
    key: ADVANCED_SEARCH_KEYS.AGENCY,
    translation: QUERY_TRANSLATION_TYPES.IN_OR_EXACT,
    conjunction: QUERY_CONJUNCTIONS.OR,
    translationOptions: {
      shouldUseLabelsAsValues: true
    }
  },
  [FILTER_KEY.DOC_TYPES]: {
    key: ADVANCED_SEARCH_KEYS.CAI_CATEGORY,
    translation: QUERY_TRANSLATION_TYPES.IN_OR_EXACT,
    conjunction: QUERY_CONJUNCTIONS.AND
  },
  [FILTER_KEY.TOPICS]: {
    key: ADVANCED_SEARCH_KEYS.TOPIC,
    translation: QUERY_TRANSLATION_TYPES.IN_OR_EXACT,
    conjunction: QUERY_CONJUNCTIONS.AND
  },
  [FILTER_KEY.ACTS]: {
    key: ADVANCED_SEARCH_KEYS.ACT,
    translation: QUERY_TRANSLATION_TYPES.IN_OR_EXACT,
    conjunction: QUERY_CONJUNCTIONS.AND
  },
  [FILTER_KEY.JURISDICTIONS]: {
    key: ADVANCED_SEARCH_KEYS.JURISDICTION,
    translation: QUERY_TRANSLATION_TYPES.IN_OR_EXACT,
    conjunction: QUERY_CONJUNCTIONS.OR
  },
  [FILTER_KEY.MAINSTREAM_NEWS_SOURCES]: {
    key: ADVANCED_SEARCH_KEYS.NEWS_SOURCE,
    translation: QUERY_TRANSLATION_TYPES.IN_OR_EXACT,
    conjunction: QUERY_CONJUNCTIONS.OR,
    translationOptions: {
      shouldUseLabelsAsValues: true
    }
  },
  [FILTER_KEY.TITLE]: {
    key: ADVANCED_SEARCH_KEYS.TITLE,
    translation: QUERY_TRANSLATION_TYPES.TEXT_WITH_MODIFIERS,
    conjunction: QUERY_CONJUNCTIONS.AND,
    translationOptions: {
      exactMatchKey: ADVANCED_SEARCH_KEYS.EXACT_MATCH_TITLE
    }
  },
  [FILTER_KEY.DOCUMENT_ID]: {
    key: ADVANCED_SEARCH_KEYS.DOC_ID,
    translation: QUERY_TRANSLATION_TYPES.EXACT,
    conjunction: QUERY_CONJUNCTIONS.AND
  },
  [FILTER_KEY.CITATION]: {
    key: ADVANCED_SEARCH_KEYS.CITATION,
    translation: QUERY_TRANSLATION_TYPES.EXACT,
    conjunction: QUERY_CONJUNCTIONS.AND
  },
  [FILTER_KEY.LANGUAGE]: {
    key: ADVANCED_SEARCH_KEYS.LANGUAGE_ID,
    translation: QUERY_TRANSLATION_TYPES.EXACT,
    conjunction: QUERY_CONJUNCTIONS.AND
  },
  [FILTER_KEY.CONCEPTS]: {
    key: ADVANCED_SEARCH_KEYS.CONCEPT,
    translation: QUERY_TRANSLATION_TYPES.IN_OR_EXACT,
    conjunction: QUERY_CONJUNCTIONS.AND
  },
  [FILTER_KEY.DOC_PROPERTIES]: {
    key: ADVANCED_SEARCH_KEYS.DOC_PROPERTY,
    translation: QUERY_TRANSLATION_TYPES.DOC_PROPERTIES,
    conjunction: QUERY_CONJUNCTIONS.AND
  },
  [FILTER_KEY.REGULATIONS]: {
    key: ADVANCED_SEARCH_KEYS.REGULATION,
    translation: QUERY_TRANSLATION_TYPES.IN_OR_EXACT,
    conjunction: QUERY_CONJUNCTIONS.AND
  },
  [FILTER_KEY.AUTHOR]: {
    key: ADVANCED_SEARCH_KEYS.AUTHOR,
    translation: QUERY_TRANSLATION_TYPES.IN_OR_EXACT,
    conjunction: QUERY_CONJUNCTIONS.OR,
    translationOptions: {
      shouldUseLabelsAsValues: true
    }
  },
  [FILTER_KEY.EITL_LABELS]: {
    key: ADVANCED_SEARCH_KEYS.EITL_LABEL_ID,
    translation: QUERY_TRANSLATION_TYPES.EITL_LABELS,
    conjunction: QUERY_CONJUNCTIONS.OR
  }
};
