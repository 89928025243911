import type { ComponentProps } from 'react';

import { ADVANCED_SEARCH_KEYS } from 'constants/AdvancedSearch';

import { AgenciesSelect, AGENCIES_SELECT_VALUE_KEYS } from 'common/AgenciesSelect';
import { JurisdictionsSelect, JURISDICTIONS_SELECT_VALUE_KEYS } from 'common/JurisdictionsSelect';

export const CUSTOM_SELECT_COMPONENTS: Partial<Record<
  ADVANCED_SEARCH_KEYS,
  {
    Component: typeof JurisdictionsSelect | typeof AgenciesSelect;
    props: ComponentProps<typeof JurisdictionsSelect> | ComponentProps<typeof AgenciesSelect>;
  }
>> = {
  [ADVANCED_SEARCH_KEYS.JURISDICTION]: {
    Component: JurisdictionsSelect,
    props: {
      valueKey: JURISDICTIONS_SELECT_VALUE_KEYS.SHORT_NAME
    }
  },
  [ADVANCED_SEARCH_KEYS.AGENCY]: {
    Component: AgenciesSelect,
    props: {
      valueKey: AGENCIES_SELECT_VALUE_KEYS.SHORT_NAME
    }
  }
};

export const MULTI_SELECTION_WITHOUT_CLOSE: Partial<Record<ADVANCED_SEARCH_KEYS, boolean>> = {
  [ADVANCED_SEARCH_KEYS.AUTHOR]: true,
  [ADVANCED_SEARCH_KEYS.AGENCY]: true,
  [ADVANCED_SEARCH_KEYS.JURISDICTION]: true,
  [ADVANCED_SEARCH_KEYS.NEWS_SOURCE]: true,
  [ADVANCED_SEARCH_KEYS.TOPIC]: true,
  [ADVANCED_SEARCH_KEYS.LABEL]: true,
  [ADVANCED_SEARCH_KEYS.EITL_LABEL_ID]: true,
  [ADVANCED_SEARCH_KEYS.REGULATION]: true
};
