import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { convertToRaw } from 'draft-js';
import classnames from 'classnames';
import { Send as SendIcon } from '@material-ui/icons';
import { Editor } from 'react-draft-wysiwyg';
import helpers from './helpers';
import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './_rich-text-editor.scss';

const TOOLBAR_TOOLS = {
  LIST: 'list',
  LINK: 'link',
  EMOJI: 'emoji',
  IMAGE: 'image'
};
const TOOLBAR_CONFIG = {
  options: Object.values(TOOLBAR_TOOLS),
  [TOOLBAR_TOOLS.LIST]: {
    inDropdown: true
  },
  [TOOLBAR_TOOLS.LINK]: {
    defaultTargetOption: '_blank'
  }
};

export const RichTextEditor = ({
  richtext,
  onSave,
  isReadOnly,
  shouldRevertStateOnReadOnlyModeChange,
  onCancel,
  placeholder,
  className
}) => {
  const [editorState, setEditorState] = useState(helpers.makeEditorState(richtext));
  const [editorPrevState, setEditorPrevState] = useState(editorState);

  useEffect(() => {
    const newEditorState = helpers.makeEditorState(richtext);

    setEditorState(newEditorState);
    setEditorPrevState(newEditorState);
  }, [richtext]);

  useEffect(() => {
    if (shouldRevertStateOnReadOnlyModeChange && isReadOnly) {
      setEditorState(editorPrevState);
    }
  }, [isReadOnly, shouldRevertStateOnReadOnlyModeChange, editorPrevState]);

  const handleTextSave = () => {
    if (!isReadOnly) {
      const newRichtext = convertToRaw(editorState.getCurrentContent());
      onSave(newRichtext);
    }
    document.activeElement.blur();
  };

  const handleCancelClick = () => {
    setEditorState(editorPrevState);
    onCancel();
  };

  return (
    <div
      data-testid="richtext-container"
      className={classnames(className, 'rich-text-editor-v2__container')}
    >
      <Editor
        editorState={editorState}
        onEditorStateChange={newEditorState => setEditorState(newEditorState)}
        readOnly={isReadOnly}
        placeholder={!isReadOnly && placeholder}
        wrapperClassName="rich-text-editor-v2__wrapper"
        editorClassName="rich-text-editor-v2__text-editor"
        toolbarClassName={classnames('rich-text-editor-v2__toolbar', {
          'rich-text-editor-v2__toolbar--hidden': isReadOnly
        })}
        toolbarCustomButtons={[
          <div className="rich-text-editor-v2__custom-buttons">
            {onCancel && (
              <span onClick={handleCancelClick} className="rich-text-editor-v2__cancel-button">
                Cancel
              </span>
            )}
            <Button
              type={BUTTON_TYPES.PRIMARY}
              startIcon={<SendIcon />}
              onClick={handleTextSave}
              isDisabled={!editorState.getCurrentContent()?.hasText()}
              className="rich-text-editor-v2__send-button"
            />
          </div>
        ]}
        toolbar={TOOLBAR_CONFIG}
      />
    </div>
  );
};

export default RichTextEditor;

RichTextEditor.propTypes = {
  richtext: PropTypes.oneOfType([
    PropTypes.shape({
      entityMap: PropTypes.shape({}),
      blocks: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          text: PropTypes.string,
          type: PropTypes.string,
          depth: PropTypes.number
        })
      )
    }),
    PropTypes.string
  ]),
  isReadOnly: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  shouldRevertStateOnReadOnlyModeChange: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string
};

RichTextEditor.defaultProps = {
  richtext: null,
  isReadOnly: false,
  onSave: () => {},
  onCancel: undefined,
  shouldRevertStateOnReadOnlyModeChange: false,
  placeholder: '',
  className: ''
};
