import lodash from 'lodash';
import {
  SINGLE_RELATIVE_DATES,
  REATIVE_DATE_OPERATORS,
  PAST_NEXT_RELATIVE_DURATIONS
} from 'constants/RelativeDates';

/**
 *
 * Check if it is single relative date value
 * @param {String|Object} value - value to be checked (e.g: today, Yesterday, Tomorrow, moment("2021-06-01") ...etc)
 * @return {Boolean} - true if the value is "Today", "Yesterday", "Tomorrow"
 *
 */
export const isSingleRelativeDate = value => {
  if (!value || !lodash.isString(value)) {
    return false;
  }

  return Object.values(SINGLE_RELATIVE_DATES).some(
    strDate => strDate.toLowerCase() === value.toLowerCase()
  );
};

/**
 *
 * Check if it is past or next relative date value
 * @param {String|Object} value - value to be checked (e.g: "2:Days", "3:Weeks", "4:MONTHS", "5:years" ...etc)
 * @return {Boolean} - true if the value is "x:Days", "y:Weeks", "z:Months", "n:Years"
 *
 */
export const isPastNextRelativeDate = value => {
  if (!value || !lodash.isString(value)) {
    return false;
  }

  const entries = value.split(REATIVE_DATE_OPERATORS.PAST_NEXT_SEPERATOR);

  return (
    entries.length === 2 &&
    !isNaN(entries[0]) &&
    Object.values(PAST_NEXT_RELATIVE_DURATIONS).some(
      duration => duration.toLowerCase() === entries[1].toLowerCase()
    )
  );
};

export const isPastRelativeDate = value =>
  isPastNextRelativeDate(value) && lodash.startsWith(value, '-');

export const isRelativeDate = value => isSingleRelativeDate(value) || isPastNextRelativeDate(value);

/**
 *
 * Convert to acceptable single relative date format
 * @param {String|Object} value - value to be formatted (e.g: "2:Days", "3:Weeks", "4:MONTHS", "5:years" ...etc)
 * @return {String}} - Acceptable single relative date formatted value
 *
 */
export const formatSingleRelativeDate = value => {
  if (isSingleRelativeDate(value)) {
    return Object.values(SINGLE_RELATIVE_DATES).find(
      strDate => strDate.toLowerCase() === value.toLowerCase()
    );
  }

  return undefined;
};

/**
 *
 * Convert to acceptable past or next relative date format
 * @param {String|Object} value - value to be formatted (e.g: "2:Days", "3:Weeks", "4:MONTHS", "5:years" ...etc)
 * @return {String}} - Acceptable past or next relative date formatted value
 *
 */
export const formatPastNextRelativeDate = value => {
  if (isPastNextRelativeDate(value)) {
    const [amount, duration] = value.split(REATIVE_DATE_OPERATORS.PAST_NEXT_SEPERATOR);
    const formatedDuration = Object.values(PAST_NEXT_RELATIVE_DURATIONS).find(
      d => d.toLowerCase() === duration.toLowerCase()
    );

    return amount + REATIVE_DATE_OPERATORS.PAST_NEXT_SEPERATOR + formatedDuration;
  }

  return undefined;
};

/**
 *
 * Convert to acceptable relative date format
 * @param {String|Object} value - value to be checked (e.g: "today", "yesterday", "tomorrow", moment(), "2:Days", "3:Weeks", "4:MONTHS", "5:years" ...etc)
 * @return {String}} - Acceptable relative date formatted value
 *
 */
export const formatRelativeDate = value => {
  if (isSingleRelativeDate(value)) {
    return formatSingleRelativeDate(value);
  }

  if (isPastNextRelativeDate(value)) {
    return formatPastNextRelativeDate(value);
  }

  return undefined;
};
