import * as folderAPIs from 'shared/features/folders/folders.api';
import * as folderHelpers from 'components/Folders/Folders.helpers';
import * as folderFeatureHelpers from 'shared/features/folders/folders.helpers';
import * as errorUtils from 'utils/errors';
import * as folderConstants from 'components/Folders/Folders.constants';
import * as constants from '../AddToFolderModal.constants';

import { useCallback } from 'react';

export const useAddToFolderModalHandlers = ({
  props,
  localState,
  localActions,
  formattedData,
  reduxActions
}) => {
  const handleFetchSavedFolders = useCallback(async () => {
    localActions.setSavedFolders([]);
    localActions.setIsLoading(true);

    const savedFolders = await reduxActions.fetchDocumentsSavedFolders({
      [folderConstants.API_KEYS.DOCUMENT_IDS]: props.documentIds
    });

    localActions.setSavedFolders(savedFolders);
    localActions.setIsLoading(false);
  }, [props.documentIds, localActions, reduxActions]);

  const handleResetFolders = useCallback(() => {
    localActions.setFolders([]);
    localActions.setPage(constants.DEFAULT_FOLDER_PARAMS.PAGE);
    localActions.setTotalCount(0);
  }, [localActions]);

  const handleFetchFolders = useCallback(async () => {
    handleResetFolders();

    localActions.setIsLoading(true);

    try {
      const response = await folderAPIs.getFolders(formattedData.currentFolder?.id, {
        [folderConstants.API_KEYS.SORT_FIELD]: folderConstants.FOLDER_KEY.NAME,
        [folderConstants.API_KEYS.SORT_ORDER]: folderConstants.SORT_ORDERS.ASC,
        [folderConstants.API_KEYS.PAGE]: localState.page,
        [folderConstants.API_KEYS.COUNT_PER_PAGE]: constants.DEFAULT_FOLDER_PARAMS.LIMIT
      });

      const {
        folders,
        totalCount,
        subFoldersCount,
        docsCount
      } = folderFeatureHelpers.normalizeFoldersResponse(response);

      localActions.setFolders(folders);
      localActions.setTotalCount(totalCount);
      localActions.setSubFoldersCount(subFoldersCount);
      localActions.setDocsCount(docsCount);
    } catch (e) {
      errorUtils.logError(e, 'Error fetching docuemnts saved folders');
      throw e;
    }

    localActions.setIsLoading(false);
  }, [handleResetFolders, localState, localActions, formattedData]);

  const handleFetchMoreFolders = useCallback(async () => {
    localActions.setIsLoading(true);

    try {
      const response = await folderAPIs.getFolders(formattedData.currentFolder?.id, {
        [folderConstants.API_KEYS.SORT_FIELD]: folderConstants.FOLDER_KEY.NAME,
        [folderConstants.API_KEYS.SORT_ORDER]: folderConstants.SORT_ORDERS.ASC,
        [folderConstants.API_KEYS.PAGE]: localState.page + 1,
        [folderConstants.API_KEYS.COUNT_PER_PAGE]: constants.DEFAULT_FOLDER_PARAMS.LIMIT
      });

      const { folders } = folderFeatureHelpers.normalizeFoldersResponse(response);

      localActions.setFolders([...localState.folders, ...folders]);
      localActions.setPage(localState.page + 1);
    } catch (e) {
      errorUtils.logError(e, 'Error fetching docuemnts saved folders');
      throw e;
    }

    localActions.setIsLoading(false);
  }, [localState, localActions, formattedData]);

  const handleFolderClick = useCallback(
    async folder => {
      await localActions.setFolderPaths([...localState.folderPaths, folder]);
    },
    [localState, localActions]
  );

  const handleFolderBackClick = useCallback(() => {
    if (localState.isAddingFolder) {
      localActions.setIsAddingFolder(false);
      localActions.setFolderName('');
    } else if (localState.folderPaths.length) {
      handleResetFolders();
      localActions.setFolderPaths(
        localState.folderPaths.slice(0, localState.folderPaths.length - 1)
      );
    }
  }, [localState, localActions, handleResetFolders]);

  const handleNewFolderClick = useCallback(() => {
    localActions.setIsAddingFolder(true);
  }, [localActions]);

  const handleFolderNameChange = useCallback(
    folderName => {
      localActions.setFolderName(folderName);
    },
    [localActions]
  );

  const handleCancel = useCallback(() => {
    props.onClose();
  }, [props]);

  const handleCreateNewFolder = useCallback(async () => {
    localActions.setIsLoading(true);

    await reduxActions.createFolder(formattedData.currentFolder?.id, {
      [folderConstants.API_KEYS.NAME]: localState.folderName
    });

    await handleFetchFolders();

    localActions.setIsAddingFolder(false);
    localActions.setFolderName('');
  }, [handleFetchFolders, localState, localActions, formattedData, reduxActions]);

  const handleAddDocumentsToFolder = useCallback(async () => {
    props.onActionStart?.();
    localActions.setIsLoading(true);

    await reduxActions.addDocumentsToFolder(formattedData.currentFolder?.id, {
      [folderConstants.API_KEYS.DOCUMENT_IDS]: props.documentIds
    });

    if (folderHelpers.isBookmarkedFolder(formattedData.currentFolder)) {
      reduxActions.markDocumentBookmarked(props.documentIds, true);
    }

    localActions.setIsLoading(false);

    props.onClose(true);
  }, [props, localActions, formattedData, reduxActions]);

  const handleSubmit = useCallback(() => {
    if (localState.isAddingFolder) {
      handleCreateNewFolder();
    } else {
      handleAddDocumentsToFolder();
    }
  }, [localState.isAddingFolder, handleCreateNewFolder, handleAddDocumentsToFolder]);

  return {
    handleFetchSavedFolders,
    handleResetFolders,
    handleFetchFolders,
    handleFetchMoreFolders,
    handleFolderClick,
    handleFolderBackClick,
    handleNewFolderClick,
    handleFolderNameChange,
    handleSubmit,
    handleCancel
  };
};
