import {
  EMAIL_VALIDATOR_KEY,
  EMAIL_VALIDATOR_MESSAGES,
  PASSWORD_VALIDATOR_KEY,
  PASSWORD_VALIDATOR_MESSAGES
} from './SignUpDataValidator.constants';
import { EMAIL } from 'constants/Regex';

export const isValidEmailItem = (item, email) => {
  if (!email || typeof email !== 'string') return false;

  switch (item) {
    case EMAIL_VALIDATOR_KEY.VALID_FORMAT:
      return EMAIL.test(email.trim());

    default:
      return false;
  }
};

export const isValidPasswordItem = (item, password) => {
  if (!password) return false;

  switch (item) {
    case PASSWORD_VALIDATOR_KEY.CHAR8:
      return password.length >= 8;

    case PASSWORD_VALIDATOR_KEY.LOWERCASE:
      return /[a-z]/.test(password);

    case PASSWORD_VALIDATOR_KEY.UPPERCASE:
      return /[A-Z]/.test(password);

    case PASSWORD_VALIDATOR_KEY.NUMBER:
      return /[0-9]/.test(password);

    case PASSWORD_VALIDATOR_KEY.SYMBOL:
      return /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password);

    default:
      return false;
  }
};

export const isValidEmail = email =>
  Object.keys(EMAIL_VALIDATOR_MESSAGES).every(key => isValidEmailItem(key, email));

export const isValidPassword = password =>
  Object.keys(PASSWORD_VALIDATOR_MESSAGES).every(key => isValidPasswordItem(key, password));
