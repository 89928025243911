import type { PlainTextHandlers } from './usePlainTextHandlers.types';
import type { PlainTextData } from './usePlainTextData.types';
import type { PlainTextProps } from '../PlainText.types';

import lodash from 'lodash';
import { Utils } from 'react-awesome-query-builder';
import * as helpers from './usePlainTextHandlers.helpers';
import { useCallback } from 'react';

export const usePlainTextHandlers = ({
  props,
  localState,
  localActions,
  formattedData,
  prevFormattedData
}: {
  props: Pick<PlainTextProps, 'config'>;
  localState: PlainTextData['localState'];
  localActions: PlainTextData['localActions'];
  formattedData: PlainTextData['formattedData'];
  prevFormattedData: PlainTextData['prevFormattedData'];
}): PlainTextHandlers => {
  const handleQuerySuccess: PlainTextHandlers['handleQuerySuccess'] = advancedSearchKey => data => {
    localActions.setAsyncIdsToNamesMaps(restData => ({
      ...restData,
      [advancedSearchKey]: data
    }));
  };

  const handlePlainTextSet: PlainTextHandlers['handlePlainTextSet'] = useCallback(() => {
    if (
      !localState.isPlainTextSet ||
      !lodash.isEqual(formattedData.idsToNamesMaps, prevFormattedData.idsToNamesMaps)
    ) {
      const clonedJsonLogicTree = lodash.cloneDeep(formattedData.parsedQuery.jsonLogicTree);

      if (clonedJsonLogicTree) {
        formattedData.parsedQuery.jsonLogicTreeVars.forEach((variable, i) => {
          const shouldConvertToSelectOptions = helpers.shouldSetSelectOptions(variable);

          const variableKey = variable[0].var;
          const variablePath = formattedData.parsedQuery.jsonLogicTreeVarsPaths[i];
          const variableValues = formattedData.parsedQuery.jsonLogicTreeVarsValues[i];
          const variableNamesMap = formattedData.idsToNamesMaps[variableKey];
          const variableNames = helpers.getValuesNames({
            variableValues,
            variableNamesMap
          });

          if (Array.isArray(variableValues)) {
            helpers.setMultipleValuesToConfig({
              jsonLogicTree: clonedJsonLogicTree,
              variableKey,
              variablePath,
              variableNames,
              shouldConvertToSelectOptions
            });
          } else if (variableValues) {
            helpers.setSingleValueToConfig({
              jsonLogicTree: clonedJsonLogicTree,
              variableKey,
              variablePath,
              variableNames
            });
          }
        });

        localActions.setPlainText(
          Utils.queryString(
            Utils.loadFromJsonLogic(clonedJsonLogicTree, props.config),
            props.config
          )
        );
        localActions.setIsPlainTextSet(true);
      }
    }
  }, [
    formattedData.idsToNamesMaps,
    formattedData.parsedQuery.jsonLogicTree,
    formattedData.parsedQuery.jsonLogicTreeVars,
    formattedData.parsedQuery.jsonLogicTreeVarsPaths,
    formattedData.parsedQuery.jsonLogicTreeVarsValues,
    localActions,
    localState.isPlainTextSet,
    prevFormattedData.idsToNamesMaps,
    props.config
  ]);

  return {
    handleQuerySuccess,
    handlePlainTextSet
  };
};
