import * as apiTypes from './acts.api.types';

import { fetch } from 'fetch';

export const fetchActs: apiTypes.FetchActs = ids => {
  return fetch({
    url: `/acts`,
    params: {
      ids: ids.join(',')
    },
    dataType: 'acts',
    method: 'GET'
  });
};
