import type { PrimaryFilterData } from './usePrimaryFilterData.types';
import type { StoreDispatch } from 'store';
import type { FetchActsResponse } from 'shared/features/acts/acts.api.types';
import type { FetchBanksResponse } from 'shared/features/banks/banks.api.types';
import type { NormalizedDefaults } from 'shared/features/defaults/defaults.types';
import type { AutocompleteResponse } from 'shared/features/search/search.types';

import * as labelsHelpers from 'shared/features/labels/labels.helpers';
import * as labelsConstants from 'shared/features/labels/labels.constants';
import * as primaryFilterConstants from 'constants/PrimaryFilter';
import * as helpers from '../PrimaryFilter.helpers';

import { updateCurrentUser as reduxUpdateCurrentUser } from 'shared/features/user/user.actions';
import { updateAlert as reduxUpdateAlert } from 'shared/features/alerts/alerts.actions';
import {
  addEditAlert as reduxAddEditAlert,
  addViewAlert as reduxAddViewAlert,
  showPreviewAlert as reduxShowPreviewAlert,
  closeFilter as reduxCloseFilter,
  removeEditAlert as reduxRemoveEditAlert,
  removeViewAlert as reduxRemoveViewAlert,
  showSaveAlertSuccessModal as reduxShowSaveAlertSuccessModal,
  toggleFilter as reduxToggleFilter,
  closeAdvancedSearchFilter as reduxCloseAdvancedSearchFilter
} from 'shared/features/view/view.actions';
import { fetchAutoComplete as reduxFetchAutoComplete } from 'shared/features/search/search.actions';
import { fetchActs as reduxFetchActs } from 'shared/features/acts/acts.actions';
import { fetchBanks as reduxFetchBanks } from 'shared/features/banks/banks.actions';
import {
  setPrimaryFilter as reduxSetPrimaryFilter,
  resetPrimaryFilter as reduxResetPrimaryFilter,
  setPrimaryFilterDefaultView as reduxSetPrimaryFilterDefaultView,
  setPrimaryFilterErrors as reduxSetPrimaryFilterErrors,
  togglePrimaryFilterLoadingState as reduxTogglePrimaryFilterLoadingState,
  togglePrimaryFilterReadyState as reduxTogglePrimaryFilterReadyState,
  clearPrimaryFilter as reduxClearPrimaryFilter
} from 'shared/features/filters/filters.actions';
import {
  fetchDefaults as reduxFetchDefaults,
  updateTeamDefaultsFromExistingMap as reduxUpdateTeamDefaultsFromExistingMap
} from 'shared/features/defaults/defaults.actions';

import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useOrganizationLabelsReduxActions } from 'shared/features/labels/hooks';
import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';

export const usePrimaryFilterReduxActions = ({
  reduxState
}: {
  reduxState: PrimaryFilterData['reduxState'];
}) => {
  const dispatch = useDispatch<StoreDispatch>();
  const location = useLocation();
  const reduxActions = useOrganizationLabelsReduxActions();
  const rightPanelReduxActions = useRightPanelReduxActions();

  const updateCurrentUser = useCallback(
    (...args: Parameters<typeof reduxUpdateCurrentUser>) => {
      return dispatch(reduxUpdateCurrentUser(...args));
    },
    [dispatch]
  );

  const updateAlert = useCallback(
    (...args: Parameters<typeof reduxUpdateAlert>) => {
      dispatch(reduxUpdateAlert(...args));
    },
    [dispatch]
  );

  const addEditAlert = useCallback(
    (...args: Parameters<typeof reduxAddEditAlert>) => {
      dispatch(reduxAddEditAlert(...args));
    },
    [dispatch]
  );

  const addViewAlert = useCallback(
    (...args: Parameters<typeof reduxAddViewAlert>) => {
      dispatch(reduxAddViewAlert(...args));
    },
    [dispatch]
  );

  const showPreviewAlert = useCallback(() => {
    dispatch(reduxShowPreviewAlert());
  }, [dispatch]);

  const showSaveAlertSuccessModal = useCallback(
    (alertName: Parameters<typeof reduxShowSaveAlertSuccessModal>['1']) => {
      dispatch(reduxShowSaveAlertSuccessModal('modify', alertName));
    },
    [dispatch]
  );

  const removeEditAlert = useCallback(() => {
    dispatch(reduxRemoveEditAlert());
  }, [dispatch]);

  const removeViewAlert = useCallback(() => {
    dispatch(reduxRemoveViewAlert());
  }, [dispatch]);

  const closeFilter = useCallback(() => {
    dispatch(reduxCloseFilter());
  }, [dispatch]);

  const closeAdvancedSearchFilter = useCallback(() => {
    dispatch(reduxCloseAdvancedSearchFilter());
  }, [dispatch]);

  const toggleFilter = useCallback(() => {
    dispatch(reduxToggleFilter());
  }, [dispatch]);

  const clearPrimaryFilter = useCallback(() => {
    dispatch(reduxClearPrimaryFilter());
  }, [dispatch]);

  const fetchAutoComplete = useCallback(
    (...args: Parameters<typeof reduxFetchAutoComplete>) => {
      return dispatch(reduxFetchAutoComplete(...args) as unknown) as Promise<AutocompleteResponse>;
    },
    [dispatch]
  );

  const setPrimaryFilter = useCallback(
    (...args: Parameters<typeof reduxSetPrimaryFilter>) => {
      dispatch(reduxSetPrimaryFilter(...args));
    },
    [dispatch]
  );

  const resetPrimaryFilter = useCallback(() => {
    dispatch(reduxResetPrimaryFilter());
  }, [dispatch]);

  const setPrimaryFilterDefaultView = useCallback(
    (...args: Parameters<typeof reduxSetPrimaryFilterDefaultView>) => {
      dispatch(reduxSetPrimaryFilterDefaultView(...args));
    },
    [dispatch]
  );

  const setPrimaryFilterErrors = useCallback(
    (errors: Record<string, string>) => {
      dispatch(reduxSetPrimaryFilterErrors({ errors }));
    },
    [dispatch]
  );

  const togglePrimaryFilterLoadingState = useCallback(
    (args: { filterKey: string; isLoading: boolean }) => {
      dispatch(reduxTogglePrimaryFilterLoadingState(args));
    },
    [dispatch]
  );

  const togglePrimaryFilterReadyState = useCallback(
    (...args: Parameters<typeof reduxTogglePrimaryFilterReadyState>) => {
      dispatch(reduxTogglePrimaryFilterReadyState(...args));
    },
    [dispatch]
  );

  const fetchActs = useCallback(async () => {
    try {
      // Filter out values that already exist
      const unsetAsyncFilterParams = helpers.getUnsetAsyncParams({
        reduxState,
        filterKey: primaryFilterConstants.FILTER_KEY.ACTS,
        queryParamKey: primaryFilterConstants.SUPPORTED_QUERY_PARAMS.ACT_ID,
        location
      });

      if (!unsetAsyncFilterParams.length) {
        return;
      }

      togglePrimaryFilterLoadingState({
        filterKey: primaryFilterConstants.FILTER_KEY.ACTS,
        isLoading: true
      });

      const response = (await dispatch(
        reduxFetchActs(unsetAsyncFilterParams) as unknown
      )) as FetchActsResponse;

      const filterCurrentValue = reduxState.filterParams[primaryFilterConstants.FILTER_KEY.ACTS];
      const filterResponseValue = response.acts.map(item => ({
        value: item.id,
        label: item.name
      }));
      const filterValue = helpers.mergeOptionsByUniqueness(
        filterCurrentValue as unknown[],
        filterResponseValue
      );

      setPrimaryFilter({ [primaryFilterConstants.FILTER_KEY.ACTS]: filterValue });
    } finally {
      togglePrimaryFilterLoadingState({
        filterKey: primaryFilterConstants.FILTER_KEY.ACTS,
        isLoading: false
      });
    }
  }, [dispatch, location, reduxState, setPrimaryFilter, togglePrimaryFilterLoadingState]);

  const fetchBanks = useCallback(async () => {
    try {
      // Filter out values that already exist
      const unsetAsyncFilterParams = helpers.getUnsetAsyncParams({
        reduxState,
        filterKey: primaryFilterConstants.FILTER_KEY.BANKS,
        queryParamKey: primaryFilterConstants.SUPPORTED_QUERY_PARAMS.BANK_ID,
        location
      });

      if (!unsetAsyncFilterParams.length) {
        return;
      }

      togglePrimaryFilterLoadingState({
        filterKey: primaryFilterConstants.FILTER_KEY.BANKS,
        isLoading: true
      });

      const response = (await dispatch(
        reduxFetchBanks(unsetAsyncFilterParams) as unknown
      )) as FetchBanksResponse;

      const filterCurrentValue = reduxState.filterParams[primaryFilterConstants.FILTER_KEY.BANKS];
      const filterResponseValue = response.banks.map(item => ({
        value: item.id,
        label: item.name
      }));
      const filterValue = helpers.mergeOptionsByUniqueness(
        filterCurrentValue as unknown[],
        filterResponseValue
      );

      setPrimaryFilter({ [primaryFilterConstants.FILTER_KEY.BANKS]: filterValue });
    } finally {
      togglePrimaryFilterLoadingState({
        filterKey: primaryFilterConstants.FILTER_KEY.BANKS,
        isLoading: false
      });
    }
  }, [dispatch, location, reduxState, setPrimaryFilter, togglePrimaryFilterLoadingState]);

  const fetchLabels = useCallback(async () => {
    try {
      // Filter out values that already exist
      const unsetAsyncFilterParams = helpers.getUnsetAsyncParams({
        reduxState,
        filterKey: primaryFilterConstants.FILTER_KEY.LABELS,
        queryParamKey: primaryFilterConstants.SUPPORTED_QUERY_PARAMS.LABEL,
        location
      });

      if (!unsetAsyncFilterParams.length) {
        return;
      }

      togglePrimaryFilterLoadingState({
        filterKey: primaryFilterConstants.FILTER_KEY.LABELS,
        isLoading: true
      });

      const response = await reduxActions.fetchAllLabels({
        id: unsetAsyncFilterParams,
        count_per_page: labelsConstants.MAX_LABELS_COUNT
      });

      const filterCurrentValue = reduxState.filterParams[primaryFilterConstants.FILTER_KEY.LABELS];
      const filterResponseValue = response.labels.map(labelsHelpers.formatLabelOption);

      const filterValue = helpers.mergeOptionsByUniqueness(
        filterCurrentValue as unknown[],
        filterResponseValue
      );

      setPrimaryFilter({ [primaryFilterConstants.FILTER_KEY.LABELS]: filterValue });
    } finally {
      togglePrimaryFilterLoadingState({
        filterKey: primaryFilterConstants.FILTER_KEY.LABELS,
        isLoading: false
      });
    }
  }, [reduxActions, location, reduxState, setPrimaryFilter, togglePrimaryFilterLoadingState]);

  const fetchDefaults = useCallback(
    async (data: { defaultsOwner: string; teamId?: number; orgId?: number }) => {
      return dispatch(reduxFetchDefaults(data as never) as unknown) as Promise<NormalizedDefaults>;
    },
    [dispatch]
  );

  const updateTeamDefaultsFromExistingMap = useCallback(
    (teamId: number) => {
      return dispatch(reduxUpdateTeamDefaultsFromExistingMap(teamId));
    },
    [dispatch]
  );

  return {
    updateCurrentUser,
    updateAlert,
    addEditAlert,
    addViewAlert,
    showPreviewAlert,
    showSaveAlertSuccessModal,
    removeEditAlert,
    removeViewAlert,
    closeFilter,
    closeAdvancedSearchFilter,
    toggleFilter,
    fetchAutoComplete,
    fetchActs,
    fetchBanks,
    fetchLabels,
    setPrimaryFilter,
    resetPrimaryFilter,
    setPrimaryFilterDefaultView,
    setPrimaryFilterErrors,
    togglePrimaryFilterLoadingState,
    togglePrimaryFilterReadyState,
    clearPrimaryFilter,
    closeOverlay: rightPanelReduxActions.closeOverlay,
    fetchDefaults,
    updateTeamDefaultsFromExistingMap
  };
};
