import type { TaskApprovalAnnotationProps } from '../TaskApprovalAnnotation.types';
import type { TaskApprovalAnnotationHandlers } from './useTaskApprovalAnnotationHandlers.types';

import * as errorUtils from 'utils/errors';

export const useTaskApprovalAnnotationHandlers = ({
  props
}: {
  props: Pick<TaskApprovalAnnotationProps, 'documentTask' | 'onChange'>;
}): TaskApprovalAnnotationHandlers => {
  const handleSubmitForApproval: TaskApprovalAnnotationHandlers['handleSubmitForApproval'] = async userId => {
    try {
      if (!props.documentTask.approvers.includes(userId)) {
        await props.onChange([
          {
            task_id: props.documentTask.id,
            approver_ids: [...props.documentTask.approvers, userId]
          }
        ]);
      }

      await props.onChange([
        {
          task_id: props.documentTask.id,
          submit_for_approval: userId
        }
      ]);
    } catch (e) {
      errorUtils.logError(e as Error);
    }
  };

  const handleApproval: TaskApprovalAnnotationHandlers['handleApproval'] = shouldApprove => async () => {
    try {
      await props.onChange([
        {
          task_id: props.documentTask.id,
          approve_task: shouldApprove
        }
      ]);
    } catch (e) {
      errorUtils.logError(e as Error);
    }
  };

  return {
    handleSubmitForApproval,
    handleApproval
  };
};
