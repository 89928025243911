import queryString from 'utils/query-string';
import lodash from 'lodash';
import { SearchButton } from 'common';
import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import { FILTER_KEY } from 'constants/JurisdictionAnalyzer';
import { formatQueryStringFromFilterParams } from '../../JurisdictionAnalyzerFilter.helpers';
import { useJurisdictionFilterData, useJurisdictionReduxActions } from '../../hooks';
import { SEARCH_TYPES } from 'constants/SearchTypes';

const FooterButtons = ({ onSubmit, urlParams }) => {
  const { localState, reduxState, isAlertEditMode, filterErrors } = useJurisdictionFilterData();
  const reduxActions = useJurisdictionReduxActions(reduxState);

  const isJASearchDisabled =
    !lodash.isEmpty(filterErrors) ||
    (lodash.isEmpty(reduxState.filterParams[FILTER_KEY.CONCEPT_ID]) &&
      lodash.isEmpty(reduxState.filterParams[FILTER_KEY.SEARCH_QUERY]));

  const handleAlertUpdate = async () => {
    const alertId = reduxState.current_view.editAlertId;
    const searchArgs = queryString.parse(
      formatQueryStringFromFilterParams({
        filterParams: reduxState.filterParams
      })
    );

    onSubmit({ shouldClearAlert: true });

    await reduxActions.updateAlert(alertId, {
      search_args: searchArgs,
      name: localState.alertName,
      pathname: '/resources'
    });

    reduxActions.addEditAlert(alertId, localState.alertName);
    reduxActions.addViewAlert(alertId, localState.alertName);
    reduxActions.showSaveAlertSuccessModal(localState.alertName);
  };

  return (
    <>
      {isAlertEditMode && reduxState.current_view.editAlertId && (
        <Button
          type={BUTTON_TYPES.SECONDARY}
          onClick={handleAlertUpdate}
          isDisabled={isJASearchDisabled}
        >
          UPDATE THIS ALERT
        </Button>
      )}
      <SearchButton
        type={BUTTON_TYPES.PRIMARY}
        onClick={() => onSubmit({ shouldClearAlert: false })}
        disabled={isJASearchDisabled}
        text="Search Jurisdictions"
        queryParams={urlParams}
        searchType={SEARCH_TYPES.JURISDICTION_ANAYLYZER_SEARCH}
      />
    </>
  );
};

export default FooterButtons;
