import type { TableProps } from '@compliance.ai/web-components';

import { AlertsTableDateColumn, AlertsTableUserColumn } from '../elements';
import { TABLE_KEYS } from '../AlertsTable.constants';

export const useAlertsTableColumns = (): TableProps['columns'] => {
  return [
    {
      title: 'Name',
      dataKey: TABLE_KEYS.NAME,
      sortKey: TABLE_KEYS.NAME
    },
    {
      title: 'Last Updated',
      dataKey: TABLE_KEYS.UPDATED_AT,
      sortKey: TABLE_KEYS.UPDATED_AT,
      render: AlertsTableDateColumn
    },
    {
      title: 'Created By',
      dataKey: TABLE_KEYS.USER_ID,
      sortKey: TABLE_KEYS.USER_ID,
      render: AlertsTableUserColumn
    }
  ];
};
