import type { SelectOption, SelectOptions } from '@compliance.ai/web-components';
import type { DefaultFiltersViewSelectData } from './useDefaultFiltersViewSelectData.types';

import * as constants from '../DefaultFiltersViewSelect.constants';
import * as sortUtils from 'utils/sort';

import { useMemo } from 'react';

export const useDefaultFiltersViewSelectOptions = ({
  reduxState
}: {
  reduxState: DefaultFiltersViewSelectData['reduxState'];
}): SelectOptions => {
  const teamsOptions: SelectOptions = useMemo(() => {
    const teamsMap = reduxState.teams.reduce((groupedTeams, team) => {
      const groupingType = team.grouping_type || constants.DEFAULT_FILTERS_VIEW_OPTIONS.CORPORATE;
      const existingTeamsOfType = groupedTeams[groupingType] || [];

      return {
        ...groupedTeams,
        [groupingType]: [...existingTeamsOfType, { value: team.id, label: team.name }]
      };
    }, {} as Record<string, SelectOption[]>);

    return Object.entries(teamsMap).map(([group, teams]) => ({
      value: group,
      label: group,
      isDisabled: true,
      options: teams.sort(sortUtils.alphabeticallyByLabel)
    }));
  }, [reduxState.teams]);

  const viewOptions = useMemo(() => {
    let options: SelectOptions = [
      {
        value: constants.DEFAULT_FILTERS_VIEW_OPTIONS.MY_VIEWS,
        label: constants.DEFAULT_FILTERS_VIEW_OPTIONS.MY_VIEWS,
        isDisabled: true,
        options: [
          {
            value: constants.DEFAULT_FILTERS_VIEW_OPTIONS.MY_DEFAULTS,
            label: 'My Default Filters'
          },
          ...(reduxState.isAuthenticated
            ? [
                {
                  value: constants.DEFAULT_FILTERS_VIEW_OPTIONS.ORG_DEFAULTS,
                  label: 'Organization Default Filters'
                }
              ]
            : [])
        ]
      }
    ];
    if (teamsOptions?.length) {
      options = [...options, ...teamsOptions];
    }
    return options;
  }, [reduxState.isAuthenticated, teamsOptions]);

  return viewOptions;
};
