import type * as actionsTypes from './orgDocumentsMetadata.actions.types';
import type * as globalReducersState from '../../reducers';
import type * as reducerTypes from './orgDocumentsMetadata.reducer.types';

import * as reactRedux from 'react-redux';
import * as reduxToolkit from '@reduxjs/toolkit';
import * as errorUtils from 'utils/errors';
import * as uiLib from '@compliance.ai/web-components';
import * as api from './orgDocumentsMetadata.api';
import * as constants from './orgDocumentsMetadata.constants';
import * as helpers from './orgDocumentsMetadata.helpers';
import * as selectors from './orgDocumentsMetadata.selectors';
import * as orgDocsApi from '../organizationDocuments/organizationDocuments.api';
import * as docFieldsSelectors from '../documentFields/documentFields.selectors';
import * as userSelectors from '../user/user.selectors';
import * as metadataPipeline from './metadata-pipeline';

export const SET_ACTIVE_UPLOAD_STEP_KEY = 'SET_ACTIVE_UPLOAD_STEP_KEY';
export const SET_ACTIVE_MAPPINGS_TAB_KEY = 'SET_ACTIVE_MAPPINGS_TAB_KEY';
export const SET_METADATA_LOADING = 'SET_METADATA_LOADING';
export const SET_IS_DRAFT = 'SET_IS_DRAFT';
export const SET_NEXT_STEP_DISABLED = 'SET_NEXT_STEP_DISABLED';
export const SET_METADATA_FOR_FILES = 'SET_METADATA_FOR_FILES';
export const SET_METADATA = 'SET_METADATA';
export const SET_METADATA_FIELD = 'SET_METADATA_FIELD';
export const SET_CONFIRMATION = 'SET_CONFIRMATION';
export const SET_SELECTED_CAI_CAPABILITIES = 'SET_SELECTED_CAI_CAPABILITIES';
export const SET_DEFAULT_FIELDS = 'SET_DEFAULT_FIELDS';
export const SET_NEXT_STEP_TRIGGER = 'SET_NEXT_STEP_TRIGGER';
export const SET_PREV_STEP_TRIGGER = 'SET_PREV_STEP_TRIGGER';
export const SET_IMPORT_ID = 'SET_IMPORT_ID';
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
export const SET_FILE_NAMES = 'SET_FILE_NAMES';
export const SET_BUCKET_IDS = 'SET_BUCKET_IDS';
export const UPLOAD_MAPPINGS_DOCUMENT = 'UPLOAD_MAPPINGS_DOCUMENT';
export const UPLOAD_METADATA = 'UPLOAD_METADATA';
export const SET_METADATA_ATTRIBUTE_TAG_MAP_ATTRIBUTE = 'SET_METADATA_ATTRIBUTE_TAG_MAP_ATTRIBUTE';
export const SET_METADATA_ATTRIBUTE_TAG_MAP_TAGS = 'SET_METADATA_ATTRIBUTE_TAG_MAP_TAGS';
export const SET_METADATA_ATTRIBUTE_TAG_MAP_TAG_VALUE = 'SET_METADATA_ATTRIBUTE_TAG_MAP_TAG_VALUE';
export const ADD_NEW_METADATA_ATTRIBUTE_TAG_MAP = 'ADD_NEW_METADATA_ATTRIBUTE_TAG_MAP';
export const REMOVE_METADATA_ATTRIBUTE_TAG_MAP = 'REMOVE_METADATA_ATTRIBUTE_TAG_MAP';

export const setActiveStepKey: actionsTypes.SetActiveStepKey = reduxToolkit.createAction(
  SET_ACTIVE_UPLOAD_STEP_KEY
);
export const setActiveMappingsTabKey: actionsTypes.SetActiveMappingsTabKey = reduxToolkit.createAction(
  SET_ACTIVE_MAPPINGS_TAB_KEY
);
export const setIsNextStepDisabled: actionsTypes.SetIsNextStepDisabled = reduxToolkit.createAction(
  SET_NEXT_STEP_DISABLED
);
export const setIsDraft: actionsTypes.SetIsDraft = reduxToolkit.createAction(SET_IS_DRAFT);
export const setMetadataForFiles: actionsTypes.SetMetadataForFiles = reduxToolkit.createAction(
  SET_METADATA_FOR_FILES
);
export const setMetadata: actionsTypes.SetMetadata = reduxToolkit.createAction(SET_METADATA);
export const setMetadataField: actionsTypes.SetMetadataField = reduxToolkit.createAction(
  SET_METADATA_FIELD
);
export const setFileNames: actionsTypes.SetFileNames = reduxToolkit.createAction(SET_FILE_NAMES);
export const setConfirmation: actionsTypes.SetConfirmation = reduxToolkit.createAction(
  SET_CONFIRMATION
);
export const setSelectedCAICapabilities: actionsTypes.SetSelectedCAICapabilities = reduxToolkit.createAction(
  SET_SELECTED_CAI_CAPABILITIES
);
export const setDefaultFields: actionsTypes.SetDefaultFields = reduxToolkit.createAction(
  SET_DEFAULT_FIELDS
);
export const setNextStepTrigger: actionsTypes.SetNextStepTrigger = reduxToolkit.createAction(
  SET_NEXT_STEP_TRIGGER
);
export const setPrevStepTrigger: actionsTypes.SetPrevStepTrigger = reduxToolkit.createAction(
  SET_PREV_STEP_TRIGGER
);
export const setImportId: actionsTypes.SetImportId = reduxToolkit.createAction(SET_IMPORT_ID);
export const setBucketIds: actionsTypes.SetBucketIds = reduxToolkit.createAction(SET_BUCKET_IDS);

export const setInitialState: actionsTypes.SetInitialState = reduxToolkit.createAction(
  SET_INITIAL_STATE
);

export const setMetadataFromOrgDocs = reduxToolkit.createAsyncThunk<
  actionsTypes.SetMetadataFromOrgDocsReturn,
  actionsTypes.SetMetadataFromOrgDocsPayload
>(UPLOAD_MAPPINGS_DOCUMENT, async (orgDocs, { dispatch, getState }) => {
  try {
    const state = getState() as globalReducersState.GlobalState;

    const docFields = docFieldsSelectors.getDocumentFields(state);
    const mandatoryDocFields = docFieldsSelectors.getMandatoryDocumentFields(state);

    const fileNames = helpers.getFileNamesFromOrgDocsResponse(orgDocs);
    const metadataFromResponse = helpers.getOrgDocsMetadataFromResponse(orgDocs);

    const metadata = await new metadataPipeline.Pipeline<reducerTypes.OrgDocumentsMetadata>({
      initialMetadata: metadataFromResponse,
      stages: [
        new metadataPipeline.TranslateFieldsStage({
          asyncTranslationsConfig: Object.fromEntries(
            Object.entries(constants.METADATA_CONFIG).map(([metadataKey, config]) => [
              metadataKey,
              config.fromUpload.asyncTranslation
            ])
          ),

          translationsMap: Object.fromEntries(
            Object.entries(constants.METADATA_CONFIG).map(([metadataKey, config]) => [
              metadataKey,
              config.fromUpload.translation?.selector(state as never)
            ])
          )
        }),

        new metadataPipeline.TransformStage({
          multiFieldsMap: Object.fromEntries(
            Object.entries(constants.METADATA_CONFIG).map(([metadataKey, config]) => [
              metadataKey,
              Boolean(config.fromUpload.isMulti)
            ])
          )
        }),

        new metadataPipeline.ValidationStage({
          validatorsMap: Object.fromEntries(
            Object.entries(constants.METADATA_CONFIG).map(([metadataKey, config]) => [
              metadataKey,
              config.fromUpload.validation
            ])
          )
        }),

        new metadataPipeline.ConditionalFieldsStage({
          isReversed: true,
          conditionalFieldsMap: Object.fromEntries(
            Object.entries(constants.METADATA_CONFIG).map(([metadataKey, config]) => [
              metadataKey,
              config.fromUpload.conditionalKey
            ])
          )
        }),

        new metadataPipeline.MapToStateMetadataStage({
          docFields: docFields,
          mapBy: 'value'
        })
      ]
    }).run();

    const unmappedMandatoryDocFields = helpers.getUnmappedMandatoryDocFields({
      metadata: metadata,
      mandatoryDocFields: mandatoryDocFields
    });

    dispatch(setFileNames(fileNames));
    dispatch(setMetadata(metadata));
    dispatch(setDefaultFields(unmappedMandatoryDocFields));
    dispatch(
      setSelectedCAICapabilities(
        helpers.getSelectedCAICapabilitiesFromResponse(Object.values(metadataFromResponse)?.[0])
      )
    );
  } catch (e) {
    errorUtils.logError(e as Error, 'Error', { isSilent: true });
  }
});

export const uploadMappingsDocument = reduxToolkit.createAsyncThunk<
  actionsTypes.UploadMappingsDocumentReturn,
  actionsTypes.UploadMappingsDocumentPayload
>(UPLOAD_MAPPINGS_DOCUMENT, async (file, { dispatch, getState }) => {
  try {
    const state = getState() as globalReducersState.GlobalState;

    const fileNames = selectors.getImportDocFileNames(state);
    const docFields = docFieldsSelectors.getDocumentFields(state);
    const fileNameFieldLabel = docFieldsSelectors.getDocFileNameFieldLabel(state);
    const mandatoryDocFields = docFieldsSelectors.getMandatoryDocumentFields(state);

    const response = await api.uploadMappingsDocument(file);

    const metadataFromResponse = helpers.normalizeOrgDocumentsMetadataResponse({
      response,
      fileNameFieldLabel
    });

    if (
      !helpers.areValidFileNamesFromUploadedDataResponse({
        fileNames,
        response,
        fileNameFieldLabel
      })
    ) {
      return uiLib.showNotification(constants.NOTIFICATIONS.MISMATCHED_FILE_NAMES);
    }

    const metadata = await new metadataPipeline.Pipeline<reducerTypes.OrgDocumentsMetadata>({
      initialMetadata: metadataFromResponse,
      stages: [
        new metadataPipeline.OmitEmptyMetadataStage({
          fileNameFieldLabel: fileNameFieldLabel
        }),

        new metadataPipeline.MapToStateMetadataStage({
          docFields: docFields,
          mapBy: 'label'
        })
      ]
    }).run();

    const unmappedMandatoryDocFields = helpers.getUnmappedMandatoryDocFields({
      metadata,
      mandatoryDocFields
    });

    if (helpers.isEmptyMetadata(metadata)) {
      uiLib.showNotification(constants.NOTIFICATIONS.EMPTY_FILE);
    }

    reactRedux.batch(() => {
      dispatch(setMetadata(metadata));
      dispatch(setDefaultFields(unmappedMandatoryDocFields));
      dispatch(setActiveStepKey(constants.UPLOAD_STEPS_KEYS.MAP_DATA));
    });
  } catch (e) {
    errorUtils.logError(e as Error, 'Error', { isSilent: true });
  }
});

export const uploadMetadata = reduxToolkit.createAsyncThunk<
  actionsTypes.UploadMetadataPayloadReturn,
  actionsTypes.UploadMetadataPayload
>(UPLOAD_METADATA, async ({ isDraft, importId }, { getState }) => {
  try {
    const state = getState() as globalReducersState.GlobalState;

    const fileNames = selectors.getImportDocFileNames(state);
    const metadataForUpload = selectors.getMetadataForUpload(state);
    const defaultValuesMap = selectors.getOrgDocumentsDefaultFieldsToUpload(state);
    const capabilitiesForUpload = selectors.getOrgDocumentsCaiCapabilitiesForUpload(state);
    const permissionsForUpload = selectors.getPermissionsForUpload(state);
    const metadataTagsMap = selectors.getMetadataAttributeTagMapsForPipeline(state);
    const ownerId = userSelectors.getUserId(state);

    const metadata = await new metadataPipeline.Pipeline({
      initialMetadata: metadataForUpload,
      stages: [
        new metadataPipeline.TagsStage({
          metadataTagsMap: metadataTagsMap
        }),

        new metadataPipeline.TranslateFieldsStage({
          asyncTranslationsConfig: Object.fromEntries(
            Object.entries(constants.METADATA_CONFIG).map(([metadataKey, config]) => [
              metadataKey,
              config.forUpload.asyncTranslation
            ])
          ),

          translationsMap: Object.fromEntries(
            Object.entries(constants.METADATA_CONFIG).map(([metadataKey, config]) => [
              metadataKey,
              config.forUpload.translation?.selector(state as never)
            ])
          )
        }),

        new metadataPipeline.TransformStage({
          multiFieldsMap: Object.fromEntries(
            Object.entries(constants.METADATA_CONFIG).map(([metadataKey, config]) => [
              metadataKey,
              Boolean(config.forUpload.isMulti)
            ])
          )
        }),

        new metadataPipeline.ValidationStage({
          onValidationFail: args => {
            if (!args.hasFailedAtLeastOnce) {
              uiLib.showNotification(constants.NOTIFICATIONS.OMITTED_DATA);
            }
          },

          validatorsMap: Object.fromEntries(
            Object.entries(constants.METADATA_CONFIG).map(([metadataKey, config]) => [
              metadataKey,
              config.forUpload.validation
            ])
          )
        }),

        new metadataPipeline.DefaultMetadataStage({
          defaultValuesMap: defaultValuesMap,
          defaultValuesFormattersMap: Object.fromEntries(
            Object.entries(constants.METADATA_CONFIG).map(([metadataKey, config]) => [
              metadataKey,
              config.forUpload.defaultValue?.format
            ])
          )
        }),

        new metadataPipeline.ConditionalFieldsStage({
          conditionalFieldsMap: Object.fromEntries(
            Object.entries(constants.METADATA_CONFIG).map(([metadataKey, config]) => [
              metadataKey,
              config.fromUpload.conditionalKey
            ])
          )
        })
      ]
    }).run();

    await api.uploadMetadata({
      document_metadata_mappings: fileNames.map(fileName => ({
        file_name: fileName,
        document_metadata: {
          owner_id: ownerId,
          permissions: permissionsForUpload,
          ...metadata[fileName],
          ...capabilitiesForUpload
        },
        is_draft_saved: isDraft,
        import_id: importId
      }))
    });

    if (!importId) {
      uiLib.showNotification(constants.NOTIFICATIONS.NO_IMPORT_ID);
    } else if (isDraft) {
      uiLib.showNotification(constants.NOTIFICATIONS.METADATA_DRAFT_SAVED);
    } else {
      await orgDocsApi.startOrganizationDocumentsImport(importId);
      uiLib.showNotification(constants.NOTIFICATIONS.METADATA_UPLOAD_SUCCESS);
    }
  } catch (e) {
    errorUtils.logError(e as Error, 'Error', { isSilent: true });
  }
});

export const setMetadataAttributeTagMapAttribute: actionsTypes.SetMetadataAttributeTagMapAttribute = reduxToolkit.createAction(
  SET_METADATA_ATTRIBUTE_TAG_MAP_ATTRIBUTE
);
export const setMetadataAttributeTagMapTags: actionsTypes.SetMetadataAttributeTagMapTags = reduxToolkit.createAction(
  SET_METADATA_ATTRIBUTE_TAG_MAP_TAGS
);
export const setMetadataAttributeTagMapTagValue: actionsTypes.SetMetadataAttributeTagMapTagValue = reduxToolkit.createAction(
  SET_METADATA_ATTRIBUTE_TAG_MAP_TAG_VALUE
);

export const addNewMetadataAttributeTagMap: actionsTypes.AddNewMetadataAttributeTagMap = reduxToolkit.createAction(
  ADD_NEW_METADATA_ATTRIBUTE_TAG_MAP
);

export const removeMetadataAttributeTagMap: actionsTypes.RemoveMetadataAttributeTagMap = reduxToolkit.createAction(
  REMOVE_METADATA_ATTRIBUTE_TAG_MAP
);
