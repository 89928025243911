export const ConjunctionGroup = ({
  id,
  not,
  setNot,
  conjunctionOptions,
  setConjunction,
  disabled,
  readonly,
  config
}) => {
  const onChange = e => setConjunction(e.target.value);

  const renderOptions = () => {
    const results = Object.keys(conjunctionOptions).map(key => {
      const { id: uniqueId, name, label, checked } = conjunctionOptions[key];
      return [
        <input
          key={uniqueId}
          type="radio"
          id={uniqueId}
          name={name}
          checked={checked}
          value={key}
          onChange={onChange}
          disabled={disabled}
        />,
        <label key={uniqueId + 'label'} htmlFor={uniqueId}>
          {label}
        </label>
      ];
    });

    return <div className="toggle">{results}</div>;
  };

  const renderNot = () => {
    // TODO: Due to the complex implementation, migrating a checkbox
    //  in this place requires more time to investigate, so it was
    //  decided to just not render it.

    return <div>ConjGroup not configured</div>;
  };

  return [renderOptions(), config.settings.showNot && renderNot()];
};
