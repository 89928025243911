import type { DiffTabHandlers } from './useDiffTabHandlers.types';

import * as rightPanelConstants from 'shared/features/rightPanel/rightPanel.constants';

import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';

export const useDiffTabHandlers = (): DiffTabHandlers => {
  const rightPanelReduxActions = useRightPanelReduxActions();

  const handleOverlayOpenOnMount: DiffTabHandlers['handleOverlayOpenOnMount'] = () => {
    rightPanelReduxActions.openOverlay(rightPanelConstants.OVERLAY_TYPES.DIFF);
  };

  const handleOverlayCloseOnUnmount: DiffTabHandlers['handleOverlayCloseOnUnmount'] = () => {
    rightPanelReduxActions.closeOverlay();
  };

  return {
    handleOverlayOpenOnMount,
    handleOverlayCloseOnUnmount
  };
};
