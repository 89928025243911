import type { WorkflowsSelectProps } from './WorkflowsSelect.types';
import type { SelectProps } from '@compliance.ai/web-components';

import { Select, SELECT_TYPES } from '@compliance.ai/web-components';
import { useWorkflowsSelectHandlers } from './hooks';

export const WorkflowsSelect = ({
  type,
  onChange,
  selectedWorkflow,
  isClearable,
  isDisabled,
  isLoading,
  shouldShowDeletedWorkflows,
  showAllWorkflowsOption
}: WorkflowsSelectProps) => {
  const handlers = useWorkflowsSelectHandlers({
    props: {
      showAllWorkflowsOption,
      shouldShowDeletedWorkflows
    }
  });

  return (
    <Select
      type={type ?? SELECT_TYPES.DEFAULT}
      value={selectedWorkflow}
      loadOptions={handlers.handleWorkflowsFetch}
      shouldFetchDefaultOptions
      isClearable={isClearable}
      onChange={onChange as SelectProps['onChange']}
      isSearchable
      isAsync
      isDisabled={isDisabled}
      isLoading={isLoading}
      placeholder="Select Workflow"
      noOptionsMessage={inputValue => (inputValue ? 'No workflows' : 'Type to search')}
    />
  );
};
