import type { AddToWorkflowProps } from '../AddToWorkflow.types';
import type { AddToWorkflowData } from './useAddToWorkflowData.types';

import * as constants from '../AddToWorkflow.constants';
import * as queryKeys from 'constants/QueryKeys';
import * as errorUtils from 'utils/errors';

import { useQuery } from '@tanstack/react-query';
import { useDocumentsApi } from 'shared/features/documents/hooks';

export const useAddToWorkflowQueries = ({
  props,
  localActions
}: {
  props: Pick<AddToWorkflowProps, 'documentIds'>;
  localActions: AddToWorkflowData['localActions'];
}) => {
  const documentsApi = useDocumentsApi();

  const fetchDocuments = useQuery({
    enabled: false,
    queryKey: queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.DOCUMENTS][
      queryKeys.QUERY_SECTIONS.ADD_TO_WORKFLOW_ACTION
    ](props.documentIds),
    queryFn: () => {
      return documentsApi.fetchDocuments({
        doc_id: props.documentIds,
        exclusive_fields: constants.EXCLUSIVE_FIELDS,
        limit: props.documentIds.length
      });
    },
    retry: false,
    refetchOnWindowFocus: false,
    onError: errorUtils.logReactQueryError,
    onSuccess: () => localActions.setIsModalOpen(true)
  });

  return {
    fetchDocuments
  };
};
