import { SUPPORTED_QUERY_PARAMS_TO_FILTER_KEYS_MAP } from 'constants/PrimaryFilter';

export const keepOnlySupported = supportedQueryParams => ([queryParamKey]) => {
  return supportedQueryParams.includes(queryParamKey);
};

export const mapToFilterKeys = (state, [queryParamKey, queryParamValue]) => {
  return {
    ...state,
    [SUPPORTED_QUERY_PARAMS_TO_FILTER_KEYS_MAP[queryParamKey]]: queryParamValue
  };
};
