import type { DocumentsFiltersProps } from './DocumentsFilters.types';
import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';

import { useFilterDefaultOptions, useFilterOptions } from 'shared/features/filters/hooks';
import {
  useDocumentsFiltersAsyncLoaders,
  useDocumentsFiltersConfig,
  useDocumentsFiltersData,
  useDocumentsFiltersHandlers,
  useDocumentsFiltersTooltipRenderers
} from './hooks';

import classnames from 'classnames';

import './_document-filters.scss';

export const DocumentsFilters: FC<DocumentsFiltersProps> = ({
  onSubmit,
  dropdownsPortalTarget,
  shouldDisableDropdownsPortal,
  classNames
}) => {
  const { localState, localActions } = useDocumentsFiltersData();

  const filterOptions = useFilterOptions();
  const filterDefaultOptions = useFilterDefaultOptions();

  const filterOptionsAsyncLoaders = useDocumentsFiltersAsyncLoaders();
  const filterTooltipsRenderers = useDocumentsFiltersTooltipRenderers();

  const config = useDocumentsFiltersConfig({
    filterOptions,
    filterOptionsAsyncLoaders,
    filterDefaultOptions,
    filterTooltipsRenderers
  });

  const handlers = useDocumentsFiltersHandlers({
    props: {
      onSubmit
    },
    localState,
    localActions
  });

  return (
    <div className={classnames(classNames?.container, 'document-filters__container')}>
      {localState.isAdvancedSearch ? (
        <elements.AdvancedSearch
          value={localState.advancedSearch}
          onSubmit={handlers.handleAdvancedSearchSubmit}
        />
      ) : (
        <uiLib.Filters
          config={config}
          values={localState.values}
          withSearch
          withSearchOptions
          onSearchOptionsFetch={handlers.handleSuggestionsFetch}
          onSubmit={handlers.handleFiltersSubmit}
          onChange={handlers.handleFiltersChange}
          dateFormat={uiLib.DATE_FORMATS.HUMANIZED_DAY_MONTH_YEAR}
          dropdownsPortalTarget={dropdownsPortalTarget}
          shouldDisableDropdownsPortal={shouldDisableDropdownsPortal}
          className={classnames(classNames?.filters)}
          searchOptionsSelectProps={{
            shouldInlineOptions: true
          }}
        />
      )}
      <elements.TypeSwitch
        onSwitch={handlers.handleFiltersTypeSwitch}
        advancedSearch={localState.advancedSearch}
        isAdvancedSearch={localState.isAdvancedSearch}
      />
    </div>
  );
};
