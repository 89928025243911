import * as apiTypes from './eitlLabels.api.types';

import { fetch } from 'fetch';

export const getEitlLabels: apiTypes.GetEitlLabels = async params => {
  return fetch({
    url: '/eitl_labels',
    method: 'GET',
    dataType: 'EITL Labels',
    params,
    shouldDisableQueryParamsArrayFormatting: true
  });
};

export const getEitlLabelsByIds: apiTypes.GetEitlLabelsByIds = async ids => {
  return getEitlLabels({
    id: ids
  });
};
