import type { AdvancedSearchData } from './useAdvancedSearchData.types';
import type { AdvancedSearchProps } from '../AdvancedSearch.types';

import { useMemo, useState } from 'react';

export const useAdvancedSearchData = (
  props: Pick<AdvancedSearchProps, 'value'>
): AdvancedSearchData => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(props.value);

  const localState: AdvancedSearchData['localState'] = {
    isOpen,
    value
  };

  const localActions: AdvancedSearchData['localActions'] = useMemo(
    () => ({
      setIsOpen,
      setValue
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
