export const SHARED_SAVED_SEARCH_PERMISSIONS = {
  EDIT: 'edit',
  VIEW: 'view'
};

export const SAVED_SEARCH_PERMISSIONS = {
  EDIT: 'edit',
  SHARE: 'share',
  DELETE: 'delete',
  RSS: 'rss',
  RENAME: 'rename'
};
