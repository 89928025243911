import { useState } from 'react';
import { useSelector } from 'react-redux';
import { isMobileView } from 'shared/features/view/view.selectors';
import { getUserAuthenticatedState } from 'shared/features/auth/auth.selectors';

export const useWorkflowPanelData = () => {
  const [showObligationTaskMessage, setShowObligationTaskMessage] = useState(false);

  const reduxState = useSelector(state => ({
    isMobileView: isMobileView(state),
    isAuthenticated: getUserAuthenticatedState(state)
  }));

  return {
    reduxState,
    localState: {
      showObligationTaskMessage
    },
    localActions: {
      setShowObligationTaskMessage
    }
  };
};
