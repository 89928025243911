import lodash from 'lodash';
import { REQUEST_TAGS, RECEIVE_TAGS } from './tags.actions';

export const getInitialState = () => ({
  isFetching: false,
  isReady: false,
  by_id: {}
});

export const tags = (state = getInitialState(), action) => {
  switch (action.type) {
    case REQUEST_TAGS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_TAGS: {
      const { user, system } = action.response;

      const by_id = {
        ...lodash.keyBy(user, 'id'),
        ...lodash.keyBy(system, 'id')
      };
      return {
        isFetching: false,
        isReady: true,
        by_id
      };
    }
    default:
      return state;
  }
};
