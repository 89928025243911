import type { ShareBucketFormData } from './useShareBucketFormData.types';
import type { ShareBucketFormHandlers } from './useShareBucketFormHandlers.types';

import type * as uiLib from '@compliance.ai/web-components';

export const useShareBucketFormHandlers = ({
  localActions
}: {
  localActions: ShareBucketFormData['localActions'];
}): ShareBucketFormHandlers => {
  const handleSharersChange: ShareBucketFormHandlers['handleSharersChange'] = options => {
    localActions.setSharersOptions(options as uiLib.SelectOption[]);
  };

  const handleAccessLevelChange: ShareBucketFormHandlers['handleAccessLevelChange'] = option => {
    localActions.setAccessLevel(option as uiLib.SelectOption);
  };

  const handleNoteChange: ShareBucketFormHandlers['handleNoteChange'] = note => {
    localActions.setNote(note);
  };

  return {
    handleSharersChange,
    handleAccessLevelChange,
    handleNoteChange
  };
};
