import PropTypes from 'prop-types';
import { LabeledField, Select, SELECT_TYPES } from '@compliance.ai/web-components';
import { DEPENDENT_ON_OPTIONS, RELEVANT_OPTIONS } from './DependenciesType.constants';
import './_dependencies-type.scss';

export const DependenciesType = ({
  taskDependentOnAll,
  taskDependencyRelevance,
  setTaskDependencyRelevance,
  setTaskDependentOnAll
}) => {
  return (
    <div className="workflow-dependencies-type__container">
      <LabeledField
        label="Make this task"
        isInline
        labelClassName="workflow-dependencies-type__text"
      >
        <Select
          type={SELECT_TYPES.DEFAULT}
          className="workflow-dependencies-type__dropdown"
          value={RELEVANT_OPTIONS.find(option => option.value === taskDependencyRelevance)}
          options={RELEVANT_OPTIONS}
          onChange={option => setTaskDependencyRelevance(option.value)}
          isClearable={false}
        />
      </LabeledField>
      <LabeledField label="if" isInline labelClassName="workflow-dependencies-type__text">
        <Select
          type={SELECT_TYPES.DEFAULT}
          className="workflow-dependencies-type__dropdown"
          value={DEPENDENT_ON_OPTIONS.find(option => option.value === taskDependentOnAll)}
          options={DEPENDENT_ON_OPTIONS}
          onChange={option => setTaskDependentOnAll(option.value)}
          isClearable={false}
        />
      </LabeledField>
      <LabeledField
        label="of the following match:"
        isInline
        labelClassName="workflow-dependencies-type__text"
      />
    </div>
  );
};

DependenciesType.propTypes = {
  taskDependentOnAll: PropTypes.bool.isRequired,
  taskDependencyRelevance: PropTypes.bool.isRequired,
  setTaskDependencyRelevance: PropTypes.func.isRequired,
  setTaskDependentOnAll: PropTypes.func.isRequired
};
