import * as actsApi from './acts.api';

export const REQUEST_ACTS = 'REQUEST_ACTS';
export const RECEIVE_ACTS = 'RECEIVE_ACTS';
export const CLEAR_ACTS = 'CLEAR_ACTS';

function requestActs(following) {
  return {
    type: REQUEST_ACTS,
    following
  };
}

function receiveActs(json) {
  return {
    type: RECEIVE_ACTS,
    acts: json.acts
  };
}

export function fetchActs(ids) {
  return function doFetch(dispatch) {
    dispatch(requestActs());
    return actsApi.fetchActs(ids).then(response => {
      dispatch(receiveActs(response));
      return response;
    });
  };
}

export const clearActs = () => dispatch => {
  dispatch({ type: CLEAR_ACTS, payload: [] });
};
