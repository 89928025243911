import type { DocumentFromResponse } from 'shared/features/documents/documents.types';

import * as restrictionsConstants from 'constants/Restrictions';
import * as documentAccessUtils from 'utils/documents/documentsAccess';

export const filterDocumentsByRestrictedAction = ({
  documents,
  action
}: {
  documents: DocumentFromResponse[];
  action: restrictionsConstants.RESTRICTED_ACTIONS;
}): DocumentFromResponse[] => {
  return documents.filter(document => {
    const userHasAccess = documentAccessUtils.userCanAccessDocument({ document });
    const restriction = documentAccessUtils.getDocumentRestriction({ document, action });

    return userHasAccess && restriction;
  });
};
