import type { WorkflowSummaryHandlers } from './useWorkflowSummaryHandlers.types';

import { useEffect } from 'react';

export const useWorkflowSummaryLifecycle = ({
  onPrint,
  onPrintableContentReady
}: {
  onPrint: WorkflowSummaryHandlers['handlePrint'];
  onPrintableContentReady: WorkflowSummaryHandlers['handlePrintableContentReady'];
}) => {
  useEffect(onPrint, [onPrint]);
  useEffect(onPrintableContentReady, [onPrintableContentReady]);
};
