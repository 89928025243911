import * as obligationsSelectors from 'shared/features/obligations/obligations.selectors';
import * as rightPanelSelectors from 'shared/features/rightPanel/rightPanel.selectors';
import * as constants from './useObligationWorkspaceData.constants';
import * as helpers from '../ObligationWorkspace.helpers';

import { DOCUMENT_DETAILS_TAB_KEY } from 'shared/features/rightPanel/rightPanel.constants';

import { useState, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { usePermissions } from 'utils/hooks';

export const useObligationWorkspaceData = () => {
  const location = useLocation();
  const permissions = usePermissions();

  const sentenceContainerRef = useRef(null);

  const refs = {
    sentenceContainer: sentenceContainerRef
  };

  const [sentenceHtml, setSentenceHtml] = useState('');
  const [textSelection, setTextSelection] = useState(constants.INITIAL_TEXT_SELECTION_STATE);
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [accessibleBucketsCount, setAccessibleBucketsCount] = useState(0);
  const [isCreateSentenceNoteModalOpen, setCreateSentenceNoteModalOpenState] = useState(false);
  const [isAddingSentenceNote, setSentenceNoteAddingState] = useState(false);
  const [firstSentenceId, setFirstSentenceId] = useState(null);
  /**
   * This flag determines if sentences where selected by manual selection. It's required to hide
   * action button when user clicks on annotation in the right panel.
   */
  const [isUserSelected, setIsUserSelected] = useState(false);

  const localState = {
    sentenceHtml,
    textSelection,
    accessibleBucketsCount,
    isTooltipOpen,
    isCreateSentenceNoteModalOpen,
    isAddingSentenceNote,
    isUserSelected,
    firstSentenceId
  };

  const localActions = useMemo(
    () => ({
      setSentenceHtml,
      setTextSelection,
      setTooltipOpen,
      setAccessibleBucketsCount,
      setCreateSentenceNoteModalOpenState,
      setSentenceNoteAddingState,
      setIsUserSelected,
      setFirstSentenceId
    }),
    []
  );

  const reduxState = useSelector(state => ({
    obligations: obligationsSelectors.getObligationsReducer(state),
    documentDetailsActiveTabKey: rightPanelSelectors.getDocumentDetailsActiveTabKey(state),
    documentId: rightPanelSelectors.getRightPanelDocumentId(state)
  }));

  const formattedData = useMemo(() => {
    const isTextSelectionEnabled = helpers.isTextSelectionAllowed({
      documentDetailsActiveTabKey: reduxState.documentDetailsActiveTabKey,
      permissions: permissions
    });

    const shouldShowOverlay =
      isTextSelectionEnabled &&
      localState.isUserSelected &&
      Boolean(localState.textSelection.firstSentenceId);

    const selectedSentence = document.querySelector(
      `#sentence-${localState.textSelection?.firstSentenceId}`
    );

    const isObligation =
      reduxState.documentDetailsActiveTabKey === DOCUMENT_DETAILS_TAB_KEY.OBLIGATIONS;

    return {
      shouldShowOverlay,
      selectedSentence,
      isObligation
    };
  }, [
    reduxState.documentDetailsActiveTabKey,
    permissions,
    localState.isUserSelected,
    localState.textSelection.firstSentenceId
  ]);

  return {
    refs,
    location,
    formattedData,
    localState,
    localActions,
    reduxState
  };
};
