import type * as apiTypes from './downloads.api.types';

import { fetch } from 'utils/api';

export const fetchDownloads: apiTypes.FetchDownloads = params => {
  return fetch({
    url: `/downloads`,
    dataType: 'Downloads',
    method: 'GET',
    params
  });
};

export const deleteDownloads: apiTypes.DeleteDownloads = params => {
  return fetch({
    url: '/downloads',
    dataType: 'Downloads',
    method: 'DELETE',
    params
  });
};

export const postDownloads: apiTypes.PostDownloads = params => {
  return fetch({
    url: '/downloads',
    dataType: 'Downloads',
    method: 'POST',
    params
  });
};
