import { useMemo } from 'react';
import lodash from 'lodash';
import { isBookmarkedFolder } from 'components/Folders/Folders.helpers';

export const useAddToFolderModalBodyData = ({ props }) => {
  const formattedData = useMemo(() => {
    const { folderPaths, savedFolders, subFoldersCount, docsCount } = props;

    const hasSavedFolders = !lodash.isEmpty(savedFolders);
    const savedFoldersTitle = hasSavedFolders
      ? `Saved in ${savedFolders.length} ${savedFolders.length > 1 ? 'folders' : 'folder'}`
      : '';
    const foldersTitle = lodash.isEmpty(folderPaths)
      ? 'Add to folder'
      : `${subFoldersCount} ${subFoldersCount > 1 ? 'folders' : 'folder'}` +
        ` and ${docsCount} ${docsCount > 1 ? 'documents' : 'document'}`;

    const isSavedInBookmarkFolder = savedFolders.some(folder => isBookmarkedFolder(folder));

    return {
      hasSavedFolders,
      savedFoldersTitle,
      foldersTitle,
      isSavedInBookmarkFolder
    };
  }, [props]);

  return {
    formattedData
  };
};
