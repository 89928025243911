import { useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Chiclets } from '@compliance.ai/web-components';
import { explicit_filter_function } from 'utils/filter/filter';
import './_topics_list.scss';

export const TEST_ID = {
  CONTAINER: 'topics-list__container'
};

export const TEXTS = {
  TITLE: 'Topics & Concepts'
};

export const TopicsList = ({ topics = [] }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const onTopicOptionClick = useCallback(
    ({ value }) => {
      explicit_filter_function({}, location, navigate, { topic_id: value, overlay: null }, {});
    },
    [location, navigate]
  );

  const topicOptions = useMemo(() => {
    return topics.map(topic => ({
      value: topic.id,
      label: topic.name
    }));
  }, [topics]);

  return (
    <div className="topics-list__container">
      <Chiclets
        title={TEXTS.TITLE}
        options={topicOptions}
        onOptionClick={onTopicOptionClick}
        dataTestId={TEST_ID.CONTAINER}
      />
    </div>
  );
};
