import type {
  AddRelatedDocumentsModalData,
  AddRelatedDocumentsModalLocalState
} from './useAddRelatedDocumentsModalData.types';
import type { GlobalState } from 'shared/reducers';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDocumentDetailsFetchingState } from 'shared/features/documents/documents.selectors';

export const useAddRelatedDocumentsModalData = (): AddRelatedDocumentsModalData => {
  const [relatedDocIds, setRelatedDocIds] = useState<
    AddRelatedDocumentsModalLocalState['relatedDocIds']
  >([]);
  const [requestParams, setRequestParams] = useState<
    AddRelatedDocumentsModalLocalState['requestParams']
  >({});

  const localState: AddRelatedDocumentsModalData['localState'] = {
    relatedDocIds,
    requestParams
  };

  const localActions: AddRelatedDocumentsModalData['localActions'] = useMemo(
    () => ({
      setRelatedDocIds,
      setRequestParams
    }),
    []
  );

  const reduxState = useSelector<GlobalState, AddRelatedDocumentsModalData['reduxState']>(
    state => ({
      isFetching: getDocumentDetailsFetchingState(state)
    })
  );

  return {
    localState,
    localActions,
    reduxState
  };
};
