export enum TABLE_KEYS {
  ID = 'id',
  NAME = 'name',
  RULE = 'rule',
  IS_GROUP_HEADER = 'is_group_header',
  IS_OWNER = 'is_owner'
}

export enum ACCESS_TYPES {
  OWNER = 'Owner',
  TEAMS = 'Teams',
  MEMBERS = 'Members'
}
