import PropTypes from 'prop-types';
import { PremiumContentUnlockButton } from 'common/index';
import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';
import { useDocumentLinkData, useDocumentLinkHandlers } from './hooks';

export const DocumentLink = ({ document }) => {
  const { reduxState, formattedData } = useDocumentLinkData({ document });

  const rightPanelReduxActions = useRightPanelReduxActions();

  const handlers = useDocumentLinkHandlers({
    props: {
      document
    },
    reduxState,
    formattedData,
    rightPanelReduxActions
  });

  if (formattedData.isPremiumContent && !formattedData.canAccessPCDocument) {
    return (
      <PremiumContentUnlockButton
        sourceId={formattedData.premiumContentSourceId}
        sourceName={formattedData.premiumContentSourceName}
        sourceURL={formattedData.premiumContentSourceURL}
        sourceAuthType={formattedData.premiumContentSourceAuthType}
        document={document}
        onSuccess={handlers.handleDocumentOpen}
      />
    );
  }

  if (formattedData.canShowSourceLink && !formattedData.canShowPDFButton) {
    return (
      <Button type={BUTTON_TYPES.PRIMARY} onClick={handlers.handleSourceOpen}>
        {formattedData.isPremiumContent
          ? 'View or License on Source Website'
          : 'View on Source Website'}
      </Button>
    );
  }

  return (
    <Button type={BUTTON_TYPES.PRIMARY} onClick={handlers.handleDocumentOpen}>
      See Document
    </Button>
  );
};

DocumentLink.propTypes = {
  document: PropTypes.object.isRequired
};
