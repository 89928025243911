import type { AlertFromResponse } from './alerts.api.types';

import { alphabeticallyByLabel } from 'utils/sort';

export const formatAlertsOptions = (alerts: AlertFromResponse[]) => {
  return alerts
    .map(alert => ({
      value: alert.id,
      label: alert.name
    }))
    .sort(alphabeticallyByLabel);
};
