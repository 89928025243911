import type { DocumentEditMetadataProps } from './DocumentEditMetadata.types';
import type { DatePickerProps, TextFieldProps, TextAreaProps } from '@compliance.ai/web-components';
import type { FC } from 'react';

import classnames from 'classnames';
import * as uiLib from '@compliance.ai/web-components';
import {
  AuthorsSelect,
  AuthorsSelectProps,
  CategoriesSelect,
  CategoriesSelectProps,
  JurisdictionsSelect,
  JurisdictionsSelectProps,
  TopicsSelect,
  TopicsSelectProps,
  AUTHORS_SELECT_VALUE_KEYS,
  JURISDICTIONS_SELECT_VALUE_KEYS
} from 'common/index';
import * as documentMetadataConstants from 'constants/DocumentMetadata';
import { useDocumentsApi } from 'shared/features/documents/hooks';
import {
  useDocumentEditMetadataData,
  useDocumentEditMetadataHandler,
  useDocumentEditMetadataLifecycle,
  useDocumentEditMetadataQueries
} from './hooks';
import './_document-edit-metadata.scss';

export const DocumentEditMetadata: FC<DocumentEditMetadataProps> = ({ document, onClose }) => {
  const documentsApi = useDocumentsApi();

  const queries = useDocumentEditMetadataQueries();

  const { localState, localActions, reduxState, formattedData } = useDocumentEditMetadataData({
    document,
    queries
  });

  const { handleChangeMetadata, handleSaveMetadata } = useDocumentEditMetadataHandler({
    props: {
      document,
      onClose
    },
    localState,
    localActions,
    reduxState,
    formattedData,
    documentsApi
  });

  useDocumentEditMetadataLifecycle({
    props: {
      document,
      onClose
    }
  });

  return (
    <div className="document-edit-metadata__container">
      <div className="document-edit-metadata__header">
        <div className="document-edit-metadata__header-content">
          {`${
            formattedData.metadataFields[documentMetadataConstants.DOCUMENT_METADATA_FIELDS.ID]
              .label
          } ${localState.metadataValues[documentMetadataConstants.DOCUMENT_METADATA_FIELDS.ID]}`}
        </div>
        <div className="document-edit-metadata__header-action-buttons">
          <uiLib.Button type={uiLib.BUTTON_TYPES.LINK_CONTRAST} onClick={onClose}>
            Cancel
          </uiLib.Button>
          <uiLib.Button
            type={uiLib.BUTTON_TYPES.PRIMARY}
            onClick={handleSaveMetadata}
            isDisabled={localState.isUpdating}
          >
            Save
          </uiLib.Button>
        </div>
      </div>

      <div className="document-edit-metadata__body">
        {formattedData.metadataFields[documentMetadataConstants.DOCUMENT_METADATA_FIELDS.TITLE]
          .available && (
          <div className="document-edit-metadata__body-item">
            <uiLib.LabeledField
              label={
                formattedData.metadataFields[
                  documentMetadataConstants.DOCUMENT_METADATA_FIELDS.TITLE
                ].label
              }
              isContrast
              className="document-edit-metadata__body-item-field"
            >
              <uiLib.TextField
                value={
                  localState.metadataValues[
                    documentMetadataConstants.DOCUMENT_METADATA_FIELDS.TITLE
                  ] as TextFieldProps['value']
                }
                onChange={handleChangeMetadata(
                  documentMetadataConstants.DOCUMENT_METADATA_FIELDS.TITLE
                )}
                isDisabled={
                  !formattedData.metadataFields[
                    documentMetadataConstants.DOCUMENT_METADATA_FIELDS.TITLE
                  ].editable
                }
              />
            </uiLib.LabeledField>
          </div>
        )}

        {formattedData.metadataFields[documentMetadataConstants.DOCUMENT_METADATA_FIELDS.SUMMARY]
          .available && (
          <div className="document-edit-metadata__body-item">
            <uiLib.LabeledField
              label={
                formattedData.metadataFields[
                  documentMetadataConstants.DOCUMENT_METADATA_FIELDS.SUMMARY
                ].label
              }
              isContrast
              className="document-edit-metadata__body-item-field"
            >
              <uiLib.TextArea
                value={
                  localState.metadataValues[
                    documentMetadataConstants.DOCUMENT_METADATA_FIELDS.SUMMARY
                  ] as TextAreaProps['value']
                }
                onChange={handleChangeMetadata(
                  documentMetadataConstants.DOCUMENT_METADATA_FIELDS.SUMMARY
                )}
                isDisabled={
                  !formattedData.metadataFields[
                    documentMetadataConstants.DOCUMENT_METADATA_FIELDS.SUMMARY
                  ].editable
                }
                minRows={4}
                maxRows={8}
              />
            </uiLib.LabeledField>
          </div>
        )}

        <div className="document-edit-metadata__body-item">
          {formattedData.metadataFields[
            documentMetadataConstants.DOCUMENT_METADATA_FIELDS.CREATED_AT
          ].available && (
            <uiLib.LabeledField
              label={
                formattedData.metadataFields[
                  documentMetadataConstants.DOCUMENT_METADATA_FIELDS.CREATED_AT
                ].label
              }
              className={classnames(
                'document-edit-metadata__body-item-field',
                'is--small-label-field'
              )}
            >
              <uiLib.DatePicker
                value={
                  localState.metadataValues[
                    documentMetadataConstants.DOCUMENT_METADATA_FIELDS.CREATED_AT
                  ] as DatePickerProps['value']
                }
                isDisabled={
                  !formattedData.metadataFields[
                    documentMetadataConstants.DOCUMENT_METADATA_FIELDS.CREATED_AT
                  ].editable
                }
              />
            </uiLib.LabeledField>
          )}

          {formattedData.metadataFields[
            documentMetadataConstants.DOCUMENT_METADATA_FIELDS.UPDATED_AT
          ].available && (
            <uiLib.LabeledField
              label={
                formattedData.metadataFields[
                  documentMetadataConstants.DOCUMENT_METADATA_FIELDS.UPDATED_AT
                ].label
              }
              className={classnames(
                'document-edit-metadata__body-item-field',
                'is--small-label-field'
              )}
            >
              <uiLib.DatePicker
                value={
                  localState.metadataValues[
                    documentMetadataConstants.DOCUMENT_METADATA_FIELDS.UPDATED_AT
                  ] as DatePickerProps['value']
                }
                isDisabled={
                  !formattedData.metadataFields[
                    documentMetadataConstants.DOCUMENT_METADATA_FIELDS.UPDATED_AT
                  ].editable
                }
              />
            </uiLib.LabeledField>
          )}
        </div>

        <div className="document-edit-metadata__body-item">
          {formattedData.metadataFields[
            documentMetadataConstants.DOCUMENT_METADATA_FIELDS.COMMENTS_CLOSE_ON
          ].available && (
            <uiLib.LabeledField
              label={
                formattedData.metadataFields[
                  documentMetadataConstants.DOCUMENT_METADATA_FIELDS.COMMENTS_CLOSE_ON
                ].label
              }
              className={classnames(
                'document-edit-metadata__body-item-field',
                'is--small-label-field'
              )}
            >
              <uiLib.DatePicker
                value={
                  localState.metadataValues[
                    documentMetadataConstants.DOCUMENT_METADATA_FIELDS.COMMENTS_CLOSE_ON
                  ] as DatePickerProps['value']
                }
                isDisabled={
                  !formattedData.metadataFields[
                    documentMetadataConstants.DOCUMENT_METADATA_FIELDS.COMMENTS_CLOSE_ON
                  ].editable
                }
              />
            </uiLib.LabeledField>
          )}

          {formattedData.metadataFields[
            documentMetadataConstants.DOCUMENT_METADATA_FIELDS.EFFECTIVE_ON
          ].available && (
            <uiLib.LabeledField
              label={
                formattedData.metadataFields[
                  documentMetadataConstants.DOCUMENT_METADATA_FIELDS.EFFECTIVE_ON
                ].label
              }
              className={classnames(
                'document-edit-metadata__body-item-field',
                'is--small-label-field'
              )}
            >
              <uiLib.DatePicker
                value={
                  localState.metadataValues[
                    documentMetadataConstants.DOCUMENT_METADATA_FIELDS.EFFECTIVE_ON
                  ] as DatePickerProps['value']
                }
                isDisabled={
                  !formattedData.metadataFields[
                    documentMetadataConstants.DOCUMENT_METADATA_FIELDS.EFFECTIVE_ON
                  ].editable
                }
              />
            </uiLib.LabeledField>
          )}
        </div>

        <div className="document-edit-metadata__body-item">
          {formattedData.metadataFields[
            documentMetadataConstants.DOCUMENT_METADATA_FIELDS.PUBLICATION_DATE
          ].available && (
            <uiLib.LabeledField
              label={
                formattedData.metadataFields[
                  documentMetadataConstants.DOCUMENT_METADATA_FIELDS.PUBLICATION_DATE
                ].label
              }
              className={classnames(
                'document-edit-metadata__body-item-field',
                'is--small-label-field'
              )}
            >
              <uiLib.DatePicker
                value={
                  localState.metadataValues[
                    documentMetadataConstants.DOCUMENT_METADATA_FIELDS.PUBLICATION_DATE
                  ] as DatePickerProps['value']
                }
                onChange={handleChangeMetadata(
                  documentMetadataConstants.DOCUMENT_METADATA_FIELDS.PUBLICATION_DATE
                )}
                isDisabled={
                  !formattedData.metadataFields[
                    documentMetadataConstants.DOCUMENT_METADATA_FIELDS.PUBLICATION_DATE
                  ].editable
                }
              />
            </uiLib.LabeledField>
          )}

          {formattedData.metadataFields[
            documentMetadataConstants.DOCUMENT_METADATA_FIELDS.JURISDICTION
          ].available && (
            <uiLib.LabeledField
              label={
                formattedData.metadataFields[
                  documentMetadataConstants.DOCUMENT_METADATA_FIELDS.JURISDICTION
                ].label
              }
              className={classnames(
                'document-edit-metadata__body-item-field',
                'is--small-label-field'
              )}
            >
              <JurisdictionsSelect
                value={
                  localState.metadataValues[
                    documentMetadataConstants.DOCUMENT_METADATA_FIELDS.JURISDICTION
                  ] as JurisdictionsSelectProps['value']
                }
                valueKey={JURISDICTIONS_SELECT_VALUE_KEYS.SHORT_NAME}
                onChange={handleChangeMetadata(
                  documentMetadataConstants.DOCUMENT_METADATA_FIELDS.JURISDICTION
                )}
                isDisabled={
                  !formattedData.metadataFields[
                    documentMetadataConstants.DOCUMENT_METADATA_FIELDS.JURISDICTION
                  ].editable
                }
              />
            </uiLib.LabeledField>
          )}
        </div>
      </div>

      <div className="document-edit-metadata__body-item">
        {formattedData.metadataFields[documentMetadataConstants.DOCUMENT_METADATA_FIELDS.DOC_TYPES]
          .available && (
          <uiLib.LabeledField
            label={
              formattedData.metadataFields[
                documentMetadataConstants.DOCUMENT_METADATA_FIELDS.DOC_TYPES
              ].label
            }
            className={classnames(
              'document-edit-metadata__body-item-field',
              'is--small-label-field'
            )}
          >
            <CategoriesSelect
              value={
                (localState.metadataValues[
                  documentMetadataConstants.DOCUMENT_METADATA_FIELDS.DOC_TYPES
                ]
                  ? [
                      localState.metadataValues[
                        documentMetadataConstants.DOCUMENT_METADATA_FIELDS.DOC_TYPES
                      ]
                    ]
                  : []) as CategoriesSelectProps['value']
              }
              onChange={handleChangeMetadata(
                documentMetadataConstants.DOCUMENT_METADATA_FIELDS.DOC_TYPES
              )}
              isDisabled={
                !formattedData.metadataFields[
                  documentMetadataConstants.DOCUMENT_METADATA_FIELDS.DOC_TYPES
                ].editable
              }
              isMulti={false}
            />
          </uiLib.LabeledField>
        )}

        {formattedData.metadataFields[documentMetadataConstants.DOCUMENT_METADATA_FIELDS.CITATION]
          .available && (
          <uiLib.LabeledField
            label={
              formattedData.metadataFields[
                documentMetadataConstants.DOCUMENT_METADATA_FIELDS.CITATION
              ].label
            }
            className={classnames(
              'document-edit-metadata__body-item-field',
              'is--small-label-field'
            )}
          >
            <uiLib.TextField
              value={
                localState.metadataValues[
                  documentMetadataConstants.DOCUMENT_METADATA_FIELDS.CITATION
                ] as TextFieldProps['value']
              }
              onChange={handleChangeMetadata(
                documentMetadataConstants.DOCUMENT_METADATA_FIELDS.CITATION
              )}
              isDisabled={
                !formattedData.metadataFields[
                  documentMetadataConstants.DOCUMENT_METADATA_FIELDS.CITATION
                ].editable
              }
            />
          </uiLib.LabeledField>
        )}
      </div>

      {formattedData.metadataFields[documentMetadataConstants.DOCUMENT_METADATA_FIELDS.AUTHOR]
        .available && (
        <div className="document-edit-metadata__body-item">
          <uiLib.LabeledField
            label={
              formattedData.metadataFields[
                documentMetadataConstants.DOCUMENT_METADATA_FIELDS.AUTHOR
              ].label
            }
            isContrast
            className="document-edit-metadata__body-item-field"
          >
            <AuthorsSelect
              isMulti={false}
              value={
                localState.metadataValues[
                  documentMetadataConstants.DOCUMENT_METADATA_FIELDS.AUTHOR
                ] as AuthorsSelectProps['value']
              }
              valueKey={AUTHORS_SELECT_VALUE_KEYS.NAME}
              onChange={handleChangeMetadata(
                documentMetadataConstants.DOCUMENT_METADATA_FIELDS.AUTHOR
              )}
              isDisabled={
                !formattedData.metadataFields[
                  documentMetadataConstants.DOCUMENT_METADATA_FIELDS.AUTHOR
                ].editable
              }
            />
          </uiLib.LabeledField>
        </div>
      )}

      {formattedData.metadataFields[documentMetadataConstants.DOCUMENT_METADATA_FIELDS.TOPICS]
        .available && (
        <div className="document-edit-metadata__body-item">
          <uiLib.LabeledField
            label={
              formattedData.metadataFields[
                documentMetadataConstants.DOCUMENT_METADATA_FIELDS.TOPICS
              ].label
            }
            isContrast
            className="document-edit-metadata__body-item-field"
          >
            <TopicsSelect
              value={
                localState.metadataValues[
                  documentMetadataConstants.DOCUMENT_METADATA_FIELDS.TOPICS
                ] as TopicsSelectProps['value']
              }
              onChange={handleChangeMetadata(
                documentMetadataConstants.DOCUMENT_METADATA_FIELDS.TOPICS
              )}
              isDisabled={
                !formattedData.metadataFields[
                  documentMetadataConstants.DOCUMENT_METADATA_FIELDS.TOPICS
                ].editable
              }
              isMulti
            />
          </uiLib.LabeledField>
        </div>
      )}
    </div>
  );
};
