import type { WorkflowAssigneeSelectProps } from './WorkflowAssigneeSelect.types';
import type { SelectOption } from '@compliance.ai/web-components';

import { Select, SELECT_TYPES } from '@compliance.ai/web-components';
import classNames from 'classnames';
import { EDITABLE_FIELDS_KEYS } from 'constants/TaskConstants';
import './_workflow-assignee-select.scss';

export const WorkflowAssigneeSelect = ({
  options,
  value,
  onChange,
  className,
  onClose
}: WorkflowAssigneeSelectProps) => {
  return (
    <Select
      type={SELECT_TYPES.DEFAULT}
      options={options}
      className={classNames('workflow-assignee-select', className)}
      value={value}
      isClearable={false}
      onClickOutside={onClose}
      onChange={obj => {
        if (obj && typeof obj === 'object') {
          return onChange?.(EDITABLE_FIELDS_KEYS.ASSIGNEE, (obj as SelectOption).value);
        }
        return null;
      }}
      onClose={onClose}
    />
  );
};
