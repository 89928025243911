import type { AppNavigationHandlers } from './useAppNavigationHandlers.types';

import { useEffect } from 'react';
import { useDidMount, useUpdateEffect, useWillUnmount } from '@compliance.ai/web-components';

export const useAppNavigationLifecycle = ({
  onLocationChange,
  onRightPanelOpenOnMount,
  onRightPanelToggleAfterReduxChange,
  onRightPanelQueryParamsChange,
  onRightPanelCloseOnRouteChange,
  onRightPanelResetOnUnmount
}: {
  onLocationChange: AppNavigationHandlers['handleLocationChange'];
  onRightPanelOpenOnMount: AppNavigationHandlers['handleRightPanelOpenOnMount'];
  onRightPanelToggleAfterReduxChange: AppNavigationHandlers['handleRightPanelToggleAfterReduxChange'];
  onRightPanelQueryParamsChange: AppNavigationHandlers['handleRightPanelQueryParamsChange'];
  onRightPanelCloseOnRouteChange: AppNavigationHandlers['handleRightPanelCloseOnRouteChange'];
  onRightPanelResetOnUnmount: AppNavigationHandlers['handleRightPanelResetOnUnmount'];
}) => {
  useDidMount(onRightPanelOpenOnMount);

  useEffect(onLocationChange, [onLocationChange]);
  useEffect(onRightPanelToggleAfterReduxChange, [onRightPanelToggleAfterReduxChange]);

  useUpdateEffect(onRightPanelQueryParamsChange, [onRightPanelQueryParamsChange]);
  useUpdateEffect(onRightPanelCloseOnRouteChange, [onRightPanelCloseOnRouteChange]);

  useWillUnmount(onRightPanelResetOnUnmount);
};
