import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import classnames from 'classnames';

import * as constants from './MetaSourceButton.constants';

import { default as DefaultModal } from 'common/Modal';
import { useMetaSourceButtonData } from './hooks';

import './_meta_source_button.scss';

export const MetaSourceButton = ({
  children,
  childContainer,
  onClick,
  followingAll,
  followingSome,
  isDisabled = false
}) => {
  const { formattedData, localActions, localState } = useMetaSourceButtonData({
    followingAll,
    followingSome
  });

  return (
    <div className="meta-source-button" data-testid={constants.TEST_ID_MAIN_CONTAINER}>
      <button
        type="button"
        className={formattedData.buttonMainClassName}
        onClick={onClick}
        data-testid={constants.MAIN_BUTTON_TEST_ID}
        disabled={isDisabled}
      >
        {children}
      </button>
      <button
        type="button"
        className={classnames(formattedData.buttonOpenIndicatorClassName, { disabled: isDisabled })}
        onClick={() => localActions.setOpen(true)}
        data-testid={constants.TEST_ID_OPEN_BUTTON}
      >
        <AddIcon />
      </button>
      <DefaultModal
        dataTestId="source-buttons-modal"
        open={localState.open}
        onClose={() => localActions.setOpen(false)}
      >
        {childContainer}
      </DefaultModal>
    </div>
  );
};

MetaSourceButton.propTypes = {
  children: PropTypes.element.isRequired,
  childContainer: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
  followingAll: PropTypes.bool.isRequired,
  followingSome: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool
};
