import * as sortUtils from 'utils/sort';
import * as advancedSearchConstants from 'constants/AdvancedSearch';

export const sortFieldsAlphabetically = (
  fields: typeof advancedSearchConstants.FIELDS
): typeof advancedSearchConstants.FIELDS => {
  return Object.fromEntries(
    Object.entries(fields).sort(([fieldKeyA, fieldA], [fieldKeyB, fieldB]) => {
      return sortUtils.alphabetically(
        {
          name: fieldA.label
        },
        {
          name: fieldB.label
        }
      );
    })
  );
};
