import { fetch } from 'utils/api';

export const api_fetchDocTasksByStatus = ({ assignee_id, workflow_id, queries }) =>
  fetch({
    url: '/doc_tasks_by_status',
    method: 'POST',
    dataType: 'DocTasks',
    params: { assignee_id, workflow_id, queries }
  });

export const api_fetchDocTasksByDate = ({
  from_date,
  to_date,
  assignee_id,
  obligation_super_tasks_only,
  workflow_id,
  hide_blocked_tasks
}) => {
  const params = {
    from_date,
    to_date,
    obligation_super_tasks_only,
    workflow_id,
    hide_blocked_tasks
  };
  if (assignee_id) {
    params.assignee_id = assignee_id;
  }
  return fetch({
    url: '/doc_tasks_by_date',
    dataType: 'DocTasks',
    method: 'GET',
    params
  });
};

export const api_fetchAllWorkflows = () =>
  fetch({
    url: '/all_workflows',
    dataType: 'Workflows',
    method: 'GET',
    params: {}
  });

export const api_fetchGroupedDocTasksCountByCreationDate = ({
  from_date,
  to_date,
  assignee_id,
  obligation_super_tasks_only,
  workflow_id,
  hide_blocked_tasks,
  status
}) => {
  const params = {
    from_date,
    to_date,
    obligation_super_tasks_only,
    workflow_id,
    hide_blocked_tasks,
    status,
    assignee_id
  };

  return fetch({
    url: '/get_grouped_doc_tasks_count_by_creation_date',
    method: 'GET',
    params
  });
};
