import PropTypes from 'prop-types';
import { Alert, ALERT_TYPES, TextField } from '@compliance.ai/web-components';
import FilterLabeledElement from '../FilterLabeledElement';
import './_alert-name-textfield.scss';

export const TEST_IDS = {
  ALERT: 'AlertNameTextfield__Alert',
  TEXTFIELD: 'AlertNameTextfield__Textfield'
};

import { usePermissions } from 'utils/hooks';
import { EDIT_ALERTS } from 'constants/Permissions';

const AlertNameTextfield = ({ value, isDisabled, onChange }) => {
  const permissions = usePermissions();

  if (!permissions[EDIT_ALERTS]) {
    return null;
  }

  return (
    <>
      <Alert
        dataTestId={TEST_IDS.ALERT}
        type={ALERT_TYPES.INFO}
        content="Editing will update the saved search, workflow alert, and any related widget"
        className="alert-name-textfield__notification"
      />
      <div className="alert-name-textfield__input-wrapper">
        <FilterLabeledElement label="Edit alert name:" labelClassName="alert-name-textfield__label">
          <TextField
            dataTestId={TEST_IDS.TEXTFIELD}
            isDisabled={isDisabled}
            value={value}
            onChange={onChange}
          />
        </FilterLabeledElement>
      </div>
    </>
  );
};

export default AlertNameTextfield;

AlertNameTextfield.propTypes = {
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};
