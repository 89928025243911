import {
  FETCH_DEFAULT_STATE_AGENCIES_BEGIN,
  FETCH_DEFAULT_STATE_AGENCIES_SUCCESS,
  FETCH_DEFAULT_STATE_AGENCIES_ERROR
} from './defaultStateAgencies.actions';

export const getInitialState = () => ({
  defaultStateAgencies: [],
  isReady: false
});

export const defaultStateAgencies = (state = getInitialState(), { type, data }) => {
  switch (type) {
    case FETCH_DEFAULT_STATE_AGENCIES_BEGIN:
      return {
        ...state,
        isReady: false
      };

    case FETCH_DEFAULT_STATE_AGENCIES_SUCCESS:
      return {
        ...state,
        defaultStateAgencies: data,
        isReady: true
      };

    case FETCH_DEFAULT_STATE_AGENCIES_ERROR:
      return state;

    default:
      return state;
  }
};
