import type { BucketAttributeLinkCellData } from './useBucketAttributeLinkCellData.types';
import type { BucketAttributeLinkCellProps } from '../BucketAttributeLinkCell.types';

import * as helpers from 'components/ManageAnnotations/ManageAnnotations.helpers';

import { useMemo } from 'react';

export const useBucketAttributeLinkCellData = ({
  props
}: {
  props: Pick<BucketAttributeLinkCellProps, 'cellValue'>;
}): BucketAttributeLinkCellData => {
  const formattedData: BucketAttributeLinkCellData['formattedData'] = useMemo(
    () => ({
      bucketAttributePageUrl: helpers.getBucketAttributePageUrl({
        bucketId: props.cellValue.bucket.id,
        attribute: props.cellValue.attribute
      })
    }),
    [props.cellValue]
  );

  return {
    formattedData
  };
};
