import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Loader } from '../Loader';
import './_loading-overlay.scss';

const LoadingOverlay = ({ isLoading, containerClassName, children }) => {
  return (
    <div className={classnames(containerClassName, 'loading-overlay__container')}>
      {isLoading && (
        <div className="loading-overlay__overlay">
          <Loader
            containerClassName="loading-overlay__spinner-wrapper"
            spinnerClassName="loading-overlay__spinner"
          />
          <div className="loading-overlay__overlay-background" />
        </div>
      )}
      {children}
    </div>
  );
};

export default LoadingOverlay;

LoadingOverlay.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  containerClassName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired
};
