import type { DashboardPDFOverlayData } from './useDashboardPDFOverlayData.types';
import type { GlobalState } from 'shared/reducers';

import * as rightPanelSelectors from 'shared/features/rightPanel/rightPanel.selectors';

import { useSelector } from 'react-redux';

export const useDashboardPDFOverlayData = (): DashboardPDFOverlayData => {
  const reduxState = useSelector<GlobalState, DashboardPDFOverlayData['reduxState']>(state => ({
    overlayRefreshTrigger: rightPanelSelectors.getOverlayRefreshTrigger(state),
    documentPDFUrl: rightPanelSelectors.getDocumentPDFUrl(state),
    shouldCloseRightPanelOnOverlayClose: rightPanelSelectors.getRightPanelOverlayCloseFlag(state)
  }));

  return {
    reduxState
  };
};
