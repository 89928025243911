import lodash from 'lodash';
import queryString from 'utils/query-string';

import * as routes from 'constants/Routes';

export const getParsedSearchArgs = alertSearchArgs => {
  try {
    let query = JSON.parse(alertSearchArgs);

    if (typeof query === 'string') {
      query = JSON.parse(query);
    }

    return {
      ...query,
      cai_category: safeGetAlertCAICategories(query),
      category: safeGetAlertCategories(query)
    };
  } catch (Exception) {
    return {};
  }
};

export const getSavedSearchPath = alertSearchArgs => {
  const query = getParsedSearchArgs(alertSearchArgs);

  return query.pathname || routes.content;
};

// category names will be deprecated but this functionality should be preserved for compatibility with old alert data
export function safeGetAlertCategories(search_args) {
  let appCategories = [];
  if (lodash.get(search_args, 'category')) {
    if (lodash.isArray(search_args.category)) {
      appCategories = search_args.category;
    } else {
      appCategories = [search_args.category];
    }
  }
  return appCategories;
}

export function safeGetAlertCAICategories(search_args) {
  let appCategories = [];
  if (lodash.get(search_args, 'cai_category')) {
    if (lodash.isArray(search_args.cai_category)) {
      appCategories = search_args.cai_category;
    } else {
      appCategories = [search_args.cai_category];
    }
  }
  return appCategories;
}

export const getAlertURLParams = (id, search_args_string, published_from) => {
  const parsedSearchArgs = getParsedSearchArgs(search_args_string);

  return `?${queryString.stringify({
    ...parsedSearchArgs,
    alert_id: id,
    ...(published_from ? { published_from } : {})
  })}`;
};

export const formatAlertUrl = (pathname, queryParams) => {
  if (pathname === routes.resources && queryParams.indexOf('view=JA') === -1) {
    return `${pathname}${queryParams}&view=JA`;
  }

  return `${pathname}${queryParams}`;
};

export const isAdvancedSearchAlert = alertSearchArgs => {
  const parsedSearchArgs = getParsedSearchArgs(alertSearchArgs);

  return !!parsedSearchArgs.advanced_search;
};
