import type { DocumentsTableSettingsProps } from './DocumentsTableSettings.types';
import type { SelectProps } from '@compliance.ai/web-components';
import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './DocumentsTableSettings.constants';

import { useDocumentsTableSettingsData, useDocumentsTableSettingsHandlers } from './hooks';

import './_documents-table-settings.scss';

export const DocumentsTableSettings: FC<DocumentsTableSettingsProps> = ({
  value,
  shouldDisablePortal,
  rowsCountOptions,
  onChange
}) => {
  const { formattedData } = useDocumentsTableSettingsData({ value, rowsCountOptions });

  const handlers = useDocumentsTableSettingsHandlers({
    props: {
      value,
      onChange,
      rowsCountOptions
    }
  });

  return (
    <uiLib.Dropdown
      shouldDisablePortal={shouldDisablePortal}
      triggerContent={
        <uiLib.ActionButton classNames={{ button: 'documents-table-settings__button-wrapper' }}>
          <uiLib.IconMenu />
        </uiLib.ActionButton>
      }
      className="documents-table-settings__dropdown"
      dropdownContent={
        <uiLib.LabeledField label="# of docs per page">
          <uiLib.Select
            type={uiLib.SELECT_TYPES.DEFAULT}
            isClearable={false}
            options={rowsCountOptions ?? constants.ROW_COUNT_OPTIONS}
            value={formattedData.value[constants.DOCUMENTS_TABLE_SETTINGS.ROWS_COUNT]}
            onChange={handlers.handleRowsCountChange as SelectProps['onChange']}
            placeholder="# of docs per page"
          />
        </uiLib.LabeledField>
      }
    />
  );
};
