import { useMemo } from 'react';
import lodash from 'lodash';
import { isBookmarkedFolder } from 'components/Folders/Folders.helpers';

export const useAddToFolderModalFooterData = ({ props }) => {
  const isNewFolderDisabled = useMemo(() => {
    const { isAddingFolder, currentFolder } = props;

    return isAddingFolder || (!lodash.isEmpty(currentFolder) && isBookmarkedFolder(currentFolder));
  }, [props]);

  const isAddDocumentDisabled = useMemo(() => {
    const {
      isLoading,
      isAddingFolder,
      areDocumentsInCurrentFolder,
      folderName,
      currentFolder
    } = props;

    return (
      isLoading ||
      (isAddingFolder && !folderName) ||
      (!isAddingFolder && (areDocumentsInCurrentFolder || !currentFolder))
    );
  }, [props]);

  return {
    formattedData: {
      isNewFolderDisabled,
      isAddDocumentDisabled
    }
  };
};
