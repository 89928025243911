import lodash from 'lodash';
import moment from 'moment';
import queryString from 'utils/query-string';
import { tryToParseToNumber } from 'utils/number';
import { safe_mixpanel_track } from 'utils/analytics';
import * as routes from 'constants/Routes';
import {
  JA_VIEW,
  FILTER_KEY,
  DATE_FILTERS,
  CATEGORY_KEY,
  EXPECTED_NON_EMPTY_FILTER_KEYS
} from 'constants/JurisdictionAnalyzer';

/**
 *
 * Convert the value to number or array of numbers for number format filters
 * @param {String|Number|Array} value - value to be formatted
 * @return {Number|Array} - formatted number or array of numbers
 */

export const formatValueToNumber = value => {
  if (Array.isArray(value)) {
    return value.map(id => tryToParseToNumber(id));
  }

  return tryToParseToNumber(value);
};

/**
 *
 * Convert the value to date format
 * @param {String|Date} value - value to be formatted
 * @return {Date} - formatted date
 */
export const formatValueToDate = dateValue => {
  if (moment(dateValue).isValid()) {
    return moment(dateValue, 'MM/DD/YYYY', true);
  }

  return null;
};

/**
 *
 * Parses dates as moment objects and extracts exact values for non-date values.
 * @param {String} filterKey - filter key
 * @param {String} filterValue - filter value to be formatted
 * @return {Object} - formatted pair of filter key and filter value
 */
export const formatValueForFilter = (filterKey, filterValue, isObj = false) => {
  if (DATE_FILTERS.includes(filterKey)) {
    return {
      [filterKey]: moment(filterValue).isValid() ? moment(filterValue).format('MM/DD/YYYY') : null
    };
  }

  if (filterValue === CATEGORY_KEY) {
    return {
      [filterValue]: filterKey
    };
  }

  if (Array.isArray(filterValue)) {
    return {
      [filterKey]: isObj ? filterValue : filterValue.map(option => option.value || option)
    };
  }

  return { [filterKey]: filterValue };
};

/**
 *
 * Parses jurisdictions and insert their countries as selected.
 * @param {Object} reduxJurisdictions - jurisdictions in Redux
 * @param {Array} jurisdictions - jurisdictions to be handled
 * @return {Array} - array of jurisdictions and countries
 */
export const insertCountriesToJurisdictions = (reduxJurisdictions, jurisdictions) => {
  const jurisdictionValues = jurisdictions.map(({ value }) => value);
  const countryIds = lodash.uniq(
    reduxJurisdictions.items
      .filter(({ short_name }) => jurisdictionValues.includes(short_name))
      .map(({ parent_id }) => parent_id)
  );
  const countries = formatJurisdictionsOptions(
    lodash.orderBy(
      reduxJurisdictions.items.filter(jurisdiction => countryIds.includes(jurisdiction.id)),
      jurisdiction => jurisdiction.name
    )
  );

  return mergeOptionsByUniqueness(jurisdictions, countries);
};

export const formatJurisdictionsOptions = jurisdictions => {
  if (Array.isArray(jurisdictions)) {
    return jurisdictions
      .filter(jurisdiction => jurisdiction.short_name && jurisdiction.name)
      .map(jurisdiction => {
        const jurisdictionLabel = `${jurisdiction.name} (${jurisdiction.short_name})`;
        return { label: jurisdictionLabel, value: jurisdiction.short_name };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }
  return [];
};

export const formatDefaultJurisdictions = (reduxJurisdictions, followedJurisdictions) => {
  if (Array.isArray(followedJurisdictions)) {
    return insertCountriesToJurisdictions(
      reduxJurisdictions,
      formatJurisdictionsOptions(followedJurisdictions)
    );
  }
  return [];
};

export const formatTopicOptions = topics => {
  if (Array.isArray(topics)) {
    return topics
      .map(topic => {
        return {
          label: topic.name || topic.label,
          value: topic.id || topic.topic_id
        };
      })
      .sort((topicA, topicB) => topicA.label.localeCompare(topicB.label));
  }
  return [];
};

export const formatDefaultTopics = (followedTopicsObj, activeTopics) => {
  if (Array.isArray(activeTopics)) {
    return activeTopics
      .filter(topic => lodash.get(followedTopicsObj, [topic.id, 'following'], false))
      .map(topic => ({ label: topic.label, value: topic.id }))
      .sort((topicA, topicB) => topicA.label.localeCompare(topicB.label))
      .map(topic => topic.value);
  }
  return [];
};

export const formatRegulationOptions = regulations => {
  if (Array.isArray(regulations)) {
    return regulations
      .map(regulation => {
        return { label: regulation.name, value: regulation.id };
      })
      .sort((regA, regB) => regA.label.localeCompare(regB.label));
  }
  return [];
};

export const formatConceptValue = (conceptList, conceptValue) => {
  if (lodash.isEmpty(conceptList) || lodash.isEmpty(conceptValue)) {
    return [];
  }

  const concept = Array.isArray(conceptValue) ? conceptValue[0] : conceptValue;
  const conceptId = concept.value ? concept.value : Number(concept);
  const conceptObj = conceptList.find(concept => concept.id === conceptId);

  return [
    {
      label: conceptObj.name,
      value: conceptObj.id
    }
  ];
};

export const formatDocTypeValue = (docTypes, docTypeValues) => {
  if (lodash.isEmpty(docTypes.cai_categories) || lodash.isEmpty(docTypeValues)) {
    return [];
  }

  const docTypeChanged = typeof docTypeValues === 'string' ? [docTypeValues] : docTypeValues;

  return docTypeChanged
    .map(value => {
      if (typeof value !== 'object') {
        const docTypeObj = docTypes.cai_categories[value];

        if (docTypeObj) {
          return {
            label: docTypeObj.name,
            value: docTypeObj.id
          };
        }
      }

      return value;
    })
    .filter(value => Boolean(value));
};

export const shouldShowAlert = reduxState => {
  const { editAlertId, viewAlertId } = reduxState.current_view;
  const currentAlertId = editAlertId || viewAlertId || null;

  const currentAlert =
    currentAlertId &&
    reduxState.alerts.user_alerts &&
    reduxState.alerts.user_alerts.find(alert => alert.id === currentAlertId);

  return Boolean(currentAlert && currentAlert.search_args.includes(routes.resources));
};

export const applyFilters = ({ reduxState, reduxActions, shouldClearAlert, navigate }) => {
  doAnalytics(reduxState);

  reduxActions.closeFilter();

  if (
    shouldClearAlert &&
    (reduxState.current_view.editAlertId || reduxState.current_view.viewAlertId)
  ) {
    reduxActions.removeViewAlert();
    reduxActions.removeEditAlert();
  }

  const queryString = formatQueryStringFromFilterParams({
    filterParams: reduxState.filterParams
  });

  navigate(`${routes.resources}?${queryString}`);
};

export const doAnalytics = reduxState => {
  safe_mixpanel_track('Jurisdiction Analyzer', {
    eventCategory: 'Jurisdiction Analyzer',
    eventAction: 'Click Search',
    hitType: 'Event',
    eventLabel: `Concept: ${reduxState.filterParams[FILTER_KEY.CONCEPT_ID]}`
  });
};

/**
 *
 * Returns boolean that shows if passed value is not empty
 * @param {Array} - pair of filter key and filter value
 * @return {Boolean} - flag that shows if passed value is not empty
 */
export const isNotEmptyFilter = ([filterKey, filterValue]) => {
  if (EXPECTED_NON_EMPTY_FILTER_KEYS.includes(filterKey)) {
    return true;
  }

  return (
    lodash.isNumber(filterValue) || (!lodash.isEmpty(filterValue) && !lodash.isNil(filterValue))
  );
};

export const formatQueryStringFromFilterParams = ({ filterParams }) => {
  const queryParams = Object.entries(filterParams)
    .filter(isNotEmptyFilter)
    .reduce(
      (params, [filterKey, filterValue]) => {
        if (Array.isArray(filterValue)) {
          params[filterKey] = filterValue.map(option => option.value || option);
        } else {
          params[filterKey] = filterValue;
        }

        return params;
      },
      { view: JA_VIEW }
    );

  return queryString.stringify(queryParams);
};

export const getUnsetAsyncParams = ({ reduxState, filterKey, queryParamKey, location }) => {
  const queryParams = queryString.parse(location.search);
  const queryParam = queryParams[queryParamKey];

  if (!queryParam) {
    return [];
  }

  const currentFilterValue = reduxState.filterParams[filterKey];
  const providedValues = Array.isArray(queryParam) ? queryParam : [queryParam];
  const stateValues = Array.isArray(currentFilterValue) ? currentFilterValue : [currentFilterValue];

  return providedValues.filter(
    providedValue => !stateValues.find(({ value }) => value === parseInt(providedValue, 10))
  );
};

/**
 *
 * Returns merged arrays of options by their value uniqueness
 * @param {Array} options1 - one array of options (objects with value and label properties)
 * @param {Array} options2 - another array of options (objects with value and label properties)
 * @return {Array} - merged and filtered by value uniqueness arrays of options
 */
export const mergeOptionsByUniqueness = (options1, options2) => {
  return lodash.uniqBy([...options1, ...options2], 'value');
};
