import type { TopicsSelectProps } from './TopicsSelect.types';
import type { SelectProps } from '@compliance.ai/web-components';
import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';

import { useTopicsSelectData } from './hooks/useTopicsSelectData';

export const TopicsSelect: FC<TopicsSelectProps> = props => {
  const { reduxState, formattedData } = useTopicsSelectData(props);

  return (
    <uiLib.Select
      {...props}
      onChange={props.onChange as SelectProps['onChange']}
      type={props.type ?? uiLib.SELECT_TYPES.DEFAULT}
      isLoading={!reduxState.isReady || props.isLoading}
      isDisabled={!reduxState.isReady || props.isDisabled}
      options={formattedData.options}
      value={formattedData.value}
    />
  );
};
