import type { DocumentTaskItemProps } from './DocumentTaskItem.types';

import { IconAccountRounded, IconReport, IconWarning } from '@compliance.ai/web-components';
import classnames from 'classnames';
import { DATE_FORMAT } from './DocumentTaskItem.constants';
import { formatDate } from 'utils/date-utils';
import { WorkflowTaskDeadline, WorkflowAssigneeSelect } from 'common';
import { Field } from './elements';
import { useDocumentTaskItemData, useDocumentTaskItemHandlers } from './hooks';
import './_document-task-item.scss';

export const DocumentTaskItem = ({
  task,
  workflow,
  document,
  onTaskUpdate,
  isContrast,
  isInvalid
}: DocumentTaskItemProps) => {
  const { reduxState, formattedData } = useDocumentTaskItemData({ task, workflow, document });

  const handlers = useDocumentTaskItemHandlers({
    props: {
      onTaskUpdate
    }
  });

  return (
    <div
      className={classnames('document-task-item', {
        'document-task-item--is-contrast': isContrast,
        'document-task-item--is-invalid': isInvalid
      })}
    >
      <Field
        label="Task"
        value={<span className="document-task-item__task-name">{task.name}</span>}
      />
      <Field
        label="Deadline"
        isEditable={formattedData.isEditableByPermission}
        value={
          <>
            {(formattedData.isPastDue || !task.deadline) && (
              <IconWarning className="document-task-item__warning-icon" />
            )}
            {task.deadline ? formatDate(task.deadline, DATE_FORMAT, undefined, true) : null}
          </>
        }
        editableValue={
          <WorkflowTaskDeadline
            deadline={task.deadline}
            handleSelectChange={handlers.handleSelectChange}
            minDate={new Date()}
          />
        }
      />
      <Field
        label="Assignee"
        isEditable
        value={
          <>
            {reduxState.selectedAssigneeOption ? (
              <IconAccountRounded className="document-task-item__account-icon" />
            ) : (
              <IconReport className="document-task-item__account-icon" />
            )}
            <span title={reduxState.selectedAssigneeOptionLabel}>
              {reduxState.selectedAssigneeOptionLabel}
            </span>
          </>
        }
        editableValue={
          <WorkflowAssigneeSelect
            value={reduxState.selectedAssigneeOption}
            onChange={handlers.handleSelectChange}
            options={reduxState.assigneeOptions}
            className="document-task-item__assignee-select"
          />
        }
      />
    </div>
  );
};
