import type { AutoSummaryTabProps } from '../AutoSummaryTab.types';
import type { AutoSummaryData } from './useAutoSummaryData.types';

import * as constants from 'constants/DocumentConstants';

import { useMemo } from 'react';

export const useAutoSummaryData = ({ props }: { props: AutoSummaryTabProps }): AutoSummaryData => {
  const formattedData: AutoSummaryData['formattedData'] = useMemo(() => {
    const summaries = {
      [constants.SUMMARY_TYPE.LONG]: [] as string[],
      [constants.SUMMARY_TYPE.SHORT]: [] as string[]
    };

    props.document.summaries.forEach(summary => {
      const existingSummaries = summaries[summary.type] || [];
      const currentSummaries = summary.summary_sentences || [];

      summaries[summary.type] = [...existingSummaries, ...currentSummaries];
    });

    return {
      longSummary: summaries[constants.SUMMARY_TYPE.LONG].join(' '),
      shortSummary: summaries[constants.SUMMARY_TYPE.SHORT].join(' ')
    };
  }, [props.document.summaries]);

  return { formattedData };
};
