import Dropzone from 'react-dropzone';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Attachment from '@material-ui/icons/Attachment';
import { Alert } from '@compliance.ai/web-components';
import { ATTACHMENT_FILES_ALLOWED_EXTENSIONS } from 'constants/TaskConstants';
import { useGenericAttachmentsData, useGenericAttachmentsHandlers } from './hooks';
import { MAX_FILES, MAX_SIZE } from './GenericAttachments.constants';
import { FileLink } from '../FileLink';

import * as images from 'shared/images';

import './_generic_attachments.scss';

export const GenericAttachments = ({
  isRequired,
  attachments,
  disableEdit,
  saveAttachment,
  deleteAttachment,
  maxFiles,
  maxFileSize,
  attachmentClassName,
  containerClassName,
  buttonClassName,
  dropzoneAreaClassName,
  deleteButtonClassName,
  actionWrapperClassName,
  attachButtonText,
  actionText,
  shouldShowAttachmentIcon,
  shouldShowLoader,
  shouldShowAllowedExtensions,
  allowedExtensions,
  onError,
  showWarnings
}) => {
  const { localState, localActions } = useGenericAttachmentsData();

  const handlers = useGenericAttachmentsHandlers({
    props: {
      onError,
      showWarnings,
      attachments,
      maxFiles,
      saveAttachment,
      deleteAttachment
    },
    localActions
  });

  return (
    <div
      className={classnames(containerClassName, 'generic-attachments__container')}
      data-testid="generic-attachments-container"
    >
      {attachments.length < maxFiles && (
        <Dropzone
          onDrop={handlers.handleFilesDrop}
          maxSize={maxFileSize}
          accept={allowedExtensions.join(',')}
          disabled={disableEdit}
          className={classnames(dropzoneAreaClassName, {
            'generic-attachments__dropzone-area': true,
            'generic-attachments__dropzone-area--is-disabled': disableEdit
          })}
          data-testid="dropzone-container"
        >
          <div
            className={classnames(actionWrapperClassName, 'generic-attachments__inner-dropzone')}
          >
            {actionText && <span className="generic-attachments__action-text">{actionText}</span>}
            {shouldShowAttachmentIcon && (
              <Attachment className="generic-attachments__attach-icon" />
            )}
            <button
              type="button"
              className={classnames(buttonClassName, 'generic-attachments__attach-button')}
              data-testid="attachment-button"
              disabled={localState.isLoading}
            >
              {attachButtonText}
            </button>
            {isRequired && (
              <span className="generic-attachments__attach-required-text">(required)</span>
            )}
            {shouldShowLoader && localState.isLoading && (
              <div className="generic-attachments__loader">
                <img src={images.loaders.loadingSpinnerDarkSmall} alt="loading" />
              </div>
            )}
          </div>
          {shouldShowAllowedExtensions && (
            <p className="generic-attachments__allowed-extensions-wrapper">
              Accepted file types:{' '}
              <span className="generic-attachments__allowed-extensions">
                {allowedExtensions.join(', ')}
              </span>
            </p>
          )}
        </Dropzone>
      )}
      {localState.validationWarning && (
        <Alert
          content={localState.validationWarning}
          canBeClosed={true}
          onClose={handlers.handleAlertClose}
          className="generic-attachments__alert"
        />
      )}
      {attachments.map((attachment, i) => (
        <div className={classnames(attachmentClassName, 'generic-attachments__attachment')} key={i}>
          <FileLink href={attachment.url}>{attachment.userFileName}</FileLink>
          {!disableEdit && (
            <span
              className={classnames(
                deleteButtonClassName,
                'generic-attachments__delete-attachment'
              )}
              onClick={handlers.handleFileDelete(attachment)}
            >
              Delete
            </span>
          )}
        </div>
      ))}
    </div>
  );
};

GenericAttachments.propTypes = {
  isRequired: PropTypes.bool,
  disableEdit: PropTypes.bool,
  saveAttachment: PropTypes.func.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      userFileName: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired,
  maxFiles: PropTypes.number,
  maxFileSize: PropTypes.number,
  onError: PropTypes.func,
  showWarnings: PropTypes.bool,
  attachmentClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  dropzoneAreaClassName: PropTypes.string,
  deleteButtonClassName: PropTypes.string,
  attachButtonText: PropTypes.string,
  actionWrapperClassName: PropTypes.string,
  shouldShowAttachmentIcon: PropTypes.bool,
  shouldShowLoader: PropTypes.bool,
  shouldShowAllowedExtensions: PropTypes.bool,
  allowedExtensions: PropTypes.arrayOf(PropTypes.string),
  actionText: PropTypes.string
};

GenericAttachments.defaultProps = {
  isRequired: false,
  disableEdit: false,
  maxFiles: MAX_FILES,
  maxFileSize: MAX_SIZE,
  onError: () => {},
  showWarnings: true,
  attachmentClassName: '',
  containerClassName: '',
  buttonClassName: '',
  dropzoneAreaClassName: '',
  deleteButtonClassName: '',
  actionWrapperClassName: '',
  attachButtonText: 'Attach Files',
  shouldShowAttachmentIcon: true,
  shouldShowLoader: true,
  shouldShowAllowedExtensions: false,
  allowedExtensions: ATTACHMENT_FILES_ALLOWED_EXTENSIONS,
  actionText: ''
};
