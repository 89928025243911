import type { AlertsSelectData } from './useAlertsSelectData.types';

import { useMemo, useState } from 'react';

export const useAlertsSelectData = (): AlertsSelectData => {
  const [value, setValue] = useState<AlertsSelectData['localState']['value']>(null);

  const localState: AlertsSelectData['localState'] = {
    value
  };

  const localActions: AlertsSelectData['localActions'] = useMemo(
    () => ({
      setValue
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
