import type { WorkflowData } from './useWorkflowData.types';
import type { WorkflowHandlers } from './useWorkflowHandlers.types';
import type { useWorkflowQueries } from './useWorkflowQueries';

import { isDocTaskBlocked } from 'shared/features/workflow/workflow.helpers';

export const useWorkflowHandlers = ({
  localActions,
  queries
}: {
  localActions: WorkflowData['localActions'];
  queries: ReturnType<typeof useWorkflowQueries>;
}) => {
  const handleWorkflowSummaryToggle: WorkflowHandlers['handleWorkflowSummaryToggle'] = isOpen => () => {
    localActions.setSummaryModalOpenState(isOpen);
  };

  const handleExpandIconClick: WorkflowHandlers['handleExpandIconClick'] = shouldExpand => () => {
    localActions.toggleTasksDetailsVisibility(() => ({
      ...(queries.fetchDocumentTasksQuery.data?.documentTasks ?? []).reduce(
        (tasksDetailsVisibility, documentTask) => ({
          ...tasksDetailsVisibility,
          [documentTask.id]: !isDocTaskBlocked(documentTask) && shouldExpand
        }),
        {}
      )
    }));
  };

  const handleDetailsRevealIconClick: WorkflowHandlers['handleDetailsRevealIconClick'] = documentTask => () => {
    localActions.toggleTasksDetailsVisibility(tasksDetailsVisibility => ({
      ...tasksDetailsVisibility,
      [documentTask.id]: !tasksDetailsVisibility[documentTask.id]
    }));
  };

  return {
    handleWorkflowSummaryToggle,
    handleExpandIconClick,
    handleDetailsRevealIconClick
  };
};
