import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withHistory } from 'utils/hooks';
import WidgetDocumentsSummary from '../WidgetDocumentsSummary';
import WidgetMessage from '../WidgetMessage';
import './_widget-documents-wrapper.scss';

const WidgetDocumentsWrapper = ({
  documents,
  onSeeAllLinkClick,
  summarySubject,
  summarySubjectMentionsCount,
  summarySubjectMentionsCountByCategories,
  shouldShowNoDocsLink,
  shouldShowSummary,
  shouldAlwaysShowSeeAllLink,
  noDocsMessage,
  children
}) => {
  const handleSeeAllLinkClick = e => {
    e.preventDefault();
    onSeeAllLinkClick && onSeeAllLinkClick();
  };

  const renderNoDocsMessage = () => {
    if (shouldShowNoDocsLink) {
      return (
        <WidgetMessage>
          The sources you follow have no recent activity.{' '}
          <Link to="/sources" onlyActiveOnIndex>
            Click here
          </Link>{' '}
          to update your selections.
        </WidgetMessage>
      );
    }
    return <WidgetMessage>There is no recent activity</WidgetMessage>;
  };

  const doDocsExist = Array.isArray(documents) && Boolean(documents.length);

  return (
    <>
      {shouldShowSummary && doDocsExist && (
        <WidgetDocumentsSummary
          subject={summarySubject}
          totalCount={summarySubjectMentionsCount}
          countByCategories={summarySubjectMentionsCountByCategories}
          shouldAlwaysShowSeeAllLink={shouldAlwaysShowSeeAllLink}
          onSeeAllLinkClick={handleSeeAllLinkClick}
        />
      )}
      {doDocsExist ? documents.map(children) : noDocsMessage || renderNoDocsMessage()}
    </>
  );
};

WidgetDocumentsWrapper.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      topics: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string
        })
      ),
      category: PropTypes.string,
      agencies: PropTypes.arrayOf(
        PropTypes.shape({
          short_name: PropTypes.string
        })
      ),
      id: PropTypes.number,
      publication_date: PropTypes.string
    })
  ).isRequired,
  shouldShowNoDocsLink: PropTypes.bool,
  shouldShowSummary: PropTypes.bool,
  summarySubject: PropTypes.string,
  summarySubjectMentionsCount: PropTypes.number,
  summarySubjectMentionsCountByCategories: PropTypes.shape({}),
  onSeeAllLinkClick: PropTypes.func,
  children: PropTypes.func.isRequired,
  noDocsMessage: PropTypes.element,
  shouldAlwaysShowSeeAllLink: PropTypes.bool
};

WidgetDocumentsWrapper.defaultProps = {
  shouldShowNoDocsLink: false,
  shouldShowSummary: false,
  noDocsMessage: null,
  onSeeAllLinkClick: () => {},
  shouldAlwaysShowSeeAllLink: false
};

export default withHistory(WidgetDocumentsWrapper);
