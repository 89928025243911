import dfs from 'depth-first';

import lodash from 'lodash';
import * as uiLib from '@compliance.ai/web-components';
import * as commentsHelpers from '../comments/comments.helpers';
import * as userHelpers from '../user/user.helpers';
import * as constants from './workflow.constants';
import * as permissionConstants from '../../../constants/Permissions';

export const normalizeDocumentTask = ({
  annotation_date = null,
  annotation_assignee_selection = [],
  annotation_richtext = null,
  annotation_settings = null,
  assignee = null,
  assigner = null,
  approvers = [],
  approval_status = null,
  original_assignee = null,
  comment_threads: commentThreads = [],
  created_at = null,
  deadline = null,
  deleted = false,
  dependency_dropdown_selections = [],
  dependency_id = null,
  doc_id = null,
  document_task_attachments = [],
  document_task_date_dependencies = [],
  dropdowns = [],
  id = null,
  is_obligation_super_task = false,
  non_relevant = false,
  properties = null,
  task_id = null,
  task_status = 'incomplete',
  team_id = null,
  teams = [],
  updated_at = null,
  workflow_id = null,
  isLoading = false
}) => {
  return {
    annotation_date,
    annotation_assignee_selection,
    annotation_richtext,
    annotation_settings,
    assignee,
    assigner,
    approvers,
    approvalStatus: normalizeApprovalStatus(approval_status),
    originalAssignee: original_assignee,
    commentThreads: commentsHelpers.normalizeCommentThreads(commentThreads),
    created_at,
    deadline,
    deleted,
    dependency_dropdown_selections,
    dependency_id,
    doc_id,
    document_task_attachments,
    document_task_date_dependencies,
    dropdowns,
    id,
    is_obligation_super_task,
    non_relevant,
    properties,
    task_id,
    task_status,
    team_id,
    teams,
    updated_at,
    workflow_id,
    isLoading
  };
};

export const normalizeTask = ({
  assignee = null,
  assigner = null,
  document_task = null,
  task = null,
  workflow = null
}) => {
  return {
    assignee,
    assigner,
    document_task: normalizeDocumentTask(document_task),
    task,
    workflow
  };
};

export const normalizeApprovalStatus = approvalStatus => {
  const statusEntry = Object.entries(constants.APPROVAL_ANNOTATION_API_STATUSES).find(
    ([_, statusNum]) => {
      return statusNum === approvalStatus;
    }
  );

  if (!statusEntry) {
    return constants.APPROVAL_ANNOTATION_STATUSES.INITIAL;
  }

  return statusEntry[0];
};

export const addCommentThreadToTask = ({ docTaskId, commentThread }) => task => {
  if (task.document_task.id === docTaskId) {
    return {
      ...task,
      document_task: {
        ...task.document_task,
        commentThreads: [
          ...task.document_task.commentThreads,
          ...commentsHelpers.normalizeCommentThreads([commentThread])
        ]
      }
    };
  }
  return task;
};

export const toggleLoadingStatus = ({ docTaskId, isLoading }) => task => {
  if (task.document_task.id === docTaskId) {
    return {
      ...task,
      document_task: {
        ...task.document_task,
        isLoading
      }
    };
  }
  return task;
};

export const updateCommentThread = updatedCommentThread => commentThread => {
  if (commentThread.id === updatedCommentThread.id) {
    return {
      ...commentThread,
      comments: updatedCommentThread.comments.map(commentsHelpers.normalizeComment)
    };
  }
  return commentThread;
};

export const createComment = ({ docTaskId, commentThread }) => task => {
  if (task.document_task.id === docTaskId) {
    return {
      ...task,
      document_task: {
        ...task.document_task,
        commentThreads: task.document_task.commentThreads.map(updateCommentThread(commentThread))
      }
    };
  }
  return task;
};

export const byCommentThreadId = (commentThreadId, shouldBeEqual = true) => {
  if (shouldBeEqual) {
    return commentThread => commentThreadId === commentThread.id;
  }
  return commentThread => commentThreadId !== commentThread.id;
};

export const byCommentId = (commentId, shouldBeEqual = true) => {
  if (shouldBeEqual) {
    return comment => commentId === comment.id;
  }
  return comment => commentId !== comment.id;
};

export const deleteComment = ({ docTaskId, comment }) => task => {
  if (task.document_task.id === docTaskId) {
    const updatedCommentThreads = comment.isRootComment
      ? task.document_task.commentThreads.filter(byCommentThreadId(comment.commentThreadId, false))
      : task.document_task.commentThreads.map(commentThread => ({
          ...commentThread,
          comments: commentThread.comments.filter(byCommentId(comment.id, false))
        }));

    return {
      ...task,
      document_task: {
        ...task.document_task,
        commentThreads: updatedCommentThreads
      }
    };
  }
  return task;
};

export const sortByTaskOrder = ({ taskOrders, documentTasks }) => {
  return documentTasks.sort((documentTaskA, documentTaskB) => {
    if (taskOrders) {
      return taskOrders.indexOf(documentTaskA.task_id) - taskOrders.indexOf(documentTaskB.task_id);
    }

    return documentTaskA.task_id - documentTaskB.task_id;
  });
};

export const formatUserName = user => {
  if (!user) {
    return 'unknown';
  }

  if (user.first_name && user.last_name) {
    return user.first_name + ' ' + user.last_name[0] + '.';
  }

  return user.email;
};

export const formatAnnotationLabel = ({ label, isRequired }) =>
  `${label}${isRequired ? ' (required)' : ''}`;

export const formatAssigneeName = assignee => {
  if (!assignee) {
    return '';
  }

  if (assignee.first_name) {
    if (assignee.last_name) {
      return `${uiLib.capitalize(assignee.first_name)} ${uiLib.capitalize(assignee.last_name)}`;
    }

    return uiLib.capitalize(assignee.first_name);
  }

  return assignee.email;
};

export const formatAssigneeOption = assignee => {
  if (!assignee) {
    return null;
  }

  return {
    value: assignee.id,
    label: formatAssigneeName(assignee)
  };
};

export const getUniqTeamMembers = (teams = []) => {
  return teams
    .filter(team => !Boolean(team.deleted))
    .reduce((teamMembers, team) => lodash.uniqBy([...teamMembers, ...team.team_members], 'id'), [])
    .filter(teamMember => !Boolean(teamMember.deleted));
};

export const formatAssigneeOptionsFromMembers = (members = []) => {
  return members.map(member => {
    return {
      value: member.id,
      disabled: !member.active,
      label: member.email,
      active: member.active,
      email: member.email
    };
  });
};

export const normalizeWorkflow = workflow => {
  return {
    id: workflow.id,
    alert: workflow.alert,
    alerts: workflow.alerts,
    createdAt: workflow.created_at,
    isDeleted: workflow.deleted,
    isEditable: workflow.is_editable,
    lastDocsUpdateDate: workflow.properties.last_data_docs_update,
    lastUpdatedTime: workflow.last_updated_time,
    name: workflow.name,
    orgId: workflow.org_id,
    publishedSince: workflow.published_since,
    teams: workflow.teams,
    bucket: workflow.bucket
      ? {
          id: workflow.bucket.id,
          name: workflow.bucket.name
        }
      : null,
    workflowStatus: workflow.workflow_status,
    documentWorkflow: workflow.document_workflow,
    obligationWorkflow: workflow.obligation_workflow,
    types: normalizeWorkflowTypes(workflow)
  };
};

export const normalizeWorkflowTypes = workflow => {
  const types = [];

  if (workflow?.document_workflow) {
    types.push(constants.WORKFLOW_TYPE.DOCUMENT);
  }

  if (workflow?.obligation_workflow) {
    types.push(constants.WORKFLOW_TYPE.OBLIGATION);
  }

  return types;
};

export const normalizeWorkflows = workflows => {
  if (!Array.isArray(workflows)) {
    return [];
  }

  return workflows.filter(lodash.isObject).map(normalizeWorkflow);
};

export const getDependencyTasksFromWorkflow = workflow => {
  const dependencyTasks = [];
  const dateDependencyTasks = [];
  const assigneeDependencyTasks = [];

  if (workflow) {
    workflow.tasks.forEach(task => {
      dependencyTasks.push({
        id: task.id,
        name: task.name,
        dropdowns: task.dropdowns || [],
        dependencies: task.dependencies
      });
      dateDependencyTasks.push({
        id: task.id,
        name: task.name,
        due_days_before_doc_date: task.due_days_before_doc_date
      });
      if (
        !lodash.isEmpty(
          task.annotation_settings[constants.WORKFLOW_ANNOTATION_SETTINGS_KEYS.ASSIGNEE_OPTIONS]
        )
      )
        assigneeDependencyTasks.push({
          id: task.id,
          name: task.name,
          assignees:
            task.annotation_settings[constants.WORKFLOW_ANNOTATION_SETTINGS_KEYS.ASSIGNEE_OPTIONS]
        });
    });
  }

  return {
    dependencyTasks,
    dateDependencyTasks,
    assigneeDependencyTasks
  };
};

export const getAvailableDependencies = ({
  task,
  tasks,
  dependenciesGraph,
  dependentTaskIdKey = 'dependent_task_id'
}) => {
  /*
    Finds tasks that are dependant on the current one.
    https://github.com/laat/depth-first#reverse-edges.
  */
  const dependantTasks = dfs(dependenciesGraph, task.id, { reverse: true });

  return tasks.filter(({ id }) => {
    const taskIsAlreadySelected = task.dependencies.some(
      ({ [dependentTaskIdKey]: dependentTaskId }) => dependentTaskId === id
    );

    if (taskIsAlreadySelected) return false;

    const willCreateACycle = dependantTasks.includes(id);

    return !willCreateACycle;
  });
};

export const isNotAssigneeDependentOnTaskOrDependencyTask = (task, _, tasks) => {
  if (Boolean(task.is_dependent_on_assignee) && Boolean(task.dependent_on_assignee_task_id)) {
    return false;
  }

  return task.dependencies.every(dependency => {
    const dependentTask = tasks.find(_task => _task.id === dependency.dependent_task_id);

    return !(Boolean(dependentTask) && Boolean(dependentTask.dependent_on_assignee_task_id));
  });
};

export const sortWorkflowTasks = workflow => {
  const sortedWorkflowTasks =
    workflow.tasks?.sort((taskA, taskB) => {
      const taskAOrderIndex =
        workflow.task_order_ids?.findIndex(taskId => taskId === taskA.id) ?? 1;
      const taskBOrderIndex =
        workflow.task_order_ids?.findIndex(taskId => taskId === taskB.id) ?? 0;

      return taskAOrderIndex < taskBOrderIndex ? -1 : 1;
    }) ?? [];

  return {
    ...workflow,
    tasks: sortedWorkflowTasks.map(task => ({
      ...task,
      dropdowns: task.dropdowns?.sort((dropdownA, dropdownB) =>
        (dropdownA.order_id ?? dropdownA.id) > (dropdownB.order_id ?? dropdownB.id) ? 1 : -1
      )
    }))
  };
};

export const filterOrgMembersByPermissions = organizationMembers => {
  return organizationMembers.reduce((orgMembers, orgMember) => {
    const permissions = userHelpers.formatPermissions(orgMember.organization_roles || []);

    if (!orgMember.active || !permissions[permissionConstants.ACCESS_TASKS]) {
      return orgMembers;
    }

    return [...orgMembers, orgMember];
  }, []);
};

export const filterTeamMembersByPermissions = teams => {
  return teams.reduce((teamsUsers, team) => {
    return team.team_members.reduce((teamUsers, teamMember) => {
      const isAlreadyAdded = teamsUsers.find(({ id }) => id === teamMember.id);

      if (!teamMember.active || isAlreadyAdded) {
        return teamUsers;
      }

      return [...teamUsers, teamMember];
    }, teamsUsers);
  }, []);
};

export const isDocTaskBlocked = docTask => {
  return Boolean(docTask.is_blocked && docTask.should_be_blocked_upstream);
};

export const getParentDropdownOptions = ({
  dropdownOption,
  dropdownOptions,
  parentDropdownOptions = []
}) => {
  if (dropdownOption.parent_id) {
    const parentDropdownOption = dropdownOptions.find(o => o.id === dropdownOption.parent_id);

    return getParentDropdownOptions({
      dropdownOption: parentDropdownOption,
      dropdownOptions: dropdownOptions,
      parentDropdownOptions: [parentDropdownOption, ...parentDropdownOptions]
    });
  }

  return parentDropdownOptions;
};

export const formatNestedSelectedDropdownOptionLabels = dropdownOptions => {
  const selectedDropdownOptions = dropdownOptions.filter(dropdownOption => dropdownOption.selected);

  return selectedDropdownOptions.map(selectedDropdownOption => {
    const parentDropdownOptions = getParentDropdownOptions({
      dropdownOption: selectedDropdownOption,
      dropdownOptions: dropdownOptions
    });

    return [...parentDropdownOptions, selectedDropdownOption]
      .map(parentDropdownOption => parentDropdownOption.dropdown_text)
      .join(': ');
  });
};

export const sortDropdowns = (a, b) => ((a.order_id ?? a.id) > (b.order_id ?? b.id) ? 1 : -1);
