import PropTypes from 'prop-types';
import { AgenciesSelect } from 'common';
import { Select, SELECT_TYPES } from '@compliance.ai/web-components';
import './_genericSelectorWithTitle.scss';

const SELECT_ID = 'generic-select';

const GenericSelectorWithTitle = ({
  id,
  title,
  value,
  options,
  onChange,
  loadOptions,
  multi,
  clearable,
  isAgenciesSelect,
  isAsync
}) => {
  const SelectComponent = isAgenciesSelect ? AgenciesSelect : Select;

  return (
    <div className="Generic-Selector-With-Title-Container">
      <label htmlFor={id || SELECT_ID}>{title}</label>
      <SelectComponent
        type={SELECT_TYPES.ALTERNATIVE}
        value={value}
        options={options}
        className="Default-Select"
        onChange={onChange}
        loadOptions={loadOptions}
        isClearable={clearable}
        isMulti={multi}
        isAsync={isAsync}
      />
    </div>
  );
};

GenericSelectorWithTitle.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onAsyncRequest: PropTypes.func,
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  multi: PropTypes.bool,
  isAsync: PropTypes.bool
};

GenericSelectorWithTitle.defaultProps = {
  id: undefined,
  onAsyncRequest: undefined,
  options: [],
  value: null,
  multi: false,
  isAsync: false
};

export default GenericSelectorWithTitle;
