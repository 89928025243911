import type { ComponentType } from 'react';

import { useRightPanelReduxActions } from './useRightPanelReduxActions';

export const withRightPanelReduxActions = (Component: ComponentType<Record<string, unknown>>) => {
  return function WrappedComponent(props: Record<string, unknown>) {
    const rightPanelReduxActions = useRightPanelReduxActions();

    return <Component {...props} {...rightPanelReduxActions} />;
  };
};
