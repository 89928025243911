export default {
  complianceAiBlack: new URL('./ComplianceAi-black.png', import.meta.url).href,
  complianceAiWhite: new URL('./ComplianceAi-white.png', import.meta.url).href,
  logoBlackName: new URL('./logo-black-name.png', import.meta.url).href,
  logoIcon: new URL('./logo-icon.png', import.meta.url).href,
  logoWhiteNameBlackBackground: new URL('./logo-white-name-black-background.png', import.meta.url)
    .href,
  logoWhiteName: new URL('./logo-white-name.png', import.meta.url).href,

  complianceLogoIcon: new URL('./compliance-logo-icon.svg', import.meta.url).href,
  logoBlackNameSvg: new URL('./logo-black-name.svg', import.meta.url).href,
  logoIconWhiteCircle: new URL('./logo-icon-white-circle.svg', import.meta.url).href,
  logoIconSvg: new URL('./logo-icon.svg', import.meta.url).href,
  logoWhiteNameSvg: new URL('./logo-white-name.svg', import.meta.url).href
};
