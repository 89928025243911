import type { RenameBucketModalData } from './useRenameBucketModalData.types';
import type { RenameBucketModalHandlers } from './useRenameBucketModalHandlers.types';
import type { RenameBucketModalProps } from '../RenameBucketModal.types';

import * as bucketsConstants from 'shared/features/buckets/buckets.constants';
import * as actionsConstants from 'shared/features/buckets/buckets.actions.constants';
import * as actionsHelpers from 'shared/features/buckets/buckets.actions.helpers';

export const useRenameBucketModalHandlers = ({
  localActions
}: {
  localActions: RenameBucketModalData['localActions'];
}): RenameBucketModalHandlers => {
  const handleBucketNameChange: RenameBucketModalHandlers['handleBucketNameChange'] = bucketName => {
    localActions.setBucketName(bucketName);
  };

  return {
    handleBucketNameChange
  };
};
