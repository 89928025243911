import type * as apiTypes from './sentenceNotes.api.types';

import { fetch } from 'fetch';

export const getSentenceNoteById: apiTypes.GetSentenceNoteById = id => {
  return fetch({
    url: `/sentence_note/${id}`,
    method: 'GET',
    shouldHideErrorNotifications: true,
    shouldDisableQueryParamsArrayFormatting: true
  });
};

export const getSentenceNotes: apiTypes.GetSentenceNotes = params => {
  return fetch({
    url: '/sentence_note',
    method: 'GET',
    params,
    shouldHideErrorNotifications: true,
    shouldDisableQueryParamsArrayFormatting: true
  });
};

export const createSentenceNote: apiTypes.CreateSentenceNote = params => {
  return fetch({
    url: '/sentence_note',
    method: 'POST',
    dataType: 'Annotations',
    params
  });
};

export const modifySentenceNote: apiTypes.ModifySentenceNote = (id, params) => {
  return fetch({
    url: `/sentence_note/${id}`,
    method: 'POST',
    params,
    shouldHideErrorNotifications: true
  });
};

export const deleteSentenceNote: apiTypes.DeleteSentenceNote = id => {
  return fetch({
    url: `/sentence_note/${id}`,
    method: 'DELETE',
    shouldHideErrorNotifications: true
  });
};

export const updateSentenceNoteLabel: apiTypes.UpdateSentenceNoteLabel = ({
  sentenceNoteId,
  labelId,
  labelName,
  bucketId
}) => {
  return fetch({
    url: '/label',
    method: 'POST',
    dataType: 'Annotations',
    params: {
      sentence_note_id: sentenceNoteId,
      label_id: labelId,
      label_name: labelName,
      bucket_id: bucketId
    }
  });
};

export const removeSentenceNoteLabel: apiTypes.DeleteSentenceNoteLabel = ({
  sentenceNoteId,
  labelId
}) => {
  return fetch({
    url: '/label',
    method: 'DELETE',
    dataType: 'Annotations',
    params: {
      sentence_note_id: sentenceNoteId,
      label_id: labelId
    }
  });
};

export const saveSentenceNoteAttachment: apiTypes.SaveSentenceNoteAttachment = ({
  sentenceNoteId,
  files
}) => {
  try {
    const formData = new window.FormData();

    files.forEach((file, i) => {
      formData.append(`file${i + 1}`, file);
    });

    return fetch({
      url: `/sentence_note/${sentenceNoteId}/attachments`,
      method: 'POST',
      dataType: 'Annotations',
      params: formData
    });
  } catch (e) {
    throw e;
  }
};

export const deleteSentenceNoteAttachment: apiTypes.DeleteSentenceNoteAttachment = ({
  sentenceNoteId,
  maskedFileName
}) => {
  return fetch({
    url: `/sentence_note/${sentenceNoteId}/attachments/${maskedFileName}`,
    method: 'DELETE',
    dataType: 'Annotations',
    params: {}
  });
};

export const fetchSystemGeneratedSentenceNotes: apiTypes.FetchSystemGeneratedSentenceNotes = documentId => {
  return fetch({
    url: `/sentence_note/system_generated_note/${documentId}`,
    method: 'GET',
    shouldHideErrorNotifications: true,
    shouldDisableQueryParamsArrayFormatting: true
  });
};
