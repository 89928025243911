import { useMemo, useState } from 'react';

export const useLabelDetailsModalData = () => {
  const [isAuthorsModalOpen, setAuthorsModalVisibility] = useState(false);
  const [isLabelsMoveModalOpen, setLabelsMoveModalVisibility] = useState(false);
  const [isLabelsRemoveModalOpen, setLabelsRemoveModalVisibility] = useState(false);

  const [rowAuthors, setRowAuthors] = useState(null);
  const [documentsIds, setDocumentsIds] = useState([]);
  const [selectedDocumentsIds, setSelectedDocumentsIds] = useState([]);

  const modalActions = useMemo(
    () => ({
      setAuthorsModalVisibility,
      setLabelsMoveModalVisibility,
      setLabelsRemoveModalVisibility,
      setDocumentsIds,
      setRowAuthors,
      setSelectedDocumentsIds
    }),
    []
  );

  const modalFormattedData = useMemo(
    () => ({
      isActionButtonDisabled: !Boolean(selectedDocumentsIds.length)
    }),
    [selectedDocumentsIds]
  );

  return {
    modalState: {
      isAuthorsModalOpen,
      rowAuthors,
      isLabelsMoveModalOpen,
      isLabelsRemoveModalOpen,
      documentsIds,
      selectedDocumentsIds
    },
    modalActions,
    modalFormattedData
  };
};
