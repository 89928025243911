import type { FieldNameProps } from './FieldName.types';

import classnames from 'classnames';
import './_field-name.scss';

export const FieldName = ({ children, className }: FieldNameProps) => {
  return (
    <div className={classnames(className, 'document-summary-metadata-field-name')}>{children}</div>
  );
};
