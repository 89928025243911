import type * as apiTypes from './filterGroups.api.types';

import { fetch } from 'fetch';

export const fetchFilterGroups: apiTypes.FetchFilterGroups = () => {
  return fetch({
    url: '/filter_groups',
    method: 'GET'
  });
};
