import type { LabelInputHandlers } from './useLabelInputHandlers.types';
import type { LabelInputProps } from '../LabelInput.types';
import type { LabelInputData } from './useLabelInputData.types';
import type { LabelOption } from 'shared/features/labels/labels.types';

import * as labelsHelpers from 'shared/features/labels/labels.helpers';
import * as helpers from './useLabelInputHandlers.helpers';
import * as labelsConstants from 'shared/features/labels/labels.constants';
import * as hookConstants from './useLabelInputData.constants';
import * as errorUtils from 'utils/errors';

import { useOrganizationLabelsReduxActions } from 'shared/features/labels/hooks';

export const useLabelInputHandlers = ({
  props,
  localActions
}: {
  props: Pick<LabelInputProps, 'labels' | 'createNewLabel' | 'removeLabel'>;
  localActions: LabelInputData['localActions'];
}): LabelInputHandlers => {
  const reduxActions = useOrganizationLabelsReduxActions();

  const handleClose: LabelInputHandlers['handleClose'] = () => {
    localActions.setIsCreating(false);
    localActions.setSearchValue(hookConstants.INITIAL_STATE.searchValue);
    localActions.setIsGroupSelectOpened(false);
  };

  const handleSubmit: LabelInputHandlers['handleSubmit'] = value => {
    const labelOption = value as LabelOption;

    props.createNewLabel(labelOption);

    localActions.setIsCreating(false);
    localActions.setSearchValue(hookConstants.INITIAL_STATE.searchValue);
    localActions.setIsGroupSelectOpened(false);
  };

  const handleLabelRemove: LabelInputHandlers['handleLabelRemove'] = labelId => () => {
    props.removeLabel(labelId);
  };

  const handleGroupSelectToggle: LabelInputHandlers['handleGroupSelectToggle'] = isOpen => () => {
    localActions.setIsGroupSelectOpened(isOpen);
  };

  const handleInputChange: LabelInputHandlers['handleInputChange'] = searchValue => {
    localActions.setSearchValue(searchValue);
  };

  const handleLabelsFetch: LabelInputHandlers['handleLabelsFetch'] = async inputString => {
    try {
      const { labels } = await reduxActions.fetchAllLabels({
        name: inputString,
        count_per_page: labelsConstants.MAX_LABELS_COUNT,
        is_editable: true
      });
      return labelsHelpers
        .getLabelsGroupedOptions(labels)
        .map(helpers.mapActiveAsDisabled(props.labels));
    } catch (e) {
      errorUtils.logError(e as Error);
      return [];
    }
  };

  const handleCreateModalOpen: LabelInputHandlers['handleCreateModalOpen'] = () => {
    localActions.setIsGroupSelectOpened(false);
    localActions.setIsCreateModalOpened(true);
  };

  const handleCreateModalClose: LabelInputHandlers['handleCreateModalClose'] = () => {
    localActions.setIsCreateModalOpened(false);
  };

  return {
    handleClose,
    handleSubmit,
    handleLabelRemove,
    handleGroupSelectToggle,
    handleInputChange,
    handleLabelsFetch,
    handleCreateModalOpen,
    handleCreateModalClose
  };
};
