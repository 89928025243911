import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setActionBarFilter as reduxSetActionBarFilter } from '../filters.actions';

export const useFilterReduxActions = () => {
  const dispatch = useDispatch();

  const setActionBarFilter = useCallback(
    filterParams => {
      dispatch(reduxSetActionBarFilter(filterParams));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      setActionBarFilter
    }),
    [setActionBarFilter]
  );
};
