import type { FirstLoginJoyrideHandlers } from './useFirstLoginExperienceHandlers.types';

import { useEffect } from 'react';

export const useFirstLoginExperienceLifecycle = ({
  handleGetOrganizationProfile
}: {
  handleGetOrganizationProfile: FirstLoginJoyrideHandlers['handleGetOrganizationProfile'];
}) => {
  useEffect(() => {
    handleGetOrganizationProfile();
  }, [handleGetOrganizationProfile]);
};
