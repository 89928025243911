import type { ConceptsProps } from '../Concepts.types';
import type { ConceptsHandlers } from './useConceptsHandlers.types';
import type { ConceptsData } from './useConceptsData.types';

export const useConceptsHandlers = ({
  props,
  localState,
  localActions
}: {
  props: Pick<ConceptsProps, 'onChange'>;
  localState: ConceptsData['localState'];
  localActions: ConceptsData['localActions'];
}): ConceptsHandlers => {
  const handleConceptsChange: ConceptsHandlers['handleConceptsChange'] = (options, concepts) => {
    localActions.setValue(options.map(option => option.value));

    if (concepts.length) {
      localActions.setQuery?.(`(${concepts.map(concept => concept.advanced_search).join(' OR ')})`);
    } else {
      localActions.setQuery?.('');
    }
  };

  const handleConceptsApply: ConceptsHandlers['handleConceptsApply'] = () => {
    localActions.setValue([]);

    props.onChange(localState.query);
  };

  return {
    handleConceptsChange,
    handleConceptsApply
  };
};
