export const ARRAY_FORMATS = {
  BRACKET: 'bracket',
  INDEX: 'index',
  COMMA: 'comma',
  SEPARATOR: 'separator',
  NONE: 'none'
};

export const QUERY_STRING_OPTION_KEYS = {
  ARRAY_FORMAT: 'array_format'
};

export const QUERY_STRING_OPTION_VALUES = {
  ARRAY_FORMAT: [
    ARRAY_FORMATS.BRACKET,
    ARRAY_FORMATS.INDEX,
    ARRAY_FORMATS.COMMA,
    ARRAY_FORMATS.SEPARATOR,
    ARRAY_FORMATS.NONE
  ]
};

export const QUERY_STRING_OPTION_DEFAULT_VALUES = {
  ARRAY_FORMAT: ARRAY_FORMATS.NONE
};
