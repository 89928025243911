import type { BucketsFilters } from '../OrganizationBuckets.types';
import type { OrganizationBucketsLocalState } from './useOrganizationBucketsData.types';

import * as constants from '../OrganizationBuckets.constants';
import * as uiLib from '@compliance.ai/web-components';

export const BUCKETS_TABLE_INITIAL_PARAMS: uiLib.Params = {
  [uiLib.TABLE_PARAMS.LIMIT]: constants.BUCKETS_PER_PAGE,
  [uiLib.TABLE_PARAMS.OFFSET]: 0,
  [uiLib.TABLE_PARAMS.SORT]: null
};

export const FILTERS_INITIAL_STATE: BucketsFilters = {
  [constants.BUCKETS_FILTER_KEY.CREATED_BY]: null
};

export const INITIAL_SHARE_MODAL_DATA: OrganizationBucketsLocalState['shareModalData'] = {
  isViewAccess: false
};
