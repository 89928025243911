import { createSelector } from '@reduxjs/toolkit';
import { sortByLabels } from '../filters/hooks/useFilterOptions.helpers';
import { alphabeticallyByLabel } from 'utils/sort';

/**
 *
 * Defaults reducer selector
 */
export const getSourcesReducer = state => state.sources;

/**
 * Sources flags selectors
 */
export const getSourcesReadyState = createSelector(getSourcesReducer, sources => {
  return sources.isReady;
});

/**
 *
 * Default topics selector
 */
export const getDefaultTopics = state => getSourcesReducer(state).sources.defaultTopics;
export const getDefaultTopicsObj = createSelector(getDefaultTopics, defaultTopics => {
  return defaultTopics.reduce((obj, topic) => {
    obj[topic.id] = topic;
    return obj;
  }, {});
});

/**
 *
 * Active topics selector
 *
 */
export const getActiveTopics = createSelector(getSourcesReducer, sourcesReducer => {
  return sourcesReducer.sources.activeTopics ?? [];
});
export const getActiveTopicsObj = createSelector(getActiveTopics, activeTopics => {
  return activeTopics.reduce((topics, topic) => {
    return {
      ...topics,
      [topic.id]: topic
    };
  }, {});
});
export const getTopicsOptions = createSelector(getActiveTopics, topics => {
  return topics
    .map(topic => ({
      label: topic.name || topic.label,
      value: topic.id || topic.topic_id
    }))
    .sort(alphabeticallyByLabel);
});
export const getActiveTopicsIdsToNamesMap = createSelector(getActiveTopics, activeTopics => {
  return activeTopics.reduce(
    (topics, topic) => ({
      ...topics,
      [topic.id]: topic.name
    }),
    {}
  );
});
export const getActiveTopicsNamesToIdsMap = createSelector(getActiveTopics, activeTopics => {
  return activeTopics.reduce(
    (topics, topic) => ({
      ...topics,
      [topic.name]: topic.id
    }),
    {}
  );
});
/**
 *
 * Default mainstream news sources selector
 */
export const getDefaultMainstreamNewsSources = state =>
  getSourcesReducer(state).sources.defaultMainstreamNewsSources;

export const getDefaultMainstreamNewsSourcesOptions = createSelector(
  getDefaultMainstreamNewsSources,
  sources => {
    if (Array.isArray(sources)) {
      return sources
        .map(newsSource => {
          if (newsSource.entity) {
            return {
              id: newsSource.entity.id,
              name: newsSource.entity.name,
              label: newsSource.entity.name,
              value: newsSource.entity.id
            };
          }
          return {
            id: newsSource.id,
            name: newsSource.name,
            label: newsSource.name,
            value: newsSource.id
          };
        })
        .sort(sortByLabels);
    }
    return [];
  }
);

export const getDefaultMainstreamNewsSourcesOptionsByKey = createSelector(
  getDefaultMainstreamNewsSourcesOptions,
  (state, valueKey) => valueKey,
  (options, valueKey) => {
    return options.map(option => ({
      ...option,
      value: option[valueKey]
    }));
  }
);

export const getDefaultMainstreamNewsSourcesObj = createSelector(
  getDefaultMainstreamNewsSources,
  defaultMainstreamNewsSources => {
    return defaultMainstreamNewsSources.reduce((obj, newsSource) => {
      obj[newsSource.id] = newsSource;
      return obj;
    }, {});
  }
);
export const getDefaultMainstreamNewsSourcesIdsToNamesMap = createSelector(
  getDefaultMainstreamNewsSources,
  defaultMainstreamNewsSources => {
    return defaultMainstreamNewsSources.reduce((obj, newsSource) => {
      obj[newsSource.id] = newsSource.name;
      return obj;
    }, {});
  }
);

export const getFollowedDefaultMainstreamNewsSources = createSelector(
  getDefaultMainstreamNewsSources,
  defaultMainstreamNewsSources => {
    return defaultMainstreamNewsSources.filter(({ following }) => following);
  }
);
export const getFollowedDefaultMainstreamNewsSourcesIds = createSelector(
  getFollowedDefaultMainstreamNewsSources,
  followedDefaultMainstreamNewsSources => {
    return followedDefaultMainstreamNewsSources.map(({ id }) => id);
  }
);
