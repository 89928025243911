import type * as storeTypes from 'store';
import type * as searchTypes from '../search.types';

import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAutoComplete as reduxFetchAutoComplete } from 'shared/features/search/search.actions';

export const useSearchReduxActions = () => {
  const dispatch = useDispatch<storeTypes.StoreDispatch>();

  const fetchAutoComplete = useCallback(
    (value: string, category?: string): searchTypes.AutocompleteResponse => {
      return (dispatch(
        reduxFetchAutoComplete(value, category)
      ) as unknown) as searchTypes.AutocompleteResponse;
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      fetchAutoComplete
    }),
    [fetchAutoComplete]
  );
};
