import { doAnalytics, removeComponentNames, sanitizeLayout } from '../Dashboard.helpers';
import {
  ADD_COMPONENT_ACTION,
  MOVE_COMPONENT_ACTION,
  REMOVE_COMPONENT_ACTION
} from '../Dashboard.constants';
import auth from 'utils/auth';
import { useCallback } from 'react';
import lodash from 'lodash';

export const useDashboardLayoutHandlers = ({
  props: { dashboardType },
  localState,
  localActions,
  reduxActions
}) => {
  /**
   *
   * Selects component (widget) and calls analytics endpoint.
   * Since we render components (widgets) by mapping through component names, there is
   * no need to update the layout. It will be updated by the call of handleLayoutChange
   */
  const handleComponentsSelect = useCallback(
    selectedComponentsNames => {
      selectedComponentsNames.forEach(selectedComponentName => {
        doAnalytics({
          dashboardType: dashboardType,
          action: ADD_COMPONENT_ACTION,
          widgetName: selectedComponentName
        });
      });

      const updatedLayoutComponents = [...localState.components, ...selectedComponentsNames];
      localActions.setLayoutComponents(updatedLayoutComponents);
    },
    [dashboardType, localActions, localState.components]
  );

  /**
   *
   * Deselects component (widget) and calls analytics endpoint
   * Since we render components (widgets) by mapping through component names, there is
   * no need to update the layout. It will be updated by the call of handleLayoutChange
   */
  const handleComponentsDeselect = useCallback(
    deselectedComponentsNames => {
      deselectedComponentsNames.forEach(componentName => {
        doAnalytics({
          dashboardType: dashboardType,
          action: REMOVE_COMPONENT_ACTION,
          widgetName: componentName
        });
      });
      const updatedLayoutComponents = removeComponentNames({
        components: localState.components,
        deselectedComponentNames: deselectedComponentsNames
      });
      localActions.setLayoutComponents(updatedLayoutComponents);
    },
    [dashboardType, localActions, localState.components]
  );

  /**
   *
   * This function performs an update action of the selected components (widgets).
   *
   */
  const handleSelectedComponentsChange = useCallback(
    selectedComponentName => {
      const isAlreadySelected = localState.components.includes(selectedComponentName);

      if (isAlreadySelected) {
        handleComponentsDeselect([selectedComponentName]);
      } else {
        handleComponentsSelect([selectedComponentName]);
      }
    },
    [handleComponentsDeselect, handleComponentsSelect, localState.components]
  );

  /**
   * This function gets triggered by react-grid-layout on every change of layout
   * Since any changed of selected components (widgets) causes rerender of widgets, only this function
   * should update layout in state
   */
  const handleLayoutChange = useCallback(
    (visibleLayout, layout) => {
      const sanitizedLayout = sanitizeLayout(layout);

      if (auth.loggedIn() && !lodash.isEqual(sanitizedLayout, localState.layout)) {
        reduxActions.updateDashboardLayout({
          components: localState.components,
          layout: sanitizedLayout,
          dashboard_type: dashboardType
        });
        localActions.setLayout(sanitizedLayout);
      }
    },
    [dashboardType, localActions, localState.components, localState.layout, reduxActions]
  );

  /**
   * This function gets triggered by react-grid-layout on every drag stop event (or mouse up)
   */
  const handleDragStop = useCallback(
    (visibleLayout, widget) => {
      doAnalytics({
        dashboardType,
        action: MOVE_COMPONENT_ACTION,
        widgetName: widget.i
      });
    },
    [dashboardType]
  );

  return {
    handleComponentsDeselect,
    handleComponentsSelect,
    handleSelectedComponentsChange,
    handleLayoutChange,
    handleDragStop
  };
};
