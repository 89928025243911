import type { PrintProps } from './Print.types';
import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';

import { usePrintData, usePrintHandlers, usePrintQueries, usePrintQueriesHandlers } from './hooks';

export const Print: FC<PrintProps> = ({
  documentIds,
  enabled,
  queryOptions,
  onActionStart,
  actionButtonType
}) => {
  const { localState, localActions } = usePrintData();

  const queryHandlers = usePrintQueriesHandlers({
    localActions
  });

  const queries = usePrintQueries({
    props: {
      documentIds,
      queryOptions
    },
    events: {
      onDocumentsFetchSuccess: queryHandlers.handleDocumentsFetchSuccess
    }
  });

  const handlers = usePrintHandlers({
    props: {
      onActionStart
    },
    queries
  });

  return (
    <uiLib.ActionButton
      isDisabled={!enabled || !documentIds?.length}
      isLoading={queries.fetchDocuments.isFetching || localState.isPrinting}
      onClick={handlers.handlePrint}
      onRestrictedClick={handlers.handleRestrictModalOpen}
      tooltip="Print"
      tooltipPlacement={uiLib.TOOLTIP_POSITION.BOTTOM}
      type={actionButtonType}
    >
      <uiLib.IconPrint />
    </uiLib.ActionButton>
  );
};

Print.defaultProps = {
  enabled: true,
  documentIds: []
};
