import type { ProgressiveSentenceNotesHandlers } from './useProgressiveSentenceNotesHandlers.types';

import type { useProgressiveSentenceNotesQueries } from './useProgressiveSentenceNotesQueries';

import { useCallback } from 'react';

export const useProgressiveSentenceNotesHandlers = ({
  queries
}: {
  queries: ReturnType<typeof useProgressiveSentenceNotesQueries>;
}): ProgressiveSentenceNotesHandlers => {
  const handleNextSentenceNotesFetch: ProgressiveSentenceNotesHandlers['handleNextSentenceNotesFetch'] = useCallback(() => {
    if (!queries.fetchSentenceNotes.isFetching && queries.fetchSentenceNotes.hasNextPage) {
      queries.fetchSentenceNotes.fetchNextPage();
    }
  }, [queries.fetchSentenceNotes]);

  return {
    handleNextSentenceNotesFetch
  };
};
