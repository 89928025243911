import type * as eitlLabelsTypes from 'shared/features/eitlLabels/eitlLabels.types';

import * as constants from './EitlLabelsSelect.constants';

export const addNoEitlLabelsOption = ({
  options,
  shouldShowNoLabelsOption
}: {
  options: eitlLabelsTypes.EitlLabelOption[];
  shouldShowNoLabelsOption: boolean;
}) => {
  return shouldShowNoLabelsOption ? [constants.EITL_LABELS_NO_LABELS_OPTION, ...options] : options;
};
