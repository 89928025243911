import type { FiltersProps } from '@compliance.ai/web-components';
import type { AlertsTableFiltersReduxState } from './useAlertsTableFiltersData.types';

import { useMemo } from 'react';
import { FILTER_TYPES } from '@compliance.ai/web-components';
import { FILTER_KEY } from '../AlertsTableFilters.constants';

export const useAlertsTableFiltersConfig = ({
  reduxState
}: {
  reduxState: AlertsTableFiltersReduxState;
}): FiltersProps['config'] =>
  useMemo((): FiltersProps['config'] => {
    return [
      {
        key: FILTER_KEY.CREATED_BY,
        type: FILTER_TYPES.SELECT,
        label: 'Created By',
        options: reduxState.organizationMemberOptions,
        isFixed: true
      },
      {
        key: FILTER_KEY.UPDATED_FROM,
        type: FILTER_TYPES.DATE,
        label: 'Updated From'
      },
      {
        key: FILTER_KEY.UPDATED_TO,
        type: FILTER_TYPES.DATE,
        label: 'Updated To'
      }
    ];
  }, [reduxState]);
