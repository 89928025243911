import type * as actionsTypes from './authors.actions.type';

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import * as errorUtils from 'utils/errors';
import * as api from './authors.api';

export const FETCH_AUTHORS = 'FETCH_AUTHORS';
export const SET_AUTHORS = 'SET_AUTHORS';

export const setAuthors: actionsTypes.SetAuthors = createAction(SET_AUTHORS);

export const fetchAuthors = createAsyncThunk<
  actionsTypes.FetchAuthorsPayload,
  actionsTypes.FetchAuthorsReturn
>(FETCH_AUTHORS, async (_, { dispatch }) => {
  try {
    const response = await api.fetchAuthors();
    dispatch(setAuthors(response));
    return response;
  } catch (e) {
    errorUtils.logError(e as Error, 'Error', { isSilent: true });
    throw e;
  }
});
