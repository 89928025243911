import PropTypes from 'prop-types';
import CheckIcon from '@material-ui/icons/Check';
import smoothscroll from 'smoothscroll-polyfill';
import './_labelOptionList.scss';

smoothscroll.polyfill();

export const TEST_ID = {
  LIST_LABEL: 'label-option-list-label',
  CHECK_ICON: 'label-option-list-check-icon',
  CHECKED_LABEL_ITEM: 'label-option-list-checked-label-item',
  UNCHECKED_LABEL_ITEM: 'label-option-list-unchecked-label-item'
};

function LabelOptionsList({
  labelOptions,
  label,
  checkedState,
  handleOptionClick,
  handleLabelSelect,
  labelCheckedState
}) {
  let allChecked = false;

  function formatLabelOptionsListItems() {
    const LabelOptionsListItems = [];
    let numberOfSelectedValue = 0;

    labelOptions.forEach(option => {
      if (checkedState[option.value]) {
        LabelOptionsListItems.push(
          <div
            data-testid={TEST_ID.CHECKED_LABEL_ITEM}
            key={option.label}
            value={option.value}
            onClick={() => handleOptionClick(option.label, option.value)}
            className="label-option-list-item"
          >
            <CheckIcon className="label-option-list-checkmark">check</CheckIcon>
            <div>{option.label}</div>
          </div>
        );

        numberOfSelectedValue += 1;
      } else {
        LabelOptionsListItems.push(
          <div
            data-testid={TEST_ID.UNCHECKED_LABEL_ITEM}
            key={option.label}
            value={option.value}
            onClick={() => handleOptionClick(option.label, option.value)}
            className="label-option-list-item"
          >
            <div className="label-option-list-item-checkmark-spacer" />
            <div>{option.label}</div>
          </div>
        );

        if (allChecked) {
          allChecked = false;
        }
      }
    });

    if (numberOfSelectedValue === LabelOptionsListItems.length) allChecked = true;
    return LabelOptionsListItems;
  }
  return (
    <section id={'right-' + label}>
      <div
        data-testid={TEST_ID.LIST_LABEL}
        onClick={() => handleLabelSelect(label)}
        className="label-option-list-label"
      >
        {labelCheckedState ? (
          <CheckIcon data-testid={TEST_ID.CHECK_ICON} className="label-option-list-checkmark">
            check
          </CheckIcon>
        ) : (
          <div className="label-option-list-label-checkmark-spacer" />
        )}
        <div>{label}</div>
      </div>
      <div>{formatLabelOptionsListItems()}</div>
    </section>
  );
}

LabelOptionsList.propTypes = {
  labelOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  label: PropTypes.string.isRequired,
  checkedState: PropTypes.objectOf(PropTypes.bool).isRequired,
  handleOptionClick: PropTypes.func.isRequired,
  labelCheckedState: PropTypes.bool.isRequired,
  handleLabelSelect: PropTypes.func.isRequired
};

export default LabelOptionsList;
