import type {
  WorkflowTaskDropdownFromResponse,
  WorkflowTaskDropdownOptionFromResponse
} from 'shared/features/workflow/workflow.types';
import type { ComponentProps } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as workflowHelpers from 'shared/features/workflow/workflow.helpers';

/**
 *
 * Unflatten dropdown options into Select's format
 *
 */
export const formatOptions = ({
  currentLevelDropdownOptions,
  allDropdownOptions,
  parentId = null
}: {
  currentLevelDropdownOptions: WorkflowTaskDropdownFromResponse['dropdown_options'];
  allDropdownOptions: WorkflowTaskDropdownFromResponse['dropdown_options'];
  parentId?: WorkflowTaskDropdownOptionFromResponse['parent_id'];
}): uiLib.SelectOptions => {
  if (!Array.isArray(currentLevelDropdownOptions)) {
    return [];
  }

  return currentLevelDropdownOptions.flatMap(dropdownOption => {
    if (dropdownOption.parent_id !== parentId) {
      return [];
    }

    const children = allDropdownOptions.filter(o => o.parent_id === dropdownOption.id);

    if (children.length) {
      return {
        label: dropdownOption.dropdown_text,
        options: formatOptions({
          currentLevelDropdownOptions: children,
          allDropdownOptions: allDropdownOptions,
          parentId: dropdownOption.id
        })
      };
    }

    return {
      value: dropdownOption.id,
      label: dropdownOption.dropdown_text
    };
  });
};

export const formatSelectedOptionsString = (
  dropdownOptions: WorkflowTaskDropdownFromResponse['dropdown_options']
) => {
  return workflowHelpers.formatNestedSelectedDropdownOptionLabels(dropdownOptions).join(', ');
};

export const formatMultiSelectValue = (
  dropdownOptions: WorkflowTaskDropdownFromResponse['dropdown_options']
): Required<ComponentProps<typeof uiLib.Select>>['value'] => {
  return dropdownOptions.flatMap(dropdownOption => {
    if (dropdownOption.selected) {
      return [
        {
          value: dropdownOption.id,
          label: dropdownOption.dropdown_text
        }
      ];
    }

    return [];
  });
};

export const formatSingleSelectValue = (
  dropdownOptions: WorkflowTaskDropdownFromResponse['dropdown_options']
): Required<ComponentProps<typeof uiLib.Select>>['value'] => {
  const selectedDropdownOption = dropdownOptions.find(dropdownOption => dropdownOption.selected);

  if (!selectedDropdownOption) {
    return null;
  }

  return {
    value: selectedDropdownOption.id,
    label: selectedDropdownOption.dropdown_text
  };
};
