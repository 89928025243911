import styled from 'styled-components';

import PropTypes from 'prop-types';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';

const StyledFormControl = styled(props => <FormControl {...props} />)`
  width: 100%;
  .MuiFormLabel-root {
    font-size: 11px;
    line-height: 36px;
    color: #4a4a4a;
    margin-top: -7px;

    &.MuiInputLabel-shrink {
      margin-top: -7px;
    }

    &:not(.MuiInputLabel-shrink) {
      padding-left: 10px;
      font-size: 14px;
    }
  }
  .MuiInput-root {
    position: relative;
    top: -2px;
    border: solid 1px #cccccc;

    &:hover:before,
    &:hover:after,
    &:before,
    &:after {
      border: none;
    }
  }
  .MuiSelect-icon {
    font-size: 20px;
    right: 10px;
    top: 8px;
  }
  .MuiSelect-root {
    height: 36px;
    text-align: left;
    padding-top: 0px;
    padding-bottom: 0px;

    &.Mui-disabled {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &.MuiInput-input {
      font-size: 14px;
      line-height: 36px;
      padding-left: 10px;
      &:focus {
        background-color: inherit;
      }
    }
  }
`;

const StyledMenuItem = styled(props => <MenuItem {...props} />)`
  font-size: 14px;
`;

function SelectSharedComponent({
  value,
  onChange,
  disabled,
  label,
  placeholder,
  options,
  clearable
}) {
  return (
    <StyledFormControl>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        value={value}
        disabled={disabled}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
      >
        {clearable && <StyledMenuItem value="">None</StyledMenuItem>}
        {options.map(({ value: optionValue, label: optionLabel }) => (
          <StyledMenuItem key={optionValue} value={optionValue}>
            {optionLabel}
          </StyledMenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
}

SelectSharedComponent.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
    })
  ).isRequired,
  clearable: PropTypes.bool
};

SelectSharedComponent.defaultProps = {
  value: '',
  label: '',
  placeholder: null,
  onChange: null,
  disabled: false,
  clearable: true
};

export default SelectSharedComponent;
