import type { FC } from 'react';

import * as constants from './Router.constants';
import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';
import * as store from '../store';
import * as muiStyles from '@material-ui/styles';
import * as muiConstants from '../constants/Mui';
import * as config from './Router.config';

import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { useRouterHandlers } from './hooks';

const router = createBrowserRouter(config.ALL_ROUTES_CONFIG);

export const Router: FC = () => {
  const handlers = useRouterHandlers();

  return (
    <elements.ReactQueryProvider>
      <Provider store={store.store}>
        <muiStyles.StylesProvider
          injectFirst
          generateClassName={muiConstants.MUI_CLASSNAMES_GENERATOR}
        >
          <uiLib.ErrorBoundaryProvider onError={handlers.handleError}>
            <uiLib.ThemeProvider theme={uiLib.UI_THEMES.COMPLIANCE}>
              <uiLib.RouterBlockerProvider>
                <uiLib.DatePickerProvider>
                  <uiLib.TabsProvider>
                    <uiLib.AlertNotificationsProvider>
                      <uiLib.DraggableListProvider>
                        <uiLib.AppNavigationContextProvider
                          initialState={constants.APP_NAVIGATION_INITIAL_STATE}
                        >
                          <RouterProvider router={router} />
                        </uiLib.AppNavigationContextProvider>
                      </uiLib.DraggableListProvider>
                    </uiLib.AlertNotificationsProvider>
                  </uiLib.TabsProvider>
                </uiLib.DatePickerProvider>
              </uiLib.RouterBlockerProvider>
            </uiLib.ThemeProvider>
          </uiLib.ErrorBoundaryProvider>
        </muiStyles.StylesProvider>
      </Provider>
    </elements.ReactQueryProvider>
  );
};
