import type { DocketsProps } from './Dockets.types';

import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';
import { useDocketsHandlers } from './hooks';
import { useResourceDocumentCheck } from 'utils/hooks';

export const Dockets = ({ document, shouldRenderForEmail }: DocketsProps) => {
  const isResourceDocument = useResourceDocumentCheck(document);

  const handlers = useDocketsHandlers();

  if (isResourceDocument || shouldRenderForEmail || !document.dockets?.length) {
    return null;
  }

  return (
    <>
      <FieldName>Docket ID</FieldName>
      <FieldValue>
        {document.dockets.map((docket, i) => (
          <span key={i}>
            <Button
              type={BUTTON_TYPES.LINK_CONTRAST}
              onClick={handlers.handleDocketClick(docket.docket_id)}
            >
              {docket.docket_id}
            </Button>
            <span>{i !== document.dockets.length - 1 && ', '}</span>
          </span>
        ))}
      </FieldValue>
    </>
  );
};
