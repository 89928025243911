import type * as reducersType from '../../reducers';
import type * as selectorsTypes from './authors.selectors.types';
import type * as reducerTypes from './authors.reducer.types';
import type * as authorsApiTypes from './authors.api.types';
import type * as authorsSelectorTypes from './authors.selectors.types';

import { REDUCER_NAME } from './authors.constants';
import { createSelector } from '@reduxjs/toolkit';
import { alphabeticallyByLabel } from 'utils/sort';

export const getAuthorsReducer = (state: reducersType.GlobalState): reducerTypes.AuthorsState =>
  state[REDUCER_NAME];

export const getAuthorsReadyState = createSelector(
  getAuthorsReducer,
  (authorsReducer): reducerTypes.AuthorsState['isReady'] => {
    return authorsReducer.isReady;
  }
);
export const getAuthors = createSelector(
  getAuthorsReducer,
  (authorsReducer): reducerTypes.AuthorsState['items'] => {
    return authorsReducer.items;
  }
);
export const getAuthorsIdsToNamesMap = createSelector(
  getAuthors,
  (authors): Record<authorsApiTypes.AuthorListItem['id'], authorsApiTypes.AuthorListItem> => {
    return authors.reduce((authors, author) => {
      return {
        ...authors,
        [author.id]: author.name
      };
    }, {});
  }
);
export const getAuthorsNamesToIdsMap = createSelector(
  getAuthors,
  (authors): Record<authorsApiTypes.AuthorListItem['id'], authorsApiTypes.AuthorListItem> => {
    return authors.reduce((authors, author) => {
      return {
        ...authors,
        [author.name]: author.id
      };
    }, {});
  }
);
export const getAuthorsOptions = createSelector(
  getAuthors,
  (authors): selectorsTypes.AuthorOption[] => {
    return authors
      .filter(author => author.name)
      .map(author => ({
        id: author.id,
        name: author.name,
        value: author.id,
        label: author.name
      }))
      .sort(alphabeticallyByLabel);
  }
);

export const getAuthorsOptionsByKey = createSelector(
  [getAuthorsOptions, (state, valueKey) => valueKey],
  (options, valueKey) => {
    return options.map(option => {
      return {
        ...option,
        value: option[valueKey]
      } as authorsSelectorTypes.AuthorOption;
    });
  }
);

export const getAuthorsOptionsForAdvancedSearch = createSelector(
  getAuthors,
  (authors): selectorsTypes.AuthorOptionForAdvancedSearch[] => {
    return authors
      .filter(author => author.name)
      .map(author => ({
        value: author.name,
        label: author.name
      }))
      .sort(alphabeticallyByLabel);
  }
);
