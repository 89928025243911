import { getCategoryNameByID } from 'shared/features/documents/documents.selectors';

const countDocsByCategories = (categories, documentsGroupedByCategories) => {
  if (typeof documentsGroupedByCategories === 'object') {
    return Object.entries(documentsGroupedByCategories).reduce(
      (docCountByCategories, [categoryId, docs]) => {
        const categoryName = getCategoryNameByID(categories, categoryId);

        return {
          ...docCountByCategories,
          [categoryName]: docs.length
        };
      },
      {}
    );
  }
  return {};
};

export default {
  countDocsByCategories
};
