import type { ComponentProps } from 'react';
import type { RegulationProps } from './Regulation.types';

import {
  WIDGET_PROPERTIES_KEYS,
  WIDGET_REGULATION_TYPES
} from 'shared/features/widgets/widgets.constants';
import { RegulationsSelect } from '../../../../../../../RegulationsSelect';
import { ActsSelect } from '../../../../../../../ActsSelect';
import { useRegulationData, useRegulationHandlers, useRegulationLifecycle } from './hooks';

const COMPONENTS_CONFIG = {
  [WIDGET_REGULATION_TYPES.REGULATION]: {
    Component: RegulationsSelect,
    valueFormatter: (
      value: RegulationProps['value']
    ): ComponentProps<typeof RegulationsSelect>['value'] => {
      return value?.value;
    }
  },
  [WIDGET_REGULATION_TYPES.ACT]: {
    Component: ActsSelect,
    valueFormatter: (
      value: RegulationProps['value']
    ): ComponentProps<typeof ActsSelect>['value'] => {
      return value;
    }
  }
};

export const Regulation = (props: RegulationProps) => {
  const { prevProps } = useRegulationData(props);

  const config = COMPONENTS_CONFIG[props.properties[WIDGET_PROPERTIES_KEYS.REGULATION_TYPE]];

  const handlers = useRegulationHandlers({
    props,
    prevProps
  });

  useRegulationLifecycle({
    onRegulationTypeChange: handlers.handleRegulationTypeChange
  });

  if (!config) {
    return null;
  }

  return (
    <config.Component
      {...props}
      value={config.valueFormatter(props.value) as never}
      onChange={
        props.onChange as ComponentProps<
          typeof COMPONENTS_CONFIG[keyof typeof COMPONENTS_CONFIG]['Component']
        >['onChange']
      }
    />
  );
};
