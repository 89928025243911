import {
  REQUEST_AUTO_COMPLETE,
  RECEIVE_AUTO_COMPLETE,
  CLEAR_AUTO_COMPLETE
} from './search.actions';

export const getInitialAutocompletesState = () => ({
  isFetching: false,
  isReady: false,
  items: {},
  currentAutoComplete: null
});

export const autocompletes = (state = getInitialAutocompletesState(), action) => {
  switch (action.type) {
    case REQUEST_AUTO_COMPLETE:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_AUTO_COMPLETE:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.autocompletes,
        currentAutoComplete: action.currentAutoComplete
      };
    case CLEAR_AUTO_COMPLETE:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: {}
      };
    default:
      return state;
  }
};
