export const useListHandlers = ({ props }) => {
  const handleOptionClick = option => () => {
    if (!props.isMulti) {
      return props.onOptionClick(option);
    }

    const isOptionSelected = props.selectedOptions.some(({ value }) => value === option.value);

    if (isOptionSelected) {
      props.onOptionClick(props.selectedOptions.filter(({ value }) => value !== option.value));
    } else {
      props.onOptionClick([...props.selectedOptions, option]);
    }
  };

  return {
    handleOptionClick
  };
};
