import * as routes from 'constants/Routes';

export const REDUCER_NAME = 'rightPanel';

export enum RIGHT_PANEL_TYPES {
  DOCUMENT_DETAILS = 'documentDetails',
  WORKFLOW_TASK_DETAILS = 'workflowTaskDetails',
  SEARCH_RESULTS = 'searchResults'
}

export enum RIGHT_PANEL_QUERY_PARAMS {
  RIGHT_PANEL_TYPE = 'right_panel_type',
  SUMMARY_ID = 'summary_id',
  SUMMARY_PAGE = 'summary_page',
  CLOSE_RIGHT_PANEL_ON_OVERLAY_CLOSE = 'close_right_panel_on_overlay_close',
  DOC_ID_V1 = 'doc_id_v1',
  DOC_ID_V2 = 'doc_id_v2',
  IS_CLOSABLE = 'is_right_panel_closable',
  SENTENCE_NOTE_ID = 'sentence_note_id'
}

export enum OVERLAY_TYPES {
  PDF_OVERLAY = 'pdf-overlay',
  PDF = 'pdf',
  DIFF = 'diff',
  HTML = 'html'
}

export enum OVERLAY_QUERY_PARAMS {
  OVERLAY_TYPE = 'overlay'
}

export enum DOCUMENT_DETAILS_TAB_KEY {
  DETAILS = 'details',
  DOCKET_TIMELINE = 'timeline',
  VERSIONS = 'versions',
  EXPERT_CONNECTION = 'expertConnection',
  RELATED_DOCUMENTS = 'related_documents',
  AUTO_SUMMARY = 'auto_summary',
  TASKS = 'tasks',
  ANNOTATIONS = 'annotations',
  OBLIGATIONS = 'obligations',
  DIFF = 'diff'
}

export const DOCUMENT_DETAILS_TAB_QUERY_PARAM_VALUES: Record<DOCUMENT_DETAILS_TAB_KEY, string> = {
  [DOCUMENT_DETAILS_TAB_KEY.OBLIGATIONS]: 'summary-obligations',
  [DOCUMENT_DETAILS_TAB_KEY.ANNOTATIONS]: 'summary-annotations',
  [DOCUMENT_DETAILS_TAB_KEY.DOCKET_TIMELINE]: 'summary-timeline',
  [DOCUMENT_DETAILS_TAB_KEY.VERSIONS]: 'document-versions',
  [DOCUMENT_DETAILS_TAB_KEY.DIFF]: 'summary-diff',
  [DOCUMENT_DETAILS_TAB_KEY.RELATED_DOCUMENTS]: 'summary-related-documents',
  [DOCUMENT_DETAILS_TAB_KEY.TASKS]: 'summary-tasks',
  [DOCUMENT_DETAILS_TAB_KEY.AUTO_SUMMARY]: 'summary-autosummary',
  [DOCUMENT_DETAILS_TAB_KEY.EXPERT_CONNECTION]: 'summary-expert-connection',
  [DOCUMENT_DETAILS_TAB_KEY.DETAILS]: 'summary'
};

export const DOCUMENT_DETAILS_TAB_QUERY_PARAM_VALUES_TO_TAB_KEYS: Record<
  string,
  DOCUMENT_DETAILS_TAB_KEY
> = Object.fromEntries(
  Object.entries(DOCUMENT_DETAILS_TAB_QUERY_PARAM_VALUES).map(([tabKey, queryParamValue]) => [
    queryParamValue,
    tabKey
  ])
) as Record<string, DOCUMENT_DETAILS_TAB_KEY>;

export const ROUTES_WITH_PREVIOUS_RIGHT_PANEL = [routes.resources];

export const ROUTES_WITH_SEARCH_RESULTS = [routes.content, routes.enforcementExplorer];
