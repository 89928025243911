import type { DocumentTaskFromResponse } from 'shared/features/workflow/workflow.types';

import { userBelongsToTeam } from 'components/Restrict';
import { APPROVAL_ANNOTATION_STATUSES } from 'shared/features/workflow/workflow.constants';

export const isInputDisabled = ({
  documentTask,
  isPotentialApprover,
  isApprovalAnnotationAvailable,
  approvalAnnotationStatus,
  currentUserReducer
}: {
  documentTask: DocumentTaskFromResponse;
  isPotentialApprover: boolean;
  isApprovalAnnotationAvailable: boolean;
  approvalAnnotationStatus: APPROVAL_ANNOTATION_STATUSES;
  currentUserReducer: unknown;
}): boolean => {
  if (
    !userBelongsToTeam({
      current_user: currentUserReducer,
      teams: documentTask.teams
    })
  ) {
    return true;
  }

  if (isApprovalAnnotationAvailable) {
    return (
      approvalAnnotationStatus === APPROVAL_ANNOTATION_STATUSES.APPROVED ||
      (approvalAnnotationStatus === APPROVAL_ANNOTATION_STATUSES.PENDING_APPROVAL &&
        !isPotentialApprover)
    );
  }

  return false;
};
