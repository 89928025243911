import type { SelectWidget, SelectWidgetProps, Type } from 'react-awesome-query-builder';
import type { Factory, FC } from 'react';
import type { SelectProps } from '@compliance.ai/web-components';

import * as queryBuilder from 'react-awesome-query-builder';
import * as selectUtils from 'utils/select-utils';

import { SELECT_TYPES } from '@compliance.ai/web-components';
import { QUERY_INPUT_TYPES, QUERY_OPERATORS } from 'constants/AdvancedSearch';
import { SELECT_DATA_TYPES } from './Query.config';

const handleSelectChange = ({
  isAlwaysSingle,
  setValue
}: {
  isAlwaysSingle?: boolean;
  setValue: SelectWidgetProps['setValue'];
}): SelectProps['onChange'] => value => {
  const values = selectUtils.extractValues(value);

  if (isAlwaysSingle) {
    // the "awesome" query library requires single values to always be strings
    return setValue(String(values));
  }

  if (!values) {
    setValue([]);
  } else {
    setValue(!Array.isArray(values) ? [values] : values);
  }
};

const formatValueForTextField = (value: queryBuilder.RuleValue): unknown[] => {
  if (!value) {
    return [];
  }

  return (Array.isArray(value) ? value : [value]).map(v =>
    typeof v === 'object' ? `"${v.value}"` : `"${v}"`
  );
};

const formatValueForSelect = ({
  value,
  dataType
}: {
  value: unknown;
  dataType: SELECT_DATA_TYPES;
}) => {
  if (!value) {
    return [];
  }

  return (Array.isArray(value) ? value : [value]).map(v =>
    ({
      [SELECT_DATA_TYPES.NUMBER]: Number,
      [SELECT_DATA_TYPES.STRING]: String
    }[dataType](v?.value ?? v))
  );
};

export const getSelectWidgetConfig = (
  // Suppressed due to multiple possible types. Unknown type would not work
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: FC<Omit<SelectProps, 'value' | 'type'> & { value: any; type?: SELECT_TYPES }>,
  config?: {
    isAlwaysSingle?: boolean;
    dataType?: SELECT_DATA_TYPES;
  }
): SelectWidget => {
  return {
    ...(Boolean(config?.isAlwaysSingle)
      ? queryBuilder.BasicConfig.widgets.select
      : queryBuilder.BasicConfig.widgets.multiselect),
    formatValue: (formatValueForTextField as unknown) as SelectWidget['formatValue'],
    factory: (({ value, fieldDefinition, setValue }: SelectWidgetProps) => {
      return (
        <Component
          type={SELECT_TYPES.DEFAULT}
          value={formatValueForSelect({
            dataType: config?.dataType ?? SELECT_DATA_TYPES.STRING,
            value
          })}
          onChange={handleSelectChange({
            isAlwaysSingle: config?.isAlwaysSingle,
            setValue
          })}
          {...fieldDefinition?.fieldSettings}
        />
      );
    }) as Factory<SelectWidgetProps>
  };
};

export const getSelectTypeConfig = (
  inputType: QUERY_INPUT_TYPES,
  operators: QUERY_OPERATORS[] = [
    QUERY_OPERATORS.MULTISELECT_EQUALS,
    QUERY_OPERATORS.MULTISELECT_NOT_EQUALS
  ]
): Type => {
  return {
    ...queryBuilder.BasicConfig.types.multiselect,
    defaultOperator: QUERY_OPERATORS.MULTISELECT_EQUALS,
    widgets: {
      [inputType]: {
        widgetProps: {},
        operators: operators
      }
    }
  };
};
