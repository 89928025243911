import PropTypes from 'prop-types';
import { LabeledField } from 'common';
import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import { TextField } from '@compliance.ai/web-components';
import { usePasswordResetData, usePasswordResetHandlers } from './hooks';
import './_password-reset.scss';

export const PasswordReset = ({ onSubmit }) => {
  const { localState, localActions } = usePasswordResetData();

  const { handleSubmit } = usePasswordResetHandlers({
    props: {
      onSubmit
    },
    localState
  });

  return (
    <>
      <LabeledField label="Email" className="password-reset__input">
        <TextField
          type="email"
          value={localState.passwordResetEmail}
          onChange={localActions.setPasswordResetEmail}
          placeholder="me@example.com"
          required
          autoFocus
        />
      </LabeledField>
      <Button
        type={BUTTON_TYPES.PRIMARY}
        className="password-reset__button"
        onClick={handleSubmit}
        isDisabled={!localState.passwordResetEmail}
      >
        Submit
      </Button>
    </>
  );
};

PasswordReset.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
