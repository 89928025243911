import type { FirstLoginJoyrideData } from './useFirstLoginExperienceData.types';

import * as routeConstants from 'constants/Routes';

export const checkConditions = ({
  localState,
  reduxState
}: {
  localState: FirstLoginJoyrideData['localState'];
  reduxState: FirstLoginJoyrideData['reduxState'];
}) => {
  if (!localState.isInitialized || !reduxState.isFirstLogin) {
    return false;
  }

  if (localState.hasManageOrgProfilePermission) {
    if (reduxState.isOrgProfileSaved || localState.hasBeenRedirectedToAdmin) {
      if (location.pathname === routeConstants.admin) {
        return false;
      }
      return true;
    }
    return false;
  }
  return true;
};
