import queryString from 'utils/query-string';
import { content, sources } from 'constants/Routes';
import { safe_mixpanel_track } from 'utils/analytics';
import { sendGoogleAnalyticsPageView } from 'utils/analytics';

export const doAnalytics = ({ pathname, search }) => {
  const parsedQuery = queryString.parse(search);

  // special handling for /content pageview hits in Content.js
  if (pathname !== content) {
    sendGoogleAnalyticsPageView({
      path: pathname
    });

    // annoying hack to add view (federal, state, topics, mainstream_news)
    // to pathname when on /sources page
    const mxp_pathname =
      pathname === sources ? `${pathname} (${parsedQuery.view || 'federal'})` : pathname;

    safe_mixpanel_track('Pageview: ' + mxp_pathname, {
      hitType: 'pageview',
      page: mxp_pathname,
      campaign_token: parsedQuery.token
    });
  }
};
