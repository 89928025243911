import type { FiltersProps } from '@compliance.ai/web-components';
import type { useFilterDefaultOptions, useFilterOptions } from 'shared/features/filters/hooks';
import type { useDocumentsFiltersAsyncLoaders } from './useDocumentsFiltersAsyncLoaders';
import type { useDocumentsFiltersTooltipRenderers } from './useDocumentsFiltersTooltipRenderers';

import * as constants from 'constants/PrimaryFilter';
import * as authorizationUtils from 'utils/authorization';
import * as uiLib from '@compliance.ai/web-components';
import * as env from 'shared/config';

import { useMemo } from 'react';

export const useDocumentsFiltersConfig = ({
  filterOptions,
  filterOptionsAsyncLoaders,
  filterDefaultOptions,
  filterTooltipsRenderers
}: {
  filterOptions: ReturnType<typeof useFilterOptions>;
  filterOptionsAsyncLoaders: ReturnType<typeof useDocumentsFiltersAsyncLoaders>;
  filterDefaultOptions: ReturnType<typeof useFilterDefaultOptions>;
  filterTooltipsRenderers: ReturnType<typeof useDocumentsFiltersTooltipRenderers>;
}): FiltersProps['config'] => {
  const basicFilters = useMemo((): FiltersProps['config'] => {
    return [
      {
        key: constants.FILTER_KEY.PUB_DATE,
        type: uiLib.FILTER_TYPES.DATE_WITH_MODIFIERS,
        label: 'Published Date',
        isFixed: true,
        inputComponentProps: {
          shouldRenderSecondaryCheckbox: true,
          secondaryCheckboxLabel: 'Exclude documents with no official publication date'
        }
      },
      {
        key: constants.FILTER_KEY.EFFECTIVE_DATE,
        type: uiLib.FILTER_TYPES.DATE_WITH_MODIFIERS,
        label: 'Effective Date'
      },
      {
        key: constants.FILTER_KEY.COMMENTS_CLOSE_DATE,
        type: uiLib.FILTER_TYPES.DATE_WITH_MODIFIERS,
        label: 'Comments Close Date'
      },
      {
        key: constants.FILTER_KEY.ADDED_DATE,
        type: uiLib.FILTER_TYPES.DATE_WITH_MODIFIERS,
        label: 'Added to Compliance.ai'
      },
      {
        key: constants.FILTER_KEY.UPDATED_DATE,
        type: uiLib.FILTER_TYPES.DATE_WITH_MODIFIERS,
        label: 'Updated in Compliance.ai'
      },
      {
        key: constants.FILTER_KEY.TEXT,
        type: uiLib.FILTER_TYPES.TEXT_WITH_MODIFIERS,
        label: 'Text',
        placeholder: 'Type Text here...'
      },
      {
        key: constants.FILTER_KEY.BANKS,
        type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
        onOptionsLoad: filterOptionsAsyncLoaders[constants.FILTER_KEY.BANKS],
        label: 'Business',
        inputComponentProps: {
          withSelectAllButton: false
        }
      },
      {
        key: constants.FILTER_KEY.AGENCIES,
        type: uiLib.FILTER_TYPES.MULTI_SELECT,
        label: 'Agency',
        isFixed: true,
        options: filterOptions[constants.FILTER_KEY.AGENCIES],
        defaultOptions: filterDefaultOptions[constants.FILTER_KEY.AGENCIES],
        inputComponentProps: {
          withTooltips: true,
          withSelectAllButton: false,
          tooltipTitleRenderer: filterTooltipsRenderers[constants.FILTER_KEY.AGENCIES]
        }
      },
      {
        key: constants.FILTER_KEY.DOC_TYPES,
        type: uiLib.FILTER_TYPES.GROUP_SELECT,
        label: 'Document Type',
        isFixed: true,
        options: filterOptions[constants.FILTER_KEY.DOC_TYPES],
        defaultOptions: filterDefaultOptions[constants.FILTER_KEY.DOC_TYPES]
      },
      {
        key: constants.FILTER_KEY.TOPICS,
        type: uiLib.FILTER_TYPES.MULTI_SELECT,
        label: 'Topics',
        options: filterOptions[constants.FILTER_KEY.TOPICS],
        defaultOptions: filterDefaultOptions[constants.FILTER_KEY.TOPICS]
      },
      {
        key: constants.FILTER_KEY.ACTS,
        type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
        onOptionsLoad: filterOptionsAsyncLoaders[constants.FILTER_KEY.ACTS],
        label: 'Acts',
        inputComponentProps: {
          withSelectAllButton: false
        }
      },
      {
        key: constants.FILTER_KEY.JURISDICTIONS,
        type: uiLib.FILTER_TYPES.MULTI_SELECT,
        label: 'Jurisdiction',
        options: filterOptions[constants.FILTER_KEY.JURISDICTIONS],
        defaultOptions: filterDefaultOptions[constants.FILTER_KEY.JURISDICTIONS],
        inputComponentProps: {
          withSelectAllButton: false
        }
      },
      {
        key: constants.FILTER_KEY.MAINSTREAM_NEWS_SOURCES,
        type: uiLib.FILTER_TYPES.MULTI_SELECT,
        label: 'News & Premium Content',
        options: filterOptions[constants.FILTER_KEY.MAINSTREAM_NEWS_SOURCES],
        defaultOptions: filterDefaultOptions[constants.FILTER_KEY.MAINSTREAM_NEWS_SOURCES],
        inputComponentProps: {
          withSelectAllButton: false
        }
      },
      {
        key: constants.FILTER_KEY.TITLE,
        type: uiLib.FILTER_TYPES.TEXT_WITH_MODIFIERS,
        placeholder: 'Type Title here...',
        label: 'Title'
      },
      {
        key: constants.FILTER_KEY.DOCUMENT_ID,
        type: uiLib.FILTER_TYPES.NUMBER,
        placeholder: 'Type Document ID here...',
        label: 'Document ID',
        inputComponentProps: {
          minValue: 1
        }
      },
      {
        key: constants.FILTER_KEY.CITATION,
        type: uiLib.FILTER_TYPES.TEXT,
        placeholder: 'Type Citation here...',
        label: 'Citation'
      },
      {
        key: constants.FILTER_KEY.LANGUAGE,
        type: uiLib.FILTER_TYPES.SELECT,
        label: 'Language',
        options: filterOptions[constants.FILTER_KEY.LANGUAGE]
      },
      {
        key: constants.FILTER_KEY.CONCEPTS,
        type: uiLib.FILTER_TYPES.GROUP_SELECT,
        label: 'Concepts',
        options: filterOptions[constants.FILTER_KEY.CONCEPTS],
        defaultOptions: filterDefaultOptions[constants.FILTER_KEY.CONCEPTS],
        inputComponentProps: {
          withSelectAllButton: false
        }
      },
      {
        key: constants.FILTER_KEY.REGULATIONS,
        type: uiLib.FILTER_TYPES.MULTI_SELECT,
        label: 'Regulation',
        options: filterOptions[constants.FILTER_KEY.REGULATIONS],
        inputComponentProps: {
          withSelectAllButton: false
        }
      },
      {
        key: constants.FILTER_KEY.AUTHOR,
        type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
        label: 'Author',
        onOptionsLoad: filterOptionsAsyncLoaders[constants.FILTER_KEY.AUTHOR],
        inputComponentProps: {
          withSelectAllButton: false,
          shouldFetchDefaultOptions: true,
          shouldCacheFetchedOptions: true,
          isMulti: true,
          isAsync: true
        }
      },
      ...(env.shouldShowEitlStatus
        ? [
            {
              key: constants.FILTER_KEY.EITL_LABELS,
              type: uiLib.FILTER_TYPES.ASYNC_MULTI_SELECT,
              label: 'EITL Labels',
              onOptionsLoad: filterOptionsAsyncLoaders[constants.FILTER_KEY.EITL_LABELS],
              inputComponentProps: {
                withSelectAllButton: false,
                shouldFetchDefaultOptions: true,
                shouldCacheFetchedOptions: true
              }
            }
          ]
        : [])
    ];
  }, [filterDefaultOptions, filterOptions, filterOptionsAsyncLoaders, filterTooltipsRenderers]);

  const restrictedFilters = useMemo((): FiltersProps['config'] => {
    return authorizationUtils.isPowerOrTeamOrProUser()
      ? [
          {
            key: constants.FILTER_KEY.DOC_PROPERTIES,
            type: uiLib.FILTER_TYPES.SELECT,
            label: 'Document Properties',
            options: filterOptions[constants.FILTER_KEY.DOC_PROPERTIES]
          },
          {
            key: constants.FILTER_KEY.LABELS,
            type: uiLib.FILTER_TYPES.ASYNC_GROUP_SELECT,
            label: 'Labels',
            onOptionsLoad: filterOptionsAsyncLoaders[constants.FILTER_KEY.LABELS],
            inputComponentProps: {
              withSelectAllButton: false,
              shouldFetchDefaultOptions: true,
              shouldCacheFetchedOptions: true
            }
          }
        ]
      : [];
  }, [filterOptions, filterOptionsAsyncLoaders]);

  return useMemo(() => [...basicFilters, ...restrictedFilters], [basicFilters, restrictedFilters]);
};
