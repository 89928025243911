import type { WorkflowQueryHandlers } from './useWorkflowQueryHandlers.types';
import type { WorkflowData } from './useWorkflowData.types';

import { isDocTaskBlocked } from 'shared/features/workflow/workflow.helpers';

export const useWorkflowQueryHandlers = ({
  localActions
}: {
  localActions: WorkflowData['localActions'];
}): WorkflowQueryHandlers => {
  const handleDocumentTasksFetchSuccess: WorkflowQueryHandlers['handleDocumentTasksFetchSuccess'] = ({
    documentTasks
  }) => {
    localActions.toggleTasksDetailsVisibility(tasksDetailsVisibility => {
      return documentTasks.reduce(
        (changedTasksDetailsVisibility, documentTask) => ({
          ...changedTasksDetailsVisibility,
          [documentTask.id]: isDocTaskBlocked(documentTask)
            ? false
            : tasksDetailsVisibility[documentTask.id] || false
        }),
        {} as WorkflowData['localState']['tasksDetailsVisibility']
      );
    });
  };

  return {
    handleDocumentTasksFetchSuccess
  };
};
