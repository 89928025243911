import * as rulesApi from 'shared/features/rules/rules.api';

export const getBucketAccessRulesData = () => (
  response: Awaited<ReturnType<typeof rulesApi.fetchRules>>
): {
  teamIds: number[];
  userIds: number[];
} => {
  const teamIds =
    response?.rules.reduce((teamIds: number[], rule) => {
      return [
        ...teamIds,
        ...rule.teams
          .filter(team => !teamIds.some(teamId => teamId === team.id))
          .map(team => team.id)
      ];
    }, []) ?? [];

  const userIds =
    response?.rules.reduce((userIds: number[], rule) => {
      return [
        ...userIds,
        ...rule.users
          .filter(user => !teamIds.some(userId => userId === user.id))
          .map(user => user.id)
      ];
    }, []) ?? [];

  return {
    teamIds,
    userIds
  };
};
