import type { RegulationProps } from '../Regulation.types';
import type { RegulationData } from './useRegulationData.types';

import { usePreviousValue } from '@compliance.ai/web-components';

export const useRegulationData = (props: RegulationProps): RegulationData => {
  const prevProps = usePreviousValue(props);

  return {
    prevProps
  };
};
