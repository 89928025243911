import type { FC } from 'react';
import type { ApproversSelectProps } from './ApproversSelect.types';
import { Button, Select, SELECT_TYPES, SelectProps } from '@compliance.ai/web-components';
import classnames from 'classnames';
import { useApproversSelectData, useApproversSelectHandlers } from './hooks';
import './_approvers-select.scss';

export const ApproversSelect: FC<ApproversSelectProps> = ({
  approversIds,
  isSubmitDisabled,
  onSubmit,
  dataTestIds,
  primaryApprover
}) => {
  const { localState, localActions, reduxState, formattedData } = useApproversSelectData({
    approversIds,
    isSubmitDisabled,
    primaryApprover
  });

  const handlers = useApproversSelectHandlers({
    props: {
      onSubmit,
      primaryApprover
    },
    localState,
    reduxState
  });

  return (
    <div className="approvers-select__input-wrapper" data-testid={dataTestIds?.container}>
      {reduxState.approversOptions.length > 1 && !reduxState.primaryApproverActive && (
        <div className="approvers-select__input" data-testid={dataTestIds?.select}>
          <Select
            type={SELECT_TYPES.DEFAULT}
            options={reduxState.approversOptions}
            value={localState.selectedApprover}
            onChange={localActions.setSelectedApprover as SelectProps['onChange']}
          />
        </div>
      )}
      <Button
        dataTestId={dataTestIds?.submitButton}
        className={classnames({
          'approvers-select__button': true,
          'approvers-select__button--extra-top-margin': reduxState.approversOptions.length <= 1
        })}
        onClick={handlers.handleSubmit}
        isDisabled={formattedData.isSubmitDisabled}
      >
        Submit for approval
      </Button>
    </div>
  );
};
