import { fetch } from 'utils/api';

export function api_createWorkflowMold({ workflow_id, workflow_tasks, workflow_name }) {
  return fetch({
    url: `/workflow_molds`,
    method: 'POST',
    dataType: 'WorkflowMold',
    params: { workflow_id, workflow_tasks, workflow_name }
  });
}

export function api_deleteWorkflowMold({ workflow_mold_id }) {
  return fetch({
    url: `/workflow_molds/${workflow_mold_id}`,
    dataType: 'WorkflowMold',
    method: 'DELETE'
  });
}

export const api_updateWorkflowMold = (id, params) => {
  return fetch({
    url: `/update_workflow/${id}`,
    dataType: 'WorkflowMold',
    method: 'POST',
    params
  });
};

export const api_fetchWorkflowMold = id => {
  return fetch({
    url: `/workflow_molds/${id}`,
    method: 'GET'
  });
};
