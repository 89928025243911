import type { WorkflowTaskDetailsHandlers } from './useWorkflowTaskDetailsHandlers.types';
import type { WorkflowTaskDetailsData } from './useWorkflowTaskDetailsData.types';

import * as errorUtils from 'utils/errors';
import * as rightPanelConstants from 'shared/features/rightPanel/rightPanel.constants';

import { useCallback } from 'react';
import { useDocumentsReduxActions } from 'shared/features/documents/hooks';
import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';

export const useWorkflowTaskDetailsHandlers = ({
  localActions,
  reduxState,
  formattedData
}: {
  localActions: WorkflowTaskDetailsData['localActions'];
  reduxState: WorkflowTaskDetailsData['reduxState'];
  formattedData: WorkflowTaskDetailsData['formattedData'];
}): WorkflowTaskDetailsHandlers => {
  const documentsReduxActions = useDocumentsReduxActions();
  const rightPanelReduxActions = useRightPanelReduxActions();

  const handleDocumentFetch: WorkflowTaskDetailsHandlers['handleDocumentFetch'] = useCallback(async () => {
    if (reduxState.documentId) {
      try {
        localActions.setIsFetchingDocument(true);

        await documentsReduxActions.fetchDocumentDetails(reduxState.documentId);

        localActions.setIsDetailsExpanded(false);
      } catch (e) {
        errorUtils.logError(e as Error);
      } finally {
        localActions.setIsFetchingDocument(false);
      }
    }
  }, [localActions, reduxState.documentId, documentsReduxActions]);

  const handleDetailsToggle: WorkflowTaskDetailsHandlers['handleDetailsToggle'] = useCallback(() => {
    localActions.setIsDetailsExpanded(isDetailsExpanded => !isDetailsExpanded);
  }, [localActions]);

  const handleViewFullDetails: WorkflowTaskDetailsHandlers['handleViewFullDetails'] = useCallback(async () => {
    rightPanelReduxActions.openRightPanel({
      type: rightPanelConstants.RIGHT_PANEL_TYPES.DOCUMENT_DETAILS,
      isClosable: false,
      props: {
        documentId: reduxState.documentId,
        shouldOpenRightPanelWithOverlay: formattedData.isPDFAvailable
      }
    });
  }, [reduxState.documentId, formattedData.isPDFAvailable, rightPanelReduxActions]);

  const handleGoToObligations: WorkflowTaskDetailsHandlers['handleGoToObligations'] = useCallback(
    key => {
      if (key === 'obligations') {
        rightPanelReduxActions.openRightPanel({
          type: rightPanelConstants.RIGHT_PANEL_TYPES.DOCUMENT_DETAILS,
          isClosable: false,
          props: {
            documentId: reduxState.documentId,
            activeTabKey: rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.OBLIGATIONS,
            shouldOpenRightPanelWithOverlay: true,
            shouldCloseRightPanelOnOverlayClose: true
          }
        });
      }
    },
    [reduxState.documentId, rightPanelReduxActions]
  );

  return {
    handleDocumentFetch,
    handleDetailsToggle,
    handleViewFullDetails,
    handleGoToObligations
  };
};
