import type { QueryData } from './useQueryData.types';
import type { QueryProps } from '../Query.types';
import type { GlobalState } from 'shared/reducers';

import * as docsSelectors from 'shared/features/documents/documents.selectors';
import * as queryBuilder from 'react-awesome-query-builder';
import * as advancedSearchUtils from 'utils/advancedSearch';
import * as advancedSearchConstants from 'constants/AdvancedSearch';
import * as helpers from './useQueryData.helpers';

import {
  QUERY_CONJUNCTIONS_CONFIG,
  QUERY_OPERATORS_CONFIG,
  QUERY_SETTINGS_CONFIG,
  QUERY_TYPES_CONFIG,
  QUERY_WIDGETS_CONFIG
} from '../Query.config';
import { SKU_RESTRICTION_TYPES } from 'constants/SKUs';

import { useSKUs } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';

export const useQueryData = ({ value }: Pick<QueryProps, 'value'>): QueryData => {
  const { userHasSKU } = useSKUs();

  const config = useMemo(
    () => ({
      conjunctions: QUERY_CONJUNCTIONS_CONFIG,
      operators: QUERY_OPERATORS_CONFIG,
      widgets: QUERY_WIDGETS_CONFIG,
      types: QUERY_TYPES_CONFIG,
      settings: QUERY_SETTINGS_CONFIG,
      fields: helpers.sortFieldsAlphabetically({
        ...advancedSearchConstants.FIELDS,
        ...(userHasSKU(SKU_RESTRICTION_TYPES.BYOC) && advancedSearchConstants.BYOC_FIELDS)
      })
    }),
    [userHasSKU]
  ) as QueryData['formattedData']['config'];

  const reduxState = useSelector<GlobalState, QueryData['reduxState']>(state => ({
    categories: docsSelectors.getCategories(state as never)
  }));

  const [tree, setTree] = useState(
    queryBuilder.Utils.checkTree(
      queryBuilder.Utils.loadFromJsonLogic(
        advancedSearchUtils.parseQueryToJsonLogic(value, {
          categories: reduxState.categories
        }),
        config
      ),
      config
    )
  );

  const localState: QueryData['localState'] = {
    tree
  };

  const localActions: QueryData['localActions'] = useMemo(
    () => ({
      setTree
    }),
    []
  );

  const formattedData: QueryData['formattedData'] = useMemo(() => {
    return {
      config
    };
  }, [config]);

  return {
    localState,
    localActions,
    reduxState,
    formattedData
  };
};
