export const FILTER_KEY = {
  DOC_TITLE: 'docTitle',
  ADDED_BY: 'addedBy',
  ADDED_TO: 'addedTo',
  ADDED_START_DATE: 'addedStartDate',
  ADDED_END_DATE: 'addedEndDate'
};

export const FILTERS_INITIAL_STATE = {
  [FILTER_KEY.DOC_TITLE]: '',
  [FILTER_KEY.ADDED_BY]: null,
  [FILTER_KEY.ADDED_TO]: null,
  [FILTER_KEY.ADDED_START_DATE]: '',
  [FILTER_KEY.ADDED_END_DATE]: ''
};
