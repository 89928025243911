import type { DocDiffPrintButtonData } from './useDocDiffPrintButtonData.types';
import type { GlobalState } from 'shared/reducers';

import * as documentSelectors from 'shared/features/documents/documents.selectors';
import * as rightPanelSelectors from 'shared/features/rightPanel/rightPanel.selectors';

import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const useDocDiffPrintButtonData = (): DocDiffPrintButtonData => {
  const reduxState = useSelector<GlobalState, DocDiffPrintButtonData['reduxState']>(state => {
    return {
      sortedDocumentsToDiff: documentSelectors.getSortedDocumentsToDiff(state as never),
      diffOverlayDocs: rightPanelSelectors.getDiffOverlayDocs(state)
    };
  });

  const formattedData: DocDiffPrintButtonData['formattedData'] = useMemo(() => {
    const diffArray = reduxState.sortedDocumentsToDiff;
    const [doc_id_v1_param, doc_id_v2_param] = reduxState.diffOverlayDocs;
    const docIdV1 = diffArray[0]?.id || doc_id_v1_param;
    const docIdV2 = diffArray[1]?.id || doc_id_v2_param;

    return {
      docIdsToCompare: { docIdV1, docIdV2 }
    };
  }, [reduxState.diffOverlayDocs, reduxState.sortedDocumentsToDiff]);

  return {
    reduxState,
    formattedData
  };
};
