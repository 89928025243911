import type { AutoSummaryData } from './useAutoSummaryData.types';

import * as uiLib from '@compliance.ai/web-components';

export const useAutoSummaryTabs = ({
  formattedData
}: {
  formattedData: AutoSummaryData['formattedData'];
}): uiLib.Tab[] => {
  const tabs = [] as uiLib.Tab[];

  if (formattedData.shortSummary) {
    tabs.push({ content: formattedData.shortSummary, title: 'Concise' });
  }

  if (formattedData.longSummary) {
    tabs.push({ content: formattedData.longSummary, title: 'Extended' });
  }

  return tabs;
};
