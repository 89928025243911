import type { RouteConfig } from './Router.types';

import * as routes from 'constants/Routes';
import * as constants from './Router.constants';
import * as accountPageConstants from 'components/UserAccount/UserAccount.constants';
import * as env from 'shared/config';
import * as newsConstants from '../constants/News';
import * as documentConstants from '../constants/DocumentConstants';

import { Navigate, Outlet } from 'react-router-dom';
import { loadLazyWithRetry, LazyRouteWrapper } from '@compliance.ai/web-components';

import AppSwitch from 'components/AppSwitch';
import RoutesChangesListener from 'components/RoutesChangesListener';

const Admin = loadLazyWithRetry(() => import('components/Organization'));
const OrgProfile = loadLazyWithRetry(() => import('components/Organization/elements/Profile'));
const Dashboard = loadLazyWithRetry(() => import('components/Dashboard'));
const Downloads = loadLazyWithRetry(() => import('components/Downloads'));
const Content = loadLazyWithRetry(() => import('components/Content/Content.index'));
const Documents = loadLazyWithRetry(() => import('components/Documents'));
const DefaultSources = loadLazyWithRetry(() => import('components/DefaultSources'));
const Insights = loadLazyWithRetry(() => import('components/Insights/Insights.index'));
const Resources = loadLazyWithRetry(() => import('components/Resources'));
const Folders = loadLazyWithRetry(() => import('components/Folders'));
const UserAccount = loadLazyWithRetry(() => import('components/UserAccount'));
const Checkout = loadLazyWithRetry(() => import('components/Checkout'));
const Notifications = loadLazyWithRetry(() => import('components/Notifications'));
const ProfileSetup = loadLazyWithRetry(() => import('components/ProfileSetup/ProfileSetup'));
const ManageAnnotations = loadLazyWithRetry(() => import('components/ManageAnnotations'));
const OrganizationDocuments = loadLazyWithRetry(
  () => import('components/OrganizationDocuments/OrganizationDocuments')
);
const Team = loadLazyWithRetry(() => import('components/Team'));
const Login = loadLazyWithRetry(() => import('components/Authentication/Login'));
const Activate = loadLazyWithRetry(() => import('components/Authentication/Activate'));
const Logout = loadLazyWithRetry(() => import('components/Authentication/Logout'));
const AuthRedirect = loadLazyWithRetry(() => import('components/Authentication/AuthRedirect'));
const MandatoryAuth = loadLazyWithRetry(() => import('components/Authentication/MandatoryAuth'));
const CorporateLoginRedirect = loadLazyWithRetry(
  () => import('components/Authentication/Legacy/CorporateLoginRedirect')
);
const PrivacyPolicy = loadLazyWithRetry(() => import('components/PrivacyPolicy'));
const TermsOfService = loadLazyWithRetry(() => import('components/TermsOfService'));
const Plans = loadLazyWithRetry(() => import('components/Plans'));
const Legal = loadLazyWithRetry(() => import('components/Legal'));
const GlassDoor = loadLazyWithRetry(() => import('components/GlassDoor'));
const UrlShortener = loadLazyWithRetry(() => import('components/UrlShortener'));
const SSOTesting = loadLazyWithRetry(() => import('components/SSOTesting'));

const Tasks = loadLazyWithRetry(() => import('components/Tasks'));
const ServiceConnectors = loadLazyWithRetry(() => import('components/ServiceConnectors'));
const Swagger = loadLazyWithRetry(() => import('components/Swagger'));
const ManageLabels = loadLazyWithRetry(() => import('components/ManageLabels'));

export const ROUTES_WITHOUT_AUTH_CHECK: RouteConfig[] = [
  {
    path: routes.sso,
    element: (
      <LazyRouteWrapper>
        <SSOTesting />
      </LazyRouteWrapper>
    )
  },
  {
    path: routes.corporateLoginRedirect,
    header: false,
    footer: true,
    wrapper: false,
    element: (
      <LazyRouteWrapper>
        <CorporateLoginRedirect />
      </LazyRouteWrapper>
    )
  },
  {
    path: routes.privacy,
    element: (
      <LazyRouteWrapper>
        <PrivacyPolicy />
      </LazyRouteWrapper>
    )
  },
  {
    path: routes.terms,
    element: (
      <LazyRouteWrapper>
        <TermsOfService />
      </LazyRouteWrapper>
    )
  },
  {
    path: routes.plans,
    element: (
      <LazyRouteWrapper>
        <Plans />
      </LazyRouteWrapper>
    )
  },
  {
    path: routes.login,
    element: (
      <LazyRouteWrapper>
        <Login />
      </LazyRouteWrapper>
    )
  },
  {
    path: routes.activate,
    element: (
      <LazyRouteWrapper>
        <Activate />
      </LazyRouteWrapper>
    )
  },
  {
    path: routes.logout,
    element: (
      <LazyRouteWrapper>
        <Logout />
      </LazyRouteWrapper>
    )
  },
  {
    path: routes.authRedirect,
    element: (
      <LazyRouteWrapper>
        <AuthRedirect />
      </LazyRouteWrapper>
    )
  },
  {
    path: routes.urlShortener,
    element: (
      <LazyRouteWrapper>
        <UrlShortener />
      </LazyRouteWrapper>
    )
  }
];

export const ROUTES_WITH_AUTH_CHECK: RouteConfig[] = [
  {
    path: '/',
    element: <Navigate to={routes.dashboard} replace />
  },
  {
    path: routes.dashboard,
    element: (
      <LazyRouteWrapper>
        <Dashboard />
      </LazyRouteWrapper>
    )
  },
  {
    path: routes.glassDoor,
    element: (
      <LazyRouteWrapper>
        <MandatoryAuth>
          <GlassDoor />
        </MandatoryAuth>
      </LazyRouteWrapper>
    )
  },
  ...Object.values(constants.BUCKETS_PATHS).map(path => ({
    path,
    element: (
      <LazyRouteWrapper>
        <MandatoryAuth>
          <ManageAnnotations />
        </MandatoryAuth>
      </LazyRouteWrapper>
    )
  })),
  {
    path: routes.organizationProfile,
    element: (
      <LazyRouteWrapper>
        <MandatoryAuth>
          <OrgProfile />
        </MandatoryAuth>
      </LazyRouteWrapper>
    )
  },
  {
    path: routes.admin,
    element: (
      <LazyRouteWrapper>
        <MandatoryAuth>
          <Admin />
        </MandatoryAuth>
      </LazyRouteWrapper>
    )
  },
  {
    path: routes.organizationDocuments,
    element: (
      <LazyRouteWrapper>
        <MandatoryAuth>
          <OrganizationDocuments />
        </MandatoryAuth>
      </LazyRouteWrapper>
    )
  },
  ...(env.shouldShowNewSearch
    ? [
        {
          path: routes.content,
          element: (
            <LazyRouteWrapper>
              <Documents key="content" />
            </LazyRouteWrapper>
          )
        },
        {
          path: routes.timeline,
          element: (
            <LazyRouteWrapper>
              <Documents
                key="timeline"
                identityIterator={'timeline'}
                shouldUseCustomOffsets
                shouldShowSorting={false}
              />
            </LazyRouteWrapper>
          )
        },
        {
          path: routes.news,
          element: (
            <LazyRouteWrapper>
              <Documents key="news" tabs={newsConstants.NEWS_PAGE_TABS} />
            </LazyRouteWrapper>
          )
        },
        {
          path: routes.enforcementExplorer,
          element: (
            <LazyRouteWrapper>
              <Documents
                key="enforcementExplorer"
                preContentType={documentConstants.PRE_CONTENT_TYPE.ENFORCEMENTS_CHART}
              />
            </LazyRouteWrapper>
          )
        }
      ]
    : [
        {
          path: routes.enforcementExplorer,
          element: (
            <LazyRouteWrapper>
              <Content />
            </LazyRouteWrapper>
          )
        },
        {
          path: routes.content,
          element: (
            <LazyRouteWrapper>
              <Content />
            </LazyRouteWrapper>
          )
        }
      ]),
  {
    path: routes.sources,
    element: (
      <LazyRouteWrapper>
        <DefaultSources />
      </LazyRouteWrapper>
    )
  },
  {
    path: routes.insights,
    element: (
      <LazyRouteWrapper>
        <Insights />
      </LazyRouteWrapper>
    )
  },
  {
    path: routes.resources,
    element: (
      <LazyRouteWrapper>
        <Resources />
      </LazyRouteWrapper>
    )
  },
  {
    path: routes.folders,
    element: (
      <LazyRouteWrapper>
        <MandatoryAuth>
          <Folders />
        </MandatoryAuth>
      </LazyRouteWrapper>
    )
  },
  {
    path: routes.account,
    element: (
      <LazyRouteWrapper>
        <MandatoryAuth>
          <UserAccount />
        </MandatoryAuth>
      </LazyRouteWrapper>
    )
  },
  {
    path: routes.legal,
    element: (
      <LazyRouteWrapper>
        <Legal />
      </LazyRouteWrapper>
    )
  },
  {
    path: routes.downloads,
    element: (
      <LazyRouteWrapper>
        <MandatoryAuth>
          <Downloads />
        </MandatoryAuth>
      </LazyRouteWrapper>
    )
  },
  {
    path: routes.checkout,
    element: (
      <LazyRouteWrapper>
        <MandatoryAuth>
          <Checkout />
        </MandatoryAuth>
      </LazyRouteWrapper>
    )
  },
  {
    path: routes.notifications,
    element: (
      <LazyRouteWrapper>
        <MandatoryAuth>
          <Notifications />
        </MandatoryAuth>
      </LazyRouteWrapper>
    )
  },
  {
    path: routes.tasks,
    element: (
      <LazyRouteWrapper>
        <MandatoryAuth>
          <Tasks />
        </MandatoryAuth>
      </LazyRouteWrapper>
    )
  },
  {
    path: routes.swagger,
    element: (
      <LazyRouteWrapper>
        <MandatoryAuth>
          <Swagger />
        </MandatoryAuth>
      </LazyRouteWrapper>
    )
  },
  ...Object.values(constants.MANAGE_LABELS_PATHS).map(path => ({
    path,
    element: (
      <LazyRouteWrapper>
        <MandatoryAuth>
          <ManageLabels />
        </MandatoryAuth>
      </LazyRouteWrapper>
    )
  })),
  {
    path: routes.profileSetup,
    element: (
      <LazyRouteWrapper>
        <MandatoryAuth>
          <ProfileSetup />
        </MandatoryAuth>
      </LazyRouteWrapper>
    )
  },
  {
    path: `${routes.team}/:id`,
    element: (
      <LazyRouteWrapper>
        <MandatoryAuth>
          <Team />
        </MandatoryAuth>
      </LazyRouteWrapper>
    )
  },
  ...Object.values(constants.SERVICE_CONNECTORS_PATHS).map(path => ({
    path,
    element: (
      <LazyRouteWrapper>
        <MandatoryAuth>
          <ServiceConnectors />
        </MandatoryAuth>
      </LazyRouteWrapper>
    )
  })),
  {
    path: routes.unsubscribe,
    element: (
      <Navigate
        replace
        to={`${routes.account}?${accountPageConstants.ROUTE_KEY}=${accountPageConstants.USER_ACCOUNT_ROUTE_KEYS.EMAIL_NOTIFICATIONS}`}
      />
    )
  }
];

export const REDIRECT_ROUTES: RouteConfig[] = [
  {
    path: '*',
    element: <Navigate to={routes.dashboard} replace />
  }
];

export const ALL_ROUTES_CONFIG: RouteConfig[] = [
  {
    path: '/',
    element: (
      <AppSwitch>
        <RoutesChangesListener />
        <Outlet />
      </AppSwitch>
    ),
    children: [...ROUTES_WITHOUT_AUTH_CHECK, ...ROUTES_WITH_AUTH_CHECK, ...REDIRECT_ROUTES]
  }
];
