import type { PrintProps } from '../Print.types';
import type { PrintHandlers } from './usePrintHandlers.types';
import type { usePrintQueries } from './usePrintQueries';

import { useViewReduxActions } from 'shared/features/view/hooks';

export const usePrintHandlers = ({
  props,
  queries
}: {
  props: Pick<PrintProps, 'onActionStart'>;
  queries: ReturnType<typeof usePrintQueries>;
}): PrintHandlers => {
  const viewReduxActions = useViewReduxActions();

  const handleRestrictModalOpen: PrintHandlers['handleRestrictModalOpen'] = () => {
    viewReduxActions.showRestrictModal();
  };

  const handlePrint: PrintHandlers['handlePrint'] = () => {
    props.onActionStart?.();
    queries.fetchDocuments.refetch();
  };

  return {
    handleRestrictModalOpen,
    handlePrint
  };
};
