import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { doAnalytics } from './RoutesChangesListener.helpers';

const RoutesChangesListener = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    doAnalytics({
      pathname,
      search
    });
  }, [pathname, search]);

  return null;
};

export default RoutesChangesListener;
