import { useMemo, useState } from 'react';

export const useAuthCheckData = () => {
  const [isLoading, setLoadingState] = useState(true);

  const localActions = useMemo(
    () => ({
      setLoadingState
    }),
    []
  );

  return {
    localState: {
      isLoading
    },
    localActions
  };
};
