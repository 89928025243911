import { objectToString } from 'utils/dataManipulator';
import {
  REQUEST_TASKS,
  RECEIVE_TASKS,
  UPDATE_TASKS,
  FETCH_DOCUMENTS_BY_IDS,
  REQUEST_ADD_DOCUMENT_TO_WORKFLOW,
  RECEIVE_ADD_DOCUMENT_TO_WORKFLOW,
  FAIL_ADD_DOCUMENT_TO_WORKFLOW,
  SET_LOCK_MANAGE_TASK_OPERATIONS,
  TOGGLE_DOC_TASK_LOADING_STATUS,
  TOGGLE_DOC_TASK_REFETCH_TRIGGER
} from './workflow.actions';
import { normalizeTask, toggleLoadingStatus } from './workflow.helpers';

export const getInitialWorkflowsState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  isUpdated: false,
  isAddingDocumentsToWorkflow: false,
  lockManageTaskOperations: false,
  selectedDocuments: []
});

export const workflows = (state = getInitialWorkflowsState(), action) => {
  switch (action.type) {
    case SET_LOCK_MANAGE_TASK_OPERATIONS:
      return {
        ...state,
        lockManageTaskOperations: action.lockManageTaskOperations
      };

    case REQUEST_ADD_DOCUMENT_TO_WORKFLOW:
      return {
        ...state,
        isAddingDocumentsToWorkflow: true
      };

    case RECEIVE_ADD_DOCUMENT_TO_WORKFLOW:
    case FAIL_ADD_DOCUMENT_TO_WORKFLOW:
      return {
        ...state,
        isAddingDocumentsToWorkflow: false
      };

    case FETCH_DOCUMENTS_BY_IDS:
      return { ...state, selectedDocuments: action.payload.documents };
    default:
      return state;
  }
};

export const getInitialTasksState = () => ({
  isFetching: false,
  isReady: false,
  tasksRefetchTrigger: false,
  tasksList: [],
  alreadyFetchedParams: new Set([])
});

export const tasks = (state = getInitialTasksState(), action) => {
  switch (action.type) {
    case REQUEST_TASKS:
      return {
        ...state,
        isFetching: true,
        isReady: false,
        alreadyFetchedParams: state.alreadyFetchedParams.add(objectToString(action.data))
      };

    case RECEIVE_TASKS: {
      return {
        ...state,
        isFetching: false,
        isReady: true,
        tasksList: action.payload.tasks.map(normalizeTask)
      };
    }

    case UPDATE_TASKS: {
      const newTasksSubsetObj = action.payload.tasks.reduce(
        (previous, task) => ({
          ...previous,
          [task.document_task.id]: normalizeTask(task) // These tasks are in initial format. They should be normalized
        }),
        {}
      );

      const storeTasksObj = state.tasksList.reduce(
        (previous, task) => ({
          ...previous,
          [task.document_task.id]: task // These tasks are already normalized
        }),
        {}
      );

      // Replace existing tasks in store with new fetched tasks.
      const updatedTasks = state.tasksList.reduce((allTasks, task) => {
        if (newTasksSubsetObj[task.document_task.id]) {
          allTasks.push(newTasksSubsetObj[task.document_task.id]);
        } else {
          allTasks.push(task);
        }

        return allTasks;
      }, []);

      // These fetched tasks are not in store so add them to store.
      action.payload.tasks.forEach(task => {
        if (!storeTasksObj[task.document_task.id]) {
          updatedTasks.push(normalizeTask(task)); // New tasks are also in initial format. They should be normalized
        }
      });

      return {
        ...state,
        isFetching: false,
        isReady: true,
        tasksList: updatedTasks
      };
    }

    case TOGGLE_DOC_TASK_LOADING_STATUS:
      return {
        ...state,
        tasksList: state.tasksList.map(
          toggleLoadingStatus({
            docTaskId: action.payload.docTaskId,
            isLoading: action.payload.isLoading
          })
        )
      };

    case TOGGLE_DOC_TASK_REFETCH_TRIGGER:
      return {
        ...state,
        tasksRefetchTrigger: !state.tasksRefetchTrigger
      };

    default:
      return state;
  }
};
