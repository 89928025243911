import lodash from 'lodash';
import { DOCUMENT_CATEGORIES } from 'constants/DocumentConstants';

export const getSourceNames = ({ documentCategory, document }) => {
  if (documentCategory === DOCUMENT_CATEGORIES.WHITEPAPER) {
    return document?.whitepaper?.metadata?.authors ?? [];
  }

  if (lodash.get(document, 'mainstream_news.news_source.name')) {
    return document.mainstream_news.news_source.name;
  }

  if (Array.isArray(document.agencies)) {
    return document.agencies
      .map(a => a.short_name || a.name)
      .sort()
      .join(', ');
  }

  return null;
};
