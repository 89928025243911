export const isValidCategoryArgument = arg => {
  const isArrayOrNum = Number.isInteger(arg) || Array.isArray(arg);
  const isEmptyArray = Array.isArray(arg) && arg.length < 1;
  const arrayEltsAreNums =
    !Array.isArray(arg) || (Array.isArray(arg) && arg.every(elt => Number.isInteger(elt)));

  if (!isArrayOrNum || isEmptyArray || !arrayEltsAreNums) {
    return false;
  }
  return true;
};
