import type { DocumentIdProps } from './DocumentId.types';

import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';

export const DocumentId = ({ document }: DocumentIdProps) => {
  return (
    <>
      <FieldName>Document ID</FieldName>
      <FieldValue>{document.id}</FieldValue>
    </>
  );
};
