import type { QueryHandlers } from './useQueryHandlers.types';
import type { QueryProps } from '../Query.types';
import type { QueryData } from './useQueryData.types';

import * as queryBuilder from 'react-awesome-query-builder';
import * as advancedSearchUtils from 'utils/advancedSearch';

import { useCallback } from 'react';

export const useQueryHandlers = ({
  props,
  reduxState,
  localState,
  localActions,
  formattedData
}: {
  props: Pick<QueryProps, 'value' | 'onSubmit'>;
  reduxState: QueryData['reduxState'];
  localState: QueryData['localState'];
  localActions: QueryData['localActions'];
  formattedData: QueryData['formattedData'];
}): QueryHandlers => {
  const { onSubmit } = props;

  const handleConceptsChange: QueryHandlers['handleConceptsChange'] = useCallback(
    conceptsQuery => {
      localActions.setTree(tree => {
        const currentQueryString = queryBuilder.Utils.queryString(tree, formattedData.config);
        const queryString = !currentQueryString
          ? conceptsQuery
          : `(${currentQueryString}) OR ${conceptsQuery}`;

        return queryBuilder.Utils.checkTree(
          queryBuilder.Utils.loadFromJsonLogic(
            advancedSearchUtils.parseQueryToJsonLogic(queryString, {
              categories: reduxState.categories
            }),
            formattedData.config
          ),
          formattedData.config
        );
      });
    },
    [formattedData.config, localActions, reduxState.categories]
  );

  const handleQueryClear: QueryHandlers['handleQueryClear'] = useCallback(() => {
    localActions.setTree(
      queryBuilder.Utils.checkTree(
        queryBuilder.Utils.loadFromJsonLogic({ and: [] }, formattedData.config),
        formattedData.config
      )
    );
  }, [formattedData.config, localActions]);

  const handleQuerySubmit: QueryHandlers['handleQuerySubmit'] = useCallback(() => {
    onSubmit(queryBuilder.Utils.queryString(localState.tree, formattedData.config));
  }, [formattedData.config, localState.tree, onSubmit]);

  return {
    handleConceptsChange,
    handleQueryClear,
    handleQuerySubmit
  };
};
