import { apiUrl } from '../shared/config';

export enum AUTHENTICATION_OPTIONS_KEYS {
  API_KEY = 'API_KEY',
  REDUX_STATE = 'REDUX_STATE'
}

export enum BASE_API_OPTIONS {
  BASE_API_APP = 'app',
  BASE_API_ABSOLUTE = 'absolute'
}

export const API_PREFIXES = {
  [BASE_API_OPTIONS.BASE_API_APP]: apiUrl,
  [BASE_API_OPTIONS.BASE_API_ABSOLUTE]: ''
};

export const HTTP_METHODS_WITH_BODY = ['POST', 'PATCH', 'PUT', 'DELETE'];

export const ORG_IP_ERROR = 'invalid org IP';
export const ORG_IP_ERROR_DESCRIPTION =
  "Access to this application is restricted, please contact your organization's administrator for more information.";
