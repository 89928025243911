import lodash from 'lodash';
import { store } from 'store';
import { latestSubscription } from 'utils/subscriptions';
import { ADMIN, POWER_USER, TEAM_ADMIN, WORKFLOW_ADMIN, RSS, ORG_ADMIN } from 'constants/UserRoles';
import { PLAN_TEAMS, PLAN_PRO, PLAN_LITE_TEAMS } from 'constants/Plans';
import { safe_analytics } from './analytics';
import { showRestrictModal as reduxShowRestrictModal } from 'shared/features/view/view.actions';
import { getUserAuthenticatedState } from 'shared/features/auth/auth.selectors';

function getCurrentUser() {
  return store.getState().current_user.user;
}

function getOrganization() {
  return store.getState().organization.organization;
}

function haveUsersLoaded() {
  return store.getState().current_user.isReady;
}

export function getLatestSubscription() {
  const { subscriptions } = store.getState().subscriptions;
  return subscriptions && lodash.get(latestSubscription(subscriptions), 'stripe_id');
}

export function isPowerOrTeamUser() {
  const isAuthenticated = getUserAuthenticatedState(store.getState());

  const latestSub = getLatestSubscription();
  const user = getCurrentUser();

  return (
    isAuthenticated &&
    (user.roles.includes(POWER_USER) || (latestSub && PLAN_TEAMS.includes(latestSub)))
  );
}

export function isPowerOrTeamOrProUser() {
  const isAuthenticated = getUserAuthenticatedState(store.getState());

  return isAuthenticated && (isPowerUser() || isTeamUser() || isProUser());
}

export function hasRSSAccess() {
  const user = getCurrentUser();
  const isAuthenticated = getUserAuthenticatedState(store.getState());

  return isAuthenticated && (isPowerOrTeamUser() || user.roles.includes(RSS));
}

export function isTeamUser() {
  const isAuthenticated = getUserAuthenticatedState(store.getState());
  const latestSub = getLatestSubscription();

  return isAuthenticated && latestSub && PLAN_TEAMS.includes(latestSub);
}

export function isLiteTeamUser() {
  const isAuthenticated = getUserAuthenticatedState(store.getState());
  const latestSub = getLatestSubscription();

  return isAuthenticated && latestSub && PLAN_LITE_TEAMS.includes(latestSub);
}

export function isProUser() {
  const isAuthenticated = getUserAuthenticatedState(store.getState());
  const latestSub = getLatestSubscription();

  return isAuthenticated && latestSub && PLAN_PRO.includes(latestSub);
}

export function isOrgMember() {
  const user = getCurrentUser();
  return (
    (lodash.get(user, 'id') || haveUsersLoaded()) &&
    isPowerOrTeamUser() &&
    user.organization &&
    user.organization.id
  );
}

//TODO.  Role Look up should really be by ID instead of strings
export function isOrgAdmin() {
  const user = getCurrentUser();
  return lodash.get(user, 'organization.org_user_roles', []).find(({ name }) => name === ORG_ADMIN);
}

export function isAdminOfTeam(teamId) {
  const user = getCurrentUser();
  const { teams } = getOrganization();
  const team = lodash.find(teams, { id: teamId });
  return !!lodash
    .get(team, 'team_members', [])
    .find(({ id, team_user_roles }) => id === user.id && team_user_roles.includes(ADMIN));
}

export function isTeamAdminUser(user) {
  return lodash
    .get(user, 'organization.org_user_roles', [])
    .find(({ name }) => name === TEAM_ADMIN);
}

export function isTeamAdmin() {
  const user = getCurrentUser();

  return isTeamAdminUser(user);
}

export function isPowerUser() {
  const user = getCurrentUser();
  return lodash.get(user, 'roles', []).includes(POWER_USER);
}

export function isWorkflowAdmin() {
  const user = getCurrentUser();
  return lodash
    .get(user, 'organization.org_user_roles', [])
    .find(({ name }) => name === WORKFLOW_ADMIN);
}

export function isTeamMember() {
  const user = getCurrentUser();
  return !lodash.isEmpty(lodash.get(user, 'teams', []));
}

export function isInternalUser() {
  const user = getCurrentUser();
  return !!lodash.get(user, 'is_internal_user', false);
}

/**
 * Note authentication in following files:
 * Team has workflow access
 * Org admin has team and workflow access
 */
export function hasOrgAdminAccess() {
  return isOrgAdmin();
}

export function hasTeamAdminAccess() {
  return isOrgAdmin() || isTeamAdmin();
}

export function hasWorkflowAdminAccess() {
  return isOrgAdmin() || isTeamAdmin() || isWorkflowAdmin();
}

export const withAuthCheck = (title, callback, text = '') => {
  const isAuthenticated = getUserAuthenticatedState(store.getState());

  if (!isAuthenticated) {
    safe_analytics('default', 'Non-auth', 'Open restrict modal', title);
    return store.dispatch(reduxShowRestrictModal('', title, text));
  } else {
    callback?.();
  }
};
