import type { SelectOption } from '@compliance.ai/web-components';
import type { CaiCategoryFromResponse } from 'shared/features/documents/documents.types';
import type {
  DocumentMetadataLocalState,
  DocumentMetadataReduxState,
  DocumentMetadataFormattedData
} from './useDocumentEditMetadataData.types';

import * as uiLib from '@compliance.ai/web-components';

import { DOCUMENT_METADATA_FIELDS } from 'constants/DocumentMetadata';

export const formatMetadataValue = (field: DOCUMENT_METADATA_FIELDS, value: unknown) => {
  if (!value) {
    return null;
  }

  switch (field) {
    // Multi select values
    case DOCUMENT_METADATA_FIELDS.TOPICS:
      return (value as SelectOption[]).map(option => option.value);

    // Single select value
    case DOCUMENT_METADATA_FIELDS.JURISDICTION:
    case DOCUMENT_METADATA_FIELDS.DOC_TYPES:
      return (value as SelectOption).value;

    case DOCUMENT_METADATA_FIELDS.AUTHOR:
      return (value as SelectOption).label || (value as SelectOption).value;

    // Date picker value
    case DOCUMENT_METADATA_FIELDS.CREATED_AT:
    case DOCUMENT_METADATA_FIELDS.UPDATED_AT:
    case DOCUMENT_METADATA_FIELDS.PUBLICATION_DATE:
    case DOCUMENT_METADATA_FIELDS.COMMENTS_CLOSE_ON:
    case DOCUMENT_METADATA_FIELDS.EFFECTIVE_ON:
      return uiLib.formatDate(value as Date, { format: 'MM-DD-YYYY' });

    default:
      return value;
  }
};

export const formatUpdatedMetadataValue = (
  metadataValues: DocumentMetadataLocalState['metadataValues'],
  caiCategoriesIdsToNamesMap: DocumentMetadataReduxState['caiCategoriesIdsToNamesMap'],
  availableFields: DocumentMetadataFormattedData['availableDocumentUpdateFields']
) => {
  return Object.entries(metadataValues)
    .filter(([field, _]) => availableFields.includes(field))
    .reduce(
      (values, [field, value]) => ({
        ...values,
        [field]:
          field === DOCUMENT_METADATA_FIELDS.DOC_TYPES
            ? caiCategoriesIdsToNamesMap[value as CaiCategoryFromResponse['id']]
            : value
      }),
      {}
    );
};
