import type { ShareBucketFormProps } from '../ShareBucketForm.types';
import type * as rulesApiTypes from 'shared/features/rules/rules.api.types';
import type * as organizationTypes from 'shared/features/organizations/organizations.types';
import type * as uiLib from '@compliance.ai/web-components';

import * as constants from '../../../ShareBucketModal.constants';

export const addEntityTypeToOptions = ({
  options,
  type
}: {
  options: organizationTypes.OrgMemberOption[] | organizationTypes.TeamOption[];
  type: constants.SHARE_BUCKET_ENTITY_TYPE;
}): (
  | organizationTypes.OrgMemberOption
  | (organizationTypes.TeamOption & { type: constants.SHARE_BUCKET_ENTITY_TYPE })
)[] => {
  return options.map(option => ({
    ...option,
    type
  }));
};

export const addEntityTypeToGroupedOptions = ({
  activeOrganizationMembersOptions,
  teamOptions,
  ownerEmail,
  sharedTeamIds,
  sharedUserIds
}: {
  activeOrganizationMembersOptions: organizationTypes.OrgMemberOption[];
  teamOptions: organizationTypes.TeamOption[];
  ownerEmail: organizationTypes.OrgMemberOption['label'];
  sharedTeamIds?: number[];
  sharedUserIds?: number[];
}): uiLib.GroupSelectProps['options'] => {
  const unsharedTeamOptions = teamOptions.filter(option => !sharedTeamIds?.includes(option.value));
  const unsharedUserOptions = activeOrganizationMembersOptions.filter(
    option => !sharedUserIds?.includes(option.value) && option.label !== ownerEmail
  );

  return [
    {
      label: 'Teams',
      options: addEntityTypeToOptions({
        options: unsharedTeamOptions,
        type: constants.SHARE_BUCKET_ENTITY_TYPE.TEAM
      })
    },
    {
      label: 'Members',
      options: addEntityTypeToOptions({
        options: unsharedUserOptions,
        type: constants.SHARE_BUCKET_ENTITY_TYPE.USER
      })
    }
  ];
};

export const formatFormParams = ({
  bucket,
  sharersOptions,
  accessLevel
}: {
  bucket: ShareBucketFormProps['bucket'];
  sharersOptions: uiLib.SelectOption[];
  accessLevel: uiLib.SelectOption;
}): rulesApiTypes.CreateRulePayload[] => {
  if (!Boolean(bucket?.id) || !Boolean(sharersOptions?.length) || !Boolean(accessLevel)) {
    return [];
  }

  const bucketIds = [Number(bucket?.id)];
  const isEditAccess = accessLevel.value === constants.SHARE_BUCKET_ACCESS_LEVEL.EDIT;
  const isViewAccess =
    isEditAccess || accessLevel.value === constants.SHARE_BUCKET_ACCESS_LEVEL.VIEW;

  const teamsParams = sharersOptions
    .filter(option => option.type === constants.SHARE_BUCKET_ENTITY_TYPE.TEAM)
    .map(
      option =>
        [
          {
            team_ids: [Number(option.value)],
            bucket_ids: bucketIds,
            view_access: isViewAccess,
            edit_access: isEditAccess
          }
        ] as rulesApiTypes.CreateRulePayload
    );

  const usersParams = sharersOptions
    .filter(option => option.type === constants.SHARE_BUCKET_ENTITY_TYPE.USER)
    .map(
      option =>
        [
          {
            user_ids: [Number(option.value)],
            bucket_ids: bucketIds,
            view_access: isViewAccess,
            edit_access: isEditAccess
          }
        ] as rulesApiTypes.CreateRulePayload
    );

  return [...teamsParams, ...usersParams];
};
