import type { DiffTabTitleData } from './useDiffTabTitleData.types';

import * as rightPanelConstants from 'shared/features/rightPanel/rightPanel.constants';

import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';

export const useDiffTabTitleHandlers = ({
  reduxState
}: {
  reduxState: DiffTabTitleData['reduxState'];
}) => {
  const rightPanelReduxActions = useRightPanelReduxActions();

  const handleGoBack = () => {
    rightPanelReduxActions.changeDocumentDetailsActiveTab({
      key: rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.DETAILS
    });
    rightPanelReduxActions.closeOverlay();
  };

  return {
    handleGoBack
  };
};
