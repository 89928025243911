import type { JurisdictionsSelectProps } from './JurisdictionsSelect.types';

import { Select, SELECT_TYPES } from '@compliance.ai/web-components';
import { useJurisdictionsSelectData, useJurisdictionsSelectHandlers } from './hooks';

export const JurisdictionsSelect = (props: JurisdictionsSelectProps) => {
  const { reduxState, formattedData } = useJurisdictionsSelectData(props);

  const handlers = useJurisdictionsSelectHandlers({
    props,
    reduxState
  });

  return (
    <Select
      {...props}
      type={props.type ?? SELECT_TYPES.DEFAULT}
      isLoading={formattedData.isLoading}
      isDisabled={formattedData.isDisabled}
      options={formattedData.options}
      value={formattedData.value}
      onChange={handlers.handleChange}
    />
  );
};
