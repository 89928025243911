import type { DocumentFromResponse } from 'shared/features/documents/documents.types';

export const MAX_DOCUMENTS_COUNT = 10;

export const EXCLUSIVE_FIELDS: (keyof DocumentFromResponse)[] = [
  'id',
  'rule',
  'publication_date',
  'bookmarked',
  'flagged',
  'related_documents',
  'restriction_type',
  'user_has_access',
  'permissions',
  'owner_id',
  'title'
];
