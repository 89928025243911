import { TASK_NOTIFICATION_KEYS } from 'constants/Workflows';

export const WORKFLOW_NOTIFICATIONS_OPTIONS: { value: TASK_NOTIFICATION_KEYS; label: string }[] = [
  {
    value: TASK_NOTIFICATION_KEYS.TASK_CREATED,
    label: 'Notify assignees when tasks are created'
  },
  {
    value: TASK_NOTIFICATION_KEYS.TASK_NEAR_DUE,
    label: 'Notify assignees 24 hours before tasks are due'
  },
  {
    value: TASK_NOTIFICATION_KEYS.TASK_OVERDUE,
    label: 'Notify assignees when tasks are past due'
  },
  {
    value: TASK_NOTIFICATION_KEYS.TASK_DONE,
    label: 'Notify assignees when tasks are done'
  }
];
