import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  authenticateUser as reduxAuthenticateUser,
  signUpUser as reduxSignUpUser,
  refreshSessionTokens as reduxRefreshSessionTokens,
  authenticateUsingCognitoCode as reduxAuthenticateUsingCognitoCode,
  changePassword as reduxChangePassword,
  signOut as reduxSignOut,
  confirmNewPassword as reduxConfirmNewPassword,
  sendPasswordResetEmail as reduxSendPasswordResetEmail,
  setTokens as reduxSetTokens,
  getExternalApiToken as reduxGetExternalApiToken,
  generateExternalApiToken as reduxGenerateExternalApiToken
} from 'shared/features/auth/auth.actions';

export const useAuthReduxActions = () => {
  const dispatch = useDispatch();

  const setTokens = useCallback(
    tokens => {
      dispatch(reduxSetTokens(tokens));
    },
    [dispatch]
  );

  const authenticateUser = useCallback(
    async ({ email, password }) => {
      return await dispatch(
        reduxAuthenticateUser({
          email,
          password
        })
      ).then(unwrapResult);
    },
    [dispatch]
  );

  const authenticateUsingCognitoCode = useCallback(
    async ({ code, orgInvitationToken }) => {
      return await dispatch(
        reduxAuthenticateUsingCognitoCode({
          code,
          orgInvitationToken
        })
      ).then(unwrapResult);
    },
    [dispatch]
  );

  const signUpUser = useCallback(
    async ({ email, password, orgInvitationToken }) => {
      return await dispatch(
        reduxSignUpUser({
          email,
          password,
          orgInvitationToken
        })
      ).then(unwrapResult);
    },
    [dispatch]
  );

  const refreshSessionTokens = useCallback(async () => {
    return await dispatch(reduxRefreshSessionTokens()).then(unwrapResult);
  }, [dispatch]);

  const changePassword = useCallback(
    async ({ oldPassword, newPassword }) => {
      return await dispatch(
        reduxChangePassword({
          oldPassword,
          newPassword
        })
      ).then(unwrapResult);
    },
    [dispatch]
  );

  const confirmNewPassword = useCallback(
    async ({ email, verificationCode, newPassword }) => {
      return await dispatch(
        reduxConfirmNewPassword({
          email,
          verificationCode,
          newPassword
        })
      ).then(unwrapResult);
    },
    [dispatch]
  );

  const sendPasswordResetEmail = useCallback(
    async ({ email }) => {
      return await dispatch(
        reduxSendPasswordResetEmail({
          email
        })
      ).then(unwrapResult);
    },
    [dispatch]
  );

  const signOut = useCallback(async () => {
    await dispatch(reduxSignOut()).then(unwrapResult);
  }, [dispatch]);

  const getExternalApiToken = useCallback(
    async approvedAppKey => {
      return await dispatch(reduxGetExternalApiToken({ approvedAppKey })).then(unwrapResult);
    },
    [dispatch]
  );

  const generateExternalApiToken = useCallback(async () => {
    return await dispatch(reduxGenerateExternalApiToken()).then(unwrapResult);
  }, [dispatch]);

  return useMemo(
    () => ({
      setTokens,
      authenticateUser,
      authenticateUsingCognitoCode,
      signUpUser,
      refreshSessionTokens,
      changePassword,
      confirmNewPassword,
      sendPasswordResetEmail,
      signOut,
      getExternalApiToken,
      generateExternalApiToken
    }),
    [
      authenticateUser,
      authenticateUsingCognitoCode,
      changePassword,
      confirmNewPassword,
      generateExternalApiToken,
      getExternalApiToken,
      refreshSessionTokens,
      sendPasswordResetEmail,
      setTokens,
      signOut,
      signUpUser
    ]
  );
};
