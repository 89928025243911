import type { BucketOption } from './buckets.types';

export const DEFAULT_BUCKET_NAME = 'Public Collection';

export const DEFAULT_BUCKET_OPTION = {
  label: DEFAULT_BUCKET_NAME
} as BucketOption;

export enum BUCKETS_ATTRIBUTE_TYPES {
  ANNOTATIONS = 'annotations',
  OBLIGATIONS = 'obligations',
  LABELS = 'labels',
  WORKFLOWS = 'workflows',
  DOCUMENTS = 'documents'
}
