import type { DropdownCellProps } from '../DropdownCell.types';
import type { DropdownCellData } from './useDropdownCellData.types';

import * as helpers from './useDropdownCellData.helpers';
import * as workflowHelpers from 'shared/features/workflow/workflow.helpers';
import * as workflowConstants from 'shared/features/workflow/workflow.constants';
import * as emptyValue from 'constants/EmptyValue';

import { useMemo } from 'react';

export const useDropdownCellData = ({
  cellValue
}: Pick<DropdownCellProps, 'cellValue'>): DropdownCellData => {
  const formattedData: DropdownCellData['formattedData'] = useMemo(() => {
    const dropdowns = [...cellValue.dropdowns].sort(workflowHelpers.sortDropdowns).map(dropdown => {
      const selectedDropdownOptionLabels = helpers.formatSelectedDropdownOptionLabels(
        dropdown.dropdown_options
      );

      return {
        id: dropdown.id,
        label:
          dropdown.label ||
          workflowConstants.DEFAULT_LABELS[workflowConstants.WORKFLOW_ANNOTATION_TYPES.DROPDOWNS],
        selectedValues: selectedDropdownOptionLabels.length
          ? selectedDropdownOptionLabels
          : emptyValue.EMPTY_VALUE.DASH
      };
    });

    return {
      dropdowns
    };
  }, [cellValue.dropdowns]);

  return {
    formattedData
  };
};
