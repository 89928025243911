import type { AddedToComplianceAiData } from './useAddedToComplianceAiData.types';
import type { GlobalState } from 'shared/reducers';
import type { AddedToComplianceAiProps } from '../AddedToComplianceAi.types';

import { getUserTimezone } from 'shared/features/user/user.selectors';
import {
  DOCUMENT_PROPERTY_LABEL,
  DOCUMENT_PROPERTY_VALUE,
  getDocumentProperty
} from 'utils/documents/documentProperties';
import { DOCUMENT_FIELDS } from 'constants/DocumentProperties';

import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const useAddedToComplianceAiData = ({
  document
}: Pick<AddedToComplianceAiProps, 'document'>): AddedToComplianceAiData => {
  const reduxState = useSelector<GlobalState, AddedToComplianceAiData['reduxState']>(state => ({
    userTimezone: getUserTimezone(state)
  }));

  const formattedData: AddedToComplianceAiData['formattedData'] = useMemo(() => {
    const createdAtProperty = getDocumentProperty[DOCUMENT_FIELDS.createdAt]({
      document,
      // TODO Uncomment when documentProperties utils will be moved to TS
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      timezoneName: reduxState.userTimezone
    });

    return {
      addedLabel: (createdAtProperty[
        DOCUMENT_PROPERTY_LABEL as keyof typeof createdAtProperty
      ] as unknown) as string,
      addedValue: (createdAtProperty[
        DOCUMENT_PROPERTY_VALUE as keyof typeof createdAtProperty
      ] as unknown) as string
    };
  }, [document, reduxState.userTimezone]);

  return {
    reduxState,
    formattedData
  };
};
