import type { ShareBucketModalProps } from '../ShareBucketModal.types';
import type * as uiLib from '@compliance.ai/web-components';

import * as elements from '../elements';
import * as constants from '../ShareBucketModal.constants';

export const useShareBucketModalTabs = ({
  props
}: {
  props: ShareBucketModalProps;
}): uiLib.Tab[] => {
  if (!props.bucket) {
    return [];
  }

  return [
    ...(props.bucket.isEditable
      ? [
          {
            key: constants.SHARE_BUCKET_MODAL_TAB_KEYS.SHARE_BUCKET_FORM,
            title: 'Share',
            default: constants.SHARE_BUCKET_MODAL_TAB_KEYS.SHARE_BUCKET_FORM === props.initialTab,
            content: (
              <elements.ShareBucketForm
                bucket={props.bucket}
                onClose={props.onModalClose}
                onModalActionStart={props.onModalActionStart}
                onActionSuccess={props.onModalActionSuccess}
              />
            )
          }
        ]
      : []),
    {
      key: constants.SHARE_BUCKET_MODAL_TAB_KEYS.MANAGE_BUCKET_ACCESS,
      title: 'Who Has Access',
      default: constants.SHARE_BUCKET_MODAL_TAB_KEYS.MANAGE_BUCKET_ACCESS === props.initialTab,
      content: (
        <elements.ManageBucketAccess
          bucket={props.bucket}
          onActionSuccess={props.onModalActionSuccess}
        />
      )
    }
  ];
};
