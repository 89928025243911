import lodash from 'lodash';

// sorts object keys then turns it into a string.  utility to compare if objects are equal
export const objectToString = obj => {
  if (!obj) return '';

  const keyValues = [];

  lodash.sortBy(Object.keys(obj)).forEach(key => {
    keyValues.push([key, obj[key]]);
  });

  return JSON.stringify(keyValues);
};
