import lodash from 'lodash';
import { DEFAULT_JURISDICTIONS_SHORT_NAMES } from 'constants/Defaults';
import { DEFAULT_TYPES } from 'constants/DefaultSources';
import { isNotCountryJurisdiction } from 'utils/jurisdiction-utils';

export const normalizeDefaults = ({
  followed_entities = [],
  sources = null,
  topics = [],
  concepts = [],
  categories = {},
  metacategories = {},
  agencies = {},
  jurisdictions = {},
  regulations = []
}) => {
  return {
    activeTopics: normalizeActiveTopics(sources),
    defaultMainstreamNewsSources: normalizeDefaultMainstreamNewsSources(sources),
    defaultTopics: normalizeDefaultTopics(sources),
    followedCategories: normalizeFollowedCategories(categories),
    followedMetacategories: normalizeFollowedCategories(metacategories),
    followedAgencies: normalizeFollowedAgencies(agencies),
    followedJurisdictions: normalizefollowedJurisdictions(jurisdictions),
    followedConcepts: normalizeFollowedConcepts(concepts),
    followedTopics: normalizeFollowedTopics(topics),
    followedEntities: normalizeFollowedEntities(followed_entities),
    realFollowedTopics: normalizeRealFollowedTopics(topics),
    followedRegulations: normalizeFollowedRegulations(regulations)
  };
};

export const normalizeFollowedTopics = topics => {
  if (Array.isArray(topics)) {
    return topics;
  }
  return [];
};

export const normalizeRealFollowedTopics = topics => {
  return normalizeFollowedTopics(topics).filter(topic => topic.following);
};

export const normalizeActiveTopics = sources => {
  if (sources && Array.isArray(sources.activeTopics)) {
    return sources.activeTopics;
  }
  return [];
};

export const normalizeDefaultMainstreamNewsSources = sources => {
  if (sources && Array.isArray(sources.defaultMainstreamNewsSources)) {
    return sources.defaultMainstreamNewsSources;
  }
  return [];
};

export const mapDefaultMainstreamNewsSourceToFollowedEntity = ({
  id = null,
  entity = null,
  name = ''
} = {}) => {
  return {
    entity,
    name,
    following: true,
    entity_type: 'news_sources',
    entity_id: id
  };
};

export const normalizeDefaultTopics = sources => {
  if (sources && Array.isArray(sources.defaultTopics)) {
    return sources.defaultTopics;
  }
  return [];
};

export const mapDefaultTopicsToFollowedTopics = ({
  following = true,
  description = '',
  id = null,
  label = ''
} = {}) => ({
  following: following,
  topic_id: id,
  user_id: null,
  org_id: null
});

export const mapAgencyToFollowedAgency = ({ following = true, id = null } = {}) => ({
  following: following,
  agency_id: id
});

export const normalizeDefaultFollowedJurisdictions = jurisdictions => {
  return jurisdictions
    .filter(({ short_name, following }) => {
      return DEFAULT_JURISDICTIONS_SHORT_NAMES.includes(short_name) && following;
    })
    .map(jurisdiction => {
      return {
        entity_id: jurisdiction.id,
        entity_type: 'jurisdictions',
        following: true,
        entity: jurisdiction
      };
    });
};

export const normalizeDefaultFollowedAgencies = agencies => {
  return agencies.reduce((followedAgencies, agency) => {
    if (agency.type !== 'state' && agency.popular) {
      return {
        ...followedAgencies,
        [agency.id]: agency
      };
    }

    return followedAgencies;
  }, {});
};

export const normalizeFollowedAgencies = agencies => {
  if (lodash.isObject(agencies)) {
    return agencies;
  }
  return {};
};

export const normalizefollowedJurisdictions = jurisdictions => {
  if (lodash.isObject(jurisdictions)) {
    return jurisdictions;
  }
  return {};
};

export const normalizeDefaultFollowedCategories = categories => {
  if (lodash.isObject(categories)) {
    return Object.keys(categories).reduce((followedCategories, categoryId) => {
      const { surface_in_filter, is_active, cai_metacategory_id } = categories[categoryId];
      if (surface_in_filter && is_active && cai_metacategory_id) {
        return { ...followedCategories, [categoryId]: true };
      }
      return followedCategories;
    }, {});
  }
  return {};
};

export const normalizeFollowedCategories = categories => {
  if (lodash.isObject(categories)) {
    return categories;
  }
  return {};
};

export const normalizeFollowedConcepts = concepts => {
  if (lodash.isObject(concepts)) {
    return concepts;
  }
  return {};
};

export const normalizeFollowedRegulations = regulations => {
  if (lodash.isObject(regulations)) {
    return regulations;
  }
  return {};
};

export const normalizeFollowedEntities = followedEntities => {
  if (Array.isArray(followedEntities)) {
    return followedEntities;
  }
  return [];
};

export const normalizeUpdatedFollowedTopics = (updatedTopics, state) => {
  const followedTopics = lodash.cloneDeep(state.followedTopics).map(topic => {
    const updatedTopic = updatedTopics.find(
      updatedTopic => updatedTopic.topic_id === topic.topic_id
    );

    if (updatedTopic) {
      return {
        ...topic,
        following: updatedTopic.following
      };
    }

    return topic;
  });

  const realFollowedTopics = followedTopics.filter(topic => topic.following);

  return {
    followedTopics,
    realFollowedTopics
  };
};

export const normalizeUpdatedFollowedEntities = (updatedEntities, state) => {
  //TODO Refactor to use followedEntities array only. Followed entities obj logic is a legacy.
  const followedEntitiesObj = convertFollowedEntitiesToObject(state.followedEntities);

  updatedEntities.forEach(entity => {
    if (entity.entity_type === 'news_sources') {
      if (!entity.following) {
        delete followedEntitiesObj.mainstreamNews[entity.entity_id];
      } else {
        const followedEntity = followedEntitiesObj.mainstreamNews[entity.entity_id];
        if (followedEntity) {
          followedEntity.followed = true;
        } else {
          followedEntitiesObj.mainstreamNews[entity.entity_id] = entity;
        }
      }
    }
  });

  return [...Object.values(followedEntitiesObj.mainstreamNews)];
};

export const convertFollowedEntitiesToObject = followedEntities => {
  const followedEntitiesObj = { mainstreamNews: {} };

  followedEntities.forEach(entity => {
    if (entity.following) {
      if (entity.entity_type === 'news_sources') {
        followedEntitiesObj.mainstreamNews[entity.entity_id] = entity;
      }
    }
  });

  return followedEntitiesObj;
};

export const updateFollowedIds = ({ ...obj }, id) => {
  if (obj[id]) {
    delete obj[id];
    return obj;
  }
  return {
    ...obj,
    [id]: true
  };
};

export const toObject = key => (obj, item) => {
  return {
    ...obj,
    [item[key]]: item
  };
};

export const formatObjectFromResponses = (responses, sources) => {
  return responses.reduce(
    (payload, response) => {
      return {
        ...payload,
        ...response
      };
    },
    {
      sources
    }
  );
};

export const getCountryDisplayNames = reduxJurisdictions => {
  return [...new Set(reduxJurisdictions.map(jurisdiction => jurisdiction.country_display_name))];
};

export const getFilteredFollowedAgencies = ({ agencies, followedAgenciesIdsMap }) => {
  return agencies.filter(agency => followedAgenciesIdsMap?.[agency.id]);
};

export const getActiveFollowedConcepts = ({ followedConcepts, activeConcepts }) => {
  return Object.keys(followedConcepts).reduce((prev, key) => {
    if (activeConcepts[key]) {
      return { ...prev, [key]: true };
    }
    return prev;
  }, {});
};

export const getFilteredNonCountryJurisdictions = jurisdictions => {
  return jurisdictions.filter(isNotCountryJurisdiction);
};

export const getFilteredFollowedJurisdictions = ({
  jurisdictions,
  followedJurisdictionsIdsMap
}) => {
  return jurisdictions.filter(jurisdiction => followedJurisdictionsIdsMap?.[jurisdiction.id]);
};

export const getUpdatedTeamDefaultsMap = ({
  defaultsType,
  teamId,
  teamDefaultsMap,
  defaultsData
}) => {
  const updatedTeamDefaultsMap = { ...teamDefaultsMap };

  if (defaultsType === DEFAULT_TYPES.TEAM_DEFAULTS && teamId) {
    const existingTeamDefaults = updatedTeamDefaultsMap[teamId] || {};
    updatedTeamDefaultsMap[teamId] = { ...existingTeamDefaults, ...defaultsData };
  }
  return updatedTeamDefaultsMap;
};
