import PropTypes from 'prop-types';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';

import { useViewReduxActions } from 'shared/features/view/hooks';
import {
  useFolderData,
  useFolderReduxActions,
  useFolderHandlers,
  useFolderLifecyle
} from './hooks';

import './_folder.scss';

export const TEST_ID = 'document-actions-user-folder';

export const DocumentActionsFolder = ({
  documentIds,
  savedFoldersCount,
  showSavedFoldersCount,
  onActionStart,
  actionButtonType
}) => {
  const { localState, localActions, reduxState, formattedData } = useFolderData({
    props: {
      documentIds
    }
  });

  const foldersReduxActions = useFolderReduxActions({
    isAuthenticated: reduxState.isAuthenticated
  });
  const viewReduxActions = useViewReduxActions();

  const handlers = useFolderHandlers({
    props: {
      showSavedFoldersCount
    },
    localActions,
    formattedData,
    reduxState,
    foldersReduxActions,
    viewReduxActions
  });

  useFolderLifecyle({
    reduxState,
    handlers
  });

  return (
    <span className="folder__container create-folder" data-testid={TEST_ID}>
      <uiLib.ActionButton
        isDisabled={formattedData.isDisabled}
        isRestricted={formattedData.isRestricted}
        onClick={handlers.handleOnClick}
        onRestrictedClick={handlers.handleRestrictModalOpen}
        tooltip="Add to folder"
        tooltipPlacement={uiLib.TOOLTIP_POSITION.BOTTOM}
        type={actionButtonType}
      >
        <uiLib.IconAddFolder />
      </uiLib.ActionButton>
      {!formattedData.isDisabled && showSavedFoldersCount && savedFoldersCount > 0 && (
        <div className="folder-count-icon">{savedFoldersCount}</div>
      )}
      {localState.isAddToFolderModalOpen && (
        <elements.AddToFolderModal
          isOpen={localState.isAddToFolderModalOpen}
          documentIds={documentIds}
          onClose={handlers.handleClose}
          onActionStart={onActionStart}
        />
      )}
    </span>
  );
};

DocumentActionsFolder.propTypes = {
  documentIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  savedFoldersCount: PropTypes.number,
  showSavedFoldersCount: PropTypes.bool,
  onActionStart: PropTypes.func,
  actionButtonType: PropTypes.string
};
