import { Modal } from 'common';
import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import PropTypes from 'prop-types';

import './_labels-remove-modal.scss';

export const LabelsRemoveModal = ({ onDelete, onClose }) => {
  return (
    <Modal width="400px" title="Remove Label" open onClose={onClose}>
      <p className="labels-remove-modal__title">
        Are you sure you want to remove the label from the selected documents?
      </p>
      <div className="labels-remove-modal__footer">
        <Button type={BUTTON_TYPES.LINK} onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="labels-remove-modal__submit"
          type={BUTTON_TYPES.DELETE}
          onClick={onDelete}
        >
          Delete
        </Button>
      </div>
    </Modal>
  );
};

LabelsRemoveModal.propTypes = {
  onDelete: PropTypes.func,
  onClose: PropTypes.func
};
