import type { TypeSwitchProps } from './TypeSwitch.types';
import type { FC } from 'react';

import { Button, BUTTON_TYPES, Dialog } from '@compliance.ai/web-components';
import classnames from 'classnames';
import './_type-switch.scss';

export const TypeSwitch: FC<TypeSwitchProps> = ({
  advancedSearch,
  isAdvancedSearch,
  onSwitch,
  classNames
}) => {
  return (
    <Dialog
      message="If you return to basic search, you will loose any existing advanced search filters. Do you wish to continue?"
      confirmButtonText="Yes"
      cancelButtonText="No"
      onConfirm={onSwitch}
      isDisabled={!isAdvancedSearch || !advancedSearch}
      classNames={{
        triggerContent: 'document-filters-type-switch__button-wrapper',
        container: 'document-filters-type-switch__dialog'
      }}
      triggerContent={
        <Button
          type={BUTTON_TYPES.LINK}
          onClick={!isAdvancedSearch || !advancedSearch ? onSwitch : () => {}}
          className={classnames(classNames?.button, 'document-filters-type-switch__button')}
        >
          {isAdvancedSearch ? 'Basic search' : 'Switch to Advanced'}
        </Button>
      }
    />
  );
};
