export default {
  freeTrialBannerGreen: new URL('./FreeTrialBanner-Green.svg', import.meta.url).href,
  newsCFPBLogo: new URL('./News-CFPB-Logo.svg', import.meta.url).href,
  newsExaminationLogo: new URL('./News-Examination-Logo.svg', import.meta.url).href,
  newsMonetaryLogo: new URL('./News-Monetary-Logo.svg', import.meta.url).href,
  newsStateCALogo: new URL('./News-State-CA-Logo.svg', import.meta.url).href,
  newsStockLogo: new URL('./News-Stock-Logo.svg', import.meta.url).href,
  savedSearch: new URL('./Saved_search.svg', import.meta.url).href,
  tenPercentOffDiscount: new URL('./TenPercentOffDiscount.svg', import.meta.url).href,
  twentyPercentOffDiscount: new URL('./TwentyPercentOffDiscount.svg', import.meta.url).href,
  annotationsHighlighter: new URL('./annotations-highlighter.svg', import.meta.url).href,
  googleLogo: new URL('./googleLogo.svg', import.meta.url).href,
  iconAccessibility: new URL('./icon-accessibility.svg', import.meta.url).href,
  iconArrowDown: new URL('./icon-arrow-down.svg', import.meta.url).href,
  iconBuilder: new URL('./icon-builder.svg', import.meta.url).href,
  iconFeedbackWidget: new URL('./icon-feedback-widget.svg', import.meta.url).href,
  iconGiveFeedback: new URL('./icon_give-feedback.svg', import.meta.url).href,
  iconMarketAnalyze: new URL('./icon_market-analyze.svg', import.meta.url).href,
  iconMarketAutomate: new URL('./icon_market-automate.svg', import.meta.url).href,
  iconMarketMonitor: new URL('./icon_market-monitor.svg', import.meta.url).href,
  iconProductGuide: new URL('./icon_product-guide.svg', import.meta.url).href,
  iconRaiseABug: new URL('./icon_raise-a-bug.svg', import.meta.url).href,
  iconRequestAnEnhancement: new URL('./icon_request-an-enhancement.svg', import.meta.url).href,
  iconWhatsNew: new URL('./icon_whats-new.svg', import.meta.url).href,
  navigation0bar: new URL('./navigation0bar.svg', import.meta.url).href,
  navigation1bar: new URL('./navigation1bar.svg', import.meta.url).href,
  navigation2bar: new URL('./navigation2bar.svg', import.meta.url).href,
  questionIcon: new URL('./questionIcon.svg', import.meta.url).href,
  sampleComparisonChart: new URL('./sample-comparison-chart.svg', import.meta.url).href,
  team: new URL('./team.svg', import.meta.url).href,
  topicsGuideRightPanel: new URL('./topics_guide_right_panel.svg', import.meta.url).href,
  topicsGuideSearchBar: new URL('./topics_guide_search_bar.svg', import.meta.url).href,
  topicsGuideTimeline: new URL('./topics_guide_timeline.svg', import.meta.url).href,

  regHeroIllustration: new URL('./RegHero-illustration.png', import.meta.url).href,
  backgroundDropArea: new URL('./backgroundDropArea.png', import.meta.url).href,
  cog: new URL('./cog.png', import.meta.url).href,
  complianceDesktopInvite: new URL('./compliance-desktop-invite.png', import.meta.url).href,
  consumerCompliantsChart: new URL('./consumerCompliantsChart.png', import.meta.url).href,
  contributorGraphic: new URL('./contributorGraphic.png', import.meta.url).href,
  errorFace24px: new URL('./error-face-24px.png', import.meta.url).href,
  leftNavFrbLogo2x: new URL('./left-nav-frb-logo@2x.png', import.meta.url).href,
  linkedinLogo: new URL('./linkedinlogo.png', import.meta.url).href,
  partyPopperEmoji: new URL('./party-popper-emoji.png', import.meta.url).href,
  registrationNormalPwdIndicator: new URL(
    './registration-normal-pwd-indicator.png',
    import.meta.url
  ).href,
  registrationStrongPwdIndicator: new URL(
    './registration-strong-pwd-indicator.png',
    import.meta.url
  ).href,
  registrationWeakPwdIndicator: new URL('./registration-weak-pwd-indicator.png', import.meta.url)
    .href,
  settingsNormalPwdIndicator: new URL('./settings-normal-pwd-indicator.png', import.meta.url).href,
  settingsStrongPwdIndicator: new URL('./settings-strong-pwd-indicator.png', import.meta.url).href,
  settingsWeakPwdIndicator: new URL('./settings-weak-pwd-indicator.png', import.meta.url).href,
  updated: new URL('./updated.png', import.meta.url).href,
  yearOnAgendaChart: new URL('./yearOnAgendaChart.png', import.meta.url).href,

  bookmarkChrome: new URL('./bookmark_chrome.gif', import.meta.url).href,
  bookmarkIe: new URL('./bookmark_ie.gif', import.meta.url).href,
  confetti: new URL('./confetti.gif', import.meta.url).href
};
