import { FC } from 'react';
import { IconAdd, IconRemove } from '@compliance.ai/web-components';

export enum BUTTON_TYPES {
  DELETE_RULE = 'delRule',
  DELETE_GROUP = 'delGroup',
  ADD_RULE = 'addRule',
  ADD_GROUP = 'addGroup'
}

export const COMPONENT_PROPS: Record<
  BUTTON_TYPES,
  {
    icon: FC;
    tooltipTitle: string;
  }
> = {
  [BUTTON_TYPES.DELETE_RULE]: {
    icon: IconRemove,
    tooltipTitle: 'Delete filter'
  },
  [BUTTON_TYPES.DELETE_GROUP]: {
    icon: IconRemove,
    tooltipTitle: 'Delete filter group'
  },
  [BUTTON_TYPES.ADD_GROUP]: {
    icon: IconAdd,
    tooltipTitle: 'Add filter group'
  },
  [BUTTON_TYPES.ADD_RULE]: {
    icon: IconAdd,
    tooltipTitle: 'Add filter'
  }
};
