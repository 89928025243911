import type { FC } from 'react';
import type { AdvancedSearchProps } from './AdvancedSearch.types';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';

import { ANCHOR_ORIGIN, PLACEHOLDER, TRANSFORM_ORIGIN } from './AdvancedSearch.constants';

import {
  useAdvancedSearchData,
  useAdvancedSearchHandlers,
  useAdvancedSearchLifecycle
} from './hooks';

import './_advanced-search.scss';

export const AdvancedSearch: FC<AdvancedSearchProps> = ({ value, onSubmit }) => {
  const { localState, localActions } = useAdvancedSearchData({ value });

  const handlers = useAdvancedSearchHandlers({
    props: {
      value,
      onSubmit
    },
    localActions
  });

  useAdvancedSearchLifecycle({
    onValuePropChange: handlers.handleValuePropChange
  });

  return (
    <uiLib.ErrorBoundary>
      <uiLib.Dropdown
        isOpen={localState.isOpen}
        onClose={handlers.handleAdvancedSearchToggle}
        containerClassName="advanced-search__input-container"
        className="advanced-search__dropdown"
        anchorOrigin={ANCHOR_ORIGIN}
        transformOrigin={TRANSFORM_ORIGIN}
        shouldShowBackdropShadow
        triggerContent={
          <elements.Input
            value={localState.value}
            placeholder={PLACEHOLDER}
            onChange={localActions.setValue}
            onToggleButtonClick={handlers.handleAdvancedSearchToggle}
            onSubmit={onSubmit}
          />
        }
        dropdownContent={
          <elements.Query value={localState.value} onSubmit={handlers.handleAdvancedSearchSubmit} />
        }
      />
    </uiLib.ErrorBoundary>
  );
};
