import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Dropdown, List, DocumentContextMenuOverlay } from 'common/index';
import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import { useLinksData, useLinksHandlers } from './hooks';
import RestrictBubble from 'components/RestrictBubble';
import { useSKUs } from 'utils/hooks';
import './_links.scss';

export const TEST_IDS = {
  CONTAINER: 'document-summary-view-btn-container'
};

export const Links = ({ document, shouldRenderForEmail }) => {
  const { reduxState, formattedData } = useLinksData({ document });

  const { showSKURestrictModal } = useSKUs();

  const handlers = useLinksHandlers({
    props: {
      document,
      showSKURestrictModal
    },
    formattedData,
    reduxState
  });

  if (shouldRenderForEmail) {
    return (
      <ul>
        {formattedData.allOptions.map((link, i) => {
          return (
            <li key={i}>
              <a href={link.value}>{link.label}</a>
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <div data-testid={TEST_IDS.CONTAINER} className="links__buttons-container">
      {formattedData.documentRestrictionType && <RestrictBubble fixed link-button />}

      <div className="links__buttons-wrapper">
        <DocumentContextMenuOverlay
          document={document}
          contextMenuDisabled={!formattedData.canShowPDFButton}
        >
          <Button
            type={BUTTON_TYPES.PRIMARY}
            onClick={() => handlers.handleOptionClick(formattedData.primaryButtonOption)}
            className="links__primary-button"
            isDisabled={!formattedData.primaryButtonOption}
          >
            {formattedData?.primaryButtonOption?.label || 'View PDF'}
          </Button>
        </DocumentContextMenuOverlay>
        {Boolean(formattedData.listOptions.length) && (
          <Dropdown
            triggerContent={
              <Button type={BUTTON_TYPES.PRIMARY} className="links__dropdown-toggle">
                <ArrowDropDownIcon className="links__arrow-icon" />
              </Button>
            }
            className="links__dropdown-menu"
            dropdownContent={
              <List
                options={formattedData.listOptions}
                onOptionClick={handlers.handleOptionClick}
              />
            }
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          />
        )}
      </div>
    </div>
  );
};

Links.propTypes = {
  document: PropTypes.object.isRequired,
  shouldRenderForEmail: PropTypes.bool
};

Links.defaultProps = {
  shouldRenderForEmail: false
};
