import { REDUCER_NAME } from './labels.reducer';
import { createSelector } from '@reduxjs/toolkit';

/**
 *
 * Labels reducer selector
 */
export const getLabelsReducer = state => state[REDUCER_NAME];

/**
 *
 * Common label selectors
 */
export const getLabelsLoadingState = createSelector(getLabelsReducer, state => state.isLoading);
export const getLabelsList = createSelector(getLabelsReducer, state => state.labels);
export const getLabelsTotalCount = createSelector(getLabelsReducer, state => state.totalCount);
export const getLabelsDetailsRefreshTrigger = createSelector(
  getLabelsReducer,
  state => state.labelsDetailsTrigger
);
