import type * as reducerTypes from './documentFields.reducer.types';

import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { setDocFieldsOptions, fetchDocFieldsOptions } from './documentFields.actions';

const INITIAL_STATE: reducerTypes.DocFieldsState = {
  isReady: false,
  items: []
};

export const reducer = createReducer(INITIAL_STATE, builder => {
  builder.addCase(setDocFieldsOptions.type, (state, action) => {
    if (setDocFieldsOptions.match(action)) {
      state.items = action.payload;
    }
  });

  builder.addMatcher(
    isAnyOf(fetchDocFieldsOptions.pending, fetchDocFieldsOptions.rejected),
    state => {
      state.isReady = false;
    }
  );

  builder.addMatcher(isAnyOf(fetchDocFieldsOptions.fulfilled), state => {
    state.isReady = true;
  });
});
