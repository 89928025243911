import type * as actionsTypes from './buckets.actions.types';
import type * as globalReducersState from '../../reducers';

import { createAsyncThunk } from '@reduxjs/toolkit';

import * as api from './buckets.api';
import * as constants from './buckets.actions.constants';
import * as helpers from './buckets.actions.helpers';
import * as orgSelectors from '../organizations/organizations.selectors';
import * as userSelectors from '../user/user.selectors';
import * as errorUtils from 'utils/errors';

export const GET_BUCKETS = 'GET_BUCKETS';
export const GET_BUCKET_BY_ID = 'GET_BUCKET_BY_ID';
export const ADD_BUCKET = 'ADD_BUCKET';
export const MODIFY_BUCKET = 'MODIFY_BUCKET';
export const DELETE_BUCKET = 'DELETE_BUCKET';

export const getBuckets = createAsyncThunk<
  actionsTypes.GetBucketsReturn,
  actionsTypes.GetBucketsPayload
>(GET_BUCKETS, async (params, { getState }) => {
  try {
    const response = await api.getBuckets(params);

    const state = getState() as globalReducersState.GlobalState;
    const userId = userSelectors.getUserId(state);
    const userTeamsIds = orgSelectors.getTeamsIdsByUserId(userId)(state);

    return helpers.normalizeBucketsResponse({ response, userTeamsIds, userId });
  } catch (e) {
    errorUtils.logError(e as Error);
    throw e;
  }
});

export const getBucketById = createAsyncThunk<
  actionsTypes.GetBucketByIdReturn,
  actionsTypes.GetBucketByIdPayload
>(GET_BUCKET_BY_ID, async (id, { getState }) => {
  try {
    const response = await api.getBucketById(id);

    const state = getState() as globalReducersState.GlobalState;
    const userId = userSelectors.getUserId(state);
    const userTeamsIds = orgSelectors.getTeamsIdsByUserId(userId)(state);

    return helpers.normalizeBucket({ bucket: response.buckets[0], userTeamsIds, userId });
  } catch (e) {
    errorUtils.logError(e as Error);
    throw e;
  }
});

export const addBucket = createAsyncThunk<
  actionsTypes.AddBucketReturn,
  actionsTypes.AddBucketPayload
>(ADD_BUCKET, async (params, { getState }) => {
  try {
    const response = await api.addBucket(params);

    const state = getState() as globalReducersState.GlobalState;
    const userId = userSelectors.getUserId(state);
    const userTeamsIds = orgSelectors.getTeamsIdsByUserId(userId)(state);

    return helpers.normalizeBucket({ bucket: response, userTeamsIds, userId });
  } catch (e) {
    const { message, status: statusCode } = e as never;

    const isValidationError = helpers.isValidationError({
      message,
      expectedMessage: constants.BUCKETS_VALIDATION_ERRORS_API_MESSAGES.ADD,
      statusCode
    });

    if (isValidationError) {
      helpers.showValidationErrorNotification({
        message: constants.BUCKETS_ERRORS_MESSAGES.BUCKET_NAME_VALIDATION
      });
    }

    errorUtils.logError(e as Error, 'Error', { isSilent: isValidationError });
    throw e;
  }
});

export const modifyBucket = createAsyncThunk<
  actionsTypes.ModifyBucketReturn,
  actionsTypes.ModifyBucketPayload
>(MODIFY_BUCKET, async ({ id, params }, { getState }) => {
  try {
    const response = await api.modifyBucket(id, params);

    const state = getState() as globalReducersState.GlobalState;
    const userId = userSelectors.getUserId(state);
    const userTeamsIds = orgSelectors.getTeamsIdsByUserId(userId)(state);

    return helpers.normalizeBucket({ bucket: response, userTeamsIds, userId });
  } catch (e) {
    const { message, status: statusCode } = e as never;

    const isValidationError = helpers.isValidationError({
      message,
      expectedMessage: constants.BUCKETS_VALIDATION_ERRORS_API_MESSAGES.MODIFY,
      statusCode
    });

    if (isValidationError) {
      helpers.showValidationErrorNotification({
        message: constants.BUCKETS_ERRORS_MESSAGES.BUCKET_NAME_VALIDATION
      });
    }

    errorUtils.logError(e as Error, 'Error', { isSilent: isValidationError });
    throw e;
  }
});

export const deleteBucket = createAsyncThunk<
  actionsTypes.DeleteBucketReturn,
  actionsTypes.DeleteBucketPayload
>(DELETE_BUCKET, async id => {
  try {
    return await api.deleteBucket(id);
  } catch (e) {
    errorUtils.logError(e as Error, 'Error');
    throw e;
  }
});
