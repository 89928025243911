import type { DocumentTaskAttachmentFromResponse } from 'shared/features/workflow/workflow.types';

import * as env from 'shared/config';

export const byDifferentName = (file: DocumentTaskAttachmentFromResponse) => {
  return (f: DocumentTaskAttachmentFromResponse) => f.masked_file_name !== file.masked_file_name;
};

export const toGenericAttachments = (file: DocumentTaskAttachmentFromResponse) => {
  return {
    ...file,
    url: `${env.apiUrl}/document_task_attachment/${file.masked_file_name}`,
    userFileName: file.user_file_name
  };
};
