import type { ShareBucketFormProps } from '../ShareBucketForm.types';
import type { GlobalState } from 'shared/reducers';
import type { ShareBucketFormData } from './useShareBucketFormData.types';

import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import * as userSelectors from 'shared/features/user/user.selectors';
import * as orgSelectors from 'shared/features/organizations/organizations.selectors';

import * as helpers from './useShareBucketFormData.helpers';
import * as constants from '../../../ShareBucketModal.constants';

import { useShareBucketFormQueries } from './useShareBucketFormQueries';

export const useShareBucketFormData = ({
  props,
  queries
}: {
  props: Pick<ShareBucketFormProps, 'bucket'>;
  queries: ReturnType<typeof useShareBucketFormQueries>;
}): ShareBucketFormData => {
  const [sharersOptions, setSharersOptions] = useState<
    ShareBucketFormData['localState']['sharersOptions']
  >([]);
  const [accessLevel, setAccessLevel] = useState<ShareBucketFormData['localState']['accessLevel']>(
    constants.SHARE_BUCKET_VIEW_ACCESS_OPTION
  );
  const [note, setNote] = useState<ShareBucketFormData['localState']['note']>('');
  const [isSaving, setIsSaving] = useState<ShareBucketFormData['localState']['isSaving']>(false);

  const localState: ShareBucketFormData['localState'] = {
    sharersOptions,
    accessLevel,
    note,
    isSaving
  };

  const localActions: ShareBucketFormData['localActions'] = useMemo(
    () => ({
      setSharersOptions,
      setAccessLevel,
      setNote,
      setIsSaving
    }),
    []
  );

  const reduxState = useSelector<GlobalState, ShareBucketFormData['reduxState']>(state => ({
    currentUserId: userSelectors.getUserId(state),
    activeOrganizationMembersOptions: orgSelectors.getActiveOrganizationMembersOptions(
      state as never
    ),
    teamOptions: orgSelectors.getOrganizationTeamsOptions(state as never)
  }));

  const formattedData: ShareBucketFormData['formattedData'] = useMemo(() => {
    const sharerOptions = helpers.addEntityTypeToGroupedOptions({
      activeOrganizationMembersOptions: reduxState.activeOrganizationMembersOptions,
      teamOptions: reduxState.teamOptions,
      ownerEmail: props.bucket?.createdBy?.email ?? '',
      sharedTeamIds: queries.bucketAccessRulesQuery.data?.teamIds,
      sharedUserIds: queries.bucketAccessRulesQuery.data?.userIds
    });

    const formParams = helpers.formatFormParams({
      bucket: props.bucket,
      sharersOptions: localState.sharersOptions,
      accessLevel: localState.accessLevel
    });

    const isSubmitButtonDisabled =
      !formParams.length || localState.isSaving || queries.bucketAccessRulesQuery.isFetching;

    return {
      sharerOptions,
      formParams,
      isSubmitButtonDisabled
    };
  }, [
    props,
    queries,
    reduxState.activeOrganizationMembersOptions,
    reduxState.teamOptions,
    localState.sharersOptions,
    localState.accessLevel,
    localState.isSaving
  ]);

  return {
    localState,
    localActions,
    reduxState,
    formattedData
  };
};
