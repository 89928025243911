import type { AddLabelsProps } from './AddLabels.types';
import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';
import * as constants from './AddLabels.constants';

import { CreateLabelModal } from '../../CreateLabelModal';

import { useAddLabelsData, useAddLabelsHandlers } from './hooks';

export const AddLabels: FC<AddLabelsProps> = ({ documentIds, onActionStart }) => {
  const { localState, localActions, formattedData } = useAddLabelsData({ documentIds });

  const handlers = useAddLabelsHandlers({
    props: {
      documentIds,
      onActionStart
    },
    localActions
  });

  return (
    <>
      {localState.modalState.isOpen &&
        {
          [constants.MODAL_TYPE.LABEL_ADDITION]: (
            <elements.AddLabelsModal
              documentIds={documentIds}
              onClose={handlers.handleModalToggle(false)}
              onLabelCreation={handlers.handleLabelCreation}
              onActionStart={onActionStart}
            />
          ),
          [constants.MODAL_TYPE.LABEL_CREATION]: (
            <CreateLabelModal
              onClose={handlers.handleModalToggle(false)}
              initialLabelName={localState.newLabelName}
              onLabelCreate={handlers.handleNewLabelsCreation}
            />
          )
        }[localState.modalState.type]}
      <uiLib.ActionButton
        isDisabled={formattedData.isDisabled}
        isRestricted={formattedData.isRestricted}
        onRestrictedClick={handlers.handleRestrictionModalShow}
        onClick={handlers.handleModalToggle(true)}
        tooltip="Select documents to add labels"
        tooltipPlacement={uiLib.TOOLTIP_POSITION.BOTTOM}
      >
        <uiLib.IconLabel />
      </uiLib.ActionButton>
    </>
  );
};
