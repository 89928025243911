import PropTypes from 'prop-types';
import Menu from '@material-ui/core//Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { DOCUMENT_CONTEXT_MENU_TEXTS } from 'constants/DocumentContextMenu';
import { useDocumentContextMenuOverlayData, useDocumentContextMenuOverlayHandlers } from './hooks';

export const TEST_IDS = {
  CONTAINER: 'document-context-menu-overlay__container',
  MENU: 'document-context-menu-overlay__menu',
  MENU_ITEM_1: 'document-context-menu-overlay__menu-item_1',
  MENU_ITEM_2: 'document-context-menu-overlay__menu-item_2'
};

export const DocumentContextMenuOverlay = ({
  tag: Tag = 'div',
  document,
  children,
  contextMenuDisabled = false,
  ...props
}) => {
  const { localState, localActions } = useDocumentContextMenuOverlayData();

  const handlers = useDocumentContextMenuOverlayHandlers({
    props: { document, contextMenuDisabled },
    localState,
    localActions
  });

  return (
    <Tag onContextMenu={handlers.handleContextMenu} {...props} data-testid={TEST_IDS.CONTAINER}>
      {children}
      <Menu
        open={localState.anchorPosition !== undefined}
        onClose={handlers.handleClose}
        anchorReference="anchorPosition"
        anchorPosition={localState.anchorPosition}
        transitionDuration={0}
        data-testid={TEST_IDS.MENU}
      >
        <MenuItem onClick={handlers.handleOpenDocument(false)} data-testid={TEST_IDS.MENU_ITEM_1}>
          {DOCUMENT_CONTEXT_MENU_TEXTS.OPEN_DOCUMENT_NEW_TAB}
        </MenuItem>
        <MenuItem onClick={handlers.handleOpenDocument(true)} data-testid={TEST_IDS.MENU_ITEM_2}>
          {DOCUMENT_CONTEXT_MENU_TEXTS.OPEN_DOCUMENT_NEW_WINDOW}
        </MenuItem>
      </Menu>
    </Tag>
  );
};

DocumentContextMenuOverlay.propTypes = {
  tag: PropTypes.string,
  document: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.string)
  ]),
  contextMenuDisabled: PropTypes.bool
};

DocumentContextMenuOverlay.defaultProps = {
  tag: 'div',
  children: null,
  contextMenuDisabled: false
};
