import type { FC } from 'react';
import type { SectionProps } from './Section.types';

import pluralize from 'pluralize';
import classnames from 'classnames';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';

import './_section.scss';

export const Section: FC<SectionProps> = ({
  type,
  isExpanded,
  onToggle,
  onExpandAnimationComplete,
  shouldShowNoContentMessage,
  classNames,
  children
}) => {
  return (
    <uiLib.LabeledPanel
      label={uiLib.capitalize(pluralize(type, 2))}
      isExpanded={isExpanded}
      isExpandable
      onExpandButtonClick={onToggle}
      onExpandAnimationComplete={onExpandAnimationComplete}
      isAnimationEnabled
      classNames={{
        ...classNames,
        content: classnames(classNames?.content, 'workflow-summary-section__content')
      }}
    >
      <div className="workflow-summary-section__spacer">
        {children}
        {shouldShowNoContentMessage && <elements.NoContent type={type} />}
      </div>
    </uiLib.LabeledPanel>
  );
};
