import type { MetaSourceButtonData } from './useMetaSourceButtonData.types';
import type { MetaSourceButtonProps } from '../MetaSourceButton.types';

import classNames from 'classnames';

import { useMemo, useState } from 'react';

export const useMetaSourceButtonData = ({
  followingAll,
  followingSome
}: Pick<MetaSourceButtonProps, 'followingAll' | 'followingSome'>): MetaSourceButtonData => {
  const [open, setOpen] = useState(false);

  const localState: MetaSourceButtonData['localState'] = { open };

  const localActions: MetaSourceButtonData['localActions'] = useMemo(() => ({ setOpen }), []);

  const followingOnlySome = followingSome && !followingAll;

  const buttonMainClassName = classNames({
    'meta-source-button-main': true,
    'following-all': followingAll,
    'following-some': followingOnlySome
  });

  const buttonOpenIndicatorClassName = classNames({
    'meta-source-button-open-indicator': true,
    'following-all': followingAll,
    'following-some': followingOnlySome
  });

  const formattedData: MetaSourceButtonData['formattedData'] = {
    buttonMainClassName,
    buttonOpenIndicatorClassName
  };

  return { formattedData, localActions, localState };
};
