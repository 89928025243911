import type { FieldValueProps } from '../FieldValue.types';
import type { FieldValueData } from './useFieldValueData.types';
import type { ReactNode } from 'react';

import { MAX_CHARS_WHEN_COLLAPSED } from '../FieldValue.constants';

export const getContent = ({
  children,
  isExpandable,
  isExpanded
}: {
  children: FieldValueProps['children'];
  isExpandable: FieldValueProps['isExpandable'];
  isExpanded: FieldValueData['localState']['isExpanded'];
}): ReactNode => {
  if (typeof children !== 'string' || !isExpandable || isExpanded) {
    return children;
  }

  if (children.length > MAX_CHARS_WHEN_COLLAPSED) {
    return `${children.substring(0, MAX_CHARS_WHEN_COLLAPSED)}...`;
  }

  return children;
};

export const shouldShowExpandButton = ({
  children,
  isExpandable
}: {
  children: FieldValueProps['children'];
  isExpandable: FieldValueProps['isExpandable'];
}): boolean => {
  if (typeof children !== 'string' || !isExpandable) {
    return false;
  }

  return children.length > MAX_CHARS_WHEN_COLLAPSED;
};
