import React from 'react';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { Link } from 'react-router-dom';
import lodash from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import * as uiLib from '@compliance.ai/web-components';

import * as appNotificationConstants from 'constants/AppNotification';
import * as corporateWebsiteLinksConstants from 'constants/CorporateWebsiteLinks';
import * as routes from 'constants/Routes';
import auth from 'utils/auth';
import * as localStorageUtils from 'utils/localStorage-utils';
import * as userActions from 'shared/features/user/user.actions';

class AppNotifications extends React.PureComponent {
  state = {
    localHiddenNotificationIds: [],
    showModal: false
  };

  getNotificationIdsToExclude = () => {
    if (auth.loggedIn()) {
      const { hiddenNotifications } = this.props.current_user.user.properties;
      return hiddenNotifications || [];
    }

    return localStorageUtils.safelyGetLocalStorageArray('hiddenNotifications');
  };

  hideNotification = notificationId => {
    const hiddenNotifications = this.getNotificationIdsToExclude();
    // localStorage only takes affect after refresh so keep track in state too
    hiddenNotifications.push(notificationId);
    this.setState({ localHiddenNotificationIds: hiddenNotifications });
    if (auth.loggedIn()) {
      const { email, properties } = this.props.current_user.user;
      this.props.updateCurrentUser(email, { properties: { ...properties, hiddenNotifications } });
    } else {
      localStorageUtils.safelySetLocalStorageData(
        'hiddenNotifications',
        JSON.stringify(hiddenNotifications)
      );
    }
  };

  findNotificationType = appNotificationToShow => {
    if (!appNotificationToShow.notification_type) {
      return undefined;
    }

    return appNotificationConstants.APP_NOTIFICATION_TYPES.find(({ value }) => {
      return value === appNotificationToShow.notification_type;
    });
  };

  render() {
    const { appNotifications } = this.props.current_user;
    const notificationIdsToExclude = this.getNotificationIdsToExclude();

    const appNotificationToShow = lodash.first(
      appNotifications.filter(
        ({ id, expires_at, enabled }) =>
          enabled &&
          moment(expires_at).isAfter(moment()) &&
          !notificationIdsToExclude.includes(id) &&
          !this.state.localHiddenNotificationIds.includes(id)
      )
    );

    if (!appNotificationToShow) return null;

    const foundNotificationType = this.findNotificationType(appNotificationToShow);

    return (
      <>
        <div
          id={appNotificationConstants.APP_NOTIFICATION_CONTAINER_ID}
          className="app-notification-header"
        >
          {foundNotificationType && (
            <span className="app-notification-header-type">
              {foundNotificationType.icon}
              {`${foundNotificationType.label}:`}
            </span>
          )}
          <span className="title">{appNotificationToShow.title}</span>
          {appNotificationToShow.video_link ? (
            <uiLib.Button
              type={uiLib.BUTTON_TYPES.SECONDARY}
              onClick={() => this.setState({ showModal: true })}
            >
              See Video
            </uiLib.Button>
          ) : (
            <uiLib.Button type={uiLib.BUTTON_TYPES.LINK}>
              <a
                className="app-notification-learn-more"
                href={appNotificationToShow.external_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {appNotificationToShow.external_link_text}
              </a>
            </uiLib.Button>
          )}
          <CloseIcon
            className="close"
            onClick={this.hideNotification.bind(null, appNotificationToShow.id)}
          />
        </div>
        <uiLib.Modal
          title={appNotificationToShow.title}
          isOpen={this.state.showModal}
          className="app-notification-modal"
          onClose={() => this.setState({ showModal: false })}
        >
          <ReactPlayer
            className="video"
            url={appNotificationToShow.video_link}
            playing
            volume={0.5}
            width="100%"
            height="400px"
          />
          <div className="app-notification-modal__footer">
            {!auth.loggedIn() && (
              <Link className="app-notification-login" to={routes.login}>
                Login
              </Link>
            )}
            <a
              className="app-notification-learn-more"
              href={appNotificationToShow.external_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {appNotificationToShow.external_link_text}
            </a>
            {!auth.loggedIn() && (
              <a
                className="app-notification-create-account"
                href={corporateWebsiteLinksConstants.CORPORATE_WEBSITE_SIGN_UP_URL}
              >
                Create an Account
              </a>
            )}
          </div>
        </uiLib.Modal>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    current_user: state.current_user
  };
};
export default connect(mapStateToProps, { updateCurrentUser: userActions.updateCurrentUser })(
  AppNotifications
);
