import type { FC } from 'react';

import * as elements from './elements';

import { useAppSearchData, useAppSearchHandlers, useAppSearchLifecycle } from './hooks';

import './_app-search.scss';

export const AppSearch: FC = () => {
  const { localState, localActions } = useAppSearchData();

  const handlers = useAppSearchHandlers({
    localActions
  });

  useAppSearchLifecycle({
    onSearchFieldTypeQueryParamChange: handlers.handleSearchFieldTypeQueryParamChange,
    onLocationChange: handlers.handleLocationChange
  });

  return (
    <>
      <div className="app-search__search-field">
        {localState.isAdvancedSearch ? (
          <elements.AdvancedSearchField />
        ) : (
          <elements.AutocompleteSearchField />
        )}
      </div>
      <div className="app-search__search-field-type-switch">
        <elements.SearchFieldTypeSwitch
          isAdvancedSearch={localState.isAdvancedSearch}
          onSwitch={localActions.setIsAdvancedSearch}
        />
      </div>
    </>
  );
};
