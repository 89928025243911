import type { AddBucketModalData } from './useAddBucketModalData.types';

import { useMemo, useState } from 'react';

export const useAddBucketModalData = (): AddBucketModalData => {
  const [bucketName, setBucketName] = useState<AddBucketModalData['localState']['bucketName']>('');

  const localState: AddBucketModalData['localState'] = {
    bucketName
  };

  const localActions: AddBucketModalData['localActions'] = useMemo(
    () => ({
      setBucketName
    }),
    [setBucketName]
  );

  const formattedData: AddBucketModalData['formattedData'] = useMemo(
    () => ({
      isAddBucketButtonDisabled: !Boolean(localState.bucketName)
    }),
    [localState.bucketName]
  );

  return {
    localState,
    localActions,
    formattedData
  };
};
