import type { TaskStatusProps } from '../TaskStatus.types';
import type { TaskStatusHandlers } from './useTaskStatusHandlers.types';

import { TASK_STATUS_DONE, TASK_STATUS_INCOMPLETE } from 'constants/TaskStatuses';

export const useTaskStatusHandlers = ({
  props
}: {
  props: Pick<TaskStatusProps, 'value' | 'documentTask' | 'onChange'>;
}): TaskStatusHandlers => {
  const handleTaskStatusChange: TaskStatusHandlers['handleTaskStatusChange'] = isChecked => {
    const status = isChecked ? TASK_STATUS_DONE : TASK_STATUS_INCOMPLETE;

    props.onChange([
      {
        task_id: props.documentTask.id,
        task_status: status
      }
    ]);
  };

  return {
    handleTaskStatusChange
  };
};
