import type { JurisdictionsSelectData } from './useJurisdictionsSelectData.types';
import type { JurisdictionsSelectProps } from '../JurisdictionsSelect.types';
import type { GlobalState } from 'shared/reducers';

import { JURISDICTIONS_SELECT_VALUE_KEYS } from '../JurisdictionsSelect.constants';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getJurisdictionsOptionsByKey,
  getJurisdictionsReadyStatus
} from 'shared/features/jurisdictions/jurisdictions.selectors';
import { getValuesFromOptions } from 'utils/select-utils';
import {
  getFilterJurisdictionGroupedOptions,
  getFilterJurisdictionGroupsOptions,
  getFilterLoadingState
} from 'shared/features/filterGroups/filterGroups.selectors';

export const useJurisdictionsSelectData = ({
  value,
  isMulti,
  isLoading,
  isDisabled,
  valueKey
}: JurisdictionsSelectProps): JurisdictionsSelectData => {
  const reduxState = useSelector<GlobalState, JurisdictionsSelectData['reduxState']>(state => ({
    // TODO Uncomment when jurisdictions.selectors will be moved to TS
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    options: getJurisdictionsOptionsByKey(state, valueKey ?? JURISDICTIONS_SELECT_VALUE_KEYS.ID),
    pinnedOptions: getFilterJurisdictionGroupsOptions(state),
    groupedOptions: getFilterJurisdictionGroupedOptions(
      state,
      valueKey ?? JURISDICTIONS_SELECT_VALUE_KEYS.ID
    ),
    areGroupsLoading: getFilterLoadingState(state),
    areJurisdictionsReady: getJurisdictionsReadyStatus(state)
  }));

  const formattedData: JurisdictionsSelectData['formattedData'] = useMemo(
    () => ({
      options: [...reduxState.pinnedOptions, ...reduxState.options],
      isLoading: Boolean(
        !reduxState.areJurisdictionsReady || reduxState.areGroupsLoading || isLoading
      ),
      isDisabled: Boolean(
        !reduxState.areJurisdictionsReady || reduxState.areGroupsLoading || isDisabled
      ),
      value: getValuesFromOptions({
        isMulti,
        options: reduxState.options,
        value
      })
    }),
    [
      isDisabled,
      isLoading,
      isMulti,
      reduxState.areGroupsLoading,
      reduxState.areJurisdictionsReady,
      reduxState.options,
      reduxState.pinnedOptions,
      value
    ]
  );

  return {
    reduxState,
    formattedData
  };
};
