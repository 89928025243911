import type { ImplementationDateData } from './useImplementationDateData.types';
import type { ImplementationDateProps } from '../ImplementationDate.types';

import { useMemo } from 'react';
import { getImplementationDate } from './useImplementationDateData.helpers';

export const useImplementationDateData = ({
  document
}: Pick<ImplementationDateProps, 'document'>): ImplementationDateData => {
  const formattedData: ImplementationDateData['formattedData'] = useMemo(() => {
    const implementationDate = getImplementationDate({
      document: document
    });

    return {
      implementationDate
    };
  }, [document]);

  return {
    formattedData
  };
};
