import { REQUEST_ACTS, RECEIVE_ACTS, CLEAR_ACTS } from './acts.actions';

export const getInitialState = () => ({
  isFetching: false,
  isReady: false,
  isFetchingAllActs: false,
  areAllActsReady: false,
  items: [],
  allActs: []
});

export const acts = (state = getInitialState(), action) => {
  switch (action.type) {
    case REQUEST_ACTS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_ACTS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.acts
      };
    case CLEAR_ACTS:
      return {
        ...state,
        isFetching: false,
        isReady: false,
        items: action.payload
      };

    default:
      return state;
  }
};
