import classnames from 'classnames';

import { WorkflowDetails } from 'common/index';

import * as uiLib from '@compliance.ai/web-components';
import * as documentSummaryElements from '../../../../../DocumentSummaryElements';
import * as constants from './WorkflowTaskDetails.constants';

import { DocumentActions } from '../../../useDocumentDetailsRightPanelConfig/elements/DocumentDetailsContent/elements/DocumentSummaryTab/elements';
import { DocumentLink } from './elements';

import {
  useWorkflowTaskDetailsData,
  useWorkflowTaskDetailsHandlers,
  useWorkflowTaskDetailsLifecycle
} from './hooks';

import './_workflow-task-details.scss';

export const WorkflowTaskDetails = () => {
  const { localState, localActions, reduxState, formattedData } = useWorkflowTaskDetailsData();

  const handlers = useWorkflowTaskDetailsHandlers({ localActions, reduxState, formattedData });

  useWorkflowTaskDetailsLifecycle({ onDocumentFetch: handlers.handleDocumentFetch });

  if (localState.isFetchingDocument || !reduxState.document) {
    return (
      <div
        className={classnames({
          'workflow-task-details__container': true,
          'workflow-task-details__container--is-loading': true
        })}
      >
        <uiLib.Loader withoutBackground />
      </div>
    );
  }

  return (
    <uiLib.ErrorBoundary>
      <div className="workflow-task-details__container">
        <div className="workflow-task-details__section">
          <DocumentActions />
        </div>
        <div className="workflow-task-details__section">
          <uiLib.Typography className="workflow-task-details__section-label">
            Title
          </uiLib.Typography>
          <p>{reduxState.document.title}</p>
        </div>
        <div className="workflow-task-details__section">
          <DocumentLink document={reduxState.document} />
        </div>
        <div
          className={classnames({
            'workflow-task-details__section': true,
            'workflow-task-details__section-action-buttons': true
          })}
        >
          <uiLib.Button
            type={uiLib.BUTTON_TYPES.LINK_CONTRAST}
            onClick={handlers.handleDetailsToggle}
          >
            {localState.isDetailsExpanded ? '- Show Less Details' : '+ Show More Details'}
          </uiLib.Button>
          <uiLib.Button
            type={uiLib.BUTTON_TYPES.LINK_CONTRAST}
            onClick={handlers.handleViewFullDetails}
            className="workflow-task-details__section-details-button"
          >
            View full details <uiLib.IconLinkArrow />
          </uiLib.Button>
        </div>

        {localState.isDetailsExpanded && (
          <>
            <div className="workflow-task-details__section">
              <documentSummaryElements.SummaryContent text={reduxState.document.summary_text} />
            </div>
            <documentSummaryElements.Metadata
              document={reduxState.document}
              fieldsToRender={constants.METADATA_TO_RENDER}
              shouldRenderForEmail={false}
            />
          </>
        )}

        <div className="workflow-task-details__section">
          <WorkflowDetails
            document={reduxState.document}
            workflowId={reduxState.activeWorkflowId}
            onTabChange={handlers.handleGoToObligations}
            shouldShowObligationsLink
          />
        </div>
      </div>
    </uiLib.ErrorBoundary>
  );
};
