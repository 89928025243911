import { useMemo, useCallback } from 'react';
import { useViewReduxActions } from 'shared/features/view/hooks/index.js';

export const useExportDropdownHandlers = ({ props, localActions, formattedData, queries }) => {
  const viewReduxActions = useViewReduxActions();

  const handleDropdownToggle = useCallback(
    isOpen => () => {
      if (isOpen) {
        queries.fetchDocuments.refetch();
      }

      localActions.setDropdownOpen(isOpen);
    },
    [localActions, queries.fetchDocuments]
  );

  const handleContentTypeSelect = useCallback(
    contentType => () => {
      if (!Boolean(formattedData.isContentTypeDisabled[contentType])) {
        props.onSelectContentType(contentType);
        localActions.setDropdownOpen(false);
      }
    },
    [props, localActions, formattedData]
  );

  const handleRestrictModalOpen = useCallback(() => {
    viewReduxActions.showRestrictModal();
  }, [viewReduxActions]);

  return useMemo(
    () => ({
      handleDropdownToggle,
      handleContentTypeSelect,
      handleRestrictModalOpen
    }),
    [handleDropdownToggle, handleContentTypeSelect, handleRestrictModalOpen]
  );
};
