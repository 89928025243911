import type { LabelTypeSelectData } from './useLabelTypeSelectData.types';
import type { LabelTypeSelectProps } from '../LabelTypeSelect.types';

import { useState, useMemo } from 'react';

export const useLabelTypeSelectData = ({
  props
}: {
  props: Pick<LabelTypeSelectProps, 'value'>;
}): LabelTypeSelectData => {
  const [selectedLabelTypeOption, setSelectedLabelTypeOption] = useState<
    LabelTypeSelectData['localState']['selectedLabelTypeOption']
  >(props.value ? props.value : null);

  const [isLoading, setIsLoading] = useState<LabelTypeSelectData['localState']['isLoading']>(false);

  const localState: LabelTypeSelectData['localState'] = {
    selectedLabelTypeOption,
    isLoading
  };

  const localActions: LabelTypeSelectData['localActions'] = useMemo(
    () => ({
      setSelectedLabelTypeOption,
      setIsLoading
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
