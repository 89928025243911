import type { ProgressiveSentenceNotesArgs } from './useProgressiveSentenceNotes.types';
import type { NormalizedSystemGeneratedSentenceNotesResponse } from 'shared/features/sentenceNotes/sentenceNotes.types';
import type { InfiniteData, QueryObserverResult } from '@tanstack/react-query';
import type { SentenceNotesQueryData } from './useProgressiveSentenceNotesQueries.types';

import type { useSentenceNotesReduxActions } from 'shared/features/sentenceNotes/hooks';

import * as constants from './useProgressiveSentenceNotes.constants';
import * as sentenceNotesConstants from '../../sentenceNotes.constants';
import * as errorUtils from 'utils/errors';

export const getRequestParams = ({
  args,
  prevPageParams
}: {
  args: Pick<ProgressiveSentenceNotesArgs, 'documentId' | 'type'>;
  prevPageParams: Parameters<
    ReturnType<typeof useSentenceNotesReduxActions>['getSentenceNotes']
  >[0]['params'];
}): Parameters<
  ReturnType<typeof useSentenceNotesReduxActions>['getSentenceNotes']
>[0]['params'] => {
  return {
    ...constants.INITIAL_PARAMS,
    ...prevPageParams,
    document_id: args.documentId,
    is_obligation: args.type === sentenceNotesConstants.SENTENCE_NOTE_TYPE.OBLIGATIONS
  };
};

export const getNextPageRequestParams = ({
  prevPageParams,
  response
}: {
  prevPageParams: Parameters<
    ReturnType<typeof useSentenceNotesReduxActions>['getSentenceNotes']
  >[0]['params'];
  response: Awaited<
    ReturnType<ReturnType<typeof useSentenceNotesReduxActions>['getSentenceNotes']>
  >;
}) => {
  const params = {
    ...constants.INITIAL_PARAMS,
    ...prevPageParams
  } as Required<
    Parameters<ReturnType<typeof useSentenceNotesReduxActions>['getSentenceNotes']>[0]['params']
  >;

  if (params.offset + response.count < response.total) {
    return {
      ...params,
      offset: params.offset + params.limit
    };
  }

  /**
   *
   * Passing undefined prevents useInfiniteQuery from fetching next page.
   *
   * See docs: https://tanstack.com/query/v4/docs/react/guides/infinite-queries
   *
   */
  return undefined;
};

export const fetchSentenceNotes = async ({
  params,
  events
}: {
  params: Parameters<
    ReturnType<typeof useSentenceNotesReduxActions>['getSentenceNotes']
  >[0]['params'];
  events: {
    onSentenceNotesFetch: ReturnType<typeof useSentenceNotesReduxActions>['getSentenceNotes'];
  };
}) => {
  await events.onSentenceNotesFetch({
    params
  });
};

export const getSystemGeneratedSentenceNotes = ({
  args,
  events
}: {
  args: Pick<ProgressiveSentenceNotesArgs, 'type' | 'onFetchComplete'>;
  events: {
    onFetch: () => Promise<
      QueryObserverResult<NormalizedSystemGeneratedSentenceNotesResponse, Error>
    >;
  };
}) => async ({ pages }: InfiniteData<SentenceNotesQueryData>) => {
  try {
    const hasFetchedUserGeneratedSentenceNotes = !pages?.[pages.length - 1]?.nextParams;

    if (
      args.type === sentenceNotesConstants.SENTENCE_NOTE_TYPE.OBLIGATIONS &&
      hasFetchedUserGeneratedSentenceNotes
    ) {
      await events.onFetch();
    }

    args.onFetchComplete?.();
  } catch (e) {
    errorUtils.logError(e as Error);
  }
};
