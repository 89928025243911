import type { MomentInput, Moment, unitOfTime } from 'moment';

import * as uiLib from '@compliance.ai/web-components';

import moment from 'moment';

/**
 * FIXME Don't use. It exists only to support backward compatibility.
 */
export const toUTCObject = (date: MomentInput): Moment => {
  return moment.utc(date);
};

/**
 * FIXME Don't use. It exists only to support backward compatibility.
 * Use formatDate from the UI lib instead
 */
export const formatDate = (
  date: MomentInput,
  format: string = uiLib.DATE_FORMATS.HUMANIZED_DAY_MONTH_YEAR,
  passedDateFormat: string | undefined = undefined,
  isLocal = false
): string => {
  if (isLocal) {
    return moment(date, passedDateFormat).local().format(format);
  }

  return moment(date, passedDateFormat).format(format);
};

/**
 * FIXME Don't use. It exists only to support backward compatibility.
 * Use formatDate from the UI lib instead
 */
export const formatDateWithTimezone = (
  date: MomentInput,
  timezoneName: string = uiLib.TIMEZONES.UTC,
  format: string = uiLib.DATE_FORMATS.HUMANIZED_DAY_MONTH_YEAR,
  passedDateFormat: string | undefined = undefined
): string => {
  return uiLib.formatDate(date, {
    format: format,
    timezone: timezoneName,
    passedTimezone: uiLib.TIMEZONES.UTC,
    passedDateFormat: passedDateFormat
  });
};

/**
 * FIXME Don't use. It exists only to support backward compatibility.
 * Use formatDate from the UI lib instead
 */
export const formatDateIgnoringTimezone = (
  date: MomentInput,
  format: string = uiLib.DATE_FORMATS.API_DATE
) => {
  return uiLib.formatDate(date, {
    format: format,
    timezone: uiLib.TIMEZONES.UTC
  });
};

/**
 * FIXME Don't use. It exists only to support backward compatibility
 * Use isBefore from the UI lib instead
 */
export const isBefore = ({
  date,
  beforeDate,
  granularity
}: {
  date: MomentInput;
  beforeDate: MomentInput;
  granularity?: unitOfTime.StartOf;
}): boolean => {
  return uiLib.isBefore(date, beforeDate, {
    unitOfTime: granularity
  });
};

/**
 * FIXME Don't use. It exists only to support backward compatibility
 * Use isBetween from the UI lib instead
 */
export const isBetween = ({
  date,
  fromDate,
  toDate
}: {
  date: MomentInput;
  fromDate: MomentInput;
  toDate: MomentInput;
}): boolean => {
  return uiLib.isBetween({
    date: date,
    dateA: fromDate,
    dateB: toDate
  });
};

export const getDateEndOfCurrentYear = (): string => {
  return uiLib
    .getEndOfDateUnit({
      date: new Date(),
      unitOfTime: uiLib.DATE_UNITS.YEAR
    })
    .format(uiLib.DATE_FORMATS.API_DATE_TIME);
};

export const getDateEndOfPreviousYear = (): string => {
  return moment(new Date())
    .startOf(uiLib.DATE_UNITS.YEAR)
    .subtract(1, uiLib.DATE_UNITS.DAY)
    .endOf(uiLib.DATE_UNITS.YEAR)
    .format(uiLib.DATE_FORMATS.API_DATE_TIME);
};

export const getDateEndOfCurrentQuarter = (): string => {
  return moment(new Date())
    .endOf(uiLib.DATE_UNITS.QUARTER)
    .format(uiLib.DATE_FORMATS.API_DATE_TIME);
};

export const getDateEndOfPreviousQuarter = (): string => {
  return moment(new Date())
    .subtract(1, uiLib.DATE_UNITS.QUARTER)
    .endOf(uiLib.DATE_UNITS.QUARTER)
    .format(uiLib.DATE_FORMATS.API_DATE_TIME);
};

/**
 * FIXME Don't use these. They exist only to support backward compatibility
 */
export const getDateBeforeDate = uiLib.getDateBeforeDate;
export const toDate = uiLib.toDate;
export const getStartOfUnitDate = uiLib.getStartOfDateUnit;
export const getEndOfUnitDate = uiLib.getEndOfDateUnit;
export const isWithinLastWeekPeriod = uiLib.isWithinLastWeekPeriod;
export const getDateInMilliseconds = uiLib.getDateInMilliseconds;
export const isValidDate = uiLib.isValidDate;

export const normalizeDateFilters = (
  { ...filters },
  {
    timezone = uiLib.getLocalTimezone(),
    passedTimezone = undefined
  }: {
    timezone: string;
    passedTimezone: string | undefined;
  } = {
    timezone: uiLib.getLocalTimezone(),
    passedTimezone: undefined
  }
) => {
  for (const dateFilterKey of Object.values(uiLib.DATE_FILTER_KEYS)) {
    const naiveDate = filters[dateFilterKey];

    if (naiveDate) {
      filters[dateFilterKey] = uiLib.formatDate(naiveDate, {
        format: uiLib.DATE_FORMATS.API_DATE,
        timezone: timezone,
        passedTimezone
      });
    }
  }

  return filters;
};
