import type { AlertOption, AlertsSelectProps } from '../AlertsSelect.types';
import type { AlertsSelectData } from './useAlertsSelectData.types';

import { COMPLEX_QUERY_KEY_GETTERS, QUERY_KEYS, QUERY_SECTIONS } from 'constants/QueryKeys';

import * as errorUtils from 'utils/errors';
import * as alertsApi from 'shared/features/alerts/alerts.api';
import * as alertsHelpers from 'shared/features/alerts/alerts.helpers';

import { useQuery } from '@tanstack/react-query';

export const useAlertsSelectQueries = ({
  props,
  localActions
}: {
  props: Pick<AlertsSelectProps, 'value'>;
  localActions: AlertsSelectData['localActions'];
}) => {
  const fetchPropsValueQuery = useQuery<AlertOption[], Error, AlertOption[]>({
    queryKey: COMPLEX_QUERY_KEY_GETTERS[QUERY_KEYS.ALERTS][QUERY_SECTIONS.ALERTS_SELECT](
      props.value ?? []
    ),
    queryFn: async () => {
      if (!props.value?.length) {
        localActions.setValue([]);
        return [];
      }

      const { results } = await alertsApi.fetchAllAlerts({
        alert_ids: props.value
      });

      const options = alertsHelpers.formatAlertsOptions(results);

      localActions.setValue(options);

      return options;
    },
    enabled: Boolean(props.value),
    onError: errorUtils.logReactQueryError,
    refetchOnWindowFocus: false
  });

  return {
    fetchPropsValueQuery
  };
};
