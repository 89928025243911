import * as uiLib from '@compliance.ai/web-components';

import { SUPPORTED_QUERY_PARAMS } from '../constants/PrimaryFilter';
import {
  WIDGET_UNITS_OF_TIME,
  WIDGET_REGULATION_TYPES,
  WIDGET_LEGACY_DEFAULT_CUSTOMIZATION_KEY
} from '../shared/features/widgets/widgets.constants';

export const REQUEST_DATE_FORMAT = 'MM/DD/YYYY';
export const REGULATION_PARAM_KEYS = {
  [WIDGET_REGULATION_TYPES.ACT]: 'act_id',
  [WIDGET_REGULATION_TYPES.REGULATION]: 'regulation_id'
};

export const NEWS_SOURCES_ENTITY_TYPE = 'news_sources';

export const toAgenciesIds = agency => agency.id;

export const toJurisdictionShortNames = jurisdiction => jurisdiction.short_name;

export const toShortNames = jurisdiction => {
  return jurisdiction.short_name;
};

export const toJurisdiction = reduxJurisdictions => jurisdictionId => {
  return reduxJurisdictions.items.find(
    reduxJurisdiction => reduxJurisdiction.id === jurisdictionId
  );
};

export const byEntityType = entityType => () => entity => entity.entity_type === entityType;

export const toEntitiesIds = entity => entity.entity_id;

export const getDefaultNewsSourceIds = reduxEntities => {
  return reduxEntities.followed_entities
    .filter(byEntityType(NEWS_SOURCES_ENTITY_TYPE))
    .map(toEntitiesIds);
};

export const convertWidgetPropertiesToParams = (
  isAllSourcedTabActive,
  { reduxEntities, reduxAgencies, reduxJurisdictions, reduxDefaults },
  {
    widgetDocTypes,
    widgetJurisdictions,
    widgetAgencies,
    widgetNews,
    widgetRegulation,
    widgetRegulationType,
    widgetTimeframe
  }
) => {
  const publishedFrom = getDateFromTimeframe({
    timeframe: widgetTimeframe
  });

  let docTypes;
  let jurisdictions;
  let agencies;
  let newsSources;
  let regulation;
  let regulationType;

  const {
    defaultDocTypes,
    defaultJurisdictions,
    defaultAgencies,
    defaultNewsSources,
    defaultRegulation,
    defaultRegulationType
  } = reduxDefaults;

  docTypes = setParamValue({
    widgetProperty: widgetDocTypes,
    defaultCustomizationValue: defaultDocTypes,
    defaultValue: []
  });
  jurisdictions = setParamValue({
    widgetProperty: mapJurisdictionIdsToShortNames({
      jurisdictions: widgetJurisdictions,
      reduxJurisdictions: reduxJurisdictions
    }),
    defaultCustomizationValue: defaultJurisdictions,
    defaultValue: []
  });
  agencies = setParamValue({
    widgetProperty: widgetAgencies,
    defaultCustomizationValue: defaultAgencies,
    defaultValue: []
  });
  newsSources = setParamValue({
    widgetProperty: widgetNews,
    defaultCustomizationValue: defaultNewsSources,
    defaultValue: []
  });
  regulationType = setParamValue({
    widgetProperty: widgetRegulationType,
    defaultCustomizationValue: defaultRegulationType
  });
  regulation = setRegulationValue({
    widgetProperty: widgetRegulation,
    defaultCustomizationValue: defaultRegulation
  });

  if (isAllSourcedTabActive) {
    return {
      published_from: publishedFrom,
      [REGULATION_PARAM_KEYS[regulationType]]: regulation,
      [SUPPORTED_QUERY_PARAMS.HAS_UNOFFICIAL_PUBLICATION_DATE]: 'false'
    };
  }

  return {
    [SUPPORTED_QUERY_PARAMS.CATEGORY]: docTypes,
    [SUPPORTED_QUERY_PARAMS.JURISDICTION]: jurisdictions,
    [SUPPORTED_QUERY_PARAMS.AGENCY]: agencies,
    [SUPPORTED_QUERY_PARAMS.NEWS_SOURCE_ID]: newsSources,
    [SUPPORTED_QUERY_PARAMS.PUBLISHED_FROM]: publishedFrom,
    [REGULATION_PARAM_KEYS[regulationType]]: regulation,
    [SUPPORTED_QUERY_PARAMS.HAS_UNOFFICIAL_PUBLICATION_DATE]: 'false'
  };
};

export const mapJurisdictionIdsToShortNames = ({ jurisdictions, reduxJurisdictions }) => {
  if (Array.isArray(jurisdictions)) {
    return jurisdictions.map(toJurisdiction(reduxJurisdictions)).filter(Boolean).map(toShortNames);
  }
  return jurisdictions;
};

export const setRegulationValue = ({ widgetProperty, defaultCustomizationValue }) => {
  if (widgetProperty === WIDGET_LEGACY_DEFAULT_CUSTOMIZATION_KEY) {
    return defaultCustomizationValue;
  }
  return widgetProperty ? [widgetProperty?.value] : [];
};

export const setParamValue = ({ widgetProperty, defaultCustomizationValue, defaultValue }) => {
  if (widgetProperty === WIDGET_LEGACY_DEFAULT_CUSTOMIZATION_KEY) {
    return defaultCustomizationValue;
  } else {
    return widgetProperty || defaultValue;
  }
};

export const getDateFromTimeframe = ({
  timeframe,
  timeframeUnit = WIDGET_UNITS_OF_TIME.DAY,
  initialDate = uiLib.getStartOfDate(new Date(), uiLib.DATE_UNITS.DAY, {
    format: uiLib.DATE_FORMATS.API_DATE_TIME
  }),
  isAfter = false
}) => {
  const date = isAfter
    ? uiLib.getDateAfterDate({
        date: initialDate,
        amount: timeframe,
        unitOfTime: timeframeUnit,
        shouldIgnoreTimezone: true
      })
    : uiLib.getDateBeforeDate({
        date: initialDate,
        amount: timeframe,
        unitOfTime: timeframeUnit,
        shouldIgnoreTimezone: true
      });

  return uiLib.formatDate(date, {
    format: REQUEST_DATE_FORMAT,
    passedTimezone: uiLib.TIMEZONES.UTC,
    timezone: uiLib.TIMEZONES.UTC,
    shouldKeepLocalTime: true
  });
};
