import * as dataConstants from './useObligationWorkspaceData.constants';
import * as helpers from '../ObligationWorkspace.helpers';
import * as uiLib from '@compliance.ai/web-components';
import * as env from 'shared/config';

import { useCallback } from 'react';
import { useObligationsReduxActions } from 'shared/features/obligations/hooks';
import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';
import { useSentenceNotesReduxActions } from 'shared/features/sentenceNotes/hooks';

export const useObligationWorkspaceHandlers = ({
  localState,
  localActions,
  reduxState,
  formattedData
}) => {
  const sentenceNotesReduxActions = useSentenceNotesReduxActions();
  const obligationsReduxActions = useObligationsReduxActions();
  const rightPanelReduxActions = useRightPanelReduxActions();

  const handleSentenceNoteSelectionInRightPanel = useCallback(() => {
    if (reduxState.obligations.firstSelectedId && reduxState.obligations.lastSelectedId) {
      localActions.setTextSelection({
        ...dataConstants.INITIAL_TEXT_SELECTION_STATE,
        firstSentenceId: reduxState.obligations.firstSelectedId,
        lastSentenceId: reduxState.obligations.lastSelectedId,
        startIndex: reduxState.obligations.startIndex,
        endIndex: reduxState.obligations.endIndex
      });

      uiLib.scrollToElement(
        document.getElementById(`sentence-${reduxState.obligations.firstSelectedId}`)
      );
    } else {
      localActions.setTextSelection(dataConstants.INITIAL_TEXT_SELECTION_STATE);
    }
    localActions.setFirstSentenceId(null);
    localActions.setIsUserSelected(false);
  }, [
    localActions,
    reduxState.obligations.firstSelectedId,
    reduxState.obligations.lastSelectedId,
    reduxState.obligations.startIndex,
    reduxState.obligations.endIndex
  ]);

  const handleCreateSentenceNoteModalToggle = useCallback(
    isOpen => () => {
      localActions.setCreateSentenceNoteModalOpenState(isOpen);
    },
    [localActions]
  );

  const handleNewSentenceNoteCreation = useCallback(
    async bucketId => {
      if (localState.isAddingSentenceNote) return;

      localActions.setSentenceNoteAddingState(true);

      try {
        const sentenceNote = await sentenceNotesReduxActions.createSentenceNote({
          params: {
            bucket_id: bucketId ?? null,
            document_id: parseInt(reduxState.documentId, 10),
            first_sentence_id: localState.textSelection.firstSentenceId,
            last_sentence_id: localState.textSelection.lastSentenceId,
            left_offset: localState.textSelection.startIndex,
            right_offset: localState.textSelection.endIndex,
            sentences_text: localState.textSelection.text,
            is_obligation: formattedData.isObligation,
            is_system_obligation: false
          }
        });

        if (sentenceNote.id) {
          rightPanelReduxActions.changeSentenceNoteId({
            id: sentenceNote.id
          });

          obligationsReduxActions.selectSentences(
            localState.textSelection.firstSentenceId,
            localState.textSelection.lastSentenceId,
            localState.textSelection.startIndex,
            localState.textSelection.endIndex
          );
        }

        localActions.setCreateSentenceNoteModalOpenState(false);
      } finally {
        localActions.setSentenceNoteAddingState(false);
      }
    },
    [
      localState.isAddingSentenceNote,
      localState.textSelection.firstSentenceId,
      localState.textSelection.lastSentenceId,
      localState.textSelection.startIndex,
      localState.textSelection.endIndex,
      localState.textSelection.text,
      localActions,
      sentenceNotesReduxActions,
      reduxState.documentId,
      formattedData.isObligation,
      rightPanelReduxActions,
      obligationsReduxActions
    ]
  );

  const handleCreateSentenceNote = useCallback(() => {
    if (
      (formattedData.isObligation && !env.shouldShowBucketObligations) ||
      !localState.accessibleBucketsCount
    ) {
      handleNewSentenceNoteCreation();
    } else {
      localActions.setCreateSentenceNoteModalOpenState(true);
    }
  }, [
    formattedData.isObligation,
    localState.accessibleBucketsCount,
    handleNewSentenceNoteCreation,
    localActions
  ]);

  const handleMouseDown = useCallback(
    firstSentenceId => {
      obligationsReduxActions.selectSentences(null, null);

      localActions.setIsUserSelected(false);
      localActions.setTextSelection(dataConstants.INITIAL_TEXT_SELECTION_STATE);
      localActions.setFirstSentenceId(firstSentenceId);
    },
    [localActions, obligationsReduxActions]
  );

  const handleMouseUp = useCallback(
    lastSentenceId => {
      if (localState.firstSentenceId) {
        const selection = window.getSelection();

        localActions.setIsUserSelected(true);

        if (selection.anchorOffset === selection.focusOffset) {
          const element = document.getElementById(`sentence-${localState.firstSentenceId}`);
          const elementText = element?.textContent || '';
          localActions.setTextSelection({
            ...localState.textSelection,
            firstSentenceId: localState.firstSentenceId,
            lastSentenceId: localState.firstSentenceId,
            startIndex: 1,
            endIndex: elementText.length,
            text: elementText
          });
          return;
        }

        localActions.setTextSelection({
          ...localState.textSelection,
          firstSentenceId: localState.firstSentenceId,
          lastSentenceId,
          startIndex: selection.anchorOffset,
          endIndex: selection.focusOffset,
          text: selection.toString()
        });

        window.getSelection().removeAllRanges();
      }
    },
    [localActions, localState.firstSentenceId, localState.textSelection]
  );

  const handleDocumentHTMLTransform = useCallback(
    node => {
      return helpers.transformDocumentHTML({
        node,
        textSelection: localState.textSelection,
        onMouseDown: handleMouseDown,
        onMouseUp: handleMouseUp
      });
    },
    [handleMouseDown, handleMouseUp, localState.textSelection]
  );

  const handleTooltipClose = useCallback(() => {
    localActions.setTextSelection(dataConstants.INITIAL_TEXT_SELECTION_STATE);
  }, [localActions]);

  return {
    handleSentenceNoteSelectionInRightPanel,
    handleCreateSentenceNote,
    handleCreateSentenceNoteModalToggle,
    handleNewSentenceNoteCreation,
    handleDocumentHTMLTransform,
    handleTooltipClose
  };
};
