import type { FC } from 'react';
import type { ExportProps } from './Export.types';

import * as elements from './elements';

import { ExportModal } from '../../ExportModal';

import { useExportData, useExportHandlers, useExportMutations } from './hooks';

export const Export: FC<ExportProps> = ({
  isEnabled,
  documentIds,
  annotationCount,
  isAnnotationExport,
  annotationIDs,
  onActionStart,
  actionButtonType
}) => {
  const { localState, localActions, reduxState } = useExportData();

  const mutations = useExportMutations({
    props: {
      documentIds,
      isAnnotationExport,
      annotationIDs
    },
    localState,
    localActions,
    reduxState
  });

  const handlers = useExportHandlers({
    props: { onActionStart },
    localActions,
    mutations
  });

  return (
    <>
      <elements.ExportDropdown
        enabled={isEnabled && documentIds.length > 0}
        documentIds={documentIds}
        isLoading={mutations.isLoading}
        onSelectContentType={handlers.handleContentTypeSelect}
        isAnnotationExport={isAnnotationExport}
        actionButtonType={actionButtonType}
        onDocumentsFetchSuccess={localActions.setDocuments}
      />
      {localState.isExportModalOpen && (
        <elements.ExportModal
          documentsCount={localState.documents.length}
          contentType={localState.contentType}
          onExport={mutations.createDownload.mutate}
          onClose={handlers.handleExportModalClose}
          annotationCount={annotationCount}
          isAnnotationExport={isAnnotationExport}
        />
      )}
      {localState.isDownloadModalOpen && (
        <ExportModal modalClose={handlers.handleDownloadModalClose} />
      )}
    </>
  );
};

Export.defaultProps = {
  isEnabled: true,
  isAnnotationExport: false
};
