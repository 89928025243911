import type { TasksSectionProps } from '../TasksSection.types';
import type { TasksSectionData } from './useTasksSectionData.types';

import * as constants from '../TasksSection.constants';
import * as docTasksConstants from 'constants/DocTasks';
import * as helpers from "./useTasksSectionData.helpers";

import { useMemo } from 'react';

export const useTasksSectionData = ({
  documentTasks
}: Pick<TasksSectionProps, 'documentTasks'>): TasksSectionData => {
  const formattedData: TasksSectionData['formattedData'] = useMemo(() => {
    const rows = documentTasks.map((documentTask, i) => {
      return {
        [constants.TABLE_FIELDS.ID]: documentTask.id,
        [constants.TABLE_FIELDS.STEP]: i + 1,
        [constants.TABLE_FIELDS.NAME]: documentTask.name,
        [constants.TABLE_FIELDS.DECISION]:
          documentTask.task_status === docTasksConstants.DONE ? 'Done' : 'Not Done',
        [constants.TABLE_FIELDS.DROPDOWNS]: documentTask,
        [constants.TABLE_FIELDS.DATES]: documentTask,
        [constants.TABLE_FIELDS.ATTACHMENTS]: helpers.formatAttachments(documentTask),
        [constants.TABLE_FIELDS.ASSIGNEE]: documentTask,
        [constants.TABLE_FIELDS.APPROVER]: documentTask,
        [constants.TABLE_FIELDS.APPROVAL]: documentTask,
        [constants.TABLE_FIELDS.NON_RELEVANT]: documentTask
      };
    });

    return {
      rows
    };
  }, [documentTasks]);

  return {
    formattedData
  };
};
