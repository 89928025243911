import * as jurisdictionAnalyzerConstants from 'constants/JurisdictionAnalyzer';

import { useEffect } from 'react';
import { useDidMount, useWillUnmount, useValueRef } from 'utils/hooks';

export const useJurisdictionFilterLifecycles = ({
  searchQuery,
  filterDefaultOptions,
  reduxState,
  reduxActions,
  reduxStateRef,
  reduxActionsRef
}) => {
  const filterParamsRef = useValueRef(reduxState.filterParams);
  const defaultJurisdictionRef = useValueRef(
    filterDefaultOptions[jurisdictionAnalyzerConstants.FILTER_KEY.JURISDICTION]
  );

  useDidMount(() => {
    reduxActions.fetchActs();
    reduxActionsRef.current.setJurisdictionFilter(reduxState.filterParams);
  });

  useEffect(() => {
    reduxStateRef.current = reduxState;
    reduxActionsRef.current = reduxActions;
  }, [reduxActions, reduxActionsRef, reduxState, reduxStateRef]);

  useEffect(() => {
    reduxActionsRef.current.setJurisdictionFilter({
      [jurisdictionAnalyzerConstants.FILTER_KEY.SEARCH_QUERY]: searchQuery
    });
  }, [searchQuery, reduxActionsRef, reduxState.categories]);

  useEffect(() => {
    const jurisdiction = filterParamsRef.current[
      jurisdictionAnalyzerConstants.FILTER_KEY.JURISDICTION
    ].length
      ? filterParamsRef.current[jurisdictionAnalyzerConstants.FILTER_KEY.JURISDICTION]
      : defaultJurisdictionRef.current;

    const filterParams = {
      [jurisdictionAnalyzerConstants.FILTER_KEY.JURISDICTION]: jurisdiction
    };
    reduxActionsRef.current.setJurisdictionFilter(filterParams);
  }, [filterParamsRef, defaultJurisdictionRef, reduxActionsRef]);

  useWillUnmount(() => {
    // Refs are required to have actual values instead of stale ones
    const reduxState = reduxStateRef.current;
    const reduxActions = reduxActionsRef.current;

    // Save filter only if there is no pending request
    if (reduxState.current_user.isReady) {
      reduxActions.updateCurrentUser(reduxState.current_user.user.email, {
        ...reduxState.current_user.user,
        properties: {
          ...reduxState.current_user.user.properties,
          jurisdictionFilter: reduxState.filterParams
        }
      });
    }
  });
};
