import { useEffect } from 'react';
import * as routes from 'constants/Routes';
import { useLocation } from 'react-router-dom';

export const useTimelineToolbarLifecycles = ({ reduxState, reduxActions, formattedData }) => {
  const location = useLocation();

  useEffect(() => {
    if (formattedData.dashBannersNotViewed && formattedData.dashBannersNotViewed.length > 0) {
      const dashBannerInView =
        reduxState.current_view.banner.type === formattedData.dashBannersNotViewed[0].type &&
        reduxState.current_view.banner.display;

      if (!dashBannerInView && location.pathname === routes.dashboard) {
        reduxActions.addBanner(
          formattedData.dashBannersNotViewed[0].type,
          true,
          formattedData.dashBannersNotViewed[0].content
        );
      }
    }
  }, [reduxState.current_view, reduxActions, location, formattedData.dashBannersNotViewed]);
};
