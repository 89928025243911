import type { FieldValueData } from './useFieldValueData.types';
import type { FieldValueProps } from '../FieldValue.types';

import { useMemo, useState } from 'react';
import { getContent, shouldShowExpandButton } from './useFieldValueData.helpers';

export const useFieldValueData = ({
  children,
  isExpandable
}: Pick<FieldValueProps, 'children' | 'isExpandable'>): FieldValueData => {
  const [isExpanded, setIsExpanded] = useState(false);

  const localState: FieldValueData['localState'] = {
    isExpanded
  };

  const localActions: FieldValueData['localActions'] = useMemo(
    () => ({
      setIsExpanded
    }),
    []
  );

  const formattedData: FieldValueData['formattedData'] = useMemo(() => {
    return {
      content: getContent({
        children: children,
        isExpandable: isExpandable,
        isExpanded: localState.isExpanded
      }),
      shouldShowExpandButton: shouldShowExpandButton({
        children,
        isExpandable
      })
    };
  }, [children, isExpandable, localState.isExpanded]);

  return {
    localState,
    localActions,
    formattedData
  };
};
