import PropTypes from 'prop-types';

import * as errorUtils from 'utils/errors';

import lodash from 'lodash';
import {
  SELECT_STYLES,
  SelectV2,
  FilterLayout,
  FilterHeader,
  FilterBody,
  FilterColumn,
  FilterLabeledElement,
  FilterFooter,
  DateFilter,
  AlertNameTextfield,
  DefaultFiltersSwitch,
  GroupSelectFilter,
  GROUP_SELECT_FILTER_TYPES,
  OfficialPubDateCheckbox
} from 'common';
import { FILTER_KEY, ERROR_KEY } from 'constants/JurisdictionAnalyzer';
import { FILTER_KEY as PRIMARY_FILTER_KEY } from 'constants/PrimaryFilter';
import { FooterButtons } from './elements';
import {
  formatValueToNumber,
  formatValueToDate,
  formatValueForFilter,
  insertCountriesToJurisdictions,
  formatConceptValue,
  formatDocTypeValue,
  applyFilters,
  formatQueryStringFromFilterParams
} from './JurisdictionAnalyzerFilter.helpers';

import {
  useJurisdictionFilterData,
  useJurisdictionFilterLifecycles,
  useJurisdictionReduxActions
} from './hooks';
import { useRef } from 'react';
import { useFilterDefaultOptions, useFilterOptions } from 'shared/features/filters/hooks';
import { useNavigate } from 'react-router-dom';

import './_jurisdiction-analyzer-filter.scss';

const JurisdictionAnalyzerFilter = ({ searchQuery }) => {
  const navigate = useNavigate();

  const {
    localState,
    localActions,
    reduxState,
    isAlertEditMode,
    filterErrors
  } = useJurisdictionFilterData();
  const reduxActions = useJurisdictionReduxActions(reduxState);

  const reduxStateRef = useRef(reduxState);
  const reduxActionsRef = useRef(reduxActions);

  const primaryFilterOptions = useFilterOptions();
  const primaryFilterDefaultOptions = useFilterDefaultOptions();

  const filterOptions = {
    [FILTER_KEY.CATEGORY]: primaryFilterOptions[PRIMARY_FILTER_KEY.DOC_TYPES],
    [FILTER_KEY.TOPIC_ID]: primaryFilterOptions[PRIMARY_FILTER_KEY.TOPICS],
    [FILTER_KEY.JURISDICTION]: primaryFilterOptions[PRIMARY_FILTER_KEY.JURISDICTIONS],
    [FILTER_KEY.REGULATION_ID]: primaryFilterOptions[PRIMARY_FILTER_KEY.REGULATIONS],
    [FILTER_KEY.CONCEPT_ID]: primaryFilterOptions[PRIMARY_FILTER_KEY.CONCEPTS],
    [FILTER_KEY.LANGUAGE_ID]: primaryFilterOptions[PRIMARY_FILTER_KEY.LANGUAGE]
  };
  const filterDefaultOptions = {
    [FILTER_KEY.JURISDICTION]: primaryFilterDefaultOptions[PRIMARY_FILTER_KEY.JURISDICTIONS],
    [FILTER_KEY.TOPIC_ID]: primaryFilterDefaultOptions[PRIMARY_FILTER_KEY.TOPICS]
  };

  useJurisdictionFilterLifecycles({
    searchQuery,
    filterDefaultOptions,
    reduxState,
    reduxActions,
    reduxStateRef,
    reduxActionsRef
  });

  const handleDefaultFiltersToggle = () => {
    if (reduxState.defaultFilters) {
      const filterParams = {
        [FILTER_KEY.JURISDICTION]: [],
        [FILTER_KEY.TOPIC_ID]: []
      };
      reduxActions.setJurisdictionFilter(filterParams);
    } else {
      const filterParams = {
        [FILTER_KEY.JURISDICTION]: filterDefaultOptions[FILTER_KEY.JURISDICTION],
        [FILTER_KEY.TOPIC_ID]: filterDefaultOptions[FILTER_KEY.TOPIC_ID]
      };
      reduxActions.setJurisdictionFilter(filterParams);
    }
    reduxActions.toggleJurisdictionFilterDefaultFilters(!reduxState.defaultFilters);
  };

  const handleChange = (filterKey, filterValue, isObj = false) => {
    const filterParams = formatValueForFilter(filterKey, filterValue, isObj);

    reduxActions.toggleJurisdictionFilterDefaultFilters(false);
    reduxActions.setJurisdictionFilter(filterParams);
  };

  const handleJurisdictionsChange = jurisdictions => {
    const jurisdictionsWithCountries = insertCountriesToJurisdictions(
      reduxState.jurisdictions,
      jurisdictions
    );
    handleChange([FILTER_KEY.JURISDICTION], jurisdictionsWithCountries);
  };

  const handleErrorChange = (errorKey, errorValue) => {
    reduxActions.setJurisdictionFilterErrors({
      [errorKey]: errorValue
    });
  };

  const handleActsSearchRequest = async inputValue => {
    try {
      if (!inputValue || inputValue.length < 3) {
        return [];
      }

      const response = await reduxActions.fetchAutoComplete(inputValue, FILTER_KEY.ACTS);

      return response.results.map(item => ({
        label: item.name,
        value: item.id
      }));
    } catch (e) {
      errorUtils.logError(e);

      return [];
    }
  };

  const handleFiltersSubmit = ({ shouldClearAlert }) => {
    applyFilters({
      reduxState,
      reduxActions,
      shouldClearAlert,
      navigate
    });
  };

  return (
    <>
      <FilterLayout className="jurisdiction-filter">
        <FilterHeader className="jurisdiction-filter__header">
          <FilterColumn>
            <FilterLabeledElement label="Concept">
              <GroupSelectFilter
                values={formatConceptValue(
                  reduxState.conceptsData.conceptsList,
                  reduxState.filterParams[FILTER_KEY.CONCEPT_ID]
                )}
                onChange={value => handleChange(FILTER_KEY.CONCEPT_ID, value)}
                options={filterOptions[FILTER_KEY.CONCEPT_ID]}
                type={GROUP_SELECT_FILTER_TYPES.SECONDARY}
                isMulti={false}
              />
            </FilterLabeledElement>
          </FilterColumn>
        </FilterHeader>
        <FilterHeader className="jurisdiction-filter__sub_header">
          {isAlertEditMode && (
            <AlertNameTextfield
              isDisabled={!reduxState.editAlertId}
              value={localState.alertName || reduxState.current_view.alertName}
              onChange={localActions.changeAlertName}
            />
          )}
          <DefaultFiltersSwitch
            isChecked={reduxState.defaultFilters}
            onChange={handleDefaultFiltersToggle}
            onClearAll={reduxActions.clearJurisdictionFilter}
          />
        </FilterHeader>
        <FilterBody className="jurisdiction-filter__body">
          <FilterColumn>
            <DateFilter
              startDate={formatValueToDate(reduxState.filterParams[FILTER_KEY.PUBLISHED_FROM])}
              endDate={formatValueToDate(reduxState.filterParams[FILTER_KEY.PUBLISHED_TO])}
              onChange={handleChange}
              errors={reduxState.errors}
              onError={handleErrorChange}
              areDatesRequired={false}
              startDateKey={FILTER_KEY.PUBLISHED_FROM}
              endDateKey={FILTER_KEY.PUBLISHED_TO}
              dateOrderErrorKey={ERROR_KEY.PUBLISHED_ORDER}
              subject="Publication"
              customLabelElements={
                <OfficialPubDateCheckbox
                  isChecked={
                    !Boolean(reduxState.filterParams[FILTER_KEY.HAS_UNOFFICIAL_PUBLICATION_DATE])
                  }
                  onChange={isChecked =>
                    handleChange(FILTER_KEY.HAS_UNOFFICIAL_PUBLICATION_DATE, !isChecked)
                  }
                />
              }
            />
            <FilterLabeledElement label="Jurisdiction:">
              <SelectV2
                options={filterOptions[FILTER_KEY.JURISDICTION]}
                onChange={handleJurisdictionsChange}
                value={reduxState.filterParams[FILTER_KEY.JURISDICTION]}
                styles={SELECT_STYLES.FILTER}
                closeMenuOnSelect={false}
                isMulti
              />
            </FilterLabeledElement>
            <FilterLabeledElement label="Topics:">
              <SelectV2
                options={filterOptions[FILTER_KEY.TOPIC_ID]}
                value={formatValueToNumber(reduxState.filterParams[FILTER_KEY.TOPIC_ID])}
                onChange={value => handleChange(FILTER_KEY.TOPIC_ID, value)}
                styles={SELECT_STYLES.FILTER}
                closeMenuOnSelect={false}
                isMulti
              />
            </FilterLabeledElement>
          </FilterColumn>
          <FilterColumn>
            <FilterLabeledElement label="Document Type:">
              <GroupSelectFilter
                values={formatDocTypeValue(
                  reduxState.docTypes.docTypes,
                  reduxState.filterParams[FILTER_KEY.CATEGORY]
                )}
                onChange={value => handleChange(value, FILTER_KEY.CATEGORY)}
                options={filterOptions[FILTER_KEY.CATEGORY]}
              />
            </FilterLabeledElement>
            <FilterLabeledElement label="Regulation:">
              <SelectV2
                options={filterOptions[FILTER_KEY.REGULATION_ID]}
                value={formatValueToNumber(reduxState.filterParams[FILTER_KEY.REGULATION_ID])}
                onChange={value => handleChange(FILTER_KEY.REGULATION_ID, value)}
                styles={SELECT_STYLES.FILTER}
                closeMenuOnSelect={false}
                isMulti
              />
            </FilterLabeledElement>
            <FilterLabeledElement label="Act:">
              <SelectV2
                value={reduxState.filterParams[FILTER_KEY.ACT_ID]}
                onChange={value => handleChange(FILTER_KEY.ACT_ID, value, true)}
                loadOptions={handleActsSearchRequest}
                styles={SELECT_STYLES.FILTER}
                isMulti
                isAsync
              />
            </FilterLabeledElement>
            <FilterLabeledElement label="Language:">
              <SelectV2
                styles={SELECT_STYLES.FILTER}
                options={filterOptions[FILTER_KEY.LANGUAGE_ID]}
                value={formatValueToNumber(reduxState.filterParams[FILTER_KEY.LANGUAGE_ID])}
                onChange={value => handleChange(FILTER_KEY.LANGUAGE_ID, lodash.get(value, 'value'))}
              />
            </FilterLabeledElement>
          </FilterColumn>
        </FilterBody>
        <FilterFooter
          className="jurisdiction-filter__footer"
          error={!lodash.isEmpty(filterErrors) && filterErrors.join(', ')}
          buttons={
            <FooterButtons
              onSubmit={handleFiltersSubmit}
              urlParams={formatQueryStringFromFilterParams({
                filterParams: reduxState.filterParams
              })}
            />
          }
        />
      </FilterLayout>
    </>
  );
};

JurisdictionAnalyzerFilter.propTypes = {
  searchQuery: PropTypes.string
};

JurisdictionAnalyzerFilter.defaultProps = {
  searchQuery: ''
};

export default JurisdictionAnalyzerFilter;
