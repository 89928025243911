import { useSelector } from 'react-redux';
import { getExpertNamesByDocumentId } from 'shared/features/expertConnection/expertConnection.selectors';

export const useExpertConnectionTabData = ({ document }) => {
  const reduxState = useSelector(state => ({
    expertNames: getExpertNamesByDocumentId(document.id)(state)
  }));

  return {
    reduxState
  };
};
