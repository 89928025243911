import type { TaskEdgeLabelsCombinerProps } from './TaskEdgeLabelsCombiner.types';

import React, { memo } from 'react';
import './_task-edge-labels-combiner.scss';

export const TaskEdgeLabelsCombiner = memo(({ labels }: TaskEdgeLabelsCombinerProps) => {
  return (
    <tspan textAnchor="middle">
      {labels.filter(Boolean).map((label, i, filteredLabels) => {
        const isFirst = i === 0;
        const isLast = i === filteredLabels.length - 1;

        return (
          <>
            <tspan dy={isFirst ? '' : '1.7em'} textAnchor="middle">
              {label}
            </tspan>
            {!isLast && (
              <tspan
                x="0"
                dy="1.4em"
                textAnchor="middle"
                className="task-edge-labels-combiner__clause"
              >
                or
              </tspan>
            )}
          </>
        );
      })}
    </tspan>
  );
});
