import type { NormalizedBucket } from 'shared/features/buckets/buckets.actions.types';
import type { ComponentProps, ComponentType } from 'react';
import type { ActionModalProps } from './OrganizationBuckets.types';

import * as uiLib from '@compliance.ai/web-components';
import * as env from 'shared/config';
import * as elements from './elements';
import * as bucketsConstants from 'shared/features/buckets/buckets.constants';

export const BUCKETS_PER_PAGE = 20;

export enum ORG_BUCKETS_TABLE_FIELDS {
  ID = 'id',
  NAME = 'name',
  ANNOTATION_COUNT = 'annotationCount',
  OBLIGATION_COUNT = 'obligationCount',
  LABEL_COUNT = 'labelCount',
  WORKFLOW_COUNT = 'workflowCount',
  DOCUMENTS_COUNT = 'documentCount',
  CREATED_AT = 'createdAt',
  LAST_UPDATED_AT = 'lastUpdatedAt',
  CREATED_BY = 'createdBy',
  WHO_HAS_ACCESS = 'whoHasAccess',
  NORMALIZED_BUCKET = 'normalizedBucket'
}

export enum FILTER_KEY {
  CREATED_BY = 'Created by'
}

export enum API_KEYS {
  LIMIT = 'limit',
  OFFSET = 'offset',
  NAME = 'name',
  CREATED_BY = 'created_by'
}

export enum BUCKETS_FILTER_KEY {
  CREATED_BY = 'Created by'
}

export const PUBLIC_BUCKET_DATA: Omit<
  NormalizedBucket,
  | 'annotationCount'
  | 'obligationCount'
  | 'labelCount'
  | 'workflowCount'
  | 'organizationDocumentCount'
> = {
  id: null,
  name: bucketsConstants.DEFAULT_BUCKET_NAME,
  createdAt: (null as unknown) as NormalizedBucket['createdAt'],
  lastUpdatedAt: (null as unknown) as NormalizedBucket['lastUpdatedAt'],
  createdBy: {
    id: null,
    email: 'Organization'
  },
  parent: null,
  permissions: null,
  isEditable: false
};

export const COLUMNS: uiLib.Column[] = [
  {
    title: 'Collection name',
    dataKey: ORG_BUCKETS_TABLE_FIELDS.NAME,
    Component: elements.BucketNameCell,
    width: '25rem'
  },
  {
    title: 'Annotations',
    dataKey: ORG_BUCKETS_TABLE_FIELDS.ANNOTATION_COUNT,
    Component: elements.BucketAttributeLinkCell,
    width: uiLib.TABLE_COLUMN_WIDTH.XS
  },
  ...(env.shouldShowObligationsLink
    ? [
        {
          title: 'Obligations',
          dataKey: ORG_BUCKETS_TABLE_FIELDS.OBLIGATION_COUNT,
          Component: elements.BucketAttributeLinkCell,
          width: uiLib.TABLE_COLUMN_WIDTH.XS
        }
      ]
    : []),
  {
    title: 'Labels',
    dataKey: ORG_BUCKETS_TABLE_FIELDS.LABEL_COUNT,
    Component: elements.BucketAttributeLinkCell,
    width: uiLib.TABLE_COLUMN_WIDTH.XS
  },
  ...(env.shouldShowBucketWorkflows
    ? [
        {
          title: 'Workflows',
          dataKey: ORG_BUCKETS_TABLE_FIELDS.WORKFLOW_COUNT,
          Component: elements.BucketAttributeLinkCell,
          width: uiLib.TABLE_COLUMN_WIDTH.XS
        }
      ]
    : []),
  ...(env.shouldShowBucketDocuments
    ? [
        {
          title: 'Organization Documents',
          dataKey: ORG_BUCKETS_TABLE_FIELDS.DOCUMENTS_COUNT,
          Component: elements.BucketAttributeLinkCell,
          width: uiLib.TABLE_COLUMN_WIDTH.XS
        }
      ]
    : []),
  {
    title: 'Date created',
    dataKey: ORG_BUCKETS_TABLE_FIELDS.CREATED_AT,
    Component: uiLib.DateCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Last updated',
    dataKey: ORG_BUCKETS_TABLE_FIELDS.LAST_UPDATED_AT,
    Component: uiLib.DateCell,
    width: uiLib.TABLE_COLUMN_WIDTH.S
  },
  {
    title: 'Created by',
    dataKey: ORG_BUCKETS_TABLE_FIELDS.CREATED_BY,
    Component: uiLib.TextCell,
    width: uiLib.TABLE_COLUMN_WIDTH.M
  },
  {
    title: 'Who has access',
    dataKey: ORG_BUCKETS_TABLE_FIELDS.WHO_HAS_ACCESS,
    Component: elements.BucketPermissionsCell
  }
];

export enum BUCKET_ACTION {
  ADD = 'ADD',
  RENAME = 'RENAME',
  SHARE = 'SHARE',
  DELETE = 'DELETE'
}

export const MODALS: Record<BUCKET_ACTION, ComponentType<ActionModalProps>> = {
  [BUCKET_ACTION.ADD]: elements.AddBucketModal,
  [BUCKET_ACTION.RENAME]: elements.RenameBucketModal,
  [BUCKET_ACTION.SHARE]: elements.ShareBucketModal,
  [BUCKET_ACTION.DELETE]: elements.DeleteBucketModal
};

export const BUCKET_TOOLBAR_ACTIONS: Partial<Record<
  BUCKET_ACTION,
  {
    Icon: ComponentType;
  }
>> = {
  [BUCKET_ACTION.ADD]: {
    Icon: uiLib.IconAdd
  }
};

export const BUCKET_DROPDOWN_ACTIONS: ComponentProps<
  typeof elements.BucketActionsDropdown
>['actions'] = [
  {
    Icon: uiLib.IconEdit,
    label: 'Rename',
    value: BUCKET_ACTION.RENAME
  },
  {
    Icon: uiLib.IconPersonAdd,
    label: 'Share',
    value: BUCKET_ACTION.SHARE
  },
  {
    Icon: uiLib.IconDelete,
    label: 'Delete',
    value: BUCKET_ACTION.DELETE
  }
];
