import type {
  TaskAssigneeLocalActions,
  TaskAssigneeFormattedData
} from './useTaskAssigneeData.types';
import type { TaskAssigneeProps } from '../TaskAssignee.types';
import type { TaskAssigneeHandlers } from './useTaskAssigneeHandlers.types';

export const useTaskAssigneeHandlers = ({
  props,
  localActions,
  formattedData
}: {
  props: Pick<TaskAssigneeProps, 'documentTask' | 'onChange'>;
  localActions: TaskAssigneeLocalActions;
  formattedData: TaskAssigneeFormattedData;
}): TaskAssigneeHandlers => {
  const handleAssigneeClick: TaskAssigneeHandlers['handleAssigneeClick'] = () => {
    if (!formattedData.isDisabled) {
      localActions.setEditableState(true);
    }
  };

  const handleAssigneeChange: TaskAssigneeHandlers['handleAssigneeChange'] = assigeeOption => {
    props.onChange([
      {
        task_id: props.documentTask.id,
        assignee: assigeeOption.value
      }
    ]);
  };

  const handleAssigneeSelectClose: TaskAssigneeHandlers['handleAssigneeSelectClose'] = () => {
    localActions.setEditableState(false);
  };

  return {
    handleAssigneeClick,
    handleAssigneeChange,
    handleAssigneeSelectClose
  };
};
