import { DOCUMENT_CATEGORIES } from 'constants/DocumentConstants';

export const categories_skipped_on_timeline = [
  DOCUMENT_CATEGORIES.ENFORCEMENT_METADATA,
  DOCUMENT_CATEGORIES.STATE_CODE,
  DOCUMENT_CATEGORIES.STATE_CODE_NAVIGATION,
  DOCUMENT_CATEGORIES.STATUTE,
  DOCUMENT_CATEGORIES.STATUTE_NAVIGATION,
  DOCUMENT_CATEGORIES.STATUTES,
  DOCUMENT_CATEGORIES.US_CODE,
  DOCUMENT_CATEGORIES.US_CODE_NAVIGATION,
  DOCUMENT_CATEGORIES.US_PUBLIC_LAW,
  DOCUMENT_CATEGORIES.CFR,
  DOCUMENT_CATEGORIES.CFR_NAVIGATION
];

export const categories_skipped_in_date_search = [
  DOCUMENT_CATEGORIES.STATE_CODE,
  DOCUMENT_CATEGORIES.US_CODE,
  DOCUMENT_CATEGORIES.US_CODE_NAVIGATION,
  DOCUMENT_CATEGORIES.STATE_CODE_NAVIGATION,
  DOCUMENT_CATEGORIES.US_PUBLIC_LAW,
  DOCUMENT_CATEGORIES.US_PUBLIC_LAW_NAVIGATION,
  DOCUMENT_CATEGORIES.STATUE,
  DOCUMENT_CATEGORIES.STATUTE_NAVIGATION,
  DOCUMENT_CATEGORIES.STATUES
];

export const categories_skipped_on_search = [DOCUMENT_CATEGORIES.ENFORCEMENT_METADATA];

export const CATEGORIES = {
  ENFORCEMENTS: 'Enforcements'
};
