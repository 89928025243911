import type { ProgressiveSentenceNotesHandlers } from './useProgressiveSentenceNotesHandlers.types';

import { useEffect } from 'react';

export const useProgressiveSentenceNotesLifecycle = ({
  onNextSentenceNotesFetch
}: {
  onNextSentenceNotesFetch: ProgressiveSentenceNotesHandlers['handleNextSentenceNotesFetch'];
}) => {
  useEffect(onNextSentenceNotesFetch, [onNextSentenceNotesFetch]);
};
