import type { DocumentTaskItemHandlers } from './useDocumentTaskItemHandlers.types';
import type { DocumentTaskItemProps } from '../DocumentTaskItem.types';

export const useDocumentTaskItemHandlers = ({
  props
}: {
  props: Pick<DocumentTaskItemProps, 'onTaskUpdate'>;
}): DocumentTaskItemHandlers => {
  const handleSelectChange: DocumentTaskItemHandlers['handleSelectChange'] = (field, val) => {
    props.onTaskUpdate({
      field,
      val
    });
  };

  return {
    handleSelectChange
  };
};
