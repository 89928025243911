import { REDUCER_NAME } from './folders.reducer';
import { createSelector } from '@reduxjs/toolkit';

/**
 *
 * Folders reducer selector
 */
export const getFoldersReducer = state => state[REDUCER_NAME];

/**
 *
 * Common folder selectors
 */
export const getFoldersLoadingState = createSelector(getFoldersReducer, state => state.isLoading);
export const getFoldersList = createSelector(getFoldersReducer, state => state.folders);
export const getCurrentFolder = createSelector(getFoldersReducer, state => state.currentFolder);
export const getFolderPathsList = createSelector(getFoldersReducer, state => state.paths);
export const getFoldersTotalCount = createSelector(getFoldersReducer, state => state.totalCount);
export const getFoldersRefreshTrigger = createSelector(
  getFoldersReducer,
  state => state.foldersTrigger
);
