import type { PlainTextProps } from '../PlainText.types';
import type {
  JsonLogicTreeVariable,
  SupportedAsyncAdvancedSearchKey
} from './usePlainTextData.types';

import { ADVANCED_SEARCH_KEY_FIELD, ASYNC_DATA_CONFIG, DATA_CONFIG } from '../PlainText.constants';
import { Utils } from 'react-awesome-query-builder';
import lodash from 'lodash';

export const getJsonLogicTreeVarsPaths = ({
  jsonLogicTree,
  jsonLogicVarFieldKey,
  initialJsonLogicTree,
  prevPath = '',
  prePrevPath = ''
}: {
  jsonLogicTree: ReturnType<typeof Utils.jsonLogicFormat>['logic'];
  jsonLogicVarFieldKey: string;
  initialJsonLogicTree?: ReturnType<typeof Utils.jsonLogicFormat>['logic'];
  prevPath?: string;
  prePrevPath?: string;
}): string[] => {
  const result = [];

  if (!jsonLogicTree) {
    return [];
  }

  /**
   *
   * Iterate through current level of object
   *
   */
  for (const k in jsonLogicTree) {
    /**
     *
     * Found the required key
     *
     */
    if (k === jsonLogicVarFieldKey) {
      const variable = lodash.get(initialJsonLogicTree, prePrevPath);
      const variableAdvancedSearchKey = Array.isArray(variable) ? variable[0].var : null;

      /**
       *
       * Add path only if the variable config exists in constants
       *
       */
      if (
        Object.keys({ ...DATA_CONFIG, ...ASYNC_DATA_CONFIG }).includes(variableAdvancedSearchKey)
      ) {
        result.push(prePrevPath);
      }

      /**
       *
       * Continue iterating through deeper levels of object if they exist
       *
       */
    } else if (
      lodash.isObject(jsonLogicTree[k as keyof ReturnType<typeof Utils.jsonLogicFormat>['logic']])
    ) {
      /**
       * Add square brackets if current level of object is an array. Required to correctly lodash.get the value later
       */
      const formattedKey = Array.isArray(jsonLogicTree) ? `[${k}]` : k;
      /**
       * Add period only if current level of object is not an array
       */
      const path = prevPath + (prevPath && !Array.isArray(jsonLogicTree) ? '.' : '') + formattedKey;

      /**
       *
       * Continue iteration with the next level of depth
       *
       */
      result.push(
        ...getJsonLogicTreeVarsPaths({
          jsonLogicTree:
            jsonLogicTree[k as keyof ReturnType<typeof Utils.jsonLogicFormat>['logic']],
          jsonLogicVarFieldKey,
          initialJsonLogicTree: initialJsonLogicTree ?? jsonLogicTree,
          prevPath: path,
          prePrevPath: prevPath
        })
      );
    }
  }

  /**
   * Return result if no more levels of depth left
   */
  return result;
};

export const getJsonLogicTreeVars = ({
  jsonLogicTree,
  jsonLogicTreeVarsPaths
}: {
  jsonLogicTree: ReturnType<typeof Utils.jsonLogicFormat>['logic'];
  jsonLogicTreeVarsPaths: string[];
}): JsonLogicTreeVariable[] => {
  return jsonLogicTreeVarsPaths.reduce((variables, variablePath) => {
    return [...variables, lodash.get(jsonLogicTree, variablePath)];
  }, [] as JsonLogicTreeVariable[]);
};

export const getJsonLogicTreeVarsValues = ({
  jsonLogicTreeVars
}: {
  jsonLogicTreeVars: JsonLogicTreeVariable[];
}): (null | number | number[])[] => {
  return jsonLogicTreeVars.reduce((values: (null | number | number[])[], variable) => {
    /**
     *
     * Check if the value operator is "IN", and it's an array of numbers
     *
     */
    if (
      typeof variable[1] === 'object' &&
      'in' in variable[1] &&
      Array.isArray(variable[1].in) &&
      Array.isArray(variable[1].in[1]) &&
      variable[1].in[1].every(value => isFinite(Number(value)))
    ) {
      const listOfNumbers = variable[1].in[1].map(Number) as number[];

      return [...values, listOfNumbers];
    }

    /**
     *
     * Check if the value operator is "IN", and it's an array of SelectOptions
     *
     */
    if (
      typeof variable[1] === 'object' &&
      'in' in variable[1] &&
      Array.isArray(variable[1].in) &&
      Array.isArray(variable[1].in[1]) &&
      variable[1].in[1].every(option => isFinite(Number(option?.value)))
    ) {
      const listOfNumbers = variable[1].in[1].map(option => option.value) as number[];

      return [...values, listOfNumbers];
    }

    /**
     *
     * Check if the value is a finite number. At this point we don't care about the operator
     *
     */
    if (isFinite(Number(variable[1]))) {
      return [...values, Number(variable[1])];
    }

    return [...values, null];
  }, []);
};

export const getJsonLogicTreeAsyncVarsValues = ({
  jsonLogicTreeVars
}: {
  jsonLogicTreeVars: ReturnType<typeof getJsonLogicTreeVars>;
}): Record<SupportedAsyncAdvancedSearchKey, number[]> => {
  return Object.keys(ASYNC_DATA_CONFIG).reduce((ids, advancedSearchKey) => {
    const variableValues = getJsonLogicTreeVarsValues({
      jsonLogicTreeVars: jsonLogicTreeVars.filter(variable => {
        return advancedSearchKey === variable[0].var;
      })
    });

    return {
      ...ids,
      [advancedSearchKey]: variableValues.flat()
    };
  }, {} as Record<SupportedAsyncAdvancedSearchKey, number[]>);
};

export const getParsedJsonConfig = ({
  tree,
  config
}: {
  tree: PlainTextProps['tree'];
  config: PlainTextProps['config'];
}): {
  jsonLogicTree: ReturnType<typeof Utils.jsonLogicFormat>['logic'];
  jsonLogicTreeVarsPaths: ReturnType<typeof getJsonLogicTreeVarsPaths>;
  jsonLogicTreeVars: ReturnType<typeof getJsonLogicTreeVars>;
  jsonLogicTreeVarsValues: ReturnType<typeof getJsonLogicTreeVarsValues>;
  jsonLogicTreeAsyncVarsValues: ReturnType<typeof getJsonLogicTreeAsyncVarsValues>;
} => {
  const jsonLogicTree = Utils.jsonLogicFormat(tree, config).logic;
  const jsonLogicTreeVarsPaths = getJsonLogicTreeVarsPaths({
    jsonLogicTree,
    jsonLogicVarFieldKey: ADVANCED_SEARCH_KEY_FIELD
  });
  const jsonLogicTreeVars = getJsonLogicTreeVars({
    jsonLogicTree,
    jsonLogicTreeVarsPaths
  });
  const jsonLogicTreeVarsValues = getJsonLogicTreeVarsValues({
    jsonLogicTreeVars
  });
  const jsonLogicTreeAsyncVarsValues = getJsonLogicTreeAsyncVarsValues({
    jsonLogicTreeVars
  });

  return {
    jsonLogicTree,
    jsonLogicTreeVarsPaths,
    jsonLogicTreeVars,
    jsonLogicTreeVarsValues,
    jsonLogicTreeAsyncVarsValues
  };
};
