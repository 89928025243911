import type * as apiTypes from './documentFields.api.types';
import type * as reducerTypes from './documentFields.reducer.types';

import * as constants from './documentFields.constants';
import * as errorUtils from 'utils/errors';

export const normalizeDocFieldsOptions = (
  response: apiTypes.FetchOrgDocsMappingFieldsResponse
): reducerTypes.DocFieldOption[] => {
  if (!Array.isArray(response?.document_fields)) {
    errorUtils.logError(new Error('Wrong doc fields returned from response'), '', {
      isSilent: true
    });
    return [];
  } else {
    return response.document_fields.map(option => ({
      value: option?.value,
      label: option?.label,
      isMandatory: Boolean(option?.required),
      isMetadataKeyField: Boolean(option?.value === constants.EXPECTED_DOC_FIELDS.FILE_NAME),
      inputPlaceholder: option?.label_text
    }));
  }
};
