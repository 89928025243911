import * as viewSelectors from 'shared/features/view/view.selectors';
import * as userSelectors from 'shared/features/user/user.selectors';
import * as rightPanelSelectors from 'shared/features/rightPanel/rightPanel.selectors';
import * as authSelectors from 'shared/features/auth/auth.selectors';
import * as skus from 'constants/SKUs';

import {
  checkPDFAvailability,
  checkSourceLinkAvailability,
  documentIsPremiumContent,
  getDocumentRestrictionType
} from 'utils/documents/documentsAccess';
import { getDocumentUrl } from 'utils/documents/documentProperties';

import { useSelector } from 'react-redux';

export const useLinksData = ({ document }) => {
  const reduxState = useSelector(state => ({
    isMobileView: viewSelectors.isMobileView(state),
    currentUserEmail: userSelectors.getUserEmail(state),
    JWTToken: authSelectors.getJWTToken(state),
    rightPanelDocumentId: rightPanelSelectors.getRightPanelDocumentId(state),
    isOverlayOpen: rightPanelSelectors.getOverlayOpenState(state),
    overlayType: rightPanelSelectors.getOverlayType(state),
    currentViewId: viewSelectors.getCurrentViewId(state),
    shouldCloseRightPanelOnOverlayClose: rightPanelSelectors.getRightPanelOverlayCloseFlag(state),
    shouldOpenOverlayWithoutQueryParams: rightPanelSelectors.getRightPanelOverlayQueryParamsFlag(
      state
    )
  }));

  const isPremiumContent = documentIsPremiumContent({ document });

  const pdfLabel = !reduxState.isMobileView && reduxState.isOverlayOpen ? 'Close PDF' : 'View PDF';
  const viewLabel = isPremiumContent
    ? 'View or License on Source Website'
    : 'View on Source Website';

  const canShowPDFButton = checkPDFAvailability(document);
  const canShowSourceLink = checkSourceLinkAvailability(document);

  const documentRestrictionType = getDocumentRestrictionType({ document });
  const canShowRestrictionModal =
    documentRestrictionType === skus.SKU_RESTRICTION_TYPES.AUTOTRANSLATE ||
    documentRestrictionType === skus.SKU_RESTRICTION_TYPES.JURISDICTION_CONTENT ||
    documentRestrictionType === skus.SKU_RESTRICTION_TYPES.ENFORCEMENT_EXPLORER;

  const allOptions = [];

  if (canShowPDFButton) {
    allOptions.push({
      label: pdfLabel,
      value: null,
      isLink: false
    });
  }

  if (canShowSourceLink) {
    allOptions.push({
      label: viewLabel,
      value: getDocumentUrl(document),
      isLink: true
    });
  }

  if (canShowRestrictionModal) {
    allOptions.push({
      label: 'View PDF',
      value: null,
      isLink: false
    });
  }

  const primaryButtonOption = allOptions[0];
  const listOptions = allOptions.filter((_, i) => i > 0);

  return {
    reduxState,
    formattedData: {
      allOptions,
      listOptions,
      primaryButtonOption,
      canShowPDFButton,
      documentRestrictionType
    }
  };
};
