import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { LocalDateFormat } from 'utils/keyDates';
import { Button } from '@compliance.ai/web-components';
import * as routes from 'constants/Routes';

export const TEST_ID = 'timeline-toolbar-subscription-alert';

const SubscriptionAlert = ({ current_view, isOnFreeTrialSubscription, currentSubscription }) => {
  const location = useLocation();

  return (
    <div className="toolbar-search-suggest" data-testid={TEST_ID}>
      {!current_view.inMobile && (
        <div className="suggestion-btn-container">
          {!isOnFreeTrialSubscription ||
          location.pathname === routes.account ||
          location.pathname === routes.checkout ? null : (
            <div>
              <span className="freeTrialEnd">
                {`Your Free Trial Ends ${
                  currentSubscription
                    ? moment(currentSubscription.expirationDate).format(LocalDateFormat)
                    : 'soon'
                }`}
              </span>
              <Link to={routes.account}>
                <Button className="upgradeButton">Upgrade</Button>
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

SubscriptionAlert.propTypes = {
  current_view: PropTypes.shape({
    inMobile: PropTypes.bool
  }).isRequired,
  isOnFreeTrialSubscription: PropTypes.bool.isRequired,
  currentSubscription: PropTypes.shape({
    expirationDate: PropTypes.string
  })
};

export default SubscriptionAlert;
