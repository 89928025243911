import type { AgenciesSelectProps } from './AgenciesSelect.types';
import type { AgencyOption } from './hooks/useAgenciesSelectData.types';

import { Select, SELECT_TYPES } from '@compliance.ai/web-components';
import { AGENCIES_SELECT_VALUE_KEYS } from './AgenciesSelect.constants';
import { useAgenciesSelectData, useAgenciesSelectHandlers } from './hooks';

export const AgenciesSelect = (props: AgenciesSelectProps) => {
  const { reduxState, formattedData } = useAgenciesSelectData(props);

  const handlers = useAgenciesSelectHandlers({
    props
  });

  return (
    <Select
      {...props}
      type={props.type || SELECT_TYPES.DEFAULT}
      value={formattedData.value}
      options={props.isAsync ? [] : reduxState.agenciesOptions}
      withTooltips
      isAsync={props.isAsync}
      isLoading={!reduxState.areAgenciesReady}
      isDisabled={!reduxState.areAgenciesReady}
      shouldCacheFetchedOptions
      noOptionsMessage={inputValue => (inputValue ? 'No options' : 'Type to search')}
      loadOptions={handlers.handleAgenciesFetch}
      tooltipTitleRenderer={({ data }) => (data as AgencyOption).fullName}
    />
  );
};

AgenciesSelect.defaultProps = {
  isAsync: true,
  valueKey: AGENCIES_SELECT_VALUE_KEYS.ID
} as AgenciesSelectProps;
