import { SUPPORTED_QUERY_PARAMS, FILTER_KEY } from 'constants/PrimaryFilter';

export const MATCH_KEY = {
  SHORT_NAME: 'short_name',
  NAME: 'name',
  NICKNAME: 'nickname'
};

export const SUGGESTION_TO_FILTER_QUERY_PARAM = {
  agencies: SUPPORTED_QUERY_PARAMS.AGENCY_ID,
  acts: SUPPORTED_QUERY_PARAMS.ACT_ID,
  named_regulations: SUPPORTED_QUERY_PARAMS.REGULATION_ID,
  jurisdictions: SUPPORTED_QUERY_PARAMS.JURISDICTION,
  news_sources: SUPPORTED_QUERY_PARAMS.NEWS_SOURCE_ID,
  citations: SUPPORTED_QUERY_PARAMS.CITATION,
  banks: SUPPORTED_QUERY_PARAMS.BANK_ID,
  topics: SUPPORTED_QUERY_PARAMS.TOPIC_ID,
  concepts: SUPPORTED_QUERY_PARAMS.CONCEPT_ID
};

export const SUGGESTION_TO_FILTER_PARAM = {
  agencies: FILTER_KEY.AGENCIES,
  acts: FILTER_KEY.ACTS,
  named_regulations: FILTER_KEY.REGULATIONS,
  jurisdictions: FILTER_KEY.JURISDICTIONS,
  news_sources: FILTER_KEY.MAINSTREAM_NEWS_SOURCES,
  citations: FILTER_KEY.CITATION,
  banks: FILTER_KEY.BANKS,
  topics: FILTER_KEY.TOPICS,
  concepts: FILTER_KEY.CONCEPTS
};

export const SUGGESTION_PROPERTIES_TO_TAKE_VALUE_FROM = {
  agencies: 'id',
  acts: 'id',
  named_regulations: 'id',
  jurisdictions: 'short_name',
  news_sources: 'id',
  citations: 'id',
  banks: 'id',
  topics: 'id',
  concepts: 'id'
};

export const SUGGESTION_PROPERTIES_TO_TAKE_LABEL_FROM = {
  agencies: 'short_name',
  acts: 'name',
  named_regulations: 'name',
  jurisdictions: 'short_name',
  news_sources: 'name',
  citations: 'name',
  banks: 'name',
  topics: 'name',
  concepts: 'name'
};

export const SUGGESTION_TO_ID_NAME = {
  agencies: 'agency_id',
  acts: 'act_id',
  named_regulations: 'regulation_id',
  news_sources: 'news_source_id',
  citations: 'citation_id',
  concepts: 'concept_id',
  banks: 'bank_id',
  topics: 'topic_id'
};

export const FILTER_OUTSIDE_CLICK_IGNORE_CLASS = 'primary-filter-outside-click-ignore';
export const SUGGESTIONS_MENU_OUTSIDE_CLICK_IGNORE_CLASS = 'suggestions-menu-outside-click-ignore';
export const ENTER_KEY = 'Enter';
