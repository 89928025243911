import type { AgencyData } from './useAgencyData.types';
import type { AgencyProps } from '../Agency.types';

import { useMemo } from 'react';
import { getAgencies } from './useAgencyData.helpers';

export const useAgencyData = ({ document }: Pick<AgencyProps, 'document'>): AgencyData => {
  const formattedData: AgencyData['formattedData'] = useMemo(() => {
    const agencies = getAgencies({
      document: document
    });

    return {
      agencies
    };
  }, [document]);

  return {
    formattedData
  };
};
