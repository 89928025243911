import type * as widgetsApiTypes from 'shared/features/widgets/widgets.api.types';
import type * as actsApiTypes from 'shared/features/acts/acts.api.types';
import type * as banksApiTypes from 'shared/features/banks/banks.api.types';
import type * as widgetTypes from 'shared/features/widgets/widgets.types';
import type * as orgDocumentsApiTypes from 'shared/features/organizationDocuments/organizationDocuments.api.types';
import type * as labelsApiTypes from 'shared/features/labels/labels.api.types';
import type * as bucketsApiTypes from 'shared/features/buckets/buckets.api.types';
import type * as alertTypes from 'shared/features/alerts/alerts.api.types';
import type * as eitlLabelsApiTypes from 'shared/features/eitlLabels/eitlLabels.api.types';
import type * as documentsTypes from 'shared/features/documents/documents.types';
import type * as documentNotesApi from 'shared/features/documentNotes/documentNotes.api';
import type * as sentenceNotesApi from 'shared/features/sentenceNotes/sentenceNotes.api';
import type * as docTasksApi from 'shared/features/docTasks/docTasks.apiV2';
import type * as organizationApiTypes from 'shared/features/organizations/organizations.types';

/**
 *
 * Maintaining query key constants is crucial. Without a properly defined constants we'll end up with a
 * bunch of random requests that will be impossible to refactor
 *
 * Each QUERY_KEY represents some entity
 *
 */
export enum QUERY_KEYS {
  ALERTS = 'alerts',
  EITL_LABELS = 'eitlLabels',
  DROPDOWNS = 'dropdowns',
  COMMENTS = 'comments',
  DOC_TASKS = 'docTasks',
  WORKFLOWS = 'workflows',
  LABELS = 'labels',
  LABEL_TYPES = 'LABEL_TYPES',
  RELATED_ALERTS = 'relatedAlerts',
  RELATED_SEARCHES = 'relatedSearches',
  ACTS = 'acts',
  BANKS = 'banks',
  BUSINESSES = 'businesses',
  DOCUMENTS = 'documents',
  ORG_DOCUMENTS = 'orgDocuments',
  TEAMS = 'teams',
  IMPORTS = 'imports',
  DOCUMENT_UPDATE_FIELDS = 'documentUpdateFields',
  BUCKETS = 'buckets',
  RULES = 'rules',
  DOCUMENT_HTML = 'documentHTML',
  RESOURCES = 'resources',
  SENTENCE_NOTES = 'sentenceNotes',
  SYSTEM_GENERATED_SENTENCE_NOTES = 'systemGeneratedSentenceNotes',
  DOCUMENT_NOTES = 'documentNotes',
  GROUPED_TASKS = 'groupedTasks',
  DOCUMENT_VERSIONS_COUNT = 'documentVersionsCount',
  DOCUMENTS_DIFF = 'diff_documents',
  SERVICE_CONNECTORS = 'serviceConnectors',
  AGGREGATED_ENFORCEMENTS = 'aggregatedEnforcements',
  AREAS_OF_FOCUS = 'areasOfFocus'
}

/**
 *
 * Some queries may require "sub-keys" to correctly fetch the results and/or clean cache
 *
 * Each QUERY_SECTION represents some place in the app
 *
 */
export enum QUERY_SECTIONS {
  DASHBOARD = 'dashboard',
  INSIGHTS = 'insights',
  ADVANCED_SEARCH_PLAIN_TEXT_MODAL = 'advancedSearchPlainTextModal',
  APPROACHING_DEADLINES_WIDGET = 'approachingDeadlinesWidget',
  ORG_DOCUMENTS_TABLE = 'orgDocumentsTable',
  ORG_DOCS_DATA_UPLOAD = 'orgDocsDataUpload',
  TEAMS_V2 = 'teamsV2',
  TEAM_V2 = 'teamV2',
  EDIT_TEAM_MODAL = 'editTeamModal',
  BUCKETS_ACCESS_TABLE = 'bucketsAccessTable',
  BUCKET_LABELS_TABLE = 'bucketLabelsTable',
  BUCKET_ITEMS_MOVE_MODAL = 'bucketItemsMoveModal',
  BUCKET_SENTENCE_NOTES_TABLE = 'bucketSentenceNotesTable',
  LABEL_INPUT_GET_BUCKETS = 'labelInputGetBuckets',
  LABEL_INPUT_CREATE_LABEL = 'labelInputCreateLabel',
  LABELS_SELECT = 'labelsSelect',
  ALERTS_SELECT = 'alertsSelect',
  BUCKET_TAB_TITLE = 'bucketTabTitle',
  EITL_LABELS_SELECT = 'eitlLabelsSelect',
  DOCUMENT_DETAILS = 'documentDetails',
  ADD_TO_WORKFLOW_ACTION = 'addToWorkflowAction',
  PRINT_ACTION = 'printAction',
  DIFF_ACTION = 'diffAction',
  EXPORT_ACTION = 'exportAction',
  DOCUMENT_TIMELINE = 'documentTimeline',
  DOC_SYNC_UPDATE_REQUESTS = 'docSyncUpdateRequests',
  MANAGE_WORKFLOWS = 'manageWorkflows',
  DIFF_TAB = 'diffTab',
  DIFF_FILE = 'DIFF_FILE',
  RIGHT_PANEL = 'rightPanel',
  SENTENCE_NOTE_CONTENT = 'sentenceNoteContent',
  DOCUMENT_LABELS = 'documentLabels',
  TASK_COLUMNS = 'taskColumns',
  ENFORCEMENTS_CHART = 'enforcementsChart',
  GET_AREAS_OF_FOCUS = 'getAreasOfFocus',
  RENAME_AREA_OF_FOCUS = 'renameAreaOfFocus',
  REMOVE_AREA_OF_FOCUS = 'removeAreaOfFocus',
  CREATE_AREA_OF_FOCUS = 'createAreaOfFocus'
}

/**
 *
 * This constant contains query key generator functions.
 * Each of such function MUST return either a string, or an array of strings
 *
 * The functions can be on any level of depth
 *
 * Note: QUERY_KEYS must be a root key!
 *
 */
export const COMPLEX_QUERY_KEY_GETTERS = {
  [QUERY_KEYS.AGGREGATED_ENFORCEMENTS]: {
    [QUERY_SECTIONS.ENFORCEMENTS_CHART]: (params: Record<string, unknown>) => {
      return [QUERY_KEYS.AGGREGATED_ENFORCEMENTS, QUERY_SECTIONS.ENFORCEMENTS_CHART, params];
    }
  },
  [QUERY_KEYS.GROUPED_TASKS]: {
    [QUERY_SECTIONS.TASK_COLUMNS]: (
      ...args: Parameters<typeof docTasksApi.fetchDocTasksByStatus>
    ) => {
      return [QUERY_KEYS.GROUPED_TASKS, QUERY_SECTIONS.TASK_COLUMNS, ...args];
    }
  },
  [QUERY_KEYS.DOCUMENT_VERSIONS_COUNT]: {
    [QUERY_SECTIONS.DOCUMENT_DETAILS]: (args: Record<string, unknown>) => {
      return [QUERY_KEYS.DOCUMENT_VERSIONS_COUNT, QUERY_SECTIONS.DOCUMENT_DETAILS, args];
    }
  },
  [QUERY_KEYS.ALERTS]: {
    [QUERY_SECTIONS.ALERTS_SELECT]: (alertIds: alertTypes.AlertFromResponse['id'][]) => {
      return [QUERY_KEYS.ALERTS, QUERY_SECTIONS.ALERTS_SELECT, alertIds];
    }
  },
  [QUERY_KEYS.DOCUMENT_NOTES]: {
    [QUERY_SECTIONS.DOCUMENT_LABELS]: (
      args: Partial<Parameters<typeof documentNotesApi.fetchDocumentNotes>[0]>
    ) => {
      return [QUERY_KEYS.DOCUMENT_NOTES, QUERY_SECTIONS.DOCUMENT_LABELS, args];
    }
  },
  [QUERY_KEYS.SENTENCE_NOTES]: {
    [QUERY_SECTIONS.RIGHT_PANEL]: (
      args: Partial<Parameters<typeof sentenceNotesApi.getSentenceNotes>[0]>
    ) => {
      return [QUERY_KEYS.SENTENCE_NOTES, QUERY_SECTIONS.RIGHT_PANEL, args];
    },
    [QUERY_SECTIONS.SENTENCE_NOTE_CONTENT]: (
      id: Partial<Parameters<typeof sentenceNotesApi.getSentenceNotes>[0]>['id']
    ) => {
      return [QUERY_KEYS.SENTENCE_NOTES, QUERY_SECTIONS.SENTENCE_NOTE_CONTENT, id];
    }
  },
  [QUERY_KEYS.SYSTEM_GENERATED_SENTENCE_NOTES]: {
    [QUERY_SECTIONS.RIGHT_PANEL]: (
      args: Partial<Parameters<typeof sentenceNotesApi.fetchSystemGeneratedSentenceNotes>[0]>
    ) => {
      return [QUERY_KEYS.SYSTEM_GENERATED_SENTENCE_NOTES, QUERY_SECTIONS.RIGHT_PANEL, args];
    }
  },
  [QUERY_KEYS.EITL_LABELS]: {
    [QUERY_SECTIONS.EITL_LABELS_SELECT]: (
      ids: eitlLabelsApiTypes.EitlLabelFromResponse['id'][]
    ) => {
      return [QUERY_KEYS.EITL_LABELS, QUERY_SECTIONS.EITL_LABELS_SELECT, ids];
    },
    [QUERY_SECTIONS.ADVANCED_SEARCH_PLAIN_TEXT_MODAL]: (
      ids: eitlLabelsApiTypes.EitlLabelFromResponse['id'][]
    ) => {
      return [QUERY_KEYS.EITL_LABELS, QUERY_SECTIONS.ADVANCED_SEARCH_PLAIN_TEXT_MODAL, ids];
    }
  },
  [QUERY_KEYS.ORG_DOCUMENTS]: {
    [QUERY_SECTIONS.ORG_DOCS_DATA_UPLOAD]: (
      ...args: orgDocumentsApiTypes.GetOrganizationDocumentsPayload
    ) => {
      return [QUERY_KEYS.ORG_DOCUMENTS, QUERY_SECTIONS.ORG_DOCS_DATA_UPLOAD, ...args];
    }
  },
  [QUERY_KEYS.IMPORTS]: {
    [QUERY_SECTIONS.ORG_DOCUMENTS_TABLE]: ({
      importId
    }: {
      importId: orgDocumentsApiTypes.OrganizationDocumentImportFromResponse['id'];
    }) => {
      return [QUERY_KEYS.IMPORTS, QUERY_SECTIONS.ORG_DOCUMENTS_TABLE, importId];
    },
    [QUERY_SECTIONS.ORG_DOCS_DATA_UPLOAD]: ({
      importId
    }: {
      importId: orgDocumentsApiTypes.OrganizationDocumentImportFromResponse['id'];
    }) => {
      return [QUERY_KEYS.IMPORTS, QUERY_SECTIONS.ORG_DOCS_DATA_UPLOAD, importId];
    }
  },
  [QUERY_KEYS.DOCUMENTS]: {
    [QUERY_SECTIONS.DOCUMENT_TIMELINE]: (args: Record<string, unknown>) => {
      return [QUERY_KEYS.DOCUMENTS, QUERY_SECTIONS.DOCUMENT_TIMELINE, args];
    },

    [QUERY_SECTIONS.DOCUMENT_DETAILS]: (id: documentsTypes.DocumentFromResponse['id']) => {
      return [QUERY_KEYS.DOCUMENTS, QUERY_SECTIONS.DOCUMENT_DETAILS, id];
    },

    [QUERY_SECTIONS.ADD_TO_WORKFLOW_ACTION]: (ids: documentsTypes.DocumentFromResponse['id'][]) => {
      return [QUERY_KEYS.DOCUMENTS, QUERY_SECTIONS.ADD_TO_WORKFLOW_ACTION, ids];
    },

    [QUERY_SECTIONS.PRINT_ACTION]: (
      ids: documentsTypes.DocumentFromResponse['id'][],
      extraQueryKey: unknown[] = []
    ) => {
      return [QUERY_KEYS.DOCUMENTS, QUERY_SECTIONS.PRINT_ACTION, ...extraQueryKey, ids];
    },

    [QUERY_SECTIONS.DIFF_ACTION]: (
      ids: documentsTypes.DocumentFromResponse['id'][],
      extraQueryKey: unknown[] = []
    ) => {
      return [QUERY_KEYS.DOCUMENTS, QUERY_SECTIONS.DIFF_ACTION, ...extraQueryKey, ids];
    },

    [QUERY_SECTIONS.EXPORT_ACTION]: (
      ids: documentsTypes.DocumentFromResponse['id'][],
      extraQueryKey: unknown[] = []
    ) => {
      return [QUERY_KEYS.DOCUMENTS, QUERY_SECTIONS.EXPORT_ACTION, ...extraQueryKey, ids];
    },

    [QUERY_SECTIONS.DIFF_TAB]: (
      docV1: documentsTypes.DocumentFromResponse['id'] | null,
      docV2: documentsTypes.DocumentFromResponse['id'] | null
    ) => {
      return [
        QUERY_KEYS.DOCUMENTS,
        QUERY_SECTIONS.DIFF_TAB,
        {
          docV1,
          docV2
        }
      ];
    },

    [QUERY_SECTIONS.DIFF_FILE]: (
      docIdV1: documentsTypes.DocumentFromResponse['id'],
      docIdV2: documentsTypes.DocumentFromResponse['id']
    ) => {
      return [QUERY_KEYS.DOCUMENTS, QUERY_KEYS.DOCUMENTS_DIFF, { docIdV1, docIdV2 }];
    },

    [QUERY_SECTIONS.APPROACHING_DEADLINES_WIDGET]: ({
      widgetId,
      properties
    }: {
      widgetId: widgetsApiTypes.UserWidgetFromResponse['id'];
      properties: Partial<widgetTypes.WidgetProperties>;
    }) => {
      return [
        QUERY_KEYS.DOCUMENTS,
        QUERY_SECTIONS.APPROACHING_DEADLINES_WIDGET,
        widgetId,
        JSON.stringify(properties)
      ];
    },

    [QUERY_SECTIONS.DOC_SYNC_UPDATE_REQUESTS]: (id: documentsTypes.DocumentFromResponse['id']) => {
      return [QUERY_KEYS.ORG_DOCUMENTS, QUERY_SECTIONS.DOC_SYNC_UPDATE_REQUESTS, id];
    }
  },
  [QUERY_KEYS.RELATED_SEARCHES]: {
    [QUERY_SECTIONS.INSIGHTS]: (params: Record<string, unknown>[]) => {
      return [QUERY_KEYS.RELATED_SEARCHES, QUERY_SECTIONS.INSIGHTS, ...params];
    }
  },
  [QUERY_KEYS.RELATED_ALERTS]: {
    [QUERY_SECTIONS.DASHBOARD]: ({
      userWidgetId,
      properties
    }: {
      userWidgetId: widgetsApiTypes.UserWidgetFromResponse['id'];
      properties: Partial<widgetTypes.WidgetProperties>;
    }) => {
      return [QUERY_KEYS.RELATED_ALERTS, QUERY_SECTIONS.DASHBOARD, userWidgetId, properties];
    },
    [QUERY_SECTIONS.DOCUMENT_DETAILS]: (id: documentsTypes.DocumentFromResponse['id']) => {
      return [QUERY_KEYS.RELATED_ALERTS, QUERY_SECTIONS.DASHBOARD, id];
    }
  },
  [QUERY_KEYS.LABELS]: {
    [QUERY_SECTIONS.DASHBOARD]: ({
      widgetId,
      userWidgetId,
      properties
    }: {
      widgetId: widgetsApiTypes.UserWidgetFromResponse['widget_id'];
      userWidgetId: widgetsApiTypes.UserWidgetFromResponse['id'];
      properties: Partial<widgetTypes.WidgetProperties>;
    }) => {
      return [
        QUERY_KEYS.LABELS,
        QUERY_SECTIONS.DASHBOARD,
        widgetId,
        userWidgetId,
        JSON.stringify(properties)
      ];
    },
    [QUERY_SECTIONS.LABELS_SELECT]: ({
      labelIds
    }: {
      labelIds: labelsApiTypes.LabelFromResponse['id'][];
    }) => {
      return [QUERY_KEYS.LABELS, QUERY_SECTIONS.LABELS_SELECT, { labelIds }];
    }
  },
  [QUERY_KEYS.TEAMS]: {
    [QUERY_SECTIONS.EDIT_TEAM_MODAL]: ({
      name,
      id,
      groupingType
    }: {
      id: number;
      name: string;
      groupingType?: string;
    }) => {
      return [QUERY_KEYS.TEAMS, QUERY_SECTIONS.EDIT_TEAM_MODAL, id, name, groupingType];
    },
    [QUERY_SECTIONS.TEAM_V2]: ({ id }: { id: number }) => {
      return [QUERY_KEYS.TEAMS, QUERY_SECTIONS.TEAM_V2, id];
    },
    [QUERY_SECTIONS.TEAMS_V2]: (params: Record<string, unknown>) => {
      return [QUERY_KEYS.TEAMS, QUERY_SECTIONS.TEAMS_V2, params];
    }
  },
  [QUERY_KEYS.ACTS]: {
    [QUERY_SECTIONS.ADVANCED_SEARCH_PLAIN_TEXT_MODAL]: (
      ids: actsApiTypes.ActFromResponse['id'][]
    ) => {
      return [QUERY_KEYS.ACTS, JSON.stringify(ids.sort())];
    }
  },
  [QUERY_KEYS.BANKS]: {
    [QUERY_SECTIONS.ADVANCED_SEARCH_PLAIN_TEXT_MODAL]: (
      ids: banksApiTypes.BankFromResponse['id'][]
    ) => {
      return [QUERY_KEYS.ACTS, JSON.stringify(ids.sort())];
    }
  },
  [QUERY_KEYS.BUCKETS]: {
    [QUERY_SECTIONS.BUCKET_ITEMS_MOVE_MODAL]: (
      id: bucketsApiTypes.BucketFromResponse['id'] | null
    ) => {
      return [QUERY_KEYS.BUCKETS, QUERY_SECTIONS.BUCKET_ITEMS_MOVE_MODAL, id];
    },
    [QUERY_SECTIONS.BUCKET_LABELS_TABLE]: (id: bucketsApiTypes.BucketFromResponse['id'] | null) => {
      return [QUERY_KEYS.BUCKETS, QUERY_SECTIONS.BUCKET_LABELS_TABLE, { id }];
    },
    [QUERY_SECTIONS.LABEL_INPUT_GET_BUCKETS]: (
      name: bucketsApiTypes.BucketFromResponse['name']
    ) => {
      return [QUERY_KEYS.BUCKETS, QUERY_SECTIONS.LABEL_INPUT_GET_BUCKETS, { name }];
    },
    [QUERY_SECTIONS.LABEL_INPUT_CREATE_LABEL]: (
      bucketId: bucketsApiTypes.BucketFromResponse['id'] | null,
      labelName: labelsApiTypes.LabelFromResponse['name']
    ) => {
      return [QUERY_KEYS.BUCKETS, QUERY_SECTIONS.LABEL_INPUT_CREATE_LABEL, { bucketId, labelName }];
    },
    [QUERY_SECTIONS.BUCKET_SENTENCE_NOTES_TABLE]: (
      id: bucketsApiTypes.BucketFromResponse['id'] | null,
      isBucketObligationsPage: boolean
    ) => {
      return [
        QUERY_KEYS.BUCKETS,
        QUERY_SECTIONS.BUCKET_SENTENCE_NOTES_TABLE,
        { id, isBucketObligationsPage }
      ];
    },
    [QUERY_SECTIONS.BUCKET_TAB_TITLE]: (id: bucketsApiTypes.BucketFromResponse['id'] | null) => {
      return [QUERY_KEYS.BUCKETS, QUERY_SECTIONS.BUCKET_TAB_TITLE, { id }];
    }
  },
  [QUERY_KEYS.RULES]: {
    [QUERY_SECTIONS.BUCKETS_ACCESS_TABLE]: (
      id: bucketsApiTypes.BucketFromResponse['id'] | null
    ) => {
      return [QUERY_KEYS.RULES, QUERY_SECTIONS.BUCKETS_ACCESS_TABLE, { id }];
    }
  },
  [QUERY_KEYS.WORKFLOWS]: {
    [QUERY_SECTIONS.MANAGE_WORKFLOWS]: (args: {
      searchString: string;
      bucketId?: bucketsApiTypes.BucketFromResponse['id'] | null;
    }) => {
      return [QUERY_KEYS.WORKFLOWS, QUERY_SECTIONS.MANAGE_WORKFLOWS, args];
    }
  },
  [QUERY_KEYS.AREAS_OF_FOCUS]: {
    [QUERY_SECTIONS.GET_AREAS_OF_FOCUS]: (
      teamId: organizationApiTypes.AreasOfFocusRequestParams['team_id'],
      params: Exclude<organizationApiTypes.AreasOfFocusRequestParams, 'team_id'>
    ) => {
      return [QUERY_KEYS.AREAS_OF_FOCUS, QUERY_SECTIONS.GET_AREAS_OF_FOCUS, teamId, params];
    },
    [QUERY_SECTIONS.RENAME_AREA_OF_FOCUS]: ({ name, id }: { id: number; name: string }) => {
      return [QUERY_KEYS.AREAS_OF_FOCUS, QUERY_SECTIONS.RENAME_AREA_OF_FOCUS, id, name];
    },
    [QUERY_SECTIONS.REMOVE_AREA_OF_FOCUS]: (id: number) => {
      return [QUERY_KEYS.AREAS_OF_FOCUS, QUERY_SECTIONS.REMOVE_AREA_OF_FOCUS, id];
    },
    [QUERY_SECTIONS.CREATE_AREA_OF_FOCUS]: ({
      teamId,
      data
    }: {
      teamId: number;
      data: Record<string, unknown>;
    }) => {
      return [QUERY_KEYS.AREAS_OF_FOCUS, QUERY_SECTIONS.CREATE_AREA_OF_FOCUS, teamId, data];
    }
  }
};
