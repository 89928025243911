import PropTypes from 'prop-types';
import smoothscroll from 'smoothscroll-polyfill';
import LabelOptionsList from './elements/LabelOptionsList';
import LabelList from './elements/LabelList';
import classnames from 'classnames';

import { useState, useEffect, useRef } from 'react';

import './_optionsList.scss';

smoothscroll.polyfill();

export const TEST_ID = {
  CONTAINER: 'options-list-container'
};

function OptionsList({
  searchText,
  options,
  checkedState,
  handleOptionClick,
  handleLabelSelect,
  classNames = {}
}) {
  const [rightPanelList, setRightPanelList] = useState([]);
  const [leftPanelLabels, setleftPanelLabels] = useState([]);
  const rightPanel = useRef(null);

  useEffect(() => {
    function filterLabelOptions(labelOptions) {
      return labelOptions.filter(option => option.label.toLowerCase().indexOf(searchText) >= 0);
    }

    function searchIncludesLabel(label) {
      return label.toLowerCase().indexOf(searchText) >= 0;
    }

    function determineLabelCheckedState(allOptions) {
      for (let i = 0; i < allOptions.length; i++) {
        const value = allOptions[i].value;
        if (!checkedState[value]) return false;
      }

      return true;
    }

    function formatRightPanelItems({ labelOptions, label, labelCheckedState }) {
      return (
        <LabelOptionsList
          labelOptions={labelOptions}
          label={label}
          checkedState={checkedState}
          key={label}
          handleOptionClick={handleOptionClick}
          handleLabelSelect={handleLabelSelect}
          labelCheckedState={labelCheckedState}
        />
      );
    }

    function formatPanels() {
      const rightPanelItems = [];
      const leftPanelItems = [];

      options.forEach(option => {
        const label = option.label;
        const labelIncluded = searchIncludesLabel(label);
        const labelOptions = labelIncluded ? option.options : filterLabelOptions(option.options);
        const labelCheckedState = determineLabelCheckedState(labelOptions);

        if (labelIncluded || labelOptions.length) {
          rightPanelItems.push(formatRightPanelItems({ labelOptions, label, labelCheckedState }));
          leftPanelItems.push(label);
        }
      });

      setRightPanelList(rightPanelItems);
      setleftPanelLabels(leftPanelItems);
    }

    formatPanels();
  }, [searchText, checkedState, options, handleLabelSelect, handleOptionClick]);

  useEffect(() => {
    let isScrolling;
    const rightPanelContainer = rightPanel.current;
    let currentActiveNode;

    function buildChildReferenceHeights() {
      let total = 0;
      const heightsTotal = [];
      for (const node of rightPanelContainer.childNodes) {
        total += node.scrollHeight;
        heightsTotal.push(total);
      }
      return heightsTotal;
    }

    function getCurrentChildNode() {
      const referenceHeights = buildChildReferenceHeights();
      const scrollTop = rightPanelContainer.scrollTop + 100;
      for (let i = 0; i < referenceHeights.length; i++) {
        if (scrollTop < referenceHeights[i]) {
          return rightPanelContainer.childNodes[i];
        }
      }
      return rightPanelContainer.firstChild;
    }

    function updateActiveLabel({ currentNode }) {
      const targetEl = document.getElementById(currentNode.id.replace('right', 'left'));
      const oldTargetEl = document.getElementById(currentActiveNode.id.replace('right', 'left'));
      targetEl.classList.add('active');
      oldTargetEl.classList.remove('active');
      targetEl.parentNode.scrollTo({ top: targetEl.offsetTop - 75, behavior: 'smooth' });
      currentActiveNode = currentNode;
    }

    function handleScroll() {
      currentActiveNode = currentActiveNode || rightPanelContainer.firstChild;
      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(() => {
        const currentNode = getCurrentChildNode();
        if (currentNode !== currentActiveNode) {
          updateActiveLabel({ currentNode });
        }
      }, 66);
    }

    rightPanelContainer.addEventListener('scroll', handleScroll);
    return () => rightPanelContainer.removeEventListener('scroll', handleScroll);
  }, [searchText]);

  return (
    <div
      data-testid={TEST_ID.CONTAINER}
      className={classnames('options-list-container', classNames.container)}
    >
      <LabelList
        labels={leftPanelLabels}
        className={classnames('options-list-left-panel-container', classNames.leftPanelContainer)}
      />
      <div
        className={classnames('options-list-right-panel-container', classNames.rightPanelContainer)}
        ref={rightPanel}
      >
        {rightPanelList}
      </div>
    </div>
  );
}

OptionsList.propTypes = {
  handleOptionClick: PropTypes.func.isRequired,
  checkedState: PropTypes.objectOf(PropTypes.bool).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string
        })
      )
    })
  ).isRequired,
  searchText: PropTypes.string.isRequired,
  handleLabelSelect: PropTypes.func.isRequired,
  classNames: PropTypes.shape({
    container: PropTypes.string,
    leftPanelContainer: PropTypes.string,
    rightPanelContainer: PropTypes.string
  })
};

export default OptionsList;
