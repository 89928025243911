import { safe_analytics } from 'utils/analytics';
import { ERROR_ALERT_MESSAGE_TYPES } from 'common';
import { EXPECTED_ERROR_MESSAGES } from 'shared/features/auth/auth.constants';

export const usePasswordResetModalHandlers = ({
  props,
  localState,
  localActions,
  reduxActions,
  alertActions,
  verificationCodeEmailData
}) => {
  const handleModalClose = () => {
    props.onClose();
  };

  const handleConfirmNewPassword = async ({ newPassword, confirmationPassword }) => {
    try {
      if (newPassword !== confirmationPassword) {
        alertActions.setErrorMessageByType(ERROR_ALERT_MESSAGE_TYPES.PASSWORDS_MISMATCH);
        return;
      }

      await reduxActions.confirmNewPassword({
        email: localState.passwordResetEmail,
        verificationCode: verificationCodeEmailData.verificationCode,
        newPassword: newPassword
      });

      props.onClose();
      props.onSuccess();
    } catch (e) {
      if (
        e.message.includes(EXPECTED_ERROR_MESSAGES.VALIDATION_ERROR) ||
        e.message.includes(EXPECTED_ERROR_MESSAGES.PASSWORD_POLICY)
      ) {
        alertActions.setErrorMessageByType(ERROR_ALERT_MESSAGE_TYPES.WEAK_PASSWORD);
      } else if (e.message.includes(EXPECTED_ERROR_MESSAGES.INVALID_VERIFICATION_CODE)) {
        alertActions.setErrorMessageByType(ERROR_ALERT_MESSAGE_TYPES.INVALID_VERIFICATION_CODE);

        props.onInvalidCodeError();
      } else {
        alertActions.setErrorMessageByType(ERROR_ALERT_MESSAGE_TYPES.UNKNOWN_ERROR);
      }
    }
  };

  const handleForgetPasswordClick = async ({ passwordResetEmail }) => {
    try {
      safe_analytics('default', 'Login', 'Button click', 'Password Reset');

      localActions.setPasswordResetEmail(passwordResetEmail);

      await reduxActions.sendPasswordResetEmail({
        email: passwordResetEmail
      });

      alertActions.setSuccessMessage("Verification code is on it's way!");
    } catch (e) {
      if (e.message === EXPECTED_ERROR_MESSAGES.ATTEMPTS_EXCEEDED) {
        alertActions.setErrorMessageByType(ERROR_ALERT_MESSAGE_TYPES.ATTEMPTS_EXCEEDED);
      } else {
        alertActions.setErrorMessageByType(ERROR_ALERT_MESSAGE_TYPES.UNKNOWN_ERROR);
      }
    }
  };

  return {
    handleModalClose,
    handleConfirmNewPassword,
    handleForgetPasswordClick
  };
};
