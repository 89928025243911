import type * as uiLib from '@compliance.ai/web-components';

import * as sortConstants from 'constants/Sort';

export const SORT_ORDER_OPTIONS: uiLib.SelectOption<sortConstants.SORT_ORDER>[] = [
  {
    value: sortConstants.SORT_ORDER.ASC,
    label: 'Asc'
  },
  {
    value: sortConstants.SORT_ORDER.DESC,
    label: 'Desc'
  }
];
