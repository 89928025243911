import type { Validator } from '../Validator';

import * as uiLib from '@compliance.ai/web-components';

export class DateValidator implements Validator {
  readonly isMulti: Validator['isMulti'] = false;
  readonly whitelistedValues: Validator['whitelistedValues'] = [];

  constructor(args?: {
    isMulti?: Validator['isMulti'];
    whitelistedValues?: Validator['whitelistedValues'];
  }) {
    this.isMulti = args?.isMulti ?? false;
    this.whitelistedValues = args?.whitelistedValues ?? [];
  }

  readonly isValueValid: Validator['isValueValid'] = metadataValue => {
    return (
      uiLib.isValidDate(metadataValue as Parameters<typeof uiLib.isValidDate>[0]) ||
      this.whitelistedValues.includes(metadataValue)
    );
  };

  readonly validate: Validator['validate'] = args => {
    if (this.isMulti) {
      if (!Array.isArray(args.metadataValue)) {
        return {
          isValid: false,
          validValue: []
        };
      }

      const validValues = args.metadataValue.filter(this.isValueValid);

      return {
        isValid: validValues.length === args.metadataValue.length,
        validValue: validValues
      };
    }

    const isValueValid = this.isValueValid(args.metadataValue);

    return {
      isValid: isValueValid,
      validValue: isValueValid ? args.metadataValue : null
    };
  };
}
