import type { AddLabelsData } from './useAddLabelsData.types';
import type { AddLabelsHandlers } from './useAddLabelsHandlers.types';
import type { AddLabelsProps } from '../AddLabels.types';

import * as constants from '../AddLabels.constants';
import * as uiLib from '@compliance.ai/web-components';
import * as errorUtils from 'utils/errors';

import { useViewReduxActions } from 'shared/features/view/hooks';
import { useOrganizationLabelsReduxActions } from 'shared/features/labels/hooks';

export const useAddLabelsHandlers = ({
  props,
  localActions
}: {
  props: Pick<AddLabelsProps, 'documentIds' | 'onActionStart'>;
  localActions: AddLabelsData['localActions'];
}): AddLabelsHandlers => {
  const viewReduxActions = useViewReduxActions();
  const orgLabelsReduxActions = useOrganizationLabelsReduxActions();

  const handleModalToggle: AddLabelsHandlers['handleModalToggle'] = (
    isOpen,
    type = constants.MODAL_TYPE.LABEL_ADDITION
  ) => () => {
    localActions.setModalState({
      isOpen,
      type
    });
  };

  const handleRestrictionModalShow: AddLabelsHandlers['handleRestrictionModalShow'] = () => {
    viewReduxActions.showRestrictModal(null, null, null);
  };

  const handleLabelCreation: AddLabelsHandlers['handleLabelCreation'] = labelName => {
    localActions.setModalState({
      isOpen: true,
      type: constants.MODAL_TYPE.LABEL_CREATION
    });
    localActions.setNewLabelName(labelName);
  };

  const handleNewLabelsCreation: AddLabelsHandlers['handleNewLabelsCreation'] = async labelOption => {
    try {
      props.onActionStart?.();
      if (!labelOption) {
        return;
      }

      await orgLabelsReduxActions.addLabels(
        props.documentIds.map(documentId => {
          return {
            document_id: documentId,
            new_document_note: true,
            label_id: 'value' in labelOption ? labelOption?.value : null,
            label_name: 'label' in labelOption ? labelOption?.label : ''
          };
        })
      );

      uiLib.showNotification({
        type: uiLib.ALERT_TYPES.INFO,
        title: 'Hold on',
        message: 'Your new label is processing and will be available soon.'
      });
    } catch (e) {
      errorUtils.logError(e as Error, 'Error', { isSilent: true });
    }
  };

  return {
    handleModalToggle,
    handleRestrictionModalShow,
    handleLabelCreation,
    handleNewLabelsCreation
  };
};
