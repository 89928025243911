import { createSelector } from '@reduxjs/toolkit';

/**
 *
 * Reducer selector
 */
export const getTopicsReducer = state => state.topics;

/**
 *
 * All topics selectors
 */
export const getTopics = state => state.topics.followed_topics;
export const getDocCountsForTopics = createSelector(
  getTopicsReducer,
  topicsReducer => topicsReducer.docCountsTopicsObj
);
export const getTopicsReadyStatus = createSelector(
  getTopicsReducer,
  topicsReducer => topicsReducer.isReady
);

/**
 *
 * Followed topics selectors
 */
export const getFollowedTopics = createSelector(getTopics, topics => {
  return topics.filter(({ following }) => following);
});
export const getFollowedTopicIds = createSelector(getFollowedTopics, followedTopics => {
  return followedTopics.map(({ topic_id }) => topic_id);
});
