import type { FC } from 'react';
import type { FilterLabeledElementProps } from './FilterLabeledElement.types';

import classnames from 'classnames';

import './_filter-labeled-element.scss';

export const TEST_ID = 'FilterLabeledElement';

const FilterLabeledElement: FC<FilterLabeledElementProps> = ({
  label,
  labelFor,
  isBold,
  labelClassName,
  labelWrapperClassName,
  elementWrapperClassName,
  customLabelElements,
  children
}) => {
  return (
    <div data-testid={TEST_ID} className="filter-labeled-element__container">
      <div className={classnames(labelWrapperClassName, 'filter-labeled-element__label-wrapper')}>
        <label
          htmlFor={labelFor}
          className={classnames('filter-labeled-element__label', labelClassName, {
            'filter-labeled-element__label--is-bold': isBold
          })}
        >
          {label}
        </label>
        {customLabelElements}
      </div>
      <div
        className={classnames('filter-labeled-element__element-wrapper', elementWrapperClassName)}
      >
        {children}
      </div>
    </div>
  );
};

export default FilterLabeledElement;

FilterLabeledElement.defaultProps = {
  label: '',
  labelFor: '',
  isBold: false,
  labelClassName: '',
  labelWrapperClassName: '',
  customLabelElements: null,
  children: null
};
