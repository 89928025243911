import classnames from 'classnames';
import { LoadingOverlay } from 'common';
import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import { isBookmarkedFolder } from 'components/Folders/Folders.helpers';
import { FolderListItem } from './elements';
import { useAddToFolderModalBodyData } from './hooks';
import './_add-to-folder-modal-body.scss';

export const TEST_IDS = {
  SAVED_FOLDERS: 'add-to-folder-modal-body-saved-folders',
  FOLDERS: 'add-to-folder-modal-body-folders'
};

export const AddToFolderModalBody = ({
  currentFolder,
  isAddingFolder,
  isLoading = true,
  folderPaths = [],
  savedFolders = [],
  folders = [],
  subFoldersCount,
  docsCount,
  hasMoreFolderDocs = false,
  onFolderClick,
  onFetchMoreClick
}) => {
  const { formattedData } = useAddToFolderModalBodyData({
    props: {
      folderPaths,
      savedFolders,
      subFoldersCount,
      docsCount
    }
  });

  return (
    <LoadingOverlay
      isLoading={isLoading}
      containerClassName="default-modal-children-container add-to-folder-modal-body"
    >
      {!isAddingFolder && (
        <>
          {formattedData.hasSavedFolders && (
            <div className="add-to-folder-modal-body__section" data-testid={TEST_IDS.SAVED_FOLDERS}>
              <h5 className="add-to-folder-modal-body__section-title">
                {formattedData.savedFoldersTitle}
              </h5>
              <div className="add-to-folder-modal-body__section-list">
                {savedFolders.map(folder => (
                  <FolderListItem folder={folder} disabled />
                ))}
              </div>
            </div>
          )}
          <div className="add-to-folder-modal-body__section" data-testid={TEST_IDS.FOLDERS}>
            <h5
              className={classnames('add-to-folder-modal-body__section-title', {
                'has-border': !formattedData.hasSavedFolders
              })}
            >
              {formattedData.foldersTitle}
            </h5>
            <div className="add-to-folder-modal-body__section-list">
              {folders.map(folder => (
                <FolderListItem
                  folder={folder}
                  onClick={onFolderClick}
                  disabled={isBookmarkedFolder(folder) && formattedData.isSavedInBookmarkFolder}
                />
              ))}
              {hasMoreFolderDocs && (
                <Button
                  className="add-to-folder-modal-body__btn btn-see-more"
                  type={BUTTON_TYPES.LINK}
                  onClick={onFetchMoreClick}
                >
                  See More
                </Button>
              )}
            </div>
          </div>
        </>
      )}
      {isAddingFolder && (
        <div className="add-to-folder-modal-body__create-folder-section">
          <h5 className="add-to-folder-modal-body__create-folder-section-title">
            Create a new folder {currentFolder ? ` in ${currentFolder.name}` : ''}
          </h5>
        </div>
      )}
    </LoadingOverlay>
  );
};
