import type { FC } from 'react';
import type { DateCellProps } from './DateCell.types';

import * as uiLib from '@compliance.ai/web-components';

import { useDateCellData } from './hooks';

import './_date-cell.scss';

export const DateCell: FC<DateCellProps> = ({ cellValue }) => {
  const { formattedData } = useDateCellData({ cellValue });

  return (
    <uiLib.LabeledField
      label={formattedData.label}
      labelClassName="workflow-summary-date-cell__title"
    >
      {formattedData.dateString}
    </uiLib.LabeledField>
  );
};
