import type { PeriodOption } from './PeriodSelect.types';

import * as uiLib from '@compliance.ai/web-components';
import * as dateUtils from 'utils/date-utils';
import * as helpers from './PeriodSelect.helpers';

export enum PERIOD_SELECT_OPTION_KEYS {
  MOST_RECENT_YEAR = 'mostRecentYear',
  PRIOR_YEAR = 'priorYear',
  MOST_RECENT_QUARTER = 'mostRecentQuarter',
  PRIOR_QUARTER = 'priorQuarter',
  LAST_3_MONTHS = 'last3Months',
  PRIOR_3_MONTHS = 'prior3Months'
}

export const DATES: Record<
  PERIOD_SELECT_OPTION_KEYS,
  {
    startDate: string;
    endDate: string;
    label: string;
  }[]
> = {
  [PERIOD_SELECT_OPTION_KEYS.MOST_RECENT_YEAR]: helpers.getPreviousDates({
    startDateAmounts: [0, 1, 2, 3],
    endDateAmounts: [1, 2, 3, 4],
    unitOfTime: uiLib.DATE_UNITS.QUARTER,
    baseDate: dateUtils.getDateEndOfCurrentYear()
  }),
  [PERIOD_SELECT_OPTION_KEYS.PRIOR_YEAR]: helpers.getPreviousDates({
    startDateAmounts: [0, 1, 2, 3],
    endDateAmounts: [1, 2, 3, 4],
    unitOfTime: uiLib.DATE_UNITS.QUARTER,
    baseDate: dateUtils.getDateEndOfPreviousYear()
  }),
  [PERIOD_SELECT_OPTION_KEYS.MOST_RECENT_QUARTER]: helpers.getPreviousDates({
    startDateAmounts: [0, 1, 2],
    endDateAmounts: [1, 2, 3],
    unitOfTime: uiLib.DATE_UNITS.MONTH,
    baseDate: dateUtils.getDateEndOfCurrentQuarter()
  }),
  [PERIOD_SELECT_OPTION_KEYS.PRIOR_QUARTER]: helpers.getPreviousDates({
    startDateAmounts: [3, 4, 5],
    endDateAmounts: [4, 5, 6],
    unitOfTime: uiLib.DATE_UNITS.MONTH,
    baseDate: dateUtils.getDateEndOfPreviousQuarter()
  }),
  [PERIOD_SELECT_OPTION_KEYS.LAST_3_MONTHS]: helpers.getPreviousDates({
    startDateAmounts: [0, 1, 2],
    endDateAmounts: [1, 2, 3],
    unitOfTime: uiLib.DATE_UNITS.MONTH,
    baseDate: new Date()
  }),
  [PERIOD_SELECT_OPTION_KEYS.PRIOR_3_MONTHS]: helpers.getPreviousDates({
    startDateAmounts: [3, 4, 5],
    endDateAmounts: [4, 5, 6],
    unitOfTime: uiLib.DATE_UNITS.MONTH,
    baseDate: new Date()
  })
};

export const PERIOD_SELECT_OPTIONS: PeriodOption[] = [
  {
    value: PERIOD_SELECT_OPTION_KEYS.MOST_RECENT_YEAR,
    label: 'Most Recent Year',
    dates: DATES[PERIOD_SELECT_OPTION_KEYS.MOST_RECENT_YEAR]
  },
  {
    value: PERIOD_SELECT_OPTION_KEYS.PRIOR_YEAR,
    label: 'Prior Year',
    dates: DATES[PERIOD_SELECT_OPTION_KEYS.PRIOR_YEAR]
  },
  {
    value: PERIOD_SELECT_OPTION_KEYS.MOST_RECENT_QUARTER,
    label: 'Most Recent Quarter',
    dates: DATES[PERIOD_SELECT_OPTION_KEYS.MOST_RECENT_QUARTER]
  },
  {
    value: PERIOD_SELECT_OPTION_KEYS.PRIOR_QUARTER,
    label: 'Prior Quarter',
    dates: DATES[PERIOD_SELECT_OPTION_KEYS.PRIOR_QUARTER]
  },
  {
    value: PERIOD_SELECT_OPTION_KEYS.LAST_3_MONTHS,
    label: 'Last 3 Months',
    dates: DATES[PERIOD_SELECT_OPTION_KEYS.LAST_3_MONTHS]
  },
  {
    value: PERIOD_SELECT_OPTION_KEYS.PRIOR_3_MONTHS,
    label: 'Prior 3 Months',
    dates: DATES[PERIOD_SELECT_OPTION_KEYS.PRIOR_3_MONTHS]
  }
];
