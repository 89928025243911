import moment from 'moment';
import { LocalDateFormat } from 'utils/keyDates';

const KeyDates = props => {
  const document = props.document;
  const rule = document.rule || {};
  const labelFirst = props.labelFirst || false;
  const labelSuffix = props.labelSuffix || '';

  const dates = [];

  if (rule.comments_close_on) {
    const temp = [];
    temp.push(
      <div className="date" key="ccd">
        <span className="timeline-sources-category">
          {moment(rule.comments_close_on).format(LocalDateFormat)}
        </span>
      </div>
    );
    temp.push(
      <div className="label light-gray-date" key="cc">{`Comments close${labelSuffix}`}</div>
    );

    if (labelFirst) {
      temp.reverse();
    }
    dates.push(...temp);
  }

  if (rule.effective_on) {
    const temp = [];
    temp.push(
      <div className="date" key="eod">
        <span className="timeline-sources-category">
          {moment(rule.effective_on).format(LocalDateFormat)}
        </span>
      </div>
    );
    temp.push(<div className="label light-gray-date" key="eo">{`Effective${labelSuffix}`}</div>);

    if (labelFirst) {
      temp.reverse();
    }
    dates.push(...temp);
  }

  return <div className="key-dates">{dates}</div>;
};

export default KeyDates;
