import type { AddLabelsModalData } from './useAddLabelsModalData.types';
import type { AddLabelsModalProps } from '../AddLabelsModal.types';

import * as uiLib from '@compliance.ai/web-components';
import * as helpers from './useAddLabelsModalHandlers.helpers';
import * as errorUtils from 'utils/errors';

import { useMutation } from '@tanstack/react-query';
import { useOrganizationLabelsReduxActions } from 'shared/features/labels/hooks';

export const useAddLabelsModalMutations = ({
  props,
  localState
}: {
  props: Pick<AddLabelsModalProps, 'documentIds' | 'onClose' | 'onActionStart'>;
  localState: AddLabelsModalData['localState'];
}) => {
  const orgLabelsReduxActions = useOrganizationLabelsReduxActions();

  const addLabels = useMutation({
    onMutate: () => {
      props.onActionStart?.();
    },
    mutationFn: async () => {
      await orgLabelsReduxActions.addLabels(
        helpers.formatLabelsForRequest({
          documentIds: props.documentIds,
          selectedLabelIds: localState.selectedLabelIds
        })
      );
    },
    onError: errorUtils.logReactQueryError,
    onSettled: props.onClose,
    onSuccess: () =>
      uiLib.showNotification({
        type: uiLib.ALERT_TYPES.INFO,
        title: 'Hold on',
        message: 'Your labels are processing and will be available soon.'
      })
  });

  return {
    addLabels
  };
};
