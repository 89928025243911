export const SET_CURRENT_TEAM = 'SET_CURRENT_TEAM';
export const SET_VIEW_TYPE = 'SET_VIEW_TYPE';
export const SET_CURRENT_WORKFLOW = 'SET_CURRENT_WORKFLOW';
export const SET_BLOCKED_TASK_SELECTION = 'SET_BLOCKED_TASK_SELECTION';
export const SET_CURRENT_CALENDAR_DATE = 'SET_CURRENT_CALENDAR_DATE';
export const SET_TASKS_TABLE_PARAMS = 'SET_TASKS_TABLE_PARAMS';

export const setCurrentTeam = currentTeam => ({
  type: SET_CURRENT_TEAM,
  currentTeam
});

export const setViewType = viewType => ({
  type: SET_VIEW_TYPE,
  viewType
});

export const setCurrentWorkflow = currentWorkflow => ({
  type: SET_CURRENT_WORKFLOW,
  currentWorkflow
});

export const setBlockedTaskSelection = blockedTaskSelection => ({
  type: SET_BLOCKED_TASK_SELECTION,
  blockedTaskSelection
});

export const setCurrentCalendarDate = currentCalendarDate => ({
  type: SET_CURRENT_CALENDAR_DATE,
  currentCalendarDate
});

export const setTasksTableParams = tasksTableParams => ({
  type: SET_TASKS_TABLE_PARAMS,
  tasksTableParams
});
