import {
  FETCH_CONCEPTS_BEGIN,
  FETCH_CONCEPTS_SUCCESS,
  FETCH_CONCEPTS_ERROR
} from './concepts.actions';

export const getInitialState = () => ({
  concepts: {},
  metaConcepts: {},
  isReady: false
});

export const concepts = (state = getInitialState(), { type, data }) => {
  switch (type) {
    case FETCH_CONCEPTS_BEGIN:
      return {
        ...state,
        isReady: false
      };

    case FETCH_CONCEPTS_SUCCESS:
      return {
        ...state,
        metaConcepts: data.metaConcepts,
        concepts: data.concepts,
        isReady: true
      };

    case FETCH_CONCEPTS_ERROR:
      return state;

    default:
      return state;
  }
};
