import * as uiLib from '@compliance.ai/web-components';
import * as common from 'common/index';

import './_sentence-note-delete-button.scss';

export const SentenceNoteDeleteButton = ({
  isSystemObligation,
  isEditable,
  isObligation,
  isLoading,
  onDelete
}) => {
  if (isSystemObligation) {
    return null;
  }

  if (!isSystemObligation && !isEditable) {
    return null;
  }

  return (
    <div className="sentence-note-delete-button">
      {isLoading && (
        <uiLib.Loader
          size={uiLib.LOADER_SIZES.SMALL}
          isContrast
          classNames={{
            spinner: 'sentence-note-delete-button__loader'
          }}
        />
      )}
      <common.DeleteButton
        buttonLabel={isObligation ? 'Delete Obligation' : 'Delete Annotation'}
        deleteType={isObligation ? 'obligation' : 'annotation'}
        deleteAction={onDelete}
        isDisabled={isLoading}
      />
    </div>
  );
};
