import type { SourceProps } from './Source.types';

import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';
import { useSourceData } from './hooks';

export const Source = ({ document }: SourceProps) => {
  const { formattedData } = useSourceData({ document });

  if (formattedData.source) {
    return (
      <>
        <FieldName>Source</FieldName>
        <FieldValue isExpandable>{formattedData.source}</FieldValue>
      </>
    );
  }

  return null;
};
