import type * as documentsApi from 'shared/features/documents/documents.apiV2';

import { RELATED_DOCUMENT_RELATION_TYPES } from 'constants/RelatedDocuments';

export const DEFAULT_RELATION_TYPES_EXPANDED = {
  [RELATED_DOCUMENT_RELATION_TYPES.PREMIUM_CONTENT]: false,
  [RELATED_DOCUMENT_RELATION_TYPES.DOCUMENTS]: false,
  [RELATED_DOCUMENT_RELATION_TYPES.LANGUAGE]: false,
  [RELATED_DOCUMENT_RELATION_TYPES.TRANSLATION]: false,
  [RELATED_DOCUMENT_RELATION_TYPES.ORGANIZATION]: false,
  [RELATED_DOCUMENT_RELATION_TYPES.ORGANIZATION_ALERT]: false
};

export const EMPTY_RELATED_ALERTS_RESPONSE: Awaited<ReturnType<
  typeof documentsApi.fetchOrganizationRelatedDocumentAlerts
>> = {
  related_doc_alerts: [],
  total_count: 0
};
