import React from 'react';

import * as helpers from './ContentHeader.helpers';

import classnames from 'classnames';
import { connect } from 'react-redux';
import { withHistory } from 'utils/hooks';
import queryString from 'utils/query-string';
import { DocumentActionsFolder } from 'common/DocumentActions/Folder';
import {
  Email as DocumentActionsEmail,
  Print as DocumentActionsPrint,
  Export as DocumentActionsExport,
  Diff as DocumentActionsDiff,
  Workflow as DocumentActionsWorkflow,
  AddLabels as DocumentAddLabels,
  RemoveUserFolder,
  NotificationSwitch
} from 'common';
import { getExportLimit } from 'utils/exportLimit/exportLimit';
import { get_search_view, APP_VIEW_SECTION_TIMELINE } from 'utils/search';
import { userCanAccessDocument, getDocumentRestriction } from 'utils/documents/documentsAccess';
import { fetchEnforcementData } from 'shared/features/documents/documents.actions';
import BulkDocumentSelect from 'components/BulkDocumentSelect';
import { ActionBar } from './elements';
import { canCurrentUserExportDocs } from 'components/Restrict';
import { RESTRICTED_ACTIONS } from 'constants/Restrictions';
import * as constants from './ContentHeader.constants';
import * as routes from 'constants/Routes';

class ContentHeader extends React.Component {
  state = {
    bulkExportSelected: false
  };

  componentDidMount() {
    this.props.fetchEnforcementData({
      monetary_penalty_min: this.state.penaltyAmount,
      respondents: this.state.respondents,
      violation: this.state.violation
    });
  }

  checkPCDocs = () => {
    let allAvailable = true;
    let canPrint = true;
    let canDiff = true;

    const { selected_items } = this.props.current_view;

    for (const docId in selected_items) {
      if (Object.prototype.hasOwnProperty.call(selected_items, docId)) {
        const document = selected_items[docId];
        const userHasAccess = userCanAccessDocument({ document });
        if (!userHasAccess) allAvailable = false;
        if (!getDocumentRestriction({ document, action: RESTRICTED_ACTIONS.PRINT })) {
          canPrint = false;
        }
        if (!getDocumentRestriction({ document, action: RESTRICTED_ACTIONS.DIFF })) canDiff = false;
      }
    }

    return {
      allAvailable,
      canPrint,
      canDiff
    };
  };

  showTimelineWithCombinedDocs = () => {
    const parsedQuery = queryString.parse(this.props.location.search);
    return (
      [APP_VIEW_SECTION_TIMELINE].includes(parsedQuery.view) ||
      this.props.location.pathname === routes.timeline
    );
  };

  folderId = () => {
    const parsedQuery = queryString.parse(this.props.location.search);
    return parsedQuery.folder_id;
  };

  render() {
    const {
      current_view: { selected_items, search_params, displayFilters },
      location,
      current_user: { user },
      bulk_select
    } = this.props;
    const document_ids = Object.keys(selected_items).map(Number).filter(Boolean);

    const selectedDocCount = document_ids.length;
    const { allAvailable, canPrint, canDiff } = this.checkPCDocs();
    const selectedOverLimit = selectedDocCount > 20;
    const selectedOverDailyLimit = !canCurrentUserExportDocs(user, selectedDocCount);

    const app_view = get_search_view(search_params, location);
    const parsedQuery = queryString.parse(location.search);
    const actionBarClasses = {
      'action-bar-container': true
    };

    const columnClass = bulk_select ? 'timeline-action-bar-column' : 'rule-action-bar-column';

    const alertSwitchClass =
      !displayFilters &&
      !parsedQuery.folderTimelineView &&
      !parsedQuery.bookmarked &&
      !parsedQuery.read
        ? 'action-bar-alert-switch'
        : 'hidden-switch';

    //TO_DO:refactor components to use document objects rather then id.
    //Can save iterations and API requests for documents we already have info for.
    const selectedDocuments = Object.values(selected_items);
    const documentExportLimit = getExportLimit();
    const underExportLimit = document_ids.length <= documentExportLimit;
    const folderID = this.folderId();

    const selectedDocumentsToExport = helpers.filterDocumentsByRestrictedAction({
      documents: selectedDocuments,
      action: RESTRICTED_ACTIONS.EXPORT
    });
    const canExport = selectedDocumentsToExport.length > 0;

    return (
      <div className="action-bar-grid">
        <div className="action-bar-row">
          <div className={columnClass}>
            <div className={classnames(actionBarClasses)} ref="tooltip_container">
              {bulk_select && (
                <BulkDocumentSelect bulk="timeline" noLabel disabled={!selectedDocCount} />
              )}
              <DocumentActionsFolder documentIds={document_ids} />
              {folderID && (
                <RemoveUserFolder
                  folderId={folderID}
                  documentIds={document_ids}
                  limit={parsedQuery.limit}
                />
              )}
              <DocumentActionsEmail documentIds={document_ids} enabled={!selectedOverLimit} />
              <DocumentActionsPrint
                documentIds={document_ids}
                enabled={allAvailable && !selectedOverLimit && canPrint}
                queryOptions={{
                  extraQueryKey: constants.EXTRA_QUERY_KEYS.PRINT
                }}
              />
              <DocumentActionsExport
                documentIds={document_ids}
                isEnabled={!selectedOverDailyLimit && underExportLimit && canExport}
              />
              <DocumentActionsDiff
                documentIds={document_ids}
                isEnabled={allAvailable && canDiff}
                queryOptions={{
                  extraQueryKey: constants.EXTRA_QUERY_KEYS.DIFF
                }}
              />
              <DocumentActionsWorkflow documentIds={document_ids} />
              <DocumentAddLabels documentIds={document_ids} />
            </div>
          </div>
          <div className={alertSwitchClass}>
            <NotificationSwitch />
          </div>
        </div>
        <ActionBar
          onExport={() => this.setState({ bulkExportSelected: true })}
          selectedDocsIds={document_ids}
          selectedOverDailyLimit={selectedOverDailyLimit}
          section={app_view?.section}
          shouldShowWithCombinedDocs={this.showTimelineWithCombinedDocs()}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    current_user: state.current_user,
    current_view: state.current_view,
    search_results_relevance: state.search_results_relevance,
    document_details: state.document_details,
    documents: state.documents
  };
};

export default connect(mapStateToProps, {
  fetchEnforcementData
})(withHistory(ContentHeader));
