import type { WorkflowDiagramModalProps } from './WorkflowDiagramModalProps.types';

import { Modal, Loader } from '@compliance.ai/web-components';
import { WorkflowDiagramTool } from './elements';
import {
  useWorkflowDiagramModalData,
  useWorkflowDiagramModalHandlers,
  useWorkflowDiagramModalLifecycle
} from './hooks';
import './_workflow-diagram-modal.scss';

export const WorkflowDiagramModal = ({
  workflowId,
  workflow,
  onClose
}: WorkflowDiagramModalProps) => {
  const { localState, localActions } = useWorkflowDiagramModalData({
    workflow
  });

  const handlers = useWorkflowDiagramModalHandlers({
    props: {
      workflowId
    },
    localActions
  });

  useWorkflowDiagramModalLifecycle({
    onWorkflowFetch: handlers.handleWorkflowFetch
  });

  return (
    <Modal
      isOpen
      title={localState.isLoading ? 'Loading' : localState.workflow?.name}
      onClose={onClose}
      className="workflow-diagram-modal__container"
      childContainerClassName="workflow-diagram-modal__content"
      shouldShowFullscreenButton
      disableScrollLock
    >
      <>
        {localState.isLoading && <Loader withoutBackground={false} />}
        {localState.workflow && <WorkflowDiagramTool workflow={localState.workflow} />}
      </>
    </Modal>
  );
};

WorkflowDiagramModal.defaultProps = {
  workflowId: null,
  workflow: null
};
