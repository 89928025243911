import type { WorkflowDiagramToolHandlers } from './useWorkflowDiagramToolHandlers.types';

import { useEffect } from 'react';

export const useWorkflowDiagramToolLifecycle = ({
  onInitialNodesSet,
  onReadyForPrint
}: {
  onInitialNodesSet: WorkflowDiagramToolHandlers['handleInitialNodesSet'];
  onReadyForPrint: WorkflowDiagramToolHandlers['handleDiagramReadyForPrint'];
}) => {
  useEffect(onInitialNodesSet, [onInitialNodesSet]);
  useEffect(onReadyForPrint, [onReadyForPrint]);
};
