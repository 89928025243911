import type { DocumentsFiltersTooltipRenderers } from './useDocumentsFiltersTooltipRenderers.types';
import type { AgencyOption } from 'shared/features/filters/filters.types';

import { useCallback } from 'react';
import { FILTER_KEY } from 'constants/PrimaryFilter';

export const useDocumentsFiltersTooltipRenderers = (): DocumentsFiltersTooltipRenderers => {
  const handleAgencyTooltipTitleRender = useCallback(({ data }: { data: unknown }) => {
    return (data as AgencyOption).fullName;
  }, []);

  return {
    [FILTER_KEY.AGENCIES]: handleAgencyTooltipTitleRender
  };
};
