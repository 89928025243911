import type { AlertPreviewBannerProps } from './AlertPreviewBanner.types';

import type * as react from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './AlertPreviewBanner.constants';

import {
  useAlertPreviewBannerData,
  useAlertPreviewBannerHandlers,
  useAlertPreviewBannerLifecycle,
  useAlertPreviewBannerMutations
} from './hooks';

import './_alert-preview-banner.scss';

export const AlertPreviewBanner: react.FC<AlertPreviewBannerProps> = ({ alertParams }) => {
  const { reduxState } = useAlertPreviewBannerData();

  const handlers = useAlertPreviewBannerHandlers({
    props: {
      alertParams
    }
  });

  const mutations = useAlertPreviewBannerMutations({
    props: {
      alertParams
    },
    reduxState
  });

  useAlertPreviewBannerLifecycle({
    onAlertCloseOnPathChange: handlers.handleAlertCloseOnPathChange
  });

  if (!reduxState.shouldShowAlertPreview) {
    return null;
  }

  return (
    <div className="alert-preview__banner" data-testid={constants.TEST_ID}>
      <uiLib.Alert
        type={uiLib.ALERT_TYPES.INFO}
        className="alert-preview__notification"
        data-testid={constants.TEST_ID}
        content={constants.ALERT_TEXT}
        extraElement={
          <div className="alert-preview__actions-wrapper">
            <uiLib.Button type={uiLib.BUTTON_TYPES.SECONDARY} onClick={handlers.handleBackClick}>
              {constants.BACK_BUTTTON_TEXT}
            </uiLib.Button>
            <uiLib.Button
              type={uiLib.BUTTON_TYPES.SECONDARY}
              onClick={mutations.updateAlert.mutate}
            >
              {constants.UPDATE_BUTTON_TEXT}
            </uiLib.Button>
          </div>
        }
      />
    </div>
  );
};
