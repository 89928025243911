const isCountByCategoriesValid = countByCategories => {
  return (
    typeof countByCategories === 'object' &&
    Boolean(Object.values(countByCategories).find(count => count > 0))
  );
};

export default {
  isCountByCategoriesValid
};
