import type { PrintData } from './usePrintData.types';
import type { PrintQueriesHandlers } from './usePrintQueriesHandlers.types';

import * as browser from 'utils/browser';
import * as helpers from './usePrintQueries.helpers';
import * as uiLib from '@compliance.ai/web-components';
import * as errorUtils from 'utils/errors';

export const usePrintQueriesHandlers = ({
  localActions
}: {
  localActions: PrintData['localActions'];
}): PrintQueriesHandlers => {
  const handleDocumentsFetchSuccess: PrintQueriesHandlers['handleDocumentsFetchSuccess'] = async data => {
    try {
      if (!data) {
        return;
      }

      localActions.setIsPrinting(true);

      const possibleErrors = await Promise.all(
        data.documents.map(document => {
          const windowReference = !browser.isIE() ? window.open() : null;
          return helpers.fetchPDF({ windowReference, document });
        })
      );

      const errors = possibleErrors.filter(Boolean);

      if (errors.length) {
        uiLib.showNotification({
          type: uiLib.ALERT_TYPES.ERROR,
          title: 'Error',
          message: `${errors.length} documents were not included because their PDFs are unavailable.`
        });
      }
    } catch (e) {
      errorUtils.logError(e as Error);
    } finally {
      localActions.setIsPrinting(false);
    }
  };

  return {
    handleDocumentsFetchSuccess
  };
};
