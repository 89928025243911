import type { ReactQueryHandlers } from './useReactQueryHandlers.types';

import { useEffect } from 'react';

export const useReactQueryLifecycle = ({
  onClearDeprecatedQueryCache
}: {
  onClearDeprecatedQueryCache: ReactQueryHandlers['handleClearDeprecatedQueryCache'];
}) => {
  useEffect(onClearDeprecatedQueryCache, [onClearDeprecatedQueryCache]);
};
