import { useCallback } from 'react';
import {
  clearAutoComplete as reduxClearAutoComplete,
  fetchAutoComplete as reduxFetchAutoComplete
} from 'shared/features/search/search.actions';
import {
  postSearchQuery as reduxPostSearchQuery,
  updateCurrentUser as reduxUpdateCurrentUser
} from 'shared/features/user/user.actions';
import {
  closeFilter as reduxCloseFilter,
  openFilter as reduxOpenFilter,
  removeViewAlert as reduxRemoveViewAlert,
  setSearchTerm as reduxSetSearchTerm,
  toggleFilter as reduxToggleFilter
} from 'shared/features/view/view.actions';
import {
  changeFilter as reduxChangeFilter,
  setPrimaryFilter as reduxSetPrimaryFilter
} from 'shared/features/filters/filters.actions';
import { useDispatch } from 'react-redux';
import lodash from 'lodash';

const AUTOCOMPLETE_TO_URL_WAIT_TIME = 200;
const AUTOCOMPLETE_CATEGORY = 'all';

export const useAutocompleteSearchFieldReduxActions = () => {
  const dispatch = useDispatch();

  const fetchAutoComplete = useCallback(
    value => {
      return dispatch(reduxFetchAutoComplete(value, AUTOCOMPLETE_CATEGORY));
    },
    [dispatch]
  );

  const clearAutoComplete = useCallback(() => {
    return dispatch(reduxClearAutoComplete());
  }, [dispatch]);

  const postSearchQuery = useCallback(
    data => {
      return dispatch(reduxPostSearchQuery(data));
    },
    [dispatch]
  );

  const setSearchTerm = useCallback(
    searchTerm => {
      return dispatch(reduxSetSearchTerm(searchTerm));
    },
    [dispatch]
  );

  const removeViewAlert = useCallback(() => {
    return dispatch(reduxRemoveViewAlert());
  }, [dispatch]);

  const openFilter = useCallback(() => {
    dispatch(reduxOpenFilter());
  }, [dispatch]);

  const closeFilter = useCallback(() => {
    return dispatch(reduxCloseFilter());
  }, [dispatch]);

  const toggleFilter = useCallback(() => {
    return dispatch(reduxToggleFilter());
  }, [dispatch]);

  const setPrimaryFilter = useCallback(
    filterParams => {
      dispatch(reduxSetPrimaryFilter(filterParams));
    },
    [dispatch]
  );

  const changeFilter = useCallback(
    currentFilter => {
      dispatch(reduxChangeFilter(currentFilter));
    },
    [dispatch]
  );

  const updateCurrentUser = useCallback(
    (email, user) => {
      return dispatch(reduxUpdateCurrentUser(email, user));
    },
    [dispatch]
  );

  const throttledFetchAutoComplete = lodash.throttle(
    fetchAutoComplete,
    AUTOCOMPLETE_TO_URL_WAIT_TIME
  );

  return {
    throttledFetchAutoComplete,
    clearAutoComplete,
    postSearchQuery,
    setSearchTerm,
    removeViewAlert,
    openFilter,
    closeFilter,
    setPrimaryFilter,
    toggleFilter,
    changeFilter,
    updateCurrentUser
  };
};
