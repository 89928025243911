import { api_fetchRegulations } from './regulations.api';

export const REQUEST_REGULATIONS = 'REQUEST_REGULATIONS';
export const RECEIVE_REGULATIONS = 'RECEIVE_REGULATIONS';

function requestRegulations() {
  return {
    type: REQUEST_REGULATIONS
  };
}

function receiveRegulations(data) {
  return {
    type: RECEIVE_REGULATIONS,
    payload: data
  };
}

export function fetchRegulations() {
  return function doFetch(dispatch) {
    dispatch(requestRegulations());
    return api_fetchRegulations().then(response => {
      dispatch(receiveRegulations(response));
      return response;
    });
  };
}
