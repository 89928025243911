import Bugsnag from '@bugsnag/js';

import {
  api_updateDashboardLayout,
  api_updateUser,
  api_getUserCreatedDocuments,
  api_getCurrentUser,
  api_getAllUsers,
  api_postSearchQuery,
  api_rateSearchResult,
  api_getUser,
  api_readNewFeatureTip,
  api_authorizePCUser,
  api_fetchAppNotifications,
  api_editAppNotification,
  api_createAppNotification,
  api_addUserToOrgFromInvite,
  api_updateUserDefaults,
  api_getRSSConfigurations,
  api_editRSSConfiguration,
  api_createRSSConfiguration,
  api_getUserPreferences,
  api_postUserPreferences
} from './user.api';
import { DEFAULTS_UPDATE_ACTIONS } from '../../../constants/Defaults';
import { getUserAuthenticatedState } from '../auth/auth.selectors';
import { logError } from '../../../utils/errors';
import { getUserAllDocsTablesSettings } from './user.selectors';

export const REQUEST_UPDATE_DASHBOARD_LAYOUT = 'REQUEST_UPDATE_DASHBOARD_LAYOUT';
export const RECEIVE_UPDATE_DASHBOARD_LAYOUT = 'RECEIVE_UPDATE_DASHBOARD_LAYOUT';
export const REQUEST_ALL_USERS = 'REQUEST_ALL_USERS';
export const RECEIVE_ALL_USERS = 'RECEIVE_ALL_USERS';
export const REQUEST_CURRENT_USER = 'REQUEST_CURRENT_USER';
export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';
export const MODIFY_CURRENT_USER = 'MODIFY_CURRENT_USER';
export const MODIFIED_CURRENT_USER = 'MODIFIED_CURRENT_USER';
export const NEW_FEATURE_TIP_HAS_BEEN_READ = 'NEW_FEATURE_TIP_HAS_BEEN_READ';
export const REQUEST_SPECIFIED_USER = 'REQUEST_SPECIFIED_USER';
export const RECEIVE_SPECIFIED_USER = 'RECEIVE_SPECIFIED_USER';
export const MODIFY_SPECIFIED_USER = 'MODIFY_SPECIFIED_USER';
export const MODIFIED_SPECIFIED_USER = 'MODIFIED_SPECIFIED_USER';
export const CLEAR_CURRENT_USER_UPDATED_STATE = 'CLEAR_CURRENT_USER_UPDATED_STATE';
export const REQUEST_USER_CREATED_DOCUMENTS = 'REQUEST_USER_CREATED_DOCUMENTS';
export const RECEIVE_USER_CREATED_DOCUMENTS = 'RECEIVE_USER_CREATED_DOCUMENTS';
export const CREATE_SEARCH_QUERY = 'CREATE_SEARCH_QUERY';
export const SEARCH_QUERY_CREATED = 'SEARCH_QUERY_CREATED';
export const SEARCH_RESULT_RATED = 'SEARCH_RESULT_RATED';
export const RATE_SEARCH_RESULT = 'RATE_SEARCH_RESULT';
export const RECEIVE_UNAUTHENTIATED_CURRENT_USER = 'RECEIVE_UNAUTHENTIATED_CURRENT_USER';
export const AUTHORIZE_PREMIUM_CONTENT = 'AUTHORIZE_PREMIUM_CONTENT';
export const REQUEST_APP_NOTIFICATIONS = 'REQUEST_APP_NOTIFICATIONS';
export const RECEIVE_APP_NOTIFICATIONS = 'RECEIVE_APP_NOTIFICATIONS';
export const NOTHING_HAPPENED_HERE = 'NOTHING_HAPPENED_HERE';
export const RECIEVE_FOLLOWED_CATEGORIES = 'RECIEVED_FOLLOWED_CATEGORIES';
export const ADD_FOLLOWED_CATEGORIES = 'ADD_FOLLOWED_CATEGORIES';
export const REMOVE_FOLLOWED_CATEGORIES = 'REMOVE_FOLLOWED_CATEGORIES';
export const SET_MODIFIED_DEFAULTS_FLAG = 'SET_MODIFIED_DEFAULTS_FLAG';
export const UPDATE_USER_WIDGET = 'UPDATE_USER_WIDGET';
export const SET_UPGRADE_BANNER_OPEN_FLAG = 'SET_UPGRADE_BANNER_OPEN_FLAG';

export const REQUEST_USER_PREFERENCES = 'REQUEST_USER_PREFERENCES';
export const RECEIVE_USER_PREFERENCES = 'RECEIVE_USER_PREFERENCES';
export const REJECT_USER_PREFERENCES = 'REJECT_USER_PREFERENCES';

export const RECEIVE_USER_SEARCH_COUNT = 'RECEIVE_USER_SEARCH_COUNT';

export const updateUserPreferences = preferences => ({
  type: RECEIVE_USER_PREFERENCES,
  payload: preferences
});

export const setModifiedDefaultsFlag = () => {
  return {
    type: SET_MODIFIED_DEFAULTS_FLAG
  };
};

export const setUpgradeBannerOpenFlag = () => {
  return {
    type: SET_UPGRADE_BANNER_OPEN_FLAG
  };
};

function rateResult() {
  return {
    type: RATE_SEARCH_RESULT
  };
}

function resultRated(doc_id, is_relevant, search_args) {
  return {
    type: SEARCH_RESULT_RATED,
    doc_id,
    is_relevant,
    search_args
  };
}

function requestCurrentUser() {
  return {
    type: REQUEST_CURRENT_USER
  };
}

export const receiveCurrentUser = json => {
  if (json.user) {
    Bugsnag.setUser(json.user.id, json.user.email);
  }

  return {
    type: RECEIVE_CURRENT_USER,
    user: json.user
  };
};

function receiveUnauthenticatedUser() {
  return {
    type: RECEIVE_UNAUTHENTIATED_CURRENT_USER
  };
}

const requestUpdateDashboardLayout = () => ({ type: REQUEST_UPDATE_DASHBOARD_LAYOUT });

function receiveUpdateDashboardLayout({ dashboard_type, components, layout }) {
  return {
    type: RECEIVE_UPDATE_DASHBOARD_LAYOUT,
    components,
    layout,
    dashboard_type
  };
}

export const updateUserWidget = widget => ({
  type: UPDATE_USER_WIDGET,
  payload: widget
});

function createSearchQuery(data) {
  return {
    type: CREATE_SEARCH_QUERY,
    search_args: data.search_args
  };
}

function searchQueryCreated() {
  return { type: SEARCH_QUERY_CREATED };
}

function requestAllUsers() {
  return {
    type: REQUEST_ALL_USERS
  };
}

function receiveAllUsers(json) {
  return {
    type: RECEIVE_ALL_USERS,
    users: json.users
  };
}

function requestAppNotifications() {
  return {
    type: REQUEST_APP_NOTIFICATIONS
  };
}

function receiveAppNotifications(appNotifications) {
  return {
    type: RECEIVE_APP_NOTIFICATIONS,
    appNotifications
  };
}

function requestUserCreatedDocuments() {
  return {
    type: REQUEST_USER_CREATED_DOCUMENTS
  };
}

function receiveUserCreatedDocuments(json) {
  return {
    type: RECEIVE_USER_CREATED_DOCUMENTS,
    user_created_documents: json
  };
}

export function modifyCurrentUser() {
  return {
    type: MODIFY_CURRENT_USER
  };
}

function newFeatureTipHasBeenRead(featureId) {
  return {
    type: NEW_FEATURE_TIP_HAS_BEEN_READ,
    featureId
  };
}

export function modifiedCurrentUser(json) {
  return {
    type: MODIFIED_CURRENT_USER,
    user: json ? json.user : null
  };
}

function modifySpecifiedUser() {
  return {
    type: MODIFY_SPECIFIED_USER
  };
}

function modifiedSpecifiedUser(json) {
  return {
    type: MODIFIED_SPECIFIED_USER,
    user: json ? json.user : null
  };
}

function requestSpecifiedUser(email) {
  return {
    type: REQUEST_SPECIFIED_USER,
    email
  };
}

function receiveSpecifiedUser(json) {
  return {
    type: RECEIVE_SPECIFIED_USER,
    user: json.user
  };
}

export function recieveFollowedCategories(data) {
  return {
    type: RECIEVE_FOLLOWED_CATEGORIES,
    payload: data
  };
}

export function recieveSearchCount(data) {
  return {
    type: RECEIVE_USER_SEARCH_COUNT,
    payload: data
  };
}

function addFollowedCategories(categories) {
  return {
    type: ADD_FOLLOWED_CATEGORIES,
    payload: categories
  };
}

function removeFollowedCategories(categories) {
  return {
    type: REMOVE_FOLLOWED_CATEGORIES,
    payload: categories
  };
}

export function addUserToOrgFromInvite(params, rnToken) {
  return function doFetch() {
    return api_addUserToOrgFromInvite(params, rnToken);
  };
}

export function readNewFeatureTip(email, featureId, current_user) {
  const update = {
    properties: {
      read_new_feature_tip: {
        ...current_user.user.properties.read_new_feature_tip,
        [featureId]: true
      }
    }
  };
  return function doPost(dispatch) {
    // Before making the request, update the user for when there isn't an authenticated user since
    // no request will be made
    dispatch(newFeatureTipHasBeenRead(featureId));
    if (email) {
      return api_readNewFeatureTip(email, update).then(response => {
        dispatch(modifiedCurrentUser(response));
      });
    }
    return Promise.resolve();
  };
}

export function clearCurrentUserUpdatedState() {
  return {
    type: CLEAR_CURRENT_USER_UPDATED_STATE
  };
}

export const updateDashboardLayout = (params = {}, rnToken = null) => async dispatch => {
  dispatch(requestUpdateDashboardLayout());
  const response = await api_updateDashboardLayout(params, rnToken);
  dispatch(receiveUpdateDashboardLayout(params));
  return response;
};

export const updateUserDashboardWidget = params => async dispatch => {
  dispatch(updateUserWidget(params));
};

export const updateCurrentUser = (email, data, rnToken = null) => async (dispatch, getState) => {
  try {
    const isAuthenticated = getUserAuthenticatedState(getState());

    if (!isAuthenticated || !email) {
      return dispatch({
        type: NOTHING_HAPPENED_HERE
      });
    }

    dispatch(modifyCurrentUser());

    const response = await api_updateUser(email, data, rnToken);

    return dispatch(modifiedCurrentUser(response));
  } catch (e) {
    dispatch(modifiedCurrentUser(null));
  }
};

export function fetchUserCreatedDocuments(status) {
  return function dofetch(dispatch) {
    dispatch(requestUserCreatedDocuments());

    return api_getUserCreatedDocuments(status).then(response => {
      dispatch(receiveUserCreatedDocuments(response));
    });
  };
}

export const fetchCurrentUser = (rnToken = null) => async (dispatch, getState) => {
  try {
    const isAuthenticated = getUserAuthenticatedState(getState());

    if (!isAuthenticated) {
      return Promise.resolve();
    }

    dispatch(requestCurrentUser());

    const response = await api_getCurrentUser(rnToken);

    dispatch(receiveCurrentUser(response));

    return response;
  } catch {
    dispatch(receiveUnauthenticatedUser());
  }
};

export function fetchSpecifiedUser(email) {
  // dispatch provided by magic middleware redux-thunk
  return function dofetch(dispatch) {
    dispatch(requestSpecifiedUser(email));

    return api_getUser(email).then(response => {
      dispatch(receiveSpecifiedUser(response));
    });
  };
}

export function updateSpecifiedUser(original_email, data) {
  return function dopost(dispatch) {
    dispatch(modifySpecifiedUser());

    return api_updateUser(original_email, data)
      .then(
        response => {
          dispatch(modifiedSpecifiedUser(response));
          return response;
        },
        () => {
          dispatch(modifiedSpecifiedUser(null));
        }
      )
      .then(() => {
        dispatch(fetchSpecifiedUser(original_email));
      });
  };
}
export function fetchAppNotifications(params) {
  return function doFetch(dispatch) {
    dispatch(requestAppNotifications());
    return api_fetchAppNotifications(params)
      .then(response => dispatch(receiveAppNotifications(response)))
      .catch(error => console.log(error));
  };
}

export function editAppNotification(appNotificationId, data) {
  return function doFetch(dispatch) {
    return api_editAppNotification(appNotificationId, data).then(response => response);
  };
}

export function createAppNotification(data) {
  return function doFetch(dispatch) {
    return api_createAppNotification(data).then(response => response);
  };
}

export function fetchAllUsers() {
  return function dofetch(dispatch) {
    dispatch(requestAllUsers());

    return api_getAllUsers().then(response => {
      dispatch(receiveAllUsers(response));
    });
  };
}

export function postSearchQuery(data) {
  return function doPost(dispatch) {
    dispatch(createSearchQuery(data));
    return api_postSearchQuery(data).then(response => {
      dispatch(searchQueryCreated(response));
      return response;
    });
  };
}

// NOTE: The method `rateSearchResult` is also used in scenario's outside
// of an actual user search (ie. topic button relevancy)
export function rateSearchResult(doc_id, is_relevant, search_args) {
  return function dopost(dispatch) {
    dispatch(rateResult());
    return api_rateSearchResult({ doc_id, is_relevant, search_args }).then(() => {
      dispatch(resultRated(doc_id, is_relevant, search_args));
    });
  };
}

export const authorizePCUser = ({ email, pcSourceId, docId }) => async dispatch => {
  try {
    const res = await api_authorizePCUser({ email, pcSourceId, docId });
    dispatch({ type: AUTHORIZE_PREMIUM_CONTENT, payload: res.pc_docs_access });
  } catch (error) {
    console.log('premium content authorization error:', error);
  }
};

export const followCategories = categories => async (dispatch, getState) => {
  const isAuthenticated = getUserAuthenticatedState(getState());

  dispatch(addFollowedCategories(categories));

  if (!isAuthenticated) {
    return Promise.resolve();
  }

  const data = {
    categories,
    action: DEFAULTS_UPDATE_ACTIONS.CATEGORY_FOLLOW
  };

  const response = await api_updateUserDefaults(data);

  dispatch(recieveFollowedCategories(response));

  return response;
};

export const unfollowCategories = categories => async (dispatch, getState) => {
  const isAuthenticated = getUserAuthenticatedState(getState());

  dispatch(removeFollowedCategories(categories));

  if (!isAuthenticated) {
    return Promise.resolve();
  }

  const data = {
    categories,
    action: DEFAULTS_UPDATE_ACTIONS.CATEGORY_UNFOLLOW
  };

  const response = await api_updateUserDefaults(data);

  dispatch(recieveFollowedCategories(response));

  return response;
};

export function getRSSConfigurations(params) {
  return function doFetch() {
    return api_getRSSConfigurations(params);
  };
}

export function editRSSConfiguration(uuid, params) {
  return function doPost() {
    return api_editRSSConfiguration(uuid, params);
  };
}

export function createRSSConfiguration(params) {
  return function doPost() {
    return api_createRSSConfiguration(params);
  };
}

export const requestUserPreferences = () => {
  return {
    type: REQUEST_USER_PREFERENCES
  };
};

export const rejectUserPreferences = () => {
  return {
    type: REJECT_USER_PREFERENCES
  };
};

export const fetchUserPreferences = () => async (dispatch, getState) => {
  try {
    const isAuthenticated = getUserAuthenticatedState(getState());

    if (!isAuthenticated) {
      return Promise.resolve();
    }

    dispatch(requestUserPreferences());

    const userPreferences = await api_getUserPreferences();

    dispatch(updateUserPreferences(userPreferences));
  } catch (e) {
    logError(e);

    dispatch(rejectUserPreferences());

    throw e;
  }
};

export const postUserPreferences = params => async dispatch => {
  const userPreferences = await api_postUserPreferences(params);
  dispatch(updateUserPreferences(userPreferences));
};

export const updateUserDocsTableSettings = (tableKey, tableSettings) => async (
  dispatch,
  getState
) => {
  try {
    const currentDocsTablesSettings = getUserAllDocsTablesSettings(getState());

    dispatch(
      postUserPreferences({
        docs_tables_settings: {
          ...currentDocsTablesSettings,
          [tableKey]: tableSettings
        }
      })
    );
  } catch (e) {
    logError(e);

    throw e;
  }
};
