import type { PrimaryFilterData } from './usePrimaryFilterData.types';
import type { GlobalState } from 'shared/reducers';

import * as helpers from '../PrimaryFilter.helpers';
import * as actionBarConstants from 'constants/ActionBar';
import * as defaultViewConstants from 'common/Filter/DefaultFiltersViewSelect/DefaultFiltersViewSelect.constants';
import * as viewConstants from 'shared/features/view/view.constants';

import { FILTER_KEY as ACTION_BAR_FILTER_KEY } from 'constants/ActionBarFilter';
import { getActionBarFilterParamValue } from 'shared/features/filters/filters.selectors';
import { getAuthors, getAuthorsReadyState } from 'shared/features/authors/authors.selectors';
import {
  getConceptOptions,
  getConcepts,
  getConceptsGroupedOptions,
  getConceptsList,
  getConceptsReadyStatus
} from 'shared/features/concepts/concepts.selectors';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQueryParams } from 'utils/hooks';

export const usePrimaryFilterData = (): PrimaryFilterData => {
  const queryParams = useQueryParams();

  const [alertName, changeAlertName] = useState<PrimaryFilterData['localState']['alertName']>('');
  const [cleared, setCleared] = useState<PrimaryFilterData['localState']['cleared']>(false);
  const [areViewDefaultsLoading, setAreViewDefaultsLoading] = useState<
    PrimaryFilterData['localState']['areViewDefaultsLoading']
  >(false);

  const localState: PrimaryFilterData['localState'] = {
    alertName,
    cleared,
    areViewDefaultsLoading
  };

  const localActions: PrimaryFilterData['localActions'] = useMemo(
    () => ({
      changeAlertName,
      setCleared,
      setAreViewDefaultsLoading
    }),
    []
  );

  const reduxState: PrimaryFilterData['reduxState'] = useSelector((state: GlobalState) => ({
    current_user: { isReady: state.current_user.isReady, user: state.current_user.user },
    agencies: state.agencies,
    jurisdictions: state.jurisdictions,
    defaults: state.defaults,
    sources: state.sources,
    topics: state.topics,
    regulations: state.regulations,
    banks: state.banks,
    current_view: state.current_view,
    entities: state.entities,
    subscriptions: state.subscriptions,
    docTypes: state.docTypes as PrimaryFilterData['reduxState']['docTypes'],
    alerts: state.alerts,
    languages: state.languages,
    authors: getAuthors(state),
    areAuthorsReady: getAuthorsReadyState(state),
    organization_labels: state.organization.organization?.labels || [],
    organization: state.organization,
    filterParams: state.filters.primaryFilter.filterParams,
    loadingParams: state.filters.primaryFilter.loadingParams,
    errors: state.filters.primaryFilter.errors,
    isFilterReady: state.filters.primaryFilter.isReady,
    selectedDefaultView: state.filters.primaryFilter.defaultView,
    conceptsData: {
      isReady: getConceptsReadyStatus(state),
      conceptsList: getConceptsList(state),
      concepts: getConcepts(state),
      conceptOptions: getConceptOptions(state),
      conceptGroupOptions: getConceptsGroupedOptions(state)
    },
    actionBarFilterValues: {
      [ACTION_BAR_FILTER_KEY.ROWS_COUNT]: getActionBarFilterParamValue(
        ACTION_BAR_FILTER_KEY.ROWS_COUNT,
        actionBarConstants.DEFAULT_LIMIT
      )(state)
    }
  }));

  const formattedData: PrimaryFilterData['formattedData'] = useMemo(
    () => ({
      filterErrors: Object.values(reduxState.errors).filter(error => error),
      isAlertEditMode: helpers.shouldShowAlert(reduxState),
      isFilterDataLoading:
        Object.values(reduxState.loadingParams).find(Boolean) || !reduxState.isFilterReady,
      isNewsPage: queryParams.view === viewConstants.VIEW_KEY.NEWS,
      doOrgDefaultsExist:
        !reduxState.defaults[defaultViewConstants.DEFAULT_FILTERS_VIEW_OPTIONS.ORG_DEFAULTS]
          ?.isFetching &&
        !reduxState.defaults[defaultViewConstants.DEFAULT_FILTERS_VIEW_OPTIONS.ORG_DEFAULTS]
          ?.isUpdating
    }),
    [reduxState, queryParams.view]
  );

  return {
    localState,
    localActions,
    reduxState,
    formattedData
  };
};
