import PropTypes from 'prop-types';
import {
  Lock as MaterialLockIcon,
  LabelImportant as MaterialLabelImportantIcon
} from '@material-ui/icons';
import { Loader, LOADER_SIZES, Button, BUTTON_TYPES, Modal } from '@compliance.ai/web-components';
import { PremiumContentForm } from './elements';
import { useUserReduxActions } from 'shared/features/user/hooks';
import { usePremiumContentUnlockButtonData, usePremiumContentUnlockButtonHandlers } from './hooks';
import './_premium-content-unlock-button.scss';

export const PremiumContentUnlockButton = ({
  sourceName,
  sourceId,
  sourceURL,
  sourceAuthType,
  document,
  onSuccess,
  onError
}) => {
  const { localState, localActions, reduxState } = usePremiumContentUnlockButtonData();

  const userReduxActions = useUserReduxActions();

  const handlers = usePremiumContentUnlockButtonHandlers({
    props: {
      sourceId,
      document,
      onSuccess,
      onError
    },
    localActions,
    reduxState,
    userReduxActions
  });

  return (
    <>
      {localState.isOpen && (
        <Modal
          isOpen
          onClose={handlers.handleModalClose}
          className="premium-content-unlock-button__modal"
        >
          {localState.isLoading ? (
            <div className="premium-content-unlock-button__loader-container">
              <Loader size={LOADER_SIZES.LARGE} />
            </div>
          ) : (
            <PremiumContentForm
              sourceName={sourceName}
              sourceURL={sourceURL}
              sourceAuthType={sourceAuthType}
              document={document}
              didTryToAuth={localState.didTryToAuth}
              onUnlockClick={handlers.handlePremiumContentUnlock}
            />
          )}
        </Modal>
      )}
      <Button
        type={BUTTON_TYPES.PRIMARY}
        onClick={handlers.handleModalOpen}
        startIcon={
          <>
            <MaterialLockIcon className="premium-content-unlock-button__icon--lock" />
            <MaterialLabelImportantIcon className="premium-content-unlock-button__icon" />
          </>
        }
      >
        Unlock to see PDF
      </Button>
    </>
  );
};

PremiumContentUnlockButton.propTypes = {
  sourceName: PropTypes.string.isRequired,
  sourceId: PropTypes.number.isRequired,
  sourceURL: PropTypes.string.isRequired,
  sourceAuthType: PropTypes.string.isRequired,
  document: PropTypes.shape({
    web_url: PropTypes.string,
    pdf_url: PropTypes.string
  }).isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func
};

PremiumContentUnlockButton.defaultProps = {
  onSuccess: undefined,
  onError: undefined
};
