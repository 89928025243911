export const checkSavedSearchSharedPermissions = ({
  currentUserId,
  savedSearch,
  permissionToCheck
}) => {
  if (!currentUserId) {
    return false;
  }

  const sharedUser = savedSearch?.share_with_users?.find(user => user.user_id === currentUserId);
  return Boolean(sharedUser && sharedUser.permission === permissionToCheck);
};
