import type { FC } from 'react';
import type { DocumentActionsProps } from './DocumentActions.types';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';
import * as constants from './DocumentActions.constants';
import { DocumentActionsFolder } from 'common/DocumentActions/Folder';
import {
  Email as DocumentActionsEmail,
  Print as DocumentActionsPrint,
  Export as DocumentActionsExport
} from 'common/index';

import { useDocumentActionsData } from './hooks';

import './_document-actions.scss';

export const DocumentActions: FC<DocumentActionsProps> = ({ onEditMetadataOpen }) => {
  const { reduxState, formattedData } = useDocumentActionsData();

  if (!formattedData.document) {
    return null;
  }

  return (
    <div className="document-actions__container">
      <DocumentActionsFolder
        documentIds={[Number(reduxState.rightPanelDocumentId)]}
        savedFoldersCount={formattedData.documentSavedFoldersCount}
        showSavedFoldersCount
        actionButtonType={uiLib.ACTION_BUTTON_TYPE.TRANSPARENT_CONTRAST}
      />
      <DocumentActionsEmail
        enabled
        documentIds={[reduxState.rightPanelDocumentId]}
        actionButtonType={uiLib.ACTION_BUTTON_TYPE.TRANSPARENT_CONTRAST}
      />
      <DocumentActionsPrint
        enabled={formattedData.userHasAccess && formattedData.canPrint}
        documentIds={[reduxState.rightPanelDocumentId]}
        actionButtonType={uiLib.ACTION_BUTTON_TYPE.TRANSPARENT_CONTRAST}
        queryOptions={{
          extraQueryKey: constants.EXTRA_QUERY_KEYS.PRINT
        }}
      />
      <DocumentActionsExport
        documentIds={[reduxState.rightPanelDocumentId]}
        isEnabled={formattedData.userHasAccess && formattedData.canExport}
        actionButtonType={uiLib.ACTION_BUTTON_TYPE.TRANSPARENT_CONTRAST}
      />
      <elements.EditMetadataButton
        document={formattedData.document}
        onEditMetadataOpen={onEditMetadataOpen}
      />
    </div>
  );
};
