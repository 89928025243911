import type { AddLabelsModalData } from './useAddLabelsModalData.types';
import type { AddLabelsModalHandlers } from './useAddLabelsModalHandlers.types';
import type { SelectOption } from '@compliance.ai/web-components';
import type { LabelFromResponse } from 'shared/features/labels/labels.api.types';

export const useAddLabelsModalHandlers = ({
  localActions
}: {
  localActions: AddLabelsModalData['localActions'];
}): AddLabelsModalHandlers => {
  const handleLabelsChange: AddLabelsModalHandlers['handleLabelsChange'] = options => {
    localActions.setSelectedLabelIds(
      (options as SelectOption<LabelFromResponse['id']>[]).map(option => option.value)
    );
  };

  return {
    handleLabelsChange
  };
};
