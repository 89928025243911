import { RECEIVE_TIMEZONES } from './timezones.actions';

export const getInitialTimezonesState = () => ({
  timezones: []
});

export const timezones = (state = getInitialTimezonesState(), action) => {
  switch (action.type) {
    case RECEIVE_TIMEZONES:
      const timezones = action.payload;
      return {
        ...state,
        timezones
      };

    default:
      return state;
  }
};
