import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addBanner as reduxAddBanner } from 'shared/features/view/view.actions';
import { readNewFeatureTip as reduxReadNewFeatureTip } from 'shared/features/user/user.actions';
import { clearErrors as reduxClearErrors } from 'shared/features/error/actions';

export const useTimelineToolbarReduxActions = () => {
  const dispatch = useDispatch();

  const addBanner = useCallback(
    (banner_type, banner_status, content) => {
      return dispatch(reduxAddBanner(banner_type, banner_status, content));
    },
    [dispatch]
  );

  const readNewFeatureTip = useCallback(
    (email, featureId, current_user) => {
      return dispatch(reduxReadNewFeatureTip(email, featureId, current_user));
    },
    [dispatch]
  );

  const clearErrors = useCallback(() => {
    return dispatch(reduxClearErrors(null));
  }, [dispatch]);

  return {
    addBanner,
    readNewFeatureTip,
    clearErrors
  };
};
