import type { DocumentEditMetadataProps } from '../DocumentEditMetadata.types';

import { useDidUpdate } from '@compliance.ai/web-components';

export const useDocumentEditMetadataLifecycle = ({
  props
}: {
  props: DocumentEditMetadataProps;
}) => {
  useDidUpdate(props.onClose, [props.document.id]);
};
