import type { RelatedDocumentsPanelProps } from './RelatedDocumentsPanel.types';
import type { ComponentProps, FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';
import * as relatedDocsConstants from 'constants/RelatedDocuments';

import {
  useRelatedDocumentsPanelData,
  useRelatedDocumentsPanelHandlers,
  useRelatedDocumentsPanelLifecycles,
  useRelatedDocumentsPanelMutations,
  useRelatedDocumentsPanelQueries
} from './hooks';

import './_related-documents-panel.scss';

export const RelatedDocumentsPanel: FC<RelatedDocumentsPanelProps> = ({
  originalDocID,
  originalDocTitle,
  relatedDocuments
}) => {
  const { localState, localActions, reduxState } = useRelatedDocumentsPanelData();

  const mutations = useRelatedDocumentsPanelMutations({
    props: {
      originalDocID
    }
  });

  const queries = useRelatedDocumentsPanelQueries({
    props: {
      originalDocID,
      relatedDocuments
    }
  });

  const handlers = useRelatedDocumentsPanelHandlers({
    props: { originalDocID },
    localState,
    localActions,
    reduxState,
    queries,
    mutations
  });

  useRelatedDocumentsPanelLifecycles({
    onDefaultExpandedTypeChange: handlers.handleDefaultExpandedTypeChange
  });

  return (
    <uiLib.ErrorBoundary>
      <div className="related-document-panel__container">
        {localState.addModal.isOpen &&
          localState.addModal.relationType ===
            relatedDocsConstants.RELATED_DOCUMENT_RELATION_TYPES.ORGANIZATION && (
            <elements.AddRelatedDocumentsModal
              documentId={originalDocID}
              onClose={handlers.handleAddModalClose}
            />
          )}

        {localState.addModal.isOpen &&
          localState.addModal.relationType ===
            relatedDocsConstants.RELATED_DOCUMENT_RELATION_TYPES.ORGANIZATION_ALERT && (
            <elements.AddRelatedDocumentAlertsModal
              documentId={originalDocID}
              maxSelectableAlertsCount={
                relatedDocsConstants.MAX_RELATED_DOCUMENT_ALERTS_COUNT -
                (queries.fetchRelatedAlerts.data?.[
                  relatedDocsConstants.RELATED_DOCUMENT_RELATION_TYPES.ORGANIZATION_ALERT
                ]?.selectedIds?.length ?? 0)
              }
              selectedAlertIds={
                queries.fetchRelatedAlerts.data?.[
                  relatedDocsConstants.RELATED_DOCUMENT_RELATION_TYPES.ORGANIZATION_ALERT
                ]?.selectedIds ?? []
              }
              onClose={handlers.handleAddModalClose}
            />
          )}

        {localState.deleteModal.isOpen &&
          localState.deleteModal.relationType ===
            relatedDocsConstants.RELATED_DOCUMENT_RELATION_TYPES.ORGANIZATION && (
            <elements.DeleteRelatedDocumentsModal
              documentId={originalDocID}
              documentTitle={originalDocTitle}
              onClose={handlers.handleDeleteModalClose}
            />
          )}

        {Object.entries(queries.fetchRelatedAlerts.data ?? {}).map(([relationType, listConfig]) => (
          <div key={relationType} className="related-document-panel__section">
            {listConfig.sectionTitle && (
              <div className="related-document-panel__section-title">{listConfig.sectionTitle}</div>
            )}

            {(queries.fetchRelatedAlerts.isFetching || mutations.deleteRelatedAlert.isLoading) &&
              relationType ===
                relatedDocsConstants.RELATED_DOCUMENT_RELATION_TYPES.ORGANIZATION_ALERT && (
                <uiLib.Loader withoutBackground={false} isContrast />
              )}

            {mutations.deleteRelatedDocument.isLoading &&
              relationType ===
                relatedDocsConstants.RELATED_DOCUMENT_RELATION_TYPES.ORGANIZATION && (
                <uiLib.Loader withoutBackground={false} isContrast />
              )}

            <uiLib.List
              title={listConfig.listTitle}
              options={listConfig.options}
              selectedOptions={[]}
              shouldRenderAddMoreButton={listConfig.shouldRenderAddButton}
              addMoreButtonText={listConfig.addButtonText}
              shouldRenderShowMoreButton={listConfig.shouldRenderShowMoreButton}
              showMoreButtonText={relatedDocsConstants.SHOW_MORE_RELATED_DOCUMENTS_BUTTON_TEXT}
              onOptionClick={handlers.handleDocumentOptionClick(relationType)}
              onOptionDelete={handlers.handleDocumentOptionDelete(relationType)}
              onAddMoreClick={handlers.handleAddModalOpen(relationType)}
              onShowMoreClick={handlers.handleShowMoreClick(relationType)}
              onExpandButtonClick={handlers.handleExpandButtonClick(relationType)}
              isExpanded={
                localState.expanded[
                  relationType as relatedDocsConstants.RELATED_DOCUMENT_RELATION_TYPES
                ]
              }
              isOptionDeletable={listConfig.isOptionDeletable}
              getOptionContextMenuOptions={
                handlers.handleGetContextMenuOptions as ComponentProps<
                  typeof uiLib.List
                >['getOptionContextMenuOptions']
              }
              shouldRenderContextMenu
              isExpandable
              isContrast
            />
          </div>
        ))}
      </div>
    </uiLib.ErrorBoundary>
  );
};
