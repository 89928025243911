import { agencies } from './agencies/agencies.reducer';
import { REDUCER_NAME as AGENCIES_REDUCER_NAME } from './agencies/agencies.constants';

import { errors } from './error/reducer';
import { subscriptions, all_subscriptions } from './subscriptions/subscriptions.reducer';
import { regulations } from './regulations/regulations.reducer';
import { all_statistics } from './analytics/analytics.reducer';
import { all_plans } from './plans/plans.reducer';
import { acts } from './acts/acts.reducer';
import { autocompletes } from './search/search.reducer';
import { banks } from './banks/banks.reducer';
import { concepts } from './concepts/concepts.reducer';
import { dashboard } from './dashboard/dashboard.reducer';
import { metrics } from './metrics/metric.reducer';
import { premiumContent } from './premiumContent/premiumContent.reducer';
import { filters } from './filters/filters.reducer';
import { workflowMolds } from './workflowMold/workflowMold.reducer';
import {
  reducer as foldersReducer,
  REDUCER_NAME as FOLDERS_REDUCER_NAME
} from './folders/folders.reducer';
import {
  reducer as documentDetailsReducer,
  REDUCER_NAME as DOCUMENT_DETAILS_REDUCER_NAME
} from './documents/document_details.reducer';
import { reducer as orgDocumentsMetadataReducer } from './orgDocumentsMetadata/orgDocumentsMetadata.reducer';
import { REDUCER_NAME as ORG_DOCUMENTS_METADATA_REDUCER_NAME } from './orgDocumentsMetadata/orgDocumentsMetadata.constants';
import { tags } from './tags/tags.reducer';
import { alerts, native_notifications, notifications } from './alerts/alerts.reducer';
import { organization, teams, all_organizations } from './organizations/organizations.reducer';
import { topics_stats } from './topics/topics.reducer';

import { popular_sources, sources } from './sources/sources.reducer';
import { REDUCER_NAME as SOURCES_REDUCER_NAME } from './sources/sources.constants';

import { current_view } from './view/view.reducer';
import { widgets } from './widgets/widgets.reducer';
import { entities, filtered_mention } from './entities/entities.reducer';
import { expertConnection } from './expertConnection/expertConnection.reducer';
import { workflows, tasks } from './workflow/workflow.reducer';
import { insights_graphs, insights_csv, enforcementsData } from './insights/insights.reducer';
import { obligations } from './obligations/obligations.reducer';
import {
  current_document,
  docTypes,
  documents,
  documents_full,
  enforcements,
  googleSearchResults,
  recent_documents,
  search_results_relevance,
  us_state,
  recent_activity
} from './documents/documents.reducer';
import {
  current_user,
  all_users,
  specified_user,
  user_vote,
  user_created_documents
} from './user/user.reducer';

import { reducer as authReducer, REDUCER_NAME as AUTH_REDUCER_NAME } from './auth/auth.reducer';
import { reducer as rightPanelReducer } from './rightPanel/rightPanel.reducer';
import { REDUCER_NAME as RIGHT_PANEL_REDUCER_NAME } from './rightPanel/rightPanel.constants';
import { organizationProfile as organizationProfileReducer } from './organizationProfile/organizationProfile.reducer';
import { REDUCER_NAME as ORG_PROFILE_REDUCER_NAME } from './organizationProfile/organizationProfile.constants';
import { reducer as documentFieldsReducer } from './documentFields/documentFields.reducer';
import { REDUCER_NAME as DOCUMENT_FIELDS_REDUCER_NAME } from './documentFields/documentFields.constants';
import { reducer as filterGroupsReducer } from './filterGroups/filterGroups.reducer';
import { REDUCER_NAME as FILTER_GROUPS_REDUCER_NAME } from './filterGroups/filterGroups.constants';
import { reducer as jurisdictionAnalyzerReducer } from './jurisdictionAnalyzer/jurisdictionAnalyzer.reducer';
import { REDUCER_NAME as JURISDICTION_ANALYZER_REDUCER_NAME } from './jurisdictionAnalyzer/jurisdictionAnalyzer.reducer.constants';
import { reducer as sentenceNotesReducer } from './sentenceNotes/sentenceNotes.reducer';
import { REDUCER_NAME as SENTENCE_NOTES_REDUCER_NAME } from './sentenceNotes/sentenceNotes.constants';

import { docTasksByDate, allWorkflows } from './docTasks/docTasks.reducers';
import { teamTaskFilters } from './teamTasks/teamTasks.reducers';
import snackbar from './snackbar/snackbar.reducer';
import taskVelocityWidget from './widgets/TaskVelocity/taskVelocityWidget.reducer';

import jurisdictions from './jurisdictions/jurisdictions.reducer';
import { REDUCER_NAME as JURISDICTIONS_REDUCER_NAME } from './jurisdictions/jurisdictions.constants';

import { topics } from './topics/topics.reducer';
import { REDUCER_NAME as TOPICS_REDUCER_NAME } from './topics/topics.constants';

import enforcementActionsWidget from './widgets/EnforcementWidgets/enforcementActions.reducer';
import { thresholds } from './thresholds/thresholds.reducers';
import { reducer as resourcesReducer } from './resources/resources.reducer';
import { REDUCER_NAME as RESOURCES_REDUCER_NAME } from './resources/resources.constants';
import { timezones } from './timezones/timezones.reducer';

import { languages } from './languages/languages.reducer';
import { REDUCER_NAME as LANGUAGES_REDUCER_NAME } from './languages/languages.constants';

import { DOC_TYPES_REDUCER_NAME } from './documents/documents.constants';

import { defaults } from './defaults/defaults.reducer';
import { reducer as labels } from './labels/labels.reducer';
import { defaultStateAgencies } from './defaultStateAgencies/defaultStateAgencies.reducer';
import { REDUCER_NAME as AUTHORS_REDUCER_NAME } from './authors/authors.constants';
import { reducer as authorsReducer } from './authors/authors.reducer';
import { REDUCER_NAME as CURRENT_USER_REDUCER_NAME } from './user/user.constants';
import { REDUCER_NAME as ORGANIZATION_REDUCER_NAME } from './organizations/organizations.constants';

//TODO Use constants for reducers names

export default {
  [RIGHT_PANEL_REDUCER_NAME]: rightPanelReducer,
  [AUTH_REDUCER_NAME]: authReducer,
  [ORG_PROFILE_REDUCER_NAME]: organizationProfileReducer,
  [FOLDERS_REDUCER_NAME]: foldersReducer,
  [RESOURCES_REDUCER_NAME]: resourcesReducer,
  [DOCUMENT_DETAILS_REDUCER_NAME]: documentDetailsReducer,
  [ORG_DOCUMENTS_METADATA_REDUCER_NAME]: orgDocumentsMetadataReducer,
  [DOCUMENT_FIELDS_REDUCER_NAME]: documentFieldsReducer,
  [AUTHORS_REDUCER_NAME]: authorsReducer,
  [CURRENT_USER_REDUCER_NAME]: current_user,
  [ORGANIZATION_REDUCER_NAME]: organization,
  [FILTER_GROUPS_REDUCER_NAME]: filterGroupsReducer,
  [AGENCIES_REDUCER_NAME]: agencies,
  [JURISDICTIONS_REDUCER_NAME]: jurisdictions,
  [SOURCES_REDUCER_NAME]: sources,
  [TOPICS_REDUCER_NAME]: topics,
  [LANGUAGES_REDUCER_NAME]: languages,
  [JURISDICTION_ANALYZER_REDUCER_NAME]: jurisdictionAnalyzerReducer,
  [SENTENCE_NOTES_REDUCER_NAME]: sentenceNotesReducer,
  acts,
  alerts,
  all_organizations,
  all_plans,
  all_statistics,
  all_subscriptions,
  all_users,
  allWorkflows,
  autocompletes,
  banks,
  concepts,
  current_document,
  current_view,
  dashboard,
  defaults,
  defaultStateAgencies,
  docTasksByDate,
  [DOC_TYPES_REDUCER_NAME]: docTypes,
  documents_full,
  documents,
  timezones,
  enforcements,
  enforcementsData,
  enforcementActionsWidget,
  entities,
  errors,
  expertConnection,
  filtered_mention,
  filters,
  googleSearchResults,
  insights_csv,
  insights_graphs,
  labels,
  metrics,
  native_notifications,
  notifications,
  obligations,
  popular_sources,
  premiumContent,
  recent_activity,
  recent_documents,
  regulations,
  search_results_relevance,
  snackbar,
  specified_user,
  subscriptions,
  tags,
  tasks,
  taskVelocityWidget,
  teams,
  teamTaskFilters,
  thresholds,
  topics_stats,
  user_created_documents,
  user_vote,
  us_state,
  widgets,
  workflowMolds,
  workflows
};
