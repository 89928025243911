import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FILTERS_INITIAL_STATE } from '../elements/LabelDetailsFilters';
import { getLabelsDetailsRefreshTrigger } from 'shared/features/labels/labels.selectors';

const INITIAL_LABEL = { label: '', value: null };

export const useLabelDetailsData = () => {
  const [label, setLabel] = useState(INITIAL_LABEL);
  const [isLoading, setLoadingState] = useState(false);
  const [labelName, setLabelName] = useState('');
  const [isLabelEditable, setIsLabelEditable] = useState(false);
  const [filters, setFilters] = useState(FILTERS_INITIAL_STATE);
  const [refreshTrigger, setRefreshTrigger] = useState(false);

  const reduxState = useSelector(state => ({
    labelsDetailsRefreshTrigger: getLabelsDetailsRefreshTrigger(state)
  }));

  const localActions = useMemo(
    () => ({
      setLoadingState,
      setLabelName,
      setIsLabelEditable,
      setFilters,
      setLabel,
      setRefreshTrigger
    }),
    []
  );

  return {
    reduxState,
    localState: {
      isLabelEditable,
      isLoading,
      labelName,
      filters,
      label,
      refreshTrigger
    },
    localActions
  };
};
