import type { ProgressiveSentenceNotesArgs } from './useProgressiveSentenceNotes.types';

import { useProgressiveSentenceNotesData } from './useProgressiveSentenceNotesData';
import { useProgressiveSentenceNotesQueries } from './useProgressiveSentenceNotesQueries';
import { useProgressiveSentenceNotesLoader } from './useProgressiveSentenceNotesLoader';
import { useProgressiveSentenceNotesHandlers } from './useProgressiveSentenceNotesHandlers';
import { useProgressiveSentenceNotesLifecycle } from './useProgressiveSentenceNotesLifecycle';

export const useProgressiveSentenceNotes = ({
  type,
  documentId,
  sentenceNoteId,
  onFetchComplete,
  loaderProps
}: ProgressiveSentenceNotesArgs) => {
  const { localState, localActions, reduxState, formattedData } = useProgressiveSentenceNotesData({
    type,
    documentId
  });

  const queries = useProgressiveSentenceNotesQueries({
    args: {
      type,
      documentId,
      sentenceNoteId,
      onFetchComplete
    },
    localState,
    localActions
  });

  const handlers = useProgressiveSentenceNotesHandlers({
    queries
  });

  useProgressiveSentenceNotesLifecycle({
    onNextSentenceNotesFetch: handlers.handleNextSentenceNotesFetch
  });

  const loader = useProgressiveSentenceNotesLoader({
    args: {
      type,
      loaderProps
    },
    localState,
    reduxState,
    formattedData,
    queries
  });

  return {
    isFetching:
      queries.fetchSentenceNoteById.isFetching ||
      queries.fetchSentenceNotes.isFetching ||
      queries.fetchSystemGeneratedSentenceNotes.isFetching,
    sentenceNotes: reduxState.sentenceNotes,
    loader: loader
  };
};
