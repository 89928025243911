import {
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_PERMISSION,
  REQUEST_ALL_ALERTS,
  RECEIVE_ALL_ALERTS,
  REQUEST_DELETE_ALERTS,
  RECEIVE_DELETE_ALERTS,
  NEW_NOTIFICATIONS_STATUS
} from './alerts.actions';

export const getInitialAlertsState = () => ({
  isFetching: false,
  isDeleting: false,
  isReady: false,
  user_alerts: []
});

export const alerts = (state = getInitialAlertsState(), action) => {
  switch (action.type) {
    case REQUEST_ALL_ALERTS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_ALL_ALERTS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        user_alerts: action.user_alerts
      };
    case REQUEST_DELETE_ALERTS:
      return {
        ...state,
        isDeleting: true,
        isReady: false
      };
    case RECEIVE_DELETE_ALERTS:
      return {
        ...state,
        isDeleting: false,
        isReady: true,
        user_alerts: action.user_alerts
      };

    default:
      return state;
  }
};

export const getInitialNativeNotificationsState = () => ({
  notifications: null,
  permission: -1
});

export const native_notifications = (state = getInitialNativeNotificationsState(), action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications
      };
    case SET_NOTIFICATIONS_PERMISSION:
      return {
        ...state,
        permission: action.permission
      };
    default:
      return state;
  }
};

export const getInitialNotificationsState = () => ({
  status: { timeline: false, news: false }
});

export const notifications = (state = getInitialNotificationsState(), action) => {
  switch (action.type) {
    case NEW_NOTIFICATIONS_STATUS:
      return {
        ...state,
        status: {
          ...state.status,
          ...action.latest_stats
        }
      };
    default:
      return state;
  }
};
