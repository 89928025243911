import * as uiLib from '@compliance.ai/web-components';

import {
  WORKFLOW_ANNOTATION_TYPES,
  WORKFLOW_ANNOTATION_SETTINGS_KEYS
} from 'shared/features/workflow/workflow.constants';
import lodash from 'lodash';
import { DEFAULT_DOC_TASK_VALUES } from './useWorkflowPanelTaskData.constants';
import { EDITABLE_FIELDS_KEYS } from 'constants/TaskConstants';
import { normalizeCommentThreads } from 'shared/features/comments/comments.helpers';

const isDropdownAnnotationRequirementFulfiled = documentTask => {
  if (documentTask[WORKFLOW_ANNOTATION_TYPES.DROPDOWNS]) {
    const requiredDropdowns = documentTask[
      WORKFLOW_ANNOTATION_TYPES.DROPDOWNS
    ].filter(({ required }) => Boolean(required));

    if (requiredDropdowns.length > 0) {
      return requiredDropdowns.every(({ dropdown_options }) =>
        dropdown_options.some(({ selected }) => Boolean(selected))
      );
    }

    return true;
  }

  return true;
};

export const isAnnotationRequirementFulfilled = ({ documentTask, documentTaskValues }) => {
  const annotationSettings = documentTask.annotation_settings;

  const requiredAnnotationTypes = lodash.get(
    annotationSettings,
    WORKFLOW_ANNOTATION_SETTINGS_KEYS.REQUIREMENT,
    []
  );
  const annotationTypes = lodash.get(
    annotationSettings,
    WORKFLOW_ANNOTATION_SETTINGS_KEYS.TYPES,
    []
  );
  const assigneeOptions = lodash.get(
    annotationSettings,
    WORKFLOW_ANNOTATION_SETTINGS_KEYS.ASSIGNEE_OPTIONS,
    []
  );

  const checkType = type => {
    return annotationTypes.includes(type) && requiredAnnotationTypes.includes(type);
  };

  if (
    checkType(WORKFLOW_ANNOTATION_TYPES.DATEFIELD) &&
    !documentTaskValues[EDITABLE_FIELDS_KEYS.ANNOTATION_DATE]
  ) {
    return false;
  }

  if (
    checkType(WORKFLOW_ANNOTATION_TYPES.TEXT) &&
    !documentTaskValues[EDITABLE_FIELDS_KEYS.COMMENTS].length
  ) {
    return false;
  }

  if (
    annotationTypes.includes(WORKFLOW_ANNOTATION_TYPES.ASSIGNEES) &&
    !documentTaskValues[EDITABLE_FIELDS_KEYS.ANNOTATIONS_ASSIGNEE].length &&
    assigneeOptions &&
    assigneeOptions.length
  ) {
    return false;
  }

  if (
    checkType(WORKFLOW_ANNOTATION_TYPES.ATTACHMENT) &&
    !documentTaskValues[EDITABLE_FIELDS_KEYS.ATTACHMENTS].length
  ) {
    return false;
  }

  if (!isDropdownAnnotationRequirementFulfiled(documentTask)) {
    return false;
  }

  return true;
};

export const validateStatus = documentTask => {
  if (documentTask.task_status) {
    return documentTask.task_status;
  }

  return DEFAULT_DOC_TASK_VALUES[EDITABLE_FIELDS_KEYS.STATUS];
};

export const validateDeadline = (documentTask, userTimezone) => {
  if (documentTask?.deadline) {
    return uiLib.formatDate(documentTask.deadline, {
      format: uiLib.DATE_FORMATS.DOCUMENT_DATE,
      passedTimezone: uiLib.TIMEZONES.UTC,
      timezone: userTimezone,
      shouldKeepLocalTime: false
    });
  }

  return DEFAULT_DOC_TASK_VALUES[EDITABLE_FIELDS_KEYS.DEADLINE];
};

export const validateAssignee = documentTask => {
  if (documentTask.assignee) {
    return documentTask.assignee;
  }

  return DEFAULT_DOC_TASK_VALUES[EDITABLE_FIELDS_KEYS.ASSIGNEE];
};

export const validateAttachments = (documentTask, userTimezone) => {
  const annotationAttachments = documentTask.attachments;

  if (Array.isArray(annotationAttachments)) {
    return annotationAttachments;
  }

  return DEFAULT_DOC_TASK_VALUES[EDITABLE_FIELDS_KEYS.ATTACHMENTS];
};

export const validateCommentThreads = (documentTask, userTimezone) => {
  return normalizeCommentThreads(documentTask.comment_threads);
};

export const validateAnnotationAssigneeSelection = (documentTask, userTimezone) => {
  const annotationAssigneeSelection = documentTask.annotation_assignee_selection;

  if (Array.isArray(annotationAssigneeSelection)) {
    return annotationAssigneeSelection;
  } else if (annotationAssigneeSelection) {
    return [annotationAssigneeSelection];
  }

  return DEFAULT_DOC_TASK_VALUES[EDITABLE_FIELDS_KEYS.ANNOTATIONS_ASSIGNEE];
};

export const validateAnnotationDate = documentTask => {
  const annotationDate = documentTask.annotation_date;

  if (uiLib.isValidDate(annotationDate)) {
    return uiLib.formatDate(annotationDate, {
      format: uiLib.DATE_FORMATS.API_DATE_TIME,
      timezone: uiLib.TIMEZONES.UTC,
      passedTimezone: uiLib.TIMEZONES.UTC,
      shouldKeepLocalTime: false
    });
  }

  return DEFAULT_DOC_TASK_VALUES[EDITABLE_FIELDS_KEYS.ANNOTATION_DATE];
};

export const TASK_EDITABLE_FIELDS_VALIDATORS = {
  [EDITABLE_FIELDS_KEYS.STATUS]: validateStatus,
  [EDITABLE_FIELDS_KEYS.DEADLINE]: validateDeadline,
  [EDITABLE_FIELDS_KEYS.ASSIGNEE]: validateAssignee,
  [EDITABLE_FIELDS_KEYS.ATTACHMENTS]: validateAttachments,
  [EDITABLE_FIELDS_KEYS.COMMENTS]: validateCommentThreads,
  [EDITABLE_FIELDS_KEYS.ANNOTATIONS_ASSIGNEE]: validateAnnotationAssigneeSelection,
  [EDITABLE_FIELDS_KEYS.ANNOTATION_DATE]: validateAnnotationDate
};
