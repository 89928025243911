import lodash from 'lodash';
import { api_getJurisdictionsDocumentCounts } from './jurisdictionAnalyzer.api';
import { api_getDocumentsPost } from '../documents/documents.api';

export const RESET_JURISDICTION_ANALYZER = 'RESET_JURISDICTION_ANALYZER';
export const REQUEST_JURISDICTION_ANALYZER_DOCUMENTS = 'REQUEST_JURISDICTION_ANALYZER_DOCUMENTS';
export const RECEIVE_JURISDICTION_ANALYZER_DOCUMENTS = 'RECEIVE_JURISDICTION_ANALYZER_DOCUMENTS';
export const FAIL_JURISDICTION_ANALYZER_DOCUMENTS = 'FAIL_JURISDICTION_ANALYZER_DOCUMENTS';
export const REQUEST_JURISDICTIONS_DOCUMENT_COUNTS = 'REQUEST_JURISDICTIONS_DOCUMENT_COUNTS';
export const RECEIVE_JURISDICTIONS_DOCUMENT_COUNTS = 'RECEIVE_JURISDICTIONS_DOCUMENT_COUNTS';
export const FAIL_JURISDICTIONS_DOCUMENT_COUNTS = 'FAIL_JURISDICTIONS_DOCUMENT_COUNTS';
export const TOGGLE_SELECT_LOADED_JURISDICTION_DOCUMENTS =
  'TOGGLE_SELECT_LOADED_JURISDICTION_DOCUMENTS';
export const CHANGE_SELECTED_JURISDICTION = 'CHANGE_SELECTED_JURISDICTION';
export const RECEIVE_JURISDICTIONS_DOCUMENT_COUNTS_FOR_ALERT =
  'RECEIVE_JURISDICTIONS_DOCUMENT_COUNTS_FOR_ALERT';
export const UPDATE_VIEWED_JURISDICTIONS = 'UPDATE_VIEWED_JURISDICTIONS';

export const fetchJurisdictionDocuments = (
  jurisdiction,
  params,
  selectingAll
) => async dispatch => {
  try {
    params = lodash.clone({ ...params, jurisdiction });
    delete params.view;
    dispatch({ type: REQUEST_JURISDICTION_ANALYZER_DOCUMENTS, jurisdiction });
    const res = await api_getDocumentsPost(params);
    dispatch({
      type: RECEIVE_JURISDICTION_ANALYZER_DOCUMENTS,
      payload: res,
      limit: params.limit,
      offset: params.offset,
      jurisdiction,
      selectingAll
    });
  } catch (error) {
    dispatch({
      type: FAIL_JURISDICTION_ANALYZER_DOCUMENTS,
      jurisdiction,
      error
    });
  }
};

export function resetJurisdictionAnalyzer() {
  return {
    type: RESET_JURISDICTION_ANALYZER
  };
}

function requestJurisdictionsDocumentCounts() {
  return {
    type: REQUEST_JURISDICTIONS_DOCUMENT_COUNTS
  };
}

function receiveJurisdictionsDocumentCounts(jurisdictionDocumentMap, selectedJurisdictions) {
  return {
    type: RECEIVE_JURISDICTIONS_DOCUMENT_COUNTS,
    jurisdictionDocumentMap,
    selectedJurisdictions
  };
}

function failJurisdictionsDocumentCounts(error) {
  return {
    type: FAIL_JURISDICTIONS_DOCUMENT_COUNTS,
    error
  };
}

function receiveJurisdictionsDocumentCountsForAlert(jurisdictionDocumentMap, alertId) {
  return {
    type: RECEIVE_JURISDICTIONS_DOCUMENT_COUNTS_FOR_ALERT,
    jurisdictionDocumentMap,
    alertId
  };
}

export function getJurisdictionsDocumentCounts(params, selectedJurisdictions, sinceAlertId) {
  return function doFetch(dispatch) {
    if (!sinceAlertId) {
      dispatch(requestJurisdictionsDocumentCounts());
    }

    return api_getJurisdictionsDocumentCounts(params)
      .then(response => {
        if (sinceAlertId) {
          dispatch(receiveJurisdictionsDocumentCountsForAlert(response, sinceAlertId));
        } else {
          dispatch(receiveJurisdictionsDocumentCounts(response, selectedJurisdictions));
        }
      })
      .catch(error => {
        dispatch(failJurisdictionsDocumentCounts(error));
      });
  };
}

export function toggleSelectLoadedJurisdictionDocuments(jurisdiction, documentIds) {
  return function doSelect(dispatch) {
    dispatch({
      type: TOGGLE_SELECT_LOADED_JURISDICTION_DOCUMENTS,
      jurisdiction,
      documentIds
    });
  };
}

export function changeSelectedJurisdiction(jurisdiction) {
  return function doSelect(dispatch) {
    dispatch({
      type: CHANGE_SELECTED_JURISDICTION,
      jurisdiction
    });
  };
}

export function updateViewedJurisdictions(jurisdiction, documentId) {
  return function doSelect(dispatch) {
    dispatch({
      type: UPDATE_VIEWED_JURISDICTIONS,
      jurisdiction,
      documentId
    });
  };
}
