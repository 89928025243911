import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getOrganizationReducer } from 'shared/features/organizations/organizations.selectors';
import helpers from '../TaskAssigneeAnnotation.helpers';

export const useTaskAssigneeAnnotationData = ({ documentTask }) => {
  const reduxState = useSelector(state => ({
    organization: getOrganizationReducer(state)
  }));

  const formattedData = useMemo(
    () => ({
      potentialAssignees: helpers.getPotentialAssignees(documentTask, reduxState.organization)
    }),
    [documentTask, reduxState.organization]
  );

  return {
    reduxState,
    formattedData
  };
};
