import { Modal, Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import PropTypes from 'prop-types';
import GetAppIcon from '@material-ui/icons/GetApp';
import { appUrl } from 'shared/config';
import * as routes from 'constants/Routes';
import './_export-modal.scss';

export const TEST_ID = {
  MODAL_CONTENT: 'modal-content',
  APPLY_BUTTON: 'apply-button'
};

export const ExportModal = ({ modalClose }) => {
  return (
    <Modal
      onClose={modalClose}
      isOpen
      title={
        <div className="export-modal__header">
          <GetAppIcon className="export-modal__header-icon" />
          Export File
        </div>
      }
    >
      <div data-testid={TEST_ID.MODAL_CONTENT} className="export-modal__content">
        <div>
          Your export will be available in the{' '}
          <Button
            type={BUTTON_TYPES.LINK}
            onClick={() => window.open(`${appUrl}${routes.downloads}`, '_blank')}
          >
            Downloads
          </Button>{' '}
          page under the Profile menu shortly.
        </div>
        <div className="export-modal__close-button-wrapper">
          <Button
            type={BUTTON_TYPES.PRIMARY}
            dataTestId={TEST_ID.APPLY_BUTTON}
            onClick={modalClose}
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ExportModal.propTypes = {
  modalClose: PropTypes.func.isRequired
};
