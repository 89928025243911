import { COGNITO_AUTH_REDIRECT_MESSAGE_TYPES } from 'shared/features/auth/auth.constants';

const listenToWindowMessages = (refs, onError, onSignIn) =>
  function (e) {
    const { type, data, error } = e.data;

    /**
     *
     * Listen to the messages, that were posted from the window, created by this component
     *
     */
    if (e.source !== refs.formWindow.current) {
      return;
    }

    if (type === COGNITO_AUTH_REDIRECT_MESSAGE_TYPES.ERROR) {
      onError(error.message);
    }

    if (type === COGNITO_AUTH_REDIRECT_MESSAGE_TYPES.SUCCESS) {
      onSignIn(data);
    }
  };

export const setMessagesListener = (refs, props) => {
  /**
   *
   * Save function reference in ref to remove listener
   *
   */
  refs.windowMessagesListener.current = listenToWindowMessages(refs, props.onError, props.onSignIn);
  window.addEventListener('message', refs.windowMessagesListener.current);
};

export const removeMessagesListener = refs => {
  window.removeEventListener('message', refs.windowMessagesListener.current);
};
