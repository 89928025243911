import type { FC } from 'react';
import type { RelevancyCellProps } from './RelevancyCell.types';

import * as docTasksConstants from 'constants/DocTasks';
import * as emptyValue from 'constants/EmptyValue';
import * as constants from './RelevancyCell.constants';

export const RelevancyCell: FC<RelevancyCellProps> = ({ cellValue }) => {
  if (cellValue.task_status !== docTasksConstants.DONE) {
    return <>{emptyValue.EMPTY_VALUE.DASH}</>;
  }

  switch (cellValue.non_relevant) {
    case true: {
      return <>{constants.TASK_RELEVANCE_VALUES.NON_RELEVANT}</>;
    }

    case false: {
      return <>{constants.TASK_RELEVANCE_VALUES.RELEVANT}</>;
    }

    default: {
      return <>{emptyValue.EMPTY_VALUE.DASH}</>;
    }
  }
};
