import type { RightPanelProps } from '../../rightPanel.reducer.types';
import type { RightPanelQueryParamsReduxState } from './useRightPanelQueryParamsData.types';
import type { DocumentFromResponse } from '../../../documents/documents.types';
import type { SentenceNoteFromResponse } from '../../../sentenceNotes/sentenceNotes.api.types';

import * as rightPanelConstants from '../../rightPanel.constants';
import * as urlUtils from 'utils/url-utils';

import { useQueryParams } from 'utils/hooks';

export const getUpdatedRightPanelQueryParams = ({
  type,
  overlayType,
  isClosable,
  documentDetails,
  props
}: {
  type: rightPanelConstants.RIGHT_PANEL_TYPES;
  overlayType: rightPanelConstants.OVERLAY_TYPES;
  isClosable: boolean;
  documentDetails: RightPanelQueryParamsReduxState['documentDetails'];
  props: RightPanelProps;
}): Omit<
  Record<
    rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS | rightPanelConstants.OVERLAY_QUERY_PARAMS,
    unknown
  >,
  | rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS.DOC_ID_V1
  | rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS.DOC_ID_V2
> &
  Partial<{
    [rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS.DOC_ID_V1]: unknown;
    [rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS.DOC_ID_V2]: unknown;
  }> => {
  const diffParams =
    props.diffData?.docV1 && props.diffData?.docV2
      ? {
          [rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS.DOC_ID_V1]: props.diffData?.docV1,
          [rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS.DOC_ID_V2]: props.diffData?.docV2
        }
      : {};

  return {
    ...diffParams,
    [rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS.SUMMARY_PAGE]: documentDetails?.activeTabKey
      ? rightPanelConstants.DOCUMENT_DETAILS_TAB_QUERY_PARAM_VALUES[documentDetails.activeTabKey]
      : rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.DETAILS,
    [rightPanelConstants.OVERLAY_QUERY_PARAMS.OVERLAY_TYPE]: overlayType,
    [rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS.RIGHT_PANEL_TYPE]: type,
    [rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS.SUMMARY_ID]: props?.documentId,
    [rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS.CLOSE_RIGHT_PANEL_ON_OVERLAY_CLOSE]:
      props?.shouldCloseRightPanelOnOverlayClose,
    [rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS.SENTENCE_NOTE_ID]: props?.sentenceNoteId,
    [rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS.IS_CLOSABLE]: isClosable
  };
};

export const removeRightPanelQueryParams = (
  queryParams: ReturnType<typeof useQueryParams>
): Record<string, unknown> => {
  return urlUtils.filterNullishQueryParams({
    ...queryParams,
    ...Object.fromEntries(
      [
        ...Object.values(rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS),
        ...Object.values(rightPanelConstants.OVERLAY_QUERY_PARAMS)
      ].map(queryParam => [queryParam, null])
    )
  });
};

export const getRightPanelType = (
  queryParams: ReturnType<typeof useQueryParams>
): rightPanelConstants.RIGHT_PANEL_TYPES | null => {
  const typeQueryParam = queryParams[
    rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS.RIGHT_PANEL_TYPE
  ] as rightPanelConstants.RIGHT_PANEL_TYPES;

  const documentIdQueryParam = queryParams[rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS.SUMMARY_ID];

  const type = Object.values(rightPanelConstants.RIGHT_PANEL_TYPES).includes(typeQueryParam)
    ? typeQueryParam
    : null;

  if (type) {
    return type;
  }

  /**
   *
   * Safety check for legacy links
   *
   */
  if (Number(documentIdQueryParam)) {
    return rightPanelConstants.RIGHT_PANEL_TYPES.DOCUMENT_DETAILS;
  }

  return null;
};

export const getInitialRightPanelOpenArgs = (
  queryParams: ReturnType<typeof useQueryParams>
): {
  type: rightPanelConstants.RIGHT_PANEL_TYPES | null;
  isClosable: boolean;
  overlayType: rightPanelConstants.OVERLAY_TYPES | undefined;
  documentId: DocumentFromResponse['id'] | null;
  shouldOpenRightPanelWithOverlay: boolean;
  shouldCloseRightPanelOnOverlayClose: boolean;
  sentenceNoteId: SentenceNoteFromResponse['id'] | null;
  activeTabKey: rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY;
  diffData: {
    docV1: DocumentFromResponse['id'] | null;
    docV2: DocumentFromResponse['id'] | null;
  };
} => {
  return {
    type: getRightPanelType(queryParams),

    isClosable: Boolean(queryParams[rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS.IS_CLOSABLE]),

    overlayType: queryParams[rightPanelConstants.OVERLAY_QUERY_PARAMS.OVERLAY_TYPE] as
      | rightPanelConstants.OVERLAY_TYPES
      | undefined,

    documentId:
      Number(queryParams[rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS.SUMMARY_ID]) || null,

    shouldOpenRightPanelWithOverlay: Boolean(
      queryParams[rightPanelConstants.OVERLAY_QUERY_PARAMS.OVERLAY_TYPE]
    ),

    shouldCloseRightPanelOnOverlayClose: Boolean(
      queryParams[rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS.CLOSE_RIGHT_PANEL_ON_OVERLAY_CLOSE]
    ),

    sentenceNoteId:
      Number(queryParams[rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS.SENTENCE_NOTE_ID]) || null,

    activeTabKey:
      rightPanelConstants.DOCUMENT_DETAILS_TAB_QUERY_PARAM_VALUES_TO_TAB_KEYS[
        queryParams[rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS.SUMMARY_PAGE] as string
      ] ?? rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.DETAILS,

    diffData: {
      docV1: Number(queryParams[rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS.DOC_ID_V1]) || null,
      docV2: Number(queryParams[rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS.DOC_ID_V2]) || null
    }
  };
};
