import type { FC } from 'react';

import * as common from 'common';

import auth from 'utils/auth';

import { useNavBarAuthHandlers } from './hooks';

import './_nav-bar-auth.scss';

export const NavBarAuth: FC = () => {
  const handlers = useNavBarAuthHandlers();

  return (
    <div className="nav-bar-auth">
      {auth.loggedIn() ? (
        <div className="nav-bar-auth__mobile-account-info nav-bar-auth__mobile-account-info--is-logged">
          <common.AccountMenu />
        </div>
      ) : (
        <div className="nav-bar-auth__mobile-account-info">
          <div
            className="nav-bar-auth__mobile-auth-btn nav-bar-auth__mobile-auth-btn--is-sign-up"
            onClick={handlers.handleSignUp}
          >
            Create an Account
          </div>
          <div
            className="nav-bar-auth__mobile-auth-btn nav-bar-auth__mobile-auth-btn--is-sign-in"
            onClick={handlers.handleSignIn}
          >
            Log In
          </div>
        </div>
      )}
    </div>
  );
};
