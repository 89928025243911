import { ALERT_TYPES, showNotification } from '@compliance.ai/web-components';

export enum DOCUMENT_METADATA_FIELDS {
  ID = 'id',
  TITLE = 'title',
  SUMMARY = 'summary',
  PUBLICATION_DATE = 'publication_date',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  COMMENTS_CLOSE_ON = 'comments_close_on',
  EFFECTIVE_ON = 'effective_on',
  DOC_TYPES = 'doc_types',
  JURISDICTION = 'jurisdiction',
  CITATION = 'citation',
  AUTHOR = 'author',
  TOPICS = 'topics'
}

export const DOCUMENT_METADATA_AVAILABLE_FIELD_MAP: Record<string, string> = {
  topic: DOCUMENT_METADATA_FIELDS.TOPICS
};

export enum DOCUMENT_METADATA_UPDATE_STATUS {
  QUEUED = 'queued',
  FAILED = 'failed',
  COMPLETE = 'complete'
}

export const DOCUMENT_METADATA_UPDATE_STATUS_ALERTS: {
  [key in DOCUMENT_METADATA_UPDATE_STATUS]: Parameters<typeof showNotification>[0];
} = {
  [DOCUMENT_METADATA_UPDATE_STATUS.QUEUED]: {
    type: ALERT_TYPES.INFO,
    title: 'Hold on',
    message: 'Metadata is updating and will be completed soon.'
  },
  [DOCUMENT_METADATA_UPDATE_STATUS.FAILED]: {
    type: ALERT_TYPES.ERROR,
    title: 'Error',
    message: `Metadata can't be updated, please try again.`
  },
  [DOCUMENT_METADATA_UPDATE_STATUS.COMPLETE]: {
    type: ALERT_TYPES.SUCCESS,
    title: 'Success',
    message: 'Metadata is updated successfully.'
  }
};

export const DOCUMENT_DATE_FORMAT = 'MM-DD-YYYY';
