import lodash from 'lodash';

export function formatNumber(value, digits = 2) {
  if (value === null || value === undefined) {
    return 0;
  }

  const labels = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' }
  ];
  const regex = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = labels.length - 1; i > 0; i--) {
    if (value >= labels[i].value) {
      break;
    }
  }
  return (value / labels[i].value).toFixed(digits).replace(regex, '$1') + labels[i].symbol;
}

export const tryToParseToNumber = value => {
  if (!isNaN(+value)) {
    return +value;
  }
  return value;
};

export const formatNumberWithCommas = x => {
  return !lodash.isNil(x) ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null;
};

export const isValidNumberOrArrayOfNumbers = value => {
  return isFinite(value) || (Array.isArray(value) && value.every(isFinite));
};
