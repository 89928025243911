import type { CreateSentenceNoteModalProps } from '../CreateSentenceNoteModal.types';
import type { CreateSentenceNoteModalHandlers } from './useCreateSentenceNoteModalHandlers.types';
import type { CreateSentenceNoteModalFormattedData } from './useCreateSentenceNoteModalData.types';

export const useCreateSentenceNoteModalHandlers = ({
  props,
  formattedData
}: {
  props: Pick<CreateSentenceNoteModalProps, 'onCreate'>;
  formattedData: CreateSentenceNoteModalFormattedData;
}): CreateSentenceNoteModalHandlers => {
  const handleSentenceNoteCreate: CreateSentenceNoteModalHandlers['handleSentenceNoteCreate'] = () => {
    props.onCreate(formattedData.bucketId);
  };
  return {
    handleSentenceNoteCreate
  };
};
