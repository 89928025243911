import type { AgenciesSelectData } from './useAgenciesSelectData.types';
import type { GlobalState } from 'shared/reducers';
import type { AgenciesSelectProps } from '../AgenciesSelect.types';

import { useSelector } from 'react-redux';
import {
  getAgenciesOptionsByKey,
  getAgenciesReadyStatus
} from 'shared/features/agencies/agencies.selectors';
import { useMemo } from 'react';

import { formatAgencyValuesWithTooltips } from './useAgenciesSelectData.helpers';
import { AGENCIES_SELECT_VALUE_KEYS } from '../AgenciesSelect.constants';

export const useAgenciesSelectData = (props: AgenciesSelectProps): AgenciesSelectData => {
  const reduxState = useSelector<GlobalState, AgenciesSelectData['reduxState']>(state => {
    return {
      areAgenciesReady: getAgenciesReadyStatus(state),
      agenciesOptions: getAgenciesOptionsByKey(
        state,
        // TODO Remove when agencies selectors will be converted to TS
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props.valueKey ?? AGENCIES_SELECT_VALUE_KEYS.ID
      )
    };
  });

  const value = useMemo(() => {
    return formatAgencyValuesWithTooltips({
      options: reduxState.agenciesOptions,
      values: props.value
    });
  }, [reduxState.agenciesOptions, props.value]);

  return {
    reduxState,
    formattedData: {
      value
    }
  };
};
