import type { GlobalState } from 'shared/reducers';
import type { ConceptsSelectData } from './useConceptsSelectData.types';
import type { ConceptsSelectProps } from '../ConceptsSelect.types';
import type { ConceptsSelectOptions } from './useConceptsSelectData.types';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import * as conceptsSelectors from 'shared/features/concepts/concepts.selectors';

export const useConceptsSelectData = (
  props: Pick<ConceptsSelectProps, 'value' | 'extraOptions'>
): ConceptsSelectData => {
  const reduxState = useSelector<GlobalState, ConceptsSelectData['reduxState']>(state => ({
    options: conceptsSelectors.getConceptsGroupedOptions(state as never),
    concepts: conceptsSelectors.getConceptsList(state)
  }));

  const formattedData: ConceptsSelectData['formattedData'] = useMemo(() => {
    const options = [...(props.extraOptions ?? []), ...reduxState.options] as ConceptsSelectOptions;
    const value = options
      .flatMap(({ options }) => options)
      .filter(o => props.value?.includes(o.value));

    return {
      options,
      value
    };
  }, [props.extraOptions, props.value, reduxState.options]);

  return {
    reduxState,
    formattedData
  };
};
