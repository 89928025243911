import type {
  NormalizedWorkflowsResponse,
  DetailedWorkflowFromResponse
} from '../../workflow.types';
import type { AxiosResponse } from 'axios';
import type { ListRequestParams } from 'types/global.types';

import { useCallback, useMemo } from 'react';
import { logError } from 'utils/errors';
import * as helpers from '../../workflow.helpers';
import * as api from '../../workflow.api';
import StatusCodes from 'http-status-codes';

export const useWorkflowsApi = () => {
  const fetchWorkflows = useCallback(async (params: ListRequestParams): Promise<
    NormalizedWorkflowsResponse
  > => {
    try {
      const response = await api.fetchWorkflows(params);

      return {
        count: response.total,
        results: helpers.normalizeWorkflows(response.workflows)
      };
    } catch (e) {
      logError(e as Error);

      return {
        count: 0,
        results: []
      };
    }
  }, []);

  const fetchWorkflow = useCallback(
    async (workflowId: number): Promise<DetailedWorkflowFromResponse | null> => {
      try {
        const workflow = await api.fetchWorkflow(workflowId);

        return helpers.sortWorkflowTasks(workflow);
      } catch (e) {
        if ((e as AxiosResponse).status === StatusCodes.NOT_FOUND) {
          return null;
        } else {
          logError(e as Error);
          return null;
        }
      }
    },
    []
  );

  const deleteWorkflow = useCallback(async (workflowId: number): Promise<void> => {
    try {
      await api.deleteWorkflow(workflowId);
    } catch (e) {
      logError(e as Error);
      throw e;
    }
  }, []);

  const updateWorkflow = useCallback(
    async (
      workflowId: number,
      params: Record<string, unknown>
    ): Promise<DetailedWorkflowFromResponse> => {
      try {
        const { workflow } = await api.api_updateWorkflow(workflowId, params);
        return workflow;
      } catch (e) {
        logError(e as Error);
        throw e;
      }
    },
    []
  );

  const updateWorkflowV2 = useCallback(
    async (
      workflowId: number,
      params: Record<string, unknown>
    ): Promise<DetailedWorkflowFromResponse> => {
      try {
        const workflow = await api.updateWorkflowV2(workflowId, params);
        return workflow;
      } catch (e) {
        logError(e as Error);
        throw e;
      }
    },
    []
  );

  const addWorkflow = useCallback(async (params: Record<string, unknown>): Promise<
    DetailedWorkflowFromResponse
  > => {
    try {
      const { workflow } = await api.api_addWorkflow(params);
      return workflow;
    } catch (e) {
      logError(e as Error);
      throw e;
    }
  }, []);

  return useMemo(
    () => ({
      fetchWorkflows,
      fetchWorkflow,
      deleteWorkflow,
      updateWorkflow,
      updateWorkflowV2,
      addWorkflow
    }),
    [fetchWorkflows, fetchWorkflow, deleteWorkflow, updateWorkflow, updateWorkflowV2, addWorkflow]
  );
};
