import type { TaskDeadlineProps } from './TaskDeadline.types';
import type { FC } from 'react';

import { WorkflowTaskDeadline } from 'common/index';
import classnames from 'classnames';
import { Tooltip, TOOLTIP_POSITION, LabeledField } from '@compliance.ai/web-components';
import { useTaskDeadlineData, useTaskDeadlineHandlers } from './hooks';
import './_task-deadline.scss';

export const TaskDeadline: FC<TaskDeadlineProps> = ({
  documentTask,
  document,
  value,
  onChange
}) => {
  const { localState, localActions, reduxState, formattedData } = useTaskDeadlineData({
    documentTask,
    document
  });

  const handlers = useTaskDeadlineHandlers({
    props: {
      documentTask,
      onChange
    },
    localActions,
    reduxState,
    formattedData
  });

  if (localState.isEditable && !reduxState.isMobile) {
    return (
      <>
        <div className="task-deadline__picker-container">
          <WorkflowTaskDeadline
            deadline={value}
            handleSelectChange={handlers.handleDeadlineChange}
            closeEditDeadline={() => localActions.setIsEditable(false)}
          />
        </div>
      </>
    );
  }

  return (
    <div
      className={classnames('task-deadline__container', {
        'task-deadline__container--is-editable': formattedData.isEditableByPermission
      })}
      onClick={handlers.handleDeadlineClick}
    >
      <LabeledField label="Deadline" isContrast labelClassName="task-deadline__label">
        <div className="task-deadline__value-container">
          <Tooltip
            id="task-deadline-tooltip"
            title={formattedData.tooltipTitle}
            placement={TOOLTIP_POSITION.BOTTOM}
          >
            {formattedData.tooltipContent}
          </Tooltip>
        </div>
      </LabeledField>
    </div>
  );
};
