import type { AlertsResponse, AlertFromResponse } from '../alerts.api.types';

import { useCallback, useMemo } from 'react';
import { api_fetchAllAlerts } from '../alerts.api';
import { logError } from 'utils/errors';
import { SUPPORTED_QUERY_PARAMS } from 'constants/PrimaryFilter';

export const useAlertsApi = () => {
  const fetchAlerts = useCallback(async (params: Record<string, unknown>): Promise<
    AlertsResponse
  > => {
    try {
      return ((await api_fetchAllAlerts(params)) as unknown) as AlertsResponse;
    } catch (e) {
      logError(e as Error);

      return {
        count: 0,
        results: []
      };
    }
  }, []);

  const fetchAlertIds = useCallback(async (params: Record<string, unknown>): Promise<
    AlertFromResponse['id'][]
  > => {
    try {
      const response = ((await api_fetchAllAlerts({
        ...params,
        [SUPPORTED_QUERY_PARAMS.EXCLUSIVE_FIELDS]: ['id']
      })) as unknown) as AlertsResponse;

      return response.results.map(alert => alert.id);
    } catch (e) {
      logError(e as Error);

      return [];
    }
  }, []);

  return useMemo(
    () => ({
      fetchAlerts,
      fetchAlertIds
    }),
    [fetchAlerts, fetchAlertIds]
  );
};
