import React from 'react';
import { connect } from 'react-redux';
import { Checkbox } from '@compliance.ai/web-components';
import classnames from 'classnames';
import { changeBulkSelectedItem } from 'shared/features/view/view.actions';

export class BulkDocumentSelect extends React.Component {
  state = {};

  render() {
    const bulkSelectClasses = {
      'bulk-select-controls': true,
      timeline: this.props.bulk === 'timeline',
      search: this.props.bulk === 'search'
    };
    const select = this.props.bulk === 'search' ? 'select all in view' : 'select all';
    const unselect = this.props.bulk === 'search' ? 'unselect all in view' : 'unselect all';
    const checkBoxLabel = this.props.current_view.bulk_docs_selected ? unselect : select;
    const handleCheck = e => {
      const value = !!e;
      this.props.changeBulkSelectedItem(value, this.props.bulk);
    };

    return (
      <div className={classnames(bulkSelectClasses)}>
        <Checkbox
          onChange={handleCheck}
          isChecked={this.props.current_view.bulk_docs_selected}
          className="bulk-select-checkbox"
          label={!this.props.noLabel && <label>{checkBoxLabel}</label>}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { current_view: state.current_view };
};

const mapDispatchToProps = dispatch => {
  return {
    changeBulkSelectedItem: (value, bulk) => {
      dispatch(changeBulkSelectedItem(value, bulk));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkDocumentSelect);
