import PropTypes from 'prop-types';
import { Alert, ALERT_TYPES } from '@compliance.ai/web-components';

export const AuthFormAlert = ({ message, ...props }) => {
  return message ? (
    <Alert type={message.type} title={message.title} content={message.content} {...props} />
  ) : null;
};

AuthFormAlert.propTypes = {
  message: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(ALERT_TYPES)),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
  }),
  type: PropTypes.oneOf(Object.values(ALERT_TYPES)),
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  isNotification: PropTypes.bool,
  isInlineNotification: PropTypes.bool,
  timeout: PropTypes.number
};
