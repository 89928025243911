import type { AnnotationPanelHandlers } from './useAnnotationPanelHandlers.types';

import { useDidMount, useWillUnmount } from '@compliance.ai/web-components';

export const useAnnotationPanelLifecycle = ({
  onOverlayOpenOnMount,
  onOverlayCloseOnUnmount,
  onStateClearOnUnmount
}: {
  onOverlayOpenOnMount: AnnotationPanelHandlers['handleOverlayOpenOnMount'];
  onOverlayCloseOnUnmount: AnnotationPanelHandlers['handleOverlayCloseOnUnmount'];
  onStateClearOnUnmount: AnnotationPanelHandlers['handleStateClearOnUnmount'];
}) => {
  useDidMount(onOverlayOpenOnMount);

  useWillUnmount(onOverlayCloseOnUnmount);
  useWillUnmount(onStateClearOnUnmount);
};
