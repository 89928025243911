import lodash from 'lodash';
import {
  RECEIVE_DOC_TYPES,
  REQUEST_ENFORCEMENTS,
  RECEIVE_ENFORCEMENTS,
  REQUEST_AGGREGATE_ENFORCEMENTS,
  RECEIVE_AGGREGATE_ENFORCEMENTS,
  REQUEST_GOOGLE_RESULTS,
  RECEIVE_GOOGLE_RESULTS,
  CLEAR_DOCUMENTS,
  REQUEST_DOCUMENTS,
  RECEIVE_DOCUMENTS,
  FAIL_DOCUMENTS,
  RECEIVE_DOCUMENTS_FOR_DASH_CCPA,
  RECEIVE_DOCUMENTS_FOR_DASH_COVID,
  REQUEST_DOCUMENTS_AFTER,
  RECEIVE_DOCUMENTS_AFTER,
  REQUEST_DOCUMENTS_BEFORE,
  RECEIVE_DOCUMENTS_BEFORE,
  REQUEST_FULL_DOCUMENTS,
  RECEIVE_FULL_DOCUMENTS,
  REQUEST_SIMPLE_DOCUMENTS,
  RECEIVE_SIMPLE_DOCUMENTS,
  REQUEST_DOCUMENT,
  RECEIVE_DOCUMENT,
  ADD_DOCS_TO_DIFF,
  MARK_DOCUMENT_BOOKMARKED,
  REQUEST_SEARCH_RESULTS_RELEVANCE,
  RECEIVE_SEARCH_RESULTS_RELEVANCE,
  FAIL_SEARCH_RESULTS_RELEVANCE,
  REQUEST_RESOURCE_CODE,
  RECEIVE_RESOURCE_CODE,
  REQUEST_RECENT_ACTIVITY,
  RECEIVE_RECENT_ACTIVITY,
  REQUEST_DOCUMENTS_FOR_DASH_COVID,
  REQUEST_DOCUMENTS_FOR_DASH_CCPA,
  REQUEST_DOCUMENTS_FOR_DASH_REGULATION,
  RECEIVE_DOCUMENTS_FOR_DASH_REGULATION,
  REQUEST_DOCUMENTS_FOR_DASH_ALERT_WIDGET,
  RECEIVE_DOCUMENTS_FOR_DASH_ALERT_WIDGET,
  RECEIVE_DOC_TYPES_UPDATES_NOTIFICATION
} from './documents.actions';
import { process_combined_documents, DEFAULT_DOCUMENT_SORT_KEY } from './documents.helper';
import { componentNames as WIDGET_COMPONENT_NAMES } from 'constants/ComponentsNames';

export const getInitialDocTypesState = () => ({});

export const docTypes = (state = getInitialDocTypesState(), action) => {
  switch (action.type) {
    case RECEIVE_DOC_TYPES:
      return {
        ...state,
        docTypes: action.docTypes
      };
    case RECEIVE_DOC_TYPES_UPDATES_NOTIFICATION:
      return {
        ...state,
        docTypesUpdatesNotification: action.docTypesUpdatesNotification
      };
    default:
      return state;
  }
};

export const getInitialEnforcementsState = () => ({
  isLoading: false,
  enforcements: [],
  isFetching: false,
  isReady: false,
  aggregateEnforcements: {}
});
export const enforcements = (state = getInitialEnforcementsState(), action) => {
  switch (action.type) {
    case REQUEST_AGGREGATE_ENFORCEMENTS:
      return {
        ...state,
        isLoading: true
      };

    case RECEIVE_AGGREGATE_ENFORCEMENTS:
      return {
        ...state,
        isLoading: false,
        aggregateEnforcements: action.aggregateEnforcements.reduce(
          (obj, data) => ({
            ...obj,
            [data.short_name]: data
          }),
          {}
        )
      };

    case REQUEST_ENFORCEMENTS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_ENFORCEMENTS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        enforcements: action.payload
      };
    default:
      return state;
  }
};

export const getInitialGoogleSearchResultsState = () => ({
  isFetching: false,
  isReady: false,
  googleSearchResults: {
    searchInformation: {
      totalResults: ''
    },
    items: []
  }
});

export const googleSearchResults = (state = getInitialGoogleSearchResultsState(), action) => {
  switch (action.type) {
    case REQUEST_GOOGLE_RESULTS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_GOOGLE_RESULTS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        googleSearchResults: action.payload
      };
    default:
      return state;
  }
};

export const getInitialRecentDocumentsState = () => ({
  isFetching: false,
  isReady: false,
  recent_documents: []
});

export const recent_documents = (state = getInitialRecentDocumentsState(), action) => {
  switch (action.type) {
    case REQUEST_SIMPLE_DOCUMENTS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_SIMPLE_DOCUMENTS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        recent_documents: action.recent_documents
      };
    default:
      return state;
  }
};

export const getInitialCurrentDocumentState = () => ({
  isFetching: false,
  isReady: false,
  doc: {}
});

export const current_document = (state = getInitialCurrentDocumentState(), action) => {
  switch (action.type) {
    case REQUEST_DOCUMENT:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_DOCUMENT:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        doc: action.payload
      };
    default:
      return state;
  }
};

export const getInitialDocumentsFullState = () => ({
  isFetching: false,
  isReady: false,
  ids: {}
});

export const documents_full = (state = getInitialDocumentsFullState(), action) => {
  switch (action.type) {
    case REQUEST_FULL_DOCUMENTS: {
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    }
    case RECEIVE_FULL_DOCUMENTS: {
      const new_ids = {};
      for (const document of action.documents) {
        new_ids[document.id] = document;
      }
      const new_state = {
        ...state,
        ids: {
          ...state.ids,
          ...new_ids
        }
      };

      if (!action.no_ready_update) {
        new_state.isFetching = false;
        new_state.isReady = true;
      }

      return new_state;
    }
    default: {
      return state;
    }
  }
};

export const getInitialAllDocumentsState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  alldocuments: []
});

export const getInitialIncompleteDocumentsState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  items: []
});

export const getInitialDocumentsState = () => ({
  isFetching: false,
  isReady: false,
  count: 0,
  combined_list: [],
  document_index: {},
  offset: 0,
  newest_dates: {},
  oldest_dates: {},
  offsets: {},
  documentsToDiff: [],
  error: null,
  [WIDGET_COMPONENT_NAMES.CCPA]: {},
  [WIDGET_COMPONENT_NAMES.COVID]: {},
  [WIDGET_COMPONENT_NAMES.REGULATION]: {},
  [WIDGET_COMPONENT_NAMES.ALERT]: {}
});

export const CCPA_WIDGET_DOCUMENTS_INITIAL_STATE = {
  isReady: false,
  documents: [],
  documentsGroupedByCategories: {},
  count: 0
};

export const COVID_WIDGET_DOCUMENTS_INITIAL_STATE = {
  isReady: false,
  documents: [],
  documentsGroupedByCategories: {},
  count: 0
};

export const REGULATION_WIDGET_DOCUMENTS_INITIAL_STATE = {
  isReady: false,
  documents: [],
  documentsGroupedByCategories: {},
  count: 0
};

export const ALERT_WIDGET_DOCUMENTS_INITIAL_STATE = {
  isReady: false,
  documents: [],
  documentsGroupedByCategories: {},
  count: 0
};

export const documents = (state = getInitialDocumentsState(), action) => {
  switch (action.type) {
    case REQUEST_DOCUMENTS_FOR_DASH_COVID: {
      const widgetDocumentsData =
        state[WIDGET_COMPONENT_NAMES.COVID][action.widgetId] ||
        COVID_WIDGET_DOCUMENTS_INITIAL_STATE;

      return {
        ...state,
        [WIDGET_COMPONENT_NAMES.COVID]: {
          ...state[WIDGET_COMPONENT_NAMES.COVID],
          [action.widgetId]: {
            ...widgetDocumentsData,
            isReady: false
          }
        }
      };
    }

    case RECEIVE_DOCUMENTS_FOR_DASH_COVID: {
      const documents = action.data[DEFAULT_DOCUMENT_SORT_KEY].documents;
      const documentsGroupedByCategories = lodash.groupBy(documents, 'cai_category_id');

      return {
        ...state,
        [WIDGET_COMPONENT_NAMES.COVID]: {
          ...state[WIDGET_COMPONENT_NAMES.COVID],
          [action.widgetId]: {
            count: action.data[DEFAULT_DOCUMENT_SORT_KEY].count,
            documents: action.data[DEFAULT_DOCUMENT_SORT_KEY].documents,
            documentsGroupedByCategories: documentsGroupedByCategories,
            isReady: true
          }
        }
      };
    }

    case REQUEST_DOCUMENTS_FOR_DASH_CCPA: {
      const widgetDocumentsData =
        state[WIDGET_COMPONENT_NAMES.CCPA][action.widgetId] || CCPA_WIDGET_DOCUMENTS_INITIAL_STATE;

      return {
        ...state,
        [WIDGET_COMPONENT_NAMES.CCPA]: {
          ...state[WIDGET_COMPONENT_NAMES.CCPA],
          [action.widgetId]: {
            ...widgetDocumentsData,
            isReady: false
          }
        }
      };
    }

    case RECEIVE_DOCUMENTS_FOR_DASH_CCPA: {
      const documents = action.data[DEFAULT_DOCUMENT_SORT_KEY].documents;
      const documentsGroupedByCategories = lodash.groupBy(documents, 'cai_category_id');

      return {
        ...state,
        [WIDGET_COMPONENT_NAMES.CCPA]: {
          ...state[WIDGET_COMPONENT_NAMES.CCPA],
          [action.widgetId]: {
            count: action.data[DEFAULT_DOCUMENT_SORT_KEY].count,
            documents: action.data[DEFAULT_DOCUMENT_SORT_KEY].documents,
            documentsGroupedByCategories: documentsGroupedByCategories,
            isReady: true
          }
        }
      };
    }

    case REQUEST_DOCUMENTS_FOR_DASH_REGULATION: {
      const widgetDocumentsData =
        state[WIDGET_COMPONENT_NAMES.REGULATION][action.widgetId] ||
        REGULATION_WIDGET_DOCUMENTS_INITIAL_STATE;

      return {
        ...state,
        [WIDGET_COMPONENT_NAMES.REGULATION]: {
          ...state[WIDGET_COMPONENT_NAMES.REGULATION],
          [action.widgetId]: {
            ...widgetDocumentsData,
            isReady: false
          }
        }
      };
    }

    case RECEIVE_DOCUMENTS_FOR_DASH_REGULATION: {
      const documents = action.data[DEFAULT_DOCUMENT_SORT_KEY].documents;
      const documentsGroupedByCategories = lodash.groupBy(documents, 'cai_category_id');

      return {
        ...state,
        [WIDGET_COMPONENT_NAMES.REGULATION]: {
          ...state[WIDGET_COMPONENT_NAMES.REGULATION],
          [action.widgetId]: {
            count: action.data[DEFAULT_DOCUMENT_SORT_KEY].count,
            documents: action.data[DEFAULT_DOCUMENT_SORT_KEY].documents,
            documentsGroupedByCategories: documentsGroupedByCategories,
            isReady: true
          }
        }
      };
    }

    case REQUEST_DOCUMENTS_FOR_DASH_ALERT_WIDGET: {
      const widgetDocumentsData =
        state[WIDGET_COMPONENT_NAMES.ALERT][action.widgetId] ||
        REGULATION_WIDGET_DOCUMENTS_INITIAL_STATE;

      return {
        ...state,
        [WIDGET_COMPONENT_NAMES.ALERT]: {
          ...state[WIDGET_COMPONENT_NAMES.ALERT],
          [action.widgetId]: {
            ...widgetDocumentsData,
            isReady: false
          }
        }
      };
    }

    case RECEIVE_DOCUMENTS_FOR_DASH_ALERT_WIDGET: {
      const documents = action.data[DEFAULT_DOCUMENT_SORT_KEY].documents;
      const documentsGroupedByCategories = lodash.groupBy(documents, 'cai_category_id');

      return {
        ...state,
        [WIDGET_COMPONENT_NAMES.ALERT]: {
          ...state[WIDGET_COMPONENT_NAMES.ALERT],
          [action.widgetId]: {
            count: action.data[DEFAULT_DOCUMENT_SORT_KEY].count,
            documents: action.data[DEFAULT_DOCUMENT_SORT_KEY].documents,
            documentsGroupedByCategories: documentsGroupedByCategories,
            isReady: true
          }
        }
      };
    }

    case REQUEST_DOCUMENTS:
    case REQUEST_DOCUMENTS_AFTER:
    case REQUEST_DOCUMENTS_BEFORE: {
      return {
        ...state,
        offset: lodash.get(action, 'params.offset', 0),
        isFetching: true,
        isReady: false,
        error: null
      };
    }
    case RECEIVE_DOCUMENTS: {
      return process_combined_documents(true, state, action);
    }
    case FAIL_DOCUMENTS: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    }
    case RECEIVE_DOCUMENTS_BEFORE:
    case RECEIVE_DOCUMENTS_AFTER: {
      return process_combined_documents(false, state, action);
    }
    case CLEAR_DOCUMENTS: {
      return getInitialDocumentsState();
    }
    case MARK_DOCUMENT_BOOKMARKED: {
      return {
        ...state,
        combined_list: state.combined_list.map(doc => {
          if (lodash.includes(action.ids, doc.id)) {
            doc.bookmarked = action.bookmarked_status;
          }
          return doc;
        })
      };
    }
    case ADD_DOCS_TO_DIFF: {
      return {
        ...state,
        documentsToDiff: action.documentsToDiff
      };
    }

    default: {
      return state;
    }
  }
};

export const getInitialSearchResultsRelevanceState = () => ({
  isFetching: false,
  isReady: false,
  results: {},
  error: null
});

// store for relevance-based search results
export const search_results_relevance = (
  state = getInitialSearchResultsRelevanceState(),
  action
) => {
  switch (action.type) {
    case REQUEST_SEARCH_RESULTS_RELEVANCE: {
      return {
        ...state,
        isFetching: true,
        isReady: false,
        error: null
      };
    }
    case RECEIVE_SEARCH_RESULTS_RELEVANCE: {
      return {
        ...state,
        isFetching: false,
        isReady: true,
        results: action.data,
        error: null
      };
    }
    case FAIL_SEARCH_RESULTS_RELEVANCE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    }
    case MARK_DOCUMENT_BOOKMARKED: {
      const new_state = lodash.cloneDeep(state); // XXX more targeted
      const my_docs = lodash.filter(new_state.results.documents, doc => {
        return lodash.includes(action.ids, doc.id);
      });
      for (const doc of my_docs) {
        doc.bookmarked = action.bookmarked_status;
      }
      return new_state;
    }
    default: {
      return state;
    }
  }
};

export const getInitialUSStateState = () => ({
  isFetching: false,
  isReady: false,
  codes: {}
});

export const us_state = (state = getInitialUSStateState(), action) => {
  switch (action.type) {
    case REQUEST_RESOURCE_CODE: {
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    }
    case RECEIVE_RESOURCE_CODE: {
      const state_code_ids = {};
      for (const branch of action.branches) {
        state_code_ids[branch.id] = branch;
      }

      const new_state = {
        ...state,
        isFetching: false,
        isReady: true,
        codes: {
          ...state.codes,
          ...state_code_ids
        }
      };

      return new_state;
    }

    default:
      return state;
  }
};

export const getInitialRecentActivityState = () => ({
  isFetching: false,
  isReady: false,
  document_stats: [],
  total_updates: null
});

export const recent_activity = (state = getInitialRecentActivityState(), action) => {
  switch (action.type) {
    case REQUEST_RECENT_ACTIVITY: {
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    }
    case RECEIVE_RECENT_ACTIVITY: {
      return {
        ...state,
        isFetching: false,
        isReady: true,
        document_stats: action.document_stats,
        total_updates: action.total_updates
      };
    }
    default: {
      return state;
    }
  }
};
