import type { WidgetHeaderProps } from './WidgetHeader.types';
import type { ComponentProps } from 'react';

import { DRAGGABLE_WIDGET_CLASSNAME } from 'constants/DashboardConstants';
import { WidgetHeaderOptionsMenu } from './elements';
import './_widget-header.scss';

export const WidgetHeader = ({
  children,
  options,
  onOptionSelect,
  timeframe,
  actionButtons
}: WidgetHeaderProps) => {
  return (
    <div className={`widget-header__container ${DRAGGABLE_WIDGET_CLASSNAME}`}>
      <div className="widget-header__title-container">
        <div className="widget-header__title-wrapper">
          <div className="widget-header__title">{children}</div>
        </div>
        {!!options.length && (
          <WidgetHeaderOptionsMenu
            options={options as ComponentProps<typeof WidgetHeaderOptionsMenu>['options']}
            onOptionSelect={onOptionSelect}
          />
        )}
        {timeframe && (
          <div className="widget-header__timeframe">
            <span className="widget-header__dash">&mdash;</span>
            {timeframe}
          </div>
        )}
      </div>
      {actionButtons && (
        <div className="widget-header__action-buttons-wrapper">{actionButtons}</div>
      )}
    </div>
  );
};

WidgetHeader.defaultProps = {
  options: [],
  onOptionSelect: () => {},
  timeframe: undefined,
  actionButtons: undefined
} as WidgetHeaderProps;
