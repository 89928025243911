import type {
  DetailedWorkflowFromResponse,
  DocumentTaskFromResponse,
  DocumentTasksResponse,
  AllWorkflowsFromResponse
} from './workflow.types';
import type * as apiTypes from './workflow.api.types';

import { fetch } from 'fetch';

export function api_addDocumentToWorkflow({
  workflowId,
  docTasks
}: {
  workflowId: number;
  docTasks: DocumentTaskFromResponse[];
}) {
  return fetch({
    url: `/v2/workflows/${workflowId}/tasks`,
    method: 'POST',
    dataType: 'Workflow',
    params: { docTasks }
  });
}

export function api_bulkDeleteDocumentTasks(ids: number[]) {
  return fetch({
    url: '/document_tasks/bulk_delete',
    dataType: 'DocumentTasks',
    method: 'POST',
    params: {
      ids
    }
  });
}

export const api_fetchTasks: apiTypes.FetchTasks = params => {
  return fetch({
    url: `/tasks`,
    dataType: 'Tasks',
    method: 'GET',
    params
  });
};

export function api_fetchDocumentTasks(
  params?: Record<string, unknown>,
  config: Record<string, unknown> = {}
): Promise<DocumentTasksResponse> {
  return fetch({
    url: `/document_tasks`,
    dataType: 'Document Tasks',
    method: 'GET',
    params,
    ...config
  });
}

export const addTaskAttachment: apiTypes.AddTaskAttachment = (taskId, files) => {
  const formData = new FormData();

  files.forEach((file, i) => {
    formData.append('file' + (i + 1).toString(), file);
  });

  return fetch({
    url: `/document_tasks/${taskId}/attachments`,
    dataType: 'Attachment',
    method: 'POST',
    params: formData
  });
};

export const deleteTaskAttachment: apiTypes.DeleteTaskAttachment = fileName => {
  return fetch({
    url: `/document_task_attachment/${fileName}`,
    dataType: 'Attachment',
    method: 'DELETE'
  });
};

export function api_fetchWorkflows(rnToken?: string): Promise<AllWorkflowsFromResponse> {
  return fetch({
    url: `/workflows`,
    dataType: 'Workflows',
    method: 'GET',
    customToken: rnToken
  });
}

export function api_updateWorkflow(
  workflowId: number,
  params: Record<string, unknown>,
  rnToken?: string
): Promise<{ workflow: DetailedWorkflowFromResponse }> {
  return fetch({
    url: `/workflows/${workflowId}`,
    method: 'POST',
    dataType: 'Workflows',
    params,
    customToken: rnToken
  });
}

export function updateWorkflowV2(
  workflowId: number,
  params: Record<string, unknown>
): Promise<DetailedWorkflowFromResponse> {
  return fetch({
    url: `/v2/workflows/${workflowId}`,
    method: 'PATCH',
    dataType: 'Workflows',
    params
  });
}

export function api_deleteWorkflow(workflowId: number) {
  return fetch({
    url: `/workflows/${workflowId}`,
    dataType: 'Workflows',
    method: 'DELETE'
  });
}

export function api_addWorkflow(
  params: Record<string, unknown>,
  rnToken?: string
): Promise<{ workflow: DetailedWorkflowFromResponse }> {
  return fetch({
    url: `/workflows`,
    dataType: 'Workflows',
    method: 'POST',
    params,
    customToken: rnToken
  });
}

export const api_editDocumentTask: apiTypes.EditDocumentTask = (params, token) => {
  return fetch({
    url: `/document_tasks/${params.task_id}`,
    dataType: 'DocumentTasks',
    method: 'POST',
    params,
    customToken: token
  });
};

export const api_documentTaskBulkUpdate: apiTypes.DocumentTaskBulkUpdate = params => {
  return fetch({
    url: '/document_tasks/bulk_update',
    dataType: 'DocumentTasks',
    method: 'POST',
    params
  });
};

export const api_documentTasksBulkUpdateV2: apiTypes.DocumentTasksBulkUpdateV2 = params => {
  return fetch({
    url: `/document_tasks/v2/bulk_update`,
    dataType: 'DocumentTasks',
    method: 'POST',
    params
  });
};

export const api_documentTaskBulkDelete = (params: Record<string, unknown>) => {
  return fetch({
    url: '/document_tasks/bulk_delete',
    dataType: 'DocumentTasks',
    method: 'POST',
    params
  });
};

export const api_fetchDocumentsByIds = (docIds: number[]) => {
  let docIdParams = '';
  for (const [index, id] of docIds.entries()) {
    if (index === 0) {
      docIdParams += `?doc_ids=${id}`;
    } else {
      docIdParams += `&doc_ids=${id}`;
    }
  }
  return fetch({
    url: `/documents${docIdParams}&skip_workflow_fields=true`,
    method: 'GET'
  });
};

export const fetchWorkflows: apiTypes.FetchWorkflows = params => {
  return fetch({
    url: `/v2/workflows/`,
    method: 'GET',
    params
  });
};

export const fetchWorkflow = (workflowId: number): Promise<DetailedWorkflowFromResponse> => {
  return fetch({
    url: `/workflows/${workflowId}`,
    method: 'GET'
  });
};

export const deleteWorkflow = api_deleteWorkflow;

/**
 * TODO Remove this endpoint when GET /v2/workflows/ will support filtering by sentence_note_id and doc_id
 */
export const fetchWorkflowsByDocIDs: apiTypes.FetchWorkflowsByDocIDs = async (
  documentId?,
  workflowId?,
  sentenceNoteId?
) => {
  const { results } = await api_fetchDocumentTasks({
    doc_id: documentId,
    workflow_id: workflowId,
    sentence_note_id: sentenceNoteId
  });

  const workflowsToFetch = [...new Set(results.map(({ workflow_id }) => workflow_id))];
  return Promise.all(workflowsToFetch.map(workflowId => fetchWorkflow(workflowId)));
};

export const updateTaskDropdown: apiTypes.UpdateTaskDropdown = (dropdownId, params) => {
  return fetch({
    url: `/dropdown/${dropdownId}`,
    method: 'POST',
    dataType: 'Annotations',
    params
  });
};

export const fetchDocumentWorkflowTask: apiTypes.FetchDocumentWorkflowTasks = params => {
  return fetch({
    url: `/document_tasks_workflows`,
    method: 'GET',
    dataType: 'DocumentTask',
    params
  });
};
