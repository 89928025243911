import type { ApplicabilityDateData } from './useApplicabilityDateData.types';
import type { ApplicabilityDateProps } from '../ApplicabilityDate.types';

import { useMemo } from 'react';
import { getApplicabilityDate } from './useApplicabilityDateData.helpers';

export const useApplicabilityDateData = ({
  document
}: Pick<ApplicabilityDateProps, 'document'>): ApplicabilityDateData => {
  const formattedData: ApplicabilityDateData['formattedData'] = useMemo(() => {
    const applicabilityDate = getApplicabilityDate({
      document: document
    });

    return {
      applicabilityDate
    };
  }, [document]);

  return {
    formattedData
  };
};
