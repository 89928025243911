import type { CitationProps } from '../Citation.types';
import type { GlobalState } from 'shared/reducers';
import type { CitationData } from './useCitationData.types';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  getDocumentCategoryName,
  getUSStateReducer
} from 'shared/features/documents/documents.selectors';

export const useCitationData = ({
  document,
  path
}: Pick<CitationProps, 'document' | 'path'>): CitationData => {
  const reduxState = useSelector<GlobalState, CitationData['reduxState']>(state => ({
    // TODO Uncomment when document selectors will be moved to TS
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    categoryName: getDocumentCategoryName(state, document.cai_category_id),
    USStateReducer: getUSStateReducer(state)
  }));

  const formattedData: CitationData['formattedData'] = useMemo(() => {
    const officialId = path?.length ? document.official_id : null;

    return {
      officialId
    };
  }, [document.official_id, path?.length]);

  return {
    reduxState,
    formattedData
  };
};
