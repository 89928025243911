import * as sortUtils from 'utils/sort';
import * as userSelectors from '../user/user.selectors';
import * as workflowConstants from 'constants/Workflows';
import * as helpers from './organizations.helpers';

import { createSelector } from '@reduxjs/toolkit';
import lodash from 'lodash';

export const getOrganizationReducer = state => state.organization;

export const getOrganization = state => state.organization.organization;

export const getOrganizationReadyState = createSelector(getOrganizationReducer, organization => {
  return organization.isReady;
});

export const getOrganizationUpdatingState = createSelector(getOrganizationReducer, organization => {
  return organization.isUpdating;
});

export const getOrganizationTeams = createSelector(getOrganization, org =>
  org.teams
    ? org.teams.map(team => ({
        ...team,
        team_members: team.team_members.sort(sortUtils.sortUsersByEmail)
      }))
    : []
);
export const getOrganizationTeamsByIds = teamIds =>
  createSelector(getOrganizationTeams, teams => {
    return teams.filter(team => teamIds.includes(team.id));
  });
export const getOrganizationTeamMembersIdsByTeamIds = teamIds =>
  createSelector(getOrganizationTeamsByIds(teamIds), teams => {
    return [
      ...new Set(
        teams.flatMap(
          organizationTeams =>
            organizationTeams.team_members?.map(teamMember => teamMember.id) || []
        )
      )
    ];
  });

export const getOrganizationTeamsOptions = createSelector(getOrganizationTeams, teams => {
  return teams
    .map(team => ({
      value: team.id,
      label: team.name
    }))
    .sort(sortUtils.alphabeticallyByLabel);
});
export const getOrganizationTeamById = teamId =>
  createSelector(getOrganizationTeams, orgTeams => {
    return orgTeams.find(orgTeam => orgTeam.id === teamId) ?? null;
  });

export const getOrganizationMembers = state =>
  getOrganization(state).organization_members?.sort(sortUtils.sortUsersByEmail) || [];

export const getOrganizationMembersAsSelectOptions = createSelector(
  getOrganizationMembers,
  orgMembers => orgMembers.map(helpers.formatOrgMemberOption)
);

export const getOrganizationMembersByIds = orgMembersIds =>
  createSelector(getOrganizationMembers, orgMembers => {
    return orgMembers.filter(({ id }) => orgMembersIds.includes(id));
  });
export const getOrganizationMembersObj = createSelector(getOrganizationMembers, orgMembers => {
  return orgMembers.reduce((orgMembers, orgMember) => {
    return {
      ...orgMembers,
      [orgMember.id]: orgMember
    };
  }, {});
});
export const getOrganizationMembersOptionsByIds = orgMembersIds =>
  createSelector(getOrganizationMembersByIds(orgMembersIds), orgMembers => {
    return orgMembers.map(helpers.formatOrgMemberOption);
  });

export const getOrganizationMemberEmailByUserId = userId =>
  createSelector(getOrganizationMembers, orgMembers => {
    return lodash.get(lodash.find(orgMembers, { id: userId }), 'email');
  });

export const getActiveOrganizationMembers = createSelector(getOrganizationMembers, orgMembers => {
  return orgMembers.filter(({ active, disabled }) => active && !disabled);
});

export const getActiveOrganizationMembersIds = createSelector(
  getActiveOrganizationMembers,
  activeOrgMembers => {
    return activeOrgMembers.map(orgMember => orgMember.id);
  }
);

export const getActiveOrganizationMembersById = orgMembersIds =>
  createSelector(getActiveOrganizationMembers, activeOrgMembers => {
    return activeOrgMembers.filter(({ id }) => orgMembersIds.includes(id));
  });

export const getAvailableActiveOrganizationMembersByTeamId = teamId =>
  createSelector(
    [getActiveOrganizationMembers, getOrganizationTeamById(teamId)],
    (activeOrgMembers, team) => {
      return activeOrgMembers.filter(
        activeOrgMember =>
          !team?.team_members.some(teamMember => teamMember.id === activeOrgMember.id)
      );
    }
  );

export const areAllUsersActive = createSelector(
  [
    (state, userIds) => getActiveOrganizationMembersById(userIds)(state),
    (state, userIds) => userIds
  ],
  (activeOrgMembers, userIds) => {
    return activeOrgMembers.length === userIds.length;
  }
);

export const getActiveOrganizationMembersOptionsById = orgMembersIds =>
  createSelector(getActiveOrganizationMembersById(orgMembersIds), activeOrgMembers => {
    return activeOrgMembers.map(helpers.formatOrgMemberOption);
  });

export const getActiveOrganizationMemberOptionLabel = createSelector(
  [
    (state, userId) => getActiveOrganizationMembersOptionsById([userId])(state)[0],
    getOrganizationReadyState
  ],
  (orgMemberOption, isOrgReady) => {
    if (!isOrgReady) {
      return 'Loading...';
    }

    if (orgMemberOption?.label) {
      return orgMemberOption?.label;
    }

    return '(Expired user)';
  }
);

export const getActiveOrganizationMembersOptions = createSelector(
  getActiveOrganizationMembers,
  activeOrgMembers => {
    return activeOrgMembers.map(helpers.formatOrgMemberOption);
  }
);

export const getOrganizationId = state => getOrganization(state).id;
export const getOrganizationName = state => getOrganization(state).name;
export const getOrganizationPublicFolderRestriction = state =>
  getOrganization(state).public_folder_disabled;
export const getOrganizationAutomatedLabelingOfAlerts = state =>
  getOrganization(state).automated_labeling_of_alerts_enabled;
export const getOrganizationRoles = state => getOrganization(state).roles ?? [];
export const getOrganizationRolesOptions = createSelector(getOrganizationRoles, roles => {
  return roles.map(role => ({ value: role.id, label: role.name }));
});
export const getOrganizationInvitations = createSelector(getOrganization, organization => {
  return organization?.org_invitations ?? [];
});
export const getOrganizationPendingInvitations = createSelector(
  getOrganizationInvitations,
  invitations => {
    return invitations.filter(invitation => invitation.invitation_status === 'pending');
  }
);

export const getOrganizationLabels = createSelector(getOrganization, organization => {
  return Array.isArray(organization?.labels) ? organization.labels : [];
});
export const getOrganizationLabelsOptions = createSelector(
  getOrganizationLabels,
  organizationLabels => {
    return organizationLabels
      .map(helpers.formatOrgLabelOption)
      .sort(sortUtils.alphabeticallyByLabel);
  }
);

export const getOrganizationLabelsIdsToNamesMap = createSelector(getOrganizationLabels, labels => {
  return labels.reduce((labels, label) => {
    return {
      ...labels,
      [label.value]: label.label
    };
  }, {});
});

export const getAvailableOrganizationLabels = activeLabels =>
  createSelector(getOrganizationLabels, organizationLabels => {
    return organizationLabels.filter(orgLabel => {
      return !activeLabels.some(activeLabel => {
        return activeLabel.value === orgLabel.value;
      });
    });
  });

export const getUsersForSharingAlert = savedSearch =>
  createSelector(
    userSelectors.getUserId,
    getActiveOrganizationMembers,
    (currentUserId, orgMembers) => {
      return orgMembers.filter(
        member =>
          member.id !== currentUserId &&
          member.id !== savedSearch.user_id &&
          !savedSearch.share_with_users.find(user => user.user_id === member.id)
      );
    }
  );

export const getUsersOptionsForSharingAlert = savedSearch =>
  createSelector(getUsersForSharingAlert(savedSearch), usersToShareAlert => {
    return lodash.orderBy(
      usersToShareAlert.map(member => ({ value: member.id, label: member.email })),
      'label'
    );
  });

export const getUsersWithSharedAlert = savedSearch =>
  createSelector(
    userSelectors.getUserId,
    getOrganizationMembers,
    (currentUserId, organizationMembers) => {
      const sharedUsers = [...savedSearch.share_with_users];

      const owner = organizationMembers.find(member => member.id === savedSearch.user_id);

      if (owner) {
        sharedUsers.push({ user_id: owner.id, email: owner.email });
      }

      return lodash.orderBy(
        sharedUsers.filter(user => user.user_id !== currentUserId),
        'label'
      );
    }
  );

export const getUsersEmailsWithSharedAlert = savedSearch =>
  createSelector(getUsersWithSharedAlert(savedSearch), usersWithSharedAlert => {
    return usersWithSharedAlert.map(member => member.email).sort();
  });

export const getUsersOptionsWithSharedAlert = savedSearch =>
  createSelector(getUsersWithSharedAlert(savedSearch), usersWithSharedAlert => {
    return lodash.orderBy(
      usersWithSharedAlert.map(member => ({ value: member.id, label: member.email })),
      'label'
    );
  });

export const getOrgProperties = createSelector(getOrganization, organization => {
  return organization?.properties ?? {};
});

export const getOrgWorkflowNotifications = createSelector(getOrgProperties, orgProperties => {
  return (
    orgProperties?.workflow_emails ?? {
      [workflowConstants.TASK_NOTIFICATION_KEYS.TASK_CREATED]: false,
      [workflowConstants.TASK_NOTIFICATION_KEYS.TASK_NEAR_DUE]: false,
      [workflowConstants.TASK_NOTIFICATION_KEYS.TASK_OVERDUE]: false,
      [workflowConstants.TASK_NOTIFICATION_KEYS.TASK_DONE]: false
    }
  );
});

export const getOrgMemberSKUs = userId =>
  createSelector(getOrganizationMembers, orgMembers => {
    return orgMembers.find(orgMember => orgMember.id === userId)?.skus ?? [];
  });

export const getTeamsByUserId = userId =>
  createSelector(getOrganizationTeams, teams => {
    return teams.filter(team => {
      return team.team_members.find(teamMember => teamMember.id === userId);
    });
  });

export const getTeamsIdsByUserId = userId =>
  createSelector(getTeamsByUserId(userId), teams => {
    return teams.map(team => team.id);
  });

export const canUserViewDocument = ({ document, userId }) =>
  createSelector(getTeamsIdsByUserId(userId), teams => {
    if (!document.permissions) {
      return true;
    }

    if (document.permissions.bucket_id || document.permissions.bucket_id === null) {
      return true;
    }

    if (document.owner_id === userId) {
      return true;
    }

    if (
      document.permissions?.user_read_access?.includes?.(userId) ||
      document.permissions?.user_read_access?.includes?.(-1) ||
      document.permissions?.user_write_access?.includes?.(userId) ||
      document.permissions?.user_write_access?.includes?.(-1)
    ) {
      return true;
    }

    if (
      teams.some(
        teamId =>
          document.permissions?.group_read_access?.includes?.(teamId) ||
          document.permissions?.group_read_access?.includes?.(-1) ||
          document.permissions?.group_write_access?.includes?.(teamId) ||
          document.permissions?.group_write_access?.includes?.(-1)
      )
    ) {
      return true;
    }

    return false;
  });

export const canUserEditDocument = ({ document, userId }) =>
  createSelector(getTeamsIdsByUserId(userId), teams => {
    if (!userId) {
      return false;
    }

    if (document.owner_id === userId) {
      return true;
    }

    if (!document.permissions) {
      return false;
    }

    if (document.permissions.bucket_id === null) {
      return true;
    }

    if (
      document.permissions?.user_write_access?.includes?.(userId) ||
      document.permissions?.user_write_access?.includes?.(-1)
    ) {
      return true;
    }

    if (
      teams.some(teamId => document.permissions?.group_write_access?.includes?.(teamId)) ||
      document.permissions?.group_write_access?.includes?.(-1)
    ) {
      return true;
    }

    return false;
  });

export const getActiveOrganizationMembersOptionsByIds = createSelector(
  getActiveOrganizationMembersOptions,
  (state, orgMemberIds) => orgMemberIds,
  (orgMemberOptions, orgMemberIds) => {
    return orgMemberOptions.filter(option => orgMemberIds.includes(option.value));
  }
);

export const getOrganizationFetchingState = createSelector(getOrganizationReducer, organization => {
  return organization.isFetching;
});
