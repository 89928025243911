import type { ThunkDispatch } from '@reduxjs/toolkit';
import type { Action } from 'redux';

import * as actions from '../authors.actions';

import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';

export const useAuthorsReduxActions = () => {
  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, Action<unknown>>>();

  const setAuthors = useCallback(
    (...args: Parameters<typeof actions.setAuthors>) => {
      dispatch(actions.setAuthors(...args));
    },
    [dispatch]
  );

  const fetchAuthors = useCallback(
    async (...args: Parameters<typeof actions.fetchAuthors>) => {
      return await dispatch(actions.fetchAuthors(...args));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      setAuthors,
      fetchAuthors
    }),
    [setAuthors, fetchAuthors]
  );
};
