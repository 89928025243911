import type { FC } from 'react';
import type { AnnotationsPanelProps } from './AnnotationsPanel.types';

import * as common from 'common/index';
import * as sentenceNotesConstants from 'shared/features/sentenceNotes/sentenceNotes.constants';

export const AnnotationsPanel: FC<AnnotationsPanelProps> = ({ document }) => {
  return (
    <common.AnnotationPanel
      type={sentenceNotesConstants.SENTENCE_NOTE_TYPE.ANNOTATIONS}
      document={document}
    />
  );
};
