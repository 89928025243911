import { SUPPORTED_QUERY_PARAMS } from 'constants/PrimaryFilter';
import { TABLE_PARAMS } from '@compliance.ai/web-components';

export enum TABLE_KEYS {
  ID = 'id',
  NAME = 'name',
  UPDATED_AT = 'updated_at',
  USER_ID = 'user_id'
}

export const DEFAULT_ROWS_COUNT = 20;
export const DEFAULT_OFFSET = 0;

export const DEFAULT_TABLE_PARAMS = {
  [TABLE_PARAMS.LIMIT]: DEFAULT_ROWS_COUNT,
  [TABLE_PARAMS.OFFSET]: DEFAULT_OFFSET
};

export const TABLE_COLUMN_DATE_FIELD_FORMAT = 'MMM DD, YYYY';
export const DEFAULT_EMPTY_COLUMN = '-';

export const SELECTED_ALERTS_MAX_COUNT = 600;

export const DEFAULT_SORT_QUERY_PARAMS = {
  [SUPPORTED_QUERY_PARAMS.SORT]: '-' + TABLE_KEYS.UPDATED_AT
};
