import type { TaskStatusProps } from './TaskStatus.types';

import { Checkbox } from '@compliance.ai/web-components';
import { TASK_STATUS_DONE } from 'constants/TaskStatuses';
import { useTaskStatusData, useTaskStatusHandlers } from './hooks';

export const TaskStatus = ({
  documentTask,
  value,
  areAllAnnotationsCompleted,
  onChange
}: TaskStatusProps) => {
  const { formattedData } = useTaskStatusData({ documentTask, areAllAnnotationsCompleted });

  const handlers = useTaskStatusHandlers({
    props: {
      documentTask,
      value,
      onChange
    }
  });

  return (
    <Checkbox
      isChecked={value === TASK_STATUS_DONE}
      onChange={handlers.handleTaskStatusChange}
      isDisabled={formattedData.isDisabled}
    />
  );
};
