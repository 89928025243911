import * as uiLib from '@compliance.ai/web-components';
import * as errorUtils from 'utils/errors';

import { api_fetchAutoComplete } from 'shared/features/search/search.api';

import { useState } from 'react';

export const AsyncSelect = ({ setValue, category, listValues }) => {
  const [selection, setSelection] = useState(listValues);

  const handleOptionsLoad = async inputValue => {
    try {
      if (!inputValue || inputValue.length < 3) {
        return [];
      }

      const response = await api_fetchAutoComplete(inputValue, { category });

      return response.results.map(category => ({
        label: category.name,
        value: String(category.id)
      }));
    } catch (e) {
      errorUtils.logError(e);

      return [];
    }
  };

  const handleSelectionChange = _selection => {
    setSelection(_selection);
    setValue(_selection);
  };

  return (
    <uiLib.Select
      type={uiLib.SELECT_TYPES.DEFAULT}
      className="advanced-search-builder-select"
      value={selection}
      isClearable
      isMulti
      isAsync
      hideSelectedOptions
      onChange={handleSelectionChange}
      loadOptions={handleOptionsLoad}
    />
  );
};
