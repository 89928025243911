import type { FC } from 'react';
import type { DeleteBucketModalProps } from './DeleteBucketModal.types';

import * as uiLib from '@compliance.ai/web-components';

import pluralize from 'pluralize';

import { useDeleteBucketModalMutations } from './hooks';

import './_delete-bucket-modal.scss';

export const DeleteBucketModal: FC<DeleteBucketModalProps> = ({
  bucket,
  onModalClose,
  onModalActionStart,
  onModalActionSuccess
}) => {
  const mutations = useDeleteBucketModalMutations({
    props: {
      bucket,
      onModalClose,
      onModalActionStart,
      onModalActionSuccess
    }
  });

  if (!bucket) {
    return null;
  }

  return (
    <uiLib.Modal
      title="Delete collection"
      isOpen
      onClose={onModalClose}
      withFooter
      primaryButtonType={uiLib.BUTTON_TYPES.DELETE}
      primaryButtonText="Delete"
      secondaryButtonType={uiLib.BUTTON_TYPES.LINK}
      secondaryButtonText="Cancel"
      onSubmit={mutations.deleteBucket.mutate}
      classNames={{
        container: 'delete-bucket-modal',
        content: 'delete-bucket-modal__container',
        title: 'delete-bucket-modal__title'
      }}
    >
      <uiLib.Typography
        className="delete-bucket-modal__delete-message"
        alignment={uiLib.TYPOGRAPHY_ALIGNMENT.CENTER}
      >
        Are you sure you want to delete "{bucket.name}"?
        {!!(bucket.labelCount || bucket.annotationCount) && (
          <uiLib.Typography alignment={uiLib.TYPOGRAPHY_ALIGNMENT.CENTER} component="div">
            This collection contains&nbsp;
            <uiLib.Typography isBold component="span">
              {bucket.labelCount} {pluralize('label', bucket.labelCount)} and{' '}
              {bucket.annotationCount} {pluralize('annotation', bucket.annotationCount)}.&nbsp;
            </uiLib.Typography>
            All labels and annotations will be moved to the&nbsp;
            <uiLib.Typography isBold component="span">
              Public Collection
            </uiLib.Typography>
            &nbsp;if you continue.
          </uiLib.Typography>
        )}
      </uiLib.Typography>
    </uiLib.Modal>
  );
};
