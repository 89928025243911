import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Table, TableBody, TableContainer, TableRow, TableCell } from '@material-ui/core';

import * as uiLib from '@compliance.ai/web-components';

import './_confirmation-card.scss';

export const ConfirmationCard = ({ rows, onEditClick, subHeaders, isNew }) => {
  let currentKeyCategory = 'default';

  return (
    <TableContainer className="confirmation-card" data-testid="confirmation-card">
      <Table size="small" className="confirmation-card-root">
        <TableBody>
          {rows.map(row => {
            if (subHeaders.includes(row.field)) {
              currentKeyCategory = row.field;
            }

            return (
              <TableRow key={`${currentKeyCategory}-${row.field}-${row.data}`}>
                <TableCell
                  component="th"
                  scope="row"
                  className={subHeaders.includes(row.field) ? 'subhead-title' : 'summary-title'}
                >
                  {row.field}
                </TableCell>
                <TableCell align="left" className="summary-text">
                  {isNew ? (
                    <uiLib.TextArea value={row.data} shouldExpandOnFocus isReadOnly />
                  ) : (
                    row.data
                  )}
                </TableCell>
              </TableRow>
            );
          })}
          <uiLib.Button
            className="edit-button"
            type={uiLib.BUTTON_TYPES.LINK}
            onClick={onEditClick}
            dataTestId="edit-button"
          >
            Edit
          </uiLib.Button>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ConfirmationCard.propTypes = {
  subHeaders: PropTypes.arrayOf(PropTypes.string),
  // rows can be vary depending on table data format
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onEditClick: PropTypes.func.isRequired,
  isNew: PropTypes.bool
};

ConfirmationCard.defaultProps = {
  subHeaders: []
};
