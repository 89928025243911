import * as banksApi from './banks.api';

export const REQUEST_BANKS = 'REQUEST_BANKS';
export const RECEIVE_BANKS = 'RECEIVE_BANKS';

function requestBanks(following) {
  return {
    type: REQUEST_BANKS,
    following
  };
}

function receiveBanks(json, following) {
  return {
    type: RECEIVE_BANKS,
    banks: json.banks
  };
}

export function fetchBanks(ids) {
  return function doFetch(dispatch) {
    dispatch(requestBanks());
    return banksApi.fetchBanks(ids).then(response => {
      dispatch(receiveBanks(response));
      return response;
    });
  };
}
