import {
  SELECT_SENTENCES,
  RECEIVE_USER_OBLIGATIONS_FOR_DOC_ID,
  RECEIVE_CREATE_OR_UPDATE_USER_OBLIGATION,
  RECIEVE_OBLIGATION_HIGH_THRESHHOLD
} from './obligations.actions';

export function getInitialObligationsState() {
  return {
    firstSelectedId: null,
    lastSelectedId: null,
    startIndex: 0,
    endIndex: 0,
    userObligationsForDocIds: {},
    obligationHighThreshold: 0.9
  };
}

export const obligations = (state = getInitialObligationsState(), action) => {
  switch (action.type) {
    case SELECT_SENTENCES:
      return {
        ...state,
        firstSelectedId: action.firstSentenceId,
        lastSelectedId: action.lastSentenceId,
        startIndex: action.startIndex,
        endIndex: action.endIndex
      };

    case RECEIVE_CREATE_OR_UPDATE_USER_OBLIGATION:
      return {
        ...state,
        userObligationsForDocIds: {
          ...state.userObligationsForDocIds,
          // If user obligation exists then an update was being made and just replace it, else add
          [action.userObligation.doc_id]: [
            // Safely removes the existing user obligation from array
            ...(state.userObligationsForDocIds[action.userObligation.doc_id] || []).filter(
              existingUserObligation =>
                !(
                  existingUserObligation.doc_id === action.userObligation.doc_id &&
                  existingUserObligation.sentence_id === action.userObligation.sentence_id &&
                  existingUserObligation.user_id === action.userObligation.user_id
                )
            ),
            action.userObligation
          ]
        }
      };

    case RECEIVE_USER_OBLIGATIONS_FOR_DOC_ID:
      return {
        ...state,
        userObligationsForDocIds: {
          ...state.userObligationsForDocIds,
          [action.docId]: action.userObligations
        }
      };

    case RECIEVE_OBLIGATION_HIGH_THRESHHOLD:
      return {
        ...state,
        obligationHighThreshold: action.payload.obligation_threshold_high
      };

    default:
      return state;
  }
};
