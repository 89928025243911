import type { FC } from 'react';
import type { ApproverCellProps } from './ApproverCell.types';

import { useApproverCellData } from './hooks';

export const ApproverCell: FC<ApproverCellProps> = ({ cellValue }) => {
  const { formattedData } = useApproverCellData({
    cellValue
  });

  return <>{formattedData.approver}</>;
};
