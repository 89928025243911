import React from 'react';

import * as uiLib from '@compliance.ai/web-components';

/**
 *
 * Note: use this HOC only for legacy class components
 *
 */
export const withAppNavigation = (Component: typeof React.Component) => {
  return function WrappedComponent(props: React.Component['props']) {
    const appNavigation = uiLib.useAppNavigation();

    return <Component {...appNavigation} {...props} />;
  };
};
