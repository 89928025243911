import { TABLE_FIELDS } from '../LabelDetails.constants';
import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import { LabelDocumentLink } from '../elements';

export const renderAuthor = ({ addedBy, onClick }) => {
  const hasAuthors = Array.isArray(addedBy) && addedBy.length;

  if (!hasAuthors) {
    return 'Unknown';
  }

  const hasSingleAuthor = addedBy.length === 1;

  if (hasSingleAuthor) {
    return addedBy[0][TABLE_FIELDS.USER_EMAIL] || 'Unknown';
  }

  return (
    <Button type={BUTTON_TYPES.LINK} onClick={() => onClick(addedBy)}>
      Multiple
    </Button>
  );
};

export const renderCreationDate = ({ addedBy, addedAt, onClick }) => {
  const hasAuthors = Array.isArray(addedBy) && addedBy.length;
  const hasSingleAuthor = addedBy?.length === 1;

  if (!hasAuthors || hasSingleAuthor) {
    return addedAt?.[0] || 'Unknown';
  }

  return (
    <Button type={BUTTON_TYPES.LINK} onClick={() => onClick(addedBy)}>
      Multiple
    </Button>
  );
};

export const normalizeDocumentsMoveRequest = (labelId, selectedDocumentsIds) => {
  const doesDataExist = labelId && selectedDocumentsIds;

  if (!doesDataExist) {
    return [];
  }

  return selectedDocumentsIds.map(documentId => ({
    label_id: labelId,
    document_id: documentId
  }));
};

export const formatLabelForTable = ({ onAddedByClick }) => label => {
  return {
    [TABLE_FIELDS.DOCUMENT_ID]: label.documentId,
    [TABLE_FIELDS.DOCUMENT_TITLE]: (
      <LabelDocumentLink documentId={label.documentId}>{label.documentTitle}</LabelDocumentLink>
    ),
    [TABLE_FIELDS.LABEL_PARENT]: label.labelParent.join(', '),
    [TABLE_FIELDS.ADDED_BY]: renderAuthor({
      addedBy: label[TABLE_FIELDS.ADDED_BY],
      onClick: onAddedByClick
    }),
    [TABLE_FIELDS.ADDED_AT]: renderCreationDate({
      addedBy: label[TABLE_FIELDS.ADDED_BY],
      addedAt: label[TABLE_FIELDS.ADDED_AT],
      onClick: onAddedByClick
    })
  };
};
