import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Loader, LOADER_LEGACY_TYPES, WidgetDocumentsWrapper } from 'common';
import { getCategories } from 'shared/features/documents/documents.selectors';
import helpers from './helpers';
import './_widget-common-docs-tab.scss';

const WidgetCommonDocsTab = ({
  // Data
  documentsData,
  summarySubject,
  noDocsMessage,
  // Handlers
  onSeeAllLinkClick,
  // Flags
  isLoading,
  shouldShowSummary,
  shouldShowNoDocsLink,
  // Children render function
  children
}) => {
  const categories = useSelector(getCategories);

  if (isLoading) {
    return (
      <Loader
        legacy
        legacyType={LOADER_LEGACY_TYPES.LIGHT}
        containerClassName="widget-common-docs-tab__loader-container"
      />
    );
  }

  const docCountByCategories = helpers.countDocsByCategories(
    categories,
    documentsData.documentsGroupedByCategories
  );

  return (
    <WidgetDocumentsWrapper
      documents={documentsData.documents}
      shouldShowSummary={shouldShowSummary}
      summarySubject={summarySubject}
      summarySubjectMentionsCount={documentsData.count}
      summarySubjectMentionsCountByCategories={docCountByCategories}
      shouldShowNoDocsLink={shouldShowNoDocsLink}
      onSeeAllLinkClick={onSeeAllLinkClick}
      noDocsMessage={noDocsMessage}
    >
      {children}
    </WidgetDocumentsWrapper>
  );
};

export default WidgetCommonDocsTab;

WidgetCommonDocsTab.propTypes = {
  documentsData: PropTypes.shape({
    isReady: PropTypes.bool,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        topics: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string
          })
        ),
        category: PropTypes.string,
        agencies: PropTypes.arrayOf(
          PropTypes.shape({
            short_name: PropTypes.string
          })
        ),
        id: PropTypes.number,
        publication_date: PropTypes.string
      })
    ).isRequired,
    documentsGroupedByCategories: PropTypes.shape({})
  }).isRequired,
  summarySubject: PropTypes.string,
  onSeeAllLinkClick: PropTypes.func,
  noDocsMessage: PropTypes.element,
  children: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  shouldShowNoDocsLink: PropTypes.bool,
  shouldShowSummary: PropTypes.bool
};

WidgetCommonDocsTab.defaultProps = {
  summarySubject: '',
  isLoading: false,
  shouldShowNoDocsLink: false,
  shouldShowSummary: false,
  noDocsMessage: null,
  onSeeAllLinkClick: () => {}
};
