import * as keyDates from 'utils/keyDates';

import {
  DatePicker,
  Dropdown,
  FilterError,
  LoadingOverlay,
  UserSelect,
  SelectV2 as Select
} from 'common';
import { TextField, Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import { FILTER_KEY } from './LabelDetailsFilters.constants';

import { useLabelDetailsFiltersData, useLabelDetailsFiltersHandlers } from './hooks';

import './_label-details-filters.scss';

export const LabelDetailsFilters = ({ onSave }) => {
  const { localState, localActions, reduxState, formattedData } = useLabelDetailsFiltersData();

  const {
    handleDropdownOpen,
    handleDropdownClose,
    handleFilterChange,
    handleFiltersClear,
    handleFiltersSave
  } = useLabelDetailsFiltersHandlers({
    props: {
      onSave
    },
    localState,
    localActions
  });

  return (
    <Dropdown
      className="label-details-filters__dropdown"
      isOpen={localState.isDropdownOpen}
      onClose={handleDropdownClose}
      triggerContent={
        <Button type={BUTTON_TYPES.SECONDARY} onClick={handleDropdownOpen}>
          Filter
        </Button>
      }
      dropdownContent={
        <LoadingOverlay isLoading={reduxState.isLoading}>
          <div className="label-details-filters__container">
            <div className="label-details-filters__inputs-wrapper">
              <TextField
                value={localState.filters[FILTER_KEY.DOC_TITLE]}
                onChange={handleFilterChange(FILTER_KEY.DOC_TITLE)}
                placeholder="Doc Title"
              />
              <Select
                value={localState.filters[FILTER_KEY.ADDED_TO]}
                options={reduxState.labelParentList}
                onChange={handleFilterChange(FILTER_KEY.ADDED_TO)}
                placeholder="Added to"
                isMulti
              />
              <UserSelect
                isClearable
                selectedUser={localState.filters[FILTER_KEY.ADDED_BY]}
                onChange={handleFilterChange(FILTER_KEY.ADDED_BY)}
                users={reduxState.organizationMembers}
                placeholder="Added by"
              />
              <div className="label-details-filters__date-pickers">
                <div className="label-details-filters__date-picker">
                  <DatePicker
                    value={localState.filters[FILTER_KEY.ADDED_START_DATE]}
                    onChange={handleFilterChange(FILTER_KEY.ADDED_START_DATE)}
                    label="Added From"
                    showCalendarIcon={false}
                    placeholder={keyDates.LocalDateFormat.toLowerCase()}
                    format={keyDates.LocalDateFormat}
                  />
                </div>
                <div className="label-details-filters__date-picker">
                  <DatePicker
                    value={localState.filters[FILTER_KEY.ADDED_END_DATE]}
                    onChange={handleFilterChange(FILTER_KEY.ADDED_END_DATE)}
                    label="Added To"
                    showCalendarIcon={false}
                    placeholder={keyDates.LocalDateFormat.toLowerCase()}
                    format={keyDates.LocalDateFormat}
                  />
                </div>
              </div>
              {formattedData.isSubmitButtonDisabled && (
                <FilterError>Added from date must be earlier than added to date</FilterError>
              )}
            </div>
            <div className="label-details-filters__footer-buttons">
              <Button type={BUTTON_TYPES.LINK} onClick={handleFiltersClear}>
                Clear All
              </Button>
              <Button
                type={BUTTON_TYPES.PRIMARY}
                onClick={handleFiltersSave}
                isDisabled={formattedData.isSubmitButtonDisabled}
              >
                Apply
              </Button>
            </div>
          </div>
        </LoadingOverlay>
      }
    />
  );
};
