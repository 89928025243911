import type { FC } from 'react';
import type { NoContentProps } from './NoContent.types';

import * as images from 'shared/images';
import './_no-content.scss';

export const NoContent: FC<NoContentProps> = ({ type }) => {
  return (
    <div className="no-content">
      <img src={images.misc.annotationsHighlighter} alt="" />
      <h3>{`You don't have any ${type}s yet.`}</h3>
      <div className="no-content__bottom-text-container">
        <div>
          {`Create an ${type} by`}
          &nbsp;
        </div>
        <br />
        <div className="no-content__highlighted-word">highlighting</div>
        <br />
        <div>
          &nbsp;
          {`text on the document`}
        </div>
      </div>
    </div>
  );
};
