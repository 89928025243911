import { useCallback } from 'react';

export const useLabelDetailsModalHandlers = ({ modalActions }) => {
  const handleAuthorsModalShow = useCallback(
    addedBy => {
      modalActions.setRowAuthors(addedBy);
      modalActions.setAuthorsModalVisibility(true);
    },
    [modalActions]
  );

  const handleAuthorsModalHide = useCallback(() => {
    modalActions.setAuthorsModalVisibility(false);
  }, [modalActions]);

  const handleLabelsMoveModalShow = useCallback(() => {
    modalActions.setLabelsMoveModalVisibility(true);
  }, [modalActions]);

  const handleLabelsMoveModalHide = useCallback(() => {
    modalActions.setLabelsMoveModalVisibility(false);
  }, [modalActions]);

  const handleLabelsRemoveModalShow = useCallback(() => {
    modalActions.setLabelsRemoveModalVisibility(true);
  }, [modalActions]);

  const handleLabelsRemoveModalHide = useCallback(() => {
    modalActions.setLabelsRemoveModalVisibility(false);
  }, [modalActions]);

  return {
    handleAuthorsModalShow,
    handleAuthorsModalHide,
    handleLabelsMoveModalShow,
    handleLabelsMoveModalHide,
    handleLabelsRemoveModalShow,
    handleLabelsRemoveModalHide
  };
};
