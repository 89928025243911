import { REDUCER_NAME } from './auth.reducer';
import { createSelector } from '@reduxjs/toolkit';

/**
 *
 * Auth reducer selector
 */
export const getAuthReducer = state => state[REDUCER_NAME];

/**
 *
 * Common auth selectors
 */
export const getUserAuthenticatedState = createSelector(
  getAuthReducer,
  state => state.isAuthenticated
);
export const getAuthenticationLoadingState = createSelector(
  getAuthReducer,
  state => state.isLoading
);
export const getTokens = createSelector(getAuthReducer, state => state.tokens);
export const getJWTToken = createSelector(getTokens, tokens => tokens.JWTToken);
