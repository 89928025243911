import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getUserAuthenticatedState } from 'shared/features/auth/auth.selectors';
import { getCurrentUserSKUs } from 'shared/features/user/user.selectors';
import { useViewReduxActions } from 'shared/features/view/hooks';
import { SKU_RESTRICTION_TYPES } from 'constants/SKUs';
import { RESTRICTION_MODAL_TYPES } from 'components/RestrictModal/RestrictModal.constants';

const SKU_RESTRICTION_MODAL_TYPE_MAP = {
  [SKU_RESTRICTION_TYPES.AUTOTRANSLATE]: RESTRICTION_MODAL_TYPES.AUTOTRANSLATE,
  [SKU_RESTRICTION_TYPES.JURISDICTION_CONTENT]: RESTRICTION_MODAL_TYPES.JURISDICTION_CONTENT,
  [SKU_RESTRICTION_TYPES.ENFORCEMENT_EXPLORER]: RESTRICTION_MODAL_TYPES.ENFORCEMENT_EXPLORER
};

export const useSKUs = () => {
  const viewReduxActions = useViewReduxActions();

  const reduxState = useSelector(state => ({
    isAuthenticated: getUserAuthenticatedState(state),
    currentUserSKUs: getCurrentUserSKUs(state)
  }));

  const userSKUs = useMemo(() => (reduxState.isAuthenticated ? reduxState.currentUserSKUs : {}), [
    reduxState
  ]);

  const userHasSKU = useCallback(sku => Boolean(userSKUs[sku]), [userSKUs]);

  const showSKURestrictModal = useCallback(
    (sku, isUserLevelSKU = true) => {
      let shouldShowSKURestrictModal = Boolean(SKU_RESTRICTION_MODAL_TYPE_MAP[sku]);

      if (isUserLevelSKU) {
        shouldShowSKURestrictModal = shouldShowSKURestrictModal && !userHasSKU(sku);
      }

      if (shouldShowSKURestrictModal) {
        return viewReduxActions.showRestrictModal(SKU_RESTRICTION_MODAL_TYPE_MAP[sku]);
      }
    },
    [userHasSKU, viewReduxActions]
  );

  return { userSKUs, userHasSKU, showSKURestrictModal };
};
