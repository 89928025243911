import type { SentenceNoteTextProps } from '../SentenceNoteText.types';
import type { SentenceNoteTextData } from './useSentenceNoteTextData.types';

import * as constants from '../SentenceNoteText.constants';
import * as bucketsConstants from 'shared/features/buckets/buckets.constants';

import { useMemo, useState } from 'react';

export const useSentenceNoteTextData = ({
  sentencesText,
  bucket
}: Pick<SentenceNoteTextProps, 'sentencesText' | 'bucket'>): SentenceNoteTextData => {
  const [isExpanded, setIsExpanded] = useState(false);

  const localState: SentenceNoteTextData['localState'] = {
    isExpanded
  };

  const localActions: SentenceNoteTextData['localActions'] = useMemo(
    () => ({
      setIsExpanded
    }),
    []
  );

  const formattedData: SentenceNoteTextData['formattedData'] = useMemo(() => {
    const shouldShowMoreText = sentencesText.length > constants.MAX_SENTENCE_NOTE_TEXT_LENGTH;
    const sentenceNoteText =
      shouldShowMoreText && !isExpanded
        ? `${sentencesText.slice(0, constants.MAX_SENTENCE_NOTE_TEXT_LENGTH)}...`
        : sentencesText;
    const bucketName = bucket?.name ?? bucketsConstants.DEFAULT_BUCKET_NAME;

    return {
      shouldShowMoreText,
      sentenceNoteText,
      bucketName
    };
  }, [sentencesText, isExpanded, bucket?.name]);

  return {
    localState,
    localActions,
    formattedData
  };
};
