import type { PlainTextHandlers } from './usePlainTextHandlers.types';

import { useEffect } from 'react';

export const usePlainTextLifecycle = ({
  onPlainTextSet
}: {
  onPlainTextSet: PlainTextHandlers['handlePlainTextSet'];
}) => {
  useEffect(onPlainTextSet, [onPlainTextSet]);
};
