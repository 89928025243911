export const useWidgetHeaderOptionsMenuHandlers = ({ props, localActions }) => {
  const handleOptionsDropdownOpen = () => {
    localActions.setOptionsDropdownOpenState(true);
  };

  const handleOptionsDropdownClose = () => {
    localActions.setOptionsDropdownOpenState(false);
  };

  const handleOptionSelect = option => {
    localActions.setOptionsDropdownOpenState(false);
    props.onOptionSelect(option);
  };

  return {
    handleOptionsDropdownOpen,
    handleOptionsDropdownClose,
    handleOptionSelect
  };
};
