import { fetch } from 'utils/api';

export function api_fetchStatistics(params) {
  return fetch({
    url: `/rules_created_by_time`,
    method: 'GET',
    params,
    dataType: 'Statistics'
  });
}
