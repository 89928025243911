export const CHANGE_FILTER = 'CHANGE_FILTER';
export const SET_PRIMARY_FILTER = 'SET_PRIMARY_FILTER';
export const RESET_PRIMARY_FILTER = 'RESET_PRIMARY_FILTER';
export const SET_PRIMARY_FILTER_ERRORS = 'SET_PRIMARY_FILTER_ERRORS';
export const SET_PRIMARY_FILTER_DEFAULT_VIEW = 'SET_PRIMARY_FILTER_DEFAULT_VIEW';
export const TOGGLE_PRIMARY_FILTER_LOADING_STATE = 'TOGGLE_PRIMARY_FILTER_LOADING_STATE';
export const TOGGLE_PRIMARY_FILTER_READY_STATE = 'TOGGLE_PRIMARY_FILTER_READY_STATE';
export const CLEAR_PRIMARY_FILTER = 'CLEAR_PRIMARY_FILTER';

export const SET_ENFORCEMENT_FILTER = 'SET_ENFORCEMENT_FILTER';

export const SET_JURISDICTION_FILTER = 'SET_JURISDICTION_FILTER';
export const SET_JURISDICTION_FILTER_ERRORS = 'SET_JURISDICTION_FILTER_ERRORS';
export const TOGGLE_JURISDICTION_FILTER_DEFAULT_FILTERS =
  'TOGGLE_JURISDICTION_FILTER_DEFAULT_FILTERS';
export const RESET_JURISDICTION_FILTER = 'RESET_JURISDICTION_FILTER';
export const CLEAR_JURISDICTION_FILTER = 'CLEAR_JURISDICTION_FILTER';

export const SET_ACTION_BAR_FILTER = 'CHANGE_ACTION_BAR_FILTER';

export const setPrimaryFilter = filterParams => ({
  type: SET_PRIMARY_FILTER,
  payload: {
    filterParams
  }
});

export const setJurisdictionFilter = filterParams => ({
  type: SET_JURISDICTION_FILTER,
  payload: {
    filterParams
  }
});

export const resetPrimaryFilter = () => ({
  type: RESET_PRIMARY_FILTER
});

export const setPrimaryFilterDefaultView = view => ({
  type: SET_PRIMARY_FILTER_DEFAULT_VIEW,
  payload: {
    view
  }
});

export const setPrimaryFilterErrors = ({ errors }) => ({
  type: SET_PRIMARY_FILTER_ERRORS,
  payload: {
    errors
  }
});

export const togglePrimaryFilterLoadingState = ({ filterKey, isLoading }) => ({
  type: TOGGLE_PRIMARY_FILTER_LOADING_STATE,
  payload: {
    filterKey,
    isLoading
  }
});

export const changeFilter = currentFilter => ({
  type: CHANGE_FILTER,
  payload: {
    currentFilter
  }
});

export const togglePrimaryFilterReadyState = isReady => ({
  type: TOGGLE_PRIMARY_FILTER_READY_STATE,
  payload: {
    isReady: isReady
  }
});

export const clearPrimaryFilter = () => ({
  type: CLEAR_PRIMARY_FILTER
});

export const setJurisdictionFilterErrors = ({ errors }) => ({
  type: SET_JURISDICTION_FILTER_ERRORS,
  payload: {
    errors
  }
});

export const toggleJurisdictionFilterDefaultFilters = isToggledOn => ({
  type: TOGGLE_JURISDICTION_FILTER_DEFAULT_FILTERS,
  payload: {
    isToggledOn
  }
});

export const resetJurisdictionFilter = () => ({
  type: RESET_JURISDICTION_FILTER
});

export const clearJurisdictionFilter = () => ({
  type: CLEAR_JURISDICTION_FILTER
});

export const setActionBarFilter = filterParams => ({
  type: SET_ACTION_BAR_FILTER,
  payload: {
    filterParams
  }
});
