import PropTypes from 'prop-types';
import { ActionButton, TOOLTIP_POSITION, IconRemoveFolder } from '@compliance.ai/web-components';
import {
  useDocumentActionsRemoveUserFolderData,
  useDocumentActionsRemoveUserFolderHandlers,
  useDocumentActionsRemoveUserFolderLifecycle,
  useDocumentActionsRemoveUserFolderReduxActions
} from './hooks';
import { useViewReduxActions } from 'shared/features/view/hooks';
import './_remove-user-folder.scss';

export const RemoveUserFolder = ({ folderId, documentIds = [], limit }) => {
  const { formattedData } = useDocumentActionsRemoveUserFolderData({
    props: {
      documentIds
    }
  });

  const reduxActions = useDocumentActionsRemoveUserFolderReduxActions();
  const viewReduxActions = useViewReduxActions();

  const handlers = useDocumentActionsRemoveUserFolderHandlers({
    props: {
      folderId,
      documentIds,
      limit
    },
    formattedData,
    reduxActions,
    viewReduxActions
  });

  useDocumentActionsRemoveUserFolderLifecycle({
    handlers
  });

  return (
    <ActionButton
      isRestricted={formattedData.isRestricted}
      isDisabled={formattedData.isDisabled}
      tooltip="Remove from folder"
      tooltipPlacement={TOOLTIP_POSITION.BOTTOM}
      onClick={handlers.handleRemoveFolderDocuments}
      onRestrictedClick={handlers.handleRestrictModalShow}
    >
      <IconRemoveFolder />
    </ActionButton>
  );
};

RemoveUserFolder.propTypes = {
  folderId: PropTypes.number.isRequired,
  documentIds: PropTypes.arrayOf(PropTypes.number),
  limit: PropTypes.number.isRequired
};
