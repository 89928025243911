import type { BucketOption } from 'shared/features/buckets/buckets.types';
import type { NormalizedBucket } from 'shared/features/buckets/buckets.actions.types';
import type { BucketSelectProps } from '../BucketSelect.types';

import * as bucketsConstants from 'shared/features/buckets/buckets.constants';
import * as bucketsHelpers from 'shared/features/buckets/buckets.helpers';

export const formatBucketsOptions = ({
  buckets,
  searchValue,
  exclusiveBucketId,
  isPublicFolderDisabled
}: {
  buckets: NormalizedBucket[];
  searchValue: string;
  exclusiveBucketId: BucketSelectProps['exclusiveBucketId'];
  isPublicFolderDisabled: boolean;
}): BucketOption[] => {
  const bucketsOptions = buckets
    .filter(bucket => bucket.id !== exclusiveBucketId)
    .map(bucketsHelpers.formatBucketOption);

  if (
    isPublicBucketFiltered(searchValue) &&
    (!isPublicFolderDisabled || bucketsOptions.length === 0)
  ) {
    return [bucketsConstants.DEFAULT_BUCKET_OPTION, ...bucketsOptions];
  }

  return bucketsOptions;
};

export const isPublicBucketFiltered = (searchValue: string) =>
  bucketsConstants.DEFAULT_BUCKET_NAME.toLowerCase().includes(searchValue.toLowerCase());
