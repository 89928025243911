import type { BucketNameCellData } from './useBucketNameCellData.types';
import type { BucketNameCellProps } from '../BucketNameCell.types';

import * as helpers from './useBucketNameCellData.helpers';

import { useMemo } from 'react';

export const useBucketNameCellData = ({
  cellValue
}: Pick<BucketNameCellProps, 'cellValue'>): BucketNameCellData => {
  const formattedData: BucketNameCellData['formattedData'] = useMemo(
    () => ({
      isBucketShared: helpers.isBucketShared({
        permissions: cellValue.bucket.permissions
      })
    }),
    [cellValue.bucket.permissions]
  );

  return {
    formattedData
  };
};
