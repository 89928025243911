import { useLocation } from 'react-router-dom';
import queryString from 'utils/query-string';
import { useMemo } from 'react';

export const useQueryParams = ({ shouldDecode = true } = { shouldDecode: true }) => {
  const location = useLocation();

  return useMemo(
    () =>
      queryString.parse(location.search, {
        decode: shouldDecode
      }),
    [location.search, shouldDecode]
  );
};
