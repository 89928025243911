import type { PlainTextProps } from './PlainText.types';

import { TextBox } from './elements';
import { Utils } from 'react-awesome-query-builder';
import {
  usePlainTextData,
  usePlainTextQueries,
  usePlainTextHandlers,
  usePlainTextLifecycle
} from './hooks';
import './_plain-text.scss';

export const PlainText = ({ tree, config }: PlainTextProps) => {
  const { localState, localActions, formattedData, prevFormattedData } = usePlainTextData({
    tree,
    config
  });

  const handlers = usePlainTextHandlers({
    props: {
      config
    },
    localState,
    localActions,
    formattedData,
    prevFormattedData
  });

  const queries = usePlainTextQueries({
    formattedData,
    onSuccess: handlers.handleQuerySuccess
  });

  usePlainTextLifecycle({
    onPlainTextSet: handlers.handlePlainTextSet
  });

  if (!Utils.queryString(tree, config)) {
    return <div className="plain-text__no-query-message">No query</div>;
  }

  return (
    <>
      <TextBox
        title="Advanced search compatible text"
        className="plain-text__box"
        value={Utils.queryString(tree, config)}
      />
      <TextBox
        title="Plain text"
        className="plain-text__box"
        value={localState.plainText}
        isLoading={queries.some(query => query.isFetching)}
      />
    </>
  );
};
