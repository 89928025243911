import type { WorkflowData } from './useWorkflowData.types';

import { useMemo, useState } from 'react';

export const useWorkflowData = (): WorkflowData => {
  const [tasksDetailsVisibility, toggleTasksDetailsVisibility] = useState({});
  const [isSummaryModalOpen, setSummaryModalOpenState] = useState(false);

  const localState: WorkflowData['localState'] = {
    tasksDetailsVisibility,
    isSummaryModalOpen
  };

  const localActions: WorkflowData['localActions'] = useMemo(
    () => ({
      toggleTasksDetailsVisibility,
      setSummaryModalOpenState
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
