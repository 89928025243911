import type { AuthorsSelectProps } from 'common/index';
import type {
  DocumentFromResponse,
  DocumentAuthorFromResponse,
  DocumentTopic
} from 'shared/features/documents/documents.types';
import type {
  DocumentMetadataLocalState,
  DocumentMetadataFormattedData
} from './useDocumentEditMetadataData.types';

import * as constants from '../DocumentEditMetadata.constants';
import * as uiLib from '@compliance.ai/web-components';

import { DOCUMENT_METADATA_FIELDS, DOCUMENT_METADATA_LABELS } from 'constants/DocumentMetadata';

export const formatInitialMetadataValues = (
  document: DocumentFromResponse
): DocumentMetadataLocalState['metadataValues'] => {
  return {
    [DOCUMENT_METADATA_FIELDS.ID]: document.id,
    [DOCUMENT_METADATA_FIELDS.TITLE]: document.title,
    [DOCUMENT_METADATA_FIELDS.SUMMARY]: document.summary_text,
    [DOCUMENT_METADATA_FIELDS.PUBLICATION_DATE]: uiLib.formatDate(document.publication_date, {
      format: constants.DOCUMENT_DATE_FORMAT
    }),
    [DOCUMENT_METADATA_FIELDS.CREATED_AT]: uiLib.formatDate(document.created_at, {
      format: constants.DOCUMENT_DATE_FORMAT
    }),
    [DOCUMENT_METADATA_FIELDS.UPDATED_AT]: uiLib.formatDate(document.updated_at, {
      format: constants.DOCUMENT_DATE_FORMAT
    }),
    [DOCUMENT_METADATA_FIELDS.COMMENTS_CLOSE_ON]: uiLib.formatDate(
      document.rule?.comments_close_on,
      { format: constants.DOCUMENT_DATE_FORMAT }
    ),
    [DOCUMENT_METADATA_FIELDS.EFFECTIVE_ON]: uiLib.formatDate(document.rule?.effective_on, {
      format: constants.DOCUMENT_DATE_FORMAT
    }),
    [DOCUMENT_METADATA_FIELDS.DOC_TYPES]: document.cai_category_id,
    [DOCUMENT_METADATA_FIELDS.CITATION]: document.official_id,
    [DOCUMENT_METADATA_FIELDS.JURISDICTION]: document.jurisdiction,
    [DOCUMENT_METADATA_FIELDS.AUTHOR]: formatDocumenAuthorValue(document.authors),
    [DOCUMENT_METADATA_FIELDS.TOPICS]: formatDocumenTopicsValues(document.topics)
  };
};

export const formatMetadataFields = (
  document: DocumentFromResponse,
  availableFields: DocumentMetadataFormattedData['availableDocumentUpdateFields']
): DocumentMetadataFormattedData['metadataFields'] => {
  return {
    [DOCUMENT_METADATA_FIELDS.ID]: {
      label: DOCUMENT_METADATA_LABELS[DOCUMENT_METADATA_FIELDS.ID],
      available: true,
      editable: availableFields.includes(DOCUMENT_METADATA_FIELDS.ID)
    },
    [DOCUMENT_METADATA_FIELDS.TITLE]: {
      label: DOCUMENT_METADATA_LABELS[DOCUMENT_METADATA_FIELDS.TITLE],
      available: true,
      editable: availableFields.includes(DOCUMENT_METADATA_FIELDS.TITLE)
    },
    [DOCUMENT_METADATA_FIELDS.SUMMARY]: {
      label: DOCUMENT_METADATA_LABELS[DOCUMENT_METADATA_FIELDS.SUMMARY],
      available: true,
      editable: availableFields.includes(DOCUMENT_METADATA_FIELDS.SUMMARY)
    },
    [DOCUMENT_METADATA_FIELDS.PUBLICATION_DATE]: {
      label: DOCUMENT_METADATA_LABELS[DOCUMENT_METADATA_FIELDS.PUBLICATION_DATE],
      available: Boolean(document.publication_date),
      editable: availableFields.includes(DOCUMENT_METADATA_FIELDS.PUBLICATION_DATE)
    },
    [DOCUMENT_METADATA_FIELDS.CREATED_AT]: {
      label: DOCUMENT_METADATA_LABELS[DOCUMENT_METADATA_FIELDS.CREATED_AT],
      available: Boolean(document.created_at),
      editable: availableFields.includes(DOCUMENT_METADATA_FIELDS.CREATED_AT)
    },
    [DOCUMENT_METADATA_FIELDS.UPDATED_AT]: {
      label: DOCUMENT_METADATA_LABELS[DOCUMENT_METADATA_FIELDS.UPDATED_AT],
      available: Boolean(document.updated_at),
      editable: availableFields.includes(DOCUMENT_METADATA_FIELDS.UPDATED_AT)
    },
    [DOCUMENT_METADATA_FIELDS.COMMENTS_CLOSE_ON]: {
      label: DOCUMENT_METADATA_LABELS[DOCUMENT_METADATA_FIELDS.COMMENTS_CLOSE_ON],
      available: Boolean(document.rule?.comments_close_on),
      editable: availableFields.includes(DOCUMENT_METADATA_FIELDS.COMMENTS_CLOSE_ON)
    },
    [DOCUMENT_METADATA_FIELDS.EFFECTIVE_ON]: {
      label: DOCUMENT_METADATA_LABELS[DOCUMENT_METADATA_FIELDS.EFFECTIVE_ON],
      available: Boolean(document.rule?.effective_on),
      editable: availableFields.includes(DOCUMENT_METADATA_FIELDS.EFFECTIVE_ON)
    },
    [DOCUMENT_METADATA_FIELDS.DOC_TYPES]: {
      label: DOCUMENT_METADATA_LABELS[DOCUMENT_METADATA_FIELDS.DOC_TYPES],
      available: true,
      editable: availableFields.includes(DOCUMENT_METADATA_FIELDS.DOC_TYPES)
    },
    [DOCUMENT_METADATA_FIELDS.JURISDICTION]: {
      label: DOCUMENT_METADATA_LABELS[DOCUMENT_METADATA_FIELDS.JURISDICTION],
      available: true,
      editable: availableFields.includes(DOCUMENT_METADATA_FIELDS.JURISDICTION)
    },
    [DOCUMENT_METADATA_FIELDS.CITATION]: {
      label: DOCUMENT_METADATA_LABELS[DOCUMENT_METADATA_FIELDS.CITATION],
      available: true,
      editable: availableFields.includes(DOCUMENT_METADATA_FIELDS.CITATION)
    },
    [DOCUMENT_METADATA_FIELDS.AUTHOR]: {
      label: DOCUMENT_METADATA_LABELS[DOCUMENT_METADATA_FIELDS.AUTHOR],
      available: true,
      editable: availableFields.includes(DOCUMENT_METADATA_FIELDS.AUTHOR)
    },
    [DOCUMENT_METADATA_FIELDS.TOPICS]: {
      label: DOCUMENT_METADATA_LABELS[DOCUMENT_METADATA_FIELDS.TOPICS],
      available: Boolean(document.topics),
      editable: availableFields.includes(DOCUMENT_METADATA_FIELDS.TOPICS)
    }
  };
};

export const formatAvailableFields = (
  availableFields: DocumentMetadataFormattedData['availableDocumentUpdateFields']
) => {
  return availableFields.map(
    field => constants.DOCUMENT_METADATA_AVAILABLE_FIELD_MAP[field] || field
  );
};

export const formatDocumenAuthorValue = (
  authors?: DocumentAuthorFromResponse[]
): AuthorsSelectProps['value'] => {
  if (Array.isArray(authors)) {
    return authors[0]?.name as AuthorsSelectProps['value'];
  }

  return undefined;
};

export const formatDocumenTopicsValues = (topics?: DocumentTopic[]): DocumentTopic['id'][] => {
  if (Array.isArray(topics)) {
    return topics.map(topic => topic.id);
  }

  return [];
};
