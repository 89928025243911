import type { ReactQueryProviderProps } from './ReactQueryProvider.types';

import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as constants from './ReactQueryProvider.constants';
import { useReactQueryHandlers, useReactQueryLifecycle } from './hooks';

export const ReactQueryProvider = ({ children }: ReactQueryProviderProps) => {
  const handlers = useReactQueryHandlers();

  useReactQueryLifecycle({
    onClearDeprecatedQueryCache: handlers.handleClearDeprecatedQueryCache
  });

  return (
    <PersistQueryClientProvider
      client={constants.QUERY_CLIENT}
      persistOptions={{ persister: constants.QUERY_CLIENT_PERSISTER }}
    >
      <ReactQueryDevtools initialIsOpen={false} />
      {children}
    </PersistQueryClientProvider>
  );
};
