import objectHelpers from 'utils/object-helpers';

export const normalizeTaskVelocityData = (
  {
    todoTasksResponse: { doc_tasks_count_by_dates: todoTasksCountByDates },
    pastDueTasksResponse: { doc_tasks_count_by_dates: pastDueTasksCountByDates },
    doneTasksResponse: { doc_tasks_count_by_dates: doneTasksCountByDates }
  } = {
    todoTasksResponse: {
      doc_tasks_count_by_dates: {}
    },
    pastDueTasksResponse: {
      doc_tasks_count_by_dates: {}
    },
    doneTasksResponse: {
      doc_tasks_count_by_dates: {}
    }
  }
) => {
  const incompleteTasksCountByDate = objectHelpers.mergeArraysOfObjectsIntoObjectAndSummarizeValues(
    [pastDueTasksCountByDates, todoTasksCountByDates]
  );

  return {
    doneTasksCountByDate: doneTasksCountByDates,
    incompleteTasksCountByDate: incompleteTasksCountByDate
  };
};
