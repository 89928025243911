import type { FC } from 'react';
import type { PrimaryFilterProps } from './PrimaryFilter.types';

import * as common from 'common';
import * as env from 'shared/config';
import * as elements from './elements';
import * as helpers from './PrimaryFilter.helpers';
import * as constants from 'constants/PrimaryFilter';
import * as skusConstants from 'constants/SKUs';
import * as authUtils from 'utils/authorization';
import * as uiLib from '@compliance.ai/web-components';
import * as buttonConstants from 'common/Filter/DefaultFiltersSwitch/DefaultFiltersSwitch';

import lodash from 'lodash';

import { useRef } from 'react';
import { useFilterOptions } from 'shared/features/filters/hooks';
import { useOrganizationLabelsReduxActions } from 'shared/features/labels/hooks';
import { useSKUs } from 'utils/hooks';
import {
  usePrimaryFilterData,
  usePrimaryFilterHandlers,
  usePrimaryFilterLifecycles,
  usePrimaryFilterReduxActions
} from './hooks';

import './_primary-filter.scss';

export const PrimaryFilter: FC<PrimaryFilterProps> = ({ currentView, searchQuery }) => {
  const { userHasSKU } = useSKUs();

  const { localState, localActions, reduxState, formattedData } = usePrimaryFilterData();

  const reduxActions = {
    ...usePrimaryFilterReduxActions({ reduxState }),
    ...useOrganizationLabelsReduxActions()
  };

  const reduxStateRef = useRef(reduxState);
  const reduxActionsRef = useRef(reduxActions);

  const filterOptions = useFilterOptions();

  const handlers = usePrimaryFilterHandlers({
    props: {
      currentView,
      searchQuery
    },
    reduxState,
    reduxActions,
    localActions,
    formattedData,
    refs: { reduxStateRef, reduxActionsRef }
  });

  usePrimaryFilterLifecycles({
    onInitialFiltersLoad: handlers.handleInitialFiltersLoad,
    onRefsUpdated: handlers.handleRefsUpdated,
    onWillUnmount: handlers.handleWillUnmount
  });

  if (formattedData.isFilterDataLoading) {
    return (
      <div className="primary-filter__loader-wrapper">
        <common.Loader containerClassName="primary-filter__loader" />
      </div>
    );
  }

  return (
    <uiLib.ErrorBoundary>
      <div className="primary-filter">
        {localState.areViewDefaultsLoading && <uiLib.Loader withoutBackground={false} />}
        <common.FilterHeader className="primary-filter__header">
          <common.FilterColumn>
            <common.FilterLabeledElement label="Select View" isBold>
              <common.DefaultFiltersViewSelect
                onSelectChange={handlers.handleDefaultsViewSelect}
                view={reduxState.selectedDefaultView}
              />
            </common.FilterLabeledElement>
          </common.FilterColumn>
        </common.FilterHeader>
        <common.FilterLayout>
          {formattedData.isAlertEditMode && (
            <common.AlertNameTextfield
              isDisabled={!reduxState.current_view.editAlertId}
              value={localState.alertName || reduxState.current_view.alertName}
              onChange={localActions.changeAlertName}
            />
          )}

          <common.FilterBody>
            <common.FilterColumn>
              <common.CustomDatePicker
                date={reduxState.filterParams[constants.FILTER_KEY.PUB_DATE]}
                startDate={reduxState.filterParams[constants.FILTER_KEY.PUB_START_DATE]}
                endDate={reduxState.filterParams[constants.FILTER_KEY.PUB_END_DATE]}
                errors={reduxState.errors}
                onChange={(key, value) => handlers.handleChange(value, key)}
                onError={handlers.handleErrorChange}
                dateKey={constants.FILTER_KEY.PUB_DATE}
                startDateKey={constants.FILTER_KEY.PUB_START_DATE}
                endDateKey={constants.FILTER_KEY.PUB_END_DATE}
                dateOrderErrorKey={constants.ERROR_KEY.PUB_DATE_ORDER}
                areDatesRequired={false}
                cleared={localState.cleared}
                subject="Publication"
                customLabelElements={
                  <common.OfficialPubDateCheckbox
                    isChecked={
                      !Boolean(
                        reduxState.filterParams[constants.FILTER_KEY.HAS_UNOFFICIAL_PUB_DATE]
                      )
                    }
                    onChange={isChecked =>
                      handlers.handleChange(
                        !isChecked,
                        constants.FILTER_KEY.HAS_UNOFFICIAL_PUB_DATE
                      )
                    }
                  />
                }
              />
              <common.CustomDatePicker
                date={reduxState.filterParams[constants.FILTER_KEY.ADDED_DATE]}
                startDate={reduxState.filterParams[constants.FILTER_KEY.ADDED_START_DATE]}
                endDate={reduxState.filterParams[constants.FILTER_KEY.ADDED_END_DATE]}
                errors={reduxState.errors}
                onChange={(key, value) => handlers.handleChange(value, key)}
                onError={handlers.handleErrorChange}
                dateKey={constants.FILTER_KEY.ADDED_DATE}
                startDateKey={constants.FILTER_KEY.ADDED_START_DATE}
                endDateKey={constants.FILTER_KEY.ADDED_END_DATE}
                dateOrderErrorKey={constants.ERROR_KEY.ADDED_DATE_ORDER}
                areDatesRequired={false}
                cleared={localState.cleared}
                subject="Added"
                label="Added to Compliance.ai:"
              />
              <common.CustomDatePicker
                date={reduxState.filterParams[constants.FILTER_KEY.UPDATED_DATE]}
                startDate={reduxState.filterParams[constants.FILTER_KEY.UPDATED_START_DATE]}
                endDate={reduxState.filterParams[constants.FILTER_KEY.UPDATED_END_DATE]}
                errors={reduxState.errors}
                onChange={(key, value) => handlers.handleChange(value, key)}
                onError={handlers.handleErrorChange}
                dateKey={constants.FILTER_KEY.UPDATED_DATE}
                startDateKey={constants.FILTER_KEY.UPDATED_START_DATE}
                endDateKey={constants.FILTER_KEY.UPDATED_END_DATE}
                dateOrderErrorKey={constants.ERROR_KEY.UPDATED_DATE_ORDER}
                areDatesRequired={false}
                cleared={localState.cleared}
                subject="Updated"
                label="Updated in Compliance.ai:"
              />
              <common.FilterLabeledElement label="Agency:">
                <common.AgenciesSelect
                  type={uiLib.SELECT_TYPES.ALTERNATIVE}
                  value={reduxState.filterParams[constants.FILTER_KEY.AGENCIES] as string[]}
                  onChange={value => handlers.handleChange(value, constants.FILTER_KEY.AGENCIES)}
                  shouldCloseMenuOnSelect={false}
                  isMulti
                />
              </common.FilterLabeledElement>
              <common.FilterLabeledElement label="Exclude Federal Register:">
                <uiLib.Checkbox
                  isChecked={Boolean(
                    reduxState.filterParams[constants.FILTER_KEY.EXCLUDE_FEDERAL_REGISTER]
                  )}
                  onChange={isChecked =>
                    handlers.handleChange(isChecked, constants.FILTER_KEY.EXCLUDE_FEDERAL_REGISTER)
                  }
                />
              </common.FilterLabeledElement>
              <common.FilterLabeledElement label="Jurisdiction:">
                <common.JurisdictionsSelect
                  type={uiLib.SELECT_TYPES.ALTERNATIVE}
                  value={(reduxState.filterParams[
                    constants.FILTER_KEY.JURISDICTIONS
                  ] as uiLib.SelectOption[]).map(o => o.value)}
                  valueKey={common.JURISDICTIONS_SELECT_VALUE_KEYS.SHORT_NAME}
                  onChange={value =>
                    handlers.handleChange(value, constants.FILTER_KEY.JURISDICTIONS)
                  }
                  shouldCloseMenuOnSelect={false}
                  isMulti
                />
              </common.FilterLabeledElement>
              <common.FilterLabeledElement label={<>News &amp; Premium Content:</>}>
                <uiLib.Select
                  type={uiLib.SELECT_TYPES.ALTERNATIVE}
                  options={filterOptions[constants.FILTER_KEY.MAINSTREAM_NEWS_SOURCES]}
                  value={
                    reduxState.filterParams[
                      constants.FILTER_KEY.MAINSTREAM_NEWS_SOURCES
                    ] as uiLib.SelectOption[]
                  }
                  onChange={value =>
                    handlers.handleChange(value, constants.FILTER_KEY.MAINSTREAM_NEWS_SOURCES)
                  }
                  filterOption={helpers.onNewsOptionsFilter}
                  shouldCloseMenuOnSelect={false}
                  isMulti
                />
              </common.FilterLabeledElement>
              <common.FilterLabeledElement label="Topics:">
                <uiLib.Select
                  type={uiLib.SELECT_TYPES.ALTERNATIVE}
                  options={filterOptions[constants.FILTER_KEY.TOPICS]}
                  value={reduxState.filterParams[constants.FILTER_KEY.TOPICS] as uiLib.SelectValue}
                  onChange={value => handlers.handleChange(value, constants.FILTER_KEY.TOPICS)}
                  shouldCloseMenuOnSelect={false}
                  isMulti
                />
              </common.FilterLabeledElement>
              <common.FilterLabeledElement label="Concepts:">
                <uiLib.GroupSelect
                  type={uiLib.SELECT_TYPES.ALTERNATIVE}
                  value={
                    reduxState.filterParams[constants.FILTER_KEY.CONCEPTS] as uiLib.SelectValue
                  }
                  onChange={value => handlers.handleChange(value, constants.FILTER_KEY.CONCEPTS)}
                  options={filterOptions[constants.FILTER_KEY.CONCEPTS]}
                />
              </common.FilterLabeledElement>
              {userHasSKU(skusConstants.SKU_RESTRICTION_TYPES.BYOC) && (
                <common.FilterLabeledElement label="Author:">
                  <common.AuthorsSelect
                    type={uiLib.SELECT_TYPES.ALTERNATIVE}
                    value={(reduxState.filterParams[
                      constants.FILTER_KEY.AUTHOR
                    ] as uiLib.SelectOption[])?.map(option => option.value)}
                    onChange={value => handlers.handleChange(value, constants.FILTER_KEY.AUTHOR)}
                    isMulti
                  />
                </common.FilterLabeledElement>
              )}
              {authUtils.isPowerOrTeamOrProUser() && (
                <common.FilterLabeledElement label="Document Properties:">
                  <uiLib.Select
                    type={uiLib.SELECT_TYPES.ALTERNATIVE}
                    options={filterOptions[constants.FILTER_KEY.DOC_PROPERTIES]}
                    value={
                      reduxState.filterParams[
                        constants.FILTER_KEY.DOC_PROPERTIES
                      ] as uiLib.SelectValue
                    }
                    onChange={value =>
                      handlers.handleChange(value, constants.FILTER_KEY.DOC_PROPERTIES, true)
                    }
                    isMulti
                    maxMenuHeight={120}
                  />
                </common.FilterLabeledElement>
              )}
              <common.FilterLabeledElement label="Text is:">
                <div className="primary-filter__text-container">
                  <uiLib.TextField
                    value={reduxState.filterParams[constants.FILTER_KEY.TEXT] as string}
                    styleType={uiLib.TEXTFIELD_STYLE_TYPES.ALTER}
                    onChange={value => handlers.handleChange(value, constants.FILTER_KEY.TEXT)}
                    placeholder="Type Text here..."
                    className="primary-filter__text-field"
                  />
                  <uiLib.Select
                    type={uiLib.SELECT_TYPES.ALTERNATIVE}
                    options={filterOptions[constants.FILTER_KEY.TEXT_MATCH_TYPES]}
                    value={
                      reduxState.filterParams[
                        constants.FILTER_KEY.TEXT_MATCH_TYPES
                      ] as uiLib.SelectValue
                    }
                    onChange={value =>
                      handlers.handleChange(value, constants.FILTER_KEY.TEXT_MATCH_TYPES, true)
                    }
                    isClearable={false}
                    className="primaryFilter__text-select"
                  />
                </div>
              </common.FilterLabeledElement>
            </common.FilterColumn>
            <common.FilterColumn>
              <common.CustomDatePicker
                date={reduxState.filterParams[constants.FILTER_KEY.EFFECTIVE_DATE]}
                startDate={reduxState.filterParams[constants.FILTER_KEY.EFFECTIVE_START_DATE]}
                endDate={reduxState.filterParams[constants.FILTER_KEY.EFFECTIVE_END_DATE]}
                errors={reduxState.errors}
                onChange={(key, value) => handlers.handleChange(value, key)}
                onError={handlers.handleErrorChange}
                dateKey={constants.FILTER_KEY.EFFECTIVE_DATE}
                startDateKey={constants.FILTER_KEY.EFFECTIVE_START_DATE}
                endDateKey={constants.FILTER_KEY.EFFECTIVE_END_DATE}
                dateOrderErrorKey={constants.ERROR_KEY.EFFECTIVE_DATE_ORDER}
                areDatesRequired={false}
                nextDatesAvailable
                cleared={localState.cleared}
                subject="Effective"
                label="Effective Date:"
              />
              <common.CustomDatePicker
                date={reduxState.filterParams[constants.FILTER_KEY.COMMENTS_CLOSE_DATE]}
                startDate={reduxState.filterParams[constants.FILTER_KEY.COMMENTS_CLOSE_START_DATE]}
                endDate={reduxState.filterParams[constants.FILTER_KEY.COMMENTS_CLOSE_END_DATE]}
                errors={reduxState.errors}
                onChange={(key, value) => handlers.handleChange(value, key)}
                onError={handlers.handleErrorChange}
                dateKey={constants.FILTER_KEY.COMMENTS_CLOSE_DATE}
                startDateKey={constants.FILTER_KEY.COMMENTS_CLOSE_START_DATE}
                endDateKey={constants.FILTER_KEY.COMMENTS_CLOSE_END_DATE}
                dateOrderErrorKey={constants.ERROR_KEY.COMMENTS_CLOSE_DATE_ORDER}
                areDatesRequired={false}
                nextDatesAvailable
                cleared={localState.cleared}
                subject="Comments Close"
                label="Comments Close Date:"
              />
              <common.FilterLabeledElement label="Document Type:">
                <uiLib.GroupSelect
                  type={uiLib.SELECT_TYPES.ALTERNATIVE}
                  value={
                    reduxState.filterParams[constants.FILTER_KEY.DOC_TYPES] as uiLib.SelectValue
                  }
                  onChange={value => handlers.handleChange(value, constants.FILTER_KEY.DOC_TYPES)}
                  options={filterOptions[constants.FILTER_KEY.DOC_TYPES]}
                />
              </common.FilterLabeledElement>
              <common.FilterLabeledElement label="Document ID:">
                <uiLib.TextField
                  type="number"
                  styleType={uiLib.TEXTFIELD_STYLE_TYPES.ALTER}
                  value={reduxState.filterParams[constants.FILTER_KEY.DOCUMENT_ID] as string}
                  placeholder="Type Document ID here..."
                  onChange={value => handlers.handleChange(value, constants.FILTER_KEY.DOCUMENT_ID)}
                />
              </common.FilterLabeledElement>
              <common.FilterLabeledElement label="Regulation:">
                <uiLib.Select
                  type={uiLib.SELECT_TYPES.ALTERNATIVE}
                  options={filterOptions[constants.FILTER_KEY.REGULATIONS]}
                  value={
                    reduxState.filterParams[constants.FILTER_KEY.REGULATIONS] as uiLib.SelectValue
                  }
                  onChange={value => handlers.handleChange(value, constants.FILTER_KEY.REGULATIONS)}
                  shouldCloseMenuOnSelect={false}
                  isMulti
                />
              </common.FilterLabeledElement>
              <common.FilterLabeledElement label="Act:">
                <uiLib.Select
                  type={uiLib.SELECT_TYPES.ALTERNATIVE}
                  value={reduxState.filterParams[constants.FILTER_KEY.ACTS] as uiLib.SelectValue}
                  onChange={value => handlers.handleChange(value, constants.FILTER_KEY.ACTS)}
                  loadOptions={handlers.handleOptionsRequest(constants.FILTER_KEY.ACTS)}
                  isMulti
                  isAsync
                />
              </common.FilterLabeledElement>
              <common.FilterLabeledElement label="Business:">
                <uiLib.Select
                  type={uiLib.SELECT_TYPES.ALTERNATIVE}
                  options={filterOptions[constants.FILTER_KEY.BANKS as keyof typeof filterOptions]}
                  value={reduxState.filterParams[constants.FILTER_KEY.BANKS] as uiLib.SelectValue}
                  onChange={value => handlers.handleChange(value, constants.FILTER_KEY.BANKS)}
                  loadOptions={handlers.handleOptionsRequest(constants.FILTER_KEY.BANKS)}
                  isMulti
                  isAsync
                />
              </common.FilterLabeledElement>
              <common.FilterLabeledElement label="Citation is:">
                <uiLib.TextField
                  styleType={uiLib.TEXTFIELD_STYLE_TYPES.ALTER}
                  value={reduxState.filterParams[constants.FILTER_KEY.CITATION] as string}
                  onChange={value => handlers.handleChange(value, constants.FILTER_KEY.CITATION)}
                  placeholder="Type Citation here..."
                />
              </common.FilterLabeledElement>
              <common.FilterLabeledElement label="Title is:">
                <div className="primary-filter__text-container">
                  <uiLib.TextField
                    styleType={uiLib.TEXTFIELD_STYLE_TYPES.ALTER}
                    value={reduxState.filterParams[constants.FILTER_KEY.TITLE] as string}
                    onChange={value => handlers.handleChange(value, constants.FILTER_KEY.TITLE)}
                    placeholder="Type Title here..."
                    className="primary-filter__text-field"
                  />
                  <uiLib.Select
                    type={uiLib.SELECT_TYPES.ALTERNATIVE}
                    options={filterOptions[constants.FILTER_KEY.TITLE_MATCH_TYPES]}
                    value={
                      reduxState.filterParams[
                        constants.FILTER_KEY.TITLE_MATCH_TYPES
                      ] as uiLib.SelectValue
                    }
                    onChange={value =>
                      handlers.handleChange(value, constants.FILTER_KEY.TITLE_MATCH_TYPES, true)
                    }
                    isClearable={false}
                    className="primaryFilter__text-select"
                  />
                </div>
              </common.FilterLabeledElement>
              {authUtils.isPowerOrTeamOrProUser() && (
                <common.FilterLabeledElement label="Label:">
                  <common.LabelsSelect
                    type={uiLib.SELECT_TYPES.ALTERNATIVE}
                    value={(reduxState.filterParams[
                      constants.FILTER_KEY.LABELS
                    ] as uiLib.SelectOption[]).map(option => option.value)}
                    onChange={value => handlers.handleChange(value, constants.FILTER_KEY.LABELS)}
                    noOptionsMessage={inputValue => (inputValue ? 'No options' : 'Type to search')}
                  />
                </common.FilterLabeledElement>
              )}
              {env.shouldShowEitlStatus && (
                <common.FilterLabeledElement label="EITL labels:">
                  <common.EitlLabelsSelect
                    type={uiLib.SELECT_TYPES.ALTERNATIVE}
                    value={reduxState.filterParams[constants.FILTER_KEY.EITL_LABELS] as number[]}
                    onChange={handlers.handleEitlLabelsChange}
                    shouldCloseMenuOnSelect
                    shouldShowNoLabelsOption
                  />
                </common.FilterLabeledElement>
              )}
              <common.FilterLabeledElement label="Language:">
                <uiLib.Select
                  type={uiLib.SELECT_TYPES.ALTERNATIVE}
                  options={filterOptions[constants.FILTER_KEY.LANGUAGE]}
                  value={
                    reduxState.filterParams[constants.FILTER_KEY.LANGUAGE] as uiLib.SelectValue
                  }
                  onChange={value => handlers.handleChange(value, constants.FILTER_KEY.LANGUAGE)}
                />
              </common.FilterLabeledElement>
            </common.FilterColumn>
          </common.FilterBody>
          <common.FilterFooter
            error={
              !lodash.isEmpty(formattedData.filterErrors) && formattedData.filterErrors.join(', ')
            }
            buttons={
              <elements.FooterButtons
                searchQuery={searchQuery}
                currentView={currentView}
                onSubmit={handlers.handleFiltersSubmit}
              />
            }
            additionalContent={
              <uiLib.Button
                type={uiLib.BUTTON_TYPES.LINK}
                onClick={handlers.handleClearAll}
                dataTestId={buttonConstants.TEST_IDS.BUTTON}
                className="primary-filter__clear-all-button"
              >
                Clear All
              </uiLib.Button>
            }
          />
        </common.FilterLayout>
      </div>
    </uiLib.ErrorBoundary>
  );
};
