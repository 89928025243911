import PropTypes from 'prop-types';
import helpers from './helpers';
import './_widget-documents-summary.scss';

const WidgetDocumentsSummary = ({
  shouldAlwaysShowSeeAllLink,
  seeAllLinkText,
  subject,
  totalCount,
  countByCategories,
  onSeeAllLinkClick,
  isSubjectPlural
}) => {
  const shouldShowCountByCategories = helpers.isCountByCategoriesValid(countByCategories);

  const handleSeeAllLinkClick = e => {
    e.preventDefault();
    onSeeAllLinkClick(e);
  };

  const renderMentionsCount = () => {
    const isJustOneDocument = +totalCount === 1;

    if (subject) {
      return `${subject} ${isSubjectPlural ? 'were' : 'was'} mentioned in ${totalCount} document${
        isJustOneDocument ? '' : 's'
      }${shouldShowCountByCategories ? ':' : ''}`;
    } else {
      return `${totalCount} document${isJustOneDocument ? '' : 's'} ${
        isJustOneDocument ? 'was' : 'were'
      } found${shouldShowCountByCategories ? ':' : ''}`;
    }
  };

  const renderSeeAllLink = () => {
    if (!shouldAlwaysShowSeeAllLink && +totalCount <= 1) {
      return null;
    }

    return (
      <>
        {' - '}
        {
          <span className="widget-documents-summary__see-all-link" onClick={handleSeeAllLinkClick}>
            {seeAllLinkText}
          </span>
        }
      </>
    );
  };

  const renderMentionsByCategories = () => {
    if (shouldShowCountByCategories) {
      return Object.entries(countByCategories).map(([categoryName, docsCount], index, arr) => {
        const isLastItem = index === arr.length - 1;

        return (
          <span key={categoryName} className="widget-documents-summary__category-mention">
            <b>{docsCount}</b>
            <> {categoryName}</>
            {!isLastItem && ','}
          </span>
        );
      });
    }
  };

  return (
    <div className="widget-documents-summary__container">
      {renderMentionsCount()}
      {renderMentionsByCategories()}
      {renderSeeAllLink()}
    </div>
  );
};

export default WidgetDocumentsSummary;

WidgetDocumentsSummary.propTypes = {
  subject: PropTypes.string,
  totalCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  countByCategories: PropTypes.shape({}),
  onSeeAllLinkClick: PropTypes.func.isRequired,
  shouldAlwaysShowSeeAllLink: PropTypes.bool,
  seeAllLinkText: PropTypes.string,
  isSubjectPlural: PropTypes.bool
};

WidgetDocumentsSummary.defaultProps = {
  shouldAlwaysShowSeeAllLink: true,
  seeAllLinkText: 'See All',
  countByCategories: undefined,
  isSubjectPlural: false
};
