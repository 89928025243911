import {
  REQUEST_TASK_VELOCITY_DATA,
  RECEIVE_TASK_VELOCITY_DATA,
  REJECT_TASK_VELOCITY_DATA
} from './taskVelocityWidget.actions';
import { normalizeTaskVelocityData } from './taskVelocityWidget.helpers';

export const INITIAL_STATE = {};
export const INITIAL_TASK_VELOCITY_WIDGET_STATE = {
  isReady: false,
  doneTasksCountByDate: {},
  incompleteTasksCountByDate: {}
};

const taskVelocityWidget = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_TASK_VELOCITY_DATA: {
      const widgetState = state[action.meta.widgetId] || INITIAL_TASK_VELOCITY_WIDGET_STATE;

      return {
        ...state,
        [action.meta.widgetId]: {
          ...widgetState,
          isReady: false
        }
      };
    }

    case RECEIVE_TASK_VELOCITY_DATA: {
      const { doneTasksCountByDate, incompleteTasksCountByDate } = normalizeTaskVelocityData(
        action.payload
      );

      return {
        ...state,
        [action.meta.widgetId]: {
          ...state[action.meta.widgetId],
          isReady: true,
          doneTasksCountByDate,
          incompleteTasksCountByDate
        }
      };
    }

    case REJECT_TASK_VELOCITY_DATA: {
      return {
        ...state,
        [action.meta.widgetId]: INITIAL_TASK_VELOCITY_WIDGET_STATE
      };
    }

    default:
      return state;
  }
};

export default taskVelocityWidget;
