import { api_fetchTopicsStats, api_fetchDocumentCountsForTopics } from './topics.api';
import { api_fetchUserDefaults, api_updateUserDefaults } from '../user/user.api';
import { DEFAULTS_GET_ACTIONS, DEFAULTS_UPDATE_ACTIONS } from 'constants/Defaults';
import { getUserAuthenticatedState } from '../auth/auth.selectors';

export const REQUEST_TOPICS = 'REQUEST_TOPICS';
export const RECEIVE_TOPICS = 'RECEIVE_TOPICS';
export const FOLLOW_TOPICS = 'FOLLOW_TOPICS';
export const FOLLOWED_TOPICS = 'FOLLOWED_TOPICS';
export const REQUEST_TOPICS_STATS = 'REQUEST_TOPICS_STATS';
export const RECEIVE_TOPICS_STATS = 'RECEIVE_TOPICS_STATS';
export const RECEIVE_DOC_COUNTS_FOR_TOPICS = 'RECEIVE_DOC_COUNTS_FOR_TOPICS';

function requestTopicsStats() {
  return {
    type: REQUEST_TOPICS_STATS
  };
}

function receiveTopicsStats(json) {
  return {
    type: RECEIVE_TOPICS_STATS,
    topics_stats: json
  };
}

export function receiveTopics(json) {
  return {
    type: RECEIVE_TOPICS,
    topics: json.topics
  };
}

function requestTopics() {
  return {
    type: REQUEST_TOPICS
  };
}

function followTopicsAction(topics) {
  return {
    type: FOLLOW_TOPICS,
    topics: topics.topics
  };
}

function receiveDocCountsForTopics(json) {
  return {
    type: RECEIVE_DOC_COUNTS_FOR_TOPICS,
    docCountsTopicsObj: json
  };
}

export const followTopics = topics => async (dispatch, getState) => {
  const isAuthenticated = getUserAuthenticatedState(getState());

  dispatch(followTopicsAction(topics));

  if (!isAuthenticated) {
    return Promise.resolve();
  }

  return api_updateUserDefaults({
    ...topics,
    action: DEFAULTS_UPDATE_ACTIONS.UPDATE_FOLLOWED_TOPICS
  });
};

export const fetchTopics = () => async (dispatch, getState) => {
  const isAuthenticated = getUserAuthenticatedState(getState());

  if (!isAuthenticated) {
    return Promise.resolve();
  }

  dispatch(requestTopics());

  const response = await api_fetchUserDefaults({
    action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_TOPICS
  });

  dispatch(receiveTopics(response));

  return response;
};

export function fetchTopicsStats() {
  return function dofetch(dispatch) {
    dispatch(requestTopicsStats());
    return api_fetchTopicsStats().then(response => {
      dispatch(receiveTopicsStats(response));
    });
  };
}

export function fetchDocumentCountsForTopics(data) {
  return function dofetch(dispatch) {
    return api_fetchDocumentCountsForTopics(data).then(response => {
      dispatch(receiveDocCountsForTopics(response));
    });
  };
}
