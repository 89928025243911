import type { TaskAttachmentsAnnotationHandlers } from './useTaskAttachmentsAnnotationHandlers.types';
import type { TaskAttachmentsAnnotationProps } from '../TaskAttachmentsAnnotation.types';

import { useTaskAttachmentsAnnotationMutations } from './useTaskAttachmentsAnnotationMutations';

export const useTaskAttachmentsAnnotationHandlers = ({
  props,
  mutations
}: {
  props: Pick<TaskAttachmentsAnnotationProps, 'files' | 'documentTask'>;
  mutations: ReturnType<typeof useTaskAttachmentsAnnotationMutations>;
}): TaskAttachmentsAnnotationHandlers => {
  const handleFileDrop: TaskAttachmentsAnnotationHandlers['handleFileDrop'] = async ({
    newFiles
  }) => {
    try {
      await mutations.addDocumentTaskAttachmentsMutation.mutateAsync([
        props.documentTask.id,
        newFiles
      ]);
    } catch {}
  };

  const handleFileRemoval: TaskAttachmentsAnnotationHandlers['handleFileRemoval'] = async ({
    attachment
  }) => {
    try {
      await mutations.deleteDocumentTaskAttachmentsMutation.mutateAsync([
        attachment.masked_file_name
      ]);
    } catch {}
  };

  return {
    handleFileDrop,
    handleFileRemoval
  };
};
