export const MY_TASKS = { label: 'My Tasks Only', value: 'myTasksOnly' };
export const ALL_ORG_TASKS = { label: 'All Organization Tasks', value: 'allOrganizationTasks' };
export const MY_OBLIGATION_TASKS = { label: 'My Obligation Tasks', value: 'myObligationTasks' };
export const ALL_OBLIGATION_TASKS = { label: 'All Obligation Tasks', value: 'allObligationTasks' };
export const TASK_SELECT_OPTIONS = [
  MY_TASKS,
  ALL_ORG_TASKS,
  MY_OBLIGATION_TASKS,
  ALL_OBLIGATION_TASKS
];

export const LIST_VIEW = 'list';
export const CALENDAR_VIEW = 'calendar';
export const DOCUMENT_VIEW = 'document';

export const HIDE_BLOCKED_TASKS = { label: 'Hide blocked tasks', value: true };
export const SHOW_BLOCKED_TASKS = { label: 'Include blocked tasks', value: false };
export const BLOCKED_TASK_SELECT_OPTIONS = [HIDE_BLOCKED_TASKS, SHOW_BLOCKED_TASKS];
export const BLOCKED_TASK_POSSIBLE_VALUES = BLOCKED_TASK_SELECT_OPTIONS.map(o => o.value);

export const CREATED_AT = 'created_at';
export const DUE_DATE = 'due_date';

export const INCOMPLETE = 'incomplete';
export const TO_DO = 'to_do';
export const PAST_DUE = 'past_due';
export const DONE = 'done';

export enum TASK_STATUS {
  INCOMPLETE = 'incomplete',
  TO_DO = 'to_do',
  PAST_DUE = 'past_due',
  DONE = 'done'
}

export const SORT_METADATA = {
  [CREATED_AT]: {
    name: 'Created Date'
  },
  [DUE_DATE]: {
    name: 'Due Date'
  }
};

export const SORT_OPTIONS = [CREATED_AT, DUE_DATE];
