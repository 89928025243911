import type { AddBucketModalData } from './useAddBucketModalData.types';
import type { AddBucketModalHandlers } from './useAddBucketModalHandlers.types';
import type { AddBucketModalProps } from '../AddBucketModal.types';

import * as bucketsConstants from 'shared/features/buckets/buckets.constants';
import * as actionsConstants from 'shared/features/buckets/buckets.actions.constants';
import * as actionsHelpers from 'shared/features/buckets/buckets.actions.helpers';

export const useAddBucketModalHandlers = ({
  localActions
}: {
  localActions: AddBucketModalData['localActions'];
}): AddBucketModalHandlers => {
  const handleBucketNameChange: AddBucketModalHandlers['handleBucketNameChange'] = bucketName => {
    localActions.setBucketName(bucketName);
  };

  return {
    handleBucketNameChange
  };
};
