import type { ViolationProps } from './Violation.types';

import { RestrictedFieldName, RESTRICTION_TYPES } from '../RestrictedFieldName';
import { FIELD_VALUES } from 'shared/features/documents/documents.constants';
import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';

export const Violation = ({ document }: ViolationProps) => {
  if (document.enforcement?.violation === FIELD_VALUES.RESTRICTED) {
    return (
      <>
        <FieldName>
          <RestrictedFieldName restrictionType={RESTRICTION_TYPES.ENFORCEMENTS}>
            Violation
          </RestrictedFieldName>
        </FieldName>
        <FieldValue>Restricted</FieldValue>
      </>
    );
  }

  if (document.enforcement?.violation) {
    return (
      <>
        <FieldName>Violation</FieldName>
        <FieldValue isExpandable>{document.enforcement.violation}</FieldValue>
      </>
    );
  }

  return null;
};
