import PropTypes from 'prop-types';
import trunc from 'trunc-html';
import { APP_VIEW_SECTION_USER_FOLDERS, APP_VIEW_NEWDASHBOARD } from 'utils/search';
import './_toolbar-menu.scss';

export const TEST_IDS = {
  DASHBOARD: 'timeline-toolbar-menu-dashboard',
  FOLDER: 'timeline-toolbar-menu-folder'
};

const ToolbarMenu = ({ searchView, folder }) => {
  if (searchView.section === APP_VIEW_NEWDASHBOARD) {
    return (
      <h1 className="saved-search-header" data-testid={TEST_IDS.DASHBOARD}>
        Dashboard
      </h1>
    );
  } else if (
    searchView.section === APP_VIEW_SECTION_USER_FOLDERS &&
    searchView.subsection === 'custom_folder'
  ) {
    const shortFolderName = trunc(folder?.name, 20).text;
    return (
      <div className="toolbar-menu__folder-name" data-testid={TEST_IDS.FOLDER}>
        {shortFolderName}
      </div>
    );
  }

  return null;
};

ToolbarMenu.propTypes = {
  searchView: PropTypes.shape({
    section: PropTypes.string,
    subsection: PropTypes.string
  }).isRequired,
  folder: PropTypes.shape({
    name: PropTypes.string
  })
};

export default ToolbarMenu;
