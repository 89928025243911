import {
  api_fetchAllOrgDefaults,
  api_fetchAllTeamDefaults,
  api_fetchOrgDefaults,
  api_fetchTeamDefaults,
  api_updateOrgDefaults,
  api_updateTeamDefaults,
  getAreasOfFocus
} from 'shared/features/organizations/organizations.api';
import {
  api_fetchAllUserDefaults,
  api_fetchUserDefaults,
  api_updateUserDefaults
} from 'shared/features/user/user.api';
import { api_fetchDefaultSources } from 'shared/features/sources/sources.api';
import { api_fetchAgencies } from 'shared/features/agencies/agencies.api';
import { api_getDocTypes, api_getDocTypesUpdates } from 'shared/features/documents/documents.api';
import { api_fetchAllJurisdictions } from 'shared/features/jurisdictions/jurisdictions.api';
import { setModifiedDefaultsFlag } from 'shared/features/user/user.actions';
import { DEFAULTS_GET_ACTIONS, DEFAULTS_UPDATE_ACTIONS } from 'constants/Defaults';
import { DEFAULT_TYPES } from 'constants/DefaultSources';
import {
  formatObjectFromResponses,
  mapDefaultMainstreamNewsSourceToFollowedEntity,
  mapDefaultTopicsToFollowedTopics,
  normalizeActiveTopics,
  normalizeDefaultFollowedAgencies,
  normalizeDefaultMainstreamNewsSources,
  normalizeDefaultFollowedCategories,
  normalizeDefaults,
  normalizeDefaultTopics,
  updateFollowedIds,
  normalizeDefaultFollowedJurisdictions,
  normalizeFollowedConcepts,
  normalizeFollowedRegulations
} from './defaults.helpers';
import { getFollowedConcepts, getFollowedRegulations } from './defaults.selectors';
import { getUserAuthenticatedState } from '../auth/auth.selectors';

export const FETCH_DEFAULTS_BEGIN = 'FETCH_DEFAULTS_BEGIN';
export const FETCH_DEFAULTS_SUCCESS = 'FETCH_DEFAULTS_SUCCESS';
export const FETCH_DEFAULTS_ERROR = 'FETCH_DEFAULTS_ERROR';

export const FETCH_DEFAULT_CONCEPTS_SUCCESS = 'FETCH_DEFAULT_CONCEPTS_SUCCESS';
export const FETCH_DEFAULT_REGULATIONS_SUCCESS = 'FETCH_DEFAULT_REGULATIONS_SUCCESS';

export const UPDATE_DEFAULTS_BEGIN = 'UPDATE_DEFAULTS_BEGIN';
export const UPDATE_DEFAULTS_SUCCESS = 'UPDATE_DEFAULTS_SUCCESS';
export const UPDATE_DEFAULTS_ERROR = 'UPDATE_DEFAULTS_ERROR';

// For anonymous users
export const SET_DEFAULT_DEFAULTS = 'SET_DEFAULT_DEFAULTS';

export const REFRESH_OLD_DEFAULTS = 'REFRESH_OLD_DEFAULTS';

export const UPDATE_TEAM_DEFAULTS_FROM_EXISTING_MAP = 'UPDATE_TEAM_DEFAULTS_FROM_EXISTING_MAP';

export const FETCH_REQUESTS = {
  [DEFAULT_TYPES.MY_DEFAULTS]: async () => await api_fetchAllUserDefaults(),
  [DEFAULT_TYPES.TEAM_DEFAULTS]: async ({ teamId, areaOfFocusId }) =>
    await api_fetchAllTeamDefaults(teamId, areaOfFocusId),
  [DEFAULT_TYPES.ORGANIZATION_DEFAULTS]: async ({ orgId }) => await api_fetchAllOrgDefaults(orgId)
};

export const CONCEPTS_FETCH_REQUESTS = {
  [DEFAULT_TYPES.MY_DEFAULTS]: async () =>
    await api_fetchUserDefaults({
      action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_CONCEPTS
    }),
  [DEFAULT_TYPES.TEAM_DEFAULTS]: async ({ teamId, areaOfFocusId }) =>
    await api_fetchTeamDefaults(teamId, areaOfFocusId, {
      action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_CONCEPTS
    }),
  [DEFAULT_TYPES.ORGANIZATION_DEFAULTS]: async ({ orgId }) =>
    await api_fetchOrgDefaults(orgId, {
      action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_CONCEPTS
    })
};

export const REGULATIONS_FETCH_REQUESTS = {
  [DEFAULT_TYPES.MY_DEFAULTS]: async () =>
    await api_fetchUserDefaults({
      action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_REGULATIONS
    }),
  [DEFAULT_TYPES.TEAM_DEFAULTS]: async ({ teamId, areaOfFocusId }) =>
    await api_fetchTeamDefaults(teamId, areaOfFocusId, {
      action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_REGULATIONS
    }),
  [DEFAULT_TYPES.ORGANIZATION_DEFAULTS]: async ({ orgId }) =>
    await api_fetchOrgDefaults(orgId, {
      action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_REGULATIONS
    })
};

export const UPDATE_REQUESTS = {
  [DEFAULT_TYPES.MY_DEFAULTS]: async ({ params }) => await api_updateUserDefaults(params),
  [DEFAULT_TYPES.TEAM_DEFAULTS]: async ({ teamId, areaOfFocusId, params }) =>
    await api_updateTeamDefaults(teamId, areaOfFocusId, params),
  [DEFAULT_TYPES.ORGANIZATION_DEFAULTS]: async ({ orgId, params }) =>
    await api_updateOrgDefaults(orgId, params)
};

export const fetchDefaultsBegin = defaultsType => ({
  type: FETCH_DEFAULTS_BEGIN,
  meta: {
    defaultsType
  }
});

export const fetchDefaultsSuccess = ({ defaultsType, teamId }, payload) => ({
  type: FETCH_DEFAULTS_SUCCESS,
  meta: {
    defaultsType,
    teamId
  },
  payload
});

export const fetchDefaultsError = (defaultsType, error) => ({
  type: FETCH_DEFAULTS_ERROR,
  meta: {
    defaultsType
  },
  error
});

export const fetchDefaultConceptsSuccess = ({ defaultsType, teamId }, followedConcepts) => ({
  type: FETCH_DEFAULT_CONCEPTS_SUCCESS,
  meta: {
    defaultsType,
    teamId
  },
  payload: {
    followedConcepts
  }
});

export const fetchDefaultRegulationsSuccess = ({ defaultsType, teamId }, followedRegulations) => ({
  type: FETCH_DEFAULT_REGULATIONS_SUCCESS,
  meta: {
    defaultsType,
    teamId
  },
  payload: {
    followedRegulations
  }
});

export const updateDefaultsBegin = defaultsType => ({
  type: UPDATE_DEFAULTS_BEGIN,
  meta: {
    defaultsType
  }
});

export const updateDefaultsSuccess = ({ defaultsType, teamId, action }, payload) => ({
  type: UPDATE_DEFAULTS_SUCCESS,
  meta: {
    defaultsType,
    teamId,
    action
  },
  payload
});

export const updateDefaultsError = (defaultsType, error) => ({
  type: UPDATE_DEFAULTS_ERROR,
  meta: {
    defaultsType
  },
  error
});

export const setDefaultDefaults = (defaultsType, payload) => ({
  type: SET_DEFAULT_DEFAULTS,
  meta: {
    defaultsType
  },
  payload
});

export const fetchFollowedConcepts = ({ defaultsOwner, teamId, orgId, areaOfFocusId }) => async (
  dispatch,
  getState
) => {
  try {
    const isAuthenticated = getUserAuthenticatedState(getState());

    if (!isAuthenticated) {
      return Promise.resolve();
    }

    dispatch(fetchDefaultsBegin(defaultsOwner));

    const { concepts } = await CONCEPTS_FETCH_REQUESTS[defaultsOwner]({
      teamId,
      orgId,
      areaOfFocusId
    });

    dispatch(
      fetchDefaultConceptsSuccess(
        { defaultsType: defaultsOwner, teamId },
        normalizeFollowedConcepts(concepts)
      )
    );
  } catch (e) {
    dispatch(fetchDefaultsError(defaultsOwner, e));
  }
};

export const fetchFollowedRegulations = ({ defaultsOwner, teamId, orgId, areaOfFocusId }) => async (
  dispatch,
  getState
) => {
  try {
    const isAuthenticated = getUserAuthenticatedState(getState());

    if (!isAuthenticated) {
      return Promise.resolve();
    }

    dispatch(fetchDefaultsBegin(defaultsOwner));

    const { regulations } = await REGULATIONS_FETCH_REQUESTS[defaultsOwner]({
      teamId,
      orgId,
      areaOfFocusId
    });

    dispatch(
      fetchDefaultRegulationsSuccess(
        { defaultsType: defaultsOwner, teamId },
        normalizeFollowedRegulations(regulations)
      )
    );
  } catch (e) {
    dispatch(fetchDefaultsError(defaultsOwner, e));
  }
};

export const fetchDefaults = ({ defaultsOwner, teamId, orgId, areaOfFocusId }) => async (
  dispatch,
  getState
) => {
  try {
    const isAuthenticated = getUserAuthenticatedState(getState());

    if (!isAuthenticated) {
      return await dispatch(fetchDefaultDefaults());
    }

    dispatch(fetchDefaultsBegin(defaultsOwner));

    const responses = await FETCH_REQUESTS[defaultsOwner]({ teamId, orgId, areaOfFocusId });

    const sources = await api_fetchDefaultSources();

    const payload = formatObjectFromResponses(responses, sources);
    const normalizedPayload = normalizeDefaults(payload);

    dispatch(fetchDefaultsSuccess({ defaultsType: defaultsOwner, teamId }, normalizedPayload));
    return normalizedPayload;
  } catch (e) {
    dispatch(fetchDefaultsError(defaultsOwner, e));
    return {};
  }
};

export const fetchDefaultDefaults = () => async dispatch => {
  const defaultsOwner = DEFAULT_TYPES.MY_DEFAULTS;
  try {
    const [
      sources,
      { agencies },
      { cai_categories, cai_metacategories },
      docTypesUpdateNotification,
      { all_jurisdictions }
    ] = await Promise.all([
      api_fetchDefaultSources(),
      api_fetchAgencies(),
      api_getDocTypes(),
      api_getDocTypesUpdates(),
      api_fetchAllJurisdictions()
    ]);

    const followedAgencies = normalizeDefaultFollowedAgencies(agencies);
    const followedCategories = normalizeDefaultFollowedCategories(cai_categories);
    const followedMetacategories = normalizeDefaultFollowedCategories(cai_metacategories);
    const followedJurisdictions = normalizeDefaultFollowedJurisdictions(all_jurisdictions);
    const defaultTopics = normalizeDefaultTopics(sources);
    const defaultMainstreamNewsSources = normalizeDefaultMainstreamNewsSources(sources);
    const followedNewsSources = defaultMainstreamNewsSources.map(
      mapDefaultMainstreamNewsSourceToFollowedEntity
    );
    const followedTopics = defaultTopics.map(mapDefaultTopicsToFollowedTopics);
    const activeTopics = normalizeActiveTopics(sources);

    dispatch(
      setDefaultDefaults(defaultsOwner, {
        activeTopics,
        defaultTopics,
        defaultMainstreamNewsSources,
        followedAgencies,
        followedCategories,
        followedMetacategories,
        followedTopics,
        followedEntities: [...followedJurisdictions, ...followedNewsSources],
        followedJurisdictions,
        docTypesUpdateNotification
      })
    );
  } catch (e) {
    dispatch(fetchDefaultsError(defaultsOwner, e));
  }
};

export const updateDefaults = ({
  defaultsOwner,
  teamId,
  orgId,
  areaOfFocusId,
  action,
  params
}) => async (dispatch, getState) => {
  try {
    const isAuthenticated = getUserAuthenticatedState(getState());

    if (!isAuthenticated) {
      return Promise.resolve();
    }

    dispatch(updateDefaultsBegin(defaultsOwner));

    const paramsWithAction = {
      ...params,
      action
    };

    const response = await UPDATE_REQUESTS[defaultsOwner]({
      orgId,
      teamId,
      areaOfFocusId,
      params: paramsWithAction
    });
    if (teamId && !areaOfFocusId && defaultsOwner === DEFAULT_TYPES.TEAM_DEFAULTS) {
      const areasOfFocusResponse = await getAreasOfFocus(teamId, { offset: 0, limit: 1000 });
      const areasOfFocusIds = areasOfFocusResponse?.results?.map(area => area.id) || [];
      await Promise.all(
        areasOfFocusIds.map(id =>
          UPDATE_REQUESTS[defaultsOwner]({
            orgId,
            teamId,
            areaOfFocusId: id,
            params: paramsWithAction
          })
        )
      );
    }
    dispatch(updateDefaultsSuccess({ defaultsType: defaultsOwner, action, teamId }, response));

    if (DEFAULT_TYPES.MY_DEFAULTS === defaultsOwner) {
      dispatch(setModifiedDefaultsFlag());
    }
  } catch (e) {
    dispatch(updateDefaultsError(defaultsOwner, e));
  }
};

export const updateDefaultDefaults = ({ defaultsOwner, action, params }) => async (
  dispatch,
  getStore
) => {
  try {
    const store = getStore();
    const followedConcepts = getFollowedConcepts(defaultsOwner)(store);
    const followedRegulations = getFollowedRegulations(defaultsOwner)(store);

    let updatedParams = { ...params };

    if (action === DEFAULTS_UPDATE_ACTIONS.UPDATE_FOLLOWED_TOPICS) {
      updatedParams.topics = updatedParams.topics.map(mapDefaultTopicsToFollowedTopics);
    }

    if (
      [DEFAULTS_UPDATE_ACTIONS.CONCEPT_FOLLOW, DEFAULTS_UPDATE_ACTIONS.CONCEPT_UNFOLLOW].includes(
        action
      )
    ) {
      updatedParams = updatedParams.concepts.reduce(updateFollowedIds, followedConcepts);
    }

    if (
      [
        DEFAULTS_UPDATE_ACTIONS.REGULATION_FOLLOW,
        DEFAULTS_UPDATE_ACTIONS.REGULATION_UNFOLLOW
      ].includes(action)
    ) {
      updatedParams = updatedParams.regulations.reduce(updateFollowedIds, followedRegulations);
    }

    dispatch(updateDefaultsSuccess({ defaultsType: defaultsOwner, action }, updatedParams));
  } catch (e) {
    dispatch(updateDefaultsError(defaultsOwner, e));
  }
};

export const refreshOldDefaults = () => ({
  type: REFRESH_OLD_DEFAULTS
});

export const updateTeamDefaultsFromExistingMap = teamId => ({
  type: UPDATE_TEAM_DEFAULTS_FROM_EXISTING_MAP,
  meta: {
    teamId
  }
});
