import type { CitationContentProps } from './CitationContent.types';

import './_citation-content.scss';

export const CitationContent = ({
  document,
  onClick,
  shouldRenderOfficialId
}: CitationContentProps) => {
  if (!document.citation_ids?.length && document.official_id && shouldRenderOfficialId) {
    return <span onClick={onClick}>{document.official_id}</span>;
  }

  return (
    <>
      {document.citation_ids?.map((idObject, index) => (
        <div
          key={`${index}${JSON.stringify(idObject)}`}
          onClick={onClick}
          className="citation-content"
        >
          {`${shouldRenderOfficialId && document.official_id ? `${document.official_id} ` : ''}${
            Object.values(idObject)[0]
          }`}
        </div>
      ))}
    </>
  );
};
