import PropTypes from 'prop-types';
import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import MaterialCachedIcon from '@material-ui/icons/Cached';
import { ERROR_MESSAGES } from './PremiumContentForm.constants';
import { usePremiumContentFormData, usePremiumContentFormHandlers } from './hooks';
import './_premium-content-form.scss';

export const PremiumContentForm = ({
  sourceName,
  sourceURL,
  sourceAuthType,
  document,
  didTryToAuth,
  onUnlockClick
}) => {
  const { formattedData, reduxState } = usePremiumContentFormData({ didTryToAuth });

  const handlers = usePremiumContentFormHandlers({
    props: {
      document
    },
    reduxState
  });

  return formattedData.shouldShowForm ? (
    <>
      <h4 className="premium-content-form__header">{sourceName}</h4>
      <div className="premium-content-form__body">
        {`Enter your ${sourceName} credentials to access content in Compliance.ai`}
        <div className="premium-content-form__form">
          <p className="premium-content-form__user-email">
            {reduxState.currentUserEmail || 'Email'}
          </p>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            startIcon={<MaterialCachedIcon />}
            className="premium-content-form__submit-button"
            onClick={onUnlockClick}
          >
            Connect Accounts
          </Button>
        </div>
      </div>
      <div className="premium-content-form__footer">
        <a
          onClick={handlers.handleOutboundLinkClick}
          href={sourceURL}
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More
        </a>
      </div>
    </>
  ) : (
    <>
      <h4 className="premium-content-form__header">{`Premium Content: ${sourceName}`}</h4>
      <div className="premium-content-form__body">
        <p>You do not have access to this document.</p>
        <p>
          {ERROR_MESSAGES[sourceAuthType]({
            sourceName: sourceName,
            currentUserEmail: reduxState.currentUserEmail,
            documentUrl: formattedData.documentUrl,
            onLinkClick: handlers.handleOutboundLinkClick
          })}
        </p>
      </div>
    </>
  );
};

PremiumContentForm.propTypes = {
  sourceName: PropTypes.string.isRequired,
  sourceURL: PropTypes.string.isRequired,
  sourceAuthType: PropTypes.string.isRequired,
  document: PropTypes.shape({
    web_url: PropTypes.string,
    pdf_url: PropTypes.string
  }).isRequired,
  didTryToAuth: PropTypes.bool.isRequired,
  onUnlockClick: PropTypes.func.isRequired
};
