import type { AlertsTableLocalState } from './useAlertsTableData.types';
import type { AlertsTableHandlers } from './useAlertsTableHandlers.types';

import { useDidUpdate } from '@compliance.ai/web-components';

export const useAlertsTableLifecycle = ({
  onRequestParamsChange
}: {
  onRequestParamsChange: AlertsTableHandlers['handleRequestParamsChange'];
}) => {
  useDidUpdate(onRequestParamsChange, [onRequestParamsChange]);
};
