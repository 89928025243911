import {
  REQUEST_FOLLOWED_ENTITIES,
  RECEIVE_FOLLOWED_ENTITIES,
  FOLLOWING_ENTITIES,
  REQUEST_MENTION,
  RECEIVE_MENTION
} from './entities.actions';

export const getInitialEntitiesState = () => ({
  isFetching: false,
  isReady: false,
  pending_updates: 0,
  followed_entities: [],
  followedEntitiesObj: { mainstreamNews: {} }
});

export const entities = (state = getInitialEntitiesState(), action) => {
  switch (action.type) {
    case REQUEST_FOLLOWED_ENTITIES:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_FOLLOWED_ENTITIES: {
      const { followed_entities } = action;
      const followedEntitiesObj = { mainstreamNews: {} };
      followed_entities.forEach(entity => {
        if (entity.entity_type === 'news_sources') {
          followedEntitiesObj.mainstreamNews[entity.entity_id] = entity;
        }
      });

      return {
        ...state,
        isFetching: false,
        isReady: true,
        followed_entities,
        followedEntitiesObj
      };
    }
    case FOLLOWING_ENTITIES: {
      const { entities: newEntities } = action;
      const { followedEntitiesObj } = state;

      newEntities.forEach(entity => {
        if (entity.entity_type === 'news_sources') {
          if (!entity.following) {
            delete followedEntitiesObj.mainstreamNews[entity.entity_id];
          } else {
            const followedEntity = followedEntitiesObj.mainstreamNews[entity.entity_id];
            if (followedEntity) {
              followedEntity.followed = true;
            } else {
              followedEntitiesObj.mainstreamNews[entity.entity_id] = entity;
            }
          }
        }
      });

      return {
        ...state,
        followed_entities: [...Object.values(followedEntitiesObj.mainstreamNews)],
        followedEntitiesObj
      };
    }

    default:
      return state;
  }
};

export const getInitialFilteredMentionState = () => ({
  isFetching: false,
  isReady: false,
  mention: {}
});

export const filtered_mention = (state = getInitialFilteredMentionState(), action) => {
  switch (action.type) {
    case REQUEST_MENTION:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_MENTION:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        mention: action.mention
      };
    default:
      return state;
  }
};
