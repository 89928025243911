import React from 'react';
import * as common from 'common';
import {
  useFirstLoginJoyrideData,
  useFirstLoginExperienceConfig,
  useFirstLoginExperienceHandlers,
  useFirstLoginExperienceLifecycle
} from './hooks';

export const FirstLoginJoyride = React.memo(() => {
  const { localState, localActions, reduxState } = useFirstLoginJoyrideData();

  const { shouldRunJoyride, cardsList } = useFirstLoginExperienceConfig({
    localState,
    reduxState
  });

  const handlers = useFirstLoginExperienceHandlers({
    localState,
    localActions,
    reduxState
  });

  useFirstLoginExperienceLifecycle({
    handleGetOrganizationProfile: handlers.handleGetOrganizationProfile
  });

  return (
    <>
      {shouldRunJoyride && (
        <common.Joyride
          steps={cardsList}
          disableScrolling
          onFinish={handlers.handleClose}
          onSkip={handlers.handleClose}
        />
      )}
    </>
  );
});
