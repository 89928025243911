import type { SentenceNoteHandlers } from './useSentenceNoteHandlers.types';

import { useDidMount } from '@compliance.ai/web-components';

export const useSentenceNoteLifecycle = ({
  onMount
}: {
  onMount: SentenceNoteHandlers['handleSentenceSelection'];
}) => {
  useDidMount(onMount);
};
