import type { RouteConfig } from '../../router/Router.types';

import { matchPath } from 'react-router-dom';

export const flattenRoutes = (routes: RouteConfig[]): Omit<RouteConfig, 'children'>[] => {
  return routes.flatMap(route => {
    if (!Array.isArray(route.children)) {
      return [route];
    }

    return [route, ...flattenRoutes(route.children)];
  });
};

export const isPathInRoutes = (path: string, routes: RouteConfig[]): boolean =>
  routes.some(({ path: routePath }) => Boolean(matchPath(routePath as string, path)));
