import type { GlobalState } from 'shared/reducers';
import type { LastCommentData } from './useLastCommentData.types';

import { useSelector } from 'react-redux';
import { getUserTimezone } from 'shared/features/user/user.selectors';

export const useLastCommentData = (): LastCommentData => {
  const reduxState = useSelector<GlobalState, LastCommentData['reduxState']>(state => ({
    userTimezone: getUserTimezone(state)
  }));

  return {
    reduxState
  };
};
