import type { AlertsTableFiltersProps } from './AlertsTableFilters.types';

import * as uiLib from '@compliance.ai/web-components';

import {
  useAlertsTableFiltersConfig,
  useAlertsTableFiltersData,
  useAlertsTableFiltersHandlers
} from './hooks';

export const AlertsTableFilters = ({ onSubmit, className }: AlertsTableFiltersProps) => {
  const { localState, localActions, reduxState } = useAlertsTableFiltersData();

  const config = useAlertsTableFiltersConfig({
    reduxState
  });

  const handlers = useAlertsTableFiltersHandlers({
    props: {
      onSubmit
    },
    localActions
  });

  return (
    <uiLib.Filters
      config={config}
      values={localState.values}
      withSearch
      onSubmit={handlers.handleFiltersSubmit}
      dateFormat={uiLib.DATE_FORMATS.HUMANIZED_DAY_MONTH_YEAR}
      shouldDisableDropdownsPortal
      className={className}
    />
  );
};
