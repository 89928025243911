import { TABLE_FIELDS, API_KEYS } from '../LabelDetails.constants';

export const useLabelDetailsTableConfig = () => {
  const columns = [
    {
      title: 'Document title',
      dataKey: TABLE_FIELDS.DOCUMENT_TITLE,
      sortKey: API_KEYS.DOCUMENT_TITLE,
      defaultSort: true
    },
    {
      title: 'Added to',
      dataKey: TABLE_FIELDS.LABEL_PARENT,
      sortKey: API_KEYS.LABEL_PARENT
    },
    {
      title: 'Added by',
      dataKey: TABLE_FIELDS.ADDED_BY,
      sortKey: API_KEYS.ADDED_BY
    },
    {
      title: 'Date added',
      dataKey: TABLE_FIELDS.ADDED_AT,
      sortKey: API_KEYS.ADDED_AT
    }
  ];

  return {
    columns
  };
};
