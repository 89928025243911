import PropTypes from 'prop-types';
import './_selectedValue.scss';

export const TEST_ID = {
  VALUE_ICON: 'group-select-selected-value-icon'
};

function SelectedValue({ label, handleOptionClick, value }) {
  return (
    <div className="group-select-selected-value">
      <span
        label={label}
        onClick={() => handleOptionClick(label, value)}
        value={value}
        data-testid={TEST_ID.VALUE_ICON}
        className="group-select-selected-value-icon"
      >
        x
      </span>
      <span className="group-select-selected-value-label">{label}</span>
    </div>
  );
}

SelectedValue.propTypes = {
  label: PropTypes.string.isRequired,
  handleOptionClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default SelectedValue;
