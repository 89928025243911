import type { RegulationsSelectProps } from './RegulationsSelect.types';

import { Select, SELECT_TYPES } from '@compliance.ai/web-components';
import { useRegulationsSelectData } from './hooks/useRegulationsSelectData';

export const RegulationsSelect = (props: RegulationsSelectProps) => {
  const { reduxState, formattedData } = useRegulationsSelectData(props);

  return (
    <Select
      {...props}
      onChange={props.onChange}
      type={props.type ?? SELECT_TYPES.DEFAULT}
      isLoading={!reduxState.isReady || props.isLoading}
      isDisabled={!reduxState.isReady || props.isDisabled}
      options={reduxState.options}
      value={formattedData.value}
    />
  );
};
