import { useCallback } from 'react';

export const useFolderListItemHandlers = ({ props, formattedData }) => {
  const handleClick = useCallback(() => {
    if (formattedData.isDisabled) {
      return;
    }
    props.onClick(props.folder);
  }, [props, formattedData]);

  return {
    handleClick
  };
};
