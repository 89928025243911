import { createSlice } from '@reduxjs/toolkit';
import { fetchAllLabels, deleteLabel, triggerLabelsDetailsRefresh } from './labels.actions';

export const REDUCER_NAME = 'labels';

export const INITIAL_STATE = {
  isLoading: false,
  totalCount: 0,
  labels: [],
  labelsDetailsTrigger: false
};

export const { actions, reducer } = createSlice({
  name: REDUCER_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: {
    [fetchAllLabels.pending]: state => {
      state.isLoading = true;
    },
    [fetchAllLabels.fulfilled]: (state, action) => {
      const { labels, totalCount } = action.payload;

      state.isLoading = false;
      state.labels = labels;
      state.totalCount = totalCount;
    },
    [fetchAllLabels.rejected]: state => {
      state.isLoading = false;
    },
    [deleteLabel.pending]: state => {
      state.isLoading = true;
    },
    [deleteLabel.fulfilled]: state => {
      state.isLoading = false;
    },
    [deleteLabel.rejected]: state => {
      state.isLoading = false;
    },
    [triggerLabelsDetailsRefresh.type]: state => {
      state.labelsDetailsTrigger = !state.labelsDetailsTrigger;
    }
  }
});
