import type {
  GroupedOrgLabelsOption,
  OrgLabelFromResponse
} from 'shared/features/organizations/organizations.types';

export const mapActiveAsDisabled = (activeLabels: OrgLabelFromResponse[]) => (
  groupedOption: GroupedOrgLabelsOption
) => {
  const mappedOptions = groupedOption.options.map(option => {
    if (activeLabels.find(({ value: activeLabelId }) => activeLabelId === option.value)) {
      return { ...option, isDisabled: true };
    }
    return option;
  });

  return { ...groupedOption, options: mappedOptions };
};
