import { Modal } from '@compliance.ai/web-components';
import { Link } from 'react-router-dom';
import * as routes from 'constants/Routes';
import { usePermissions } from 'utils/hooks';
import { MANAGE_WORKFLOWS } from 'constants/Permissions';
import { useViewReduxActions } from 'shared/features/view/hooks';
import { SAVE_ALERT_SUCCESS_MODAL_TYPES } from 'shared/features/view/view.constants';
import { QUERY_PARAMS, TASKS_PAGE_VIEWS } from 'shared/features/workflow/workflow.constants';
import { formatRedirectUrl } from 'utils/url-utils';
import { useSaveSuccessModalData } from './hooks';
import './_save-alert-success-modal.scss';

export const SaveAlertSuccessModal = () => {
  const permissions = usePermissions();

  const { reduxState } = useSaveSuccessModalData();

  const viewReduxActions = useViewReduxActions();

  return (
    <Modal
      title={
        reduxState.alertSuccessSaveModalData.type === SAVE_ALERT_SUCCESS_MODAL_TYPES.CREATE
          ? "You've created a new alert"
          : "You've updated an alert"
      }
      isOpen={reduxState.alertSuccessSaveModalData.show}
      onClose={viewReduxActions.hideSaveAlertSuccessModal}
    >
      <div className="save-alert-success-modal__body">
        <div className="save-alert-success-modal__alert-name">
          <strong>{reduxState.alertSuccessSaveModalData.name}</strong>
        </div>
        {reduxState.alertSuccessSaveModalData.type === SAVE_ALERT_SUCCESS_MODAL_TYPES.CREATE && (
          <div className="save-alert-success-modal__text-wrapper">
            <div className="save-alert-success-modal__text">
              Share, monitor, and edit the notification settings on{' '}
              <span
                className="save-alert-success-modal__link"
                onClick={viewReduxActions.hideSaveAlertSuccessModal}
              >
                <Link to={routes.notifications}>Notifications</Link>
              </span>{' '}
              page.
            </div>
            {permissions[MANAGE_WORKFLOWS] && (
              <div className="save-alert-success-modal__text">
                You can use this alert to create a workflow.{' '}
                <span
                  className="save-alert-success-modal__link"
                  onClick={viewReduxActions.hideSaveAlertSuccessModal}
                >
                  <Link
                    to={formatRedirectUrl({
                      path: routes.tasks,
                      params: {
                        [QUERY_PARAMS.TASKS_PAGE_VIEW]: TASKS_PAGE_VIEWS.MANAGE_WORKFLOW
                      }
                    })}
                  >
                    GO TO MANAGE WORKFLOW
                  </Link>
                </span>
              </div>
            )}
          </div>
        )}
        {reduxState.alertSuccessSaveModalData.type === SAVE_ALERT_SUCCESS_MODAL_TYPES.MODIFY &&
          reduxState.isShowingWorkflowAlert && (
            <div className="save-alert-success-modal__text-wrapper">
              <div className="save-alert-success-modal__text">
                This alert is tied to a workflow.{' '}
                <span
                  className="save-alert-success-modal__link"
                  onClick={viewReduxActions.hideSaveAlertSuccessModal}
                >
                  <Link
                    to={formatRedirectUrl({
                      path: routes.tasks,
                      params: {
                        [QUERY_PARAMS.TASKS_PAGE_VIEW]: TASKS_PAGE_VIEWS.MANAGE_WORKFLOW
                      }
                    })}
                  >
                    GO TO MANAGE WORKFLOW
                  </Link>
                </span>
              </div>
            </div>
          )}
      </div>
    </Modal>
  );
};
