import type { ManageAnnotationsData } from './hooks/useManageAnnotationsData.types';
import type { Location } from 'react-router-dom';

import * as routerConstants from 'router/Router.constants';
import * as routes from 'constants/Routes';
import * as bucketsConstants from 'shared/features/buckets/buckets.constants';

import { matchPath } from 'react-router-dom';

export const getBucketAttributePageUrl = ({
  bucketId,
  attribute
}: {
  bucketId: ManageAnnotationsData['formattedData']['bucketId'];
  attribute: bucketsConstants.BUCKETS_ATTRIBUTE_TYPES;
}) => {
  if (!bucketId) {
    return `${routes.managementHub}/${attribute}`;
  }

  return `${routes.managementHub}/${bucketId}/${attribute}`;
};

export const isBucketLabelsPageOpened = (pathname: Location['pathname']) => {
  return (
    Boolean(matchPath(routerConstants.BUCKETS_PATHS.PUBLIC_BUCKET_LABELS, location.pathname)) ||
    Boolean(matchPath(routerConstants.BUCKETS_PATHS.PRIVATE_BUCKET_LABELS, pathname)) ||
    Boolean(matchPath(routerConstants.BUCKETS_PATHS.PUBLIC_BUCKET_LABEL_DETAILS, pathname)) ||
    Boolean(matchPath(routerConstants.BUCKETS_PATHS.PRIVATE_BUCKET_LABEL_DETAILS, pathname))
  );
};

export const isBucketAnnotationsPageOpened = (pathname: Location['pathname']) => {
  return (
    Boolean(
      matchPath(routerConstants.BUCKETS_PATHS.PUBLIC_BUCKET_ANNOTATIONS, location.pathname)
    ) || Boolean(matchPath(routerConstants.BUCKETS_PATHS.PRIVATE_BUCKET_ANNOTATIONS, pathname))
  );
};

export const isBucketObligationsPageOpened = (pathname: Location['pathname']) => {
  return (
    Boolean(
      matchPath(routerConstants.BUCKETS_PATHS.PUBLIC_BUCKET_OBLIGATIONS, location.pathname)
    ) || Boolean(matchPath(routerConstants.BUCKETS_PATHS.PRIVATE_BUCKET_OBLIGATIONS, pathname))
  );
};

export const isBucketWorkflowsPageOpened = (pathname: Location['pathname']) => {
  return (
    Boolean(matchPath(routerConstants.BUCKETS_PATHS.PUBLIC_BUCKET_WORKFLOWS, location.pathname)) ||
    Boolean(matchPath(routerConstants.BUCKETS_PATHS.PRIVATE_BUCKET_WORKFLOWS, pathname))
  );
};

export const isBucketDocumentsPageOpened = (pathname: Location['pathname']) => {
  return (
    Boolean(matchPath(routerConstants.BUCKETS_PATHS.PUBLIC_BUCKET_DOCUMENTS, location.pathname)) ||
    Boolean(matchPath(routerConstants.BUCKETS_PATHS.PRIVATE_BUCKET_DOCUMENTS, pathname))
  );
};

export const getBucketAttributeType = (pathname: Location['pathname']) => {
  switch (true) {
    case isBucketLabelsPageOpened(pathname): {
      return bucketsConstants.BUCKETS_ATTRIBUTE_TYPES.LABELS;
    }

    case isBucketAnnotationsPageOpened(pathname): {
      return bucketsConstants.BUCKETS_ATTRIBUTE_TYPES.ANNOTATIONS;
    }

    case isBucketObligationsPageOpened(pathname): {
      return bucketsConstants.BUCKETS_ATTRIBUTE_TYPES.OBLIGATIONS;
    }

    case isBucketWorkflowsPageOpened(pathname): {
      return bucketsConstants.BUCKETS_ATTRIBUTE_TYPES.WORKFLOWS;
    }

    case isBucketDocumentsPageOpened(pathname): {
      return bucketsConstants.BUCKETS_ATTRIBUTE_TYPES.DOCUMENTS;
    }

    default: {
      return null;
    }
  }
};
