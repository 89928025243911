import * as api from './timezones.api';

export const RECEIVE_TIMEZONES = 'RECEIVE_TIMEZONES';

export const updateTimeZones = timezones => ({
  type: RECEIVE_TIMEZONES,
  payload: timezones
});

export const fetchTimezones = () => async dispatch => {
  const timezones = await api.getTimezones();
  dispatch(updateTimeZones(timezones));
};
