import type { EitlLabelsSelectProps } from '../EitlLabelsSelect.types';
import type { EitlLabelsSelectData } from './useEitlLabelsSelectData.types';
import type { EitlLabelsSelectHandlers } from './useEitlLabelsSelectHandlers.types';

import * as errorUtils from 'utils/errors';
import * as constants from '../EitlLabelsSelect.constants';
import * as editLabelsApi from 'shared/features/eitlLabels/eitlLabels.api';
import * as eitlLabelsApiHelpers from 'shared/features/eitlLabels/eitlLabels.api.helpers';
import * as helpers from '../EitlLabelsSelect.helpers';

export const useEitlLabelsSelectHandlers = ({
  props,
  localActions
}: {
  props: Pick<EitlLabelsSelectProps, 'shouldShowNoLabelsOption'>;
  localActions: EitlLabelsSelectData['localActions'];
}): EitlLabelsSelectHandlers => {
  const handleEitlLabelsFetch: EitlLabelsSelectHandlers['handleEitlLabelsFetch'] = async inputValue => {
    try {
      localActions.setIsLoading(true);

      const response = await editLabelsApi.getEitlLabels({
        name: inputValue.toLowerCase(),
        limit: constants.EITL_LABELS_COUNT_PER_REQUEST
      });

      const options = eitlLabelsApiHelpers.formatEitlLabelsOptions(response);

      return helpers.addNoEitlLabelsOption({
        options,
        shouldShowNoLabelsOption: Boolean(props.shouldShowNoLabelsOption)
      });
    } catch (e) {
      errorUtils.logError(e as Error);

      return [];
    } finally {
      localActions.setIsLoading(false);
    }
  };

  return {
    handleEitlLabelsFetch
  };
};
