export const useCorporateLoginWindowedFormHandlers = ({ props, reduxActions }) => {
  const handleCorporateLoginError = e => {
    props.onError(e.message);
  };

  const handleCorporateLoginSuccess = async code => {
    await reduxActions.authenticateUsingCognitoCode({
      code: code,
      orgInvitationToken: props.orgInvitationToken
    });

    props.onSuccess();
  };

  return {
    handleCorporateLoginSuccess,
    handleCorporateLoginError
  };
};
