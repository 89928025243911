import type { GlobalState } from 'shared/reducers';
import type { DocumentDetailsContentData } from './useDocumentDetailsContentData.types';

import * as rightPanelSelectors from 'shared/features/rightPanel/rightPanel.selectors';

import { useSelector } from 'react-redux';

export const useDocumentDetailsContentData = (): DocumentDetailsContentData => {
  const reduxState = useSelector<GlobalState, DocumentDetailsContentData['reduxState']>(state => ({
    documentDetailsActiveTabKey: rightPanelSelectors.getDocumentDetailsActiveTabKey(state)
  }));

  return {
    reduxState
  };
};
