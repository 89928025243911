import type { ApplicabilityDateProps } from './ApplicabilityDate.types';
import type { FC } from 'react';

import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';
import { useApplicabilityDateData } from './hooks';
import { useResourceDocumentCheck } from 'utils/hooks';

export const ApplicabilityDate: FC<ApplicabilityDateProps> = ({
  document,
  shouldRenderForEmail
}) => {
  const isResourceDocument = useResourceDocumentCheck(document);

  const { formattedData } = useApplicabilityDateData({ document });

  if (isResourceDocument || shouldRenderForEmail || !formattedData.applicabilityDate?.length) {
    return null;
  }

  return (
    <>
      <FieldName>Applicability Date</FieldName>
      <FieldValue>{formattedData.applicabilityDate}</FieldValue>
    </>
  );
};
