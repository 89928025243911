import * as uiLib from '@compliance.ai/web-components';
import * as documentPropertiesConstants from 'constants/DocumentProperties';
import * as constants from './ExportModal.constants';
import { useExportModalData, useExportModalHandlers } from './hooks';
import './_export-modal.scss';

export const ExportModal = ({
  documentsCount,
  contentType,
  onExport,
  onClose,
  isAnnotationExport = false,
  annotationCount
}) => {
  const { localState, localActions, formattedData } = useExportModalData({
    props: { documentsCount, contentType, annotationCount, isAnnotationExport }
  });

  const handlers = useExportModalHandlers({
    props: { contentType, onExport, onClose },
    localState,
    localActions,
    formattedData
  });

  return (
    <uiLib.Modal
      title={formattedData.title}
      className="document-export-modal__container"
      isOpen
      onClose={onClose}
      childContainerClassName="document-export-modal-child__container"
    >
      <div className="document-export-modal__header">{formattedData.headerText}</div>

      <div className="document-export-modal__body">
        <div className="document-export-modal__body-section all-buttons-section">
          <uiLib.Button type={uiLib.BUTTON_TYPES.LINK} onClick={handlers.handleSelectAll}>
            Select All
          </uiLib.Button>
          <uiLib.Button type={uiLib.BUTTON_TYPES.LINK} onClick={handlers.handleClearAll}>
            Clear All
          </uiLib.Button>
        </div>

        <div className="document-export-modal__body-section export-columns-section">
          {formattedData.basicColumnsToDisplay.map((sectionColumns, sectionIndex) => (
            <div className="document-export-modal__body-section-block" key={sectionIndex}>
              {sectionColumns.map(column => (
                <div key={sectionIndex + column} className="document-export-modal__checkbox">
                  <uiLib.Checkbox
                    isChecked={localState.selectedColumns.includes(column)}
                    label={column}
                    onChange={handlers.handleColumnSelectChange(column)}
                  />
                </div>
              ))}

              {sectionIndex === 2 && !isAnnotationExport && !Boolean(localState.exportMode) && (
                <div className="document-export-modal__checkbox">
                  <uiLib.Checkbox
                    label={documentPropertiesConstants.SENTENCES_KEY}
                    onChange={handlers.handleExportModeChange(
                      documentPropertiesConstants.SENTENCES_KEY
                    )}
                  />
                </div>
              )}
            </div>
          ))}
        </div>

        {Boolean(localState.exportMode) && !isAnnotationExport && (
          <>
            <div className="document-export-modal__body-section comments-section">
              You can receive additional data fields for sentencified documents. If you want to
              receive each sentence and related data in individual rows, choose "Sentences",. If you
              want to receive an annotation-focused export that includes groups of sentences with
              annotations, choose "Annotations"
            </div>

            <div className="document-export-modal__body-section options-section">
              {constants.ADDITIONAL_COLUMNS_CHECKS.map(key => (
                <uiLib.Radio
                  label={key}
                  key={key}
                  isChecked={localState.exportMode === key}
                  onChange={handlers.handleExportModeChange(key)}
                />
              ))}
            </div>

            {localState.exportMode === documentPropertiesConstants.SENTENCES_KEY && (
              <div className="document-export-modal__body-section export-columns-section">
                {[constants.SENTENCES_COLUMNS_ONE, constants.SENTENCES_COLUMNS_TWO].map(
                  (sectionColumns, sectionIndex) => (
                    <div className="document-export-modal__body-section-block" key={sectionIndex}>
                      {sectionColumns.map(column => (
                        <div
                          key={sectionIndex + column}
                          className="document-export-modal__checkbox"
                        >
                          <uiLib.Checkbox
                            isChecked={localState.selectedColumns.includes(column)}
                            label={column}
                            onChange={handlers.handleColumnSelectChange(column)}
                          />
                        </div>
                      ))}
                    </div>
                  )
                )}
              </div>
            )}

            {localState.exportMode === documentPropertiesConstants.ANNOTATIONS_KEY && (
              <div>
                <div className="document-export-modal__body-section export-columns-section">
                  <div className="document-export-modal__body-section-single">
                    <div className="document-export-modal__checkbox">
                      <uiLib.Checkbox
                        isChecked={localState.selectedColumns.includes(
                          documentPropertiesConstants.ANNOTATION_SINGLE_FILE
                        )}
                        label={'Export in a single file (includes annotated sentence only)'}
                        onChange={handlers.handleColumnSelectChange(
                          documentPropertiesConstants.ANNOTATION_SINGLE_FILE
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="document-export-modal__body-section export-columns-section">
                  {[constants.ANNOTATIONS_COLUMNS_ONE, constants.ANNOTATIONS_COLUMNS_TWO].map(
                    (sectionColumns, sectionIndex) => (
                      <div className="document-export-modal__body-section-block" key={sectionIndex}>
                        {sectionColumns.map(column => (
                          <div
                            key={sectionIndex + column}
                            className="document-export-modal__checkbox"
                          >
                            <uiLib.Checkbox
                              isChecked={localState.selectedColumns.includes(column)}
                              label={column}
                              onChange={handlers.handleColumnSelectChange(column)}
                            />
                          </div>
                        ))}
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </>
        )}

        {isAnnotationExport && (
          <>
            <div className="document-export-modal__body-section comments-section">
              The following data fields provide annotation-focused information, including groups of
              sentences with annotations and annotation information including attachments, labels
              and comments.
            </div>
            <div className="document-export-modal__body-section export-columns-section">
              {[constants.ANNOTATIONS_COLUMNS_ONE, constants.ANNOTATIONS_COLUMNS_TWO].map(
                (sectionColumns, sectionIndex) => (
                  <div className="document-export-modal__body-section-block" key={sectionIndex}>
                    {sectionColumns.map(column => (
                      <div key={sectionIndex + column} className="document-export-modal__checkbox">
                        <uiLib.Checkbox
                          isChecked={localState.selectedColumns.includes(column)}
                          label={column}
                          onChange={handlers.handleColumnSelectChange(column)}
                        />
                      </div>
                    ))}
                  </div>
                )
              )}
            </div>
          </>
        )}
      </div>

      <div className="document-export-modal__footer">
        <uiLib.Button type={uiLib.BUTTON_TYPES.LINK} onClick={onClose}>
          Cancel
        </uiLib.Button>
        <uiLib.Button
          type={uiLib.BUTTON_TYPES.PRIMARY}
          onClick={handlers.handleExport}
          isDisabled={formattedData.isExportDisabled}
        >
          Export
        </uiLib.Button>
      </div>
    </uiLib.Modal>
  );
};
