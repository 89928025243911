import { safe_analytics } from 'utils/analytics';

export const usePremiumContentUnlockButtonHandlers = ({
  props,
  localActions,
  reduxState,
  userReduxActions
}) => {
  const handleModalOpen = () => {
    safe_analytics('Premium Content', 'click on locked content', 'click on locked premium content');
    localActions.setIsOpen(true);
  };

  const handleModalClose = () => {
    localActions.setIsOpen(false);
    localActions.setDidTryToAuth(false);
  };

  const handlePremiumContentUnlock = async e => {
    try {
      e.preventDefault();
      localActions.setIsLoading(true);

      await userReduxActions.unlockPremiumContent({
        email: reduxState.currentUserEmail,
        sourceId: props.sourceId,
        documentId: props.document.id
      });

      props.onSuccess && props.onSuccess();

      localActions.setDidTryToAuth(true);
    } catch (e) {
      props.onError && props.onError(e);
    } finally {
      localActions.setIsLoading(false);
    }
  };

  return {
    handleModalOpen,
    handleModalClose,
    handlePremiumContentUnlock
  };
};
