import type { CreateLabelModalHandlers } from './useCreateLabelModalHandlers.types';
import type {
  CreateLabelModalData,
  CreateLabelModalLocalState
} from './useCreateLabelModalData.types';

export const useCreateLabelModalHandlers = ({
  localActions
}: {
  localActions: CreateLabelModalData['localActions'];
}): CreateLabelModalHandlers => {
  const handleLabelNameChange: CreateLabelModalHandlers['handleLabelNameChange'] = labelName => {
    localActions.setLabelName(labelName);
  };

  const handleLabelDescriptionChange: CreateLabelModalHandlers['handleLabelDescriptionChange'] = labelDescription => {
    localActions.setLabelDescription(labelDescription);
  };

  const handleLabelExternalIdChange: CreateLabelModalHandlers['handleLabelExternalIdChange'] = labelExternalId => {
    localActions.setLabelExternalId(labelExternalId);
  };

  const handleBucketChange: CreateLabelModalHandlers['handleBucketChange'] = bucket => {
    localActions.setBucket(bucket as CreateLabelModalLocalState['bucket']);
  };

  const handleLabelTypeChange: CreateLabelModalHandlers['handleLabelTypeChange'] = labelType => {
    localActions.setLabelType(labelType as CreateLabelModalLocalState['labelType']);
  };

  return {
    handleLabelNameChange,
    handleBucketChange,
    handleLabelDescriptionChange,
    handleLabelTypeChange,
    handleLabelExternalIdChange
  };
};
