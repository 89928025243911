import type { MomentInput } from 'moment';

import * as uiLib from '@compliance.ai/web-components';
import * as dateUtils from 'utils/date-utils';

export const getDate = ({
  amount,
  unitOfTime,
  baseDate,
  shouldUseEndOfUnit = false
}: {
  amount: number;
  unitOfTime: uiLib.DATE_UNITS;
  baseDate: MomentInput;
  shouldUseEndOfUnit?: boolean;
}): string => {
  const startOrEndGetter = shouldUseEndOfUnit
    ? dateUtils.getEndOfUnitDate
    : dateUtils.getStartOfUnitDate;

  return dateUtils.formatDate(
    startOrEndGetter({
      date: dateUtils.getDateBeforeDate({
        date: baseDate,
        amount: amount,
        unitOfTime: unitOfTime,
        shouldIgnoreTimezone: true
      }),
      unitOfTime: uiLib.DATE_UNITS.DAY
    }),
    uiLib.DATE_FORMATS.API_DATE_TIME
  );
};

export const getPreviousDates = ({
  startDateAmounts,
  endDateAmounts,
  unitOfTime = uiLib.DATE_UNITS.DAY,
  baseDate = new Date()
}: {
  startDateAmounts: number[];
  endDateAmounts: number[];
  unitOfTime?: uiLib.DATE_UNITS;
  baseDate?: MomentInput;
}): {
  startDate: string;
  endDate: string;
  label: string;
}[] => {
  const startDates = startDateAmounts.map(amount =>
    getDate({
      amount,
      unitOfTime,
      baseDate
    })
  );

  const endDates = endDateAmounts.map(amount =>
    getDate({
      amount,
      unitOfTime,
      baseDate,
      shouldUseEndOfUnit: true
    })
  );

  if (startDates.length !== endDates.length) {
    return [];
  }

  return startDates.map((startDate, i) => ({
    /**
     * Swap dates to correctly reference them in query params
     * (e.g. published_from should be less than published_to)
     */
    startDate: endDates[i],
    endDate: startDate,
    label:
      {
        [uiLib.DATE_UNITS.QUARTER]: `${startDates.length - i} Quarter`,
        [uiLib.DATE_UNITS.MONTH]: dateUtils.formatDate(
          endDates[i],
          uiLib.DATE_FORMATS.HUMANIZED_MONTH_YEAR
        )
      }[unitOfTime as string] ?? startDate
  }));
};
