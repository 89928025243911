import type { FC } from 'react';
import type { DocDiffPrintButtonProps } from './DocDiffPrintButton.types';

import * as uiLib from '@compliance.ai/web-components';
import * as common from 'common/index';

import { useDocDiffPrintButtonData, useDocDiffPrintButtonHandlers } from './hooks';
import { useDocDiff } from 'shared/features/documents/hooks';

import './_document-diff-button.scss';

export const DocDiffPrintButton: FC<DocDiffPrintButtonProps> = ({ classNames, tooltip }) => {
  const { formattedData } = useDocDiffPrintButtonData();

  const docsDiff = useDocDiff({ docIdsToCompare: formattedData.docIdsToCompare });

  const handlers = useDocDiffPrintButtonHandlers({
    docsDiff
  });

  return (
    <>
      <uiLib.ActionButton
        isDisabled={!docsDiff.isReady}
        onClick={handlers.handlePrint}
        tooltip={tooltip || 'Print'}
        tooltipPlacement={uiLib.TOOLTIP_POSITION.BOTTOM}
        type={uiLib.ACTION_BUTTON_TYPE.TRANSPARENT_CONTRAST}
        classNames={{
          button: classNames?.button,
          tooltipWrapper: classNames?.tooltipWrapper
        }}
      >
        <uiLib.IconPrint />
      </uiLib.ActionButton>
      <common.DocumentsDiff diff={docsDiff.diff} className="document-diff-button__result-print" />
    </>
  );
};
