import type { FilterColumnProps } from './FilterColumn.types';
import type { FC } from 'react';

import classnames from 'classnames';

import './_filter-column.scss';

export const TEST_ID = 'FilterColumn';

const FilterColumn: FC<FilterColumnProps> = ({ className, children }) => {
  return (
    <div data-testid={TEST_ID} className={classnames(className, 'filter-column')}>
      {children}
    </div>
  );
};

export default FilterColumn;
