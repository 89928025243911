import type { FC } from 'react';
import type { DocumentsTabsProps } from './DocumentsTabs.types';

import * as uiLib from '@compliance.ai/web-components';

import './_documents-tabs.scss';

export const DocumentsTabs: FC<DocumentsTabsProps> = ({ activeTabIndex, tabs, onTabChange }) => {
  return (
    <uiLib.Tabs
      activeTabIndex={activeTabIndex}
      tabs={tabs?.map(tab => ({
        ...tab,
        content: null
      }))}
      onSelect={onTabChange}
      classNames={{
        container: 'documents-tabs__container',
        tabContent: 'documents-tabs__content',
        tabsBar: 'documents-tabs__tabs-bar',
        tabTitle: 'documents-tabs__tab-title'
      }}
    />
  );
};
