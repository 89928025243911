import type { LabelsProps } from './Labels.types';
import type { FC } from 'react';

import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';
import { DocumentLabels } from '../../../../DocumentLabels';

export const Labels: FC<LabelsProps> = ({ document }) => {
  return (
    <>
      <FieldName>Document Labels</FieldName>
      <FieldValue>
        <DocumentLabels docId={document.id} />
      </FieldValue>
    </>
  );
};
