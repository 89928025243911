import type { FilterBodyProps } from './FilterBody.types';
import type { FC } from 'react';

import classnames from 'classnames';

import './_filter-body.scss';

export const TEST_ID = 'FilterBody';

const FilterBody: FC<FilterBodyProps> = ({ className, children }) => {
  return (
    <div data-testid={TEST_ID} className={classnames(className, 'filter-body')}>
      {children}
    </div>
  );
};

export default FilterBody;
