const ALL = 'All';
const ANY = 'Any';
const RELEVANT = 'Relevant';
const NOT_RELEVANT = 'Not Relevant';

export const DEPENDENT_ON_OPTIONS = [
  {
    label: ALL,
    value: true
  },
  {
    label: ANY,
    value: false
  }
];

export const RELEVANT_OPTIONS = [
  {
    label: RELEVANT,
    value: true
  },
  {
    label: NOT_RELEVANT,
    value: false
  }
];
