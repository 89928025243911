import type { SelectProps } from './Select.types';

import { Provider } from 'react-redux';
import * as webComponents from '@compliance.ai/web-components';
import * as constants from './Select.constants';
import { store } from 'store';
import { useSelectHandlers } from './hooks';

export const Select = ({
  listValues,
  value,
  setValue,
  field,
  isMulti,
  withTooltips,
  withValueTooltips
}: SelectProps) => {
  const handlers = useSelectHandlers({
    props: {
      setValue,
      isMulti
    }
  });

  const { Component, props } = constants.CUSTOM_SELECT_COMPONENTS[field] ?? {
    Component: webComponents.Select,
    props: {}
  };

  return (
    <Provider store={store}>
      <Component
        {...props}
        type={webComponents.SELECT_TYPES.DEFAULT}
        options={listValues}
        // FIXME Figure out the correct type
        value={value as never}
        onChange={handlers.handleChange}
        isClearable
        className="advanced-search-builder-select"
        isMulti={isMulti}
        withTooltips={withTooltips}
        withValueTooltips={withValueTooltips}
        shouldCloseMenuOnSelect={!constants.MULTI_SELECTION_WITHOUT_CLOSE[field]}
      />
    </Provider>
  );
};
