import type {
  WorkflowModalData,
  WorkflowModalLocalActions,
  WorkflowModalLocalState
} from './useWorkflowModalData.types';
import type { WorkflowModalFormattedData } from './useWorkflowModalData.types';

import { useMemo, useState } from 'react';

export const useWorkflowModalData = (): WorkflowModalData => {
  const [selectedWorkflowOption, setSelectedWorkflowOption] = useState<
    WorkflowModalData['localState']['selectedWorkflowOption']
  >(null);

  const [preDocumentTasks, setPreDocumentTasks] = useState<
    WorkflowModalData['localState']['preDocumentTasks']
  >({});

  const [isLoading, setIsLoading] = useState<WorkflowModalData['localState']['isLoading']>(false);

  const localState: WorkflowModalLocalState = {
    selectedWorkflowOption,
    preDocumentTasks,
    isLoading
  };

  const localActions: WorkflowModalLocalActions = useMemo(
    () => ({
      setSelectedWorkflowOption,
      setPreDocumentTasks,
      setIsLoading
    }),
    []
  );

  const formattedData: WorkflowModalFormattedData = {
    selectedWorkflowId: selectedWorkflowOption?.value ?? null,
    isCreateTasksButtonDisabled: !selectedWorkflowOption || isLoading
  };

  return {
    localState,
    localActions,
    formattedData
  };
};
