import type * as apiTypes from './resources.api.types';

import { fetch } from 'fetch';

export const getResources: apiTypes.GetResourcesHeadDocs = () => {
  return fetch({
    url: '/resources',
    method: 'GET',
    params: {
      jurisdictions: []
    }
  });
};

export const getResource: apiTypes.GetResource = ({ docId }) => {
  return fetch({
    url: `/resource/${docId}`,
    method: 'GET'
  });
};

export const getResourceHierarchy: apiTypes.GetResourceHierarchy = ({ docId }) => {
  return fetch({
    url: `/resource_hierarchy/${docId}`,
    method: 'GET'
  });
};

export const getResourceHierarchyV2: apiTypes.GetResourceHierarchyV2 = ({ docId }) => {
  return fetch({
    url: `/v2/resource_hierarchy/${docId}`,
    method: 'GET'
  });
};

export const getDocumentShelves: apiTypes.GetDocumentShelves = params => {
  return fetch({
    url: '/document_shelves',
    method: 'GET',
    params
  });
};
