import type { AutosuggestionSelectProps } from './AutosuggestionSelect.types';
import type { FC } from 'react';

import { Select, SELECT_TYPES } from '@compliance.ai/web-components';
import {
  useAutosuggestionSelectData,
  useAutosuggestionSelectHandlers,
  useAutosuggestionSelectLifecycle
} from './hooks';

export const AutosuggestionSelect: FC<AutosuggestionSelectProps> = ({
  onChange,
  category,
  value,
  type
}) => {
  const { localState, localActions } = useAutosuggestionSelectData();

  const handlers = useAutosuggestionSelectHandlers({
    props: {
      value,
      category
    },
    localActions
  });

  useAutosuggestionSelectLifecycle({
    onValuePropChange: handlers.handleValuePropChange
  });

  return (
    <Select
      type={type ?? SELECT_TYPES.DEFAULT}
      className="advanced-search-builder-select"
      value={localState.value}
      isClearable
      isLoading={localState.isLoading}
      isMulti
      isAsync
      hideSelectedOptions
      onChange={onChange}
      loadOptions={handlers.handleAutocompleteFetch}
    />
  );
};
