import type { DocumentTypeData } from './useDocumentTypeData.types';
import type { GlobalState } from 'shared/reducers';
import type { DocumentTypeProps } from '../DocumentType.types';

import { useSelector } from 'react-redux';
import { getDocumentCategoryName } from 'shared/features/documents/documents.selectors';

export const useDocumentTypeData = ({
  document
}: Pick<DocumentTypeProps, 'document'>): DocumentTypeData => {
  const reduxState = useSelector<GlobalState, DocumentTypeData['reduxState']>(state => ({
    // TODO Uncomment when document selectors will be moved to TS
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    categoryName: getDocumentCategoryName(state, document.cai_category_id)
  }));

  return {
    reduxState
  };
};
