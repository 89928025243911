import {
  REQUEST_TOPICS,
  RECEIVE_TOPICS,
  REQUEST_TOPICS_STATS,
  RECEIVE_TOPICS_STATS,
  RECEIVE_DOC_COUNTS_FOR_TOPICS
} from './topics.actions';

export const getInitialTopicsState = () => ({
  isFetching: false,
  isReady: false,
  followed_topics: [],
  followedTopicsObj: {},
  docCountsTopicsObj: {}
});

export const topics = (state = getInitialTopicsState(), action) => {
  switch (action.type) {
    case REQUEST_TOPICS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_TOPICS: {
      const followed_topics = action.topics;
      const followedTopicsObj = followed_topics.reduce((obj, topic) => {
        obj[topic.topic_id] = topic;
        return obj;
      }, {});

      return {
        ...state,
        isFetching: false,
        isReady: true,
        followed_topics,
        followedTopicsObj
      };
    }
    case RECEIVE_DOC_COUNTS_FOR_TOPICS: {
      const docCountsTopicsObj = action.docCountsTopicsObj;

      return {
        ...state,
        docCountsTopicsObj
      };
    }
    default:
      return state;
  }
};

export const getInitialTopicsStatsState = () => ({
  isFetching: false,
  isReady: false,
  items: []
});

export const topics_stats = (state = getInitialTopicsStatsState(), action) => {
  switch (action.type) {
    case REQUEST_TOPICS_STATS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_TOPICS_STATS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.topics_stats
      };
    default:
      return state;
  }
};

export const getInitialTopicAnnotationModelValidationsState = () => ({
  isFetching: false,
  isReady: false,
  items: []
});
