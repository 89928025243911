import type { AlertsSelectProps } from './AlertsSelect.types';
import type { SelectProps } from '@compliance.ai/web-components';

import React, { memo } from 'react';

import * as uiLib from '@compliance.ai/web-components';

import { useAlertsSelectData, useAlertsSelectHandlers, useAlertsSelectQueries } from './hooks';

export const AlertsSelect = memo((props: AlertsSelectProps) => {
  const { localState, localActions } = useAlertsSelectData();

  const queries = useAlertsSelectQueries({
    props,
    localActions
  });

  const handlers = useAlertsSelectHandlers({
    props
  });

  return (
    <uiLib.Select
      {...props}
      value={localState.value}
      isLoading={props.isLoading || queries.fetchPropsValueQuery.isFetching}
      onChange={props.onChange as SelectProps['onChange']}
      type={props.type || uiLib.SELECT_TYPES.DEFAULT}
      withTooltips
      isAsync
      isMulti
      shouldCacheFetchedOptions
      noOptionsMessage={inputValue => (inputValue ? 'No options' : 'Type to search')}
      loadOptions={handlers.handleAlertsFetch}
    />
  );
});
