import moment from 'moment';

export const FILTER_KEY = {
  FEDERAL_SOURCE: 'federalSource',
  JURISDICTION: 'jurisdiction',
  TOPIC_ID: 'topic_id',
  PENALTY_AMOUNT: 'penaltyAmount',
  PENALTY_LIMIT: 'penaltyLimit',
  PUBLISHED_FROM: 'published_from',
  PUBLISHED_TO: 'published_to',
  HAS_UNOFFICIAL_PUBLICATION_DATE: 'has_unofficial_publication_date',
  VIOLATION: 'violation',
  RESPONDENT: 'respondent',
  RESPONDENT_TYPE: 'respondentType',
  LANGUAGE: 'language',
  CONCEPTS: 'concepts'
};

export const QUERY_PARAMS = {
  AGENCY: 'agency',
  AGENCY_ID: 'agency_id',
  CATEGORY: 'category',
  CAI_CATEGORY: 'cai_category',
  VIOLATION_QUERY: 'violation_query',
  TOPIC_ID: 'topic_id',
  LANGUAGE_ID: 'language_id',
  PUBLISHED_FROM: 'published_from',
  PUBLISHED_TO: 'published_to',
  HAS_UNOFFICIAL_PUBLICATION_DATE: 'has_unofficial_publication_date',
  JURISDICTION: 'jurisdiction',
  HAS_INDIVIDUAL_RESPONDENT: 'has_individual_respondent',
  HAS_ENTITY_RESPONDENT: 'has_entity_respondent',
  MONETARY_PENALTY: 'monetary_penalty',
  MONETARY_PENALTY_MIN: 'monetary_penalty_min',
  MONETARY_PENALTY_MAX: 'monetary_penalty_max',
  RESPONDENT: 'respondent',
  EXCLUSIVE_FIELDS: 'exclusive_fields',
  SEARCH_QUERY: 'search_query',
  ORDER: 'order',
  SEARCH_SORT: 'search_sort',
  LIMIT: 'limit',
  TOPIC_THRESHOLD_KEY: 'topic_threshold_key',
  DEFAULT_VIEW: 'default_view'
};

export const PENALTY_OPTIONS = [
  { label: 'Greater than', value: QUERY_PARAMS.MONETARY_PENALTY_MIN },
  { label: 'Equal to', value: QUERY_PARAMS.MONETARY_PENALTY },
  { label: 'Less than', value: QUERY_PARAMS.MONETARY_PENALTY_MAX }
];

export const RESPONDENT_TYPE_OPTIONS = [
  { label: 'Entity', value: QUERY_PARAMS.HAS_ENTITY_RESPONDENT },
  { label: 'Individual', value: QUERY_PARAMS.HAS_INDIVIDUAL_RESPONDENT }
];

export const DEFAULT_STATE = {
  [FILTER_KEY.FEDERAL_SOURCE]: [],
  [FILTER_KEY.JURISDICTION]: [],
  [FILTER_KEY.TOPIC_ID]: [],
  [FILTER_KEY.LANGUAGE]: null,
  [FILTER_KEY.PENALTY_AMOUNT]: undefined,
  [FILTER_KEY.PENALTY_LIMIT]: PENALTY_OPTIONS[0],
  [FILTER_KEY.PUBLISHED_FROM]: moment().subtract(30, 'days'),
  [FILTER_KEY.PUBLISHED_TO]: null,
  [FILTER_KEY.HAS_UNOFFICIAL_PUBLICATION_DATE]: false,
  [FILTER_KEY.VIOLATION]: '',
  [FILTER_KEY.RESPONDENT]: '',
  [FILTER_KEY.RESPONDENT_TYPE]: ''
};

export const EXPECTED_QUERY_PARAMS = [
  QUERY_PARAMS.AGENCY,
  QUERY_PARAMS.VIOLATION_QUERY,
  QUERY_PARAMS.TOPIC_ID,
  QUERY_PARAMS.LANGUAGE_ID,
  QUERY_PARAMS.PUBLISHED_FROM,
  QUERY_PARAMS.PUBLISHED_TO,
  QUERY_PARAMS.JURISDICTION,
  QUERY_PARAMS.HAS_INDIVIDUAL_RESPONDENT,
  QUERY_PARAMS.HAS_ENTITY_RESPONDENT,
  QUERY_PARAMS.MONETARY_PENALTY,
  QUERY_PARAMS.MONETARY_PENALTY_MIN,
  QUERY_PARAMS.MONETARY_PENALTY_MAX,
  QUERY_PARAMS.RESPONDENT,
  QUERY_PARAMS.SEARCH_QUERY
];
