import { RESTRICTION_MODAL_TYPES } from '../../RestrictModal.constants';

export const ACTION_STATUSES = {
  EMPTY: 'empty',
  SUCCESS: 'success',
  ERROR: 'error'
};

export const EVENT_CATEGORIES = {
  [RESTRICTION_MODAL_TYPES.AUTOTRANSLATE]: 'Auto-Translate Access Request',
  [RESTRICTION_MODAL_TYPES.JURISDICTION_CONTENT]: 'Jurisdiction Content Access Request',
  [RESTRICTION_MODAL_TYPES.ENFORCEMENT_EXPLORER]: 'Enforcement Explorer Access Request'
};

export const ACTION_SUCCESS_MESSAGE = {
  [RESTRICTION_MODAL_TYPES.AUTOTRANSLATE]:
    'Your request for information about auto-translation has been submitted. We will contact you soon.',
  [RESTRICTION_MODAL_TYPES.JURISDICTION_CONTENT]:
    'Your request for information about jurisdictional content has been submitted. We will contact you soon.',
  [RESTRICTION_MODAL_TYPES.ENFORCEMENT_EXPLORER]:
    'Your request for information about enforcement explorer has been submitted. We will contact you soon.'
};

export const ACTION_ERROR_MESSAGE = 'Somethine went wrong, please try again later';
