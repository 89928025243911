import lodash from 'lodash';

import {
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
  DEFAULT_ORDER_OPTION,
  DEFAULT_SORT_OPTION,
  MAX_DOCUMENTS_SHOW,
  ORDER_OPTIONS,
  SORT_OPTIONS,
  VIEW_TYPE_DATE,
  VIEW_TYPE_NEWS,
  VIEW_TYPE_PAGE
} from 'constants/ActionBar';

import * as searchUtils from 'utils/search';
import * as numberUtils from 'utils/number';
import * as primaryFilterConstants from 'constants/PrimaryFilter';

export const getPage = ({ offset, limit }) => {
  return Math.floor((offset + limit) / limit);
};

export const getOffsetParams = ({
  page,
  limit,
  localStatePage,
  localStateLimit,
  documentsOffsets,
  isPreviousDocs = false
}) => {
  const params = {};

  const offsets = { ...documentsOffsets };

  // user hits paginate back button
  if (isPreviousDocs) {
    params.previous_docs = true;
  }

  // user hits paginate back button or changes limit (rows per page)
  // TODO Wtf is this logic? Do we really need it?
  if (page - localStatePage < 0 || limit !== localStateLimit) {
    offsets.pub_date_offset -= offsets.pub_date_count;
    offsets.effective_on_date_offset -= offsets.effective_on_count;
    offsets.comments_close_date_offset -= offsets.comments_close_count;

    offsets.pub_date_offset = Math.max(offsets.pub_date_offset, 0);
    offsets.effective_on_date_offset = Math.max(offsets.effective_on_date_offset, 0);
    offsets.comments_close_date_offset = Math.max(offsets.comments_close_date_offset, 0);
  }

  return {
    ...params,
    ...offsets
  };
};

export const getPageByLimitAndOffset = ({ offset, limit }) => {
  const page = parseInt(offset, 10) === 0 ? 1 : parseInt(offset / limit, 10) + 1;
  return isNaN(page) ? 0 : page;
};

export const getInitialPage = queryParams => {
  const limit = queryParams[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.LIMIT] || DEFAULT_LIMIT;
  const offset =
    queryParams[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.OFFSET] || DEFAULT_OFFSET;

  return getPageByLimitAndOffset({
    offset,
    limit
  });
};

export const getInitialLimit = queryParams => {
  return queryParams[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.LIMIT] || DEFAULT_LIMIT;
};

export const getInitialOrderOption = ({ currentUserProperties, queryParams }) => {
  const order =
    queryParams[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.ORDER] || currentUserProperties.order;

  return ORDER_OPTIONS.find(({ value }) => value === order) || DEFAULT_ORDER_OPTION;
};

export const getInitialSortOption = ({ currentUserProperties, queryParams }) => {
  const searchSort =
    queryParams[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.SEARCH_SORT] ||
    currentUserProperties.search_sort;

  return SORT_OPTIONS.find(({ value }) => value === searchSort) || DEFAULT_SORT_OPTION;
};

export const getStartDateOffset = ({ offsets, offset = DEFAULT_OFFSET }) => {
  // HACK: There are many document stores and it's not clear where the offsets should be stored
  if (!offsets) {
    return offset || DEFAULT_OFFSET;
  }

  return (
    Math.max(offsets.pub_date_offset - offsets.pub_date_count, DEFAULT_OFFSET) +
    Math.max(offsets.effective_on_date_offset - offsets.effective_on_count, DEFAULT_OFFSET) +
    Math.max(offsets.comments_close_date_offset - offsets.comments_close_count, DEFAULT_OFFSET) +
    1
  );
};

export const getEndDateOffset = ({ offsets, offset = DEFAULT_OFFSET }) => {
  // HACK: There are many document stores and it's not clear where the offsets should be stored
  if (!offsets) {
    return offset || DEFAULT_OFFSET;
  }

  return (
    Math.max(offsets.pub_date_offset, DEFAULT_OFFSET) +
    Math.max(offsets.effective_on_date_offset, DEFAULT_OFFSET) +
    Math.max(offsets.comments_close_date_offset, DEFAULT_OFFSET)
  );
};

export const getViewType = (showWithCombinedDocs, section) => {
  if (showWithCombinedDocs) {
    return VIEW_TYPE_DATE;
  }

  if (section === searchUtils.APP_VIEW_SECTION_NEWS) {
    return VIEW_TYPE_NEWS;
  }

  return VIEW_TYPE_PAGE;
};

export const formatNumberWithCommas = numberUtils.formatNumberWithCommas;

export const getDecoratedCount = ({ offset, limit, totalCount }) => {
  return {
    totalCount: formatNumberWithCommas(lodash.min([totalCount, MAX_DOCUMENTS_SHOW])),
    fromCount: formatNumberWithCommas(lodash.min([offset + 1, totalCount])),
    toCount: formatNumberWithCommas(lodash.min([offset + limit, totalCount]))
  };
};

/**
 * TODO Refactor this function (or better delete it). Don't use search params from reducer.
 */
export const getViewParams = ({
  viewType,
  localLimit,
  localPage,
  isLoading,
  documentsReducer,
  searchParams,
  searchResultsRelevance
}) => {
  if (viewType === VIEW_TYPE_DATE) {
    const totalCount = lodash.get(documentsReducer.publication_date, 'count', 0);
    const offset = getStartDateOffset(documentsReducer);
    const limit = Number(localLimit);
    const page = Number(localPage);

    const canGoBack = !(isLoading || offset <= 1);
    const canGoForward = !(isLoading || getEndDateOffset(documentsReducer) >= totalCount);

    return {
      totalCount,
      offset,
      canGoBack,
      canGoForward,
      page,
      limit
    };
  }

  const totalCount = lodash.get(
    viewType === VIEW_TYPE_NEWS ? documentsReducer.publication_date : searchResultsRelevance,
    'count',
    0
  );
  const offset = searchParams.offset;
  const limit = searchParams.limit;
  const page = getPageByLimitAndOffset({ offset, limit });

  const canGoBack = page > 1;
  const canGoForward = Math.ceil(totalCount / limit) !== page && totalCount !== 0;

  return {
    totalCount,
    offset,
    canGoBack,
    canGoForward,
    page,
    limit
  };
};
