import GoogleLogin from 'react-google-login';
import PropTypes from 'prop-types';
import * as images from 'shared/images';
import './_google-button.scss';

export const GoogleButton = ({ onClick, clientId, isDummyButton }) => {
  if (isDummyButton) {
    return (
      <div className="google-button" onClick={onClick}>
        <>
          <img className="google-button__logo" alt="Google's logo" src={images.misc.googleLogo} />
          Log in with Google
        </>
      </div>
    );
  }

  return (
    <GoogleLogin
      onRequest={onClick}
      clientId={clientId}
      scope="email profile openid"
      uxMode="redirect"
      onSuccess={e => e}
      onFailure={e => e}
      className="google-button"
      buttonText={
        <>
          <img className="google-button__logo" alt="Google's logo" src={GoogleLogo} />
          Sign in with Google
        </>
      }
    />
  );
};

GoogleButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired
};
