import type { GlobalState } from 'shared/reducers';
import type { WorkflowDetailsData } from './useWorkflowDetailsData.types';

import { useSelector } from 'react-redux';
import { getRightPanelTasksRefreshTrigger } from 'shared/features/rightPanel/rightPanel.selectors';

export const useWorkflowDetailsData = (): WorkflowDetailsData => {
  const reduxState = useSelector<GlobalState, WorkflowDetailsData['reduxState']>(state => ({
    tasksRefreshTrigger: getRightPanelTasksRefreshTrigger(state as never)
  }));

  return {
    reduxState
  };
};
