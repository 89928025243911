import type { ComponentProps, ComponentType } from 'react';

import * as elements from './elements';
import * as constants from './WorkflowSummary.constants';

export const SECTIONS: Record<
  constants.SECTION_TYPE,
  {
    Component:
      | ComponentType<ComponentProps<typeof elements.TasksSection>>
      | ComponentType<ComponentProps<typeof elements.CommentsSection>>
      | ComponentType<ComponentProps<typeof elements.SentenceNotesSection>>;
    props?: Partial<
      | ComponentProps<typeof elements.TasksSection>
      | ComponentProps<typeof elements.CommentsSection>
      | ComponentProps<typeof elements.SentenceNotesSection>
    >;
  }
> = {
  [constants.SECTION_TYPE.TASKS]: {
    Component: elements.TasksSection
  },
  [constants.SECTION_TYPE.COMMENTS]: {
    Component: elements.CommentsSection
  },
  [constants.SECTION_TYPE.ANNOTATIONS]: {
    Component: elements.SentenceNotesSection
  },
  [constants.SECTION_TYPE.OBLIGATIONS]: {
    Component: elements.SentenceNotesSection
  }
};
