import type { FC } from 'react';
import type { BucketAttributeLinkCellProps } from './BucketAttributeLinkCell.types';

import { Link } from 'react-router-dom';

import * as uiLib from '@compliance.ai/web-components';

import { useBucketAttributeLinkCellData, useBucketAttributeLinkCellHandlers } from './hooks';

import './_bucket-attribute-link-cell.scss';

export const BucketAttributeLinkCell: FC<BucketAttributeLinkCellProps> = ({ cellValue }) => {
  const { formattedData } = useBucketAttributeLinkCellData({ props: { cellValue } });

  const handlers = useBucketAttributeLinkCellHandlers({
    props: {
      cellValue
    }
  });

  return (
    <Link to={formattedData.bucketAttributePageUrl} className="bucket-attribute-link">
      <uiLib.Button type={uiLib.BUTTON_TYPES.LINK} onClick={handlers.handleLinkClick}>
        {cellValue.count ?? 'View'}
      </uiLib.Button>
    </Link>
  );
};
