import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';

import './_document-not-found.scss';

export const DocumentNotFound: FC = () => {
  return (
    <div className="document-not-found__container">
      <uiLib.Alert type={uiLib.ALERT_TYPES.ERROR} title="This document does not exist" />
    </div>
  );
};
