import {
  api_addDocumentToWorkflow,
  api_fetchTasks,
  api_fetchDocumentsByIds,
  api_fetchDocumentTasks,
  api_bulkDeleteDocumentTasks
} from './workflow.api';

export const REQUEST_ADD_DOCUMENT_TO_WORKFLOW = 'REQUEST_ADD_DOCUMENT_TO_WORKFLOW';
export const RECEIVE_ADD_DOCUMENT_TO_WORKFLOW = 'RECEIVE_ADD_DOCUMENT_TO_WORKFLOW';
export const FAIL_ADD_DOCUMENT_TO_WORKFLOW = 'FAIL_ADD_DOCUMENT_TO_WORKFLOW';
export const REQUEST_TASKS = 'REQUEST_TASKS';
export const RECEIVE_TASKS = 'RECEIVE_TASKS';
export const UPDATE_TASKS = 'UPDATE_TASKS';
export const FETCH_DOCUMENTS_BY_IDS = 'FETCH_DOCUMENTS_BY_IDS';
export const SET_LOCK_MANAGE_TASK_OPERATIONS = 'SET_LOCK_MANAGE_TASK_OPERATIONS';
export const TOGGLE_DOC_TASK_LOADING_STATUS = 'TOGGLE_DOC_TASK_LOADING_STATUS';
export const TOGGLE_DOC_TASK_REFETCH_TRIGGER = 'TOGGLE_DOC_TASK_REFETCH_TRIGGER';

export const addDocumentToWorkflow = ({ workflowId, docTasks }) => async dispatch => {
  try {
    dispatch({
      type: REQUEST_ADD_DOCUMENT_TO_WORKFLOW
    });
    await api_addDocumentToWorkflow({ workflowId, docTasks });
    dispatch({
      type: RECEIVE_ADD_DOCUMENT_TO_WORKFLOW
    });
  } catch (error) {
    dispatch({
      type: FAIL_ADD_DOCUMENT_TO_WORKFLOW
    });
  }
};

function requestTasks(data) {
  return {
    type: REQUEST_TASKS,
    data
  };
}

function receiveTasks(response) {
  return {
    type: RECEIVE_TASKS,
    payload: response
  };
}

export function fetchDocumentTasks(params) {
  return function getDocumentTasks(dispatch) {
    return api_fetchDocumentTasks(params);
  };
}

export function fetchTasks(data = {}) {
  return function getTasks(dispatch) {
    dispatch(requestTasks(data));
    return api_fetchTasks(data).then(response => {
      dispatch(receiveTasks(response));
      return response;
    });
  };
}

export const bulkDeleteDocumentTasks = ids => async () => {
  return await api_bulkDeleteDocumentTasks(ids);
};

export function setLockManageTaskOperations(lockManageTaskOperations) {
  return function doUpdate(dispatch) {
    dispatch({
      type: SET_LOCK_MANAGE_TASK_OPERATIONS,
      lockManageTaskOperations
    });
  };
}

export const fetchDocumentsByIds = docIds => async dispatch => {
  try {
    const res = await api_fetchDocumentsByIds(docIds);
    dispatch({ type: FETCH_DOCUMENTS_BY_IDS, payload: res });
  } catch {
    // pass
  }
};

export const toggleDocTaskRefetchTrigger = () => dispatch => {
  dispatch({ type: TOGGLE_DOC_TASK_REFETCH_TRIGGER });
};
