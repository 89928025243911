import type { FC } from 'react';
import type { FilterFooterProps } from './FilterFooter.types';

import classnames from 'classnames';
import FilterError from '../FilterError';

import './_filter-footer.scss';

export const TEST_ID = 'FilterFooter';

const FilterFooter: FC<FilterFooterProps> = ({ className, error, buttons, additionalContent }) => {
  return (
    <div data-testid={TEST_ID} className={classnames(className, 'filter-footer__container')}>
      <div className="filter-footer__errors-wrapper">
        {additionalContent}
        {error && <FilterError>{error}</FilterError>}
      </div>
      <div className="filter-footer__buttons-wrapper">{buttons}</div>
    </div>
  );
};

export default FilterFooter;
