import lodash from 'lodash';
import moment from 'moment';
import queryString from 'utils/query-string';
import auth from 'utils/auth';

import * as uiLib from '@compliance.ai/web-components';
import * as common from 'common';
import * as analyticsUtils from 'utils/analytics';
import * as relativeDateUtils from 'utils/relativeDate-utils';
import * as localStorageUtils from 'utils/localStorage-utils';
import * as documentSelectors from 'shared/features/documents/documents.selectors';
import * as thresholdSelectors from 'shared/features/thresholds/thresholds.selectors';
import * as config from 'shared/config';
import * as routes from 'constants/Routes';
import * as actionBarConstants from 'constants/ActionBar';
import * as filterConstants from 'shared/features/filters/filters.constants';
import * as viewConstants from 'shared/features/view/view.constants';

import { store } from 'store';
import { FILTER_INITIAL_STATE } from 'shared/features/filters/filters.reducer';
import {
  DOC_PROPERTIES_TO_QUERY_PARAMS,
  SAVED_USER_FILTERS_TO_QUERY_PARAMS,
  EXPECTED_NON_EMPTY_FILTER_KEYS
} from './PrimaryFilter.constants';
import {
  FILTER_KEY,
  SUPPORTED_QUERY_PARAMS,
  DATE_FILTERS,
  MATCH_TYPE_MAP
} from 'constants/PrimaryFilter';
import { FILTER_KEY as ACTION_BAR_FILTER_KEY } from 'constants/ActionBarFilter';

// TODO Cover with tests!
// TODO Add JSDocs

export const getFilterState = ({ location, reduxState }) => {
  const queryParams = queryString.parse(location.search);

  const fullAuthorsList = reduxState.authors;
  const fullAgencyList = reduxState.agencies.items.filter(
    agency => !lodash.isNil(agency.short_name)
  );
  const fullMainstreamNewsList = reduxState.sources.sources.defaultMainstreamNewsSources;
  const fullTopicList = reduxState.sources.sources.activeTopics;
  const fullRegulationList = reduxState.regulations.regulations;
  const fullLanguageList = reduxState.languages.languages;
  const fullDocTypeIdsMap = documentSelectors.formatCategoriesByField(
    reduxState.docTypes.docTypes.cai_categories,
    'id'
  );
  const fullConceptsList = reduxState.conceptsData.conceptsList;
  const hasUnofficialPubDate = queryParams[SUPPORTED_QUERY_PARAMS.HAS_UNOFFICIAL_PUBLICATION_DATE];

  const queryParamsFormatters = getValueFormatters({
    fullAgencyList,
    fullMainstreamNewsList,
    fullTopicList,
    fullAuthorsList,
    fullRegulationList,
    fullDocTypeIdsMap,
    fullConceptsList,
    fullLanguageList,
    hasUnofficialPubDate
  });

  // if the query contains any parameters that can be filtered, replace previous filters
  const areThereAnySupportedParams = areThereAnySupportedQueryParams(queryParams);

  if (
    [routes.content, routes.news, routes.timeline, routes.resources].includes(location.pathname) &&
    areThereAnySupportedParams
  ) {
    let filterParams = lodash.cloneDeep(FILTER_INITIAL_STATE.primaryFilter.filterParams);

    // force add query param for official only checkbox
    if (!queryParams[SUPPORTED_QUERY_PARAMS.HAS_UNOFFICIAL_PUBLICATION_DATE]) {
      queryParams[SUPPORTED_QUERY_PARAMS.HAS_UNOFFICIAL_PUBLICATION_DATE] = 'true';
    }

    Object.entries(queryParams).forEach(([queryParamKey, queryParamValue]) => {
      if (queryParamsFormatters[queryParamKey]) {
        filterParams = queryParamsFormatters[queryParamKey](queryParamValue, filterParams);
      }
    });

    return filterParams;
  }

  return getFilterFromUser({
    currentUser: reduxState.current_user,
    languages: fullLanguageList
  });
};

export const areThereAnySupportedQueryParams = queryParams => {
  return Object.keys(queryParams).find(queryParamKey =>
    Object.values(SUPPORTED_QUERY_PARAMS).includes(queryParamKey)
  );
};

/**
 *
 * Returns boolean that shows if passed value is not empty
 * @param {Array} - pair of filter key and filter value
 * @return {Boolean} - flag that shows if passed value is not empty
 */
export const isNotEmptyFilter = ([filterKey, filterValue]) => {
  if (EXPECTED_NON_EMPTY_FILTER_KEYS.includes(filterKey)) {
    return true;
  }

  return !lodash.isEmpty(filterValue) && !lodash.isNil(filterValue);
};

/**
 *
 * Some of the filter values are stored in the old format (regulation=123, agency=321, .etc)
 * This helper tries to get the part after '='
 * @param {String} filterValue - saved filter value
 * @return {String} - extracted value
 */
export const extractValue = filterValue => {
  if (lodash.isString(filterValue)) {
    const separatedValue = filterValue.split('=');
    return separatedValue[1] ? separatedValue[1] : separatedValue[0];
  }
  return filterValue;
};

/**
 *
 * Parses dates as moment objects and extracts exact values for non-date values.
 * @param {Array} - pair of filter key and filter value
 * @return {Array} - formatted pair of filter key and filter value
 */
export const formatValueForFilter = ([filterKey, filterValue]) => {
  return DATE_FILTERS.includes(filterKey)
    ? [
        filterKey,
        relativeDateUtils.isRelativeDate(filterValue)
          ? relativeDateUtils.formatRelativeDate(filterValue)
          : moment(filterValue)
      ]
    : [
        filterKey,
        Array.isArray(filterValue) ? filterValue.map(extractValue) : extractValue(filterValue)
      ];
};

export const removeNotExistingOrgLabels = organizationLabels => ([filterKey, filterValue]) => {
  if (filterKey === FILTER_KEY.LABELS) {
    const value = Array.isArray(filterValue) ? filterValue : [filterValue];

    const filteredValue = value.reduce((filteredValue, selectedOrgLabelOption) => {
      const existingOrgLabelOption = organizationLabels.find(orgLabelOption => {
        return orgLabelOption.value === parseInt(selectedOrgLabelOption.value, 10);
      });

      if (existingOrgLabelOption) {
        return [...filteredValue, existingOrgLabelOption];
      }

      return filteredValue;
    }, []);

    return [filterKey, filteredValue];
  }

  return [filterKey, filterValue];
};

/**
 *
 * Converts array of object entries to object
 * @param {Object} obj - object of processed keys and values.
 * @param {Array} - pair of key and value.
 * @return {Object} - converted object.
 */
export const toObject = (obj, [filterKey, filterValue]) => {
  return {
    ...obj,
    [filterKey]: filterValue
  };
};

/**
 *
 * Returns filter from current Redux user
 * @param {Object} reduxState - Redux state
 * @return {Object} - filter params object.
 */
export const getFilterFromUser = ({ currentUser, languages }) => {
  const filter = auth.loggedIn()
    ? currentUser.user.properties.filter
    : localStorageUtils.getAnonymousUserFilter();

  if (filter) {
    let filterParams = Object.entries(filter)
      .filter(isNotEmptyFilter)
      .map(formatValueForFilter)
      .reduce(toObject, {});

    if (currentUser.user.languageId) {
      filterParams = languageIdFormatter(languages)(currentUser.user.languageId, filterParams);
    }

    return filterParams;
  }

  return {};
};

export const convertSavedUserFilterToQueryParams = currentUser => {
  const { filter } = currentUser.user.properties;
  const queryParams = {};

  if (filter) {
    Object.entries(filter).forEach(([filterKey, filterValue]) => {
      const queryParamName = SAVED_USER_FILTERS_TO_QUERY_PARAMS[filterKey];

      if (queryParamName) {
        if (Array.isArray(filterValue)) {
          queryParams[queryParamName] = filterValue.map(({ value }) => extractValue(value));
        } else {
          queryParams[queryParamName] = extractValue(filterValue);
        }
      }
    });
  }

  return queryParams;
};

export const agenciesFormatter = fullAgencyList => (value, { ...state }) => {
  for (const agency of fullAgencyList) {
    if (Array.isArray(value) && value.length > 0) {
      for (const elem of value) {
        if (elem === agency.id.toString()) {
          state[FILTER_KEY.AGENCIES].push({
            label: agency.short_name,
            value: agency.id
          });
        }
      }
    } else if (value === agency.id.toString()) {
      state[FILTER_KEY.AGENCIES].push({ label: agency.short_name, value: agency.id });
    }
  }
  return state;
};

export const authorsFormatter = authors => (value, { ...state }) => {
  for (const author of authors) {
    if (Array.isArray(value) && value.length > 0) {
      for (const elem of value) {
        if (elem === author.id.toString()) {
          state[FILTER_KEY.AUTHOR].push({
            label: author.name,
            value: author.id
          });
        }
      }
    } else if (value === author.id.toString()) {
      state[FILTER_KEY.AUTHOR].push({ label: author.name, value: author.id });
    }
  }
  return state;
};

export const categoryIdsFormatter = fullDocTypeIdsMap => (value, { ...state }) => {
  if (Array.isArray(value) && value.length > 0) {
    for (const categoryId of value) {
      const category = getCategoryById(categoryId, fullDocTypeIdsMap);

      if (category) {
        state[FILTER_KEY.DOC_TYPES].push({ label: category, value: category });
      }
    }
  } else {
    const category = getCategoryById(value, fullDocTypeIdsMap);

    if (category) {
      state[FILTER_KEY.DOC_TYPES].push({
        label: category,
        value: category
      });
    }
  }

  return state;
};

export const categoriesFormatter = fullDocTypeIdsMap => (value, { ...state }) => {
  if (Array.isArray(value) && value.length > 0) {
    for (const category of value) {
      const AllActiveIds = fullDocTypeIdsMap[category]?.all_active_ids || [];

      for (const id of AllActiveIds) {
        const label = fullDocTypeIdsMap[id].name;
        state[FILTER_KEY.DOC_TYPES].push({ label, value: id });
      }
    }
  } else {
    const AllActiveIds = fullDocTypeIdsMap[value]?.all_active_ids || [];
    for (const id of AllActiveIds) {
      const label = fullDocTypeIdsMap[id].name;
      state[FILTER_KEY.DOC_TYPES].push({ label, value: id });
    }
  }
  return state;
};

export const relativeDateFormatter = filterKey => (value, { ...state }) => {
  if (relativeDateUtils.isRelativeDate(value)) {
    state[filterKey] = relativeDateUtils.formatRelativeDate(value);
  } else {
    state[filterKey] = moment(decodeURIComponent(value), 'MM DD YYYY');
  }

  return state;
};

export const dateFormatter = filterKey => (value, { ...state }) => {
  state[filterKey] = moment(decodeURIComponent(value), 'MM DD YYYY');
  return state;
};

export const exactFalseBooleanFormatter = filterKey => (value, { ...state }) => {
  state[filterKey] = value !== 'false';
  return state;
};

export const textFormatter = filterKey => (value, { ...state }) => {
  state[filterKey] = decodeURIComponent(encodeURIComponent(value));
  return state;
};

export const conceptIdsFormatter = concepts => (value, { ...state }) => {
  if (Array.isArray(value) && value.length > 0) {
    for (const conceptId of value) {
      const concept = concepts.find(concept => concept.id === Number(conceptId));

      if (concept) {
        state[FILTER_KEY.CONCEPTS].push({
          label: concept.name,
          value: concept.id
        });
      }
    }
  } else {
    const concept = concepts.find(concept => concept.id === Number(value));

    if (concept) {
      state[FILTER_KEY.CONCEPTS].push({
        label: concept.name,
        value: concept.id
      });
    }
  }

  return state;
};

export const languageIdFormatter = languages => (value, { ...state }) => {
  const language = languages.find(_language => _language.id === Number(value));

  if (language) {
    state[FILTER_KEY.LANGUAGE] = {
      label: language.name,
      value: language.id
    };
  }

  return state;
};

export const eitlLabelsFormatter = (value, { ...state }) => {
  const eitlLabelIds = Array.isArray(value) ? value.map(Number) : [Number(value)];

  if (eitlLabelIds.length) {
    state[FILTER_KEY.EITL_LABELS] = [...state[FILTER_KEY.EITL_LABELS], ...eitlLabelIds];
  }

  return state;
};

export const getValueFormatters = ({
  fullAgencyList,
  fullAuthorsList,
  fullMainstreamNewsList,
  fullTopicList,
  fullRegulationList,
  fullDocTypeIdsMap,
  fullConceptsList,
  fullLanguageList
}) => ({
  [SUPPORTED_QUERY_PARAMS.AGENCY_ID]: agenciesFormatter(fullAgencyList),
  [SUPPORTED_QUERY_PARAMS.AGENCY]: agenciesFormatter(fullAgencyList),
  [SUPPORTED_QUERY_PARAMS.PUBLICATION_DATE]: relativeDateFormatter(FILTER_KEY.PUB_DATE),
  [SUPPORTED_QUERY_PARAMS.HAS_UNOFFICIAL_PUBLICATION_DATE]: exactFalseBooleanFormatter(
    FILTER_KEY.HAS_UNOFFICIAL_PUB_DATE
  ),
  [SUPPORTED_QUERY_PARAMS.PUBLISHED_FROM]: dateFormatter(FILTER_KEY.PUB_START_DATE),
  [SUPPORTED_QUERY_PARAMS.PUBLISHED_TO]: dateFormatter(FILTER_KEY.PUB_END_DATE),
  [SUPPORTED_QUERY_PARAMS.ADDED_DATE]: relativeDateFormatter(FILTER_KEY.ADDED_DATE),
  [SUPPORTED_QUERY_PARAMS.ADDED_FROM]: dateFormatter(FILTER_KEY.ADDED_START_DATE),
  [SUPPORTED_QUERY_PARAMS.ADDED_TO]: dateFormatter(FILTER_KEY.ADDED_END_DATE),
  [SUPPORTED_QUERY_PARAMS.UPDATED_DATE]: relativeDateFormatter(FILTER_KEY.UPDATED_DATE),
  [SUPPORTED_QUERY_PARAMS.UPDATED_FROM]: dateFormatter(FILTER_KEY.UPDATED_START_DATE),
  [SUPPORTED_QUERY_PARAMS.UPDATED_TO]: dateFormatter(FILTER_KEY.UPDATED_END_DATE),
  [SUPPORTED_QUERY_PARAMS.EFFECTIVE_DATE]: relativeDateFormatter(FILTER_KEY.EFFECTIVE_DATE),
  [SUPPORTED_QUERY_PARAMS.EFFECTIVE_FROM]: dateFormatter(FILTER_KEY.EFFECTIVE_START_DATE),
  [SUPPORTED_QUERY_PARAMS.EFFECTIVE_TO]: dateFormatter(FILTER_KEY.EFFECTIVE_END_DATE),
  [SUPPORTED_QUERY_PARAMS.AUTHOR_ID]: authorsFormatter(fullAuthorsList),
  [SUPPORTED_QUERY_PARAMS.COMMENTS_CLOSE_DATE]: relativeDateFormatter(
    FILTER_KEY.COMMENTS_CLOSE_DATE
  ),
  [SUPPORTED_QUERY_PARAMS.COMMENTS_CLOSE_FROM]: dateFormatter(FILTER_KEY.COMMENTS_CLOSE_START_DATE),
  [SUPPORTED_QUERY_PARAMS.COMMENTS_CLOSE_TO]: dateFormatter(FILTER_KEY.COMMENTS_CLOSE_END_DATE),
  [SUPPORTED_QUERY_PARAMS.DOC_PROPERTIES]: (value, { ...state }) => {
    if (Array.isArray(value) && value.length > 0) {
      for (const docProperty of value) {
        state[FILTER_KEY.DOC_PROPERTIES].push({
          label: docProperty,
          value: docProperty
        });
      }
    } else {
      state[FILTER_KEY.DOC_PROPERTIES].push({
        label: value,
        value: value
      });
    }
    return state;
  },
  [SUPPORTED_QUERY_PARAMS.TITLE]: textFormatter(FILTER_KEY.TITLE),
  [SUPPORTED_QUERY_PARAMS.TITLE_MATCH_TYPE]: (value, { ...state }) => {
    state[FILTER_KEY.TITLE_MATCH_TYPES] = {
      label: MATCH_TYPE_MAP[value],
      value
    };

    return state;
  },
  [SUPPORTED_QUERY_PARAMS.TEXT]: textFormatter(FILTER_KEY.TEXT),
  [SUPPORTED_QUERY_PARAMS.TEXT_MATCH_TYPE]: (value, { ...state }) => {
    state[FILTER_KEY.TEXT_MATCH_TYPES] = {
      label: MATCH_TYPE_MAP[value],
      value
    };

    return state;
  },
  [SUPPORTED_QUERY_PARAMS.CITATION]: textFormatter(FILTER_KEY.CITATION),
  [SUPPORTED_QUERY_PARAMS.TASK_OWNER]: (value, { ...state }) => {
    if (value === 'current_user') {
      state[FILTER_KEY.DOC_PROPERTIES].push(filterConstants.ALL_DOC_PROPERTIES.myTasks);
    } else if (value === 'organization') {
      state[FILTER_KEY.DOC_PROPERTIES].push(filterConstants.ALL_DOC_PROPERTIES.myOrgTasks);
    }
    return state;
  },
  [SUPPORTED_QUERY_PARAMS.HAS_SENTENCES]: (value, { ...state }) => {
    if (value === 'true') {
      state[FILTER_KEY.DOC_PROPERTIES].push(filterConstants.ALL_DOC_PROPERTIES.hasSentences);
    }
    return state;
  },
  [SUPPORTED_QUERY_PARAMS.HAS_ANNOTATIONS]: (value, { ...state }) => {
    if (value === 'true') {
      state[FILTER_KEY.DOC_PROPERTIES].push(filterConstants.ALL_DOC_PROPERTIES.hasAnnotations);
    }
    return state;
  },
  [SUPPORTED_QUERY_PARAMS.HAS_OBLIGATIONS]: (value, { ...state }) => {
    if (value === 'true') {
      state[FILTER_KEY.DOC_PROPERTIES].push(filterConstants.ALL_DOC_PROPERTIES.hasObligations);
    }
    return state;
  },
  [SUPPORTED_QUERY_PARAMS.IS_PREMIUM_CONTENT]: (value, { ...state }) => {
    if (value === 'true') {
      state[FILTER_KEY.DOC_PROPERTIES].push(filterConstants.ALL_DOC_PROPERTIES.isPremiumContent);
    }
    return state;
  },
  [SUPPORTED_QUERY_PARAMS.IS_ORGANIZATION_DOCUMENT]: (value, { ...state }) => {
    if (value === 'true') {
      state[FILTER_KEY.DOC_PROPERTIES].push(filterConstants.BYOC_PROPERTIES.isOrganizationDocument);
    }
    return state;
  },
  [SUPPORTED_QUERY_PARAMS.NEWS_SOURCE_ID]: (value, { ...state }) => {
    for (const newsSource of fullMainstreamNewsList) {
      if (Array.isArray(value) && value.length > 0) {
        for (const elem of value) {
          if (elem === newsSource.id.toString()) {
            state[FILTER_KEY.MAINSTREAM_NEWS_SOURCES].push({
              label: newsSource.name,
              value: newsSource.id
            });
          }
        }
      } else if (value === newsSource.id.toString()) {
        state[FILTER_KEY.MAINSTREAM_NEWS_SOURCES].push({
          label: newsSource.name,
          value: newsSource.id
        });
      }
    }
    return state;
  },
  [SUPPORTED_QUERY_PARAMS.JURISDICTION]: (value, { ...state }) => {
    if (Array.isArray(value) && value.length > 0) {
      for (const jurisdiction of value) {
        state[FILTER_KEY.JURISDICTIONS].push({
          label: jurisdiction,
          value: jurisdiction
        });
      }
    } else {
      state[FILTER_KEY.JURISDICTIONS].push({
        label: value,
        value: value
      });
    }
    return state;
  },
  [SUPPORTED_QUERY_PARAMS.TOPIC_ID]: (value, { ...state }) => {
    for (const topic of fullTopicList) {
      if (Array.isArray(value) && value.length > 0) {
        for (const elem of value) {
          if (elem === topic.id.toString()) {
            state[FILTER_KEY.TOPICS].push({ label: topic.name, value: topic.id });
          }
        }
      } else if (value === topic.id.toString()) {
        state[FILTER_KEY.TOPICS].push({ label: topic.name, value: topic.id });
      }
    }
    return state;
  },
  [SUPPORTED_QUERY_PARAMS.CATEGORY_ID]: categoryIdsFormatter(fullDocTypeIdsMap),
  [SUPPORTED_QUERY_PARAMS.CATEGORY]: categoriesFormatter(fullDocTypeIdsMap),
  [SUPPORTED_QUERY_PARAMS.REGULATION_ID]: (value, { ...state }) => {
    for (const regulation of fullRegulationList) {
      if (Array.isArray(value) && value.length > 0) {
        for (const elem of value) {
          if (elem === regulation.id.toString()) {
            state[FILTER_KEY.REGULATIONS].push({
              label: regulation.name,
              value: regulation.id
            });
          }
        }
      } else if (value === regulation.id.toString()) {
        state[FILTER_KEY.REGULATIONS].push({
          label: regulation.name,
          value: regulation.id
        });
      }
    }
    return state;
  },
  [SUPPORTED_QUERY_PARAMS.CONCEPT_ID]: conceptIdsFormatter(fullConceptsList),
  [SUPPORTED_QUERY_PARAMS.LANGUAGE_ID]: languageIdFormatter(fullLanguageList),
  [SUPPORTED_QUERY_PARAMS.HAS_EITL_LABELS]: (value, { ...state }) => {
    if (value === 'false') {
      state[FILTER_KEY.HAS_EITL_LABELS] = false;
      state[FILTER_KEY.EITL_LABELS].push(common.EITL_LABELS_NO_LABELS_OPTION.value);
    }
    if (lodash.isNil(value)) {
      state[FILTER_KEY.HAS_EITL_LABELS] = null;
    }

    return state;
  },
  [SUPPORTED_QUERY_PARAMS.EITL_LABEL_ID]: eitlLabelsFormatter,
  [SUPPORTED_QUERY_PARAMS.EXCLUDE_FEDERAL_REGISTER]: exactFalseBooleanFormatter(
    FILTER_KEY.EXCLUDE_FEDERAL_REGISTER
  ),
  [SUPPORTED_QUERY_PARAMS.DOC_ID]: (value, { ...state }) => {
    if (uiLib.isNumber(Number(value))) {
      state[FILTER_KEY.DOCUMENT_ID] = value;
    }

    return state;
  },
  [SUPPORTED_QUERY_PARAMS.DEFAULT_VIEW]: textFormatter(FILTER_KEY.DEFAULT_VIEW)
});

export const getCategoryById = (categoryId, fullDocTypeIdsMap) => {
  const docTypesMapEntry = Object.entries(fullDocTypeIdsMap).find(
    ([cat, id]) => `${id}` === categoryId
  );

  if (Array.isArray(docTypesMapEntry)) {
    const [category] = docTypesMapEntry;

    return category;
  }
};

export const onNewsOptionsFilter = (option, rawInput) => {
  const words = rawInput.split(' ');

  return words.reduce((acc, cur) => {
    return acc && option.data.name.toLowerCase().includes(cur.toLowerCase());
  }, true);
};

export const applyFilters = ({
  reduxState,
  reduxActions,
  shouldClearAlert,
  history,
  location,
  searchQuery,
  currentView
}) => {
  doAnalytics(reduxState);

  reduxActions.closeFilter();
  reduxActions.closeAdvancedSearchFilter();

  if (
    shouldClearAlert &&
    (reduxState.current_view.editAlertId || reduxState.current_view.viewAlertId)
  ) {
    reduxActions.removeViewAlert();
    reduxActions.removeEditAlert();
  }
  const queryString = formatQueryStringFromFilterParams({
    filterParams: reduxState.filterParams,
    location,
    searchQuery,
    currentView,
    defaultView: reduxState.selectedDefaultView,
    limit: reduxState.actionBarFilterValues[ACTION_BAR_FILTER_KEY.ROWS_COUNT]
  });

  /**
   * FIXME This is hell. Keeping the shitty logic until we move to
   * search v2.
   *
   * The logic assumes that since there are alertIds in Redux, the correct
   * documents pathname is already in the url
   */
  const pathname =
    reduxState.current_view.editAlertId || reduxState.current_view.viewAlertId
      ? location.pathname
      : routes.content;

  history.push(`${pathname}?${queryString}`);
};

export const doAnalytics = reduxState => {
  let AnalyticsData = '';

  if (
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.JURISDICTIONS]) &&
    !lodash.isEmpty(reduxState.filterParams[FILTER_KEY.JURISDICTIONS])
  ) {
    AnalyticsData += 'Jurisdictions,';
  }
  if (
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.AGENCIES]) &&
    !lodash.isEmpty(reduxState.filterParams[FILTER_KEY.AGENCIES])
  ) {
    AnalyticsData += 'Agencies,';
  }
  if (
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.MAINSTREAM_NEWS_SOURCES]) &&
    !lodash.isEmpty(reduxState.filterParams[FILTER_KEY.MAINSTREAM_NEWS_SOURCES])
  ) {
    AnalyticsData += 'Mainstream News,';
  }
  if (
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.DOC_TYPES]) &&
    !lodash.isEmpty(reduxState.filterParams[FILTER_KEY.DOC_TYPES])
  ) {
    AnalyticsData += 'Document Type,';
  }
  if (
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.REGULATIONS]) &&
    !lodash.isEmpty(reduxState.filterParams[FILTER_KEY.REGULATIONS])
  ) {
    AnalyticsData += 'Regulation,';
  }
  if (reduxState.filterParams[FILTER_KEY.CITATION].length) {
    AnalyticsData += 'Citation,';
  }
  if (reduxState.filterParams[FILTER_KEY.TITLE].length) {
    AnalyticsData += 'Title,';
  }
  if (reduxState.filterParams[FILTER_KEY.TEXT].length) {
    AnalyticsData += 'Text,';
  }
  if (
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.LABELS]) &&
    !lodash.isEmpty(reduxState.filterParams[FILTER_KEY.LABELS])
  ) {
    AnalyticsData += 'Labels,';
  }
  if (
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.LANGUAGE]) &&
    !lodash.isEmpty(reduxState.filterParams[FILTER_KEY.LANGUAGE])
  ) {
    AnalyticsData += 'Language,';
  }
  if (
    lodash.isObject(reduxState.filterParams[FILTER_KEY.ACTS]) &&
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.ACTS].label)
  ) {
    AnalyticsData += 'Act,';
  }
  if (
    lodash.isObject(reduxState.filterParams[FILTER_KEY.BANKS]) &&
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.BANKS].label)
  ) {
    AnalyticsData += 'Business,';
  }
  if (
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.TOPICS]) &&
    !lodash.isEmpty(reduxState.filterParams[FILTER_KEY.TOPICS])
  ) {
    AnalyticsData += 'Topics,';
  }
  if (
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.AUTHOR]) &&
    !lodash.isEmpty(reduxState.filterParams[FILTER_KEY.AUTHOR])
  ) {
    AnalyticsData += 'Author,';
  }
  if (
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.PUB_DATE]) ||
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.PUB_START_DATE]) ||
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.PUB_END_DATE])
  ) {
    AnalyticsData += 'Publication Date';
  }
  if (
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.ADDED_DATE]) ||
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.ADDED_START_DATE]) ||
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.ADDED_END_DATE])
  ) {
    AnalyticsData += 'Added to Compliance.ai,';
  }
  if (
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.UPDATED_DATE]) ||
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.UPDATED_START_DATE]) ||
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.UPDATED_END_DATE])
  ) {
    AnalyticsData += 'Updated in Compliance.ai,';
  }
  if (
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.EFFECTIVE_DATE]) ||
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.EFFECTIVE_START_DATE]) ||
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.EFFECTIVE_END_DATE])
  ) {
    AnalyticsData += 'Effective Date,';
  }
  if (
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.COMMENTS_CLOSE_DATE]) ||
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.COMMENTS_CLOSE_START_DATE]) ||
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.COMMENTS_CLOSE_END_DATE])
  ) {
    AnalyticsData += 'Comments Close Date,';
  }
  if (
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.EITL_LABELS]) &&
    !lodash.isEmpty(reduxState.filterParams[FILTER_KEY.EITL_LABELS])
  ) {
    AnalyticsData += 'EITL Labels,';
  }
  analyticsUtils.safe_analytics('default', 'Filter', 'Click on Search', AnalyticsData);

  if (
    !lodash.isNil(reduxState.filterParams[FILTER_KEY.DOC_PROPERTIES]) &&
    !lodash.isEmpty(reduxState.filterParams[FILTER_KEY.DOC_PROPERTIES])
  ) {
    analyticsUtils.safe_mixpanel_track(`Primary Filter`, {
      eventCategory: 'Primary Filter',
      eventAction: 'Applied Document Properties Filter',
      eventLabel: reduxState.filterParams[FILTER_KEY.DOC_PROPERTIES].map(
        docProp => docProp.label
      )[0]
    });
  }
};

export const shouldShowAlert = reduxState => {
  const { editAlertId } = reduxState.current_view;

  const currentAlertId = editAlertId || null;

  const currentAlert =
    currentAlertId &&
    reduxState.alerts.user_alerts &&
    reduxState.alerts.user_alerts.find(alert => alert.id === currentAlertId);

  return Boolean(
    currentAlert &&
      !currentAlert.search_args.includes(routes.resources) &&
      !currentAlert.search_args.includes(routes.enforcementExplorer)
  );
};

export const formatQueryStringFromFilterParams = ({
  filterParams,
  location,
  searchQuery = '',
  currentView,
  searchSort,
  order,
  limit = actionBarConstants.DEFAULT_LIMIT,
  defaultView
}) => {
  const parsedQuery = queryString.parse(location.search);
  const view = currentView;

  const queryParams = {
    [SUPPORTED_QUERY_PARAMS.LIMIT]: limit,
    [SUPPORTED_QUERY_PARAMS.TOPIC_THRESHOLD_KEY]: thresholdSelectors.getInitialTopicThresholdKey(
      parsedQuery
    )(store.getState())
  };

  if (defaultView) {
    queryParams[SUPPORTED_QUERY_PARAMS.DEFAULT_VIEW] = defaultView;
  }

  if (searchQuery) {
    queryParams[SUPPORTED_QUERY_PARAMS.SEARCH_QUERY] = searchQuery;
    queryParams[SUPPORTED_QUERY_PARAMS.MIN_SCORE] = 5;
  }

  if (
    !config.shouldShowNewSearch &&
    view &&
    view !== 'topics' &&
    view !== 'state' &&
    view !== 'federal' &&
    view !== 'mainstream_news'
  ) {
    queryParams[SUPPORTED_QUERY_PARAMS.VIEW] = view;
  }

  if (config.shouldShowNewSearch) {
    queryParams[SUPPORTED_QUERY_PARAMS.VIEW] = viewConstants.VIEW_KEY.ALL;
  }

  if (order) {
    queryParams[SUPPORTED_QUERY_PARAMS.ORDER] = order;
  } else if (parsedQuery[SUPPORTED_QUERY_PARAMS.ORDER]) {
    queryParams[SUPPORTED_QUERY_PARAMS.ORDER] = parsedQuery[SUPPORTED_QUERY_PARAMS.ORDER];
  } else {
    queryParams[SUPPORTED_QUERY_PARAMS.ORDER] = 'desc';
  }

  if (searchSort) {
    queryParams[SUPPORTED_QUERY_PARAMS.SEARCH_SORT] = searchSort;
  } else if (parsedQuery[SUPPORTED_QUERY_PARAMS.SEARCH_SORT]) {
    queryParams[SUPPORTED_QUERY_PARAMS.SEARCH_SORT] =
      parsedQuery[SUPPORTED_QUERY_PARAMS.SEARCH_SORT];
  } else {
    queryParams[SUPPORTED_QUERY_PARAMS.SEARCH_SORT] = 'publication_date';
  }

  Object.entries(filterParams).forEach(([filterKey, filterValue]) => {
    const filterMultipleValues = lodash.reject(lodash.map(filterValue, 'value'), ['', null]);
    const filterMultipleLabelValues = lodash.reject(lodash.map(filterValue, 'label'), ['', null]);

    if (isNotEmptyFilter([filterKey, filterValue])) {
      if (filterKey === FILTER_KEY.PUB_DATE) {
        if (relativeDateUtils.isRelativeDate(filterValue)) {
          queryParams[
            SUPPORTED_QUERY_PARAMS.PUBLICATION_DATE
          ] = relativeDateUtils.formatRelativeDate(filterValue);
        } else {
          queryParams[SUPPORTED_QUERY_PARAMS.PUBLICATION_DATE] = moment(filterValue).format(
            'MM DD YYYY'
          );
        }
      }
      if (filterKey === FILTER_KEY.HAS_UNOFFICIAL_PUB_DATE && filterValue === false) {
        queryParams[SUPPORTED_QUERY_PARAMS.HAS_UNOFFICIAL_PUBLICATION_DATE] = false;
      }
      if (filterKey === FILTER_KEY.PUB_START_DATE) {
        queryParams[SUPPORTED_QUERY_PARAMS.PUBLISHED_FROM] = moment(filterValue).format(
          'MM DD YYYY'
        );
      }
      if (filterKey === FILTER_KEY.PUB_END_DATE) {
        queryParams[SUPPORTED_QUERY_PARAMS.PUBLISHED_TO] = moment(filterValue).format('MM DD YYYY');
      }
      if (filterKey === FILTER_KEY.ADDED_DATE) {
        if (relativeDateUtils.isRelativeDate(filterValue)) {
          queryParams[SUPPORTED_QUERY_PARAMS.ADDED_DATE] = relativeDateUtils.formatRelativeDate(
            filterValue
          );
        } else {
          queryParams[SUPPORTED_QUERY_PARAMS.ADDED_DATE] = moment(filterValue).format('MM DD YYYY');
        }
      }
      if (filterKey === FILTER_KEY.ADDED_START_DATE) {
        queryParams[SUPPORTED_QUERY_PARAMS.ADDED_FROM] = moment(filterValue).format('MM DD YYYY');
      }
      if (filterKey === FILTER_KEY.ADDED_END_DATE) {
        queryParams[SUPPORTED_QUERY_PARAMS.ADDED_TO] = moment(filterValue).format('MM DD YYYY');
      }
      if (filterKey === FILTER_KEY.UPDATED_DATE) {
        if (relativeDateUtils.isRelativeDate(filterValue)) {
          queryParams[SUPPORTED_QUERY_PARAMS.UPDATED_DATE] = relativeDateUtils.formatRelativeDate(
            filterValue
          );
        } else {
          queryParams[SUPPORTED_QUERY_PARAMS.UPDATED_DATE] = moment(filterValue).format(
            'MM DD YYYY'
          );
        }
      }
      if (filterKey === FILTER_KEY.UPDATED_START_DATE) {
        queryParams[SUPPORTED_QUERY_PARAMS.UPDATED_FROM] = moment(filterValue).format('MM DD YYYY');
      }
      if (filterKey === FILTER_KEY.UPDATED_END_DATE) {
        queryParams[SUPPORTED_QUERY_PARAMS.UPDATED_TO] = moment(filterValue).format('MM DD YYYY');
      }
      if (filterKey === FILTER_KEY.EFFECTIVE_DATE) {
        if (relativeDateUtils.isRelativeDate(filterValue)) {
          queryParams[SUPPORTED_QUERY_PARAMS.EFFECTIVE_DATE] = relativeDateUtils.formatRelativeDate(
            filterValue
          );
        } else {
          queryParams[SUPPORTED_QUERY_PARAMS.EFFECTIVE_DATE] = moment(filterValue).format(
            'MM DD YYYY'
          );
        }
      }
      if (filterKey === FILTER_KEY.EFFECTIVE_START_DATE) {
        queryParams[SUPPORTED_QUERY_PARAMS.EFFECTIVE_FROM] = moment(filterValue).format(
          'MM DD YYYY'
        );
      }
      if (filterKey === FILTER_KEY.EFFECTIVE_END_DATE) {
        queryParams[SUPPORTED_QUERY_PARAMS.EFFECTIVE_TO] = moment(filterValue).format('MM DD YYYY');
      }
      if (filterKey === FILTER_KEY.COMMENTS_CLOSE_DATE) {
        if (relativeDateUtils.isRelativeDate(filterValue)) {
          queryParams[
            SUPPORTED_QUERY_PARAMS.COMMENTS_CLOSE_DATE
          ] = relativeDateUtils.formatRelativeDate(filterValue);
        } else {
          queryParams[SUPPORTED_QUERY_PARAMS.COMMENTS_CLOSE_DATE] = moment(filterValue).format(
            'MM DD YYYY'
          );
        }
      }
      if (filterKey === FILTER_KEY.COMMENTS_CLOSE_START_DATE) {
        queryParams[SUPPORTED_QUERY_PARAMS.COMMENTS_CLOSE_FROM] = moment(filterValue).format(
          'MM DD YYYY'
        );
      }
      if (filterKey === FILTER_KEY.COMMENTS_CLOSE_END_DATE) {
        queryParams[SUPPORTED_QUERY_PARAMS.COMMENTS_CLOSE_TO] = moment(filterValue).format(
          'MM DD YYYY'
        );
      }
      if (filterKey === FILTER_KEY.BANKS) {
        queryParams[SUPPORTED_QUERY_PARAMS.BANK_ID] = filterMultipleValues;
      }
      if (filterKey === FILTER_KEY.ACTS) {
        queryParams[SUPPORTED_QUERY_PARAMS.ACT_ID] = filterMultipleValues;
      }
      if (filterKey === FILTER_KEY.REGULATIONS) {
        queryParams[SUPPORTED_QUERY_PARAMS.REGULATION_ID] = filterMultipleValues;
      }
      if (filterKey === FILTER_KEY.TOPICS) {
        queryParams[SUPPORTED_QUERY_PARAMS.TOPIC_ID] = filterMultipleValues;
      }
      if (filterKey === FILTER_KEY.JURISDICTIONS) {
        queryParams[SUPPORTED_QUERY_PARAMS.JURISDICTION] = filterMultipleValues;
      }
      if (filterKey === FILTER_KEY.MAINSTREAM_NEWS_SOURCES) {
        queryParams[SUPPORTED_QUERY_PARAMS.NEWS_SOURCE_ID] = filterMultipleValues;
      }
      if (filterKey === FILTER_KEY.AGENCIES) {
        queryParams[SUPPORTED_QUERY_PARAMS.AGENCY_ID] = filterMultipleValues;
      }
      if (filterKey === FILTER_KEY.DOC_PROPERTIES) {
        const docPropertyOption = filterValue[0];
        const docPropertyToParamsConfig = DOC_PROPERTIES_TO_QUERY_PARAMS[docPropertyOption.label];

        if (docPropertyToParamsConfig) {
          queryParams[docPropertyToParamsConfig.param] = docPropertyToParamsConfig.value;
        } else {
          queryParams[SUPPORTED_QUERY_PARAMS.DOC_PROPERTIES] = filterMultipleLabelValues;
        }
      }
      if (filterKey === FILTER_KEY.CONCEPTS) {
        queryParams[SUPPORTED_QUERY_PARAMS.CONCEPT_ID] = filterMultipleValues;
      }
      if (filterKey === FILTER_KEY.DOC_TYPES) {
        queryParams[SUPPORTED_QUERY_PARAMS.CATEGORY] = filterMultipleValues;
      }
      if (filterKey === FILTER_KEY.LABELS) {
        queryParams[SUPPORTED_QUERY_PARAMS.LABEL] = filterMultipleValues;
      }
      if (filterKey === FILTER_KEY.AUTHOR) {
        queryParams[SUPPORTED_QUERY_PARAMS.AUTHOR_ID] = filterMultipleValues;
      }
      if (filterKey === FILTER_KEY.LANGUAGE) {
        queryParams[SUPPORTED_QUERY_PARAMS.LANGUAGE_ID] = filterValue.value;
      }
      if (filterKey === FILTER_KEY.CITATION) {
        queryParams[SUPPORTED_QUERY_PARAMS.CITATION] = filterValue;
      }
      if (filterKey === FILTER_KEY.TITLE) {
        queryParams[SUPPORTED_QUERY_PARAMS.TITLE] = filterValue;
      }
      if (filterKey === FILTER_KEY.TEXT) {
        queryParams[SUPPORTED_QUERY_PARAMS.TEXT] = filterValue;
      }
      if (filterKey === FILTER_KEY.TEXT_MATCH_TYPES) {
        queryParams[SUPPORTED_QUERY_PARAMS.TEXT_MATCH_TYPE] = filterValue.value;
      }
      if (filterKey === FILTER_KEY.TITLE_MATCH_TYPES) {
        queryParams[SUPPORTED_QUERY_PARAMS.TITLE_MATCH_TYPE] = filterValue.value;
      }
      if (filterKey === FILTER_KEY.HAS_EITL_LABELS && filterValue !== null) {
        queryParams[SUPPORTED_QUERY_PARAMS.HAS_EITL_LABELS] = filterValue;
      }
      if (filterKey === FILTER_KEY.EITL_LABELS) {
        queryParams[SUPPORTED_QUERY_PARAMS.EITL_LABEL_ID] = filterValue.filter(Boolean);
      }
      if (filterKey === FILTER_KEY.EXCLUDE_FEDERAL_REGISTER && filterValue === true) {
        queryParams[SUPPORTED_QUERY_PARAMS.EXCLUDE_FEDERAL_REGISTER] = true;
      }
      if (filterKey === FILTER_KEY.DOCUMENT_ID) {
        queryParams[SUPPORTED_QUERY_PARAMS.DOC_ID] = filterValue;
      }
    }
  });

  return queryString.stringify(queryParams);
};

export const getUnsetAsyncParams = ({ reduxState, filterKey, queryParamKey, location }) => {
  const queryParams = queryString.parse(location.search);
  const queryParam = queryParams[queryParamKey];

  if (!queryParam) {
    return [];
  }

  const providedValues = Array.isArray(queryParam) ? queryParam : [queryParam];

  return providedValues.filter(
    providedValue =>
      !reduxState.filterParams[filterKey].find(({ value }) => value === parseInt(providedValue, 10))
  );
};

/**
 *
 * Returns merged arrays of options by their value uniqueness
 * @param {Array} options1 - one array of options (objects with value and label properties)
 * @param {Array} options2 - another array of options (objects with value and label properties)
 * @return {Array} - merged and filtered by value uniqueness arrays of options
 */
export const mergeOptionsByUniqueness = (options1, options2) => {
  return lodash.uniqBy([...options1, ...options2], 'value');
};
