import PropTypes from 'prop-types';
import classnames from 'classnames';
import './_separator.scss';

export const Separator = ({ text, className }) => {
  return (
    <div className={classnames(className, 'form-separator__wrapper')}>
      <hr className="form-separator" />
      {text && <span className="form-separator__text">{text}</span>}
    </div>
  );
};

Separator.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string
};
