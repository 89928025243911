import { fetch } from 'utils/api';
import queryString from 'utils/query-string';

export function api_getWhitePaperDocuments({
  published_from,
  published_to,
  cai_category,
  skip_cai_category
}) {
  const requestParams = {
    cai_category,
    published_from,
    published_to,
    order: 'desc',
    search_sort: 'publication_date',
    all_agencies: true,
    all_topics: true,
    skip_unused_fields: true,
    skip_cai_category,
    has_unofficial_publication_date: false
  };
  const url = `/documents?${queryString.stringify(requestParams)}`;
  return fetch({
    url,
    dataType: 'NewsDocuments',
    method: 'GET'
  });
}

export function api_getAgenciesActivitiesGlanceDocs({ agency_id, cai_category, timeframe }) {
  return fetch({
    url: '/agencies_activities_glance',
    dataType: 'NewsDocuments',
    method: 'POST',
    params: {
      agency_id,
      cai_category,
      timeframe,
      has_unofficial_publication_date: false
    }
  });
}
