import type { FC } from 'react';
import type { MarkerProps } from './Marker.types';

import * as uiLib from '@compliance.ai/web-components';

import './_marker.scss';

export const Marker: FC<MarkerProps> = ({ children }) => {
  return (
    <div className="timeline-marker__container">
      <div className="timeline-marker">
        <uiLib.Typography type={uiLib.TYPOGRAPHY_TYPES.CAPTION} className="timeline-marker__title">
          {children}
        </uiLib.Typography>
      </div>
    </div>
  );
};
