import type { FC } from 'react';
import type { AnnotationPanelProps } from './AnnotationPanel.types';

import * as routes from 'constants/Routes';
import * as constants from './AnnotationPanel.constants';
import * as elements from './elements';
import * as sentenceNotesConstants from 'shared/features/sentenceNotes/sentenceNotes.constants';

import { SentenceNote } from 'common/AnnotationPanel/elements/SentenceNote';
import { Navigate } from 'react-router-dom';
import { DocumentLabels } from '../DocumentLabels';

import { useProgressiveSentenceNotes } from 'shared/features/sentenceNotes/hooks';
import {
  useAnnotationPanelData,
  useAnnotationPanelHandlers,
  useAnnotationPanelLifecycle
} from './hooks';

import './_annotations-panel.scss';

export const AnnotationPanel: FC<AnnotationPanelProps> = ({ type, document }) => {
  const { reduxState } = useAnnotationPanelData();

  const handlers = useAnnotationPanelHandlers({
    reduxState
  });

  useAnnotationPanelLifecycle({
    onOverlayOpenOnMount: handlers.handleOverlayOpenOnMount,
    onOverlayCloseOnUnmount: handlers.handleOverlayCloseOnUnmount,
    onStateClearOnUnmount: handlers.handleStateClearOnUnmount
  });

  const progressiveSentenceNotes = useProgressiveSentenceNotes({
    type: type,
    documentId: document.id,
    sentenceNoteId: reduxState.selectedSentenceNoteId,
    loaderProps: {
      isContrast: true
    }
  });

  if (!reduxState.isAuthenticated) {
    return <Navigate to={routes.login} />;
  }

  return (
    <div className="annotations-panel__container" data-testid={constants.TEST_ID}>
      {type === sentenceNotesConstants.SENTENCE_NOTE_TYPE.OBLIGATIONS && <elements.Legend />}
      {type === sentenceNotesConstants.SENTENCE_NOTE_TYPE.ANNOTATIONS && (
        <DocumentLabels docId={document.id} />
      )}
      {type === sentenceNotesConstants.SENTENCE_NOTE_TYPE.OBLIGATIONS &&
      reduxState.isAddingDocumentsToWorkflow ? (
        <div className="annotations-panel__loading-message">
          This document is being added to a workflow, please wait while we create obligation tasks
        </div>
      ) : (
        <>
          {!progressiveSentenceNotes.isFetching &&
            !progressiveSentenceNotes.sentenceNotes.length && <elements.NoContent type={type} />}

          {progressiveSentenceNotes.sentenceNotes.map((sentenceNote, i) => (
            <SentenceNote
              key={`${sentenceNote.id}-${i}`}
              document={document}
              sentenceNote={sentenceNote}
            />
          ))}
        </>
      )}

      {progressiveSentenceNotes.loader}
    </div>
  );
};
