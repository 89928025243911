import { safe_analytics } from 'utils/analytics';
import * as api from 'shared/features/feedback/feedback.api';
import { logError } from 'utils/errors';
import { EVENT_CATEGORIES, ACTION_STATUSES } from '../RestrictModalActionButton.constants';

export const useRestrictModalActionButtonHandlers = ({ props, localActions, reduxState }) => {
  const handleSkuAccessRequest = async e => {
    try {
      e.preventDefault();

      localActions.setIsLoading(true);

      safe_analytics('default', 'Feedback', EVENT_CATEGORIES[props.restrictionType]);

      await api.postFeedback({
        email: reduxState.currentUserEmail,
        sku_type: props.restrictionType,
        request_sku_access: true
      });

      localActions.setActionStatus(ACTION_STATUSES.SUCCESS);
    } catch (e) {
      logError(e);
      localActions.setActionStatus(ACTION_STATUSES.ERROR);
    } finally {
      localActions.setIsLoading(false);
    }
  };

  return {
    handleSkuAccessRequest
  };
};
