import type { FC } from 'react';
import type { BucketPermissionsCellProps } from './BucketPermissionsCell.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './BucketPermissionsCell.constants';

import { ShareBucketModal, SHARE_BUCKET_MODAL_TAB_KEYS } from '../ShareBucketModal';

import { useBucketPermissionsCellData, useBucketPermissionsCellHandlers } from './hooks';

import './_bucket-permissions-cell.scss';

export const BucketPermissionsCell: FC<BucketPermissionsCellProps> = ({ cellValue, row }) => {
  const { localState, localActions, formattedData } = useBucketPermissionsCellData({
    cellValue
  });

  const handlers = useBucketPermissionsCellHandlers({
    localActions
  });

  if (!cellValue.bucket.createdBy.id) {
    return <uiLib.TextCell cellValue={constants.PUBLIC_BUCKET_WHO_HAS_ACCESS_FIELD} row={row} />;
  }

  return (
    <>
      <uiLib.Button
        type={uiLib.BUTTON_TYPES.LINK}
        className="bucket-permissions-cell"
        onClick={handlers.handleModalToggle(true)}
      >
        <uiLib.Typography component="span" className="bucket-permissions-cell__name">
          {formattedData.teamsAndMembers}
        </uiLib.Typography>
      </uiLib.Button>
      {localState.isModalOpen && (
        <ShareBucketModal
          bucket={cellValue.bucket}
          initialTab={SHARE_BUCKET_MODAL_TAB_KEYS.MANAGE_BUCKET_ACCESS}
          onModalClose={handlers.handleModalToggle(false)}
          onModalActionSuccess={cellValue.onChangeSuccess}
        />
      )}
    </>
  );
};
