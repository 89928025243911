import { useMemo, useCallback } from 'react';
import { FOLDER_TYPE } from 'components/Folders/Folders.constants';
import { logError } from 'utils/errors';

export const useDocumentActionsRemoveUserFolderHandlers = ({
  props,
  formattedData,
  reduxActions,
  viewReduxActions
}) => {
  const handleRestrictModalShow = useCallback(() => {
    viewReduxActions.showRestrictModal();
  }, [viewReduxActions]);

  const handleFetchCurrentFolder = useCallback(async () => {
    try {
      reduxActions.resetFolderDetails();
      await reduxActions.fetchFolderDetails(props.folderId);
    } catch (e) {
      logError(e, 'Error removing folder documents');
      throw e;
    }
  }, [props, reduxActions]);

  const handleRemoveFolderDocuments = useCallback(async () => {
    if (!formattedData.isDisabled) {
      try {
        await reduxActions.deleteFolderDocuments(props.folderId, {
          folder_ids: props.documentIds.map(documentId => ({
            id: documentId,
            type: FOLDER_TYPE.DOCUMENT
          }))
        });
        reduxActions.clearSelectedItems();
        await reduxActions.fetchSearchResults({
          folder_id: props.folderId,
          folderTimelineView: true,
          limit: props.limit
        });
      } catch (e) {
        logError(e, 'Error removing folder documents');
        throw e;
      }
    }
  }, [props, formattedData, reduxActions]);

  return useMemo(
    () => ({
      handleRestrictModalShow,
      handleFetchCurrentFolder,
      handleRemoveFolderDocuments
    }),
    [handleRestrictModalShow, handleFetchCurrentFolder, handleRemoveFolderDocuments]
  );
};
