import type { TaskDeadlineProps } from '../TaskDeadline.types';
import type { TaskDeadlineData } from './useTaskDeadlineData.types';
import type { TaskDeadlineHandlers } from './useTaskDeadlineHandlers.types';

import * as uiLib from '@compliance.ai/web-components';

import { userBelongsToTeam } from 'components/Restrict';

export const useTaskDeadlineHandlers = ({
  props,
  reduxState,
  localActions,
  formattedData
}: {
  props: Pick<TaskDeadlineProps, 'documentTask' | 'onChange'>;
  reduxState: TaskDeadlineData['reduxState'];
  localActions: TaskDeadlineData['localActions'];
  formattedData: TaskDeadlineData['formattedData'];
}) => {
  const handleDeadlineClick: TaskDeadlineHandlers['handleDeadlineClick'] = () => {
    if (
      userBelongsToTeam({
        current_user: reduxState.currentUser,
        teams: props.documentTask.teams
      }) &&
      formattedData.isEditableByPermission
    ) {
      localActions.setIsEditable(true);
    }
  };

  const handleDeadlineChange: TaskDeadlineHandlers['handleDeadlineChange'] = (field, val) => {
    props.onChange([
      {
        task_id: props.documentTask.id,
        deadline: uiLib.formatDate(val as Parameters<typeof uiLib.formatDate>[0], {
          format: uiLib.DATE_FORMATS.API_DATE_TIME,
          timezone: uiLib.TIMEZONES.UTC,
          shouldKeepLocalTime: false
        })
      }
    ]);

    localActions.setIsEditable(false);
  };

  return {
    handleDeadlineClick,
    handleDeadlineChange
  };
};
