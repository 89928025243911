import type { AutoSummaryHandlers } from './useAutoSummaryHandlers.types';
import type { AutoSummaryTabProps } from '../AutoSummaryTab.types';

import * as analytics from 'utils/analytics';

import { useCallback } from 'react';

export const useAutoSummaryHandlers = ({
  props
}: {
  props: AutoSummaryTabProps;
}): AutoSummaryHandlers => {
  const handleAutoSummaryActionLog: AutoSummaryHandlers['handleAutoSummaryActionLog'] = useCallback(() => {
    analytics.safe_mixpanel_track('Doc Click', {
      eventCategory: 'Doc Details',
      eventAction: 'Document with Auto Summary Click',
      hitType: 'Event',
      eventLabel: props.document.title
    });
  }, [props.document.title]);

  return { handleAutoSummaryActionLog };
};
