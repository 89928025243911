import type * as types from './MapToStateMetadataStage.types';
import type * as docFieldsTypes from 'shared/features/documentFields/documentFields.constants';

import * as helpers from './MapToStateMetadataStage.helpers';
import * as constants from './MapToStateMetadataStage.constants';

export class MapToStateMetadataStage implements types.MapToStateMetadataStage {
  readonly docFields: types.MapToStateMetadataStage['docFields'];
  readonly mapBy: types.MapToStateMetadataStage['mapBy'];

  constructor(args: {
    docFields: types.MapToStateMetadataStage['docFields'];
    mapBy: types.MapToStateMetadataStage['mapBy'];
  }) {
    this.docFields = args.docFields;
    this.mapBy = args.mapBy;
  }

  public run: types.MapToStateMetadataStage['run'] = async args => {
    return args.metadata.flatMap(metadataEntry => {
      const [metadataKey, metadataValue] = metadataEntry;

      const docFieldEntry = this.docFields.find(docField => metadataKey === docField[this.mapBy]);

      if (
        docFieldEntry?.isMetadataKeyField ||
        constants.INTERNAL_META_DATA_FIELDS.includes(
          metadataKey as docFieldsTypes.INTERNAL_DOC_METADATA_FIELDS
        )
      ) {
        return [];
      }

      const data = (Array.isArray(metadataValue)
        ? metadataValue
        : helpers.parseValue(metadataValue)
      ).filter(Boolean);

      const label = docFieldEntry?.label ?? metadataKey;

      return [
        [
          label,
          {
            mappedCaiDocField: data.length ? docFieldEntry?.value ?? null : null,
            data
          }
        ]
      ];
    });
  };
}
