import type { ShareBucketFormProps } from '../ShareBucketForm.types';
import type { ShareBucketFormData } from './useShareBucketFormData.types';

import * as rulesApi from 'shared/features/rules/rules.api';
import * as errorUtils from 'utils/errors';
import * as constants from '../../../ShareBucketModal.constants';
import * as queryKeysConstants from 'constants/QueryKeys';

import { useMutation } from '@tanstack/react-query';

export const useShareBucketFormMutations = ({
  props,
  localActions,
  formattedData
}: {
  props: Pick<ShareBucketFormProps, 'onClose' | 'onActionSuccess' | 'onModalActionStart'>;
  localActions: ShareBucketFormData['localActions'];
  formattedData: ShareBucketFormData['formattedData'];
}) => {
  const shareBucket = useMutation<void, Error, void>({
    mutationKey: [queryKeysConstants.QUERY_KEYS.RULES],
    onMutate: () => {
      props.onModalActionStart?.();
      localActions.setIsSaving(true);
    },
    mutationFn: async () => {
      await Promise.all(formattedData.formParams.map(params => rulesApi.createRule(...params)));
    },
    onSuccess: () => {
      localActions.setSharersOptions([]);
      localActions.setAccessLevel(constants.SHARE_BUCKET_VIEW_ACCESS_OPTION);
      localActions.setNote('');
      localActions.setIsSaving(false);

      props.onClose();
      props.onActionSuccess();
    },
    onError: errorUtils.logReactQueryError
  });

  return {
    shareBucket
  };
};
