import { memo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, TOOLTIP_POSITION } from 'common';
import * as dateUtils from 'utils/date-utils';
import { useCommentInfoData } from './hooks/useCommentInfoData';
import './_comment-info.scss';

const DATE_TOOLTIP_FORMAT = 'MMMM DD, YYYY hh:mm A';

const CommentInfo = memo(({ comment, onEditClick, onDeleteClick, isEditableByAuthor }) => {
  const { commentAuthorEmail, isCommentEditable } = useCommentInfoData({
    comment,
    isEditableByAuthor
  });

  return (
    <div className="comment-info__container">
      <span className="comment-info__item">{commentAuthorEmail}</span>
      <span className="comment-info__item">
        <Tooltip
          id={`${comment.id}-comment-date-tooltip`}
          title={dateUtils.formatDate(comment.createdAt, DATE_TOOLTIP_FORMAT)}
          placement={TOOLTIP_POSITION.BOTTOM}
        >
          {dateUtils.formatDate(comment.createdAt)}
        </Tooltip>
      </span>
      {isCommentEditable && (
        <>
          <span onClick={onEditClick} className="comment-info__item--clickable">
            Edit
          </span>
          <span onClick={onDeleteClick} className="comment-info__item--clickable">
            Delete
          </span>
        </>
      )}
    </div>
  );
});

export default CommentInfo;

CommentInfo.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.number,
    richtext: PropTypes.string,
    userId: PropTypes.number,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    repliedTo: PropTypes.number
  }).isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  isEditableByAuthor: PropTypes.bool
};

CommentInfo.defaultProps = {
  isEditableByAuthor: true
};
