import { GET_WIDGETS } from './widgets.actions';

export const WIDGETS_INITIAL_STATE = {
  default_widgets: null,
  user_widgets: null
};

export const widgets = (state = WIDGETS_INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_WIDGETS:
      return {
        ...state,
        default_widgets: action.payload.default_widgets,
        user_widgets: action.payload.user_widgets
      };
    default:
      return state;
  }
};
