import type { WorkflowDetailsHandlers } from './useWorkflowDetailsHandlers.types';

import { useDidUpdate } from '@compliance.ai/web-components';

export const useWorkflowDetailsLifecycle = ({
  onDataRefetch
}: {
  onDataRefetch: WorkflowDetailsHandlers['handleDataRefetch'];
}) => {
  useDidUpdate(onDataRefetch, [onDataRefetch]);
};
