import { CognitoWindowedSignInForm } from '../CognitoWindowedSignInForm';
import { CorporateLogin } from '../CorporateLogin';
import { useAuthReduxActions } from 'shared/features/auth/hooks';
import { useCorporateLoginWindowedFormHandlers } from './hooks';
import PropTypes from 'prop-types';

export const CorporateLoginWindowedForm = ({
  onError,
  onSuccess,
  orgInvitationToken,
  ...restProps
}) => {
  const reduxActions = useAuthReduxActions();

  const {
    handleCorporateLoginError,
    handleCorporateLoginSuccess
  } = useCorporateLoginWindowedFormHandlers({
    props: {
      onError,
      onSuccess,
      orgInvitationToken
    },
    reduxActions
  });

  return (
    <CognitoWindowedSignInForm
      onError={handleCorporateLoginError}
      onSignIn={handleCorporateLoginSuccess}
    >
      {({ showWindow }) => (
        <CorporateLogin
          onError={onError}
          showWindow={showWindow}
          orgInvitationToken={orgInvitationToken}
          {...restProps}
        />
      )}
    </CognitoWindowedSignInForm>
  );
};

CorporateLoginWindowedForm.propTypes = {
  buttonText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  activationUrl: PropTypes.bool,
  orgInvitationToken: PropTypes.string,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  isLegacy: PropTypes.bool,
  isDisabled: PropTypes.bool
};

CorporateLoginWindowedForm.defaultProps = {
  buttonText: 'Corporate Login',
  activationUrl: false,
  orgInvitationToken: undefined,
  onError: () => {},
  onSuccess: () => {},
  isLegacy: false,
  isDisabled: false
};
