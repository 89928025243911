import {
  fetchFullDocuments as reduxFetchFullDocuments,
  fetchRelatedDocumentCount as reduxFetchRelatedDocumentCount,
  fetchDocumentCommentsCount as reduxFetchDocumentCommentsCount,
  fetchDocument as reduxFetchDocument,
  fetchDocumentsForDashAlertWidget as reduxFetchDocumentsForDashAlertWidget,
  fetchDiff as reduxFetchDiff,
  REQUEST_DOCUMENTS,
  RECEIVE_DOCUMENTS,
  fetchDocuments as reduxFetchDocuments,
  addDocsToDiff as reduxAddDocsToDiff,
  markDocumentBookmarked as reduxMarkDocumentBookmarked,
  setDocumentSavedFolderCount as reduxSetDocumentSavedFolderCount,
  fetchSentences as reduxFetchSentences,
  fetchSearchResults as reduxFetchSearchResults,
  fetchAggregateEnforcements as reduxFetchAggregatedEnforcements,
  resetDocumentDetails as reduxResetDocumentDeteails,
  fetchGoogleResults as reduxFetchGoogleResults,
  fetchDocumentsForDashCovid as reduxFetchDocumentsForDashCovid,
  fetchDocumentsForDashCCPA as reduxFetchDocumentsForDashCCPA
} from '../documents.actions';
import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';

export const useDocumentsReduxActions = () => {
  const dispatch = useDispatch();

  const fetchResourceCode = useCallback(
    id => {
      return dispatch(
        reduxFetchFullDocuments({ id, resource_code: true, decorate_children: true })
      );
    },
    [dispatch]
  );

  const fetchDocumentsForDashCovid = useCallback(
    (...args) => {
      return dispatch(reduxFetchDocumentsForDashCovid(...args));
    },
    [dispatch]
  );

  const fetchDocumentDetails = useCallback(
    id => {
      return dispatch(reduxFetchFullDocuments({ id, doc_details: true }));
    },
    [dispatch]
  );

  const fetchRelatedDocumentCount = useCallback(
    async documentId => {
      return await dispatch(reduxFetchRelatedDocumentCount({ more_like_doc_id: documentId }));
    },
    [dispatch]
  );

  const fetchDocumentCommentsCount = useCallback(
    async documentId => {
      return await dispatch(reduxFetchDocumentCommentsCount(documentId));
    },
    [dispatch]
  );

  const fetchDocument = useCallback(
    async documentId => {
      return await dispatch(reduxFetchDocument(documentId));
    },
    [dispatch]
  );

  const fetchDocumentsForDashAlertWidget = useCallback(
    async (widgetId, params) => {
      return await dispatch(reduxFetchDocumentsForDashAlertWidget(widgetId, params));
    },
    [dispatch]
  );

  const fetchDiff = useCallback(
    async params => {
      return await dispatch(reduxFetchDiff(params));
    },
    [dispatch]
  );

  const fetchDocuments = useCallback(
    async ({
      params = {},
      extraParams,
      customToken,
      requestType = REQUEST_DOCUMENTS,
      receiveType = RECEIVE_DOCUMENTS,
      widgetId
    } = {}) => {
      return await dispatch(
        reduxFetchDocuments(params, extraParams, customToken, requestType, receiveType, widgetId)
      );
    },
    [dispatch]
  );

  const addDocsToDiff = useCallback(
    documents => {
      return dispatch(reduxAddDocsToDiff(documents));
    },
    [dispatch]
  );

  const markDocumentBookmarked = useCallback(
    (documentIds, bookmarked_status) => {
      return dispatch(reduxMarkDocumentBookmarked(documentIds, bookmarked_status));
    },
    [dispatch]
  );

  const setDocumentSavedFolderCount = useCallback(
    (documentId, count) => {
      dispatch(reduxSetDocumentSavedFolderCount(documentId, count));
    },
    [dispatch]
  );

  const fetchSentences = useCallback(
    (docIds, use_obligation_version, contentType) => {
      return dispatch(reduxFetchSentences(docIds, use_obligation_version, contentType));
    },
    [dispatch]
  );

  const fetchSearchResults = useCallback(
    params => {
      return dispatch(reduxFetchSearchResults(params));
    },
    [dispatch]
  );

  const fetchAggregatedEnforcements = useCallback(
    params => {
      return dispatch(reduxFetchAggregatedEnforcements(params));
    },
    [dispatch]
  );

  const resetDocumentDetails = useCallback(() => {
    return dispatch(reduxResetDocumentDeteails());
  }, [dispatch]);

  const fetchGoogleResults = useCallback(
    params => {
      return dispatch(reduxFetchGoogleResults(params));
    },
    [dispatch]
  );

  const fetchDocumentsForDashCCPA = useCallback(
    (...args) => {
      return dispatch(reduxFetchDocumentsForDashCCPA(...args));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      fetchDocumentsForDashCovid,
      fetchResourceCode,
      fetchDocumentDetails,
      fetchRelatedDocumentCount,
      fetchDocumentCommentsCount,
      fetchDocument,
      fetchDocumentsForDashAlertWidget,
      fetchDiff,
      addDocsToDiff,
      fetchDocuments,
      markDocumentBookmarked,
      setDocumentSavedFolderCount,
      fetchSentences,
      fetchSearchResults,
      fetchAggregatedEnforcements,
      resetDocumentDetails,
      fetchGoogleResults,
      fetchDocumentsForDashCCPA
    }),
    [
      fetchDocumentsForDashCovid,
      fetchDocumentCommentsCount,
      fetchDocumentDetails,
      fetchRelatedDocumentCount,
      fetchResourceCode,
      fetchDocument,
      fetchDocumentsForDashAlertWidget,
      fetchDiff,
      addDocsToDiff,
      fetchDocuments,
      markDocumentBookmarked,
      setDocumentSavedFolderCount,
      fetchSentences,
      fetchSearchResults,
      fetchAggregatedEnforcements,
      resetDocumentDetails,
      fetchGoogleResults,
      fetchDocumentsForDashCCPA
    ]
  );
};
