import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';

import { SOURCE_SELECTION_CONTAINER_ID } from './SourceSelectionContainer.constants';

export const SourceSelectionContainer = ({
  selectAll,
  clearAll,
  elements,
  title,
  customBulkElements,
  notificationBanner,
  isDisabled = false
}) => {
  return (
    <div className="source-selection-container" data-testid="source-selection-container">
      {notificationBanner}
      <div className="source-selection-container-bulk-selection">
        <Button
          type={BUTTON_TYPES.LINK}
          onClick={selectAll}
          className="source-selection-container-select-all"
          isDisabled={isDisabled}
        >
          Select All
        </Button>
        <div
          className={classnames('source-selection-container-bulk-selection-divider', {
            disabled: isDisabled
          })}
        >
          {' | '}
        </div>
        <Button type={BUTTON_TYPES.LINK} onClick={clearAll} isDisabled={isDisabled}>
          Clear All
        </Button>
        {customBulkElements}
      </div>
      {title && <h2>{title}</h2>}
      <div className="default-doctypes-container-buttons" id={SOURCE_SELECTION_CONTAINER_ID}>
        {elements}
      </div>
    </div>
  );
};

SourceSelectionContainer.propTypes = {
  selectAll: PropTypes.func.isRequired,
  clearAll: PropTypes.func.isRequired,
  elements: PropTypes.arrayOf(PropTypes.element).isRequired,
  customBulkElements: PropTypes.arrayOf(PropTypes.element),
  title: PropTypes.string,
  isDisabled: PropTypes.bool
};

SourceSelectionContainer.defaultProps = {
  title: null,
  customBulkElements: [],
  isDisabled: false
};
