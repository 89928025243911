import PropTypes from 'prop-types';
import classnames from 'classnames';
import OptionsList from './elements/optionsList/OptionsList';
import SelectedValue from './elements/SelectedValue';

import {
  GROUP_SELECT_FILTER_TYPES,
  GROUP_SELECT_CONTROL_CLASSNAME,
  GROUP_SELECT_PLACEHOLDER_CLASSNAME,
  GROUP_SELECT_INPUT_CLASSNAME
} from './GroupSelectFilter.constants';

import { useState, useEffect, useRef } from 'react';

import './_groupSelectFilter.scss';

export const TEST_ID = {
  CONTAINER: 'group-select-filter-container',
  CONTROL: 'group-select-filter-control',
  SINGLE_VALUE: 'group-select-selected-single-value',
  ARROW_ZONE: 'group-select-filter-arrow-zone',
  CLEAR_ALL_BUTTON: 'group-select-filter-clear-all'
};

const GroupSelectFilter = ({
  values,
  onChange,
  options,
  defaultText,
  type,
  isMulti,
  isOnlyList = false,
  className,
  classNames = {}
}) => {
  const [search, setSearch] = useState('');
  const [active, setActive] = useState(false);
  const groupSelectFilter = useRef(null);
  const controlRef = useRef(null);
  const textInput = useRef(null);

  useEffect(() => {
    function handleDocumentMousedown(e) {
      if (groupSelectFilter.current && groupSelectFilter.current.contains(e.target)) {
        return;
      }

      if (controlRef.current && controlRef.current.scrollTo) {
        controlRef.current.scrollTo(0, 0);
      }

      setActive(false);
    }
    document.addEventListener('mousedown', handleDocumentMousedown);
    return () => document.removeEventListener('mousedown', handleDocumentMousedown);
  }, []);

  useEffect(() => {
    // Clear search text in single selection mode
    setSearch('');
  }, [active]);

  function buildSelectedValuesHash({ filter = false }) {
    const valueHash = {};
    const valuesToUse = filter
      ? values.filter(value => value.label.toLowerCase().indexOf(search.toLocaleLowerCase()) >= 0)
      : values;

    valuesToUse.forEach(value => {
      valueHash[value.value] = true;
    });

    return valueHash;
  }

  function handleOptionClick(label, value) {
    function checkAgainstValue(element) {
      return element.value === value;
    }

    const valueIndex = values.findIndex(checkAgainstValue);
    if (valueIndex === -1) {
      const option = { label: label, value: value };
      if (isMulti) {
        values.push(option);
      } else {
        values = [option];
      }
    } else {
      values.splice(valueIndex, 1);
    }

    onChange(values);
    setSearch('');

    if (!isMulti) {
      setActive(false);
    }
  }

  function handleLabelSelect(label) {
    if (!isMulti) {
      return;
    }

    function checkAgainstLabel(element) {
      return element.label === label;
    }
    function filterLabelOptions(labelOptions) {
      return labelOptions.filter(option => {
        return option.label.toLowerCase().indexOf(search.toLocaleLowerCase()) >= 0;
      });
    }

    const labelIndex = options.findIndex(checkAgainstLabel);
    const labelInSearch =
      options[labelIndex].label.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0;

    const selectedValuesHash = labelInSearch
      ? buildSelectedValuesHash({})
      : buildSelectedValuesHash({ filter: true });
    const labelOptions = labelInSearch
      ? options[labelIndex].options
      : filterLabelOptions(options[labelIndex].options);

    const alreadySelectedOptions = [];
    const notSelectedOptions = [];

    labelOptions.forEach(option => {
      if (selectedValuesHash[option.value]) {
        alreadySelectedOptions.push(option);
      } else {
        notSelectedOptions.push(option);
      }
    });

    if (
      !alreadySelectedOptions.length ||
      (alreadySelectedOptions.length && notSelectedOptions.length)
    ) {
      values = values.concat(notSelectedOptions);
    } else {
      alreadySelectedOptions.forEach(option => {
        function checkAgainstOption(element) {
          return element.value === option.value;
        }

        const valueIndex = values.findIndex(checkAgainstOption);
        values.splice(valueIndex, 1);
      });
    }

    onChange(values);
  }

  function focusInput() {
    textInput.current.focus();
    setActive(true);
  }

  function formatSelectedValues() {
    const groupSelectSelectedPlaceholderClassName = GROUP_SELECT_PLACEHOLDER_CLASSNAME[type];

    if (values.length === 0 && search.length === 0) {
      return <div className={groupSelectSelectedPlaceholderClassName}>{defaultText}</div>;
    }

    if (!isMulti && values.length) {
      if (search.length === 0)
        return (
          <div className="group-select-selected-single-value" data-testid={TEST_ID.SINGLE_VALUE}>
            {values[0].label}
          </div>
        );
      return null;
    }

    const formatedSelectedValues = values.map(value => {
      return (
        <SelectedValue
          key={value.label}
          label={value.label}
          value={value.value}
          handleOptionClick={handleOptionClick}
        />
      );
    });

    return formatedSelectedValues;
  }

  const groupSelectControlClassName = GROUP_SELECT_CONTROL_CLASSNAME[type];
  const groupSelectInputClassName = GROUP_SELECT_INPUT_CLASSNAME[type];

  return (
    <div
      data-testid={TEST_ID.CONTAINER}
      className={classnames('group-select-filter', className, {
        'group-select-filter--single': !isMulti
      })}
      ref={groupSelectFilter}
    >
      {!isOnlyList && (
        <div
          ref={controlRef}
          className={classnames(groupSelectControlClassName, {
            'group-select-control--is-expanded': active
          })}
        >
          <div
            data-testid={TEST_ID.CONTROL}
            className="group-select-control-wrapper"
            onClick={() => focusInput()}
          >
            {formatSelectedValues()}
            <div className={groupSelectInputClassName}>
              <input
                type="text"
                value={search}
                onChange={e => setSearch(e.target.value)}
                ref={textInput}
                className="group-select-input-element"
              />
            </div>
          </div>
          <div className="group-select-indicators">
            {values.length > 0 && (
              <div
                data-testid={TEST_ID.CLEAR_ALL_BUTTON}
                className="group-select-clear-zone"
                onClick={() => onChange([])}
              >
                <div className="group-select-clear">×</div>
              </div>
            )}
            <div
              data-testid={TEST_ID.ARROW_ZONE}
              className="group-select-select-arrow-zone"
              onClick={() => setActive(!active)}
            >
              {active ? (
                <div className="group-select-select-arrow arrow-up" />
              ) : (
                <div className="group-select-select-arrow arrow-down" />
              )}
            </div>
          </div>
        </div>
      )}
      {(active || isOnlyList) && (
        <OptionsList
          searchText={search.toLocaleLowerCase()}
          options={options}
          checkedState={buildSelectedValuesHash({})}
          handleOptionClick={handleOptionClick}
          handleLabelSelect={handleLabelSelect}
          classNames={{
            container: classnames(classNames.optionsList, 'group-select-filter__options-list')
          }}
        />
      )}
    </div>
  );
};

GroupSelectFilter.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        })
      )
    })
  ).isRequired,
  values: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  defaultText: PropTypes.string,
  type: PropTypes.oneOf(Object.values(GROUP_SELECT_FILTER_TYPES)),
  isMulti: PropTypes.bool,
  isOnlyList: PropTypes.bool,
  classNames: PropTypes.shape({
    optionsList: PropTypes.string
  })
};

GroupSelectFilter.defaultProps = {
  className: undefined,
  defaultText: 'Select...',
  type: GROUP_SELECT_FILTER_TYPES.PRIMARY,
  isMulti: true,
  isOnlyList: false,
  classNames: {}
};

export default GroupSelectFilter;
