import { isValidSKU } from 'utils/skus';

export const formatPermissions = organizationRoles => {
  return organizationRoles.reduce((result, { permissions }) => {
    for (const permission of permissions) {
      result[permission.name] = true;
    }

    return result;
  }, {});
};

export const formatSKUs = skus => {
  return skus.reduce((result, sku) => {
    if (isValidSKU(sku.restriction_type)) {
      result[sku.restriction_type] = sku;
    }

    return result;
  }, {});
};
