import type { FC } from 'react';

import classnames from 'classnames';

import * as uiLib from '@compliance.ai/web-components';
import * as common from 'common/index';

import { useDocDiff } from 'shared/features/documents/hooks';
import { useDocumentWorkspaceData } from './hooks';

import './_document-workspace.scss';

export const DocumentWorkspace: FC = () => {
  const { formattedData } = useDocumentWorkspaceData();

  const docsDiff = useDocDiff({ docIdsToCompare: formattedData.docIdsToCompare });

  return (
    <div
      className={classnames('document-workspace__container', {
        'document-workspace__container--is-loading': docsDiff.isFetching
      })}
    >
      {docsDiff.isFetching ? (
        <uiLib.Loader size={uiLib.LOADER_SIZES.MEDIUM} />
      ) : (
        <common.DocumentsDiff diff={docsDiff.diff} />
      )}
    </div>
  );
};
