import type { DocumentFromResponse } from 'shared/features/documents/documents.types';

import * as uiLib from '@compliance.ai/web-components';

export function getKeyDates(document: DocumentFromResponse): string {
  if (!document.rule) {
    return '';
  }

  const dates: string[] = [];

  if (document.rule.comments_close_on) {
    dates.push(
      `Comments should be received on or before ${uiLib.formatDate(
        document.rule.comments_close_on,
        {
          format: uiLib.DATE_FORMATS.DOCUMENT_DATE,
          shouldKeepLocalTime: true,
          timezone: uiLib.TIMEZONES.UTC,
          passedTimezone: uiLib.TIMEZONES.UTC
        }
      )}`
    );
  }

  if (document.rule.effective_on) {
    dates.push(
      `Effective ${uiLib.formatDate(document.rule.effective_on, {
        format: uiLib.DATE_FORMATS.DOCUMENT_DATE,
        shouldKeepLocalTime: true,
        timezone: uiLib.TIMEZONES.UTC,
        passedTimezone: uiLib.TIMEZONES.UTC
      })}`
    );
  }

  return dates.join('. ');
}
