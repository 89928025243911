import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import * as authApi from './auth.api';
import { StatusCodes } from 'http-status-codes';
import { EXPECTED_ERROR_MESSAGES } from './auth.constants';

export const SET_TOKENS = 'SET_TOKENS';
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const AUTHENTICATE_USER_USING_COGNITO_CODE = 'AUTHENTICATE_USER_USING_COGNITO_CODE';
export const SIGN_UP_USER = 'SIGN_UP_USER';
export const SEND_PASSWORD_RESET_EMAIL = 'SEND_PASSWORD_RESET_EMAIL';
export const REFRESH_SESSION_TOKENS = 'REFRESH_SESSION_TOKENS';
export const CONFIRM_NEW_PASSWORD = 'CONFIRM_NEW_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SIGN_OUT = 'SIGN_OUT';
export const GET_EXTERNAL_API_TOKEN = 'GET_EXTERNAL_API_TOKEN';
export const GENERATE_EXTERNAL_API_TOKEN = 'GENERATE_EXTERNAL_API_TOKEN';

export const setTokens = createAction(SET_TOKENS);

export const authenticateUser = createAsyncThunk(
  AUTHENTICATE_USER,
  async ({ email, password }, { dispatch }) => {
    try {
      await authApi.signOut();

      const tokens = await authApi.authenticate({
        email,
        password
      });

      dispatch(setTokens(tokens));

      return tokens;
    } catch (e) {
      throw e;
    }
  }
);

export const authenticateUsingCognitoCode = createAsyncThunk(
  AUTHENTICATE_USER_USING_COGNITO_CODE,
  async ({ code, orgInvitationToken }, { dispatch }) => {
    try {
      await authApi.signOut();

      const tokens = await authApi.authenticateUsingCognitoCode({
        code,
        orgInvitationToken
      });

      dispatch(setTokens(tokens));

      return tokens;
    } catch (e) {
      throw e;
    }
  }
);

export const signUpUser = createAsyncThunk(
  SIGN_UP_USER,
  async ({ email, password, orgInvitationToken }, { dispatch }) => {
    try {
      await authApi.signOut();

      const response = await authApi.signUp({
        email,
        password,
        orgInvitationToken
      });

      const statusCode = Array.isArray(response) ? response[1] : StatusCodes.OK;

      if (StatusCodes.INTERNAL_SERVER_ERROR === statusCode) {
        throw new Error(EXPECTED_ERROR_MESSAGES.VALIDATION_ERROR);
      }

      const tokens = await authApi.authenticate({
        email,
        password
      });

      dispatch(setTokens(tokens));

      return tokens;
    } catch (e) {
      throw e;
    }
  }
);

export const refreshSessionTokens = createAsyncThunk(
  REFRESH_SESSION_TOKENS,
  async ({} = {}, { dispatch }) => {
    try {
      const tokens = await authApi.refreshSession();

      dispatch(setTokens(tokens));

      return tokens;
    } catch (e) {
      throw e;
    }
  }
);

export const sendPasswordResetEmail = createAsyncThunk(
  SEND_PASSWORD_RESET_EMAIL,
  async ({ email }) => {
    try {
      await authApi.sendPasswordResetEmail({
        email
      });
    } catch (e) {
      throw e;
    }
  }
);

export const confirmNewPassword = createAsyncThunk(
  CONFIRM_NEW_PASSWORD,
  async ({ email, verificationCode, newPassword }) => {
    try {
      await authApi.confirmNewPassword({
        email,
        verificationCode,
        newPassword
      });
    } catch (e) {
      throw e;
    }
  }
);

export const changePassword = createAsyncThunk(
  CHANGE_PASSWORD,
  async ({ oldPassword, newPassword }) => {
    try {
      await authApi.changePassword({ oldPassword, newPassword });
    } catch (e) {
      throw e;
    }
  }
);

export const signOut = createAsyncThunk(SIGN_OUT, async () => {
  try {
    await authApi.signOut();
  } catch (e) {
    throw e;
  }
});

export const getExternalApiToken = createAsyncThunk(
  GET_EXTERNAL_API_TOKEN,
  async ({ approvedAppKey }) => {
    try {
      return await authApi.getExternalApiToken({
        application_key: approvedAppKey
      });
    } catch (e) {
      throw e;
    }
  }
);

export const generateExternalApiToken = createAsyncThunk(GENERATE_EXTERNAL_API_TOKEN, async () => {
  try {
    return await authApi.generateExternalApiToken();
  } catch (e) {
    throw e;
  }
});
