import * as filterConstants from 'constants/PrimaryFilter';
import * as filterOptionsHelpers from './useFilterOptions.helpers';
import * as utilsConstants from 'utils/widget-utils';

import lodash from 'lodash';

export const formatDefaultCategories = ({ categories, allCategories }) => {
  const result = [];

  if (lodash.isObject(categories)) {
    Object.keys(categories)
      .filter(categoryId => categories[categoryId])
      .forEach(categoryId => {
        const all_active_ids = allCategories[categoryId]?.all_active_ids || [];
        for (const id of all_active_ids) {
          if (allCategories[id]) {
            result.push(allCategories[id]);
          }
        }
      });
  }

  return result.map(formatDefaultCategory);
};

export const formatDefaultCategory = category => {
  return { label: category.name, value: category.id };
};

export const formatDefaultEnforcementsCategories = ({
  metaCategoriesObject,
  followedCategories
}) => {
  const enforcementMetacategoryID = Object.keys(metaCategoriesObject).filter(metacategoryID => {
    return metaCategoriesObject[metacategoryID].name === filterConstants.ENFORCEMENTS_CATEGORY;
  })[0];

  const allEnforcementsCategories =
    metaCategoriesObject[enforcementMetacategoryID]?.categories || [];

  const followedEnforcementsCategories = allEnforcementsCategories.filter(category => {
    return category.id in followedCategories;
  });

  return followedEnforcementsCategories.map(formatDefaultCategory);
};

export const formatDefaultConcepts = (followedConcepts, conceptOptions) => {
  if (lodash.isObject(followedConcepts) && Array.isArray(conceptOptions)) {
    return conceptOptions.filter(({ value }) => followedConcepts[String(value)]);
  }
  return [];
};

export const formatDefaultTopics = ({ followedTopics, defaultTopics }) => {
  const namedTopics = defaultTopics;

  const topics = [];

  for (const followedTopic of followedTopics) {
    for (const namedTopic of namedTopics) {
      if (followedTopic.topic_id === namedTopic.id) {
        followedTopic.label = namedTopic.label;
        followedTopic.value = namedTopic.id;
        topics.push(followedTopic);
      }
    }
  }

  return topics;
};

export const getFormattedDefaultOptions = ({
  followedAgencies,
  followedJurisdictions,
  followedMainstreamNewsSources,
  defaultTopics,
  followedTopics,
  followedCategories,
  allCategories,
  metaCategoriesObject,
  followedConcepts,
  allConceptOptions
}) => {
  const agencyOptions = filterOptionsHelpers.formatAgencyOptions(followedAgencies);
  const jurisdictionOptions = filterOptionsHelpers.formatJurisdictionsOptions(
    followedJurisdictions
  );
  const mainstreamNewsSourcesOptions = filterOptionsHelpers.formatMainstreamNewsOptions(
    followedMainstreamNewsSources
  );
  const topicsOptions = formatDefaultTopics({
    defaultTopics,
    followedTopics
  });
  const docTypesOptions = formatDefaultCategories({
    categories: followedCategories,
    allCategories
  });
  const enforcementsDocTypesOptions = formatDefaultEnforcementsCategories({
    metaCategoriesObject,
    followedCategories
  });
  const conceptOptions = formatDefaultConcepts(followedConcepts, allConceptOptions);

  return {
    [filterConstants.FILTER_KEY.AGENCIES]: agencyOptions,
    [filterConstants.FILTER_KEY.JURISDICTIONS]: jurisdictionOptions,
    [filterConstants.FILTER_KEY.MAINSTREAM_NEWS_SOURCES]: mainstreamNewsSourcesOptions,
    [filterConstants.FILTER_KEY.TOPICS]: topicsOptions,
    [filterConstants.FILTER_KEY.DOC_TYPES]: docTypesOptions,
    [filterConstants.FILTER_KEY.CONCEPTS]: conceptOptions,
    [filterConstants.CUSTOM_FILTER_OPTIONS_KEYS.ENFORCEMENTS_CATEGORY]: enforcementsDocTypesOptions
  };
};

export const getFollowedMainstreamNewsSourcesFromEntities = followedEntities => {
  return followedEntities.filter(
    entity => entity.entity_type === utilsConstants.NEWS_SOURCES_ENTITY_TYPE && entity.following
  );
};
