import PropTypes from 'prop-types';
import { FORM_TYPES } from '../../Feedback.constants';

export const TERMS = {
  [FORM_TYPES.TEAM]: 'request',
  [FORM_TYPES.PRO]: 'request',
  [FORM_TYPES.SCHEDULED_DEMO]: 'feedback',
  [FORM_TYPES.TOPICS]: 'feedback',
  [FORM_TYPES.JURISDICTION]: 'feedback',
  [FORM_TYPES.AGENCY]: 'feedback',
  [FORM_TYPES.NEWS_SOURCES]: 'feedback',
  [FORM_TYPES.DEFAULT]: 'feedback'
};

export const FeedbackError = ({ formType }) => {
  return (
    <form className="feedback__form">
      <p className="feedback__response-text">
        {`Could not send your ${TERMS[formType]}. Please try again later.`}
      </p>
    </form>
  );
};

FeedbackError.propTypes = {
  formType: PropTypes.string
};

FeedbackError.defaultProps = {
  formType: FORM_TYPES.DEFAULT
};
