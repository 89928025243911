import type { WorkflowPanelTaskData } from './useWorkflowPanelTaskData.types';
import type { WorkflowPanelTaskProps } from '../WorkflowPanelTask.types';
import type { GlobalState } from 'shared/reducers';

import * as taskUtils from 'utils/task-utils';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { usePermissions } from 'utils/hooks';

import { CREATE_COMMENTS } from 'constants/Permissions';

import { getOrganizationReducer } from 'shared/features/organizations/organizations.selectors';
import { getCurrentDocument } from 'shared/features/documents/documents.selectors';
import { getUserTimezone } from 'shared/features/user/user.selectors';

import {
  isAnnotationRequirementFulfilled,
  TASK_EDITABLE_FIELDS_VALIDATORS
} from './useWorkflowPanelTaskData.helpers';
import { getUserId } from 'shared/features/user/user.selectors';
import { isMobileView } from 'shared/features/view/view.selectors';
import { TASK_STATUS_DONE } from 'constants/TaskStatuses';
import { isDocTaskBlocked } from 'shared/features/workflow/workflow.helpers';

export const useWorkflowPanelTaskData = ({
  documentTask
}: Pick<WorkflowPanelTaskProps, 'documentTask'>): WorkflowPanelTaskData => {
  const permissions = usePermissions();

  const reduxState = useSelector<GlobalState, WorkflowPanelTaskData['reduxState']>(state => ({
    organization: getOrganizationReducer(state),
    currentUserId: getUserId(state),
    isMobileView: isMobileView(state),
    currentDocument: getCurrentDocument(state) || {},
    userTimezone: getUserTimezone(state)
  }));

  const formattedData: WorkflowPanelTaskData['formattedData'] = useMemo(() => {
    const documentTaskValues = Object.entries(TASK_EDITABLE_FIELDS_VALIDATORS).reduce(
      (values, [fieldKey, validator]) => {
        return {
          ...values,
          [fieldKey]: validator(documentTask, reduxState.userTimezone)
        };
      },
      {} as WorkflowPanelTaskData['formattedData']['documentTaskValues']
    );
    const isObligationTask = documentTask.is_obligation_super_task;
    const wasDocumentSentencified = Boolean(reduxState.currentDocument.has_sentences);
    const isObligationsLinkVisible = isObligationTask && wasDocumentSentencified;
    const isTaskAssignee = documentTask.assignee === reduxState.currentUserId;
    const isTaskDone = documentTask.task_status === TASK_STATUS_DONE;
    const isTaskOverdue = isTaskAssignee && taskUtils.isOverdueTaskStatus(documentTask);
    const isMobileView = reduxState.isMobileView;
    const areAnnotationsEditable = isTaskAssignee && !isTaskDone && !isMobileView;
    const areRepliesEnabled = permissions[CREATE_COMMENTS];
    const areAllAnnotationsCompleted = isAnnotationRequirementFulfilled({
      documentTask: documentTask,
      documentTaskValues: documentTaskValues
    });
    const isBlocked = isDocTaskBlocked(documentTask);

    return {
      documentTaskValues,
      isObligationTask,
      wasDocumentSentencified,
      isObligationsLinkVisible,
      isTaskAssignee,
      isMobileView,
      areAnnotationsEditable,
      areRepliesEnabled,
      areAllAnnotationsCompleted,
      isBlocked,
      isTaskOverdue
    };
  }, [
    documentTask,
    permissions,
    reduxState.currentDocument.has_sentences,
    reduxState.currentUserId,
    reduxState.isMobileView,
    reduxState.userTimezone
  ]);

  return {
    reduxState,
    formattedData
  };
};
