import PropTypes from 'prop-types';
import { ErrorBoundary, Select } from '@compliance.ai/web-components';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {
  MAX_DOCUMENTS_SHOW,
  ORDER_OPTIONS,
  RELEVANCE_OPTION,
  SORT_OPTIONS,
  VIEW_TYPE_PAGE
} from 'constants/ActionBar';
import { SUPPORTED_QUERY_PARAMS } from 'constants/PrimaryFilter';
import classnames from 'classnames';
import { AdditionalFilters, DocumentsBulkExport } from './elements';
import { useViewReduxActions } from 'shared/features/view/hooks';
import { useDocumentsReduxActions } from 'shared/features/documents/hooks';
import { useUserReduxActions } from 'shared/features/user/hooks';
import { useFilterReduxActions } from 'shared/features/filters/hooks';
import { useActionBarData, useActionBarLifecycle, useActionBarHandlers } from './hooks';
import './_action-bar.scss';

export const ActionBar = ({
  selectedDocsIds,
  shouldShowWithCombinedDocs,
  selectedOverDailyLimit,
  onExport,
  section
}) => {
  const { localState, localActions, reduxState, formattedData } = useActionBarData({
    shouldShowWithCombinedDocs,
    section
  });

  const userReduxActions = useUserReduxActions();
  const viewReduxActions = useViewReduxActions();
  const documentsReduxActions = useDocumentsReduxActions();
  const filterReduxActions = useFilterReduxActions();

  useActionBarLifecycle({
    localState,
    localActions,
    reduxState,
    userReduxActions
  });

  const handlers = useActionBarHandlers({
    props: {
      onExport,
      shouldShowWithCombinedDocs
    },
    localState,
    localActions,
    reduxState,
    formattedData,
    viewReduxActions,
    documentsReduxActions,
    filterReduxActions
  });

  return (
    <div className="action-bar__container">
      <ErrorBoundary>
        <div className="action-bar__docs-count">
          {`${formattedData.totalCount} Document${
            formattedData.totalCount === 1 ? '' : 's'
          } from Compliance.ai${
            formattedData.totalCount > MAX_DOCUMENTS_SHOW
              ? ` (showing ${formattedData.decoratedCount.totalCount})`
              : ''
          } -`}
          &nbsp;
          <strong>{`${selectedDocsIds.length} selected`}</strong>
        </div>
        <DocumentsBulkExport
          selectedDocsIds={selectedDocsIds}
          selectedOverDailyLimit={selectedOverDailyLimit}
          onExport={handlers.handleBulkExport}
        />
        {formattedData.viewType === VIEW_TYPE_PAGE && (
          <div className="action-bar__sort-and-order-wrapper">
            <Select
              options={SORT_OPTIONS}
              onChange={handlers.handleParamSelect(SUPPORTED_QUERY_PARAMS.SEARCH_SORT)}
              value={localState.currentSort}
              placeholder="Sort by"
              isClearable={false}
              isDisabled={localState.isLoading}
            />
            <Select
              options={ORDER_OPTIONS}
              onChange={handlers.handleParamSelect(SUPPORTED_QUERY_PARAMS.ORDER)}
              value={localState.currentOrder}
              placeholder="Order by"
              isClearable={false}
              isDisabled={
                localState.isLoading || localState.currentSort.value === RELEVANCE_OPTION.value
              }
            />
          </div>
        )}
        <div className="action-bar__pagination">
          <span className="action-bar__pagination-count">
            {`${formattedData.decoratedCount.fromCount}-${formattedData.decoratedCount.toCount} of ${formattedData.decoratedCount.totalCount}`}
          </span>
          <div className="action-bar__pagination-arrows-wrapper">
            <KeyboardArrowLeftIcon
              onClick={handlers.handlePageSelect(formattedData.page - 1, formattedData.canGoBack)}
              className={classnames('action-bar__pagination-arrow', {
                'action-bar__pagination-arrow--is-disabled': !formattedData.canGoBack
              })}
            />
            <KeyboardArrowRightIcon
              onClick={handlers.handlePageSelect(
                formattedData.page + 1,
                formattedData.canGoForward
              )}
              className={classnames('action-bar__pagination-arrow', {
                'action-bar__pagination-arrow--is-disabled': !formattedData.canGoForward
              })}
            />
            <div className="action-bar__additional-filters-wrapper">
              <AdditionalFilters
                isDisabled={localState.isLoading}
                onRowsCountChange={handlers.handleRowCountChange}
              />
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </div>
  );
};

ActionBar.propTypes = {
  selectedDocsIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  shouldShowWithCombinedDocs: PropTypes.bool.isRequired,
  selectedOverDailyLimit: PropTypes.number.isRequired,
  onExport: PropTypes.func.isRequired,
  section: PropTypes.string.isRequired
};
