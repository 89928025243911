import lodash from 'lodash';
import PropTypes from 'prop-types';
import { Tabs, Tooltip, TOOLTIP_POSITION, TOOLTIP_TYPES } from '@compliance.ai/web-components';
import { LibraryBooks as LibraryBooksIcon } from '@material-ui/icons';
import './_widget-tabs.scss';

export const WidgetTabs = ({
  id,
  tabs,
  activeTabIndex,
  shouldRenderComponent,
  shouldRenderActionButton,
  onChange,
  onActionButtonClick,
  actionButtonTooltip
}) => {
  if (!lodash.isNil(activeTabIndex) && Array.isArray(tabs)) {
    return (
      <div className="widget-tabs__container">
        <Tabs
          id={id}
          className="widget-tabs"
          activeTabIndex={activeTabIndex}
          onSelect={changedActiveTabIndex => onChange(changedActiveTabIndex)}
          tabClassName="widget-tabs__tab"
          tabsBarClassName="widget-tabs__tabs-bar"
          tabs={tabs.map(tab => ({
            title: tab.label,
            content: shouldRenderComponent && tab.component
          }))}
        />
        {shouldRenderActionButton && (
          <div className="widget-tabs__action-button" onClick={onActionButtonClick}>
            {actionButtonTooltip ? (
              <Tooltip
                id={`${id}-widget-tabs-tooltip`}
                placement={TOOLTIP_POSITION.BOTTOM}
                title={actionButtonTooltip}
                type={TOOLTIP_TYPES.SECONDARY}
              >
                <LibraryBooksIcon className="widget-tabs__action-button-icon" />
              </Tooltip>
            ) : (
              <LibraryBooksIcon className="widget-tabs__action-button-icon" />
            )}
          </div>
        )}
      </div>
    );
  }

  return null;
};

WidgetTabs.propTypes = {
  id: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
      component: PropTypes.element
    })
  ).isRequired,
  shouldRenderComponent: PropTypes.bool,
  shouldRenderActionButton: PropTypes.bool,
  activeTabIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onActionButtonClick: PropTypes.func,
  actionButtonTooltip: PropTypes.element
};

WidgetTabs.defaultProps = {
  shouldRenderActionButton: false,
  shouldRenderComponent: false,
  onActionButtonClick: undefined,
  actionButtonTooltip: null
};
