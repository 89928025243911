import { Loader } from '@compliance.ai/web-components';
import { FORM_STATUSES } from './Feedback.constants';
import { FeedbackEmpty, FeedbackError, FeedbackSuccess } from './elements';
import { useFeedbackData, useFeedbackHandlers } from './hooks';
import './_feedback.scss';

export const FEEDBACK_FORMS = {
  [FORM_STATUSES.EMPTY]: FeedbackEmpty,
  [FORM_STATUSES.SUCCESS]: FeedbackSuccess,
  [FORM_STATUSES.ERROR]: FeedbackError
};

export const Feedback = ({
  requestTeam,
  scheduleDemo,
  topics,
  requestPro,
  requestJurisdiction,
  requestAgency,
  requestNewsSource,
  forceNonAuth,
  shouldShowCancelButton,
  onCancel
}) => {
  const { localState, localActions, formattedData } = useFeedbackData({
    requestTeam,
    scheduleDemo,
    topics,
    requestPro,
    requestJurisdiction,
    requestAgency,
    requestNewsSource,
    forceNonAuth
  });

  const handlers = useFeedbackHandlers({
    props: {
      requestTeam,
      scheduleDemo,
      topics,
      requestPro,
      requestJurisdiction,
      requestAgency,
      requestNewsSource,
      onCancel
    },
    formattedData,
    localState,
    localActions
  });

  const FormComponent = FEEDBACK_FORMS[localState.formStatus];

  return (
    <div className="feedback">
      <div className="feedback__container">
        {localState.isLoading && <Loader withoutBackground={false} />}
        <FormComponent
          formType={formattedData.formType}
          onSubmit={handlers.handleFeedbackSend}
          onCancel={handlers.handleCancel}
          shouldShowCancelButton={shouldShowCancelButton}
          shouldShowEmailInput={formattedData.shouldShowEmailInput}
          fieldValues={localState.fieldsValues}
          onFieldChange={handlers.handleFieldChange}
        />
      </div>
    </div>
  );
};
