import PropTypes from 'prop-types';
import { IconLinkedin } from '../Icons';
import './_linkedIn-button.scss';

export const TEST_ID = {
  BUTTON: 'linkedin-button'
};

const LinkedInButton = ({ onClick, children }) => {
  return (
    <button
      data-testid={TEST_ID.BUTTON}
      type="button"
      className="linkedIn_button"
      onClick={onClick}
    >
      <IconLinkedin className={'linkedIn_logo'} />
      {children}
    </button>
  );
};

export default LinkedInButton;

LinkedInButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
};

LinkedInButton.defaultProps = {
  children: 'LinkedIn'
};
