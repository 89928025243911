import { safe_mixpanel_track } from 'utils/analytics';
import { appUrl } from 'shared/config';

const SUPPORT_CENTER_SECTION = 'BasicandAdvancedSearch';

export const useSearchFieldTypeSwitchHandlers = ({ props }) => {
  const handleSwitchClick = () => {
    const newPosition = !props.isAdvancedSearch ? 'Basic' : 'Advanced';

    safe_mixpanel_track(`Search - Advanced Search – Switch Toggled to ${newPosition}`, {
      eventCategory: 'Search',
      eventAction: 'Advanced Search Click',
      eventLabel: `Switch Toggled to ${newPosition}`
    });

    props.onSwitch(!props.isAdvancedSearch);
  };

  const handleRedirectToSupport = e => {
    e.stopPropagation();
    window.open(`${appUrl}/support?view=${SUPPORT_CENTER_SECTION}`, '_blank');
  };

  return {
    handleSwitchClick,
    handleRedirectToSupport
  };
};
