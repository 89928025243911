import { useState } from 'react';

export const useDocumentContextMenuOverlayData = () => {
  const [anchorPosition, setAnchorPosition] = useState(undefined);

  return {
    localState: {
      anchorPosition
    },
    localActions: {
      setAnchorPosition
    }
  };
};
