import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import './_widget-header-link.scss';

const WidgetHeaderLink = ({ to, onClick, className, children }) => {
  return (
    <Link to={to} onClick={onClick} onlyActiveOnIndex>
      <span className={classnames(className, 'widget-header-link')}>{children}</span>
    </Link>
  );
};

export default WidgetHeaderLink;

WidgetHeaderLink.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.string)
  ])
};

WidgetHeaderLink.defaultProps = {
  to: undefined,
  onClick: () => {},
  className: undefined
};
