import type { FC } from 'react';
import type { BucketNameCellProps } from './BucketNameCell.types';

import * as uiLib from '@compliance.ai/web-components';

import { useBucketNameCellData } from './hooks';

import './_bucket-name-cell.scss';

export const BucketNameCell: FC<BucketNameCellProps> = ({ cellValue }) => {
  const { formattedData } = useBucketNameCellData({
    cellValue
  });

  return (
    <div className="bucket-name">
      {formattedData.isBucketShared ? <uiLib.IconSharedFolder /> : <uiLib.IconFolder />}
      <uiLib.Typography component="span">{cellValue.bucket.name}</uiLib.Typography>
    </div>
  );
};
