export const DUE_DATE_CONFIGS = {
  WORKFLOW_INITIALIZED: {
    key: 'task_creation',
    text: 'Task Creation'
  },
  COMMENTS_CLOSE_ON: {
    key: 'comments_close_on',
    text: 'Comments Close'
  },
  EFFECTIVE_ON: {
    key: 'effective_on',
    text: 'Effective Date'
  },
  PUBLICATION_DATE: {
    key: 'publication_date',
    text: 'Publication Date'
  }
};
export const DUE_DATE_RELATION_KEYS = {
  AFTER: 'after',
  BEFORE: 'before'
};
export enum TASK_NOTIFICATION_KEYS {
  TASK_CREATED = 'task_created',
  TASK_NEAR_DUE = 'task_near_due',
  TASK_OVERDUE = 'task_overdue',
  TASK_DONE = 'task_done'
}
export const WORKFLOW_NAME_MAX_LEN = 100;
export const WORKFLOW_TARGET_TYPES = {
  NOTIFICATION: 'notification',
  DOC_TYPE: 'docType'
};
export const TASK_DATE_FORMAT = 'YYYY-MM-DD  HH:mm:ss';
