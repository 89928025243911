import type { GlobalState } from '../../../../reducers';
import type { RightPanelQueryParamsData } from './useRightPanelQueryParamsData.types';

import * as rightPanelSelectors from '../../rightPanel.selectors';
import * as urlUtils from 'utils/url-utils';
import * as helpers from './useRightPanelQueryParamsData.helpers';

import { useQueryParams } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useShallowEqualMemo } from '@compliance.ai/web-components';

export const useRightPanelQueryParamsData = (): RightPanelQueryParamsData => {
  const queryParams = useQueryParams();

  const reduxState = useSelector<GlobalState, RightPanelQueryParamsData['reduxState']>(state => ({
    type: rightPanelSelectors.getRightPanelType(state as never),
    isOpen: rightPanelSelectors.getRightPanelOpenState(state as never),
    isClosable: rightPanelSelectors.getRightPanelIsClosable(state as never),
    props: rightPanelSelectors.getRightPanelProps(state as never),
    overlayType: rightPanelSelectors.getOverlayType(state),
    isOverlayOpen: rightPanelSelectors.getOverlayOpenState(state),
    documentDetails: {
      activeTabKey: rightPanelSelectors.getDocumentDetailsActiveTabKey(state),
      expandedRelatedDocsType: rightPanelSelectors.getDocumentDetailsExpandedRelatedDocsType(state)
    }
  }));

  const rightPanelQueryParams = useMemo(
    () =>
      urlUtils.filterNullishQueryParams(
        reduxState.isOpen && !reduxState.props.shouldOpenOverlayWithoutQueryParams
          ? helpers.getUpdatedRightPanelQueryParams({
              type: reduxState.type,
              overlayType: reduxState.overlayType,
              isClosable: reduxState.isClosable,
              documentDetails: reduxState.documentDetails,
              props: reduxState.props
            })
          : {}
      ),
    [
      reduxState.documentDetails,
      reduxState.isClosable,
      reduxState.isOpen,
      reduxState.overlayType,
      reduxState.props,
      reduxState.type
    ]
  );

  const allQueryParams = useMemo(
    () =>
      urlUtils.filterNullishQueryParams({
        ...helpers.removeRightPanelQueryParams(queryParams),
        ...rightPanelQueryParams
      }),
    [queryParams, rightPanelQueryParams]
  );

  /**
   *
   * Memorizes the created query params to ensure that
   * the object reference stays the same. Useful in useEffect dependencies
   *
   */
  const finalParams = useShallowEqualMemo(
    () => ({
      rightPanelQueryParams,
      allQueryParams
    }),
    [rightPanelQueryParams]
  );

  const rightPanelInitialOpenArgs = helpers.getInitialRightPanelOpenArgs(queryParams);

  const formattedData: RightPanelQueryParamsData['formattedData'] = useMemo(
    () => ({
      initialOpenArgs: rightPanelInitialOpenArgs,
      queryParams: finalParams.rightPanelQueryParams,
      allQueryParams: finalParams.allQueryParams
    }),
    [finalParams.allQueryParams, finalParams.rightPanelQueryParams, rightPanelInitialOpenArgs]
  );

  return {
    reduxState,
    formattedData
  };
};
