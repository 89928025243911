import { usePermissions } from 'utils/hooks';
import { MANAGE_ORG_PROFILE } from 'constants/Permissions';
import { useSelector } from 'react-redux';
import { getOrganizationMemberEmailByUserId } from 'shared/features/organizations/organizations.selectors';
import { getUserId } from 'shared/features/user/user.selectors';

export const useCommentInfoData = ({ comment, isEditableByAuthor }) => {
  const permissions = usePermissions();
  const reduxState = useSelector(state => ({
    currentUserId: getUserId(state),
    commentAuthorEmail: getOrganizationMemberEmailByUserId(comment.userId)(state)
  }));

  const isAnonymousComment = !comment.userId;
  const commentAuthorEmail = isAnonymousComment ? 'Unknown' : reduxState.commentAuthorEmail;

  const isCommentAuthor = reduxState.currentUserId === comment.userId;
  const isCommentEditable =
    isEditableByAuthor &&
    (isCommentAuthor || (isAnonymousComment && permissions[MANAGE_ORG_PROFILE]));

  return {
    isCommentEditable,
    commentAuthorEmail
  };
};
