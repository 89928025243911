import lodash from 'lodash';

import * as routes from 'constants/Routes';
import * as viewConstants from 'shared/features/view/view.constants';
import * as primaryFilterConstants from 'constants/PrimaryFilter';

export const APP_VIEW_SECTION_TASKS = 'tasks';
export const APP_VIEW_SECTION_RESOURCES = 'resources';
export const APP_VIEW_SECTION_ENFORCEMENTEXPLORER = 'enforcementexplorer';
export const APP_VIEW_SECTION_SUPPORT = 'support';
export const APP_VIEW_SECTION_USER_SETTINGS = 'user_settings';
export const APP_VIEW_SECTION_DASHBOARD = 'dashboard';
export const APP_VIEW_SECTION_SOURCES = 'sources';
export const APP_VIEW_SECTION_NOTIFICATIONS = 'notifications';
export const APP_VIEW_SECTION_USER_FOLDERS = 'user_folders';
export const APP_VIEW_SECTION_TOPICS = 'topics';
export const APP_VIEW_SECTION_INSIGHTS = 'insights';
export const APP_VIEW_SECTION_ALL = 'all';
export const APP_VIEW_SECTION_TIMELINE = 'timeline';
export const APP_VIEW_SECTION_NEWS = 'news';
export const APP_VIEW_SECTION_SEARCH = 'search';
export const APP_VIEW_SECTION_SAVED_SEARCHES = 'saved_searches';
export const APP_VIEW_NEWDASHBOARD = 'newdashboard';

export const shouldShowFilterMenu = section =>
  ![
    APP_VIEW_SECTION_INSIGHTS,
    APP_VIEW_SECTION_SOURCES,
    APP_VIEW_SECTION_RESOURCES,
    APP_VIEW_SECTION_TASKS,
    APP_VIEW_SECTION_SUPPORT,
    APP_VIEW_SECTION_NOTIFICATIONS,
    APP_VIEW_SECTION_USER_SETTINGS
  ].includes(section);

const DATE_PARAMS = [
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.PUBLICATION_DATE,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.PUBLISHED_FROM,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.PUBLISHED_TO,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.ADDED_DATE,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.ADDED_FROM,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.ADDED_TO,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.UPDATED_DATE,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.UPDATED_FROM,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.UPDATED_TO,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.COMMENTS_CLOSE_DATE,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.COMMENTS_CLOSE_FROM,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.COMMENTS_CLOSE_TO,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.EFFECTIVE_DATE,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.EFFECTIVE_FROM,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.EFFECTIVE_TO
];
// params that are actually used in search. handle these explicitly so
// if the store gets polluted with unexpected values our logic doesn't break
const WHITELIST_PARAMS = DATE_PARAMS.concat([
  'agency',
  'category',
  'search_query',
  'followed_sources',
  'autosuggest_filter',
  'search_sort',
  'folder_id',
  'insights_view',
  'saved_searches_view',
  'respondent',
  'monetary_penalty',
  'monetary_penalty_max',
  'monetary_penalty_min',
  'docket_id'
]);

// user-selected filter items
const USER_FILTER_PARAMS = [
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.PUBLICATION_DATE,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.PUBLISHED_FROM,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.PUBLISHED_TO,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.AGENCY,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.CATEGORY,
  'respondent',
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.MIN_SCORE,
  'monetary_penalty',
  'monetary_penalty_max',
  'monetary_penalty_min',
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.ADDED_DATE,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.ADDED_FROM,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.ADDED_TO,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.UPDATED_DATE,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.UPDATED_FROM,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.UPDATED_TO,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.EFFECTIVE_DATE,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.EFFECTIVE_FROM,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.EFFECTIVE_TO,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.COMMENTS_CLOSE_DATE,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.COMMENTS_CLOSE_FROM,
  primaryFilterConstants.SUPPORTED_QUERY_PARAMS.COMMENTS_CLOSE_TO
];

// return only the parameters that are changed from the default
export function get_changed_parameters(search_params) {
  const whitelisted = lodash.pick(search_params, WHITELIST_PARAMS);
  const changed = lodash.pickBy(whitelisted); // all truthy parameters
  // clear out some false positives
  for (const param of Object.keys(changed)) {
    const value = changed[param];

    // date params are initialized to {}
    if (
      DATE_PARAMS.indexOf(param) !== -1 &&
      lodash.isObject(value) &&
      !lodash.isEmpty(value) &&
      Object.keys.length(value) === 0
    ) {
      delete changed[param];
    }
  }

  return changed;
}

// simple helper function to map the current search parameters to the view in the UI.
// simplisitic now, but hopefully should prevent duplicated logic later on
export function get_search_view(search_params, location = {}) {
  const view = {};
  const active_params = get_changed_parameters(search_params);
  const active_param_names = Object.keys(active_params);

  const support_page =
    location.pathname === routes.account ||
    location.pathname === routes.legal ||
    location.pathname === routes.checkout;

  if (location.pathname === routes.tasks) {
    view.section = APP_VIEW_SECTION_TASKS;
  } else if (location.pathname === routes.resources) {
    view.section = APP_VIEW_SECTION_RESOURCES;
  } else if (location.pathname === routes.enforcementExplorer) {
    view.section = APP_VIEW_SECTION_ENFORCEMENTEXPLORER;
  } else if (support_page) {
    view.section = APP_VIEW_SECTION_USER_SETTINGS; //support_page view
  } else if (location.pathname === routes.dashboard) {
    view.section = APP_VIEW_SECTION_DASHBOARD; //routes.dashboard view
  } else if (location.pathname === routes.sources) {
    view.section = APP_VIEW_SECTION_SOURCES;
  } else if (location.pathname === routes.notifications) {
    view.section = APP_VIEW_SECTION_NOTIFICATIONS;
  } else if (search_params.folderTimelineView || location.pathname === routes.folders) {
    //folder content view
    view.section = APP_VIEW_SECTION_USER_FOLDERS;
    if (location.pathname === routes.folders) {
      view.subsection = 'folder_menu';
    } else if (search_params.folderTimelineView) {
      view.subsection = 'custom_folder';
    }
  } else if (location.pathname === routes.topics) {
    view.section = APP_VIEW_SECTION_TOPICS; //routes.dashboard view
  } else if (location.pathname === routes.insights) {
    view.section = APP_VIEW_SECTION_INSIGHTS; //routes.insights view
  } else if (search_params.view === viewConstants.VIEW_KEY.ALL) {
    view.section = APP_VIEW_SECTION_ALL;
  } else if (
    search_params.view === viewConstants.VIEW_KEY.TIMELINE ||
    location.pathname === routes.timeline
  ) {
    view.section = APP_VIEW_SECTION_TIMELINE;
    if (active_param_names.length === 0) {
      view.subsection = 'new'; // new on routes.timeline
    } else if (active_params.followed_sources === 'federal') {
      view.subsection = 'fed_agencies_view'; // fed routes.sources on routes.timeline
    } else if (active_params.followed_sources === 'state') {
      view.subsection = 'state_agencies_view'; // state routes.sources on routes.timeline
    } else if (search_params.recent_activity_view) {
      view.subsection = 'recent_activity_view'; // recent_activity_view on routes.timeline
    }
  } else if (
    search_params.view === viewConstants.VIEW_KEY.NEWS ||
    location.pathname === routes.news
  ) {
    view.section = APP_VIEW_SECTION_NEWS; //routes.news view
  } else if (search_params.search_sort) {
    view.section = APP_VIEW_SECTION_SEARCH; //search results view
  } else if (search_params.insights_view) {
    view.section = APP_VIEW_SECTION_INSIGHTS; //routes.insights view
  } else if (search_params.saved_searches_view) {
    view.section = APP_VIEW_SECTION_SAVED_SEARCHES; //saved_searches view
  }

  view.active_user_filters = lodash.intersection(active_param_names, USER_FILTER_PARAMS);

  return view;
}
