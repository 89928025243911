import {
  FETCH_DEFAULTS_BEGIN,
  FETCH_DEFAULTS_ERROR,
  FETCH_DEFAULTS_SUCCESS,
  FETCH_DEFAULT_CONCEPTS_SUCCESS,
  FETCH_DEFAULT_REGULATIONS_SUCCESS,
  UPDATE_DEFAULTS_BEGIN,
  UPDATE_DEFAULTS_SUCCESS,
  UPDATE_DEFAULTS_ERROR,
  REFRESH_OLD_DEFAULTS,
  SET_DEFAULT_DEFAULTS,
  UPDATE_TEAM_DEFAULTS_FROM_EXISTING_MAP
} from './defaults.actions';
import { DEFAULTS_UPDATE_ACTIONS } from 'constants/Defaults';
import {
  getUpdatedTeamDefaultsMap,
  normalizeUpdatedFollowedEntities,
  normalizeUpdatedFollowedTopics
} from './defaults.helpers';
import { DEFAULT_TYPES } from 'constants/DefaultSources';

export const INITIAL_STATE = {
  [DEFAULT_TYPES.MY_DEFAULTS]: {
    activeTopics: [],
    defaultTopics: [],
    defaultMainstreamNewsSources: [],
    realFollowedTopics: [],
    followedCategories: {},
    followedMetacategories: {},
    followedAgencies: {},
    followedJurisdictions: {},
    followedConcepts: {},
    followedTopics: [],
    followedEntities: [],
    followedDefaultTopics: [],
    followedActiveTopics: [],
    followedRegulations: [],
    isFetching: true,
    isUpdating: false,
    refreshTrigger: false
  },
  [DEFAULT_TYPES.TEAM_DEFAULTS]: {
    activeTopics: [],
    defaultTopics: [],
    defaultMainstreamNewsSources: [],
    realFollowedTopics: [],
    followedCategories: {},
    followedMetacategories: {},
    followedAgencies: {},
    followedJurisdictions: {},
    followedConcepts: {},
    followedTopics: [],
    followedEntities: [],
    followedDefaultTopics: [],
    followedActiveTopics: [],
    followedRegulations: [],
    isFetching: true,
    isUpdating: false
  },
  [DEFAULT_TYPES.ORGANIZATION_DEFAULTS]: {
    activeTopics: [],
    defaultTopics: [],
    defaultMainstreamNewsSources: [],
    realFollowedTopics: [],
    followedCategories: {},
    followedMetacategories: {},
    followedAgencies: {},
    followedJurisdictions: {},
    followedConcepts: {},
    followedTopics: [],
    followedEntities: [],
    followedDefaultTopics: [],
    followedActiveTopics: [],
    followedRegulations: [],
    isFetching: true,
    isUpdating: false
  },
  teamDefaultsMap: {}
};

export const defaults = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DEFAULTS_BEGIN: {
      return {
        ...state,
        [action.meta.defaultsType]: {
          ...state[action.meta.defaultsType],
          isFetching: true
        }
      };
    }

    case FETCH_DEFAULTS_SUCCESS: {
      const {
        activeTopics,
        defaultMainstreamNewsSources,
        defaultTopics,
        realFollowedTopics,
        followedCategories,
        followedMetacategories,
        followedAgencies,
        followedJurisdictions,
        followedConcepts,
        followedRegulations,
        followedTopics,
        followedEntities
      } = action.payload;

      const { defaultsType, teamId } = action.meta;

      const defaultsData = {
        ...state[defaultsType],
        activeTopics,
        defaultMainstreamNewsSources,
        defaultTopics,
        realFollowedTopics,
        followedCategories,
        followedMetacategories,
        followedAgencies,
        followedJurisdictions,
        followedConcepts,
        followedRegulations,
        followedTopics,
        followedEntities,
        isFetching: false
      };

      const teamDefaultsMap = getUpdatedTeamDefaultsMap({
        defaultsType,
        teamDefaultsMap: state.teamDefaultsMap,
        teamId,
        defaultsData
      });

      return {
        ...state,
        [defaultsType]: defaultsData,
        teamDefaultsMap
      };
    }

    case FETCH_DEFAULT_CONCEPTS_SUCCESS: {
      const { defaultsType, teamId } = action.meta;

      const defaultsData = {
        ...state[action.meta.defaultsType],
        followedConcepts: action.payload.followedConcepts,
        isFetching: false
      };

      const teamDefaultsMap = getUpdatedTeamDefaultsMap({
        defaultsType,
        teamDefaultsMap: state.teamDefaultsMap,
        teamId,
        defaultsData
      });

      return {
        ...state,
        [action.meta.defaultsType]: defaultsData,
        teamDefaultsMap
      };
    }

    case FETCH_DEFAULT_REGULATIONS_SUCCESS: {
      const { defaultsType, teamId } = action.meta;

      const defaultsData = {
        ...state[defaultsType],
        followedRegulations: action.payload.followedRegulations,
        isFetching: false
      };

      const teamDefaultsMap = getUpdatedTeamDefaultsMap({
        defaultsType,
        teamDefaultsMap: state.teamDefaultsMap,
        teamId,
        defaultsData
      });

      return {
        ...state,
        [defaultsType]: defaultsData,
        teamDefaultsMap
      };
    }

    case FETCH_DEFAULTS_ERROR: {
      return {
        ...state,
        [action.meta.defaultsType]: {
          ...state[action.meta.defaultsType],
          isFetching: false
        }
      };
    }

    case UPDATE_DEFAULTS_BEGIN: {
      return {
        ...state,
        [action.meta.defaultsType]: {
          ...state[action.meta.defaultsType],
          isUpdating: true
        }
      };
    }

    case UPDATE_DEFAULTS_SUCCESS: {
      const { defaultsType, teamId } = action.meta;

      const defaultsData = {
        ...setUpdatedDefaults(state[defaultsType], action),
        isUpdating: false
      };

      const teamDefaultsMap = getUpdatedTeamDefaultsMap({
        defaultsType,
        teamDefaultsMap: state.teamDefaultsMap,
        teamId,
        defaultsData
      });

      return {
        ...state,
        [defaultsType]: defaultsData,
        teamDefaultsMap
      };
    }
    case UPDATE_DEFAULTS_ERROR:
      return {
        ...state,
        [action.meta.defaultsType]: {
          ...state[action.meta.defaultsType],
          isUpdating: false
        }
      };

    case SET_DEFAULT_DEFAULTS:
      const {
        followedTopics,
        followedEntities,
        followedCategories,
        followedMetacategories,
        followedAgencies,
        followedJurisdictions,
        defaultMainstreamNewsSources,
        defaultTopics,
        activeTopics
      } = action.payload;

      return {
        ...state,
        [action.meta.defaultsType]: {
          ...state[action.meta.defaultsType],
          activeTopics,
          followedCategories,
          followedMetacategories,
          followedAgencies,
          followedJurisdictions,
          followedTopics,
          followedEntities,
          defaultTopics,
          defaultMainstreamNewsSources,
          isFetching: false
        }
      };

    case REFRESH_OLD_DEFAULTS: {
      return {
        ...state,
        [DEFAULT_TYPES.MY_DEFAULTS]: {
          ...state[DEFAULT_TYPES.MY_DEFAULTS],
          refreshTrigger: !state[DEFAULT_TYPES.MY_DEFAULTS].refreshTrigger
        }
      };
    }

    case UPDATE_TEAM_DEFAULTS_FROM_EXISTING_MAP: {
      const existingTeamDefaults = state.teamDefaultsMap[action.meta.teamId];

      return {
        ...state,
        [DEFAULT_TYPES.TEAM_DEFAULTS]: {
          ...state[DEFAULT_TYPES.TEAM_DEFAULTS],
          ...existingTeamDefaults
        }
      };
    }

    default:
      return state;
  }
};

export const setUpdatedDefaults = (state, action) => {
  switch (action.meta.action) {
    case DEFAULTS_UPDATE_ACTIONS.UPDATE_FOLLOWED_TOPICS: {
      const { followedTopics, realFollowedTopics } = normalizeUpdatedFollowedTopics(
        action.payload.topics,
        state
      );

      return {
        ...state,
        realFollowedTopics,
        followedTopics
      };
    }

    case DEFAULTS_UPDATE_ACTIONS.UPDATE_FOLLOWED_AGENCIES: {
      const { agencies, jurisdictions } = action.payload;
      return {
        ...state,
        followedAgencies: agencies,
        followedJurisdictions: jurisdictions
      };
    }

    case DEFAULTS_UPDATE_ACTIONS.UPDATE_FOLLOWED_ENTITY: {
      const followedEntities = normalizeUpdatedFollowedEntities(action.payload.entities, state);

      return {
        ...state,
        followedEntities
      };
    }

    case DEFAULTS_UPDATE_ACTIONS.UPDATE_FOLLOWED_CATEGORIES: {
      const { categories, metacategories } = action.payload;
      return {
        ...state,
        followedCategories: categories,
        followedMetacategories: metacategories
      };
    }

    case DEFAULTS_UPDATE_ACTIONS.CONCEPT_FOLLOW: {
      return {
        ...state,
        followedConcepts: action.payload
      };
    }

    case DEFAULTS_UPDATE_ACTIONS.CONCEPT_UNFOLLOW: {
      return {
        ...state,
        followedConcepts: action.payload
      };
    }

    case DEFAULTS_UPDATE_ACTIONS.REGULATION_FOLLOW: {
      return {
        ...state,
        followedRegulations: action.payload
      };
    }

    case DEFAULTS_UPDATE_ACTIONS.REGULATION_UNFOLLOW: {
      return {
        ...state,
        followedRegulations: action.payload
      };
    }

    default:
      return state;
  }
};
