import * as elements from './elements';
import * as common from 'common';

import {
  useTimelineToolbarData,
  useTimelineToolbarReduxActions,
  useTimelineToolbarLifecycles
} from './hooks';

import './_timeline-toolbar.scss';

export const TEST_ID = 'timeline-toolbar';

export const TimelineToolbar = () => {
  const { localState, localActions, reduxState, formattedData } = useTimelineToolbarData();

  const reduxActions = useTimelineToolbarReduxActions();

  useTimelineToolbarLifecycles({ reduxState, reduxActions, formattedData });

  return (
    <div className={formattedData.mainClassName} data-testid={TEST_ID}>
      <elements.SubscriptionAlert
        current_view={reduxState.current_view}
        isOnFreeTrialSubscription={formattedData.isOnFreeTrialSubscription}
        currentSubscription={formattedData.currentSubscription}
      />
      <elements.ToolbarMenu
        searchView={formattedData.searchView}
        folder={reduxState.folders.currentFolder}
      />
      <elements.FilterMenu show={formattedData.showFilterMenu} />
      <elements.SuggestionBoxModal
        show={localState.openSuggestionBoxModal}
        onClose={() => localActions.setOpenSuggestionBoxModal(false)}
      />
      <common.AlertPreviewBanner requestParams={reduxState.current_view?.search_params ?? {}} />
      <elements.ToolbarSourcesTab
        parsedQuery={formattedData.parsedQuery}
        languageId={formattedData.languageId}
        newsCategoryIds={formattedData.newsCategoryIds}
        displaySource={formattedData.displaySource}
        followedAgenciesIds={reduxState.followedAgenciesIds}
        followedNewsSourcesIds={reduxState.followedNewsSourcesIds}
      />
      <elements.AutoSuggest
        parsedQuery={formattedData.parsedQuery}
        search_results_relevance={reduxState.search_results_relevance}
        filtered_mention={reduxState.filtered_mention}
        documents_full={reduxState.documents_full}
      />
    </div>
  );
};
