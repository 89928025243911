import type { DocumentsFiltersHandlers } from './useDocumentsFiltersHandlers.types';
import type { DocumentsFiltersData } from './useDocumentsFiltersData.types';
import type { DocumentsFiltersProps } from '../DocumentsFilters.types';

import {
  formatDocumentsQueryParams,
  formatFilterSearchOptions
} from './useDocumentsFiltersHandlers.helpers';
import { logError } from 'utils/errors';

import { SUPPORTED_QUERY_PARAMS } from '../../../constants/PrimaryFilter';
import { DOCUMENTS_FILTERS_DEFAULT_VALUES } from '../DocumentsFilters.constants';

import { useCallback } from 'react';
import { useSearchReduxActions } from 'shared/features/search/hooks';
import { useAdvancedSearchQuery } from 'utils/hooks';

export const useDocumentsFiltersHandlers = ({
  props,
  localState,
  localActions
}: {
  props: Pick<DocumentsFiltersProps, 'onSubmit'>;
  localState: DocumentsFiltersData['localState'];
  localActions: DocumentsFiltersData['localActions'];
}): DocumentsFiltersHandlers => {
  const searchReduxActions = useSearchReduxActions();
  const advancedSearchQuery = useAdvancedSearchQuery(localState.values);

  const handleFiltersTypeSwitch: DocumentsFiltersHandlers['handleFiltersTypeSwitch'] = () => {
    if (!localState.isAdvancedSearch) {
      localActions.setAdvancedSearch(advancedSearchQuery);
    }

    localActions.setValues(DOCUMENTS_FILTERS_DEFAULT_VALUES);
    localActions.setIsAdvancedSearch(!localState.isAdvancedSearch);
  };

  const handleSuggestionsFetch: DocumentsFiltersHandlers['handleSuggestionsFetch'] = async searchValue => {
    try {
      const response = await searchReduxActions.fetchAutoComplete(searchValue);

      return formatFilterSearchOptions(response);
    } catch (e) {
      logError(e as Error, 'Error', { isSilent: true });
      return [];
    }
  };

  const handleFiltersSubmit: DocumentsFiltersHandlers['handleFiltersSubmit'] = (
    values,
    searchValue
  ) => {
    localActions.setValues(values);
    localActions.setSearchValue(searchValue);

    props.onSubmit?.(formatDocumentsQueryParams(values, searchValue));
  };

  const handleFiltersChange: DocumentsFiltersHandlers['handleFiltersChange'] = useCallback(
    (values, searchValue) => {
      localActions.setValues(values);
      localActions.setSearchValue(searchValue);
    },
    [localActions]
  );

  const handleAdvancedSearchSubmit: DocumentsFiltersHandlers['handleAdvancedSearchSubmit'] = value => {
    localActions.setAdvancedSearch(value);

    props.onSubmit?.({
      [SUPPORTED_QUERY_PARAMS.ADVANCED_SEARCH]: value
    });
  };

  return {
    handleFiltersTypeSwitch,
    handleSuggestionsFetch,
    handleFiltersSubmit,
    handleAdvancedSearchSubmit,
    handleFiltersChange
  };
};
