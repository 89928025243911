import type { WorkflowDropdownProps } from './WorkflowDropdown.types';
import type { FC } from 'react';

import { WorkflowsSelect } from 'common/index';
import * as uiLib from '@compliance.ai/web-components';
import './_workflow-dropdown.scss';

export const WorkflowDropdown: FC<WorkflowDropdownProps> = ({
  onSubmit,
  onCancel,
  selectedWorkflowOption,
  onWorkflowSelect
}) => {
  return (
    <div className="workflow-dropdown">
      <uiLib.LabeledField
        isContrast
        label="You are adding this document to a workflow:"
        labelClassName="workflow-dropdown__label"
      >
        <WorkflowsSelect
          type={uiLib.SELECT_TYPES.DEFAULT_CONTRAST}
          selectedWorkflow={selectedWorkflowOption}
          onChange={onWorkflowSelect}
        />
      </uiLib.LabeledField>
      <div className="workflow-dropdown__footer">
        <uiLib.Button type={uiLib.BUTTON_TYPES.SECONDARY} onClick={onCancel}>
          Cancel
        </uiLib.Button>
        <uiLib.Button
          isDisabled={!selectedWorkflowOption}
          onClick={onSubmit}
          type={uiLib.BUTTON_TYPES.PRIMARY}
        >
          Next
        </uiLib.Button>
      </div>
    </div>
  );
};
