import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserEmail } from 'shared/features/user/user.selectors';
import { ACTION_STATUSES } from '../RestrictModalActionButton.constants';

export const useRestrictModalActionButtonData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [actionStatus, setActionStatus] = useState(ACTION_STATUSES.EMPTY);

  const reduxState = useSelector(state => ({
    currentUserEmail: getUserEmail(state)
  }));

  const localActions = useMemo(
    () => ({
      setIsLoading,
      setActionStatus
    }),
    [setIsLoading, setActionStatus]
  );

  return {
    localState: { isLoading, actionStatus },
    localActions: localActions,
    reduxState
  };
};
