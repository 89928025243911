import type * as reducerTypes from './authors.reducer.types';

import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { setAuthors, fetchAuthors } from './authors.actions';

export const INITIAL_STATE: reducerTypes.AuthorsState = {
  isReady: false,
  items: []
};

export const reducer = createReducer(INITIAL_STATE, builder => {
  builder.addCase(setAuthors.type, (state, action) => {
    if (setAuthors.match(action)) {
      state.items = action.payload.authors || [];
    }
  });

  builder.addMatcher(isAnyOf(fetchAuthors.pending, fetchAuthors.rejected), state => {
    state.isReady = false;
  });

  builder.addMatcher(isAnyOf(fetchAuthors.fulfilled), state => {
    state.isReady = true;
  });
});
