import type { GlobalState } from 'shared/reducers';
import type { BucketPermissionsCellData } from './useBucketPermissionsCellData.types';
import type { BucketPermissionsCellProps } from '../BucketPermissionsCell.types';

import * as orgSelectors from 'shared/features/organizations/organizations.selectors';
import * as helpers from './useBucketPermissionsCell.helpers';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const useBucketPermissionsCellData = ({
  cellValue
}: Pick<BucketPermissionsCellProps, 'cellValue'>): BucketPermissionsCellData => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const localState: BucketPermissionsCellData['localState'] = {
    isModalOpen
  };

  const localActions: BucketPermissionsCellData['localActions'] = useMemo(
    () => ({
      setIsModalOpen
    }),
    []
  );

  const permissionsTeamIds = useMemo(
    () => helpers.getBucketPermissionsTeamsIds(cellValue.bucket.permissions),
    [cellValue.bucket.permissions]
  );
  const permissionsMemberIds = useMemo(
    () => helpers.getBucketPermissionsMembersIds(cellValue.bucket.permissions),
    [cellValue.bucket.permissions]
  );

  const reduxState: BucketPermissionsCellData['reduxState'] = useSelector<
    GlobalState,
    BucketPermissionsCellData['reduxState']
  >(state => ({
    orgTeamMemberIds: orgSelectors.getOrganizationTeamMembersIdsByTeamIds(permissionsTeamIds)(
      state as never
    )
  }));

  const formattedData: BucketPermissionsCellData['formattedData'] = useMemo(
    () => ({
      teamsAndMembers: helpers.formatBucketPermissionsTeamsAndMembers({
        bucket: cellValue.bucket,
        orgTeamMemberIds: reduxState.orgTeamMemberIds,
        permissionsTeamIds: permissionsTeamIds,
        permissionsMemberIds: permissionsMemberIds
      })
    }),
    [cellValue.bucket, reduxState.orgTeamMemberIds, permissionsTeamIds, permissionsMemberIds]
  );

  return {
    localState,
    localActions,
    reduxState,
    formattedData
  };
};
