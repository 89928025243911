import { api_fetchDocTasksByDate, api_fetchAllWorkflows } from './docTasks.api';

export const RECEIVE_DOC_TASKS_BY_DATE = 'RECEIVE_DOC_TASKS_BY_DATE';
export const CLEAR_DOC_TASKS = 'CLEAR_DOC_TASKS';
export const RECEIVE_ALL_WORKFLOWS = 'RECEIVE_ALL_WORKFLOWS';

export const fetchDocTasksByDate = ({
  from_date,
  to_date,
  assignee_id = null,
  obligation_super_tasks_only = false,
  workflow_id = null,
  hide_blocked_tasks = false
}) => async dispatch => {
  try {
    const response = await api_fetchDocTasksByDate({
      from_date,
      to_date,
      assignee_id,
      obligation_super_tasks_only,
      workflow_id,
      hide_blocked_tasks
    });
    dispatch({ type: RECEIVE_DOC_TASKS_BY_DATE, response });
  } catch (error) {
    // pass
  }
};

export const clearDocTasks = () => dispatch => dispatch({ type: CLEAR_DOC_TASKS });

export const fetchAllWorkflows = () => async dispatch => {
  try {
    const response = await api_fetchAllWorkflows();
    dispatch({ type: RECEIVE_ALL_WORKFLOWS, response });
  } catch (error) {
    // pass
  }
};
