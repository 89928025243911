export const REDUCER_NAME = 'jurisdictions';

export const JURISDICTION_CATEGORY_KEY = {
  STATE: 'state',
  PROVINCIAL: 'provincial',
  INTERNATION: 'international',
  FEDERAL: 'federal'
};

export const COUNTRY_CATEGORIES = [
  JURISDICTION_CATEGORY_KEY.FEDERAL,
  JURISDICTION_CATEGORY_KEY.INTERNATION
];

export const SUBREGION_CATEGORIES = [
  JURISDICTION_CATEGORY_KEY.STATE,
  JURISDICTION_CATEGORY_KEY.PROVINCIAL
];

export const US_ID = 0;
export const CANADA_ID = 200;

export const DEFAULT_SUBREGIONS_TITLE = 'Regions';

export const SUBREGION_MAPPING = {
  [US_ID]: {
    subregionsTitle: 'States',
    subregionsCategoryKey: JURISDICTION_CATEGORY_KEY.STATE
  },
  [CANADA_ID]: {
    subregionsTitle: 'Provinces and Territories',
    subregionsCategoryKey: JURISDICTION_CATEGORY_KEY.PROVINCIAL
  }
};
