import type { TaskEdgeLabelProps } from './TaskEdgeLabel.types';

import React, { memo } from 'react';
import { useFormattedTaskDate } from '../../hooks';
import './_egde-label.scss';

export const TaskEdgeLabel = memo(({ daysCount, dateType }: TaskEdgeLabelProps) => {
  const { date, dateLabel } = useFormattedTaskDate({ daysCount, dateType });

  return (
    <>
      <tspan x="0" textAnchor="middle" className="edge-label__text">
        {date}
      </tspan>
      <tspan x="0" dy="1.2em" textAnchor="middle" className="edge-label__text">
        {dateLabel}
      </tspan>
    </>
  );
});
