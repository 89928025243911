import type { CitationProps } from '../Citation.types';
import type { CitationData } from './useCitationData.types';
import type { CitationHandlers } from './useCitationHandlers.types';

import * as urlUtils from 'utils/url-utils';
import * as routes from 'constants/Routes';
import * as resourcesConstants from 'shared/features/resources/resources.constants';

import { useNavigate } from 'react-router-dom';
import { useViewReduxActions } from 'shared/features/view/hooks';
import { useDocumentsReduxActions } from 'shared/features/documents/hooks';

export const useCitationHandlers = ({
  props,
  reduxState,
  formattedData
}: {
  props: Pick<CitationProps, 'onResourceTreeBuild'>;
  reduxState: CitationData['reduxState'];
  formattedData: CitationData['formattedData'];
}): CitationHandlers => {
  const navigate = useNavigate();

  const viewReduxActions = useViewReduxActions();
  const documentReduxActions = useDocumentsReduxActions();

  const handleCitationClick: CitationHandlers['handleCitationClick'] = () => {
    if (!formattedData.officialId) {
      return;
    }

    if (!reduxState.USStateReducer.codes?.[formattedData.officialId]) {
      documentReduxActions.fetchResourceCode(formattedData.officialId);
    }

    props.onResourceTreeBuild?.(formattedData.officialId);

    viewReduxActions.changeDocumentView('summary', formattedData.officialId);

    navigate(
      urlUtils.formatRedirectUrl({
        path: routes.resources,
        params: {
          [resourcesConstants.QUERY_PARAMS.CITATION_SELECTED_ID]: formattedData.officialId
        }
      })
    );
  };

  return {
    handleCitationClick
  };
};
