import type { AlertsTableData, AlertsTableLocalState } from './useAlertsTableData.types';

import React, { useMemo, useState } from 'react';
import { usePreviousValue, TABLE_PARAMS } from '@compliance.ai/web-components';
import { DEFAULT_TABLE_PARAMS } from '../AlertsTable.constants';

export const useAlertsTableData = (): AlertsTableData => {
  const [selectedAlertsIds, setSelectedAlertsIds] = useState<
    AlertsTableLocalState['selectedAlertsIds']
  >([]);
  const [allAlertsCount, setAllAlertsCount] = useState<AlertsTableLocalState['allAlertsCount']>(0);
  const [isLoading, setIsLoading] = useState<AlertsTableLocalState['isLoading']>(false);
  const [tableRefreshTrigger, setTableRefreshTrigger] = useState<
    AlertsTableLocalState['tableRefreshTrigger']
  >(false);
  const [tableParams, setTableParams] = useState<AlertsTableLocalState['tableParams']>(
    DEFAULT_TABLE_PARAMS
  );
  const [requestParams, setRequestParams] = useState<AlertsTableLocalState['requestParams']>({});

  const localState: AlertsTableData['localState'] = {
    selectedAlertsIds,
    allAlertsCount,
    isLoading,
    tableRefreshTrigger,
    tableParams,
    requestParams
  };

  const localActions: AlertsTableData['localActions'] = useMemo(
    () => ({
      setSelectedAlertsIds,
      setAllAlertsCount,
      setIsLoading,
      setTableRefreshTrigger,
      setTableParams,
      setRequestParams
    }),
    []
  );

  const prevAlertsRequestParams = usePreviousValue(requestParams);

  const prevProps: AlertsTableData['prevProps'] = {
    requestParams: prevAlertsRequestParams
  };

  return {
    prevProps,
    localState,
    localActions
  };
};
