import type { AdvancedSearchData } from './useAdvancedSearchData.types';
import type { AdvancedSearchHandlers } from './useAdvancedSearchHandlers.types';
import type { AdvancedSearchProps } from '../AdvancedSearch.types';

import { useCallback } from 'react';

export const useAdvancedSearchHandlers = ({
  props,
  localActions
}: {
  props: Pick<AdvancedSearchProps, 'onSubmit' | 'value'>;
  localActions: AdvancedSearchData['localActions'];
}): AdvancedSearchHandlers => {
  const handleAdvancedSearchToggle: AdvancedSearchHandlers['handleAdvancedSearchToggle'] = () => {
    localActions.setIsOpen(isOpen => !isOpen);
  };

  const handleAdvancedSearchSubmit: AdvancedSearchHandlers['handleAdvancedSearchSubmit'] = value => {
    localActions.setValue(value);
    localActions.setIsOpen(false);

    props.onSubmit(value);
  };

  const handleValuePropChange: AdvancedSearchHandlers['handleValuePropChange'] = useCallback(() => {
    localActions.setValue(props.value);
  }, [localActions, props.value]);

  return {
    handleAdvancedSearchToggle,
    handleAdvancedSearchSubmit,
    handleValuePropChange
  };
};
