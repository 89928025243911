import { DocumentActions, DocumentSummary, DocumentEditMetadata } from './elements';

import { useDocumentSummaryTabData, useDocumentSummaryTabHandlers } from './hooks';
import { useDocumentsReduxActions } from 'shared/features/documents/hooks/useDocumentsReduxActions';

import './_document-summary-tab.scss';

export const DocumentSummaryTab = ({ document }) => {
  const { reduxState, localState, localActions } = useDocumentSummaryTabData();

  const reduxActions = useDocumentsReduxActions();

  const handlers = useDocumentSummaryTabHandlers({ localActions, reduxState });

  return (
    <div className="document-summary-tab">
      {localState.isEditingMetadata && (
        <DocumentEditMetadata
          document={document}
          onClose={handlers.handleToggleMetadataEdit(false)}
        />
      )}

      {!localState.isEditingMetadata && (
        <>
          {!reduxState.isMobile && (
            <DocumentActions onEditMetadataOpen={handlers.handleToggleMetadataEdit(true)} />
          )}
          <DocumentSummary
            document={document}
            buildResourceCodeTree={handlers.handleBuildResourceCodeTree}
            fetchResourceCode={reduxActions.fetchResourceCode}
            path={localState.path}
          />
        </>
      )}
    </div>
  );
};
