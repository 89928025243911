import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAuthenticationLoadingState } from 'shared/features/auth/auth.selectors';

export const TITLE_MESSAGES = {
  EMAIL_SENT: 'We emailed you',
  FORGOT_PASSWORD: 'Forgot your password?',
  SET_YOUR_PASSWORD: 'Create your new password'
};

export const HEADING_MESSAGES = {
  EMAIL_SENT:
    'A verification code is on the way. To reset your password, enter the code we emailed you. It may take a minute to arrive.',
  FORGOT_PASSWORD: 'We’ll send you instructions for choosing a new password.',
  SET_YOUR_PASSWORD: ''
};

export const usePasswordResetModalData = ({ verificationCodeEmailData }) => {
  const [passwordResetEmail, setPasswordResetEmail] = useState(
    verificationCodeEmailData.email || ''
  );
  const [isEmailConfirmationModalOpen, setEmailConfirmationModalOpenState] = useState(
    verificationCodeEmailData.shouldUseEmailPasswordResetFlow
  );

  const reduxState = useSelector(state => ({
    isLoading: getAuthenticationLoadingState(state)
  }));

  const localActions = useMemo(() => {
    return {
      setPasswordResetEmail,
      setEmailConfirmationModalOpenState
    };
  }, []);

  let modalTitle = '';
  let modalHeadingTitle = '';

  if (verificationCodeEmailData.shouldUseEmailPasswordResetFlow) {
    modalTitle = TITLE_MESSAGES.SET_YOUR_PASSWORD;
    modalHeadingTitle = HEADING_MESSAGES.SET_YOUR_PASSWORD;
  } else if (isEmailConfirmationModalOpen) {
    modalTitle = TITLE_MESSAGES.EMAIL_SENT;
    modalHeadingTitle = HEADING_MESSAGES.EMAIL_SENT;
  } else {
    modalTitle = TITLE_MESSAGES.FORGOT_PASSWORD;
    modalHeadingTitle = HEADING_MESSAGES.FORGOT_PASSWORD;
  }

  return {
    localState: {
      passwordResetEmail,
      isEmailConfirmationModalOpen
    },
    localActions,
    reduxState,
    formattedData: {
      modalTitle,
      modalHeadingTitle
    }
  };
};
