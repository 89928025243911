import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';
import { RIGHT_PANEL_TYPES } from 'shared/features/rightPanel/rightPanel.constants';
import './_label-document-link.scss';

export const LabelDocumentLink = ({ documentId, children }) => {
  const reduxActions = useRightPanelReduxActions();

  const handleRightPanelOpen = () => {
    reduxActions.openRightPanel({
      type: RIGHT_PANEL_TYPES.DOCUMENT_DETAILS,
      props: {
        documentId
      }
    });
  };

  return (
    <a className="label-document-link" onClick={handleRightPanelOpen}>
      {children}
    </a>
  );
};
