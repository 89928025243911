import { useMemo } from 'react';
import { components } from 'react-select-3';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Tooltip } from 'common';

export const Option = props => {
  const { withCheckboxes, withTooltips, tooltipTitleRenderer, isSelected } = props;

  const checkbox = useMemo(() => {
    return isSelected ? (
      <CheckBoxIcon className="select-option-checkbox" />
    ) : (
      <CheckBoxOutlineBlankIcon className="select-option-checkbox--is-blank" />
    );
  }, [isSelected]);

  const content = useMemo(() => {
    if (withTooltips) {
      return (
        <Tooltip title={tooltipTitleRenderer ? tooltipTitleRenderer(props) : props.data.label}>
          {withCheckboxes && checkbox}
          {props.children}
        </Tooltip>
      );
    } else {
      return (
        <>
          {withCheckboxes && checkbox}
          {props.children}
        </>
      );
    }
  }, [checkbox, props, tooltipTitleRenderer, withCheckboxes, withTooltips]);

  return <components.Option {...props}>{content}</components.Option>;
};
