import PropTypes from 'prop-types';
import classnames from 'classnames';
import './_filter-header.scss';

export const TEST_ID = 'FilterHeader';

const FilterHeader = ({ className, children }) => {
  return (
    <div data-testid={TEST_ID} className={classnames(className, 'filter-header')}>
      {children}
    </div>
  );
};

FilterHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element
};

FilterHeader.defaultProps = {
  className: '',
  children: null
};

export default FilterHeader;
