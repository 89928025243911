import PropTypes from 'prop-types';
import { GoogleButton } from '../GoogleButton';
import { CognitoWindowedSignInForm } from '../CognitoWindowedSignInForm';
import { useAuthReduxActions } from 'shared/features/auth/hooks';
import { useGoogleLoginHandlers } from './hooks';

export const GoogleLogin = ({ onError, onSuccess, isDisabled, orgInvitationToken }) => {
  const reduxActions = useAuthReduxActions();

  const {
    handleGoogleSignInError,
    handleGoogleSignInSuccess,
    handleGoogleSignInButtonClick
  } = useGoogleLoginHandlers({
    props: {
      onError,
      onSuccess,
      orgInvitationToken
    },
    reduxActions
  });

  return (
    <CognitoWindowedSignInForm
      onError={handleGoogleSignInError}
      onSignIn={handleGoogleSignInSuccess}
    >
      {({ showWindow }) => (
        <GoogleButton
          onClick={isDisabled ? undefined : handleGoogleSignInButtonClick(showWindow)}
          isDummyButton
          clientId={null}
        />
      )}
    </CognitoWindowedSignInForm>
  );
};

GoogleLogin.propTypes = {
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  isDisabled: PropTypes.bool,
  orgInvitationToken: PropTypes.string
};

GoogleLogin.defaultProps = {
  onError: () => {},
  onSuccess: () => {},
  isDisabled: false,
  orgInvitationToken: undefined
};
