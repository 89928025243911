import PropTypes from 'prop-types';
import { GenericAttachments } from '../GenericAttachments';
import './_dropzone.scss';

export const Dropzone = ({
  files,
  onSave,
  onDelete,
  isEditable,
  maxFiles,
  shouldShowAllowedExtensions,
  allowedExtensions
}) => {
  return (
    <GenericAttachments
      containerClassName="dropzone__container"
      dropzoneAreaClassName="dropzone__drop-area"
      buttonClassName="dropzone__attach-button"
      attachmentClassName="dropzone__attachment"
      deleteButtonClassName="dropzone__delete-file-button"
      actionWrapperClassName="dropzone__action-wrapper"
      actionText="Drag and drop or "
      attachButtonText="select file"
      shouldShowAttachmentIcon={false}
      shouldShowAllowedExtensions={shouldShowAllowedExtensions}
      allowedExtensions={allowedExtensions}
      attachments={files}
      saveAttachment={onSave}
      deleteAttachment={onDelete}
      disableEdit={!isEditable}
      maxFiles={maxFiles}
    />
  );
};

Dropzone.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      userFileName: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
  maxFiles: PropTypes.number,
  shouldShowAllowedExtensions: PropTypes.bool,
  allowedExtensions: PropTypes.arrayOf(PropTypes.string)
};
