import { fetch } from 'utils/api';
import { addMockPermissionsToDocs, buildUrlFromParams, sanitizeParams } from './documents.helper';
import { googleSearchAPIKey, googleSearchCXId } from 'shared/config';
import { DOCUMENTS } from 'constants/DataTypes';
import { AUTHENTICATION_OPTIONS_KEYS, BASE_API_OPTIONS } from 'fetch';

export function api_updateDocuments(document_ids, data) {
  return fetch({
    url: '/documents',
    dataType: DOCUMENTS,
    method: 'POST',
    params: {
      ...data,
      document_ids
    }
  });
}

export function api_getDocTypes() {
  return fetch({
    url: '/doc_types',
    dataType: 'DocTypes',
    method: 'GET'
  });
}

export function api_getDocTypesUpdates() {
  return fetch({
    url: '/doc_types_notification',
    dataType: 'DocTypesUpdatesNotification',
    method: 'GET'
  });
}

export function api_fetchGoogleResults(queryParam) {
  return fetch({
    url: `https://www.googleapis.com/customsearch/v1?key=${googleSearchAPIKey}&cx=${googleSearchCXId}&q=${queryParam}`,
    method: 'GET',
    dataType: 'GoogleResults',
    baseApi: BASE_API_OPTIONS.BASE_API_ABSOLUTE
  });
}

export function api_fetchDocDiff(docs) {
  return fetch({
    url: '/diff_documents',
    method: 'POST',
    dataType: 'DocDiff',
    params: docs
  });
}

export function api_getDocumentsBasic(params) {
  return fetch({
    url: '/docs',
    dataType: DOCUMENTS,
    params,
    method: 'GET'
  });
}

export function api_getDocsForIFrame(params) {
  return fetch({
    url: buildUrlFromParams(params, '/docs'),
    dataType: DOCUMENTS,
    method: 'GET',
    authenticationType: AUTHENTICATION_OPTIONS_KEYS.API_KEY
  });
}

export function api_fetchSentences(docIds, params, contentType = 'application/json') {
  return fetch({
    url: `/sentences`,
    dataType: 'Sentences',
    params: {
      ...params,
      ids: docIds.join(','),
      data_type: contentType === 'text/html' ? 'html' : 'json'
    },
    method: 'GET'
  });
}

export async function api_getEnforcements(params, useAPIKey) {
  const response = await fetch({
    url: '/documents_post',
    dataType: 'Enforcements',
    method: 'POST',
    authenticationType: useAPIKey ? AUTHENTICATION_OPTIONS_KEYS.API_KEY : undefined,
    params: params
  });

  return addMockPermissionsToDocs(response);
}

export async function api_getDocuments(params, rnToken) {
  const response = await fetch({
    url: buildUrlFromParams(params, '/documents'),
    customToken: rnToken,
    dataType: DOCUMENTS,
    method: 'GET',
    shouldHideErrorNotifications: params?.doc_details
  });

  return addMockPermissionsToDocs(response);
}

export async function api_getDocumentsPost(params, rnToken) {
  const response = await fetch({
    url: '/documents_post',
    customToken: rnToken,
    dataType: DOCUMENTS,
    method: 'POST',
    params: params
  });

  return addMockPermissionsToDocs(response);
}

export async function api_getDocuments_V2(params) {
  const response = await fetch({
    url: '/documents_post',
    dataType: DOCUMENTS,
    method: 'POST',
    params
  });

  return addMockPermissionsToDocs(response);
}

export function api_fetchAggregateEnforcements(params) {
  const aggregateParams = sanitizeParams({
    ...params,
    limit: '2000', // need to aggregate all enforcements,
    offset: String(params?.offset ?? 0)
  });

  return fetch({
    url: '/documents/enforcements-aggregate',
    params: aggregateParams,
    dataType: 'AggregateEnforcements',
    method: 'POST'
  });
}

export async function api_vanillaGetDocuments(params) {
  const response = fetch({
    url: '/documents',
    dataType: DOCUMENTS,
    params,
    method: 'GET'
  });

  return addMockPermissionsToDocs(response);
}

export async function api_getDocument(documentId) {
  const response = await fetch({
    url: `/documents/${documentId}`,
    dataType: 'Document',
    method: 'GET'
  });

  return addMockPermissionsToDocs(response);
}

export function api_fetchRecentActivity(params) {
  return fetch({
    url: `/rules_created_by_time`,
    dataType: 'RecentActivity',
    params,
    method: 'GET'
  });
}

export const api_addOrganizationRelatedDocuments = (documentId, params) => {
  return fetch({
    url: `/organization_related_documents/${documentId}`,
    dataType: 'RelatedDocuments',
    method: 'POST',
    params
  });
};

export const api_deleteOrganizationRelatedDocuments = (documentId, params) => {
  return fetch({
    url: `/organization_related_documents/${documentId}`,
    dataType: 'RelatedDocuments',
    method: 'DELETE',
    params
  });
};

export const api_getOrganizationRelatedDocumentAlerts = documentId => {
  return fetch({
    url: `/organization_related_document_alerts/${documentId}`,
    dataType: 'RelatedDocuments',
    method: 'GET'
  });
};

export const api_addOrganizationRelatedDocumentAlerts = (documentId, params) => {
  return fetch({
    url: `/organization_related_document_alerts/${documentId}`,
    dataType: 'RelatedDocuments',
    method: 'POST',
    params
  });
};

export const api_deleteOrganizationRelatedDocumentAlerts = (documentId, params) => {
  return fetch({
    url: `/organization_related_document_alerts/${documentId}`,
    dataType: 'RelatedDocuments',
    method: 'DELETE',
    params
  });
};
