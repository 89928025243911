import type { ComponentProps } from 'react';
import type * as elements from './elements';

export enum SECTION_TYPE {
  TASKS = 'tasks',
  COMMENTS = 'comments',
  ANNOTATIONS = 'annotations',
  OBLIGATIONS = 'obligations'
}

export const SECTION_CLASSNAMES: Required<
  ComponentProps<
    | typeof elements.TasksSection
    | typeof elements.CommentsSection
    | typeof elements.SentenceNotesSection
  >['classNames']
> = {
  headerCell: 'workflow-summary__table-header-cell',
  cell: 'workflow-summary__table-cell',
  tableOuterContainer: 'workflow-summary__table-outer-container',
  tableContainer: 'workflow-summary__table-container'
};
