export const SKU_RESTRICTION_TYPES = {
  AUTOTRANSLATE: 'autotranslate',
  PREMIUM_CONTENT: 'premium_content',
  GLOBAL_CONTENT: 'global_content',
  US_CONTENT: 'us_content',
  JURISDICTION_CONTENT: 'jurisdiction_content',
  ENFORCEMENT_EXPLORER: 'enforcement_explorer',
  BYOC: 'byoc',
  GLASSDOOR: 'glassdoor',
  INTERNAL_DATA: 'internal_data',
  SERVICE_CONNECTOR: 'service_connector',
  BPM: 'bpm'
};

export const SKU_RESTRICTION_FIELDS = {
  PRINT: 'print',
  EXPORT: 'export',
  DIFF: 'diff',
  VIEW: 'view',
  RESOURCES_ACCESS: 'resources_access',
  ANNOTATIONS: 'annotations',
  OBLIGATIONS: 'obligations',
  SENTENCES: 'sentences',
  FULL_TEXT: 'full_text',
  PDF_URL: 'pdf_url'
};
