import { useState, useMemo } from 'react';
import lodash from 'lodash';

export const useSelectV2Data = ({ value, inputValue, options }) => {
  const [input, setInput] = useState(inputValue);

  const formattedValue = useMemo(() => {
    // Only value of the selected option is provided.
    if (!lodash.isObject(value)) {
      if (Array.isArray(options)) {
        return options.find(option => option.value === value) || null;
      }

      return null;
    }

    if (Array.isArray(value)) {
      return value.map(valueItem => {
        // If array of values was passed instead of objects, try to find option
        if (!lodash.isObject(valueItem) && Array.isArray(options)) {
          return options.find(option => option.value === valueItem);
        }
        return valueItem;
      });
    }

    return value;
  }, [value, options]);

  return {
    localState: { input },
    localActions: {
      setInput
    },
    formattedData: {
      value: formattedValue
    }
  };
};
