import lodash from 'lodash';
import {
  REQUEST_ALL_USERS,
  RECEIVE_ALL_USERS,
  REQUEST_CURRENT_USER,
  RECEIVE_CURRENT_USER,
  MODIFY_CURRENT_USER,
  MODIFIED_CURRENT_USER,
  NEW_FEATURE_TIP_HAS_BEEN_READ,
  CLEAR_CURRENT_USER_UPDATED_STATE,
  REQUEST_SPECIFIED_USER,
  RECEIVE_SPECIFIED_USER,
  MODIFY_SPECIFIED_USER,
  MODIFIED_SPECIFIED_USER,
  REQUEST_USER_CREATED_DOCUMENTS,
  RECEIVE_USER_CREATED_DOCUMENTS,
  CREATE_SEARCH_QUERY,
  SEARCH_RESULT_RATED,
  RECEIVE_UNAUTHENTIATED_CURRENT_USER,
  RECEIVE_UPDATE_DASHBOARD_LAYOUT,
  AUTHORIZE_PREMIUM_CONTENT,
  RECEIVE_APP_NOTIFICATIONS,
  REQUEST_UPDATE_DASHBOARD_LAYOUT,
  RECIEVE_FOLLOWED_CATEGORIES,
  REMOVE_FOLLOWED_CATEGORIES,
  ADD_FOLLOWED_CATEGORIES,
  SET_MODIFIED_DEFAULTS_FLAG,
  UPDATE_USER_WIDGET,
  SET_UPGRADE_BANNER_OPEN_FLAG,
  RECEIVE_USER_PREFERENCES,
  RECEIVE_USER_SEARCH_COUNT,
  REQUEST_USER_PREFERENCES,
  REJECT_USER_PREFERENCES
} from './user.actions';
import { FINANCE_REGULATORY } from 'constants/UserDashboardTypes';

export const getInitialCurrentUserState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  appNotifications: [],
  isLoadingUserPreferences: false,
  areUserPreferencesReady: false,
  user: {
    roles: [],
    organization_roles: [],
    properties: {
      email_updates: {}
    },
    dashboard_layouts: [],
    pc_docs_access: {},
    followed_categories: {},
    docsTablesSettings: {},
    has_modified_defaults: true,
    timezone: 'UTC',
    autoSetTimezone: true,
    languageId: '',
    selectedJurisdictions: [],
    selectedResources: [],
    selectedResource: null,
    taskReportsFields: {},
    skus: [],
    glassdoorApplyUserDefaults: true,
    selectedTasksView: null
  },
  upgradeBannerOpen: true
});

export const current_user = (state = getInitialCurrentUserState(), action) => {
  switch (action.type) {
    case REQUEST_CURRENT_USER:
      return {
        ...state,
        isFetching: true,
        isReady: false,
        updated: false
      };
    case RECEIVE_CURRENT_USER: {
      return {
        ...state,
        isFetching: false,
        isReady: true,
        user: { ...state.user, ...action.user },
        updated: false
      };
    }
    case RECEIVE_USER_SEARCH_COUNT: {
      const { search_count } = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          search_count
        }
      };
    }
    case RECEIVE_UNAUTHENTIATED_CURRENT_USER:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        user: state.user,
        updated: false
      };
    case MODIFY_CURRENT_USER:
      return {
        ...state,
        isFetching: false,
        isReady: false,
        isUpdating: true,
        updated: false
      };

    case REQUEST_USER_PREFERENCES:
      return {
        ...state,
        isLoadingUserPreferences: true
      };

    case REJECT_USER_PREFERENCES:
      return {
        ...state,
        isLoadingUserPreferences: false
      };

    case RECEIVE_USER_PREFERENCES:
      const {
        timezone_name: timezone,
        language_id: languageId,
        auto_set_timezone: autoSetTimezone,
        selected_jurisdictions: selectedJurisdictions,
        selected_resources: selectedResources,
        selected_resource: selectedResource,
        task_reports_fields: taskReportsFields,
        docs_tables_settings: docsTablesSettings,
        glassdoor_apply_user_defaults: glassdoorApplyUserDefaults,
        selected_tasks_view: selectedTasksView
      } = action.payload;

      return {
        ...state,
        isLoadingUserPreferences: false,
        areUserPreferencesReady: true,
        user: {
          ...state.user,
          timezone,
          autoSetTimezone,
          languageId,
          selectedJurisdictions,
          selectedResources,
          selectedResource,
          taskReportsFields,
          docsTablesSettings: docsTablesSettings ?? {},
          glassdoorApplyUserDefaults,
          selectedTasksView
        }
      };

    case NEW_FEATURE_TIP_HAS_BEEN_READ:
      return {
        ...state,
        user: {
          ...state.user,
          properties: {
            ...state.user.properties,
            read_new_feature_tip: {
              ...state.user.properties.read_new_feature_tip,
              [action.featureId]: true
            }
          }
        }
      };

    case MODIFIED_CURRENT_USER:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        isUpdating: false,
        updated: true,
        user: action.user ? { ...state.user, ...action.user } : state.user
      };
    case CLEAR_CURRENT_USER_UPDATED_STATE:
      return {
        ...state,
        updated: false
      };
    case RECEIVE_APP_NOTIFICATIONS:
      return {
        ...state,
        appNotifications: action.appNotifications
      };

    case REQUEST_UPDATE_DASHBOARD_LAYOUT:
      return {
        ...state,
        isReady: false
      };
    case RECEIVE_UPDATE_DASHBOARD_LAYOUT:
      return {
        ...state,
        isReady: true,
        user: {
          ...state.user,
          // If the dashboard already exists then update it otherwise add it
          dashboard_layouts: ((dashboard_type, layout, components) => {
            let foundLayout = false;
            const layouts = state.user.dashboard_layouts.map(dl => {
              if (dl.dashboard_type === dashboard_type) {
                foundLayout = true;
                return { ...dl, layout, components };
              }

              return dl;
            });

            if (!foundLayout) {
              layouts.push({ dashboard_type, layout, components });
            }

            return layouts;
          })(action.dashboard_type, action.layout, action.components)
        }
      };
    case UPDATE_USER_WIDGET:
      const { component, layout } = action.payload;
      const { dashboard_layouts } = state.user;

      const updatedDashboardLayouts = dashboard_layouts.map(dashboard => {
        if (dashboard.dashboard_type === FINANCE_REGULATORY) {
          return {
            ...dashboard,
            components: [...dashboard.components, component],
            layout: {
              ...dashboard.layout,
              lg: [...dashboard.layout.lg, layout.lg],
              sm: [...dashboard.layout.sm, layout.sm]
            }
          };
        } else {
          return dashboard;
        }
      });

      return {
        ...state,
        user: { ...state.user, dashboard_layouts: updatedDashboardLayouts }
      };
    case AUTHORIZE_PREMIUM_CONTENT:
      return {
        ...state,
        user: {
          ...state.user,
          pc_docs_access: action.payload
        }
      };
    case RECIEVE_FOLLOWED_CATEGORIES:
      return {
        ...state,
        user: {
          ...state.user,
          followed_categories: action.payload
        }
      };
    case REMOVE_FOLLOWED_CATEGORIES:
      return {
        ...state,
        user: {
          ...state.user,
          followed_categories: (categories => {
            const newCategoriesObject = lodash.cloneDeep(state.user.followed_categories);
            categories.forEach(category => {
              if (newCategoriesObject[category]) {
                delete newCategoriesObject[category];
              }
            });

            return newCategoriesObject;
          })(action.payload)
        }
      };

    case ADD_FOLLOWED_CATEGORIES:
      return {
        ...state,
        user: {
          ...state.user,
          followed_categories: (categories => {
            const newCategoriesObject = lodash.cloneDeep(state.user.followed_categories);
            categories.forEach(category => {
              newCategoriesObject[category] = true;
            });

            return newCategoriesObject;
          })(action.payload)
        }
      };
    case SET_MODIFIED_DEFAULTS_FLAG:
      return {
        ...state,
        user: {
          ...state.user,
          has_modified_defaults: true
        }
      };
    case SET_UPGRADE_BANNER_OPEN_FLAG:
      return {
        ...state,
        upgradeBannerOpen: false
      };

    default:
      return state;
  }
};

export const getInitialAllUsersState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  users: []
});

export const all_users = (state = getInitialAllUsersState(), action) => {
  switch (action.type) {
    case REQUEST_ALL_USERS:
      return {
        ...state,
        isFetching: true,
        isReady: false,
        updated: false
      };
    case RECEIVE_ALL_USERS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        users: action.users,
        updated: false
      };
    default:
      return state;
  }
};

export const getInitialSpecifiedUserState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  user: {}
});

export const specified_user = (state = getInitialSpecifiedUserState(), action) => {
  switch (action.type) {
    case REQUEST_SPECIFIED_USER:
      return {
        ...state,
        isFetching: true,
        isReady: false,
        updated: false
      };
    case RECEIVE_SPECIFIED_USER:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        user: action.user,
        updated: false
      };
    case MODIFY_SPECIFIED_USER:
      return {
        ...state,
        isFetching: false,
        isReady: false,
        isUpdating: true,
        updated: false
      };
    case MODIFIED_SPECIFIED_USER:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        isUpdating: false,
        updated: true,
        user: action.user || state.user // error state if action.user is null
      };
    case CLEAR_CURRENT_USER_UPDATED_STATE:
      return {
        ...state,
        updated: false
      };

    default:
      return state;
  }
};

export const getInitialUserVoteState = () => ({
  search_args: {},
  voted_docs: {}
});

export const user_vote = (state = getInitialUserVoteState(), action) => {
  switch (action.type) {
    case CREATE_SEARCH_QUERY: {
      return {
        ...state,
        search_args: action.search_args
      };
    }
    case SEARCH_RESULT_RATED: {
      return {
        ...state,
        voted_docs: {
          ...state.voted_docs,
          [action.doc_id]: {
            relevance: action.is_relevant,
            search_args: action.search_args
          }
        }
      };
    }
    default:
      return state;
  }
};

export const getInitialUserCreatedDocumentsState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  user_created_documents: []
});

export const user_created_documents = (state = getInitialUserCreatedDocumentsState(), action) => {
  switch (action.type) {
    case REQUEST_USER_CREATED_DOCUMENTS:
      return {
        ...state,
        isFetching: true,
        isReady: false,
        updated: false
      };
    case RECEIVE_USER_CREATED_DOCUMENTS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.user_created_documents,
        updated: false
      };
    default:
      return state;
  }
};
