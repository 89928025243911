import type { CommenterProps } from './Commenter.types';

import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';
import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import { useCommenterHandlers } from './hooks';

export const Commenter = ({ document }: CommenterProps) => {
  const handlers = useCommenterHandlers({
    props: {
      document
    }
  });

  if (!document.comment) {
    return null;
  }

  return (
    <>
      <FieldName>Commenter</FieldName>
      <FieldValue>
        <Button type={BUTTON_TYPES.LINK_CONTRAST} onClick={handlers.handleClick}>
          {document.comment.submitter_name ?? ''}
        </Button>
      </FieldValue>
    </>
  );
};
