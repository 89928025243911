import { fetch } from 'utils/api';
import { AUTHENTICATION_OPTIONS_KEYS } from 'fetch';

export function api_fetchAutoComplete(partialValue, params) {
  return fetch({
    url: `/autosuggest/${encodeURIComponent(partialValue)}`,
    method: 'GET',
    dataType: 'AutoComplete',
    params
  });
}

export function api_fetchIframeAutoComplete(params, useAPIKey) {
  return fetch({
    url: `/suggestion`,
    method: 'GET',
    authenticationType: useAPIKey ? AUTHENTICATION_OPTIONS_KEYS.API_KEY : undefined,
    params,
    dataType: 'AutoComplete'
  });
}

export function api_postSearch(params) {
  return fetch({
    url: '/searches',
    method: 'POST',
    dataType: 'Searches',
    params
  });
}
