import * as uiLib from '@compliance.ai/web-components';
import * as dateUtils from 'utils/date-utils';

import lodash from 'lodash';
import { DOCUMENT_CATEGORIES } from 'constants/DocumentConstants';
import { DOCUMENT_FIELDS } from 'constants/DocumentProperties';

export const DOCUMENT_PROPERTY_LABEL = 'DOCUMENT_PROPERTY_LABEL';
export const DOCUMENT_PROPERTY_VALUE = 'DOCUMENT_PROPERTY_VALUE';

const toAgencyNames = ({ short_name, name }) => short_name || name;

const alphabetically = (a, b) => a.localeCompare(b);

export const getDocumentUrl = document => {
  return document ? document.web_url || document.pdf_url : null;
};

const getDocumentAuthorNamesValue = document => {
  switch (document.category) {
    case DOCUMENT_CATEGORIES.WHITEPAPER: {
      const authors = lodash.get(document, 'whitepaper.metadata.authors', []);
      return authors.sort(alphabetically);
    }

    case DOCUMENT_CATEGORIES.MAINSTREAM_NEWS:
      return lodash.get(document, 'mainstream_news.news_source.name', []);

    default:
      if (lodash.isEmpty(document.agencies)) {
        return ['Other'];
      }

      return document.agencies.map(toAgencyNames).sort(alphabetically);
  }
};

const AUTHOR_NAMES_LABEL = 'Authors';

const getDocumentAuthorNames = ({ document }) => {
  return {
    [DOCUMENT_PROPERTY_LABEL]: AUTHOR_NAMES_LABEL,
    [DOCUMENT_PROPERTY_VALUE]: getDocumentAuthorNamesValue(document)
  };
};

export const PUBLICATION_DATE_LABEL = 'Publication Date';
export const ADDED_TO_COMPLIANCE_DATE_LABEL = 'Added to Compliance.ai';
export const UNKNOWN_PUBLICATION_DATE_LABEL = 'Unknown Publication Date';
export const UNKONWN_PUBLICATION_DATE_VALUE = 'Unknown';
/**
 *
 * Gets a Documents publication or add to compliance date based on key properties
 * @param {Object} document - document to lodash.get published data
 * @param {String} timezoneName - timezoneName to format data on
 * @return {Object} - key date and associated string for verbage
 *
 */
const getDocumentPublishedorAddedDate = ({ document, timezoneName }) => {
  const publicationDate = document[DOCUMENT_FIELDS.publicationDate];
  const hasUnofficialPublicationDate = document[DOCUMENT_FIELDS.hasUnofficialPublicationDate];

  if (publicationDate && !hasUnofficialPublicationDate) {
    return {
      [DOCUMENT_PROPERTY_LABEL]: PUBLICATION_DATE_LABEL,
      [DOCUMENT_PROPERTY_VALUE]: dateUtils.formatDateWithTimezone(
        publicationDate,
        timezoneName,
        uiLib.DATE_FORMATS.DOCUMENT_DATE
      )
    };
  }

  const addToComplianceDate = document[DOCUMENT_FIELDS.createdAt];

  if (addToComplianceDate) {
    return {
      [DOCUMENT_PROPERTY_LABEL]: ADDED_TO_COMPLIANCE_DATE_LABEL,
      [DOCUMENT_PROPERTY_VALUE]: dateUtils.formatDateWithTimezone(
        addToComplianceDate,
        timezoneName,
        'dddd, MMMM Do, YYYY'
      )
    };
  }

  //If we can't extract either of the other two dates return a default
  return {
    [DOCUMENT_PROPERTY_LABEL]: UNKNOWN_PUBLICATION_DATE_LABEL,
    [DOCUMENT_PROPERTY_VALUE]: UNKONWN_PUBLICATION_DATE_VALUE
  };
};

const getDocumentCreatedAt = ({ document, timezoneName }) => {
  const createdAtDate = document[DOCUMENT_FIELDS.createdAt];

  return {
    [DOCUMENT_PROPERTY_LABEL]: ADDED_TO_COMPLIANCE_DATE_LABEL,
    [DOCUMENT_PROPERTY_VALUE]: dateUtils.formatDateWithTimezone(
      createdAtDate,
      timezoneName,
      uiLib.DATE_FORMATS.DOCUMENT_DATE
    )
  };
};

export const getDocumentProperty = {
  [DOCUMENT_FIELDS.publicationDate]: getDocumentPublishedorAddedDate,
  [DOCUMENT_FIELDS.agencies]: getDocumentAuthorNames,
  [DOCUMENT_FIELDS.createdAt]: getDocumentCreatedAt
};
