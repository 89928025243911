import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { normalizeFoldersResponse } from './folders.helpers';
import { logError } from 'utils/errors';
import * as api from './folders.api';

export const FETCH_FOLDERS = 'FETCH_FOLDERS';
export const FETCH_DOCUMENTS_SAVED_FOLDERS = 'FETCH_DOCUMENTS_SAVED_FOLDERS';
export const FETCH_DOCUMENT_SAVED_FOLDERS_COUNT = 'FETCH_DOCUMENT_SAVED_FOLDERS_COUNT';
export const FETCH_FOLDER_DETAILS = 'FETCH_FOLDER_DETAILS';
export const RESET_FOLDER_DETAILS = 'RESET_FOLDER_DETAILS';
export const FETCH_FOLDER_PATHS = 'FETCH_FOLDER_PATHS';
export const RESET_FOLDER_PATHS = 'RESET_FOLDER_PATHS';
export const CREATE_FOLDER = 'CREATE_FOLDER';
export const MODIFY_FOLDER = 'MODIFY_FOLDER';
export const DELETE_FOLDERS = 'DELETE_FOLDERS';
export const COPY_FOLDER = 'COPY_FOLDER';
export const SHARE_FOLDER = 'SHARE_FOLDER';
export const ADD_DOCUMENTS_TO_FOLDER = 'ADD_DOCUMENTS_TO_FOLDER';
export const TRIGGER_FOLDERS_REFRESH = 'TRIGGER_FOLDERS_REFRESH';

export const triggerFoldersRefresh = createAction(TRIGGER_FOLDERS_REFRESH);

export const fetchFolders = createAsyncThunk(FETCH_FOLDERS, async ({ parentId, params }) => {
  try {
    const response = await api.getFolders(parentId, params);

    return normalizeFoldersResponse(response);
  } catch (e) {
    logError(e, 'Error fetching folders');
    throw e;
  }
});

export const fetchDocumentsSavedFolders = createAsyncThunk(
  FETCH_DOCUMENTS_SAVED_FOLDERS,
  async ({ params }) => {
    try {
      const response = await api.getDocumentsSavedFolders(params);

      return response;
    } catch (e) {
      logError(e, 'Error fetching docuemnts saved folders');
      throw e;
    }
  }
);

export const fetchDocumentSavedFoldersCount = createAsyncThunk(
  FETCH_DOCUMENT_SAVED_FOLDERS_COUNT,
  async ({ documentId }) => {
    try {
      const response = await api.getDocumentSavedFoldersCount(documentId);

      return response;
    } catch (e) {
      logError(e, 'Error fetching docuemnt saved folders count');
      throw e;
    }
  }
);

export const fetchFolderDetails = createAsyncThunk(FETCH_FOLDER_DETAILS, async ({ id }) => {
  try {
    const response = await api.getFolderDetails(id);

    return response;
  } catch (e) {
    logError(e, 'Error fetching folder details');
    throw e;
  }
});

export const resetFolderDetails = createAction(RESET_FOLDER_DETAILS);

export const fetchFolderPaths = createAsyncThunk(FETCH_FOLDER_PATHS, async ({ id }) => {
  try {
    const response = await api.getFolderPaths(id);

    return response;
  } catch (e) {
    logError(e, 'Error fetching folder paths');
    throw e;
  }
});

export const resetFolderPaths = createAction(RESET_FOLDER_PATHS);

export const createFolder = createAsyncThunk(CREATE_FOLDER, async ({ parentId, params }) => {
  try {
    return await api.createFolder(parentId, params);
  } catch (e) {
    logError(e, 'Error creating folder');
    throw e;
  }
});

export const modifyFolder = createAsyncThunk(MODIFY_FOLDER, async ({ parentId, id, params }) => {
  try {
    return await api.modifyFolder(parentId, id, params);
  } catch (e) {
    logError(e, 'Error modifying folder');
    throw e;
  }
});

export const deleteFolders = createAsyncThunk(DELETE_FOLDERS, async ({ parentId, params }) => {
  try {
    return await api.deleteFolders(parentId, params);
  } catch (e) {
    logError(e, 'Error deleting folder');
    throw e;
  }
});

export const copyFolder = createAsyncThunk(COPY_FOLDER, async ({ id, params }) => {
  try {
    const response = await api.copyFolder(id, params);

    return response;
  } catch (e) {
    logError(e, 'Error copying folder');
    throw e;
  }
});

export const shareFolder = createAsyncThunk(SHARE_FOLDER, async ({ id, params }) => {
  try {
    const response = await api.shareFolder(id, params);

    return response;
  } catch (e) {
    logError(e, 'Error sharing folder');
    throw e;
  }
});

export const addDocumentsToFolder = createAsyncThunk(
  ADD_DOCUMENTS_TO_FOLDER,
  async ({ id, params }) => {
    try {
      const response = await api.addDocumentsToFolder(id, params);

      return response;
    } catch (e) {
      logError(e, 'Error adding documents to a folder');
      throw e;
    }
  }
);
