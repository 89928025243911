import type * as actionsTypes from './sentenceNotes.actions.types';

import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

import * as api from './sentenceNotes.api';
import * as helpers from './sentenceNotes.helpers';
import * as errorUtils from 'utils/errors';
import * as redux from '@reduxjs/toolkit';

export const GET_SENTENCE_NOTES = 'GET_SENTENCE_NOTES';
export const FETCH_SYSTEM_GENERATED_SENTENCE_NOTES = 'FETCH_SYSTEM_GENERATED_SENTENCE_NOTES';
export const MODIFY_SENTENCE_NOTE = 'MODIFY_SENTENCE_NOTE';
export const DELETE_SENTENCE_NOTE = 'DELETE_SENTENCE_NOTE';
export const CREATE_SENTENCE_NOTE = 'CREATE_SENTENCE_NOTE';
export const CLEAR_SENTENCE_NOTES_REDUCER = 'CLEAR_SENTENCE_NOTES_REDUCER';
export const TOGGLE_SENTENCE_NOTE = 'TOGGLE_SENTENCE_NOTE';

export const toggleSentenceNote: actionsTypes.ToggleSentenceNote = createAction(
  TOGGLE_SENTENCE_NOTE
);

export const getSentenceNotes = createAsyncThunk<
  actionsTypes.GetSentenceNotesReturn,
  actionsTypes.GetSentenceNotesPayload
>(GET_SENTENCE_NOTES, async ({ params }) => {
  try {
    const response = await api.getSentenceNotes(params);

    return helpers.normalizeSentenceNotesResponse(response);
  } catch (e) {
    errorUtils.logError(e as Error);
    throw e;
  }
});

export const fetchSystemGeneratedSentenceNotes = createAsyncThunk<
  actionsTypes.FetchSystemGeneratedSentenceNotesReturn,
  actionsTypes.FetchSystemGeneratedSentenceNotesPayload
>(FETCH_SYSTEM_GENERATED_SENTENCE_NOTES, async ({ documentId }) => {
  try {
    const response = await api.fetchSystemGeneratedSentenceNotes(documentId);

    return helpers.normalizeSystemGeneratedSentenceNotesResponse(response);
  } catch (e) {
    errorUtils.logError(e as Error);
    throw e;
  }
});

export const modifySentenceNote = createAsyncThunk<
  actionsTypes.ModifySentenceNoteReturn,
  actionsTypes.ModifySentenceNotePayload
>(MODIFY_SENTENCE_NOTE, async ({ id, params }) => {
  try {
    const response = await api.modifySentenceNote(id, params);

    const { sentence_notes } = await api.getSentenceNotes({
      id: response.id
    });

    return helpers.normalizeSentenceNote(sentence_notes[0]);
  } catch (e) {
    errorUtils.logError(e as Error, 'Error', { isSilent: true });
    throw e;
  }
});

export const deleteSentenceNote = createAsyncThunk<
  actionsTypes.DeleteSentenceNoteReturn,
  actionsTypes.DeleteSentenceNotePayload
>(DELETE_SENTENCE_NOTE, async id => {
  try {
    return await api.deleteSentenceNote(id);
  } catch (e) {
    errorUtils.logError(e as Error, 'Error', { isSilent: true });
    throw e;
  }
});

export const createSentenceNote = createAsyncThunk<
  actionsTypes.CreateSentenceNoteReturn,
  actionsTypes.CreateSentenceNotePayload
>(CREATE_SENTENCE_NOTE, async ({ params }) => {
  try {
    const response = await api.createSentenceNote(params);

    const { sentence_notes } = await api.getSentenceNotes({
      id: response.new_sentence_note_id
    });

    return helpers.normalizeSentenceNote(sentence_notes[0]);
  } catch (e) {
    errorUtils.logError(e as Error, 'Error', { isSilent: true });
    throw e;
  }
});

export const clearSentenceNotesReducer: actionsTypes.ClearSentenceNotesReducer = redux.createAction(
  CLEAR_SENTENCE_NOTES_REDUCER
);
