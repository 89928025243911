import type * as documentTypes from 'shared/features/documents/documents.types';
import type * as viewConstants from 'shared/features/view/view.constants';

import lodash from 'lodash';

import * as constants from '../DocumentsTable.constants';
import * as uiLib from '@compliance.ai/web-components';

export const GROUP_IDENTITY_ITERATORS: Partial<Record<
  constants.SORT_BY | viewConstants.VIEW_KEY.TIMELINE,
  lodash.ValueIteratee<documentTypes.DocumentFromResponse>
>> = {
  [constants.SORT_BY.PUBLICATION_DATE]: document => {
    if (!document.publication_date) {
      return 'Non Published';
    }

    return uiLib.getStartOfDate(document.publication_date, uiLib.DATE_UNITS.DAY, {
      format: uiLib.DATE_FORMATS.DOCUMENT_DATE,
      timezone: uiLib.TIMEZONES.UTC,
      passedTimezone: uiLib.TIMEZONES.UTC,
      shouldKeepLocalTime: true
    });
  },
  timeline: document => {
    return uiLib.getStartOfDate(document.sorted_by, uiLib.DATE_UNITS.DAY, {
      format: uiLib.DATE_FORMATS.DOCUMENT_DATE,
      timezone: uiLib.TIMEZONES.UTC,
      passedTimezone: uiLib.TIMEZONES.UTC,
      shouldKeepLocalTime: true
    });
  }
};
