import type { GlobalState } from 'shared/reducers';

import { useSelector } from 'react-redux';
import { getOrganizationMemberEmailByUserId } from 'shared/features/organizations/organizations.selectors';
import { DEFAULT_EMPTY_COLUMN } from '../../AlertsTable.constants';

export const AlertsTableUserColumn = (userId: unknown) => {
  const reduxState = useSelector<GlobalState, { userEmail: string }>(state => ({
    userEmail: getOrganizationMemberEmailByUserId(userId)(state)
  }));

  return <>{reduxState.userEmail ?? DEFAULT_EMPTY_COLUMN}</>;
};
