import { useState } from 'react';
import { usePermissions } from 'utils/hooks';
import { logError } from 'utils/errors';
import { MANAGE_WORKFLOWS } from 'constants/Permissions';
import { Modal, TextField, Button, BUTTON_TYPES } from '@compliance.ai/web-components';

export const SaveAlertModal = ({
  createBtnTitle,
  modalTitle,
  saveAlertModalOpen,
  renderErrors,
  saveAlert,
  closeModal
}) => {
  const [name, setName] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const permissions = usePermissions();

  const handleSaveAlert = async () => {
    setIsSaving(true);

    try {
      await saveAlert(name);
    } catch (e) {
      logError(e, 'Error creating the alert');
      throw e;
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal
      title={modalTitle}
      isOpen={saveAlertModalOpen}
      className="save-alert-modal"
      onClose={() => closeModal('cancel')}
    >
      <div className="modal-body">
        {renderErrors()}
        <div className="create-saved-search-input">
          <div className="text">Name:</div>
          <TextField
            className="create-saved-search-input__container"
            type="text"
            value={name}
            onChange={setName}
            isDisabled={false}
          />
        </div>
        {permissions[MANAGE_WORKFLOWS] && (
          <div className="workflow-text">You can add this alert to workflows.</div>
        )}
        <div className="save-alert-modal__footer">
          <Button
            onClick={() => closeModal('cancel')}
            className="cancelAlertButton btn"
            type={BUTTON_TYPES.SECONDARY}
          >
            Cancel
          </Button>
          <Button isDisabled={isSaving || !Boolean(name.trim())} onClick={handleSaveAlert}>
            {createBtnTitle}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
