import type { FirmProps } from './Firm.types';

import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';
import { DOCUMENT_CATEGORIES } from 'constants/DocumentConstants';
import { useFirmData } from './hooks';

export const Firm = ({ document }: FirmProps) => {
  const { reduxState } = useFirmData({ document });

  if (reduxState.categoryName === DOCUMENT_CATEGORIES.WHITEPAPER) {
    return (
      <>
        <FieldName>Firm</FieldName>
        <FieldValue>{document?.whitepaper?.metadata?.byline?.firm}</FieldValue>
      </>
    );
  }

  return null;
};
