import {
  api_getUserObligationsForDocumentId,
  api_createOrUpdateUserObligation,
  api_addObligationTaskAttachment,
  api_deleteObligationTaskAttachment,
  api_getObligationHighThreshold
} from './obligations.api';

export const SELECT_SENTENCES = 'SELECT_SENTENCES';
export const REQUEST_USER_OBLIGATIONS_FOR_DOC_ID = 'REQUEST_USER_OBLIGATIONS_FOR_DOC_ID';
export const RECEIVE_USER_OBLIGATIONS_FOR_DOC_ID = 'RECEIVE_USER_OBLIGATIONS_FOR_DOC_ID';
export const REQUEST_CREATE_OR_UPDATE_USER_OBLIGATION = 'REQUEST_CREATE_OR_UPDATE_USER_OBLIGATION';
export const RECEIVE_CREATE_OR_UPDATE_USER_OBLIGATION = 'RECEIVE_CREATE_OR_UPDATE_USER_OBLIGATION';
export const RECIEVE_OBLIGATION_HIGH_THRESHHOLD = 'RECIEVE_OBLIGATION_HIGH_THRESHHOLD';

function requestUserObligationsForDocId(docId) {
  return {
    type: REQUEST_USER_OBLIGATIONS_FOR_DOC_ID,
    docId
  };
}

function receiveUserObligationsForDocId(docId, userObligations) {
  return {
    type: RECEIVE_USER_OBLIGATIONS_FOR_DOC_ID,
    docId,
    userObligations
  };
}

function requestCreateOrUpdateUserObligation() {
  return {
    type: REQUEST_CREATE_OR_UPDATE_USER_OBLIGATION
  };
}

function receiveCreateOrUpdateUserObligation(userObligation) {
  return {
    type: RECEIVE_CREATE_OR_UPDATE_USER_OBLIGATION,
    userObligation
  };
}

function receiveObligationThresholdHigh(response) {
  return {
    type: RECIEVE_OBLIGATION_HIGH_THRESHHOLD,
    payload: response
  };
}

export function addObligationTaskAttachment(params) {
  const { document_task_attachments: files, task_id } = params;
  return function doFetch() {
    const formData = new window.FormData();
    files.forEach((file, i) => {
      formData.append('file' + (i + 1).toString(), file);
    });
    return api_addObligationTaskAttachment(task_id, formData);
  };
}

export function deleteObligationTaskAttachment(fileName) {
  return function doFetch() {
    return api_deleteObligationTaskAttachment(fileName);
  };
}

export function selectSentences(firstSentenceId, lastSentenceId, startIndex = 0, endIndex = 0) {
  return {
    type: SELECT_SENTENCES,
    firstSentenceId,
    lastSentenceId,
    startIndex,
    endIndex
  };
}

export function getUserObligationsForDocumentId(docId) {
  return function dofetch(dispatch) {
    dispatch(requestUserObligationsForDocId(docId));
    return api_getUserObligationsForDocumentId(docId).then(response => {
      dispatch(receiveUserObligationsForDocId(docId, response));
      return response;
    });
  };
}

export function createOrUpdateUserObligation(data) {
  return function doPost(dispatch) {
    dispatch(requestCreateOrUpdateUserObligation());
    return api_createOrUpdateUserObligation(data).then(userObligation => {
      dispatch(receiveCreateOrUpdateUserObligation(userObligation));
    });
  };
}

export function fetchObligationHighThreshold() {
  return function doFetch(dispatch) {
    return api_getObligationHighThreshold().then(response => {
      dispatch(receiveObligationThresholdHigh(response));
    });
  };
}
