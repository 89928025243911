import lodash from 'lodash';
import { safe_mixpanel_track } from 'utils/analytics';
import {
  ADD_COMPONENT_ACTION,
  MOVE_COMPONENT_ACTION,
  REMOVE_COMPONENT_ACTION
} from './Dashboard.constants';

/**
 *
 * Removes unnecessary props added by react-grid-layout from the layout. Required to check
 * if the layout has changed using deep equality in handleLayoutChange
 *
 */
export const sanitizeLayout = layout => {
  return Object.entries(layout).reduce((sanitizedLayout, [size, layoutItems]) => {
    return {
      ...sanitizedLayout,
      [size]: layoutItems.map(({ i, w, h, x, y }) => ({ i, w, h, x, y }))
    };
  }, {});
};

/**
 *
 * Applies to the layout custom dimensions passed from props.
 *
 */
export const applyDimensionsToLayout = ({ layout, componentDimensions }) => {
  return Object.fromEntries(
    Object.entries(layout).map(([size, widgets]) => {
      const widgetsWithDimensions = widgets.map(widget => {
        const componentName = widget.i;

        if (!componentDimensions[componentName] || !componentDimensions[componentName][size]) {
          return widget;
        }

        return {
          ...widget,
          ...componentDimensions[componentName][size]
        };
      });

      return [size, widgetsWithDimensions];
    })
  );
};

/**
 *
 * Converts layoutComponentMapping into options for DashboardOptionsDropdown and sorts them
 *
 */
export const sortAndConvertToOptions = ({ layoutComponentMapping }) => {
  return lodash.orderBy(
    Object.entries(layoutComponentMapping).map(([componentName, componentData]) => ({
      value: componentName,
      label: componentData.name
    })),
    option => option.label
  );
};

/**
 *
 * Tries to get saved user layout. If none was found, returns the default layout
 *
 */
export const getDashboardLayout = ({
  defaultLayout,
  defaultComponents,
  dashboardType,
  userDashboardLayouts
}) => {
  const foundUserLayout =
    lodash.find(userDashboardLayouts, { dashboard_type: dashboardType }) || {};
  const layout = sanitizeLayout(foundUserLayout.layout || defaultLayout);

  return {
    components: foundUserLayout.components || defaultComponents,
    layout: layout
  };
};

/**
 *
 * Performs an analytics action
 *
 */
export const doAnalytics = ({ dashboardType, action, widgetName }) => {
  switch (action) {
    case REMOVE_COMPONENT_ACTION:
      safe_mixpanel_track(`Dashboard - ${dashboardType} – Remove Widget`, {
        eventCategory: 'Dashboard',
        eventAction: 'Remove component',
        eventLabel: widgetName
      });
      break;

    case ADD_COMPONENT_ACTION:
      safe_mixpanel_track(`Dashboard - ${dashboardType} – Add Widget`, {
        eventCategory: 'Dashboard',
        eventAction: 'Add component',
        eventLabel: widgetName
      });
      break;

    case MOVE_COMPONENT_ACTION:
      safe_mixpanel_track(`Dashboard - ${dashboardType} – Move Widget`, {
        eventCategory: 'Dashboard',
        eventAction: 'Move component',
        eventLabel: widgetName
      });
      break;

    default:
      return;
  }
};

/**
 *
 * Filters component names by deselected ones
 *
 */
export const removeComponentNames = ({ components, deselectedComponentNames }) =>
  components.filter(componentName => {
    return !deselectedComponentNames.includes(componentName);
  });
