import { useState } from 'react';

export const usePasswordResetData = () => {
  const [passwordResetEmail, setPasswordResetEmail] = useState('');

  return {
    localState: {
      passwordResetEmail
    },
    localActions: {
      setPasswordResetEmail
    }
  };
};
