import type { AddBucketModalData } from './useAddBucketModalData.types';
import type { AddBucketModalProps } from '../AddBucketModal.types';

import * as errorUtils from 'utils/errors';
import * as bucketsConstants from 'shared/features/buckets/buckets.constants';
import * as actionsHelpers from 'shared/features/buckets/buckets.actions.helpers';
import * as actionsConstants from 'shared/features/buckets/buckets.actions.constants';

import { useMutation } from '@tanstack/react-query';
import { useBucketsReduxActions } from 'shared/features/buckets/hooks';

export const useAddBucketModalMutations = ({
  props,
  localState
}: {
  props: Pick<AddBucketModalProps, 'onModalClose' | 'onModalActionSuccess' | 'onModalActionStart'>;
  localState: AddBucketModalData['localState'];
}) => {
  const bucketReduxActions = useBucketsReduxActions();

  const addBucket = useMutation<void, Error, void>({
    onMutate: () => {
      props.onModalActionStart?.();
      props.onModalClose();
    },
    mutationFn: async () => {
      if (localState.bucketName === bucketsConstants.DEFAULT_BUCKET_NAME) {
        actionsHelpers.showValidationErrorNotification({
          message: actionsConstants.BUCKETS_ERRORS_MESSAGES.BUCKET_NAME_VALIDATION
        });
      } else {
        await bucketReduxActions.addBucket({ name: localState.bucketName });
      }
    },
    onSuccess: props.onModalActionSuccess,
    onError: errorUtils.logReactQueryError
  });

  return {
    addBucket
  };
};
