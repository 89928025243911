import type { GlobalState } from 'shared/reducers';
import type { ResourceDocumentCheckData } from './useResourceDocumentCheck.types';
import type { DocumentFromResponse } from 'shared/features/documents/documents.types';

import { useSelector } from 'react-redux';
import {
  getDocumentCategoryName,
  getUSStateReducer
} from 'shared/features/documents/documents.selectors';
import { DOCUMENT_CATEGORIES, RESOURCE_CODE_CATEGORIES } from 'constants/DocumentConstants';

export const useResourceDocumentCheck = (document: DocumentFromResponse): boolean => {
  const reduxState = useSelector<GlobalState, ResourceDocumentCheckData['reduxState']>(state => ({
    // TODO Uncomment when document selectors will be moved to TS
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    categoryName: getDocumentCategoryName(state, document.cai_category_id),
    USStateReducer: getUSStateReducer(state)
  }));

  return Boolean(
    RESOURCE_CODE_CATEGORIES.includes(reduxState.categoryName as DOCUMENT_CATEGORIES) &&
      reduxState.USStateReducer.codes
  );
};
