import { useMemo, useState } from 'react';
import { isPastDueTask } from 'utils/task-utils';
import { areAllPreDocumentTasksAssigneesValid } from 'shared/features/workflow/workflow.selectors';
import { useSelector } from 'react-redux';

export const useWorkflowPreTaskContainerData = ({ document }) => {
  const [workflow, setWorkflow] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [isCreatingTasks, setTasksCreationStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const localActions = useMemo(
    () => ({
      setTasks,
      setTasksCreationStatus,
      setWorkflow,
      setIsLoading
    }),
    []
  );

  const reduxState = useSelector(state => ({
    areAllPreDocumentTasksAssigneesValid: areAllPreDocumentTasksAssigneesValid({
      tasks: tasks,
      workflow: workflow
    })(state)
  }));

  const formattedData = useMemo(() => {
    const areTherePasDueTasks = tasks.map(isPastDueTask(document)).find(Boolean);

    return {
      areTherePasDueTasks,
      isSubmitButtonDisabled:
        areTherePasDueTasks || !reduxState.areAllPreDocumentTasksAssigneesValid || isCreatingTasks
    };
  }, [document, isCreatingTasks, reduxState.areAllPreDocumentTasksAssigneesValid, tasks]);

  return {
    localState: {
      workflow,
      tasks,
      isCreatingTasks,
      isLoading
    },
    localActions,
    reduxState,
    formattedData
  };
};
