import { detect } from 'detect-browser';
const browser = detect();

export function isIE10() {
  if (browser && browser.name) {
    return browser.name === 'ie' && browser.version.startsWith('10.');
  }
  return false;
}
export function isIE() {
  if (browser && browser.name) {
    return browser.name === 'ie';
  }
  return false;
}
export function browserName() {
  if (browser && browser.name) {
    return browser.name;
  }

  return '';
}
