import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import './_error-alert-message.scss';

export const ERROR_ALERT_MESSAGE_TYPES = {
  CORPORATE_LOGIN_NOT_ENABLED: 'corporateLoginNotEnabled',
  UNKNOWN_ERROR: 'unknownError',
  WEAK_PASSWORD: 'weakPassword',
  ATTEMPTS_EXCEEDED: 'attemptsExceeded',
  PASSWORDS_MISMATCH: 'passwordsMismatch',
  NO_DATA_ENTERED: 'noDataEntered',
  TERMS_OF_SERVICE: 'termsOfService',
  INVALID_VERIFICATION_CODE: 'invalidVerificationCode',
  INVALID_ORG_INVITATION_TOKEN: 'invalidOrgInvitationToken',
  APP_IS_NOT_APPROVED: 'notApprovedApp',
  COGNITO_NOT_PASSING: 'cognitoNotPassing'
};

const ErrorAlertSupportLink = () => (
  <a href="mailto:support@compliance.ai">
    <Button type={BUTTON_TYPES.LINK} isBold className="error-alert-message__link">
      support@compliance.ai
    </Button>
  </a>
);

export const ERROR_ALERT_MESSAGES = {
  [ERROR_ALERT_MESSAGE_TYPES.CORPORATE_LOGIN_NOT_ENABLED]: (
    <>
      Corporate Login is not enabled for that user. Email <ErrorAlertSupportLink /> for help.
    </>
  ),
  [ERROR_ALERT_MESSAGE_TYPES.UNKNOWN_ERROR]: (
    <>
      An unknown error occurred. Please try again later. Email <ErrorAlertSupportLink /> for help.
    </>
  ),
  [ERROR_ALERT_MESSAGE_TYPES.WEAK_PASSWORD]: (
    <>
      Your password must include upper and lower case letters, at least one number and at least one
      symbol.
    </>
  ),
  [ERROR_ALERT_MESSAGE_TYPES.ATTEMPTS_EXCEEDED]: (
    <>
      Attempt limit exceeded. Please wait some time or email <ErrorAlertSupportLink /> for help.
    </>
  ),
  [ERROR_ALERT_MESSAGE_TYPES.INVALID_VERIFICATION_CODE]: (
    <>
      Invalid or expired verification code. Email <ErrorAlertSupportLink /> for help.
    </>
  ),
  [ERROR_ALERT_MESSAGE_TYPES.PASSWORDS_MISMATCH]: 'Passwords don’t match. Please try again.',
  [ERROR_ALERT_MESSAGE_TYPES.NO_DATA_ENTERED]: 'Please enter email and password',
  [ERROR_ALERT_MESSAGE_TYPES.TERMS_OF_SERVICE]: 'You must agree to Compliance.ai Terms of Service',
  [ERROR_ALERT_MESSAGE_TYPES.INVALID_ORG_INVITATION_TOKEN]:
    'Invalid or expired organization invitation token',
  [ERROR_ALERT_MESSAGE_TYPES.APP_IS_NOT_APPROVED]: 'App is not approved.',
  [ERROR_ALERT_MESSAGE_TYPES.COGNITO_NOT_PASSING]:
    'There is an issue with your account, please contact your organization’s network administrator'
};

export const ErrorAlertMessage = ({ type }) => {
  return (
    ERROR_ALERT_MESSAGES[type] || ERROR_ALERT_MESSAGES[ERROR_ALERT_MESSAGE_TYPES.UNKNOWN_ERROR]
  );
};
