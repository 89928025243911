import type * as apiTypes from './filterGroups.api.types';

import * as constants from './filterGroups.constants';

export const MOCK_FILTER_GROUPS_RESPONSE: apiTypes.FetchFilterGroupsResponse = {
  filter_groups: [
    {
      id: 1,
      label: 'All 50 US States',
      options: [
        60,
        59,
        58,
        57,
        56,
        55,
        54,
        53,
        51,
        50,
        49,
        48,
        47,
        46,
        45,
        44,
        42,
        41,
        40,
        39,
        38,
        37,
        36,
        35,
        34,
        33,
        32,
        31,
        30,
        29,
        28,
        27,
        26,
        25,
        24,
        23,
        22,
        21,
        20,
        19,
        18,
        17,
        16,
        15,
        13,
        12,
        11,
        10,
        9,
        8,
        6,
        5,
        4,
        2,
        1,
        0
      ],
      type: constants.FILTER_GROUP_TYPES.JURISDICTION
    },
    {
      id: 2,
      label: 'All Canadian Provinces',
      options: [213, 212, 211, 210, 209, 208, 207, 206, 205, 204, 203, 202, 201, 200],
      type: constants.FILTER_GROUP_TYPES.JURISDICTION
    }
  ]
};
