import PropTypes from 'prop-types';

import * as workflowConstants from 'shared/features/workflow/workflow.constants';
import * as uiLib from '@compliance.ai/web-components';
import * as common from 'common';

import { useDocumentTaskAnnotations } from 'shared/features/workflow/hooks';

export const TaskDatefieldsAnnotation = ({ documentTask, value, isEditable, onChange }) => {
  const annotation = useDocumentTaskAnnotations(documentTask)[
    workflowConstants.WORKFLOW_ANNOTATION_TYPES.DATEFIELD
  ];

  if (!annotation.isAvailable) {
    return null;
  }

  const handleAnnotationDateChange = date => {
    if (uiLib.isValidDate(date)) {
      onChange([
        {
          task_id: documentTask.id,
          annotation_date: uiLib.formatDate(date, {
            format: uiLib.DATE_FORMATS.API_DATE_TIME,
            shouldKeepLocalTime: false
          })
        }
      ]);
    }
  };

  return (
    <common.RightPanelLabeledDatefield
      value={value}
      label={annotation.label}
      onChange={handleAnnotationDateChange}
      isEditable={isEditable}
      isEditableStateControlled
      withTextfield
    />
  );
};

TaskDatefieldsAnnotation.propTypes = {
  documentTask: PropTypes.shape({}).isRequired,
  value: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};
