import { useSelector } from 'react-redux';
import {
  getOverlayOpenState,
  getOverlayType
} from 'shared/features/rightPanel/rightPanel.selectors';
import { isMobileView } from 'shared/features/view/view.selectors';

export const useOverlayData = () => {
  const reduxState = useSelector(state => ({
    isOpen: getOverlayOpenState(state),
    type: getOverlayType(state),
    isMobileView: isMobileView(state)
  }));

  return {
    reduxState
  };
};
