import { api_fetchAllJurisdictions } from './jurisdictions.api';
import { receiveJurisdictions, requestAllJurisdictions } from './jurisdictions.reducer';
import { getUserAuthenticatedState } from '../auth/auth.selectors';

export const fetchAllJurisdictions = isInitialFetch => async (dispatch, getState) => {
  const isAuthenticated = getUserAuthenticatedState(getState());

  // If the user is not logged in don't refetch data
  if (!isAuthenticated && !isInitialFetch) {
    return Promise.resolve();
  }

  dispatch(requestAllJurisdictions());

  const response = await api_fetchAllJurisdictions();

  dispatch(receiveJurisdictions(response.all_jurisdictions));

  return response;
};
