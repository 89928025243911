import type { GlobalState } from 'shared/reducers';
import type { LanguagesSelectData } from './useLanguagesSelectData.types';
import type { LanguagesSelectProps } from '../LanguagesSelect.types';

import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { getLanguagesOptions } from 'shared/features/languages/languages.selectors';

export const useLanguagesSelectData = ({
  value
}: Pick<LanguagesSelectProps, 'value'>): LanguagesSelectData => {
  const reduxState = useSelector<GlobalState, LanguagesSelectData['reduxState']>(state => ({
    options: getLanguagesOptions(state as never)
  }));

  const formattedData = useMemo(
    () => ({
      value: reduxState.options.filter(o => {
        if (Array.isArray(value)) {
          return value?.includes(o.value);
        }

        return value === o.value;
      })
    }),
    [reduxState.options, value]
  );

  return {
    reduxState,
    formattedData
  };
};
