import type { FC } from 'react';
import type { ShareBucketModalProps } from './ShareBucketModal.types';

import * as uiLib from '@compliance.ai/web-components';

import { useShareBucketModalTabs } from './hooks';

import './_share-bucket-modal.scss';

export const ShareBucketModal: FC<ShareBucketModalProps> = props => {
  const tabs = useShareBucketModalTabs({ props });

  if (!props.bucket) {
    return null;
  }

  return (
    <uiLib.Modal
      title={`Share labels collection "${props.bucket.name}"`}
      isOpen
      onClose={props.onModalClose}
      dataTestId="share-bucket-modal"
      classNames={{
        container: 'share-bucket-modal__container',
        content: 'share-bucket-modal__content',
        title: 'share-bucket-modal__title'
      }}
    >
      <uiLib.Tabs
        tabs={tabs}
        tabsBarClassName="share-bucket-modal__tabs-bar"
        tabClassName="share-bucket-modal__tabs-tab"
        tabContentClassName="share-bucket-modal__tabs-content"
      />
    </uiLib.Modal>
  );
};
