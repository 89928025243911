import {
  REQUEST_NEWS_DOCS,
  RECEIVE_NEWS_DOCS,
  FETCH_WHITEPAPER_DOCS,
  FETCH_AGENCIES_ACTIVITIES_GLANCE_DOCS
} from './dashboard.actions';

import { formatAgenciesActivitiesGlanceDocs } from './dashboard.helpers';

export const DASH_INITIAL_STATE = {
  isLoadingNewsDocs: false
};

export const dashboard = (state = DASH_INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_NEWS_DOCS:
      return {
        ...state,
        isLoadingNewsDocs: true
      };

    case RECEIVE_NEWS_DOCS:
      return {
        ...state,
        isLoadingNewsDocs: false,
        dashNewsDocs: action.payload
      };
    case FETCH_WHITEPAPER_DOCS:
      return {
        ...state,
        dashWhitePaperDocs: action.payload
      };
    case FETCH_AGENCIES_ACTIVITIES_GLANCE_DOCS:
      return {
        ...state,
        [action.payload.widgetId]: formatAgenciesActivitiesGlanceDocs(action.payload.res)
      };
    default:
      return state;
  }
};
