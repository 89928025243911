import PropTypes from 'prop-types';
import { getPasswordSuggestionsAndWarningMessages } from 'utils/password-utils';

export const PasswordValidationMessages = ({ password }) => {
  const passwordMessages = getPasswordSuggestionsAndWarningMessages(password);

  if (!password) {
    return null;
  }

  return (
    <>
      {passwordMessages.suggestions.join(' ')}
      {Boolean(passwordMessages.suggestions.length) && ' '}
      {passwordMessages.warning}
    </>
  );
};

PasswordValidationMessages.propTypes = {
  password: PropTypes.string.isRequired
};
