import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FilterLabeledElement } from 'common';
import {
  Select,
  SELECT_TYPES,
  TEXTFIELD_STYLE_TYPES,
  DatePicker
} from '@compliance.ai/web-components';
import { LocalDateFormat } from 'utils/keyDates';
import { REATIVE_DATE_OPERATOR_LABELS } from 'constants/RelativeDates';
import {
  useCustomDatePickerData,
  useCustomDatePickerLifecycle,
  useCustomDatePickerDateHandlers
} from './hooks';
import {
  CUSTOM_DATE_PICKER_OPERATION_OPTIONS,
  CUSTOM_DATE_PICKER_OPERATION_OPTIONS_WITH_NEXT,
  CUSTOM_DATE_PICKER_PAST_DURATION_OPTIONS
} from './CustomDatePicker.constants';
import './_custom-date-picker.scss';

export const TEST_IDS = {
  CONTAINER: 'custom-date-picker-container',
  OPERATOR: 'custom-date-picker-operator',
  DATE: 'custom-date-picker-date',
  START_DATE: 'custom-date-picker-start-date',
  END_DATE: 'custom-date-picker-end-date',
  PAST_RELATVE_AMOUNT: 'custom-date-picker-past-relative-amount',
  PAST_RELATVE_DURATION: 'custom-date-picker-past-relative-duration'
};

const CustomDatePicker = ({
  date,
  startDate,
  endDate,
  onError,
  onChange,
  errors,
  areDatesRequired,
  nextDatesAvailable,
  cleared,
  dateKey,
  startDateKey,
  endDateKey,
  dateOrderErrorKey,
  label,
  customLabelElements,
  subject
}) => {
  const { localState, localActions } = useCustomDatePickerData({ date, startDate, endDate });

  const {
    handleSelectedOperatorChange,
    handleSelectedDateChange,
    handleSelectedStartDateChange,
    handleSelectedEndDateChange
  } = useCustomDatePickerDateHandlers({
    props: {
      subject,
      startDate,
      endDate,
      onError,
      onChange,
      dateKey,
      startDateKey,
      endDateKey,
      dateOrderErrorKey,
      areDatesRequired
    },
    localActions
  });

  useCustomDatePickerLifecycle({
    props: {
      date,
      startDate,
      endDate,
      cleared
    },
    localState,
    dateHandlers: {
      handleSelectedOperatorChange,
      handleSelectedDateChange,
      handleSelectedStartDateChange,
      handleSelectedEndDateChange
    }
  });

  const renderOperator = () => {
    const operatorOptions = nextDatesAvailable
      ? CUSTOM_DATE_PICKER_OPERATION_OPTIONS_WITH_NEXT
      : CUSTOM_DATE_PICKER_OPERATION_OPTIONS;

    return (
      <div className="custom-date-picker__picker-wrapper" data-testid={TEST_IDS.OPERATOR}>
        <Select
          type={SELECT_TYPES.ALTERNATIVE}
          className="custom-date-picker__selector operator_selector"
          options={operatorOptions}
          onChange={handleSelectedOperatorChange}
          value={localState.operator}
          isSearchable={false}
          isClearable={false}
        />
      </div>
    );
  };

  const renderOnDatePicker = () => {
    if (localState.operator === REATIVE_DATE_OPERATOR_LABELS.ON) {
      return (
        <div className="custom-date-picker__picker-wrapper" data-testid={TEST_IDS.DATE}>
          <DatePicker
            styleType={TEXTFIELD_STYLE_TYPES.ALTER}
            value={date}
            format={LocalDateFormat}
            onChange={handleSelectedDateChange}
            className={classnames('custom-date-picker__picker', {
              'custom-date-picker__picker--is-invalid': errors[dateKey]
            })}
            placeholder=""
            shouldShowCalendarIcon={false}
          />
        </div>
      );
    }

    return null;
  };

  const renderStartDatePicker = () => {
    if (
      localState.operator === REATIVE_DATE_OPERATOR_LABELS.ON_OR_AFTER ||
      localState.operator === REATIVE_DATE_OPERATOR_LABELS.BETWEEN
    ) {
      return (
        <div className="custom-date-picker__picker-wrapper" data-testid={TEST_IDS.START_DATE}>
          <DatePicker
            styleType={TEXTFIELD_STYLE_TYPES.ALTER}
            value={startDate}
            format={LocalDateFormat}
            onChange={handleSelectedStartDateChange}
            className={classnames('custom-date-picker__picker', {
              'custom-date-picker__picker--is-invalid': errors[startDateKey]
            })}
            placeholder=""
            shouldShowCalendarIcon={false}
          />
        </div>
      );
    }

    return null;
  };

  const renderEndDatePicker = () => {
    if (
      localState.operator === REATIVE_DATE_OPERATOR_LABELS.ON_OR_BEFORE ||
      localState.operator === REATIVE_DATE_OPERATOR_LABELS.BETWEEN
    ) {
      return (
        <div className="custom-date-picker__picker-wrapper" data-testid={TEST_IDS.END_DATE}>
          <DatePicker
            styleType={TEXTFIELD_STYLE_TYPES.ALTER}
            value={endDate}
            format={LocalDateFormat}
            onChange={handleSelectedEndDateChange}
            className={classnames('custom-date-picker__picker', {
              'custom-date-picker__picker--is-invalid': errors[endDateKey]
            })}
            placeholder=""
            shouldShowCalendarIcon={false}
          />
        </div>
      );
    }

    return null;
  };

  const renderPastNextRelativeDatesPicker = () => {
    if (
      localState.operator === REATIVE_DATE_OPERATOR_LABELS.PAST ||
      localState.operator === REATIVE_DATE_OPERATOR_LABELS.NEXT
    ) {
      return (
        <>
          <div
            className="custom-date-picker__picker-wrapper"
            data-testid={TEST_IDS.PAST_RELATVE_AMOUNT}
          >
            <input
              type="number"
              placeholder="Enter number"
              className="custom-date-picker__picker amount-selector"
              onChange={e => localActions.setAmount(e.target.value)}
              value={localState.amount}
              min={1}
            />
          </div>
          <div
            className="custom-date-picker__picker-wrapper"
            data-testid={TEST_IDS.PAST_RELATVE_DURATION}
          >
            <Select
              type={SELECT_TYPES.ALTERNATIVE}
              className="custom-date-picker__selector duration_selector"
              options={CUSTOM_DATE_PICKER_PAST_DURATION_OPTIONS}
              onChange={value => localActions.setDuration(value.value)}
              value={localState.duration}
              isSearchable={false}
              isClearable={false}
            />
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <FilterLabeledElement
      label={label}
      customLabelElements={customLabelElements}
      isBold
      labelClassName="custom-date-picker__container-label"
    >
      <div className="custom-date-picker__container" data-testid={TEST_IDS.CONTAINER}>
        {renderOperator()}
        {renderOnDatePicker()}
        {renderStartDatePicker()}
        {renderEndDatePicker()}
        {renderPastNextRelativeDatesPicker()}
      </div>
    </FilterLabeledElement>
  );
};

export default CustomDatePicker;

CustomDatePicker.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  areDatesRequired: PropTypes.bool,
  nextDatesAvailable: PropTypes.bool,
  cleared: PropTypes.bool,
  dateKey: PropTypes.string.isRequired,
  startDateKey: PropTypes.string.isRequired,
  endDateKey: PropTypes.string.isRequired,
  dateOrderErrorKey: PropTypes.string.isRequired,
  label: PropTypes.string,
  customLabelElements: PropTypes.element,
  subject: PropTypes.string
};

CustomDatePicker.defaultProps = {
  date: null,
  startDate: null,
  endDate: null,
  areDatesRequired: true,
  nextDatesAvailable: false,
  cleared: false,
  label: 'Publication Date:',
  customLabelElements: null,
  subject: ''
};
