import type { WorkflowPanelTaskHandlers } from './useWorkflowPanelTaskHandlers.types';
import type { WorkflowPanelTaskProps } from '../WorkflowPanelTask.types';

const OBLIGATIONS_TAB_KEY = 'obligations';

export const useWorkflowPanelTaskHandlers = ({
  props
}: {
  props: Pick<WorkflowPanelTaskProps, 'onTabChange'>;
}): WorkflowPanelTaskHandlers => {
  const handleObligationsLinkClick: WorkflowPanelTaskHandlers['handleObligationsLinkClick'] = () =>
    props.onTabChange?.(OBLIGATIONS_TAB_KEY);

  return {
    handleObligationsLinkClick
  };
};
