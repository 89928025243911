import type { FC } from 'react';
import type { LabelTypeSelectProps } from './LabelTypeSelect.types';

import * as uiLib from '@compliance.ai/web-components';

import { useLabelTypeSelectData, useLabelTypeSelectHandlers } from './hooks';

export const LabelTypeSelect: FC<LabelTypeSelectProps> = props => {
  const { localState, localActions } = useLabelTypeSelectData({ props });

  const handlers = useLabelTypeSelectHandlers({ props, localActions });

  return (
    <uiLib.Select
      {...props}
      type={props.type ?? uiLib.SELECT_TYPES.DEFAULT}
      value={localState.selectedLabelTypeOption}
      isLoading={localState.isLoading}
      loadOptions={handlers.handleLabelTypesFetch}
      onChange={handlers.handleOptionChange}
      shouldCacheFetchedOptions
      shouldFetchDefaultOptions
      isAsync
    />
  );
};
