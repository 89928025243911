import { useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'utils/query-string';
import * as routes from 'constants/Routes';
import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import {
  DOCUMENT_DETAILS_TAB_KEY,
  RIGHT_PANEL_TYPES
} from 'shared/features/rightPanel/rightPanel.constants';
import './_documentDropdown.scss';

export const TEST_ID = {
  CONTAINER: 'document-dropdown-container',
  SEE_ALL_LINK: 'document-dropdown-see-all-link',
  ARROW_ZONE: 'document-dropdown-arrow-zone',
  ARROW_DOWN: 'document-dropdown-arrow-down',
  ARROW_RIGHT: 'document-dropdown-arrow-right'
};

const DocumentDropdown = ({ title, documents, originalDocID, children }) => {
  const navigate = useNavigate();

  const [active, setActive] = useState(false);

  const rightPanelReduxActions = useRightPanelReduxActions();

  const seeAllClick = () => {
    navigate(
      `${routes.content}?${queryString.stringify({
        related_doc_id: originalDocID,
        search_sort: 'publication_date',
        order: 'desc'
      })}`
    );

    rightPanelReduxActions.openRightPanel({
      type: RIGHT_PANEL_TYPES.DOCUMENT_DETAILS,
      props: {
        documentId: originalDocID,
        activeTabKey: DOCUMENT_DETAILS_TAB_KEY.RELATED_DOCUMENTS
      }
    });
  };

  const renderSeeAll = () => {
    return documents.length >= 1 ? (
      <div
        data-testid={TEST_ID.SEE_ALL_LINK}
        className="document-dropdown-see-all"
        onClick={() => seeAllClick()}
      >
        {'(See all ' + documents.length + ')'}
      </div>
    ) : (
      <div className="document-dropdown-see-all">(0)</div>
    );
  };

  return (
    <div data-testid={TEST_ID.CONTAINER} className="document-dropdown-container">
      <div className="document-dropdown-header">
        <div
          data-testid={TEST_ID.ARROW_ZONE}
          className="document-dropdown-arrow-zone"
          onClick={() => setActive(!active)}
        >
          {active ? (
            <KeyboardArrowDown data-testid={TEST_ID.ARROW_DOWN} />
          ) : (
            <KeyboardArrowRight data-testid={TEST_ID.ARROW_RIGHT} />
          )}
        </div>
        <h3 className="document-dropdown-title">{title}</h3>
        {renderSeeAll()}
      </div>
      <div className="document-dropdown-children">{active && children}</div>
    </div>
  );
};

DocumentDropdown.propTypes = {
  title: PropTypes.string.isRequired,
  documents: PropTypes.arrayOf(PropTypes.number)
};

DocumentDropdown.defaultProps = {
  documents: []
};

export default DocumentDropdown;
