export const ADD_USER_TO_ORGANIZATION = 'add_user_to_organization';
export const MANAGE_ORG_PROFILE = 'manage_org_profile';
export const ADD_WORKFLOW_MOLDS = 'add_workflow_molds';
export const MANAGE_ORGANIZATION_FOLLOWED_DEFAULTS = 'manage_organization_followed_defaults';
export const MANAGE_LABELS = 'manage_labels';
export const MANAGE_BUCKETS = 'manage_buckets';
export const MANAGE_TEAMS = 'manage_teams';
export const MANAGE_TEAM_FOLLOWED_DEFAULTS = 'manage_team_followed_defaults';
export const MANAGE_WORKFLOWS = 'manage_workflows';
export const MANAGE_WORKFLOW_ALERTS = 'manage_workflow_alerts';
export const ACCESS_TASKS = 'access_tasks';
export const CREATE_FOLDERS = 'create_folders';
export const EDIT_ALERTS = 'edit_alerts';
export const MANAGE_ORG = 'manage_org';
export const VIEW_ORG_MEMBERS = 'view_org_members';
export const MANAGE_ORG_MEMBERS = 'manage_org_members';
export const MANAGE_TASKS = 'manage_tasks';
export const ACCESS_ADMIN = 'access_admin';
export const ACCESS_GLASS_DOOR = 'access_glass_door';
export const EDIT_ANNOTATIONS = 'edit_annotations';
export const EDIT_OBLIGATIONS = 'edit_obligations';
export const CREATE_LABELS = 'create_labels';
export const CREATE_COMMENTS = 'create_comments';
export const UNLIMITED_SEARCHES = 'unlimited_searches';
export const EDIT_METADATA = 'edit_metadata';
export const MANAGE_ORG_RELATED_DOCS = 'manage_org_related_docs';
export const EDIT_DOC_TASK_DUE_DATE_BY_ORG_ADMINS =
  'manage_highly_restricted_document_task_due_dates';
export const EDIT_DOC_TASK_DUE_DATE_BY_ORG_AND_TEAM_ADMINS =
  'manage_medium_restricted_document_task_due_dates';
export const EDIT_DOC_TASK_DUE_DATE_BY_ORG_AND_TEAM_AND_WORKFLOW_ADMINS =
  'manage_low_restricted_document_task_due_dates';
