import { RIGHT_PANEL_TYPES } from 'shared/features/rightPanel/rightPanel.constants';
import { triggerPremiumContentMixpanel } from 'utils/documents/documentsAccess';
import { getDocumentUrl } from 'utils/documents/documentProperties';
import { safe_analytics } from 'utils/analytics';

export const useDocumentLinkHandlers = ({
  props,
  reduxState,
  formattedData,
  rightPanelReduxActions
}) => {
  const handleDocumentOpen = () => {
    rightPanelReduxActions.openRightPanel({
      type: RIGHT_PANEL_TYPES.DOCUMENT_DETAILS,
      props: {
        documentId: formattedData.documentId,
        shouldOpenRightPanelWithOverlay: true,
        shouldCloseRightPanelOnOverlayClose: true
      }
    });
  };

  const handleSourceOpen = () => {
    const url = getDocumentUrl(props.document);

    if (formattedData.isPremiumContent && reduxState.currentUserEmail) {
      triggerPremiumContentMixpanel(reduxState.currentUserEmail, props.document);
    }

    safe_analytics(
      'Task Doc Details – View Agency Website',
      'Task Doc Details',
      'View Agency Website',
      props.document.title
    );

    window.open(url, '_blank');
  };

  return {
    handleDocumentOpen,
    handleSourceOpen
  };
};
