import type { AdvancedSearchBuilderProps } from '../AdvancedSearchBuilder.types';
import type { AdvancedSearchBuilderData } from './useAdvancedSearchBuilderData.types';
import type { AdvancedSearchBuilderHandlers } from './useAdvancedSearchBuilderHandlers.types';

import * as helpers from '../AdvancedSearchBuilder.helpers';
import * as rightPanelConstants from 'shared/features/rightPanel/rightPanel.constants';
import * as defaultViewConstants from 'common/Filter/DefaultFiltersViewSelect/DefaultFiltersViewSelect.constants';
import * as advancedSearchHelpers from 'utils/hooks/useAdvancedSearchQuery/useAdvancedSearchQuery.helpers';
import * as advancedSearchutils from 'utils/advancedSearch';

import queryString from 'utils/query-string';

import loadConfig, {
  ADVANCED_SEARCH_BUILDER_KEYS_ACT,
  ADVANCED_SEARCH_BUILDER_KEYS_BANK
} from '../AdvancedSearchBuilder.config';
import { Builder, Config, ImmutableTree, Utils as QbUtils } from 'react-awesome-query-builder';
import { useSKUs } from 'utils/hooks';
import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';
import { useAdvancedSearchBuilderReduxActions } from './useAdvancedSearchBuilderReduxActions';
import { useCallback } from 'react';

export const useAdvancedSearchBuilderHandlers = ({
  props: {
    conceptValues,
    currentAlert,
    doSearch,
    getUrlParams,
    handleConceptSelect,
    jsonLogicQuery,
    setSearchValue
  },
  localActions,
  reduxState,
  reduxActions,
  localState,
  formattedData
}: {
  props: AdvancedSearchBuilderProps;
  localActions: AdvancedSearchBuilderData['localActions'];
  reduxState: AdvancedSearchBuilderData['reduxState'];
  reduxActions: ReturnType<typeof useAdvancedSearchBuilderReduxActions>;
  localState: AdvancedSearchBuilderData['localState'];
  formattedData: AdvancedSearchBuilderData['formattedData'];
}): AdvancedSearchBuilderHandlers => {
  const rightPanelReduxActions = useRightPanelReduxActions();

  const { userHasSKU } = useSKUs();

  const handleBuilderChange: AdvancedSearchBuilderHandlers['handleBuilderChange'] = (
    tree,
    config
  ) => {
    localActions.setTree(tree);
    localActions.setConfig(config);
  };

  const handleRefreshBuilder: AdvancedSearchBuilderHandlers['handleRefreshBuilder'] = useCallback(
    async jsonQuery => {
      const dataToLoadFromAPI: Record<string, string> = helpers.getAdvancedSearchDataToLoadFromAPI(
        jsonQuery
      );

      const [{ banks }, { acts }] = await Promise.all([
        dataToLoadFromAPI[ADVANCED_SEARCH_BUILDER_KEYS_BANK]
          ? reduxActions.fetchBanks(dataToLoadFromAPI[ADVANCED_SEARCH_BUILDER_KEYS_BANK])
          : Promise.resolve({ banks: [] }),
        dataToLoadFromAPI[ADVANCED_SEARCH_BUILDER_KEYS_ACT]
          ? reduxActions.fetchActs(dataToLoadFromAPI[ADVANCED_SEARCH_BUILDER_KEYS_ACT])
          : Promise.resolve({ acts: [] })
      ]);

      const config = loadConfig(
        helpers.formatConfigParams({
          ...reduxState,
          userHasSKU,
          banks,
          acts
        })
      ) as unknown;

      const uncheckedTree = QbUtils.loadFromJsonLogic(jsonQuery, config as Config);
      const tree = QbUtils.checkTree(uncheckedTree, config as Config);

      localActions.setTree(tree);
      localActions.setConfig(config as Config);
      localActions.setIsLoading(false);
    },
    [localActions, reduxActions, reduxState, userHasSKU]
  );

  const handleGetOrgDefaults: AdvancedSearchBuilderHandlers['handleGetOrgDefaults'] = async () => {
    if (!formattedData.doOrgDefaultsExist) {
      localActions.setIsLoading(true);

      const defaultsData = await reduxActions.fetchDefaults({
        defaultsOwner: defaultViewConstants.DEFAULT_FILTERS_VIEW_OPTIONS.ORG_DEFAULTS,
        orgId: reduxState.orgId,
        teamId: ''
      });

      return helpers.prepareDefaultFilters({ defaultsData, reduxState });
    }
    return helpers.prepareDefaultFilters({
      defaultsData:
        reduxState.defaults[defaultViewConstants.DEFAULT_FILTERS_VIEW_OPTIONS.ORG_DEFAULTS],
      reduxState
    });
  };

  const handleGetTeamDefaults: AdvancedSearchBuilderHandlers['handleGetTeamDefaults'] = async teamId => {
    const existingTeamDefaults = reduxState.defaults.teamDefaultsMap[teamId];

    if (existingTeamDefaults) {
      return helpers.prepareDefaultFilters({
        defaultsData: existingTeamDefaults,
        reduxState
      });
    }
    localActions.setIsLoading(true);

    const defaultsData = await reduxActions.fetchDefaults({
      defaultsOwner: defaultViewConstants.DEFAULT_FILTERS_VIEW_OPTIONS.TEAM_DEFAULTS,
      teamId,
      orgId: ''
    });

    return helpers.prepareDefaultFilters({ defaultsData, reduxState });
  };

  const handleApplySelection: AdvancedSearchBuilderHandlers['handleApplySelection'] = async () => {
    if (!localState.dataReady) {
      return;
    }
    localActions.setIsLoading(true);

    let advancedSearchQuery = '';

    if (localState.isConceptsSelection) {
      advancedSearchQuery = helpers.formatConceptsToQuery(
        conceptValues,
        reduxState.concepts.concepts
      );
      handleConceptSelect([]);
    } else {
      let filterParams = {};
      switch (localState.defaultView) {
        case null: {
          filterParams = {};
          break;
        }
        case defaultViewConstants.DEFAULT_FILTERS_VIEW_OPTIONS.MY_DEFAULTS: {
          filterParams = helpers.prepareDefaultFilters({
            reduxState,
            defaultsData:
              reduxState.defaults[defaultViewConstants.DEFAULT_FILTERS_VIEW_OPTIONS.MY_DEFAULTS]
          });
          break;
        }

        case defaultViewConstants.DEFAULT_FILTERS_VIEW_OPTIONS.ORG_DEFAULTS: {
          const orgDefaultParams = await handleGetOrgDefaults();
          filterParams = orgDefaultParams;

          break;
        }

        default: {
          const teamDefaultParams = await handleGetTeamDefaults(localState.defaultView as string);
          filterParams = teamDefaultParams;
          break;
        }
      }
      advancedSearchQuery = advancedSearchHelpers.convertBasicFiltersToAdvancedSearch(filterParams);
      localActions.setDefaultView(null);
    }
    const query =
      QbUtils.queryString(localState.tree as ImmutableTree, localState.config as Config) || '';
    const updatedQuery = helpers.formatQueryWithQueryFromSelection({
      query,
      queryFromSelection: advancedSearchQuery
    });

    handleRefreshBuilder(
      advancedSearchutils.parseQueryToJsonLogic(updatedQuery, {
        categories: reduxState.docTypes.cai_categories
      })
    );
  };

  const handleRenderBuilder: AdvancedSearchBuilderHandlers['handleRenderBuilder'] = builderProps => (
    <div className="query-builder-container">
      <div className="query-builder">
        <Builder {...builderProps} />
      </div>
    </div>
  );

  const handleClearBuilder: AdvancedSearchBuilderHandlers['handleClearBuilder'] = () => {
    const config = loadConfig(helpers.formatConfigParams({ ...reduxState, userHasSKU })) as unknown;
    localActions.setTree(
      QbUtils.checkTree(QbUtils.loadFromJsonLogic({ and: [] }, config as Config), config as Config)
    );
    handleConceptSelect([]);
    localActions.setDefaultView(null);
    reduxActions.removeViewAlert();
    reduxActions.removeEditAlert();
  };

  const handleSearch: AdvancedSearchBuilderHandlers['handleSearch'] = () => {
    doSearch();
    reduxActions.removeViewAlert();
    reduxActions.removeEditAlert();
    reduxActions.closeFilter();
    reduxActions.closeAdvancedSearchFilter();
    reduxActions.closeOverlay();

    rightPanelReduxActions.openRightPanel({
      type: rightPanelConstants.RIGHT_PANEL_TYPES.SEARCH_RESULTS
    });
  };

  const handlePreviewAlert: AdvancedSearchBuilderHandlers['handlePreviewAlert'] = () => {
    doSearch();
    reduxActions.showPreviewAlert();
    reduxActions.closeFilter();
    reduxActions.closeAdvancedSearchFilter();
  };

  const handleUpdateAlert: AdvancedSearchBuilderHandlers['handleUpdateAlert'] = async () => {
    const { id: alertId, name: alertName } = currentAlert || {};
    const searchArgs = queryString.parse(getUrlParams());

    await reduxActions.updateAlert(alertId, {
      search_args: searchArgs,
      name: alertName
    });

    doSearch();
    reduxActions.removeEditAlert();
    reduxActions.hidePreviewAlert();
    reduxActions.closeFilter();
    reduxActions.closeAdvancedSearchFilter();
    reduxActions.showSaveAlertSuccessModal(alertName);
  };

  const handlePlainTextModalToggle: AdvancedSearchBuilderHandlers['handlePlainTextModalToggle'] = isOpen => () => {
    localActions.setIsPlainTextModalOpen(isOpen);
  };

  const handleDefaultViewChange: AdvancedSearchBuilderHandlers['handleDefaultViewChange'] = view => {
    localActions.setDefaultView(view);
  };

  const handleSelectToggle: AdvancedSearchBuilderHandlers['handleSelectToggle'] = () => {
    handleConceptSelect([]);
    localActions.setDefaultView(null);
    localActions.setIsConceptsSelection(prevValue => !prevValue);
  };

  const handleDidMount: AdvancedSearchBuilderHandlers['handleDidMount'] = useCallback(async () => {
    await handleRefreshBuilder(jsonLogicQuery);
    localActions.setDataReady(true);
  }, [handleRefreshBuilder, jsonLogicQuery, localActions]);

  const handleUpdateSearchValue: AdvancedSearchBuilderHandlers['handleUpdateSearchValue'] = useCallback(() => {
    const query =
      QbUtils.queryString(localState.tree as ImmutableTree, localState.config as Config) || '';

    setSearchValue(query);
  }, [localState.config, localState.tree, setSearchValue]);

  return {
    handleBuilderChange,
    handleRefreshBuilder,
    handleRenderBuilder,
    handleClearBuilder,
    handleSearch,
    handlePreviewAlert,
    handleUpdateAlert,
    handlePlainTextModalToggle,
    handleSelectToggle,
    handleDefaultViewChange,
    handleApplySelection,
    handleGetOrgDefaults,
    handleGetTeamDefaults,
    handleDidMount,
    handleUpdateSearchValue
  };
};
