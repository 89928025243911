import type { DocumentsResponse, DocumentFromResponse } from '../documents.types';
import type * as apiTypes from '../documents.apiV2.types';

import * as documentsApi from '../documents.api';
import * as api from '../documents.apiV2';
import * as errorUtils from 'utils/errors';
import * as primaryFilterConstants from 'constants/PrimaryFilter';

import { useCallback, useMemo } from 'react';

export const useDocumentsApi = () => {
  const fetchDocuments = useCallback(async (params: Record<string, unknown>): Promise<
    DocumentsResponse
  > => {
    try {
      return ((await documentsApi.api_getDocuments_V2(params)) as unknown) as DocumentsResponse;
    } catch (e) {
      errorUtils.logError(e as Error);

      return {
        aggregations: null,
        corrected_search: {},
        count: 0,
        documents: [],
        offsets: null
      };
    }
  }, []);

  const fetchDocumentIds = useCallback(
    async (
      params: Record<string, unknown>,
      shouldFetchExtraFields?: boolean
    ): Promise<DocumentFromResponse['id'][]> => {
      try {
        const response = ((await documentsApi.api_getDocuments_V2({
          ...params,
          [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.EXCLUSIVE_FIELDS]: shouldFetchExtraFields
            ? ['id', 'title', 'rule.comments_close_on', 'publication_date', 'rule.effective_on']
            : ['id']
        })) as unknown) as DocumentsResponse;

        return response.documents.map(document => document.id);
      } catch (e) {
        errorUtils.logError(e as Error);

        return [];
      }
    },
    []
  );

  const bookmarkDocuments = useCallback(
    async (documentIds: DocumentFromResponse['id'][], isBookmarked: boolean): Promise<void> => {
      try {
        await documentsApi.api_updateDocuments(documentIds, {
          bookmarked: isBookmarked
        });
      } catch (e) {
        errorUtils.logError(e as Error);
      }
    },
    []
  );

  const updateDocumentMetadata = useCallback(
    async (
      documentId: DocumentFromResponse['id'],
      params: Record<string, unknown>
    ): Promise<apiTypes.FetchDocumentMetadataUpdateStatusResponse> => {
      try {
        const { id } = await api.updateDocumentMetadata(documentId, params);
        const response = await api.fetchDocumentMetadataUpdateStatus(id);
        return response;
      } catch (e) {
        errorUtils.logError(e as Error);
        throw e;
      }
    },
    []
  );

  return useMemo(
    () => ({
      fetchDocuments,
      fetchDocumentIds,
      bookmarkDocuments,
      updateDocumentMetadata
    }),
    [fetchDocuments, fetchDocumentIds, bookmarkDocuments, updateDocumentMetadata]
  );
};
