import type { ComponentProps } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';

export const useSearchResultsRightPanelConfig = (): Required<
  ComponentProps<typeof uiLib.AppNavigation>
>['rightPanelConfig'] => {
  return {
    tabs: [],
    contentTitle: 'Search Results',
    content: <elements.SearchResults />
  };
};
