export const GROUP_SELECT_FILTER_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
};

export const GROUP_SELECT_CONTROL_CLASSNAME = {
  [GROUP_SELECT_FILTER_TYPES.PRIMARY]: 'group-select-control',
  [GROUP_SELECT_FILTER_TYPES.SECONDARY]: 'group-select-control group-select-control--secondary'
};

export const GROUP_SELECT_PLACEHOLDER_CLASSNAME = {
  [GROUP_SELECT_FILTER_TYPES.PRIMARY]: 'group-select-selected-placeholder',
  [GROUP_SELECT_FILTER_TYPES.SECONDARY]:
    'group-select-selected-placeholder group-select-selected-placeholder--secondary'
};

export const GROUP_SELECT_INPUT_CLASSNAME = {
  [GROUP_SELECT_FILTER_TYPES.PRIMARY]: 'group-select-input',
  [GROUP_SELECT_FILTER_TYPES.SECONDARY]: 'group-select-input--secondary'
};
