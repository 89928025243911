import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { addError as reduxAddError, clearErrors as reduxClearErrors } from '../actions';

export const useErrorReduxActions = () => {
  const dispatch = useDispatch();

  const addError = useCallback(
    (error: Error, component: string) => {
      return dispatch(reduxAddError(error, component));
    },
    [dispatch]
  );

  const clearErrors = useCallback(
    (component: string) => {
      return dispatch(reduxClearErrors(component));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      addError,
      clearErrors
    }),
    [addError, clearErrors]
  );
};
