import type { SelectProps } from '../Select.types';
import type { SelectHandlers } from './useSelectHandlers.types';
import type { SelectOption } from '@compliance.ai/web-components';

export const useSelectHandlers = ({
  props
}: {
  props: Pick<SelectProps, 'isMulti' | 'setValue'>;
}) => {
  const handleChange: SelectHandlers['handleChange'] = value => {
    if (props.isMulti) {
      return props.setValue(
        Array.isArray(value) ? value.map((option: SelectOption) => option.value) : []
      );
    }

    props.setValue((value as SelectOption)?.value);
  };

  return {
    handleChange
  };
};
