import type { LabelsSelectHandlers } from './useLabelsSelectHandlers.types';
import type { LabelsSelectProps } from '../LabelsSelect.types';

import * as labelsConstants from 'shared/features/labels/labels.constants';
import * as labelsHelpers from 'shared/features/labels/labels.helpers';
import * as errorUtils from 'utils/errors';

import { useOrganizationLabelsReduxActions } from 'shared/features/labels/hooks';

export const useLabelsSelectHandlers = ({
  props
}: {
  props: Pick<LabelsSelectProps, 'isEditable'>;
}): LabelsSelectHandlers => {
  const reduxActions = useOrganizationLabelsReduxActions();

  const handleLabelsFetch: LabelsSelectHandlers['handleLabelsFetch'] = async inputString => {
    try {
      const { labels } = await reduxActions.fetchAllLabels({
        name: inputString,
        count_per_page: labelsConstants.MAX_LABELS_COUNT,
        is_editable: props.isEditable ? true : false
      });

      return labelsHelpers.getLabelsGroupedOptions(labels);
    } catch (e) {
      errorUtils.logError(e as Error);
      return [];
    }
  };

  return {
    handleLabelsFetch
  };
};
