import type { PrintData } from './usePrintData.types';
import type { GlobalState } from 'shared/reducers';

import * as authSelectors from 'shared/features/auth/auth.selectors';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const usePrintData = (): PrintData => {
  const [isPrinting, setIsPrinting] = useState(false);

  const localState = {
    isPrinting
  };

  const localActions = useMemo(
    () => ({
      setIsPrinting
    }),
    []
  );

  const reduxState = useSelector<GlobalState, PrintData['reduxState']>(state => ({
    isAuthenticated: authSelectors.getUserAuthenticatedState(state)
  }));

  return {
    localState,
    localActions,
    reduxState
  };
};
