import type * as uiLib from '@compliance.ai/web-components';

export enum SHARE_BUCKET_MODAL_TAB_KEYS {
  SHARE_BUCKET_FORM = 'ShareBucketForm',
  MANAGE_BUCKET_ACCESS = 'ManageBucketAccess'
}

export enum SHARE_BUCKET_ENTITY_TYPE {
  TEAM = 'team',
  USER = 'user'
}

export enum SHARE_BUCKET_ACCESS_LEVEL {
  VIEW = 'view',
  EDIT = 'edit'
}

export const SHARE_BUCKET_VIEW_ACCESS_OPTION: uiLib.SelectOption = {
  value: SHARE_BUCKET_ACCESS_LEVEL.VIEW,
  label: 'View only'
};
export const SHARE_BUCKET_EDIT_ACCESS_OPTION: uiLib.SelectOption = {
  value: SHARE_BUCKET_ACCESS_LEVEL.EDIT,
  label: 'Edit'
};

export const SHARE_BUCKET_ACCESS_LEVEL_OPTIONS: uiLib.SelectOption[] = [
  SHARE_BUCKET_VIEW_ACCESS_OPTION,
  SHARE_BUCKET_EDIT_ACCESS_OPTION
];

export const SHARE_BUCKET_RULES_LIMIT = 10000;
