import { useState } from 'react';
import PropTypes from 'prop-types';
import { DeleteModalContent } from './DeleteModalContent';
import * as uiLib from '@compliance.ai/web-components';
import './_delete-button.scss';

export const TEST_ID = {
  CONTAINER: 'delete-container',
  BUTTON: 'delete-button',
  MODAL: 'delete-modal',
  MODAL_CONTENT: 'delete-modal-content-container'
};

export const DeleteButton = ({ buttonLabel, deleteType, deleteAction, isDisabled }) => {
  const [open, setOpen] = useState(false);

  const handleDelete = () => {
    deleteAction();
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <span data-testid={TEST_ID.CONTAINER}>
      <div className="delete-button-wrapper">
        <uiLib.Button
          onClick={() => setOpen(true)}
          type={uiLib.BUTTON_TYPES.LINK_CONTRAST}
          dataTestId={TEST_ID.BUTTON}
          isDisabled={isDisabled}
          className="delete-button"
        >
          {buttonLabel}
        </uiLib.Button>
      </div>
      <uiLib.Modal
        isOpen={open}
        title={buttonLabel}
        dataTestId={TEST_ID.MODAL}
        withFooter
        primaryButtonText="Delete"
        secondaryButtonText="Cancel"
        primaryButtonType={uiLib.BUTTON_TYPES.DELETE}
        secondaryButtonType={uiLib.BUTTON_TYPES.SECONDARY}
        onClose={handleClose}
        onSubmit={handleDelete}
      >
        <DeleteModalContent deleteType={deleteType} dataTestId={TEST_ID.MODAL_CONTENT} />
      </uiLib.Modal>
    </span>
  );
};

DeleteButton.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  deleteType: PropTypes.string.isRequired,
  deleteAction: PropTypes.func.isRequired
};
