import type { SentenceNoteProps } from '../SentenceNote.types';
import type { SentenceNoteData } from './useSentenceNoteData.types';
import type { GlobalState } from 'shared/reducers';

import * as permissionsConstants from 'constants/Permissions';
import * as obligationsSelectors from 'shared/features/obligations/obligations.selectors';
import * as rightPanelSelectors from 'shared/features/rightPanel/rightPanel.selectors';
import * as sentenceNotesSelectors from 'shared/features/sentenceNotes/sentenceNotes.selectors';

import { useMemo, useRef } from 'react';
import { usePermissions } from 'utils/hooks';
import { useSelector } from 'react-redux';

export const useSentenceNoteData = ({
  sentenceNote
}: Pick<SentenceNoteProps, 'sentenceNote'>): SentenceNoteData => {
  const permissions = usePermissions();

  const sentenceNoteRef = useRef(null);

  const refs: SentenceNoteData['refs'] = {
    sentenceNote: sentenceNoteRef
  };

  const reduxState = useSelector<GlobalState, SentenceNoteData['reduxState']>(state => ({
    firstSelectedId: obligationsSelectors.getFirstSelectedId(state as never),
    lastSelectedId: obligationsSelectors.getLastSelectedId(state as never),
    leftOffset: obligationsSelectors.getStartIndex(state as never),
    rightOffset: obligationsSelectors.getEndIndex(state as never),
    obligationHighThreshold: obligationsSelectors.getObligationHighThreshold(state as never),
    selectedSentenceNoteId: rightPanelSelectors.getSentenceNoteId(state as never),
    isExpanded: sentenceNotesSelectors.isSentenceNoteExpanded(state as never, sentenceNote.id)
  }));

  const formattedData: SentenceNoteData['formattedData'] = useMemo(() => {
    const isEditable =
      sentenceNote.isEditable &&
      (sentenceNote.isObligation
        ? permissions[permissionsConstants.EDIT_OBLIGATIONS]
        : permissions[permissionsConstants.EDIT_ANNOTATIONS]);

    const isSelected =
      sentenceNote.firstSentenceId === reduxState.firstSelectedId &&
      sentenceNote.lastSentenceId === reduxState.lastSelectedId &&
      sentenceNote.leftOffset === reduxState.leftOffset &&
      sentenceNote.rightOffset === reduxState.rightOffset;

    const isLikelyObligation =
      sentenceNote.isSystemObligation &&
      'obligationProbability' in sentenceNote &&
      sentenceNote.obligationProbability < reduxState.obligationHighThreshold;

    return {
      isEditable,
      isSelected,
      isLikelyObligation
    };
  }, [
    sentenceNote,
    permissions,
    reduxState.firstSelectedId,
    reduxState.lastSelectedId,
    reduxState.leftOffset,
    reduxState.rightOffset,
    reduxState.obligationHighThreshold
  ]);

  return {
    refs,
    reduxState,
    formattedData
  };
};
