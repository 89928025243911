export const MAX_DOCUMENTS_SHOW = 10000;

export const VIEW_TYPE_DATE = 'DATE';
export const VIEW_TYPE_PAGE = 'PAGE';
export const VIEW_TYPE_NEWS = 'NEWS';

export const RELEVANCE_OPTION = { label: 'Relevance', value: 'relevance' };
export const SORT_OPTIONS = [
  RELEVANCE_OPTION,
  { label: 'Publication Date', value: 'publication_date' },
  { label: 'Agency', value: 'agency_short_name' },
  { label: 'News Source', value: 'news_source_name' },
  { label: 'Document Type', value: 'category' },
  { label: 'Rule Effective On', value: 'effective_date' },
  { label: 'Rule Comments Close On', value: 'comments_close_date' }
];
export const ORDER_OPTIONS = [
  { label: 'Descending', value: 'desc' },
  { label: 'Ascending', value: 'asc' }
];

export const ROW_COUNT_OPTIONS = [
  { label: '20', value: 20 },
  { label: '40', value: 40 },
  { label: '60', value: 60 }
];

export const DEFAULT_SORT_OPTION = SORT_OPTIONS[1];
export const DEFAULT_ORDER_OPTION = ORDER_OPTIONS[1];
export const DEFAULT_ROW_COUNT_OPTION = ROW_COUNT_OPTIONS[0];
export const DEFAULT_LIMIT = DEFAULT_ROW_COUNT_OPTION.value;
export const DEFAULT_ORDER = DEFAULT_ORDER_OPTION.value;
export const DEFAULT_SORT = DEFAULT_SORT_OPTION.value;
export const DEFAULT_MIN_SCORE = 0;
export const DEFAULT_OFFSET = 0;

export const MAX_ROWS_PER_PAGE = ROW_COUNT_OPTIONS[2].length;
