import type { DropdownProps } from '@compliance.ai/web-components';

export const PLACEHOLDER =
  'Example (jurisdiction=US-CA AND agency != SEC and topic = Lending) OR (doc_type = Enforcement)';

export const ANCHOR_ORIGIN: DropdownProps['anchorOrigin'] = {
  vertical: 'bottom',
  horizontal: 'left'
};

export const TRANSFORM_ORIGIN: DropdownProps['transformOrigin'] = {
  vertical: 'top',
  horizontal: 'left'
};
