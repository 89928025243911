import { useDidMount, useUpdateEffect } from 'utils/hooks';
import { REATIVE_DATE_OPERATOR_LABELS, REATIVE_DATE_OPERATORS } from 'constants/RelativeDates';

export const useCustomDatePickerLifecycle = ({ props, localState, dateHandlers }) => {
  useDidMount(() => {
    dateHandlers.handleSelectedDateChange(props.date);
    dateHandlers.handleSelectedStartDateChange(props.startDate);
    dateHandlers.handleSelectedEndDateChange(props.endDate);
  });

  useUpdateEffect(() => {
    if (props.cleared) {
      dateHandlers.handleSelectedOperatorChange(null);
    }
  }, [props.cleared]);

  useUpdateEffect(() => {
    if (
      localState.operator === REATIVE_DATE_OPERATOR_LABELS.PAST ||
      localState.operator === REATIVE_DATE_OPERATOR_LABELS.NEXT
    ) {
      let updatedRelativeDate =
        !!localState.amount && !!localState.duration && parseInt(localState.amount, 10) > 0
          ? `${localState.amount}${REATIVE_DATE_OPERATORS.PAST_NEXT_SEPERATOR}${localState.duration}`
          : '';

      if (localState.operator === REATIVE_DATE_OPERATOR_LABELS.PAST) {
        updatedRelativeDate = '-' + updatedRelativeDate;
      }

      dateHandlers.handleSelectedDateChange(updatedRelativeDate);
    }
  }, [localState.operator, localState.amount, localState.duration]);
};
