import { useEffect } from 'react';
import { useUpdateEffect } from '@compliance.ai/web-components';

export const useLabelDetailsLifecycle = ({ onLabelFetch, onTriggerChange }) => {
  useEffect(() => {
    onLabelFetch();
  }, [onLabelFetch]);

  useUpdateEffect(onTriggerChange, [onTriggerChange]);
};
