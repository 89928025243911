import type * as apiTypes from './orgDocumentsMetadata.api.types';

import { fetch } from 'fetch';

export const uploadMappingsDocument: apiTypes.UploadMappingsDocument = async payload => {
  const formData = new FormData();

  formData.append('file', payload);

  return await fetch({
    url: '/organizations/organization_documents/xlsx_template_validation',
    method: 'POST',
    params: formData
  });
};

export const uploadMetadata: apiTypes.UploadMetadata = async payload => {
  return fetch({
    url: '/organizations/organization_documents_metadata',
    method: 'POST',
    params: payload
  });
};
