import type { ProgressiveSentenceNotesData } from './useProgressiveSentenceNotesData.types';
import type { GlobalState } from '../../../../reducers';
import type { ProgressiveSentenceNotesArgs } from './useProgressiveSentenceNotes.types';

import * as sentenceNotesSelectors from '../../sentenceNotes.selectors';
import * as authSelectors from '../../../auth/auth.selectors';
import * as sentenceNotesConstants from '../../sentenceNotes.constants';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const useProgressiveSentenceNotesData = ({
  type,
  documentId
}: Pick<ProgressiveSentenceNotesArgs, 'type' | 'documentId'>): ProgressiveSentenceNotesData => {
  const [hasFetchedSingleSentenceNote, setHasFetchedSingleSentenceNote] = useState<
    ProgressiveSentenceNotesData['localState']['hasFetchedSingleSentenceNote']
  >(false);
  const [isInitialFetchOfList, setIsInitialFetchOfList] = useState<
    ProgressiveSentenceNotesData['localState']['isInitialFetchOfList']
  >(true);
  const [totalCount, setTotalCount] = useState<
    ProgressiveSentenceNotesData['localState']['totalCount']
  >(0);

  const localState: ProgressiveSentenceNotesData['localState'] = {
    totalCount,
    hasFetchedSingleSentenceNote,
    isInitialFetchOfList
  };

  const localActions: ProgressiveSentenceNotesData['localActions'] = useMemo(
    () => ({
      setTotalCount,
      setHasFetchedSingleSentenceNote,
      setIsInitialFetchOfList
    }),
    []
  );

  const reduxState = useSelector<GlobalState, ProgressiveSentenceNotesData['reduxState']>(
    state => ({
      sentenceNotes: {
        [sentenceNotesConstants.SENTENCE_NOTE_TYPE
          .ANNOTATIONS]: sentenceNotesSelectors.getAnnotationSentenceNotesByDocId(
          state,
          documentId
        ),
        [sentenceNotesConstants.SENTENCE_NOTE_TYPE
          .OBLIGATIONS]: sentenceNotesSelectors.getObligationSentenceNotesByDocId(state, documentId)
      }[type],
      isAuthenticated: authSelectors.getUserAuthenticatedState(state as never)
    })
  );

  const formattedData: ProgressiveSentenceNotesData['formattedData'] = useMemo(() => {
    const progress =
      localState.totalCount > 0
        ? Math.round((reduxState.sentenceNotes.length / localState.totalCount) * 100)
        : 0;

    return {
      progress
    };
  }, [localState.totalCount, reduxState.sentenceNotes.length]);

  return {
    localState,
    localActions,
    reduxState,
    formattedData
  };
};
