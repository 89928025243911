import { useSelector } from 'react-redux';
import {
  getFetchingSimpleDocsStatus,
  getBulkSelectedDocsFlag
} from 'shared/features/view/view.selectors';
import { getExportLimit } from 'utils/exportLimit/exportLimit';
import { MAX_ROWS_PER_PAGE } from 'constants/ActionBar';
import { getUserAuthenticatedState } from 'shared/features/auth/auth.selectors';

export const useDocumentsBulkExportData = ({ selectedDocsIds, selectedOverDailyLimit }) => {
  const reduxState = useSelector(state => ({
    isBulkExport: getBulkSelectedDocsFlag(state),
    isFetchingSimpleDocs: getFetchingSimpleDocsStatus(state),
    isAuthenticated: getUserAuthenticatedState(state)
  }));

  const selectedDocsCount = selectedDocsIds.length;
  const shouldFetchBulkDocs = selectedDocsCount > MAX_ROWS_PER_PAGE;
  const hasSelectedOverUpperLimit = selectedDocsCount > getExportLimit();

  const shouldRenderBulkExport = Boolean(
    reduxState.isBulkExport &&
      selectedDocsCount > 0 &&
      !hasSelectedOverUpperLimit &&
      !selectedOverDailyLimit &&
      reduxState.isAuthenticated
  );

  return {
    reduxState,
    formattedData: {
      shouldRenderBulkExport,
      selectedDocsCount,
      shouldFetchBulkDocs,
      hasSelectedOverUpperLimit
    }
  };
};
