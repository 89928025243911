import type { Stage } from '../Stage';

export class OmitEmptyMetadataStage implements Stage {
  public readonly fileNameFieldLabel: string;

  constructor(args: { fileNameFieldLabel: string }) {
    this.fileNameFieldLabel = args.fileNameFieldLabel;
  }

  public run: Stage['run'] = async args => {
    const isEmptyRow = !Object.values(args.metadata).filter(Boolean).length;
    const documentFileName = args.metadata.find(
      ([metadataField]) => metadataField === this.fileNameFieldLabel
    );

    if (!documentFileName || isEmptyRow) {
      return [];
    }

    return args.metadata;
  };
}
