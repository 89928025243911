import { useMemo } from 'react';
import { isValidEmail, isValidPassword } from '../SignUpDataValidator.helpers';
import { CONFIRM_PASSWORD_DOES_NOT_MATCH_ERROR } from '../SignUpDataValidator.constants';

export const useSignUpDataValidator = ({ email = '', password = '', confirmPassword = '' }) => {
  const signUpDataValidation = useMemo(() => {
    const passwordIsValid = isValidPassword(password);
    const confirmPasswordIsValid = isValidPassword(confirmPassword) && confirmPassword === password;
    const confirmPasswordErrorMessage =
      !confirmPassword || confirmPasswordIsValid ? '' : CONFIRM_PASSWORD_DOES_NOT_MATCH_ERROR;

    return {
      email: {
        isValid: isValidEmail(email)
      },
      password: {
        isValid: passwordIsValid
      },
      confirmPassword: {
        isValid: confirmPasswordIsValid,
        errorMessage: confirmPasswordErrorMessage
      }
    };
  }, [password, confirmPassword, email]);

  return {
    signUpDataValidation
  };
};
