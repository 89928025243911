import { useSKUs } from './useSKUs';

/**
 *
 * Note: use this HOC only for legacy class components
 *
 */
export const withSKUs = Component => {
  return function WrappedComponent(props) {
    const { userHasSKU, showSKURestrictModal } = useSKUs();
    return (
      <Component {...props} userHasSKU={userHasSKU} showSKURestrictModal={showSKURestrictModal} />
    );
  };
};
