import MUITooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import './_tooltip.scss';

export const TOOLTIP_TYPE = {
  DEFAULT: 'default',
  BLUE: 'blue',
  WHITE: 'white'
};

export const TOOLTIP_POSITION = {
  BOTTOM: 'bottom',
  TOP: 'top',
  RIGHT: 'right',
  LEFT: 'left',
  BOTTOM_END: 'bottom-end',
  BOTTOM_START: 'bottom-start'
};

const Tooltip = ({
  id,
  title,
  children,
  placement,
  interactive,
  leaveDelay,
  isOpen,
  type,
  shouldShowArrow,
  shouldShowCloseButton,
  onCloseButtonClick,
  onClose,
  wrapperClassName
}) => {
  if (!title) {
    isOpen = false;
  }

  return (
    <MUITooltip
      placement={placement}
      id={id}
      title={
        <>
          <span className={`tooltip__text--${type}`}>{title}</span>
          {shouldShowCloseButton && (
            <CloseIcon
              onClick={onCloseButtonClick}
              classes={{
                root: `tooltip__icon--${type}`
              }}
            />
          )}
        </>
      }
      open={isOpen}
      leaveDelay={leaveDelay}
      interactive={interactive}
      classes={{
        tooltip: `tooltip--${type}`,
        popper: 'tooltip__popper',
        arrow: `tooltip__arrow--${type}`
      }}
      arrow={shouldShowArrow}
      onClose={onClose}
    >
      <div data-testid="tooltip-wrapper" className={wrapperClassName}>
        {children}
      </div>
    </MUITooltip>
  );
};

export default Tooltip;

Tooltip.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  placement: PropTypes.oneOf(Object.values(TOOLTIP_POSITION)),
  interactive: PropTypes.bool,
  leaveDelay: PropTypes.number,
  isOpen: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(TOOLTIP_TYPE)),
  shouldShowArrow: PropTypes.bool,
  shouldShowCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  onCloseButtonClick: PropTypes.func,
  wrapperClassName: PropTypes.string
};

Tooltip.defaultProps = {
  children: null,
  id: undefined,
  placement: 'bottom',
  interactive: false,
  leaveDelay: 0,
  isOpen: undefined,
  type: TOOLTIP_TYPE.DEFAULT,
  shouldShowArrow: true,
  shouldShowCloseButton: false,
  onClose: () => {},
  onCloseButtonClick: () => {}
};
