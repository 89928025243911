import { useMemo, useState } from 'react';

export const useGenericAttachmentsData = () => {
  const [validationWarning, showValidationWarning] = useState('');
  const [isLoading, setLoadingState] = useState(false);

  const localActions = useMemo(
    () => ({
      showValidationWarning,
      setLoadingState
    }),
    []
  );

  return {
    localState: {
      isLoading,
      validationWarning
    },
    localActions
  };
};
