import type { GlobalState } from '../../reducers';
import type {
  DocumentTaskFromResponse,
  PreDocumentTask,
  WorkflowFromResponse,
  WorkflowTaskFromResponse
} from './workflow.types';
import type { OrganizationMemberFromResponse } from '../organizations/organizations.types';
import type { TeamFromResponse } from '../user/user.types';
import type { DocumentFromResponse } from '../documents/documents.types';

import { createSelector } from '@reduxjs/toolkit';
import {
  filterOrgMembersByPermissions,
  filterTeamMembersByPermissions,
  formatUserName
} from './workflow.helpers';
import lodash from 'lodash';
import {
  getOrganizationMembers,
  getOrganizationReadyState
} from 'shared/features/organizations/organizations.selectors';
import {
  canUserViewDocument,
  getOrganizationTeams
} from '../organizations/organizations.selectors';
import { checkCurrentUserRole, getCurrentUserPermissions, getUserId } from '../user/user.selectors';
import { ORG_ADMIN, TEAM_ADMIN, WORKFLOW_ADMIN } from '../../../constants/UserRoles';
import { getGlobalState } from '../global/global.selectors';
import { MANAGE_WORKFLOWS } from '../../../constants/Permissions';

export const getWorkflowsReducer = (state: GlobalState): GlobalState['workflows'] =>
  state.workflows;

export const getWorkflowsReadyState = createSelector(getWorkflowsReducer, workflowsReducer => {
  return workflowsReducer?.isReady;
});

export const getAddingDocumentsToWorkflowFlag = createSelector(
  getWorkflowsReducer,
  workflowsReducer => {
    return workflowsReducer?.isAddingDocumentsToWorkflow;
  }
);

export const getTasksReducer = (state: GlobalState): GlobalState['tasks'] => state.tasks;

export const getTasksRefetchTrigger = createSelector(getTasksReducer, tasksReducer => {
  return tasksReducer.tasksRefetchTrigger;
});

export const getTasks = createSelector(getTasksReducer, tasksReducer => {
  return tasksReducer.tasksList ?? [];
});

export const getTasksReadyState = createSelector(getTasksReducer, tasksReducer => {
  return tasksReducer?.isReady;
});

export const getTasksFetchingState = createSelector(getTasksReducer, tasksReducer => {
  return tasksReducer?.isFetching;
});

export const getWorkflowTaskPossibleAssignees = ({
  task,
  workflow
}: {
  task: WorkflowTaskFromResponse | PreDocumentTask;
  workflow: WorkflowFromResponse;
}) =>
  createSelector(
    getOrganizationMembers,
    getOrganizationTeams,
    (organizationMembers, orgTeams: TeamFromResponse[]): OrganizationMemberFromResponse[] => {
      const taskTeams = orgTeams.filter(team => task?.teams?.find(({ id }) => id === team.id));
      const workflowTeams = orgTeams.filter(team =>
        workflow?.teams?.find(({ id }) => id === team.id)
      );
      const teams = taskTeams.length ? taskTeams : workflowTeams;

      if (!teams.length) {
        return filterOrgMembersByPermissions(organizationMembers);
      }

      return filterTeamMembersByPermissions(teams);
    }
  );

export const getPreDocumentTaskPossibleAssignees = ({
  task,
  workflow
}: {
  task: PreDocumentTask;
  workflow: WorkflowFromResponse;
}) => {
  // FIXME Inferred types nightmare. Should be resolved when we move organization.selectors to TS
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return createSelector(
    getWorkflowTaskPossibleAssignees({ task, workflow }),
    getGlobalState,
    (
      orgMembers: OrganizationMemberFromResponse[],
      state: GlobalState
    ): OrganizationMemberFromResponse[] => {
      return orgMembers.filter(({ id }) => {
        return canUserViewDocument({ document: task.document, userId: id })(state as never);
      });
    }
  );
};

export const getPreDocumentTaskPossibleAssigneesOptions = ({
  task,
  workflow
}: {
  task: PreDocumentTask;
  workflow: WorkflowFromResponse;
}) =>
  createSelector(getPreDocumentTaskPossibleAssignees({ task, workflow }), orgMembers => {
    return orgMembers.map(user => ({
      value: lodash.get(user, 'id'),
      label: formatUserName(user)
    }));
  });

export const getPreDocumentTaskPossibleAssigneeOption = createSelector(
  (
    state: GlobalState,
    args: {
      task: PreDocumentTask;
      workflow: WorkflowFromResponse;
    }
  ) => ({
    ...args,
    state
  }),
  ({ task, workflow, state }) => {
    const assigneeOptions = getPreDocumentTaskPossibleAssigneesOptions({
      task,
      workflow
    })(state);

    return assigneeOptions.find(({ value }) => value === task.assignee) ?? null;
  }
);

export const getPreDocumentTaskPossibleAssigneeOptionLabel = createSelector(
  (
    state: GlobalState,
    args: {
      task: PreDocumentTask;
      workflow: WorkflowFromResponse;
    }
  ) => getPreDocumentTaskPossibleAssigneeOption(state, args),
  getOrganizationReadyState,
  (assigneeOption, isOrgReady) => {
    if (!isOrgReady) {
      return 'Loading...';
    }

    if (assigneeOption?.label) {
      return assigneeOption?.label;
    }

    return '(Expired user)';
  }
);

export const areAllPreDocumentTasksAssigneesValid = ({
  tasks,
  workflow
}: {
  tasks: PreDocumentTask[];
  workflow: WorkflowFromResponse;
}) => (state: GlobalState) => {
  return tasks.every(task => {
    const possibleAssignees = getPreDocumentTaskPossibleAssignees({ task, workflow })(state);
    const foundAssignee = possibleAssignees.find(assignee => assignee.id === task.assignee);

    return Boolean(foundAssignee);
  });
};

export const getWorkflowTaskPossibleAssigneeOptions = ({
  task,
  workflow
}: {
  task: WorkflowTaskFromResponse;
  workflow: WorkflowFromResponse;
}) => {
  // FIXME Inferred types nightmare. Should be resolved when we move organization.selectors to TS
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return createSelector(
    getWorkflowTaskPossibleAssignees({ task, workflow }),
    (users: OrganizationMemberFromResponse[]) => {
      return users.map(user => ({
        value: lodash.get(user, 'id'),
        label: formatUserName(user)
      }));
    }
  );
};

export const getDocumentTaskPossibleAssignees = ({
  documentTask,
  document
}: {
  documentTask: DocumentTaskFromResponse;
  document: DocumentFromResponse;
}) =>
  createSelector(
    getOrganizationMembers,
    getOrganizationTeams,
    getGlobalState,
    (organizationMembers, orgTeams: TeamFromResponse[], state) => {
      const documentTaskTeams = orgTeams.filter(team =>
        documentTask?.teams?.find(docTaskTeam => docTaskTeam.id === team.id)
      );
      const teams = documentTaskTeams.length ? documentTaskTeams : [];

      const orgMembers: OrganizationMemberFromResponse[] = !teams.length
        ? filterOrgMembersByPermissions(organizationMembers)
        : filterTeamMembersByPermissions(teams);

      return orgMembers.filter(({ id }) => {
        return canUserViewDocument({ document, userId: id })(state);
      });
    }
  );

export const getDocumentTaskPossibleAssigneeIds = createSelector(
  (
    state: GlobalState,
    args: {
      documentTask: DocumentTaskFromResponse;
      document: DocumentFromResponse;
    }
  ) => getDocumentTaskPossibleAssignees(args)(state),
  assignees => {
    return assignees.map(assignee => assignee.id);
  }
);

export const getDocumentTaskPossibleAssigneeOptions = ({
  documentTask,
  document
}: {
  documentTask: DocumentTaskFromResponse;
  document: DocumentFromResponse;
}) =>
  createSelector(getDocumentTaskPossibleAssignees({ documentTask, document }), users => {
    return users.map(user => ({
      value: lodash.get(user, 'id'),
      label: formatUserName(user)
    }));
  });

export const hasAccessToWorkflow = createSelector(
  [
    getGlobalState,
    getOrganizationTeams,
    getUserId,
    getCurrentUserPermissions,
    (state, teamIds: TeamFromResponse['id'][]) => teamIds
  ],
  (
    state,
    orgTeams: TeamFromResponse[],
    currentUserId,
    currentUserPermissions,
    teamIds
  ): boolean => {
    const isOrgAdmin = checkCurrentUserRole(state, ORG_ADMIN);
    const isTeamAdmin = checkCurrentUserRole(state, TEAM_ADMIN);
    const isWorkflowAdmin = checkCurrentUserRole(state, WORKFLOW_ADMIN);
    const hasManageWorkflowsPermission = currentUserPermissions[MANAGE_WORKFLOWS];

    if (!hasManageWorkflowsPermission) {
      return false;
    }

    if (isOrgAdmin || isTeamAdmin) {
      return true;
    }

    if (isWorkflowAdmin) {
      if (!teamIds?.length) {
        return true;
      }

      const teamMemberIds = orgTeams
        .filter(team => teamIds.includes(team.id))
        .flatMap(team => team?.team_members?.map?.(teamMember => teamMember.id) ?? []);

      return teamMemberIds.includes(currentUserId);
    }

    return false;
  }
);
