import type { DashboardPDFOverlayHandlers } from './useDashboardPDFOverlayHandlers.types';

import { useEffect } from 'react';

export const useDashboardPDFOverlayLifecycle = ({
  onPDFLoad
}: {
  onPDFLoad: DashboardPDFOverlayHandlers['handlePDFLoad'];
}) => {
  useEffect(onPDFLoad, [onPDFLoad]);
};
