import type { DocumentFromResponse } from 'shared/features/documents/documents.types';

import { fetchDocumentPDF, generateFilename } from 'utils/pdf';
import { safe_analytics } from 'utils/analytics';
import { initiatePrinting } from 'utils/print';
import { isIE } from 'utils/browser';

export const fetchPDF = async ({
  windowReference,
  document
}: {
  windowReference: Window | null;
  document: DocumentFromResponse;
}): Promise<Error | null> => {
  try {
    const pdf = await fetchDocumentPDF(document);

    if (pdf) {
      safe_analytics('Doc Action – Print document', 'Doc Action', 'Print document', document.title);

      const filename = generateFilename(document.title, document.id);
      const blob = new Blob([pdf], { type: 'application/pdf' });

      initiatePrinting(isIE(), windowReference, blob, filename);
    } else {
      safe_analytics('Dashboard – PDF unavailable', 'Dashboard', 'PDF unavailable', document.title);
    }

    return null;
  } catch (e) {
    return e as Error;
  }
};
