import type * as apiTypes from './eitlLabels.api.types';
import type * as eitlLabelsTypes from './eitlLabels.types';
import type * as api from './eitlLabels.api';

import * as sortUtils from 'utils/sort';

export const formatEitlLabelsOption = (
  eitlLabel: apiTypes.EitlLabelFromResponse
): eitlLabelsTypes.EitlLabelOption => ({
  label: eitlLabel.name,
  value: eitlLabel.id
});

export const formatEitlLabelsOptions = (
  response: Awaited<ReturnType<typeof api.getEitlLabels>>
): eitlLabelsTypes.EitlLabelOption[] => {
  return response.eitl_labels.map(formatEitlLabelsOption).sort(sortUtils.alphabeticallyByLabel);
};
