export enum TABLE_FIELDS {
  ID = 'id',
  NAME = 'name',
  UNFORMATTED_NAME = 'unformattedName',
  DOCUMENTS_COUNT = 'documentsCount',
  CREATED_BY = 'createdBy',
  CREATED_AT = 'createdAt',
  DESCRIPTION = 'description',
  EXTERNAL_ID = 'externalId',
  ALERTS = 'alerts',
  TYPE = 'type',
  TYPE_OPTION = 'typeOption',
  BUCKET = 'bucket',
  BUCKET_OPTION = 'bucketOption',
  IS_EDITABLE = 'isEditable'
}

export enum TABLE_SORT_KEYS {
  NAME = 'name',
  DOCUMENTS_COUNT = 'documents_count',
  CREATED_BY = 'created_by',
  CREATED_AT = 'created_at',
  TYPE = 'type',
  BUCKET = 'bucket'
}

export enum API_KEYS {
  SORT_FIELD = 'sort_field',
  SORT_ORDER = 'sort_order',
  NAME = 'name',
  CREATED_BY = 'created_by',
  CREATED_FROM = 'created_from',
  CREATED_TO = 'created_to',
  PAGE = 'page',
  COUNT_PER_PAGE = 'count_per_page',
  BUCKET_ID = 'bucket_id',
  LIMIT = 'limit',
  IS_EDITABLE = 'is_editable',
  TYPE = 'type',
  DESCRIPTION = 'description',
  EXTERNAL_ID = 'external_id'
}

export enum LABELS_FILTER_KEY {
  DATE_CREATED = 'Date created',
  CREATED_BY = 'Created by',
  TYPE = 'Type',
  DESCRIPTION = 'Description',
  BUCKET_ID = 'Bucket',
  EXTERNAL_ID = 'External ID'
}

export const ROOT_FOLDER_NAME = 'All Folders';

export const LABELS_REPORT_FIELDS = [
  'label_name',
  'number_of_docs',
  'created_by',
  'date_created',
  'label_type',
  'label_description',
  'label_external_id'
];

export const FILTERS_OPTIONS_LIMIT = 100;

export const EMPTY_LABEL_TYPE_LABEL = 'None';
