import type { SelectOption } from '@compliance.ai/web-components';
import type { DropdownHandlers } from './useDropdownHandlers.types';
import type { DropdownProps } from '../Dropdown.types';
import type { WorkflowTaskDropdownOptionFromResponse } from 'shared/features/workflow/workflow.types';

import { useCallback } from 'react';

export const useDropdownHandlers = ({
  props
}: {
  props: Pick<DropdownProps, 'onChange' | 'dropdown'>;
}): DropdownHandlers => {
  const handleChange: DropdownHandlers['handleChange'] = useCallback(
    value => {
      let selectedOptions: SelectOption<WorkflowTaskDropdownOptionFromResponse['id']>[];

      if (!value) {
        selectedOptions = [];
      } else if (!Array.isArray(value) && value) {
        selectedOptions = [value];
      } else {
        selectedOptions = value;
      }

      props.onChange([
        props.dropdown.id,
        {
          selected_option_id: selectedOptions.map(option => option.value)
        }
      ]);
    },
    [props]
  );

  return {
    handleChange
  };
};
