import type { DocumentFromResponse } from 'shared/features/documents/documents.types';

import * as exportConstants from 'constants/Export';

export const EXPORT_DROPDOWN_OPTIONS = [
  {
    value: exportConstants.CONTENT_TYPES.CSV,
    label: exportConstants.CONTENT_DISPLAY_NAMES[exportConstants.CONTENT_TYPES.CSV]
  },
  {
    value: exportConstants.CONTENT_TYPES.XLSX,
    label: exportConstants.CONTENT_DISPLAY_NAMES[exportConstants.CONTENT_TYPES.XLSX]
  },
  {
    value: exportConstants.CONTENT_TYPES.HTML,
    label: exportConstants.CONTENT_DISPLAY_NAMES[exportConstants.CONTENT_TYPES.HTML]
  },
  {
    value: exportConstants.CONTENT_TYPES.PDF,
    label: exportConstants.CONTENT_DISPLAY_NAMES[exportConstants.CONTENT_TYPES.PDF]
  }
];

export const ANNOTATION_EXPORT_DROPDOWN_OPTIONS = [
  {
    value: exportConstants.CONTENT_TYPES.CSV,
    label: exportConstants.CONTENT_DISPLAY_NAMES[exportConstants.CONTENT_TYPES.CSV]
  },
  {
    value: exportConstants.CONTENT_TYPES.XLSX,
    label: exportConstants.CONTENT_DISPLAY_NAMES[exportConstants.CONTENT_TYPES.XLSX]
  }
];

export const EXCLUSIVE_FIELDS: (keyof DocumentFromResponse)[] = ['id', 'has_sentences', 'title'];
