import type { EnforcementFilterData } from './useEnforcementFilterData.types';
import type { NormalizedDefaults } from 'shared/features/defaults/defaults.types';

import * as defaultsHelpers from 'shared/features/defaults/defaults.helpers';
import * as filterDefaultOptionsHelpers from 'shared/features/filters/hooks/useFilterDefaultOptions.helpers';

export const prepareDefaultFilterOptions = ({
  reduxState,
  defaultsData
}: {
  reduxState: EnforcementFilterData['reduxState'];
  defaultsData: NormalizedDefaults;
}) => {
  return filterDefaultOptionsHelpers.getFormattedDefaultOptions({
    defaultTopics: defaultsData.defaultTopics,
    followedAgencies: defaultsHelpers.getFilteredFollowedAgencies({
      agencies: reduxState.agencies.items,
      followedAgenciesIdsMap: defaultsData.followedAgencies
    }),
    followedTopics: defaultsData.realFollowedTopics,
    followedConcepts: defaultsHelpers.getActiveFollowedConcepts({
      activeConcepts: reduxState.concepts,
      followedConcepts: defaultsData.followedConcepts
    }),
    allConceptOptions: reduxState.allConceptOptions,

    followedCategories: {},
    followedJurisdictions: [],
    metaCategoriesObject: {},
    followedMainstreamNewsSources: [],
    allCategories: []
  });
};
