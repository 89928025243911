export const TABLE_FIELDS = {
  DOCUMENT_ID: 'documentId',
  DOCUMENT_TITLE: 'documentTitle',
  LABEL_PARENT: 'labelParent',
  ADDED_BY: 'addedBy',
  USER_ID: 'userId',
  USER_EMAIL: 'userEmail',
  ADDED_AT: 'addedAt'
};

export const API_KEYS = {
  PAGE: 'page',
  COUNT_PER_PAGE: 'count_per_page',
  SORT_FIELD: 'sort_field',
  SORT_ORDER: 'sort_order',
  LABEL_PARENT: 'label_parent',
  ADDED_BY: 'added_by',
  DOCUMENT_TITLE: 'document_title',
  ADDED_FROM: 'added_from',
  ADDED_TO: 'added_to',
  USER_EMAIL: 'user_email',
  ADDED_AT: 'added_at'
};

export const SELECTED_LABEL_DETAILS_MAX_COUNT = 600;
export const SELECTED_LABEL_DETAILS_DEFAULT_PAGE = 1;
