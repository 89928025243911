import { Add } from '@material-ui/icons';
import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import { useAddToFolderModalFooterData } from './hooks';
import './_add-to-folder-modal-footer.scss';

export const TEST_ID = 'add-to-folder-modal-footer';

export const AddToFolderModalFooter = ({
  isLoading,
  isAddingFolder,
  areDocumentsInCurrentFolder,
  currentFolder,
  folderName,
  onNewFolderClick,
  onCancel,
  onSubmit
}) => {
  const { formattedData } = useAddToFolderModalFooterData({
    props: {
      isLoading,
      isAddingFolder,
      areDocumentsInCurrentFolder,
      currentFolder,
      folderName
    }
  });

  return (
    <div
      className="default-modal-children-container add-to-folder-modal-footer"
      data-testid={TEST_ID}
    >
      {!formattedData.isNewFolderDisabled && (
        <Button
          className="add-to-folder-modal-footer__btn btn-new-folder"
          type={BUTTON_TYPES.SECONDARY}
          onClick={onNewFolderClick}
        >
          <Add className="icon-new-folder" fontSize="small" />
          New Folder
        </Button>
      )}
      <div className="add-to-folder-modal-footer__action-btns">
        <Button
          className="add-to-folder-modal-footer__btn btn-cancel"
          type={BUTTON_TYPES.SECONDARY}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          className="add-to-folder-modal-footer-btn btn-add-folder"
          type={BUTTON_TYPES.PRIMARY}
          onClick={onSubmit}
          isDisabled={formattedData.isAddDocumentDisabled}
        >
          {isAddingFolder ? 'Add folder' : 'Add here'}
        </Button>
      </div>
    </div>
  );
};
