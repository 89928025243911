import type { FC } from 'react';

import * as constants from './Legend.constants';
import './_legend.scss';

export const Legend: FC = () => {
  return (
    <div className="obligations-legend">
      {constants.LEGEND.map(legend => (
        <div key={legend.label} className="obligations-legend__legend-item">
          <div
            style={{ backgroundColor: legend.color }}
            className="obligations-legend__legend-color-square"
          />
          <div className="obligations-legend__legend-definition">{legend.label}</div>
        </div>
      ))}
    </div>
  );
};
