import type { FC } from 'react';
import type { ApprovalCellProps } from './ApprovalCell.types';

import * as workflowHelpers from 'shared/features/workflow/workflow.helpers';
import * as workflowConstants from 'shared/features/workflow/workflow.constants';
import * as emptyValue from 'constants/EmptyValue';

export const ApprovalCell: FC<ApprovalCellProps> = ({ cellValue }) => {
  if (
    !cellValue.annotation_settings[
      workflowConstants.WORKFLOW_ANNOTATION_SETTINGS_KEYS.TYPES
    ].includes(workflowConstants.WORKFLOW_ANNOTATION_TYPES.APPROVAL)
  ) {
    return <>{emptyValue.EMPTY_VALUE.NA}</>;
  }

  const normalizedApprovalStatus = workflowHelpers.normalizeApprovalStatus(
    cellValue.approval_status
  );

  switch (normalizedApprovalStatus) {
    case workflowConstants.APPROVAL_ANNOTATION_STATUSES.APPROVED: {
      return <>Done</>;
    }

    case workflowConstants.APPROVAL_ANNOTATION_STATUSES.REJECTED: {
      return <>Rejected</>;
    }

    default: {
      return <>Not Done</>;
    }
  }
};
