import { ExpertConnectionPanel } from './elements';
import { useExpertConnectionTabData } from './hooks';

export const ExpertConnectionTab = ({ document }) => {
  const { reduxState } = useExpertConnectionTabData({ document });

  return (
    <ExpertConnectionPanel
      expertNames={reduxState.expertNames}
      docId={document.id}
      docTitle={document.title}
    />
  );
};
