import { isSingleRelativeDate, isPastNextRelativeDate } from 'utils/relativeDate-utils';
import { REATIVE_DATE_OPERATORS, RELATIVE_DATE_COMPARATOR } from 'constants/RelativeDates';
import advancedSearchParser from './advancedSearchGrammar';
import { getActiveCategoryIDsByIDs } from 'shared/features/documents/documents.helper';
import { ADVANCED_SEARCH_KEYS } from 'constants/AdvancedSearch';

export const getActions = queryState => {
  //commented out attributes that should work with IN but builder doesn't support them currently
  const inClauseAttributes = {
    [ADVANCED_SEARCH_KEYS.ACT]: true,
    // act_id: true,
    [ADVANCED_SEARCH_KEYS.AGENCY]: true,
    // agency_id: true,
    [ADVANCED_SEARCH_KEYS.BANK]: true,
    [ADVANCED_SEARCH_KEYS.AUTHOR]: true,
    // bank_id: true,
    [ADVANCED_SEARCH_KEYS.BUSINESS]: true,
    [ADVANCED_SEARCH_KEYS.CAI_CATEGORY_LEGACY]: true,
    [ADVANCED_SEARCH_KEYS.CAI_CATEGORY]: true,
    [ADVANCED_SEARCH_KEYS.CONCEPT]: true,
    // citation: true,
    // citation_id: true,
    // doc_id: true,
    [ADVANCED_SEARCH_KEYS.DOC_TYPE_LEGACY]: true,
    // docket_id: true,
    // full_text: true,
    [ADVANCED_SEARCH_KEYS.JURISDICTION]: true,
    // location_id: true,
    [ADVANCED_SEARCH_KEYS.NEWS_SOURCE]: true,
    // news_source_id: true,
    [ADVANCED_SEARCH_KEYS.REGULATION]: true,
    // regulation_id: true,
    [ADVANCED_SEARCH_KEYS.RESPONDENT]: true,
    // text: true,
    // title: true,
    [ADVANCED_SEARCH_KEYS.TOPIC]: true,
    [ADVANCED_SEARCH_KEYS.LABEL]: true,
    // topic_id: true,
    // violation: true,
    [ADVANCED_SEARCH_KEYS.EITL_LABEL_ID]: true
  };

  //relative date constants and helpers
  const relativeDateAttributes = {
    [ADVANCED_SEARCH_KEYS.COMMENTS_CLOSE_DATE]: true,
    [ADVANCED_SEARCH_KEYS.EFFECTIVE_DATE]: true,
    [ADVANCED_SEARCH_KEYS.PUBLICATION_DATE]: true,
    [ADVANCED_SEARCH_KEYS.PUBLISHED_ON]: true,
    [ADVANCED_SEARCH_KEYS.ADDED_DATE]: true,
    [ADVANCED_SEARCH_KEYS.UPDATED_DATE]: true
  };

  const booleanAttributes = {
    [ADVANCED_SEARCH_KEYS.OBLIGATIONS]: true,
    [ADVANCED_SEARCH_KEYS.PREMIUM_CONTENT]: true,
    [ADVANCED_SEARCH_KEYS.DIFFING_AVAILABLE]: true,
    [ADVANCED_SEARCH_KEYS.HAS_UNOFFICIAL_PUB_DATE]: true,
    [ADVANCED_SEARCH_KEYS.HAS_INDIVIDUAL_RESPONDENT]: true,
    [ADVANCED_SEARCH_KEYS.HAS_ENTITY_RESPONDENT]: true,
    [ADVANCED_SEARCH_KEYS.HAS_EITL_LABELS]: true,
    [ADVANCED_SEARCH_KEYS.AUTO_SUMMARY_AVAILABLE]: true,
    [ADVANCED_SEARCH_KEYS.AUTO_TRANSLATED_DOCUMENT]: true,
    [ADVANCED_SEARCH_KEYS.HAS_PARENT]: true,
    [ADVANCED_SEARCH_KEYS.EXCLUDE_FEDERAL_REGISTER]: true
  };

  const formatBooleanValue = value => {
    if (value.toLowerCase() === 'false') return false;
    return true;
  };

  const formatCategoryID = values => {
    const { categories } = queryState;

    const selectedCategoryIDs = values.filter(value => value in categories);
    const activeCategoryIDS = getActiveCategoryIDsByIDs(selectedCategoryIDs, categories);

    return activeCategoryIDS.map(categoryID => ({
      label: categories[categoryID].name,
      value: categories[categoryID].id
    }));
  };

  const formatDocType = values => values.map(value => ({ label: value, value }));

  const valueFormat = {
    cai_category: formatCategoryID,
    category: formatDocType,
    doc_type: formatDocType
  };

  const formatValues = ({ attribute, values }) => {
    if (valueFormat[attribute]) values = valueFormat[attribute](values);
    return values;
  };

  const in_clause = ({ values, attribute, notClause = false }) => {
    values = formatValues({ attribute, values });
    const inClause = {
      all: [{ var: attribute }, { in: [{ var: '' }, values] }]
    };

    return notClause ? { '!': inClause } : inClause;
  };

  //action functions
  const make_query = (input, start, end, elements) => {
    return elements[0];
  };

  const make_not_group = (input, start, end, elements) => {
    return {
      '!': elements[2]
    };
  };

  const make_and_group = (input, start, end, elements) => {
    const andArray = [elements[2]];
    elements[3].elements.forEach(element => andArray.push(element.group_or_clause));
    return {
      and: andArray
    };
  };

  const make_or_group = (input, start, end, elements) => {
    const orArray = [elements[2]];
    elements[3].elements.forEach(element => orArray.push(element.group_or_clause));
    return {
      or: orArray
    };
  };

  const make_compare_clause = (input, start, end, elements) => {
    let comparator = elements[2].text;
    const attribute = elements[0].toLowerCase();
    if (comparator === '=') comparator = '==';

    if (inClauseAttributes[attribute]) {
      return in_clause({
        values: [elements[4]],
        attribute,
        notClause: comparator === '!='
      });
    }

    let value = elements[4];

    if (relativeDateAttributes[attribute]) {
      if (isSingleRelativeDate(value)) {
        comparator = RELATIVE_DATE_COMPARATOR[value.toLowerCase()];
      } else if (isPastNextRelativeDate(value)) {
        if (comparator === '<=') {
          comparator = RELATIVE_DATE_COMPARATOR[REATIVE_DATE_OPERATORS.PAST];
        } else if (comparator === '>=') {
          comparator = RELATIVE_DATE_COMPARATOR[REATIVE_DATE_OPERATORS.NEXT];
        }
      }
    }

    if (booleanAttributes[attribute]) value = formatBooleanValue(value);

    return {
      [comparator]: [{ var: attribute }, value]
    };
  };

  const make_in_clause = (input, start, end, elements) => {
    const values = [elements[6]];
    elements[7].elements.forEach(element => values.push(element.value));

    return in_clause({
      values,
      attribute: elements[0].toLowerCase()
    });
  };

  const make_word = (input, start, end, elements) => {
    return input.slice(start, end);
  };

  const make_exact = (input, start, end, elements) => {
    return input.slice(start + 1, end - 1);
  };

  return {
    make_query,
    make_not_group,
    make_and_group,
    make_or_group,
    make_compare_clause,
    make_in_clause,
    make_word,
    make_exact
  };
};

export const parseQueryToJsonLogic = (query, queryState) => {
  try {
    return advancedSearchParser.parse(`(${query})`, { actions: getActions(queryState) });
  } catch {
    return { and: [] };
  }
};
