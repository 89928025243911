import PropTypes from 'prop-types';

import * as uiLib from '@compliance.ai/web-components';
import * as filterUtils from 'utils/filter/filter';
import * as analyticUtils from 'utils/analytics';
import * as constants from '../../TimelineToolbar.contants';
import * as viewConstants from 'shared/features/view/view.constants';

import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const TEST_ID = 'timeline-toolbar-sources-tab';

const ToolbarSourcesTab = ({
  parsedQuery,
  newsCategoryIds,
  languageId,
  displaySource,
  followedAgenciesIds,
  followedNewsSourcesIds
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const displayNews = categoryId => {
    analyticUtils.safe_analytics('default', 'News', 'Button Click', 'All Sources');

    if (categoryId === newsCategoryIds[constants.DISPLAY_SOURCES.AGENCY_NEWS]) {
      filterUtils.explicit_filter_function(
        { newsSourcesView: true },
        location,
        navigate,
        {
          cai_category: categoryId,
          view: viewConstants.VIEW_KEY.NEWS,
          agency: followedAgenciesIds,
          language_id: languageId
        },
        {}
      );
    } else if (categoryId === newsCategoryIds[constants.DISPLAY_SOURCES.MAINSTREAM_NEWS]) {
      filterUtils.explicit_filter_function(
        { newsSourcesView: true },
        location,
        navigate,
        {
          cai_category: categoryId,
          view: viewConstants.VIEW_KEY.NEWS,
          language_id: languageId,
          news_source_id: followedNewsSourcesIds
        },
        {}
      );
    } else {
      filterUtils.explicit_filter_function(
        { newsSourcesView: true },
        location,
        navigate,
        {
          cai_category: categoryId,
          view: viewConstants.VIEW_KEY.NEWS,
          language_id: languageId,
          agency: followedAgenciesIds,
          news_source_id: followedNewsSourcesIds
        },
        {}
      );
    }
  };

  const handleSourceSelect = tabIndex => {
    if (
      constants.DISPLAY_SOURCES_TABS[tabIndex].key === constants.DISPLAY_SOURCES.ALL_NEWS_SOURCES
    ) {
      displayNews([
        newsCategoryIds[constants.DISPLAY_SOURCES.AGENCY_NEWS],
        newsCategoryIds[constants.DISPLAY_SOURCES.MAINSTREAM_NEWS]
      ]);
    }
    if (constants.DISPLAY_SOURCES_TABS[tabIndex].key === constants.DISPLAY_SOURCES.AGENCY_NEWS) {
      displayNews(newsCategoryIds[constants.DISPLAY_SOURCES.AGENCY_NEWS]);
    }
    if (
      constants.DISPLAY_SOURCES_TABS[tabIndex].key === constants.DISPLAY_SOURCES.MAINSTREAM_NEWS
    ) {
      displayNews(newsCategoryIds[constants.DISPLAY_SOURCES.MAINSTREAM_NEWS]);
    }
    setActiveTabIndex(tabIndex);
  };

  return parsedQuery.view === viewConstants.VIEW_KEY.NEWS ? (
    <uiLib.Tabs
      activeTabIndex={activeTabIndex}
      onSelect={handleSourceSelect}
      tabsBarClassName="tabs-container"
      tabClassName="sources-tab"
      tabs={constants.DISPLAY_SOURCES_TABS}
    />
  ) : null;
};

ToolbarSourcesTab.propTypes = {
  parsedQuery: PropTypes.shape({
    view: PropTypes.string
  }).isRequired,
  newsCategoryIds: PropTypes.object.isRequired,
  displaySource: PropTypes.string.isRequired,
  followedAgenciesIds: PropTypes.array.isRequired
};

export default ToolbarSourcesTab;
