import * as uiLib from '@compliance.ai/web-components';
import * as components from 'components/Support';
import * as routes from 'constants/Routes';
import * as common from 'common';

import { Link } from 'react-router-dom';

import { useTopBarMenuData, useTopBarMenuHandlers } from './hooks';

import './_top-bar-menu.scss';

export const TopBarMenu = () => {
  const { formattedData } = useTopBarMenuData();

  const handlers = useTopBarMenuHandlers();

  return (
    <>
      {formattedData.shouldRenderActions && (
        <div className="top-bar-menu__action-icons">
          <components.Support className="top-bar-menu__action-icon" />
          <common.AccountMenu className="top-bar-menu__action-icon" />
        </div>
      )}
      {formattedData.shouldRenderLogin && (
        <div className="top-bar-menu__auth-buttons">
          <Link
            className="top-bar-menu__auth-button top-bar-menu__auth-button--is-sign-in"
            to={routes.login}
          >
            Login
          </Link>
          <uiLib.Button
            className="top-bar-menu__auth-button top-bar-menu__auth-button--is-sign-up"
            onClick={handlers.handleSignUpClick}
          >
            Create an Account
          </uiLib.Button>
        </div>
      )}
    </>
  );
};
