import type { AutosuggestionSelectData } from './useAutosuggestionSelectData.types';

import { useMemo, useState } from 'react';

export const useAutosuggestionSelectData = (): AutosuggestionSelectData => {
  const [value, setValue] = useState<AutosuggestionSelectData['localState']['value']>([]);
  const [isLoading, setIsLoading] = useState<AutosuggestionSelectData['localState']['isLoading']>(
    false
  );

  const localState: AutosuggestionSelectData['localState'] = {
    value,
    isLoading
  };

  const localActions: AutosuggestionSelectData['localActions'] = useMemo(
    () => ({
      setValue,
      setIsLoading
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
