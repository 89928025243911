import type { ImplementationDateProps } from './ImplementationDate.types';

import type { FC } from 'react';
import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';
import { useImplementationDateData } from './hooks';
import { useResourceDocumentCheck } from 'utils/hooks';

export const ImplementationDate: FC<ImplementationDateProps> = ({
  document,
  shouldRenderForEmail
}) => {
  const isResourceDocument = useResourceDocumentCheck(document);

  const { formattedData } = useImplementationDateData({ document });

  if (isResourceDocument || shouldRenderForEmail || !formattedData.implementationDate?.length) {
    return null;
  }

  return (
    <>
      <FieldName>Implementation Date</FieldName>
      <FieldValue>{formattedData.implementationDate}</FieldValue>
    </>
  );
};
