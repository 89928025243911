import type { PlainTextIdsToNamesMap } from './PlainText.types';

import { ADVANCED_SEARCH_KEYS } from 'constants/AdvancedSearch';

import * as actsApi from 'shared/features/acts/acts.api';
import * as banksApi from 'shared/features/banks/banks.api';
import * as agenciesSelectors from 'shared/features/agencies/agencies.selectors';
import * as authorsSelectors from 'shared/features/authors/authors.selectors';
import * as sourcesSelectors from 'shared/features/sources/sources.selectors';
import * as regulationsSelectors from 'shared/features/regulations/regulations.selectors';
import * as documentsSelectors from 'shared/features/documents/documents.selectors';
import * as organizationSelectors from 'shared/features/organizations/organizations.selectors';
import * as languagesSelectors from 'shared/features/languages/languages.selectors';
import * as eitlLabelsApi from 'shared/features/eitlLabels/eitlLabels.api';

import { COMPLEX_QUERY_KEY_GETTERS, QUERY_KEYS, QUERY_SECTIONS } from 'constants/QueryKeys';

export const ADVANCED_SEARCH_KEY_FIELD = 'var';
export const DEFAULT_VALUE = 'Unknown';

export const DATA_CONFIG = {
  [ADVANCED_SEARCH_KEYS.AGENCY_ID]: {
    onSelectFromRedux: agenciesSelectors.getAgenciesIdsToShortNamesMap
  },
  [ADVANCED_SEARCH_KEYS.AUTHOR_ID]: {
    onSelectFromRedux: authorsSelectors.getAuthorsIdsToNamesMap
  },
  [ADVANCED_SEARCH_KEYS.NEWS_SOURCE_ID]: {
    onSelectFromRedux: sourcesSelectors.getDefaultMainstreamNewsSourcesIdsToNamesMap
  },
  [ADVANCED_SEARCH_KEYS.TOPIC_ID]: {
    onSelectFromRedux: sourcesSelectors.getActiveTopicsIdsToNamesMap
  },
  [ADVANCED_SEARCH_KEYS.TOPIC]: {
    onSelectFromRedux: sourcesSelectors.getActiveTopicsIdsToNamesMap
  },
  [ADVANCED_SEARCH_KEYS.REGULATION_ID]: {
    onSelectFromRedux: regulationsSelectors.getRegulationsIdsToNamesMap
  },
  [ADVANCED_SEARCH_KEYS.CAI_CATEGORY]: {
    onSelectFromRedux: documentsSelectors.getCategoriesIdsToNamesMap
  },
  [ADVANCED_SEARCH_KEYS.LABEL]: {
    onSelectFromRedux: organizationSelectors.getOrganizationLabelsIdsToNamesMap
  },
  [ADVANCED_SEARCH_KEYS.LANGUAGE_ID]: {
    onSelectFromRedux: languagesSelectors.getLanguagesIdsToNamesMap
  }
};

export const ASYNC_DATA_CONFIG = {
  [ADVANCED_SEARCH_KEYS.EITL_LABEL_ID]: {
    onFetch: eitlLabelsApi.getEitlLabelsByIds,
    onSelect: (data: unknown): PlainTextIdsToNamesMap => {
      return (data as Awaited<
        ReturnType<typeof eitlLabelsApi.getEitlLabelsByIds>
      >).eitl_labels.reduce((map, label) => {
        return {
          ...map,
          [label.id]: label.name
        };
      }, {});
    },
    queryKeyGetter:
      COMPLEX_QUERY_KEY_GETTERS[QUERY_KEYS.EITL_LABELS][
        QUERY_SECTIONS.ADVANCED_SEARCH_PLAIN_TEXT_MODAL
      ]
  },
  [ADVANCED_SEARCH_KEYS.ACT_ID]: {
    onFetch: actsApi.fetchActs,
    onSelect: (data: unknown): PlainTextIdsToNamesMap => {
      return (data as Awaited<ReturnType<typeof actsApi.fetchActs>>).acts.reduce((map, act) => {
        return {
          ...map,
          [act.id]: act.name
        };
      }, {});
    },
    queryKeyGetter:
      COMPLEX_QUERY_KEY_GETTERS[QUERY_KEYS.ACTS][QUERY_SECTIONS.ADVANCED_SEARCH_PLAIN_TEXT_MODAL]
  },
  [ADVANCED_SEARCH_KEYS.BANK_ID]: {
    onFetch: banksApi.fetchBanks,
    onSelect: (data: unknown): PlainTextIdsToNamesMap => {
      return (data as Awaited<ReturnType<typeof banksApi.fetchBanks>>).banks.reduce((map, bank) => {
        return {
          ...map,
          [bank.id]: bank.name
        };
      }, {});
    },
    queryKeyGetter:
      COMPLEX_QUERY_KEY_GETTERS[QUERY_KEYS.BANKS][QUERY_SECTIONS.ADVANCED_SEARCH_PLAIN_TEXT_MODAL]
  },
  [ADVANCED_SEARCH_KEYS.BANK]: {
    onFetch: banksApi.fetchBanks,
    onSelect: (data: unknown): PlainTextIdsToNamesMap => {
      return (data as Awaited<ReturnType<typeof banksApi.fetchBanks>>).banks.reduce((map, bank) => {
        return {
          ...map,
          [bank.id]: bank.name
        };
      }, {});
    },
    queryKeyGetter:
      COMPLEX_QUERY_KEY_GETTERS[QUERY_KEYS.BANKS][QUERY_SECTIONS.ADVANCED_SEARCH_PLAIN_TEXT_MODAL]
  }
};
