import type * as types from './TransformStage.types';

import lodash from 'lodash';

export class TransformStage implements types.TransformStage {
  readonly multiFieldsMap: types.TransformStage['multiFieldsMap'];

  constructor(args: { multiFieldsMap?: types.TransformStage['multiFieldsMap'] }) {
    this.multiFieldsMap = args.multiFieldsMap ?? {};
  }

  readonly run: types.TransformStage['run'] = async args => {
    return args.metadata.flatMap(([metadataKey, metadataValue]) => {
      const isMulti = Boolean(this.multiFieldsMap[metadataKey]);

      if (isMulti) {
        const listValue = Array.isArray(metadataValue)
          ? metadataValue
          : [metadataValue].filter(lodash.isNil);

        if (!listValue.length) {
          return [];
        }

        return [[metadataKey, listValue]];
      }

      const singleValue = Array.isArray(metadataValue) ? metadataValue[0] : metadataValue;

      if (!singleValue) {
        return [];
      }

      return [[metadataKey, singleValue]];
    });
  };
}
