import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateAlert as reduxUpdateAlert } from 'shared/features/alerts/alerts.actions';
import {
  removeViewAlert as reduxRemoveViewAlert,
  removeEditAlert as reduxRemoveEditAlert,
  openAdvancedSearchFilter as reduxOpenAdvancedSearchFilter,
  closeAdvancedSearchFilter as reduxCloseAdvancedSearchFilter,
  showPreviewAlert as reduxShowPreviewAlert,
  hidePreviewAlert as reduxHidePreviewAlert,
  showSaveAlertSuccessModal as reduxShowSaveAlertSuccessModal,
  closeFilter as reduxCloseFilter
} from 'shared/features/view/view.actions';
import { fetchBanks as reduxFetchBanks } from 'shared/features/banks/banks.actions';
import { fetchActs as reduxFetchActs } from 'shared/features/acts/acts.actions';
import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';
import { fetchDefaults as reduxFetchDefaults } from 'shared/features/defaults/defaults.actions';

export const useAdvancedSearchBuilderReduxActions = () => {
  const dispatch = useDispatch();
  const rightPanelReduxActions = useRightPanelReduxActions();

  const updateAlert = useCallback(
    (alertId, alertData) => {
      return dispatch(reduxUpdateAlert(alertId, alertData));
    },
    [dispatch]
  );

  const removeViewAlert = useCallback(() => {
    return dispatch(reduxRemoveViewAlert());
  }, [dispatch]);

  const removeEditAlert = useCallback(() => {
    return dispatch(reduxRemoveEditAlert());
  }, [dispatch]);

  const openAdvancedSearchFilter = useCallback(() => {
    return dispatch(reduxOpenAdvancedSearchFilter());
  }, [dispatch]);

  const closeAdvancedSearchFilter = useCallback(() => {
    return dispatch(reduxCloseAdvancedSearchFilter());
  }, [dispatch]);

  const closeFilter = useCallback(() => {
    return dispatch(reduxCloseFilter());
  }, [dispatch]);

  const showPreviewAlert = useCallback(() => {
    return dispatch(reduxShowPreviewAlert());
  }, [dispatch]);

  const hidePreviewAlert = useCallback(() => {
    return dispatch(reduxHidePreviewAlert());
  }, [dispatch]);

  const showSaveAlertSuccessModal = useCallback(
    alertName => {
      return dispatch(reduxShowSaveAlertSuccessModal('modify', alertName));
    },
    [dispatch]
  );

  const fetchBanks = useCallback(
    bankIds => {
      return dispatch(reduxFetchBanks(bankIds));
    },
    [dispatch]
  );

  const fetchActs = useCallback(
    actIds => {
      return dispatch(reduxFetchActs(actIds));
    },
    [dispatch]
  );

  const fetchDefaults = useCallback(
    ({ defaultsOwner, teamId, orgId }) => {
      return dispatch(reduxFetchDefaults({ defaultsOwner, teamId, orgId }));
    },
    [dispatch]
  );

  return {
    updateAlert,
    removeViewAlert,
    removeEditAlert,
    openAdvancedSearchFilter,
    closeAdvancedSearchFilter,
    closeFilter,
    showPreviewAlert,
    hidePreviewAlert,
    showSaveAlertSuccessModal,
    fetchBanks,
    fetchActs,
    fetchDefaults,
    closeOverlay: rightPanelReduxActions.closeOverlay
  };
};
