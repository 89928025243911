import type { FC } from 'react';
import type { DocumentDetailsContentProps } from './DocumentDetailsContent.types';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';
import * as constants from './DocumentDetailsContent.constants';

import { useDocumentDetailsContentData } from './hooks';

import './_document-details-content.scss';

export const DocumentDetailsContent: FC<DocumentDetailsContentProps> = ({
  document,
  isFetchingDocument
}) => {
  const { reduxState } = useDocumentDetailsContentData();

  if (!reduxState.documentDetailsActiveTabKey) {
    return null;
  }

  const Component = constants.CONTENT_COMPONENTS[reduxState.documentDetailsActiveTabKey];

  return (
    <uiLib.ErrorBoundary>
      {isFetchingDocument ? (
        <div className="document-details-content__loader-container">
          <uiLib.Loader
            containerClassName="document-details-content__loader"
            size={uiLib.LOADER_SIZES.MEDIUM}
            isContrast
          />
        </div>
      ) : (
        <>
          {document && <Component document={document} />}
          {!document && <elements.DocumentNotFound />}
        </>
      )}
    </uiLib.ErrorBoundary>
  );
};
