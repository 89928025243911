import * as errorUtils from 'utils/errors';
import * as helpers from './labels.helpers';
import * as api from './labels.api';
import * as orgActions from '../organizations/organizations.actions';

import { StatusCodes } from 'http-status-codes';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

export const FETCH_ALL_LABELS = 'FETCH_ALL_LABELS';
export const FETCH_LABEL = 'FETCH_LABEL';
export const CREATE_LABEL = 'CREATE_LABEL';
export const DELETE_LABEL = 'DELETE_LABEL';
export const DELETE_MULTIPLE_LABELS = 'DELETE_MULTIPLE_LABELS';
export const MODIFY_LABEL = 'MODIFY_LABEL';
export const FETCH_LABEL_DETAILS = 'FETCH_LABEL_DETAILS';
export const REMOVE_LABEL_FROM_DOCUMENTS = 'REMOVE_LABEL_FROM_DOCUMENTS';
export const TRIGGER_LABELS_DETAILS_REFRESH = 'TRIGGER_LABELS_DETAILS_REFRESH';
export const IMPORT_LABELS = 'IMPORT_LABELS';
export const ADD_LABELS = 'ADD_LABELS';

export const triggerLabelsDetailsRefresh = createAction(TRIGGER_LABELS_DETAILS_REFRESH);

export const fetchAllLabels = createAsyncThunk(FETCH_ALL_LABELS, async params => {
  try {
    const response = await api.getAllLabels(params);
    return helpers.normalizeLabelsResponse(response);
  } catch (e) {
    errorUtils.logError(e, 'Error fetching all labels');
    throw e;
  }
});

export const fetchLabel = createAsyncThunk(FETCH_LABEL, async id => {
  try {
    const response = await api.getLabel(id);

    return helpers.normalizeLabel(response);
  } catch (e) {
    errorUtils.logError(e, 'Error fetching label');
    throw e;
  }
});

export const deleteLabel = createAsyncThunk(DELETE_LABEL, async id => {
  try {
    return await api.deleteLabel(id);
  } catch (e) {
    errorUtils.logError(e, 'Error deleting label');
    throw e;
  }
});

export const deleteMultipleLabels = createAsyncThunk(DELETE_MULTIPLE_LABELS, async ids => {
  try {
    return await Promise.all(ids.map(id => api.deleteLabel(id)));
  } catch (e) {
    errorUtils.logError(e, 'Error deleting labels');
    throw e;
  }
});

export const modifyLabel = createAsyncThunk(MODIFY_LABEL, async ({ id, params }) => {
  try {
    return await api.modifyLabel(id, params);
  } catch (e) {
    errorUtils.logError(e, 'Error modifying label');
    throw e;
  }
});

export const fetchLabelDetails = createAsyncThunk(FETCH_LABEL_DETAILS, async ({ id, params }) => {
  try {
    const response = await api.getLabelDetails(id, params);
    return helpers.normalizeLabelDetailsResponse(response);
  } catch (e) {
    errorUtils.logError(e, 'Error fetching label details');
    throw e;
  }
});

export const createLabel = createAsyncThunk(
  CREATE_LABEL,
  async (
    { bucketId = null, labelName, labelDescription, labelType, labelExternalId },
    { dispatch }
  ) => {
    try {
      const response = await api.createLabel({
        bucket_id: bucketId,
        label_name: labelName,
        description: labelDescription,
        type: labelType,
        external_id: labelExternalId
      });

      dispatch(orgActions.receiveOrganizationLabels(response.organization_labels));
    } catch (e) {
      errorUtils.logError(e, 'Error creating label');
    }
  }
);

export const removeLabelsFromDocuments = createAsyncThunk(
  REMOVE_LABEL_FROM_DOCUMENTS,
  async ({ id, params }) => {
    try {
      return await api.removeLabelFromDocuments(id, params);
    } catch (e) {
      errorUtils.logError(e, 'Error removing labels from documents');
      throw e;
    }
  }
);

export const importLabels = createAsyncThunk(IMPORT_LABELS, async ({ bucketId, file }) => {
  try {
    const formData = new FormData();

    formData.append('file', file);

    const response = await api.importLabels(bucketId, formData, {
      shouldHideErrorNotifications: true
    });

    return helpers.normalizeImportResponse(response);
  } catch (e) {
    if (e.status > StatusCodes.BAD_REQUEST) {
      errorUtils.logError(e);
    }

    throw e;
  }
});

export const addLabels = createAsyncThunk(ADD_LABELS, async labels => {
  try {
    await api.addLabels({
      labels
    });
  } catch (e) {
    errorUtils.logError(e, 'Error adding labels');

    throw e;
  }
});
