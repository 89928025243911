import type { LabelsSelectProps } from '../LabelsSelect.types';
import type { LabelsSelectData } from './useLabelsSelectData.types';
import type * as labelsTypes from 'shared/features/labels/labels.types';

import { COMPLEX_QUERY_KEY_GETTERS, QUERY_KEYS, QUERY_SECTIONS } from 'constants/QueryKeys';

import * as labelsConstants from 'shared/features/labels/labels.constants';
import * as labelsHelpers from 'shared/features/labels/labels.helpers';
import * as errorUtils from 'utils/errors';

import { useQuery } from '@tanstack/react-query';
import { useOrganizationLabelsReduxActions } from 'shared/features/labels/hooks';

export const useLabelsSelectQueries = ({
  props,
  localActions,
  formattedData
}: {
  props: Pick<LabelsSelectProps, 'value' | 'isEditable'>;
  localActions: LabelsSelectData['localActions'];
  formattedData: LabelsSelectData['formattedData'];
}) => {
  const reduxActions = useOrganizationLabelsReduxActions();

  const fetchPropsValueQuery = useQuery<
    Awaited<ReturnType<typeof reduxActions.fetchAllLabels>>,
    Error,
    labelsTypes.LabelOption[]
  >({
    queryKey: COMPLEX_QUERY_KEY_GETTERS[QUERY_KEYS.LABELS][QUERY_SECTIONS.LABELS_SELECT]({
      labelIds: formattedData.labelIds
    }),
    queryFn: async () => {
      if (!formattedData.labelIds.length) {
        localActions.setValue([]);
        return [];
      }

      const { labels } = await reduxActions.fetchAllLabels({
        id: formattedData.labelIds,
        count_per_page: labelsConstants.MAX_LABELS_COUNT,
        is_editable: props.isEditable ? true : false
      });

      const options = labels.map(labelsHelpers.formatLabelOption);

      localActions.setValue(options);

      return options;
    },
    enabled: Boolean(props.value),
    onError: errorUtils.logReactQueryError,
    refetchOnWindowFocus: false
  });

  return {
    fetchPropsValueQuery
  };
};
