import type { FC } from 'react';
import type { LabelsCellProps } from './LabelsCell.types';

import * as emptyValue from 'constants/EmptyValue';

export const LabelsCell: FC<LabelsCellProps> = ({ cellValue }) => {
  if (!cellValue?.length) {
    return <>{emptyValue.EMPTY_VALUE.DASH}</>;
  }

  return <>{cellValue.map(label => label.name).join(', ')}</>;
};
