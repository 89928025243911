import { Component } from 'react';
import { connect } from 'react-redux';
import { fetchExpertConnectionData } from 'shared/features/expertConnection/expertConnection.actions';
import { safe_mixpanel_track } from 'utils/analytics';

export class ExpertConnectionPanel extends Component {
  componentDidMount() {
    const { experts, areasOfExpertise } = this.props.expertConnection;
    if (!experts || !experts.length || !areasOfExpertise || areasOfExpertise.length) {
      this.props.fetchExpertConnectionData();
    }
    if (this.props.docTitle) {
      safe_mixpanel_track('Doc Click', {
        eventCategory: ' Doc Details',
        eventAction: 'Document with Expert Connection Click',
        hitType: 'Event',
        eventLabel: this.props.docTitle
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.docId !== prevProps.docId) {
      safe_mixpanel_track('Doc Click', {
        eventCategory: ' Doc Details',
        eventAction: 'Document with Expert Connection Click',
        hitType: 'Event',
        eventLabel: this.props.docTitle
      });
    }
  }

  renderExperts = () => {
    return this.props.expertConnection.experts.map(expert => {
      const { id, name, email, schedule_url, url } = expert;
      if (!this.props.expertNames.includes(name)) return null;
      return (
        <li key={id} className="ec-item">
          <h4>{name}</h4>
          {email && (
            <p>
              <a href={`mailto:${email}`} rel="noopener noreferrer" target="_blank">
                Contact Now
              </a>
            </p>
          )}
          {schedule_url && (
            <p>
              <a href={schedule_url} target="_blank" rel="noopener noreferrer">
                Schedule Now
              </a>
            </p>
          )}
          {url && (
            <p>
              <a href={url} target="_blank" rel="noopener noreferrer">
                View Site
              </a>
            </p>
          )}
        </li>
      );
    });
  };

  render() {
    if (!this.props.expertConnection.experts.length) return null;
    return (
      <div className="expert-connection-panel" data-testid="expert-connection-panel-container">
        <div className="ec-info">
          <p>Looking for expert input on this document? Connect to an expert below.</p>
        </div>
        <ul className="ec-list">{this.renderExperts()}</ul>
      </div>
    );
  }
}

const mapStateToProps = ({ expertConnection }) => {
  return { expertConnection };
};

export default connect(mapStateToProps, { fetchExpertConnectionData })(ExpertConnectionPanel);
