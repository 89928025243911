import lodash from 'lodash';

export const getCountryDisplayNames = reduxJurisdictions => {
  return [
    ...new Set(
      reduxJurisdictions.items
        .filter(jurisdiction => isCountryJurisdiction(jurisdiction))
        .map(country => country.country_display_name)
    )
  ];
};

export const getCountries = reduxJurisdictions => {
  return lodash.orderBy(
    reduxJurisdictions.items.filter(isCountryJurisdiction),
    jurisdiction => jurisdiction.name
  );
};

export const getSubregions = reduxJurisdictions => {
  return lodash.orderBy(
    reduxJurisdictions.items.filter(isNotCountryJurisdiction),
    jurisdiction => jurisdiction.name
  );
};

export const getSubregionsMappedByCountries = reduxJurisdictions => {
  const subregions = getSubregions(reduxJurisdictions);

  return subregions.reduce((subregionsMap, subregion) => {
    if (Array.isArray(subregionsMap[subregion.country_display_name])) {
      return {
        ...subregionsMap,
        [subregion.country_display_name]: [
          ...subregionsMap[subregion.country_display_name],
          subregion
        ]
      };
    }

    return {
      ...subregionsMap,
      [subregion.country_display_name]: [subregion]
    };
  }, {});
};

export const toOption = jurisdiction => ({
  value: jurisdiction.id,
  label: jurisdiction.name,
  countryDisplayName: jurisdiction.country_display_name,
  countryFullName: jurisdiction.name
});

export const sortJurisdictionsByPrimaryFirst = (jurisdictions, primaryJurisdiction) => {
  return [
    ...jurisdictions.sort(jurisdiction => {
      if (jurisdiction.value === primaryJurisdiction?.value) {
        return -1;
      }

      return 1;
    })
  ];
};

export const isCountryJurisdiction = jurisdiction => {
  return lodash.isNull(jurisdiction.parent_id);
};

export const isNotCountryJurisdiction = jurisdiction => !isCountryJurisdiction(jurisdiction);
