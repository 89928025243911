import { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { fetchDocumentSavedFoldersCount as reduxFetchDocumentSavedFoldersCount } from 'shared/features/folders/folders.actions';
import { setDocumentSavedFolderCount as reduxSetDocumentSavedFolderCount } from 'shared/features/documents/documents.actions';
import { logError } from 'utils/errors';

export const useFolderReduxActions = ({ isAuthenticated }) => {
  const dispatch = useDispatch();

  const fetchAndSaveDocumentSavedFoldersCount = useCallback(
    async document => {
      if (!isAuthenticated || !document) {
        return Promise.resolve();
      }
      try {
        const { count } = await dispatch(
          reduxFetchDocumentSavedFoldersCount({ documentId: document.id })
        ).then(unwrapResult);
        await dispatch(reduxSetDocumentSavedFolderCount(document.id, count));
      } catch (e) {
        logError(e, 'Error fetching document saved folders count');
        throw e;
      }
    },
    [dispatch, isAuthenticated]
  );

  return useMemo(
    () => ({
      fetchAndSaveDocumentSavedFoldersCount
    }),
    [fetchAndSaveDocumentSavedFoldersCount]
  );
};
