import type {
  FiltersProps,
  FilterValue,
  DatePickerWithModifiersProps,
  TextFieldWithModifiersProps,
  SelectOption
} from '@compliance.ai/web-components';
import type { AutocompleteResponse } from 'shared/features/search/search.types';
import type { DocumentsFiltersHandlers } from './useDocumentsFiltersHandlers.types';
import type { ReactNode } from 'react';

import * as uiLib from '@compliance.ai/web-components';

import {
  CUSTOM_FILTER_OPTIONS_KEYS,
  FILTER_KEY,
  SUPPORTED_QUERY_PARAMS
} from 'constants/PrimaryFilter';
import {
  SUGGESTION_TO_FILTER_OPTIONS_CONFIG,
  TEXT_MODIFIERS_TO_QUERY_PARAMS
} from '../DocumentsFilters.constants';
import {
  RELATIVE_DATE_UNITS_OF_TIME_TO_QUERY_PARAMS,
  SINGLE_RELATIVE_DATES
} from 'constants/RelativeDates';

export const formatDateValue = (value?: Date | null): string | null => {
  return value ? uiLib.formatDate(value, { format: uiLib.DATE_FORMATS.AMERICAN_DATE }) : null;
};

export const formatDateWithModifiers = ({
  startDateKey,
  endDateKey,
  dateKey
}: {
  startDateKey: string;
  endDateKey: string;
  dateKey: string;
}) => (filterValue: FilterValue): Record<string, unknown> => {
  const value = filterValue as DatePickerWithModifiersProps['value'];

  switch (value?.modifier) {
    case uiLib.DATE_MODIFIERS.BETWEEN: {
      return {
        [startDateKey]: formatDateValue(value?.dateA),
        [endDateKey]: formatDateValue(value?.dateB)
      };
    }

    case uiLib.DATE_MODIFIERS.ON: {
      return {
        [dateKey]: formatDateValue(value?.dateA)
      };
    }

    case uiLib.DATE_MODIFIERS.ON_OR_BEFORE: {
      return {
        [endDateKey]: formatDateValue(value?.dateA)
      };
    }

    case uiLib.DATE_MODIFIERS.ON_OR_AFTER: {
      return {
        [startDateKey]: formatDateValue(value?.dateA)
      };
    }

    case uiLib.DATE_MODIFIERS.TODAY: {
      return {
        [dateKey]: SINGLE_RELATIVE_DATES.TODAY
      };
    }

    case uiLib.DATE_MODIFIERS.YESTERDAY: {
      return {
        [dateKey]: SINGLE_RELATIVE_DATES.YESTERDAY
      };
    }

    case uiLib.DATE_MODIFIERS.TOMORROW: {
      return {
        [dateKey]: SINGLE_RELATIVE_DATES.TOMORROW
      };
    }

    case uiLib.DATE_MODIFIERS.IN_THE_LAST: {
      const count = value?.relativeUnitsOfTimeCount;
      const units =
        RELATIVE_DATE_UNITS_OF_TIME_TO_QUERY_PARAMS[
          value?.relativeUnitOfTime as uiLib.RELATIVE_DATE_UNITS_OF_TIME
        ];

      return {
        [dateKey]: `-${count}:${units}`
      };
    }

    default: {
      return {};
    }
  }
};

export const formatDateWithModifiersWithCheckboxKey = ({
  startDateKey,
  endDateKey,
  dateKey,
  secondaryCheckboxKey,
  secondaryCheckboxReversed = false
}: {
  startDateKey: string;
  endDateKey: string;
  dateKey: string;
  secondaryCheckboxKey?: string;
  secondaryCheckboxReversed?: boolean;
}) => (filetValue: FilterValue): Record<string, unknown> => {
  const value = filetValue as DatePickerWithModifiersProps['value'];

  const formattedModifiers = formatDateWithModifiers({ startDateKey, endDateKey, dateKey })(value);

  if (secondaryCheckboxKey) {
    if (value?.secondaryChecboxIsChecked && secondaryCheckboxReversed) {
      formattedModifiers[secondaryCheckboxKey] = false;
    } else if (!value?.secondaryChecboxIsChecked && !secondaryCheckboxReversed) {
      formattedModifiers[secondaryCheckboxKey] = true;
    }
  }

  return formattedModifiers;
};

export const formatTextWithModifiers = ({
  textKey,
  textModifierKey
}: {
  textKey: string;
  textModifierKey: string;
}) => (filterValue: FilterValue): Record<string, unknown> => {
  const value = filterValue as TextFieldWithModifiersProps['value'];

  if (value?.value && value?.modifier) {
    return {
      [textKey]: value.value,
      [textModifierKey]: TEXT_MODIFIERS_TO_QUERY_PARAMS[value.modifier as uiLib.TEXT_MODIFIERS]
    };
  }

  return {};
};

export const formatDate = ({ key }: { key: string }) => (filterValue: FilterValue) => {
  const value = filterValue as Date;

  if (key) {
    return {
      [key]: formatDateValue(value)
    };
  }

  return {};
};

export const formatText = ({ key }: { key: string }) => (filterValue: FilterValue) => {
  const value = filterValue as string;

  if (value) {
    return {
      [key]: value
    };
  }

  return {};
};

export const formatSingleSelectValue = ({ key }: { key: string }) => (filterValue: FilterValue) => {
  const value = filterValue as SelectOption;

  if (value?.value) {
    return {
      [key]: value?.value
    };
  }

  return {};
};

export const formatMultiSelectValues = ({ key }: { key: string }) => (filterValue: FilterValue) => {
  const values = filterValue as SelectOption[];

  if (Array.isArray(values) && values.length) {
    return {
      [key]: values.map(({ value }) => value)
    };
  }

  return {};
};

export const formatEitlLabels = ({
  eitlLabelsKey,
  hasEitlLabelsKey
}: {
  eitlLabelsKey: string;
  hasEitlLabelsKey: string;
}) => (filterValue: FilterValue) => {
  const values = filterValue as SelectOption[];

  if (Array.isArray(values) && values.length) {
    const eitlLabelsIds = values.map(({ value }) => value);
    const filteredEitlLabelsIds = eitlLabelsIds.filter(value => value !== null);
    const isNoLabelsOptionSelected = eitlLabelsIds.some(labelId => labelId === null);

    const eitlLabelsFilter =
      filteredEitlLabelsIds.length > 0 ? { [eitlLabelsKey]: filteredEitlLabelsIds } : {};

    const hasEitlLabelsFilter = isNoLabelsOptionSelected ? { [hasEitlLabelsKey]: false } : {};

    return { ...eitlLabelsFilter, ...hasEitlLabelsFilter };
  }

  return {};
};

export const formatDocProperties = (filterValue: FilterValue) => {
  const value = filterValue as SelectOption;

  if (typeof value?.value === 'string') {
    const key = value.value.split('=')[0];
    const val = value.value.split('=')[1];

    if (key && val) {
      return {
        [key]: val
      };
    }
  }

  return {};
};

export const formatQuery = ({ key }: { key: string }) => (
  values: FilterValue,
  searchValue: string
) => {
  if (searchValue) {
    return {
      [key]: searchValue
    };
  }

  return {};
};

export const DOCUMENT_FILTER_VALUES_PARSERS: Record<
  string,
  (value: FilterValue) => Record<string, unknown>
> = {
  [FILTER_KEY.PUB_DATE]: formatDateWithModifiersWithCheckboxKey({
    startDateKey: SUPPORTED_QUERY_PARAMS.PUBLISHED_FROM,
    endDateKey: SUPPORTED_QUERY_PARAMS.PUBLISHED_TO,
    dateKey: SUPPORTED_QUERY_PARAMS.PUBLICATION_DATE,
    secondaryCheckboxKey: SUPPORTED_QUERY_PARAMS.HAS_UNOFFICIAL_PUBLICATION_DATE,
    secondaryCheckboxReversed: true
  }),
  [FILTER_KEY.EFFECTIVE_DATE]: formatDateWithModifiers({
    startDateKey: SUPPORTED_QUERY_PARAMS.EFFECTIVE_FROM,
    endDateKey: SUPPORTED_QUERY_PARAMS.EFFECTIVE_TO,
    dateKey: SUPPORTED_QUERY_PARAMS.EFFECTIVE_DATE
  }),
  [FILTER_KEY.COMMENTS_CLOSE_DATE]: formatDateWithModifiers({
    startDateKey: SUPPORTED_QUERY_PARAMS.COMMENTS_CLOSE_FROM,
    endDateKey: SUPPORTED_QUERY_PARAMS.COMMENTS_CLOSE_TO,
    dateKey: SUPPORTED_QUERY_PARAMS.COMMENTS_CLOSE_DATE
  }),
  [FILTER_KEY.ADDED_DATE]: formatDateWithModifiers({
    startDateKey: SUPPORTED_QUERY_PARAMS.ADDED_FROM,
    endDateKey: SUPPORTED_QUERY_PARAMS.ADDED_TO,
    dateKey: SUPPORTED_QUERY_PARAMS.ADDED_DATE
  }),
  [FILTER_KEY.UPDATED_DATE]: formatDateWithModifiers({
    startDateKey: SUPPORTED_QUERY_PARAMS.UPDATED_FROM,
    endDateKey: SUPPORTED_QUERY_PARAMS.UPDATED_TO,
    dateKey: SUPPORTED_QUERY_PARAMS.UPDATED_DATE
  }),
  [FILTER_KEY.TEXT]: formatTextWithModifiers({
    textKey: SUPPORTED_QUERY_PARAMS.TEXT,
    textModifierKey: SUPPORTED_QUERY_PARAMS.TEXT_MATCH_TYPE
  }),
  [FILTER_KEY.BANKS]: formatMultiSelectValues({
    key: SUPPORTED_QUERY_PARAMS.BANK_ID
  }),
  [FILTER_KEY.AGENCIES]: formatMultiSelectValues({
    key: SUPPORTED_QUERY_PARAMS.AGENCY_ID
  }),
  [FILTER_KEY.DOC_TYPES]: formatMultiSelectValues({
    key: SUPPORTED_QUERY_PARAMS.CAI_CATEGORY
  }),
  [FILTER_KEY.TOPICS]: formatMultiSelectValues({
    key: SUPPORTED_QUERY_PARAMS.TOPIC_ID
  }),
  [FILTER_KEY.ACTS]: formatMultiSelectValues({
    key: SUPPORTED_QUERY_PARAMS.ACT_ID
  }),
  [FILTER_KEY.JURISDICTIONS]: formatMultiSelectValues({
    key: SUPPORTED_QUERY_PARAMS.JURISDICTION
  }),
  [FILTER_KEY.MAINSTREAM_NEWS_SOURCES]: formatMultiSelectValues({
    key: SUPPORTED_QUERY_PARAMS.NEWS_SOURCE_ID
  }),
  [FILTER_KEY.TITLE]: formatTextWithModifiers({
    textKey: SUPPORTED_QUERY_PARAMS.TITLE,
    textModifierKey: SUPPORTED_QUERY_PARAMS.TITLE_MATCH_TYPE
  }),
  [FILTER_KEY.DOCUMENT_ID]: formatText({
    key: SUPPORTED_QUERY_PARAMS.DOC_ID
  }),
  [FILTER_KEY.CITATION]: formatText({
    key: SUPPORTED_QUERY_PARAMS.CITATION
  }),
  [FILTER_KEY.LANGUAGE]: formatSingleSelectValue({
    key: SUPPORTED_QUERY_PARAMS.LANGUAGE_ID
  }),
  [FILTER_KEY.CONCEPTS]: formatMultiSelectValues({
    key: SUPPORTED_QUERY_PARAMS.CONCEPT_ID
  }),
  [FILTER_KEY.REGULATIONS]: formatMultiSelectValues({
    key: SUPPORTED_QUERY_PARAMS.REGULATION_ID
  }),
  [FILTER_KEY.DOC_PROPERTIES]: formatDocProperties,
  [FILTER_KEY.LABELS]: formatMultiSelectValues({
    key: SUPPORTED_QUERY_PARAMS.LABEL
  }),
  [FILTER_KEY.AUTHOR]: formatMultiSelectValues({
    key: SUPPORTED_QUERY_PARAMS.AUTHOR_ID
  }),
  [FILTER_KEY.EITL_LABELS]: formatEitlLabels({
    eitlLabelsKey: SUPPORTED_QUERY_PARAMS.EITL_LABEL_ID,
    hasEitlLabelsKey: SUPPORTED_QUERY_PARAMS.HAS_EITL_LABELS
  })
};

export const ADDITIONAL_DOCUMENT_FILTER_VALUES_PARSERS: Record<
  string,
  (value: FilterValue, searchValue: string) => Record<string, unknown>
> = {
  [CUSTOM_FILTER_OPTIONS_KEYS.QUERY]: formatQuery({
    key: SUPPORTED_QUERY_PARAMS.QUERY
  })
};

export const formatDocumentsQueryParams = (
  values: FiltersProps['values'],
  searchValue: string
): Record<string, unknown> => {
  return Object.entries(values).reduce((formattedQueryParams, [filterKey, filterValue]) => {
    return {
      ...formattedQueryParams,
      ...DOCUMENT_FILTER_VALUES_PARSERS[filterKey](filterValue),
      ...ADDITIONAL_DOCUMENT_FILTER_VALUES_PARSERS[CUSTOM_FILTER_OPTIONS_KEYS.QUERY](
        filterValue,
        searchValue as string
      )
    };
  }, {});
};

export const formatFilterSearchOptions = (
  response: AutocompleteResponse
): Awaited<ReturnType<DocumentsFiltersHandlers['handleSuggestionsFetch']>> => {
  return response.results.flatMap(result => {
    const updatedResult = {
      ...result,
      short_name: Array.isArray(result.short_name) ? result.short_name[0] : result.short_name
    };

    const filterOptionsConfig = SUGGESTION_TO_FILTER_OPTIONS_CONFIG[result._type];

    if (!filterOptionsConfig) return [];

    return [
      {
        value: updatedResult[filterOptionsConfig.optionValueKey],
        label: `${
          filterOptionsConfig.optionLabelKey !== filterOptionsConfig.filterOptionLabelKey
            ? `${updatedResult[filterOptionsConfig.filterOptionLabelKey]} (${
                updatedResult[filterOptionsConfig.optionLabelKey]
              })`
            : `${updatedResult[filterOptionsConfig.optionLabelKey]}`
        }`,
        filterOptionLabel: updatedResult[filterOptionsConfig.filterOptionLabelKey] as ReactNode,
        filterKey: filterOptionsConfig.filterKey,
        optionType: filterOptionsConfig.optionType
      }
    ];
  });
};
