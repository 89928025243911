import { useLocation, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

/**
 *  DO NOT USE THIS HOOK!
 *
 *  This hook is being used only for backwards compatibility after migration to react-router v6.
 *
 */
export const useHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return useMemo(
    () => ({
      location: location,
      push: (to, state) => navigate(to, { state }),
      replace: (to, state) => navigate(to, { state, replace: true }),
      back: () => navigate(-1)
    }),
    [location, navigate]
  );
};
