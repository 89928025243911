import type { GlobalState } from 'shared/reducers';
import type { CreateLabelModalProps } from '../CreateLabelModal.types';
import type { CreateLabelModalData } from './useCreateLabelModalData.types';

import * as orgSelectors from 'shared/features/organizations/organizations.selectors';
import * as bucketsConstants from 'shared/features/buckets/buckets.constants';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const useCreateLabelModalData = ({
  props
}: {
  props: Pick<CreateLabelModalProps, 'initialLabelName'>;
}): CreateLabelModalData => {
  const reduxState: CreateLabelModalData['reduxState'] = useSelector<
    GlobalState,
    CreateLabelModalData['reduxState']
  >(state => ({
    isPublicFolderDisabled: orgSelectors.getOrganizationPublicFolderRestriction(state)
  }));

  const [labelName, setLabelName] = useState<CreateLabelModalData['localState']['labelName']>(
    props.initialLabelName
  );
  const [labelDescription, setLabelDescription] = useState<
    CreateLabelModalData['localState']['labelDescription']
  >('');
  const [labelExternalId, setLabelExternalId] = useState<
    CreateLabelModalData['localState']['labelExternalId']
  >('');
  const [bucket, setBucket] = useState<CreateLabelModalData['localState']['bucket']>(
    reduxState.isPublicFolderDisabled ? null : bucketsConstants.DEFAULT_BUCKET_OPTION
  );
  const [labelType, setLabelType] = useState<CreateLabelModalData['localState']['labelType']>(null);

  const localState: CreateLabelModalData['localState'] = {
    labelName,
    bucket,
    labelDescription,
    labelType,
    labelExternalId
  };

  const localActions: CreateLabelModalData['localActions'] = useMemo(
    () => ({
      setLabelName,
      setBucket,
      setLabelDescription,
      setLabelType,
      setLabelExternalId
    }),
    []
  );

  const formattedData: CreateLabelModalData['formattedData'] = useMemo(
    () => ({
      isCreateLabelButtonDisabled: !Boolean(localState.labelName) || localState.bucket === null,
      bucketId: localState.bucket?.value ?? null,
      labelTypeId: localState.labelType?.value ?? null
    }),
    [localState.labelName, localState.bucket, localState.labelType]
  );

  return {
    localState,
    localActions,
    formattedData,
    reduxState
  };
};
