import { apiFetchConcepts } from './concepts.api';

const ACTION_TYPES_PREFIX = 'concepts';

export const FETCH_CONCEPTS_BEGIN = `${ACTION_TYPES_PREFIX}/FETCH_CONCEPTS_BEGIN`;
export const FETCH_CONCEPTS_SUCCESS = `${ACTION_TYPES_PREFIX}/FETCH_CONCEPTS_SUCCESS`;
export const FETCH_CONCEPTS_ERROR = `${ACTION_TYPES_PREFIX}/FETCH_CONCEPTS_ERROR`;

export const fetchConceptsBegin = () => ({
  type: FETCH_CONCEPTS_BEGIN
});

export const fetchConceptsSuccess = (concepts, metaConcepts) => ({
  type: FETCH_CONCEPTS_SUCCESS,
  data: { metaConcepts, concepts }
});

export const fetchConceptsError = () => ({
  type: FETCH_CONCEPTS_ERROR
});

export const fetchConcepts = () => dispatch => {
  dispatch(fetchConceptsBegin());
  return apiFetchConcepts()
    .then(res => {
      dispatch(fetchConceptsSuccess(res.concept_dic, res.metaconcept_dic));
      return res;
    })
    .catch(error => {
      dispatch(fetchConceptsError());
      return error;
    });
};
