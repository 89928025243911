import type { AddRelatedDocumentAlertsModalProps } from '../AddRelatedDocumentAlertsModal.types';
import type { AddRelatedDocumentAlertsModalData } from './useAddRelatedDocumentAlertsModalData.types';
import type { MouseEvent } from 'react';

import * as documentsApi from 'shared/features/documents/documents.api';
import * as relatedDocsConstants from 'constants/RelatedDocuments';
import * as queryKeys from 'constants/QueryKeys';
import * as errorUtils from 'utils/errors';

import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useAddRelatedDocumentAlertsModalMutations = ({
  props,
  localState
}: {
  props: Pick<AddRelatedDocumentAlertsModalProps, 'documentId' | 'onClose'>;
  localState: AddRelatedDocumentAlertsModalData['localState'];
}) => {
  const queryClient = useQueryClient();

  const addAlerts = useMutation<void, Error, MouseEvent<HTMLButtonElement>>({
    mutationKey: [],
    mutationFn: () =>
      documentsApi.api_addOrganizationRelatedDocumentAlerts(props.documentId, {
        [relatedDocsConstants.RELATED_DOCUMENT_REQUEST_KEYS.RELATED_DOC_ALERT_IDS]:
          localState.alertIds
      }),
    onSettled: props.onClose,
    onError: errorUtils.logReactQueryError,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.RELATED_ALERTS][
          queryKeys.QUERY_SECTIONS.DOCUMENT_DETAILS
        ](Number(props.documentId))
      });
    }
  });

  return {
    addAlerts
  };
};
