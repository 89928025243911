import { useMemo, useCallback } from 'react';
import queryString from 'utils/query-string';
import {
  RIGHT_PANEL_QUERY_PARAMS,
  OVERLAY_TYPES,
  OVERLAY_QUERY_PARAMS
} from 'shared/features/rightPanel/rightPanel.constants';
import { DOCUMENT_SUMMARY_PAGE_KEY } from 'constants/DocumentConstants';
import * as routes from 'constants/Routes';
import { checkPDFAvailability } from 'utils/documents/documentsAccess';

export const useDocumentContextMenuOverlayHandlers = ({ props, localState, localActions }) => {
  const handleContextMenu = useCallback(
    event => {
      if (!props.contextMenuDisabled && checkPDFAvailability(props.document)) {
        event.preventDefault();

        localActions.setAnchorPosition(
          localState.anchorPosition === undefined
            ? {
                top: event.clientY,
                left: event.clientX
              }
            : undefined
        );
      }
    },
    [props, localState, localActions]
  );

  const handleClose = useCallback(() => {
    localActions.setAnchorPosition(undefined);
  }, [localActions]);

  const handleOpenDocument = useCallback(
    (openInNewWindow = false) => () => {
      const stringifiedQueryParams = queryString.stringify({
        [RIGHT_PANEL_QUERY_PARAMS.CLOSE_RIGHT_PANEL_ON_OVERLAY_CLOSE]: true,
        [RIGHT_PANEL_QUERY_PARAMS.SUMMARY_PAGE]: DOCUMENT_SUMMARY_PAGE_KEY,
        [RIGHT_PANEL_QUERY_PARAMS.SUMMARY_ID]: props.document.id,
        [OVERLAY_QUERY_PARAMS.OVERLAY_TYPE]: OVERLAY_TYPES.PDF
      });

      const url = `${routes.dashboard}?${stringifiedQueryParams}`;

      if (openInNewWindow) {
        window.open(url, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,fullscreen=yes');
      } else {
        window.open(url);
      }

      handleClose();
    },
    [props, handleClose]
  );

  return useMemo(
    () => ({
      handleContextMenu,
      handleClose,
      handleOpenDocument
    }),
    [handleContextMenu, handleClose, handleOpenDocument]
  );
};
