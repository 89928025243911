import type {
  NormalizedWorkflow,
  NormalizedWorkflowsResponse,
  WorkflowOption
} from 'shared/features/workflow/workflow.types';
import type { WorkflowsGroupOption } from './useWorkflowsSelectHandlers.types';

import lodash from 'lodash';
import {
  ALL_WORKFLOW_OPTION,
  DELETED_WORKFLOW_PREFIX,
  WORKFLOW_GROUP_LABELS,
  WORKFLOW_OPTIONS_SORT_ORDER
} from '../WorkflowsSelect.constants';

export const formatWorkflowOption = (workflow: NormalizedWorkflow): WorkflowOption => {
  return {
    value: workflow.id,
    label: workflow.name,
    types: workflow.types
  };
};

export const formatWorkflow = ({ isDeleted }: { isDeleted: boolean }) => (
  workflow: NormalizedWorkflow
): NormalizedWorkflow => {
  return {
    ...workflow,
    name: isDeleted ? `${DELETED_WORKFLOW_PREFIX} ${workflow.name}` : workflow.name
  };
};

export const isDocumentAndObligationWorkflow = (workflow: NormalizedWorkflow) =>
  workflow.documentWorkflow && workflow.obligationWorkflow;

export const isDocumentWorkflow = (workflow: NormalizedWorkflow) =>
  workflow.documentWorkflow && !workflow.obligationWorkflow;

export const isObligationWorkflow = (workflow: NormalizedWorkflow) =>
  !workflow.documentWorkflow && workflow.obligationWorkflow;

export const formatWorkflowOptions = ({
  workflowsResponse,
  deletedWorkflowsResponse,
  showAllWorkflowsOption
}: {
  workflowsResponse: NormalizedWorkflowsResponse;
  deletedWorkflowsResponse: NormalizedWorkflowsResponse;
  showAllWorkflowsOption?: boolean;
}): WorkflowsGroupOption[] => {
  const sortedWorkflows = lodash.orderBy(
    workflowsResponse.results,
    workflow => workflow.name.toLowerCase(),
    [WORKFLOW_OPTIONS_SORT_ORDER]
  );
  const sortedDeletedWorkflows = lodash.orderBy(
    deletedWorkflowsResponse.results,
    workflow => workflow.name.toLowerCase(),
    [WORKFLOW_OPTIONS_SORT_ORDER]
  );

  const formattedWorkflows = [
    ...sortedWorkflows.map(formatWorkflow({ isDeleted: false })),
    ...sortedDeletedWorkflows.map(formatWorkflow({ isDeleted: true }))
  ];

  const groupedWorkflowsOptions: WorkflowsGroupOption[] = [
    {
      label: WORKFLOW_GROUP_LABELS.DOCUMENT_OBLIGATION_WORKFLOW,
      options: formattedWorkflows.filter(isDocumentAndObligationWorkflow).map(formatWorkflowOption)
    },
    {
      label: WORKFLOW_GROUP_LABELS.DOCUMENT_WORKFLOW,
      options: formattedWorkflows.filter(isDocumentWorkflow).map(formatWorkflowOption)
    },
    {
      label: WORKFLOW_GROUP_LABELS.OBLIGATION_WORKFLOW,
      options: formattedWorkflows.filter(isObligationWorkflow).map(formatWorkflowOption)
    }
  ];

  if (showAllWorkflowsOption) {
    groupedWorkflowsOptions.unshift(ALL_WORKFLOW_OPTION);
  }

  return groupedWorkflowsOptions;
};
