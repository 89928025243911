import ReactHtmlParser, { processNodes } from 'react-html-parser';
import lodash from 'lodash';
import { EDIT_ANNOTATIONS, EDIT_OBLIGATIONS } from 'constants/Permissions';
import { DOCUMENT_DETAILS_TAB_KEY } from 'shared/features/rightPanel/rightPanel.constants';

import './_obligation-workspace.scss';

/**
 *
 * Highlights text
 *
 * @param node {Object} - ReactHtmlParser node
 * @param startIndex {Number} - Selection left offset
 * @param endIndex {Number} - Selection right offset
 * @return {Array} - ReactHtmlParser parsed string with highlighted text
 */
export const renderHighlightedText = (node, startIndex, endIndex) => {
  const string =
    node.data.slice(0, startIndex) +
    `<mark id="highlight" class="obligation-workspace__highlighted-text active">${node.data.slice(
      startIndex,
      endIndex
    )}</mark>` +
    node.data.slice(endIndex);

  return ReactHtmlParser(string);
};

/**
 *
 * Parses document HTML, applying necessary logic for sentence highlighting.
 *
 * @param node {Object} - ReactHtmlParser node
 * @param textSelection {Object} - ObligationWorkspace text selection state
 * @param onMouseDown {Function} - Mouse down handler
 * @param onMouseUp {Function} - Mouse up handler
 * @return {JSX.Element|Array|undefined|*} - Parsed HTML
 */
export const transformDocumentHTML = ({ node, textSelection, onMouseDown, onMouseUp }) => {
  // Wrap a sentence into div to capture clicks and apply classname.
  if (node.type === 'tag' && node.name === 'div' && node.attribs.id) {
    const id = parseInt(node.attribs.id, 10);
    const elementId = `sentence-${id}`;

    return (
      <div
        key={elementId}
        id={elementId}
        className="sentence"
        onMouseDown={() => onMouseDown(id)}
        onMouseUp={() => onMouseUp(id)}
      >
        {processNodes(node.children, node =>
          transformDocumentHTML({ node, textSelection, onMouseDown, onMouseUp })
        )}
      </div>
    );
  }

  /**
   * Highlight text
   */
  if (node.type === 'text') {
    const parentId = parseInt(node.parent.attribs.id, 10);

    /**
     * Swap first and last sentences if user selected text starting from the bottom
     */
    const firstSentenceId =
      textSelection.firstSentenceId > textSelection.lastSentenceId
        ? textSelection.lastSentenceId
        : textSelection.firstSentenceId;
    const lastSentenceId =
      textSelection.firstSentenceId > textSelection.lastSentenceId
        ? textSelection.firstSentenceId
        : textSelection.lastSentenceId;

    const startIndex =
      textSelection.firstSentenceId > textSelection.lastSentenceId
        ? textSelection.endIndex
        : textSelection.startIndex;
    let endIndex =
      textSelection.firstSentenceId > textSelection.lastSentenceId
        ? textSelection.startIndex
        : textSelection.endIndex;

    /**
     * In the case where the start and end index are the same we want to highlight the full node text
     */
    if (endIndex === startIndex) {
      endIndex = node.data.length;
    }

    /**
     * If for some reason we were not able to determine the end of selection, do not highlight text
     */
    if (lodash.isNil(endIndex)) {
      return node.data;
    }

    /**
     * If multiple sentences were selected, highlight the in-between sentences
     */
    if (firstSentenceId && lastSentenceId && firstSentenceId !== lastSentenceId) {
      const sentencesInBetweenIds = lodash.range(firstSentenceId + 1, lastSentenceId);

      if (sentencesInBetweenIds.includes(parentId)) {
        const sentenceInBetweenFirstIndex = 0;
        const sentenceInBetweenLastIndex = node.data.length;

        return renderHighlightedText(node, sentenceInBetweenFirstIndex, sentenceInBetweenLastIndex);
      }
    }

    /**
     * If only one sentence was selected
     */
    if (parentId === firstSentenceId && parentId === lastSentenceId && !lodash.isNil(endIndex)) {
      /**
       * Swap indexes if text was selected starting from the bottom
       */
      if (startIndex > endIndex) {
        return renderHighlightedText(node, endIndex, startIndex);
      } else {
        return renderHighlightedText(node, startIndex, endIndex);
      }
    }

    /**
     * If multiple sentences were selected, highlight the first sentence till the end of the text
     */
    if (parentId === firstSentenceId && !lodash.isNil(endIndex)) {
      const firstSentenceLastIndex = node.data.length;

      return renderHighlightedText(node, startIndex, firstSentenceLastIndex);
    }

    /**
     * If multiple sentences were selected, highlight the last sentence from the start of the text
     */
    if (parentId === lastSentenceId && !lodash.isNil(endIndex)) {
      const lastSentenceFirstIndex = 0;

      return renderHighlightedText(node, lastSentenceFirstIndex, endIndex);
    }

    return node.data;
  }

  return undefined;
};

export const isTextSelectionAllowed = ({ documentDetailsActiveTabKey, permissions }) => {
  if (
    documentDetailsActiveTabKey === DOCUMENT_DETAILS_TAB_KEY.OBLIGATIONS &&
    !permissions[EDIT_OBLIGATIONS]
  ) {
    return false;
  }

  if (
    documentDetailsActiveTabKey === DOCUMENT_DETAILS_TAB_KEY.ANNOTATIONS &&
    !permissions[EDIT_ANNOTATIONS]
  ) {
    return false;
  }

  return true;
};
