import type { NormalizedBucket } from 'shared/features/buckets/buckets.actions.types';

export const isBucketShared = ({ permissions }: Pick<NormalizedBucket, 'permissions'>) =>
  !permissions ||
  Boolean(permissions.groupPermissionWriteAccess.length) ||
  Boolean(permissions.groupReadAccess.length) ||
  Boolean(permissions.groupWriteAccess.length) ||
  Boolean(permissions.roleReadAccess.length) ||
  Boolean(permissions.roleWriteAccess.length) ||
  Boolean(permissions.userPermissionWriteAccess.length) ||
  Boolean(permissions.userReadAccess.length) ||
  Boolean(permissions.userWriteAccess.length);
