import { DEFAULT_ERROR_MESSAGE } from './auth.constants';
import lodash from 'lodash';

export const getErrorMessageFromResponse = error => {
  if (lodash.isString(error)) {
    return error;
  }

  if (lodash.isObject(error) && lodash.isString(error.message)) {
    return error.message;
  }

  if (error instanceof Error) {
    return error.message;
  }

  return DEFAULT_ERROR_MESSAGE;
};
