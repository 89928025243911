import type { AddToWorkflowHandlers } from './useAddToWorkflowHandlers.types';
import type { AddToWorkflowData } from './useAddToWorkflowData.types';
import type { useAddToWorkflowQueries } from './useAddToWorkflowQueries';

import { useViewReduxActions } from 'shared/features/view/hooks';

export const useAddToWorkflowHandlers = ({
  localActions,
  queries
}: {
  localActions: AddToWorkflowData['localActions'];
  queries: ReturnType<typeof useAddToWorkflowQueries>;
}): AddToWorkflowHandlers => {
  const viewReduxActions = useViewReduxActions();

  const handleClick: AddToWorkflowHandlers['handleClick'] = () => {
    queries.fetchDocuments.refetch();
  };

  const handleModalClose: AddToWorkflowHandlers['handleModalClose'] = () => {
    localActions.setIsModalOpen(false);
  };

  const handleRestrictedClick: AddToWorkflowHandlers['handleRestrictedClick'] = () => {
    viewReduxActions.showRestrictModal(null, 'Add to Workflow');
  };

  return {
    handleClick,
    handleModalClose,
    handleRestrictedClick
  };
};
