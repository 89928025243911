import type { ComponentProps } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as workflowSummaryCommonCells from "../CommonCells";

export enum TABLE_FIELDS {
  ID = 'id',
  STEP = 'step',
  NAME = 'name',
  COMMENTS = 'comments'
}

export const TABLE_COLUMNS: Required<ComponentProps<typeof uiLib.Table>>['columns'] = [
  {
    title: 'Step',
    dataKey: TABLE_FIELDS.STEP,
    width: '4rem',
    Component: uiLib.TextCell
  },
  {
    title: 'Name',
    dataKey: TABLE_FIELDS.NAME,
    width: '15rem',
    Component: uiLib.TextCell
  },
  {
    title: 'Comments',
    dataKey: TABLE_FIELDS.COMMENTS,
    Component: workflowSummaryCommonCells.CommentsCell
  }
];
