import { LabeledField } from '../index';
import { getPasswordScore } from 'utils/password-utils';
import PropTypes from 'prop-types';
import './_password-strength.scss';

const STRENGTH_CLASSNAMES = {
  0: 'password-strength--is-undef',
  1: 'password-strength--is-weak',
  2: 'password-strength--is-fair',
  3: 'password-strength--is-strong'
};

const STRENGTH_LABELS = {
  0: 'Unset',
  1: 'Weak',
  2: 'Fair',
  3: 'Strong'
};

export const PasswordStrength = ({ password, className }) => {
  const passwordScore = getPasswordScore(password);

  return (
    <>
      <LabeledField
        label={STRENGTH_LABELS[passwordScore]}
        isLabelUnderElement
        isRightAligned={passwordScore === 3}
        className={className}
      >
        <div className="password-strength__wrapper">
          <div className={STRENGTH_CLASSNAMES[passwordScore]} />
        </div>
      </LabeledField>
    </>
  );
};

PasswordStrength.propTypes = {
  password: PropTypes.string.isRequired,
  className: PropTypes.string
};
