import moment from 'moment';
import {
  isSingleRelativeDate,
  isPastNextRelativeDate,
  isPastRelativeDate,
  formatSingleRelativeDate,
  formatPastNextRelativeDate
} from 'utils/relativeDate-utils';
import { LocalDateFormat } from 'utils/keyDates';
import { REATIVE_DATE_OPERATOR_LABELS, REATIVE_DATE_OPERATORS } from 'constants/RelativeDates';
import {
  DEFAULT_PAST_RELATIVE_DATE_AMOUNT,
  DEFAULT_PAST_RELATIVE_DATE_DURATION
} from './CustomDatePicker.constants';

/**
 *
 * Parses the operator from date values and returns the corresponding operator
 * @param {String|Object} date - date value to be formatted (e.g: moment("06/01/2021"), "Today", "Tomorrow", "Yesterday", "Tomorrow", "1:Weeks")
 * @param {Object} startDate - startDate value to be formatted (e.g: moment("06/01/2021"))
 * @param {Object} endDate - endDate value to be formatted (e.g: moment("06/01/2021"))
 * @return {String} - "On", "Between", "In the past", "After", "Before", "Today", "Yesterday", "Tomorrow"
 *
 */
export const parseOperatorFromDates = (date = null, startDate = null, endDate = null) => {
  if (date) {
    if (isSingleRelativeDate(date)) {
      return formatSingleRelativeDate(date);
    }
    if (isPastNextRelativeDate(date)) {
      return isPastRelativeDate(date)
        ? REATIVE_DATE_OPERATOR_LABELS.PAST
        : REATIVE_DATE_OPERATOR_LABELS.NEXT;
    }
    return REATIVE_DATE_OPERATOR_LABELS.ON;
  } else if (startDate && endDate) {
    return REATIVE_DATE_OPERATOR_LABELS.BETWEEN;
  } else if (startDate) {
    return REATIVE_DATE_OPERATOR_LABELS.ON_OR_AFTER;
  } else if (endDate) {
    return REATIVE_DATE_OPERATOR_LABELS.ON_OR_BEFORE;
  }

  return null;
};

/**
 *
 * Parses the date value and returns valid formatted date string
 * @param {String} date - date value to be formatted
 * @return {Object} - formatted moment object
 *
 */
export const parseDateFromString = date => {
  const localDate = moment(date, LocalDateFormat, true);

  if (localDate.isValid()) {
    return localDate;
  }

  const shortFormat = LocalDateFormat === 'MM/DD/YYYY' ? 'M/D/YYYY' : 'D/M/YYYY';
  const shortDate = moment(date, shortFormat, true);
  if (shortDate.isValid()) {
    return shortDate;
  }

  return null;
};

/**
 *
 * Parses the past relative dates and returns the amount number and durations
 * @param {String} date - date value to be parsed (e.g: "2:Ddays", "3:Weeks", "5:Months", "6:Years")
 * @return {Object} - Dictionary with { amount, duration } (e.g: { amount: 2, duration: "Days" })
 *
 */
export const parsePastNextRelativeDate = date => {
  if (isPastNextRelativeDate(date)) {
    const [amount, duration] = formatPastNextRelativeDate(date).split(
      REATIVE_DATE_OPERATORS.PAST_NEXT_SEPERATOR
    );

    return {
      amount: Math.abs(parseInt(amount, 10)),
      duration
    };
  }

  return {
    amount: DEFAULT_PAST_RELATIVE_DATE_AMOUNT,
    duration: DEFAULT_PAST_RELATIVE_DATE_DURATION
  };
};
