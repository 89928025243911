export const mapToConceptsGroupOption = conceptsList => ({ name, concepts: conceptIds }) => {
  return {
    label: name,
    options: conceptIds
      .map(conceptId => {
        const concept = conceptsList.find(({ id }) => id === conceptId);

        if (!concept) {
          return null;
        }

        return {
          label: concept.name,
          value: concept.id,
          advancedSearch: concept.advanced_search
        };
      })
      .filter(Boolean)
      .sort((a, b) => a.label.localeCompare(b.label))
  };
};

export const getSortedConceptIds = (conceptIds = [], conceptsList = []) => {
  return conceptsList.filter(({ id }) => conceptIds.includes(id)).map(({ id }) => id);
};
