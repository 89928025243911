import type { PenaltyData } from './usePenaltyData.types';
import type { GlobalState } from 'shared/reducers';
import type { PenaltyProps } from '../Penalty.types';

import { useSelector } from 'react-redux';
import { getDocumentCategoryName } from 'shared/features/documents/documents.selectors';

export const usePenaltyData = ({ document }: Pick<PenaltyProps, 'document'>): PenaltyData => {
  const reduxState = useSelector<GlobalState, PenaltyData['reduxState']>(state => ({
    // TODO Uncomment when the selectors file will be moved to TS
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    categoryName: getDocumentCategoryName(state, document.cai_category_id)
  }));

  return {
    reduxState
  };
};
