import { fetch } from 'utils/api';

export function api_getObligationHistory(params) {
  return fetch({
    url: '/obligation-history',
    dataType: 'Obligations',
    method: 'GET',
    params
  });
}

export function api_getObligationTasks(doc_id) {
  return fetch({
    url: `/obligation_tasks/${doc_id}`,
    dataType: 'Obligations',
    method: 'GET'
  });
}

export function api_editObligationTasks(id, params) {
  return fetch({
    url: `/obligation_tasks/${id}`,
    dataType: 'Obligations',
    method: 'POST',
    params
  });
}

export function api_getUserObligationsForDocumentId(docId) {
  return fetch({
    url: `/user_obligations/${docId}`,
    dataType: 'Obligations',
    method: 'GET'
  });
}

export function api_createOrUpdateUserObligation(params) {
  return fetch({
    url: '/user_obligations',
    dataType: 'Obligations',
    method: 'POST',
    params
  });
}

export function api_addObligationTaskAttachment(taskId, params) {
  return fetch({
    url: `/obligation_tasks/${taskId}/attachments`,
    dataType: 'Attachment',
    method: 'POST',
    params
  });
}

export function api_deleteObligationTaskAttachment(fileName) {
  return fetch({
    url: `/obligation_task_attachment/${fileName}`,
    dataType: 'Attachment',
    method: 'DELETE'
  });
}

export function api_getObligationHighThreshold() {
  return fetch({
    url: '/obligation_high_threshold',
    dataType: 'Obligations',
    method: 'GET'
  });
}
