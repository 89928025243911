const getRootComment = comments => {
  return comments.find(comment => comment.isRootComment);
};

const getReplies = comments => {
  return comments.filter(comment => !comment.isRootComment);
};

export default {
  getRootComment,
  getReplies
};
