import type { GlobalState } from 'shared/reducers';
import type { LegacyDocTypesSelectData } from './useLegacyDocTypesSelectData.types';
import type { LegacyDocTypesSelectProps } from '../LegacyDocTypesSelect.types';

import { useSelector } from 'react-redux';
import { getGroupedLegacyDocTypesOptions } from 'shared/features/documents/documents.selectors';
import { useMemo } from 'react';

export const useLegacyDocTypesSelectData = ({
  value
}: Pick<LegacyDocTypesSelectProps, 'value'>): LegacyDocTypesSelectData => {
  const reduxState = useSelector<GlobalState, LegacyDocTypesSelectData['reduxState']>(state => ({
    options: getGroupedLegacyDocTypesOptions(state as never)
  }));

  const formattedData = useMemo(
    () => ({
      value: reduxState.options
        .flatMap(({ options }) => options)
        .filter(o => value?.includes(o.value))
    }),
    [reduxState.options, value]
  );

  return {
    reduxState,
    formattedData
  };
};
