import type { AuthorsSelectProps } from '../AuthorsSelect.types';
import type { GlobalState } from 'shared/reducers';
import type { AuthorsSelectData } from './useAuthorsSelectData.types';

import * as authorSelectors from 'shared/features/authors/authors.selectors';
import * as selectUtils from 'utils/select-utils';
import * as constants from '../AuthorsSelect.constants';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useAuthorsSelectData = ({
  isMulti,
  value,
  valueKey
}: Pick<AuthorsSelectProps, 'value' | 'isMulti' | 'valueKey'>): AuthorsSelectData => {
  const reduxState = useSelector<GlobalState, AuthorsSelectData['reduxState']>(state => ({
    options: authorSelectors.getAuthorsOptionsByKey(
      state as never,
      valueKey ?? constants.AUTHORS_SELECT_VALUE_KEYS.ID
    ),
    areAuthorsReady: authorSelectors.getAuthorsReadyState(state)
  }));

  const formattedData: AuthorsSelectData['formattedData'] = useMemo(
    () => ({
      value: selectUtils.getValuesFromOptions({
        isMulti,
        options: reduxState.options,
        value
      })
    }),
    [isMulti, reduxState.options, value]
  );

  return {
    reduxState,
    formattedData
  };
};
