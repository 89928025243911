import PropTypes from 'prop-types';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Dropdown, Button, BUTTON_TYPES } from '@compliance.ai/web-components';

const DashboardOptionsDropdown = ({ onChange, selectionOptionValues, allOptions }) => (
  <Dropdown
    className="dashboard-component-dropdown"
    containerClassName="dashboard-component-dropdown"
    triggerContent={
      <Button className="component-dropdown-button" type={BUTTON_TYPES.LINK}>
        Customize <ArrowDropDownIcon />
      </Button>
    }
    dropdownContent={
      <ul className="options">
        {allOptions.map(({ value, label }) => (
          <li className="option" key={value} onClick={() => onChange(value)}>
            {selectionOptionValues.includes(value) ? (
              <CheckBoxIcon className="option-checkbox checked" />
            ) : (
              <CheckBoxOutlineBlankIcon className="option-checkbox" />
            )}
            {label}
          </li>
        ))}
      </ul>
    }
  />
);

export default DashboardOptionsDropdown;

DashboardOptionsDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectionOptionValues: PropTypes.array.isRequired,
  allOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any
    })
  ).isRequired
};
