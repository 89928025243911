import styled from 'styled-components';

import PropTypes from 'prop-types';

const StyledLinkAction = styled.span`
  color: #2c6ddf;
  height: 38px;
  line-height: 38px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

function LinkAction({ onClick, content, ...props }) {
  return (
    <StyledLinkAction onClick={onClick} {...props}>
      {content}
    </StyledLinkAction>
  );
}

LinkAction.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onClick: PropTypes.func.isRequired
};

export default LinkAction;
