import type { GlobalState } from 'shared/reducers';
import type { ApproverCellData } from './useApproverCellData.types';
import type { ApproverCellProps } from '../ApproverCell.types';

import * as orgSelectors from 'shared/features/organizations/organizations.selectors';
import * as helpers from './useApproverCellData.helpers';

import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const useApproverCellData = ({
  cellValue
}: Pick<ApproverCellProps, 'cellValue'>): ApproverCellData => {
  const reduxState = useSelector<GlobalState, ApproverCellData['reduxState']>(state => ({
    orgMembers: orgSelectors.getOrganizationMembers(state)
  }));

  const formattedData = useMemo(() => {
    return {
      approver: helpers.formatApprover({
        documentTask: cellValue,
        orgMembers: reduxState.orgMembers
      })
    };
  }, [cellValue, reduxState.orgMembers]);

  return {
    reduxState,
    formattedData
  };
};
