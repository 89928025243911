export const MY_TASKS = 'My tasks';
export const MY_ORG_TASKS = "My organization's tasks";
export const HAS_SENTENCES = 'Diffing available';
export const HAS_ANNOTATIONS = 'Annotations available';
export const OBLIGATIONS = 'Obligations';
export const HAS_EITL_LABELS = 'EITL Labels available';
export const IS_PREMIUM_CONTENT = 'Premium content';
export const IS_ORGANIZATION_DOCUMENT = 'Organization Document';
export const EXCLUDE_FEDERAL_REGISTER = 'Exclude Federal Register';

export const TASK_DOC_PROPERTIES = {
  myTasks: { label: MY_TASKS, value: 'task_owner=current_user' },
  myOrgTasks: { label: MY_ORG_TASKS, value: 'task_owner=organization' }
};

export const STANDARD_DOC_PROPERTIES = {
  hasSentences: { label: HAS_SENTENCES, value: 'has_sentences=true' },
  hasAnnotations: { label: HAS_ANNOTATIONS, value: 'has_annotations=true' },
  hasObligations: { label: OBLIGATIONS, value: 'has_obligations=true' },
  isPremiumContent: { label: IS_PREMIUM_CONTENT, value: 'is_premium_content=true' }
};

export const BYOC_PROPERTIES = {
  isOrganizationDocument: { label: IS_ORGANIZATION_DOCUMENT, value: 'organization_documents_only' }
};

export const ALL_DOC_PROPERTIES = {
  ...TASK_DOC_PROPERTIES,
  ...STANDARD_DOC_PROPERTIES
};
