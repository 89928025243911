import PropTypes from 'prop-types';
import { Modal } from '@compliance.ai/web-components';
import { Feedback } from 'components/Feedback';

export const TEST_ID = 'timeline-toolbar-subscription-box-modal';

const SuggestionBoxModal = ({ show, onClose }) => {
  return show ? (
    <Modal
      isOpen={show}
      onClose
      className="suggestion-box-modal"
      dataTestId={TEST_ID}
      disableClose={true}
    >
      <Feedback modalClose={onClose} />
    </Modal>
  ) : null;
};

SuggestionBoxModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

SuggestionBoxModal.defaultProps = {
  show: false
};

export default SuggestionBoxModal;
