import { components } from 'react-select-3';

export const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
        <path d="M7 10l5 5 5-5z" />
      </svg>
    </components.DropdownIndicator>
  );
};
