import { convertFromRaw, EditorState } from 'draft-js';
import Bugsnag from '@bugsnag/js';

const formatTextEditorState = richtext => {
  try {
    if (richtext !== null) {
      const stringifiedRichtext =
        typeof richtext === 'string' ? richtext : JSON.stringify(richtext);
      return JSON.parse(stringifiedRichtext);
    }
    return null;
  } catch (error) {
    const errorLog = 'RichTextEditor: Corrupted richtext provided: ' + richtext;
    console.log(errorLog);
    Bugsnag.notify(errorLog);
    return null;
  }
};

const makeEditorState = rawRichtext => {
  const formattedRichtext = formatTextEditorState(rawRichtext);

  return formattedRichtext
    ? EditorState.createWithContent(convertFromRaw(formattedRichtext))
    : EditorState.createEmpty();
};

export default {
  formatTextEditorState,
  makeEditorState
};
