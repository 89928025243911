import type { TaskAssigneeProps } from './TaskAssignee.types';

import classnames from 'classnames';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ReportIcon from '@material-ui/icons/Report';
import {
  Tooltip,
  TOOLTIP_POSITION,
  LabeledField,
  Select,
  SELECT_TYPES,
  SelectProps
} from '@compliance.ai/web-components';
import { useTaskAssigneeData, useTaskAssigneeHandlers } from './hooks';
import './_task-assignee.scss';

export const TaskAssignee = ({ documentTask, assignee, document, onChange }: TaskAssigneeProps) => {
  const { localState, localActions, reduxState, formattedData } = useTaskAssigneeData({
    documentTask,
    assignee,
    document
  });

  const handlers = useTaskAssigneeHandlers({
    props: {
      documentTask,
      onChange
    },
    localActions,
    formattedData
  });

  if (localState.isEditable) {
    return (
      <div className="task-assignee__select-container">
        <Select
          type={SELECT_TYPES.DEFAULT}
          value={reduxState.selectedAssigneeOption}
          onChange={handlers.handleAssigneeChange as SelectProps['onChange']}
          options={
            formattedData.shouldShowOnlyApprovers
              ? reduxState.approversOptions
              : reduxState.assigneesOptions
          }
          onClose={handlers.handleAssigneeSelectClose}
          onClickOutside={handlers.handleAssigneeSelectClose}
          isClearable={false}
        />
      </div>
    );
  }

  return (
    <div
      className={classnames({
        'task-assignee__container': true,
        'task-assignee__container--is-clickable': !formattedData.isDisabled
      })}
      onClick={handlers.handleAssigneeClick}
    >
      <LabeledField label="Assignee" isContrast>
        <Tooltip
          id="task-assignee-tooltip"
          title={reduxState.selectedAssigneeOption?.label}
          placement={TOOLTIP_POSITION.BOTTOM}
        >
          <div className="task-assignee__value-container">
            {reduxState.selectedAssigneeOption ? (
              <AccountCircleIcon className="task-assignee__icon" />
            ) : (
              <ReportIcon className="task-assignee__icon" />
            )}
            <div className="task-assignee__name">{reduxState.selectedAssigneeOptionLabel}</div>
          </div>
        </Tooltip>
      </LabeledField>
    </div>
  );
};
