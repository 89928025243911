import classNames from 'classnames';
import { ArrowRight } from '@material-ui/icons';
import { FOLDER_DOC_ICONS } from 'components/Folders/Folders.constants';
import { useFolderListItemData, useFolderListItemHandlers } from './hooks';
import './_folder-list-item.scss';

export const TEST_ID = 'folder-list-item-container';

export const FolderListItem = ({ folder, disabled, onClick = () => {} }) => {
  const { formattedData } = useFolderListItemData({
    props: { disabled, folder }
  });
  const handlers = useFolderListItemHandlers({
    props: {
      folder,
      onClick
    },
    formattedData
  });

  const Icon = FOLDER_DOC_ICONS[folder.type];

  return (
    <div
      className={classNames('folder-list-item__container', {
        disabled: formattedData.isDisabled
      })}
      onClick={handlers.handleClick}
      data-testid={TEST_ID}
    >
      <Icon className="folder-list-item__icon" />
      <span className="folder-list-item__title">{folder.name}</span>
      {formattedData.showArrow && (
        <ArrowRight className="folder-list-item__arrow" fontSize="large" />
      )}
    </div>
  );
};
