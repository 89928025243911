import type { RouterHandlers } from './useRouterHandlers.types';

import Bugsnag from '@bugsnag/js';

export const useRouterHandlers = (): RouterHandlers => {
  const handleError: RouterHandlers['handleError'] = (error, errorInfo) => {
    Bugsnag.notify(error);
    console.error(error, errorInfo);
  };

  return {
    handleError
  };
};
