import { useQueryParams, useUpdateEffect } from 'utils/hooks';
import lodash from 'lodash';
import { SUPPORTED_QUERY_PARAMS } from 'constants/PrimaryFilter';

export const useAdditionalFiltersLifecycle = ({
  reduxState,
  localState,
  localActions,
  userReduxActions
}) => {
  const queryParams = useQueryParams();

  const handleMinScoreSetOnParamsChange = () => {
    let currentMinScore = 0;

    if (!lodash.isNil(queryParams[SUPPORTED_QUERY_PARAMS.OVERRIDE_MIN_SCORE])) {
      currentMinScore = queryParams[SUPPORTED_QUERY_PARAMS.OVERRIDE_MIN_SCORE];
    } else if (!lodash.isNil(queryParams[SUPPORTED_QUERY_PARAMS.MIN_SCORE])) {
      currentMinScore = queryParams[SUPPORTED_QUERY_PARAMS.MIN_SCORE];
    }

    localActions.setMinScore(currentMinScore);
  };

  const handleTopicThresholdSaveOnStateChange = () => {
    if (reduxState.isAuthenticated) {
      userReduxActions.updateCurrentUser(reduxState.currentUserEmail, {
        ...reduxState.currentUser,
        properties: {
          ...reduxState.currentUserProperties,
          topicThreshold: localState.topicThreshold
        }
      });
    }
  };

  useUpdateEffect(handleMinScoreSetOnParamsChange, [
    queryParams[SUPPORTED_QUERY_PARAMS.MIN_SCORE],
    queryParams[SUPPORTED_QUERY_PARAMS.OVERRIDE_MIN_SCORE]
  ]);

  useUpdateEffect(handleTopicThresholdSaveOnStateChange, [localState.topicThreshold]);
};
