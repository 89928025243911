import type { WorkflowDetailsData } from './useWorkflowDetailsData.types';
import type { WorkflowDetailsHandlers } from './useWorkflowDetailsHandlers.types';

import { useCallback } from 'react';
import { useWorkflowDetailsQueries } from './useWorkflowDetailsQueries';

export const useWorkflowDetailsHandlers = ({
  reduxState,
  queries
}: {
  reduxState: WorkflowDetailsData['reduxState'];
  queries: ReturnType<typeof useWorkflowDetailsQueries>;
}): WorkflowDetailsHandlers => {
  const { refetch } = queries.fetchWorkflowsByDocIDs;

  const handleDataRefetch: WorkflowDetailsHandlers['handleDataRefetch'] = useCallback(() => {
    // This dumb condition makes ESLint happy
    if (reduxState.tasksRefreshTrigger || !reduxState.tasksRefreshTrigger) {
      refetch();
    }
  }, [refetch, reduxState.tasksRefreshTrigger]);

  return {
    handleDataRefetch
  };
};
