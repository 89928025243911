import type { SentenceNoteProps } from '../SentenceNote.types';
import type { SentenceNoteHandlers } from './useSentenceNoteHandlers.types';
import type { SentenceNoteData } from './useSentenceNoteData.types';

import * as uiLib from '@compliance.ai/web-components';

import { useObligationsReduxActions } from 'shared/features/obligations/hooks';
import { useCallback } from 'react';
import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';
import { useSentenceNotesReduxActions } from '../../../../../shared/features/sentenceNotes/hooks';

export const useSentenceNoteHandlers = ({
  props,
  refs,
  reduxState
}: {
  props: Pick<SentenceNoteProps, 'document' | 'sentenceNote'>;
  refs: SentenceNoteData['refs'];
  reduxState: SentenceNoteData['reduxState'];
}): SentenceNoteHandlers => {
  const obligationsReduxActions = useObligationsReduxActions();
  const rightPanelReduxActions = useRightPanelReduxActions();
  const sentenceNotesReduxActions = useSentenceNotesReduxActions();

  const handleRelevancyChange: SentenceNoteHandlers['handleRelevancyChange'] = isRelevant => {
    if (props.sentenceNote.id) {
      sentenceNotesReduxActions.toggleSentenceNote({
        id: props.sentenceNote.id,
        isExpanded: isRelevant
      });
    }
  };

  const handleSentenceNoteClick: SentenceNoteHandlers['handleSentenceNoteClick'] = () => {
    if (props.sentenceNote.firstSentenceId && props.sentenceNote.lastSentenceId) {
      rightPanelReduxActions.changeSentenceNoteId({ id: props.sentenceNote.id });

      obligationsReduxActions.selectSentences(
        props.sentenceNote.firstSentenceId,
        props.sentenceNote.lastSentenceId,
        props.sentenceNote.leftOffset,
        props.sentenceNote.rightOffset
      );
    }
  };

  const handleSentenceSelection: SentenceNoteHandlers['handleSentenceSelection'] = useCallback(() => {
    if (
      reduxState.selectedSentenceNoteId &&
      reduxState.selectedSentenceNoteId === props.sentenceNote.id
    ) {
      obligationsReduxActions.selectSentences(
        props.sentenceNote.firstSentenceId,
        props.sentenceNote.lastSentenceId,
        props.sentenceNote.leftOffset,
        props.sentenceNote.rightOffset
      );

      uiLib.scrollToElement(refs.sentenceNote.current, { behavior: 'auto' });
    }
  }, [
    refs.sentenceNote,
    obligationsReduxActions,
    reduxState.selectedSentenceNoteId,
    props.sentenceNote.id,
    props.sentenceNote.firstSentenceId,
    props.sentenceNote.lastSentenceId,
    props.sentenceNote.leftOffset,
    props.sentenceNote.rightOffset
  ]);

  return {
    handleRelevancyChange,
    handleSentenceNoteClick,
    handleSentenceSelection
  };
};
