import STYLE_VARIABLES from '@compliance.ai/styles';

export const SERIES_DATA_LABELS_CONFIG = {
  allowOverlap: true,
  enabled: true,
  stacking: 'normal',
  inside: false,
  style: {
    color: STYLE_VARIABLES.PRIMARY_COLOR_3,
    fontWeight: 'normal'
  }
};

export const LEGEND_CONFIG = {
  enabled: false
};

const DEFAULT_CHART_AXIS_WIDTH = 170;

const DEFAULT_CHART_EXPORT_COLUMN_SIZE = 80;

const DEFAULT_NUMBER_OF_COLUMNS = 5;

export const EXPORTING_CONFIG = (numberOfColumns: number) => ({
  chartOptions: {
    chart: {
      width:
        DEFAULT_CHART_AXIS_WIDTH +
        (numberOfColumns || DEFAULT_NUMBER_OF_COLUMNS) * DEFAULT_CHART_EXPORT_COLUMN_SIZE
    },
    plotOptions: {
      column: {
        dataLabels: {
          style: {
            fontSize: '0.6rem'
          }
        }
      }
    }
  }
});

export const Y_AXIS_CONFIG = {
  min: 0,
  title: {
    text: '# Enforcement Docs'
  },
  lineColor: 'transparent',
  gridLineWidth: 1
};

export const MOCK_AGGREGATED_ENFORCEMENTS = {
  SEC: {
    agency_id: 300008,
    category: 'Motion',
    count: 57,
    currency: '$',
    jurisdiction: 'US-TX',
    monetary_penalty: 92769177,
    short_name: 'SEC'
  },
  FINRA: {
    agency_id: 203,
    category: 'Financial Sanction',
    count: 45,
    currency: '$',
    jurisdiction: 'US',
    monetary_penalty: 1406976,
    short_name: 'FINRA'
  },
  OFAC: {
    agency_id: 2600003,
    category: 'Enforcement Order',
    count: 18,
    currency: '$',
    jurisdiction: 'US-KY',
    monetary_penalty: 0,
    short_name: 'OFAC'
  },
  CFTC: {
    agency_id: 9015,
    category: 'NAC Decisions',
    count: 15,
    currency: '$',
    jurisdiction: 'US',
    monetary_penalty: 9381901,
    short_name: 'CFTC'
  },
  OCC: {
    agency_id: 5900002,
    category: 'Enforcement Order',
    count: 3,
    currency: '$',
    jurisdiction: 'US-NJ',
    monetary_penalty: 60000000,
    short_name: 'OCC'
  },
  FINCEN: {
    agency_id: 77,
    category: 'Enforcement Order',
    count: 3,
    currency: '$',
    jurisdiction: 'US',
    monetary_penalty: 140000000,
    short_name: 'FINCEN'
  },
  CFPB: {
    agency_id: 466,
    category: 'Enforcement',
    count: 3,
    currency: '$',
    jurisdiction: 'US',
    monetary_penalty: 1000000,
    short_name: 'CFPB'
  }
};
