import * as muiIcons from '@material-ui/icons';
import * as muiCore from '@material-ui/core';
import * as config from 'shared/config';
import * as components from 'components/Feedback';
import * as elements from './elements';
import * as supportConstants from 'constants/Support';
import * as images from 'shared/images';
import * as constants from './Support.constants';

import Modal from 'common/Modal';

import { useAuthReduxActions } from 'shared/features/auth/hooks';
import { useSupportData, useSupportHandlers } from './hooks';

import './_support.scss';

export const Support = ({ className }) => {
  const authReduxActions = useAuthReduxActions();

  const { localState, localActions, reduxState } = useSupportData();

  const {
    handleMenuOpen,
    handleMenuClose,
    handleRedirect,
    handleFeedbackOpen,
    handleFeedbackClose,
    handleTrainingCenterRedirect
  } = useSupportHandlers({
    localState,
    localActions,
    reduxState,
    authReduxActions
  });

  return (
    <div className={className}>
      <muiIcons.HelpOutline
        onClick={handleMenuOpen}
        className="support-menu__support-button"
        data-testid={constants.TEST_ID.ICON}
      />
      <muiCore.Drawer
        anchor="right"
        open={localState.isMenuOpen}
        onClose={handleMenuClose}
        onOpen={handleMenuOpen}
        PaperProps={{
          className: 'support-menu__drawer'
        }}
      >
        <muiCore.Paper
          className={
            reduxState.currentView.inMobile
              ? 'support-menu__mobile-paper'
              : 'support-menu__outer-container'
          }
          elevation={0}
        >
          <muiIcons.Close className="support-menu__close-icon" onClick={handleMenuClose} />
          <elements.SupportMenuItem
            onClick={
              config.trainingCenterUrl
                ? handleTrainingCenterRedirect
                : handleRedirect(supportConstants.SUPPORT_URLS.SUPPORT_PAGE)
            }
            title={config.trainingCenterUrl ? 'Training Center' : 'Product Guide'}
            content="Learn about our features and how to get the most out of Compliance.ai"
            icon={images.misc.iconProductGuide}
          />
          <elements.SupportMenuItem
            onClick={handleRedirect(supportConstants.SUPPORT_URLS.CORPORATE_WEBSITE)}
            title="What's New"
            content="RegTech News, Product Updates and Thought-Leadership articles"
            icon={images.misc.iconWhatsNew}
          />
          {reduxState.isLoggedIn && (
            <elements.SupportMenuItem
              onClick={handleRedirect(supportConstants.SUPPORT_URLS.ZOHO_TICKET)}
              title="Raise a Bug"
              content="Let us know about a broken feature so we can improve Compliance.ai"
              icon={images.misc.iconRaiseABug}
            />
          )}
          {reduxState.isLoggedIn && (
            <elements.SupportMenuItem
              onClick={handleRedirect(supportConstants.SUPPORT_URLS.ZOHO_TICKET)}
              title="Request an Enhancement"
              content="Help us improve Compliance.ai by submitting ideas for new features"
              icon={images.misc.iconRequestAnEnhancement}
            />
          )}
          <elements.SupportMenuItem
            onClick={handleFeedbackOpen}
            title="Give Feedback"
            content="Give feedback about your Compliance.ai experience"
            icon={images.misc.iconGiveFeedback}
          />
        </muiCore.Paper>
      </muiCore.Drawer>
      <Modal
        open={localState.isGivingFeedback}
        onClose={handleFeedbackClose}
        title="Give Feedback"
        data-testid={constants.TEST_ID.MODAL}
      >
        <components.Feedback shouldShowCancelButton onCancel={handleFeedbackClose} />
      </Modal>
    </div>
  );
};
