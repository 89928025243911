const HEIGHT = 38;
const MAX_HEIGHT = '20vh';
const INPUT_HEIGHT = 20;

const getContainerDefaultStyles = provided => ({
  ...provided,
  width: '100%'
});

const getClearIndicatorDefaultStyles = provided => ({
  ...provided,
  color: '#999',
  fontSize: 18,
  padding: 0,
  paddingLeft: 13,

  '&:hover': {
    color: '#d0021b'
  }
});

const getDropdownIndicatorDefaultStyles = (provided, state) => ({
  width: 22,
  height: 22,
  color: state.isDisabled ? '#d8d8d8' : '#999999',
  position: 'relative',
  marginRight: '10px',
  transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'unset'
});

const getIndicatorSeparatorDefaultStyles = () => ({
  display: 'none'
});

const getMenuDefaultStyles = provided => ({
  ...provided,
  margin: 0,
  borderRadius: 0,
  boxShadow: 'unset',
  paddingTop: '2px',
  paddingBottom: '2px',
  zIndex: 2 // Note: the default react-select menu's z-index is 1.
});

const getMenuListDefaultStyles = provided => ({
  ...provided,
  padding: 0
});

const getMultiValueLabelDefaultStyles = provided => ({
  ...provided,
  color: '#007eff',
  order: 2
});

const getNoOptionsMessageDefaultStyles = provided => ({
  ...provided,
  color: '#A9A9A9'
});

const getMultiValueRemoveDefaultStyles = provided => ({
  ...provided,
  color: '#007eff',
  borderRight: '1px solid rgba(0,126,255,.24)',
  order: 1,

  '&:hover': {
    color: '#007eff',
    backgroundColor: 'rgba(0,113,230,.08)'
  }
});

const getControlDefaultStyles = (provided, state) => ({
  width: '100%',
  display: 'flex',
  cursor: state.isDisabled ? 'not-allowed' : 'pointer'
});

const getMultiValueDefaultStyles = provided => ({
  ...provided,
  border: '1px solid rgba(0,126,255,.24)',
  backgroundColor: 'rgba(0,126,255,.08)'
});

const getPlaceholderDefaultStyles = provided => ({
  ...provided,
  color: '#A9A9A9'
});

const getValueContainerDefaultStyles = (provided, state) => ({
  ...provided,
  maxHeight: MAX_HEIGHT,
  overflowY: Array.isArray(state.selectProps.value) && state.isMulti ? 'auto' : 'hidden'
});

const getInputDefaultStyles = provided => ({
  ...provided,

  '& input': {
    height: INPUT_HEIGHT
  }
});

const defaultStyles = {
  menu: provided => ({
    ...getMenuDefaultStyles(provided),
    border: '1px solid #CCCCCC',
    borderTop: 'none'
  }),
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: state.isSelected ? 'rgba(0, 0, 0, 0.08)' : '',
    color: state.isDisabled ? '#777' : '#000000',
    padding: '6px 14px 6px 14px',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },

    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },

    '& .select-option-checkbox': {
      color: '#000000',
      marginRight: 5
    },

    '& .select-option-checkbox--is-blank': {
      color: '#000000',
      marginRight: 5
    }
  }),
  control: (provided, state) => ({
    ...getControlDefaultStyles(provided, state),
    backgroundColor: 'white',
    border: '1px solid #CCCCCC',
    boxShadow: 'unset',
    minHeight: HEIGHT,
    maxHeight: MAX_HEIGHT,
    height: state.isMulti && state.isFocused ? 'auto' : HEIGHT
  }),
  input: provided => ({
    ...getInputDefaultStyles(provided),
    display: 'flex',
    alignItems: 'center'
  }),
  indicatorSeparator: getIndicatorSeparatorDefaultStyles,
  valueContainer: getValueContainerDefaultStyles,
  menuList: getMenuListDefaultStyles,
  dropdownIndicator: getDropdownIndicatorDefaultStyles,
  multiValue: getMultiValueDefaultStyles,
  multiValueRemove: getMultiValueRemoveDefaultStyles,
  multiValueLabel: getMultiValueLabelDefaultStyles,
  clearIndicator: getClearIndicatorDefaultStyles,
  container: getContainerDefaultStyles,
  noOptionsMessage: getNoOptionsMessageDefaultStyles,
  placeholder: getPlaceholderDefaultStyles
};

const darkStyles = {
  menu: provided => ({
    ...getMenuDefaultStyles(provided),
    border: '1px solid white',
    backgroundColor: '#272727',
    borderTop: 'none'
  }),
  option: provided => ({
    ...provided,
    backgroundColor: 'unset',
    color: 'white',
    padding: '0.75rem 2rem;',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#444'
    },

    '&:active': {
      backgroundColor: 'unset'
    }
  }),
  control: (provided, state) => ({
    ...getControlDefaultStyles(provided, state),
    border: '1px solid white',
    boxShadow: 'unset',
    height: HEIGHT
  }),
  singleValue: () => ({
    color: 'white'
  }),
  input: provided => ({
    ...getInputDefaultStyles(provided),
    color: 'white'
  }),
  menuList: getMenuListDefaultStyles,
  dropdownIndicator: getDropdownIndicatorDefaultStyles,
  container: getContainerDefaultStyles,
  indicatorSeparator: getIndicatorSeparatorDefaultStyles,
  noOptionsMessage: getNoOptionsMessageDefaultStyles,
  placeholder: getPlaceholderDefaultStyles
};
const annotationStyles = {
  menu: provided => ({
    ...getMenuDefaultStyles(provided),
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
    border: '1px solid #CCCCCC',
    borderTop: 'none'
  }),
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: state.isSelected ? '#f5faff' : '',
    color: '#000000',
    padding: '6px 9px 6px 9px',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: 'rgba(0,126,255,.08)'
    },

    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },

    '& .select-option-checkbox': {
      color: '#007eff',
      marginRight: 5
    },

    '& .select-option-checkbox--is-blank': {
      color: '#000000',
      marginRight: 5
    }
  }),
  control: (provided, state) => ({
    ...getControlDefaultStyles(provided, state),
    backgroundColor: 'white',
    border: '1px solid #CCCCCC',
    boxShadow: 'unset',
    height: HEIGHT
  }),
  indicatorSeparator: getIndicatorSeparatorDefaultStyles,
  menuList: getMenuListDefaultStyles,
  dropdownIndicator: getDropdownIndicatorDefaultStyles,
  multiValue: getMultiValueDefaultStyles,
  multiValueRemove: getMultiValueRemoveDefaultStyles,
  multiValueLabel: getMultiValueLabelDefaultStyles,
  clearIndicator: getClearIndicatorDefaultStyles,
  container: getContainerDefaultStyles,
  noOptionsMessage: getNoOptionsMessageDefaultStyles,
  placeholder: getPlaceholderDefaultStyles
};

export const filterStyles = {
  menu: (provided, state) => {
    return {
      ...getMenuDefaultStyles(provided),
      border: '1px solid #CCCCCC',
      borderTop: 'none',
      boxShadow: state.isMulti ? '0 3px 6px 0 rgba(0,0,0,0.2)' : 'unset'
    };
  },
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: state.isSelected ? 'rgba(0, 0, 0, 0.08)' : '',
    color: state.isDisabled ? '#777' : '#000000',
    padding: '6px 14px 6px 14px',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },

    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },

    '& .select-option-checkbox': {
      color: '#000000',
      marginRight: 5
    },

    '& .select-option-checkbox--is-blank': {
      color: '#000000',
      marginRight: 5
    }
  }),
  control: (provided, state) => ({
    ...getControlDefaultStyles(provided, state),
    backgroundColor: 'transparent',
    borderTop: state.isMulti && state.isFocused ? '1px solid #CCCCCC' : 'none',
    borderLeft: state.isMulti && state.isFocused ? '1px solid #CCCCCC' : 'none',
    borderRight: state.isMulti && state.isFocused ? '1px solid #CCCCCC' : 'none',
    borderBottom: '1px solid black',
    boxShadow: state.isMulti && state.isFocused ? '0 3px 6px 0 rgba(0,0,0,0.2)' : 'unset',
    minHeight: HEIGHT,
    maxHeight: MAX_HEIGHT,
    height: state.isMulti && state.isFocused ? 'auto' : HEIGHT
  }),
  placeholder: provided => ({
    ...getPlaceholderDefaultStyles(provided),
    fontSize: 11
  }),
  menuList: getMenuListDefaultStyles,
  dropdownIndicator: getDropdownIndicatorDefaultStyles,
  indicatorSeparator: getIndicatorSeparatorDefaultStyles,
  valueContainer: getValueContainerDefaultStyles,
  multiValue: getMultiValueDefaultStyles,
  multiValueRemove: getMultiValueRemoveDefaultStyles,
  multiValueLabel: getMultiValueLabelDefaultStyles,
  clearIndicator: getClearIndicatorDefaultStyles,
  container: getContainerDefaultStyles,
  noOptionsMessage: getNoOptionsMessageDefaultStyles
};

const defaultForTextSelection = {
  ...defaultStyles,
  valueContainer: (provided, state) => ({
    ...getValueContainerDefaultStyles(provided, state),
    cursor: 'text'
  }),
  input: provided => ({
    ...provided,
    '& input': {
      /**
       *
       *  It looks like react-select doesn't properly rerender input after option change
       *  and the input string will be blank. !important fixes the issue
       */
      opacity: '1 !important'
    }
  })
};

export const SELECT_STYLES = {
  DEFAULT: defaultStyles,
  ANNOTATIONS: annotationStyles,
  FILTER: filterStyles,
  DARK: darkStyles,
  DEFAULT_FOR_TEXT_SELECTION: defaultForTextSelection
};
