import type { FC } from 'react';

import * as constants from './DashboardPDFOverlay.constants';

import {
  useDashboardPDFOverlayData,
  useDashboardPDFOverlayLifecycle,
  useDashboardPDFOverlayHandlers
} from './hooks';

import './_dashboard-pdf-overlay.scss';

export const DashboardPDFOverlay: FC = () => {
  const { reduxState } = useDashboardPDFOverlayData();

  const handlers = useDashboardPDFOverlayHandlers({
    reduxState
  });

  useDashboardPDFOverlayLifecycle({
    onPDFLoad: handlers.handlePDFLoad
  });

  return (
    <>
      <button
        type="button"
        onClick={handlers.handleOverlayClose}
        className="dashboard-pdf-overlay__close-button"
      >
        Close Document
      </button>
      <div
        id={constants.DASHBOARD_PDF_OVERLAY_ID}
        className="dashboard-pdf-overlay__overlay-container"
      />
    </>
  );
};
