import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserEmail } from 'shared/features/user/user.selectors';
import { getUserAuthenticatedState } from 'shared/features/auth/auth.selectors';
import { FORM_FIELDS, FORM_STATUSES } from '../Feedback.constants';
import { getFeedbackFormType } from './useFeedbackData.helpers';

export const useFeedbackData = ({
  requestTeam,
  scheduleDemo,
  topics,
  requestPro,
  requestJurisdiction,
  requestAgency,
  requestNewsSource,
  forceNonAuth
}) => {
  const [isLoading, setLoadingState] = useState(false);
  const [fieldsValues, setFieldsValues] = useState({
    [FORM_FIELDS.COMPANY]: '',
    [FORM_FIELDS.EMAIL]: '',
    [FORM_FIELDS.TITLE]: '',
    [FORM_FIELDS.FEEDBACK]: ''
  });
  const [formStatus, setFormStatus] = useState(FORM_STATUSES.EMPTY);

  const localState = {
    isLoading,
    formStatus,
    fieldsValues
  };

  const localActions = useMemo(
    () => ({
      setLoadingState,
      setFormStatus,
      setFieldsValues
    }),
    []
  );

  const reduxState = useSelector(state => ({
    isAuthenticated: getUserAuthenticatedState(state),
    currentUserEmail: getUserEmail(state)
  }));

  const email =
    !reduxState.isAuthenticated || forceNonAuth
      ? localState.fieldsValues[FORM_FIELDS.EMAIL]
      : reduxState.currentUserEmail;

  const shouldShowEmailInput = !reduxState.isAuthenticated || forceNonAuth;

  const formType = getFeedbackFormType({
    requestTeam,
    scheduleDemo,
    topics,
    requestPro,
    requestJurisdiction,
    requestAgency,
    requestNewsSource,
    forceNonAuth
  });

  return {
    localState,
    localActions,
    reduxState,
    formattedData: {
      email,
      shouldShowEmailInput,
      formType
    }
  };
};
