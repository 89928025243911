import type { FC } from 'react';
import type { WorkflowSummaryProps } from './WorkflowSummary.types';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';
import * as sentenceNotesConstants from 'shared/features/sentenceNotes/sentenceNotes.constants';
import * as constants from './WorkflowSummary.constants';
import * as sections from './WorkflowSummary.sections';

import { useProgressiveSentenceNotes } from 'shared/features/sentenceNotes/hooks';
import {
  useWorkflowSummaryData,
  useWorkflowSummaryHandlers,
  useWorkflowSummaryLifecycle
} from './hooks';

import './_workflow-summary.scss';

export const WorkflowSummary: FC<WorkflowSummaryProps> = ({
  workflow_name,
  documentId,
  documentTasks,
  onClose
}) => {
  const { refs, localState, localActions, reduxState, formattedData } = useWorkflowSummaryData({
    documentTasks,
    documentId
  });

  const handlers = useWorkflowSummaryHandlers({
    props: {
      workflow_name
    },
    refs,
    localState,
    localActions,
    reduxState
  });

  const progressiveAnnotations = useProgressiveSentenceNotes({
    type: sentenceNotesConstants.SENTENCE_NOTE_TYPE.ANNOTATIONS,
    documentId: documentId,
    onFetchComplete: handlers.handleSentenceNotesFetchCompletion(
      sentenceNotesConstants.SENTENCE_NOTE_TYPE.ANNOTATIONS
    ),
    loaderProps: {
      withoutBackground: false
    }
  });

  const progressiveObligations = useProgressiveSentenceNotes({
    type: sentenceNotesConstants.SENTENCE_NOTE_TYPE.OBLIGATIONS,
    documentId: documentId,
    onFetchComplete: handlers.handleSentenceNotesFetchCompletion(
      sentenceNotesConstants.SENTENCE_NOTE_TYPE.OBLIGATIONS
    ),
    loaderProps: {
      withoutBackground: false
    }
  });

  useWorkflowSummaryLifecycle({
    onPrint: handlers.handlePrint,
    onPrintableContentReady: handlers.handlePrintableContentReady
  });

  return (
    <uiLib.Modal
      isOpen
      title="Summary"
      className="workflow-summary__modal"
      childContainerClassName="workflow-summary__container"
      shouldShowFullscreenButton
      onClose={onClose}
      withFooter
      secondaryButtonText="Close"
      secondaryButtonType={uiLib.BUTTON_TYPES.SECONDARY}
      primaryButtonText="Print"
      primaryButtonType={uiLib.BUTTON_TYPES.PRIMARY}
      onSubmit={handlers.handlePrintPreparation}
      isPrimaryButtonDisabled={localState.isPreparingForPrint}
    >
      <>
        {localState.isPreparingForPrint && <uiLib.Loader withoutBackground={false} />}
        <div className="workflow-summary__scrollable-container">
          <div ref={refs.componentToPrint} className="workflow-summary__content">
            <uiLib.LabeledField
              label="Document Title"
              labelClassName="workflow-summary__field-title"
            >
              {formattedData.documentTitle}
            </uiLib.LabeledField>

            <uiLib.LabeledField
              label="Workflow Name"
              labelClassName="workflow-summary__field-title"
            >
              {workflow_name}
            </uiLib.LabeledField>

            {Object.entries(sections.SECTIONS).map(([type, section]) => {
              const sectionType = type as constants.SECTION_TYPE;

              if (
                !reduxState.doesDocumentHasSentences &&
                [constants.SECTION_TYPE.ANNOTATIONS, constants.SECTION_TYPE.OBLIGATIONS].includes(
                  sectionType
                )
              ) {
                return null;
              }

              return (
                <elements.Section
                  key={sectionType}
                  type={sectionType}
                  isExpanded={localState.isSectionExpanded[sectionType]}
                  onToggle={handlers.handleSectionToggle(sectionType)}
                  onExpandAnimationComplete={handlers.handleSectionExpandAnimationComplete(
                    sectionType
                  )}
                  shouldShowNoContentMessage={
                    {
                      [constants.SECTION_TYPE.TASKS]: !documentTasks.length,
                      [constants.SECTION_TYPE.COMMENTS]: !documentTasks.length,
                      [constants.SECTION_TYPE.ANNOTATIONS]:
                        !progressiveAnnotations.sentenceNotes.length &&
                        !progressiveAnnotations.isFetching,
                      [constants.SECTION_TYPE.OBLIGATIONS]:
                        !progressiveObligations.sentenceNotes.length &&
                        !progressiveObligations.isFetching
                    }[sectionType]
                  }
                >
                  <section.Component
                    documentTasks={documentTasks}
                    classNames={constants.SECTION_CLASSNAMES}
                    sentenceNotes={
                      {
                        [constants.SECTION_TYPE.TASKS]: [],
                        [constants.SECTION_TYPE.COMMENTS]: [],
                        [constants.SECTION_TYPE.ANNOTATIONS]: progressiveAnnotations.sentenceNotes,
                        [constants.SECTION_TYPE.OBLIGATIONS]: progressiveObligations.sentenceNotes
                      }[sectionType]
                    }
                    loader={
                      {
                        [constants.SECTION_TYPE.TASKS]: null,
                        [constants.SECTION_TYPE.COMMENTS]: null,
                        [constants.SECTION_TYPE.ANNOTATIONS]: progressiveAnnotations.loader,
                        [constants.SECTION_TYPE.OBLIGATIONS]: progressiveObligations.loader
                      }[sectionType]
                    }
                  />
                </elements.Section>
              );
            })}
          </div>
        </div>
      </>
    </uiLib.Modal>
  );
};
