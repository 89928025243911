import type { GlobalState } from 'shared/reducers';
import type { BucketSelectData } from './useBucketSelectData.types';

import * as orgSelectors from 'shared/features/organizations/organizations.selectors';

import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useBucketSelectData = (): BucketSelectData => {
  const [bucketNameInput, setBucketNameInput] = useState<
    BucketSelectData['localState']['bucketNameInput']
  >('');
  const [selectedBucketOption, setSelectedBucketOption] = useState<
    BucketSelectData['localState']['selectedBucketOption']
  >(null);

  const localState: BucketSelectData['localState'] = {
    bucketNameInput,
    selectedBucketOption
  };

  const localActions: BucketSelectData['localActions'] = useMemo(
    () => ({
      setBucketNameInput,
      setSelectedBucketOption
    }),
    []
  );

  const reduxState: BucketSelectData['reduxState'] = useSelector<
    GlobalState,
    BucketSelectData['reduxState']
  >(state => ({
    isPublicFolderDisabled: orgSelectors.getOrganizationPublicFolderRestriction(state)
  }));

  return {
    localState,
    localActions,
    reduxState
  };
};
