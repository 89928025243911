import lodash from 'lodash';
import { BasicConfig } from 'react-awesome-query-builder';
import * as uiLib from '@compliance.ai/web-components';

import * as common from 'common';
import * as elements from './elements';

import * as sortUtils from 'utils/sort';

import * as env from 'shared/config';
import * as filterConstants from 'shared/features/filters/filters.constants';
import * as relativeDatesConstants from 'constants/RelativeDates';
import * as advancedSearchConstants from 'constants/AdvancedSearch';
import * as skuConstants from 'constants/SKUs';

//Types Constants
const NUMBER = 'number';
const TEXT_BASIC = 'textBasic';
const BOOLEAN = 'boolean';
const SELECT = 'select';
const MULTI_SELECT = 'multiselect';
const MULTI_SELECT_ASYNC = 'multiselectAsync';
const MULTI_SELECT_GROUP = 'mutliselectGroup';
const MULTI_SELECT_GROUP_LEGACY = 'multiselectGroupLegacy';
const LABELS_SELECT = 'labelsSelect';
const EITL_LABELS_SELECT = 'eitlLabelsSelect';
const AUTHORS_SELECT = 'authorsSelect';
const CONCEPTS_SELECT = 'conceptsSelect';
const DATE = 'date';

const QUOTES_ARRAY = ['"', '“', '”'];

export const ADVANCED_SEARCH_BUILDER_KEYS_ACT = 'act';
export const ADVANCED_SEARCH_BUILDER_KEYS_BANK = 'bank';
export const ADVANCED_SEARCH_BUILDER_KEYS_TO_LOAD_FROM_API = [
  ADVANCED_SEARCH_BUILDER_KEYS_ACT,
  ADVANCED_SEARCH_BUILDER_KEYS_BANK
];

//Widget Constants
const CUSTOM_RELATIVE_DATES = 'customRelativeDates';

export default ({
  agencies,
  jurisdictions,
  banks,
  acts,
  news_sources,
  topics,
  regulations,
  docTypes,
  languages,
  docTypesLegacy,
  authorsOptions,
  userHasSKU
}) => {
  const conjunctions = {
    ...BasicConfig.conjunctions,
    AND: {
      label: 'And',
      reversedConj: 'OR',
      formatConj: (children, conj, not) => {
        return children.size > 1
          ? (not ? 'NOT ' : '') + '(' + children.join(' AND ') + ')'
          : (not ? 'NOT (' : '') + children.first() + (not ? ')' : '');
      }
    },
    OR: {
      label: 'Or',
      reversedConj: 'AND',
      formatConj: (children, conj, not) => {
        return children.size > 1
          ? (not ? 'NOT ' : '') + '(' + children.join(' OR ') + ')'
          : (not ? 'NOT (' : '') + children.first() + (not ? ')' : '');
      }
    }
  };

  const operators = {
    ...BasicConfig.operators,
    equal: {
      ...BasicConfig.operators.equal,
      label: 'is',
      formatOp: (field, op, value, valueSrcs, valueTypes, opDef, operatorOptions, isForDisplay) => {
        if (valueTypes === 'boolean' && isForDisplay) {
          return value === 'No' ? `NOT ${field}` : `${field}`;
        }
        if (isForDisplay) {
          return `${field}`;
        }
        return `${field} ${opDef.labelForFormat} ${value}`;
      },
      labelForFormat: '='
    },
    not_equal: {
      ...BasicConfig.operators.not_equal,
      label: 'is not',
      labelForFormat: '!=',
      formatOp: (field, op, value, valueSrcs, valueTypes, opDef, operatorOptions, isForDisplay) => {
        if (valueTypes === 'boolean' && isForDisplay) {
          return value === 'No' ? `${field}` : `NOT ${field}`;
        }
        if (isForDisplay) {
          return `${field}`;
        }
        return `${field} ${opDef.labelForFormat} ${value}`;
      }
    },
    select_equals: {
      ...BasicConfig.operators.select_equals,
      label: 'is',
      labelForFormat: '=',
      formatOp: (field, op, value, valueSrc, valueType, opDef, operatorOptions, isForDisplay) => {
        if (isForDisplay) return `${field}`;
        if (!value.length) return undefined;
        return `${field} ${opDef.labelForFormat} ${value}`;
      }
    },
    multiselect_equals: {
      ...BasicConfig.operators.multiselect_equals,
      label: 'Equals',
      labelForFormat: '=',
      formatOp: (field, op, values, valueSrc, valueType, opDef, operatorOptions, isForDisplay) => {
        if (isForDisplay) return `${field}`;
        if (!values.length) return undefined;
        if (values.length === 1) return `${field} = ${values[0]}`;
        if (valueSrc === 'value') return `${field} IN [${values.join(', ')}]`;
        return `${field} ${opDef.labelForFormat} ${values}`;
      }
    },
    multiselect_not_equals: {
      ...BasicConfig.operators.multiselect_not_equals,
      label: 'Not equals',
      labelForFormat: '!=',
      formatOp: (field, op, values, valueSrc, valueType, opDef, operatorOptions, isForDisplay) => {
        if (isForDisplay) return `${field}`;
        if (!values.length) return undefined;
        if (values.length === 1) return `${field} != ${values[0]}`;
        if (valueSrc === 'value') return `NOT ${field} IN [${values.join(', ')}]`;
        return `${field} ${opDef.labelForFormat} ${values}`;
      }
    },
    [relativeDatesConstants.REATIVE_DATE_OPERATORS.TODAY]: {
      label: relativeDatesConstants.SINGLE_RELATIVE_DATES.TODAY,
      cardinality: 0,
      formatOp: (field, op, value, valueSrcs, valueTypes, opDef, operatorOptions, isForDisplay) => {
        return isForDisplay
          ? field
          : `${field} = "${relativeDatesConstants.SINGLE_RELATIVE_DATES.TODAY}"`;
      },
      jsonLogic: relativeDatesConstants.REATIVE_DATE_OPERATORS.TODAY
    },
    [relativeDatesConstants.REATIVE_DATE_OPERATORS.YESTERDAY]: {
      label: relativeDatesConstants.SINGLE_RELATIVE_DATES.YESTERDAY,
      cardinality: 0,
      formatOp: (field, op, value, valueSrcs, valueTypes, opDef, operatorOptions, isForDisplay) => {
        return isForDisplay
          ? field
          : `${field} = "${relativeDatesConstants.SINGLE_RELATIVE_DATES.YESTERDAY}"`;
      },
      jsonLogic: relativeDatesConstants.REATIVE_DATE_OPERATORS.YESTERDAY
    },
    [relativeDatesConstants.REATIVE_DATE_OPERATORS.TOMORROW]: {
      label: relativeDatesConstants.SINGLE_RELATIVE_DATES.TOMORROW,
      cardinality: 0,
      formatOp: (field, op, value, valueSrcs, valueTypes, opDef, operatorOptions, isForDisplay) => {
        return isForDisplay
          ? field
          : `${field} = "${relativeDatesConstants.SINGLE_RELATIVE_DATES.TOMORROW}"`;
      },
      jsonLogic: relativeDatesConstants.REATIVE_DATE_OPERATORS.TOMORROW
    },
    [relativeDatesConstants.REATIVE_DATE_OPERATORS.PAST]: {
      cardinality: 1,
      label: relativeDatesConstants.REATIVE_DATE_OPERATOR_LABELS.PAST,
      formatOp: (field, op, value, valueSrc, valueType, opDef, operatorOptions, isForDisplay) => {
        return !!value ? `${field} <= "${value}"` : undefined;
      },
      jsonLogic: relativeDatesConstants.REATIVE_DATE_OPERATORS.PAST
    },
    [relativeDatesConstants.REATIVE_DATE_OPERATORS.NEXT]: {
      cardinality: 1,
      label: relativeDatesConstants.REATIVE_DATE_OPERATOR_LABELS.NEXT,
      formatOp: (field, op, value, valueSrc, valueType, opDef, operatorOptions, isForDisplay) => {
        return !!value ? `${field} >= "${value}"` : undefined;
      },
      jsonLogic: relativeDatesConstants.REATIVE_DATE_OPERATORS.NEXT
    }
  };

  const widgets = {
    ...BasicConfig.widgets,
    text: {
      ...BasicConfig.widgets.text,
      formatValue: (val, _fieldDef, _wgtDef) => {
        if (QUOTES_ARRAY.includes(val[0]) && QUOTES_ARRAY.includes(val[val.length - 1])) {
          return val;
        }
        return `"${val}"`;
      }
    },
    boolean: {
      ...BasicConfig.widgets.boolean,
      labelYes: 'True',
      labelNo: 'False',
      defaultValue: true
    },
    date: {
      ...BasicConfig.widgets.date,
      dateFormat: 'YYYY-MM-DD',
      valueFormat: 'YYYY-MM-DD'
    },
    multiselect: {
      ...BasicConfig.widgets.multiselect,
      factory: props => <elements.Select {...props} isMulti={true} />,
      formatValue: (vals, _fieldDef, _wgtDef, isForDisplay) => {
        if (Array.isArray(vals)) {
          return isForDisplay ? vals.map(v => `"${v}"`) : vals.map(v => `"${v}"`);
        }
        return [];
      }
    },
    [SELECT]: {
      ...BasicConfig.widgets.select,
      factory: props => <elements.Select {...props} />,
      formatValue: (vals, _fieldDef, _wgtDef, isForDisplay) => {
        return `"${vals}"`;
      }
    },
    [MULTI_SELECT_ASYNC]: {
      jsType: 'array',
      valueSrc: 'value',
      valueLabel: 'Values',
      valuePLaceholder: 'Select Values',
      type: MULTI_SELECT_ASYNC,
      factory: props => {
        const categoryToListValues = { acts, banks };

        props.listValues = categoryToListValues[props.category] || props.listValues;

        return <elements.AsyncSelect {...props} />;
      },
      formatValue: (vals, _fieldDef, _wgtDef) => {
        return Array.isArray(vals) ? vals.map(v => `"${lodash.get(v, 'value', v)}"`) : [];
      }
    },
    [MULTI_SELECT_GROUP]: {
      type: MULTI_SELECT_GROUP,
      jsType: 'array',
      valueSrc: 'value',
      valueLabel: 'Values',
      valuePLaceholder: 'Select Values',
      factory: props => <elements.GroupSelect {...props} />,
      formatValue: (vals, _fieldDef, _wgtDef, isForDisplay) => {
        return isForDisplay ? vals.map(v => '"' + v.value + '"') : vals.map(v => `"${v.value}"`);
      }
    },
    [MULTI_SELECT_GROUP_LEGACY]: {
      type: MULTI_SELECT_GROUP_LEGACY,
      jsType: 'array',
      valueSrc: 'value',
      valueLabel: 'Values',
      valuePLaceholder: 'Select Values',
      factory: props => <elements.GroupSelect {...props} />,
      formatValue: (vals, _fieldDef, _wgtDef, isForDisplay) => {
        return isForDisplay ? vals.map(v => '"' + v.value + '"') : vals.map(v => `"${v.value}"`);
      }
    },
    [CUSTOM_RELATIVE_DATES]: {
      jsType: 'string',
      valueSrc: 'value',
      valueLabel: 'Values',
      valuePlaceholder: 'Select Values',
      type: CUSTOM_RELATIVE_DATES,
      factory: props => <elements.RelativeDatePicker {...props} />
    },
    [LABELS_SELECT]: {
      ...BasicConfig.widgets.multiselect,
      factory: ({ value, fieldDefinition, setValue }) => {
        return (
          <common.LabelsSelect
            type={uiLib.SELECT_TYPES.DEFAULT}
            value={value?.map(v => Number(v?.value || v)) ?? null}
            onChange={setValue}
            {...fieldDefinition?.fieldSettings}
          />
        );
      },
      formatValue: (vals, _fieldDef, _wgtDef) => {
        return Array.isArray(vals) ? vals.map(v => `"${lodash.get(v, 'value', v)}"`) : [];
      }
    },
    [EITL_LABELS_SELECT]: {
      ...BasicConfig.widgets.multiselect,
      factory: ({ value, fieldDefinition, setValue }) => {
        return (
          <common.EitlLabelsSelect
            type={uiLib.SELECT_TYPES.DEFAULT}
            value={value?.map(v => Number(v?.value || v)) ?? null}
            onChange={setValue}
            {...fieldDefinition?.fieldSettings}
          />
        );
      },
      formatValue: (vals, _fieldDef, _wgtDef) => {
        return Array.isArray(vals) ? vals.map(v => `"${lodash.get(v, 'value', v)}"`) : [];
      }
    },
    [AUTHORS_SELECT]: {
      ...BasicConfig.widgets.multiselect,
      factory: ({ value, fieldDefinition, setValue }) => {
        return (
          <common.AuthorsSelect
            type={uiLib.SELECT_TYPES.DEFAULT}
            value={value?.map(v => Number(v?.value || v)) ?? null}
            onChange={setValue}
            {...fieldDefinition?.fieldSettings}
            isMulti
            isAsync
          />
        );
      },
      formatValue: (vals, _fieldDef, _wgtDef) => {
        return Array.isArray(vals) ? vals.map(v => `"${lodash.get(v, 'value', v)}"`) : [];
      }
    },
    [CONCEPTS_SELECT]: {
      ...BasicConfig.widgets.multiselect,
      factory: ({ value, fieldDefinition, setValue }) => {
        return (
          <common.ConceptsSelect
            type={uiLib.SELECT_TYPES.DEFAULT}
            value={value?.map(v => Number(v?.value || v)) ?? null}
            onChange={v => setValue(v)}
            {...fieldDefinition?.fieldSettings}
          />
        );
      },
      formatValue: (vals, _fieldDef, _wgtDef) => {
        return Array.isArray(vals) ? vals.map(v => `"${lodash.get(v, 'value', v)}"`) : [];
      }
    }
  };

  const types = {
    ...BasicConfig.types,
    [BOOLEAN]: lodash.merge(BasicConfig.types.boolean, {
      valueSources: ['value']
    }),
    [MULTI_SELECT_GROUP]: {
      defaultOperator: 'multiselect_equals',
      widgets: {
        [MULTI_SELECT_GROUP]: {
          widgetProps: {},
          operators: ['multiselect_equals', 'multiselect_not_equals']
        }
      },
      mainWidget: MULTI_SELECT_GROUP,
      valueSources: ['value'],
      operators: ['multiselect_equals', 'multiselect_not_equals']
    },
    [MULTI_SELECT_GROUP_LEGACY]: {
      defaultOperator: 'multiselect_equals',
      widgets: {
        [MULTI_SELECT_GROUP_LEGACY]: {
          widgetProps: {},
          operators: ['multiselect_equals', 'multiselect_not_equals']
        }
      },
      mainWidget: MULTI_SELECT_GROUP_LEGACY,
      valueSources: ['value'],
      operators: ['multiselect_equals', 'multiselect_not_equals']
    },
    [MULTI_SELECT_ASYNC]: {
      defaultOperator: 'multiselect_equals',
      widgets: {
        [MULTI_SELECT_ASYNC]: {
          widgetProps: {},
          operators: ['multiselect_equals', 'multiselect_not_equals']
        }
      },
      mainWidget: MULTI_SELECT_ASYNC,
      valueSources: ['value'],
      operators: ['multiselect_equals', 'multiselect_not_equals']
    },
    [DATE]: lodash.merge(BasicConfig.types.date, {
      widgets: {
        [CUSTOM_RELATIVE_DATES]: {
          widgetProps: {},
          operators: [
            relativeDatesConstants.REATIVE_DATE_OPERATORS.PAST,
            relativeDatesConstants.REATIVE_DATE_OPERATORS.NEXT
          ]
        },
        [DATE]: {
          opProps: {
            equal: { label: `${relativeDatesConstants.REATIVE_DATE_OPERATOR_LABELS.ON} (=)` },
            less: { label: `${relativeDatesConstants.REATIVE_DATE_OPERATOR_LABELS.BEFORE} (<)` },
            less_or_equal: {
              label: `${relativeDatesConstants.REATIVE_DATE_OPERATOR_LABELS.ON_OR_BEFORE} (<=)`
            },
            greater: { label: `${relativeDatesConstants.REATIVE_DATE_OPERATOR_LABELS.AFTER} (>)` },
            greater_or_equal: {
              label: `${relativeDatesConstants.REATIVE_DATE_OPERATOR_LABELS.ON_OR_AFTER} (>=)`
            }
          }
        }
      },
      operators: [
        'greater_or_equal',
        'less_or_equal',
        'equal',
        'not_equal',
        'less',
        'greater',
        relativeDatesConstants.REATIVE_DATE_OPERATORS.TODAY,
        relativeDatesConstants.REATIVE_DATE_OPERATORS.YESTERDAY,
        relativeDatesConstants.REATIVE_DATE_OPERATORS.TOMORROW
      ]
    }),
    [TEXT_BASIC]: lodash.merge(BasicConfig.types.text, {
      operators: ['equal', 'not_equal']
    }),
    [NUMBER]: lodash.merge(BasicConfig.types.number, {
      operators: ['equal', 'not_equal']
    }),
    [LABELS_SELECT]: {
      ...BasicConfig.types.multiselect,
      defaultOperator: advancedSearchConstants.QUERY_OPERATORS.MULTISELECT_EQUALS,
      widgets: {
        [LABELS_SELECT]: {
          widgetProps: {},
          operators: [
            advancedSearchConstants.QUERY_OPERATORS.MULTISELECT_EQUALS,
            advancedSearchConstants.QUERY_OPERATORS.MULTISELECT_NOT_EQUALS
          ]
        }
      }
    },
    [AUTHORS_SELECT]: {
      ...BasicConfig.types.multiselect,
      defaultOperator: advancedSearchConstants.QUERY_OPERATORS.MULTISELECT_EQUALS,
      widgets: {
        [AUTHORS_SELECT]: {
          widgetProps: {},
          operators: [
            advancedSearchConstants.QUERY_OPERATORS.MULTISELECT_EQUALS,
            advancedSearchConstants.QUERY_OPERATORS.MULTISELECT_NOT_EQUALS
          ]
        }
      }
    },
    [EITL_LABELS_SELECT]: {
      ...BasicConfig.types.multiselect,
      defaultOperator: advancedSearchConstants.QUERY_OPERATORS.MULTISELECT_EQUALS,
      widgets: {
        [EITL_LABELS_SELECT]: {
          widgetProps: {},
          operators: [
            advancedSearchConstants.QUERY_OPERATORS.MULTISELECT_EQUALS,
            advancedSearchConstants.QUERY_OPERATORS.MULTISELECT_NOT_EQUALS
          ]
        }
      }
    },
    [CONCEPTS_SELECT]: {
      ...BasicConfig.types.multiselect,
      defaultOperator: advancedSearchConstants.QUERY_OPERATORS.MULTISELECT_EQUALS,
      widgets: {
        [CONCEPTS_SELECT]: {
          widgetProps: {},
          operators: [
            advancedSearchConstants.QUERY_OPERATORS.MULTISELECT_EQUALS,
            advancedSearchConstants.QUERY_OPERATORS.MULTISELECT_NOT_EQUALS
          ]
        }
      }
    }
  };

  const localeSettings = {
    valueLabel: 'Value',
    valuePlaceholder: 'Value',
    fieldLabel: 'Field',
    operatorLabel: 'Operator',
    funcLabel: 'Function',
    fieldPlaceholder: 'Select field',
    funcPlaceholder: 'Select function',
    operatorPlaceholder: 'Select operator',
    deleteLabel: null,
    addGroupLabel: '+',
    addRuleLabel: '+',
    delGroupLabel: null,
    notLabel: 'NOT',
    valueSourcesPopupTitle: 'Select value source',
    removeRuleConfirmOptions: {
      title: 'Do you want to delete the filter?',
      okText: 'Yes',
      okType: 'danger'
    },
    removeGroupConfirmOptions: {
      title: 'Do you want to delete the filter group?',
      okText: 'Yes',
      okType: 'danger'
    }
  };

  const settings = {
    ...BasicConfig.settings,
    ...localeSettings,

    valueSourcesInfo: {
      value: {
        label: 'Value'
      }
    },
    canLeaveEmptyGroup: false,
    mustLeaveAtLeastOneRule: false,
    renderButton: props => <elements.Button {...props} />,
    renderConjs: props => <elements.ConjunctionGroup {...props} />,
    renderField: props => <elements.QueryFieldSelect withValueTooltips {...props} />,
    renderOperator: props => <elements.QueryFieldSelect {...props} />
  };

  //////////////////////////////////////////////////////////////////////

  const fields = {
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.ACT]: {
      label: 'Act',
      type: MULTI_SELECT_ASYNC,
      fieldSettings: {
        category: 'acts'
      }
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.ACT_ID]: {
      label: 'Act ID',
      type: NUMBER,
      fieldSettings: {
        min: 1
      }
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.AGENCY]: {
      label: 'Agency',
      type: MULTI_SELECT,
      listValues: agencies
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.AGENCY_ID]: {
      label: 'Agency ID',
      type: NUMBER,
      fieldSettings: {
        min: 1
      }
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.CONCEPT_ID]: {
      label: 'Concept ID',
      type: NUMBER,
      fieldSettings: {
        min: 1
      }
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.AUTO_SUMMARY_AVAILABLE]: {
      label: 'Auto Summary Available',
      type: BOOLEAN
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.AUTO_TRANSLATED_DOCUMENT]: {
      label: 'Auto Translated Document',
      type: BOOLEAN
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.HAS_PARENT]: {
      label: 'Has Parent Document',
      type: BOOLEAN
    },
    ...(userHasSKU(skuConstants.SKU_RESTRICTION_TYPES.BYOC)
      ? {
          [advancedSearchConstants.ADVANCED_SEARCH_KEYS.AUTHOR]: {
            label: 'Author',
            type: AUTHORS_SELECT
          },
          [advancedSearchConstants.ADVANCED_SEARCH_KEYS.AUTHOR_ID]: {
            label: 'Author ID',
            type: NUMBER,
            fieldSettings: {
              min: 1
            }
          },
          [advancedSearchConstants.ADVANCED_SEARCH_KEYS.IS_ORGANIZATION_DOCUMENT]: {
            label: 'Organization Document',
            type: BOOLEAN
          }
        }
      : {}),
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.BANK]: {
      label: 'Business',
      type: MULTI_SELECT_ASYNC,
      fieldSettings: {
        category: 'banks'
      }
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.BANK_ID]: {
      label: 'Bank ID',
      type: NUMBER,
      fieldSettings: {
        min: 1
      }
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.BUSINESS]: {
      label: 'Business ID',
      type: NUMBER,
      fieldSettings: {
        min: 1
      }
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.CAI_CATEGORY_LEGACY]: {
      label: 'Category (Legacy)',
      type: MULTI_SELECT_GROUP_LEGACY,
      options: docTypesLegacy
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.DOC_TYPE_LEGACY]: {
      label: 'Category (Legacy)',
      type: MULTI_SELECT_GROUP_LEGACY,
      options: docTypesLegacy
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.CITATION]: {
      label: 'Citation',
      type: TEXT_BASIC,
      fieldSettings: {}
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.CITATION_ID]: {
      label: 'Citation ID',
      type: TEXT_BASIC,
      fieldSettings: {}
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.COMMENTS_CLOSE_DATE]: {
      label: 'Comments Closed Date',
      type: DATE,
      operators: [
        'greater_or_equal',
        'less_or_equal',
        'less',
        'greater',
        'equal',
        relativeDatesConstants.REATIVE_DATE_OPERATORS.TODAY,
        relativeDatesConstants.REATIVE_DATE_OPERATORS.YESTERDAY,
        relativeDatesConstants.REATIVE_DATE_OPERATORS.TOMORROW,
        relativeDatesConstants.REATIVE_DATE_OPERATORS.PAST,
        relativeDatesConstants.REATIVE_DATE_OPERATORS.NEXT
      ]
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.COMMENTS_CLOSE_FROM]: {
      label: 'Comments Closed From',
      type: DATE,
      operators: ['equal']
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.COMMENTS_CLOSE_TO]: {
      label: 'Comments Closed To',
      type: DATE,
      operators: ['equal']
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.CAI_CATEGORY]: {
      label: 'Document Type',
      type: MULTI_SELECT_GROUP,
      options: docTypes
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.CONCEPT]: {
      label: 'Concept',
      type: CONCEPTS_SELECT,
      fieldSettings: {}
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.EFFECTIVE_DATE]: {
      label: 'Effective Date',
      type: DATE,
      operators: [
        'greater_or_equal',
        'less_or_equal',
        'less',
        'greater',
        'equal',
        relativeDatesConstants.REATIVE_DATE_OPERATORS.TODAY,
        relativeDatesConstants.REATIVE_DATE_OPERATORS.YESTERDAY,
        relativeDatesConstants.REATIVE_DATE_OPERATORS.TOMORROW,
        relativeDatesConstants.REATIVE_DATE_OPERATORS.PAST,
        relativeDatesConstants.REATIVE_DATE_OPERATORS.NEXT
      ]
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.EFFECTIVE_FROM]: {
      label: 'Effective From',
      type: DATE,
      operators: ['equal']
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.EFFECTIVE_TO]: {
      label: 'Effective To',
      type: DATE,
      operators: ['equal']
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.DIFFING_AVAILABLE]: {
      label: 'Diffing Available',
      type: BOOLEAN
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.DOC_ID]: {
      label: 'Document ID',
      type: NUMBER,
      fieldSettings: {
        min: 1
      }
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.DOCKET_ID]: {
      label: 'Docket ID',
      type: TEXT_BASIC,
      fieldSettings: {
        min: 1
      }
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.FULL_TEXT]: {
      label: 'Full Text',
      type: TEXT_BASIC
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.JURISDICTION]: {
      label: 'Jurisdiction',
      type: MULTI_SELECT,
      listValues: jurisdictions
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.LABEL]: {
      label: 'Label',
      type: LABELS_SELECT
    },
    ...(env.shouldShowEitlStatus && {
      [advancedSearchConstants.ADVANCED_SEARCH_KEYS.EITL_LABEL_ID]: {
        label: 'Eitl Label',
        type: EITL_LABELS_SELECT
      }
    }),
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.LANGUAGE_ID]: {
      label: 'Language',
      type: SELECT,
      listValues: languages,
      operators: ['select_equals']
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.LOCATION_ID]: {
      label: 'Location ID',
      type: NUMBER,
      fieldSettings: {
        min: 1
      }
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.LOCATION_TITLE]: {
      label: 'Location Title',
      type: TEXT_BASIC,
      fieldSettings: {}
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.MONETARY_PENALTY]: {
      label: 'Monetary Penalty',
      type: NUMBER,
      fieldSettings: {
        min: 0
      },
      operators: ['greater_or_equal', 'less_or_equal', 'equal', 'not_equal', 'less', 'greater']
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.MONETARY_PENALTY_MAX]: {
      label: 'Monetary Penalty Max',
      type: NUMBER,
      fieldSettings: {
        min: 0
      }
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.MONETARY_PENALTY_MIN]: {
      label: 'Monetary Penalty Min',
      type: NUMBER,
      fieldSettings: {
        min: 0
      }
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.MY_TASKS]: {
      label: filterConstants.MY_TASKS,
      type: BOOLEAN
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.MY_ORG_TASKS]: {
      label: filterConstants.MY_ORG_TASKS,
      type: BOOLEAN
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.NEWS_SOURCE]: {
      label: 'News & Premium Content',
      type: MULTI_SELECT,
      listValues: news_sources
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.NEWS_SOURCE_ID]: {
      label: 'News Source ID',
      type: NUMBER,
      fieldSettings: {
        min: 1
      }
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.OBLIGATIONS]: {
      label: 'Obligations',
      type: BOOLEAN
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.PREMIUM_CONTENT]: {
      label: 'Premium Content',
      type: BOOLEAN
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.PUBLICATION_DATE]: {
      label: 'Publication Date',
      type: DATE,
      operators: [
        'greater_or_equal',
        'less_or_equal',
        'less',
        'greater',
        'equal',
        relativeDatesConstants.REATIVE_DATE_OPERATORS.TODAY,
        relativeDatesConstants.REATIVE_DATE_OPERATORS.YESTERDAY,
        relativeDatesConstants.REATIVE_DATE_OPERATORS.TOMORROW,
        relativeDatesConstants.REATIVE_DATE_OPERATORS.PAST
      ]
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.HAS_UNOFFICIAL_PUB_DATE]: {
      label: 'Include Unofficial Publication Date',
      type: BOOLEAN
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.PUBLISHED_ON]: {
      label: 'Published On',
      type: DATE,
      operators: [
        'greater_or_equal',
        'less_or_equal',
        'less',
        'greater',
        'equal',
        relativeDatesConstants.REATIVE_DATE_OPERATORS.TODAY,
        relativeDatesConstants.REATIVE_DATE_OPERATORS.YESTERDAY,
        relativeDatesConstants.REATIVE_DATE_OPERATORS.TOMORROW,
        relativeDatesConstants.REATIVE_DATE_OPERATORS.PAST
      ]
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.PUBLISHED_FROM]: {
      label: 'Published From',
      type: DATE,
      operators: ['equal']
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.PUBLISHED_TO]: {
      label: 'Published To',
      type: DATE,
      operators: ['equal']
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.REGULATION]: {
      label: 'Regulation',
      type: MULTI_SELECT,
      listValues: regulations
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.REGULATION_ID]: {
      label: 'Regulation ID',
      type: NUMBER,
      fieldSettings: {
        min: 1
      }
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.RESPONDENT]: {
      label: 'Respondent',
      type: TEXT_BASIC
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.TEXT]: {
      label: 'Text',
      type: TEXT_BASIC
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.EXACT_MATCH_TEXT]: {
      label: 'Exact match text',
      type: TEXT_BASIC
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.TITLE]: {
      label: 'Title',
      type: TEXT_BASIC
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.EXACT_MATCH_TITLE]: {
      label: 'Exact match title',
      type: TEXT_BASIC
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.TOPIC]: {
      label: 'Topic',
      type: MULTI_SELECT,
      listValues: topics
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.TOPIC_ID]: {
      label: 'Topic ID',
      type: NUMBER,
      fieldSettings: {
        min: 1
      }
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.VIOLATION]: {
      label: 'Violation',
      type: TEXT_BASIC
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.HAS_INDIVIDUAL_RESPONDENT]: {
      label: 'Has Individual Respondent',
      type: BOOLEAN
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.HAS_ENTITY_RESPONDENT]: {
      label: 'Has Entity Respondent',
      type: BOOLEAN
    },
    ...(env.shouldShowEitlStatus && {
      [advancedSearchConstants.ADVANCED_SEARCH_KEYS.HAS_EITL_LABELS]: {
        label: 'Has EITL Labels',
        type: BOOLEAN
      }
    }),
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.ADDED_DATE]: {
      label: 'Added to Compliance.ai',
      type: DATE,
      operators: [
        'greater_or_equal',
        'less_or_equal',
        'less',
        'greater',
        'equal',
        relativeDatesConstants.REATIVE_DATE_OPERATORS.TODAY,
        relativeDatesConstants.REATIVE_DATE_OPERATORS.YESTERDAY,
        relativeDatesConstants.REATIVE_DATE_OPERATORS.TOMORROW,
        relativeDatesConstants.REATIVE_DATE_OPERATORS.PAST
      ]
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.ADDED_FROM]: {
      label: 'Added to Compliance.ai From',
      type: DATE,
      operators: ['equal']
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.ADDED_TO]: {
      label: 'Added to Compliance.ai To',
      type: DATE,
      operators: ['equal']
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.UPDATED_DATE]: {
      label: 'Updated in Compliance.ai',
      type: DATE,
      operators: [
        'greater_or_equal',
        'less_or_equal',
        'less',
        'greater',
        'equal',
        relativeDatesConstants.REATIVE_DATE_OPERATORS.TODAY,
        relativeDatesConstants.REATIVE_DATE_OPERATORS.YESTERDAY,
        relativeDatesConstants.REATIVE_DATE_OPERATORS.TOMORROW,
        relativeDatesConstants.REATIVE_DATE_OPERATORS.PAST
      ]
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.UPDATED_FROM]: {
      label: 'Updated in Compliance.ai From',
      type: DATE,
      operators: ['equal']
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.UPDATED_TO]: {
      label: 'Updated in Compliance.ai To',
      type: DATE,
      operators: ['equal']
    },
    [advancedSearchConstants.ADVANCED_SEARCH_KEYS.EXCLUDE_FEDERAL_REGISTER]: {
      label: 'Exclude Federal Register',
      type: BOOLEAN
    }
  };

  return {
    conjunctions,
    operators,
    widgets,
    types,
    settings,
    fields: Object.fromEntries(
      Object.entries(fields).sort(([fieldKeyA, fieldA], [fieldKeyB, fieldB]) => {
        return sortUtils.alphabetically(
          {
            name: fieldA.label
          },
          {
            name: fieldB.label
          }
        );
      })
    )
  };
};
