import { fetch } from 'utils/api';

export function api_getJurisdictionAnalyzerDocuments(url) {
  return fetch({
    url: '/documents' + url,
    method: 'GET',
    dataType: 'Jurisdictions'
  });
}

export function api_getJurisdictionsDocumentCounts(params) {
  return fetch({
    url: '/documents/jurisdictions-aggregate',
    params: params,
    method: 'POST',
    dataType: 'Jurisdictions'
  });
}
