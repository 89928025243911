import { fetch } from 'utils/api';

export function api_getWidgets() {
  return fetch({
    url: '/widgets',
    dataType: 'Widgets',
    method: 'GET'
  });
}

export function api_saveWidget(params) {
  return fetch({
    url: '/save_widgets',
    dataType: 'Widgets',
    method: 'POST',
    params
  });
}

export function api_deleteWidget({ widgetId }) {
  return fetch({
    url: `/widgets/${widgetId}`,
    dataType: 'Widgets',
    method: 'DELETE'
  });
}
