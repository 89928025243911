import { Loader, LOADER_SIZES } from '@compliance.ai/web-components';
import { useDidMount } from 'utils/hooks';
import { useAuthReduxActions } from 'shared/features/auth/hooks';
import { useAuthCheckHandlers, useAuthCheckData } from './hooks';

export const AuthCheck = ({ children }) => {
  const { localState, localActions } = useAuthCheckData();

  const { handleSessionRefreshSuccess, handleSessionRefreshError } = useAuthCheckHandlers({
    localActions
  });

  const reduxActions = useAuthReduxActions();

  useDidMount(() => {
    reduxActions
      .refreshSessionTokens()
      .then(handleSessionRefreshSuccess)
      .catch(handleSessionRefreshError);
  });

  return localState.isLoading ? (
    <Loader size={LOADER_SIZES.LARGE} withoutBackground={false} />
  ) : (
    children
  );
};
