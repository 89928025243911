import type { FC } from 'react';
import type { ManageBucketAccessProps } from './ManageBucketAccess.types';

import * as uiLib from '@compliance.ai/web-components';

import { useManageBucketAccessTableColumns, useManageBucketAccessQueries } from './hooks';

import './_manage-bucket-access.scss';

export const ManageBucketAccess: FC<ManageBucketAccessProps> = props => {
  const queries = useManageBucketAccessQueries({ props });

  const columns = useManageBucketAccessTableColumns({ props });

  return (
    <uiLib.ErrorBoundary>
      <uiLib.Table
        columns={columns}
        rows={queries.bucketAccessRulesQuery.data?.rows}
        shouldRenderToolbar={false}
        isLoading={queries.bucketAccessRulesQuery.isFetching}
        classNames={{
          tableContainer: 'manage-bucket-access-table__container',
          headerCell: 'manage-bucket-access-table__header-cell',
          cell: 'manage-bucket-access-table__cell'
        }}
      />
    </uiLib.ErrorBoundary>
  );
};
