import type { PrintProps } from '../Print.types';
import type { PrintQueriesHandlers } from './usePrintQueriesHandlers.types';

import * as constants from '../Print.constants';
import * as queryKeys from 'constants/QueryKeys';
import * as errorUtils from 'utils/errors';

import { useQuery } from '@tanstack/react-query';
import { useDocumentsApi } from 'shared/features/documents/hooks';

export const usePrintQueries = ({
  props,
  events
}: {
  props: Pick<PrintProps, 'documentIds' | 'queryOptions'>;
  events: {
    onDocumentsFetchSuccess: PrintQueriesHandlers['handleDocumentsFetchSuccess'];
  };
}) => {
  const documentsApi = useDocumentsApi();

  const fetchDocuments = useQuery({
    enabled: false,
    queryKey: [
      ...queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.DOCUMENTS][
        queryKeys.QUERY_SECTIONS.PRINT_ACTION
      ](props.documentIds, props.queryOptions?.extraQueryKey)
    ],
    queryFn: () => {
      return documentsApi.fetchDocuments({
        doc_id: props.documentIds,
        exclusive_fields: constants.EXCLUSIVE_FIELDS
      });
    },
    retry: false,
    refetchOnWindowFocus: false,
    onError: errorUtils.logReactQueryError,
    onSuccess: events.onDocumentsFetchSuccess
  });

  return {
    fetchDocuments
  };
};
