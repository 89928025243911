import React from 'react';
import { connect } from 'react-redux';
import DocumentList from './elements/DocumentList';

class Timeline extends React.PureComponent {
  render() {
    return (
      <DocumentList
        isLoading={this.props.isLoading}
        error={this.props.documents.error}
        documents={{ items: this.props.documents.combined_list }}
        count={this.props.documents.count}
        current_view={this.props.current_view}
        location={this.props.location}
        last_doc_ref={this.props.last_doc_ref}
        first_doc_ref={this.props.first_doc_ref}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    documents: state.documents,
    current_view: state.current_view
  };
};

const ReduxTimeline = connect(mapStateToProps)(Timeline);

export default ReduxTimeline;
