import { safe_analytics } from 'utils/analytics';
import { COGNITO_LOGOUT_URL } from 'shared/features/auth/auth.constants';

export const useGoogleLoginHandlers = ({ props, reduxActions }) => {
  const handleGoogleSignInButtonClick = showWindow => () => {
    safe_analytics('default', 'Login', 'Button click', 'Google Login');
    showWindow(COGNITO_LOGOUT_URL);
  };

  const handleGoogleSignInError = errorMessage => {
    props.onError(errorMessage);
  };

  const handleGoogleSignInSuccess = async code => {
    try {
      await reduxActions.authenticateUsingCognitoCode({
        code: code,
        orgInvitationToken: props.orgInvitationToken
      });

      props.onSuccess();
    } catch (e) {
      props.onError(e.message);
    }
  };

  return {
    handleGoogleSignInButtonClick,
    handleGoogleSignInError,
    handleGoogleSignInSuccess
  };
};
