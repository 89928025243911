import type { NavBarItemSourceConfig } from './useAppNavigationHandlers.types';
import type { AppNavigationData, AppNavigationReduxState } from './useAppNavigationData.types';
import type { ParsedQuery } from 'query-string';
import type { useLocation } from 'react-router-dom';

import * as searchUtils from 'utils/search';
import * as navBarConstants from './useAppNavigationNavBarConfig.constants';
import * as documentsSelectors from 'shared/features/documents/documents.selectors';
import * as uiLib from '@compliance.ai/web-components';

import { QUERY_PARAMS } from 'components/Filters/EnforcementFilter/EnforcementFilter.constants';
import { DOCUMENT_META_CATEGORIES } from 'constants/DocumentConstants';
import { DEFAULT_ENFORCEMENT_EXCLUSIVE_FIELDS } from 'utils/enforcements/enforcements';
import { SUPPORTED_QUERY_PARAMS } from 'constants/PrimaryFilter';
import queryString from 'utils/query-string';

export const formatNavBarItemRoute = ({
  source,
  query = {}
}: Pick<NavBarItemSourceConfig, 'source' | 'query'>) =>
  `/${source.toLowerCase()}?${queryString.stringify(query)}`;

export const getEnforcementFilterDefaultQueryParams = ({
  defaults,
  user,
  query,
  docTypes,
  actionBarFilterValues,
  topicThresholdKey,
  shouldShowNewSearch
}: Pick<
  AppNavigationReduxState,
  'defaults' | 'docTypes' | 'actionBarFilterValues' | 'topicThresholdKey'
> & {
  user: AppNavigationReduxState['currentUser']['user'];
  query: ParsedQuery;
  shouldShowNewSearch?: boolean;
}) => {
  const { properties: userProperties, languageId: userLanguageId } = user;
  let followedAgencyIdsToSet: number[] = [];
  let followedJurisdictionsToSet: number[] = [];

  if (!defaults.isFetching) {
    followedAgencyIdsToSet = defaults.followedAgenciesIds;
  }

  const publishedFrom = uiLib.getDateBeforeDate({
    date: new Date(),
    amount: 30,
    unitOfTime: 'days'
  });

  const { cai_metacategories: metacategories } = docTypes;
  const caiCategoryParams = documentsSelectors.getAllCategoriesIDsForMetacategoryName(
    DOCUMENT_META_CATEGORIES.ENFORCEMENTS,
    metacategories
  );

  return {
    ...(shouldShowNewSearch
      ? {}
      : {
          [QUERY_PARAMS.EXCLUSIVE_FIELDS]: DEFAULT_ENFORCEMENT_EXCLUSIVE_FIELDS,
          [QUERY_PARAMS.LIMIT]: actionBarFilterValues.rowsCount
        }),
    [QUERY_PARAMS.CAI_CATEGORY]: caiCategoryParams,
    [QUERY_PARAMS.SEARCH_SORT]:
      userProperties.search_sort || query.search_sort || 'publication_date',
    [QUERY_PARAMS.ORDER]: userProperties.order || query.order || 'asc',
    [QUERY_PARAMS.PUBLISHED_FROM]: uiLib.formatDate(publishedFrom, {
      format: uiLib.DATE_FORMATS.AMERICAN_DATE
    }),
    [QUERY_PARAMS.AGENCY]: followedAgencyIdsToSet,
    [QUERY_PARAMS.LANGUAGE_ID]: userLanguageId,
    [QUERY_PARAMS.JURISDICTION]: followedJurisdictionsToSet,
    [SUPPORTED_QUERY_PARAMS.TOPIC_THRESHOLD_KEY]: topicThresholdKey,
    [SUPPORTED_QUERY_PARAMS.HAS_UNOFFICIAL_PUBLICATION_DATE]: false
  };
};

export const getPrimaryFilterDefaultQueryParams = ({
  currentUser,
  defaults,
  actionBarFilterValues,
  topicThresholdKey
}: Pick<
  AppNavigationData['reduxState'],
  'currentUser' | 'defaults' | 'actionBarFilterValues' | 'topicThresholdKey'
>) => {
  let followedAgencyIdsToSet: number[] = [];
  let followedJurisdictionsToSet: number[] = [];
  let followedTopicIdsToSet: number[] = [];
  let followedNewsSourceIdsToSet: number[] = [];
  let followedConceptsToSet: string[] = [];

  if (!defaults.isFetching) {
    followedAgencyIdsToSet = defaults.followedAgenciesIds;
    followedTopicIdsToSet = defaults.followedTopicsIds;
    followedNewsSourceIdsToSet = defaults.followedNewsSources;
    followedConceptsToSet = defaults.followedConceptIds;
  }

  return {
    [SUPPORTED_QUERY_PARAMS.AGENCY_ID]: followedAgencyIdsToSet,
    [SUPPORTED_QUERY_PARAMS.JURISDICTION]: followedJurisdictionsToSet,
    [SUPPORTED_QUERY_PARAMS.TOPIC_ID]: followedTopicIdsToSet,
    [SUPPORTED_QUERY_PARAMS.NEWS_SOURCE_ID]: followedNewsSourceIdsToSet,
    [SUPPORTED_QUERY_PARAMS.CONCEPT_ID]: followedConceptsToSet,
    [SUPPORTED_QUERY_PARAMS.LANGUAGE_ID]: currentUser.user.languageId,
    [SUPPORTED_QUERY_PARAMS.LIMIT]: actionBarFilterValues.rowsCount,
    [SUPPORTED_QUERY_PARAMS.TOPIC_THRESHOLD_KEY]: topicThresholdKey,
    [SUPPORTED_QUERY_PARAMS.HAS_UNOFFICIAL_PUBLICATION_DATE]: false
  };
};

export const getSelectedNavBarItemLabelByLocation = ({
  location
}: {
  location: ReturnType<typeof useLocation>;
}) => {
  const parsedQuery = queryString.parse(location.search);
  const { section } = searchUtils.get_search_view(parsedQuery, location);

  return (navBarConstants.NAV_BAR_SECTIONS_TO_LABEL_MAP[
    section as navBarConstants.NAV_BAR_ITEM_SECTIONS
  ] ?? null) as navBarConstants.NAV_BAR_ITEM_LABELS;
};
