import type { WorkflowTaskDeadlineHandlers } from './useWorkflowTaskDeadlineHandlers.types';
import type { WorkflowTaskDeadlineProps } from '../WorkflowTaskDeadline.types';
import type { WorkflowTaskDeadlineLocalActions } from './useWorkflowTaskDeadlineData.types';

import { isValidDate } from '@compliance.ai/web-components';
import { EDITABLE_FIELDS_KEYS } from 'constants/TaskConstants';
import { useCallback } from 'react';

export const useWorkflowTaskDeadlineHandlers = ({
  props,
  localActions
}: {
  props: Pick<WorkflowTaskDeadlineProps, 'deadline' | 'handleSelectChange'>;
  localActions: WorkflowTaskDeadlineLocalActions;
}): WorkflowTaskDeadlineHandlers => {
  const handleDeadlineChange: WorkflowTaskDeadlineHandlers['handleDeadlineChange'] = date => {
    localActions.setSelectedDeadline(date);

    if (isValidDate(date)) {
      props.handleSelectChange(EDITABLE_FIELDS_KEYS.DEADLINE, date);
    }
  };

  const handleDeadlinePropChange: WorkflowTaskDeadlineHandlers['handleDeadlinePropChange'] = useCallback(() => {
    localActions.setSelectedDeadline(props.deadline);
  }, [localActions, props.deadline]);

  return {
    handleDeadlineChange,
    handleDeadlinePropChange
  };
};
