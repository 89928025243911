import type { FC } from 'react';
import type { AppNavigationProps } from './AppNavigation.types';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';

import {
  useAppNavigationData,
  useAppNavigationHandlers,
  useAppNavigationLifecycle,
  useAppNavigationNavBarConfig,
  useAppNavigationRightPanelConfig
} from './hooks';

import './_app-navigation.scss';

export const AppNavigation: FC<AppNavigationProps> = ({ children }) => {
  const { localState, localActions, formattedData, reduxState } = useAppNavigationData();

  const handlers = useAppNavigationHandlers({
    localActions,
    reduxState,
    formattedData
  });

  const navBarConfig = useAppNavigationNavBarConfig({
    localState,
    formattedData,

    events: {
      onNavBarToggle: handlers.handleNavBarToggle,
      onNavBarItemClick: handlers.handleNavBarItemClick,
      onNavBarTimelineClick: handlers.handleNavBarTimelineClick,
      onNavBarEnforcementClick: handlers.handleNavBarEnforcementClick,
      onNavBarNewsClick: handlers.handleNavBarNewsClick
    }
  });

  const rightPanelConfig = useAppNavigationRightPanelConfig({
    reduxState,

    events: {
      onRightPanelCollapse: handlers.handleRightPanelCollapse,
      onRightPanelClose: handlers.handleRightPanelClose,
      onHideAnimationComplete: handlers.handleWindowResizeEventCall,
      onToggleAnimationComplete: handlers.handleWindowResizeEventCall,
      onRightPanelDragStart: handlers.handleRightPanelDragStart,
      onRightPanelDragEnd: handlers.handleRightPanelDragEnd
    }
  });

  useAppNavigationLifecycle({
    onLocationChange: handlers.handleLocationChange,
    onRightPanelOpenOnMount: handlers.handleRightPanelOpenOnMount,
    onRightPanelToggleAfterReduxChange: handlers.handleRightPanelToggleAfterReduxChange,
    onRightPanelQueryParamsChange: handlers.handleRightPanelQueryParamsChange,
    onRightPanelCloseOnRouteChange: handlers.handleRightPanelCloseOnRouteChange,
    onRightPanelResetOnUnmount: handlers.handleRightPanelResetOnUnmount
  });

  return (
    <>
      <elements.AppNotifications />
      <uiLib.AppNavigation
        topBarConfig={{
          searchElement: <elements.AppSearch />,
          menuElement: <elements.TopBarMenu />
        }}
        navBarConfig={navBarConfig}
        rightPanelConfig={rightPanelConfig}
        classNames={{
          container: 'app-navigation__container',
          contentContainer: 'app-navigation__content-container',
          topBar: {
            mainContainer: 'app-navigation__top-bar-main-container'
          },
          navBarDrawer: {
            content: 'app-navigation__nav-bar-drawer-content'
          }
        }}
      >
        <elements.Overlay shouldDisableIframeMouseEvents={localState.isIframeMouseEventsDisabled} />
        {children}
      </uiLib.AppNavigation>
    </>
  );
};
