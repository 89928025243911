import type { TopicsSelectData } from './useTopicsSelectData.types';
import type { TopicsSelectProps } from '../TopicsSelect.types';
import type { GlobalState } from 'shared/reducers';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getSourcesReadyState, getTopicsOptions } from 'shared/features/sources/sources.selectors';
import { getValuesFromOptions } from 'utils/select-utils';

export const useTopicsSelectData = (
  props: Pick<TopicsSelectProps, 'value' | 'extraOptions' | 'isMulti'>
): TopicsSelectData => {
  const reduxState = useSelector<GlobalState, TopicsSelectData['reduxState']>(state => ({
    options: getTopicsOptions(state as never),
    isReady: getSourcesReadyState(state)
  }));

  const formattedData: TopicsSelectData['formattedData'] = useMemo(() => {
    const options = [...(props.extraOptions ?? []), ...reduxState.options];

    const value = getValuesFromOptions({
      isMulti: props.isMulti,
      options: options,
      value: props.value
    });

    return {
      options,
      value
    };
  }, [props.extraOptions, props.isMulti, props.value, reduxState.options]);

  return {
    reduxState,
    formattedData
  };
};
