import type { BucketAttributeLinkCellHandlers } from './useBucketAttributeLinkCellHandlers.types';
import type { BucketAttributeLinkCellProps } from '../BucketAttributeLinkCell.types';

import * as bucketHelpers from 'shared/features/buckets/buckets.helpers';

export const useBucketAttributeLinkCellHandlers = ({
  props
}: {
  props: Pick<BucketAttributeLinkCellProps, 'cellValue'>;
}): BucketAttributeLinkCellHandlers => {
  const handleLinkClick: BucketAttributeLinkCellHandlers['handleLinkClick'] = () => {
    bucketHelpers.sendBucketsAnalytics({
      action: 'navigation',
      eventLabel: `navigate to ${props.cellValue.attribute}`
    });
  };

  return {
    handleLinkClick
  };
};
