import { useCallback } from 'react';
import { isRelativeDate, isSingleRelativeDate } from 'utils/relativeDate-utils';
import { parseDateFromString } from '../CustomDatePicker.helpers';
import {
  DEFAULT_PAST_RELATIVE_DATE_AMOUNT,
  DEFAULT_PAST_RELATIVE_DATE_DURATION
} from '../CustomDatePicker.constants';
import { isAfter, isBefore } from '@compliance.ai/web-components';

export const useCustomDatePickerDateHandlers = ({
  props: {
    subject,
    startDate,
    endDate,
    onError,
    onChange,
    dateKey,
    startDateKey,
    endDateKey,
    dateOrderErrorKey,
    areDatesRequired
  },
  localActions
}) => {
  /**
   *
   * Handle selected date changes
   * Selected date will be changed by one of "Today", "Yesterday", "Tomorrow", "On",
   * and "In the past X Days/Weeks/Months/Years" operators
   */
  const handleSelectedDateChange = useCallback(
    value => {
      // Reset errors
      onError(dateKey, undefined);

      let date = value;
      // If it's a string then try and parse into a date
      if (typeof value === 'string' || value instanceof Date) {
        if (!isRelativeDate(value)) {
          date = parseDateFromString(value);
          if (!date) {
            onChange(dateKey, date);
            onError(dateKey, `${subject} date is invalid`);
            return;
          }
        }
      }

      if (!date) {
        if (areDatesRequired) {
          onError(dateKey, `${subject} date is required to search`);
        }
      }

      onChange(dateKey, date);
    },
    [subject, areDatesRequired, dateKey, onChange, onError]
  );

  /**
   *
   * Handle selected startDate changes
   * Selected startDate will be changed by "After" and "Between" operators
   */
  const handleSelectedStartDateChange = useCallback(
    value => {
      // Reset errors
      onError(startDateKey, undefined);
      onError(dateOrderErrorKey, undefined);

      let date = value;
      // If it's a string then try and parse into a date
      if (typeof value === 'string' || value instanceof Date) {
        date = parseDateFromString(value);
        if (!date) {
          onChange(startDateKey, date);
          onError(startDateKey, `${subject} start date is invalid`);
          return;
        }
      }

      if (!date) {
        if (areDatesRequired) {
          onError(startDateKey, `${subject} start date is required to search`);
        }
      } else {
        // Compare the dates
        if (isAfter(date, endDate)) {
          onError(dateOrderErrorKey, `${subject} start date must be earlier than end date`);
        }
      }

      onChange(startDateKey, date);
    },
    [subject, areDatesRequired, startDateKey, dateOrderErrorKey, endDate, onChange, onError]
  );

  /**
   *
   * Handle selected endDate changes
   * Selected endDate will be changed by "Before" and "Between" operators
   */
  const handleSelectedEndDateChange = useCallback(
    value => {
      // Reset errors
      onError(endDateKey, undefined);
      onError(dateOrderErrorKey, undefined);

      let date = value;
      // If it's a string then try and parse into a date
      if (typeof value === 'string' || value instanceof Date) {
        date = parseDateFromString(value);

        if (!date) {
          onChange(endDateKey, date);
          onError(endDateKey, `${subject} end date is invalid`);
          return;
        }
      }

      if (isBefore(date, startDate)) {
        onError(dateOrderErrorKey, `${subject} start date must be earlier than end date`);
      } else {
        onError(dateOrderErrorKey, undefined);
      }

      onError(endDateKey, undefined);
      onChange(endDateKey, date);
    },
    [subject, endDateKey, dateOrderErrorKey, startDate, onChange, onError]
  );

  /**
   *
   * Handle selected operator changes
   */
  const handleSelectedOperatorChange = useCallback(
    val => {
      const { value = null } = val || {};

      if (value && isSingleRelativeDate(value)) {
        handleSelectedDateChange(value);

        localActions.setAmount(DEFAULT_PAST_RELATIVE_DATE_AMOUNT);
        localActions.setDuration(DEFAULT_PAST_RELATIVE_DATE_DURATION);
      } else {
        handleSelectedDateChange(null);
      }

      handleSelectedStartDateChange(null);
      handleSelectedEndDateChange(null);

      localActions.setOperator(value);
    },
    [
      localActions,
      handleSelectedDateChange,
      handleSelectedStartDateChange,
      handleSelectedEndDateChange
    ]
  );

  return {
    handleSelectedOperatorChange,
    handleSelectedDateChange,
    handleSelectedStartDateChange,
    handleSelectedEndDateChange
  };
};
