import type { ComponentProps } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';
import * as workflowSummaryCommonCells from "../CommonCells";

export enum TABLE_FIELDS {
  ID = 'id',
  STEP = 'step',
  NAME = 'name',
  DECISION = 'decision',
  DROPDOWNS = 'dropdowns',
  DATES = 'dates',
  ATTACHMENTS = 'attachments',
  ASSIGNEE = 'assignee',
  APPROVER = 'approver',
  APPROVAL = 'approval',
  NON_RELEVANT = 'non_relevant'
}

export const TABLE_COLUMNS: Required<ComponentProps<typeof uiLib.Table>>['columns'] = [
  {
    title: 'Step',
    dataKey: TABLE_FIELDS.STEP,
    width: '4rem'
  },
  {
    title: 'Name',
    dataKey: TABLE_FIELDS.NAME,
    width: '15rem',
    className: 'tasks-section__cell--is-name',
    Component: uiLib.TextCell
  },
  {
    title: 'Decision',
    dataKey: TABLE_FIELDS.DECISION,
    Component: uiLib.TextCell
  },
  {
    title: 'Dropdowns',
    dataKey: TABLE_FIELDS.DROPDOWNS,
    className: 'tasks-section__cell--is-dropdowns',
    Component: elements.DropdownCell
  },
  {
    title: 'Dates',
    dataKey: TABLE_FIELDS.DATES,
    Component: elements.DateCell
  },
  {
    title: 'Attachments',
    dataKey: TABLE_FIELDS.ATTACHMENTS,
    width: '15rem',
    Component: workflowSummaryCommonCells.AttachmentsCell
  },
  {
    title: 'Original Assignee',
    dataKey: TABLE_FIELDS.ASSIGNEE,
    Component: elements.AssigneeCell
  },
  {
    title: 'Approver',
    dataKey: TABLE_FIELDS.APPROVER,
    className: 'tasks-section__cell--is-approver',
    Component: elements.ApproverCell
  },
  {
    title: 'Approval Required',
    dataKey: TABLE_FIELDS.APPROVAL,
    Component: elements.ApprovalCell
  },
  {
    title: 'Task Relevant?',
    dataKey: TABLE_FIELDS.NON_RELEVANT,
    Component: elements.RelevancyCell
  }
];
