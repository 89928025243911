import PropTypes from 'prop-types';
import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import { useSearchButtonData } from './useSearchButtonData';
import { useSearchButtonHandlers } from './useSearchButtonHandlers';
import { Tooltip } from 'common';

const OVER_SEARCH_LIMIT_WARNING = 'You have reached your monthly limit on searches.';

const SearchButton = ({ onClick, disabled, text, queryParams, type, className, searchType }) => {
  const { isDisabled, overSearchLimit } = useSearchButtonData({ disabled });

  const { onSearchClick } = useSearchButtonHandlers({
    onClick,
    queryParams,
    overSearchLimit,
    searchType
  });

  if (overSearchLimit) {
    return (
      <Tooltip title={OVER_SEARCH_LIMIT_WARNING}>
        <Button onClick={onSearchClick} type={type} className={className} isDisabled={isDisabled}>
          {text}
        </Button>
      </Tooltip>
    );
  }
  return (
    <Button onClick={onSearchClick} type={type} className={className} isDisabled={isDisabled}>
      {text}
    </Button>
  );
};

SearchButton.propTypes = {
  type: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
  queryParams: PropTypes.string,
  startIcon: PropTypes.element,
  onClick: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  searchType: PropTypes.string
};

SearchButton.defaultProps = {
  type: BUTTON_TYPES.PRIMARY,
  onClick: () => {},
  text: null,
  disabled: false,
  queryParams: '',
  searchType: ''
};

export default SearchButton;
