import type { FilterValue } from '@compliance.ai/web-components';

export enum FILTER_KEY {
  NAME = 'name',
  CREATED_BY = 'created_by',
  UPDATED_FROM = 'updated_from',
  UPDATED_TO = 'updated_to'
}

export const ALERTS_FILTERS_DEFAULT_VALUES: Partial<Record<FILTER_KEY, FilterValue>> = {
  [FILTER_KEY.CREATED_BY]: null,
  [FILTER_KEY.UPDATED_FROM]: null,
  [FILTER_KEY.UPDATED_TO]: null
};
