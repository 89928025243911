import * as sentenceNotesConstants from 'shared/features/sentenceNotes/sentenceNotes.constants';
export const LOADER_MESSAGES: Record<sentenceNotesConstants.SENTENCE_NOTE_TYPE, string[]> = {
  [sentenceNotesConstants.SENTENCE_NOTE_TYPE.ANNOTATIONS]: [
    'Loading more annotations...',
    'Populating annotation list...'
  ],
  [sentenceNotesConstants.SENTENCE_NOTE_TYPE.OBLIGATIONS]: [
    'Loading more obligations...',
    'Populating obligation list...'
  ]
};

export const TEST_ID = 'annotation-panel-container';
