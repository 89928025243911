import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import * as uiLib from '@compliance.ai/web-components';

import './_right-panel-labeled-datefield.scss';

export const RightPanelLabeledDatefield = ({
  value,
  label,
  isEditable,
  isEditableStateControlled,
  withTextfield,
  onChange
}) => {
  const [isDatefieldEditable, toggleDatefieldEditableMode] = useState(isEditable);

  useEffect(() => {
    if (isEditableStateControlled) {
      toggleDatefieldEditableMode(isEditable);
    }
  }, [isEditable, isEditableStateControlled]);

  if (isDatefieldEditable || !withTextfield) {
    return (
      <uiLib.LabeledField label={label} isContrast>
        <div className="right-panel-labeled-datefield__wrapper">
          <uiLib.DatePicker
            value={value}
            onChange={onChange}
            className="right-panel-labeled-datefield"
            placeholder={uiLib.getLocalDateFormat().toLowerCase()}
            format={uiLib.getLocalDateFormat()}
          />
        </div>
      </uiLib.LabeledField>
    );
  }

  if (value) {
    return (
      <uiLib.LabeledField label={label} isContrast>
        <div className="right-panel-labeled-datefield__wrapper">
          <input
            type="text"
            className="right-panel-labeled-datefield__selected-value"
            value={value}
            disabled
          />
        </div>
      </uiLib.LabeledField>
    );
  }

  return null;
};

RightPanelLabeledDatefield.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  isEditable: PropTypes.bool,
  isEditableStateControlled: PropTypes.bool,
  withTextfield: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

RightPanelLabeledDatefield.defaultProps = {
  value: null,
  label: 'Date',
  isEditable: false,
  isEditableStateControlled: false,
  withTextfield: false
};
