import type { FC } from 'react';
import type { AddBucketModalProps } from './AddBucketModal.types';

import * as uiLib from '@compliance.ai/web-components';

import {
  useAddBucketModalData,
  useAddBucketModalHandlers,
  useAddBucketModalMutations
} from './hooks';

import './_add-bucket-modal.scss';

export const AddBucketModal: FC<AddBucketModalProps> = ({
  onModalActionSuccess,
  onModalClose,
  onModalActionStart
}) => {
  const { localState, localActions, formattedData } = useAddBucketModalData();

  const handlers = useAddBucketModalHandlers({
    localActions
  });

  const mutations = useAddBucketModalMutations({
    props: {
      onModalActionSuccess,
      onModalClose,
      onModalActionStart
    },
    localState
  });

  return (
    <uiLib.Modal
      title="Add collection"
      isOpen
      onClose={onModalClose}
      withFooter
      primaryButtonType={uiLib.BUTTON_TYPES.PRIMARY}
      primaryButtonText="Add"
      secondaryButtonType={uiLib.BUTTON_TYPES.LINK}
      secondaryButtonText="Cancel"
      isPrimaryButtonDisabled={formattedData.isAddBucketButtonDisabled}
      onSubmit={mutations.addBucket.mutate}
      classNames={{
        container: 'add-bucket-modal',
        content: 'add-bucket-modal__container',
        title: 'add-bucket-modal__title'
      }}
    >
      <uiLib.LabeledField
        label={
          <uiLib.Typography className="add-bucket-modal__input-label">
            Type to add the collection
          </uiLib.Typography>
        }
      >
        <uiLib.TextField
          value={localState.bucketName}
          placeholder="Enter a new collection name"
          onChange={handlers.handleBucketNameChange}
        />
      </uiLib.LabeledField>
    </uiLib.Modal>
  );
};
