import type { FC } from 'react';
import type { OfficialPubDateCheckboxProps } from './OfficialPubDateCheckbox.types';

import { Tooltip, Checkbox } from '@compliance.ai/web-components';
import classnames from 'classnames';
import { DEFAULT_LABEL, DEFAULT_TOOLTIP_TITLE } from './OfficialPubDateCheckbox.constants';
import './_official-pub-date-checkbox.scss';

export const OfficialPubDateCheckbox: FC<OfficialPubDateCheckboxProps> = ({
  label,
  tooltipTitle,
  isChecked,
  onChange,
  classNames
}) => {
  return (
    <Checkbox
      label={<Tooltip title={tooltipTitle}>{label}</Tooltip>}
      isChecked={isChecked}
      onChange={onChange}
      classNames={{
        checkbox: classnames('official-pub-date-checkbox', classNames?.checkbox),
        label: classnames('official-pub-date-checkbox__label', classNames?.label)
      }}
    />
  );
};

OfficialPubDateCheckbox.defaultProps = {
  label: DEFAULT_LABEL,
  tooltipTitle: DEFAULT_TOOLTIP_TITLE,
  classNames: undefined
};
