import type { EditMetadataButtonProps } from './EditMetadataButton.types';
import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './EditMetadataButton.constants';

import { useEditMetadataButtonData, useEditMetadataButtonQueries } from './hooks';

export const EditMetadataButton: FC<EditMetadataButtonProps> = ({
  document,
  onEditMetadataOpen
}) => {
  const { localActions, formattedData } = useEditMetadataButtonData({
    document,
    onEditMetadataOpen
  });

  const queries = useEditMetadataButtonQueries({
    props: {
      document
    },
    localActions,
    formattedData
  });

  if (!formattedData.isButtonShown) {
    return null;
  }

  return (
    <uiLib.ActionButton
      isDisabled={!document}
      isLoading={queries.syncUpdateRequestsStatus.data?.isUpdating}
      onClick={onEditMetadataOpen}
      tooltip={constants.BUTTON_TITLE}
      tooltipPlacement={uiLib.TOOLTIP_POSITION.BOTTOM}
      type={uiLib.ACTION_BUTTON_TYPE.TRANSPARENT_CONTRAST}
    >
      <uiLib.IconEdit />
    </uiLib.ActionButton>
  );
};
