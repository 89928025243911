import type { BucketPermissionsCellProps } from '../BucketPermissionsCell.types';
import type { NormalizedBucket } from 'shared/features/buckets/buckets.actions.types';
import type { TeamFromResponse, UserFromResponse } from 'shared/features/user/user.types';

import pluralize from 'pluralize';

export const getBucketPermissionsTeamsIds = (
  permissions: NormalizedBucket['permissions']
): TeamFromResponse['id'][] =>
  permissions
    ? [...new Set([...permissions.groupReadAccess, ...permissions.groupWriteAccess])]
    : [];

export const getBucketPermissionsMembersIds = (
  permissions: NormalizedBucket['permissions']
): UserFromResponse['id'][] =>
  permissions ? [...new Set([...permissions.userReadAccess, ...permissions.userWriteAccess])] : [];

export const formatMembersCount = (teamsCount: number) =>
  `${teamsCount} ${pluralize('member', teamsCount)}`;
export const formatTeamsCount = (teamsCount: number) =>
  `${teamsCount} ${pluralize('team', teamsCount)}`;

export const formatBucketPermissionsTeamsAndMembers = ({
  bucket,
  orgTeamMemberIds,
  permissionsTeamIds,
  permissionsMemberIds
}: {
  bucket: BucketPermissionsCellProps['cellValue']['bucket'];
  orgTeamMemberIds: UserFromResponse['id'][];
  permissionsTeamIds: TeamFromResponse['id'][];
  permissionsMemberIds: UserFromResponse['id'][];
}): string => {
  const allMembers = [...new Set([bucket.createdBy.id, ...orgTeamMemberIds])];

  const additionalMemberIds = permissionsMemberIds.filter(
    memberId => !allMembers.includes(memberId)
  );

  const membersCount = allMembers.length + additionalMemberIds.length;

  if (!permissionsTeamIds.length) {
    return formatMembersCount(membersCount);
  }

  return `${formatTeamsCount(permissionsTeamIds.length)}, ${formatMembersCount(membersCount)}`;
};
