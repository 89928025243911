import * as localStorageConstants from 'constants/LocalStorage';

import { QueryClient } from '@tanstack/react-query';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

export const QUERY_CLIENT = new QueryClient();

export const QUERY_CLIENT_PERSISTER = createSyncStoragePersister({
  storage: window.localStorage,
  key: localStorageConstants.LOCAL_STORAGE_KEYS.REACT_QUERY_OFFLINE_CACHE
});
