export const TEST_IDS = {
  CONTAINER: 'advanced-search-builder-container',
  CONCEPTS: 'advanced-search-concepts-container',
  FOOTER: 'advanced-search-builder-footer'
};

export const SELECT_TYPES = {
  CONCEPTS: 'concepts',
  VIEW: 'view'
};

export const TEXTS = {
  LABEL: {
    [SELECT_TYPES.CONCEPTS]: 'Select Concepts',
    CONCEPT_SELECT: 'Add concept to advanced filter',
    [SELECT_TYPES.VIEW]: 'Select View',
    FILTER: 'Filter'
  },
  BUTTON: {
    APPLY: { [SELECT_TYPES.CONCEPTS]: 'Apply concepts', [SELECT_TYPES.VIEW]: 'Apply view' },
    SELECT: { [SELECT_TYPES.VIEW]: 'Select concepts', [SELECT_TYPES.CONCEPTS]: 'Select view' },
    CLEAR_FILTER: 'Clear Filter',
    SEARCH: 'Search',
    PREVIEW: 'Preview',
    UPDATE_ALERT: 'Update Alert'
  }
};
