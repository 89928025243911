import type { DocumentsTableProps } from './DocumentsTable.types';
import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './DocumentsTable.constants';
import * as elements from './elements';

import classnames from 'classnames';

import {
  useDocumentsTableData,
  useDocumentsTableHandlers,
  useDocumentsTableLifecycle,
  useDocumentsTableSelector
} from './hooks';

import './_documents-table.scss';

export const DocumentsTable: FC<DocumentsTableProps> = ({
  requestParams,
  externalTableParams,
  activeDocumentId,
  resultsCountPlacement,
  onSelectedDocumentsChange,
  onRelatedDocumentsClick,
  onTopicClick,
  shouldDisableDropdownsPortal,
  shouldUseSavedTableSettings,
  shouldShowDocumentActions,
  shouldShowRefreshButton,
  shouldShowColumnsSettingsButton,
  shouldShowSorting,
  shouldUpdateQueryParams,
  tableSettingsKey,
  isDocumentClickable,
  isDocumentChicletClickable,
  onDocumentClick,
  onTableActionStart,
  rowsCountOptions,
  customActions,
  customToolbarElements,
  preTableContent,
  navBar,
  activeTabIndex,
  shouldUseCustomOffsets,
  tabs,
  onTabChange,
  identityIterator,
  classNames
}) => {
  const { prevProps, localState, localActions, formattedData, reduxState } = useDocumentsTableData({
    requestParams,
    tableSettingsKey,
    shouldUseSavedTableSettings,
    rowsCountOptions,
    externalTableParams,
    shouldUseCustomOffsets
  });

  const handlers = useDocumentsTableHandlers({
    props: {
      requestParams,
      onSelectedDocumentsChange,
      shouldUseSavedTableSettings,
      tableSettingsKey,
      rowsCountOptions,
      shouldUseCustomOffsets
    },
    prevProps,
    localState,
    localActions,
    reduxState
  });

  const tableSelector = useDocumentsTableSelector({
    props: {
      isDocumentChicletClickable,
      isDocumentClickable,
      activeDocumentId,
      identityIterator,
      onDocumentClick,
      onRelatedDocumentsClick,
      onTopicClick
    },
    localState,
    reduxState: { isAuthenticated: reduxState.isAuthenticated },
    events: {
      onTableRefresh: handlers.handleTableRefresh
    }
  });

  useDocumentsTableLifecycle({
    onRequestParamsChange: handlers.handleRequestParamsChange,
    onTableSettingsInitialLoad: handlers.handleTableSettingsInitialLoad,
    onSortStateToUrlReflection: handlers.handleSortStateToUrlReflection,
    onRouteChange: handlers.handleRouteChange
  });

  return (
    <uiLib.Table
      activeRowKey={activeDocumentId}
      columns={formattedData.columns}
      queryOptions={{ select: tableSelector }}
      preTableContent={preTableContent}
      onVisibleColumnsChange={handlers.handleVisibleFieldsChange}
      shouldUpdateQueryParams={shouldUpdateQueryParams}
      shouldShowRefreshButton={shouldShowRefreshButton}
      limitToDisplay={localState.limitToDisplay}
      columnsSettingsConfig={{
        shouldDisplayActionButtons: true,
        customTitle: 'Show in table'
      }}
      customActionsElements={
        <>
          {shouldShowDocumentActions && (
            <elements.DocumentActions
              documentIds={localState.selectedDocsIds}
              onTableActionStart={onTableActionStart}
              customActions={customActions}
            />
          )}
          <div
            className={classnames({
              ['documents-table__custom-toolbar-elements']: true,
              ['documents-table__custom-toolbar-elements--is-count-near-pagination']:
                resultsCountPlacement === constants.RESULTS_COUNT_PLACEMENT.NEAR_PAGINATION
            })}
          >
            {customToolbarElements}
            <uiLib.Typography shouldTruncate>
              {resultsCountPlacement === constants.RESULTS_COUNT_PLACEMENT.NEAR_PAGINATION &&
                `${localState.allDocumentsCount} results`}
              {resultsCountPlacement === constants.RESULTS_COUNT_PLACEMENT.NEAR_SELECT_ALL && (
                <>
                  {localState.allDocumentsCount} documents from Compliance.ai &nbsp;
                  {localState.selectedDocsIds.length > 0 ? '-' : ''}
                  &nbsp;
                </>
              )}
            </uiLib.Typography>
          </div>
        </>
      }
      customToolsElements={
        <>
          {shouldShowSorting && (
            <elements.DocumentsSort
              sort={localState.sort}
              sortOptions={constants.SORT_OPTIONS}
              onSortChange={handlers.handleSortChange}
            />
          )}
          <elements.DocumentsTableSettings
            onChange={handlers.handleTableSettingsChange}
            shouldDisablePortal={shouldDisableDropdownsPortal}
            rowsCountOptions={rowsCountOptions}
            value={{
              [elements.DOCUMENTS_TABLE_SETTINGS.ROWS_COUNT]:
                localState.tableParams[uiLib.TABLE_PARAMS.LIMIT]
            }}
          />
        </>
      }
      rowsType={'document'}
      reloadTrigger={localState.tableRefreshTrigger}
      isLoading={localState.isLoading}
      selectedRowsCountPlacement={uiLib.SELECTED_ROWS_COUNT_PLACEMENT.TOOLBAR}
      rowKey={constants.TABLE_KEYS.ID}
      areRowsSelectable
      shouldShowColumnsSettingsButton={shouldShowColumnsSettingsButton}
      localStorageKey={constants.LOCAL_STORAGE_KEY}
      navBar={
        <>
          {Boolean(tabs?.length) && (
            <elements.DocumentsTabs
              activeTabIndex={activeTabIndex}
              tabs={tabs}
              onTabChange={onTabChange}
            />
          )}
          {navBar}
        </>
      }
      onDataFetch={handlers.handleDocumentsFetch}
      onSelectedRowKeysChange={handlers.handleSelectedDocumentsChange}
      onSelectAllClick={handlers.handleDocumentsIdsFetch}
      onParamsChange={handlers.handleTableParamsChange}
      externalParams={formattedData.externalParams}
      shouldSkipRefetchLoading={localState.shouldSkipRefetchLoading}
      classNames={{
        container: classNames?.container,
        tableContainer: classNames?.tableContainer,
        table: classNames?.table,
        toolbar: classnames('documents-table__toolbar', classNames?.toolbar),
        row: classnames('documents-table__row', classNames?.row, {
          'documents-table__row--is-clickable': isDocumentClickable
        }),
        rowSelected: classnames('documents-table__row--is-selected', classNames?.rowSelected),
        rowActive: classnames('documents-table__row--is-active', classNames?.rowActive),
        cell: classnames('documents-table__cell', classNames?.cell),
        headerCell: classnames('documents-table__cell--is-header', classNames?.headerCell),
        checkboxCell: classnames('documents-table__cell--is-checkbox', classNames?.checkboxCell),
        selectedRowsCount: classnames(
          'documents-table__selected-rows-count',
          classNames?.selectedRowsCount
        ),
        actionsContainer: classnames(
          'documents-table__actions-container',
          classNames?.actionsContainer
        )
      }}
    />
  );
};

DocumentsTable.defaultProps = {
  isDocumentClickable: false,
  isDocumentChicletClickable: false,
  tableSettingsKey: constants.DEFAULT_TABLE_SETTINGS_KEY,
  shouldUseSavedTableSettings: true,
  resultsCountPlacement: constants.RESULTS_COUNT_PLACEMENT.NEAR_SELECT_ALL,
  shouldShowSorting: true
};
