import lodash from 'lodash';
import { INITIAL_JA_DOCUMENTS_LIMIT } from 'constants/JurisdictionAnalyzer';
import {
  REQUEST_JURISDICTION_ANALYZER_DOCUMENTS,
  RECEIVE_JURISDICTION_ANALYZER_DOCUMENTS,
  FAIL_JURISDICTION_ANALYZER_DOCUMENTS,
  REQUEST_JURISDICTIONS_DOCUMENT_COUNTS,
  RECEIVE_JURISDICTIONS_DOCUMENT_COUNTS,
  FAIL_JURISDICTIONS_DOCUMENT_COUNTS,
  TOGGLE_SELECT_LOADED_JURISDICTION_DOCUMENTS,
  CHANGE_SELECTED_JURISDICTION,
  RECEIVE_JURISDICTIONS_DOCUMENT_COUNTS_FOR_ALERT,
  RESET_JURISDICTION_ANALYZER,
  UPDATE_VIEWED_JURISDICTIONS
} from './jurisdictionAnalyzer.actions';

export const JA_INITIAL_STATE = {
  documents: {},
  isLoading: false,
  countComparisons: {
    // EXAMPLE DATA:
    // [alertId]: { US-CA: 5 }
  },
  jurisdictionDocumentMap: {
    // EXAMPLE DATA:
    // US-CA: {
    //  selectable: true,
    //  documentsLoaded: false,
    //  documentsLoading: false,
    //  documents: [],
    //  selectedIds: [],
    //  count: 0,
    //  limit: 40
    // }
  },
  selectedJurisdiction: null,
  viewedJurisdictions: {
    // Example data: { <jurisdiction>: [<viewed_document_id>] }
  },
  error: null
};

export const reducer = (state = JA_INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_JURISDICTION_ANALYZER:
      return {
        ...JA_INITIAL_STATE
      };

    case CHANGE_SELECTED_JURISDICTION:
      return {
        ...state,
        selectedJurisdiction: action.jurisdiction
      };

    case UPDATE_VIEWED_JURISDICTIONS:
      const { jurisdiction, documentId } = action;
      return {
        ...state,
        viewedJurisdictions: {
          ...state.viewedJurisdictions,
          [jurisdiction]: [
            ...lodash.uniq([...lodash.get(state.viewedJurisdictions, jurisdiction, []), documentId])
          ]
        }
      };

    case RECEIVE_JURISDICTION_ANALYZER_DOCUMENTS:
      return {
        ...state,
        jurisdictionDocumentMap: {
          ...state.jurisdictionDocumentMap,
          [action.jurisdiction]: {
            ...state.jurisdictionDocumentMap[action.jurisdiction],
            documentsLoaded: true,
            documentsLoading: false,
            limit: action.limit,
            offset: action.offset,
            shortName: action.jurisdiction,
            documents: [
              ...lodash.uniq([
                ...lodash.get(state.jurisdictionDocumentMap[action.jurisdiction], 'documents', []),
                ...action.payload.documents
              ])
            ],
            selected: action.selectingAll
              ? action.payload.documents.map(({ id }) => id)
              : lodash.get(state.jurisdictionDocumentMap[action.jurisdiction], 'selected', [])
          }
        }
      };
    case FAIL_JURISDICTION_ANALYZER_DOCUMENTS:
      return {
        ...state,
        jurisdictionDocumentMap: {
          ...state.jurisdictionDocumentMap,
          [action.jurisdiction]: {
            ...state.jurisdictionDocumentMap[action.jurisdiction],
            documentsLoaded: false,
            documentsLoading: false,
            error: action.error
          }
        }
      };
    case REQUEST_JURISDICTION_ANALYZER_DOCUMENTS:
      return {
        ...state,
        jurisdictionDocumentMap: {
          ...state.jurisdictionDocumentMap,
          [action.jurisdiction]: {
            ...state.jurisdictionDocumentMap[action.jurisdiction],
            selected: [],
            documentsLoaded: false,
            documentsLoading: true,
            error: null
          }
        }
      };

    case REQUEST_JURISDICTIONS_DOCUMENT_COUNTS:
      return {
        ...state,
        isLoading: true
      };

    case TOGGLE_SELECT_LOADED_JURISDICTION_DOCUMENTS:
      return {
        ...state,
        jurisdictionDocumentMap: {
          ...state.jurisdictionDocumentMap,
          [action.jurisdiction]: {
            ...state.jurisdictionDocumentMap[action.jurisdiction],
            selected: (data => {
              const documentIds = data.documents.map(({ id }) => id);
              const selected = lodash.get(data, 'selected', []);

              // Toggling specific document ids
              if (action.documentIds) {
                const [
                  unselectedDocumentIds,
                  selectedDocumentIds
                ] = lodash.partition(action.documentIds, documentId =>
                  selected.includes(documentId)
                );

                return [
                  ...selected.filter(documentId => !unselectedDocumentIds.includes(documentId)),
                  ...selectedDocumentIds
                ];
              }

              // Otherwise using bulk select
              const selectingAll = !lodash.isEqual(documentIds, selected);

              return selectingAll ? documentIds : [];
            })(state.jurisdictionDocumentMap[action.jurisdiction])
          }
        }
      };

    case RECEIVE_JURISDICTIONS_DOCUMENT_COUNTS_FOR_ALERT:
      return {
        ...state,
        countComparisons: {
          [action.alertId]: Object.entries(action.jurisdictionDocumentMap).reduce(
            (obj, [jurisdiction, count]) => ({
              ...obj,
              [jurisdiction]: count
            }),
            {}
          )
        }
      };

    case RECEIVE_JURISDICTIONS_DOCUMENT_COUNTS:
      return {
        ...state,
        isLoading: false,
        jurisdictionDocumentMap: Object.entries(action.jurisdictionDocumentMap).reduce(
          (obj, [jurisdiction, count]) => ({
            ...obj,
            [jurisdiction]: {
              documents: [],
              documentsLoaded: false,
              documentsLoading: false,
              shortName: jurisdiction,
              count,
              name: jurisdiction,
              limit: INITIAL_JA_DOCUMENTS_LIMIT,
              offset: 0,
              selected: [],
              selectable: action.selectedJurisdictions.includes(jurisdiction)
            }
          }),
          {}
        )
      };

    case FAIL_JURISDICTIONS_DOCUMENT_COUNTS:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };

    default:
      return state;
  }
};
