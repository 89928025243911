import type * as types from './Pipeline.types';
import type * as stages from '../stages';

export class Pipeline<R = stages.FilesMetadata> implements types.Pipeline<R> {
  readonly initialMetadata: types.Pipeline['initialMetadata'];
  readonly stages: types.Pipeline['stages'];

  private readonly stagesCallCounts: types.PipelinePrivateFields['stagesCallCounts'];

  constructor(args: {
    initialMetadata: types.Pipeline['initialMetadata'];
    stages: types.Pipeline['stages'];
  }) {
    this.initialMetadata = args.initialMetadata;
    this.stages = args.stages;
    this.stagesCallCounts = new Map();
  }

  private readonly increaseStageCallsCount: types.PipelinePrivateFields['increaseStageCallsCount'] = stage => {
    this.stagesCallCounts.set(stage.constructor.name, this.getStageCallCount(stage) + 1);

    return this.stagesCallCounts;
  };

  private readonly getStageCallCount: types.PipelinePrivateFields['getStageCallCount'] = stage => {
    return this.stagesCallCounts.get(stage.constructor.name) ?? 0;
  };

  private readonly resetStageCallsCount: types.PipelinePrivateFields['resetStageCallsCount'] = () => {
    this.stagesCallCounts.clear();

    return this.stagesCallCounts;
  };

  readonly run: types.Pipeline<R>['run'] = async () => {
    const metadataEntries = Object.entries(this.initialMetadata);
    const processedFilesMetadataEntries = [];

    for (const [fileName, metadata] of metadataEntries) {
      const initialMetadata = Object.entries(metadata);

      let processedMetadata = initialMetadata;

      for (const stage of this.stages) {
        processedMetadata = await stage.run({
          metadata: processedMetadata,
          initialMetadata: initialMetadata,
          stageCallCount: this.getStageCallCount(stage),
          allFilesInitialMetadata: this.initialMetadata
        });

        this.increaseStageCallsCount(stage);
      }

      processedFilesMetadataEntries.push([fileName, Object.fromEntries(processedMetadata)]);
    }

    this.resetStageCallsCount();

    return Object.fromEntries(processedFilesMetadataEntries);
  };
}
