export const SAVE_ALERT_SUCCESS_MODAL_TYPES = {
  CREATE: 'create',
  MODIFY: 'modify'
};

export enum VIEW_KEY {
  TIMELINE = 'timeline',
  NEWS = 'news',
  ALL = 'all',
  ENFORCEMENT = 'enforcement'
}
