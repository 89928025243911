export const LOCAL_STORAGE_KEYS = {
  ORG_INVITATION_TOKEN: 'org_invitation_token',
  IS_CONTRIBUTOR: 'is_contributor',
  MARKETING_CAMPAIGN_TOKEN: 'marketing_campaign_token',
  TERMS_AGREED: 'termsAgreed',
  COGNITO_POOL_INFORMATION_ID: 'cognitoPoolInformationId',
  CORPORATE_LOGIN_EMAIL: 'corporateLoginEmail',
  ANONYMOUS_FILTER: 'anonymousFilter',
  PAGE_HAS_BEEN_FORCED_REFRESHED: 'page-has-been-force-refreshed',
  CORPORATE_LOGIN_JWT_TOKEN: 'corporateLoginJWTToken',
  CORPORATE_LOGIN_APPROVED_APP_URl: 'corporateLoginApprovedAppUrl',
  SENT_ACTIVITY_ANALYTICS: 'sentActivityAnalytics',
  REACT_QUERY_OFFLINE_CACHE: 'reactQueryCache',
  /**
   * TODO Remove this key once there won't be any users with the old cache
   **/
  DEPRECATED_REACT_QUERY_OFFLINE_CACHE: 'REACT_QUERY_OFFLINE_CACHE'
};
