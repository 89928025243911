import type { NewsAndPremiumContentSelectProps } from './NewsAndPremiumContentSelect.types';
import type { SelectProps } from '@compliance.ai/web-components';

import { Select, SELECT_TYPES } from '@compliance.ai/web-components';
import { useNewsAndPremiumContentSelectData } from './hooks/useNewsAndPremiumContentSelectData';

export const NewsAndPremiumContentSelect = (props: NewsAndPremiumContentSelectProps) => {
  const { reduxState, formattedData } = useNewsAndPremiumContentSelectData(props);

  return (
    <Select
      {...props}
      onChange={props.onChange as SelectProps['onChange']}
      type={props.type ?? SELECT_TYPES.DEFAULT}
      isLoading={!reduxState.isReady || props.isLoading}
      isDisabled={!reduxState.isReady || props.isDisabled}
      options={reduxState.options}
      value={formattedData.value}
    />
  );
};
