import type { ReadableQueryData } from './useReadableQueryData.types';
import type { ReadableQueryHandlers } from './useReadableQueryHandlers.types';

export const useReadableQueryHandlers = ({
  localActions
}: {
  localActions: ReadableQueryData['localActions'];
}): ReadableQueryHandlers => {
  const handlePlainTextModalToggle: ReadableQueryHandlers['handlePlainTextModalToggle'] = () => {
    localActions.setIsPlainTextModalOpen(isOpen => !isOpen);
  };

  return {
    handlePlainTextModalToggle
  };
};
