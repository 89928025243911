import type { ApproversSelectHandlers } from './useApproversSelectHandlers.types';
import type { ApproversSelectProps } from '../ApproversSelect.types';
import type { ApproversSelectData } from './useApproversSelectData.types';

export const useApproversSelectHandlers = ({
  props,
  localState,
  reduxState
}: {
  props: Pick<ApproversSelectProps, 'onSubmit' | 'primaryApprover'>;
  localState: ApproversSelectData['localState'];
  reduxState: ApproversSelectData['reduxState'];
}): ApproversSelectHandlers => {
  const handleSubmit: ApproversSelectHandlers['handleSubmit'] = () => {
    if (localState.selectedApprover) {
      return props.onSubmit(localState.selectedApprover.value);
    }

    if (props.primaryApprover && reduxState.primaryApproverActive) {
      props.onSubmit(props.primaryApprover);
    }

    if (reduxState.approversOptions.length === 1) {
      props.onSubmit(reduxState.approversOptions[0].value);
    }
  };

  return {
    handleSubmit
  };
};
