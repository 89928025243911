import type { DocumentNoteFromResponse } from 'shared/features/documentNotes/documentNotes.api.types';
import type { OrgLabelFromResponse } from 'shared/features/organizations/organizations.types';

import type * as notesApi from 'shared/features/documentNotes/documentNotes.api';

export const formatDocumentNoteLabels = (
  response: Awaited<ReturnType<typeof notesApi.fetchDocumentNotes>>
): {
  labels: OrgLabelFromResponse[];
  documentNote: DocumentNoteFromResponse | null;
} => {
  const documentNote = response.document_notes[0] ?? null;

  return {
    labels:
      documentNote?.labels?.map?.(label => ({
        value: label.value,
        label: label.label,
        bucket: label.bucket,
        is_editable: label.is_editable
      })) ?? [],
    documentNote: documentNote
  };
};
