import PropTypes from 'prop-types';
import {
  Dropdown,
  Select,
  LabeledField,
  TextField,
  IconMenu,
  SELECT_TYPES
} from '@compliance.ai/web-components';
import { ROW_COUNT_OPTIONS } from 'constants/ActionBar';
import { FILTER_KEY } from 'constants/ActionBarFilter';
import { POWER_USER } from 'constants/UserRoles';
import { useUserReduxActions } from 'shared/features/user/hooks';
import {
  useAdditionalFiltersData,
  useAdditionalFiltersHandlers,
  useAdditionalFiltersLifecycle
} from './hooks';
import './_additional-filters.scss';

export const AdditionalFilters = ({ onRowsCountChange, isDisabled }) => {
  const { localState, localActions, reduxState } = useAdditionalFiltersData();

  const userReduxActions = useUserReduxActions();

  const handlers = useAdditionalFiltersHandlers({
    props: {
      onRowsCountChange
    },
    localState,
    localActions
  });

  useAdditionalFiltersLifecycle({
    reduxState,
    localState,
    localActions,
    userReduxActions
  });

  if (isDisabled) {
    return <IconMenu className="additional-filters__dropdown-button-icon--is-disabled" />;
  }

  return (
    <Dropdown
      isOpen={localState.isOpen}
      onClose={handlers.handleClose}
      containerClassName="additional-filters__dropdown-button"
      className="additional-filters__dropdown"
      triggerContent={
        <IconMenu
          className="additional-filters__dropdown-button-icon"
          onClick={handlers.handleOpen}
        />
      }
      dropdownContent={
        <div className="additional-filters__inputs-wrapper">
          <LabeledField label="# of docs per page">
            <Select
              type={SELECT_TYPES.DEFAULT}
              isClearable={false}
              options={ROW_COUNT_OPTIONS}
              value={reduxState.actionBarFilterParams[FILTER_KEY.ROWS_COUNT]}
              onChange={handlers.handleRowsCountChange}
              placeholder="# of docs per page"
            />
          </LabeledField>
          <LabeledField label="Min. Score">
            <TextField
              onChange={handlers.handleMinScoreChange}
              onBlur={handlers.handleMinScoreSubmit}
              value={localState.minScore}
              type="number"
              isDisabled={!reduxState.currentUserRoles.includes(POWER_USER)}
              placeholder="Min. Score"
            />
          </LabeledField>
          <LabeledField label="Topic Threshold">
            <Select
              type={SELECT_TYPES.DEFAULT}
              isClearable={false}
              options={reduxState.topicThresholdSelectOptions}
              value={localState.topicThreshold}
              onChange={handlers.handleTopicThresholdChange}
              placeholder="Topic Threshold"
            />
          </LabeledField>
        </div>
      }
    />
  );
};

AdditionalFilters.propTypes = {
  onRowsCountChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired
};
