import type { ButtonProps } from './Button.types';
import type { FC } from 'react';

import {
  ActionButton,
  Tooltip,
  TOOLTIP_POSITION,
  TOOLTIP_TYPES
} from '@compliance.ai/web-components';
import { COMPONENT_PROPS } from './Button.constants';
import './_button.scss';

export const Button: FC<ButtonProps> = ({ type, onClick }) => {
  const Icon = COMPONENT_PROPS[type].icon;
  const tooltipTitle = COMPONENT_PROPS[type].tooltipTitle;

  return (
    <Tooltip
      title={tooltipTitle}
      placement={TOOLTIP_POSITION.TOP}
      type={TOOLTIP_TYPES.SECONDARY}
      classNames={{
        elementWrapper: 'advanced-search-button__tooltip-wrapper'
      }}
    >
      <ActionButton onClick={onClick}>
        <Icon />
      </ActionButton>
    </Tooltip>
  );
};
