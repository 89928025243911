import type { TopBarMenuHandlers } from './useTopBarMenuHandlers.types';

import * as analyticsUtils from 'utils/analytics';

import { CORPORATE_WEBSITE_SIGN_UP_URL } from 'constants/CorporateWebsiteLinks';

export const useTopBarMenuHandlers = (): TopBarMenuHandlers => {
  const handleSignUpClick: TopBarMenuHandlers['handleSignUpClick'] = () => {
    analyticsUtils.safe_ga('send', 'event', 'button', 'click', 'Create an Account');
    analyticsUtils.safe_mixpanel_track('Sign up – Button click – Create an Account', {
      eventCategory: 'Sign up',
      eventAction: 'Button click',
      eventLabel: 'Create an Account'
    });

    window.location.href = CORPORATE_WEBSITE_SIGN_UP_URL;
  };

  return {
    handleSignUpClick
  };
};
