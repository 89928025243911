import { apiUrl, apiKey } from 'shared/config';
import { getJWTToken } from 'utils/auth';

export function fetchDocumentPDF(doc) {
  let params = null;
  const url = apiUrl + '/document_pdf/' + doc.id;

  return new Promise((resolve, reject) => {
    // use pain in the ass XMLHttpRequest directly because reqwest doesn't handle binary data
    const xhr = new XMLHttpRequest();

    xhr.onload = evt => {
      if (xhr.status === 200) {
        resolve(xhr.response);
      } else {
        reject(xhr);
      }
    };

    const error_fn = e => {
      reject(xhr);
    };
    xhr.onabort = error_fn;
    xhr.onerror = error_fn;

    xhr.open('POST', url);
    xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    xhr.setRequestHeader('Authorization', getJWTToken() || apiKey);
    xhr.responseType = 'arraybuffer';
    xhr.send(JSON.stringify(params));
  });
}

export function generateFilename(title, doc_id, prefix = 'Compliance_ai_', suffix = 'pdf') {
  title = title.slice(0, 50);
  title = title.replace(/ /g, '_');
  title = title.replace(/[^a-zA-Z0-9_-]/g, '');
  return `${prefix}${title}_${doc_id}.${suffix}`;
}
