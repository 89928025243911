import type { FC } from 'react';
import type { InputProps } from './Input.types';

import { IconDiagram, TextField } from '@compliance.ai/web-components';
import './_input.scss';

export const Input: FC<InputProps> = ({
  value,
  placeholder,
  onToggleButtonClick,
  onChange,
  onSubmit
}) => {
  return (
    <div className="advanced-search-input__container">
      <button onClick={onToggleButtonClick} className="advanced-search-input__toggle-button">
        <IconDiagram className="advanced-search-input__toggle-button-icon" />
        Filter
      </button>
      <TextField
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        withSearchIcon
        fullWidth
        onSubmit={onSubmit}
      />
    </div>
  );
};
