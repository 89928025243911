import type * as storeTypes from 'store';

import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../workflowMold.actions';

export const useWorkflowMoldReduxActions = () => {
  const dispatch = useDispatch<storeTypes.StoreDispatch>();

  const fetchWorkflowMolds = useCallback(
    (...args: Parameters<typeof actions.fetchWorkflowMolds>) => {
      return dispatch(actions.fetchWorkflowMolds(...args));
    },
    [dispatch]
  );

  const updateWorkflowMold = useCallback(
    (...args: Parameters<typeof actions.updateWorkflowMold>) => {
      return dispatch(actions.updateWorkflowMold(...args));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      fetchWorkflowMolds,
      updateWorkflowMold
    }),
    [fetchWorkflowMolds, updateWorkflowMold]
  );
};
