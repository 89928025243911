import type { GlobalState } from 'shared/reducers';
import type { TopBarMenuData } from './useTopBarMenuData.types';

import * as authSelectors from 'shared/features/auth/auth.selectors';
import * as viewSelectors from 'shared/features/view/view.selectors';

import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const useTopBarMenuData = (): TopBarMenuData => {
  const reduxState: TopBarMenuData['reduxState'] = useSelector<
    GlobalState,
    TopBarMenuData['reduxState']
  >(state => ({
    isMobileView: viewSelectors.isMobileView(state),
    isAuthenticated: authSelectors.getUserAuthenticatedState(state)
  }));

  const formattedData: TopBarMenuData['formattedData'] = useMemo(
    () => ({
      shouldRenderLogin: !reduxState.isAuthenticated && !reduxState.isMobileView,
      shouldRenderActions: !reduxState.isMobileView
    }),
    [reduxState.isAuthenticated, reduxState.isMobileView]
  );

  return {
    reduxState,
    formattedData
  };
};
