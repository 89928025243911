import type {
  PreDocumentTask,
  WorkflowTaskFromResponse
} from 'shared/features/workflow/workflow.types';
import type { WorkflowModalProps } from '../WorkflowModal.types';
import type { WorkflowModalData } from './useWorkflowModalData.types';

import lodash from 'lodash';
import { isNotAssigneeDependentOnTaskOrDependencyTask } from 'shared/features/workflow/workflow.helpers';
import { getDeadline, isPastDueTask } from 'utils/task-utils';

export const formatPreDocTasksFromWorkflowTasks = ({
  tasks,
  documents
}: {
  tasks: WorkflowTaskFromResponse[];
  documents: WorkflowModalProps['documents'];
}): Record<number, PreDocumentTask[]> => {
  const preDocumentTasks = [];

  for (const document of documents) {
    for (const task of tasks) {
      if (isNotAssigneeDependentOnTaskOrDependencyTask(task, 0, tasks)) {
        preDocumentTasks.push({
          ...task,
          doc_id: document.id,
          document: document,
          deadline: getDeadline({ document, task }),
          isPastDue: isPastDueTask(document)(task)
        });
      }
    }
  }

  return lodash.groupBy(preDocumentTasks, 'doc_id');
};

export const formatPreDocTasksForUpload = ({
  preDocumentTasks
}: {
  preDocumentTasks: WorkflowModalData['localState']['preDocumentTasks'];
}) => {
  return Object.entries(preDocumentTasks).reduce(
    (formattedPreDocTasks, [docId, preDocumentTasks]) => {
      return {
        ...formattedPreDocTasks,
        [docId]: preDocumentTasks.reduce((preDocumentTasks, preDocumentTask) => {
          return {
            ...preDocumentTasks,
            [preDocumentTask.id]: preDocumentTask
          };
        }, {})
      };
    },
    {}
  );
};
