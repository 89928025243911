import type { WorkflowTaskDeadlineHandlers } from './useWorkflowTaskDeadlineHandlers.types';

import { useEffect } from 'react';

export const useWorkflowTaskDeadlineLifecycle = ({
  onDeadlinePropChange
}: {
  onDeadlinePropChange: WorkflowTaskDeadlineHandlers['handleDeadlinePropChange'];
}) => {
  useEffect(onDeadlinePropChange, [onDeadlinePropChange]);
};
