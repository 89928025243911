import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import auth from 'utils/auth';
import RestrictBubble from '../RestrictBubble';
import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import { getUpgradeBannerOpen } from 'shared/features/user/user.selectors';
import { setUpgradeBannerOpenFlag } from 'shared/features/user/user.actions';
import { CORPORATE_WEBSITE_COMPARE_EDITIONS_URL } from 'constants/CorporateWebsiteLinks';
import './_upgrade-banner.scss';

const upgradeText = 'Upgrade to PRO or TEAM for more features.';
const linkText = 'Compare Editions';

export const UpgradeBanner = () => {
  const dispatch = useDispatch();
  const upgradeBannerOpen = useSelector(getUpgradeBannerOpen);

  if (auth.loggedIn() || !upgradeBannerOpen) {
    return null;
  }

  const openLink = () => {
    window.open(CORPORATE_WEBSITE_COMPARE_EDITIONS_URL, '_blank');
  };

  return (
    <div className="upgrade-banner">
      <div className="left-container">
        <RestrictBubble />
        <span className="upgrade-text left-container-item">{upgradeText}</span>
        <Button className="left-container-item" type={BUTTON_TYPES.LINK} onClick={openLink}>
          {linkText}
        </Button>
      </div>
      <div className="right-container">
        <CloseIcon
          className="upgrade-banner-close"
          onClick={() => dispatch(setUpgradeBannerOpenFlag())}
        />
      </div>
    </div>
  );
};

export default UpgradeBanner;
