import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import PropTypes from 'prop-types';
import './_delete-button.scss';

export const TEST_ID = {
  CONTAINER: 'delete-modal-content-container'
};

export const DeleteModalContent = ({ deleteType, dataTestId }) => {
  return (
    <div className="delete-modal-content" data-testid={dataTestId}>
      {`Are you sure you want to delete this ${deleteType}? `}
      <em>This action cannot be undone.</em>
    </div>
  );
};

DeleteModalContent.propTypes = {
  deleteType: PropTypes.string.isRequired,
  dataTestId: PropTypes.string
};

DeleteModalContent.defaultProps = {
  dataTestId: TEST_ID.CONTAINER
};
