let extend = function (destination, source) {
  if (!destination || !source) return destination;
  for (let key in source) {
    if (destination[key] !== source[key]) destination[key] = source[key];
  }
  return destination;
};

let formatError = function (input, offset, expected) {
  let lines = input.split(/\n/g),
    lineNo = 0,
    position = 0;

  while (position <= offset) {
    position += lines[lineNo].length + 1;
    lineNo += 1;
  }
  let message = 'Line ' + lineNo + ': expected ' + expected.join(', ') + '\n',
    line = lines[lineNo - 1];

  message += line + '\n';
  position -= line.length + 1;

  while (position < offset) {
    message += ' ';
    position += 1;
  }
  return message + '^';
};

let inherit = function (subclass, parent) {
  let chain = function () {};
  chain.prototype = parent.prototype;
  subclass.prototype = new chain();
  subclass.prototype.constructor = subclass;
};

let TreeNode = function (text, offset, elements) {
  this.text = text;
  this.offset = offset;
  this.elements = elements || [];
};

TreeNode.prototype.forEach = function (block, context) {
  for (let el = this.elements, i = 0, n = el.length; i < n; i++) {
    block.call(context, el[i], i, el);
  }
};

let TreeNode1 = function (text, offset, elements) {
  TreeNode.apply(this, arguments);
  this['not_operator'] = elements[0];
  this['space'] = elements[1];
  this['group_or_clause'] = elements[2];
};
inherit(TreeNode1, TreeNode);

let TreeNode2 = function (text, offset, elements) {
  TreeNode.apply(this, arguments);
  this['group_or_clause'] = elements[2];
};
inherit(TreeNode2, TreeNode);

let TreeNode3 = function (text, offset, elements) {
  TreeNode.apply(this, arguments);
  this['space'] = elements[2];
  this['and_operator'] = elements[1];
  this['group_or_clause'] = elements[3];
};
inherit(TreeNode3, TreeNode);

let TreeNode4 = function (text, offset, elements) {
  TreeNode.apply(this, arguments);
  this['group_or_clause'] = elements[2];
};
inherit(TreeNode4, TreeNode);

let TreeNode5 = function (text, offset, elements) {
  TreeNode.apply(this, arguments);
  this['space'] = elements[2];
  this['or_operator'] = elements[1];
  this['group_or_clause'] = elements[3];
};
inherit(TreeNode5, TreeNode);

let TreeNode6 = function (text, offset, elements) {
  TreeNode.apply(this, arguments);
  this['attribute'] = elements[0];
  this['comparator'] = elements[2];
  this['value'] = elements[4];
};
inherit(TreeNode6, TreeNode);

let TreeNode7 = function (text, offset, elements) {
  TreeNode.apply(this, arguments);
  this['attribute'] = elements[0];
  this['space'] = elements[3];
  this['value'] = elements[6];
};
inherit(TreeNode7, TreeNode);

let TreeNode8 = function (text, offset, elements) {
  TreeNode.apply(this, arguments);
  this['value'] = elements[3];
};
inherit(TreeNode8, TreeNode);

let TreeNode9 = function (text, offset, elements) {
  TreeNode.apply(this, arguments);
  this['quote'] = elements[2];
};
inherit(TreeNode9, TreeNode);

let FAILURE = {};

let Grammar = {
  _read_query: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._query = this._cache._query || {};
    let cached = this._cache._query[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    let remaining0 = 1,
      index1 = this._offset,
      elements0 = [],
      address1 = true;
    while (address1 !== FAILURE) {
      address1 = this._read_group();
      if (address1 !== FAILURE) {
        elements0.push(address1);
        --remaining0;
      }
    }
    if (remaining0 <= 0) {
      address0 = this._actions.make_query(this._input, index1, this._offset, elements0);
      this._offset = this._offset;
    } else {
      address0 = FAILURE;
    }
    this._cache._query[index0] = [address0, this._offset];
    return address0;
  },

  _read_group_or_clause: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._group_or_clause = this._cache._group_or_clause || {};
    let cached = this._cache._group_or_clause[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    let index1 = this._offset;
    address0 = this._read_group();
    if (address0 === FAILURE) {
      this._offset = index1;
      address0 = this._read_clause();
      if (address0 === FAILURE) {
        this._offset = index1;
      }
    }
    this._cache._group_or_clause[index0] = [address0, this._offset];
    return address0;
  },

  _read_group: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._group = this._cache._group || {};
    let cached = this._cache._group[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    let index1 = this._offset;
    address0 = this._read_not_group();
    if (address0 === FAILURE) {
      this._offset = index1;
      address0 = this._read_and_group();
      if (address0 === FAILURE) {
        this._offset = index1;
        address0 = this._read_or_group();
        if (address0 === FAILURE) {
          this._offset = index1;
        }
      }
    }
    this._cache._group[index0] = [address0, this._offset];
    return address0;
  },

  _read_not_group: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._not_group = this._cache._not_group || {};
    let cached = this._cache._not_group[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    let index1 = this._offset,
      elements0 = new Array(3);
    let address1 = FAILURE;
    address1 = this._read_not_operator();
    if (address1 !== FAILURE) {
      elements0[0] = address1;
      let address2 = FAILURE;
      address2 = this._read_space();
      if (address2 !== FAILURE) {
        elements0[1] = address2;
        let address3 = FAILURE;
        address3 = this._read_group_or_clause();
        if (address3 !== FAILURE) {
          elements0[2] = address3;
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
    } else {
      elements0 = null;
      this._offset = index1;
    }
    if (elements0 === null) {
      address0 = FAILURE;
    } else {
      address0 = this._actions.make_not_group(this._input, index1, this._offset, elements0);
      this._offset = this._offset;
    }
    this._cache._not_group[index0] = [address0, this._offset];
    return address0;
  },

  _read_and_group: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._and_group = this._cache._and_group || {};
    let cached = this._cache._and_group[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    let index1 = this._offset,
      elements0 = new Array(6);
    let address1 = FAILURE;
    let chunk0 = null;
    if (this._offset < this._inputSize) {
      chunk0 = this._input.substring(this._offset, this._offset + 1);
    }
    if (chunk0 === '(') {
      address1 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset);
      this._offset = this._offset + 1;
    } else {
      address1 = FAILURE;
      if (this._offset > this._failure) {
        this._failure = this._offset;
        this._expected = [];
      }
      if (this._offset === this._failure) {
        this._expected.push('"("');
      }
    }
    if (address1 !== FAILURE) {
      elements0[0] = address1;
      let address2 = FAILURE;
      let index2 = this._offset;
      address2 = this._read_space();
      if (address2 === FAILURE) {
        address2 = new TreeNode(this._input.substring(index2, index2), index2);
        this._offset = index2;
      }
      if (address2 !== FAILURE) {
        elements0[1] = address2;
        let address3 = FAILURE;
        address3 = this._read_group_or_clause();
        if (address3 !== FAILURE) {
          elements0[2] = address3;
          let address4 = FAILURE;
          let remaining0 = 0,
            index3 = this._offset,
            elements1 = [],
            address5 = true;
          while (address5 !== FAILURE) {
            let index4 = this._offset,
              elements2 = new Array(4);
            let address6 = FAILURE;
            address6 = this._read_space();
            if (address6 !== FAILURE) {
              elements2[0] = address6;
              let address7 = FAILURE;
              address7 = this._read_and_operator();
              if (address7 !== FAILURE) {
                elements2[1] = address7;
                let address8 = FAILURE;
                address8 = this._read_space();
                if (address8 !== FAILURE) {
                  elements2[2] = address8;
                  let address9 = FAILURE;
                  address9 = this._read_group_or_clause();
                  if (address9 !== FAILURE) {
                    elements2[3] = address9;
                  } else {
                    elements2 = null;
                    this._offset = index4;
                  }
                } else {
                  elements2 = null;
                  this._offset = index4;
                }
              } else {
                elements2 = null;
                this._offset = index4;
              }
            } else {
              elements2 = null;
              this._offset = index4;
            }
            if (elements2 === null) {
              address5 = FAILURE;
            } else {
              address5 = new TreeNode3(
                this._input.substring(index4, this._offset),
                index4,
                elements2
              );
              this._offset = this._offset;
            }
            if (address5 !== FAILURE) {
              elements1.push(address5);
              --remaining0;
            }
          }
          if (remaining0 <= 0) {
            address4 = new TreeNode(this._input.substring(index3, this._offset), index3, elements1);
            this._offset = this._offset;
          } else {
            address4 = FAILURE;
          }
          if (address4 !== FAILURE) {
            elements0[3] = address4;
            let address10 = FAILURE;
            let index5 = this._offset;
            address10 = this._read_space();
            if (address10 === FAILURE) {
              address10 = new TreeNode(this._input.substring(index5, index5), index5);
              this._offset = index5;
            }
            if (address10 !== FAILURE) {
              elements0[4] = address10;
              let address11 = FAILURE;
              let chunk1 = null;
              if (this._offset < this._inputSize) {
                chunk1 = this._input.substring(this._offset, this._offset + 1);
              }
              if (chunk1 === ')') {
                address11 = new TreeNode(
                  this._input.substring(this._offset, this._offset + 1),
                  this._offset
                );
                this._offset = this._offset + 1;
              } else {
                address11 = FAILURE;
                if (this._offset > this._failure) {
                  this._failure = this._offset;
                  this._expected = [];
                }
                if (this._offset === this._failure) {
                  this._expected.push('")"');
                }
              }
              if (address11 !== FAILURE) {
                elements0[5] = address11;
              } else {
                elements0 = null;
                this._offset = index1;
              }
            } else {
              elements0 = null;
              this._offset = index1;
            }
          } else {
            elements0 = null;
            this._offset = index1;
          }
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
    } else {
      elements0 = null;
      this._offset = index1;
    }
    if (elements0 === null) {
      address0 = FAILURE;
    } else {
      address0 = this._actions.make_and_group(this._input, index1, this._offset, elements0);
      this._offset = this._offset;
    }
    this._cache._and_group[index0] = [address0, this._offset];
    return address0;
  },

  _read_or_group: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._or_group = this._cache._or_group || {};
    let cached = this._cache._or_group[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    let index1 = this._offset,
      elements0 = new Array(6);
    let address1 = FAILURE;
    let chunk0 = null;
    if (this._offset < this._inputSize) {
      chunk0 = this._input.substring(this._offset, this._offset + 1);
    }
    if (chunk0 === '(') {
      address1 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset);
      this._offset = this._offset + 1;
    } else {
      address1 = FAILURE;
      if (this._offset > this._failure) {
        this._failure = this._offset;
        this._expected = [];
      }
      if (this._offset === this._failure) {
        this._expected.push('"("');
      }
    }
    if (address1 !== FAILURE) {
      elements0[0] = address1;
      let address2 = FAILURE;
      let index2 = this._offset;
      address2 = this._read_space();
      if (address2 === FAILURE) {
        address2 = new TreeNode(this._input.substring(index2, index2), index2);
        this._offset = index2;
      }
      if (address2 !== FAILURE) {
        elements0[1] = address2;
        let address3 = FAILURE;
        address3 = this._read_group_or_clause();
        if (address3 !== FAILURE) {
          elements0[2] = address3;
          let address4 = FAILURE;
          let remaining0 = 0,
            index3 = this._offset,
            elements1 = [],
            address5 = true;
          while (address5 !== FAILURE) {
            let index4 = this._offset,
              elements2 = new Array(4);
            let address6 = FAILURE;
            address6 = this._read_space();
            if (address6 !== FAILURE) {
              elements2[0] = address6;
              let address7 = FAILURE;
              address7 = this._read_or_operator();
              if (address7 !== FAILURE) {
                elements2[1] = address7;
                let address8 = FAILURE;
                address8 = this._read_space();
                if (address8 !== FAILURE) {
                  elements2[2] = address8;
                  let address9 = FAILURE;
                  address9 = this._read_group_or_clause();
                  if (address9 !== FAILURE) {
                    elements2[3] = address9;
                  } else {
                    elements2 = null;
                    this._offset = index4;
                  }
                } else {
                  elements2 = null;
                  this._offset = index4;
                }
              } else {
                elements2 = null;
                this._offset = index4;
              }
            } else {
              elements2 = null;
              this._offset = index4;
            }
            if (elements2 === null) {
              address5 = FAILURE;
            } else {
              address5 = new TreeNode5(
                this._input.substring(index4, this._offset),
                index4,
                elements2
              );
              this._offset = this._offset;
            }
            if (address5 !== FAILURE) {
              elements1.push(address5);
              --remaining0;
            }
          }
          if (remaining0 <= 0) {
            address4 = new TreeNode(this._input.substring(index3, this._offset), index3, elements1);
            this._offset = this._offset;
          } else {
            address4 = FAILURE;
          }
          if (address4 !== FAILURE) {
            elements0[3] = address4;
            let address10 = FAILURE;
            let index5 = this._offset;
            address10 = this._read_space();
            if (address10 === FAILURE) {
              address10 = new TreeNode(this._input.substring(index5, index5), index5);
              this._offset = index5;
            }
            if (address10 !== FAILURE) {
              elements0[4] = address10;
              let address11 = FAILURE;
              let chunk1 = null;
              if (this._offset < this._inputSize) {
                chunk1 = this._input.substring(this._offset, this._offset + 1);
              }
              if (chunk1 === ')') {
                address11 = new TreeNode(
                  this._input.substring(this._offset, this._offset + 1),
                  this._offset
                );
                this._offset = this._offset + 1;
              } else {
                address11 = FAILURE;
                if (this._offset > this._failure) {
                  this._failure = this._offset;
                  this._expected = [];
                }
                if (this._offset === this._failure) {
                  this._expected.push('")"');
                }
              }
              if (address11 !== FAILURE) {
                elements0[5] = address11;
              } else {
                elements0 = null;
                this._offset = index1;
              }
            } else {
              elements0 = null;
              this._offset = index1;
            }
          } else {
            elements0 = null;
            this._offset = index1;
          }
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
    } else {
      elements0 = null;
      this._offset = index1;
    }
    if (elements0 === null) {
      address0 = FAILURE;
    } else {
      address0 = this._actions.make_or_group(this._input, index1, this._offset, elements0);
      this._offset = this._offset;
    }
    this._cache._or_group[index0] = [address0, this._offset];
    return address0;
  },

  _read_clause: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._clause = this._cache._clause || {};
    let cached = this._cache._clause[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    let index1 = this._offset;
    address0 = this._read_in_clause();
    if (address0 === FAILURE) {
      this._offset = index1;
      address0 = this._read_compare_clause();
      if (address0 === FAILURE) {
        this._offset = index1;
      }
    }
    this._cache._clause[index0] = [address0, this._offset];
    return address0;
  },

  _read_compare_clause: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._compare_clause = this._cache._compare_clause || {};
    let cached = this._cache._compare_clause[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    let index1 = this._offset,
      elements0 = new Array(5);
    let address1 = FAILURE;
    address1 = this._read_attribute();
    if (address1 !== FAILURE) {
      elements0[0] = address1;
      let address2 = FAILURE;
      let index2 = this._offset;
      address2 = this._read_space();
      if (address2 === FAILURE) {
        address2 = new TreeNode(this._input.substring(index2, index2), index2);
        this._offset = index2;
      }
      if (address2 !== FAILURE) {
        elements0[1] = address2;
        let address3 = FAILURE;
        address3 = this._read_comparator();
        if (address3 !== FAILURE) {
          elements0[2] = address3;
          let address4 = FAILURE;
          let index3 = this._offset;
          address4 = this._read_space();
          if (address4 === FAILURE) {
            address4 = new TreeNode(this._input.substring(index3, index3), index3);
            this._offset = index3;
          }
          if (address4 !== FAILURE) {
            elements0[3] = address4;
            let address5 = FAILURE;
            address5 = this._read_value();
            if (address5 !== FAILURE) {
              elements0[4] = address5;
            } else {
              elements0 = null;
              this._offset = index1;
            }
          } else {
            elements0 = null;
            this._offset = index1;
          }
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
    } else {
      elements0 = null;
      this._offset = index1;
    }
    if (elements0 === null) {
      address0 = FAILURE;
    } else {
      address0 = this._actions.make_compare_clause(this._input, index1, this._offset, elements0);
      this._offset = this._offset;
    }
    this._cache._compare_clause[index0] = [address0, this._offset];
    return address0;
  },

  _read_in_clause: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._in_clause = this._cache._in_clause || {};
    let cached = this._cache._in_clause[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    let index1 = this._offset,
      elements0 = new Array(10);
    let address1 = FAILURE;
    address1 = this._read_attribute();
    if (address1 !== FAILURE) {
      elements0[0] = address1;
      let address2 = FAILURE;
      address2 = this._read_space();
      if (address2 !== FAILURE) {
        elements0[1] = address2;
        let address3 = FAILURE;
        let chunk0 = null;
        if (this._offset < this._inputSize) {
          chunk0 = this._input.substring(this._offset, this._offset + 2);
        }
        if (chunk0 !== null && chunk0.toLowerCase() === 'in'.toLowerCase()) {
          address3 = new TreeNode(
            this._input.substring(this._offset, this._offset + 2),
            this._offset
          );
          this._offset = this._offset + 2;
        } else {
          address3 = FAILURE;
          if (this._offset > this._failure) {
            this._failure = this._offset;
            this._expected = [];
          }
          if (this._offset === this._failure) {
            this._expected.push('`in`');
          }
        }
        if (address3 !== FAILURE) {
          elements0[2] = address3;
          let address4 = FAILURE;
          address4 = this._read_space();
          if (address4 !== FAILURE) {
            elements0[3] = address4;
            let address5 = FAILURE;
            let chunk1 = null;
            if (this._offset < this._inputSize) {
              chunk1 = this._input.substring(this._offset, this._offset + 1);
            }
            if (chunk1 === '[') {
              address5 = new TreeNode(
                this._input.substring(this._offset, this._offset + 1),
                this._offset
              );
              this._offset = this._offset + 1;
            } else {
              address5 = FAILURE;
              if (this._offset > this._failure) {
                this._failure = this._offset;
                this._expected = [];
              }
              if (this._offset === this._failure) {
                this._expected.push('"["');
              }
            }
            if (address5 !== FAILURE) {
              elements0[4] = address5;
              let address6 = FAILURE;
              let index2 = this._offset;
              address6 = this._read_space();
              if (address6 === FAILURE) {
                address6 = new TreeNode(this._input.substring(index2, index2), index2);
                this._offset = index2;
              }
              if (address6 !== FAILURE) {
                elements0[5] = address6;
                let address7 = FAILURE;
                address7 = this._read_value();
                if (address7 !== FAILURE) {
                  elements0[6] = address7;
                  let address8 = FAILURE;
                  let remaining0 = 0,
                    index3 = this._offset,
                    elements1 = [],
                    address9 = true;
                  while (address9 !== FAILURE) {
                    let index4 = this._offset,
                      elements2 = new Array(4);
                    let address10 = FAILURE;
                    let index5 = this._offset;
                    address10 = this._read_space();
                    if (address10 === FAILURE) {
                      address10 = new TreeNode(this._input.substring(index5, index5), index5);
                      this._offset = index5;
                    }
                    if (address10 !== FAILURE) {
                      elements2[0] = address10;
                      let address11 = FAILURE;
                      let chunk2 = null;
                      if (this._offset < this._inputSize) {
                        chunk2 = this._input.substring(this._offset, this._offset + 1);
                      }
                      if (chunk2 === ',') {
                        address11 = new TreeNode(
                          this._input.substring(this._offset, this._offset + 1),
                          this._offset
                        );
                        this._offset = this._offset + 1;
                      } else {
                        address11 = FAILURE;
                        if (this._offset > this._failure) {
                          this._failure = this._offset;
                          this._expected = [];
                        }
                        if (this._offset === this._failure) {
                          this._expected.push('","');
                        }
                      }
                      if (address11 !== FAILURE) {
                        elements2[1] = address11;
                        let address12 = FAILURE;
                        let index6 = this._offset;
                        address12 = this._read_space();
                        if (address12 === FAILURE) {
                          address12 = new TreeNode(this._input.substring(index6, index6), index6);
                          this._offset = index6;
                        }
                        if (address12 !== FAILURE) {
                          elements2[2] = address12;
                          let address13 = FAILURE;
                          address13 = this._read_value();
                          if (address13 !== FAILURE) {
                            elements2[3] = address13;
                          } else {
                            elements2 = null;
                            this._offset = index4;
                          }
                        } else {
                          elements2 = null;
                          this._offset = index4;
                        }
                      } else {
                        elements2 = null;
                        this._offset = index4;
                      }
                    } else {
                      elements2 = null;
                      this._offset = index4;
                    }
                    if (elements2 === null) {
                      address9 = FAILURE;
                    } else {
                      address9 = new TreeNode8(
                        this._input.substring(index4, this._offset),
                        index4,
                        elements2
                      );
                      this._offset = this._offset;
                    }
                    if (address9 !== FAILURE) {
                      elements1.push(address9);
                      --remaining0;
                    }
                  }
                  if (remaining0 <= 0) {
                    address8 = new TreeNode(
                      this._input.substring(index3, this._offset),
                      index3,
                      elements1
                    );
                    this._offset = this._offset;
                  } else {
                    address8 = FAILURE;
                  }
                  if (address8 !== FAILURE) {
                    elements0[7] = address8;
                    let address14 = FAILURE;
                    let index7 = this._offset;
                    address14 = this._read_space();
                    if (address14 === FAILURE) {
                      address14 = new TreeNode(this._input.substring(index7, index7), index7);
                      this._offset = index7;
                    }
                    if (address14 !== FAILURE) {
                      elements0[8] = address14;
                      let address15 = FAILURE;
                      let chunk3 = null;
                      if (this._offset < this._inputSize) {
                        chunk3 = this._input.substring(this._offset, this._offset + 1);
                      }
                      if (chunk3 === ']') {
                        address15 = new TreeNode(
                          this._input.substring(this._offset, this._offset + 1),
                          this._offset
                        );
                        this._offset = this._offset + 1;
                      } else {
                        address15 = FAILURE;
                        if (this._offset > this._failure) {
                          this._failure = this._offset;
                          this._expected = [];
                        }
                        if (this._offset === this._failure) {
                          this._expected.push('"]"');
                        }
                      }
                      if (address15 !== FAILURE) {
                        elements0[9] = address15;
                      } else {
                        elements0 = null;
                        this._offset = index1;
                      }
                    } else {
                      elements0 = null;
                      this._offset = index1;
                    }
                  } else {
                    elements0 = null;
                    this._offset = index1;
                  }
                } else {
                  elements0 = null;
                  this._offset = index1;
                }
              } else {
                elements0 = null;
                this._offset = index1;
              }
            } else {
              elements0 = null;
              this._offset = index1;
            }
          } else {
            elements0 = null;
            this._offset = index1;
          }
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
    } else {
      elements0 = null;
      this._offset = index1;
    }
    if (elements0 === null) {
      address0 = FAILURE;
    } else {
      address0 = this._actions.make_in_clause(this._input, index1, this._offset, elements0);
      this._offset = this._offset;
    }
    this._cache._in_clause[index0] = [address0, this._offset];
    return address0;
  },

  _read_attribute: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._attribute = this._cache._attribute || {};
    let cached = this._cache._attribute[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    address0 = this._read_word();
    this._cache._attribute[index0] = [address0, this._offset];
    return address0;
  },

  _read_value: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._value = this._cache._value || {};
    let cached = this._cache._value[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    let index1 = this._offset;
    address0 = this._read_exact();
    if (address0 === FAILURE) {
      this._offset = index1;
      address0 = this._read_word();
      if (address0 === FAILURE) {
        this._offset = index1;
      }
    }
    this._cache._value[index0] = [address0, this._offset];
    return address0;
  },

  _read_comparator: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._comparator = this._cache._comparator || {};
    let cached = this._cache._comparator[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    let index1 = this._offset;
    address0 = this._read_is();
    if (address0 === FAILURE) {
      this._offset = index1;
      address0 = this._read_is_not();
      if (address0 === FAILURE) {
        this._offset = index1;
        address0 = this._read_greater_equal();
        if (address0 === FAILURE) {
          this._offset = index1;
          address0 = this._read_greater_strict();
          if (address0 === FAILURE) {
            this._offset = index1;
            address0 = this._read_less_equal();
            if (address0 === FAILURE) {
              this._offset = index1;
              address0 = this._read_less_strict();
              if (address0 === FAILURE) {
                this._offset = index1;
              }
            }
          }
        }
      }
    }
    this._cache._comparator[index0] = [address0, this._offset];
    return address0;
  },

  _read_is: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._is = this._cache._is || {};
    let cached = this._cache._is[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    let chunk0 = null;
    if (this._offset < this._inputSize) {
      chunk0 = this._input.substring(this._offset, this._offset + 1);
    }
    if (chunk0 === '=') {
      address0 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset);
      this._offset = this._offset + 1;
    } else {
      address0 = FAILURE;
      if (this._offset > this._failure) {
        this._failure = this._offset;
        this._expected = [];
      }
      if (this._offset === this._failure) {
        this._expected.push('"="');
      }
    }
    this._cache._is[index0] = [address0, this._offset];
    return address0;
  },

  _read_is_not: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._is_not = this._cache._is_not || {};
    let cached = this._cache._is_not[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    let chunk0 = null;
    if (this._offset < this._inputSize) {
      chunk0 = this._input.substring(this._offset, this._offset + 2);
    }
    if (chunk0 === '!=') {
      address0 = new TreeNode(this._input.substring(this._offset, this._offset + 2), this._offset);
      this._offset = this._offset + 2;
    } else {
      address0 = FAILURE;
      if (this._offset > this._failure) {
        this._failure = this._offset;
        this._expected = [];
      }
      if (this._offset === this._failure) {
        this._expected.push('"!="');
      }
    }
    this._cache._is_not[index0] = [address0, this._offset];
    return address0;
  },

  _read_greater_equal: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._greater_equal = this._cache._greater_equal || {};
    let cached = this._cache._greater_equal[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    let chunk0 = null;
    if (this._offset < this._inputSize) {
      chunk0 = this._input.substring(this._offset, this._offset + 2);
    }
    if (chunk0 === '>=') {
      address0 = new TreeNode(this._input.substring(this._offset, this._offset + 2), this._offset);
      this._offset = this._offset + 2;
    } else {
      address0 = FAILURE;
      if (this._offset > this._failure) {
        this._failure = this._offset;
        this._expected = [];
      }
      if (this._offset === this._failure) {
        this._expected.push('">="');
      }
    }
    this._cache._greater_equal[index0] = [address0, this._offset];
    return address0;
  },

  _read_greater_strict: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._greater_strict = this._cache._greater_strict || {};
    let cached = this._cache._greater_strict[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    let chunk0 = null;
    if (this._offset < this._inputSize) {
      chunk0 = this._input.substring(this._offset, this._offset + 1);
    }
    if (chunk0 === '>') {
      address0 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset);
      this._offset = this._offset + 1;
    } else {
      address0 = FAILURE;
      if (this._offset > this._failure) {
        this._failure = this._offset;
        this._expected = [];
      }
      if (this._offset === this._failure) {
        this._expected.push('">"');
      }
    }
    this._cache._greater_strict[index0] = [address0, this._offset];
    return address0;
  },

  _read_less_equal: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._less_equal = this._cache._less_equal || {};
    let cached = this._cache._less_equal[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    let chunk0 = null;
    if (this._offset < this._inputSize) {
      chunk0 = this._input.substring(this._offset, this._offset + 2);
    }
    if (chunk0 === '<=') {
      address0 = new TreeNode(this._input.substring(this._offset, this._offset + 2), this._offset);
      this._offset = this._offset + 2;
    } else {
      address0 = FAILURE;
      if (this._offset > this._failure) {
        this._failure = this._offset;
        this._expected = [];
      }
      if (this._offset === this._failure) {
        this._expected.push('"<="');
      }
    }
    this._cache._less_equal[index0] = [address0, this._offset];
    return address0;
  },

  _read_less_strict: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._less_strict = this._cache._less_strict || {};
    let cached = this._cache._less_strict[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    let chunk0 = null;
    if (this._offset < this._inputSize) {
      chunk0 = this._input.substring(this._offset, this._offset + 1);
    }
    if (chunk0 === '<') {
      address0 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset);
      this._offset = this._offset + 1;
    } else {
      address0 = FAILURE;
      if (this._offset > this._failure) {
        this._failure = this._offset;
        this._expected = [];
      }
      if (this._offset === this._failure) {
        this._expected.push('"<"');
      }
    }
    this._cache._less_strict[index0] = [address0, this._offset];
    return address0;
  },

  _read_not_operator: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._not_operator = this._cache._not_operator || {};
    let cached = this._cache._not_operator[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    let chunk0 = null;
    if (this._offset < this._inputSize) {
      chunk0 = this._input.substring(this._offset, this._offset + 3);
    }
    if (chunk0 !== null && chunk0.toLowerCase() === 'not'.toLowerCase()) {
      address0 = new TreeNode(this._input.substring(this._offset, this._offset + 3), this._offset);
      this._offset = this._offset + 3;
    } else {
      address0 = FAILURE;
      if (this._offset > this._failure) {
        this._failure = this._offset;
        this._expected = [];
      }
      if (this._offset === this._failure) {
        this._expected.push('`not`');
      }
    }
    this._cache._not_operator[index0] = [address0, this._offset];
    return address0;
  },

  _read_and_operator: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._and_operator = this._cache._and_operator || {};
    let cached = this._cache._and_operator[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    let chunk0 = null;
    if (this._offset < this._inputSize) {
      chunk0 = this._input.substring(this._offset, this._offset + 3);
    }
    if (chunk0 !== null && chunk0.toLowerCase() === 'and'.toLowerCase()) {
      address0 = new TreeNode(this._input.substring(this._offset, this._offset + 3), this._offset);
      this._offset = this._offset + 3;
    } else {
      address0 = FAILURE;
      if (this._offset > this._failure) {
        this._failure = this._offset;
        this._expected = [];
      }
      if (this._offset === this._failure) {
        this._expected.push('`and`');
      }
    }
    this._cache._and_operator[index0] = [address0, this._offset];
    return address0;
  },

  _read_or_operator: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._or_operator = this._cache._or_operator || {};
    let cached = this._cache._or_operator[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    let chunk0 = null;
    if (this._offset < this._inputSize) {
      chunk0 = this._input.substring(this._offset, this._offset + 2);
    }
    if (chunk0 !== null && chunk0.toLowerCase() === 'or'.toLowerCase()) {
      address0 = new TreeNode(this._input.substring(this._offset, this._offset + 2), this._offset);
      this._offset = this._offset + 2;
    } else {
      address0 = FAILURE;
      if (this._offset > this._failure) {
        this._failure = this._offset;
        this._expected = [];
      }
      if (this._offset === this._failure) {
        this._expected.push('`or`');
      }
    }
    this._cache._or_operator[index0] = [address0, this._offset];
    return address0;
  },

  _read_word: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._word = this._cache._word || {};
    let cached = this._cache._word[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    let remaining0 = 1,
      index1 = this._offset,
      elements0 = [],
      address1 = true;
    while (address1 !== FAILURE) {
      let chunk0 = null;
      if (this._offset < this._inputSize) {
        chunk0 = this._input.substring(this._offset, this._offset + 1);
      }
      if (chunk0 !== null && /^[^\s\]=!()"“”,<>]/.test(chunk0)) {
        address1 = new TreeNode(
          this._input.substring(this._offset, this._offset + 1),
          this._offset
        );
        this._offset = this._offset + 1;
      } else {
        address1 = FAILURE;
        if (this._offset > this._failure) {
          this._failure = this._offset;
          this._expected = [];
        }
        if (this._offset === this._failure) {
          this._expected.push('[^\\s\\]=!()"“”,<>]');
        }
      }
      if (address1 !== FAILURE) {
        elements0.push(address1);
        --remaining0;
      }
    }
    if (remaining0 <= 0) {
      address0 = this._actions.make_word(this._input, index1, this._offset, elements0);
      this._offset = this._offset;
    } else {
      address0 = FAILURE;
    }
    this._cache._word[index0] = [address0, this._offset];
    return address0;
  },

  _read_exact: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._exact = this._cache._exact || {};
    let cached = this._cache._exact[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    let index1 = this._offset,
      elements0 = new Array(3);
    let address1 = FAILURE;
    address1 = this._read_quote();
    if (address1 !== FAILURE) {
      elements0[0] = address1;
      let address2 = FAILURE;
      let remaining0 = 1,
        index2 = this._offset,
        elements1 = [],
        address3 = true;
      while (address3 !== FAILURE) {
        let chunk0 = null;
        if (this._offset < this._inputSize) {
          chunk0 = this._input.substring(this._offset, this._offset + 1);
        }
        if (chunk0 !== null && /^[^"“”]/.test(chunk0)) {
          address3 = new TreeNode(
            this._input.substring(this._offset, this._offset + 1),
            this._offset
          );
          this._offset = this._offset + 1;
        } else {
          address3 = FAILURE;
          if (this._offset > this._failure) {
            this._failure = this._offset;
            this._expected = [];
          }
          if (this._offset === this._failure) {
            this._expected.push('[^"“”]');
          }
        }
        if (address3 !== FAILURE) {
          elements1.push(address3);
          --remaining0;
        }
      }
      if (remaining0 <= 0) {
        address2 = new TreeNode(this._input.substring(index2, this._offset), index2, elements1);
        this._offset = this._offset;
      } else {
        address2 = FAILURE;
      }
      if (address2 !== FAILURE) {
        elements0[1] = address2;
        let address4 = FAILURE;
        address4 = this._read_quote();
        if (address4 !== FAILURE) {
          elements0[2] = address4;
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
    } else {
      elements0 = null;
      this._offset = index1;
    }
    if (elements0 === null) {
      address0 = FAILURE;
    } else {
      address0 = this._actions.make_exact(this._input, index1, this._offset, elements0);
      this._offset = this._offset;
    }
    this._cache._exact[index0] = [address0, this._offset];
    return address0;
  },

  _read_space: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._space = this._cache._space || {};
    let cached = this._cache._space[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    let remaining0 = 1,
      index1 = this._offset,
      elements0 = [],
      address1 = true;
    while (address1 !== FAILURE) {
      let chunk0 = null;
      if (this._offset < this._inputSize) {
        chunk0 = this._input.substring(this._offset, this._offset + 1);
      }
      if (chunk0 !== null && /^[\s]/.test(chunk0)) {
        address1 = new TreeNode(
          this._input.substring(this._offset, this._offset + 1),
          this._offset
        );
        this._offset = this._offset + 1;
      } else {
        address1 = FAILURE;
        if (this._offset > this._failure) {
          this._failure = this._offset;
          this._expected = [];
        }
        if (this._offset === this._failure) {
          this._expected.push('[\\s]');
        }
      }
      if (address1 !== FAILURE) {
        elements0.push(address1);
        --remaining0;
      }
    }
    if (remaining0 <= 0) {
      address0 = new TreeNode(this._input.substring(index1, this._offset), index1, elements0);
      this._offset = this._offset;
    } else {
      address0 = FAILURE;
    }
    this._cache._space[index0] = [address0, this._offset];
    return address0;
  },

  _read_quote: function () {
    let address0 = FAILURE,
      index0 = this._offset;
    this._cache._quote = this._cache._quote || {};
    let cached = this._cache._quote[index0];
    if (cached) {
      this._offset = cached[1];
      return cached[0];
    }
    let chunk0 = null;
    if (this._offset < this._inputSize) {
      chunk0 = this._input.substring(this._offset, this._offset + 1);
    }
    if (chunk0 !== null && /^["“”]/.test(chunk0)) {
      address0 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset);
      this._offset = this._offset + 1;
    } else {
      address0 = FAILURE;
      if (this._offset > this._failure) {
        this._failure = this._offset;
        this._expected = [];
      }
      if (this._offset === this._failure) {
        this._expected.push('["“”]');
      }
    }
    this._cache._quote[index0] = [address0, this._offset];
    return address0;
  }
};

let Parser = function (input, actions, types) {
  this._input = input;
  this._inputSize = input.length;
  this._actions = actions;
  this._types = types;
  this._offset = 0;
  this._cache = {};
  this._failure = 0;
  this._expected = [];
};

Parser.prototype.parse = function () {
  let tree = this._read_query();
  if (tree !== FAILURE && this._offset === this._inputSize) {
    return tree;
  }
  if (this._expected.length === 0) {
    this._failure = this._offset;
    this._expected.push('<EOF>');
  }
  this.constructor.lastError = { offset: this._offset, expected: this._expected };
  throw new SyntaxError(formatError(this._input, this._failure, this._expected));
};

let parse = function (input, options) {
  options = options || {};
  let parser = new Parser(input, options.actions, options.types);
  return parser.parse();
};
extend(Parser.prototype, Grammar);

let exported = { Grammar: Grammar, Parser: Parser, parse: parse };

if (typeof require === 'function' && typeof exports === 'object') {
  extend(exports, exported);
} else {
  let namespace = typeof this !== 'undefined' ? this : window;
  namespace.search_query = exported;
}

export default exported;
