import type { AddToWorkflowButtonProps } from './AddToWorkflowButton.types';
import type { FC } from 'react';
import type { NormalizedWorkflow } from 'shared/features/workflow/workflow.types';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';
import * as workflowConstants from 'shared/features/workflow/workflow.constants';

import { ACCESS_TASKS } from 'constants/Permissions';

import classnames from 'classnames';

import { usePermissions } from 'utils/hooks';
import { useAddToWorkflowButtonData, useAddToWorkflowButtonHandlers } from './hooks';

import './_add-to-workflow-button.scss';

export const AddToWorkflowButton: FC<AddToWorkflowButtonProps> = ({ documentId, classNames }) => {
  const permissions = usePermissions();

  const { localState, localActions, reduxState } = useAddToWorkflowButtonData({ documentId });

  const handlers = useAddToWorkflowButtonHandlers({
    localActions
  });

  if (!permissions[ACCESS_TASKS]) {
    return null;
  }

  return (
    <uiLib.ErrorBoundary>
      <div className={classnames('add-to-workflow-button', classNames?.container)}>
        {!localState.isSelectingWorkflow && !localState.isViewingTasks && (
          <div className="add-to-workflow-button__button-wrapper">
            <uiLib.Button type={uiLib.BUTTON_TYPES.PRIMARY} onClick={handlers.handleAddButtonClick}>
              Add Workflow
            </uiLib.Button>
          </div>
        )}
        {localState.isSelectingWorkflow && (
          <elements.WorkflowDropdown
            onSubmit={handlers.handleWorkflowSelect}
            onCancel={handlers.handleCancel}
            selectedWorkflowOption={localState.workflowOption}
            onWorkflowSelect={localActions.setWorkflowOption}
          />
        )}
        {localState.isViewingTasks && localState.workflowOption && (
          <elements.WorkflowPreTaskContainer
            document={reduxState.document}
            workflowId={localState.workflowOption.value as NormalizedWorkflow['id']}
            isObligationWorkflow={localState.workflowOption.types.includes(
              workflowConstants.WORKFLOW_TYPE.OBLIGATION
            )}
            onWorkflowAddCancel={handlers.handleCancel}
          />
        )}
      </div>
    </uiLib.ErrorBoundary>
  );
};
