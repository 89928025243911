import type { NewsAndPremiumContentSelectData } from './useNewsAndPremiumContentSelectData.types';
import type { NewsAndPremiumContentSelectProps } from '../NewsAndPremiumContentSelect.types';
import type { GlobalState } from 'shared/reducers';

import {
  getDefaultMainstreamNewsSourcesOptionsByKey,
  getSourcesReadyState
} from 'shared/features/sources/sources.selectors';
import { getValuesFromOptions } from 'utils/select-utils';
import { NEWS_AND_PREMIUM_CONTENT_SELECT_VALUE_KEYS } from '../NewsAndPremiumContentSelect.constants';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useNewsAndPremiumContentSelectData = ({
  value,
  valueKey,
  isMulti
}: NewsAndPremiumContentSelectProps): NewsAndPremiumContentSelectData => {
  const reduxState = useSelector<GlobalState, NewsAndPremiumContentSelectData['reduxState']>(
    state => ({
      options: getDefaultMainstreamNewsSourcesOptionsByKey(
        state,
        // TODO Remove ts-ignore when sources.selectors.js will be converted to ts
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        valueKey ?? NEWS_AND_PREMIUM_CONTENT_SELECT_VALUE_KEYS.ID
      ),
      isReady: getSourcesReadyState(state)
    })
  );

  const formattedData: NewsAndPremiumContentSelectData['formattedData'] = useMemo(
    () => ({
      value: getValuesFromOptions({
        isMulti,
        options: reduxState.options,
        value
      })
    }),
    [isMulti, reduxState.options, value]
  );

  return {
    reduxState,
    formattedData
  };
};
