import { ReactNode } from 'react';

export enum DOCUMENT_TIMELINE_TYPE {
  DOCKET = 'docket',
  VERSION = 'version'
}

export enum DOCUMENT_TIMELINE_SORT_OPTION {
  PUBLICATION_DATE = 'publication_date',
  COMMENTS_CLOSE_DATE = 'rule.comments_close_on',
  EFFECTIVE_DATE = 'rule.effective_on'
}

export const DOCUMENT_TIMELINE_HUMANIZED_SORT_OPTION: Record<
  DOCUMENT_TIMELINE_SORT_OPTION,
  ReactNode
> = {
  [DOCUMENT_TIMELINE_SORT_OPTION.PUBLICATION_DATE]: 'Pub Date',
  [DOCUMENT_TIMELINE_SORT_OPTION.EFFECTIVE_DATE]: 'Effective Date',
  [DOCUMENT_TIMELINE_SORT_OPTION.COMMENTS_CLOSE_DATE]: 'Comments Due'
};

export const BLACKLISTED_FOR_DIFFING_CATEGORIES = ['Comment', 'Notice', 'Bill Analysis'];

export const DEFAULT_QUERY_PARAMS = {
  limit: '100',
  offset: '0',
  exclusive_fields: [
    'id',
    'title',
    'agencies',
    'has_sentences',
    'cai_category_id',
    'cai_category_name',
    ...Object.values(DOCUMENT_TIMELINE_SORT_OPTION)
  ]
};
