import Autosuggest from 'react-autosuggest';
import classnames from 'classnames';
import FilterSpecial from 'components/Filters/FilterContainer';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';
import * as helpers from './AutocompleteSearchField.helpers';
import * as constants from './AutocompleteSearchField.constants';

import {
  useAutocompleteSearchFieldData,
  useAutocompleteSearchFieldHandlers,
  useAutocompleteSearchFieldLifecycles
} from './hooks';

import './_autocomplete-search-field.scss';

export const AutocompleteSearchField = () => {
  const {
    localState,
    localActions,
    reduxState,
    formattedData,
    history
  } = useAutocompleteSearchFieldData();

  const handlers = useAutocompleteSearchFieldHandlers({
    localState,
    localActions,
    reduxState,
    history
  });

  useAutocompleteSearchFieldLifecycles({ localState, localActions, reduxState });

  return (
    <uiLib.ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={handlers.handleOutsideClick}
    >
      <div
        className="autocomplete-search-field autocomplete-search-field__search-bar"
        data-testid="basic-search-field"
      >
        <div className={classnames('autocomplete-search-field__search-menu')}>
          <elements.FilterCount searchTermTooltipTrigger={localState.searchTermTooltipTrigger} />
          <Autosuggest
            suggestions={[]}
            onSuggestionsClearRequested={() => {
              // empty changes handled above because we only want to clear values when a user clears through typing
              // this function is triggered when a user triggers the blur event
            }}
            onSuggestionsFetchRequested={({ value }) => handlers.handleOnChange(value)}
            getSuggestionValue={suggestion => suggestion.key}
            renderSuggestionsContainer={() => null}
            inputProps={{
              className: classnames(
                'autocomplete-search-field__search-input',
                constants.SUGGESTIONS_MENU_OUTSIDE_CLICK_IGNORE_CLASS
              ),
              placeholder: formattedData.placeholder,
              value: localState.searchTerm || '',
              onKeyPress: handlers.handleEnterPress,
              onChange: handlers.handleInputChange,
              onFocus: handlers.handleInputFocus
            }}
          />
          <uiLib.IconSearch
            className="autocomplete-search-field__search-icon"
            onClick={handlers.handleSearchTermTrigger}
          />
        </div>
        {localState.searchTerm && localState.isSuggestionsMenuVisible && (
          <elements.SuggestionsMenu
            suggestions={helpers.formatSuggestions(
              localState.searchTerm,
              helpers.parseAutoSuggestOptions(
                localState.autocompletes,
                localState.searchTerm,
                handlers.handleAutosuggestItemClick
              )
            )}
            onSelect={handlers.handleAutosuggestItemClick}
            onClickOutside={handlers.handleSuggestionsMenuOutsideClick}
            className={constants.FILTER_OUTSIDE_CLICK_IGNORE_CLASS}
          />
        )}
        {reduxState.current_view.displayFilter && (
          <FilterSpecial
            search_query={localState.searchTerm}
            currentQuery={localState.currentQuery}
            currentView={localState.currentView}
          />
        )}
      </div>
    </uiLib.ClickAwayListener>
  );
};
