export const DEFAULTS_UPDATE_ACTIONS = {
  CONCEPT_FOLLOW: 'CONCEPT_FOLLOW',
  CONCEPT_UNFOLLOW: 'CONCEPT_UNFOLLOW',
  REGULATION_FOLLOW: 'REGULATION_FOLLOW',
  REGULATION_UNFOLLOW: 'REGULATION_UNFOLLOW',
  UPDATE_FOLLOWED_CATEGORIES: 'UPDATE_FOLLOWED_CATEGORIES',
  UPDATE_FOLLOWED_TOPICS: 'UPDATE_FOLLOWED_TOPICS',
  UPDATE_FOLLOWED_AGENCIES: 'UPDATE_FOLLOWED_AGENCIES',
  UPDATE_FOLLOWED_ENTITY: 'UPDATE_FOLLOWED_ENTITY'
};

export const DEFAULTS_GET_ACTIONS = {
  GET_FOLLOWED_CATEGORIES: 'GET_FOLLOWED_CATEGORIES',
  GET_FOLLOWED_CONCEPTS: 'GET_FOLLOWED_CONCEPTS',
  GET_FOLLOWED_ENTITIES: 'GET_FOLLOWED_ENTITIES',
  GET_FOLLOWED_TOPICS: 'GET_FOLLOWED_TOPICS',
  GET_FOLLOWED_AGENCIES: 'GET_FOLLOWED_AGENCIES',
  GET_FOLLOWED_REGULATIONS: 'GET_FOLLOWED_REGULATIONS'
};

export const DEFAULT_JURISDICTIONS_SHORT_NAMES = ['US-CA', 'US-NY', 'US-TX', 'US-PA', 'US-IL'];
