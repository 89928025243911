import { useCallback, useMemo } from 'react';
import { Chiclets } from '@compliance.ai/web-components';
import { getRelatedDocumentsByRelationType } from 'utils/relatedDocuments';
import {
  RELATED_DOCUMENT_KEYS,
  RELATED_DOCUMENT_RELATION_TYPES,
  RELATED_DOCUMENT_CHICLET_TITLES
} from 'constants/RelatedDocuments';
import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';
import {
  DOCUMENT_DETAILS_TAB_KEY,
  RIGHT_PANEL_TYPES
} from 'shared/features/rightPanel/rightPanel.constants';
import { orgRelatedDocsEnabled } from 'shared/config';
import auth from 'utils/auth';
import './_related_docs_items_list.scss';

export const TEST_ID = {
  CONTAINER: 'related-documents-items-list__container'
};

export const TEXTS = {
  TITLE: 'Related Docs'
};

export const RelatedDocsItemsList = ({ documentId, relatedDocs = [] }) => {
  const rightPanelReduxActions = useRightPanelReduxActions();

  const relatedDocsOptions = useMemo(() => {
    const relatedDocuments = getRelatedDocumentsByRelationType(relatedDocs);

    const result = Object.values(RELATED_DOCUMENT_RELATION_TYPES)
      .filter(
        relationType =>
          Boolean(relatedDocuments[relationType]) &&
          relatedDocuments[relationType][RELATED_DOCUMENT_KEYS.TOTAL_COUNT] > 0
      )
      .map(relationType => ({
        value: relationType,
        label: `${RELATED_DOCUMENT_CHICLET_TITLES[relationType]} (${
          relatedDocuments[relationType][RELATED_DOCUMENT_KEYS.TOTAL_COUNT]
        })`
      }));

    if (!orgRelatedDocsEnabled) {
      return result.filter(({ value }) => value !== RELATED_DOCUMENT_RELATION_TYPES.ORGANIZATION);
    }

    return result;
  }, [relatedDocs]);

  const onRelatedDocOptionClick = useCallback(
    ({ value }) => {
      if (!auth.loggedIn()) {
        return;
      }

      rightPanelReduxActions.openRightPanel({
        type: RIGHT_PANEL_TYPES.DOCUMENT_DETAILS,
        props: {
          documentId,
          activeTabKey: DOCUMENT_DETAILS_TAB_KEY.RELATED_DOCUMENTS,
          expandedRelatedDocsType: value
        }
      });
    },
    [documentId, rightPanelReduxActions]
  );

  if (relatedDocsOptions.length === 0) {
    return null;
  }

  return (
    <div className="related-documents-items-list__container">
      <Chiclets
        title={TEXTS.TITLE}
        options={relatedDocsOptions}
        isOptionClickable={auth.loggedIn()}
        onOptionClick={onRelatedDocOptionClick}
        dataTestId={TEST_ID.CONTAINER}
      />
    </div>
  );
};
