import { ALERT_TYPES, showNotification } from '@compliance.ai/web-components';

// FIXME DO NOT USE ANY OF THESE FUNCTIONS !!!
// If you want to show notifications, use showNotification function from @compliance.ai/web-components directly

export const showInfoNotification = ({ title, message, duration }) => {
  showNotification({
    type: ALERT_TYPES.INFO,
    title,
    message,
    duration
  });
};

export const showSuccessNotification = ({ title, message, duration }) => {
  showNotification({
    type: ALERT_TYPES.SUCCESS,
    title,
    message,
    duration
  });
};

export const showErrorNotification = ({ title = 'Error', message = 'Error', duration }) => {
  showNotification({
    type: ALERT_TYPES.ERROR,
    title,
    message,
    duration
  });
};

export const showSystemErrorNotification = ({
  dataType,
  title = 'Error',
  ...errorNotificationArgs
}) => {
  if (import.meta.env.VITE_ERROR_MESSAGES_ENABLED) {
    const systemErrorTitle = `${dataType ? `${dataType} ${title}` : title}`;

    showErrorNotification({
      ...errorNotificationArgs,
      title: systemErrorTitle
    });
  }
};
