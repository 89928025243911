export const DOCUMENT_EXPORT_LIMIT = 600;
export const DOCUMENT_EXPORT_LIMIT_INTERNAL_USER = 3000;

export enum CONTENT_TYPES {
  CSV = 'text/csv',
  XLSX = 'xlsx',
  HTML = 'text/html',
  PDF = 'application/pdf'
}

export const EXPORT_TYPES = {
  TASK: 'task-history',
  CERTIFIED_TASK: 'certified-task-history',
  WORKFLOW: 'workflow-history',
  OBLIGATION: 'obligation-history',
  DOCUMENT: 'document-export',
  ALERT: 'alert-export',
  USER: 'user-export',
  USER_AUDIT: 'user-audit',
  GLASSDOOR: 'glassdoor',
  LABELS: 'labels'
};

export const CONTENT_DISPLAY_NAMES = {
  [CONTENT_TYPES.CSV]: 'CSV',
  [CONTENT_TYPES.XLSX]: 'XLSX',
  [CONTENT_TYPES.HTML]: 'HTML',
  [CONTENT_TYPES.PDF]: 'PDF'
};
