import type { AutoSummaryHandlers } from './useAutoSummaryHandlers.types';

import { useEffect } from 'react';

export const useAutoSummaryLifecycles = ({
  onAutoSummaryActionLog
}: {
  onAutoSummaryActionLog: AutoSummaryHandlers['handleAutoSummaryActionLog'];
}) => {
  useEffect(onAutoSummaryActionLog);
};
