import type { ComponentProps } from 'react';

import * as workflowSummaryCommonCells from "../CommonCells";
import * as uiLib from '@compliance.ai/web-components';
import * as elements from "./elements";

export enum TABLE_FIELDS {
  ID = 'id',
  SENTENCE = 'sentencesText',
  LABELS = 'labels',
  COMMENTS = 'commentThreads',
  ATTACHMENTS = 'attachments'
}

export const TABLE_COLUMNS: Required<ComponentProps<typeof uiLib.Table>>['columns'] = [
  {
    title: 'Sentence',
    dataKey: TABLE_FIELDS.SENTENCE,
    width: '25rem',
    Component: uiLib.TextCell
  },
  {
    title: 'Labels',
    dataKey: TABLE_FIELDS.LABELS,
    width: '20rem',
    Component: elements.LabelsCell
  },
  {
    title: 'Comments',
    dataKey: TABLE_FIELDS.COMMENTS,
    Component: workflowSummaryCommonCells.CommentsCell
  },
  {
    title: 'Attachments',
    dataKey: TABLE_FIELDS.ATTACHMENTS,
    width: '15rem',
    Component: workflowSummaryCommonCells.AttachmentsCell
  }
];
