import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'utils/query-string';
import lodash from 'lodash';
import classnames from 'classnames';
import { getCategoryIDByName } from 'shared/features/documents/documents.selectors';
import {
  getFollowedAgenciesIds,
  getRealFollowedNewsSourcesIds
} from 'shared/features/defaults/defaults.selectors';
import { dashboard_banners, bookmark_banner } from 'utils/bannerComponents';
import { browserName } from 'utils/browser';
import {
  get_search_view,
  shouldShowFilterMenu,
  APP_VIEW_SECTION_USER_FOLDERS,
  APP_VIEW_SECTION_SAVED_SEARCHES
} from 'utils/search';
import { DOCUMENT_CATEGORIES } from 'constants/DocumentConstants';
import { DEFAULT_TYPES } from 'constants/DefaultSources';
import {
  DISPLAY_SOURCES,
  SEARCH_QUERY,
  FREE_TRIAL,
  UNSUPPORTED_FILERMENU_PATHS
} from '../TimelineToolbar.contants';

export const useTimelineToolbarData = () => {
  const location = useLocation();

  const [openSuggestionBoxModal, setOpenSuggestionBoxModal] = useState(false);

  const reduxState = useSelector(state => ({
    current_view: state.current_view,
    current_user: state.current_user,
    folders: state.folders,
    documents_full: state.documents_full,
    filtered_mention: state.filtered_mention,
    subscriptions: state.subscriptions,
    search_results_relevance: state.search_results_relevance,
    categories: state.docTypes.docTypes.cai_categories,
    errors: state.errors,
    followedAgenciesIds: getFollowedAgenciesIds(DEFAULT_TYPES.MY_DEFAULTS)(state),
    followedNewsSourcesIds: getRealFollowedNewsSourcesIds(DEFAULT_TYPES.MY_DEFAULTS)(state)
  }));

  const parsedQuery = queryString.parse(location.search);

  const languageId = reduxState.current_user.user.languageId;

  const newsCategoryIds = useMemo(() => {
    const newsID = getCategoryIDByName(reduxState.categories, DOCUMENT_CATEGORIES.NEWS);
    const mainstreamNewsID = getCategoryIDByName(
      reduxState.categories,
      DOCUMENT_CATEGORIES.MAINSTREAM_NEWS
    );

    return {
      [DISPLAY_SOURCES.AGENCY_NEWS]: newsID,
      [DISPLAY_SOURCES.MAINSTREAM_NEWS]: mainstreamNewsID
    };
  }, [reduxState.categories]);

  const displaySource = useMemo(() => {
    if (parsedQuery.cai_category === newsCategoryIds[DISPLAY_SOURCES.AGENCY_NEWS].toString()) {
      return DISPLAY_SOURCES.AGENCY_NEWS;
    } else if (
      parsedQuery.cai_category === newsCategoryIds[DISPLAY_SOURCES.MAINSTREAM_NEWS].toString()
    ) {
      return DISPLAY_SOURCES.MAINSTREAM_NEWS;
    }

    return DISPLAY_SOURCES.ALL_NEWS_SOURCES;
  }, [parsedQuery.cai_category, newsCategoryIds]);

  const dashBannersNotViewed = useMemo(() => {
    const dash_banners_not_viewed = [];

    if (lodash.has(reduxState.current_user.user, 'properties') && reduxState.current_user.isReady) {
      for (const dash_banner of dashboard_banners) {
        const user_properties = reduxState.current_user.user.properties;
        if (
          !lodash.has(user_properties, 'read_new_feature_tip') ||
          !user_properties.read_new_feature_tip[dash_banner.id]
        ) {
          //add content here to pass router to button
          const bkmk_banner = bookmark_banner(browserName());

          if (dash_banner.type === 'bookmark_compliance_ai_success') {
            dash_banner.content = bkmk_banner;
          }
          dash_banners_not_viewed.push(dash_banner);
        }
      }
    }

    return dash_banners_not_viewed;
  }, [reduxState.current_user.user, reduxState.current_user.isReady]);

  const searchView = useMemo(() => {
    return get_search_view(reduxState.current_view.search_params, location);
  }, [reduxState.current_view.search_params, location]);

  const showFilterMenu = useMemo(() => {
    return (
      shouldShowFilterMenu(searchView.section) &&
      !UNSUPPORTED_FILERMENU_PATHS.includes(location.pathname)
    );
  }, [searchView.section, location]);

  const isOnFreeTrialSubscription = useMemo(() => {
    return reduxState.subscriptions.subscriptions.reduce((mem, subscription) => {
      if (subscription.active && subscription.category === FREE_TRIAL) {
        mem = true;
      }
      return mem;
    }, false);
  }, [reduxState.subscriptions.subscriptions]);

  const currentSubscription = useMemo(() => {
    return reduxState.subscriptions.subscriptions.filter(subscription => subscription.active)[0];
  }, [reduxState.subscriptions.subscriptions]);

  const mainClassName = useMemo(() => {
    return classnames({
      'timeline-toolbar': !reduxState.current_view.inMobile,
      noBorders:
        searchView.section === APP_VIEW_SECTION_SAVED_SEARCHES ||
        searchView.section === APP_VIEW_SECTION_USER_FOLDERS,
      'tab-styling': SEARCH_QUERY in parsedQuery
    });
  }, [reduxState.current_view.inMobile, searchView.section, parsedQuery]);

  return {
    localState: {
      openSuggestionBoxModal
    },
    localActions: {
      setOpenSuggestionBoxModal
    },
    formattedData: {
      parsedQuery,
      newsCategoryIds,
      displaySource,
      dashBannersNotViewed,
      searchView,
      showFilterMenu,
      isOnFreeTrialSubscription,
      currentSubscription,
      mainClassName,
      languageId
    },
    reduxState
  };
};
