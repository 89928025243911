import type * as react from 'react';
import type { DocumentItemProps } from './DocumentItem.types';

import * as uiLib from '@compliance.ai/web-components';
import * as env from 'shared/config';

import { DOCUMENT_CONTEXT_MENU_TEXTS } from 'constants/DocumentContextMenu';

import classnames from 'classnames';

import { useDocumentItemHandlers } from './hooks';

export const DocumentItem: react.FC<DocumentItemProps> = ({ cellValue }) => {
  const handlers = useDocumentItemHandlers({
    props: {
      cellValue
    }
  });

  return (
    <uiLib.DocumentListItem
      size={uiLib.DOCUMENT_LIST_ITEM_SIZES.MEDIUM}
      document={cellValue.document}
      onClick={handlers.handleDocumentClick}
      isSelected={cellValue.selectedDocsIds.includes(cellValue.document.id)}
      isClickable={cellValue.isDocumentClickable}
      isChicletClickable={cellValue.isDocumentChicletClickable}
      onBookmarkButtonClick={handlers.handleBookmark}
      onRelatedDocumentChicletOptionClick={handlers.handleRelatedDocsChicletClick}
      onTopicChicletOptionClick={handlers.handleTopicClick}
      whitelistedFields={cellValue.visibleDocFields}
      shouldRenderBookmarkButton={!cellValue.isRestricted}
      shouldRenderChiclets
      shouldRenderContextMenu
      shouldRenderOrgRelatedDocsChiclet={Boolean(env.orgRelatedDocsEnabled)}
      contextMenuOptions={[
        {
          label: DOCUMENT_CONTEXT_MENU_TEXTS.OPEN_DOCUMENT_NEW_TAB,
          onClick: handlers.handleDocumentOpen(true)
        },
        {
          label: DOCUMENT_CONTEXT_MENU_TEXTS.OPEN_DOCUMENT_NEW_WINDOW,
          onClick: handlers.handleDocumentOpen(false)
        }
      ]}
      className={classnames('documents-table__document', {
        'documents-table__document--is-clickable': cellValue.isDocumentClickable
      })}
    />
  );
};
