import type * as apiTypes from './labelTypes.api.types';

export const normalizeLabelType = ({
  labelType
}: {
  labelType: apiTypes.LabelTypeFromResponse;
}): apiTypes.NormalizedLabelType => {
  return {
    id: labelType.id,
    name: labelType.name
  };
};

export const normalizeLabelTypesResponse = ({
  response
}: {
  response: apiTypes.GetAllLabelTypesResponse;
}): apiTypes.NormalizedServiceConnectorsResponse => {
  return {
    count: response.count,
    offset: response.offset,
    total: response.total,
    limit: response.limit,
    results: response.results.map(labelType => normalizeLabelType({ labelType }))
  };
};

export const formatLabelTypeOption = (response: apiTypes.GetAllLabelTypesResponse) => {
  return response.results.map(labelType => {
    return {
      value: labelType.id,
      label: labelType.name
    };
  });
};
