export const USER_ACCOUNT_TAB_TITLES = {
  PROFILE: 'Profile',
  EMAIL_NOTIFICATIONS: 'Email Notifications'
};

export const ROUTE_KEY = 'view';

export enum USER_ACCOUNT_ROUTE_KEYS {
  PROFILE = 'profile',
  EMAIL_NOTIFICATIONS = 'emailNotifications'
}
