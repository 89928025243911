import type { DocumentTimelineItemData } from './useDocumentTimelineItemData.types';
import type { GlobalState } from 'shared/reducers';
import type { DocumentTimelineItemProps } from '../DocumentTimelineItem.types';

import * as documentsSelectors from '../../../shared/features/documents/documents.selectors';

import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { getCheckboxType } from './useDocumentTimelineItemData.helpers';

export const useDocumentTimelineItemData = (
  props: Pick<DocumentTimelineItemProps, 'document'>
): DocumentTimelineItemData => {
  const reduxState = useSelector<GlobalState, DocumentTimelineItemData['reduxState']>(state => ({
    sortedDocumentsToDiff: documentsSelectors.getSortedDocumentsToDiff(state as never),
    // TODO Remove suppression once documents.selectors moved to TS
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    categoryName: documentsSelectors.getCategoryNameById(state, props.document.cai_category_id)
  }));

  const formattedData: DocumentTimelineItemData['formattedData'] = useMemo(() => {
    const agencyShortNames = props.document.agencies
      .map(agency => agency.short_name)
      .sort()
      .join(', ');

    const isCheckedOldDoc = reduxState.sortedDocumentsToDiff[0]?.id === props.document.id;
    const isCheckedNewDoc = reduxState.sortedDocumentsToDiff[1]?.id === props.document.id;

    const checkboxType = getCheckboxType({
      isCheckedOldDoc,
      isCheckedNewDoc
    });

    const isCheckboxTooltipDisabled = Boolean(
      isCheckedOldDoc || isCheckedNewDoc || reduxState.sortedDocumentsToDiff.length < 2
    );

    const isCheckboxDisabled =
      !isCheckedOldDoc && !isCheckedNewDoc && reduxState.sortedDocumentsToDiff.length >= 2;

    return {
      agencyShortNames,
      isCheckedOldDoc,
      isCheckedNewDoc,
      checkboxType,
      isCheckboxTooltipDisabled,
      isCheckboxDisabled
    };
  }, [props.document.agencies, props.document.id, reduxState.sortedDocumentsToDiff]);

  return {
    reduxState,
    formattedData
  };
};
