import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import queryString from 'utils/query-string';
import lodash from 'lodash';
import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { explicit_filter_function } from 'utils/filter/filter';
import { safe_analytics } from 'utils/analytics';
import {
  postSearchQuery as postSearchQueryAction,
  rateSearchResult as rateSearchResultAction
} from 'shared/features/user/user.actions';
import { ADMIN } from 'constants/UserRoles';
import { withHistory } from 'utils/hooks';
import './_topic-list-item.scss';

export const TEST_ID = {
  CONTAINER: 'topics-list-item-container',
  THUMBS_UP_ICON: 'topics-list-item-thumbs-up-icon',
  THUMBS_DOWN_ICON: 'topics-list-item-thumbs-down-icon',
  ADMIN_DATA: 'topics-list-item-admin-data'
};

export class TopicsListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      relevant: null
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    /*
      If the document has already been rated relevant by the user using the same
      search parameters, surface the last vote cast, otherwise show as if not voted.
    */
    const { relevant } = this.state;

    const document = nextProps.document;
    if (document && nextProps.user_vote.voted_docs[document.id]) {
      if (
        lodash.isEqual(
          nextProps.user_vote.voted_docs[document.id].search_args,
          nextProps.user_vote.search_args
        )
      ) {
        const nextPropsTopicId =
          nextProps.user_vote.voted_docs[document.id].search_args &&
          nextProps.user_vote.voted_docs[document.id].search_args.query &&
          nextProps.user_vote.voted_docs[document.id].search_args.query.topic_id;
        const correct_topic =
          !lodash.isNull(nextPropsTopicId) && nextPropsTopicId === nextProps.topic.id;

        if (nextProps.user_vote.voted_docs[document.id].relevance && correct_topic) {
          if (relevant !== true) {
            this.setState({ relevant: true });
          }
        }

        if (nextProps.user_vote.voted_docs[document.id].relevance === false && correct_topic) {
          if (relevant !== false) {
            this.setState({ relevant: false });
          }
        }
      }
    }
  }

  handleTopicClick = e => {
    const { topic, directLink, location } = this.props;

    e.preventDefault();
    e.stopPropagation();

    safe_analytics('default', 'Doc Details', 'Topic click', topic.name);
    if (directLink) {
      this.props.history.push(
        `/content?${queryString.stringify({
          order: 'desc',
          search_sort: 'publication_date',
          topic_id: topic.id
        })}`
      );
    } else {
      explicit_filter_function(
        {},
        location,
        (url, options) => this.props.history.push(url, options?.state),
        { topic_id: topic.id, overlay: null },
        this.props
      );
    }
  };

  handleVoting(is_relevant) {
    const { topic, document, postSearchQuery, user_vote, rateSearchResult, location } = this.props;
    const { search } = location;
    const parsedQuery = queryString.parse(search);

    this.setState({ relevant: is_relevant });

    const vote = is_relevant ? 'Yes' : 'No';
    safe_analytics('default', 'Feedback', 'Relevant Topic', vote);

    const queryWithTopicId = {
      ...parsedQuery,
      topic_id: topic.id
    };

    postSearchQuery({ search_args: { query: queryWithTopicId } }).then(() => {
      const search_args = user_vote.search_args;
      rateSearchResult(document.id, is_relevant, search_args);
    });
  }

  render() {
    const { topic, current_user, rightPanel } = this.props;
    const { relevant } = this.state;

    const probability = topic.model_probability;

    const relevanceVoteClasses = {
      'topic-list-item__relevant-vote': true,
      'topic-list-item__relevant': relevant === true
    };
    const notRelevanceVoteClasses = {
      'topic-list-item__not-relevant-vote': true,
      'topic-list-item__not-relevant': relevant === false
    };

    const showAdminData = current_user.user.roles ? current_user.user.roles.includes(ADMIN) : false;

    return (
      <div data-testid={TEST_ID.CONTAINER} className="topic-list-item">
        <Button type={BUTTON_TYPES.CHICLET} onClick={this.handleTopicClick}>
          {topic.name}&nbsp;
          {rightPanel && showAdminData && (
            <span data-testid={TEST_ID.ADMIN_DATA}>
              {' (' + topic.judge_count + ', ' + topic.positive_judgments + ', '}
              {!lodash.isNull(probability) ? lodash.round(probability, 4) : ''})
            </span>
          )}
        </Button>
        {rightPanel && showAdminData ? (
          <div className="topic-list-item__vote-icons">
            <ThumbUpIcon
              data-testid={TEST_ID.THUMBS_UP_ICON}
              className={classNames(relevanceVoteClasses)}
              title="Relevant"
              onClick={() => this.handleVoting(true)}
            />
            <ThumbDownIcon
              data-testid={TEST_ID.THUMBS_DOWN_ICON}
              className={classNames(notRelevanceVoteClasses)}
              title="Not Relevant"
              onClick={() => this.handleVoting(false)}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ user_vote, current_user, agencies }) => {
  return {
    user_vote,
    current_user,
    agencies
  };
};

const ReduxTopicsListItem = connect(mapStateToProps, {
  rateSearchResult: rateSearchResultAction,
  postSearchQuery: postSearchQueryAction
})(withHistory(TopicsListItem));

export default ReduxTopicsListItem;
