import type { TextBoxProps } from './TextBox.types';

import { ActionButton, IconFileCopy, TOOLTIP_TYPES, Loader } from '@compliance.ai/web-components';
import classnames from 'classnames';
import { TEST_IDS } from './TextBox.constants';
import { useTextBoxHandlers } from './hooks';
import './_text-box.scss';

export const TextBox = ({ title, value, isLoading, className }: TextBoxProps) => {
  const handlers = useTextBoxHandlers({
    props: {
      value
    }
  });

  return (
    <div className={classnames('text-box__container', className)}>
      <div className="text-box__header">
        <span className="text-box__title" data-testid={TEST_IDS.TITLE}>
          {title}
        </span>
        <ActionButton
          tooltip="Copy"
          tooltipType={TOOLTIP_TYPES.PRIMARY}
          onClick={handlers.handleCopy}
          isDisabled={!value}
          dataTestId={TEST_IDS.COPY_BUTTON}
        >
          <IconFileCopy />
        </ActionButton>
      </div>
      <div
        className={classnames({
          'text-box__text': true,
          'text-box__text--is-loading': isLoading
        })}
      >
        {isLoading ? <Loader dataTestId={TEST_IDS.LOADER} /> : value}
      </div>
    </div>
  );
};
