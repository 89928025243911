import type { WorkflowDetailsProps } from '../WorkflowDetails.types';

import { QUERY_KEYS } from 'constants/QueryKeys';

import * as workflowsApi from 'shared/features/workflow/workflow.api';
import * as helpers from './useWorkflowDetailsQueries.helpers';

import { useQuery } from '@tanstack/react-query';

export const useWorkflowDetailsQueries = ({
  props
}: {
  props: Pick<WorkflowDetailsProps, 'document' | 'workflowId' | 'sentenceNoteId'>;
}) => {
  const fetchWorkflowsByDocIDs = useQuery({
    queryKey: [
      QUERY_KEYS.WORKFLOWS,
      {
        documentId: props.document.id,
        workflowId: props.workflowId,
        sentenceNoteId: props.sentenceNoteId
      }
    ],
    queryFn: async () => {
      const response = await workflowsApi.api_fetchDocumentTasks(
        {
          doc_id: props.document.id,
          workflow_id: props.workflowId,
          sentence_note_id: props.sentenceNoteId,
          exclusive_fields: [
            'results.workflow_name',
            'results.workflow_id',
            'results.task_order_ids'
          ]
        },
        { shouldDisableQueryParamsArrayFormatting: true }
      );

      return helpers.formatTasksResponse(response);
    },
    refetchOnWindowFocus: false
  });

  return {
    fetchWorkflowsByDocIDs
  };
};
