import lodash from 'lodash';

export const useGenericAttachmentsHandlers = ({ props, localActions }) => {
  const showWarning = message => {
    if (message) {
      props.onError(message);
    }

    if (props.showWarnings) {
      localActions.showValidationWarning(message);
    }
  };

  const handleFilesDrop = async newFiles => {
    try {
      const existingFileNames = lodash.intersection(
        props.attachments.map(a => a.userFileName),
        newFiles.map(f => f.name)
      );

      if (existingFileNames.length > 0) {
        showWarning('File name already exists.');
        return;
      }

      if (props.attachments.length + newFiles.length > props.maxFiles) {
        showWarning(`You cannot save more than ${props.maxFiles} files.`);
        return;
      }

      localActions.setLoadingState(true);
      await props.saveAttachment({ newFiles });
    } finally {
      localActions.setLoadingState(false);
    }
  };

  const handleFileDelete = attachment => async () => {
    try {
      localActions.setLoadingState(true);
      await props.deleteAttachment({ attachment });
    } finally {
      localActions.setLoadingState(false);
    }
  };

  const handleAlertClose = () => {
    showWarning('');
  };

  return {
    handleFilesDrop,
    handleFileDelete,
    handleAlertClose
  };
};
