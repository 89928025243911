import queryParams from 'query-string';

import * as config from 'shared/config';
import * as routes from 'constants/Routes';
import * as cognitoUtils from 'utils/cognito-utils';

export const DEFAULT_ERROR_MESSAGE = 'An unknown error occurred. Please try again later.';

export const QUERY_PARAMS = {
  EMAIL: 'email',
  ORG_INVITATION_TOKEN: 'org_invitation_token',
  VERIFICATION_CODE: 'verification_code',
  APPROVED_APP_KEY: 'approved_app_key',
  APPROVED_APP_TOKEN: 'approved_app_token',
  IS_SSO_USER: 'is_sso_user'
};

export const COGNITO_AUTH_QUERY_PARAMS = {
  IDENTITY_PROVIDER: 'identity_provider',
  REDIRECT_URI: 'redirect_uri',
  LOGOUT_URI: 'logout_uri',
  RESPONSE_TYPE: 'response_type',
  CLIENT_ID: 'client_id',
  SCOPE: 'scope',
  GRANT_TYPE: 'grant_type',
  CODE: 'code'
};

/**
 *
 * Docs: https://docs.aws.amazon.com/cognito/latest/developerguide/authorization-endpoint.html
 */
export const GOOGLE_AUTH_QUERY_PARAMS = queryParams.stringify(
  {
    [COGNITO_AUTH_QUERY_PARAMS.IDENTITY_PROVIDER]: 'Google',
    [COGNITO_AUTH_QUERY_PARAMS.REDIRECT_URI]: `${config.appUrl}${routes.authRedirect}`,
    [COGNITO_AUTH_QUERY_PARAMS.RESPONSE_TYPE]: 'CODE',
    [COGNITO_AUTH_QUERY_PARAMS.CLIENT_ID]: config.clientId,
    [COGNITO_AUTH_QUERY_PARAMS.SCOPE]: 'aws.cognito.signin.user.admin+email+openid+profile'
  },
  {
    encode: false
  }
);

/**
 *
 * Docs: https://docs.aws.amazon.com/cognito/latest/developerguide/authorization-endpoint.html
 */
export const GOOGLE_AUTH_URL = `${config.cognitoDomain}/oauth2/authorize?${GOOGLE_AUTH_QUERY_PARAMS}`;

/**
 *
 * Note #1: /logout means here that the signed in user will be logged out, but afterwards the
 * same authorization process will happen as in /oauth2/authorize
 *
 * See example #2:
 * https://docs.aws.amazon.com/cognito/latest/developerguide/logout-endpoint.html
 *
 * Note #2: This endpoint is wrongly documented. It redirects to /login instead of /oauth2/authorize
 */
export const GOOGLE_AUTH_URL_WITH_LOGOUT = `${config.cognitoDomain}/logout?${GOOGLE_AUTH_QUERY_PARAMS}`;

/**
 *
 * Docs (example #1): https://docs.aws.amazon.com/cognito/latest/developerguide/logout-endpoint.html
 */
export const COGNITO_LOGOUT_URL = `${config.cognitoDomain}/logout?${queryParams.stringify(
  {
    [COGNITO_AUTH_QUERY_PARAMS.CLIENT_ID]: config.clientId,
    [COGNITO_AUTH_QUERY_PARAMS.LOGOUT_URI]: `${config.appUrl}${routes.authRedirect}`
  },
  {
    encode: false
  }
)}`;

export const COGNITO_TOKEN_URL = `${config.cognitoDomain}/oauth2/token`;

export const EXPECTED_COGNITO_AUTH_QUERY_PARAMS = {
  ERROR: 'error',
  ERROR_DESCRIPTION: 'error_description',
  CODE: 'code'
};

export const COGNITO_AUTH_REDIRECT_MESSAGE_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error'
};

export const EXPECTED_ERROR_MESSAGES = {
  ATTEMPTS_EXCEEDED: 'Attempt limit exceeded, please try after some time.',
  VALIDATION_ERROR: 'validation error',
  PASSWORD_POLICY: 'Password did not conform with policy',
  INVALID_VERIFICATION_CODE: 'Invalid code provided, please request a code again.',
  INVALID_ORG_INVITATION_TOKEN: 'Invalid org_invitation_token',
  USER_DOES_NOT_EXIST: 'User does not exist.',
  CURRENT_USER_IS_NOT_DETECTED: 'Current user is not detected',
  COGNITO_ERROR: cognitoUtils.COGNITO_UNKNOWN_ERROR_CODE,
  NETWORK_ERROR: 'Network error'
};
