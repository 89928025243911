import type { DocumentTasksResponse } from 'shared/features/workflow/workflow.types';
import type { minimalWorkflowItems } from './useWorkflowDetailsQueries.types';

export const formatTasksResponse = (response: DocumentTasksResponse): minimalWorkflowItems => {
  const result = response.results.reduce((unique, o) => {
    if (!unique.some(obj => obj.workflow_id === o.workflow_id)) {
      unique.push(o);
    }
    return unique;
  }, [] as minimalWorkflowItems);

  return result;
};
