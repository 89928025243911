import { useDidMount, useUpdateEffect } from 'utils/hooks';
import { useEffect } from 'react';
import { getDashboardLayout } from '../Dashboard.helpers';
import lodash from 'lodash';
import auth from 'utils/auth';
import { sendBrowserAgnosticEvent } from 'utils/dispatchEvent';

export const useDashboardLifecycle = ({
  props,
  localState,
  localActions,
  reduxState,
  reduxActions,
  layoutHandlers
}) => {
  useDidMount(() => {
    reduxActions.clearAutoComplete();
  });

  /**
   * This hook resizes dashboard after right panel close
   */
  useEffect(() => {
    if (!reduxState.isRightPanelOpen) {
      sendBrowserAgnosticEvent(window, 'resize');
    }
  }, [reduxState.isRightPanelOpen]);

  /**
   * This hook sets initial dashboard state
   */
  useEffect(() => {
    if (!localState.isUserLayoutUsed && (!auth.loggedIn() || reduxState.isCurrentUserReady)) {
      const { components, layout } = getDashboardLayout({
        defaultLayout: props.defaultLayout,
        defaultComponents: props.defaultComponents,
        dashboardType: props.dashboardType,
        userDashboardLayouts: reduxState.userDashboardLayouts
      });

      localActions.setLoadingState(false);
      localActions.setLayoutComponents(components);
      localActions.setLayout(layout);
      localActions.setUserLayoutUsedState(true);
    }
  }, [
    localActions,
    localState.components,
    localState.isUserLayoutUsed,
    props.componentDimensions,
    props.dashboardType,
    props.defaultComponents,
    props.defaultLayout,
    reduxActions,
    reduxState.isCurrentUserReady,
    reduxState.userDashboardLayouts
  ]);

  /**
   * This hook tracks if there is any copied widget and adds it to the dashboard.
   */
  useUpdateEffect(() => {
    const { components } = getDashboardLayout({
      defaultLayout: props.defaultLayout,
      defaultComponents: props.defaultComponents,
      dashboardType: props.dashboardType,
      userDashboardLayouts: reduxState.userDashboardLayouts
    });
    const addedComponents = lodash.differenceWith(
      components,
      localState.components,
      lodash.isEqual
    );

    if (addedComponents.length) {
      layoutHandlers.handleComponentsSelect(addedComponents);
    }
  }, [reduxState.userDashboardLayouts]);
};
