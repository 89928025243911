import type { GlobalState } from '../../reducers';
import type * as actionTypes from './rightPanel.actions.types';

import * as reduxToolkit from '@reduxjs/toolkit';
import * as redux from 'react-redux';
import * as errorUtils from 'utils/errors';
import * as rightPanelSelectors from './rightPanel.selectors';

export const OPEN_RIGHT_PANEL = 'OPEN_RIGHT_PANEL';
export const CLOSE_RIGHT_PANEL = 'CLOSE_RIGHT_PANEL';
export const CHANGE_DOCUMENT_DETAILS_ACTIVE_TAB = 'CHANGE_DOCUMENT_DETAILS_ACTIVE_TAB';
export const CHANGE_SENTENCE_NOTE_ID = 'CHANGE_SENTENCE_NOTE_ID';
export const TRIGGER_RIGHT_PANEL_LABELS_REFRESH = 'TRIGGER_RIGHT_PANEL_LABELS_REFRESH';
export const TRIGGER_RIGHT_PANEL_TASKS_REFRESH = 'TRIGGER_RIGHT_PANEL_TASKS_REFRESH';
export const TRIGGER_OVERLAY_REFRESH = 'TRIGGER_OVERLAY_REFRESH';
export const OPEN_OVERLAY = 'OPEN_OVERLAY';
export const CLOSE_OVERLAY = 'CLOSE_OVERLAY';
export const TOGGLE_OVERLAY = 'TOGGLE_OVERLAY';

export const openRightPanel: actionTypes.OpenRightPanelAction = reduxToolkit.createAction(
  OPEN_RIGHT_PANEL
);
export const closeRightPanel: actionTypes.CloseRightPanelAction = reduxToolkit.createAction(
  CLOSE_RIGHT_PANEL
);
export const changeDocumentDetailsActiveTab: actionTypes.ChangeDocumentDetailsActiveTabKey = reduxToolkit.createAction(
  CHANGE_DOCUMENT_DETAILS_ACTIVE_TAB
);
export const changeSentenceNoteId: actionTypes.ChangeSentenceNoteId = reduxToolkit.createAction(
  CHANGE_SENTENCE_NOTE_ID
);
export const triggerRightPanelLabelsRefresh: actionTypes.triggerRightPanelLabelsRefresh = reduxToolkit.createAction(
  TRIGGER_RIGHT_PANEL_LABELS_REFRESH
);
export const triggerRightPanelTasksRefresh: actionTypes.TriggerRightPanelTasksRefresh = reduxToolkit.createAction(
  TRIGGER_RIGHT_PANEL_TASKS_REFRESH
);
export const triggerOverlayRefresh: actionTypes.TriggerOverlayRefresh = reduxToolkit.createAction(
  TRIGGER_OVERLAY_REFRESH
);
export const openOverlay: actionTypes.OpenOverlay = reduxToolkit.createAction(OPEN_OVERLAY);
export const closeOverlay: actionTypes.CloseOverlay = reduxToolkit.createAction(CLOSE_OVERLAY);

export const toggleOverlay = reduxToolkit.createAsyncThunk<
  actionTypes.ToggleOverlayReturn,
  actionTypes.ToggleOverlayPayload
>(TOGGLE_OVERLAY, (type, { dispatch, getState }) => {
  try {
    redux.batch(() => {
      const isOverlayOpen = rightPanelSelectors.getOverlayOpenState(getState() as GlobalState);

      if (isOverlayOpen) {
        dispatch(closeOverlay());
      } else {
        dispatch(openOverlay(type));
      }
    });
  } catch (e) {
    errorUtils.logError(e as Error);
  }
});
