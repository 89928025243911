import type { FC } from 'react';
import type { LoaderProps } from './Loader.types';

import * as uiLib from '@compliance.ai/web-components';

import classnames from 'classnames';

import './_loader.scss';

export const Loader: FC<LoaderProps> = ({
  text,
  messages,
  progress,
  isContrast,
  withoutBackground
}) => {
  return (
    <div
      className={classnames({
        'sentence-notes-loader__container': true,
        'sentence-notes-loader__container--with-background': !withoutBackground
      })}
    >
      {!withoutBackground && <div className="sentence-notes-loader__background" />}
      <uiLib.Typography
        type={uiLib.TYPOGRAPHY_TYPES.BODY1}
        isContrast={isContrast}
        className={classnames({
          'sentence-notes-loader__text': true,
          'sentence-notes-loader__text--with-extra-padding': !messages?.length
        })}
      >
        {text}
      </uiLib.Typography>
      <uiLib.Loader
        type={uiLib.LOADER_TYPE.PROGRESS}
        isContrast={isContrast}
        messages={messages}
        progress={progress}
        classNames={{
          messagesContainer: 'sentence-notes-loader__messages',
          innerContainer: 'sentence-notes-loader__inner-container'
        }}
      />
    </div>
  );
};

Loader.defaultProps = {
  withoutBackground: true
};
