export enum DOCUMENT_CATEGORIES {
  ENFORCEMENT = 'Enforcement',
  WHITEPAPER = 'Whitepaper',
  ENFORCEMENT_METADATA = 'Enforcement Metadata', //Look into what the hell this is
  POLICY_STATEMENT = 'Policy Statement',
  STATEMENT_OF_POLICY = 'Statement of Policy',
  POLICY = 'Policy',
  POLICY_PAPER = 'Policy Paper',
  PROPOSED_RULE = 'Proposed Rule',
  RULE = 'Rule',
  STATE_CODE = 'State Code',
  STATE_CODE_NAVIGATION = 'State Code Navigation',
  STATUTE = 'Statute',
  STATUTES = 'Statutes',
  NEWS = 'News',
  MAINSTREAM_NEWS = 'Mainstream News',
  STATUTE_NAVIGATION = 'Statute Navigation',
  US_CODE = 'US Code',
  US_CODE_NAVIGATION = 'US Code Navigation',
  US_PUBLIC_LAW = 'US Public Law',
  US_PUBLIC_LAW_NAVIGATION = 'US Public Law Navigation',
  CFR = 'CFR',
  CFR_NAVIGATION = 'CFR Navigation',
  LAW = 'Law',
  PUBLIC_ACT = 'Public Act',
  ACT = 'Act',
  DECISION_EU = 'Decision (EU)',
  DIRECTIVE = 'Directive',
  REGULATION = 'Regulation',
  STATUSES_NAVIGATION = 'Statutes Navigation',
  STATUSES = 'Statuses',
  ORDINANCE = 'Ordinance',
  DECREE = 'Decree',
  DIRECTIVE_EU = 'Directive (EU)',
  ENROLLED_AND_ENACTED_BILL = 'Enrolled And Enacted Bill',
  STANDARD = 'Standard',
  INTERNATIONAL_TREATY = 'International Treaty',
  REGULATION_EU = 'Regulation (EU)',
  EMERGENCY_RULE = 'Emergency Rule'
}

export const RESOURCE_CODE_CATEGORIES = [
  DOCUMENT_CATEGORIES.STATE_CODE_NAVIGATION,
  DOCUMENT_CATEGORIES.STATE_CODE,
  DOCUMENT_CATEGORIES.STATUTE_NAVIGATION,
  DOCUMENT_CATEGORIES.STATUTE,
  DOCUMENT_CATEGORIES.STATUSES_NAVIGATION,
  DOCUMENT_CATEGORIES.STATUSES,
  DOCUMENT_CATEGORIES.US_PUBLIC_LAW_NAVIGATION,
  DOCUMENT_CATEGORIES.US_PUBLIC_LAW,
  DOCUMENT_CATEGORIES.US_CODE_NAVIGATION,
  DOCUMENT_CATEGORIES.US_CODE,
  DOCUMENT_CATEGORIES.CFR_NAVIGATION,
  DOCUMENT_CATEGORIES.CFR
];

export const DOCUMENT_META_CATEGORIES = {
  ENFORCEMENTS: 'Enforcements',
  REPORTS: 'Reports',
  REGULATIONS: 'Regulations',
  LEGISLATION: 'Legislation'
};
export const DOCUMENT_SUMMARY_PAGE_KEY = 'summary';

export const FINANCIAL_TRENDS_CONSTANTS = {
  categories: [DOCUMENT_CATEGORIES.RULE, DOCUMENT_CATEGORIES.PROPOSED_RULE],
  metacategories: [DOCUMENT_META_CATEGORIES.ENFORCEMENTS]
};

export const FINAL_PROPOSED_RULE_CONSTANTS = {
  categories: [DOCUMENT_CATEGORIES.RULE, DOCUMENT_CATEGORIES.PROPOSED_RULE]
};

export enum PRE_CONTENT_TYPE {
  ENFORCEMENTS_CHART = 'enforcementsChart'
}

export enum SUMMARY_TYPE {
  LONG = 'Long',
  SHORT = 'Short'
}
