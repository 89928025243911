import { useMemo } from 'react';
import { usePermissions } from 'utils/hooks';
import { UNLIMITED_SEARCHES } from 'constants/Permissions';
import { getSearchCount } from 'shared/features/user/user.selectors';
import { useSelector } from 'react-redux';

const SEARCH_LIMIT = 100;

export const useSearchButtonData = ({ disabled }) => {
  const searchCount = useSelector(getSearchCount);
  const permissions = usePermissions();
  const limitedSearches = !permissions[UNLIMITED_SEARCHES];
  const overSearchLimit = limitedSearches && searchCount > SEARCH_LIMIT;

  const isDisabled = useMemo(() => {
    return disabled || overSearchLimit;
  }, [disabled, overSearchLimit]);

  return {
    isDisabled,
    overSearchLimit
  };
};
