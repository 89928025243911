import type { FC } from 'react';
import type { SentenceNoteTooltipTitleProps } from './SentenceNoteTooltipTitle.types';

import * as uiLib from '@compliance.ai/web-components';

import './_sentence-note-tooltip-title.scss';

export const SentenceNoteTooltipTitle: FC<SentenceNoteTooltipTitleProps> = ({ bucketName }) => {
  return (
    <span className="sentence-note-tooltip-title">
      <uiLib.Typography
        type={uiLib.TYPOGRAPHY_TYPES.BODY2}
        className="sentence-note-tooltip-title__prefix"
      >
        Collection:&nbsp;
      </uiLib.Typography>
      <uiLib.Typography type={uiLib.TYPOGRAPHY_TYPES.BODY2}>{bucketName}</uiLib.Typography>
    </span>
  );
};
