import { fetch } from 'utils/api';

export const getFolders = (parentId, params) => {
  return fetch({
    url: `/folders/${parentId || ''}`,
    method: 'GET',
    params
  });
};

export const getDocumentsSavedFolders = params => {
  return fetch({
    url: '/folders/docs_saved',
    method: 'POST',
    params
  });
};

export const getDocumentSavedFoldersCount = documentId => {
  return fetch({
    url: `/folders/doc_saved_folders_count/${documentId}`,
    method: 'GET'
  });
};

export const getFolderDetails = id => {
  return fetch({
    url: `/folders/${id}/details`,
    method: 'GET'
  });
};

export const getFolderPaths = id => {
  return fetch({
    url: `/folders/${id}/paths`,
    method: 'GET'
  });
};

export const createFolder = (parentId, params) => {
  return fetch({
    url: `/folders/${parentId || ''}`,
    method: 'POST',
    params
  });
};

export const modifyFolder = (parentId, id, params) => {
  return fetch({
    url: `/folders/${parentId ? parentId + '/' : ''}${id}`,
    method: 'PATCH',
    params
  });
};

export const deleteFolders = (parentId, params) => {
  return fetch({
    url: `/folders/${parentId || ''}`,
    method: 'DELETE',
    params
  });
};

export const copyFolder = (id, params) => {
  return fetch({
    url: `/folders/${id}/copy`,
    method: 'POST',
    params
  });
};

export const shareFolder = (id, params) => {
  return fetch({
    url: `/folders/${id}/share`,
    method: 'POST',
    params
  });
};

export const addDocumentsToFolder = (id, params) => {
  return fetch({
    url: `/folders/${id}/add_documents`,
    method: 'POST',
    params
  });
};
