import type { AccountMenuHandlers, AccountMenuOption } from './useAccountMenuHandlers.types';
import type { AccountMenuData } from './useAccountMenuData.types';

import * as routes from 'constants/Routes';
import * as errorUtils from 'utils/errors';

import { useNavigate } from 'react-router-dom';
import { useAuthReduxActions } from 'shared/features/auth/hooks';

export const useAccountMenuHandlers = ({
  localActions
}: {
  localActions: AccountMenuData['localActions'];
}): AccountMenuHandlers => {
  const navigate = useNavigate();
  const authReduxActions = useAuthReduxActions();

  const handleMenuToggle: AccountMenuHandlers['handleMenuToggle'] = isOpen => () => {
    localActions.setIsOpen(isOpen);
  };

  const handleOptionClick: AccountMenuHandlers['handleOptionClick'] = ([listOption]) => {
    const option = listOption as AccountMenuOption;

    if (option.onClick) {
      option.onClick();
    }

    if (option.route) {
      navigate(option.route);
    }

    localActions.setIsOpen(false);
  };

  const handleLogOut: AccountMenuHandlers['handleLogOut'] = async () => {
    try {
      await authReduxActions.signOut();

      window.location.href = `${window.location.origin}${routes.login}`;
    } catch (e) {
      errorUtils.logError(e as Error);
    }
  };

  return {
    handleMenuToggle,
    handleOptionClick,
    handleLogOut
  };
};
