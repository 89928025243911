import { memo } from 'react';
import { Handle } from 'reactflow';
import PropTypes from 'prop-types';
import {
  NODE_LINK_POINT_POSITIONS,
  NODE_LINK_POINT_TYPES
} from '../../../../WorkflowDiagramTool.constants';
import './_task-node-link-point.scss';

export const TaskNodeLinkPoint = memo(({ id, type, position, onConnect }) => {
  return (
    <Handle
      id={id}
      type={type}
      position={position}
      className="task-node-link-point"
      onConnect={onConnect}
    />
  );
});

TaskNodeLinkPoint.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(Object.values(NODE_LINK_POINT_TYPES)).isRequired,
  position: PropTypes.oneOf(Object.values(NODE_LINK_POINT_POSITIONS)).isRequired,
  onConnect: PropTypes.func
};
