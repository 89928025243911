import type { FC } from 'react';
import type { ReadableQueryProps } from './ReadableQuery.types';

import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import { PlainTextModal } from './elements';
import { useReadableQueryData, useReadableQueryHandlers } from './hooks';
import './_readable-query.scss';

export const ReadableQuery: FC<ReadableQueryProps> = ({ tree, config }) => {
  const { localState, localActions, formattedData } = useReadableQueryData({ tree, config });

  const handlers = useReadableQueryHandlers({
    localActions
  });

  return (
    <div className="readable-query__container">
      <div className="readable-query__view-as-text-container">
        Filter:
        <Button
          type={BUTTON_TYPES.LINK}
          onClick={handlers.handlePlainTextModalToggle}
          className="readable-query__button"
        >
          View as text
        </Button>
        {localState.isPlainTextModalOpen && (
          <PlainTextModal
            tree={tree}
            config={config}
            onClose={handlers.handlePlainTextModalToggle}
          />
        )}
      </div>
      <div className="readable-query__query">{formattedData.queryForDisplay}</div>
    </div>
  );
};
