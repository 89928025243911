import type { AddLabelsProps } from '../AddLabels.types';
import type { AddLabelsData } from './useAddLabelsData.types';
import type { GlobalState } from 'shared/reducers';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePermissions } from 'utils/hooks';

import * as permissionConstants from 'constants/Permissions';
import * as authUtils from 'utils/authorization';
import * as authSelectors from 'shared/features/auth/auth.selectors';
import * as constants from '../AddLabels.constants';

export const useAddLabelsData = ({
  documentIds
}: Pick<AddLabelsProps, 'documentIds'>): AddLabelsData => {
  const permissions = usePermissions();

  const [modalState, setModalState] = useState<AddLabelsData['localState']['modalState']>({
    isOpen: false,
    type: constants.MODAL_TYPE.LABEL_ADDITION
  });
  const [newLabelName, setNewLabelName] = useState<AddLabelsData['localState']['newLabelName']>('');

  const localState: AddLabelsData['localState'] = {
    modalState,
    newLabelName
  };

  const localActions: AddLabelsData['localActions'] = useMemo(
    () => ({
      setModalState,
      setNewLabelName
    }),
    []
  );

  const reduxState = useSelector<GlobalState, AddLabelsData['reduxState']>(state => ({
    isAuthenticated: authSelectors.getUserAuthenticatedState(state)
  }));

  const formattedData: AddLabelsData['formattedData'] = {
    isDisabled: !documentIds?.length,
    isRestricted:
      !reduxState.isAuthenticated ||
      authUtils.isLiteTeamUser() ||
      !permissions[permissionConstants.CREATE_LABELS]
  };

  return {
    localState,
    localActions,
    reduxState,
    formattedData
  };
};
