import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserEmail } from 'shared/features/user/user.selectors';

export const usePremiumContentUnlockButtonData = () => {
  const [didTryToAuth, setDidTryToAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const reduxState = useSelector(state => ({
    currentUserEmail: getUserEmail(state)
  }));

  return {
    localState: {
      didTryToAuth,
      isLoading,
      isOpen
    },
    localActions: {
      setDidTryToAuth,
      setIsLoading,
      setIsOpen
    },
    reduxState
  };
};
