import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TOOLTIP_POSITION, Tooltip } from 'common';

export const TEST_IDS = {
  CONTAINER: 'toggle-button-test-id'
};

export const CLASSNAMES = {
  LAST_ELEMENT: 'last-element',
  FIRST_ELEMENT: 'first-element'
};

const ToggleButton = ({
  label,
  value,
  hovertext,
  isActive,
  onClick,
  isFirstElement,
  isLastElement
}) => {
  return (
    <Tooltip title={hovertext} placement={TOOLTIP_POSITION.BOTTOM}>
      <div
        key={value}
        className={classNames({
          active: isActive,
          'toggle-buttons-element': true,
          [CLASSNAMES.FIRST_ELEMENT]: isFirstElement,
          [CLASSNAMES.LAST_ELEMENT]: isLastElement
        })}
        onClick={onClick}
        data-testid={TEST_IDS.CONTAINER}
      >
        {label}
      </div>
    </Tooltip>
  );
};

export default ToggleButton;

ToggleButton.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  hovertext: PropTypes.string,
  isFirstElement: PropTypes.bool.isRequired,
  isLastElement: PropTypes.bool.isRequired
};

ToggleButton.defaultProps = {
  hoverText: null
};
