import type { WorkflowOption } from 'shared/features/workflow/workflow.types';

export const WORKFLOWS_PER_REQUEST_COUNT = 1000;
export const WORKFLOW_OPTIONS_SORT_ORDER = 'asc';
export const DELETED_WORKFLOW_PREFIX = '(Deleted)';

export const ALL_WORKFLOW_OPTION: WorkflowOption = {
  value: null,
  label: 'All Workflows',
  types: []
};

export enum WORKFLOW_GROUP_LABELS {
  DOCUMENT_OBLIGATION_WORKFLOW = 'Document and Obligation-level workflows',
  DOCUMENT_WORKFLOW = 'Document-level workflows',
  OBLIGATION_WORKFLOW = 'Obligation-level workflows'
}
