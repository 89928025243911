import lodash from 'lodash';
import PropTypes from 'prop-types';
import { Select, SELECT_TYPES } from '@compliance.ai/web-components';

export const USER_OPTIONS_SORT_ORDER = 'asc';

export const mapUserOptions = users => {
  const userOptions = users.flatMap(({ id, email, active }) =>
    active
      ? {
          value: id,
          label: email
        }
      : []
  );

  return lodash.orderBy(
    userOptions,
    [userOption => userOption.label.toLowerCase()],
    [USER_OPTIONS_SORT_ORDER]
  );
};

export const UserSelect = ({
  users,
  onChange,
  selectedUser,
  placeholder,
  isFetching,
  isClearable,
  isMulti
}) => {
  const userOptions = mapUserOptions(users);

  return (
    <Select
      type={SELECT_TYPES.DEFAULT}
      value={selectedUser}
      isClearable={isClearable}
      onChange={onChange}
      isSearchable
      placeholder={placeholder}
      options={userOptions}
      noOptionsMessage={() => (isFetching ? 'Loading...' : 'No users')}
      isMulti={isMulti}
    />
  );
};

UserSelect.propTypes = {
  styles: PropTypes.shape({}),
  selectedUser: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any
    }),
    PropTypes.string
  ]),
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  isFetching: PropTypes.bool,
  isMulti: PropTypes.bool,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired
};

UserSelect.defaultProps = {
  placeholder: 'Select user',
  isFetching: false,
  selectedUser: undefined,
  isClearable: false,
  isMulti: false
};
