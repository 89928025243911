import { RELATIVE_DATE_UNITS_OF_TIME } from '@compliance.ai/web-components';

export const SINGLE_RELATIVE_DATES = {
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  TOMORROW: 'Tomorrow'
};

export const PAST_NEXT_RELATIVE_DURATIONS = {
  DAYS: 'Days',
  WEEKS: 'Weeks',
  MONTHS: 'Months',
  YEARS: 'Years'
};

export const RELATIVE_DATE_UNITS_OF_TIME_TO_QUERY_PARAMS = {
  [RELATIVE_DATE_UNITS_OF_TIME.DAY]: 'Days',
  [RELATIVE_DATE_UNITS_OF_TIME.WEEK]: 'Weeks',
  [RELATIVE_DATE_UNITS_OF_TIME.MONTH]: 'Months',
  [RELATIVE_DATE_UNITS_OF_TIME.YEAR]: 'Years'
};

export const REATIVE_DATE_OPERATOR_LABELS = {
  ON: 'On',
  BEFORE: 'Before',
  AFTER: 'After',
  BETWEEN: 'Between',
  ON_OR_BEFORE: 'On or Before',
  ON_OR_AFTER: 'On or After',
  PAST: 'In the last',
  NEXT: 'In the next'
};

export const REATIVE_DATE_OPERATORS = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  TOMORROW: 'tomorrow',
  PAST: 'past',
  NEXT: 'next',
  PAST_NEXT_SEPERATOR: ':'
};

export const RELATIVE_DATE_COMPARATOR = {
  [REATIVE_DATE_OPERATORS.TODAY]: REATIVE_DATE_OPERATORS.TODAY,
  [REATIVE_DATE_OPERATORS.YESTERDAY]: REATIVE_DATE_OPERATORS.YESTERDAY,
  [REATIVE_DATE_OPERATORS.TOMORROW]: REATIVE_DATE_OPERATORS.TOMORROW,
  [REATIVE_DATE_OPERATORS.PAST]: REATIVE_DATE_OPERATORS.PAST,
  [REATIVE_DATE_OPERATORS.NEXT]: REATIVE_DATE_OPERATORS.NEXT
};
