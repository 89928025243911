import * as primaryFilterConstants from 'constants/PrimaryFilter';
import * as constants from 'shared/features/filters/filters.constants';

export const DOC_PROPERTIES_TO_QUERY_PARAMS = {
  [constants.MY_TASKS]: {
    param: primaryFilterConstants.SUPPORTED_QUERY_PARAMS.TASK_OWNER,
    value: 'current_user'
  },
  [constants.MY_ORG_TASKS]: {
    param: primaryFilterConstants.SUPPORTED_QUERY_PARAMS.TASK_OWNER,
    value: 'organization'
  },
  [constants.HAS_SENTENCES]: {
    param: primaryFilterConstants.SUPPORTED_QUERY_PARAMS.HAS_SENTENCES,
    value: true
  },
  [constants.HAS_ANNOTATIONS]: {
    param: primaryFilterConstants.SUPPORTED_QUERY_PARAMS.HAS_ANNOTATIONS,
    value: true
  },
  [constants.OBLIGATIONS]: {
    param: primaryFilterConstants.SUPPORTED_QUERY_PARAMS.HAS_OBLIGATIONS,
    value: true
  },
  [constants.IS_PREMIUM_CONTENT]: {
    param: primaryFilterConstants.SUPPORTED_QUERY_PARAMS.IS_PREMIUM_CONTENT,
    value: true
  },
  [constants.IS_ORGANIZATION_DOCUMENT]: {
    param: primaryFilterConstants.SUPPORTED_QUERY_PARAMS.IS_ORGANIZATION_DOCUMENT,
    value: true
  }
};

export const EXPECTED_NON_EMPTY_FILTER_KEYS = [
  primaryFilterConstants.FILTER_KEY.HAS_UNOFFICIAL_PUB_DATE,
  primaryFilterConstants.FILTER_KEY.HAS_EITL_LABELS,
  primaryFilterConstants.FILTER_KEY.EXCLUDE_FEDERAL_REGISTER
];

export const SAVED_USER_FILTERS_TO_QUERY_PARAMS = {
  [primaryFilterConstants.FILTER_KEY.PUB_DATE]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.PUBLICATION_DATE,
  [primaryFilterConstants.FILTER_KEY.HAS_UNOFFICIAL_PUB_DATE]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.HAS_UNOFFICIAL_PUBLICATION_DATE,
  [primaryFilterConstants.FILTER_KEY.PUB_START_DATE]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.PUBLISHED_FROM,
  [primaryFilterConstants.FILTER_KEY.PUB_END_DATE]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.PUBLISHED_TO,
  [primaryFilterConstants.FILTER_KEY.AGENCIES]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.AGENCY_ID,
  [primaryFilterConstants.FILTER_KEY.AUTHOR]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.AUTHOR_ID,
  [primaryFilterConstants.FILTER_KEY.JURISDICTIONS]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.JURISDICTION,
  [primaryFilterConstants.FILTER_KEY.MAINSTREAM_NEWS_SOURCES]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.NEWS_SOURCE_ID,
  [primaryFilterConstants.FILTER_KEY.TOPICS]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.TOPIC_ID,
  [primaryFilterConstants.FILTER_KEY.DOC_PROPERTIES]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.DOC_PROPERTIES,
  [primaryFilterConstants.FILTER_KEY.DOC_TYPES]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.CATEGORY_ID,
  [primaryFilterConstants.FILTER_KEY.REGULATIONS]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.REGULATION_ID,
  [primaryFilterConstants.FILTER_KEY.ACTS]: primaryFilterConstants.SUPPORTED_QUERY_PARAMS.ACT_ID,
  [primaryFilterConstants.FILTER_KEY.BANKS]: primaryFilterConstants.SUPPORTED_QUERY_PARAMS.BANK_ID,
  [primaryFilterConstants.FILTER_KEY.LABELS]: primaryFilterConstants.SUPPORTED_QUERY_PARAMS.LABEL,
  [primaryFilterConstants.FILTER_KEY.LANGUAGE]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.LANGUAGE_ID,
  [primaryFilterConstants.FILTER_KEY.CITATION]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.CITATION,
  [primaryFilterConstants.FILTER_KEY.TITLE]: primaryFilterConstants.SUPPORTED_QUERY_PARAMS.TITLE,
  [primaryFilterConstants.FILTER_KEY.TEXT]: primaryFilterConstants.SUPPORTED_QUERY_PARAMS.TEXT,
  [primaryFilterConstants.FILTER_KEY.ADDED_DATE]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.ADDED_DATE,
  [primaryFilterConstants.FILTER_KEY.ADDED_START_DATE]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.ADDED_FROM,
  [primaryFilterConstants.FILTER_KEY.ADDED_END_DATE]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.ADDED_TO,
  [primaryFilterConstants.FILTER_KEY.UPDATED_DATE]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.UPDATED_DATE,
  [primaryFilterConstants.FILTER_KEY.UPDATED_START_DATE]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.UPDATED_FROM,
  [primaryFilterConstants.FILTER_KEY.UPDATED_END_DATE]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.UPDATED_TO,
  [primaryFilterConstants.FILTER_KEY.EFFECTIVE_DATE]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.EFFECTIVE_DATE,
  [primaryFilterConstants.FILTER_KEY.EFFECTIVE_START_DATE]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.EFFECTIVE_FROM,
  [primaryFilterConstants.FILTER_KEY.EFFECTIVE_END_DATE]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.EFFECTIVE_TO,
  [primaryFilterConstants.FILTER_KEY.COMMENTS_CLOSE_DATE]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.COMMENTS_CLOSE_DATE,
  [primaryFilterConstants.FILTER_KEY.COMMENTS_CLOSE_START_DATE]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.COMMENTS_CLOSE_FROM,
  [primaryFilterConstants.FILTER_KEY.COMMENTS_CLOSE_END_DATE]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.COMMENTS_CLOSE_TO,
  [primaryFilterConstants.FILTER_KEY.DOCUMENT_ID]:
    primaryFilterConstants.SUPPORTED_QUERY_PARAMS.DOC_ID
};

export const EMPTY_DEFAULT_FILTER_PARAMS = {
  [primaryFilterConstants.FILTER_KEY.AGENCIES]: [],
  [primaryFilterConstants.FILTER_KEY.MAINSTREAM_NEWS_SOURCES]: [],
  [primaryFilterConstants.FILTER_KEY.TOPICS]: [],
  [primaryFilterConstants.FILTER_KEY.DOC_TYPES]: [],
  [primaryFilterConstants.FILTER_KEY.CONCEPTS]: []
};
