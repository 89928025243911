import type { FC } from 'react';
import type { AutoSummaryTabProps } from './AutoSummaryTab.types';

import * as uiLib from '@compliance.ai/web-components';
import * as config from 'shared/config';

import { v4 as uuidv4 } from 'uuid';
import {
  useAutoSummaryData,
  useAutoSummaryHandlers,
  useAutoSummaryLifecycles,
  useAutoSummaryTabs
} from './hooks';

import './_auto-summary-tab.scss';

export const AutoSummaryTab: FC<AutoSummaryTabProps> = props => {
  const { formattedData } = useAutoSummaryData({ props });

  const handlers = useAutoSummaryHandlers({ props });

  useAutoSummaryLifecycles({ onAutoSummaryActionLog: handlers.handleAutoSummaryActionLog });

  const tabs = useAutoSummaryTabs({ formattedData });

  if (!config.shouldDisplayExtendedAutoSummary) {
    return (
      <div className="autosummary-container">
        {props.document?.summaries?.[0].summary_sentences.map(summary_sentence => (
          <p key={uuidv4()}>{summary_sentence}</p>
        ))}
      </div>
    );
  }

  return (
    <uiLib.Tabs
      tabs={tabs}
      classNames={{
        container: 'auto-summary-tab__container',
        indicator: 'auto-summary-tab__indicator',
        tab: 'auto-summary-tab__tab',
        tabContent: 'auto-summary-tab__content',
        tabsBar: 'auto-summary-tab__bar',
        tabTitle: 'auto-summary-tab__title'
      }}
    />
  );
};
