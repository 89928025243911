import { RelatedDocumentsPanel } from './elements';

export const RelatedDocumentsTab = ({ document }) => {
  return (
    <RelatedDocumentsPanel
      originalDocID={document.id}
      originalDocTitle={document.title}
      relatedDocuments={document.related_documents}
    />
  );
};
