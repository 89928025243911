import { fetch } from 'utils/api';

export function api_fetchTags() {
  return fetch({
    url: `/tags`,
    dataType: 'Tags',
    method: 'GET'
  });
}

export function api_createTag(name) {
  return fetch({
    url: `/tags`,
    dataType: 'Tags',
    method: 'POST',
    params: {
      name
    }
  });
}
