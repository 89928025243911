import { useCallback } from 'react';
import { TABLE_PARAMS } from '@compliance.ai/web-components';
import { getSortField, getSortOrder } from 'utils/sort';
import { getPage } from 'utils/pagination-utils';
import {
  API_KEYS,
  SELECTED_LABEL_DETAILS_DEFAULT_PAGE,
  SELECTED_LABEL_DETAILS_MAX_COUNT
} from '../LabelDetails.constants';
import { FILTER_KEY } from '../elements/LabelDetailsFilters';
import { logError } from 'utils/errors';
import {
  normalizeDocumentsMoveRequest,
  formatLabelForTable
} from './useLabelDetailsHandlers.helpers';

export const useLabelDetailsHandlers = ({
  props,
  localState,
  localActions,
  reduxState,
  modalActions,
  organizationLabelsReduxActions,
  rightPanelReduxActions
}) => {
  const handleLabelFetch = useCallback(async () => {
    try {
      localActions.setLoadingState(true);

      const { name, isEditable } = await organizationLabelsReduxActions.fetchLabel(props.labelId);

      localActions.setLabelName(name);
      localActions.setIsLabelEditable(isEditable);
    } finally {
      localActions.setLoadingState(false);
    }
  }, [localActions, organizationLabelsReduxActions, props.labelId]);

  const handleDataFetch = useCallback(
    async params => {
      try {
        const limit = params[TABLE_PARAMS.LIMIT];
        const offset = params[TABLE_PARAMS.OFFSET];
        const sort = params[TABLE_PARAMS.SORT];

        const page = getPage({ limit, offset });
        const sortField = getSortField(sort);
        const sortOrder = getSortOrder(sort);

        const { labels, totalCount } = await organizationLabelsReduxActions.fetchLabelDetails(
          props.labelId,
          {
            [API_KEYS.SORT_FIELD]: sortField,
            [API_KEYS.SORT_ORDER]: sortOrder,
            [API_KEYS.PAGE]: page,
            [API_KEYS.COUNT_PER_PAGE]: limit,
            [API_KEYS.DOCUMENT_TITLE]: localState.filters[FILTER_KEY.DOC_TITLE],
            [API_KEYS.ADDED_BY]: localState.filters[FILTER_KEY.ADDED_BY],
            [API_KEYS.LABEL_PARENT]: localState.filters[FILTER_KEY.ADDED_TO],
            [API_KEYS.ADDED_FROM]: localState.filters[FILTER_KEY.ADDED_START_DATE],
            [API_KEYS.ADDED_TO]: localState.filters[FILTER_KEY.ADDED_END_DATE]
          }
        );

        modalActions.setDocumentsIds(labels.map(({ documentId }) => documentId));

        return {
          results: labels.map(
            formatLabelForTable({
              onAddedByClick: props.handleAuthorsModalShow
            })
          ),
          count: totalCount
        };
      } catch (e) {
        logError(e);
        return {
          results: [],
          count: 0
        };
      }
    },
    [
      organizationLabelsReduxActions,
      props.labelId,
      props.handleAuthorsModalShow,
      localState.filters,
      modalActions
    ]
  );

  const handleDocumentsMoveConfirm = useCallback(async () => {
    try {
      modalActions.setLabelsMoveModalVisibility(false);
      localActions.setLoadingState(true);

      await organizationLabelsReduxActions.modifyLabel(localState.label.value, {
        label_documents: normalizeDocumentsMoveRequest(
          props.labelId,
          localState.selectedDocumentsIds
        )
      });

      modalActions.setSelectedDocumentsIds([]);
    } catch (e) {
      logError(e);
      modalActions.setLabelsMoveModalVisibility(false);
      modalActions.setSelectedDocumentsIds([]);
    } finally {
      localActions.setLoadingState(false);
      localActions.setRefreshTrigger(refreshTrigger => !refreshTrigger);

      rightPanelReduxActions.triggerRightPanelLabelsRefresh();
    }
  }, [
    modalActions,
    localActions,
    organizationLabelsReduxActions,
    localState.label.value,
    localState.selectedDocumentsIds,
    props.labelId,
    rightPanelReduxActions
  ]);

  const handleDocumentsRemoveConfirm = useCallback(async () => {
    try {
      modalActions.setLabelsRemoveModalVisibility(false);
      localActions.setLoadingState(true);

      await organizationLabelsReduxActions.removeLabelsFromDocuments(props.labelId, {
        documents_ids: localState.selectedDocumentsIds
      });

      modalActions.setSelectedDocumentsIds([]);
    } catch (e) {
      logError(e);
      modalActions.setLabelsRemoveModalVisibility(false);
      modalActions.setSelectedDocumentsIds([]);
    } finally {
      localActions.setLoadingState(false);
      localActions.setRefreshTrigger(refreshTrigger => !refreshTrigger);

      rightPanelReduxActions.triggerRightPanelLabelsRefresh();
    }
  }, [
    modalActions,
    localActions,
    organizationLabelsReduxActions,
    props.labelId,
    localState.selectedDocumentsIds,
    rightPanelReduxActions
  ]);

  const handleFilterSave = useCallback(
    filters => {
      localActions.setFilters(filters);
      localActions.setRefreshTrigger(refreshTrigger => !refreshTrigger);
    },
    [localActions]
  );

  const handleSelectAllClick = useCallback(async () => {
    try {
      const { labels } = await organizationLabelsReduxActions.fetchLabelDetails(props.labelId, {
        [API_KEYS.PAGE]: SELECTED_LABEL_DETAILS_DEFAULT_PAGE,
        [API_KEYS.COUNT_PER_PAGE]: SELECTED_LABEL_DETAILS_MAX_COUNT,
        [API_KEYS.DOCUMENT_TITLE]: localState.filters[FILTER_KEY.DOC_TITLE],
        [API_KEYS.ADDED_BY]: localState.filters[FILTER_KEY.ADDED_BY],
        [API_KEYS.LABEL_PARENT]: localState.filters[FILTER_KEY.ADDED_TO],
        [API_KEYS.ADDED_FROM]: localState.filters[FILTER_KEY.ADDED_START_DATE],
        [API_KEYS.ADDED_TO]: localState.filters[FILTER_KEY.ADDED_END_DATE]
      });

      return labels.map(({ documentId }) => documentId);
    } catch (e) {
      logError(e);

      return [];
    }
  }, [props, localState, organizationLabelsReduxActions]);

  const handleTriggerChange = useCallback(() => {
    localActions.setRefreshTrigger(reduxState.labelsDetailsRefreshTrigger);
  }, [localActions, reduxState.labelsDetailsRefreshTrigger]);

  const handleSelectedDocumentsIdsChange = selectedDocumentsIds => {
    modalActions.setSelectedDocumentsIds(selectedDocumentsIds);
  };

  return {
    handleLabelFetch,
    handleDataFetch,
    handleFilterSave,
    handleDocumentsMoveConfirm,
    handleDocumentsRemoveConfirm,
    handleSelectAllClick,
    handleTriggerChange,
    handleSelectedDocumentsIdsChange
  };
};
