import lodash from 'lodash';
import {
  RIGHT_PANEL_QUERY_PARAMS,
  OVERLAY_TYPES,
  OVERLAY_QUERY_PARAMS,
  RIGHT_PANEL_TYPES,
  DOCUMENT_DETAILS_TAB_KEY
} from 'shared/features/rightPanel/rightPanel.constants';
import queryString from 'utils/query-string';
import * as routes from 'constants/Routes';
import {
  RELATED_DOCUMENT_RELATION_TYPES,
  MAX_RELATED_DOCUMENT_ALERTS_COUNT
} from 'constants/RelatedDocuments';
import { SKU_RESTRICTION_TYPES } from 'constants/SKUs';
import { DOCUMENT_SUMMARY_PAGE_KEY } from 'constants/DocumentConstants';
import { DOCUMENT_CONTEXT_MENU_TEXTS } from 'constants/DocumentContextMenu';
import { DEFAULT_RELATION_TYPES_EXPANDED } from '../RelatedDocumentsPanel.constants';

import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';
import { useSKUs } from 'utils/hooks';

export const useRelatedDocumentsPanelHandlers = ({
  props,
  localState,
  localActions,
  reduxState,
  queries,
  mutations
}) => {
  const { userHasSKU, showSKURestrictModal } = useSKUs();

  const rightPanelReduxActions = useRightPanelReduxActions();

  const navigate = useNavigate();

  const handleDocumentOptionClick = relationType => options => {
    if (lodash.isEmpty(options) || lodash.isEmpty(options[0]) || !options[0].value) {
      return;
    }

    if (relationType === RELATED_DOCUMENT_RELATION_TYPES.TRANSLATION) {
      if (!userHasSKU(SKU_RESTRICTION_TYPES.AUTOTRANSLATE)) {
        return showSKURestrictModal(SKU_RESTRICTION_TYPES.AUTOTRANSLATE);
      }
    } else if (relationType === RELATED_DOCUMENT_RELATION_TYPES.ORGANIZATION_ALERT) {
      const alertUrl = options[0]?.url;
      return alertUrl ? window.open(alertUrl) : null;
    }

    const docId = options[0].value;

    rightPanelReduxActions.openRightPanel({
      type: RIGHT_PANEL_TYPES.DOCUMENT_DETAILS,
      props: {
        documentId: docId,
        shouldOpenRightPanelWithOverlay: true
      }
    });
  };

  const handleAddModalOpen = useCallback(
    relationType => () => {
      if (relationType === RELATED_DOCUMENT_RELATION_TYPES.ORGANIZATION) {
        localActions.setAddModal({ isOpen: true, relationType });
      } else if (relationType === RELATED_DOCUMENT_RELATION_TYPES.ORGANIZATION_ALERT) {
        const isOpen =
          MAX_RELATED_DOCUMENT_ALERTS_COUNT >
          queries.fetchRelatedAlerts.data?.[relationType]?.selectedIds?.length;
        localActions.setAddModal({ isOpen, relationType });
      }
    },
    [localActions, queries.fetchRelatedAlerts.data]
  );

  const handleAddModalClose = useCallback(() => {
    localActions.setAddModal({ isOpen: false, relationType: undefined });
  }, [localActions]);

  const handleDeleteModalOpen = useCallback(
    relationType => () => {
      if (relationType === RELATED_DOCUMENT_RELATION_TYPES.ORGANIZATION) {
        localActions.setDeleteModal({ isOpen: true, relationType });
      }
    },
    [localActions]
  );

  const handleDeleteModalClose = useCallback(() => {
    localActions.setDeleteModal({ isOpen: false, relationType: undefined });
  }, [localActions]);

  const handleDocumentOptionDelete = useCallback(
    relationType => async option => {
      if (lodash.isEmpty(option) || !option.value) {
        return;
      }

      return {
        [RELATED_DOCUMENT_RELATION_TYPES.ORGANIZATION]: mutations.deleteRelatedDocument,
        [RELATED_DOCUMENT_RELATION_TYPES.ORGANIZATION_ALERT]: mutations.deleteRelatedAlert
      }[relationType].mutate(option.value);
    },
    [mutations.deleteRelatedAlert, mutations.deleteRelatedDocument]
  );

  const handleShowMoreClick = useCallback(
    relationType => () => {
      if (relationType === RELATED_DOCUMENT_RELATION_TYPES.ORGANIZATION) {
        return handleDeleteModalOpen(relationType)();
      }

      navigate(
        `${routes.content}?${queryString.stringify({
          related_doc_id: props.originalDocID,
          search_sort: 'publication_date',
          order: 'desc'
        })}`
      );

      rightPanelReduxActions.openRightPanel({
        type: RIGHT_PANEL_TYPES.DOCUMENT_DETAILS,
        props: {
          documentId: props.originalDocID,
          activeTabKey: DOCUMENT_DETAILS_TAB_KEY.RELATED_DOCUMENTS
        }
      });
    },
    [navigate, props.originalDocID, rightPanelReduxActions, handleDeleteModalOpen]
  );

  const handleExpandButtonClick = useCallback(
    relationType => isOpen => {
      localActions.setExpanded({
        ...localState.expanded,
        [relationType]: isOpen
      });
    },
    [localState, localActions]
  );

  const handleDefaultExpandedTypeChange = useCallback(() => {
    if (reduxState.expandedRelatedDocsType) {
      localActions.setExpanded({
        ...DEFAULT_RELATION_TYPES_EXPANDED,
        [reduxState.expandedRelatedDocsType]: true
      });
    }
  }, [localActions, reduxState.expandedRelatedDocsType]);

  const handleGetContextMenuOptions = useCallback(option => {
    if (!option.value) {
      return;
    }

    const stringifiedQueryParams = queryString.stringify({
      [RIGHT_PANEL_QUERY_PARAMS.CLOSE_RIGHT_PANEL_ON_OVERLAY_CLOSE]: true,
      [RIGHT_PANEL_QUERY_PARAMS.SUMMARY_PAGE]: DOCUMENT_SUMMARY_PAGE_KEY,
      [RIGHT_PANEL_QUERY_PARAMS.SUMMARY_ID]: option.value,
      [OVERLAY_QUERY_PARAMS.OVERLAY_TYPE]: OVERLAY_TYPES.PDF
    });

    const url = `${routes.dashboard}?${stringifiedQueryParams}`;

    return Object.values(DOCUMENT_CONTEXT_MENU_TEXTS).map(contextMenuText => ({
      label: contextMenuText,
      onClick: () => {
        if (contextMenuText === DOCUMENT_CONTEXT_MENU_TEXTS.OPEN_DOCUMENT_NEW_WINDOW) {
          window.open(url, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,fullscreen=yes');
        } else {
          window.open(url);
        }
      }
    }));
  }, []);

  return {
    handleDefaultExpandedTypeChange,
    handleDocumentOptionClick,
    handleAddModalOpen,
    handleAddModalClose,
    handleDeleteModalOpen,
    handleDeleteModalClose,
    handleDocumentOptionDelete,
    handleShowMoreClick,
    handleExpandButtonClick,
    handleGetContextMenuOptions
  };
};
