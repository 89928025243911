import type { WorkflowTaskDeadlineProps } from '../WorkflowTaskDeadline.types';
import type { WorkflowTaskDeadlineData } from './useWorkflowTaskDeadlineData.types';

import { useMemo, useState } from 'react';

export const useWorkflowTaskDeadlineData = ({
  deadline
}: Pick<WorkflowTaskDeadlineProps, 'deadline'>): WorkflowTaskDeadlineData => {
  const [selectedDeadline, setSelectedDeadline] = useState(deadline);

  const localState: WorkflowTaskDeadlineData['localState'] = {
    selectedDeadline
  };

  const localActions: WorkflowTaskDeadlineData['localActions'] = useMemo(
    () => ({
      setSelectedDeadline
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
