import { useRef } from 'react';

export const useCognitoWindowedSignInFormData = () => {
  const formWindow = useRef(null);
  const windowMessagesListener = useRef(null);

  return {
    refs: {
      formWindow,
      windowMessagesListener
    }
  };
};
