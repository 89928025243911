import type { FC } from 'react';
import type { DefaultFiltersViewSelectProps } from './DefaultFiltersViewSelect.types';

import * as uiLib from '@compliance.ai/web-components';

import { useDefaultFiltersViewSelectHandlers, useDefaultFiltersViewSelectData } from './hooks';

export const DefaultFiltersViewSelect: FC<DefaultFiltersViewSelectProps> = props => {
  const { formattedData } = useDefaultFiltersViewSelectData({ props });

  const handlers = useDefaultFiltersViewSelectHandlers({ props });

  return (
    <div>
      <uiLib.Select
        type={uiLib.SELECT_TYPES.ALTERNATIVE}
        value={formattedData.selectedView}
        onChange={handlers.handleSelectChange}
        options={formattedData.options}
        hideSelectedOptions={false}
        withCheckmarks
      />
    </div>
  );
};
