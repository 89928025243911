import PropTypes from 'prop-types';
import { restrict } from 'components/Restrict';
import { Loader, LOADER_LEGACY_TYPES } from 'common';
import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import { getExportLimit } from 'utils/exportLimit/exportLimit';
import { useViewReduxActions } from 'shared/features/view/hooks';
import { useDocumentsBulkExportData, useDocumentsBulkExportHandlers } from './hooks';
import './documents-bulk-export.scss';

export const DocumentsBulkExport = ({ selectedDocsIds, selectedOverDailyLimit, onExport }) => {
  const { reduxState, formattedData } = useDocumentsBulkExportData({
    selectedDocsIds,
    selectedOverDailyLimit
  });

  const viewReduxActions = useViewReduxActions();

  const handlers = useDocumentsBulkExportHandlers({
    props: {
      onExport
    },
    reduxState,
    formattedData,
    viewReduxActions
  });

  if (!formattedData.shouldRenderBulkExport) {
    return null;
  }

  if (reduxState.isFetchingSimpleDocs) {
    return (
      <div className="documents-bulk-export__container">
        <Loader
          legacy
          legacyType={LOADER_LEGACY_TYPES.LIGHT}
          spinnerClassName="documents-bulk-export__loader"
        />
      </div>
    );
  }

  return (
    <div className="documents-bulk-export__container">
      {formattedData.shouldFetchBulkDocs ? (
        <Button type={BUTTON_TYPES.LINK} isBold onClick={handlers.handleSelectionClear}>
          Clear selection.
        </Button>
      ) : (
        <Button
          type={BUTTON_TYPES.LINK}
          isBold
          onClick={restrict(handlers.handlePrepareBulkExport)}
        >
          {'Select all (up to ' + getExportLimit() + ').'}
        </Button>
      )}
    </div>
  );
};

DocumentsBulkExport.propTypes = {
  selectedDocsIds: PropTypes.number.isRequired,
  selectedOverDailyLimit: PropTypes.bool.isRequired,
  onExport: PropTypes.func.isRequired
};
