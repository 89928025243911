import type { BucketSelectProps } from '../BucketSelect.types';
import type { BucketSelectData } from './useBucketSelectData.types';
import type { BucketOption } from 'shared/features/buckets/buckets.types';
import type { NormalizedBucketsResponse } from 'shared/features/buckets/buckets.actions.types';

import * as queryHelpers from './useBucketSelectQueries.helpers';
import * as constants from '../BucketSelect.constants';
import * as errorsUtils from 'utils/errors';

import { COMPLEX_QUERY_KEY_GETTERS, QUERY_KEYS, QUERY_SECTIONS } from 'constants/QueryKeys';

import { useQuery } from '@tanstack/react-query';
import { useBucketsReduxActions } from 'shared/features/buckets/hooks';

export const useBucketSelectQueries = ({
  props,
  localState,
  reduxState
}: {
  props: Pick<BucketSelectProps, 'exclusiveBucketId'>;
  localState: BucketSelectData['localState'];
  reduxState: BucketSelectData['reduxState'];
}) => {
  const reduxActions = useBucketsReduxActions();

  const fetchBucketsOptionsQuery = useQuery<
    Awaited<NormalizedBucketsResponse>,
    Error,
    BucketOption[]
  >({
    queryKey: COMPLEX_QUERY_KEY_GETTERS[QUERY_KEYS.BUCKETS][QUERY_SECTIONS.LABEL_INPUT_GET_BUCKETS](
      localState.bucketNameInput
    ),
    queryFn: () =>
      reduxActions.getBuckets({
        name: localState.bucketNameInput,
        limit: constants.BUCKETS_PER_REQUEST_COUNT,
        offset: 0,
        is_editable: true
      }),
    select: data =>
      queryHelpers.formatBucketsOptions({
        buckets: data.buckets,
        searchValue: localState.bucketNameInput,
        exclusiveBucketId: props.exclusiveBucketId,
        isPublicFolderDisabled: reduxState.isPublicFolderDisabled
      }),
    onError: errorsUtils.logReactQueryError,
    refetchOnWindowFocus: false
  });

  return {
    fetchBucketsOptionsQuery
  };
};
