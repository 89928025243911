import { useEffect, useState } from 'react';
import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import PropTypes from 'prop-types';
import WorkflowMoldHeader from './ elements/WorkflowMoldHeader/WorkflowMoldHeader';
import WorkflowMoldDeleteModal from './ elements/WorkflowMoldDeleteModal/WorkflowMoldDeleteModal';
import { useWorkflowMoldReduxActions } from 'shared/features/workflowMold/hooks';
import './_workflow-mold.scss';

const renderCategories = categories => {
  if (categories.length) {
    return categories.map((category, i) => {
      return (
        <div className="workflow-mold__category" key={i}>
          {category}
        </div>
      );
    });
  }
  return <div className="workflow-mold__category">{'--'}</div>;
};

export const WorkflowMold = ({ mold, addWorkflow }) => {
  const [showDeleteModal, setShowDeleteModalStatus] = useState(false);
  const [editNameMode, setEditNameMode] = useState(false);
  const [name, setName] = useState('Template Name');
  const { doc_filter, id, properties, tasks, share_scope } = mold;

  const workflowMoldReduxActions = useWorkflowMoldReduxActions();

  useEffect(() => {
    if (properties.name) setName(properties.name);
  }, [properties.name]);

  const saveTemplateName = () => {
    const params = {
      id,
      properties: {
        name
      }
    };
    workflowMoldReduxActions.updateWorkflowMold(params);
    setEditNameMode(false);
  };

  const importButton = (
    <Button onClick={() => addWorkflow(mold)} type={BUTTON_TYPES.PRIMARY} title="Import Template">
      Import Template
    </Button>
  );

  return (
    <div className="workflow-mold" key={id}>
      <WorkflowMoldHeader
        properties={properties}
        name={name}
        setName={setName}
        id={id}
        editNameMode={editNameMode}
        share_scope={share_scope}
        setShowDeleteModalStatus={setShowDeleteModalStatus}
        setEditNameMode={setEditNameMode}
        saveTemplateName={saveTemplateName}
      />
      <div className="workflow-mold__document-type">For Document Type</div>
      {renderCategories(doc_filter.category)}
      <div className="workflow-mold__numSteps">{tasks.length || 0}</div>
      <div>{tasks && tasks.length === 1 ? 'STEP' : 'STEPS'}</div>
      <div className="workflow-mold__import-container">
        <div className="workflow-mold__import-or-delete">{importButton}</div>
      </div>
      <WorkflowMoldDeleteModal
        doc_filter={doc_filter}
        setShowDeleteModalStatus={setShowDeleteModalStatus}
        showDeleteModal={showDeleteModal}
        workflow_mold_id={id}
      />
    </div>
  );
};

WorkflowMold.propTypes = {
  mold: PropTypes.shape({}).isRequired,
  addWorkflow: PropTypes.func.isRequired
};
