import type { FC } from 'react';
import type { SentenceNoteTextProps } from './SentenceNoteText.types';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';
import * as env from 'shared/config';

import { useSentenceNoteTextData, useSentenceNoteTextHandlers } from './hooks';

import './_sentence-note-text.scss';

export const SentenceNoteText: FC<SentenceNoteTextProps> = ({
  sentencesText,
  isObligation,
  bucket,
  onClick
}) => {
  const { localState, localActions, formattedData } = useSentenceNoteTextData({
    sentencesText,
    bucket
  });

  const handlers = useSentenceNoteTextHandlers({ localActions });

  return (
    <div className="sentence-note-text__container" data-testid="sentence-note-text-container">
      <div className="sentence-note-text__content" data-testid="sentence-text" onClick={onClick}>
        <uiLib.Tooltip
          classNames={{
            tooltip: 'sentence-note-text__content-tooltip'
          }}
          type={uiLib.TOOLTIP_TYPES.SECONDARY}
          isDisabled={isObligation && !env.shouldShowBucketObligations}
          title={<elements.SentenceNoteTooltipTitle bucketName={formattedData.bucketName} />}
        >
          {formattedData.sentenceNoteText}
        </uiLib.Tooltip>
      </div>
      {formattedData.shouldShowMoreText &&
        (localState.isExpanded ? (
          <div
            className="sentence-note-text__read-more"
            onClick={handlers.handleExpandToggle(false)}
          >
            Read Less
          </div>
        ) : (
          <div
            className="sentence-note-text__read-more"
            onClick={handlers.handleExpandToggle(true)}
          >
            Read More
          </div>
        ))}
    </div>
  );
};
