import type { GlobalState } from 'shared/reducers';
import type { DocumentEditMetadataProps } from '../DocumentEditMetadata.types';
import type {
  DocumentMetadataLocalState,
  DocumentMetadataFormattedData,
  DocumentEditMetadataData
} from './useDocumentEditMetadataData.types';
import type { useDocumentEditMetadataQueries } from './useDocumentEditMetadataQueries';

import * as helpers from './useDocumentEditMetadataData.helpers';
import * as documentsSelectors from 'shared/features/documents/documents.selectors';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const useDocumentEditMetadataData = ({
  document,
  queries
}: {
  document: DocumentEditMetadataProps['document'];
  queries: ReturnType<typeof useDocumentEditMetadataQueries>;
}): DocumentEditMetadataData => {
  const [metadataValues, setMetadataValues] = useState<
    DocumentMetadataLocalState['metadataValues']
  >(helpers.formatInitialMetadataValues(document));
  const [isUpdating, setIsUpdating] = useState<DocumentMetadataLocalState['isUpdating']>(false);

  const localActions: DocumentEditMetadataData['localActions'] = useMemo(
    () => ({
      setMetadataValues,
      setIsUpdating
    }),
    []
  );

  const reduxState = useSelector<GlobalState, DocumentEditMetadataData['reduxState']>(state => ({
    caiCategoriesIdsToNamesMap: documentsSelectors.getCategoriesIdsToNamesMap(state)
  }));

  const formattedData: DocumentMetadataFormattedData = useMemo(() => {
    const availableDocumentUpdateFields = helpers.formatAvailableFields(
      queries.fetchAvailableDocumentUpdateFields?.data?.avaliable_fields ?? []
    );
    const metadataFields = helpers.formatMetadataFields(document, availableDocumentUpdateFields);

    return {
      availableDocumentUpdateFields,
      metadataFields
    };
  }, [document, queries.fetchAvailableDocumentUpdateFields?.data?.avaliable_fields]);

  return {
    localState: {
      isUpdating,
      metadataValues
    },
    localActions,
    reduxState,
    formattedData
  };
};
