import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'utils/query-string';
import { OpenInNew } from '@material-ui/icons';
import { TextField } from '@compliance.ai/web-components';
import * as routes from 'constants/Routes';

export const TEST_IDS = {
  TEXT_FIELD: 'add-to-folder-modal-header-text-field',
  LINK: 'add-to-folder-modal-header-folder-link'
};

export const useAddToFolderModalHeaderData = ({ props }) => {
  const title = useMemo(() => {
    if (props.isAddingFolder) {
      return (
        <TextField
          value={props.folderName}
          onChange={props.onFolderNameChange}
          placeholder="Folder name"
          className="add-to-folder-modal-header__input"
          dataTestId={TEST_IDS.TEXT_FIELD}
        />
      );
    } else if (!props.currentFolder) {
      return 'Add to Folder';
    }

    const folderLink = `${routes.content}?${queryString.stringify({
      folder_id: props.currentFolder.id,
      folderTimelineView: true,
      search_sort: 'publication_date',
      order: 'desc',
      offset: 0,
      limit: 20,
      no_skipping: true
    })}`;

    return (
      <Link
        to={folderLink}
        className="add-to-folder-modal-header__link"
        data-testid={TEST_IDS.LINK}
      >
        {props.currentFolder.name}
        <OpenInNew />
      </Link>
    );
  }, [props]);

  return {
    formattedData: {
      title
    }
  };
};
