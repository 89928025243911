import type { FieldValueProps } from './FieldValue.types';

import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import { useFieldValueData } from './hooks';
import './_field-value.scss';

export const FieldValue = ({ children, isExpandable }: FieldValueProps) => {
  const { localState, localActions, formattedData } = useFieldValueData({ children, isExpandable });

  return (
    <div className="document-summary-metadata-field-value">
      {formattedData.content}
      {formattedData.shouldShowExpandButton && (
        <div className="document-summary-metadata-field-value__expand-button-container">
          <Button
            type={BUTTON_TYPES.LINK_CONTRAST}
            onClick={() => localActions.setIsExpanded(!localState.isExpanded)}
            className="document-summary-metadata-field-value__expand-button"
          >
            {localState.isExpanded ? 'See Less' : 'See More'}
          </Button>
        </div>
      )}
    </div>
  );
};
