import type { GlobalState } from 'shared/reducers';
import type { NotificationSwitchData } from './useNotificationSwitchData.types';

import * as viewSelectors from 'shared/features/view/view.selectors';
import * as alertsSelectors from 'shared/features/alerts/alerts.selectors';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getShowAlertPreviewFlag } from 'shared/features/view/view.selectors';

export const useNotificationSwitchData = (): NotificationSwitchData => {
  const [isSaveAlertModalOpen, setIsSaveAlertModalOpen] = useState<
    NotificationSwitchData['localState']['isSaveAlertModalOpen']
  >(false);

  const localState: NotificationSwitchData['localState'] = {
    isSaveAlertModalOpen
  };

  const localActions: NotificationSwitchData['localActions'] = useMemo(
    () => ({
      setIsSaveAlertModalOpen
    }),
    []
  );

  const reduxState = useSelector<GlobalState, NotificationSwitchData['reduxState']>(state => {
    const viewAlertId = viewSelectors.getViewAlertId(state);
    return {
      currentView: viewSelectors.getCurrentView(state),
      currentViewAlert: alertsSelectors.getUserAlertById(state, viewAlertId),
      isEditingAlert: viewSelectors.getShowAlertPreviewFlag(state)
    };
  });

  const formattedData: NotificationSwitchData['formattedData'] = useMemo(
    () => ({
      savedSearch: reduxState.currentViewAlert ?? {},
      currentViewAlertId: reduxState.currentViewAlert?.id ?? null,
      currentViewAlertName: reduxState.currentViewAlert?.name ?? null
    }),
    [reduxState.currentViewAlert]
  );

  return {
    localState,
    localActions,
    reduxState,
    formattedData
  };
};
