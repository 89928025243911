import PropTypes from 'prop-types';
import ToggleButton from './ToggleButton';
import './_toggle-buttons.scss';
import classNames from 'classnames';

export const TEST_IDS = {
  CONTAINER: 'toggle-buttons-test-id'
};

const ToggleButtons = ({ buttons, activeButton, className }) => {
  return (
    <div className={classNames('toggle-buttons', className)} data-testid={TEST_IDS.CONTAINER}>
      {buttons.map((button, index) => {
        return (
          <ToggleButton
            value={button.value}
            label={button.label}
            hovertext={button.hovertext}
            isActive={button.value === activeButton}
            onClick={button.onClick}
            isFirstElement={index === 0}
            isLastElement={index === buttons.length - 1}
            key={`${button.value} ${index}`}
          />
        );
      })}
    </div>
  );
};

export default ToggleButtons;

ToggleButtons.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      hovertext: PropTypes.string,
      onClick: PropTypes.func
    })
  ).isRequired,
  className: PropTypes.string,
  activeButton: PropTypes.string.isRequired
};

ToggleButtons.defaultProps = {
  className: ''
};
