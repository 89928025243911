import { batch } from 'react-redux';
import {
  api_fetchCompanies,
  api_fetchProductServices,
  api_fetchOrganizationProfile,
  api_postOrganizationProfile,
  api_resetOrganizationProfile
} from './organizationProfile.api';
import {
  normalizeCompany,
  normalizeProductService,
  normalizeUsersOrgProfile
} from './organizationProfile.helpers';
import { refreshOldDefaults } from '../defaults/defaults.actions';

export const CHANGE_CURRENT_STEP = 'CHANGE_CURRENT_STEP';
export const REQUEST_SUGGESTED_COMPANIES = 'REQUEST_SUGGESTED_COMPANIES';
export const RECEIVE_SUGGESTED_COMPANIES = 'RECEIVE_SUGGESTED_COMPANIES';
export const REJECT_SUGGESTED_COMPANIES = 'REJECT_SUGGESTED_COMPANIES';
export const RESET_SUGGESTED_COMPANIES = 'RESET_SUGGESTED_COMPANIES';
export const CHANGE_ORG_PROFILE = 'CHANGE_ORG_PROFILE';
export const REQUEST_PRODUCT_SERVICES = 'REQUEST_PRODUCT_SERVICES';
export const RECEIVE_PRODUCT_SERVICES = 'RECEIVE_PRODUCT_SERVICES';
export const REJECT_PRODUCT_SERVICES = 'REJECT_PRODUCT_SERVICES';
export const REQUEST_ORGANIZATION_PROFILE = 'REQUEST_ORGANIZATION_PROFILE';
export const RECEIVE_ORGANIZATION_PROFILE = 'RECEIVE_ORGANIZATION_PROFILE';

export const changeOrgProfile = orgProfile => {
  return {
    type: CHANGE_ORG_PROFILE,
    payload: {
      orgProfile
    }
  };
};

export const changeCurrentStep = step => {
  return {
    type: CHANGE_CURRENT_STEP,
    payload: {
      step
    }
  };
};

export const requestSuggestedCompanies = () => {
  return {
    type: REQUEST_SUGGESTED_COMPANIES
  };
};

export const receiveSuggestedCompanies = companies => {
  return {
    type: RECEIVE_SUGGESTED_COMPANIES,
    payload: {
      companies
    }
  };
};

export const rejectSuggestedCompanies = () => {
  return {
    type: REJECT_SUGGESTED_COMPANIES
  };
};

export const resetSuggestedCompanies = () => {
  return {
    type: RESET_SUGGESTED_COMPANIES
  };
};

export const requestOrganizationProfile = () => {
  return {
    type: REQUEST_ORGANIZATION_PROFILE
  };
};

export const receiveOrganizationProfile = organizationProfile => {
  return {
    type: RECEIVE_ORGANIZATION_PROFILE,
    payload: organizationProfile
  };
};

export const fetchSuggestedCompanies = params => async dispatch => {
  try {
    dispatch(requestSuggestedCompanies());
    const { companies } = await api_fetchCompanies(params);
    dispatch(receiveSuggestedCompanies(companies.map(normalizeCompany)));
  } catch (e) {
    dispatch(rejectSuggestedCompanies());
  }
};

export const requestProductServices = () => {
  return {
    type: REQUEST_PRODUCT_SERVICES
  };
};

export const receiveProductServices = productServices => {
  return {
    type: RECEIVE_PRODUCT_SERVICES,
    payload: {
      productServices
    }
  };
};

export const rejectProductServices = () => {
  return {
    type: REJECT_PRODUCT_SERVICES
  };
};

export const fetchProductServices = params => async dispatch => {
  try {
    dispatch(requestProductServices());
    const { product_services } = await api_fetchProductServices(params);
    dispatch(receiveProductServices(product_services.map(normalizeProductService)));
  } catch (e) {
    dispatch(rejectProductServices());
  }
};

export const fetchOrganizationProfile = () => async dispatch => {
  dispatch(requestOrganizationProfile());
  const organizationProfile = await api_fetchOrganizationProfile();

  return dispatch(receiveOrganizationProfile(normalizeUsersOrgProfile(organizationProfile)));
};

export const saveOrganizationProfile = params => async dispatch => {
  const results = await api_postOrganizationProfile(params);
  const organizationProfile = results.org_profile;

  batch(() => {
    dispatch(receiveOrganizationProfile(normalizeUsersOrgProfile(organizationProfile)));
    dispatch(refreshOldDefaults());
  });

  return results;
};

export const resetOrganizationProfile = () => async dispatch => {
  const results = await api_resetOrganizationProfile();
  const organizationProfile = results.org_profile;

  batch(() => {
    dispatch(receiveOrganizationProfile(normalizeUsersOrgProfile(organizationProfile)));
    dispatch(refreshOldDefaults());
  });

  return results;
};
