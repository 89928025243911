import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './_loader.scss';

import * as images from 'shared/images';

export const TEST_ID = 'loader';
export const LEGACY_TEST_ID = 'legacy-loader';
export const LEGACY_TYPES = {
  LIGHT: 'light',
  DARK: 'dark'
};
const LEGACY_IMAGES = {
  [LEGACY_TYPES.LIGHT]: images.loaders.loadingSpinnerLight64,
  [LEGACY_TYPES.DARK]: images.loaders.loadingSpinnerDark64
};

export const Loader = ({
  dataTestId,
  legacy,
  containerClassName,
  spinnerClassName,
  legacyType,
  withoutBackground
}) => {
  if (legacy) {
    return (
      <div
        data-testid={dataTestId || LEGACY_TEST_ID}
        className={classnames(containerClassName, 'image-container')}
      >
        <img src={LEGACY_IMAGES[legacyType]} alt="loading" className={spinnerClassName} />
      </div>
    );
  }

  return (
    <div
      data-testid={dataTestId || TEST_ID}
      className={classnames(containerClassName, 'loader__background', {
        'loader__background--is-invisible': withoutBackground
      })}
    >
      <CircularProgress className={classnames(spinnerClassName, 'loader__spinner')} />
    </div>
  );
};

Loader.propTypes = {
  legacy: PropTypes.bool,
  containerClassName: PropTypes.string,
  spinnerClassName: PropTypes.string,
  withoutBackground: PropTypes.bool,
  legacyType: PropTypes.oneOf(Object.values(LEGACY_TYPES)),
  dataTestId: PropTypes.string
};

Loader.defaultProps = {
  legacy: false,
  containerClassName: '',
  spinnerClassName: '',
  withoutBackground: false,
  legacyType: LEGACY_TYPES.LIGHT,
  dataTestId: ''
};
