import type { DiffProps } from '../Diff.types';

import * as constants from '../Diff.constants';
import * as queryKeys from 'constants/QueryKeys';
import * as errorUtils from 'utils/errors';

import { useQuery } from '@tanstack/react-query';
import { useDocumentsApi } from 'shared/features/documents/hooks';

export const useDiffQueries = ({
  props
}: {
  props: Pick<DiffProps, 'documentIds' | 'queryOptions' | 'isEnabled' | 'onActionStart'>;
}) => {
  const documentsApi = useDocumentsApi();

  const fetchDocuments = useQuery({
    enabled: props.documentIds.length > 0,
    queryKey: [
      ...queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.DOCUMENTS][
        queryKeys.QUERY_SECTIONS.DIFF_ACTION
      ](props.documentIds, props.queryOptions?.extraQueryKey)
    ],
    queryFn: () => {
      props.onActionStart?.();

      return documentsApi.fetchDocuments({
        doc_id: props.documentIds,
        exclusive_fields: constants.EXCLUSIVE_FIELDS
      });
    },
    retry: false,
    refetchOnWindowFocus: false,
    onError: errorUtils.logReactQueryError,
    select: response => {
      return {
        isDisabled:
          !props.isEnabled ||
          !(
            response.documents.length === 2 &&
            response.documents[0].has_sentences &&
            response.documents[1].has_sentences
          ),
        documents: response.documents
      };
    }
  });

  return {
    fetchDocuments
  };
};
