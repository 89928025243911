import { createSelector } from '@reduxjs/toolkit';
import lodash from 'lodash';

export const getFiltersReducer = state => state.filters;

export const getActionBarFilterParams = state =>
  getFiltersReducer(state).actionBarFilter.filterParams;

export const getActionBarFilterParamValue = (key, defaultValue) =>
  createSelector(getActionBarFilterParams, filterParams => {
    const option = filterParams[key];

    if (lodash.isUndefined(option)) {
      return defaultValue;
    } else {
      if (Array.isArray(option)) {
        return option.map(({ value }) => value);
      }
      return option.value;
    }
  });

export const getCurrentFilterType = createSelector(getFiltersReducer, filtersReducer => {
  return filtersReducer.currentFilter;
});

export const getPrimaryFilterReadyState = createSelector(getFiltersReducer, filtersReducer => {
  return filtersReducer.primaryFilter.isReady;
});

export const getPrimaryFilterParams = createSelector(getFiltersReducer, filtersReducer => {
  return filtersReducer.primaryFilter.filterParams;
});

export const getJurisdictionFilterParams = createSelector(getFiltersReducer, filtersReducer => {
  return filtersReducer.jurisdictionFilter.filterParams;
});
