import PropTypes from 'prop-types';
import { Modal, PasswordReset, EmailConfirmation } from './elements';
import { usePasswordResetModalData, usePasswordResetModalHandlers } from './hooks';
import { useAuthFormAlert } from 'common';
import { useAuthReduxActions } from 'shared/features/auth/hooks';
import { useVerificationCodeEmailData } from '../../hooks';
import './_password-reset-modal.scss';

export const PasswordResetModal = ({ onClose, onSuccess, onInvalidCodeError }) => {
  const verificationCodeEmailData = useVerificationCodeEmailData();

  const { localState, localActions, reduxState, formattedData } = usePasswordResetModalData({
    verificationCodeEmailData
  });

  const reduxActions = useAuthReduxActions();

  const { Alert, alertActions } = useAuthFormAlert();

  const {
    handleModalClose,
    handleForgetPasswordClick,
    handleConfirmNewPassword
  } = usePasswordResetModalHandlers({
    props: {
      onClose,
      onSuccess,
      onInvalidCodeError
    },
    localState,
    localActions,
    reduxActions,
    alertActions,
    verificationCodeEmailData
  });

  return (
    <Modal
      onClose={handleModalClose}
      isLoading={reduxState.isLoading}
      title={formattedData.modalTitle}
      headingMessage={formattedData.modalHeadingTitle}
    >
      <Alert className="password-reset-modal__alert" />
      {!localState.isEmailConfirmationModalOpen && (
        <PasswordReset onSubmit={handleForgetPasswordClick} />
      )}
      {localState.isEmailConfirmationModalOpen && (
        <EmailConfirmation onSubmit={handleConfirmNewPassword} />
      )}
    </Modal>
  );
};

PasswordResetModal.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  onInvalidCodeError: PropTypes.func
};
