import * as docTaskConstants from 'constants/DocTasks';
import * as uiLib from '@compliance.ai/web-components';

import { DUE_DATE_CONFIGS, TASK_DATE_FORMAT } from 'constants/Workflows';
import { formatDate, getDateBeforeDate, isBefore } from 'utils/date-utils';
import { EDITABLE_FIELDS_KEYS } from 'constants/TaskConstants';

export const getDeadline = ({ document, task }) => {
  if (task.deadline) {
    return task.deadline;
  }

  const taskDateKey = Object.keys(task.due_days_before_doc_date)[0];
  const relativeDays = task.due_days_before_doc_date[taskDateKey];

  if (taskDateKey === DUE_DATE_CONFIGS.WORKFLOW_INITIALIZED.key) {
    return getTaskDeadlineBeforeDate({
      date: new Date(),
      amount: relativeDays
    });
  }

  if (document[taskDateKey]) {
    return getTaskDeadlineBeforeDate({
      date: document[taskDateKey],
      amount: relativeDays
    });
  }

  /**
   *
   * Set current date as the default task deadline
   *
   */
  return formatDate(new Date(), TASK_DATE_FORMAT);
};

export const getTaskDeadlineBeforeDate = ({ date, amount }) => {
  const prevDate = getDateBeforeDate({
    date: date,
    amount: amount,
    unitOfTime: 'days'
  });

  return formatDate(prevDate, TASK_DATE_FORMAT);
};

export const updateTasks = ({ taskId, field, val }) => task => {
  const isTaskToUpdate = task.id === taskId;

  if (!isTaskToUpdate) {
    return task;
  }

  if (field === EDITABLE_FIELDS_KEYS.ASSIGNEE) {
    return {
      ...task,
      assignee: val
    };
  }

  if (field === EDITABLE_FIELDS_KEYS.DEADLINE) {
    return {
      ...task,
      deadline: formatDate(val, TASK_DATE_FORMAT)
    };
  }

  return task;
};

export const isPastDueTask = document => task => {
  return isBefore({
    date: getDeadline({ document, task }),
    beforeDate: new Date(),
    granularity: 'day'
  });
};

export const isDueDateEditableByPermission = ({
  userPermissions,
  document,
  task,
  isPreTask = false
}) => {
  if (isPreTask && document && isPastDueTask(document)(task)) {
    return true;
  }

  const dueDatePermission = task?.modify_deadline_permission?.name;

  if (dueDatePermission) {
    return Boolean(userPermissions[dueDatePermission]);
  }

  return true;
};

export const isOverdueTaskStatus = task => {
  if (task.task_status === docTaskConstants.TASK_STATUS.DONE) return false;

  return uiLib.isBefore(task.deadline, new Date());
};
