import {
  SINGLE_RELATIVE_DATES,
  PAST_NEXT_RELATIVE_DURATIONS,
  REATIVE_DATE_OPERATOR_LABELS
} from 'constants/RelativeDates';

export const DEFAULT_PAST_RELATIVE_DATE_AMOUNT = 1;
export const DEFAULT_PAST_RELATIVE_DATE_DURATION = PAST_NEXT_RELATIVE_DURATIONS.DAYS;

export const CUSTOM_DATE_PICKER_OPERATION_OPTIONS = [
  { label: REATIVE_DATE_OPERATOR_LABELS.BETWEEN, value: REATIVE_DATE_OPERATOR_LABELS.BETWEEN },
  { label: REATIVE_DATE_OPERATOR_LABELS.ON, value: REATIVE_DATE_OPERATOR_LABELS.ON },
  {
    label: REATIVE_DATE_OPERATOR_LABELS.ON_OR_BEFORE,
    value: REATIVE_DATE_OPERATOR_LABELS.ON_OR_BEFORE
  },
  {
    label: REATIVE_DATE_OPERATOR_LABELS.ON_OR_AFTER,
    value: REATIVE_DATE_OPERATOR_LABELS.ON_OR_AFTER
  },
  { label: SINGLE_RELATIVE_DATES.TODAY, value: SINGLE_RELATIVE_DATES.TODAY },
  { label: SINGLE_RELATIVE_DATES.YESTERDAY, value: SINGLE_RELATIVE_DATES.YESTERDAY },
  { label: SINGLE_RELATIVE_DATES.TOMORROW, value: SINGLE_RELATIVE_DATES.TOMORROW },
  { label: REATIVE_DATE_OPERATOR_LABELS.PAST, value: REATIVE_DATE_OPERATOR_LABELS.PAST }
];
export const CUSTOM_DATE_PICKER_OPERATION_OPTIONS_WITH_NEXT = [
  ...CUSTOM_DATE_PICKER_OPERATION_OPTIONS,
  { label: REATIVE_DATE_OPERATOR_LABELS.NEXT, value: REATIVE_DATE_OPERATOR_LABELS.NEXT }
];
export const CUSTOM_DATE_PICKER_PAST_DURATION_OPTIONS = [
  { label: PAST_NEXT_RELATIVE_DURATIONS.DAYS, value: PAST_NEXT_RELATIVE_DURATIONS.DAYS },
  { label: PAST_NEXT_RELATIVE_DURATIONS.WEEKS, value: PAST_NEXT_RELATIVE_DURATIONS.WEEKS },
  { label: PAST_NEXT_RELATIVE_DURATIONS.MONTHS, value: PAST_NEXT_RELATIVE_DURATIONS.MONTHS },
  { label: PAST_NEXT_RELATIVE_DURATIONS.YEARS, value: PAST_NEXT_RELATIVE_DURATIONS.YEARS }
];
