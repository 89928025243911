import type { DocumentTaskItemProps } from '../DocumentTaskItem.types';
import type { DocumentTaskData } from './useDocumentTaskItemData.types';
import type { GlobalState } from 'shared/reducers';

import * as uiLib from '@compliance.ai/web-components';
import * as taskUtils from 'utils/task-utils';
import * as workflowSelectors from 'shared/features/workflow/workflow.selectors';

import { usePermissions } from 'utils/hooks';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export const useDocumentTaskItemData = ({
  task,
  workflow,
  document
}: Pick<DocumentTaskItemProps, 'task' | 'workflow' | 'document'>): DocumentTaskData => {
  const permissions = usePermissions();

  const [initialTask] = useState(task);

  const reduxState = useSelector<GlobalState, DocumentTaskData['reduxState']>(state => ({
    assigneeOptions: workflowSelectors.getPreDocumentTaskPossibleAssigneesOptions({
      task,
      workflow
    })(state),
    selectedAssigneeOption: workflowSelectors.getPreDocumentTaskPossibleAssigneeOption(state, {
      task,
      workflow
    }),
    selectedAssigneeOptionLabel: workflowSelectors.getPreDocumentTaskPossibleAssigneeOptionLabel(
      state,
      {
        task,
        workflow
      }
    )
  }));

  const formattedData: DocumentTaskData['formattedData'] = {
    isEditableByPermission: taskUtils.isDueDateEditableByPermission({
      userPermissions: permissions,
      document,
      task: {
        ...initialTask,
        modify_deadline_permission: workflow.modify_deadline_permission
      },
      isPreTask: true
    }),
    isPastDue: uiLib.isBefore(task.deadline, new Date(), {
      unitOfTime: uiLib.DATE_UNITS.DAY
    })
  };

  return {
    reduxState,
    formattedData
  };
};
