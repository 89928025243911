import * as apiTypes from './labels.api.types';

import { fetch } from 'fetch';

export const createLabel: apiTypes.CreateLabel = params => {
  return fetch({
    url: '/label',
    method: 'POST',
    dataType: 'Annotations',
    params: params
  });
};

export const getAllLabels: apiTypes.GetAllLabels = params => {
  return fetch({
    url: '/label',
    method: 'GET',
    params,
    shouldDisableQueryParamsArrayFormatting: true
  });
};

export const getLabel: apiTypes.GetLabel = id => {
  return fetch({
    url: `/label/${id}`,
    method: 'GET'
  });
};

export const deleteLabel: apiTypes.DeleteLabel = id => {
  return fetch({
    url: `/label/${id}`,
    method: 'DELETE'
  });
};

export const modifyLabel: apiTypes.ModifyLabel = (id, params) => {
  return fetch({
    url: `/label/${id}`,
    method: 'PATCH',
    params
  });
};

export const getLabelDetails: apiTypes.GetLabelDetails = (id, params) => {
  return fetch({
    url: `/label/${id}/details`,
    method: 'GET',
    params
  });
};

export const removeLabelFromDocuments: apiTypes.RemoveLabelFromDocuments = (id, params) => {
  return fetch({
    url: `/label/${id}/details`,
    method: 'DELETE',
    params
  });
};

export const importLabels: apiTypes.ImportLabels = (bucketId, params, config = {}) => {
  let url = '/label/bulk';
  if (bucketId) {
    url += `?bucket_id=${bucketId}`;
  }
  return fetch({
    url: url,
    method: 'POST',
    params,
    ...config
  });
};

export const addLabels: apiTypes.AddLabel = params => {
  return fetch({
    url: '/labels',
    method: 'POST',
    params
  });
};

export const removeLabel: apiTypes.RemoveLabel = params =>
  fetch({
    url: '/label',
    method: 'DELETE',
    dataType: 'Annotations',
    params
  });

export const getLabeledDocsCountByDocTypes: apiTypes.GetLabeledDocsCountByDocTypes = async params => {
  const { result: labels } = await getAllLabels(params);

  const responses = (await Promise.all(
    labels
      .filter(label => Boolean(label.document_ids.length))
      .map(label =>
        fetch({
          url: '/documents_post',
          method: 'POST',
          params: {
            limit: 0,
            aggregate_by: 'cai_category_id',
            advanced_search: `doc_id IN [${[...new Set(label.document_ids)].join(', ')}]`,
            deprecated: true
          }
        })
      )
  )) as apiTypes.DocumentsAggregationResponse[];

  const categories = [
    ...new Set(
      responses.flatMap(response =>
        response.aggregations['by-cai_category_id'].buckets.map(bucket => bucket.key)
      )
    )
  ];

  const labelsWithAggregations = labels.map((label, i) => {
    return {
      ...label,
      document_ids: [...new Set(label.document_ids)],
      docs_count_grouped_by_doc_types: responses[i].aggregations['by-cai_category_id'].buckets.map(
        bucket => ({
          cai_category_id: bucket.key,
          count: bucket.doc_count
        })
      )
    };
  });

  return categories.map(categoryId => {
    const categoryLabels = labelsWithAggregations.filter(label => {
      return label.docs_count_grouped_by_doc_types.find(
        group => group.cai_category_id === categoryId
      );
    });

    return {
      categoryId: categoryId,
      labels: categoryLabels.map(label => {
        return {
          id: label.id,
          name: label.name,
          docIds: label.document_ids,
          docsCount:
            label.docs_count_grouped_by_doc_types.find(
              group => group.cai_category_id === categoryId
            )?.count ?? 0
        };
      })
    };
  });
};
