import React from 'react';
import { connect } from 'react-redux';
import { withHistory } from 'utils/hooks';
import moment from 'moment';
import lodash from 'lodash';
import queryString from 'utils/query-string';
import DocumentListItem from './DocumentListItem';
import { get_search_view, APP_VIEW_SECTION_NEWS } from 'utils/search';
import { documentIsPremiumContent } from 'utils/documents/documentsAccess';
import { setDocsToSelect } from 'shared/features/view/view.actions';
import { sameDocsToSelect } from 'utils/getSelected';
import { LoadingErrorIndicator } from 'common';

class DocumentList extends React.Component {
  componentDidUpdate() {
    if (!this.props.documents.isFetching) {
      if (!sameDocsToSelect(this.props.current_view.docs_to_select, this.props.documents)) {
        this.props.setDocsToSelect(this.props.documents);
      }
    }
  }

  getEmptyTimelineData = (query, app_view) => {
    if (query.bookmarked) {
      //empty bookmark folder
      return (
        <div className="empty-timeline-msg">
          <h1>You have no bookmarked documents.</h1>
        </div>
      );
    }
    if (query.read && query.read_folder_view) {
      //empty read folder
      return (
        <div className="empty-timeline-msg">
          <h1>You have no read documents.</h1>
        </div>
      );
    }
    if (query.folder_id) {
      //empty custom folder
      return (
        <div className="empty-timeline-msg">
          <h1>You have no documents in this folder.</h1>
        </div>
      );
    }

    return (
      <div className="empty-timeline-msg">
        <h1>{`Your ${app_view.section} is empty`}</h1>
        <h3>Suggestions:</h3>
        <ul>
          <li>Open Filters, and expand your date range, or</li>
          <li>Open Filters, and select more/different sources of data, or</li>
          <li>Open Filters, and select more/different document types, or</li>
          <li>Click on the Sources tab to follow agencies that have timeline documents</li>
        </ul>
      </div>
    );
  };

  render() {
    if (!this.props.documents) {
      return null;
    }

    if (this.props.isLoading || this.props.error) {
      return <LoadingErrorIndicator isLoading={this.props.isLoading} error={this.props.error} />;
    }

    const parsedQuery = queryString.parse(this.props.location.search);
    const app_view = get_search_view(this.props.current_view.search_params, this.props.location);

    if (this.props.count < 1 && !this.props.current_view.expanding_list) {
      return this.getEmptyTimelineData(parsedQuery, app_view);
    }

    // In the UI, we want to group the documents by the date they were created
    const documents = lodash.groupBy(
      this.props.documents.items.map(document => {
        const sorted_by_date = document.sorted_by ? document.sorted_by : document.publication_date;
        return {
          ...document,
          publicationOnDate: moment(sorted_by_date).startOf('day').unix()
        };
      }),
      'publicationOnDate'
    );

    // The keys in reverse are the unix timestamps for each day in descending order
    const descendingUnixStamps = Object.keys(documents).reverse();
    const listItems = [];
    const colSpan = app_view.section !== APP_VIEW_SECTION_NEWS ? 6 : 5;

    descendingUnixStamps.forEach(unixStamp => {
      const datetime = moment(parseInt(unixStamp, 10) * 1000);
      const isToday = datetime.isSame(moment(), 'day');
      listItems.push(
        <tr className="document-list-header" key={unixStamp}>
          <td colSpan={colSpan}>
            {isToday && 'Today: '}
            {datetime.format('MMMM Do, YYYY')}
          </td>
        </tr>
      );

      documents[unixStamp].forEach((document, docKey) => {
        const isPremiumContent = documentIsPremiumContent({ document });
        listItems.push(
          <DocumentListItem
            ref={document.id + document.sort_basis + document.sort_date}
            key={`${unixStamp}-${docKey}`}
            document={document}
            today={isToday}
            location={this.props.location}
            app_view={app_view}
            isPremiumContent={isPremiumContent}
          />
        );
      });
    });

    return (
      <table className="document-list">
        <tbody>{listItems}</tbody>
      </table>
    );
  }
}

export default connect(null, { setDocsToSelect })(withHistory(DocumentList));
