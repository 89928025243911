import type { GlobalState } from 'shared/reducers';
import type { TaskDeadlineData } from './useTaskDeadlineData.types';
import type { TaskDeadlineProps } from '../TaskDeadline.types';

import * as uiLib from '@compliance.ai/web-components';

import { isMobileView } from 'shared/features/view/view.selectors';
import { getCurrentUserReducer } from 'shared/features/user/user.selectors';
import { formatDateIgnoringTimezone } from 'utils/date-utils';
import { isDueDateEditableByPermission } from 'utils/task-utils';

import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { usePermissions } from 'utils/hooks';

export const useTaskDeadlineData = ({
  documentTask,
  document
}: Pick<TaskDeadlineProps, 'documentTask' | 'document'>): TaskDeadlineData => {
  const permissions = usePermissions();

  const [isEditable, setIsEditable] = useState(false);

  const localState: TaskDeadlineData['localState'] = {
    isEditable
  };

  const localActions: TaskDeadlineData['localActions'] = useMemo(
    () => ({
      setIsEditable
    }),
    []
  );

  const reduxState = useSelector<GlobalState, TaskDeadlineData['reduxState']>(state => ({
    isMobile: isMobileView(state),
    currentUser: getCurrentUserReducer(state)
  }));

  const formattedData: TaskDeadlineData['formattedData'] = useMemo(() => {
    const tooltipTitle = formatDateIgnoringTimezone(
      documentTask.deadline,
      uiLib.DATE_FORMATS.HUMANIZED_MONTH_DAY_YEAR
    );
    const tooltipContent = formatDateIgnoringTimezone(
      documentTask.deadline,
      uiLib.DATE_FORMATS.HUMANIZED_MONTH_DAY
    );
    const isEditableByPermission = isDueDateEditableByPermission({
      userPermissions: permissions,
      document,
      task: documentTask,
      isPreTask: false
    });

    return {
      tooltipTitle,
      tooltipContent,
      isEditableByPermission
    };
  }, [document, documentTask, permissions]);

  return {
    localState,
    localActions,
    reduxState,
    formattedData
  };
};
