import type { ListFormatter } from './useRelatedDocumentsPanelQueries.types';
import type {
  DocumentFromResponse,
  RelatedAlertFromResponse
} from 'shared/features/documents/documents.types';
import type { usePermissions } from 'utils/hooks';

import type * as documentsApi from 'shared/features/documents/documents.apiV2';

import * as relatedDocsUtils from 'utils/relatedDocuments';
import * as relatedDocsConstants from 'constants/RelatedDocuments';
import * as helpers from '../RelatedDocumentsPanel.helpers';
import * as permissionConstants from 'constants/Permissions';
import * as env from 'shared/config';
import * as uiLib from '@compliance.ai/web-components';

export const formatPremiumContentList: ListFormatter = ({ relatedDocsObject, totalCount }) => {
  const items = relatedDocsObject?.[relatedDocsConstants.RELATED_DOCUMENT_KEYS.RELATED_DOCS] ?? [];

  return {
    sectionTitle: null,
    listTitle: `Related Premium Content (${totalCount})`,
    addButtonText: null,
    options: helpers.formatPremiumDocumentOptions(relatedDocsObject) as uiLib.ListOption[],
    selectedIds: [],
    shouldRenderAddButton: false,
    shouldRenderShowMoreButton: totalCount > 0 && totalCount > items.length,
    isOptionDeletable: false
  };
};

export const formatDocumentsList: ListFormatter = ({ relatedDocsObject, totalCount }) => {
  const items = relatedDocsObject?.[relatedDocsConstants.RELATED_DOCUMENT_KEYS.RELATED_DOCS] ?? [];

  return {
    sectionTitle: null,
    listTitle: `Related Documents (${totalCount})`,
    addButtonText: null,
    options: helpers.formatRelatedDoumentOptions(relatedDocsObject),
    selectedIds: [],
    shouldRenderAddButton: false,
    shouldRenderShowMoreButton: totalCount > 0 && totalCount > items.length,
    isOptionDeletable: false
  };
};

export const formatLanguagesList: ListFormatter = ({ relatedDocsObject, totalCount }) => {
  const items = relatedDocsObject?.[relatedDocsConstants.RELATED_DOCUMENT_KEYS.RELATED_DOCS] ?? [];

  return {
    sectionTitle: null,
    listTitle: `Official Languages (${totalCount})`,
    addButtonText: null,
    options: helpers.formatOfficialLaguagesDoumentOptions(relatedDocsObject) as uiLib.ListOption[],
    selectedIds: [],
    shouldRenderAddButton: false,
    shouldRenderShowMoreButton: totalCount > 0 && totalCount > items.length,
    isOptionDeletable: false
  };
};

export const formatTranslationsList: ListFormatter = ({ relatedDocsObject, totalCount }) => {
  const items = relatedDocsObject?.[relatedDocsConstants.RELATED_DOCUMENT_KEYS.RELATED_DOCS] ?? [];

  return {
    sectionTitle: null,
    listTitle: `Auto-Translation by Compliance.ai (${totalCount})`,
    addButtonText: null,
    options: helpers.formatAutoTranslatedDocumentOptions(relatedDocsObject),
    selectedIds: [],
    shouldRenderAddButton: false,
    shouldRenderShowMoreButton: totalCount > 0 && totalCount > items.length,
    isOptionDeletable: false
  };
};

export const formatOrgDocumentsList: ListFormatter = ({
  relatedDocsObject,
  totalCount,
  permissions
}) => {
  const items = relatedDocsObject?.[relatedDocsConstants.RELATED_DOCUMENT_KEYS.RELATED_DOCS] ?? [];

  return {
    sectionTitle: 'Related Documents Set by Organization',
    listTitle: `Specific Documents (${totalCount})`,
    addButtonText: '+ Add Related Documents',
    options: helpers.formatOrganizationRelatedDocumentOptions(relatedDocsObject),
    selectedIds: [],
    shouldRenderAddButton: permissions[permissionConstants.MANAGE_ORG_RELATED_DOCS],
    shouldRenderShowMoreButton: totalCount > 0 && totalCount > items.length,
    isOptionDeletable: permissions[permissionConstants.MANAGE_ORG_RELATED_DOCS]
  };
};

export const formatDocumentAlertsList: ListFormatter = ({
  relatedDocsObject,
  totalCount,
  permissions
}) => {
  const items =
    relatedDocsObject?.[relatedDocsConstants.RELATED_DOCUMENT_KEYS.RELATED_DOC_ALERTS] ?? [];

  return {
    sectionTitle: null,
    listTitle: `Set by Alerts (${totalCount})`,
    addButtonText: (
      <uiLib.Tooltip
        title={relatedDocsConstants.RELATED_DOCUMENT_ALERTS_TOOLTIP_TITLE.replace(
          /MAX_RELATED_DOCUMENT_ALERTS_COUNT/g,
          String(relatedDocsConstants.MAX_RELATED_DOCUMENT_ALERTS_COUNT)
        )}
        type={uiLib.TOOLTIP_TYPES.SECONDARY}
        placement={uiLib.TOOLTIP_POSITION.BOTTOM}
      >
        + Related Documents by Alert
      </uiLib.Tooltip>
    ),
    options: helpers.formatOrganizationRelatedDoumentAlertOptions(relatedDocsObject),
    selectedIds: (items as RelatedAlertFromResponse[]).map(({ id }) => Number(id)),
    shouldRenderAddButton: permissions[permissionConstants.MANAGE_ORG_RELATED_DOCS],
    shouldRenderShowMoreButton: false,
    isOptionDeletable: permissions[permissionConstants.MANAGE_ORG_RELATED_DOCS]
  };
};

export const formatLists = ({
  relatedDocuments,
  permissions
}: {
  relatedDocuments: DocumentFromResponse['related_documents'];
  permissions: ReturnType<typeof usePermissions>;
}) => (
  response: Awaited<ReturnType<typeof documentsApi.fetchOrganizationRelatedDocumentAlerts>>
): Record<relatedDocsConstants.RELATED_DOCUMENT_RELATION_TYPES, ReturnType<ListFormatter>> => {
  const groupedRelatedDocs = relatedDocsUtils.getRelatedDocumentsByRelationType([
    ...relatedDocuments,
    {
      [relatedDocsConstants.RELATED_DOCUMENT_KEYS.RELATED_DOC_ALERTS]: response.related_doc_alerts,
      [relatedDocsConstants.RELATED_DOCUMENT_KEYS.RELATION_TYPE]:
        relatedDocsConstants.RELATED_DOCUMENT_RELATION_TYPES.ORGANIZATION_ALERT,
      [relatedDocsConstants.RELATED_DOCUMENT_KEYS.TOTAL_COUNT]: response.total_count
    }
  ]);

  return Object.fromEntries(
    Object.entries({
      [relatedDocsConstants.RELATED_DOCUMENT_RELATION_TYPES
        .PREMIUM_CONTENT]: formatPremiumContentList,
      [relatedDocsConstants.RELATED_DOCUMENT_RELATION_TYPES.DOCUMENTS]: formatDocumentsList,
      [relatedDocsConstants.RELATED_DOCUMENT_RELATION_TYPES.LANGUAGE]: formatLanguagesList,
      [relatedDocsConstants.RELATED_DOCUMENT_RELATION_TYPES.TRANSLATION]: formatTranslationsList,
      ...(env.orgRelatedDocsEnabled && {
        [relatedDocsConstants.RELATED_DOCUMENT_RELATION_TYPES.ORGANIZATION]: formatOrgDocumentsList,
        [relatedDocsConstants.RELATED_DOCUMENT_RELATION_TYPES
          .ORGANIZATION_ALERT]: formatDocumentAlertsList
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }).map(([relationType, formatter]: [relationType: string, formatter: any]) => [
      relationType,
      formatter({
        relatedDocsObject: groupedRelatedDocs[relationType],
        totalCount:
          groupedRelatedDocs[relationType]?.[
            relatedDocsConstants.RELATED_DOCUMENT_KEYS.TOTAL_COUNT
          ] ?? 0,
        permissions: permissions
      })
    ])
  ) as Record<relatedDocsConstants.RELATED_DOCUMENT_RELATION_TYPES, ReturnType<ListFormatter>>;
};
