import type { GlobalState } from 'shared/reducers';
import type {
  Resources,
  NormalizedResourceChild,
  NormalizedResourcesJurisdiction,
  Resources2,
  NormalizedDocumentShelf
} from './resources.types';

import * as helpers from './resources.helpers';
import * as shelvesHelpers from './resourcesShelves.helpers';

import { REDUCER_NAME } from './resources.constants';
import { createSelector } from '@reduxjs/toolkit';
import { alphabeticallyByLabel } from 'utils/sort';

import lodash from 'lodash';

export const getResourcesV2Reducer = (state: GlobalState) => state[REDUCER_NAME];

export const getResourcesReadyFlag = createSelector(getResourcesV2Reducer, resourcesReducer => {
  return resourcesReducer.areResourcesReady;
});

export const getResourcesHierarchyReadyFlag = createSelector(
  getResourcesV2Reducer,
  resourcesReducer => {
    return resourcesReducer.isHierarchyReady;
  }
);

export const getResourcesJurisdictions = createSelector(
  getResourcesV2Reducer,
  resourcesReducer => {
    return resourcesReducer.jurisdictions;
  },
  {
    memoizeOptions: {
      maxSize: 0
    }
  }
);

export const getResourcesSelectedJurisdictions = createSelector(
  getResourcesV2Reducer,
  resourcesReducer => {
    return resourcesReducer.selectedJurisdictions;
  },
  {
    memoizeOptions: {
      maxSize: 0
    }
  }
);

export const getSelectedJurisdictionsRootShelfId = createSelector(
  getResourcesSelectedJurisdictions,
  selectedJurisdictions => {
    const jurisdictionWithRootDoc = selectedJurisdictions.find(
      (jurisdiction: NormalizedResourcesJurisdiction) =>
        jurisdiction.resources?.rootShelfId || jurisdiction.resources?.rootDocId
    );

    if (jurisdictionWithRootDoc) {
      return (
        jurisdictionWithRootDoc?.resources?.rootShelfId ??
        jurisdictionWithRootDoc?.resources?.rootDocId ??
        null
      );
    }

    return null;
  }
);

export const getResources = createSelector(getResourcesV2Reducer, resourcesReducer => {
  return resourcesReducer.resources;
});

export const getAllResourcesItems = createSelector(getResources, resources => {
  return Object.values(resources).flatMap(({ items }: Resources) => items);
});

export const getSelectedResource = createSelector(
  [
    getAllResourcesItems,
    (state, selectedResourceId: NormalizedResourceChild['id'] | null) => selectedResourceId
  ],
  (resources, selectedResourceId): NormalizedResourceChild | null => {
    return (
      resources.find(resource => (resource as NormalizedResourceChild).id === selectedResourceId) ??
      null
    );
  }
);

export const getSelectedResourceParentIds = createSelector(
  getResources,
  getSelectedResource,
  (resources, selectedResource) => {
    return selectedResource
      ? helpers.getResourceParentIds({
          resource: selectedResource,
          resources: resources
        })
      : [];
  }
);

export const getVisibleResources = createSelector(
  [
    getResources,
    getAllResourcesItems,
    getSelectedJurisdictionsRootShelfId,
    getSelectedResourceParentIds,
    (state, selectedResourceId: NormalizedResourceChild['id'] | null) => selectedResourceId
  ],
  (
    resources,
    resourceItems,
    selectedJurisdictionRootDocId,
    selectedResourceParentIds: NormalizedResourceChild['parentId'][],
    selectedResourceId
  ) => {
    const filteredResources = Object.entries(resources)
      .map(([parentId, resource]) => [Number(parentId), resource])
      .filter(([parentId]) => {
        return (
          Number(parentId) === selectedResourceId ||
          selectedJurisdictionRootDocId === Number(parentId) ||
          selectedResourceParentIds.includes(Number(parentId))
        );
      });

    return helpers.sortItemsByParentId(
      filteredResources as Parameters<typeof helpers.sortItemsByParentId>[0],
      resourceItems
    );
  }
);

export const getSelectedResourceItems = createSelector(
  getAllResourcesItems,
  getSelectedResourceParentIds,
  (resourceItems, selectedResourceParentIds) => {
    const filteredResourceSortedById = helpers.sortResourcesByParentId(
      selectedResourceParentIds,
      resourceItems
    );
    return filteredResourceSortedById
      .map(id => {
        return resourceItems.find(resource => resource.id === id);
      })
      .filter(Boolean) as NormalizedResourceChild[];
  }
);

export const getResourcesByParentId = (parentId: NormalizedResourceChild['id']) =>
  createSelector(getResources, resources => {
    return resources[parentId];
  });

export const getResourcesRootJurisdictions = createSelector(
  getResourcesJurisdictions,
  jurisdictions => {
    return jurisdictions.filter((jurisdiction: NormalizedResourcesJurisdiction) =>
      lodash.isNil(jurisdiction.parentId)
    );
  }
);

export const getResourcesRootJurisdictionsOptions = createSelector(
  getResourcesRootJurisdictions,
  rootJurisdictions => {
    return rootJurisdictions.map(helpers.formatJurisdictionOption).sort(alphabeticallyByLabel);
  },
  {
    memoizeOptions: {
      maxSize: 0
    }
  }
);

export const getResourcesSelectedJurisdictionsOptions = createSelector(
  getResourcesSelectedJurisdictions,
  selectedJurisdictions => {
    return selectedJurisdictions.map(helpers.formatJurisdictionOption);
  },
  {
    memoizeOptions: {
      maxSize: 0
    }
  }
);

export const getResourcesSelectedJurisdictionsOptionsMap = createSelector(
  getResourcesJurisdictions,
  getResourcesSelectedJurisdictions,
  (jurisdictions, selectedJurisdictions) => {
    return selectedJurisdictions.reduce((selectedJurisdictionsOptions, selectedJurisdiction) => {
      const jurisdictionsOptions = jurisdictions
        .filter(
          ({ parentId }: NormalizedResourcesJurisdiction) =>
            parentId === selectedJurisdiction.parentId
        )
        .map(helpers.formatJurisdictionOption)
        .sort(alphabeticallyByLabel);

      return {
        ...selectedJurisdictionsOptions,
        [selectedJurisdiction.id]: jurisdictionsOptions
      };
    }, {});
  },
  {
    memoizeOptions: {
      maxSize: 0
    }
  }
);

export const getResourcesSelectedJurisdictionsChildrenOptionsMap = createSelector(
  getResourcesJurisdictions,
  getResourcesSelectedJurisdictions,
  (jurisdictions, selectedJurisdictions) => {
    return selectedJurisdictions.reduce(
      (selectedJurisdictionsChildrenOptions, selectedJurisdiction) => {
        const jurisdictionsOptions = jurisdictions
          .filter(({ parentId }) => parentId === selectedJurisdiction.id)
          .map(helpers.formatJurisdictionOption)
          .sort(alphabeticallyByLabel);

        return {
          ...selectedJurisdictionsChildrenOptions,
          [selectedJurisdiction.id]: jurisdictionsOptions
        };
      },
      {}
    );
  },
  {
    memoizeOptions: {
      maxSize: 0
    }
  }
);

export const getSelectedResourceParentJurisdictions = createSelector(
  [
    getResourcesJurisdictions,
    getSelectedResourceItems,
    (state, selectedResourceId: NormalizedResourceChild['id'] | null) => selectedResourceId
  ],
  (jurisdictions, selectedResources, selectedResourceId): NormalizedResourcesJurisdiction[] => {
    return helpers.getParentJurisdictionsByChildId({
      resourceId: selectedResources[0]?.parentId ?? selectedResourceId,
      jurisdictions
    });
  },
  {
    memoizeOptions: {
      maxSize: 0
    }
  }
);

// used by ResourcesV2 (with doc shelves)

export const getResourcesShelves = createSelector(getResourcesV2Reducer, resourcesReducer => {
  return resourcesReducer.resourcesShelves;
});

export const getBreadcrumbs = createSelector(getResourcesV2Reducer, resourcesReducer => {
  return resourcesReducer.breadcrumbs;
});

export const getActiveShelfId = createSelector(getResourcesV2Reducer, resourcesReducer => {
  return resourcesReducer.activeShelfId;
});

export const getSelectedShelvesIds = createSelector(getBreadcrumbs, breadcrumbs => {
  return breadcrumbs.reduce((ids, breadcrumb) => {
    if (breadcrumb.shelf_id) {
      ids.push(breadcrumb.shelf_id);
    }
    return ids;
  }, [] as number[]);
});

export const getSelectedDocIds = createSelector(getBreadcrumbs, breadcrumbs => {
  return breadcrumbs.reduce((ids, breadcrumb) => {
    if (breadcrumb.latest_doc_id) {
      ids.push(breadcrumb.latest_doc_id);
    }

    return ids;
  }, [] as number[]);
});

export const getVisibleShelves = createSelector(
  [
    getResourcesShelves,
    getSelectedShelvesIds,
    (state, selectedShelfId: NormalizedDocumentShelf['id']) => selectedShelfId
  ],
  (shelves, breadcrumbs, selectedShelfId): [string | number, Resources2][] => {
    if (shelves?.children) {
      let selectedShelfIds: number[] = [];
      if (breadcrumbs?.length) {
        selectedShelfIds = breadcrumbs;
      } else if (selectedShelfId) {
        selectedShelfIds = [selectedShelfId];
      }

      return shelvesHelpers.getVisibleShelves(shelves, selectedShelfIds);
    }
    return [];
  }
);

export const getSelectedResourceParentJurisdictionsV2 = createSelector(
  [
    getResourcesJurisdictions,
    getBreadcrumbs,
    (state, selectedResourceId: NormalizedResourceChild['id'] | null) => selectedResourceId
  ],
  (jurisdictions, breadcrumbs, selectedResourceId): NormalizedResourcesJurisdiction[] => {
    const rootBreadcrumb = breadcrumbs[0];

    if (rootBreadcrumb) {
      const jurisdictionsForDocId = helpers.getParentJurisdictionsByChildId({
        resourceId: rootBreadcrumb.latest_doc_id,
        jurisdictions
      });
      if (jurisdictionsForDocId?.length) {
        return jurisdictionsForDocId;
      }
      const jurisdictionsForShelfId = helpers.getParentJurisdictionsByChildId({
        resourceId: rootBreadcrumb.shelf_id,
        jurisdictions
      });
      if (jurisdictionsForShelfId?.length) {
        return jurisdictionsForShelfId;
      }
    }
    return helpers.getParentJurisdictionsByChildId({
      resourceId: selectedResourceId,
      jurisdictions
    });
  },
  {
    memoizeOptions: {
      maxSize: 0
    }
  }
);
