import { Link } from 'react-router-dom';
import { Switch } from 'common';
import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import PropTypes from 'prop-types';
import './_default-filters-switch.scss';

export const TEST_IDS = {
  CONTAINER: 'DefaultFilterSwitch__Container',
  SWITCH: 'DefaultFilterSwitch__Switch',
  BUTTON: 'DefaultFilterSwitch__Button'
};

const DefaultFilterSwitch = ({ isChecked, onChange, onClearAll }) => {
  return (
    <div data-testid={TEST_IDS.CONTAINER} className="default-filters-switch__container">
      <div className="default-filters-switch__column">
        <Switch dataTestId={TEST_IDS.SWITCH} isChecked={isChecked} onChange={onChange} />
        <Link to="/sources" className="default-filters-switch__link">
          Default Filters
        </Link>
      </div>
      <div className="default-filters-switch__column">
        <Button dataTestId={TEST_IDS.BUTTON} type={BUTTON_TYPES.LINK} onClick={onClearAll}>
          Clear All
        </Button>
      </div>
    </div>
  );
};

export default DefaultFilterSwitch;

DefaultFilterSwitch.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired
};
