import styled from 'styled-components';
import { useState } from 'react';
import { IconAdd, IconRemove } from '@compliance.ai/web-components';
import PropTypes from 'prop-types';

const StyledAccordion = styled.div`
  background: #f2f2f2;
  padding: 5px 15px;
  width: 100%;
  margin-bottom: 15px;
  position: relative;
`;

const StyledAccordionHeader = styled.div`
  padding: 10px 0px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

const StyledAccordionContent = styled.div`
  display: ${props => (props.expanded ? 'block' : 'none')};
  margin-bottom: 10px;
`;

export const TEST_ID = {
  CONTAINER: 'accordion-container',
  HEADER: 'accordion-header',
  CONTENT: 'accordion-content'
};

function SharedAccordion({ accordions }) {
  const [expandedKey, setExpandedKey] = useState(0);

  const toggleExpandedKey = newKey => setExpandedKey(expandedKey === newKey ? null : newKey);

  return (
    <>
      {accordions.map(({ title, disabled, content }, index) => (
        <StyledAccordion data-testid={TEST_ID.CONTAINER} disabled={disabled} key={index}>
          <StyledAccordionHeader
            data-testid={TEST_ID.HEADER}
            onClick={toggleExpandedKey.bind(this, index)}
          >
            {title}
            {expandedKey === index ? <IconRemove /> : <IconAdd />}
          </StyledAccordionHeader>
          <StyledAccordionContent data-testid={TEST_ID.CONTENT} expanded={expandedKey === index}>
            {content}
          </StyledAccordionContent>
        </StyledAccordion>
      ))}
    </>
  );
}

SharedAccordion.propTypes = {
  accordions: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    })
  ).isRequired
};

export default SharedAccordion;
