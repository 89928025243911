import type { ConceptsSelectHandlers } from './useConceptsSelectHandlers.types';
import type { ConceptsSelectProps } from '../ConceptsSelect.types';
import type { ConceptsSelectData } from './useConceptsSelectData.types';

export const useConceptsSelectHandlers = ({
  props,
  reduxState
}: {
  props: Pick<ConceptsSelectProps, 'onChange'>;
  reduxState: ConceptsSelectData['reduxState'];
}): ConceptsSelectHandlers => {
  const handleChange: ConceptsSelectHandlers['handleChange'] = options => {
    if (!Array.isArray(options)) {
      props.onChange?.([], []);
    } else {
      const concepts = reduxState.concepts.filter(concept => {
        return options.find(option => concept.id === option.value);
      });

      props.onChange?.(options, concepts);
    }
  };

  return {
    handleChange
  };
};
