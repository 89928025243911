import { createSelector } from '@reduxjs/toolkit';
import { REDUCER_NAME } from './organizationProfile.constants';

export const getOrganizationProfileReducer = state => state[REDUCER_NAME];

export const getOrganizationProfile = createSelector(
  getOrganizationProfileReducer,
  organizationProfileReducer => organizationProfileReducer.orgProfile
);

export const getOrganizationProfileName = createSelector(
  getOrganizationProfileReducer,
  organizationProfileReducer => organizationProfileReducer.orgProfile?.name
);

export const getOrganizationProfileCurrentStep = createSelector(
  getOrganizationProfileReducer,
  organizationProfileReducer => organizationProfileReducer.currentStep
);

export const getIsOrgProfileSaved = createSelector(
  getOrganizationProfileReducer,
  organizationProfileReducer => organizationProfileReducer.orgProfileSaved
);

export const getSuggestedCompanies = createSelector(
  getOrganizationProfileReducer,
  organizationProfileReducer => organizationProfileReducer.suggestedCompanies.results
);

export const getSuggestionsFetchingState = createSelector(
  getOrganizationProfileReducer,
  organizationProfileReducer => organizationProfileReducer.suggestedCompanies.isFetching
);

export const getProductServices = createSelector(
  getOrganizationProfileReducer,
  organizationProfileReducer => organizationProfileReducer.productServices.results
);

export const getProductServicesFetchingState = createSelector(
  getOrganizationProfileReducer,
  organizationProfileReducer => organizationProfileReducer.productServices.isFetching
);
