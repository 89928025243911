import React from 'react';
import { Modal, Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { deleteWorkflowMold } from 'shared/features/workflowMold/workflowMold.actions';
import './_workflow-mold-delete-modal.scss';

const WorkflowMoldDeleteModal = ({
  doc_filter,
  reduxDeleteWorkflowMold,
  setShowDeleteModalStatus,
  showDeleteModal,
  workflow_mold_id
}) => {
  const documentTypes = doc_filter && doc_filter.category && doc_filter.category.join(', ');

  if (!showDeleteModal) return null;

  return (
    <Modal
      isOpen={showDeleteModal}
      onClose={() => setShowDeleteModalStatus(false)}
      title={
        documentTypes.length
          ? `Delete workflow template for ${documentTypes}?`
          : 'Delete workflow template?'
      }
    >
      <div className="workflow-mold-delete-modal">
        <Button
          type={BUTTON_TYPES.PRIMARY}
          onClick={() => {
            reduxDeleteWorkflowMold({ workflow_mold_id });
            setShowDeleteModalStatus(false);
          }}
        >
          Delete Template
        </Button>
      </div>
    </Modal>
  );
};

WorkflowMoldDeleteModal.propTypes = {
  doc_filter: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array])
  ).isRequired,
  reduxDeleteWorkflowMold: PropTypes.func.isRequired,
  setShowDeleteModalStatus: PropTypes.func.isRequired,
  showDeleteModal: PropTypes.bool.isRequired,
  workflow_mold_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default connect(null, {
  reduxDeleteWorkflowMold: deleteWorkflowMold
})(React.memo(WorkflowMoldDeleteModal));
