import moment from 'moment';
import lodash from 'lodash';
import { getCategoryIDByName } from 'shared/features/documents/documents.selectors';
import { isValidCategoryArgument } from './category/category';

// parameters used for the graph API call
export const INSIGHTS_PARAMS = {
  act_enforcement_matrix: {
    agency_id: ['164', '573', '466'],
    has_unofficial_publication_date: false
  },
  rules_by_quarter: {
    agency_id: ['466', '80', '188', '164', '573'],
    has_unofficial_publication_date: false
  },
  rules_by_agency: {
    agency_id: ['573', '164', '466', '188', '80'],
    act_id: '3207',
    has_unofficial_publication_date: false
  }
};

// additional parameters needed to build an equivalent search in the dashboard
const buildDefaultSearchParams = categories => ({
  act_enforcement_matrix: {},
  rules_by_quarter: {
    cai_category: [
      getCategoryIDByName(categories, 'Rule'),
      getCategoryIDByName(categories, 'Proposed Rule')
    ]
  },
  insights_csv: {
    cai_category: [getCategoryIDByName(categories, 'Enforcement')]
  },
  rules_by_agency: {
    search_sort: 'date',
    autosuggest_filter: 'acts'
  }
});

export const agency_id_hash = {
  CFPB: '573',
  FDIC: '164',
  SEC: '466',
  FRS: '188',
  OCC: '80',
  TREAS: '497',
  NYSE: '9030',
  FTC: '192',
  FINRA: '9015',
  FFIEC: '168',
  FINCEN: '194',
  OFAC: '203'
};

const published_from_date = aggregation => {
  let published_from;

  if (aggregation === 'rules_by_quarter' || aggregation === 'rules_by_agency') {
    // 3 full years of quarters
    const from_date = moment.utc().subtract(40, 'months');

    published_from = from_date.format('MM/DD/YYYY');
  } else if (aggregation === 'insights_csv') {
    // search from 1 year ago
    const now = moment.utc();
    const from_date = moment.utc().year(now.year() - 1);

    published_from = from_date.format('MM/DD/YYYY');
  }

  return published_from;
};

export function get_dashboard_params(
  aggregation,
  agency_name = '',
  caiCategory = null,
  published_from = '',
  published_to = '',
  props = null,
  categories = {}
) {
  const params = {
    ...INSIGHTS_PARAMS[aggregation],
    ...buildDefaultSearchParams(categories)[aggregation]
  };

  if (!lodash.isEmpty(agency_name)) {
    // specify agency for agency specific graphs
    if (props) {
      const agency = props.agencies.items.find(a => a.short_name === agency_name);
      params.agency = agency.id;

      if (props.regulation) {
        params[props.regulation.type] = props.regulation.value;
      }
      if (props.act_id) {
        params.act_id = props.act_id;
      }
      if (props.regulation_id) {
        params.regulation_id = props.regulation_id;
      }
    } else {
      params.agency = [agency_id_hash[agency_name]];
    }
  } else {
    // rename agency_id -> agency
    params.agency = params.agency_id;
  }

  delete params.agency_id;

  if (isValidCategoryArgument(caiCategory)) {
    if (Array.isArray(caiCategory)) {
      params.cai_category = caiCategory;
    } else {
      params.cai_category = [caiCategory];
    }
  }

  // set published_from date
  params.published_from = !lodash.isEmpty(published_from)
    ? published_from
    : published_from_date(aggregation);
  params.published_to = !lodash.isEmpty(published_to) ? published_to : null;
  params.search_sort = 'publication_date';
  params.order = 'desc';
  params.has_unofficial_publication_date = false;

  return params;
}
