import type { FC } from 'react';
import type { DropdownProps } from './Dropdown.types';

import * as uiLib from '@compliance.ai/web-components';

import { useDropdownData, useDropdownHandlers } from './hooks';

import './_dropdown.scss';

export const Dropdown: FC<DropdownProps> = ({ isEditable, dropdown, onChange }) => {
  const { formattedData } = useDropdownData({
    dropdown
  });

  const handlers = useDropdownHandlers({
    props: {
      dropdown,
      onChange
    }
  });

  if (!isEditable && formattedData.selectedOptionsString.length) {
    return (
      <uiLib.LabeledField label={formattedData.label} isContrast>
        <div className="task-dropdown-annotation__non-editable-input-wrapper">
          <uiLib.Tooltip
            type={uiLib.TOOLTIP_TYPES.SECONDARY}
            title={formattedData.selectedOptionsString}
          >
            <input
              type="text"
              className="task-dropdown-annotation__input"
              value={formattedData.selectedOptionsString}
              disabled
            />
          </uiLib.Tooltip>
        </div>
      </uiLib.LabeledField>
    );
  }

  if (isEditable && dropdown.is_multi) {
    return (
      <uiLib.LabeledField label={formattedData.label} isContrast>
        <uiLib.Select
          type={uiLib.SELECT_TYPES.DEFAULT}
          className="task-dropdown-annotation__dropdown"
          value={formattedData.multiSelectValue}
          options={formattedData.options}
          onChange={handlers.handleChange}
          isGroupSelectable
          shouldShowLabelBreadcrumbs
          withTooltips
          hideSelectedOptions={false}
          isMulti
          withCheckboxes
        />
      </uiLib.LabeledField>
    );
  }

  if (isEditable && !dropdown.is_multi) {
    return (
      <uiLib.LabeledField label={formattedData.label} isContrast>
        <uiLib.Select
          type={uiLib.SELECT_TYPES.DEFAULT}
          className="task-dropdown-annotation__dropdown"
          options={formattedData.options}
          value={formattedData.singleSelectValue}
          onChange={handlers.handleChange}
        />
      </uiLib.LabeledField>
    );
  }

  return null;
};
