export const REDUCER_NAME = 'organization';

export enum ORG_MEMBER_SORT_FIELD {
  EMAIL = 'email'
}

export enum ORG_MEMBERS_REQUEST_PARAMS {
  SORT_BY = 'sort_by',
  ORDER_BY = 'order_by'
}

export enum TEAM_MEMBER_SORT_FIELD {
  NAME = 'name',
  EMAIL = 'email',
  STATUS = 'status',
  ROLE = 'role',
  TEAM = 'team'
}

export enum TEAM_MEMBERS_REQUEST_PARAMS {
  EMAIL = 'email',
  SORT_BY = 'sort_by',
  ORDER_BY = 'order_by'
}

export const DEFAULT_TEAM_GROUP = 'Corporate';
