import type { AddToWorkflowProps } from '../AddToWorkflow.types';
import type { GlobalState } from 'shared/reducers';
import type { AddToWorkflowData } from './useAddToWorkflowData.types';

import * as authSelectors from 'shared/features/auth/auth.selectors';
import * as constants from '../AddToWorkflow.constants';
import * as permissionConstants from 'constants/Permissions';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePermissions } from 'utils/hooks';

export const useAddToWorkflowData = (
  props: Pick<AddToWorkflowProps, 'documentIds'>
): AddToWorkflowData => {
  const permissions = usePermissions();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const localState: AddToWorkflowData['localState'] = {
    isModalOpen
  };

  const localActions: AddToWorkflowData['localActions'] = useMemo(
    () => ({
      setIsModalOpen
    }),
    []
  );

  const reduxState = useSelector<GlobalState, AddToWorkflowData['reduxState']>(state => ({
    isAuthenticated: authSelectors.getUserAuthenticatedState(state)
  }));

  const formattedData: AddToWorkflowData['formattedData'] = {
    areAllDocumentsSelected: props.documentIds?.length === constants.MAX_DOCUMENTS_COUNT,
    isDisabled: !props.documentIds?.length,
    isRestricted: !reduxState.isAuthenticated || !permissions[permissionConstants.ACCESS_TASKS]
  };

  return {
    localState,
    localActions,
    reduxState,
    formattedData
  };
};
