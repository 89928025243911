import lodash from 'lodash';
import * as orgHelpers from '../organizations/organizations.helpers';
import * as bucketHelpers from '../buckets/buckets.helpers';
import * as dateUtils from 'utils/date-utils';
import * as sortUtils from 'utils/sort';

export const normalizeLabelsResponse = response => {
  if (Array.isArray(response?.result)) {
    return {
      labels: response.result.map(normalizeLabel),
      totalCount: lodash.isNumber(response.total_count) ? response.total_count : 0
    };
  }

  return {
    labels: [],
    totalCount: 0
  };
};

export const normalizeLabel = (
  {
    id = null,
    name = null,
    created_at = null,
    created_by = null,
    documents_count = 0,
    bucket = null,
    is_editable = false,
    description = '',
    external_id = '',
    type = null,
    alerts = []
  } = {
    id: null,
    name: null,
    created_at: null,
    created_by: null,
    documents_count: 0,
    bucket: null,
    is_editable: false,
    description: null,
    external_id: null,
    type: null,
    alerts: []
  }
) => {
  return {
    id: id,
    name: name,
    createdAt: created_at,
    createdBy: lodash.isObject(created_by) ? normalizeLabelAuthor(created_by) : created_by,
    documentsCount: documents_count,
    bucket,
    isEditable: is_editable,
    description: description,
    externalId: external_id,
    type: type,
    alerts
  };
};

export const normalizeLabelAuthor = (
  { email = null, first_name = null, id = null, last_name = null } = {
    email: null,
    first_name: null,
    id: null,
    last_name: null
  }
) => {
  return {
    id: id,
    email: email,
    firstName: first_name,
    lastName: last_name
  };
};

export const normalizeLabelDetailCreationDate = addedAt => {
  return dateUtils.isValidDate(addedAt) ? dateUtils.formatDate(addedAt) : '';
};

export const normalizeLabelDetailAuthor = (
  { added_at = '', user_email = '', user_id = null } = {
    added_at: '',
    user_email: '',
    user_id: null
  }
) => ({
  userId: user_id,
  userEmail: user_email,
  addedAt: normalizeLabelDetailCreationDate(added_at)
});

export const normalizeLabelDetail = (
  { document_id = null, added_by = [], added_at = [], document_title = '', label_parent = '' } = {
    document_id: null,
    added_by: [],
    added_at: [],
    document_title: '',
    label_parent: ''
  }
) => ({
  documentId: document_id,
  documentTitle: document_title,
  labelParent: lodash.isString(label_parent) ? label_parent.split(',') : [],
  addedBy: added_by.map(normalizeLabelDetailAuthor),
  addedAt: added_at.map(normalizeLabelDetailCreationDate)
});

export const normalizeLabelDetailsResponse = response => {
  if (Array.isArray(response?.result)) {
    return {
      labels: response.result.map(normalizeLabelDetail),
      totalCount: lodash.isNumber(response.total_count) ? response.total_count : 0
    };
  }

  return {
    labels: [],
    totalCount: 0
  };
};

export const normalizeImportResponse = response => {
  return {
    allLabelsCount: response.all_labels_count ?? 0,
    createdLabelsCount: response.created_labels_count ?? 0,
    existingLabelsCount: response.existing_labels_count ?? 0
  };
};

export const formatLabelOption = label => ({
  label: label.name,
  value: label.id
});

export const getLabelsBucketsOptions = labels => {
  return Object.values(
    Object.fromEntries(
      labels.flatMap(orgLabel => {
        const bucketOption = bucketHelpers.formatBucketOption(orgLabel.bucket);
        return [[bucketOption.value, bucketOption]];
      })
    )
  ).sort(orgHelpers.sortBucketsByLabel);
};

export const getLabelsGroupedOptions = labels => {
  const bucketsOptions = getLabelsBucketsOptions(labels);
  return bucketsOptions
    .map(({ label: bucketName }) => ({
      label: bucketName,
      options: labels
        .filter(label => bucketHelpers.getBucketName(label.bucket) === bucketName)
        .map(formatLabelOption)
        .sort(sortUtils.alphabeticallyByLabel)
    }))
    .sort(orgHelpers.sortBucketsByLabel);
};
