import type { TaskAttachmentsAnnotationProps } from './TaskAttachmentsAnnotation.types';

import { GenericAttachments } from 'common/index';
import {
  MAX_ALLOWED_ATTACHMENT_FILE_SIZE,
  MAX_ALLOWED_ATTACHMENT_FILES
} from 'constants/TaskConstants';
import { WORKFLOW_ANNOTATION_TYPES } from 'shared/features/workflow/workflow.constants';
import { toGenericAttachments } from './TaskAttachmentsAnnotation.helpers';
import { useDocumentTaskAnnotations } from 'shared/features/workflow/hooks';
import {
  useTaskAttachmentsAnnotationHandlers,
  useTaskAttachmentsAnnotationMutations
} from './hooks';
import './_task-attachments-annotation.scss';

export const TaskAttachmentsAnnotation = ({
  documentTask,
  files,
  isEditable
}: TaskAttachmentsAnnotationProps) => {
  const annotation = useDocumentTaskAnnotations(documentTask)[WORKFLOW_ANNOTATION_TYPES.ATTACHMENT];

  const mutations = useTaskAttachmentsAnnotationMutations();

  const handlers = useTaskAttachmentsAnnotationHandlers({
    props: {
      files,
      documentTask
    },
    mutations
  });

  if (!annotation.isAvailable && !files.length && !annotation.isRequired) {
    return null;
  }

  return (
    <GenericAttachments
      isRequired={annotation.isRequired}
      attachments={files.map(toGenericAttachments)}
      disableEdit={!isEditable}
      saveAttachment={handlers.handleFileDrop}
      deleteAttachment={handlers.handleFileRemoval}
      maxFiles={MAX_ALLOWED_ATTACHMENT_FILES}
      maxFileSize={MAX_ALLOWED_ATTACHMENT_FILE_SIZE}
      attachmentClassName="task-attachments-annotation__attachment"
      containerClassName="task-attachments-annotation__container"
      buttonClassName="task-attachments-annotation__attach-button"
    />
  );
};
