import { fetch } from 'utils/api';

export function api_fetchDefaultSources() {
  return fetch({
    url: `/sources`,
    method: 'GET',
    dataType: 'Sources'
  });
}

export function api_fetchPopularSources(params) {
  return fetch({
    url: `/popular_sources`,
    method: 'GET',
    params,
    dataType: 'PopularSources'
  });
}
