import type { PublicationDateData } from './usePublicationDateData.types';
import type { GlobalState } from 'shared/reducers';
import type { PublicationDateProps } from '../PublicationDate.types';

import { getUserTimezone } from 'shared/features/user/user.selectors';
import {
  DOCUMENT_PROPERTY_LABEL,
  DOCUMENT_PROPERTY_VALUE,
  getDocumentProperty
} from 'utils/documents/documentProperties';
import { DOCUMENT_FIELDS } from 'constants/DocumentProperties';

import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const usePublicationDateData = ({
  document
}: Pick<PublicationDateProps, 'document'>): PublicationDateData => {
  const reduxState = useSelector<GlobalState, PublicationDateData['reduxState']>(state => ({
    userTimezone: getUserTimezone(state)
  }));

  const formattedData: PublicationDateData['formattedData'] = useMemo(() => {
    const publicationDateProperty = getDocumentProperty[DOCUMENT_FIELDS.publicationDate]({
      document,
      // TODO Uncomment when documentProperties utils will be moved to TS
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      timezoneName: reduxState.userTimezone
    });

    return {
      publicationLabel: (publicationDateProperty[
        DOCUMENT_PROPERTY_LABEL as keyof typeof publicationDateProperty
      ] as unknown) as string,
      publicationValue: (publicationDateProperty[
        DOCUMENT_PROPERTY_VALUE as keyof typeof publicationDateProperty
      ] as unknown) as string
    };
  }, [document, reduxState.userTimezone]);

  return {
    reduxState,
    formattedData
  };
};
