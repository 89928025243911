import type * as uiLib from '@compliance.ai/web-components';

export const ROW_COUNT_OPTIONS: uiLib.SelectOption[] = [
  { label: '20', value: 20 },
  { label: '40', value: 40 },
  { label: '60', value: 60 }
];

export const DEFAULT_ROW_COUNT_OPTION = ROW_COUNT_OPTIONS[0];
export const DEFAULT_ROW_COUNT = DEFAULT_ROW_COUNT_OPTION.value;

export enum DOCUMENTS_TABLE_SETTINGS {
  ROWS_COUNT = 'rowsCount'
}
