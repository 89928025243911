import { CORPORATE_WEBSITE_COMPARE_EDITIONS_URL } from 'constants/CorporateWebsiteLinks';
import * as images from 'shared/images';

export const RESTRICTION_MODAL_TYPES = {
  AUTOTRANSLATE: 'autotranslate',
  JURISDICTION_CONTENT: 'jurisdiction_content',
  ENFORCEMENT_EXPLORER: 'enforcement_explorer'
};

export const RESTRICTION_MODAL_ICON_TYPES = {
  ICON: 'icon',
  IMAGE: 'image'
};

export const RESTRICTION_MODAL_OPTIONS = {
  [RESTRICTION_MODAL_TYPES.AUTOTRANSLATE]: {
    title: 'Upgrade to use this feature.',
    text:
      "With Auto-Translation, you gain access to English translations of regulatory documents that do not have an official English version. Once this feature is enabled, you will not only have access to a PDF of the English version, but also, if the document is sentencified, you can leverage Compliance.ai's annotations and obligations capabilities.",
    boxes: [
      {
        text: 'Monitor any source',
        icon: {
          type: RESTRICTION_MODAL_ICON_TYPES.IMAGE,
          src: images.misc.iconMarketMonitor,
          className: 'icon_market-monitor'
        }
      },
      {
        text: 'Analyze the impact',
        icon: {
          type: RESTRICTION_MODAL_ICON_TYPES.IMAGE,
          src: images.misc.iconMarketAnalyze,
          className: 'icon_market-analyze'
        }
      },
      {
        text: 'Automate processes',
        icon: {
          type: RESTRICTION_MODAL_ICON_TYPES.IMAGE,
          src: images.misc.iconMarketAutomate,
          className: 'icon_market-automate'
        }
      }
    ],
    button: {
      authenticated: {
        text: 'Request Access'
      },
      unauthenticated: {
        text: 'Compare Editions',
        link: CORPORATE_WEBSITE_COMPARE_EDITIONS_URL
      }
    }
  },
  [RESTRICTION_MODAL_TYPES.JURISDICTION_CONTENT]: {
    title: 'Upgrade to use this feature.',
    text:
      "Need access to documents from this jurisdiction? If you need to research and analyze global regulatory content, you can upgrade to access PDFs from this jurisdiction. In addition, if the document is sentencified, you can leverage Compliance.ai's annotations and obligations capabilities.",
    boxes: [
      {
        text: 'Monitor any source',
        icon: {
          type: RESTRICTION_MODAL_ICON_TYPES.IMAGE,
          src: images.misc.iconMarketMonitor,
          className: 'icon_market-monitor'
        }
      },
      {
        text: 'Analyze the impact',
        icon: {
          type: RESTRICTION_MODAL_ICON_TYPES.IMAGE,
          src: images.misc.iconMarketAnalyze,
          className: 'icon_market-analyze'
        }
      },
      {
        text: 'Automate processes',
        icon: {
          type: RESTRICTION_MODAL_ICON_TYPES.IMAGE,
          src: images.misc.iconMarketAutomate,
          className: 'icon_market-automate'
        }
      }
    ],
    button: {
      authenticated: {
        text: 'Request Access'
      },
      unauthenticated: {
        text: 'Compare Editions',
        link: CORPORATE_WEBSITE_COMPARE_EDITIONS_URL
      }
    }
  },
  [RESTRICTION_MODAL_TYPES.ENFORCEMENT_EXPLORER]: {
    title: 'Upgrade to use this feature.',
    text:
      'The Enforcement Explorer helps you stay abreast of enforcement documents issued by your default agencies. Once this feature is enabled, you will not only gain access to the document PDFs, you will also be able to review key information including respondents, details on violations, and penalties without having to drill into each document.',
    boxes: [
      {
        text: 'Monitor any source',
        icon: {
          type: RESTRICTION_MODAL_ICON_TYPES.IMAGE,
          src: images.misc.iconMarketMonitor,
          className: 'icon_market-monitor'
        }
      },
      {
        text: 'Analyze the impact',
        icon: {
          type: RESTRICTION_MODAL_ICON_TYPES.IMAGE,
          src: images.misc.iconMarketAnalyze,
          className: 'icon_market-analyze'
        }
      },
      {
        text: 'Automate processes',
        icon: {
          type: RESTRICTION_MODAL_ICON_TYPES.IMAGE,
          src: images.misc.iconMarketAutomate,
          className: 'icon_market-automate'
        }
      }
    ],
    button: {
      authenticated: {
        text: 'Request Access'
      },
      unauthenticated: {
        text: 'Compare Editions',
        link: CORPORATE_WEBSITE_COMPARE_EDITIONS_URL
      }
    }
  }
};
