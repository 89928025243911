import type * as types from './resources.types';

import * as sort from 'utils/sort';

import lodash from 'lodash';

export const flattenJurisdictions = (
  jurisdictions: types.ResourceJurisdictionFromResponse[],
  parentId: string | number | null
): types.ResourceJurisdictionFromResponseWithParentId[] => {
  return jurisdictions.flatMap(jurisdiction => {
    const parentJurisdiction = {
      ...jurisdiction,
      parentId
    };

    const jurisdictionsWithParentId =
      jurisdiction.children?.map(child => {
        return {
          ...child,
          parentId: jurisdiction.id
        };
      }) ?? [];

    return [
      parentJurisdiction,
      ...flattenJurisdictions(jurisdictionsWithParentId, jurisdiction.id)
    ];
  });
};

export const normalizeRootResource = (
  resources: types.ResourceJurisdictionFromResponse['resources']
): types.NormalizedRootResource | null => {
  if (!resources) {
    return null;
  }

  return {
    id: resources.id,
    jurisdictionId: resources.jurisdiction_id,
    activeCrawls: resources.active_crawls,
    activeDisplay: resources.active_display,
    category: resources.category,
    crawlerClassName: resources.crawler_class_name,
    crawlerLibName: resources.crawler_lib_name,
    daysBetweenCrawls: resources.days_between_crawls,
    devComplexity: resources.dev_complexity,
    jurisdiction: resources.jurisdiction,
    lastCompleted: resources.last_completed,
    lastCrawled: resources.last_crawled,
    lastStatus: resources.last_status,
    name: resources.name,
    rootDocId: resources.root_doc_id,
    rootShelfId: resources.root_shelf_id,
    shortName: resources.short_name
  };
};

export const normalizeResourceChildren = (
  resourceChildren?: types.ResourceChildFromResponse[]
): types.NormalizedResourceChild[] => {
  if (Array.isArray(resourceChildren)) {
    return resourceChildren
      .filter(resourceChild => !resourceChild.deprecated)
      .map(resourceChild => ({
        agencyIds: resourceChild.agency_ids,
        caiCategoryId: resourceChild.cai_category_id,
        category: resourceChild.category,
        citationType: resourceChild.citation_type,
        deprecated: resourceChild.deprecated,
        documentVersionLatest: resourceChild.document_version_latest,
        hasChildren: resourceChild.has_children,
        id: resourceChild.id,
        jurisdiction: resourceChild.jurisdiction,
        officialId: resourceChild.official_id,
        parentId: resourceChild.parent_id,
        pdfUrl: resourceChild.pdf_url,
        pubDate: resourceChild.pub_date,
        summaryText: resourceChild.summary_text,
        title: resourceChild.title,
        webUrl: resourceChild.web_url
      }))
      .sort(sort.naturalSortWithRomanNumerals);
  }

  return [];
};

export const normalizeResourceHierarchy = (
  response: types.ResourceHierarchyResponse
): types.ResourcesMap => {
  const allResources = normalizeResourceChildren(response?.resource_hierarchy?.flat?.() ?? []);

  return Object.entries(lodash.groupBy(allResources, 'parentId')).reduce(
    (resourcesMap, [parentId, resources]) => {
      return {
        ...resourcesMap,
        [parentId]: {
          isLoading: false,
          items: resources
        }
      };
    },
    {}
  );
};

export const normalizeJurisdiction = (
  jurisdiction: types.ResourceJurisdictionFromResponseWithParentId
): types.NormalizedResourcesJurisdiction => {
  return {
    id: jurisdiction.id,
    category: jurisdiction.category,
    name: jurisdiction.name ?? null,
    parentId: jurisdiction?.parentId,
    resources: normalizeRootResource(jurisdiction?.resources)
  };
};

export const normalizeJurisdictions = (
  resourcesResponse: types.ResourcesFromResponse
): types.NormalizedResourcesJurisdiction[] => {
  const flatJurisdictions = flattenJurisdictions(Object.values(resourcesResponse), null);
  return flatJurisdictions.map(normalizeJurisdiction);
};

export const formatJurisdictionOption = (
  jurisdiction: types.NormalizedResourcesJurisdiction
): types.ResourcesJurisdictionOption => {
  return {
    value: jurisdiction.id,
    label: jurisdiction.name
  };
};

export const getResourceParentIds = ({
  resource,
  resources,
  results = []
}: {
  resource: types.NormalizedResourceChild;
  resources: types.ResourcesMap;
  results?: types.NormalizedResourceChild['id'][];
}): types.NormalizedResourceChild['id'][] => {
  const resourcesItems = Object.values(resources).flatMap(({ items }) => items);

  const parentResource = resourcesItems.find(({ id }) => id === resource.parentId);

  if (!parentResource) {
    return [...new Set([...results, resource.parentId, resource.id])];
  }

  return getResourceParentIds({
    resource: parentResource,
    resources: resources,
    results: [...results, resource.parentId, resource.id, parentResource.parentId]
  });
};

export const sortItemsByParentId = (
  resources: [types.ResourceChildFromResponse['id'], types.Resources][],
  allResourceItems: types.NormalizedResourceChild[]
) => {
  const resourceIdMap = resources.map(([parentId]) => parentId);

  const sortedByPartentId = sortResourcesByParentId(resourceIdMap, allResourceItems);

  return sortedByPartentId.map(id => {
    return resources.filter(resource => resource[0] === id)[0];
  });
};

export const sortResourcesByParentId = (
  resources: types.ResourceChildFromResponse['id'][],
  allResourceItems: types.NormalizedResourceChild[]
) => {
  const parentIdMap: {
    [key: types.ResourceChildFromResponse['parent_id']]: types.ResourceChildFromResponse['id'];
  } = {};

  let rootResource = resources.reduce((rootResource, resourceId) => {
    const upperLevelResource = allResourceItems.find(({ id }) => id === resourceId);
    if (upperLevelResource) {
      const parentId = upperLevelResource?.parentId;
      parentIdMap[parentId] = resourceId;
    } else {
      return resourceId;
    }

    return rootResource;
  }, null as types.ResourceChildFromResponse['id'] | null);

  const result: types.ResourceChildFromResponse['id'][] = rootResource ? [rootResource] : [];

  while (true) {
    const nextElement: types.ResourceChildFromResponse['id'] = parentIdMap[rootResource ?? 0];
    if (!nextElement) break;
    result.push(nextElement);
    rootResource = nextElement;
  }

  return result;
};

export const getParentJurisdictionsByChildId = ({
  resourceId,
  jurisdictions,
  foundJurisdictions = []
}: {
  resourceId?: types.NormalizedResourcesJurisdiction['parentId'];
  jurisdictions: types.NormalizedResourcesJurisdiction[];
  foundJurisdictions?: types.NormalizedResourcesJurisdiction[];
}): types.NormalizedResourcesJurisdiction[] => {
  const parentJurisdiction = jurisdictions.find(jurisdiction => {
    if (jurisdiction.id === resourceId) return true;
    if (!jurisdiction?.resources?.rootShelfId && jurisdiction?.resources?.rootDocId) {
      return jurisdiction?.resources?.rootDocId === resourceId;
    }
    return jurisdiction?.resources?.rootShelfId === resourceId;
  });

  if (!parentJurisdiction) {
    return foundJurisdictions;
  }

  if (parentJurisdiction) {
    foundJurisdictions = [parentJurisdiction, ...foundJurisdictions];
  }

  return getParentJurisdictionsByChildId({
    resourceId: parentJurisdiction?.parentId,
    jurisdictions,
    foundJurisdictions
  });
};
