export const DATA_TEST_IDS = {
  CONTAINER: 'approval-annotation-container',
  INPUT_CONTAINER: 'approval-annotation-approvers-input-container',
  INPUT: 'approval-annotation-approvers-input',
  SUBMIT_BUTTON: 'approval-annotation-approvers-submit',
  APPROVER_BUTTONS: 'approval-annotation-approver-buttons',
  REJECT_BUTTON: 'approval-annotation-reject-button',
  APPROVE_BUTTON: 'approval-annotation-approve-button',
  PENDING_APPROVAL_TEXT: 'approval-annotation-pending-approval-text'
};

export enum ERROR_MESSAGES {
  SELECT_ACTIVE_APPROVER = 'The approver’s account is no longer active. Select a new approver.',
  NO_APPROVERS = 'There are no possible approvers.',
  CONTACT_ADMIN = "The approver's account is no longer active. Please contact your organization's admin to change the approver."
}
