import type { ComponentProps } from 'react';
import type { SentenceNoteContentProps } from '../SentenceNoteContent.types';
import type { SentenceNoteAttachmentFile } from 'shared/features/sentenceNotes/sentenceNotes.api.types';
import type { useSentenceNoteContentQueries } from './useSentenceNoteContentQueries';

import * as errorUtils from 'utils/errors';
import * as common from 'common/index';
import * as sentenceNotesApi from 'shared/features/sentenceNotes/sentenceNotes.api';

import { useMutation } from '@tanstack/react-query';
import { useOrganizationLabelsReduxActions } from 'shared/features/labels/hooks';

export const useSentenceNoteContentMutations = ({
  props,
  queries
}: {
  props: Pick<SentenceNoteContentProps, 'id'>;
  queries: ReturnType<typeof useSentenceNoteContentQueries>;
}) => {
  const labelsReduxActions = useOrganizationLabelsReduxActions();

  const saveLabel = useMutation<
    void,
    Error,
    Parameters<ComponentProps<typeof common.LabelInput>['createNewLabel']>[0]
  >({
    mutationFn: async option => {
      await sentenceNotesApi.updateSentenceNoteLabel({
        sentenceNoteId: props.id,
        labelId: option.value,
        labelName: option.label as string,
        bucketId: null
      });

      await labelsReduxActions.triggerLabelsDetailsRefresh();
    },
    onSuccess: () => queries.fetchSentenceNote.refetch(),
    onError: errorUtils.logReactQueryError
  });

  const removeLabel = useMutation<
    void,
    Error,
    Parameters<ComponentProps<typeof common.LabelInput>['removeLabel']>[0]
  >({
    mutationFn: async labelId => {
      await sentenceNotesApi.removeSentenceNoteLabel({
        sentenceNoteId: props.id,
        labelId: labelId
      });

      await labelsReduxActions.triggerLabelsDetailsRefresh();
    },

    onSuccess: () => queries.fetchSentenceNote.refetch(),
    onError: errorUtils.logReactQueryError
  });

  const saveAttachments = useMutation<void, Error, { newFiles: SentenceNoteAttachmentFile[] }>({
    mutationFn: async ({ newFiles }) => {
      await sentenceNotesApi.saveSentenceNoteAttachment({
        sentenceNoteId: props.id,
        files: newFiles
      });
    },

    onSuccess: () => queries.fetchSentenceNote.refetch(),
    onError: errorUtils.logReactQueryError
  });

  const deleteAttachment = useMutation<void, Error, { attachment: SentenceNoteAttachmentFile }>({
    mutationFn: async ({ attachment }) => {
      await sentenceNotesApi.deleteSentenceNoteAttachment({
        sentenceNoteId: props.id,
        maskedFileName: attachment.maskedFileName
      });
    },

    onSuccess: () => queries.fetchSentenceNote.refetch(),
    onError: errorUtils.logReactQueryError
  });

  return {
    saveLabel,
    removeLabel,
    saveAttachments,
    deleteAttachment,

    isLoading:
      saveLabel.isLoading ||
      removeLabel.isLoading ||
      saveAttachments.isLoading ||
      deleteAttachment.isLoading
  };
};
