import PropTypes from 'prop-types';
import { Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import classnames from 'classnames';

const StyledModal = styled(props => <Modal {...props} />)`
  padding: ${props => props.padding};
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);

  .default-modal-header {
    width: ${props => props.width};
    max-width: ${props => props.maxWidth};
    margin: auto;
    background-color: #ffffff;
    display: flex;
    height: 54px;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    background-color: #f2f2f2;

    .default-modal-closeicon {
      &:hover {
        cursor: pointer;
      }
    }

    .default-modal-title {
      font-size: 16px;
    }
  }

  .default-modal-children-container {
    background-color: #ffffff;
    width: ${props => props.width};
    max-width: ${props => props.maxWidth};
    margin: auto;
    padding: 20px 20px 20px 20px;
  }
`;

const DefaultModal = ({
  dataTestId,
  children,
  closeAfterTransition,
  container,
  width,
  customContainer,
  disableAutoFocus,
  disableBackdropClick,
  disableEnforceFocus,
  disableEscapeKeyDown,
  disablePortal,
  disableRestoreFocus,
  disableScrollLock,
  disableClose,
  hideBackdrop,
  keepMounted,
  onBackdropClick,
  onClose,
  onEscapeKeyDown,
  onRendered,
  open,
  className,
  childContainerClassName,
  title,
  maxWidth,
  padding
}) => {
  return (
    <StyledModal
      data-testid={dataTestId}
      closeAfterTransition={closeAfterTransition}
      container={container}
      disableAutoFocus={disableAutoFocus}
      disableBackdropClick={disableBackdropClick}
      disableEnforceFocus={disableEnforceFocus}
      disableEscapeKeyDown={disableEscapeKeyDown}
      disablePortal={disablePortal}
      disableRestoreFocus={disableRestoreFocus}
      disableScrollLock={disableScrollLock}
      hideBackdrop={hideBackdrop}
      keepMounted={keepMounted}
      onBackdropClick={onBackdropClick}
      onClose={onClose}
      onEscapeKeyDown={onEscapeKeyDown}
      onRendered={onRendered}
      open={open}
      width={width}
      className={className}
      maxWidth={maxWidth}
      padding={padding}
    >
      {customContainer ? (
        children
      ) : (
        <>
          <div className="default-modal-header">
            {title && <div className="default-modal-title">{title}</div>}
            {!disableClose && (
              <CloseIcon className="default-modal-closeicon" onClick={onClose} fontSize="large" />
            )}
          </div>
          <div className={classnames(childContainerClassName, 'default-modal-children-container')}>
            {children}
          </div>
        </>
      )}
    </StyledModal>
  );
};

export default DefaultModal;

DefaultModal.propTypes = {
  dataTestId: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  closeAfterTransition: PropTypes.bool,
  container: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  customContainer: PropTypes.bool,
  disableAutoFocus: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  disableEnforceFocus: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  disablePortal: PropTypes.bool,
  disableRestoreFocus: PropTypes.bool,
  disableScrollLock: PropTypes.bool,
  disableClose: PropTypes.bool,
  hideBackdrop: PropTypes.bool,
  keepMounted: PropTypes.bool,
  onBackdropClick: PropTypes.func,
  onClose: PropTypes.func,
  onEscapeKeyDown: PropTypes.func,
  onRendered: PropTypes.func,
  open: PropTypes.bool.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  maxWidth: PropTypes.string,
  padding: PropTypes.string
};

DefaultModal.defaultProps = {
  dataTestId: '',
  closeAfterTransition: null,
  container: null,
  width: '70%',
  customContainer: false,
  disableAutoFocus: null,
  disableBackdropClick: null,
  disableEnforceFocus: null,
  disableEscapeKeyDown: null,
  disablePortal: null,
  disableRestoreFocus: null,
  disableScrollLock: null,
  disableClose: null,
  hideBackdrop: null,
  keepMounted: null,
  onBackdropClick: null,
  onEscapeKeyDown: null,
  onRendered: null,
  childContainerClassName: '',
  className: '',
  padding: '150px 0px 150px 0px'
};
