import { useMemo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import classnames from 'classnames';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';

import {
  useObligationWorkspaceLifecycle,
  useObligationWorkspaceData,
  useObligationWorkspaceHandlers,
  useObligationWorkspaceQueries
} from './hooks';

import './_obligation-workspace.scss';

export const ObligationWorkspace = () => {
  const {
    refs,
    formattedData,
    localState,
    localActions,
    reduxState
  } = useObligationWorkspaceData();

  const handlers = useObligationWorkspaceHandlers({
    localState,
    localActions,
    reduxState,
    formattedData
  });

  const queries = useObligationWorkspaceQueries({
    localActions,
    reduxState,
    onFetchHTMLSuccess: handlers.handleSentenceNoteSelectionInRightPanel
  });

  useObligationWorkspaceLifecycle({
    onSentenceNoteSelectionInRightPanel: handlers.handleSentenceNoteSelectionInRightPanel
  });

  const parsedHTML = useMemo(
    () =>
      ReactHtmlParser(localState.sentenceHtml, { transform: handlers.handleDocumentHTMLTransform }),
    [handlers.handleDocumentHTMLTransform, localState.sentenceHtml]
  );

  return (
    <div
      className={classnames('obligation-workspace__container', {
        'obligation-workspace__container--is-loading': localState.isLoading
      })}
    >
      {queries.fetchHTML.isFetching || queries.fetchAccessibleBucketsCount.isFetching ? (
        <uiLib.Loader size={uiLib.LOADER_SIZES.MEDIUM} withoutBackground={false} />
      ) : (
        <div ref={refs.sentenceContainer} className="obligation-workspace__sentences-container">
          {formattedData.shouldShowOverlay && (
            <uiLib.Tooltip
              isOpen
              isLoading={localState.isAddingSentenceNote}
              anchorEl={formattedData.selectedSentence}
              shouldShowCloseButton
              onCloseButtonClick={handlers.handleTooltipClose}
              placement={uiLib.TOOLTIP_POSITION.TOP}
              title={
                <div
                  className="obligation-workspace__annotation-action"
                  onClick={handlers.handleCreateSentenceNote}
                >
                  {formattedData.isObligation ? 'Add Obligation' : 'Add Annotation'}
                </div>
              }
              classNames={{
                tooltip: classnames('obligation-workspace__tooltip', {
                  'obligation-workspace__tooltip--is-overlapped':
                    localState.isCreateSentenceNoteModalOpen
                })
              }}
            />
          )}
          {parsedHTML}
        </div>
      )}

      {localState.isCreateSentenceNoteModalOpen && (
        <elements.CreateSentenceNoteModal
          isObligation={formattedData.isObligation}
          isCreating={localState.isAddingSentenceNote}
          onCreate={handlers.handleNewSentenceNoteCreation}
          onClose={handlers.handleCreateSentenceNoteModalToggle(false)}
        />
      )}
    </div>
  );
};
