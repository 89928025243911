import { useSelector } from 'react-redux';
import { userHasDocumentAccess } from 'utils/documents/documentsAccess';
import { getDocumentUrl } from 'utils/documents/documentProperties';
import { getUserEmail } from 'shared/features/user/user.selectors';

export const usePremiumContentFormData = ({ didTryToAuth }) => {
  const hasDocumentAccess = userHasDocumentAccess({ document });
  const documentUrl = getDocumentUrl(document);
  const shouldShowForm = !didTryToAuth && !hasDocumentAccess;

  const reduxState = useSelector(state => ({
    currentUserEmail: getUserEmail(state)
  }));

  return {
    formattedData: {
      documentUrl,
      shouldShowForm
    },
    reduxState
  };
};
