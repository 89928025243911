import lodash from 'lodash';

export const normalizeFoldersResponse = response => {
  if (Array.isArray(response?.result)) {
    return {
      folders: response.result,
      totalCount: lodash.isNumber(response.total_count) ? response.total_count : 0,
      subFoldersCount: lodash.isNumber(response.sub_folders_count) ? response.sub_folders_count : 0,
      docsCount: lodash.isNumber(response.docs_count) ? response.docs_count : 0
    };
  }

  return {
    folders: [],
    totalCount: 0,
    subFoldersCount: 0,
    docsCount: 0
  };
};
