import { useSelector } from 'react-redux';
import { getUserAuthenticatedState } from 'shared/features/auth/auth.selectors';

export const useSearchFieldTypeSwitchData = () => {
  const reduxState = useSelector(state => ({
    isAuthenticated: getUserAuthenticatedState(state)
  }));

  return {
    reduxState
  };
};
