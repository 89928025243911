import lodash from 'lodash';
import moment from 'moment';
import { latestSubscription } from './subscriptions';
import * as routes from 'constants/Routes';
import { store } from 'store';
import * as selectors from 'shared/features/auth/auth.selectors';

/**
 *
 * FIXME Don't use this file. Only legacy components use it
 *
 * If you won't to get current auth state use auth.selectors
 *
 */

export const loggedIn = () => {
  const state = store.getState();
  return Boolean(selectors.getUserAuthenticatedState(state));
};

/**
 *
 * Don't use it. This function was created just for the legacy utils.
 * If you want to get current auth state, use selectors inside your componentF
 *
 * Don't use it inside your actions/reducers/selectors! It will cause dependency cycle
 *
 */
export const getJWTToken = () => {
  const state = store.getState();
  return selectors.getJWTToken(state);
};

export const lockoutOnExpiredFreeTrial = (user, subscriptions, props, history, addBanner) => {
  if (lodash.isNil(user) || lodash.isNil(subscriptions)) {
    return;
  }

  const latestSub = latestSubscription(subscriptions);
  const expirationDate = latestSub.expirationDate;
  const today = moment().format('l');
  const expired = moment(expirationDate).isBefore(today);

  if (expired) {
    if (props.location.pathname !== routes.account && props.location.pathname !== routes.checkout) {
      history.push(routes.checkout);

      const bannerContent = (
        <div className="buyNowBanner">
          {`Your free trial has ended as of ${expirationDate}.  Please purchase a subscription.`}
        </div>
      );

      addBanner('error', true, bannerContent, true);
    }
  }
};

export default {
  loggedIn,
  lockoutOnExpiredFreeTrial
};
