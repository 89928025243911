import type { AccountMenuData } from './useAccountMenuData.types';
import type { GlobalState } from 'shared/reducers';

import * as viewSelectors from 'shared/features/view/view.selectors';
import * as authSelectors from 'shared/features/auth/auth.selectors';
import * as subscriptionsSelectors from 'shared/features/subscriptions/subscriptions.selectors';

import { useState } from 'react';
import { useSelector } from 'react-redux';

export const useAccountMenuData = (): AccountMenuData => {
  const [isOpen, setIsOpen] = useState(false);

  const localState: AccountMenuData['localState'] = {
    isOpen
  };

  const localActions: AccountMenuData['localActions'] = {
    setIsOpen
  };

  const reduxState = useSelector<GlobalState, AccountMenuData['reduxState']>(state => {
    return {
      isMobileView: viewSelectors.isMobileView(state),
      isAuthenticated: authSelectors.getUserAuthenticatedState(state),
      isAccountWithin10DaysOfExpiration: subscriptionsSelectors.isAccountWithin10DaysOfExpiration(
        state as never
      )
    };
  });

  return {
    localState,
    localActions,
    reduxState
  };
};
