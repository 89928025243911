import type { StoreDispatch } from 'store';
import type { NormalizedDefaults } from 'shared/features/defaults/defaults.types';

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateCurrentUser as reduxUpdateCurrentUser } from 'shared/features/user/user.actions';
import { updateAlert as reduxUpdateAlert } from 'shared/features/alerts/alerts.actions';
import {
  addViewAlert as reduxAddViewAlert,
  addEditAlert as reduxAddEditAlert,
  showSaveAlertSuccessModal as reduxShowSaveAlertSuccessModal,
  closeFilter as reduxCloseFilter,
  removeViewAlert as reduxRemoveViewAlert,
  removeEditAlert as reduxRemoveEditAlert
} from 'shared/features/view/view.actions';
import { fetchDefaults as reduxFetchDefaults } from 'shared/features/defaults/defaults.actions';

export const useEnforcementFilterReduxActions = () => {
  const dispatch = useDispatch<StoreDispatch>();

  const updateCurrentUser = useCallback(
    (...args: Parameters<typeof reduxUpdateCurrentUser>) => {
      return dispatch(reduxUpdateCurrentUser(...args));
    },
    [dispatch]
  );

  const updateAlert = useCallback(
    (...args: Parameters<typeof reduxUpdateAlert>) => {
      return dispatch(reduxUpdateAlert(...args));
    },
    [dispatch]
  );

  const addEditAlert = useCallback(
    (...args: Parameters<typeof reduxAddEditAlert>) => {
      return dispatch(reduxAddEditAlert(...args));
    },
    [dispatch]
  );

  const addViewAlert = useCallback(
    (...args: Parameters<typeof reduxAddViewAlert>) => {
      return dispatch(reduxAddViewAlert(...args));
    },
    [dispatch]
  );

  const showSaveAlertSuccessModal = useCallback(
    (...args: Parameters<typeof reduxShowSaveAlertSuccessModal>) => {
      return dispatch(reduxShowSaveAlertSuccessModal(...args));
    },
    [dispatch]
  );

  const closeFilter = useCallback(
    (...args: Parameters<typeof reduxCloseFilter>) => {
      return dispatch(reduxCloseFilter(...args));
    },
    [dispatch]
  );

  const removeViewAlert = useCallback(
    (...args: Parameters<typeof reduxRemoveViewAlert>) => {
      return dispatch(reduxRemoveViewAlert(...args));
    },
    [dispatch]
  );

  const removeEditAlert = useCallback(
    (...args: Parameters<typeof reduxRemoveEditAlert>) => {
      return dispatch(reduxRemoveEditAlert(...args));
    },
    [dispatch]
  );

  const fetchDefaults = useCallback(
    async (data: { defaultsOwner: string; teamId?: string; orgId?: number }) => {
      return dispatch(reduxFetchDefaults(data as never) as unknown) as Promise<NormalizedDefaults>;
    },
    [dispatch]
  );

  return {
    updateCurrentUser,
    updateAlert,
    addEditAlert,
    addViewAlert,
    showSaveAlertSuccessModal,
    closeFilter,
    removeViewAlert,
    removeEditAlert,
    fetchDefaults
  };
};
