import { useSelector } from 'react-redux';
import { isMobileView } from 'shared/features/view/view.selectors';
import { getUSStateReducer } from 'shared/features/documents/documents.selectors';
import { useMemo, useState } from 'react';
import {
  getRightPanelOverlayQueryParamsFlag,
  getRightPanelOverlayCloseFlag
} from 'shared/features/rightPanel/rightPanel.selectors';

export const useDocumentSummaryTabData = () => {
  const [path, setPath] = useState([]);
  const [isEditingMetadata, setIsEditingMetadata] = useState(false);

  const localState = {
    path,
    isEditingMetadata
  };

  const localActions = useMemo(
    () => ({
      setPath,
      setIsEditingMetadata
    }),
    [setPath, setIsEditingMetadata]
  );

  const reduxState = useSelector(state => ({
    USState: getUSStateReducer(state),
    isMobile: isMobileView(state),
    shouldOpenOverlayWithoutQueryParams: getRightPanelOverlayQueryParamsFlag(state),
    shouldCloseRightPanelOnOverlayClose: getRightPanelOverlayCloseFlag(state)
  }));

  return {
    localState,
    localActions,
    reduxState
  };
};
