import { useState } from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import { useUpdateEffect } from 'utils/hooks';
import { Select, SELECT_TYPES } from '@compliance.ai/web-components';
import { PAST_NEXT_RELATIVE_DURATIONS, REATIVE_DATE_OPERATORS } from 'constants/RelativeDates';

export const TEST_ID = 'custom-relative-dates-container';

export const RelativeDatePicker = ({ value, setValue }) => {
  const defaultValues = value.split(REATIVE_DATE_OPERATORS.PAST_NEXT_SEPERATOR);
  const [amount, setAmount] = useState(lodash.get(defaultValues, 0));
  const [duration, setDuration] = useState(lodash.get(defaultValues, 1));

  const relativeDurationOptions = [
    { label: PAST_NEXT_RELATIVE_DURATIONS.DAYS, value: PAST_NEXT_RELATIVE_DURATIONS.DAYS },
    { label: PAST_NEXT_RELATIVE_DURATIONS.WEEKS, value: PAST_NEXT_RELATIVE_DURATIONS.WEEKS },
    { label: PAST_NEXT_RELATIVE_DURATIONS.MONTHS, value: PAST_NEXT_RELATIVE_DURATIONS.MONTHS },
    { label: PAST_NEXT_RELATIVE_DURATIONS.YEARS, value: PAST_NEXT_RELATIVE_DURATIONS.YEARS }
  ];

  useUpdateEffect(() => {
    const updatedValue =
      !!amount && !!duration && parseInt(amount, 10) > 0
        ? `${amount}${REATIVE_DATE_OPERATORS.PAST_NEXT_SEPERATOR}${duration}`
        : '';
    setValue(updatedValue);
  }, [amount, duration]);

  return (
    <div className="widget--custom-relative-dates" data-testid={TEST_ID}>
      <input
        type="number"
        placeholder="Enter number"
        onChange={e => setAmount(e.target.value)}
        value={amount}
        min={1}
      />
      <Select
        type={SELECT_TYPES.DEFAULT}
        options={relativeDurationOptions}
        onChange={value => setDuration(value.value)}
        value={duration}
        isClearable={false}
      />
    </div>
  );
};

RelativeDatePicker.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired
};

RelativeDatePicker.defaultProps = {
  value: ''
};
