import type { RenameBucketModalData } from './useRenameBucketModalData.types';
import type { RenameBucketModalProps } from '../RenameBucketModal.types';

import { useMemo, useState } from 'react';

export const useRenameBucketModalData = ({
  props
}: {
  props: Pick<RenameBucketModalProps, 'bucket'>;
}): RenameBucketModalData => {
  const [bucketName, setBucketName] = useState<RenameBucketModalData['localState']['bucketName']>(
    props.bucket?.name ?? ''
  );

  const localState: RenameBucketModalData['localState'] = {
    bucketName
  };

  const localActions: RenameBucketModalData['localActions'] = useMemo(
    () => ({
      setBucketName
    }),
    [setBucketName]
  );

  const formattedData: RenameBucketModalData['formattedData'] = useMemo(
    () => ({
      isRenameBucketButtonDisabled:
        !Boolean(localState.bucketName) || localState.bucketName === props.bucket?.name
    }),
    [localState.bucketName, props.bucket?.name]
  );

  return {
    localState,
    localActions,
    formattedData
  };
};
