export const ERROR_TYPES = {
  TIMEOUT: 'timeout',
  SEVER: 'server',
  NETWORK: 'network',
  UNKNOWN: 'unkown'
};

export const ERROR_MESSAGES = {
  TIMEOUT: 'Timeout Exception',
  SERVER: 'Server Exception',
  NETWORK: 'Network Exception',
  UNKNOWN: 'Unexpected Exception'
};
