import * as apiTypes from './filterGroups.api.types';
import * as reducerTypes from './filterGroups.reducer.types';

export const normalizeFilterGroups = (
  response: apiTypes.FetchFilterGroupsResponse
): reducerTypes.FilterGroup[] => {
  return (
    response?.filter_groups?.map?.(filterGroup => {
      return {
        id: filterGroup.id,
        label: filterGroup.label,
        type: filterGroup.type,
        options: Array.isArray(filterGroup.options) ? filterGroup.options : []
      };
    }) ?? []
  );
};
