import type { FiltersProps, FilterValue, SelectOption } from '@compliance.ai/web-components';

import * as uiLib from '@compliance.ai/web-components';

import { FILTER_KEY } from '../AlertsTableFilters.constants';

export const formatDateValue = (value?: Date | null): string | null => {
  return value ? uiLib.formatDate(value, { format: uiLib.DATE_FORMATS.AMERICAN_DATE }) : null;
};

export const formatDate = (key: FILTER_KEY) => (filterValue: FilterValue) => {
  const value = filterValue as Date;

  if (key) {
    return {
      [key]: formatDateValue(value)
    };
  }

  return {};
};

export const formatSingleSelectValue = (key: FILTER_KEY) => (filterValue: FilterValue) => {
  const value = filterValue as SelectOption;

  if (value?.value) {
    return {
      [key]: value?.value
    };
  }

  return {};
};

export const formatQuery = (key: FILTER_KEY) => (searchValue: string) => {
  if (searchValue) {
    return {
      [key]: searchValue
    };
  }

  return {};
};

export const ALERT_FILTER_VALUES_PARSERS: Record<
  string,
  (value: FilterValue) => Record<string, unknown>
> = {
  [FILTER_KEY.CREATED_BY]: formatSingleSelectValue(FILTER_KEY.CREATED_BY),
  [FILTER_KEY.UPDATED_FROM]: formatDate(FILTER_KEY.UPDATED_FROM),
  [FILTER_KEY.UPDATED_TO]: formatDate(FILTER_KEY.UPDATED_TO)
};

export const ADDITIONAL_ALERT_FILTER_VALUES_PARSERS: Record<
  string,
  (searchValue: string) => Record<string, unknown>
> = {
  [FILTER_KEY.NAME]: formatQuery(FILTER_KEY.NAME)
};

export const formatAlertsQueryParams = (
  values: FiltersProps['values'],
  searchValue: string
): Record<string, unknown> => {
  const formattedQueryParams = Object.entries(values).reduce(
    (formattedQueryParams, [filterKey, filterValue]) => {
      return {
        ...formattedQueryParams,
        ...ALERT_FILTER_VALUES_PARSERS[filterKey](filterValue)
      };
    },
    {}
  );

  return {
    ...formattedQueryParams,
    ...ADDITIONAL_ALERT_FILTER_VALUES_PARSERS[FILTER_KEY.NAME](searchValue)
  };
};
