import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUserAuthenticatedState } from 'shared/features/auth/auth.selectors';
import { getCurrentUserPermissions } from 'shared/features/user/user.selectors';
import { getCurrentFolder } from 'shared/features/folders/folders.selectors';
import { CREATE_FOLDERS } from 'constants/Permissions';
import { hasEditPermission } from 'components/Folders/Folders.helpers';
import { getExportLimit } from 'utils/exportLimit/exportLimit';

export const useDocumentActionsRemoveUserFolderData = ({ props }) => {
  const reduxState = useSelector(state => ({
    isAuthenticated: getUserAuthenticatedState(state),
    userPermissions: getCurrentUserPermissions(state),
    currentFolder: getCurrentFolder(state)
  }));

  const isRestricted = !reduxState.isAuthenticated || !reduxState.userPermissions[CREATE_FOLDERS];
  const isDisabled = useMemo(() => {
    const selectedOverUpperLimit = props.documentIds.length > getExportLimit();
    const hasFolderEditPermission =
      !reduxState.currentFolder || hasEditPermission(reduxState.currentFolder);

    return !(props.documentIds.length > 0 && !selectedOverUpperLimit && hasFolderEditPermission);
  }, [props, reduxState]);

  return {
    formattedData: {
      isRestricted,
      isDisabled
    }
  };
};
