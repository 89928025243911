import PropTypes from 'prop-types';
import classnames from 'classnames';

import './_labelList.scss';

export const TEST_ID = 'label-list-label';

function LabelList({ labels, className }) {
  function scrollToLabel(e) {
    const element = document.getElementById('right-' + e.currentTarget.innerText);
    element.scrollIntoView({ behavior: 'smooth' });
  }

  function formatlabels() {
    const labelList = [];
    labels.forEach((label, i) => {
      const className = i === 0 ? 'label-list-item active' : 'label-list-item';
      labelList.push(
        <div
          key={label}
          data-testid={TEST_ID}
          className={className}
          onClick={e => scrollToLabel(e)}
          id={'left-' + label}
        >
          {label}
        </div>
      );
    });

    return labelList;
  }

  return <div className={classnames('label-list-container', className)}>{formatlabels()}</div>;
}

LabelList.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string
};

export default LabelList;
