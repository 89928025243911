import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';

import { useDiffTabTitleData, useDiffTabTitleHandlers } from './hooks';

import './_diff-tab-title.scss';

export const DiffTabTitle: FC = () => {
  const { reduxState } = useDiffTabTitleData();

  const handlers = useDiffTabTitleHandlers({
    reduxState
  });

  return (
    <div className="diff-tab-title">
      <uiLib.Button type={uiLib.BUTTON_TYPES.LINK_CONTRAST} onClick={handlers.handleGoBack}>
        <uiLib.IconArrowLeft />
        Go Back
      </uiLib.Button>
      <span>Changes for the following documents</span>
    </div>
  );
};
