import { ArrowBack, Close } from '@material-ui/icons';
import { useAddToFolderModalHeaderData } from './hooks';
import './_add-to-folder-modal-header.scss';

export const TEST_ID = 'add-to-folder-modal-header';

export const AddToFolderModalHeader = ({
  currentFolder,
  isAddingFolder,
  folderName,
  onFolderBackClick,
  onFolderNameChange,
  onClose
}) => {
  const { formattedData } = useAddToFolderModalHeaderData({
    props: {
      isAddingFolder,
      folderName,
      onFolderNameChange,
      currentFolder
    }
  });

  return (
    <div className="add-to-folder-modal-header" data-testid={TEST_ID}>
      <div className="add-to-folder-modal-header__content">
        {(currentFolder || isAddingFolder) && (
          <ArrowBack
            className="add-to-folder-modal-header__backicon"
            onClick={onFolderBackClick}
            fontSize="large"
          />
        )}
        <div className="add-to-folder-modal-header__title">{formattedData.title}</div>
      </div>
      <Close
        className="add-to-folder-modal-header__close-icon"
        onClick={onClose}
        fontSize="large"
      />
    </div>
  );
};
