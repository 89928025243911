import type { DocumentsTableSettingsProps } from '../DocumentsTableSettings.types';
import type { SelectOption } from '@compliance.ai/web-components';

import * as constants from '../DocumentsTableSettings.constants';

export const useDocumentsTableSettingsHandlers = ({
  props
}: {
  props: Pick<DocumentsTableSettingsProps, 'value' | 'onChange' | 'rowsCountOptions'>;
}) => {
  const handleRowsCountChange = (option: SelectOption) => {
    props.onChange({
      ...props.value,
      [constants.DOCUMENTS_TABLE_SETTINGS.ROWS_COUNT]:
        option?.value ?? props.rowsCountOptions?.[0]?.value ?? constants.DEFAULT_ROW_COUNT
    });
  };

  return {
    handleRowsCountChange
  };
};
