import type {
  DocumentFromResponse,
  DocumentsResponse
} from 'shared/features/documents/documents.types';

import * as constants from '../ExportDropdown.constants';
import * as queryKeys from 'constants/QueryKeys';
import * as errorUtils from 'utils/errors';

import { useQuery } from '@tanstack/react-query';
import { useDocumentsApi } from 'shared/features/documents/hooks';
import { useExportDropdownData } from './useExportDropdownData';

export const useExportDropdownQueries = ({
  props,
  localActions
}: {
  props: {
    documentIds: DocumentFromResponse['id'][];
    onDocumentsFetchSuccess: (documents: DocumentsResponse['documents']) => void;
  };
  localActions: ReturnType<typeof useExportDropdownData>['localActions'];
}) => {
  const documentsApi = useDocumentsApi();

  const fetchDocuments = useQuery({
    enabled: false,
    queryKey: [
      ...queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.DOCUMENTS][
        queryKeys.QUERY_SECTIONS.EXPORT_ACTION
      ](props.documentIds)
    ],
    queryFn: () => {
      return documentsApi.fetchDocuments({
        doc_id: props.documentIds,
        exclusive_fields: constants.EXCLUSIVE_FIELDS
      });
    },
    retry: false,
    refetchOnWindowFocus: false,
    onError: errorUtils.logReactQueryError,
    onSuccess: response => {
      props.onDocumentsFetchSuccess(response.documents);

      localActions.setSentenceDocumentsCount(
        response.documents.filter(document => document.has_sentences).length
      );
    }
  });

  return {
    fetchDocuments
  };
};
