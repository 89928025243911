import type { FC } from 'react';
import type { SentenceNoteContentProps } from './SentenceNoteContent.types';

import * as common from 'common/index';
import * as permissionsConstants from 'constants/Permissions';
import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';

import { useSentenceNoteContentMutations, useSentenceNoteContentQueries } from './hooks';
import { usePermissions } from 'utils/hooks';

import './_sentence-note-content.scss';

export const SentenceNoteContent: FC<SentenceNoteContentProps> = ({ document, isEditable, id }) => {
  const permissions = usePermissions();

  const queries = useSentenceNoteContentQueries({
    props: {
      id
    }
  });

  const mutations = useSentenceNoteContentMutations({
    props: {
      id
    },
    queries
  });

  return (
    <>
      <div className="sentence-note-content__document-tasks-container">
        <common.WorkflowDetails
          document={document}
          sentenceNoteId={id}
          shouldHideLoaders={queries.fetchSentenceNote.isFetching}
        />
      </div>
      <div className="sentence-note-content__content-container">
        {(!queries.fetchSentenceNote.data || queries.fetchSentenceNote.isFetching) && (
          <uiLib.Loader
            type={uiLib.LOADER_TYPE.SPINNER}
            isContrast
            withoutBackground={!queries.fetchSentenceNote.data}
          />
        )}
        {queries.fetchSentenceNote.data && (
          <>
            {queries.fetchSentenceNote.data.sentenceNote.labels_enabled && (
              <common.LabelInput
                labels={queries.fetchSentenceNote.data.sentenceNote.labels}
                removeLabel={mutations.removeLabel.mutate}
                createNewLabel={mutations.saveLabel.mutate}
                isReadOnly={!permissions[permissionsConstants.CREATE_LABELS] || !isEditable}
                isLoading={mutations.removeLabel.isLoading || mutations.saveLabel.isLoading}
                shouldRenderTitle
                classNames={{
                  container: 'sentence-note-content__label-input'
                }}
              />
            )}
            {queries.fetchSentenceNote.data.sentenceNote.attachments_enabled && (
              <div className="sentence-note-content__attachments">
                <common.GenericAttachments
                  attachments={queries.fetchSentenceNote.data.attachments}
                  saveAttachment={mutations.saveAttachments.mutate}
                  deleteAttachment={mutations.deleteAttachment.mutate}
                  disableEdit={!isEditable}
                />
              </div>
            )}
            {queries.fetchSentenceNote.data.sentenceNote.comments_enabled && (
              <div className="sentence-note-content__comments-container">
                <elements.Comments
                  sentenceNoteId={id}
                  commentThreads={queries.fetchSentenceNote.data.sentenceNote.comment_threads}
                  isEditable={isEditable}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
