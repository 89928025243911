import type { DocumentTaskFromResponse } from 'shared/features/workflow/workflow.types';
import type { WorkflowData } from './useWorkflowData.types';
import type { WorkflowProps } from '../Workflow.types';

import { DONE } from 'constants/DocTasks';

import * as workflowsApi from 'shared/features/workflow/workflow.api';
import * as workflowsHelpers from 'shared/features/workflow/workflow.helpers';

export const areAllTaskDetailsRevealed = ({
  tasksDetailsVisibility,
  documentTasks
}: {
  tasksDetailsVisibility: WorkflowData['localState']['tasksDetailsVisibility'];
  documentTasks: DocumentTaskFromResponse[];
}): boolean => {
  const nonBlockedTasks = documentTasks.filter(
    docTask => !workflowsHelpers.isDocTaskBlocked(docTask)
  );

  if (!nonBlockedTasks.length) {
    return false;
  }

  return nonBlockedTasks.every(task => Boolean(tasksDetailsVisibility[task.id]));
};

export const areAllTasksDone = (documentTasks?: DocumentTaskFromResponse[]): boolean => {
  return Boolean(
    documentTasks?.length && documentTasks?.every(documentTask => documentTask.task_status === DONE)
  );
};

export const formatTasksResponse = ({
  props,
  localState
}: {
  props: Pick<WorkflowProps, 'workflow_task_order_ids'>;
  localState: WorkflowData['localState'];
}) => (
  response: Awaited<ReturnType<typeof workflowsApi.api_fetchDocumentTasks>>
): {
  areAllTaskDetailsRevealed: boolean;
  areAllTasksDone: boolean;
  documentTasks: DocumentTaskFromResponse[];
} => {
  const documentTasks = workflowsHelpers.sortByTaskOrder({
    taskOrders: props.workflow_task_order_ids,
    documentTasks: response.results
  });

  return {
    areAllTaskDetailsRevealed: areAllTaskDetailsRevealed({
      tasksDetailsVisibility: localState.tasksDetailsVisibility,
      documentTasks: documentTasks
    }),
    areAllTasksDone: areAllTasksDone(documentTasks),
    documentTasks: documentTasks
  };
};
