import type { GlobalState } from 'shared/reducers';
import type { AdvancedSearchBuilderData } from './useAdvancedSearchBuilderData.types';

import * as constants from '../AdvancedSearchBuilder.constants';
import * as defaultViewConstants from 'common/Filter/DefaultFiltersViewSelect/DefaultFiltersViewSelect.constants';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Config, ImmutableTree, Utils as QbUtils } from 'react-awesome-query-builder';
import { getAuthorsOptionsForAdvancedSearch } from 'shared/features/authors/authors.selectors';
import {
  getConceptOptions,
  getConceptsGroupedOptions
} from 'shared/features/concepts/concepts.selectors';

export const useAdvancedSearchBuilderData = (): AdvancedSearchBuilderData => {
  const [tree, setTree] = useState<AdvancedSearchBuilderData['localState']['tree']>(null);
  const [config, setConfig] = useState<AdvancedSearchBuilderData['localState']['config']>(null);
  const [dataReady, setDataReady] = useState<AdvancedSearchBuilderData['localState']['dataReady']>(
    false
  );
  const [isPlainTextModalOpen, setIsPlainTextModalOpen] = useState<
    AdvancedSearchBuilderData['localState']['isPlainTextModalOpen']
  >(false);
  const [isConceptsSelection, setIsConceptsSelection] = useState<
    AdvancedSearchBuilderData['localState']['isConceptsSelection']
  >(true);
  const [defaultView, setDefaultView] = useState<
    AdvancedSearchBuilderData['localState']['defaultView']
  >(null);
  const [isLoading, setIsLoading] = useState<AdvancedSearchBuilderData['localState']['isLoading']>(
    false
  );

  const localActions: AdvancedSearchBuilderData['localActions'] = useMemo(
    () => ({
      setTree,
      setConfig,
      setDataReady,
      setIsPlainTextModalOpen,
      setIsConceptsSelection,
      setDefaultView,
      setIsLoading
    }),
    [
      setTree,
      setConfig,
      setDataReady,
      setIsPlainTextModalOpen,
      setIsConceptsSelection,
      setDefaultView,
      setIsLoading
    ]
  );

  const reduxState = useSelector<GlobalState, AdvancedSearchBuilderData['reduxState']>(state => ({
    current_view: state.current_view,
    agencies: state.agencies.items,
    jurisdictions: state.jurisdictions.items,
    sources: state.sources.sources,
    regulations: state.regulations.regulations,
    docTypes: (state.docTypes as Record<
      string,
      AdvancedSearchBuilderData['reduxState']['docTypes']
    >).docTypes,
    concepts: state.concepts,
    conceptGroupOptions: getConceptsGroupedOptions(state),
    conceptOptions: getConceptOptions(state),
    languages: state.languages.languages,
    authorsOptions: getAuthorsOptionsForAdvancedSearch(state),
    defaults: state.defaults,
    orgId: state.current_user?.user?.org_id
  }));

  const conceptOptions = useMemo(() => {
    return reduxState.conceptGroupOptions;
  }, [reduxState.conceptGroupOptions]);

  const isSearchDisabled = useMemo(() => {
    return !dataReady || !QbUtils.queryString(tree as ImmutableTree, config as Config);
  }, [tree, config, dataReady]);

  const formattedData: AdvancedSearchBuilderData['formattedData'] = useMemo(() => {
    return {
      conceptOptions,
      isSearchDisabled,
      selectType: isConceptsSelection
        ? constants.SELECT_TYPES.CONCEPTS
        : constants.SELECT_TYPES.VIEW,
      doOrgDefaultsExist:
        !reduxState.defaults[defaultViewConstants.DEFAULT_FILTERS_VIEW_OPTIONS.ORG_DEFAULTS]
          ?.isFetching &&
        !reduxState.defaults[defaultViewConstants.DEFAULT_FILTERS_VIEW_OPTIONS.ORG_DEFAULTS]
          ?.isUpdating
    };
  }, [conceptOptions, isConceptsSelection, isSearchDisabled, reduxState.defaults]);

  return {
    localState: {
      tree,
      config,
      dataReady,
      isPlainTextModalOpen,
      isConceptsSelection,
      defaultView,
      isLoading
    },
    localActions,
    formattedData,
    reduxState
  };
};
