import React, { memo } from 'react';

import * as common from 'common';

import { connect } from 'react-redux';
import { withHistory, withSKUs, withAppNavigation } from 'utils/hooks';
import lodash from 'lodash';
import classNames from 'classnames';
import queryString from 'utils/query-string';
import { Helmet } from 'react-helmet';
import * as uiLib from '@compliance.ai/web-components';

import * as routes from 'constants/Routes';
import * as appNotificationConstants from 'constants/AppNotification';
import * as defaultSourcesConstants from 'constants/DefaultSources';
import * as skuConstants from 'constants/SKUs';

import auth from 'utils/auth';
import * as browserUtils from 'utils/browser';
import * as analyticsUtils from 'utils/analytics';
import * as localStorageUtils from 'utils/localStorage-utils';
import * as searchUtils from 'utils/search';
import * as dispatchEventUtils from 'utils/dispatchEvent';

import * as userActions from 'shared/features/user/user.actions';
import * as timezoneActions from 'shared/features/timezones/timezones.actions';
import * as languageActions from 'shared/features/languages/languages.actions';
import * as regulationActions from 'shared/features/regulations/regulations.actions';
import * as tagsActions from 'shared/features/tags/tags.actions';
import * as entitiesActions from 'shared/features/entities/entities.actions';
import * as sourcesActions from 'shared/features/sources/sources.actions';
import * as alertActions from 'shared/features/alerts/alerts.actions';
import * as topicsActions from 'shared/features/topics/topics.actions';
import * as expertConnectionActions from 'shared/features/expertConnection/expertConnection.actions';
import * as viewActions from 'shared/features/view/view.actions';
import * as organizationsActions from 'shared/features/organizations/organizations.actions';
import * as jurisdictionsActions from 'shared/features/jurisdictions/jurisdictions.actions';
import * as defaultStateAgenciesActions from 'shared/features/defaultStateAgencies/defaultStateAgencies.actions';
import * as filterGroupsActions from 'shared/features/filterGroups/filterGroups.actions';
import * as documentActions from 'shared/features/documents/documents.actions';
import * as subscriptionActions from 'shared/features/subscriptions/subscriptions.actions';
import * as obligationActions from 'shared/features/obligations/obligations.actions';
import * as errorActions from 'shared/features/error/actions';
import * as agenciesActions from 'shared/features/agencies/agencies.actions';
import * as thresholdActions from 'shared/features/thresholds/thresholds.actions';
import * as premiumContentActions from 'shared/features/premiumContent/premiumContent.actions';
import * as documentFieldsActions from 'shared/features/documentFields/documentFields.actions';
import * as conceptsActions from '../shared/features/concepts/concepts.actions';
import * as defaultsActions from '../shared/features/defaults/defaults.actions';
import * as authActions from '../shared/features/auth/auth.actions';
import * as authorsActions from 'shared/features/authors/authors.actions';
import * as defaultsSelectors from '../shared/features/defaults/defaults.selectors';
import * as authSelectors from 'shared/features/auth/auth.selectors';
import * as env from 'shared/config';
import * as viewConstants from 'shared/features/view/view.constants';

import { store } from '../store';

import UpgradeBanner from './UpgradeBanner';
import { TimelineToolbar } from './TimelineToolbar';
import CookieBanner from './CookieBanner/index';
import AgencyBanner from './AgencyBanner';
import RestrictLegacy from './Restrict';
import { RestrictModal } from './RestrictModal';
import { notificationsEnabled } from 'shared/config';
import { FirstLoginJoyride } from './FirstLoginJoyride/FirstLoginJoyride';

// uncomment, ..., to enable websocket
// let subscribedToNotifications = false;

const DocumentHead = memo(() => {
  const shouldMountNoIndex = new RegExp(/integration|staging/).test(window.location.hostname);
  const shouldMountGoogleCode = new RegExp(/integration|staging|pro/).test(
    window.location.hostname
  );

  return (
    <Helmet>
      {shouldMountNoIndex && <meta name="robots" content="noindex" />}
      {shouldMountGoogleCode && (
        <meta
          name="google-site-verification"
          content="2v5SG_uTdjD_LNX1DBjPB2g4PVbofi49juqKO1wqOfs"
        />
      )}
    </Helmet>
  );
});

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchResults: '',
      isMenuOpen: false
    };

    localStorageUtils.removeAnonymousUserFilter();
  }

  fetchDefaults = () => {
    this.props.fetchRegulations();
    this.props.fetchTopics();
    this.props.fetchConcepts();
    this.props.fetchDefaults({ defaultsOwner: defaultSourcesConstants.DEFAULT_TYPES.MY_DEFAULTS });
    this.props.fetchFollowedEntities();
    this.props.fetchDefaultSources().then(s => {
      if (!this.props.isAuthenticated) {
        this.props.followDefaultSources(s);
      }
    });
  };

  componentDidMount() {
    this.props.fetchDocumentTypes();
    this.props.fetchDocumentTypesUpdates();
    this.props.fetchAllAgencies();
    this.props.fetchAuthors();
    this.props.fetchAllJurisdictions(true);
    this.props.fetchCurrentUser().then(() => this.props.fetchAppNotifications());
    this.props.fetchPremiumContentSources();
    this.props.fetchTags();
    this.props.fetchSubscriptions().then(response => {
      const currentSub =
        response &&
        response.subscriptions &&
        response.subscriptions.find(sub => sub.latest && sub.status === 'active');
      if (currentSub) {
        localStorageUtils.safelySetLocalStorageData('userType', 'premium');
        localStorageUtils.safelySetLocalStorageData('subscription', currentSub.name);
      } else {
        localStorageUtils.safelySetLocalStorageData('userType', 'notLoggedIn');
        localStorageUtils.safelySetLocalStorageData('subscription', 'basic');
      }
      dispatchEventUtils.sendBrowserAgnosticEvent(window, 'salesiq');
    });
    this.fetchDefaults();
    this.props.fetchUserPreferences();
    this.props.fetchTimezones();
    this.props.fetchLanguages();
    this.props.fetchDefaultStateAgencies();
    this.props.fetchObligationHighThreshold();
    this.props.fetchExpertConnectionData();
    this.props.fetchTopicThresholdOptions();
    this.props.fetchFilterGroups();

    this.updateDimensions();
    if (notificationsEnabled) {
      this.props.fetchAllAlerts();
    }
    window.addEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.refreshTrigger !== this.props.refreshTrigger) {
      this.fetchDefaults();
      this.props.fetchCurrentUser();
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    // check for auth errors
    // for now, just force the user to logout
    // Brought this code back after regression issues around expired JWT tokens not logging user out
    if (nextProps.errors && nextProps.errors.auth && nextProps.errors.auth.length > 0) {
      store.dispatch(authActions.signOut()).then(() => {
        window.location.reload();
      });
    }
    // check to see if freeTrial is expired and redirect if it is
    auth.lockoutOnExpiredFreeTrial(
      this.user,
      this.subscriptions,
      nextProps,
      this.props.history,
      nextProps.addBanner
    );

    if (this.props.subscriptions.isFetching && !nextProps.subscriptions.isFetching) {
      this.subscriptions = nextProps.subscriptions.subscriptions;
    }

    if (
      !this.props.userHasSKU(skuConstants.SKU_RESTRICTION_TYPES.BYOC) &&
      nextProps.userHasSKU(skuConstants.SKU_RESTRICTION_TYPES.BYOC)
    ) {
      this.props.fetchDocFieldsOptions();
    }

    if (this.props.current_user.isFetching && !nextProps.current_user.isFetching) {
      // fetch tasks if member of an organization
      if (
        this.props.isAuthenticated &&
        nextProps.current_user.user.organization &&
        nextProps.current_user.user.organization.id
      ) {
        this.props.fetchOrganization(nextProps.current_user.user.organization.id);
      }

      // set GA user id
      const user_id = !lodash.isNil(lodash.get(nextProps.current_user, 'user.id'))
        ? lodash.get(nextProps.current_user, 'user.id').toString()
        : '';

      const is_internal = !lodash.isNil(nextProps.current_user.user.is_internal_user)
        ? nextProps.current_user.user.is_internal_user.toString()
        : '';

      // track users by role
      const roles = !lodash.isNil(nextProps.current_user.user.roles)
        ? nextProps.current_user.user.roles.toString()
        : '';

      analyticsUtils.setGoogleAnalyticsDimension({
        dimensionKey: 'userId',
        dimensionValue: user_id
      });
      analyticsUtils.setGoogleAnalyticsDimension({
        dimensionKey: 'dimension1',
        dimensionValue: user_id
      });
      analyticsUtils.setGoogleAnalyticsDimension({
        dimensionKey: 'dimension2',
        dimensionValue: is_internal
      });
      analyticsUtils.setGoogleAnalyticsDimension({
        dimensionKey: 'dimension4',
        dimensionValue: roles
      });

      this.user = nextProps.current_user.user;

      analyticsUtils.mixpanel_identify(this.user.email);
      analyticsUtils.mixpanel_people_set({
        $email: this.user.email,
        user_id,
        is_internal,
        roles
      });

      /*
        In the case of a new user, the email notification settings
        need to be set upon very first login
      */
      if (!this.user.properties.email_updates) {
        const user_email_settings = {};

        user_email_settings.properties = {
          email_updates: {
            topics_weekly: true,
            topics_daily: false,
            agency_daily: false,
            agency_weekly: true,
            enforcements_weekly: true,
            enforcements_daily: false,
            task_email_sending_type: 'daily',
            self_tasks_assigned: true,
            self_tasks_due_24_hours: true,
            self_tasks_past_due: true,
            self_tasks_complete: false,
            organization_tasks_assigned: true,
            organization_tasks_due_24_hours: true,
            organization_tasks_past_due: true,
            organization_tasks_complete: false,
            shared_alerts: true,
            shared_folders: true,
            release_notes: true
          }
        };
        this.props.updateCurrentUser(this.user.email, user_email_settings);
      }
    }

    const error_banner_in_view =
      this.props.current_view.banner.type === 'error' && this.props.current_view.banner.display;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    if (window.document.body.clientWidth < 768) {
      this.props.setMobile(true);
    } else {
      this.props.setMobile(false);
    }
  };

  _getUserStyle() {
    if (this.props.current_user.isReady) {
      let user_style = localStorage.getItem('user_style');

      user_style = user_style ?? lodash.get(this.props.current_user, 'user.properties.user_style');

      if (user_style) {
        return user_style;
      }
    }

    return null;
  }

  handleActivePage = () => {
    if (this.props.current_view.search_params.offset === '0') {
      return 1;
    }
    const offset = this.props.current_view.search_params.offset;
    const limit = this.props.current_view.search_params.limit;
    return parseInt(offset / limit, 10) + 1;
  };

  handleGoogleResultsClick = () => {
    // add google search query parameter from URL, e.g. '&q=my+search+criteria'
    this.setState({ searchResults: 'google' });
    const { search_term } = this.props.current_view;
    let searchCriteria = '';
    if (search_term) {
      searchCriteria = search_term.split(' ').join('+');
    }
    const { pathname } = this.props.location;
    const parsedQuery = queryString.parse(this.props.location.search);
    parsedQuery.q = searchCriteria;
    this.props.history.push(`${pathname}?${queryString.stringify(parsedQuery)}`);
    // window.location.reload();
  };

  handleComplianceResultsClick = () => {
    // remove google search query parameter from URL, e.g. '&q=my+search+criteria'
    this.setState({ searchResults: 'compliance' });
    const parsedQuery = queryString.parse(this.props.location.search);
    const { pathname } = this.props.location;
    if ('q' in parsedQuery) {
      delete parsedQuery.q;
    }
    this.props.history.push(`${pathname}?${queryString.stringify(parsedQuery)}`);
  };

  doNotificationsExist = () => {
    return !!document.getElementById(appNotificationConstants.APP_NOTIFICATION_CONTAINER_ID);
  };

  openMenu = () => {
    this.setState({ isMenuOpen: true });
  };

  closeMenu = () => {
    this.setState({ isMenuOpen: false });
  };

  render() {
    //redux state that is needed before app is loaded and requests for documents can be sent
    if (!this.props.docTypes.docTypes) {
      return null;
    }
    // look through the children to apply a classname to the root element for css purposes
    const container_classes = ['auth'];

    React.Children.forEach(this.props.children, component => {
      // check for classname defined as a static property
      if (component.type && component.type.className) {
        container_classes.push(component.type.className);
      }
    });

    const user_style = this._getUserStyle();

    if (user_style) {
      container_classes.push(user_style);
    }

    let warningModal = null;

    if (this.props.current_view.warning_modal === 'pswrd') {
      const openSettingsCloseModal = e => {
        e.preventDefault();
        this.props.history.push(routes.account, { fromPasswordModal: true });
        this.props.closeWarningModal();
      };

      warningModal = (
        <uiLib.Modal
          isOpen={this.props.current_view.warning_modal === 'pswrd'}
          onClose={() => this.props.closeWarningModal()}
          title="Welcome back!"
        >
          <div id="campaign-user-warning-menu">
            <p>
              We have improved our password security. Please update your password now. Thank you!
            </p>
            <div className="campaign-user-warning-buttons">
              <uiLib.Button type={uiLib.BUTTON_TYPES.PRIMARY} onClick={openSettingsCloseModal}>
                Change Password
              </uiLib.Button>
            </div>
          </div>
        </uiLib.Modal>
      );
    }

    let agencyBanner = null;
    let basicSearch = false;
    let googleSearch = false;
    const { pathname, search } = this.props.location;
    const parsedQuery = queryString.parse(search);

    const isDocumentRoute = [routes.content, routes.news, routes.timeline].includes(pathname);
    const isTimelinePage = pathname === routes.timeline;
    const isNewsPage = pathname === routes.news;
    const isEnforcementPage = pathname === routes.enforcementExplorer;
    const isSearchPage = pathname === routes.content;
    const isTimelineView = parsedQuery.view === viewConstants.VIEW_KEY.TIMELINE;
    const isNewsView = parsedQuery.view === viewConstants.VIEW_KEY.NEWS;

    if (env.shouldShowNewSearch ? isDocumentRoute : isSearchPage) {
      if (
        (env.shouldShowNewSearch
          ? !isTimelinePage && !isNewsPage
          : !isTimelineView && !isNewsView) &&
        !parsedQuery.q
      ) {
        basicSearch = true;
        googleSearch = false;
      } else {
        if (
          env.shouldShowNewSearch ? !isTimelinePage && !isNewsPage : !isTimelineView && !isNewsView
        ) {
          googleSearch = true;
          basicSearch = false;
        } else {
          googleSearch = false;
          basicSearch = false;
        }
      }
    }

    // FIXME This component is one of the most horrible things I've ever seen in my entire career.
    // FIXME This whole monster needs to be rewritten.
    const complianceTabClass = 'q' in parsedQuery ? 'tab' : 'tab active';
    const googleTabClass = 'q' in parsedQuery ? 'tab active' : 'tab';

    // show the header for search queries, proposed filters and related doc filters
    if (
      ((isSearchPage && !isTimelineView && !isNewsView) || isEnforcementPage) &&
      parsedQuery.autosuggest_filter === 'agencies'
    ) {
      agencyBanner = <AgencyBanner />;
    }

    const app_view = searchUtils.get_search_view(
      this.props.current_view.search_params,
      this.props.location
    );

    const centerContainerClasses = {
      'center-container': true,
      // hack to keep bottom of resources and search from cutting off last element
      'resource-code-scroll': app_view.section === searchUtils.APP_VIEW_SECTION_RESOURCES,
      'search-results-scroll': app_view.section === searchUtils.APP_VIEW_SECTION_SEARCH,
      'timeline-scroll': [
        searchUtils.APP_VIEW_SECTION_TIMELINE,
        searchUtils.APP_VIEW_SECTION_ENFORCEMENTEXPLORER
      ].includes(app_view.section),
      'news-scroll': app_view.section === searchUtils.APP_VIEW_SECTION_NEWS,
      'folders-scroll': app_view.section === searchUtils.APP_VIEW_SECTION_USER_FOLDERS,
      'google-no-scroll': googleSearch
    };

    //an ie10 style hack
    const ie10Style = () => {
      if (browserUtils.isIE10()) {
        const browserWidth = document.body.clientWidth;
        const leftPanelWidth = 96;
        const rightPanelWidth = 335;
        if (this.props.location.pathname === routes.dashboard) {
          return null;
        }
        return {
          'max-width': browserWidth - (leftPanelWidth + rightPanelWidth)
        };
      }
      return null;
    };

    const searchTabs = (
      <div className="tab-container">
        <div className={complianceTabClass} onClick={this.handleComplianceResultsClick}>
          <span>Compliance.ai Search Results</span>
        </div>
        <div className={googleTabClass} onClick={this.handleGoogleResultsClick}>
          <span>Google Search Results</span>
        </div>
      </div>
    );

    return (
      <common.AppNavigation>
        <div className={classNames(container_classes)} id="top">
          <DocumentHead />
          <RestrictLegacy />
          <RestrictModal />
          {warningModal}
          <div className="outer-container">
            <div
              className={classNames('main-container', {
                'main-container--is-top-bar-hidden': !this.props.isTopBarVisible,
                'main-container--is-nav-bar-hidden':
                  !this.props.isNavBarVisible || this.props.current_view.inMobile
              })}
            >
              <div style={ie10Style()} className={classNames(centerContainerClasses)}>
                {[
                  routes.content,
                  ...(env.shouldShowNewSearch ? [] : [routes.enforcementExplorer])
                ].includes(this.props.location.pathname) ? (
                  <>
                    <UpgradeBanner />
                    {!env.shouldShowNewSearch && <TimelineToolbar />}
                  </>
                ) : null}
                <div className="content-container">
                  {agencyBanner}
                  {basicSearch && parsedQuery.search_query ? searchTabs : null}
                  {googleSearch ? searchTabs : null}
                  {this.props.children}
                </div>
              </div>
            </div>
          </div>
          {this.props.location.pathname !== routes.legal && (
            <CookieBanner loggedIn={auth.loggedIn()} />
          )}
          <FirstLoginJoyride />
        </div>
      </common.AppNavigation>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: authSelectors.getUserAuthenticatedState(state),
    current_user: state.current_user,
    current_view: state.current_view,
    document_details: state.document_details,
    agencies: state.agencies,
    recent_activity: state.recent_activity,
    errors: state.errors,
    subscriptions: state.subscriptions,
    filtered_mention: state.filtered_mention,
    alerts: state.alerts,
    docTypes: state.docTypes,
    refreshTrigger: defaultsSelectors.getUserDefaultsRefreshTrigger(state)
  };
};

const ReduxApp = connect(mapStateToProps, {
  fetchDocumentTypes: documentActions.fetchDocumentTypes,
  fetchDocumentTypesUpdates: documentActions.fetchDocumentTypesUpdates,
  fetchRecentActivity: documentActions.fetchRecentActivity,
  fetchCurrentUser: userActions.fetchCurrentUser,
  updateCurrentUser: userActions.updateCurrentUser,
  fetchAppNotifications: userActions.fetchAppNotifications,
  fetchUserPreferences: userActions.fetchUserPreferences,
  fetchConcepts: conceptsActions.fetchConcepts,
  fetchTags: tagsActions.fetchTags,
  closeWarningModal: viewActions.closeWarningModal,
  setMobile: viewActions.setMobile,
  addBanner: viewActions.addBanner,
  clearErrors: errorActions.clearErrors,
  fetchSubscriptions: subscriptionActions.fetchSubscriptions,
  notificationsUpdate: alertActions.notificationsUpdate,
  fetchAllAlerts: alertActions.fetchAllAlerts,
  fetchDefaultSources: sourcesActions.fetchDefaultSources,
  followDefaultSources: sourcesActions.followDefaultSources,
  fetchAllAgencies: agenciesActions.fetchAllAgencies,
  fetchAllJurisdictions: jurisdictionsActions.fetchAllJurisdictions,
  fetchOrganization: organizationsActions.fetchOrganization,
  fetchRegulations: regulationActions.fetchRegulations,
  fetchFollowedEntities: entitiesActions.fetchFollowedEntities,
  fetchTopics: topicsActions.fetchTopics,
  fetchDefaults: defaultsActions.fetchDefaults,
  fetchFilterGroups: filterGroupsActions.fetchFilterGroups,
  fetchObligationHighThreshold: obligationActions.fetchObligationHighThreshold,
  fetchTopicThresholdOptions: thresholdActions.fetchTopicThresholdOptions,
  fetchPremiumContentSources: premiumContentActions.fetchPremiumContentSources,
  fetchTimezones: timezoneActions.fetchTimezones,
  fetchExpertConnectionData: expertConnectionActions.fetchExpertConnectionData,
  fetchLanguages: languageActions.fetchLanguages,
  fetchDefaultStateAgencies: defaultStateAgenciesActions.fetchDefaultStateAgencies,
  fetchDocFieldsOptions: documentFieldsActions.fetchDocFieldsOptions,
  fetchAuthors: authorsActions.fetchAuthors
})(withHistory(withSKUs(withAppNavigation(App))));

export default ReduxApp;
