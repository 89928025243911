import lodash from 'lodash';
import * as exportConstants from 'constants/Export';
import * as documentPropertiesConstants from 'constants/DocumentProperties';
import * as constants from '../ExportModal.constants';
import * as skusConstants from 'constants/SKUs';
import { useState, useMemo } from 'react';
import { useSKUs } from 'utils/hooks';

export const useExportModalData = ({ props }) => {
  const { isAnnotationExport } = props;

  const skus = useSKUs();

  const isInternalUser = skus.userHasSKU(skusConstants.SKU_RESTRICTION_TYPES.INTERNAL_DATA);

  const basicColumns = useMemo(
    () => [
      ...constants.BASIC_EXPORT_COLUMNS,
      ...(isInternalUser ? [constants.PERMISSION_BASED_COLUMNS] : [])
    ],
    [isInternalUser]
  );

  const [exportMode, setExportMode] = useState(undefined);
  const [selectedColumns, setSelectedColumns] = useState(
    isAnnotationExport ? [...basicColumns, ...constants.ANNOTATIONS_COLUMNS] : basicColumns
  );

  const localActions = useMemo(
    () => ({
      setExportMode,
      setSelectedColumns
    }),
    [setExportMode, setSelectedColumns]
  );

  const formattedData = useMemo(() => {
    const { contentType, documentsCount, annotationCount } = props;

    const isExportDisabled = lodash.isEmpty(selectedColumns);

    const basicColumnsToDisplay = [
      constants.BASIC_EXPORT_COLUMNS_ONE,
      constants.BASIC_EXPORT_COLUMNS_TWO,
      [
        ...constants.BASIC_EXPORT_COLUMNS_THREE,
        ...(isInternalUser ? [constants.PERMISSION_BASED_COLUMNS] : [])
      ]
    ];

    const apiColumns = [
      ...basicColumns,
      ...constants.SENTENCES_COLUMNS,
      ...constants.ANNOTATIONS_COLUMNS,
      documentPropertiesConstants.ANNOTATION_SINGLE_FILE
    ]
      .filter(
        column =>
          Boolean(selectedColumns.includes(column)) &&
          Boolean(documentPropertiesConstants.API_KEYS[column])
      )
      .map(column => documentPropertiesConstants.API_KEYS[column]);

    const title = `Export to ${exportConstants.CONTENT_DISPLAY_NAMES[contentType]}`;

    const defaultHeaderText = `${documentsCount} document${
      documentsCount > 1 ? 's have' : ' has'
    } been selected for export: Select the data fields you want to include in the ${contentType}:`;
    const annotationExportHeaderText = `${annotationCount} annotations found in ${documentsCount} documents have been selected for export: Select the data fields you want to include in the ${contentType}`;
    const headerText = isAnnotationExport ? annotationExportHeaderText : defaultHeaderText;

    return { isExportDisabled, apiColumns, title, headerText, basicColumns, basicColumnsToDisplay };
  }, [props, selectedColumns, isInternalUser, basicColumns, isAnnotationExport]);

  return {
    localState: { exportMode, selectedColumns },
    localActions,
    formattedData
  };
};
