export const DEFAULT_TYPES = {
  ORGANIZATION_DEFAULTS: 'organization_defaults',
  TEAM_DEFAULTS: 'team_defaults',
  MY_DEFAULTS: 'my_defaults',
  PRE_BPM_DEFAULTS: 'pre_bpm_defaults'
};

export const TAB_NAMES = {
  TOPICS: 'topics',
  REGULATORY_SOURCES: 'regulatory_sources',
  MAINSTREAM_NEWS: 'mainstream_news',
  DOCUMENT_TYPES: 'document_types',
  REGULATIONS: 'regulations'
};
