import type { JurisdictionsSelectHandlers } from './useJurisdictionsSelectHandlers.types';
import type { JurisdictionsSelectProps } from '../JurisdictionsSelect.types';
import type { JurisdictionsSelectData } from './useJurisdictionsSelectData.types';
import type { SelectOption, WithRequired } from '@compliance.ai/web-components';

import { getPinnedOption } from 'utils/select-utils';

export const useJurisdictionsSelectHandlers = ({
  props,
  reduxState
}: {
  props: Pick<JurisdictionsSelectProps, 'onChange'>;
  reduxState: JurisdictionsSelectData['reduxState'];
}): JurisdictionsSelectHandlers => {
  const handleChange: JurisdictionsSelectHandlers['handleChange'] = options => {
    const pinnedOption = getPinnedOption(options as SelectOption[]);

    if (pinnedOption) {
      props.onChange?.(reduxState.groupedOptions[pinnedOption.id as number]);
    } else {
      props.onChange?.(
        options as Parameters<WithRequired<JurisdictionsSelectProps, 'onChange'>['onChange']>[0]
      );
    }
  };

  return {
    handleChange
  };
};
