export const REDUCER_NAME = 'current_user';

export enum DOCS_TABLES_KEYS {
  RELATED_DOCS = 'addRelatedDocsTable'
}

export enum RELATED_DOCS_TABLE_SETTINGS_KEYS {
  LIMIT = 'limit'
}

export enum ORGANIZATION_DOCUMENTS_TABLE_SETTINGS_KEYS {
  LIMIT = 'limit'
}

export enum ORGANIZATION_DOCUMENT_IMPORTS_TABLE_SETTINGS_KEYS {
  LIMIT = 'limit'
}
