import lodash from 'lodash';
import {
  api_getDocTypes,
  api_getDocTypesUpdates,
  api_fetchDocDiff,
  api_fetchAggregateEnforcements,
  api_getEnforcements,
  api_fetchGoogleResults,
  api_getDocuments,
  api_updateDocuments,
  api_getDocument,
  api_fetchRecentActivity,
  api_getDocumentsBasic,
  api_fetchSentences,
  api_getDocsForIFrame,
  api_getDocumentsPost
} from './documents.api';
import {
  DEFAULT_DOCUMENT_SORT_KEY,
  DEFAULT_DASH_COVID_PARAMS,
  DEFAULT_DASH_REGULATION_PARAMS,
  DEFAULT_DASH_ALERT_WIDGET_PARAMS
} from './documents.helper';
import { DEFAULT_TYPES } from 'constants/DefaultSources';
import { getFollowedAgenciesIds } from 'shared/features/defaults/defaults.selectors';

import { recieveFollowedCategories } from 'shared/features/user/user.actions';
import { extractCategoriesFromMetacategories } from 'shared/features/documents/documents.selectors';
import { getUserAuthenticatedState } from '../auth/auth.selectors';

export const FETCH_SENTENCES_REQUEST = 'FETCH_SENTENCES_REQUEST';
export const FETCH_SENTENCES_SUCCESS = 'FETCH_SENTENCES_SUCCESS';
export const FETCH_SENTENCES_FAILURE = 'FETCH_SENTENCES_FAILURE';
export const RECEIVE_DOC_TYPES = 'RECEIVE_DOC_TYPES';
export const RECEIVE_DOC_TYPES_UPDATES_NOTIFICATION = 'RECEIVE_DOC_TYPES_UPDATES_NOTIFICATION';
export const REQUEST_AGGREGATE_ENFORCEMENTS = 'REQUEST_AGGREGATE_ENFORCEMENTS';
export const RECEIVE_AGGREGATE_ENFORCEMENTS = 'RECEIVE_AGGREGATE_ENFORCEMENTS';
export const REQUEST_ENFORCEMENTS = 'REQUEST_ENFORCEMENTS';
export const RECEIVE_ENFORCEMENTS = 'RECEIVE_ENFORCEMENTS';
export const REQUEST_GOOGLE_RESULTS = 'REQUEST_GOOGLE_RESULTS';
export const RECEIVE_GOOGLE_RESULTS = 'RECEIVE_GOOGLE_RESULTS';
export const CLEAR_DOCUMENTS = 'CLEAR_DOCUMENTS';
export const REQUEST_DOCUMENTS_FOR_DASH_CCPA = 'REQUEST_DOCUMENTS_FOR_DASH_CCPA';
export const RECEIVE_DOCUMENTS_FOR_DASH_CCPA = 'RECEIVE_DOCUMENTS_FOR_DASH_CCPA';
export const REQUEST_DOCUMENTS_FOR_DASH_COVID = 'REQUEST_DOCUMENTS_FOR_DASH_COVID';
export const RECEIVE_DOCUMENTS_FOR_DASH_COVID = 'RECEIVE_DOCUMENTS_FOR_DASH_COVID';
export const REQUEST_DOCUMENTS_FOR_DASH_REGULATION = 'REQUEST_DOCUMENTS_FOR_DASH_REGULATION';
export const RECEIVE_DOCUMENTS_FOR_DASH_REGULATION = 'RECEIVE_DOCUMENTS_FOR_DASH_REGULATION';
export const REQUEST_DOCUMENTS_FOR_DASH_ALERT_WIDGET = 'REQUEST_DOCUMENTS_FOR_DASH_ALERT_WIDGET';
export const RECEIVE_DOCUMENTS_FOR_DASH_ALERT_WIDGET = 'RECEIVE_DOCUMENTS_FOR_DASH_ALERT_WIDGET';
export const REQUEST_DOCUMENTS = 'REQUEST_DOCUMENTS';
export const REQUEST_DOCUMENTS_BEFORE = 'REQUEST_DOCUMENTS_BEFORE';
export const REQUEST_DOCUMENTS_AFTER = 'REQUEST_DOCUMENTS_AFTER';
export const RECEIVE_DOCUMENTS = 'RECEIVE_DOCUMENTS';
export const FAIL_DOCUMENTS = 'FAIL_DOCUMENTS';
export const REQUEST_DOCUMENT = 'REQUEST_DOCUMENT';
export const RECEIVE_DOCUMENT = 'RECEIVE_DOCUMENT';
export const RECEIVE_DOCUMENTS_BEFORE = 'RECEIVE_DOCUMENTS_BEFORE';
export const RECEIVE_DOCUMENTS_AFTER = 'RECEIVE_DOCUMENTS_AFTER';
export const REQUEST_FULL_DOCUMENTS = 'REQUEST_FULL_DOCUMENTS';
export const RECEIVE_FULL_DOCUMENTS = 'RECEIVE_FULL_DOCUMENTS';
export const REQUEST_RELATED_DOCUMENT_COUNT = 'REQUEST_RELATED_DOCUMENT_COUNT';
export const RECEIVE_RELATED_DOCUMENT_COUNT = 'RECEIVE_RELATED_DOCUMENT_COUNT';
export const ADD_DOCS_TO_DIFF = 'ADD_DOCS_TO_DIFF';
export const RECEIVE_DOCUMENT_COMMENTS_COUNT = 'RECEIVE_DOCUMENT_COMMENTS_COUNT';
export const REQUEST_DOCUMENT_DETAILS = 'REQUEST_DOCUMENT_DETAILS';
export const RECEIVE_DOCUMENT_DETAILS = 'RECEIVE_DOCUMENT_DETAILS';
export const FAIL_DOCUMENT_DETAILS = 'FAIL_DOCUMENT_DETAILS';
export const REQUEST_SIMPLE_DOCUMENTS = 'REQUEST_SIMPLE_DOCUMENTS';
export const RECEIVE_SIMPLE_DOCUMENTS = 'RECEIVE_SIMPLE_DOCUMENTS';
export const MARK_DOCUMENT_BOOKMARKED = 'MARK_DOCUMENT_BOOKMARKED';
export const SET_DOCUMENT_SAVED_FOLDER_COUNT = 'SET_DOCUMENT_SAVED_FOLDER_COUNT';
export const RESET_DOCUMENT_DETAILS = 'RESET_DOCUMENT_DETAILS';
export const REQUEST_SEARCH_RESULTS_RELEVANCE = 'REQUEST_SEARCH_RESULTS_RELEVANCE';
export const RECEIVE_SEARCH_RESULTS_RELEVANCE = 'RECEIVE_SEARCH_RESULTS_RELEVANCE';
export const FAIL_SEARCH_RESULTS_RELEVANCE = 'FAIL_SEARCH_RESULTS_RELEVANCE';
export const REQUEST_RESOURCE_CODE = 'REQUEST_RESOURCE_CODE';
export const RECEIVE_RESOURCE_CODE = 'RECEIVE_RESOURCE_CODE';
export const REQUEST_RECENT_ACTIVITY = 'REQUEST_RECENT_ACTIVITY';
export const RECEIVE_RECENT_ACTIVITY = 'RECEIVE_RECENT_ACTIVITY';

function mungeDocumentResponses(extra_params_list, responses) {
  const data = {};

  extra_params_list.forEach((extra_params, i) => {
    data[extra_params.sort] = {
      documents: responses[i].documents,
      count: responses[i].count,
      offsets: responses[i].offsets
    };
  });

  return data;
}

export function makeDocumentRequests(commonParams, listOfParams, rnToken) {
  return listOfParams.map(params => {
    return api_getDocumentsPost(
      {
        ...commonParams,
        ...params
      },
      rnToken
    );
  });
}

function recieveDocTypes(docTypes) {
  return {
    type: RECEIVE_DOC_TYPES,
    docTypes
  };
}

function receiveDocTypesUpdates(docTypesUpdatesNotification) {
  return {
    type: RECEIVE_DOC_TYPES_UPDATES_NOTIFICATION,
    docTypesUpdatesNotification
  };
}

function requestAggregateEnforcements() {
  return {
    type: REQUEST_AGGREGATE_ENFORCEMENTS
  };
}

function receiveAggregateEnforcements(aggregateEnforcements) {
  return {
    type: RECEIVE_AGGREGATE_ENFORCEMENTS,
    aggregateEnforcements
  };
}

function requestEnforcements() {
  return {
    type: REQUEST_ENFORCEMENTS
  };
}

function receiveEnforcements(response) {
  return {
    type: RECEIVE_ENFORCEMENTS,
    payload: response
  };
}

function requestGoogleResults() {
  return {
    type: REQUEST_GOOGLE_RESULTS
  };
}

function receiveGoogleResults(response) {
  return {
    type: RECEIVE_GOOGLE_RESULTS,
    payload: response
  };
}

function receiveDocumentsAfter(params, extraParamsList, responses) {
  const data = mungeDocumentResponses(extraParamsList, responses);

  return {
    type: RECEIVE_DOCUMENTS_AFTER,
    params,
    data
  };
}

function receiveDocumentsBefore(params, extraParamsList, responses) {
  const data = mungeDocumentResponses(extraParamsList, responses);

  return {
    type: RECEIVE_DOCUMENTS_BEFORE,
    params,
    data
  };
}

export function clearDocuments() {
  return {
    type: CLEAR_DOCUMENTS,
    documents: null
  };
}

function requestFullDocuments(params) {
  return {
    type: REQUEST_FULL_DOCUMENTS,
    params
  };
}

function receiveFullDocuments(params, json, no_ready_update) {
  return {
    type: RECEIVE_FULL_DOCUMENTS,
    params,
    no_ready_update,
    documents: lodash.map(json, 'document')
  };
}

function requestDocumentDetails() {
  return {
    type: REQUEST_DOCUMENT_DETAILS
  };
}

function recieveDocumentDetails(response) {
  return {
    type: RECEIVE_DOCUMENT_DETAILS,
    document: lodash.map(response, 'document')[0]
  };
}

function failDocumentDetails(error) {
  return {
    type: FAIL_DOCUMENT_DETAILS,
    error
  };
}

function requestDocumentsAfter(params) {
  return {
    type: REQUEST_DOCUMENTS_AFTER,
    params
  };
}

function requestDocumentsBefore(params) {
  return {
    type: REQUEST_DOCUMENTS_BEFORE,
    params
  };
}

export function markDocumentBookmarked(ids, bookmarked_status) {
  return {
    type: MARK_DOCUMENT_BOOKMARKED,
    ids,
    bookmarked_status
  };
}

export function resetDocumentDetails() {
  return {
    type: RESET_DOCUMENT_DETAILS
  };
}

function requestRelatedDocumentCount(params) {
  return {
    type: REQUEST_RELATED_DOCUMENT_COUNT,
    params
  };
}

function receiveRelatedDocumentCount(params, response) {
  return {
    type: RECEIVE_RELATED_DOCUMENT_COUNT,
    params,
    response
  };
}
function requestSimpleDocuments() {
  return {
    type: REQUEST_SIMPLE_DOCUMENTS
  };
}

function receiveSimpleDocuments(data) {
  return {
    type: RECEIVE_SIMPLE_DOCUMENTS,
    recent_documents: data.documents
  };
}

function requestDocuments(params, sorts, type, widgetId) {
  return {
    type,
    params,
    widgetId
  };
}

function receiveDocuments(params, extraParamsList, responses, type, widgetId) {
  const data = mungeDocumentResponses(extraParamsList, responses);
  return {
    type,
    params,
    data,
    widgetId
  };
}

function failDocuments(error) {
  return {
    type: FAIL_DOCUMENTS,
    error
  };
}

function requestSearchResults(params) {
  return {
    type: REQUEST_SEARCH_RESULTS_RELEVANCE,
    params
  };
}

function receiveSearchResults(data) {
  return {
    type: RECEIVE_SEARCH_RESULTS_RELEVANCE,
    data
  };
}

function failSearchResults(error) {
  return {
    type: FAIL_SEARCH_RESULTS_RELEVANCE,
    error
  };
}

function requestResourceCode() {
  return {
    type: REQUEST_RESOURCE_CODE
  };
}

function receiveResourceCode(json) {
  return {
    type: RECEIVE_RESOURCE_CODE,
    branches: lodash.map(json, 'document')
  };
}

function receiveDocumentCommentsCount(params, response) {
  return {
    type: RECEIVE_DOCUMENT_COMMENTS_COUNT,
    params,
    response
  };
}

function requestDocument() {
  return {
    type: REQUEST_DOCUMENT
  };
}

function receiveDocument(response) {
  return {
    type: RECEIVE_DOCUMENT,
    payload: response
  };
}

function requestRecentActivity() {
  return {
    type: REQUEST_RECENT_ACTIVITY
  };
}

function receiveRecentActivity(response, agencies) {
  const agenciesArray = response.aggregations.filtered_documents['by_agencies.id'].buckets;
  const total_updates = response.aggregations.filtered_documents.doc_count;

  // put agencies into an object to reduce lookup time complexity
  const agenciesObj = agenciesArray.reduce((mem, agency) => {
    mem[agency.key] = {
      categories: agency.by_category.buckets
    };
    return mem;
  }, {});
  // filter by user's agencies and format data for reducer
  const document_stats = agencies.map(agency => {
    const myAgencyObj = {
      agency_id: agency,
      categories: {}
    };
    if (!lodash.isNil(agenciesObj[agency])) {
      const categories = agenciesObj[agency].categories.reduce((mem, category) => {
        mem[category.key] = category.doc_count;
        return mem;
      }, {});
      myAgencyObj.categories = categories;
    }

    return myAgencyObj;
  });

  return {
    type: RECEIVE_RECENT_ACTIVITY,
    document_stats,
    total_updates
  };
}

export function fetchGoogleResults(queryParam) {
  return function doFetch(dispatch) {
    dispatch(requestGoogleResults());
    return api_fetchGoogleResults(queryParam).then(response =>
      dispatch(receiveGoogleResults(response))
    );
  };
}

export function fetchEnforcementData(parameters, useAPIKey) {
  return function doFetch(dispatch) {
    dispatch(requestEnforcements());
    return api_getEnforcements(parameters, useAPIKey).then(response =>
      dispatch(receiveEnforcements(response))
    );
  };
}

function getAllCategoriesObject(docTypes) {
  const result = {};

  const allCategories = extractCategoriesFromMetacategories(docTypes.cai_metacategories);

  allCategories.forEach(category => {
    result[category.id] = true;
  });

  return result;
}

export const fetchDocumentTypes = () => async (dispatch, getState) => {
  const isAuthenticated = getUserAuthenticatedState(getState());

  const response = await api_getDocTypes();

  if (!isAuthenticated) {
    const allCategoriesObject = getAllCategoriesObject(response);
    dispatch(recieveFollowedCategories(allCategoriesObject));
  }

  dispatch(recieveDocTypes(response));

  return response;
};

export function fetchDocumentTypesUpdates() {
  return function doFetch(dispatch) {
    return api_getDocTypesUpdates().then(DocTypesUpdatesNotification => {
      dispatch(receiveDocTypesUpdates(DocTypesUpdatesNotification));
    });
  };
}

export function fetchDiff(params) {
  return async () => {
    try {
      const response = await api_fetchDocDiff(params);

      return response;
    } catch (e) {}
  };
}

export function fetchAggregateEnforcements(params) {
  return function doFetch(dispatch) {
    dispatch(requestAggregateEnforcements());
    return api_fetchAggregateEnforcements(params).then(response => {
      return dispatch(receiveAggregateEnforcements(response));
    });
  };
}

export function fetchSearchResults(params = {}) {
  return function dofetch(dispatch) {
    dispatch(requestSearchResults(params));
    return api_getDocumentsPost(params)
      .then(response => {
        dispatch(receiveSearchResults(response));
      })
      .catch(error => {
        dispatch(failSearchResults(error));
      });
  };
}

export function fetchDocuments(
  params = {},
  extra_params,
  rnToken,
  request_type = REQUEST_DOCUMENTS,
  receive_type = RECEIVE_DOCUMENTS,
  widgetId
) {
  const common_params = lodash.assign({}, params, extra_params);
  const per_request_params = [{ sort: params.sort ? params.sort : DEFAULT_DOCUMENT_SORT_KEY }];

  return async function dofetch(dispatch, getState) {
    dispatch(requestDocuments(common_params, per_request_params, request_type, widgetId));
    return Promise.all(makeDocumentRequests(common_params, per_request_params, rnToken))
      .then(responses => {
        dispatch(
          receiveDocuments(common_params, per_request_params, responses, receive_type, widgetId)
        );
        return responses;
      })
      .catch(error => {
        if (request_type === REQUEST_DOCUMENTS) {
          dispatch(failDocuments(error));
        }
      });
  };
}

export function fetchDocumentsForDashCCPA(widgetId, params) {
  return fetchDocuments(
    params,
    null,
    null,
    REQUEST_DOCUMENTS_FOR_DASH_CCPA,
    RECEIVE_DOCUMENTS_FOR_DASH_CCPA,
    widgetId
  );
}

export function fetchDocumentsForDashCovid(widgetId, params) {
  return fetchDocuments(
    { ...DEFAULT_DASH_COVID_PARAMS, ...params },
    null,
    null,
    REQUEST_DOCUMENTS_FOR_DASH_COVID,
    RECEIVE_DOCUMENTS_FOR_DASH_COVID,
    widgetId
  );
}

export function fetchDocumentsForDashRegulation(widgetId, params) {
  return fetchDocuments(
    { ...DEFAULT_DASH_REGULATION_PARAMS, ...params },
    null,
    null,
    REQUEST_DOCUMENTS_FOR_DASH_REGULATION,
    RECEIVE_DOCUMENTS_FOR_DASH_REGULATION,
    widgetId
  );
}

export function fetchDocumentsForDashAlertWidget(widgetId, params) {
  return fetchDocuments(
    { ...DEFAULT_DASH_ALERT_WIDGET_PARAMS, ...params },
    null,
    null,
    REQUEST_DOCUMENTS_FOR_DASH_ALERT_WIDGET,
    RECEIVE_DOCUMENTS_FOR_DASH_ALERT_WIDGET,
    widgetId
  );
}

export function simpleFetchDocuments(params = {}, rnToken) {
  return async function doFetch(dispatch) {
    dispatch(requestSimpleDocuments());
    return api_getDocuments(params, rnToken).then(response => {
      dispatch(receiveSimpleDocuments(response));
      return response;
    });
  };
}

export function fetchDocumentsAfter(commonParams = {}, extraParamsList) {
  return async function dofetch(dispatch) {
    dispatch(requestDocumentsAfter(commonParams, extraParamsList));

    return Promise.all(makeDocumentRequests(commonParams, extraParamsList)).then(responses => {
      dispatch(receiveDocumentsAfter(commonParams, extraParamsList, responses));
    });
  };
}

export function fetchDocumentsBefore(commonParams = {}, extraParamsList) {
  return async function dofetch(dispatch) {
    dispatch(requestDocumentsBefore(commonParams, extraParamsList));

    return Promise.all(makeDocumentRequests(commonParams, extraParamsList)).then(responses => {
      dispatch(receiveDocumentsBefore(commonParams, extraParamsList, responses));
    });
  };
}

export function fetchFullDocuments(params = {}, no_ready_update) {
  no_ready_update = !!no_ready_update;
  const ids = lodash.isArray(params.id) ? params.id : [params.id];
  const commonParams = {
    ...params,
    id: undefined
  };

  return function dofetch(dispatch) {
    if (params.resource_code) {
      dispatch(requestResourceCode());
    } else if (params.doc_details) {
      dispatch(requestDocumentDetails());
    } else {
      dispatch(requestFullDocuments(params, ids));
    }
    const promises = ids.map(id =>
      api_getDocuments({
        ...commonParams,
        id
      })
    );

    return Promise.all(promises)
      .then(responses => {
        if (params.resource_code) {
          dispatch(receiveResourceCode(responses));
        } else if (params.doc_details) {
          dispatch(recieveDocumentDetails(responses));
        } else {
          dispatch(receiveFullDocuments(commonParams, responses, no_ready_update));
        }
        return responses;
      })
      .catch(error => {
        if (params.doc_details) {
          dispatch(failDocumentDetails(error));
        }
      });
  };
}

export function markDocumentAsBookmarked(doc_ids, bookmarked_status) {
  let document_ids = doc_ids;

  if (!lodash.isArray(doc_ids)) {
    document_ids = [doc_ids];
  }

  return function dopost(dispatch) {
    return api_updateDocuments(document_ids, { bookmarked: bookmarked_status }).then(response => {
      dispatch(markDocumentBookmarked(document_ids, bookmarked_status));
    });
  };
}

export function setDocumentSavedFolderCount(doc_id, count) {
  return {
    type: SET_DOCUMENT_SAVED_FOLDER_COUNT,
    params: {
      doc_id,
      count
    }
  };
}

export function fetchRelatedDocumentCount(params = {}) {
  return function dofetch(dispatch) {
    dispatch(requestRelatedDocumentCount(params));
    return api_getDocumentsPost({
      ...params,
      search_sort: 'relevance',
      get_count_only: true
    }).then(response => {
      dispatch(receiveRelatedDocumentCount(params, response));
    });
  };
}

export function fetchDocumentCommentsCount(comments_for_id) {
  const params = {
    comments_for_id,
    get_count_only: true
  };

  return function dofetch(dispatch) {
    return api_getDocumentsPost(params).then(response => {
      dispatch(receiveDocumentCommentsCount(params, response));
    });
  };
}

export function fetchDocument(doc_id) {
  return function getDocument(dispatch) {
    dispatch(requestDocument());
    return api_getDocument(doc_id).then(response => {
      dispatch(receiveDocument(response));
      return response.document;
    });
  };
}

export function addDocsToDiff(documents) {
  return {
    type: ADD_DOCS_TO_DIFF,
    documentsToDiff: documents
  };
}

export function fetchIframeDocs(data) {
  return function dofetch() {
    return api_getDocumentsBasic(data);
  };
}

export function fetchIframeDocsBuildUrl(data) {
  return function dofetch() {
    return api_getDocsForIFrame(data);
  };
}

export function fetchSentences(docIds, use_obligation_version, contentType) {
  return async dispatch => {
    dispatch({ type: FETCH_SENTENCES_REQUEST });

    try {
      const response = await api_fetchSentences(docIds, { use_obligation_version }, contentType);
      dispatch({ type: FETCH_SENTENCES_SUCCESS, response });

      return { response };
    } catch (e) {
      return dispatch({ type: FETCH_SENTENCES_FAILURE });
    }
  };
}

export const fetchRecentActivity = (daysSinceLastLogin, agencies) => async (dispatch, getState) => {
  const isAuthenticated = getUserAuthenticatedState(getState());

  // note: the user's the server determines which agencies the user is following
  // and returns recent activity for only those agencies
  const data = {
    date_range_field: 'publication_date',
    from_date: daysSinceLastLogin + 'd',
    to_date: '0d',
    terms: ['agencies.id', 'category'], // order is important!
    followed_agencies: true
  };

  if (!isAuthenticated) {
    data.agency_id = getFollowedAgenciesIds(DEFAULT_TYPES.MY_DEFAULTS)(getState());
  }

  dispatch(requestRecentActivity());

  const response = await api_fetchRecentActivity(data);

  dispatch(receiveRecentActivity(response, agencies));

  return receiveRecentActivity(response, agencies);
};
