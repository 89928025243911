import queryString from 'utils/query-string';
import escapeStringRegexp from 'escape-string-regexp';
import lodash from 'lodash';
import auth from 'utils/auth';

import * as autosuggestUtils from 'utils/autosuggest';
import * as analyticsUtils from 'utils/analytics';
import * as filterUtils from 'utils/filter';
import * as primaryFilterHelpers from 'components/Filters/PrimaryFilter/PrimaryFilter.helpers';
import * as localStorageUtils from 'utils/localStorage-utils';
import * as constants from './AutocompleteSearchField.constants';
import * as routes from 'constants/Routes';
import * as primaryFilterConstants from 'constants/PrimaryFilter';
import * as images from 'shared/images';

import { FILTER_KEY as ACTION_BAR_FILTER_KEY } from 'constants/ActionBarFilter';

import './_autocomplete-search-field.scss';

export const getInitialSearchTerm = ({ history, reduxState }) => {
  let updatedSearchTerm = '';

  const parsedQuery = parseQueryParams(history.location.search);

  if (parsedQuery.search_sort === 'relevance' || parsedQuery.search_sort === 'publication_date') {
    updatedSearchTerm = parsedQuery.search_query;
  }

  if (parsedQuery.search_sort === 'date' && reduxState.filtered_mention.isReady) {
    const entity = reduxState.filtered_mention.mention;
    updatedSearchTerm = autosuggestUtils.get_autosuggest_term(parsedQuery, entity);
  }

  return updatedSearchTerm;
};

export const parseQueryParams = string => {
  const parsedQueryParams = queryString.parse(string);

  // For some reason, there are two params in the system responsible for the text in the search field.
  // However, the expected param is search_query
  if (parsedQueryParams.query && !parsedQueryParams.search_query) {
    parsedQueryParams.search_query = parsedQueryParams.query;
  }

  return parsedQueryParams;
};

export const getSortFromCurrentUserProperties = (currentUser, currentView) => {
  let searchSort = 'publication_date';
  let order = 'desc';

  if (currentUser.isReady && currentUser.user.properties.search_sort) {
    searchSort = currentUser.user.properties.search_sort;
  } else if (currentView.search_params.search_sort) {
    searchSort = currentView.search_params.search_sort;
  }

  if (currentUser.isReady && currentUser.user.properties.order) {
    order = currentUser.user.properties.order;
  } else if (currentView.search_params.order) {
    order = currentView.search_params.order;
  }

  return {
    searchSort,
    order
  };
};

export const getShortNames = item => {
  let shortNames = [];

  if (lodash.isString(item.short_name)) {
    shortNames = [item.short_name];
  } else if (Array.isArray(item.short_name)) {
    shortNames = item.short_name;
  }

  return shortNames;
};

export const getNicknames = item => {
  let nicknames = [];

  if (lodash.isString(item.nicknames)) {
    nicknames = [item.nicknames];
  } else if (Array.isArray(item.nicknames)) {
    nicknames = item.nicknames;
  }

  return nicknames;
};

export const increaseTermCount = (term, termCounts) => {
  const lowercaseTerm = term.toLowerCase();

  let updatedTermCounts = { ...termCounts };

  if (updatedTermCounts[lowercaseTerm]) {
    updatedTermCounts[lowercaseTerm] += 1;
  } else {
    updatedTermCounts[lowercaseTerm] = 1;
  }

  return updatedTermCounts;
};

export const getMatches = (autocompletes, searchTerm) => {
  const searchTermRegex = new RegExp(escapeStringRegexp(searchTerm || ''), 'gi');
  const matches = [];

  let termCounts = {};

  lodash.forEach(autocompletes, item => {
    const shortNames = getShortNames(item);
    const nicknames = getNicknames(item);

    item._matched_queries.forEach(matchedQueryField => {
      if (matchedQueryField === 'name') {
        matches.push({
          key: constants.MATCH_KEY.NAME,
          term: item.name,
          item: item
        });

        termCounts = increaseTermCount(item.name, termCounts);
      } else if (matchedQueryField === 'nicknames') {
        nicknames.forEach((nickname, i) => {
          if (nickname !== item.name) {
            matches.push({
              key: constants.MATCH_KEY.NICKNAME,
              term: nickname,
              item: item,
              index: i
            });

            termCounts = increaseTermCount(nickname, termCounts);
          }
        });
      } else {
        shortNames.forEach((shortName, i) => {
          // n.b. only other option supported by api is short_name
          // use the short name if it matches our regex, since elastic tells us
          // that the field matched, not which indices in the array matched
          if (searchTermRegex.test(shortName)) {
            matches.push({
              key: constants.MATCH_KEY.SHORT_NAME,
              term: shortName,
              item: item,
              index: i
            });

            termCounts = increaseTermCount(shortName, termCounts);
          }
        });
      }
    });
  });

  return { matches, termCounts };
};

export const matchToOption = termCounts => (match, i) => {
  const item = match.item;
  const entity = autosuggestUtils.autosuggest_name_map[item._type];

  let displayName = match.term;
  // if there are duplicate names in the list here, we need to resolve it somehow
  // n.b. this does get very specific but i think it probably has to since
  // the display
  if (termCounts[displayName.toLowerCase()] > 1) {
    if (match.key === constants.MATCH_KEY.SHORT_NAME) {
      displayName += ` (${item.name})`;
    } else if (item._type === 'regulations' || item._type === 'named_regulations') {
      displayName += ` (${item.issue})`;
    }
  }

  const entitySpan = (
    <span className="autocomplete-search-field__entity-span">{` (${entity})`}</span>
  );
  const lendingConcept = displayName === 'Lending' && entity === 'concept';

  return {
    item,
    match,
    name: (
      <span>
        {item._type === 'topics' ? (
          <img
            src={images.logos.logoIconWhiteCircle}
            alt="roundLogo"
            className="autocomplete-search-field__round-logo"
          />
        ) : null}
        {displayName}
        {entitySpan}
      </span>
    ),
    key: `${displayName}${i}`,
    //FIXME a temporary hack to remove lending concepts.
    //this should be removed when the data team removes lending concepts
    keep: !lodash.isNil(displayName) && !lodash.isNil(entity) && !lendingConcept
  };
};

export const parseAutoSuggestOptions = (autocompletes, searchTerm) => {
  const { matches, termCounts } = getMatches(autocompletes, searchTerm);

  return matches.map(matchToOption(termCounts)).filter(({ keep }) => keep);
};

export const formatSuggestions = (searchTerm, options) => {
  const searchTermOption = {
    name: searchTerm,
    key: searchTerm,
    match: {
      term: searchTerm
    },
    item: {}
  };

  return searchTerm ? [searchTermOption, ...options] : options;
};

export const isOptionSelected = newOption => option => {
  return String(option.value) === String(newOption.value);
};

export const setSuggestionToFilter = ({
  localState,
  localActions,
  reduxState,
  reduxActions,
  suggestion,
  history
}) => {
  doAnalytics(history.location, suggestion.match.term);

  if (!reduxState.current_view.editAlertId) {
    reduxActions.removeViewAlert();
  }

  localActions.changeSearchTerm('');

  const filterParams = getFilterParams(reduxState);

  if (areThereAnyExpectedQueryParams(history)) {
    if (filterUtils.isPrimaryFilterOpen()) {
      const updatedFilterParams = getUpdatedFilterParams({ filterParams, suggestion });

      updateFilter({ updatedFilterParams, reduxActions, suggestion });
    } else {
      updateQueryParams({
        suggestion,
        history,
        queryParamsString: history.location.search
      });
      localActions.toggleSearchTermTooltipTrigger(!localState.searchTermTooltipTrigger);
    }
  } else {
    if (filterUtils.isPrimaryFilterOpen()) {
      const updatedFilterParams = getUpdatedFilterParams({ filterParams, suggestion });

      updateFilter({ updatedFilterParams, reduxActions, suggestion });
    } else {
      const updatedFilterParams = getUpdatedFilterParams({ filterParams, suggestion });

      updateSavedFilter({ updatedFilterParams, reduxActions, reduxState });
      localActions.toggleSearchTermTooltipTrigger(!localState.searchTermTooltipTrigger);
    }
  }

  reduxActions.clearAutoComplete();
};

export const updateQueryParams = ({ suggestion, history, queryParamsString }) => {
  const propertyToTakeValueFrom =
    constants.SUGGESTION_PROPERTIES_TO_TAKE_VALUE_FROM[suggestion.item._type];

  const prevQueryParams = queryString.parse(queryParamsString);

  const filterQueryParam = constants.SUGGESTION_TO_FILTER_QUERY_PARAM[suggestion.item._type];
  const filterParamValue = String(suggestion.item[propertyToTakeValueFrom]);

  const queryParams = mergeWithCurrentQueryParams({
    prevQueryParams,
    filterQueryParam,
    filterParamValue
  });

  history.push(`${routes.content}?${queryString.stringify(queryParams)}`);
};

export const mergeWithCurrentQueryParams = ({
  prevQueryParams,
  filterQueryParam,
  filterParamValue
}) => {
  const queryParams = { ...prevQueryParams };

  // Delete search query from query params so it won't be applied to search field state after rerender
  if ('search_query' in queryParams) {
    delete queryParams.search_query;
  }

  if (queryParams[filterQueryParam]) {
    // Covert single query param to array so it will be easier to add new params or check
    // if the selected param already in query params
    if (!Array.isArray(queryParams[filterQueryParam])) {
      queryParams[filterQueryParam] = [queryParams[filterQueryParam]];
    }

    if (!queryParams[filterQueryParam].includes(filterParamValue)) {
      queryParams[filterQueryParam] = [...queryParams[filterQueryParam], filterParamValue];
    }
  } else {
    queryParams[filterQueryParam] = filterParamValue;
  }

  return queryParams;
};

export const updateSavedFilter = ({ updatedFilterParams, reduxActions, reduxState }) => {
  if (!auth.loggedIn()) {
    localStorageUtils.setAnonymousUserFilter(updatedFilterParams);
  } else {
    reduxActions.updateCurrentUser(reduxState.current_user.user.email, {
      ...reduxState.current_user.user,
      properties: {
        ...reduxState.current_user.properties,
        filter: updatedFilterParams
      }
    });
  }
};

export const updateFilter = ({ updatedFilterParams, reduxActions }) => {
  reduxActions.setPrimaryFilter(updatedFilterParams);
};

export const getUpdatedFilterParams = ({ filterParams, suggestion }) => {
  const propertyToTakeValueFrom =
    constants.SUGGESTION_PROPERTIES_TO_TAKE_VALUE_FROM[suggestion.item._type];
  const propertyToTakeLabelFrom =
    constants.SUGGESTION_PROPERTIES_TO_TAKE_LABEL_FROM[suggestion.item._type];

  const filterKey = constants.SUGGESTION_TO_FILTER_PARAM[suggestion.item._type];
  const filterValue = filterParams[filterKey] || [];

  const filterNewValueItem = {
    value: suggestion.item[propertyToTakeValueFrom],
    label: suggestion.item[propertyToTakeLabelFrom]
  };

  const isNewFilterValueItemAlreadySelected = filterValue.find(
    isOptionSelected(filterNewValueItem)
  );

  if (isNewFilterValueItemAlreadySelected) {
    return filterParams;
  }

  return {
    ...filterParams,
    [filterKey]: [...filterValue, filterNewValueItem]
  };
};

export const areThereAnyExpectedQueryParams = history => {
  const queryParams = queryString.parse(history.location.search);

  return Object.keys(queryParams).find(paramKey => {
    return Object.values(primaryFilterConstants.SUPPORTED_QUERY_PARAMS).find(
      filterParamKey => filterParamKey === paramKey
    );
  });
};

export const getFilterParams = reduxState => {
  if (filterUtils.isPrimaryFilterOpen() && reduxState.isFilterReady) {
    return reduxState.filterParams;
  }
  return primaryFilterHelpers.getFilterFromUser({
    currentUser: reduxState.current_user,
    organizationLabels: reduxState.organizationLabels,
    languages: reduxState.languages.languages
  });
};

export const setSearchTermToUrl = ({
  localState,
  localActions,
  reduxState,
  reduxActions,
  suggestion,
  history
}) => {
  const wasSelectedSuggestion = Boolean(suggestion);
  const searchQuery = wasSelectedSuggestion ? suggestion.match.term : localState.searchTerm;

  doAnalytics(history.location, searchQuery);

  const filterParams = getFilterParams(reduxState);

  if (!reduxState.current_view.editAlertId) {
    reduxActions.removeViewAlert();
  }

  if (!wasSelectedSuggestion && localState.searchTerm) {
    // postSearchQuery keeps track of search term count
    reduxActions.postSearchQuery({
      search_args: { query: localState.searchTerm }
    });
  }

  const { searchSort, order } = getSortFromCurrentUserProperties(
    reduxState.current_user,
    reduxState.current_view
  );

  let queryParams;

  if (!filterUtils.isPrimaryFilterOpen() && routes.content === history.location.pathname) {
    // Filter validates options for redux only on mount.
    // If filter is closed (unmounted), it may contain in Redux not relevant values.
    // In this case take params from query string
    queryParams = parseQueryParams(history.location.search);
    queryParams[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.LIMIT] =
      reduxState.actionBarFilterValues[ACTION_BAR_FILTER_KEY.ROWS_COUNT];

    if (searchQuery) {
      queryParams[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.SEARCH_QUERY] = searchQuery;
      queryParams[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.MIN_SCORE] = 5;
    }

    if (order) {
      queryParams[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.ORDER] = order;
    }

    if (searchSort) {
      queryParams[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.SEARCH_SORT] = searchSort;
    }
  } else {
    queryParams = parseQueryParams(
      primaryFilterHelpers.formatQueryStringFromFilterParams({
        filterParams,
        searchQuery,
        currentView: localState.currentView,
        location: history.location,
        searchSort,
        order,
        autocompleteQuery: wasSelectedSuggestion,
        limit: reduxState.actionBarFilterValues[ACTION_BAR_FILTER_KEY.ROWS_COUNT]
      })
    );
  }

  reduxActions.clearAutoComplete();
  reduxActions.closeFilter();

  history.push(`${routes.content}?${queryString.stringify(queryParams)}`);
};

export const selectAutosuggestItem = ({
  history,
  localState,
  localActions,
  reduxState,
  reduxActions,
  suggestion
}) => {
  if (isValidFilter(suggestion)) {
    setSuggestionToFilter({
      localState,
      localActions,
      reduxState,
      reduxActions,
      suggestion,
      history
    });
  } else {
    setSearchTermToUrl({
      localState,
      localActions,
      reduxState,
      reduxActions,
      suggestion,
      history
    });
    saveNamedSearchQuery({ reduxActions, item: suggestion.item });
  }
};

export const isValidFilter = suggestion => {
  const filterQueryParam = constants.SUGGESTION_TO_FILTER_QUERY_PARAM[suggestion.item._type];
  const propertyToTakeValueFrom =
    constants.SUGGESTION_PROPERTIES_TO_TAKE_VALUE_FROM[suggestion.item._type];

  return filterQueryParam && propertyToTakeValueFrom;
};

export const saveNamedSearchQuery = ({ reduxActions, item }) => {
  const idName = constants.SUGGESTION_TO_ID_NAME[item._type];

  if (item.id && idName) {
    // postSearchQuery keeps track of search term count
    reduxActions.postSearchQuery({
      search_args: { [idName]: item.id }
    });
  }
};

export const doAnalytics = (location, searchTerm) => {
  const eventCategory = location.pathname === routes.dashboard ? 'Dashboard' : 'Search';

  analyticsUtils.safe_ga('send', 'event', eventCategory, 'Do search', searchTerm);
  analyticsUtils.safe_mixpanel_track(`${eventCategory} – Do search`, {
    hitType: 'event',
    eventCategory,
    eventAction: 'Do search',
    eventLabel: searchTerm,
    search_type: 'bag of words'
  });
};
