import { ERROR_MESSAGES, ERROR_TYPES } from '../constants/Errors';
import { ORG_IP_ERROR, ORG_IP_ERROR_DESCRIPTION } from './fetch.constants';
import Bugsnag from '@bugsnag/js';
import { showSystemErrorNotification } from '../common/AlertsNotifications/AlertsNotifications.utils';
import * as routes from '../constants/Routes';
import { safe_mixpanel_track, sendGoogleAnalyticsException } from 'utils/analytics';
import { NetworkError } from '../utils/errors';

/**
 *
 * Convert the axios error to the object to use and handle errors in Redux
 * @param {Error} axiosError - axios error returned from axios reuest
 * @return {Object} - formatted object to use in redux
 *
 * Returned object has following types, and we use it in redux error handling:
 *
 *  - TIMEOUT: Timeouted from the API request(408, 504 and request timeouted error)
 *  - SERVER: API response with the error (4xx ~ 5xx)
 *  - NETWORK: Client network error
 *  - UNKNOWN: Unknown and unexpected error
 */

export const convertAxiosErrorToObject = axiosError => {
  const errorObject = new NetworkError({
    status: 0,
    type: ERROR_TYPES.UNKNOWN,
    message: ERROR_MESSAGES.UNKNOWN
  });

  if (axiosError.response) {
    const { status, data: { message, error } = {} } = axiosError.response;
    errorObject.status = status;
    errorObject.message = message || error || ERROR_MESSAGES.SERVER;
    errorObject.type = status === 408 || status === 504 ? ERROR_TYPES.TIMEOUT : ERROR_TYPES.SEVER;
  } else if (axiosError.request) {
    errorObject.message = axiosError.message || ERROR_MESSAGES.NETWORK;
    errorObject.type =
      axiosError.code && axiosError.code === 'ECONNABORTED'
        ? ERROR_TYPES.TIMEOUT
        : ERROR_TYPES.NETWORK;
  }

  return errorObject;
};

export const getErrorData = errorResponse => {
  const {
    status: errorStatus,
    statusText: errorStatusText,
    data: errorData,
    request: { _url: errorURL }
  } = errorResponse;

  const errorMessage = errorData.message || errorData.error || ERROR_MESSAGES.SERVER;

  return {
    errorStatus,
    errorStatusText,
    errorURL,
    errorMessage,
    errorData
  };
};

export const redirectToLoginPage = ({ errorMessage, dataType }) => {
  if (errorMessage.includes(ORG_IP_ERROR)) {
    Bugsnag.notify(ORG_IP_ERROR_DESCRIPTION);

    showSystemErrorNotification({
      dataType: dataType,
      message: ORG_IP_ERROR_DESCRIPTION
    });
  }

  window.location.href = `${window.location.origin}${routes.login}`;
};

export const logError = ({ dataType, errorResponse }) => {
  const { errorStatus, errorStatusText, errorMessage, errorURL } = getErrorData(errorResponse);

  const metadata = {
    component: {
      name: dataType
    },
    xhr: {
      status: errorStatus,
      statusText: errorStatusText,
      url: errorURL
    }
  };

  Bugsnag.notify(errorMessage);
  Bugsnag.addMetadata('XMLHttpRequestError', metadata);

  showSystemErrorNotification({
    dataType: dataType,
    message: errorMessage
  });

  sendGoogleAnalyticsException({
    description: errorMessage,
    isFatal: true
  });

  safe_mixpanel_track('Exception – Other', {
    hitType: 'exception',
    exDescription: errorMessage,
    exFatal: true
  });
};
