import type { ResourcesState } from './resources.types';

import * as helpers from './resourcesShelves.helpers';

import { createSlice } from '@reduxjs/toolkit';
import {
  fetchResources,
  fetchResource,
  fetchResourceHierarchy,
  setSelectedJurisdictions,
  fetchDocumentShelves,
  updateBreadcrumbs,
  fetchResourceHierarchyV2,
  setActiveShelfId
} from './resources.actions';
import { REDUCER_NAME } from './resources.constants';

export const INITIAL_STATE: ResourcesState = {
  areResourcesReady: false,
  isHierarchyReady: true,
  jurisdictions: [],
  resources: {},
  // have a root shelf with id === null, so we can store under it all shelves with parentId === null
  resourcesShelves: { id: null },
  breadcrumbs: [],
  selectedJurisdictions: [],
  activeShelfId: null
};

export const { actions, reducer } = createSlice({
  name: REDUCER_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setSelectedJurisdictions.type, (state, action) => {
      if (setSelectedJurisdictions.match(action)) {
        state.selectedJurisdictions = action.payload;
      }
    });

    builder.addCase(fetchResources.fulfilled, (state, action) => {
      state.areResourcesReady = true;
      state.jurisdictions = action.payload;
    });

    builder.addCase(fetchResource.pending, (state, action) => {
      state.resources[action.meta.arg.docId] = {
        isLoading: true,
        items: state.resources[action.meta.arg.docId]?.items ?? []
      };
    });

    builder.addCase(fetchResource.fulfilled, (state, action) => {
      state.resources[action.meta.arg.docId] = {
        isLoading: false,
        items: action.payload.items
      };
    });

    builder.addCase(fetchResource.rejected, (state, action) => {
      state.resources[action.meta.arg.docId] = {
        isLoading: false,
        items: state.resources[action.meta.arg.docId]?.items ?? []
      };
    });

    builder.addCase(fetchDocumentShelves.pending, (state, action) => {
      state.isHierarchyReady = false;
    });

    builder.addCase(fetchDocumentShelves.fulfilled, (state, action) => {
      state.resourcesShelves = helpers.updateShelves({
        shelves: state.resourcesShelves,
        updatedShelf: action.payload
      });
      state.isHierarchyReady = true;
    });

    builder.addCase(fetchDocumentShelves.rejected, (state, action) => {
      state.isHierarchyReady = true;
    });

    builder.addCase(fetchResourceHierarchy.fulfilled, (state, action) => {
      state.isHierarchyReady = true;
      state.resources = {
        ...state.resources,
        ...action.payload
      };
    });

    builder.addCase(fetchResourceHierarchy.pending, (state, action) => {
      state.isHierarchyReady = false;
    });

    builder.addCase(fetchResourceHierarchyV2.fulfilled, (state, action) => {
      state.isHierarchyReady = true;
      state.resourcesShelves = { ...state.resourcesShelves, children: [action.payload.shelves] };
      state.breadcrumbs = action.payload.breadcrumbs;
      state.activeShelfId = helpers.getActiveShelfId(action.payload.breadcrumbs);
    });

    builder.addCase(fetchResourceHierarchyV2.pending, state => {
      state.isHierarchyReady = false;
    });

    builder.addCase(fetchResourceHierarchyV2.rejected, state => {
      state.isHierarchyReady = true;
    });

    builder.addCase(updateBreadcrumbs.fulfilled, (state, action) => {
      state.breadcrumbs = action.payload;
    });
    builder.addCase(setActiveShelfId.type, (state, action) => {
      if (setActiveShelfId.match(action)) {
        state.activeShelfId = action.payload;
      }
    });
  }
});
