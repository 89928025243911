import PropTypes from 'prop-types';
import { LabeledField } from 'common';
import { Button, BUTTON_TYPES, TextField } from '@compliance.ai/web-components';
import { useEmailConfirmationData, useEmailConfirmationHandlers } from './hooks';
import { SignUpDataValidator, useSignUpDataValidator } from '../../../../../SignUpDataValidator';
import './_email-confirmation.scss';

export const EmailConfirmation = ({ onSubmit }) => {
  const { localState, localActions } = useEmailConfirmationData();

  const { signUpDataValidation } = useSignUpDataValidator({
    password: localState.newPassword,
    confirmPassword: localState.confirmationPassword
  });

  const { handleSubmit } = useEmailConfirmationHandlers({
    props: {
      onSubmit
    },
    localState
  });

  return (
    <>
      <LabeledField label="New password" className="email-confirmation__labeled-field">
        <TextField
          type="password"
          value={localState.newPassword}
          onChange={localActions.setNewPassword}
          placeholder="New password"
          required
        />
        <SignUpDataValidator password={localState.newPassword} />
      </LabeledField>
      <LabeledField label="Confirm password" className="email-confirmation__labeled-field">
        <TextField
          type="password"
          value={localState.confirmationPassword}
          onChange={localActions.setConfirmationPassword}
          placeholder="Re-enter new password"
          required
          error={!signUpDataValidation.confirmPassword.isValid}
          helperText={signUpDataValidation.confirmPassword.errorMessage}
        />
      </LabeledField>
      <Button
        type={BUTTON_TYPES.PRIMARY}
        className="email-confirmation__confirm-button"
        isDisabled={
          !(signUpDataValidation.password.isValid && signUpDataValidation.confirmPassword.isValid)
        }
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </>
  );
};

EmailConfirmation.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
