import type { TaskAssigneeData } from './useTaskAssigneeData.types';
import type { TaskAssigneeProps } from '../TaskAssignee.types';
import type { GlobalState } from 'shared/reducers';

import {
  APPROVAL_ANNOTATION_STATUSES,
  WORKFLOW_ANNOTATION_TYPES
} from 'shared/features/workflow/workflow.constants';

import * as helpers from './useTaskAssigneeData.helpers';
import * as orgSelectors from 'shared/features/organizations/organizations.selectors';
import * as workflowSelectors from 'shared/features/workflow/workflow.selectors';
import * as userSelectors from 'shared/features/user/user.selectors';

import { useDocumentTaskAnnotations } from 'shared/features/workflow/hooks';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const useTaskAssigneeData = ({
  documentTask,
  assignee,
  document
}: Pick<TaskAssigneeProps, 'documentTask' | 'assignee' | 'document'>): TaskAssigneeData => {
  const approvalAnnotation = useDocumentTaskAnnotations(documentTask)[
    WORKFLOW_ANNOTATION_TYPES.APPROVAL
  ];

  const [isEditable, setEditableState] = useState(false);

  const localState: TaskAssigneeData['localState'] = {
    isEditable
  };

  const localActions: TaskAssigneeData['localActions'] = useMemo(
    () => ({
      setEditableState
    }),
    []
  );

  const reduxState = useSelector<GlobalState, TaskAssigneeData['reduxState']>(state => ({
    currentUserReducer: userSelectors.getCurrentUserReducer(state),
    assigneesOptions: workflowSelectors.getDocumentTaskPossibleAssigneeOptions({
      documentTask,
      document
    })(state),
    selectedAssigneeOption: orgSelectors.getActiveOrganizationMembersOptionsById([assignee])(
      state as never
    )[0],
    selectedAssigneeOptionLabel: orgSelectors.getActiveOrganizationMemberOptionLabel(
      state,
      // Suppressing because TS doesn't allow to pass second argument to JS-written selectors
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      assignee
    ),
    approversOptions: orgSelectors.getActiveOrganizationMembersOptionsById(
      approvalAnnotation.approversIds
    )(state),
    isOrgReady: orgSelectors.getOrganizationReadyState(state)
  }));

  const formattedData: TaskAssigneeData['formattedData'] = {
    isDisabled: helpers.isInputDisabled({
      documentTask: documentTask,
      isPotentialApprover: approvalAnnotation.isPotentialApprover,
      isApprovalAnnotationAvailable: approvalAnnotation.isAvailable,
      approvalAnnotationStatus: approvalAnnotation.status,
      currentUserReducer: reduxState.currentUserReducer
    }),
    shouldShowOnlyApprovers:
      approvalAnnotation.status === APPROVAL_ANNOTATION_STATUSES.PENDING_APPROVAL
  };

  return {
    localState,
    localActions,
    reduxState,
    formattedData
  };
};
