import PropTypes from 'prop-types';
import queryString from 'utils/query-string';
import trunc from 'trunc-html';
import DescriptionIcon from '@material-ui/icons/Description';
import { appUrl } from 'shared/config';
import { get_autosuggest_term, autosuggest_name_map } from 'utils/autosuggest';
import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';
import { RIGHT_PANEL_TYPES } from 'shared/features/rightPanel/rightPanel.constants';
import * as images from 'shared/images';
import { useLocation, useNavigate } from 'react-router-dom';

export const TEST_IDS = {
  CONTAINER: 'auto-suggest-container',
  CORRECTED_SEARCH: 'currected-search-box'
};

export const AutoSuggest = ({
  parsedQuery,
  search_results_relevance,
  filtered_mention,
  documents_full
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  let search_results_title;
  let search_query;
  const corrected_search = search_results_relevance.results.corrected_search;

  const rightPanelReduxActions = useRightPanelReduxActions();

  const handleDocumentOpen = documentId => () => {
    rightPanelReduxActions.openRightPanel({
      type: RIGHT_PANEL_TYPES.DOCUMENT_DETAILS,
      props: {
        documentId: documentId
      }
    });
  };

  if (parsedQuery.autosuggest_filter) {
    // if the value originally selected was in the short name list, parse our
    // autosuggest_mapper query argument and pull out the index, otherwise
    // use the name of the mention as the default behavior
    const value = get_autosuggest_term(parsedQuery, filtered_mention.mention);

    let type = autosuggest_name_map[parsedQuery.autosuggest_filter] || null;
    type = type[0].toUpperCase() + type.slice(1);
    search_results_title = `Results for ${type}:`;
    search_query = <h4>{value}</h4>;
  } else if (parsedQuery.more_like_doc_id || parsedQuery.comments_for_id) {
    const related_id = parsedQuery.more_like_doc_id
      ? parseInt(parsedQuery.more_like_doc_id, 10)
      : parseInt(parsedQuery.comments_for_id, 10);
    const related_doc = documents_full.ids[related_id];

    let related_to_frag = null;

    if (related_doc) {
      const title = trunc(related_doc.title, 50).text;

      related_to_frag = (
        <span className="related-link">
          <DescriptionIcon />
          <Button type={BUTTON_TYPES.LINK} onClick={handleDocumentOpen(related_id)}>
            {title}
          </Button>
        </span>
      );
    }
    search_results_title = parsedQuery.comments_for_id
      ? 'Comments for:'
      : 'Results for documents related to:';
    search_query = related_to_frag;
  } else if (parsedQuery.docket_id) {
    search_results_title = 'Results for docket ID:';
    search_query = parsedQuery.docket_id;
  } else {
    search_results_title = 'Showing results for:';
    if (corrected_search && corrected_search.correct_query) {
      search_query = corrected_search.correct_query;
    } else if (corrected_search && corrected_search.corrected_advanced_search) {
      search_query = corrected_search.corrected_advanced_search;
    } else search_query = parsedQuery.search_query;
  }

  const executeOriginalSearch = originalSearchQuery => {
    parsedQuery.search_query = originalSearchQuery;
    parsedQuery.original_query_param = true;
    navigate(`${location.pathname}?${queryString.stringify(parsedQuery)}`);
  };

  if (!search_query) {
    return null;
  }

  return (
    <div className="toolbar-autocorrect-container" data-testid={TEST_IDS.CONTAINER}>
      <div>
        {search_results_title}
        <span className="searchQuery">{search_query}</span>{' '}
        <img
          onClick={() => window.open(`${appUrl}/support?view=BasicandAdvancedSearch`, '_blank')}
          src={images.misc.questionIcon}
          className="helpIcon"
          alt="help_icon"
        />
      </div>
      {corrected_search &&
        (corrected_search.correct_query || corrected_search.corrected_advanced_search) && (
          <div data-testid={TEST_IDS.CORRECTED_SEARCH}>
            Search instead for:{' '}
            <span
              className="searchSuggestion"
              onClick={() => executeOriginalSearch(corrected_search.original_query)}
            >
              {corrected_search.original_query}
            </span>
          </div>
        )}
    </div>
  );
};

AutoSuggest.propTypes = {
  parsedQuery: PropTypes.object.isRequired,
  search_results_relevance: PropTypes.object.isRequired,
  filtered_mention: PropTypes.object.isRequired,
  documents_full: PropTypes.object.isRequired
};

export default AutoSuggest;
