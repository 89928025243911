import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DocumentContextMenuOverlay } from 'common';
import { getCategories } from 'shared/features/documents/documents.selectors';
import { getUserTimezone } from 'shared/features/user/user.selectors';
import * as dateUtils from 'utils/date-utils';
import { LocalDateFormat } from 'utils/keyDates';
import {
  DOCUMENT_PROPERTY_LABEL,
  DOCUMENT_PROPERTY_VALUE,
  getDocumentProperty
} from 'utils/documents/documentProperties';
import { DOCUMENT_FIELDS } from 'constants/DocumentProperties';
import { getCategoryNameByID } from 'shared/features/documents/documents.selectors';
import './_widget-document.scss';

const WidgetDocument = ({
  document,
  shouldRenderPublishDate,
  shouldRenderSources,
  shouldRenderCategories,
  shouldRenderComments,
  shouldRenderCommentsDates,
  shouldRenderTopics,
  onDocumentClick,
  className
}) => {
  const categories = useSelector(getCategories);
  const timezone = useSelector(getUserTimezone);
  const rule = document.rule || {};

  const comments = rule.comments_close_on;
  const commentsDate = comments
    ? dateUtils.formatDateWithTimezone(comments, timezone, LocalDateFormat)
    : '';

  const effective = rule.effective_on;
  const effectiveDate = effective
    ? dateUtils.formatDateWithTimezone(effective, timezone, LocalDateFormat)
    : '';

  const toTopicNames = ({ name }) => name;

  const renderDivider = () => <div className="widget-document__vertical-divider">|</div>;

  const { [DOCUMENT_PROPERTY_VALUE]: documentSources } = getDocumentProperty[
    DOCUMENT_FIELDS.agencies
  ]({
    document
  });
  const {
    [DOCUMENT_PROPERTY_LABEL]: publicationLabel,
    [DOCUMENT_PROPERTY_VALUE]: publicationValue
  } = getDocumentProperty[DOCUMENT_FIELDS.publicationDate]({
    document,
    timezoneName: timezone
  });

  return (
    <DocumentContextMenuOverlay
      document={document}
      key={document.id}
      className={classnames(className, 'widget-document__container', {
        'widget-document__container--is-clickable': Boolean(onDocumentClick)
      })}
    >
      <div onClick={() => onDocumentClick && onDocumentClick(document)}>
        <div className="widget-document__header">{document.title}</div>
        <div className="widget-document__content">
          {shouldRenderPublishDate && (
            <>
              <span className="widget-document__publish-date-caption">{`${publicationLabel}: `}</span>
              {publicationValue}
            </>
          )}
          {shouldRenderCategories && (
            <>
              {shouldRenderPublishDate && renderDivider()}
              {getCategoryNameByID(categories, document.cai_category_id)}
            </>
          )}
          {shouldRenderSources && (
            <>
              {renderDivider()}
              {Array.isArray(documentSources) ? documentSources.join(', ') : documentSources}
            </>
          )}
          {shouldRenderComments && comments && (
            <>
              {renderDivider()}
              <span className="widget-documents__comments">Comments:</span>
              <span>{commentsDate}</span>
            </>
          )}
          {shouldRenderCommentsDates && effective && (
            <>
              {renderDivider()}
              <span className="widget-documents__comments">Effective:</span>
              <span>{effectiveDate}</span>
            </>
          )}
          {shouldRenderTopics && Array.isArray(document.topics) && document.topics.length > 0 && (
            <span>
              {renderDivider()}
              {document.topics.map(toTopicNames).join(', ')}
            </span>
          )}
        </div>
      </div>
    </DocumentContextMenuOverlay>
  );
};

export default WidgetDocument;

WidgetDocument.propTypes = {
  document: PropTypes.shape({
    title: PropTypes.string,
    topics: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string
      })
    ),
    publisher: PropTypes.string,
    whitepaper: PropTypes.shape({
      metadata: PropTypes.shape({
        authors: PropTypes.array
      })
    }),
    category: PropTypes.string,
    agencies: PropTypes.arrayOf(
      PropTypes.shape({
        short_name: PropTypes.string
      })
    ),
    id: PropTypes.number,
    publication_date: PropTypes.string
  }).isRequired,
  onDocumentClick: PropTypes.func,
  shouldRenderPublishDate: PropTypes.bool,
  shouldRenderSources: PropTypes.bool,
  shouldRenderCategories: PropTypes.bool,
  shouldRenderComments: PropTypes.bool,
  shouldRenderCommentsDates: PropTypes.bool,
  shouldRenderTopics: PropTypes.bool,
  className: PropTypes.string
};

WidgetDocument.defaultProps = {
  onDocumentClick: undefined,
  shouldRenderPublishDate: true,
  shouldRenderSources: true,
  shouldRenderCategories: true,
  shouldRenderComments: true,
  shouldRenderCommentsDates: true,
  shouldRenderTopics: true,
  className: ''
};
