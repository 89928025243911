import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';
import {
  authorizePCUser as reduxAuthorizePCUser,
  updateCurrentUser as reduxUpdateCurrentUser,
  postUserPreferences as reduxUpdateUserPreferences,
  updateUserDocsTableSettings as reduxUpdateUserDocsTableSettings,
  postSearchQuery as reduxPostSearchQuery
} from 'shared/features/user/user.actions';

export const useUserReduxActions = () => {
  const dispatch = useDispatch();

  const postSearchQuery = useCallback(
    (...args) => {
      return dispatch(reduxPostSearchQuery(...args));
    },
    [dispatch]
  );

  const updateLastDocTypesUpdateNotification = useCallback(
    (...args) => {
      dispatch(reduxUpdateCurrentUser(...args));
    },
    [dispatch]
  );

  const updateCurrentUser = useCallback(
    (...args) => {
      dispatch(reduxUpdateCurrentUser(...args));
    },
    [dispatch]
  );

  const unlockPremiumContent = useCallback(
    async ({ email, sourceId, documentId }) => {
      return await dispatch(
        reduxAuthorizePCUser({
          email: email,
          pcSourceId: sourceId,
          docId: documentId
        })
      );
    },
    [dispatch]
  );

  const updateUserPreferences = useCallback(
    async preferences => {
      return await dispatch(reduxUpdateUserPreferences(preferences));
    },
    [dispatch]
  );

  const updateUserDocsTableSettings = useCallback(
    (tableKey, tableSettings) => {
      return dispatch(reduxUpdateUserDocsTableSettings(tableKey, tableSettings));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      postSearchQuery,
      updateLastDocTypesUpdateNotification,
      updateCurrentUser,
      unlockPremiumContent,
      updateUserPreferences,
      updateUserDocsTableSettings
    }),
    [
      postSearchQuery,
      updateLastDocTypesUpdateNotification,
      updateCurrentUser,
      unlockPremiumContent,
      updateUserPreferences,
      updateUserDocsTableSettings
    ]
  );
};
