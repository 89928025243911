import type { BucketSelectProps } from '../BucketSelect.types';
import type { BucketSelectData, BucketSelectLocalState } from './useBucketSelectData.types';
import type { BucketSelectHandlers } from './useBucketSelectHandlers.types';
import type { useBucketSelectQueries } from './useBucketSelectQueries';

export const useBucketSelectHandlers = ({
  props,
  reduxState,
  localActions,
  queries
}: {
  props: Pick<BucketSelectProps, 'exclusiveBucketId' | 'onChange'>;
  reduxState: BucketSelectData['reduxState'];
  localActions: BucketSelectData['localActions'];
  queries: ReturnType<typeof useBucketSelectQueries>;
}): BucketSelectHandlers => {
  const handleOptionChange: BucketSelectHandlers['handleOptionChange'] = value => {
    let option = value as BucketSelectLocalState['selectedBucketOption'];

    if (reduxState.isPublicFolderDisabled && option === null) {
      option = queries.fetchBucketsOptionsQuery?.data?.[0] || null;
    }

    localActions.setSelectedBucketOption(option);
    localActions.setBucketNameInput('');

    props.onChange?.(option);
  };

  const handleInputChange: BucketSelectHandlers['handleInputChange'] = inputString => {
    localActions.setBucketNameInput(inputString);
  };

  return {
    handleOptionChange,
    handleInputChange
  };
};
