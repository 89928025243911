import { fetch } from 'utils/api';

export const api_fetchCompanies = params => {
  return fetch({
    url: `/companies`,
    params,
    method: 'GET'
  });
};

export const api_fetchProductServices = params => {
  return fetch({
    url: `/product_services`,
    params,
    method: 'GET'
  });
};

export const api_fetchOrganizationProfile = () => {
  return fetch({
    url: '/organization_profiles',
    method: 'GET'
  });
};

export const api_postOrganizationProfile = params => {
  return fetch({
    url: '/organization_profiles',
    params: { organization_profile: params },
    method: 'POST'
  });
};

export const api_resetOrganizationProfile = () => {
  return fetch({
    url: `/organization_profiles`,
    method: 'DELETE'
  });
};
