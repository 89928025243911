export const PASSWORD_VALIDATOR_KEY = {
  CHAR8: 'CHAR8',
  LOWERCASE: 'LOWERCASE',
  UPPERCASE: 'UPPERCASE',
  NUMBER: 'NUMBER',
  SYMBOL: 'SYMBOL'
};

export const EMAIL_VALIDATOR_KEY = {
  VALID_FORMAT: 'VALID_FORMAT'
};

export const EMAIL_VALIDATOR_MESSAGES = {
  [EMAIL_VALIDATOR_KEY.VALID_FORMAT]: 'Email is correct'
};

export const PASSWORD_VALIDATOR_MESSAGES = {
  [PASSWORD_VALIDATOR_KEY.CHAR8]: 'Mininum 8 characters',
  [PASSWORD_VALIDATOR_KEY.LOWERCASE]: 'Lowercase letter',
  [PASSWORD_VALIDATOR_KEY.UPPERCASE]: 'Uppercase letter',
  [PASSWORD_VALIDATOR_KEY.NUMBER]: 'Number',
  [PASSWORD_VALIDATOR_KEY.SYMBOL]: 'Symbol'
};

export const CONFIRM_PASSWORD_DOES_NOT_MATCH_ERROR = 'Password does not match';
