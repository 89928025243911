import type { ProgressiveSentenceNotesData } from './useProgressiveSentenceNotesData.types';
import type { ProgressiveSentenceNotesArgs } from './useProgressiveSentenceNotes.types';

import type { useProgressiveSentenceNotesQueries } from './useProgressiveSentenceNotesQueries';

import * as elements from './elements';
import * as constants from './useProgressiveSentenceNotes.constants';

export const useProgressiveSentenceNotesLoader = ({
  args,
  localState,
  reduxState,
  formattedData,
  queries
}: {
  args: Pick<ProgressiveSentenceNotesArgs, 'type' | 'loaderProps'>;
  localState: ProgressiveSentenceNotesData['localState'];
  reduxState: ProgressiveSentenceNotesData['reduxState'];
  formattedData: ProgressiveSentenceNotesData['formattedData'];
  queries: ReturnType<typeof useProgressiveSentenceNotesQueries>;
}) => {
  switch (true) {
    case queries.fetchSentenceNoteById.isFetching: {
      return (
        <elements.Loader
          {...args.loaderProps}
          text={`Loading selected ${args.type}`}
          progress={0}
        />
      );
    }

    case queries.fetchSentenceNotes.isFetching: {
      return (
        <elements.Loader
          {...args.loaderProps}
          text={
            !localState.isInitialFetchOfList &&
            `You're viewing ${reduxState.sentenceNotes.length} of ${localState.totalCount}`
          }
          messages={constants.LOADER_MESSAGES[args.type]}
          progress={formattedData.progress}
        />
      );
    }

    case queries.fetchSystemGeneratedSentenceNotes.isFetching: {
      return <elements.Loader {...args.loaderProps} text="Loading system-generated obligations" />;
    }

    default: {
      return null;
    }
  }
};
