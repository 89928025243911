import type * as apiTypes from './buckets.api.types';

import { fetch } from 'fetch';

export const getBuckets: apiTypes.GetBuckets = params => {
  return fetch({
    url: '/buckets',
    method: 'GET',
    params,
    shouldHideErrorNotifications: true
  });
};

export const getBucketById: apiTypes.GetBucketById = id => {
  return fetch({
    url: `/buckets`,
    method: 'GET',
    params: { id },
    shouldHideErrorNotifications: true
  });
};

export const addBucket: apiTypes.AddBucket = params => {
  return fetch({
    url: `/buckets`,
    method: 'POST',
    params,
    shouldHideErrorNotifications: true
  });
};

export const modifyBucket: apiTypes.ModifyBucket = (id, params) => {
  return fetch({
    url: `/buckets/${id}`,
    method: 'PATCH',
    params,
    shouldHideErrorNotifications: true
  });
};

export const deleteBucket: apiTypes.DeleteBucket = id => {
  return fetch({
    url: `/buckets/${id}`,
    method: 'DELETE',
    shouldHideErrorNotifications: true
  });
};
