import type { DocumentTaskFromResponse } from 'shared/features/workflow/workflow.types';

import {
  DEFAULT_LABELS,
  WORKFLOW_ANNOTATION_SETTINGS_KEYS,
  WORKFLOW_ANNOTATION_TYPES
} from 'shared/features/workflow/workflow.constants';
import lodash from 'lodash';
import { formatAnnotationLabel } from 'shared/features/workflow/workflow.helpers';

const isValidAnnotationField = (type: WORKFLOW_ANNOTATION_TYPES) => {
  return Object.values(WORKFLOW_ANNOTATION_TYPES).includes(type);
};

export const isAvailableAnnotationField = ({
  documentTask,
  type
}: {
  documentTask: DocumentTaskFromResponse;
  type: WORKFLOW_ANNOTATION_TYPES;
}): boolean => {
  const annotationSettings = documentTask.annotation_settings;
  const annotationTypes =
    lodash.get(annotationSettings, WORKFLOW_ANNOTATION_SETTINGS_KEYS.TYPES) ?? [];

  return isValidAnnotationField(type) && annotationTypes.includes(type);
};

export const isRequiredAnnotationField = ({
  documentTask,
  type
}: {
  documentTask: DocumentTaskFromResponse;
  type: WORKFLOW_ANNOTATION_TYPES;
}) => {
  const annotationSettings = documentTask.annotation_settings;
  const requiredAnnotationTypes =
    lodash.get(annotationSettings, WORKFLOW_ANNOTATION_SETTINGS_KEYS.REQUIREMENT) ?? [];

  return isValidAnnotationField(type) && requiredAnnotationTypes.includes(type);
};

export const getTextAnnotationLabel = ({
  documentTask
}: {
  documentTask: DocumentTaskFromResponse;
}) => {
  const isRequired = isRequiredAnnotationField({
    documentTask,
    type: WORKFLOW_ANNOTATION_TYPES.TEXT
  });

  return formatAnnotationLabel({
    label: DEFAULT_LABELS[WORKFLOW_ANNOTATION_TYPES.TEXT],
    isRequired
  });
};

export const getAttachmentsAnnotationLabel = ({
  documentTask
}: {
  documentTask: DocumentTaskFromResponse;
}) => {
  const isRequired = isRequiredAnnotationField({
    documentTask,
    type: WORKFLOW_ANNOTATION_TYPES.ATTACHMENT
  });

  return formatAnnotationLabel({
    label: DEFAULT_LABELS[WORKFLOW_ANNOTATION_TYPES.ATTACHMENT],
    isRequired
  });
};

export const getAssigneesAnnotationLabel = () => {
  return formatAnnotationLabel({
    label: DEFAULT_LABELS[WORKFLOW_ANNOTATION_TYPES.ASSIGNEES],
    isRequired: true
  });
};

export const getDatefieldAnnotationLabel = ({
  documentTask
}: {
  documentTask: DocumentTaskFromResponse;
}) => {
  const label =
    lodash.get(
      documentTask.annotation_settings,
      WORKFLOW_ANNOTATION_SETTINGS_KEYS.DATEFIELD_LABEL
    ) || DEFAULT_LABELS[WORKFLOW_ANNOTATION_TYPES.DATEFIELD];

  const isRequired = isRequiredAnnotationField({
    documentTask,
    type: WORKFLOW_ANNOTATION_TYPES.DATEFIELD
  });

  return formatAnnotationLabel({
    label,
    isRequired
  });
};

export const getApprovalAnnotationLabel = () => {
  return formatAnnotationLabel({
    label: DEFAULT_LABELS[WORKFLOW_ANNOTATION_TYPES.APPROVAL],
    isRequired: true
  });
};
