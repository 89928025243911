import type * as reducersTypes from '../../reducers';
import type * as reducerTypes from './filterGroups.reducer.types';
import type * as jurisdictionsApiTypes from '../jurisdictions/jurisdictions.api.types';
import type * as uiLibTypes from '@compliance.ai/web-components';
import type * as selectorsTypes from './filterGroups.selectors.types';

import * as constants from './filterGroups.constants';

import { createSelector } from '@reduxjs/toolkit';
import { getAllJurisdictionsOptions } from '../jurisdictions/jurisdictions.selectors';

export const getFilterGroupsReducer = (
  state: reducersTypes.GlobalState
): reducerTypes.FilterGroupsState => state[constants.REDUCER_NAME];

export const getFilterLoadingState = createSelector(
  getFilterGroupsReducer,
  (reducer): reducerTypes.FilterGroupsState['isLoading'] => reducer.isLoading
);

export const getFilterGroups = createSelector(
  getFilterGroupsReducer,
  (reducer): reducerTypes.FilterGroupsState['items'] => {
    return reducer.items;
  }
);

export const getFilterJurisdictionGroups = createSelector(
  getFilterGroups,
  (filterGroups): reducerTypes.FilterGroupsState['items'] => {
    return filterGroups.filter(
      filterGroup => filterGroup.type === constants.FILTER_GROUP_TYPES.JURISDICTION
    );
  }
);

export const getFilterJurisdictionGroupsOptions = createSelector(
  getFilterJurisdictionGroups,
  (jurisdictionGroups): selectorsTypes.FilterGroupSelectOption[] => {
    return jurisdictionGroups.map(group => ({
      value: group.label,
      label: group.label,
      id: group.id,
      isPinned: true
    }));
  }
);

export const getFilterJurisdictionGroupedOptions = createSelector(
  [
    getAllJurisdictionsOptions,
    getFilterJurisdictionGroups,
    (state, optionsValueKey: string) => optionsValueKey
  ],
  (jurisdictionOptions, jurisdictionGroups, optionsValueKey) => {
    return jurisdictionGroups.reduce((groups, group) => {
      const jurisdictionOptionsWithUpdatedValue = jurisdictionOptions.map(
        (option: uiLibTypes.SelectOption<jurisdictionsApiTypes.JurisdictionFromResponse['id']>) => {
          return {
            ...option,
            value: option[optionsValueKey]
          };
        }
      );

      return {
        ...groups,
        [group.id]: jurisdictionOptionsWithUpdatedValue.filter(
          (
            option: uiLibTypes.SelectOption<jurisdictionsApiTypes.JurisdictionFromResponse['id']>
          ) => {
            return group.options.includes(option.id as number);
          }
        )
      };
    }, {} as selectorsTypes.FilterGroupedOptions);
  }
);

export const getFilterAgencyGroups = createSelector(
  getFilterGroups,
  (filterGroups): reducerTypes.FilterGroupsState['items'] => {
    return filterGroups.filter(
      filterGroup => filterGroup.type === constants.FILTER_GROUP_TYPES.AGENCY
    );
  }
);
