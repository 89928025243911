import { createSlice } from '@reduxjs/toolkit';
import lodash from 'lodash';
import {
  RECEIVE_RELATED_DOCUMENT_COUNT,
  REQUEST_DOCUMENT_DETAILS,
  RECEIVE_DOCUMENT_DETAILS,
  FAIL_DOCUMENT_DETAILS,
  RECEIVE_DOCUMENT_COMMENTS_COUNT,
  SET_DOCUMENT_SAVED_FOLDER_COUNT,
  MARK_DOCUMENT_BOOKMARKED,
  RESET_DOCUMENT_DETAILS
} from './documents.actions';

export const REDUCER_NAME = 'document_details';

export const INITIAL_DOCUMENT_DETAILS_STATE = {
  isFetching: false,
  isReady: false,
  documents: {},
  related_count: {}, // by id
  comments_count: {}, // by id
  saved_folders_count: {} // by id
};

export const { reducer } = createSlice({
  name: REDUCER_NAME,
  initialState: INITIAL_DOCUMENT_DETAILS_STATE,
  reducers: {},
  extraReducers: {
    [REQUEST_DOCUMENT_DETAILS]: (state, action) => {
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    },
    [RECEIVE_DOCUMENT_DETAILS]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isReady: true,
        documents: {
          ...state.documents,
          [action.document.id]: action.document
        }
      };
    },
    [FAIL_DOCUMENT_DETAILS]: (state, action) => {
      return {
        ...state,
        isFetching: false,
        isReady: false
      };
    },
    [RECEIVE_RELATED_DOCUMENT_COUNT]: (state, action) => {
      const doc_id = action.params.more_like_doc_id;
      const count = lodash.get(action.response, 'count', 0);

      return {
        ...state,
        related_count: {
          ...state.related_count,
          [doc_id]: count
        }
      };
    },
    [RECEIVE_DOCUMENT_COMMENTS_COUNT]: (state, action) => {
      const doc_id = action.params.comments_for_id;
      const count = lodash.get(action.response, 'count', 0);

      return {
        ...state,
        comments_count: {
          ...state.comments_count,
          [doc_id]: count
        }
      };
    },
    [SET_DOCUMENT_SAVED_FOLDER_COUNT]: (state, action) => {
      const { doc_id, count } = action.params;

      return {
        ...state,
        saved_folders_count: {
          ...state.saved_folders_count,
          [doc_id]: count
        }
      };
    },
    [MARK_DOCUMENT_BOOKMARKED]: (state, action) => {
      const new_state = lodash.cloneDeep(state); // XXX more targeted
      const my_docs = lodash.filter(new_state.documents, doc => {
        return lodash.includes(action.ids, doc.id);
      });
      for (const doc of my_docs) {
        doc.bookmarked = action.bookmarked_status;
      }

      for (const id of Object.keys(new_state.documents)) {
        const doc = new_state.documents[id];

        if (doc.children) {
          for (const child of doc.children.filter(c => lodash.includes(action.ids, c.id))) {
            child.bookmarked = action.bookmarked_status;
          }
        }
      }

      return new_state;
    },
    [RESET_DOCUMENT_DETAILS]: (state, action) => {
      return INITIAL_DOCUMENT_DETAILS_STATE;
    }
  }
});
