import type { FC } from 'react';
import type { AdvancedSearchBuilderProps } from './AdvancedSearchBuilder.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './AdvancedSearchBuilder.constants';
import * as searchConstants from 'constants/SearchTypes';
import * as common from 'common';
import * as elements from './elements';

import { Query, Utils as QbUtils, ImmutableTree, Config } from 'react-awesome-query-builder';
import {
  useAdvancedSearchBuilderData,
  useAdvancedSearchBuilderReduxActions,
  useAdvancedSearchBuilderHandlers,
  useAdvancedSearchBuilderLifecycles
} from './hooks';

import './_advanced_search_builder.scss';

const AdvancedSearchBuilder: FC<AdvancedSearchBuilderProps> = ({
  jsonLogicQuery,
  currentAlert = null,
  conceptValues,
  setSearchValue,
  doSearch,
  getUrlParams,
  handleConceptSelect
}) => {
  const { localState, localActions, reduxState, formattedData } = useAdvancedSearchBuilderData();

  const reduxActions = useAdvancedSearchBuilderReduxActions();

  const handlers = useAdvancedSearchBuilderHandlers({
    props: {
      jsonLogicQuery,
      currentAlert,
      conceptValues,
      setSearchValue,
      doSearch,
      getUrlParams,
      handleConceptSelect
    },
    localActions,
    reduxState,
    reduxActions,
    localState,
    formattedData
  });

  useAdvancedSearchBuilderLifecycles({
    onDidMount: handlers.handleDidMount,
    onUpdateSearchValue: handlers.handleUpdateSearchValue
  });

  if (!localState.dataReady) {
    return null;
  }

  return (
    <uiLib.ErrorBoundary>
      <div className="advanced-search-builder-container" data-testid={constants.TEST_IDS.CONTAINER}>
        {localState.isLoading && <uiLib.Loader withoutBackground={false} />}
        <div className="advanced-search-header" data-testid={constants.TEST_IDS.CONCEPTS}>
          <h4 className="header-label">{constants.TEXTS.LABEL[formattedData.selectType]}</h4>
          <div className="header-dropdown">
            {localState.isConceptsSelection ? (
              <uiLib.GroupSelect
                type={uiLib.SELECT_TYPES.ALTERNATIVE}
                value={conceptValues}
                onChange={handleConceptSelect}
                options={formattedData.conceptOptions}
                placeholder={constants.TEXTS.LABEL.CONCEPT_SELECT}
              />
            ) : (
              <common.DefaultFiltersViewSelect
                onSelectChange={handlers.handleDefaultViewChange}
                view={localState.defaultView}
              />
            )}
          </div>
          <div className="header-buttons">
            <uiLib.Button
              className="header-button"
              type={uiLib.BUTTON_TYPES.LINK}
              onClick={handlers.handleSelectToggle}
            >
              {constants.TEXTS.BUTTON.SELECT[formattedData.selectType]}
            </uiLib.Button>
            <uiLib.Button
              className="header-button"
              type={uiLib.BUTTON_TYPES.PRIMARY}
              onClick={handlers.handleApplySelection}
            >
              {constants.TEXTS.BUTTON.APPLY[formattedData.selectType]}
            </uiLib.Button>
          </div>
        </div>
        <Query
          {...(localState.config as Config)}
          value={localState.tree as ImmutableTree}
          onChange={handlers.handleBuilderChange}
          renderBuilder={handlers.handleRenderBuilder}
        />
        <div className="advanced-search-builder-footer" data-testid={constants.TEST_IDS.FOOTER}>
          <div className="footer-builder-structure">
            <div className="advanced-search-builder-footer__text-label">
              {constants.TEXTS.LABEL.FILTER}:
              <uiLib.Button
                type={uiLib.BUTTON_TYPES.LINK}
                onClick={handlers.handlePlainTextModalToggle(true)}
                className="advanced-search-builder-footer__text-button"
              >
                View as text
              </uiLib.Button>
              {localState.isPlainTextModalOpen && (
                <elements.PlainTextModal
                  tree={localState.tree as ImmutableTree}
                  config={localState.config as Config}
                  onClose={handlers.handlePlainTextModalToggle(false)}
                />
              )}
            </div>
            <div>
              {QbUtils.queryString(
                localState.tree as ImmutableTree,
                localState.config as Config,
                true
              )}
            </div>
          </div>
          <div className="footer-buttons">
            <uiLib.Button type={uiLib.BUTTON_TYPES.LINK} onClick={handlers.handleClearBuilder}>
              {constants.TEXTS.BUTTON.CLEAR_FILTER}
            </uiLib.Button>
            {currentAlert && reduxState.current_view.editAlertId ? (
              <>
                <uiLib.Button
                  type={uiLib.BUTTON_TYPES.SECONDARY}
                  onClick={handlers.handlePreviewAlert}
                >
                  {constants.TEXTS.BUTTON.PREVIEW}
                </uiLib.Button>
                <uiLib.Button
                  type={uiLib.BUTTON_TYPES.PRIMARY}
                  onClick={handlers.handleUpdateAlert}
                >
                  {constants.TEXTS.BUTTON.UPDATE_ALERT}
                </uiLib.Button>
              </>
            ) : (
              <common.SearchButton
                type={currentAlert ? uiLib.BUTTON_TYPES.SECONDARY : uiLib.BUTTON_TYPES.PRIMARY}
                onClick={handlers.handleSearch}
                disabled={formattedData.isSearchDisabled}
                queryParams={getUrlParams()}
                text={constants.TEXTS.BUTTON.SEARCH}
                searchType={searchConstants.SEARCH_TYPES.ADVANCED_SEARCH}
                className=""
              />
            )}
          </div>
        </div>
      </div>
    </uiLib.ErrorBoundary>
  );
};

export default AdvancedSearchBuilder;
