import type { DiffTabHandlers } from './useDiffTabHandlers.types';

import { useDidMount } from 'utils/hooks';
import { useWillUnmount } from '@compliance.ai/web-components';

export const useDiffTabLifecycle = ({
  onOverlayOpenOnMount,
  onOverlayCloseOnUnmount
}: {
  onOverlayOpenOnMount: DiffTabHandlers['handleOverlayOpenOnMount'];
  onOverlayCloseOnUnmount: DiffTabHandlers['handleOverlayCloseOnUnmount'];
}) => {
  useDidMount(onOverlayOpenOnMount);
  useWillUnmount(onOverlayCloseOnUnmount);
};
