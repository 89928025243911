import * as uiLib from '@compliance.ai/web-components';
import { fetch } from 'utils/api';
import { DEFAULTS_GET_ACTIONS } from 'constants/Defaults';
import { AUTHENTICATION_OPTIONS_KEYS } from 'fetch';

export function api_updateDashboardLayout(params, rnToken) {
  return fetch({
    url: '/dashboard_layout',
    method: 'POST',
    dataType: 'DashboardLayout',
    params,
    customToken: rnToken
  });
}

export function api_readNewFeatureTip(email, data) {
  return fetch({
    url: `/users/${encodeURIComponent(email)}`,
    params: data,
    dataType: 'Feature',
    method: 'POST'
  });
}

export function api_fetchAppNotifications(params) {
  return fetch({
    url: '/app-notifications/all',
    dataType: 'AppNotifications',
    params,
    method: 'GET'
  });
}

export function api_createAppNotification(data) {
  return fetch({
    url: '/app-notifications',
    dataType: 'AppNotifications',
    method: 'POST',
    params: data
  });
}

export function api_editAppNotification(editNotificationId, data) {
  return fetch({
    url: `/app-notifications/${editNotificationId}`,
    dataType: 'AppNotifications',
    params: data,
    method: 'POST'
  });
}

export function api_updateUser(email, params, rnToken) {
  return fetch({
    url: `/users/${encodeURIComponent(email)}`,
    dataType: 'User',
    params,
    method: 'POST',
    customToken: rnToken,
    shouldHideErrorNotifications: true
  });
}

export function api_getUserCreatedDocuments(status) {
  return fetch({
    url: `/user_created_documents`,
    dataType: 'UserCreatedDocuments',
    method: 'GET',
    params: { status }
  });
}

export function api_getCurrentUser(rnToken) {
  return fetch({
    url: '/current_user',
    dataType: 'CurrentUser',
    method: 'GET',
    customToken: rnToken
  });
}

export function api_getAllUsers() {
  return fetch({
    url: '/users',
    dataType: 'Users',
    method: 'GET'
  });
}

export function api_postSearchQuery(data) {
  return fetch({
    url: '/search_queries',
    params: data,
    method: 'POST'
  });
}

export function api_rateSearchResult(data) {
  return fetch({
    url: '/rated_results',
    params: data,
    method: 'POST'
  });
}

export function api_getUser(email) {
  return fetch({
    url: `/users/${email}`,
    method: 'GET'
  });
}

export function api_authorizePCUser({ email, pcSourceId, docId }) {
  return fetch({
    url: '/premium_content/authorize',
    method: 'POST',
    params: { email, premium_content_source_id: pcSourceId, document_id: docId }
  });
}

export function api_addUserToOrgFromInvite(params, rnToken) {
  return fetch({
    url: '/join_organization_from_invite',
    method: 'POST',
    customToken: rnToken,
    params
  });
}

export function api_fetchUserDefaults(params, shouldUseAPIToken = false) {
  return fetch({
    url: '/user_defaults',
    method: 'GET',
    params,
    authenticationType: shouldUseAPIToken ? AUTHENTICATION_OPTIONS_KEYS.API_KEY : undefined,
    dataType: 'User defaults'
  });
}

export function api_updateUserDefaults(params) {
  return fetch({
    url: '/user_defaults',
    method: 'POST',
    params
  });
}

export function api_fetchAllUserDefaults() {
  return Promise.all([
    api_fetchUserDefaults({
      action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_TOPICS
    }),
    api_fetchUserDefaults({
      action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_AGENCIES
    }),
    api_fetchUserDefaults({
      action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_ENTITIES
    }),
    api_fetchUserDefaults({
      action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_CATEGORIES
    }),
    api_fetchUserDefaults({
      action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_CONCEPTS
    }),
    api_fetchUserDefaults({
      action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_REGULATIONS
    })
  ]);
}

export function api_getRSSConfigurations(params) {
  return fetch({
    url: '/rss_configurations',
    method: 'GET',
    params
  });
}

export function api_editRSSConfiguration(uuid, params) {
  return fetch({
    url: `/rss_configuration/${uuid}`,
    method: 'POST',
    params
  });
}

export function api_createRSSConfiguration(params) {
  return fetch({
    url: `/rss_configuration`,
    method: 'POST',
    params
  });
}

export const api_getUserPreferences = () => {
  return fetch({
    url: '/user_preferences',
    method: 'GET',
    params: { default_timezone: uiLib.getLocalTimezone() }
  });
};

export const api_postUserPreferences = params => {
  return fetch({
    url: '/user_preferences',
    method: 'POST',
    params
  });
};
