import type { PlainTextModalProps } from './PlainTextModal.types';

import { Modal, ErrorBoundary } from '@compliance.ai/web-components';
import { PlainText } from './elements';
import './_plain-text-modal.scss';

export const PlainTextModal = ({ tree, config, onClose }: PlainTextModalProps) => {
  return (
    <Modal
      isOpen
      onClose={onClose}
      title="View advanced search as text"
      className="plain-text-modal__container"
      childContainerClassName="plain-text-modal__inner-container"
    >
      <ErrorBoundary>
        <div className="plain-text-modal__children-aligner">
          <PlainText tree={tree} config={config} />
        </div>
      </ErrorBoundary>
    </Modal>
  );
};
