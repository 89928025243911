import PropTypes from 'prop-types';
import classnames from 'classnames';

import * as uiLib from '@compliance.ai/web-components';

import './_options-popup.scss';

export const TEST_ID = 'options-popup';

function OptionsPopup({ className, children, onClickOutside }) {
  return (
    <uiLib.ClickAwayListener onClickAway={onClickOutside}>
      <div data-testid={TEST_ID} className={classnames(className, 'options-popup__container')}>
        <div className="options-popup__arrow-up" />
        {children}
      </div>
    </uiLib.ClickAwayListener>
  );
}

export default OptionsPopup;

OptionsPopup.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  onClickOutside: PropTypes.func
};

OptionsPopup.defaultProps = {
  className: undefined
};
