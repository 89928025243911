import type { AppNavigationData } from './useAppNavigationData.types';
import type { AppNavigationHandlers } from './useAppNavigationHandlers.types';

import * as firstLoginConstants from 'constants/FirstLogin';
import * as navBarConstants from './useAppNavigationNavBarConfig.constants';
import * as helpers from './useAppNavigationNavBarConfig.helpers';
import * as uiLib from '@compliance.ai/web-components';
import * as elements from '../elements';
import * as env from 'shared/config';

export const useAppNavigationNavBarConfig = ({
  localState,
  formattedData,
  events
}: {
  localState: AppNavigationData['localState'];
  formattedData: AppNavigationData['formattedData'];
  events: {
    onNavBarToggle: AppNavigationHandlers['handleNavBarToggle'];
    onNavBarItemClick: AppNavigationHandlers['handleNavBarItemClick'];
    onNavBarTimelineClick: AppNavigationHandlers['handleNavBarTimelineClick'];
    onNavBarEnforcementClick: AppNavigationHandlers['handleNavBarEnforcementClick'];
    onNavBarNewsClick: AppNavigationHandlers['handleNavBarNewsClick'];
  };
}): uiLib.NavBarConfig => {
  const navBarItems: uiLib.NavBarConfig['items'] = [
    {
      label: navBarConstants.NAV_BAR_ITEM_LABELS.DEFAULT_FILTER,
      icon: <uiLib.IconDefaultFilter id={firstLoginConstants.JOYRIDE_TARGET_IDS.FILTERS_TAB} />,
      onClick: events.onNavBarItemClick(
        navBarConstants.NAV_BAR_ITEMS_SOURCES_CONFIGS[
          navBarConstants.NAV_BAR_ITEM_LABELS.DEFAULT_FILTER
        ]
      )
    },
    {
      label: navBarConstants.NAV_BAR_ITEM_LABELS.DASHBOARD,
      icon: <uiLib.IconDashboard />,
      onClick: events.onNavBarItemClick(
        navBarConstants.NAV_BAR_ITEMS_SOURCES_CONFIGS[navBarConstants.NAV_BAR_ITEM_LABELS.DASHBOARD]
      )
    },
    {
      label: navBarConstants.NAV_BAR_ITEM_LABELS.TIMELINE,
      icon: <uiLib.IconTimeline />,
      onClick: events.onNavBarTimelineClick
    },
    {
      label: navBarConstants.NAV_BAR_ITEM_LABELS.ENFORCEMENT,
      icon: <uiLib.IconEnforcement />,
      onClick: events.onNavBarEnforcementClick
    },
    {
      label: navBarConstants.NAV_BAR_ITEM_LABELS.NEWS,
      icon: <uiLib.IconLanguage />,
      onClick: events.onNavBarNewsClick
    },
    {
      label: navBarConstants.NAV_BAR_ITEM_LABELS.INSIGHTS,
      icon: <uiLib.IconPieChart />,
      onClick: events.onNavBarItemClick(
        navBarConstants.NAV_BAR_ITEMS_SOURCES_CONFIGS[navBarConstants.NAV_BAR_ITEM_LABELS.INSIGHTS]
      )
    },
    {
      label: navBarConstants.NAV_BAR_ITEM_LABELS.FOLDERS,
      icon: <uiLib.IconFolder />,
      onClick: events.onNavBarItemClick(
        navBarConstants.NAV_BAR_ITEMS_SOURCES_CONFIGS[navBarConstants.NAV_BAR_ITEM_LABELS.FOLDERS]
      )
    },
    ...(env.notificationsEnabled
      ? [
          {
            label: navBarConstants.NAV_BAR_ITEM_LABELS.NOTIFICATIONS,
            icon: <uiLib.IconNotifications />,
            onClick: events.onNavBarItemClick(
              navBarConstants.NAV_BAR_ITEMS_SOURCES_CONFIGS[
                navBarConstants.NAV_BAR_ITEM_LABELS.NOTIFICATIONS
              ]
            )
          }
        ]
      : []),
    {
      label: navBarConstants.NAV_BAR_ITEM_LABELS.RESOURCES,
      icon: <uiLib.IconLibraryBooks />,
      onClick: events.onNavBarItemClick(
        navBarConstants.NAV_BAR_ITEMS_SOURCES_CONFIGS[navBarConstants.NAV_BAR_ITEM_LABELS.RESOURCES]
      )
    },
    ...(env.workflowEnabled
      ? [
          {
            label: navBarConstants.NAV_BAR_ITEM_LABELS.TASKS,
            icon: <uiLib.IconCheckboxFilled />,
            onClick: events.onNavBarItemClick(
              navBarConstants.NAV_BAR_ITEMS_SOURCES_CONFIGS[
                navBarConstants.NAV_BAR_ITEM_LABELS.TASKS
              ]
            )
          }
        ]
      : [])
  ];

  const markedNavBarItems: uiLib.NavBarConfig['items'] = navBarItems
    .map(helpers.markSelectedNavBarItems(localState))
    .map(helpers.markRestrictedNavBarItems(formattedData));

  return {
    authElement: <elements.NavBarAuth />,
    items: markedNavBarItems,
    isOpen: localState.isNavBarOpened,
    onOpen: events.onNavBarToggle(true),
    onClose: events.onNavBarToggle(false)
  };
};
