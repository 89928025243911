import type { TaskStatusProps } from '../TaskStatus.types';
import type { TaskStatusData } from './useTaskStatusData.types';
import type { GlobalState } from 'shared/reducers';

import { useSelector } from 'react-redux';
import { isUserCurrentUser } from 'shared/features/user/user.selectors';
import {
  APPROVAL_ANNOTATION_STATUSES,
  WORKFLOW_ANNOTATION_TYPES
} from 'shared/features/workflow/workflow.constants';
import { useDocumentTaskAnnotations } from 'shared/features/workflow/hooks';
import { isDocTaskBlocked } from 'shared/features/workflow/workflow.helpers';

export const useTaskStatusData = ({
  documentTask,
  areAllAnnotationsCompleted
}: Pick<TaskStatusProps, 'documentTask' | 'areAllAnnotationsCompleted'>): TaskStatusData => {
  const approvalAnnotation = useDocumentTaskAnnotations(documentTask)[
    WORKFLOW_ANNOTATION_TYPES.APPROVAL
  ];

  const reduxState = useSelector<GlobalState, TaskStatusData['reduxState']>(state => ({
    isAssignee: isUserCurrentUser(documentTask.assignee)(state as never)
  }));

  const formattedData = {
    isDisabled:
      isDocTaskBlocked(documentTask.is_blocked) ||
      !reduxState.isAssignee ||
      !areAllAnnotationsCompleted ||
      (approvalAnnotation.isAvailable &&
        approvalAnnotation.status !== APPROVAL_ANNOTATION_STATUSES.APPROVED)
  };

  return {
    reduxState,
    formattedData
  };
};
