import type { ActionCellData } from './useActionCellData.types';
import type { ActionCellProps } from '../ActionCell.types';

import { useState, useMemo } from 'react';

import * as helpers from './useActionCellData.helpers';

export const useActionCellData = ({
  props
}: {
  props: Pick<ActionCellProps, 'row'>;
}): ActionCellData => {
  const [accessLevel, setAccessLevel] = useState<ActionCellData['localState']['accessLevel']>(
    helpers.getInitialAccessLevel(props.row)
  );

  const localState: ActionCellData['localState'] = {
    accessLevel
  };

  const localActions: ActionCellData['localActions'] = useMemo(
    () => ({
      setAccessLevel
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
