import { REQUEST_ALL_PLANS, RECEIVE_ALL_PLANS } from './plans.actions';

export const getInitialState = () => ({
  isFetching: false,
  isReady: false,
  items: []
});

export const all_plans = (state = getInitialState(), action) => {
  switch (action.type) {
    case REQUEST_ALL_PLANS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_ALL_PLANS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.plans
      };
    default:
      return state;
  }
};
