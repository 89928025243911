import type { ReadableQueryData } from './useReadableQueryData.types';
import type { ReadableQueryProps } from '../ReadableQuery.types';

import { useMemo, useState } from 'react';
import * as queryBuilder from 'react-awesome-query-builder';

export const useReadableQueryData = ({
  tree,
  config
}: Pick<ReadableQueryProps, 'tree' | 'config'>): ReadableQueryData => {
  const [isPlainTextModalOpen, setIsPlainTextModalOpen] = useState(false);

  const localState: ReadableQueryData['localState'] = {
    isPlainTextModalOpen
  };

  const localActions: ReadableQueryData['localActions'] = useMemo(
    () => ({
      setIsPlainTextModalOpen
    }),
    []
  );

  const formattedData: ReadableQueryData['formattedData'] = useMemo(() => {
    const queryForDisplay = queryBuilder.Utils.queryString(tree, config, true);

    return {
      queryForDisplay
    };
  }, [config, tree]);

  return {
    localState,
    localActions,
    formattedData
  };
};
