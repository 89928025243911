import type { FC } from 'react';
import type { DocumentsDiffProps } from './DocumentDiff.types';

import classnames from 'classnames';
import ReactHtmlParser from 'react-html-parser';

import './_documents-diff.scss';

export const DocumentsDiff: FC<DocumentsDiffProps> = ({ diff, className }) => {
  if (!diff) {
    return null;
  }

  return (
    <div className={classnames('document-diff__container', className)}>
      <div className="document-diff__legend-container">
        <span className="document-diff__legend-entry">
          <div className="document-diff__color-block--additions" />
          <span>Additions</span>
        </span>
        <span className="document-diff__legend-entry">
          <div className="document-diff__color-block--deletions" />
          <span>Deletions</span>
        </span>
      </div>
      <div className="document-diff__results">{ReactHtmlParser(diff)}</div>
    </div>
  );
};
