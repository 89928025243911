import type { WorkflowPanelTaskProps } from './WorkflowPanelTask.types';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';
import * as taskConstants from 'constants/TaskConstants';

import {
  useWorkflowPanelTaskData,
  useWorkflowPanelTaskHandlers,
  useWorkflowPanelTaskMutations
} from './hooks';

import './_workflow-panel-task.scss';

export const WorkflowPanelTask = ({
  areDetailsRevealed,
  onRevealIconClick,
  document,
  documentTask,
  onTabChange,
  shouldShowObligationsLink
}: WorkflowPanelTaskProps) => {
  const { formattedData } = useWorkflowPanelTaskData({ documentTask });

  const handlers = useWorkflowPanelTaskHandlers({
    props: {
      onTabChange
    }
  });

  const mutations = useWorkflowPanelTaskMutations();

  return (
    <div className="workflow-panel-task__container">
      {Object.values(mutations).some(mutaion => mutaion.isLoading) && (
        <uiLib.Loader withoutBackground={false} />
      )}
      <uiLib.Tooltip
        isDisabled={!formattedData.isBlocked}
        title="This task is blocked until upstream tasks are marked complete"
      >
        <div className="workflow-panel-task__info-row">
          <div className="workflow-panel-task__info-cell--status">
            <elements.TaskStatus
              documentTask={documentTask}
              areAllAnnotationsCompleted={formattedData.areAllAnnotationsCompleted}
              value={formattedData.documentTaskValues[taskConstants.EDITABLE_FIELDS_KEYS.STATUS]}
              onChange={mutations.editDocumentTaskMutation.mutate}
            />
          </div>
          <div className="workflow-panel-task__info-cell--name">
            <elements.TaskName documentTask={documentTask} />
          </div>
          <elements.TaskDeadline
            documentTask={documentTask}
            document={document}
            value={formattedData.documentTaskValues[taskConstants.EDITABLE_FIELDS_KEYS.DEADLINE]}
            onChange={mutations.editDocumentTaskMutation.mutate}
          />
          <div className="workflow-panel-task__info-cell--assignee">
            <elements.TaskAssignee
              documentTask={documentTask}
              document={document}
              assignee={
                formattedData.documentTaskValues[taskConstants.EDITABLE_FIELDS_KEYS.ASSIGNEE]
              }
              onChange={mutations.editDocumentTaskMutation.mutate}
            />
          </div>
          <div
            className="workflow-panel-task__expand-button"
            onClick={!formattedData.isBlocked ? onRevealIconClick : undefined}
          >
            {!formattedData.isBlocked &&
              (areDetailsRevealed ? (
                <uiLib.IconRemove className="workflow-panel-task__expand-icon" />
              ) : (
                <uiLib.IconAdd className="workflow-panel-task__expand-icon" />
              ))}
          </div>
        </div>
      </uiLib.Tooltip>
      {areDetailsRevealed && (
        <>
          <div className="workflow-panel-task__description-container">
            <div className="workflow-panel-task__description">
              <uiLib.LabeledField label="Description" isContrast>
                <div className="workflow-panel-task__description-value">
                  {documentTask.description}
                </div>
              </uiLib.LabeledField>
            </div>
          </div>
          <div className="workflow-panel-task__annotations-container">
            {shouldShowObligationsLink && formattedData.isObligationsLinkVisible && (
              <div className="workflow-panel-task__annotation-item">
                <div
                  onClick={handlers.handleObligationsLinkClick}
                  className="workflow-panel-task__obligations-link"
                >
                  Go to Obligations
                </div>
              </div>
            )}
            <div className="workflow-panel-task__annotation-item">
              <elements.TaskDropdownsAnnotation
                documentTask={documentTask}
                isEditable={formattedData.areAnnotationsEditable}
                onChange={mutations.updateDropdownMutation.mutate}
              />
            </div>
            <div className="workflow-panel-task__annotation-item">
              <elements.TaskAssigneeAnnotation
                documentTask={documentTask}
                value={
                  formattedData.documentTaskValues[
                    taskConstants.EDITABLE_FIELDS_KEYS.ANNOTATIONS_ASSIGNEE
                  ]
                }
                isEditable={formattedData.areAnnotationsEditable}
                onChange={mutations.editDocumentTaskMutation.mutate}
              />
            </div>
            <div className="workflow-panel-task__annotation-item">
              <elements.TaskDatefieldsAnnotation
                documentTask={documentTask}
                value={
                  formattedData.documentTaskValues[
                    taskConstants.EDITABLE_FIELDS_KEYS.ANNOTATION_DATE
                  ]
                }
                isEditable={formattedData.areAnnotationsEditable}
                onChange={mutations.editDocumentTaskMutation.mutate}
              />
            </div>
            <div className="workflow-panel-task__annotation-item">
              <elements.TaskAttachmentsAnnotation
                documentTask={documentTask}
                files={
                  formattedData.documentTaskValues[taskConstants.EDITABLE_FIELDS_KEYS.ATTACHMENTS]
                }
                isEditable={formattedData.areAnnotationsEditable}
              />
            </div>
            <div className="workflow-panel-task__annotation-item">
              <elements.TaskCommentsAnnotation
                documentTask={documentTask}
                commentThreads={
                  formattedData.documentTaskValues[taskConstants.EDITABLE_FIELDS_KEYS.COMMENTS]
                }
                areRepliesEnabled={formattedData.areRepliesEnabled}
                isThreadCreationEnabled={formattedData.isTaskAssignee}
                onCommentThreadCreate={mutations.createDocumentTaskCommentThreadMutation.mutate}
                onCommentCreate={mutations.createCommentMutation.mutate}
                onCommentEdit={mutations.editCommentMutation.mutate}
                onCommentDelete={mutations.deleteCommentMutation.mutate}
              />
            </div>
            <div className="workflow-panel-task__annotation-item">
              <elements.TaskApprovalAnnotation
                document={document}
                documentTask={documentTask}
                areAllAnnotationsCompleted={formattedData.areAllAnnotationsCompleted}
                onChange={mutations.editDocumentTaskMutation.mutateAsync}
              />
            </div>
          </div>
        </>
      )}
      {formattedData.isTaskOverdue && (
        <div className="workflow-panel-task__status-badge">
          <uiLib.StatusBadge type={uiLib.STATUS_BADGE_TYPE.OVERDUE} tooltipText="Overdue" />
        </div>
      )}
    </div>
  );
};
