import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';
import {
  changeCurrentStep as reduxChangeCurrentStep,
  changeOrgProfile as reduxChangeOrgProfile,
  fetchOrganizationProfile as reduxFetchOrganizationProfile,
  fetchSuggestedCompanies as reduxFetchSuggestedCompanies,
  resetSuggestedCompanies as reduxResetSuggestedCompanies,
  saveOrganizationProfile as reduxSaveOrganizationProfile
} from '../organizationProfile.actions';

export const useOrgProfileReduxActions = () => {
  const dispatch = useDispatch();

  const fetchSuggestedCompanies = useCallback(
    (params = {}) => {
      dispatch(
        reduxFetchSuggestedCompanies({
          limit: 20,
          ...params
        })
      );
    },
    [dispatch]
  );

  const resetSuggestedCompanies = useCallback(() => {
    dispatch(reduxResetSuggestedCompanies());
  }, [dispatch]);

  const changeCurrentStep = useCallback(
    step => {
      dispatch(reduxChangeCurrentStep(step));
    },
    [dispatch]
  );

  const changeOrgProfile = useCallback(
    updatedOrgProfile => {
      dispatch(reduxChangeOrgProfile(updatedOrgProfile));
    },
    [dispatch]
  );

  const getOrgProfile = useCallback(() => dispatch(reduxFetchOrganizationProfile()), [dispatch]);

  const saveOrgProfile = useCallback(
    updatedOrgProfile => dispatch(reduxSaveOrganizationProfile(updatedOrgProfile)),
    [dispatch]
  );

  return useMemo(
    () => ({
      fetchSuggestedCompanies,
      resetSuggestedCompanies,
      changeCurrentStep,
      changeOrgProfile,
      getOrgProfile,
      saveOrgProfile
    }),
    [
      changeCurrentStep,
      changeOrgProfile,
      fetchSuggestedCompanies,
      getOrgProfile,
      resetSuggestedCompanies,
      saveOrgProfile
    ]
  );
};
