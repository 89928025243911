import { SOURCE_AUTH_TYPES } from '../../PremiumContentUnlockButton.constants';

export const ERROR_MESSAGES = {
  [SOURCE_AUTH_TYPES.PEER_TO_PEER]: ({ sourceName }) => (
    <p>{`Please go to ${sourceName} to update your subscription.`}</p>
  ),
  [SOURCE_AUTH_TYPES.EMAIL]: ({ sourceName, currentUserEmail, documentUrl, onLinkClick }) => (
    <>
      <p>
        {`Please make sure you have purchased this product through the email address provided: ${currentUserEmail}.`}
      </p>
      <p>
        {`If you have purchased this document within the past 24 hours using this email address,
          your order is still being processed. Otherwise, you can order this document through `}
        <a onClick={onLinkClick} href={documentUrl} target="_blank" rel="noopener noreferrer">
          {sourceName}
        </a>
        {'.'}
      </p>
    </>
  ),
  [SOURCE_AUTH_TYPES.COMPLIANCE]: ({ currentUserEmail }) => (
    <>
      <p>
        {`Please make sure you have purchased this product through the email address provided: ${currentUserEmail}.`}
      </p>
      <p>
        {`If you have purchased this document within the past 24 hours using this email address,
          your order is still being processed. Otherwise, you can order this document through support@compliance.ai.`}
      </p>
    </>
  )
};
