import PropTypes from 'prop-types';
import { ContentHeader } from 'components/Content/elements';

export const TEST_ID = 'timeline-toolbar-filter-menu';

export const FilterMenu = ({ show }) => {
  return show ? (
    <div className="toolbarContainer" data-testid={TEST_ID}>
      <div className="toolbar-icons timeline-action-bar">
        <ContentHeader bulk_select />
      </div>
    </div>
  ) : null;
};

FilterMenu.propTypes = {
  show: PropTypes.bool
};

FilterMenu.defaultProps = {
  show: false
};

export default FilterMenu;
