import { apiFetchDefaultStateAgencies } from './defaultStateAgencies.api';

const ACTION_TYPES_PREFIX = 'defaultStateAgencies';

export const FETCH_DEFAULT_STATE_AGENCIES_BEGIN = `${ACTION_TYPES_PREFIX}/FETCH_DEFAULT_STATE_AGENCIES_BEGIN`;
export const FETCH_DEFAULT_STATE_AGENCIES_SUCCESS = `${ACTION_TYPES_PREFIX}/FETCH_DEFAULT_STATE_AGENCIES_SUCCESS`;
export const FETCH_DEFAULT_STATE_AGENCIES_ERROR = `${ACTION_TYPES_PREFIX}/FETCH_DEFAULT_STATE_AGENCIES_ERROR`;

export const fetchDefaultStateAgenciesBegin = () => ({
  type: FETCH_DEFAULT_STATE_AGENCIES_BEGIN
});

export const fetchDefaultStateAgenciesSuccess = defaultStateAgencies => ({
  type: FETCH_DEFAULT_STATE_AGENCIES_SUCCESS,
  data: defaultStateAgencies
});

export const fetchDefaultstateAgenciesError = () => ({
  type: FETCH_DEFAULT_STATE_AGENCIES_ERROR
});

export const fetchDefaultStateAgencies = () => dispatch => {
  dispatch(fetchDefaultStateAgenciesBegin());
  return apiFetchDefaultStateAgencies()
    .then(res => {
      dispatch(fetchDefaultStateAgenciesSuccess(res));
      return res;
    })
    .catch(error => {
      dispatch(fetchDefaultstateAgenciesError());
      return error;
    });
};
