import type * as eitlLabelsTypes from 'shared/features/eitlLabels/eitlLabels.types';
import type {
  DatePickerWithModifiersValue,
  FiltersProps,
  SelectOption,
  TextFieldWithModifiersValue
} from '@compliance.ai/web-components';
import type { QueryClauseConverterFunc } from './useAdvancedSearchQuery.types';

import * as uiLib from '@compliance.ai/web-components';

import {
  ADVANCED_SEARCH_KEYS,
  QUERY_CONJUNCTIONS,
  QUERY_TRANSLATION_TYPES
} from 'constants/AdvancedSearch';
import {
  SINGLE_RELATIVE_DATES,
  RELATIVE_DATE_UNITS_OF_TIME_TO_QUERY_PARAMS
} from 'constants/RelativeDates';
import { FILTER_KEY } from 'constants/PrimaryFilter';
import {
  HAS_ANNOTATIONS,
  HAS_SENTENCES,
  IS_ORGANIZATION_DOCUMENT,
  IS_PREMIUM_CONTENT,
  MY_ORG_TASKS,
  MY_TASKS,
  OBLIGATIONS
} from 'shared/features/filters/filters.constants';
import { BASIC_FILTERS_TO_ADVANCED_SEARCH_CONFIG } from './useAdvancedSearchQuery.constants';

export const convertInClause: QueryClauseConverterFunc<
  unknown,
  {
    shouldUseLabelsAsValues: boolean;
  }
> = ({ key, value, translationOptions }) => {
  if (!Array.isArray(value) || !value.length) {
    return '';
  }

  const valueKey = translationOptions?.shouldUseLabelsAsValues ? 'label' : 'value';

  return `${key} IN [${value.map(v => `"${v?.[valueKey] ?? v}"`).join(', ')}]`;
};

export const convertExact: QueryClauseConverterFunc<
  unknown,
  {
    shouldUseLabelsAsValues: boolean;
  }
> = ({ key, value, translationOptions }): string => {
  if (!value) {
    return '';
  }

  const valueKey = translationOptions?.shouldUseLabelsAsValues ? 'label' : 'value';

  if (Array.isArray(value)) {
    return `${key} = "${(value[0] as SelectOption)?.[valueKey] ?? value[0]}"`;
  }

  return `${key} = "${(value as SelectOption)?.[valueKey] ?? value}"`;
};

export const convertInOrExact: QueryClauseConverterFunc<
  unknown,
  {
    shouldUseLabelsAsValues: boolean;
  }
> = ({ key, value, translationOptions }) => {
  if (!Array.isArray(value) || !value.length) {
    return '';
  }

  if (value.length === 1) {
    return convertExact({
      key,
      value,
      translationOptions
    });
  }

  return convertInClause({
    key,
    value,
    translationOptions
  });
};

export const convertDateWithModifiers: QueryClauseConverterFunc<
  DatePickerWithModifiersValue | null,
  {
    startDateKey: ADVANCED_SEARCH_KEYS;
    endDateKey: ADVANCED_SEARCH_KEYS;
    secondaryCheckboxKey?: ADVANCED_SEARCH_KEYS;
  }
> = ({ key, value, translationOptions }) => {
  if (!value) {
    return '';
  }

  let advancedSearch = '';

  switch (value?.modifier) {
    case uiLib.DATE_MODIFIERS.BETWEEN: {
      advancedSearch = `
        ${translationOptions?.startDateKey} >= ${uiLib.formatDate(value.dateA, {
        format: uiLib.DATE_FORMATS.API_DATE
      })}
        ${QUERY_CONJUNCTIONS.AND}
        ${translationOptions?.endDateKey} <= ${uiLib.formatDate(value.dateB, {
        format: uiLib.DATE_FORMATS.API_DATE
      })}
      `;
      break;
    }

    case uiLib.DATE_MODIFIERS.ON: {
      advancedSearch = `${key} = ${uiLib.formatDate(value.dateA, {
        format: uiLib.DATE_FORMATS.API_DATE
      })}`;
      break;
    }

    case uiLib.DATE_MODIFIERS.ON_OR_BEFORE: {
      advancedSearch = `${key} <= ${uiLib.formatDate(value.dateA, {
        format: uiLib.DATE_FORMATS.API_DATE
      })}`;
      break;
    }

    case uiLib.DATE_MODIFIERS.ON_OR_AFTER: {
      advancedSearch = `${key} >= ${uiLib.formatDate(value.dateA, {
        format: uiLib.DATE_FORMATS.API_DATE
      })}`;
      break;
    }

    case uiLib.DATE_MODIFIERS.TODAY: {
      advancedSearch = `${key} = ${SINGLE_RELATIVE_DATES.TODAY}`;
      break;
    }

    case uiLib.DATE_MODIFIERS.YESTERDAY: {
      advancedSearch = `${key} = ${SINGLE_RELATIVE_DATES.YESTERDAY}`;
      break;
    }

    case uiLib.DATE_MODIFIERS.TOMORROW: {
      advancedSearch = `${key} = ${SINGLE_RELATIVE_DATES.TOMORROW}`;
      break;
    }

    case uiLib.DATE_MODIFIERS.IN_THE_LAST: {
      const count = value?.relativeUnitsOfTimeCount;
      const units =
        RELATIVE_DATE_UNITS_OF_TIME_TO_QUERY_PARAMS[
          value?.relativeUnitOfTime as uiLib.RELATIVE_DATE_UNITS_OF_TIME
        ];

      advancedSearch = `${key} = -${count}:${units}`;
      break;
    }
  }

  return `${
    translationOptions?.secondaryCheckboxKey && value.secondaryChecboxIsChecked
      ? `${advancedSearch} ${QUERY_CONJUNCTIONS.AND} ${translationOptions.secondaryCheckboxKey} = true`
      : advancedSearch
  }`;
};

export const convertTextWithModifiers: QueryClauseConverterFunc<
  TextFieldWithModifiersValue | null,
  {
    exactMatchKey: ADVANCED_SEARCH_KEYS;
  }
> = ({ key, value, translationOptions }) => {
  if (!value) {
    return '';
  }

  return `
    ${key} = ${value.value}
    ${
      translationOptions?.exactMatchKey && value.modifier === uiLib.TEXT_MODIFIERS.EXACT_MATCH
        ? ` ${QUERY_CONJUNCTIONS.AND} ${translationOptions.exactMatchKey} = true`
        : ''
    }
  `;
};

export const convertDocProperties: QueryClauseConverterFunc = ({ value }) => {
  const advancedSearchKey = {
    [OBLIGATIONS]: ADVANCED_SEARCH_KEYS.OBLIGATIONS,
    [HAS_SENTENCES]: ADVANCED_SEARCH_KEYS.DIFFING_AVAILABLE,
    [HAS_ANNOTATIONS]: ADVANCED_SEARCH_KEYS.DIFFING_AVAILABLE,
    [IS_PREMIUM_CONTENT]: ADVANCED_SEARCH_KEYS.PREMIUM_CONTENT,
    [IS_ORGANIZATION_DOCUMENT]: ADVANCED_SEARCH_KEYS.IS_ORGANIZATION_DOCUMENT,
    [MY_ORG_TASKS]: ADVANCED_SEARCH_KEYS.MY_ORG_TASKS,
    [MY_TASKS]: ADVANCED_SEARCH_KEYS.MY_TASKS
  }[(value as SelectOption)?.label as string];

  if (!advancedSearchKey) {
    return '';
  }

  return `${advancedSearchKey} = true`;
};

export const convertEitlLabels: QueryClauseConverterFunc = ({ value }) => {
  const options = value as eitlLabelsTypes.EitlLabelOption[];

  const filteredOptions = options.filter(({ value }) => value !== null);
  const isNoLabelsOptionSelected = options.some(({ value }) => value === null);

  const eitlLabelsFilter = convertInOrExact({
    key: ADVANCED_SEARCH_KEYS.EITL_LABEL_ID,
    value: filteredOptions
  });

  const hasEitlLabelsFilter = isNoLabelsOptionSelected
    ? `${ADVANCED_SEARCH_KEYS.HAS_EITL_LABELS} = false`
    : '';

  return [eitlLabelsFilter, hasEitlLabelsFilter].filter(Boolean).join(` ${QUERY_CONJUNCTIONS.OR} `);
};

export const CONVERTERS: Record<QUERY_TRANSLATION_TYPES, QueryClauseConverterFunc> = {
  [QUERY_TRANSLATION_TYPES.IN]: convertInClause as QueryClauseConverterFunc,
  [QUERY_TRANSLATION_TYPES.EXACT]: convertExact as QueryClauseConverterFunc,
  [QUERY_TRANSLATION_TYPES.IN_OR_EXACT]: convertInOrExact as QueryClauseConverterFunc,
  [QUERY_TRANSLATION_TYPES.DATE_WITH_MODIFIERS]: convertDateWithModifiers as QueryClauseConverterFunc,
  [QUERY_TRANSLATION_TYPES.TEXT_WITH_MODIFIERS]: convertTextWithModifiers as QueryClauseConverterFunc,
  [QUERY_TRANSLATION_TYPES.DOC_PROPERTIES]: convertDocProperties,
  [QUERY_TRANSLATION_TYPES.EITL_LABELS]: convertEitlLabels
};

export const convertBasicFiltersToAdvancedSearch = (
  filterValues: Required<FiltersProps>['values']
): string => {
  const translatedValues = Object.entries(filterValues).reduce(
    (translatedValues, [filterKey, filterValue]) => {
      const translationConfig = BASIC_FILTERS_TO_ADVANCED_SEARCH_CONFIG[filterKey as FILTER_KEY];

      if (!translationConfig) {
        return translatedValues;
      }

      const converter = CONVERTERS[translationConfig.translation];

      const convertedValue = converter({
        key: translationConfig.key,
        value: filterValue,
        translationOptions: translationConfig.translationOptions
      });

      if (!convertedValue) {
        return translatedValues;
      }

      return [
        ...translatedValues,
        {
          conjunction: translationConfig.conjunction,
          advancedSearch: convertedValue
        }
      ];
    },
    [] as { conjunction: QUERY_CONJUNCTIONS; advancedSearch: string }[]
  );

  const orGroup = translatedValues
    .filter(({ conjunction }) => conjunction === QUERY_CONJUNCTIONS.OR)
    .map(({ advancedSearch }) => advancedSearch)
    .join(` ${QUERY_CONJUNCTIONS.OR} `);
  const andGroup = translatedValues
    .filter(({ conjunction }) => conjunction === QUERY_CONJUNCTIONS.AND)
    .map(({ advancedSearch }) => advancedSearch)
    .join(` ${QUERY_CONJUNCTIONS.AND} `);

  if (orGroup.length && andGroup.length) {
    return `(${orGroup}) ${QUERY_CONJUNCTIONS.AND} ${andGroup}`;
  }

  if (orGroup.length) {
    return orGroup;
  }

  if (andGroup.length) {
    return andGroup;
  }

  return '';
};
