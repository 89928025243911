export const toArray = (value: unknown): unknown[] => {
  if (!value) {
    return [];
  }

  if (!Array.isArray(value)) {
    return [value];
  }

  return value;
};
