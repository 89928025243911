import type { RegulationHandlers } from './useRegulationHandlers.types';

import { useEffect } from 'react';

export const useRegulationLifecycle = ({
  onRegulationTypeChange
}: {
  onRegulationTypeChange: RegulationHandlers['handleRegulationTypeChange'];
}) => {
  useEffect(onRegulationTypeChange, [onRegulationTypeChange]);
};
