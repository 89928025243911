import {
  removeMessagesListener,
  setMessagesListener
} from './useCognitoWindowedSignInFormHandlers.helpers';

const WINDOW_TARGET = '';
const WINDOW_FEATURES = 'width=600,height=400,left=200,top=200';

export const useCognitoWindowedSignInFormHandlers = ({ props, refs }) => {
  const handleWindowOpen = windowUrl => {
    if (refs.formWindow.current) {
      refs.formWindow.current.close();
    }

    refs.formWindow.current = window.open(windowUrl, WINDOW_TARGET, WINDOW_FEATURES);
  };

  const handleMount = () => {
    setMessagesListener(refs, props);
  };

  const handleUnmount = () => {
    if (refs.formWindow.current) {
      refs.formWindow.current.close();
    }

    removeMessagesListener(refs);
  };

  return {
    handleWindowOpen,
    handleMount,
    handleUnmount
  };
};
