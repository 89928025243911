import type { LabelInputProps } from '../LabelInput.types';
import type { LabelInputData, LabelInputLocalState } from './useLabelInputData.types';

import * as hookConstants from './useLabelInputData.constants';
import * as orgHelpers from 'shared/features/organizations/organizations.helpers';

import { useMemo, useState } from 'react';

export const useLabelInputData = ({
  props
}: {
  props: Pick<LabelInputProps, 'labels' | 'isLoading'>;
}) => {
  const [isCreating, setIsCreating] = useState<LabelInputLocalState['isCreating']>(
    hookConstants.INITIAL_STATE.isCreating
  );
  const [isGroupSelectOpened, setIsGroupSelectOpened] = useState<
    LabelInputLocalState['isGroupSelectOpened']
  >(hookConstants.INITIAL_STATE.isGroupSelectOpened);
  const [searchValue, setSearchValue] = useState<LabelInputLocalState['searchValue']>(
    hookConstants.INITIAL_STATE.searchValue
  );
  const [isCreateModalOpened, setIsCreateModalOpened] = useState<
    LabelInputLocalState['isCreateModalOpened']
  >(hookConstants.INITIAL_STATE.isCreateModalOpened);

  const localState: LabelInputData['localState'] = {
    isCreating,
    isGroupSelectOpened,
    searchValue,
    isCreateModalOpened
  };

  const localActions: LabelInputData['localActions'] = useMemo(
    () => ({
      setIsCreating,
      setIsGroupSelectOpened,
      setSearchValue,
      setIsCreateModalOpened
    }),
    []
  );

  const formattedData: LabelInputData['formattedData'] = useMemo(
    () => ({
      labels: props.labels.map(label => ({
        ...label,
        isEditable: label.is_editable,
        bucket: orgHelpers.formatOrgLabelBucket(label)
      }))
    }),
    [props.labels]
  );

  return {
    localState,
    localActions,
    formattedData
  };
};
