import type { WorkflowTaskDetailsHandlers } from './useWorkflowTaskDetailsHandlers.types';

import { useEffect } from 'react';

export const useWorkflowTaskDetailsLifecycle = ({
  onDocumentFetch
}: {
  onDocumentFetch: WorkflowTaskDetailsHandlers['handleDocumentFetch'];
}) => {
  useEffect(() => {
    onDocumentFetch();
  }, [onDocumentFetch]);
};
