//How we format these fields as strings for the User
export const LOCATION_KEY = 'Location';
export const VIOLATION_KEY = 'Violation';
export const PENALTY_KEY = 'Penalty';
export const DOCUMENT_TYPE_KEY = 'Document Type';
export const COMMENTS_CLOSED_DATE_KEY = 'Comments Close Date';
export const EFFECTIVE_DATE_KEY = 'Effective Date';
export const PDF_URL_KEY = 'PDF URL';
export const SOURCE_KEY = 'Source';
export const DOCUMENT_ID_KEY = 'Document ID';
export const DOCKET_ID_KEY = 'Docket ID';
export const SUMMARY_KEY = 'Summary';
export const READ_KEY = 'Read/Unread';
export const AUTO_SUMMARY_KEY = 'Auto Summary';
export const JURISDICTION_KEY = 'Jurisdiction';
export const RESPONDENT_KEY = 'Respondents';
export const PUBLICATION_DATE_KEY = 'Publication Date';
export const CREATED_DATE_KEY = 'Created Date';
export const WEB_URL_KEY = 'Web URL';
export const WORKFLOW_STATUS_KEY = 'Workflow Status';
export const FULL_TEXT_KEY = 'Full Text';
export const TITLE_KEY = 'Title';
export const TOPICS_KEY = 'Topics';
export const CFR_KEY = 'CFR';
export const CITATION_KEY = 'Citation';
export const DOC_LABELS_KEY = 'Document Labels';
export const SENTENCES_KEY = 'Sentences';
export const SENTENCE_TEXT_KEY = 'Sentence Text';
export const SENTENCE_ID_KEY = 'Sentence ID';
export const SENTENCE_TYPE_KEY = 'Sentence Type';
export const SENTENCE_GROUP_KEY = 'Group ID';
export const SYSTEM_GENERATED_OBLIGATIONS_KEY = 'System-Generated Obligation';
export const USER_GENERATED_OBLIGATIONS_KEY = 'User-Generated Obligation';
export const OBLIGATION_KEY = 'Obligation';
export const ANNOTATIONS_KEY = 'Annotations';
export const USER_ANNOTATED_KEY = 'User_Annotated';
export const COMBINED_SENTENCES_KEY = 'Combined_Sentences';
export const ANNOTATION_GROUP_KEY = 'Annotation_Group';
export const FOR_ASSESSMENT_KEY = 'For_Assessment';
export const ANNOTATION_TEXT_KEY = 'Annotation Text';
export const ANNOTATION_ATTACHMENTS_KEY = 'Annotation Attachments';
export const ANNOTATION_LABELS_KEY = 'Annotation Labels';
export const ANNOTATION_SINGLE_FILE = 'annotation single file';
export const HAS_UNOFFICIAL_PUBLICATION_DATE = 'Unofficial Publication Date';
export const OFFICIAL_ID = 'Official ID';
export const EITL_LABELS = 'EITL Labels';

//Documents fields as they come from the API document object
export const DOCUMENT_FIELDS = {
  publicationDate: 'publication_date',
  agencyIds: 'agency_ids',
  agencies: 'agencies',
  createdAt: 'created_at',
  fullText: 'full_text',
  hasComments: 'has_comments',
  hasSentences: 'has_sentences',
  id: 'id',
  importantDates: 'important_dates',
  jurisdiction: 'jurisdiction',
  officialId: 'official_id',
  pdfUrl: 'pdf_url',
  pdfHash: 'pdf_hash',
  rule: 'pdf_hash',
  spiderName: 'spider_name',
  summaryText: 'summary_text',
  title: 'title',
  topics: 'topics',
  updatedAt: 'updated_at',
  hasUnofficialPublicationDate: 'has_unofficial_publication_date',
  caiCategoryId: 'cai_category_id'
};

//Our fields to the Document's route API fields
export const API_KEYS = {
  [LOCATION_KEY]: 'location',
  [VIOLATION_KEY]: 'violation',
  [PENALTY_KEY]: 'penalty',
  [DOCUMENT_TYPE_KEY]: 'document_type',
  [COMMENTS_CLOSED_DATE_KEY]: 'comments_close_on',
  [EFFECTIVE_DATE_KEY]: 'effective_on',
  [PDF_URL_KEY]: 'pdf_url',
  [SOURCE_KEY]: 'source',
  [DOCUMENT_ID_KEY]: 'document_id',
  [DOCKET_ID_KEY]: 'docket_id',
  [SUMMARY_KEY]: 'summary',
  [READ_KEY]: 'read',
  [AUTO_SUMMARY_KEY]: 'autosummary',
  [JURISDICTION_KEY]: 'jurisdiction',
  [RESPONDENT_KEY]: 'respondents',
  [PUBLICATION_DATE_KEY]: 'publication_date',
  [CREATED_DATE_KEY]: 'created_date',
  [WEB_URL_KEY]: 'web_url',
  [WORKFLOW_STATUS_KEY]: 'workflow_status',
  [FULL_TEXT_KEY]: 'full_text',
  [TITLE_KEY]: 'title',
  [TOPICS_KEY]: 'topics',
  [CFR_KEY]: 'cfr',
  [CITATION_KEY]: 'citation',
  [DOC_LABELS_KEY]: 'doc_labels',
  [SENTENCES_KEY]: 'sentences',
  [SENTENCE_TEXT_KEY]: 'sentence_text',
  [SENTENCE_ID_KEY]: 'sentence_id',
  [SENTENCE_TYPE_KEY]: 'sentence_type',
  [SENTENCE_GROUP_KEY]: 'sentence_group_id',
  [SYSTEM_GENERATED_OBLIGATIONS_KEY]: 'system_obligation',
  [USER_GENERATED_OBLIGATIONS_KEY]: 'user_obligation',
  [OBLIGATION_KEY]: 'obligation',
  [ANNOTATIONS_KEY]: 'annotations',
  [USER_ANNOTATED_KEY]: 'user_annotated',
  [COMBINED_SENTENCES_KEY]: 'combined_sentences',
  [ANNOTATION_GROUP_KEY]: 'annotation_group',
  [FOR_ASSESSMENT_KEY]: 'for_assessment',
  [ANNOTATION_TEXT_KEY]: 'annotation_text',
  [ANNOTATION_ATTACHMENTS_KEY]: 'annotation_attachments',
  [ANNOTATION_LABELS_KEY]: 'annotation_labels',
  [ANNOTATION_SINGLE_FILE]: 'annotation_single_file',
  [HAS_UNOFFICIAL_PUBLICATION_DATE]: 'has_unofficial_publication_date',
  [OFFICIAL_ID]: 'official_id',
  [EITL_LABELS]: 'eitl_labels'
};
