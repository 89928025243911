import type { AlertsResponse } from 'shared/features/alerts/alerts.api.types';
import type { AlertsSelectHandlers } from './useAlertsSelectHandlers.types';
import type { AlertsSelectProps } from '../AlertsSelect.types';

import { api_fetchAllAlerts } from 'shared/features/alerts/alerts.api';
import { formatAlertsOptions } from 'shared/features/alerts/alerts.helpers';
import { logError } from 'utils/errors';
import { ALERTS_COUNT_PER_REQUEST } from '../AlertsSelect.constants';
import { isAdvancedSearchAlert } from 'utils/alert-utils';

export const useAlertsSelectHandlers = ({
  props
}: {
  props: Pick<AlertsSelectProps, 'shouldShowOnlyAdvancedSearchAlerts' | 'blacklistedAlertIds'>;
}): AlertsSelectHandlers => {
  const handleAlertsFetch: AlertsSelectHandlers['handleAlertsFetch'] = async inputValue => {
    try {
      const response = (await api_fetchAllAlerts({
        name: inputValue.toLowerCase(),
        limit: ALERTS_COUNT_PER_REQUEST
      })) as AlertsResponse;

      const alerts = (response.results ?? []).filter(alert => {
        return (
          !props.blacklistedAlertIds?.includes?.(alert.id) &&
          (!props.shouldShowOnlyAdvancedSearchAlerts || isAdvancedSearchAlert(alert.search_args))
        );
      });

      return formatAlertsOptions(alerts);
    } catch (e) {
      logError(e as Error);

      return [];
    }
  };

  return {
    handleAlertsFetch
  };
};
