import type { ConceptsProps } from './Concepts.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './Concepts.constants';

import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';

import { useConceptsData, useConceptsHandlers } from './hooks';

import './_concepts.scss';

export const Concepts = ({ document }: ConceptsProps) => {
  const { reduxState, formattedData } = useConceptsData({ document });

  const handlers = useConceptsHandlers();

  if (!formattedData.conceptIds.length) {
    return null;
  }

  return (
    <>
      <FieldName>Concepts</FieldName>
      <FieldValue>
        <uiLib.Chiclets
          options={reduxState.conceptOptions}
          isContrast
          onOptionClick={handlers.handleConceptClick}
          isStatic
          withTooltips
          tooltipProps={{ placement: uiLib.TOOLTIP_POSITION.TOP, isInteractive: true }}
          minVisibleItemsCount={constants.MIN_VISIBLE_ITEMS_COUNT}
          dataTestId={constants.DATA_TEST_IDS.CONTAINER}
          classNames={{
            option: 'concepts__option'
          }}
        />
      </FieldValue>
    </>
  );
};
