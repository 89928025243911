import { safe_analytics } from 'utils/analytics';
import * as api from 'shared/features/feedback/feedback.api';
import { FORM_FIELDS, FORM_STATUSES, FORM_TYPES } from '../Feedback.constants';
import { logError } from 'utils/errors';

export const EVENT_CATEGORIES = {
  [FORM_TYPES.TEAM]: 'Request Team Edition',
  [FORM_TYPES.PRO]: 'Request Pro Edition',
  [FORM_TYPES.SCHEDULED_DEMO]: 'Schedule Demo',
  [FORM_TYPES.AGENCY]: 'Submit Feedback',
  [FORM_TYPES.NEWS_SOURCES]: 'Submit Feedback',
  [FORM_TYPES.JURISDICTION]: 'Submit Feedback',
  [FORM_TYPES.TOPICS]: 'Submit Feedback'
};

export const useFeedbackHandlers = ({ refs, props, formattedData, localState, localActions }) => {
  const handleFeedbackSend = async e => {
    try {
      e.preventDefault();

      localActions.setLoadingState(true);

      safe_analytics('default', 'Feedback', EVENT_CATEGORIES[formattedData.formType]);

      await api.postFeedback({
        email: formattedData.email,
        feedback: localState.fieldsValues[FORM_FIELDS.FEEDBACK],
        company: localState.fieldsValues[FORM_FIELDS.COMPANY],
        title: localState.fieldsValues[FORM_FIELDS.TITLE],
        topics: props.topics,
        requestTeam: props.requestTeam,
        requestPro: props.requestPro,
        scheduleDemo: props.scheduleDemo,
        requestJurisdiction: props.requestJurisdiction,
        requestAgency: props.requestAgency
      });

      localActions.setFormStatus(FORM_STATUSES.SUCCESS);
    } catch (e) {
      logError(e);
      localActions.setFormStatus(FORM_STATUSES.ERROR);
    } finally {
      localActions.setLoadingState(false);
    }
  };

  const handleFieldChange = field => val => {
    const value = val?.target?.value ?? val;

    localActions.setFieldsValues({
      ...localState.fieldsValues,
      [field]: value
    });
  };

  const handleCancel = () => {
    props.onCancel();
  };

  return {
    handleFeedbackSend,
    handleFieldChange,
    handleCancel
  };
};
