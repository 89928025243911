import type { GlobalState } from 'shared/reducers';
import type { AlertsTableFiltersData } from './useAlertsTableFiltersData.types';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getActiveOrganizationMembersOptions } from 'shared/features/organizations/organizations.selectors';
import { ALERTS_FILTERS_DEFAULT_VALUES } from '../AlertsTableFilters.constants';

export const useAlertsTableFiltersData = (): AlertsTableFiltersData => {
  const [values, setValues] = useState(ALERTS_FILTERS_DEFAULT_VALUES);
  const [searchValue, setSearchValue] = useState('');

  const localState: AlertsTableFiltersData['localState'] = {
    values,
    searchValue
  };

  const localActions: AlertsTableFiltersData['localActions'] = useMemo(
    () => ({
      setValues,
      setSearchValue
    }),
    []
  );

  const reduxState: AlertsTableFiltersData['reduxState'] = useSelector<
    GlobalState,
    AlertsTableFiltersData['reduxState']
  >(state => ({
    organizationMemberOptions: getActiveOrganizationMembersOptions(state as never)
  }));

  return {
    localState,
    localActions,
    reduxState
  };
};
