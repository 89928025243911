export const sendBrowserAgnosticEvent = (elem, eventName) => {
  //Needed for IE Support: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/dispatchEvent#Browser_Compatibility
  //https://stackoverflow.com/a/49071358/79677
  let event;
  if (typeof Event === 'function') {
    event = new Event(eventName); // eslint-disable-line no-undef
  } else {
    event = document.createEvent('Event');
    event.initEvent(eventName, true, true);
  }
  elem.dispatchEvent(event);

  return event;
};
