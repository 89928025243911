import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';

export const useWorkflowPanelHandlers = () => {
  const rightPanelReduxActions = useRightPanelReduxActions();

  const handleTabChange = key => {
    rightPanelReduxActions.changeDocumentDetailsActiveTab({
      key
    });
  };

  return {
    handleTabChange
  };
};
