import type { GlobalState } from 'shared/reducers';
import type {
  CreateSentenceNoteModalData,
  CreateSentenceNoteModalLocalState
} from './useCreateSentenceNoteModalData.types';

import * as orgSelectors from 'shared/features/organizations/organizations.selectors';
import * as bucketsConstants from 'shared/features/buckets/buckets.constants';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const useCreateSentenceNoteModalData = (): CreateSentenceNoteModalData => {
  const reduxState: CreateSentenceNoteModalData['reduxState'] = useSelector<
    GlobalState,
    CreateSentenceNoteModalData['reduxState']
  >(state => ({
    isPublicFolderDisabled: orgSelectors.getOrganizationPublicFolderRestriction(state)
  }));

  const [bucket, setBucket] = useState<CreateSentenceNoteModalLocalState['bucket']>(
    reduxState.isPublicFolderDisabled ? null : bucketsConstants.DEFAULT_BUCKET_OPTION
  );

  const localState: CreateSentenceNoteModalData['localState'] = {
    bucket
  };

  const localActions: CreateSentenceNoteModalData['localActions'] = useMemo(
    () => ({
      setBucket
    }),
    []
  );

  const formattedData: CreateSentenceNoteModalData['formattedData'] = useMemo(
    () => ({
      isCreateButtonDisabled: localState.bucket === null,
      bucketId: localState.bucket?.value ?? null
    }),
    [localState.bucket]
  );

  return {
    localState,
    localActions,
    formattedData,
    reduxState
  };
};
