import type { GlobalState } from 'shared/reducers';
import type { DiffTabTitleData } from './useDiffTabTitleData.types';

import * as rightPanelSelectors from 'shared/features/rightPanel/rightPanel.selectors';

import { useSelector } from 'react-redux';

export const useDiffTabTitleData = (): DiffTabTitleData => {
  const reduxState = useSelector<GlobalState, DiffTabTitleData['reduxState']>(state => ({
    rightPanelProps: rightPanelSelectors.getRightPanelProps(state as never)
  }));

  return {
    reduxState
  };
};
