import type { BucketPermissionsCellData } from './useBucketPermissionsCellData.types';
import type { BucketPermissionsCellHandlers } from './useBucketPermissionsCellHandlers.types';

export const useBucketPermissionsCellHandlers = ({
  localActions
}: {
  localActions: BucketPermissionsCellData['localActions'];
}): BucketPermissionsCellHandlers => {
  const handleModalToggle: BucketPermissionsCellHandlers['handleModalToggle'] = isOpen => () => {
    localActions.setIsModalOpen(isOpen);
  };

  return {
    handleModalToggle
  };
};
