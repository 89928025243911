import type { RespondentsProps } from './Respondents.types';

import { RestrictedFieldName, RESTRICTION_TYPES } from '../RestrictedFieldName';
import { FIELD_VALUES } from 'shared/features/documents/documents.constants';
import { FieldName, FieldValue } from '../index';

export const Respondents = ({ document }: RespondentsProps) => {
  if (!document.enforcement) {
    return null;
  }

  if (document.enforcement.respondents === FIELD_VALUES.RESTRICTED) {
    return (
      <>
        <FieldName>
          <RestrictedFieldName restrictionType={RESTRICTION_TYPES.ENFORCEMENTS}>
            Respondents
          </RestrictedFieldName>
        </FieldName>
        <FieldValue>Restricted</FieldValue>
      </>
    );
  }

  if (document.enforcement.individual_respondent) {
    return (
      <>
        <FieldName>Respondents (Individual)</FieldName>
        <FieldValue>{document.enforcement.individual_respondent}</FieldValue>
      </>
    );
  }

  if (document.enforcement.entity_respondent) {
    return (
      <>
        <FieldName>Respondents (Entity)</FieldName>
        <FieldValue>{document.enforcement.entity_respondent}</FieldValue>
      </>
    );
  }

  if (document.enforcement.respondents) {
    return (
      <>
        <FieldName>Respondents</FieldName>
        <FieldValue>{document.enforcement.respondents}</FieldValue>
      </>
    );
  }

  return null;
};
