// Full team
export const PLAN_TEAM_ANNUAL_RECUR = 'team_annual_recur';
export const PLAN_TEAM_MONTHLY_RECUR = 'team_monthly_recur';
// Team-lite
export const PLAN_SERVICE_CONNECTOR_MONTHLY_RECUR = 'service_connector_monthly_recur';
export const PLAN_SERVICE_CONNECTOR_ANNUAL_RECUR = 'service_connector_annual_recur';
//Pro
export const PLAN_PRO_ANNUAL_RECUR = 'pro_annual_recur';
export const PLAN_PRO_MONTHLY_RECUR = 'pro_monthly_recur';
export const PLAN_PRO_ANNUAL_RECUR_PREEXISTING = 'pro_annual_recur_preexisting';
export const PLAN_PRO_ANNUAL_NEW_OCT_2017 = 'pro_annual_new_oct_2017';
export const PLAN_PRO_ANNUAL_APRIL_2019 = 'pro_annual_april_2019';

export const PLAN_LITE_TEAMS = [
  PLAN_SERVICE_CONNECTOR_ANNUAL_RECUR,
  PLAN_SERVICE_CONNECTOR_MONTHLY_RECUR
];

export const PLAN_FULL_TEAMS = [PLAN_TEAM_ANNUAL_RECUR, PLAN_TEAM_MONTHLY_RECUR];

export const PLAN_TEAMS = [...PLAN_LITE_TEAMS, ...PLAN_FULL_TEAMS];
export const PLAN_PRO = [
  PLAN_PRO_ANNUAL_RECUR,
  PLAN_PRO_MONTHLY_RECUR,
  PLAN_PRO_ANNUAL_RECUR_PREEXISTING,
  PLAN_PRO_ANNUAL_NEW_OCT_2017,
  PLAN_PRO_ANNUAL_APRIL_2019
];

export const PLANS = {
  PRO: 'pro',
  TEAMS: 'teams',
  SERVICE_CONNECTOR: 'serviceConnector'
};
