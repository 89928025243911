import { fetch } from 'utils/api';

export function api_fetchTopicsStats() {
  return fetch({
    url: `/topics_stats`,
    method: 'GET',
    dataType: 'Topic Stats'
  });
}

export function api_fetchDocumentCountsForTopics(params) {
  return fetch({
    url: `/documents-counts-for-topics`,
    method: 'GET',
    params,
    dataType: 'DocumentCountForTopic'
  });
}
