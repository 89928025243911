import { fetch } from 'utils/api';
import { apiKey } from 'shared/config';

export const postFeedback = async params => {
  return await fetch({
    url: '/feedback',
    method: 'POST',
    params: params,
    customToken: apiKey
  });
};
