import lodash from 'lodash';

const toOptions = team => {
  return { label: team.name, value: team };
};

const formatTeams = teams => {
  const uniqTeams = lodash.uniqBy(teams, 'id');
  return uniqTeams.map(toOptions);
};

const toTeamNames = team => team.name;

export default {
  toOptions,
  formatTeams,
  toTeamNames
};
