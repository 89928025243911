import type { ManageBucketAccessProps } from '../ManageBucketAccess.types';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from '../elements';
import * as constants from '../ManageBucketAccess.constants';

export const useManageBucketAccessTableColumns = ({
  props
}: {
  props: Pick<ManageBucketAccessProps, 'bucket' | 'onActionSuccess'>;
}) => {
  const columns: uiLib.TableProps['columns'] = [
    {
      title: 'Who has access',
      dataKey: constants.TABLE_KEYS.NAME,
      className: 'manage-bucket-access-table__cell-title',
      render: (_, row) => <elements.TitleCell row={row} />
    },
    {
      title: 'Access Level',
      dataKey: constants.TABLE_KEYS.ID,
      render: (_, row) => (
        <elements.ActionCell
          row={row}
          bucketId={Number(props.bucket?.id)}
          isBucketEditable={Boolean(props.bucket?.isEditable)}
          onActionSuccess={props.onActionSuccess}
        />
      )
    }
  ];

  return columns;
};
