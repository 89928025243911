import PropTypes from 'prop-types';

export const ConjunctionGroup = ({ conjunctionOptions, setConjunction, disabled, config }) => {
  const onChange = e => setConjunction(e.target.value);

  return (
    <>
      <div className="toggle">
        {Object.keys(conjunctionOptions).map(key => {
          const { id: uniqueId, name, label, checked } = conjunctionOptions[key];
          return [
            <input
              key={uniqueId}
              type="radio"
              id={uniqueId}
              name={name}
              checked={checked}
              value={key}
              onChange={onChange}
              disabled={disabled}
            />,
            <label key={uniqueId + 'label'} htmlFor={uniqueId}>
              {label}
            </label>
          ];
        })}
      </div>
      {config.settings.showNot && <div>ConjunctionGroup is not configured</div>}
    </>
  );
};

ConjunctionGroup.propTypes = {
  conjunctionOptions: PropTypes.shape({}),
  setConjunction: PropTypes.func,
  disabled: PropTypes.bool,
  config: PropTypes.shape({})
};
