import type * as apiTypes from './orgDocumentsMetadata.api.types';
import type * as organizationDocumentsApiTypes from 'shared/features/organizationDocuments/organizationDocuments.api.types';
import type * as reducerTypes from './orgDocumentsMetadata.reducer.types';
import type * as documentFieldsReducerTypes from '../documentFields/documentFields.reducer.types';
import type * as metadataPipeline from './metadata-pipeline';

import lodash from 'lodash';

import * as constants from './orgDocumentsMetadata.constants';
import * as documentFieldsConstants from '../documentFields/documentFields.constants';
import * as env from 'shared/config';
import * as uiLib from '@compliance.ai/web-components';

export const normalizeOrgDocumentsMetadataResponse = ({
  response,
  fileNameFieldLabel
}: {
  response: apiTypes.UploadMappingsDocumentResponse;
  fileNameFieldLabel: string;
}): ConstructorParameters<typeof metadataPipeline.Pipeline>[0]['initialMetadata'] => {
  if (!lodash.isObject(response)) {
    return {};
  }

  return Object.fromEntries(
    response.files.flatMap(metadata => {
      const documentFileName = metadata[fileNameFieldLabel];

      if (!documentFileName) {
        return [];
      }

      return [[documentFileName, metadata]];
    })
  );
};

export const getOrgDocsMetadataFromResponse = (
  orgDocs: organizationDocumentsApiTypes.OrganizationDocumentFromResponse[]
): Record<reducerTypes.OrgDocumentFileName, apiTypes.MetadataFromResponse> => {
  if (!Array.isArray(orgDocs)) {
    return {};
  }

  return orgDocs.reduce((normalizedMetadata, orgDoc) => {
    const docMetadata = orgDoc.document_metadata;
    const documentFileName = orgDoc.file_name as string;

    if (!docMetadata || !documentFileName) {
      return normalizedMetadata;
    }

    return {
      ...normalizedMetadata,
      [documentFileName]: docMetadata
    };
  }, {});
};

export const getUnmappedMandatoryDocFields = ({
  metadata,
  mandatoryDocFields
}: {
  metadata: reducerTypes.OrgDocumentsMetadataState['metadata'];
  mandatoryDocFields: documentFieldsReducerTypes.DocFieldOption[];
}): reducerTypes.OrgDocumentsMetadataState['defaultFields'] => {
  const selectedFields = Object.values(metadata ?? {})
    .flatMap(metadataItem => {
      return Object.values(metadataItem).map(({ mappedCaiDocField }) => mappedCaiDocField);
    })
    .filter(Boolean);

  return mandatoryDocFields
    .filter(docField => !docField.isMetadataKeyField)
    .flatMap(docField => {
      if (selectedFields.find(field => field === docField.value)) {
        return [];
      }

      return [
        {
          id: uiLib.getRandomId(),
          key: docField.value,
          isMandatory: true,
          defaultValue: null
        }
      ];
    });
};

export const getTemplateLink = (accessToken: string, importId: number | null): string =>
  `${env.apiUrl}/organizations/organization_documents/xlsx_template?access_token=${accessToken}&import_id=${importId}`;

export const getFileNamesFromOrgDocsResponse = (
  orgDocs: organizationDocumentsApiTypes.OrganizationDocumentFromResponse[]
): reducerTypes.OrgDocumentFileName[] => {
  return orgDocs.map(orgDoc => orgDoc.file_name).sort();
};

export const areValidFileNamesFromUploadedDataResponse = ({
  fileNames,
  response,
  fileNameFieldLabel
}: {
  fileNames: reducerTypes.OrgDocumentFileName[];
  response: apiTypes.UploadMappingsDocumentResponse;
  fileNameFieldLabel: string;
}): boolean => {
  if (!lodash.isObject(response)) {
    return false;
  }

  return lodash.isEqual(response.files.map(file => file[fileNameFieldLabel]).sort(), fileNames);
};

export const isEmptyMetadata = (
  metadata: reducerTypes.OrgDocumentsMetadataState['metadata']
): boolean => {
  return (
    Object.values(metadata).flatMap(metadata => {
      return Object.values(metadata).flatMap(metadataDate => {
        return metadataDate.data.flat();
      });
    }).length === 0
  );
};

export const getSelectedCAICapabilitiesFromResponse = (
  metadata: apiTypes.MetadataFromResponse
): reducerTypes.OrgDocumentsMetadataState['selectedCAICapabilities'] => {
  return {
    [constants.CAI_METADATA_CAPABILITIES_KEYS.SENTENCES]: Boolean(
      metadata?.[documentFieldsConstants.INTERNAL_DOC_METADATA_FIELDS.HAS_SENTENCES]
    ),
    [constants.CAI_METADATA_CAPABILITIES_KEYS.OBLIGATIONS]: Boolean(
      metadata?.[documentFieldsConstants.INTERNAL_DOC_METADATA_FIELDS.HAS_OBLIGATIONS]
    )
  };
};
