import type { FC } from 'react';
import type { AnnotationsSectionProps } from './SentenceNotesSection.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './SentenceNotesSection.constants';

import './_sentence-notes-section.scss';

export const SentenceNotesSection: FC<AnnotationsSectionProps> = ({
  sentenceNotes,
  loader,
  classNames
}) => {
  return (
    <>
      <uiLib.Table
        rowKey={constants.TABLE_FIELDS.ID}
        shouldRenderToolbar={false}
        columns={constants.TABLE_COLUMNS}
        rows={sentenceNotes}
        classNames={{
          container: classNames?.tableOuterContainer,
          headerCell: classNames?.headerCell,
          cell: classNames?.cell,
          tableContainer: classNames?.tableContainer
        }}
      />

      {loader}
    </>
  );
};
