import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import * as images from 'shared/images';

export const APP_NOTIFICATION_TYPES = [
  { value: 'new_feature', label: 'New Feature', icon: <PlayArrowIcon /> },
  {
    value: 'content_update',
    label: 'Content Update',
    icon: (
      <img
        src={images.misc.updated}
        alt="updated"
        className="app-notification-header-updated-img"
      />
    )
  }
];

export const APP_NOTIFICATION_CONTAINER_ID = 'app-notifications-container';
