import type { MetadataEntries, MetadataKey } from '../Stage';
import type { ConditionalFieldsMap } from './ConditionalFieldsStage.types';

export const getReversedConditionalField = (args: {
  metadataKey: MetadataKey;
  conditionalFieldsMap: ConditionalFieldsMap;
}): string | undefined => {
  const [reversedConditionalField] = Object.entries(args.conditionalFieldsMap).flatMap(
    ([field, conditionalField]) => {
      if (!conditionalField) {
        return [];
      }

      if (conditionalField === args.metadataKey) {
        return [field];
      }

      return [];
    }
  );

  return reversedConditionalField;
};

export const getReversedConditionalFieldValues = (args: {
  metadata: MetadataEntries;
  reversedConditionalField: string;
}): unknown[] => {
  return args.metadata.filter(([metadataField, metadataValue]) => {
    return (
      metadataField === args.reversedConditionalField &&
      Boolean(Array.isArray(metadataValue) ? metadataValue.length : metadataValue)
    );
  });
};
