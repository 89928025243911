import type * as globalReducersTypes from '../../reducers';
import type * as reducerTypes from './orgDocumentsMetadata.reducer.types';
import type * as apiTypes from './orgDocumentsMetadata.api.types';
import type * as docFieldReducerTypes from '../documentFields/documentFields.reducer.types';

import { createSelector } from '@reduxjs/toolkit';

import lodash from 'lodash';
import * as constants from './orgDocumentsMetadata.constants';
import * as docFieldsSelectors from '../documentFields/documentFields.selectors';
import * as metadataPipeline from './metadata-pipeline';
import * as selectUtils from 'utils/select-utils';

export const getOrgDocumentsMetadataReducer = (
  state: globalReducersTypes.GlobalState
): reducerTypes.OrgDocumentsMetadataState => state[constants.REDUCER_NAME];

export const getOrgDocumentsMetadataActiveStepKey = createSelector(
  getOrgDocumentsMetadataReducer,
  (
    orgDocumentsMetadataReducer: reducerTypes.OrgDocumentsMetadataState
  ): reducerTypes.OrgDocumentsMetadataState['activeStepKey'] => {
    return orgDocumentsMetadataReducer.activeStepKey;
  }
);

export const getOrgDocumentsMetadataNextStepTrigger = createSelector(
  getOrgDocumentsMetadataReducer,
  (
    orgDocumentsMetadataReducer: reducerTypes.OrgDocumentsMetadataState
  ): reducerTypes.OrgDocumentsMetadataState['nextStepTrigger'] => {
    return orgDocumentsMetadataReducer.nextStepTrigger;
  }
);

export const getOrgDocumentsMetadataPrevStepTrigger = createSelector(
  getOrgDocumentsMetadataReducer,
  (
    orgDocumentsMetadataReducer: reducerTypes.OrgDocumentsMetadataState
  ): reducerTypes.OrgDocumentsMetadataState['prevStepTrigger'] => {
    return orgDocumentsMetadataReducer.prevStepTrigger;
  }
);

export const getOrgDocumentsMetadataIsNextStepDisabled = createSelector(
  getOrgDocumentsMetadataReducer,
  (
    orgDocumentsMetadataReducer: reducerTypes.OrgDocumentsMetadataState
  ): reducerTypes.OrgDocumentsMetadataState['isNextStepDisabled'] => {
    return orgDocumentsMetadataReducer.isNextStepDisabled;
  }
);

export const getIsDraft = createSelector(
  getOrgDocumentsMetadataReducer,
  (
    orgDocumentsMetadataReducer: reducerTypes.OrgDocumentsMetadataState
  ): reducerTypes.OrgDocumentsMetadataState['isDraft'] => {
    return orgDocumentsMetadataReducer.isDraft;
  }
);

export const getOrgDocumentsMetadataMappingsTabActiveKey = createSelector(
  getOrgDocumentsMetadataReducer,
  (
    orgDocumentsMetadataReducer: reducerTypes.OrgDocumentsMetadataState
  ): reducerTypes.OrgDocumentsMetadataState['activeMappingsTabKey'] => {
    return orgDocumentsMetadataReducer.activeMappingsTabKey;
  }
);

export const getOrgDocumentsMetadata = createSelector(
  getOrgDocumentsMetadataReducer,
  (
    orgDocumentsMetadataReducer: reducerTypes.OrgDocumentsMetadataState
  ): reducerTypes.OrgDocumentsMetadataState['metadata'] => {
    return orgDocumentsMetadataReducer.metadata;
  }
);

export const getMetadataAttributeTagMaps = createSelector(
  getOrgDocumentsMetadataReducer,
  (
    orgDocumentsMetadataReducer: reducerTypes.OrgDocumentsMetadataState
  ): reducerTypes.OrgDocumentsMetadataState['attributeTagMaps'] => {
    return orgDocumentsMetadataReducer.attributeTagMaps;
  }
);

export const getMetadataAttributeTagMapsForPipeline = createSelector(
  getMetadataAttributeTagMaps,
  docFieldsSelectors.getDocumentFields,
  (
    attributeTagMaps: reducerTypes.OrgDocumentsMetadataState['attributeTagMaps'],
    docFields: docFieldReducerTypes.DocFieldsState['items']
  ): ConstructorParameters<typeof metadataPipeline.TagsStage>[0]['metadataTagsMap'] => {
    return Object.fromEntries(
      attributeTagMaps.map(tagMap => {
        const metadataKey = docFields.find(docField => docField.label === tagMap.attribute)?.value;
        const extractedTagValues = Object.fromEntries(
          Object.entries(tagMap.tagValues).map(([metadataKey, tagValues]) => {
            return [
              metadataKey,
              Object.fromEntries(
                Object.entries(tagValues).map(([tagKey, tagValues]) => {
                  return [
                    tagKey,
                    selectUtils.extractValues(
                      tagValues as Parameters<typeof selectUtils.extractValues>[0]
                    )
                  ];
                })
              )
            ];
          })
        );

        return [metadataKey ?? tagMap.attribute, extractedTagValues];
      })
    );
  }
);

export const getMetadataKeys = createSelector(
  getOrgDocumentsMetadata,
  (
    documentsMetadata: reducerTypes.OrgDocumentsMetadataState['metadata']
  ): reducerTypes.OrgDocumentsMetadataKey[] => {
    if (!documentsMetadata) {
      return [];
    }

    return [
      ...new Set(
        Object.values(documentsMetadata).flatMap(metadata => {
          return Object.keys(metadata);
        })
      )
    ];
  }
);

export const getMappedMetadataKeys = createSelector(
  getOrgDocumentsMetadata,
  docFieldsSelectors.getDocumentFields,
  (
    documentsMetadata: reducerTypes.OrgDocumentsMetadataState['metadata'],
    docFields: docFieldReducerTypes.DocFieldsState['items']
  ): reducerTypes.OrgDocumentsMetadataKey[] => {
    if (!documentsMetadata) {
      return [];
    }

    return [
      ...new Set(
        Object.values(documentsMetadata).flatMap(metadata => {
          return Object.entries(metadata).flatMap(([metadataKey, metadataItem]) => {
            if (!docFields.find(docField => docField.value === metadataItem?.mappedCaiDocField)) {
              return [];
            }

            return metadataKey;
          });
        })
      )
    ];
  }
);

export const getUnmappedMetadataKeys = createSelector(
  getOrgDocumentsMetadata,
  getMappedMetadataKeys,
  (
    documentsMetadata: reducerTypes.OrgDocumentsMetadataState['metadata'],
    mappedMetadataKeys: reducerTypes.OrgDocumentsMetadataKey[]
  ): reducerTypes.OrgDocumentsMetadataKey[] => {
    if (!documentsMetadata) {
      return [];
    }

    return [
      ...new Set(
        Object.values(documentsMetadata).flatMap(metadata => {
          return Object.keys(metadata).filter(
            metadataKey => !mappedMetadataKeys.includes(metadataKey)
          );
        })
      )
    ];
  }
);

export const getAllMetadataKeys = createSelector(
  getUnmappedMetadataKeys,
  getMappedMetadataKeys,
  (
    unmappedMetadataKeys: reducerTypes.OrgDocumentsMetadataKey[],
    mappedMetadataKeys: reducerTypes.OrgDocumentsMetadataKey[]
  ): reducerTypes.OrgDocumentsMetadataKey[] => {
    return [...new Set([...unmappedMetadataKeys, ...mappedMetadataKeys])];
  }
);

export const getDataByMetadataKey = createSelector(
  [
    getOrgDocumentsMetadata,
    (_: globalReducersTypes.GlobalState, metadataKey: string) => metadataKey
  ],
  (documentsMetadata, metadataKey): reducerTypes.OrgDocumentsMetadataItem['data'] => {
    return Object.values(documentsMetadata).flatMap(metadata => {
      return metadata[metadataKey]?.data;
    });
  }
);

export const getFileMetadataByKey = createSelector(
  [
    getOrgDocumentsMetadata,
    (_: globalReducersTypes.GlobalState, metadataKey: string) => metadataKey
  ],
  (documentsMetadata, metadataKey): reducerTypes.OrgDocumentsMetadataItem['data'][] => {
    return Object.values(documentsMetadata).map(metadata => {
      return metadata[metadataKey]?.data ?? [];
    });
  }
);

export const getFieldByMetadataKey = createSelector(
  [
    getOrgDocumentsMetadata,
    (state: globalReducersTypes.GlobalState, metadataKey: string) => metadataKey
  ],
  (documentsMetadata, metadataKey): reducerTypes.OrgDocumentsMetadataItem['mappedCaiDocField'] => {
    return (
      Object.values(documentsMetadata)
        .map(metadata => {
          return metadata[metadataKey]?.mappedCaiDocField;
        })
        .filter(Boolean)[0] ?? null
    );
  }
);

export const getMappedMetadataFields = createSelector(
  [getOrgDocumentsMetadata],
  (
    documentsMetadata
  ): Exclude<reducerTypes.OrgDocumentsMetadataItem['mappedCaiDocField'], null>[] => {
    return [
      ...new Set(
        Object.values(documentsMetadata).flatMap(metadata => {
          return Object.values(metadata).map(metadataItem => {
            return metadataItem.mappedCaiDocField;
          });
        })
      )
    ].filter(Boolean) as string[];
  }
);

export const getOrgDocumentsMetadataFieldsCount = createSelector(
  getOrgDocumentsMetadata,
  (metadata: reducerTypes.OrgDocumentsMetadataState['metadata']): number => {
    if (!metadata) {
      return 0;
    }

    return Object.values(metadata).length;
  }
);

export const getNonEmptyMetadataKeys = createSelector(
  getOrgDocumentsMetadata,
  (
    documentsMetadata: reducerTypes.OrgDocumentsMetadataState['metadata']
  ): reducerTypes.OrgDocumentsMetadataKey[] => {
    if (!documentsMetadata) {
      return [];
    }

    return [
      ...new Set(
        Object.values(documentsMetadata).flatMap(metadata => {
          return Object.entries(metadata).flatMap(([metadataKey, metadataItem]) => {
            if (!metadataItem.data?.length) {
              return [];
            }

            return metadataKey;
          });
        })
      )
    ];
  }
);

export const getNotSelectedMetadataAttributes = createSelector(
  getMetadataAttributeTagMaps,
  getNonEmptyMetadataKeys,
  (
    attributeTagMaps: reducerTypes.OrgDocumentsMetadataState['attributeTagMaps'],
    metadataKeys: reducerTypes.OrgDocumentsMetadataKey[]
  ): reducerTypes.OrgDocumentsMetadataKey[] => {
    return metadataKeys.filter(
      metadataKey =>
        !attributeTagMaps.some(attributeTagMap => attributeTagMap.attribute === metadataKey)
    );
  }
);

export const getOrgDocumentsMetadataConfirmationConfig = createSelector(
  getOrgDocumentsMetadataReducer,
  (
    orgDocumentsMetadataReducer: reducerTypes.OrgDocumentsMetadataState
  ): reducerTypes.OrgDocumentsMetadataState['confirmationConfig'] => {
    return orgDocumentsMetadataReducer.confirmationConfig;
  }
);

export const getImportDocFileNames = createSelector(
  getOrgDocumentsMetadataReducer,
  (
    orgMetadataReducer: reducerTypes.OrgDocumentsMetadataState
  ): reducerTypes.OrgDocumentFileName[] => {
    return orgMetadataReducer.filenames;
  }
);

export const getSelectedBucketIds = createSelector(
  getOrgDocumentsMetadataReducer,
  (
    orgMetadataReducer: reducerTypes.OrgDocumentsMetadataState
  ): reducerTypes.OrgDocumentsMetadataState['selectedBucketIds'] => {
    return orgMetadataReducer.selectedBucketIds;
  }
);

export const getPermissionsForUpload = createSelector(
  getSelectedBucketIds,
  (bucketIds): apiTypes.MetadataFromResponse['permissions'] => {
    return {
      bucket_id: bucketIds[0] ?? null
    };
  }
);

export const getSelectedCAICapabilities = createSelector(
  getOrgDocumentsMetadataReducer,
  (
    orgMetadataReducer: reducerTypes.OrgDocumentsMetadataState
  ): reducerTypes.OrgDocumentsMetadataState['selectedCAICapabilities'] => {
    return orgMetadataReducer.selectedCAICapabilities;
  }
);

export const getOrgDocumentsMetadataDefaultFields = createSelector(
  getOrgDocumentsMetadataReducer,
  (
    orgDocumentsMetadataReducer: reducerTypes.OrgDocumentsMetadataState
  ): reducerTypes.OrgDocumentsMetadataState['defaultFields'] => {
    return orgDocumentsMetadataReducer.defaultFields ?? [];
  }
);

export const getOrgDocumentsEmptyDefaultFields = createSelector(
  getOrgDocumentsMetadataDefaultFields,
  docFieldsSelectors.getDocumentFields,
  (
    defaultFields: reducerTypes.OrgDocumentsMetadataState['defaultFields'],
    docFieldOptions: docFieldReducerTypes.DocFieldsState['items']
  ): docFieldReducerTypes.DocFieldsState['items'] => {
    return docFieldOptions.filter(field => {
      return defaultFields.some(
        defaultField =>
          defaultField.key === field.value &&
          (!defaultField.defaultValue ||
            (lodash.isString(defaultField.defaultValue) && !defaultField.defaultValue.trim()) ||
            (Array.isArray(defaultField.defaultValue) && !defaultField.defaultValue.length))
      );
    });
  }
);

export const getMappedDocFields = createSelector(
  docFieldsSelectors.getDocumentFields,
  getMappedMetadataFields,
  getOrgDocumentsMetadataDefaultFields,
  getOrgDocumentsEmptyDefaultFields,
  (
    docFields: docFieldReducerTypes.DocFieldsState['items'],
    mappedMetadataFields: reducerTypes.OrgDocumentsMetadataItem['mappedCaiDocField'][],
    defaultFields: reducerTypes.OrgDocumentsMetadataState['defaultFields'],
    emptyDefaultFields: docFieldReducerTypes.DocFieldsState['items']
  ): docFieldReducerTypes.DocFieldsState['items'] => {
    const selectedFields = mappedMetadataFields.filter(Boolean);

    const selectedDefaultFields = defaultFields
      .filter(field => {
        return !emptyDefaultFields.find(emptyField => emptyField.value === field.key);
      })
      .map(field => field.key);

    return docFields.filter(field => {
      return selectedFields.includes(field.value) || selectedDefaultFields.includes(field.value);
    });
  }
);

export const getSelectedAndMappedFieldKeys = createSelector(
  getMappedDocFields,
  getOrgDocumentsMetadataDefaultFields,
  (
    mappedDocField: docFieldReducerTypes.DocFieldsState['items'],
    selectedDocFields: reducerTypes.OrgDocumentsMetadataState['defaultFields']
  ): docFieldReducerTypes.DocFieldOption['value'][] => {
    return [
      ...new Set([
        ...(selectedDocFields.map(docField => docField.key) as string[]),
        ...mappedDocField.map(field => field.value)
      ])
    ];
  }
);

export const getOrgDocumentsMetadataUnmappedMandatoryFields = createSelector(
  docFieldsSelectors.getDocumentFields,
  getMappedDocFields,
  (
    docFields: docFieldReducerTypes.DocFieldsState['items'],
    mappedFields: docFieldReducerTypes.DocFieldsState['items']
  ): docFieldReducerTypes.DocFieldsState['items'] => {
    return docFields.filter(field => {
      return (
        !mappedFields.find(mappedField => mappedField.value === field.value) && field.isMandatory
      );
    });
  }
);

export const areAllMandatoryFieldsMapped = createSelector(
  getOrgDocumentsMetadataUnmappedMandatoryFields,
  (unmappedMandatoryDocFields: docFieldReducerTypes.DocFieldsState['items']): boolean => {
    return !unmappedMandatoryDocFields.length;
  }
);

export const getOrgDocumentsMetadataImportId = createSelector(
  getOrgDocumentsMetadataReducer,
  (
    orgDocumentsMetadataReducer: reducerTypes.OrgDocumentsMetadataState
  ): reducerTypes.OrgDocumentsMetadataState['importId'] => {
    return orgDocumentsMetadataReducer.importId;
  }
);

export const getOrgDocumentsMetadataMandatoryDefaultFields = createSelector(
  getOrgDocumentsMetadataDefaultFields,
  (
    defaultFields: reducerTypes.OrgDocumentsMetadataState['defaultFields']
  ): reducerTypes.OrgDocumentsMetadataState['defaultFields'] => {
    return defaultFields.filter(field => field.isMandatory);
  }
);

export const getSelectedFile = createSelector(
  getOrgDocumentsMetadataReducer,
  (
    orgDocumentsMetadataReducer: reducerTypes.OrgDocumentsMetadataState
  ): reducerTypes.OrgDocumentsMetadataState['file'] => {
    return orgDocumentsMetadataReducer.file;
  }
);

export const getOrgDocumentsDefaultFieldsToUpload = createSelector(
  getOrgDocumentsMetadataDefaultFields,
  (
    defaultValues: reducerTypes.OrgDocumentsMetadataState['defaultFields']
  ): Partial<apiTypes.MetadataFromResponse> => {
    return defaultValues.reduce((defaultValues, field) => {
      if (!field.key) {
        return defaultValues;
      }

      return {
        ...defaultValues,
        [field.key]: field.defaultValue
      };
    }, {});
  }
);

export const getOrgDocumentsCaiCapabilitiesForUpload = createSelector(
  getSelectedCAICapabilities,
  (selectedCAICapabilities: reducerTypes.OrgDocumentsMetadataState['selectedCAICapabilities']) => {
    return Object.entries(selectedCAICapabilities).reduce(
      (formattedCapabilities, [capabilityKey, isSelected]) => {
        return {
          ...formattedCapabilities,
          [constants.CAI_METADATA_CAPABILITIES_QUERY_PARAMS[
            capabilityKey as constants.CAI_METADATA_CAPABILITIES_KEYS
          ]]: isSelected
        };
      },
      {}
    );
  }
);

export const getMetadataForUpload = createSelector(
  getOrgDocumentsMetadata,
  (
    metadata: reducerTypes.OrgDocumentsMetadataState['metadata']
  ): Record<string, Record<docFieldReducerTypes.DocFieldOption['value'], unknown[]>> => {
    return Object.fromEntries(
      Object.entries(metadata).map(([fileName, documentMetadata]) => {
        const documentData = Object.fromEntries(
          Object.entries(documentMetadata).map(([metadataKey, metadataItem]) => {
            return [metadataItem.mappedCaiDocField || metadataKey, metadataItem.data];
          })
        );

        return [fileName, documentData];
      })
    );
  }
);
