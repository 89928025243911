import type { LanguagesSelectProps } from '../LanguagesSelect.types';
import type { LanguagesSelectHandlers } from './useLanguagesSelectHandlers.types';

export const useLanguagesSelectHandlers = ({
  props
}: {
  props: Pick<LanguagesSelectProps, 'shouldAlwaysReturnArray' | 'onChange'>;
}): LanguagesSelectHandlers => {
  const handleChange: LanguagesSelectHandlers['handleChange'] = value => {
    if (props.shouldAlwaysReturnArray) {
      if (!value) {
        return props.onChange?.([]);
      }

      props.onChange?.(Array.isArray(value) ? value : [value]);
    } else {
      props.onChange?.(value);
    }
  };

  return {
    handleChange
  };
};
