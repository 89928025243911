import { trainingCenterUrl } from 'shared/config';
import { QUERY_PARAMS } from 'shared/features/auth/auth.constants';
import queryString from 'utils/query-string';

export const useSupportHandlers = ({ localState, localActions, reduxState, authReduxActions }) => {
  const handleMenuOpen = () => {
    localActions.setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    localActions.setIsMenuOpen(false);
  };

  const handleRedirect = url => () => window.open(url, '_blank');

  const handleFeedbackOpen = () => {
    localActions.setIsGivingFeedback(true);
  };

  const handleFeedbackClose = () => {
    localActions.setIsGivingFeedback(false);
  };

  const handleTrainingCenterRedirect = async () => {
    if (!reduxState.isLoggedIn) {
      return handleRedirect(trainingCenterUrl)();
    }

    const { approvedAppToken } = await authReduxActions.generateExternalApiToken();

    if (approvedAppToken) {
      const queryParams = queryString.stringify({
        [QUERY_PARAMS.APPROVED_APP_TOKEN]: approvedAppToken
      });
      const url = `${trainingCenterUrl}?${queryParams}`;
      return handleRedirect(url)();
    }
  };

  return {
    handleMenuOpen,
    handleMenuClose,
    handleRedirect,
    handleFeedbackOpen,
    handleFeedbackClose,
    handleTrainingCenterRedirect
  };
};
