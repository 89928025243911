import type { SentenceNoteContentProps } from '../SentenceNoteContent.types';

import * as queryKeys from 'constants/QueryKeys';
import * as errorUtils from 'utils/errors';
import * as sentenceNotesApi from 'shared/features/sentenceNotes/sentenceNotes.api';
import * as sentenceNotesHelpers from 'shared/features/sentenceNotes/sentenceNotes.helpers';

import { useQuery } from '@tanstack/react-query';

export const useSentenceNoteContentQueries = ({
  props
}: {
  props: Pick<SentenceNoteContentProps, 'id'>;
}) => {
  const fetchSentenceNote = useQuery({
    queryKey: queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.SENTENCE_NOTES][
      queryKeys.QUERY_SECTIONS.SENTENCE_NOTE_CONTENT
    ](props.id),
    queryFn: () => sentenceNotesApi.getSentenceNoteById(props.id),
    retry: false,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    select: data => ({
      sentenceNote: data,
      attachments: sentenceNotesHelpers.normalizeAttachments(data.attachments)
    }),
    onError: errorUtils.logReactQueryError
  });

  return {
    fetchSentenceNote
  };
};
