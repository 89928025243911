import { fetch } from 'utils/api';
import { AUTHENTICATION_OPTIONS_KEYS } from 'fetch';

export function api_fetchAgencies(params, shouldUseApiToken = false) {
  return fetch({
    url: `/agencies`,
    params,
    dataType: 'agencies',
    method: 'GET',
    authenticationType: shouldUseApiToken ? AUTHENTICATION_OPTIONS_KEYS.API_KEY : undefined
  });
}

export function api_fetchAgencyInfo(agencyId) {
  return fetch({
    url: '/agency_infos',
    params: {
      agency_id: agencyId
    },
    authenticationType: AUTHENTICATION_OPTIONS_KEYS.API_KEY,
    dataType: 'agency',
    method: 'GET'
  });
}

export function api_fetchAgencyEnforcementsByMonth(params) {
  return fetch({
    url: '/agency_enforcements_by_month',
    params,
    authenticationType: AUTHENTICATION_OPTIONS_KEYS.API_KEY,
    dataType: 'enforcement',
    method: 'GET'
  });
}
