import lodash from 'lodash';
import { WORKFLOW_ANNOTATION_SETTINGS_KEYS } from 'shared/features/workflow/workflow.constants';
import { formatAssigneeName } from 'shared/features/workflow/workflow.helpers';

const getPotentialAssignees = (documentTask, organization) => {
  let potentialAssignees = [];

  if (organization.isReady) {
    const assigneeIds = lodash.get(
      documentTask.annotation_settings,
      WORKFLOW_ANNOTATION_SETTINGS_KEYS.ASSIGNEE_OPTIONS,
      []
    );
    if (assigneeIds.length) {
      potentialAssignees = organization.organization.organization_members
        .filter(member => assigneeIds.includes(member.id))
        .map(member => ({
          value: member.id,
          disabled: !member.active,
          label: member.email,
          active: member.active,
          email: member.email
        }));
    }
  }

  return potentialAssignees;
};

const convertOptionsToString = (values, options) => {
  const selectedOptions = options.filter(({ value }) => values.includes(value));
  return selectedOptions.map(formatAssigneeName).join(', ');
};

const assigneeOptionsToSelectOptions = assigneeOptions => {
  if (Array.isArray(assigneeOptions)) {
    return assigneeOptions.map(option => ({
      value: option.value,
      label: formatAssigneeName(option)
    }));
  }
  return [];
};

export default {
  getPotentialAssignees,
  convertOptionsToString,
  assigneeOptionsToSelectOptions
};
