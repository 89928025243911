import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PropTypes from 'prop-types';
import './_widget-customization-modal-header.scss';

const WidgetCustomizationModalHeader = ({
  widgetName,
  isDefaultComponent,
  handleNameChange,
  handleDeleteClick,
  handleCopyClick,
  isNameChangeDisabled
}) => {
  return (
    <div className="widget-customization-modal-header__container">
      <div className="widget-customization-modal-header__name-input-wrapper">
        <input
          className="widget-customization-modal-header__name-input"
          type="text"
          onChange={handleNameChange}
          value={widgetName}
          data-testid="name-field"
          disabled={isNameChangeDisabled}
        />
      </div>
      <div className="widget-customization-modal-header__icon-container">
        {!isDefaultComponent && (
          <DeleteIcon
            className="widget-customization-modal-header__icon"
            onClick={handleDeleteClick}
          />
        )}
        <FileCopyIcon
          className="widget-customization-modal-header__icon"
          onClick={handleCopyClick}
        />
      </div>
    </div>
  );
};

WidgetCustomizationModalHeader.propTypes = {
  widgetName: PropTypes.string.isRequired,
  isDefaultComponent: PropTypes.bool.isRequired,
  handleNameChange: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  handleCopyClick: PropTypes.func.isRequired,
  isNameChangeDisabled: PropTypes.bool.isRequired
};

export default WidgetCustomizationModalHeader;
