import type { WorkflowDiagramToolHandlers } from './useWorkflowDiagramToolHandlers.types';
import type { WorkflowDiagramToolData } from './useWorkflowDiagramToolData.types';
import type { WorkflowDiagramToolProps } from '../WorkflowDiagramTool.types';

import * as constants from '../WorkflowDiagramTool.constants';
import * as helpers from '../WorkflowDiagramTool.helpers';
import * as handlersHelpers from './useWorkflowDiagramToolHandlers.helpers';

import { useCallback } from 'react';
import { usePrint } from '@compliance.ai/web-components';

export const useWorkflowDiagramToolHandlers = ({
  props,
  localState,
  localActions
}: {
  props: Pick<WorkflowDiagramToolProps, 'workflow'>;
  localState: WorkflowDiagramToolData['localState'];
  localActions: WorkflowDiagramToolData['localActions'];
}): WorkflowDiagramToolHandlers => {
  const print = usePrint({
    bodyClass: 'workflow-diagram-tool__pdf-body',
    copyStyles: true,
    documentTitle: `${props.workflow?.name} diagram`,
    content: () => document.querySelector(constants.DIAGRAM_VIEWPORT_SELECTOR),
    pageStyle: `
      @page {
        margin: 0;
        padding: 0;
      }
    `
  });

  const handleInitialNodesSet: WorkflowDiagramToolHandlers['handleInitialNodesSet'] = useCallback(() => {
    if (!props.workflow) {
      localActions.setNodes([]);
      localActions.setEdges([]);
    } else {
      const { nodes, edges } = helpers.getLayoutedElements([
        ...helpers.formatTasksForDiagram(props.workflow),
        ...helpers.formatTasksLinksForDiagram(props.workflow.tasks)
      ]);

      localActions.setEdges(edges);
      localActions.setNodes(nodes);
    }
  }, [localActions, props.workflow]);

  const handleDiagramLoad: WorkflowDiagramToolHandlers['handleDiagramLoad'] = useCallback(
    reactFlowInstance => {
      localActions.setDiagramInstance(reactFlowInstance);
    },
    [localActions]
  );

  const handleDiagramPrint: WorkflowDiagramToolHandlers['handleDiagramPrint'] = () => {
    localState.diagramInstance?.fitView?.({
      includeHiddenNodes: true,
      minZoom: constants.MIN_ZOOM
    });

    localActions.setIsReadyForPrint(true);
  };

  const handleDiagramReadyForPrint: WorkflowDiagramToolHandlers['handleDiagramReadyForPrint'] = useCallback(() => {
    if (localState.isReadyForPrint) {
      /**
       * A hack to correctly display diagram in PDFs
       */
      handlersHelpers.setVisibleOverflowForEdges();

      print();

      localActions.setIsReadyForPrint(false);
    }
  }, [localActions, localState.isReadyForPrint, print]);

  return {
    handleInitialNodesSet,
    handleDiagramLoad,
    handleDiagramPrint,
    handleDiagramReadyForPrint
  };
};
