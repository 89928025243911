import { useMemo, useState } from 'react';
import { getEnteredCorporateEmail } from 'utils/localStorage-utils';

export const useCorporateLoginData = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState(getEnteredCorporateEmail());
  const [isLoading, setLoadingState] = useState(false);

  const localActions = useMemo(
    () => ({
      setIsOpen,
      setEmail,
      setLoadingState
    }),
    []
  );

  return {
    localState: {
      email,
      isOpen,
      isLoading
    },
    localActions
  };
};
