import type { WorkflowDiagramToolProps } from './WorkflowDiagramTool.types';
import type { NodeTypes } from 'reactflow';

import React, { memo } from 'react';
import ReactFlow, { ReactFlowProvider, Controls, Background } from 'reactflow';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';
import * as constants from './WorkflowDiagramTool.constants';

import {
  useWorkflowDiagramToolData,
  useWorkflowDiagramToolHandlers,
  useWorkflowDiagramToolLifecycle
} from './hooks';

import './_workflow-diagram-tool.scss';

export const CUSTOM_ELEMENTS: NodeTypes = {
  [constants.NODE_TYPES.TASK]: (elements.TaskNode as unknown) as NodeTypes[keyof NodeTypes]
};

export const WorkflowDiagram = memo(({ workflow }: WorkflowDiagramToolProps) => {
  const { refs, localState, localActions } = useWorkflowDiagramToolData();

  const handlers = useWorkflowDiagramToolHandlers({
    props: {
      workflow
    },
    localState,
    localActions
  });

  useWorkflowDiagramToolLifecycle({
    onInitialNodesSet: handlers.handleInitialNodesSet,
    onReadyForPrint: handlers.handleDiagramReadyForPrint
  });

  return (
    <div
      ref={refs.diagramRef}
      data-testid={constants.TEST_IDS.CONTAINER}
      className="workflow-diagram-tool__container"
    >
      <ReactFlow
        nodes={localState.nodes}
        edges={localState.edges}
        onNodesChange={localActions.onNodesChange}
        onEdgesChange={localActions.onEdgesChange}
        nodeTypes={CUSTOM_ELEMENTS}
        fitView
        className="workflow-diagram-tool__diagram"
        onInit={handlers.handleDiagramLoad}
      >
        <div className="workflow-diagram-tool__controls-container">
          <div className="workflow-diagram-tool__controls-aligner">
            <button
              className="workflow-diagram-tool__custom-control-button"
              onClick={handlers.handleDiagramPrint}
            >
              <uiLib.IconPrint className="workflow-diagram-tool__custom-control-icon" />
            </button>
            <Controls
              className="workflow-diagram-tool__controls"
              fitViewOptions={{
                includeHiddenNodes: true,
                minZoom: constants.MIN_ZOOM
              }}
            />
          </div>
        </div>
        <Background />
      </ReactFlow>
    </div>
  );
});

/**
 *
 * The separation into two components is required to use ReactFlow hooks (like useStoreState) inside
 * the WorkflowDiagram component
 *
 */
export const WorkflowDiagramTool = memo(({ workflow }: WorkflowDiagramToolProps) => {
  return (
    <ReactFlowProvider>
      <WorkflowDiagram workflow={workflow} />
    </ReactFlowProvider>
  );
});
