import * as config from './AdvancedSearchBuilder.config';
import * as defaultsHelpers from 'shared/features/defaults/defaults.helpers';
import * as filterDefaultOptionsHelpers from 'shared/features/filters/hooks/useFilterDefaultOptions.helpers';

import lodash from 'lodash';

import { formatMetaCategories } from 'shared/features/documents/documents.selectors';

const formatJurisdictions = jurisdictions => {
  return jurisdictions
    .filter(jurisdiction => jurisdiction.short_name && jurisdiction.name)
    .map(jurisdiction => {
      const jurisdictionLabel = `${jurisdiction.name} (${jurisdiction.short_name})`;
      return {
        label: jurisdictionLabel,
        value: jurisdiction.short_name
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
};

const formatNewsSources = newsSources => {
  return newsSources.map(newsSource => {
    return {
      label: newsSource.name,
      value: newsSource.name
    };
  });
};

const formatTopics = topics => {
  return topics
    .map(topic => {
      return { label: topic.name, value: topic.id };
    })
    .sort((topicA, topicB) => topicA.label.localeCompare(topicB.label));
};

const formatRegulations = regulations => {
  return regulations
    .map(regulation => {
      return {
        label: regulation.name,
        value: regulation.id
      };
    })
    .sort((regA, regB) => regA.label.localeCompare(regB.label));
};

const formatDocTypes = docTypes => {
  const { cai_metacategories: metacategories } = docTypes;
  return formatMetaCategories(metacategories);
};

const formatDocTypesLegacy = docTypes => {
  const metaCategoriesObject = lodash.get(docTypes, 'metacategory_dic', {});
  return Object.keys(metaCategoriesObject).map(metaCategory => {
    return {
      label: metaCategory,
      options: metaCategoriesObject[metaCategory]
        .map(appCategory => {
          return { label: appCategory, value: appCategory };
        })
        .sort((docTypeA, docTypeB) => docTypeA.label.localeCompare(docTypeB.label))
    };
  });
};

const formatLanguages = languages => {
  return languages
    .map(language => {
      return { label: language.name, value: language.id };
    })
    .sort((languageA, languageB) => languageA.label.localeCompare(languageB.label));
};

const formatActs = acts => lodash.map(acts, act => ({ value: `${act.id}`, label: act.name }));

const formatBanks = banks => lodash.map(banks, bank => ({ value: `${bank.id}`, label: bank.name }));

const formatAgencies = agencies => {
  return agencies
    .filter(agency => !lodash.isNil(agency.short_name))
    .map(agency => ({
      label: agency.short_name,
      value: agency.short_name
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const formatConfigParams = props => {
  return {
    agencies: formatAgencies(props.agencies),
    jurisdictions: formatJurisdictions(props.jurisdictions),
    news_sources: formatNewsSources(props.sources.defaultMainstreamNewsSources),
    topics: formatTopics(props.sources.activeTopics),
    regulations: formatRegulations(props.regulations),
    docTypes: formatDocTypes(props.docTypes),
    acts: formatActs(props.acts),
    banks: formatBanks(props.banks),
    labels: props.labels,
    groupedLabelOptions: props.groupedLabelOptions,
    docTypesLegacy: formatDocTypesLegacy(props.docTypes),
    languages: formatLanguages(props.languages),
    authorsOptions: props.authorsOptions,
    userHasSKU: props.userHasSKU
  };
};

function searchTree(tree, keysToValues) {
  return Object.entries(tree).reduce((obj, [key, values]) => {
    if (['and', 'or'].includes(key)) {
      return values.reduce(
        (innerObj, innerTree) => ({
          ...searchTree(innerTree, innerObj)
        }),
        {
          ...obj
        }
      );
    } else {
      if (key === 'all') {
        const fieldKey = values[0].var;
        const fieldValues = values[1].in[1];
        return {
          ...obj,
          [fieldKey]: obj[fieldKey] ? [...obj[fieldKey], ...fieldValues] : fieldValues
        };
      }

      return obj;
    }
  }, keysToValues);
}

export function getAdvancedSearchDataToLoadFromAPI(tree) {
  try {
    const valuesToLoad = searchTree(tree, {});
    return Object.entries(valuesToLoad).reduce(
      (obj, [key, values]) => ({
        ...obj,
        ...(config.ADVANCED_SEARCH_BUILDER_KEYS_TO_LOAD_FROM_API.includes(key)
          ? { [key]: values }
          : {})
      }),
      {}
    );
  } catch (exception) {
    // Unable to parse tree so be safe and return empty
    return {};
  }
}

export const formatConceptsToQuery = (conceptValues, concepts) =>
  conceptValues.map(conceptValue => concepts[conceptValue.value].advanced_search).join(' OR ');

export const formatQueryWithQueryFromSelection = ({ query, queryFromSelection }) => {
  if (queryFromSelection && query) {
    return `((${query}) AND (${queryFromSelection}))`;
  }

  if (queryFromSelection && !query) {
    return queryFromSelection;
  }

  return query;
};

export const formatConceptOptions = ({ concepts, metaConcepts }) =>
  Object.keys(metaConcepts).map(metaConcept => ({
    label: metaConcepts[metaConcept].name,
    options: metaConcepts[metaConcept].concepts.map(concept => ({
      value: concept,
      label: concepts[concept].name
    }))
  }));

export const prepareDefaultFilters = ({ reduxState, defaultsData }) => {
  return filterDefaultOptionsHelpers.getFormattedDefaultOptions({
    allCategories: reduxState.docTypes.cai_categories,
    allConceptOptions: reduxState.conceptOptions,
    defaultTopics: defaultsData.defaultTopics,
    followedAgencies: defaultsHelpers.getFilteredFollowedAgencies({
      agencies: reduxState.agencies,
      followedAgenciesIdsMap: defaultsData.followedAgencies
    }),
    followedCategories: defaultsData.followedCategories,
    followedConcepts: defaultsHelpers.getActiveFollowedConcepts({
      activeConcepts: reduxState.concepts.concepts,
      followedConcepts: defaultsData.followedConcepts || {}
    }),
    followedJurisdictions: defaultsHelpers.getFilteredNonCountryJurisdictions(
      defaultsHelpers.getFilteredFollowedJurisdictions({
        followedJurisdictionsIdsMap: defaultsData.followedJurisdictions,
        jurisdictions: reduxState.jurisdictions
      })
    ),
    followedMainstreamNewsSources: filterDefaultOptionsHelpers.getFollowedMainstreamNewsSourcesFromEntities(
      defaultsData.followedEntities || []
    ),
    followedTopics: defaultsData.realFollowedTopics || {},
    metaCategoriesObject: reduxState.docTypes.cai_metacategories || {}
  });
};
