import { createSlice } from '@reduxjs/toolkit';
import {
  fetchFolders,
  fetchFolderDetails,
  resetFolderDetails,
  fetchFolderPaths,
  resetFolderPaths,
  createFolder,
  modifyFolder,
  deleteFolders,
  copyFolder,
  shareFolder,
  triggerFoldersRefresh
} from './folders.actions';

export const REDUCER_NAME = 'folders';

export const INITIAL_STATE = {
  isLoading: false,
  totalCount: 0,
  folders: [],
  foldersTrigger: false,
  currentFolder: null,
  paths: []
};

export const { actions, reducer } = createSlice({
  name: REDUCER_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: {
    [fetchFolders.pending]: state => {
      state.isLoading = true;
    },
    [fetchFolders.fulfilled]: (state, action) => {
      const { folders, totalCount } = action.payload;

      state.isLoading = false;
      state.folders = folders;
      state.totalCount = totalCount;
    },
    [fetchFolders.rejected]: state => {
      state.isLoading = false;
    },
    [fetchFolderDetails.pending]: state => {
      state.isLoading = true;
    },
    [fetchFolderDetails.fulfilled]: (state, action) => {
      const currentFolder = action.payload;

      state.isLoading = false;
      state.currentFolder = currentFolder;
    },
    [fetchFolderDetails.rejected]: state => {
      state.isLoading = false;
    },
    [resetFolderDetails.type]: state => {
      state.currentFolder = null;
    },
    [fetchFolderPaths.pending]: state => {
      state.isLoading = true;
    },
    [fetchFolderPaths.fulfilled]: (state, action) => {
      const { result: paths = [] } = action.payload;

      state.isLoading = false;
      state.paths = paths;
    },
    [fetchFolderPaths.rejected]: state => {
      state.isLoading = false;
    },
    [resetFolderPaths.type]: state => {
      state.paths = [];
    },
    [createFolder.pending]: state => {
      state.isLoading = true;
    },
    [createFolder.fulfilled]: state => {
      state.isLoading = false;
    },
    [createFolder.rejected]: state => {
      state.isLoading = false;
    },
    [modifyFolder.pending]: state => {
      state.isLoading = true;
    },
    [modifyFolder.fulfilled]: state => {
      state.isLoading = false;
    },
    [modifyFolder.rejected]: state => {
      state.isLoading = false;
    },
    [deleteFolders.pending]: state => {
      state.isLoading = true;
    },
    [deleteFolders.fulfilled]: state => {
      state.isLoading = false;
    },
    [deleteFolders.rejected]: state => {
      state.isLoading = false;
    },
    [copyFolder.pending]: state => {
      state.isLoading = true;
    },
    [copyFolder.fulfilled]: state => {
      state.isLoading = false;
    },
    [copyFolder.rejected]: state => {
      state.isLoading = false;
    },
    [shareFolder.pending]: state => {
      state.isLoading = true;
    },
    [shareFolder.fulfilled]: state => {
      state.isLoading = false;
    },
    [shareFolder.rejected]: state => {
      state.isLoading = false;
    },
    [triggerFoldersRefresh.type]: state => {
      state.foldersTrigger = !state.foldersTrigger;
    }
  }
});
