import type * as apiTypes from './comments.api.types';

import { fetch } from 'fetch';

export const createComment: apiTypes.CreateComment = params => {
  return fetch({
    url: `/comments`,
    method: 'POST',
    params
  });
};

export const deleteComment: apiTypes.DeleteComment = commentId => {
  return fetch({
    url: `/comments/${commentId}`,
    method: 'DELETE'
  });
};

export const editComment: apiTypes.EditComment = (commentId, params) => {
  return fetch({
    url: `/comments/${commentId}`,
    method: 'PUT',
    params
  });
};

export const createDocumentTaskCommentThread: apiTypes.CreateDocumentTaskCommentThread = params => {
  return fetch({
    url: `/document_task_comment_threads`,
    method: 'POST',
    params
  });
};

export const createSentenceNoteCommentThread: apiTypes.CreateSentenceNoteCommentThread = params => {
  return fetch({
    url: `/sentence_note_comment_threads`,
    method: 'POST',
    params
  });
};
