export enum FILTER_KEY {
  PUB_DATE = 'pubDate',
  HAS_UNOFFICIAL_PUB_DATE = 'hasUnofficialPubDate',
  PUB_START_DATE = 'pubStartDate',
  PUB_END_DATE = 'pubEndDate',
  AGENCIES = 'agencies',
  JURISDICTIONS = 'jurisdictions',
  MAINSTREAM_NEWS_SOURCES = 'mainstreamNewsSources',
  TOPICS = 'topics',
  CONCEPTS = 'concepts',
  DOC_PROPERTIES = 'docProperties',
  DOC_TYPES = 'docTypes',
  DOCUMENT_ID = 'documentId',
  REGULATIONS = 'regulations',
  ACTS = 'acts',
  BANKS = 'banks',
  LABELS = 'labels',
  CITATION = 'citation',
  TITLE = 'title',
  TEXT = 'text',
  LANGUAGE = 'language',
  ADDED_DATE = 'addedDate',
  ADDED_START_DATE = 'addedStartDate',
  ADDED_END_DATE = 'addedEndDate',
  UPDATED_DATE = 'updatedState',
  UPDATED_START_DATE = 'updatedStartState',
  UPDATED_END_DATE = 'updatedEndDate',
  EFFECTIVE_DATE = 'effectiveDate',
  EFFECTIVE_START_DATE = 'effectiveStartDate',
  EFFECTIVE_END_DATE = 'effectiveEndDate',
  COMMENTS_CLOSE_DATE = 'commentsCloseDate',
  COMMENTS_CLOSE_START_DATE = 'commentsCloseStartDate',
  COMMENTS_CLOSE_END_DATE = 'commentsCloseEndDate',
  TEXT_MATCH_TYPES = 'textMatchTypes',
  TITLE_MATCH_TYPES = 'titleMatchTypes',
  AUTHOR = 'author',
  HAS_EITL_LABELS = 'hasEitlLabels',
  EITL_LABELS = 'eitlLabels',
  EXCLUDE_FEDERAL_REGISTER = 'excludeFederalRegister',
  DEFAULT_VIEW = 'defaultView'
}

export enum CUSTOM_FILTER_OPTIONS_KEYS {
  QUERY = 'query',
  ENFORCEMENTS_CATEGORY = 'enforcementsDocTypesOptions'
}

export const ERROR_KEY = {
  PUB_DATE: 'pubDate',
  PUB_START_DATE: 'pubStartDate',
  PUB_END_DATE: 'pubEndDate',
  PUB_DATE_ORDER: 'pubDateOrder',
  ADDED_DATE: 'addedDate',
  ADDED_START_DATE: 'addedStartDate',
  ADDED_END_DATE: 'addedEndDate',
  ADDED_DATE_ORDER: 'addedDateOrder',
  UPDATED_DATE: 'updatedState',
  UPDATED_START_DATE: 'updatedStartState',
  UPDATED_END_DATE: 'updatedEndDate',
  UPDATED_DATE_ORDER: 'updatedDateOrder',
  EFFECTIVE_DATE: 'effectiveDate',
  EFFECTIVE_START_DATE: 'effectiveStartDate',
  EFFECTIVE_END_DATE: 'effectiveEndDate',
  EFFECTIVE_DATE_ORDER: 'effectiveDateOrder',
  COMMENTS_CLOSE_DATE: 'commentsCloseDate',
  COMMENTS_CLOSE_START_DATE: 'commentsCloseStartDate',
  COMMENTS_CLOSE_END_DATE: 'commentsCloseEndDate',
  COMMENTS_CLOSE_DATE_ORDER: 'commentsCloseDateOrder'
};

export const SUPPORTED_QUERY_PARAMS = {
  AGENCY_ID: 'agency_id',
  AGENCY: 'agency',
  AUTHOR_ID: 'author_id',
  PUBLICATION_DATE: 'publication_date',
  HAS_UNOFFICIAL_PUBLICATION_DATE: 'has_unofficial_publication_date',
  PUBLISHED_FROM: 'published_from',
  PUBLISHED_TO: 'published_to',
  DOC_PROPERTIES: 'docProperties',
  TITLE: 'title',
  TEXT: 'text',
  CITATION: 'citation',
  CONCEPT_ID: 'concept_id',
  TASK_OWNER: 'task_owner',
  MORE_LIKE_DOC_ID: 'more_like_doc_id',
  HAS_SENTENCES: 'has_sentences',
  HAS_ANNOTATIONS: 'has_annotations',
  HAS_OBLIGATIONS: 'has_obligations',
  IS_PREMIUM_CONTENT: 'is_premium_content',
  IS_ORGANIZATION_DOCUMENT: 'organization_documents_only',
  NEWS_SOURCE_ID: 'news_source_id',
  JURISDICTION: 'jurisdiction',
  LABEL: 'label',
  LANGUAGE_ID: 'language_id',
  TOPIC_ID: 'topic_id',
  CATEGORY_ID: 'category_id',
  CATEGORY: 'cai_category',
  CAI_CATEGORY: 'cai_category',
  REGULATION_ID: 'regulation_id',
  BANK_ID: 'bank_id',
  ACT_ID: 'act_id',
  LIMIT: 'limit',
  SEARCH_QUERY: 'search_query',
  QUERY: 'query',
  DOCKET_ID: 'docket_id',
  SEARCH_SORT: 'search_sort',
  MIN_SCORE: 'min_score',
  OVERRIDE_MIN_SCORE: 'override_min_score',
  VIEW: 'view',
  ORDER: 'order',
  SKIP_CATEGORY: 'skip_category',
  SKIP_CAI_CATEGORY: 'skip_cai_category',
  SUMMARY_ID: 'summary_id',
  SUMMARY_PAGE: 'summary_page',
  OFFSET: 'offset',
  OVERLAY: 'overlay',
  SORT: 'sort',
  ADVANCED_SEARCH: 'advanced_search',
  ADDED_DATE: 'created_date',
  ADDED_FROM: 'created_from',
  ADDED_TO: 'created_to',
  UPDATED_DATE: 'updated_date',
  UPDATED_FROM: 'updated_from',
  UPDATED_TO: 'updated_to',
  EFFECTIVE_DATE: 'effective_date',
  EFFECTIVE_FROM: 'effective_from',
  EFFECTIVE_TO: 'effective_to',
  COMMENTS_CLOSE_DATE: 'comments_close_date',
  COMMENTS_CLOSE_FROM: 'comments_close_from',
  COMMENTS_CLOSE_TO: 'comments_close_to',
  TEXT_MATCH_TYPE: 'text_match_type',
  TITLE_MATCH_TYPE: 'title_match_type',
  FOLDER_TIMELINE_VIEW: 'folderTimelineView',
  TIMELINE_VIEW: 'timeline_view',
  TOPIC_THRESHOLD_KEY: 'topic_threshold_key',
  EXCLUSIVE_FIELDS: 'exclusive_fields',
  EXCLUSIVE_IDS: 'exclusive_ids',
  AUTOSUGGEST_FILTER: 'autosuggest_filter', // TODO Check if we really use it
  ALL_TOPICS: 'all_topics',
  ALL_AGENCIES: 'all_agencies',
  HAS_EITL_LABELS: 'has_eitl_labels',
  EITL_LABEL_ID: 'eitl_label_id',
  EXCLUDE_FEDERAL_REGISTER: 'exclude_federal_register',
  DOCUMENT_VERSION_LATEST: 'document_version_latest',
  DOCUMENT_VERSION_DOCKET_ID: 'document_version_docket_id',
  DOC_ID: 'doc_id',
  BUCKET_ID: 'bucket_id',
  HAS_BUCKET: 'has_bucket',
  DEFAULT_VIEW: 'defaultView'
};

export enum CUSTOM_OFFSET_PARAMS {
  PUB_DATE_COUNT = 'pub_date_count',
  PUB_DATE_OFFSET = 'pub_date_offset',
  EFFECTIVE_ON_DATE_COUNT = 'effective_on_count',
  EFFECTIVE_ON_DATE_OFFSET = 'effective_on_date_offset',
  COMMENTS_CLOSE_DATE_COUNT = 'comments_close_count',
  COMMENTS_CLOSE_DATE_OFFSET = 'comments_close_date_offset',
  PREVIOUS_DOCS = 'previous_docs'
}

export const SUPPORTED_QUERY_PARAMS_TO_FILTER_KEYS_MAP = {
  [SUPPORTED_QUERY_PARAMS.ACT_ID]: FILTER_KEY.ACTS,
  [SUPPORTED_QUERY_PARAMS.BANK_ID]: FILTER_KEY.BANKS,
  [SUPPORTED_QUERY_PARAMS.AGENCY_ID]: FILTER_KEY.AGENCIES,
  [SUPPORTED_QUERY_PARAMS.AGENCY]: FILTER_KEY.AGENCIES,
  [SUPPORTED_QUERY_PARAMS.PUBLICATION_DATE]: FILTER_KEY.PUB_DATE,
  [SUPPORTED_QUERY_PARAMS.PUBLISHED_FROM]: FILTER_KEY.PUB_START_DATE,
  [SUPPORTED_QUERY_PARAMS.PUBLISHED_TO]: FILTER_KEY.PUB_END_DATE,
  [SUPPORTED_QUERY_PARAMS.ADDED_DATE]: FILTER_KEY.ADDED_DATE,
  [SUPPORTED_QUERY_PARAMS.ADDED_FROM]: FILTER_KEY.ADDED_START_DATE,
  [SUPPORTED_QUERY_PARAMS.ADDED_TO]: FILTER_KEY.ADDED_END_DATE,
  [SUPPORTED_QUERY_PARAMS.UPDATED_DATE]: FILTER_KEY.UPDATED_DATE,
  [SUPPORTED_QUERY_PARAMS.UPDATED_FROM]: FILTER_KEY.UPDATED_START_DATE,
  [SUPPORTED_QUERY_PARAMS.UPDATED_TO]: FILTER_KEY.UPDATED_END_DATE,
  [SUPPORTED_QUERY_PARAMS.EFFECTIVE_DATE]: FILTER_KEY.EFFECTIVE_DATE,
  [SUPPORTED_QUERY_PARAMS.EFFECTIVE_FROM]: FILTER_KEY.EFFECTIVE_START_DATE,
  [SUPPORTED_QUERY_PARAMS.EFFECTIVE_TO]: FILTER_KEY.EFFECTIVE_END_DATE,
  [SUPPORTED_QUERY_PARAMS.COMMENTS_CLOSE_DATE]: FILTER_KEY.COMMENTS_CLOSE_DATE,
  [SUPPORTED_QUERY_PARAMS.COMMENTS_CLOSE_FROM]: FILTER_KEY.COMMENTS_CLOSE_START_DATE,
  [SUPPORTED_QUERY_PARAMS.COMMENTS_CLOSE_TO]: FILTER_KEY.COMMENTS_CLOSE_END_DATE,
  [SUPPORTED_QUERY_PARAMS.DOC_PROPERTIES]: FILTER_KEY.DOC_PROPERTIES,
  [SUPPORTED_QUERY_PARAMS.TITLE]: FILTER_KEY.TITLE,
  [SUPPORTED_QUERY_PARAMS.TEXT]: FILTER_KEY.TEXT,
  [SUPPORTED_QUERY_PARAMS.CITATION]: FILTER_KEY.CITATION,
  [SUPPORTED_QUERY_PARAMS.TASK_OWNER]: FILTER_KEY.DOC_PROPERTIES,
  [SUPPORTED_QUERY_PARAMS.HAS_SENTENCES]: FILTER_KEY.DOC_PROPERTIES,
  [SUPPORTED_QUERY_PARAMS.HAS_ANNOTATIONS]: FILTER_KEY.DOC_PROPERTIES,
  [SUPPORTED_QUERY_PARAMS.HAS_OBLIGATIONS]: FILTER_KEY.DOC_PROPERTIES,
  [SUPPORTED_QUERY_PARAMS.IS_PREMIUM_CONTENT]: FILTER_KEY.DOC_PROPERTIES,
  [SUPPORTED_QUERY_PARAMS.NEWS_SOURCE_ID]: FILTER_KEY.MAINSTREAM_NEWS_SOURCES,
  [SUPPORTED_QUERY_PARAMS.JURISDICTION]: FILTER_KEY.JURISDICTIONS,
  [SUPPORTED_QUERY_PARAMS.LABEL]: FILTER_KEY.LABELS,
  [SUPPORTED_QUERY_PARAMS.LANGUAGE_ID]: FILTER_KEY.LANGUAGE,
  [SUPPORTED_QUERY_PARAMS.TOPIC_ID]: FILTER_KEY.TOPICS,
  [SUPPORTED_QUERY_PARAMS.CATEGORY_ID]: FILTER_KEY.DOC_TYPES,
  [SUPPORTED_QUERY_PARAMS.CATEGORY]: FILTER_KEY.DOC_TYPES,
  [SUPPORTED_QUERY_PARAMS.REGULATION_ID]: FILTER_KEY.REGULATIONS,
  [SUPPORTED_QUERY_PARAMS.CONCEPT_ID]: FILTER_KEY.CONCEPTS,
  [SUPPORTED_QUERY_PARAMS.TEXT_MATCH_TYPE]: FILTER_KEY.TEXT_MATCH_TYPES,
  [SUPPORTED_QUERY_PARAMS.TITLE_MATCH_TYPE]: FILTER_KEY.TITLE_MATCH_TYPES,
  [SUPPORTED_QUERY_PARAMS.EITL_LABEL_ID]: FILTER_KEY.EITL_LABELS,
  [SUPPORTED_QUERY_PARAMS.EXCLUDE_FEDERAL_REGISTER]: FILTER_KEY.EXCLUDE_FEDERAL_REGISTER,
  [SUPPORTED_QUERY_PARAMS.DOC_ID]: FILTER_KEY.DOCUMENT_ID,
  [SUPPORTED_QUERY_PARAMS.HAS_EITL_LABELS]: FILTER_KEY.HAS_EITL_LABELS
};

export const DATE_FILTERS = [
  FILTER_KEY.PUB_DATE,
  FILTER_KEY.PUB_START_DATE,
  FILTER_KEY.PUB_END_DATE,
  FILTER_KEY.ADDED_DATE,
  FILTER_KEY.ADDED_START_DATE,
  FILTER_KEY.ADDED_END_DATE,
  FILTER_KEY.UPDATED_DATE,
  FILTER_KEY.UPDATED_START_DATE,
  FILTER_KEY.UPDATED_END_DATE,
  FILTER_KEY.EFFECTIVE_DATE,
  FILTER_KEY.EFFECTIVE_START_DATE,
  FILTER_KEY.EFFECTIVE_END_DATE,
  FILTER_KEY.COMMENTS_CLOSE_DATE,
  FILTER_KEY.COMMENTS_CLOSE_START_DATE,
  FILTER_KEY.COMMENTS_CLOSE_END_DATE
];

export const ENFORCEMENTS_CATEGORY = 'Enforcements';

export const EXACT_MATCH = 'exact_match';
export const CLOSE_MATCH = 'close_match';

export const MATCH_TYPE_MAP = {
  [EXACT_MATCH]: 'Exact match',
  [CLOSE_MATCH]: 'Close match'
};

export const EXACT_MATCH_FILTER_OPTION = {
  value: 'exact_match',
  label: MATCH_TYPE_MAP.exact_match
};
export const CLOSE_MATCH_FILTER_OPTION = {
  value: 'close_match',
  label: MATCH_TYPE_MAP.close_match
};

export const TEXT_MATCH_TYPES = [EXACT_MATCH_FILTER_OPTION, CLOSE_MATCH_FILTER_OPTION];
export const TITLE_MATCH_TYPES = [EXACT_MATCH_FILTER_OPTION, CLOSE_MATCH_FILTER_OPTION];
