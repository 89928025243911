export const COLORS = {
  notifRed: '#ff3838',
  restrictPurple: '#9013fe',
  black: '#000',

  grayBorder: '#ddd',
  grayText: '#ccc',
  lightBackground: '#E8E8E8',

  // palette colors from style guide:
  appBkgd: '#e9f2f4',
  compliance_ai_bkgrd: '#f7fafd',
  white: '#fff',
  darkGrey: '#4a4a4a',
  grey: '#393939',
  mediumGrey: '#9B9B9B',
  randoGrey: '#898989',
  lightGrey: '#d8d8d8',
  brandBlue: '#112e61',
  hoverBlue: '#2C6DDF',
  linkedinBlue: '#0077b5',
  brandGrey: '#E5E5E5',
  rightPanelBlue: '#49628e',
  primaryActionBlue: '#7f91b6',
  fadedYellow: '#ffecb3',
  activeGrey: '#F4F4F4',
  borderLineGrey: '#e7e9ea',
  bottomBorderGrey: '#D3D1D1',
  josueGrey: '#dbdde8',
  josueDarkerGrey: '#cfcfcf',
  josueBlue: '#2254d7',
  highlightYellow: 'rgba(248, 231, 28, .75)',
  highlightGreen: 'rgba(107, 255, 90, 0.25)',
  iconPurple: '#8b1c41',
  iconGreen: '#278b1c',
  betaOrange: '#ff7617',
  successGreen: '#2ea87f',
  feedbackGreen: '#19a400',
  statusGreen: '#D0E7E0',
  feedbackOrange: '#FFA929',
  dangerRed: '#E16768',
  logoBlue: '#4D8DCC',
  blackGrey: '#272727',
  barGrey: '#f0f0f0',
  backgroundGrey: '#f2f2f2',
  monthBlue: '#c7e5ff',
  warningPurple: '#8f00ee',
  pinkHighlight: '#F4D0D4',
  limeHighlight: '#DBF3C1',
  lime: '#AFE47A',
  pink: '#FA3F3D',

  // FRB colors from style guide:
  frbDarkGreen: '#00340f',
  frbBackgroundGreen: '#dcdfde',
  frbYellow: '#EAD484',

  // insights color palette from style guide:
  insightsPurple: '#7c13a4',
  insightsLightBlue: '#0086d4',
  insightsTeal: '#00796b',
  insightsGreen: '#348f37',
  insightsLime: '#afb513',
  insightsYellow: '#fcc102',
  insightsOrange: '#f77c00',

  insightsHeatmapHigh: '#f19d83',
  insightsHeatmapMedium: '#f8d871',
  insightsHeatmapLow: '#d2d57d',

  // other frb styles
  frbLightGreen: '#005518',
  frbYellowActiveText: '#EAD484',
  frbBackgroundGrey: '#DCDFDE',

  frbDarkGreenFiltersText: '#092E23',
  frbItemInsideFilterBg: '#F8F8F8',

  // misc colors
  rightPanelInactive: '#525252',
  docket: '#7cc0fb',
  docketTimelineLine: '#5d9bb8',

  brightRed: '#ff0000',

  chartColor: '#5591C0'
};
