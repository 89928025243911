import { useHistory } from './useHistory';
import { useLocation } from 'react-router-dom';

/**
 *
 * Note: use this HOC only for legacy class components
 *
 */
export const withHistory = Component => {
  return function WrappedComponent(props) {
    const history = useHistory();
    const location = useLocation();

    return <Component {...props} history={history} location={location} />;
  };
};
