import {
  REQUEST_DOCUMENTS,
  REQUEST_DOCUMENTS_AFTER,
  REQUEST_DOCUMENTS_BEFORE,
  RECEIVE_SEARCH_RESULTS_RELEVANCE,
  RECEIVE_DOCUMENTS,
  RECEIVE_RESOURCE_CODE
} from '../documents/documents.actions';
import {
  CHANGE_DOCUMENT_VIEW,
  SET_FIRST_TIMELINE_VIEW,
  CHANGE_SEARCH_PARAMS,
  CHANGE_SELECTED_ITEM,
  CHANGE_BULK_SELECTED_ITEM,
  SET_DOCS_TO_SELECT,
  CLEAR_SELECTED_ITEMS,
  CLEAR_BULK_SELECTED_ITEMS,
  INITIATE_PENDING_ACTION,
  COMPLETE_PENDING_ACTION,
  CHANGE_EXPAND_STATUS,
  SAVE_UNBOOKMARKED_DOCUMENT,
  UPDATE_FOLDER_MODAL_STATUS,
  OPEN_SOURCE_SELECTION,
  CLOSE_SOURCE_SELECTION,
  OPEN_WARNING_MODAL,
  CLOSE_WARNING_MODAL,
  CLEAR_DOC_REF,
  DISPLAY_FILTERS,
  HIDE_FILTERS,
  TOGGLE_FILTERS,
  SET_MOBILE,
  HIGHLIGHT_SEARCH,
  ADD_BANNER,
  SHOW_RESTRICT_MODAL,
  HIDE_RESTRICT_MODAL,
  SET_SEARCH_TERM,
  SET_TASK_VIEW,
  SET_ACTIVE_TASK,
  ADD_NAVIGATE_ON_CLOSE_DOCUMENT,
  REMOVE_NAVIGATE_ON_CLOSE_DOCUMENT,
  ADD_EDIT_ALERT,
  REMOVE_EDIT_ALERT,
  ADD_VIEW_ALERT,
  REMOVE_VIEW_ALERT,
  SHOW_PREVIEW_ALERT,
  HIDE_PREVIEW_ALERT,
  OPEN_FILTER,
  CLOSE_FILTER,
  TOGGLE_FILTER,
  OPEN_ADVANCED_SEARCH_FILTER,
  CLOSE_ADVANCED_SEARCH_FILTER,
  TOGGLE_ADVANCED_SEARCH_FILTER,
  SHOW_SAVE_ALERT_SUCCESS_MODAL,
  HIDE_SAVE_ALERT_SUCCESS_MODAL,
  SET_SELECTED_ITEMS,
  SET_SIMPLE_FETCH_DOCUMENTS_STATUS
} from './view.actions';

export const getInitialState = () => ({
  page: '',
  id: null,
  first_timeline_view: true,
  open_search: false,
  expand_list: null,
  pending_action: {},
  folder_modal_status: false,
  source_modal_status: false,
  warning_modal: '',
  expanding_list: false,
  banner: {
    type: '',
    display: false,
    content: {},
    suppressCloseButton: false,
    err_type: ''
  },
  search_params: {
    agency: '',
    jurisdiction: '',
    cai_category: '',
    read: null,
    read_folder_view: null,
    saved_searches_view: null,
    no_skipping: null,
    bookmarked: null,
    //Date-picker react library requires the default state of these inputs to be an empty object
    effective_from: {},
    effective_to: {},
    comments_close_from: {},
    comments_close_to: {},
    publication_date: {},
    published_from: {},
    published_to: {},
    //Date-picker react library requires the default state of these inputs to be an empty object
    docket_id: null,
    more_like_doc_id: null,
    comments_for_id: null,
    search_query: null,
    autosuggest_filter: null,
    search_sort: null,
    limit: 20,
    offset: 0,
    folder_id: null,
    folderTimelineView: null,
    state_code_id: null,
    citation_selected_id: null,
    location_crumb_selected: null,
    insights_view: null,
    followed_sources: null,
    recent_activity_view: null,
    respondent: '',
    min_score: null,
    monetary_penalty: null,
    monetary_penalty_min: null,
    monetary_penalty_max: null,
    view: null,
    related_doc_id: null,
    task_owner: null,
    has_sentences: null,
    has_obligations: null,
    has_annotations: null,
    is_premium_content: null
  },
  search_loading: false,
  selected_items: {},
  bulk_docs_selected: false,
  bulk: '',
  docs_to_select: {},
  unBookmarkedDocuments: {},
  displayFilters: false,
  inMobile: false,
  highlightSearch: false,
  skipOnboarding: false,
  search_term: '',
  workflowView: null,
  taskDetails: null,
  activeDocTask: null,
  activeWorkflow: null,
  activeUserId: null,
  navigateOnCloseDocument: null,
  editAlertId: null,
  viewAlertId: null,
  alertName: null,
  showPreviewAlert: false,
  displayFilter: false,
  displayAdvancedSearchFilter: false,
  saveAlertSuccessModal: {
    show: false,
    name: '',
    type: ''
  },
  restrictModal: {
    type: '',
    show: false,
    // TODO:: we will deprecate title and text
    title: '',
    text: ''
  },
  fetching_simple_docs: false
});

export const current_view = (state = getInitialState(), action) => {
  switch (action.type) {
    case CHANGE_DOCUMENT_VIEW: {
      return {
        ...state,
        page: action.page,
        id: action.id,
        last_id: state.id,
        last_doc_ref: null,
        first_doc_ref: null
      };
    }
    case CHANGE_SEARCH_PARAMS: {
      const old_search_params = state.search_params;
      const new_search_params = {
        ...old_search_params,
        ...action.params
      };

      return {
        ...state,
        search_params: new_search_params,
        last_doc_ref: null,
        first_doc_ref: null,
        search_loading: true
      };
    }
    case CLEAR_DOC_REF: {
      return {
        ...state,
        first_doc_ref: null,
        last_doc_ref: null
      };
    }
    case CHANGE_EXPAND_STATUS: {
      const updated_state = {
        ...state,
        expand_list: action.dir
      };
      if (action.last_doc_ref) {
        updated_state.last_doc_ref = action.last_doc_ref;
        updated_state.first_doc_ref = null;
      } else if (action.first_doc_ref) {
        updated_state.first_doc_ref = action.first_doc_ref;
        updated_state.last_doc_ref = null;
      }
      return updated_state;
    }
    case SET_FIRST_TIMELINE_VIEW: {
      return {
        ...state,
        first_timeline_view: action.value
      };
    }
    case CHANGE_BULK_SELECTED_ITEM: {
      let selected_items = { ...state.selected_items };
      const docs_to_select_items = state.docs_to_select.items;
      if (action.value) {
        docs_to_select_items.forEach(doc => {
          selected_items[doc.id] = doc;
        });
      } else {
        selected_items = {};
      }

      return {
        ...state,
        bulk_docs_selected: action.value,
        bulk: action.bulk,
        selected_items
      };
    }
    case SET_DOCS_TO_SELECT: {
      return {
        ...state,
        docs_to_select: action.docs_to_select
      };
    }
    case SET_SELECTED_ITEMS: {
      return {
        ...state,
        selected_items: action.docs.reduce(
          (previous, current, index) => ({
            ...previous,
            ...{ [current.id]: current }
          }),
          {}
        )
      };
    }
    case SET_SIMPLE_FETCH_DOCUMENTS_STATUS: {
      return {
        ...state,
        fetching_simple_docs: action.status
      };
    }
    case CHANGE_SELECTED_ITEM: {
      const selected_items = { ...state.selected_items };
      if (action.value) {
        selected_items[action.doc.id] = action.doc;
      } else {
        delete selected_items[action.doc.id];
      }
      return {
        ...state,
        selected_items
      };
    }
    case CLEAR_BULK_SELECTED_ITEMS: {
      return {
        ...state,
        bulk_docs_selected: false,
        bulk: '',
        selected_items: {}
      };
    }
    case CLEAR_SELECTED_ITEMS: {
      return {
        ...state,
        bulk_docs_selected: false,
        bulk: '',
        selected_items: {}
      };
    }
    case INITIATE_PENDING_ACTION: {
      return {
        ...state,
        pending_action: {
          ...action.data
        }
      };
    }
    case COMPLETE_PENDING_ACTION: {
      return {
        ...state,
        pending_action: {}
      };
    }
    case SAVE_UNBOOKMARKED_DOCUMENT: {
      const unBookmarkedDocuments = { ...state.unBookmarkedDocuments };
      unBookmarkedDocuments[action.id] = true;
      return {
        ...state,
        unBookmarkedDocuments
      };
    }
    case OPEN_SOURCE_SELECTION: {
      return {
        ...state,
        source_modal_status: true
      };
    }
    case CLOSE_SOURCE_SELECTION: {
      return {
        ...state,
        source_modal_status: false
      };
    }
    case OPEN_WARNING_MODAL: {
      return {
        ...state,
        warning_modal: action.modal
      };
    }
    case CLOSE_WARNING_MODAL: {
      return {
        ...state,
        warning_modal: ''
      };
    }
    case RECEIVE_SEARCH_RESULTS_RELEVANCE:
    case RECEIVE_DOCUMENTS:
    case RECEIVE_RESOURCE_CODE: {
      return {
        ...state,
        search_loading: false
      };
    }
    case UPDATE_FOLDER_MODAL_STATUS: {
      return {
        ...state,
        folder_modal_status: action.status
      };
    }
    case REQUEST_DOCUMENTS: {
      return {
        ...state,
        expanding_list: false
      };
    }
    case REQUEST_DOCUMENTS_AFTER:
    case REQUEST_DOCUMENTS_BEFORE: {
      return {
        ...state,
        expanding_list: true
      };
    }
    case DISPLAY_FILTERS: {
      return {
        ...state,
        displayFilters: true
      };
    }
    case HIDE_FILTERS: {
      return {
        ...state,
        displayFilters: false
      };
    }
    case TOGGLE_FILTERS: {
      return {
        ...state,
        displayFilters: !state.displayFilters
      };
    }
    case SET_MOBILE: {
      return {
        ...state,
        inMobile: action.bool
      };
    }
    case HIGHLIGHT_SEARCH: {
      return {
        ...state,
        highlightSearch: !state.highlightSearch
      };
    }
    case ADD_BANNER: {
      let content = action.content;

      if (typeof action.content === 'string') {
        content = (
          <div className="banner-alert-container">
            <h4 className="banner-text">{action.content}</h4>
          </div>
        );
      }
      return {
        ...state,
        banner: {
          type: action.banner_type,
          display: action.banner_status,
          content,
          suppressCloseButton: action.suppressCloseButton
        }
      };
    }
    case SHOW_RESTRICT_MODAL: {
      return {
        ...state,
        restrictModal: {
          show: true,
          type: action.modal_type,
          title: action.title,
          text: action.text
        }
      };
    }
    case HIDE_RESTRICT_MODAL: {
      return {
        ...state,
        restrictModal: {
          show: false,
          type: '',
          title: '',
          text: ''
        }
      };
    }
    case SET_SEARCH_TERM: {
      return {
        ...state,
        search_term: action.search_term
      };
    }

    case SET_TASK_VIEW: {
      return {
        ...state,
        workflowView: action.workflowView,
        taskDetails: action.taskDetails
      };
    }

    case SET_ACTIVE_TASK: {
      return {
        ...state,
        activeWorkflow: action.workflowId,
        activeDocTask: action.docTaskId,
        activeUserId: action.userId
      };
    }

    case ADD_NAVIGATE_ON_CLOSE_DOCUMENT: {
      return {
        ...state,
        navigateOnCloseDocument: action.routerParams
      };
    }

    case REMOVE_NAVIGATE_ON_CLOSE_DOCUMENT: {
      return {
        ...state,
        navigateOnCloseDocument: null
      };
    }

    case ADD_EDIT_ALERT: {
      return {
        ...state,
        editAlertId: action.alert_id,
        viewAlertId: action.alert_id,
        alertName: action.alert_name
      };
    }

    case REMOVE_EDIT_ALERT: {
      return {
        ...state,
        editAlertId: null
      };
    }

    case ADD_VIEW_ALERT: {
      return {
        ...state,
        viewAlertId: action.alert_id,
        alertName: action.alert_name
      };
    }

    case REMOVE_VIEW_ALERT: {
      return {
        ...state,
        editAlertId: null,
        viewAlertId: null,
        alertName: null,
        showPreviewAlert: false
      };
    }

    case SHOW_PREVIEW_ALERT: {
      return {
        ...state,
        showPreviewAlert: true
      };
    }

    case HIDE_PREVIEW_ALERT: {
      return {
        ...state,
        showPreviewAlert: false
      };
    }

    case OPEN_FILTER: {
      return {
        ...state,
        displayFilter: true
      };
    }

    case CLOSE_FILTER: {
      return {
        ...state,
        displayFilter: false
      };
    }

    case TOGGLE_FILTER: {
      return {
        ...state,
        displayFilter: !state.displayFilter
      };
    }

    case OPEN_ADVANCED_SEARCH_FILTER: {
      return {
        ...state,
        displayAdvancedSearchFilter: true
      };
    }

    case CLOSE_ADVANCED_SEARCH_FILTER: {
      return {
        ...state,
        displayAdvancedSearchFilter: false
      };
    }

    case TOGGLE_ADVANCED_SEARCH_FILTER: {
      return {
        ...state,
        displayAdvancedSearchFilter: !state.displayAdvancedSearchFilter
      };
    }

    case SHOW_SAVE_ALERT_SUCCESS_MODAL: {
      return {
        ...state,
        saveAlertSuccessModal: {
          show: true,
          type: action.modal_type,
          name: action.alert_name
        }
      };
    }

    case HIDE_SAVE_ALERT_SUCCESS_MODAL: {
      return {
        ...state,
        saveAlertSuccessModal: {
          show: false,
          type: '',
          name: ''
        }
      };
    }

    default: {
      return state;
    }
  }
};
