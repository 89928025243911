import React from 'react';
import classnames from 'classnames';
import lodash from 'lodash';
import queryString from 'utils/query-string';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';

import { connect } from 'react-redux';
import {
  SummaryContent,
  Links,
  Metadata
} from '../../../../../../../../../DocumentSummaryElements';
import { FieldName } from '../../../../../../../../../DocumentSummaryElements/Metadata/elements';
import { fetchPremiumContentSources } from 'shared/features/premiumContent/premiumContent.actions';
import { defaultPubLaws, defaultUSCode, defaultCFR } from 'utils/defaultSources';
import { documentIsPremiumContent, userCanAccessDocument } from 'utils/documents/documentsAccess';
import { PremiumContentUnlockButton } from 'common/index';
import { Button, BUTTON_TYPES, Alert, ALERT_TYPES } from '@compliance.ai/web-components';
import { content } from 'constants/Routes';
import { DOCUMENT_SUMMARY_PAGE_KEY, DOCUMENT_CATEGORIES } from 'constants/DocumentConstants';
import {
  getCategoryNameByID,
  getDocumentsCommentsCount,
  getRelatedDocumentCount,
  getUSStateReducer
} from 'shared/features/documents/documents.selectors';
import { getCurrentView } from 'shared/features/view/view.selectors';
import { getDefaultStateAgencies } from 'shared/features/defaultStateAgencies/defaultStateAgencies.selectors';
import { changeDocumentView } from 'shared/features/view/view.actions';
import {
  DOCUMENT_DETAILS_TAB_KEY,
  RIGHT_PANEL_TYPES
} from 'shared/features/rightPanel/rightPanel.constants';

import * as images from 'shared/images';
import * as viewConstants from 'shared/features/view/view.constants';

import { withHistory } from 'utils/hooks';
import { withRightPanelReduxActions } from 'shared/features/rightPanel/hooks';

import './_document-summary.scss';

export class DocumentSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resources_head_id: null
    };
  }

  componentDidMount() {
    if (this.props.premiumContent.pcSources && !this.props.premiumContent.pcSources.length) {
      this.props.fetchPremiumContentSources();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const new_doc_selected = this.props.document.id !== nextProps.document.id;
    const admin_code_categories = new Set(['State Code Navigation', 'State Code']);
    const statute_categories = new Set([
      'Statute Navigation',
      'Statute',
      'Statutes Navigation',
      'Statutes'
    ]);
    const publaw_categories = new Set(['US Public Law Navigation', 'US Public Law']);
    const uscode_categories = new Set(['US Code Navigation', 'US Code']);
    const cfr_categories = new Set(['CFR Navigation', 'CFR']);
    const nextDocumentCategory = getCategoryNameByID(
      this.props.categories,
      nextProps.document.cai_category_id
    );
    const is_admin_code = admin_code_categories.has(nextDocumentCategory);
    const is_statute = statute_categories.has(nextDocumentCategory);
    const is_publaw = publaw_categories.has(nextDocumentCategory);
    const is_uscode = uscode_categories.has(nextDocumentCategory);
    const is_cfr = cfr_categories.has(nextDocumentCategory);

    if ((is_admin_code || is_statute || is_publaw || is_uscode || is_cfr) && new_doc_selected) {
      let resources_head_id;
      if (is_admin_code || is_statute) {
        for (const state of this.props.defaultStateAgencies) {
          if (state.short_name === nextProps.document.agencies[0].jurisdiction) {
            resources_head_id = is_statute ? state.statute_id : state.admin_code_id;
          }
        }
      } else if (is_publaw) {
        for (const congress of defaultPubLaws) {
          if (
            nextProps.document.official_id.includes(congress.official_id) ||
            nextProps.document.official_id.includes(congress.short_name)
          ) {
            resources_head_id = congress.publaw_id;
          }
        }
      } else if (is_uscode) {
        resources_head_id = defaultUSCode.id;
      } else if (is_cfr) {
        resources_head_id = defaultCFR.id;
      }

      this.setState({ resources_head_id });

      if (!lodash.isNil(nextProps.document.id)) {
        if (!this.props.us_state.codes[nextProps.document.id]) {
          this.props.fetchResourceCode(nextProps.document.id).then(() => {
            this.props.buildResourceCodeTree(nextProps.document.id);
          });
        } else {
          this.props.buildResourceCodeTree(nextProps.document.id);
        }
      }
    }
  }

  viewRelated = e => {
    e.preventDefault();

    const {
      document,
      location: { search },
      closeOverlay
    } = this.props;

    const parsedQuery = queryString.parse(search);
    let { search_sort, order, view } = parsedQuery;
    if (!search_sort) {
      search_sort = 'publication_date';
      order = 'desc';
    }
    if (!view) {
      view = viewConstants.VIEW_KEY.ALL;
    }

    closeOverlay();

    this.props.history.push(
      `${content}?${queryString.stringify({
        more_like_doc_id: document.id,
        summary_id: document.id,
        summary_page: DOCUMENT_SUMMARY_PAGE_KEY,
        search_sort,
        order,
        view
      })}`
    );
  };

  viewProposedRules = e => {
    e.preventDefault();

    this.props.openRightPanel({
      type: RIGHT_PANEL_TYPES.DOCUMENT_DETAILS,
      props: {
        documentId: this.props.document.comment.commented_doc_id,
        shouldOpenRightPanelWithOverlay: true,
        activeTabKey: DOCUMENT_DETAILS_TAB_KEY.DETAILS
      }
    });
  };

  viewPremiumContent = () => {
    this.props.triggerOverlayRefresh();
  };

  viewComments = e => {
    e.preventDefault();
    if (this.props.documentCommentsCount) {
      this.props.history.push(
        `${content}?${queryString.stringify({
          comments_for_id: this.props.document.id,
          search_sort: 'publication_date',
          summary_id: this.props.document.id,
          summary_page: DOCUMENT_SUMMARY_PAGE_KEY
        })}`
      );
    }
  };

  renderAuthorNames(document, documentCategory) {
    let text = null;
    if (document.agencies) {
      text = document.agencies
        .map(a => a.short_name || a.name)
        .sort()
        .join(', ');
    }

    if (lodash.get(document, 'mainstream_news.news_source.name')) {
      text = document.mainstream_news.news_source.name;
    }

    if (documentCategory === DOCUMENT_CATEGORIES.WHITEPAPER) {
      text = document.whitepaper?.metadata?.authors;
    }

    return text;
  }

  render() {
    if (!this.props.current_user.user) {
      return <img src={images.loaders.loadingSpinnerLight64} alt="loading" />;
    }
    const { document, path, categories } = this.props;
    const documentCategory = getCategoryNameByID(categories, document.cai_category_id);
    const isPremiumContent = documentIsPremiumContent({ document });
    const pcSource =
      isPremiumContent &&
      this.props.premiumContent.pcSources &&
      this.props.premiumContent.pcSources.find(source => {
        return source.news_source_id === document.mainstream_news.news_source.id;
      });
    const pcSourceId = pcSource && pcSource.id;
    const pcSourceAuthType = pcSource && pcSource.auth_schema.type;
    const pcSourceName = this.renderAuthorNames(document, documentCategory);
    const pcSourceURL = pcSource && pcSource.url_home;
    const canAccessPCDocument = userCanAccessDocument({ document });

    if (!document || Object.keys(document).length === 0) {
      return null;
    }

    const related_document_count =
      !lodash.isNil(this.props.relatedDocumentCount) && ` (${this.props.relatedDocumentCount})`;

    const document_comments_count =
      !lodash.isNil(this.props.documentCommentsCount) && ` (${this.props.documentCommentsCount})`;

    const show_comments_classes = {
      'related-link': true,
      disabled: !this.props.documentCommentsCount
    };

    const navigation_categories = new Set([
      'State Code Navigation',
      'Statute Navigation',
      'Statutes Navigation',
      'US Public Law Navigation'
    ]);

    return (
      <div className="rule-content" data-testid="document-summary-container">
        {document.deprecated && (
          <Alert
            type={ALERT_TYPES.WARNING}
            title={'Deleted Document'}
            content="This document has been deleted from the Compliance.ai platform. Documents may be deleted from the platform if they are problematic and are no longer necessary. For example, a document may be deleted if it is a duplicate, has invalid or corrupted text, or has been incorrectly translated."
          />
        )}
        {isPremiumContent && !canAccessPCDocument && (
          <div className="premium-content-button-wrapper">
            <PremiumContentUnlockButton
              sourceId={pcSourceId}
              sourceName={pcSourceName}
              sourceURL={pcSourceURL}
              sourceAuthType={pcSourceAuthType}
              document={document}
              onSuccess={this.viewPremiumContent}
            />
          </div>
        )}
        {document.has_sentences && !this.props.renderForEmail && (
          <div className="diffFeature">
            Compare with any other document with this icon{' '}
            <VerticalSplitIcon className="diffIcon" />
          </div>
        )}
        <FieldName
          className={classnames({
            'document-summary__label': true,
            'document-summary__label--is-title': true
          })}
        >
          Title
        </FieldName>
        <h2>{document.title}</h2>

        <section className="view-btn-container">
          {!navigation_categories.has(documentCategory) && (
            <Links document={document} shouldRenderForEmail={this.props.renderForEmail} />
          )}
        </section>

        {document.summary_text && !navigation_categories.has(documentCategory) && (
          <SummaryContent text={document.summary_text} />
        )}

        <Metadata
          document={document}
          path={path}
          shouldRenderForEmail={this.props.renderForEmail}
          onResourceTreeBuild={this.props.buildResourceCodeTree}
        />
        {document.comment && document.comment.commented_doc_id && (
          <div className="related-button-container">
            <Button type={BUTTON_TYPES.LINK_CONTRAST} onClick={e => this.viewProposedRules(e)}>
              Related Proposed Rule
            </Button>
          </div>
        )}
        {documentCategory === 'Proposed Rule' && (
          <div className="related-button-container">
            <Button
              type={BUTTON_TYPES.LINK_CONTRAST}
              className={classnames(show_comments_classes)}
              onClick={e => this.viewComments(e)}
            >
              Show All Comments
              {document_comments_count}
            </Button>
          </div>
        )}
        <div className="related-button-container">
          <Button type={BUTTON_TYPES.LINK_CONTRAST} onClick={e => this.viewRelated(e)}>
            Similar Documents {related_document_count}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    documents: state.documents,
    current_user: state.current_user,
    current_view: getCurrentView(state),
    premiumContent: state.premiumContent,
    us_state: getUSStateReducer(state),
    relatedDocumentCount: getRelatedDocumentCount(props.document)(state),
    documentCommentsCount: getDocumentsCommentsCount(props.document)(state),
    organization: state.organization,
    categories: state.docTypes.docTypes.cai_categories,
    timezone: state.current_user.user.timezone,
    defaultStateAgencies: getDefaultStateAgencies(state)
  };
};

export default connect(mapStateToProps, {
  fetchPremiumContentSources,
  changeDocumentView
})(withHistory(withRightPanelReduxActions(DocumentSummary)));
