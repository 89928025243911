import type { ObligationToolbarProps } from './ObligationToolbar.types';
import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './ObligationToolbar.constants';

import classnames from 'classnames';

import { useObligationToolbarMutations } from './hooks';

import './_obligation-toolbar.scss';

export const ObligationToolbar: FC<ObligationToolbarProps> = ({
  documentId,
  sentenceNote,
  onRelevancyChange,
  isReadOnly
}) => {
  const mutations = useObligationToolbarMutations({
    props: {
      documentId,
      sentenceNote,
      onRelevancyChange
    }
  });

  return (
    <div className="obligation-toolbar" data-testid={constants.DATA_TEST_IDS.CONTAINER}>
      <div className="obligation-toolbar__relevancy-selection">
        Relevant?
        <div className="obligation-toolbar__relevancy-buttons-container">
          <button
            data-testid={constants.DATA_TEST_IDS.YES_BUTTON}
            disabled={isReadOnly || mutations.isLoading}
            onClick={() =>
              mutations.selectRelevance.mutate(sentenceNote.relevance === true ? null : true)
            }
            className={classnames({
              'obligation-toolbar__relevancy-button': true,
              'obligation-toolbar__relevancy-button--is-active': sentenceNote.relevance === true
            })}
          >
            Y
          </button>
          <button
            data-testid={constants.DATA_TEST_IDS.NO_BUTTON}
            disabled={isReadOnly || mutations.isLoading}
            onClick={() =>
              mutations.selectRelevance.mutate(sentenceNote.relevance === false ? null : false)
            }
            className={classnames({
              'obligation-toolbar__relevancy-button': true,
              'obligation-toolbar__relevancy-button--is-active': sentenceNote.relevance === false
            })}
          >
            N
          </button>
        </div>
        {mutations.selectRelevance.isLoading && (
          <div className="obligation-toolbar__loader-container">
            <uiLib.Loader isContrast size={uiLib.LOADER_SIZES.SMALL} />
          </div>
        )}
      </div>
      {sentenceNote.relevance === false && (
        <div className="obligation-toolbar__hide-button-container">
          {mutations.hideSentenceNote.isLoading && (
            <div className="obligation-toolbar__loader-container">
              <uiLib.Loader isContrast size={uiLib.LOADER_SIZES.SMALL} />
            </div>
          )}
          <button
            data-testid={constants.DATA_TEST_IDS.HIDE_BUTTON}
            className="obligation-toolbar__hide-button"
            disabled={mutations.isLoading}
            onClick={mutations.hideSentenceNote.mutate}
          >
            Hide
          </button>
        </div>
      )}
    </div>
  );
};
