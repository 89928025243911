import type { LabelsSelectProps } from './LabelsSelect.types';
import type { SelectProps } from '@compliance.ai/web-components';
import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';

import { useLabelsSelectData, useLabelsSelectHandlers, useLabelsSelectQueries } from './hooks';

export const LabelsSelect: FC<LabelsSelectProps> = props => {
  const { localState, localActions, formattedData } = useLabelsSelectData(props);

  const handlers = useLabelsSelectHandlers({ props });

  const queries = useLabelsSelectQueries({
    props,
    localActions,
    formattedData
  });

  return (
    <uiLib.GroupSelect
      onChange={props.onChange as SelectProps['onChange']}
      isLoading={props.isLoading || queries.fetchPropsValueQuery.isFetching}
      isDisabled={props.isDisabled}
      shouldFetchDefaultOptions
      shouldCacheFetchedOptions
      isAsync
      isMulti
      {...props}
      loadOptions={handlers.handleLabelsFetch}
      value={localState.value}
    />
  );
};
