import queryString from 'utils/query-string';
import auth from 'utils/auth';
import JurisdictionAnalyzerFilter from './JurisdictionAnalyzerFilter/JurisdictionAnalyzerFilter';
import RestrictBubble from 'components/RestrictBubble';

import * as routes from 'constants/Routes';

import { Component } from 'react';
import { connect } from 'react-redux';
import { PrimaryFilter } from './PrimaryFilter';
import { EnforcementFilter } from './EnforcementFilter';
import { showModal } from 'components/Restrict';
import { safe_analytics } from 'utils/analytics';
import { enforcementExplorer } from 'constants/Routes';
import { changeFilter } from 'shared/features/filters/filters.actions';
import { FILTERS } from 'constants/Filters';
import { refreshOldDefaults } from 'shared/features/defaults/defaults.actions';
import { isLiteTeamUser } from 'utils/authorization';

import { withHistory } from 'utils/hooks';

import './_filter.scss';

class FilterContainer extends Component {
  constructor(props) {
    super(props);

    const { pathname, search } = props.location;
    const parsedQuery = queryString.parse(search);

    const view = Array.isArray(parsedQuery.view) ? parsedQuery.view[0] : parsedQuery.view;

    let currentFilter;

    if (pathname === enforcementExplorer) {
      currentFilter = FILTERS.ENFORCEMENT;
    } else if (pathname === '/resources' && view === 'JA') {
      currentFilter = FILTERS.JA;
    } else {
      currentFilter = FILTERS.PRIMARY;
    }

    props.changeFilter(currentFilter);
  }

  componentDidMount() {
    const { location, refreshOldDefaults } = this.props;
    if ([routes.sources, routes.admin].includes(location.pathname)) {
      refreshOldDefaults();
    }
  }

  handleTabClick = currentFilter => {
    safe_analytics('default', currentFilter, 'Clicked on Tab');
    this.props.changeFilter(currentFilter);
  };

  renderFilterTabs = () => {
    const { filters, current_user } = this.props;
    // Changes to filter modify the current user so we don't want to show purple icon when
    // updating user. This code assumes that by the time the filter has been opened, the user is
    // loaded
    const allowJurisdictionAnalyzer =
      !current_user.isReady || (auth.loggedIn() && !isLiteTeamUser());

    const filterLabels = [
      { label: 'Filter', filter: FILTERS.PRIMARY },
      {
        label: 'Enforcement Explorer',
        filter: FILTERS.ENFORCEMENT
      },
      { label: 'Jurisdiction Analyzer', filter: FILTERS.JA, restrict: !allowJurisdictionAnalyzer }
    ];

    return filterLabels.map(({ label, filter, restrict }) => {
      const tabClass = filter === filters.currentFilter ? 'tab active' : 'tab';

      return (
        <div
          className={tabClass}
          onClick={() => {
            if (!restrict) {
              return this.handleTabClick(filter);
            }

            if (filter === FILTERS.JA) {
              return showModal('Jurisdictions Analyzer');
            }
          }}
          key={label}
        >
          {label}
          {restrict ? <RestrictBubble fixed alertSwitch /> : null}
        </div>
      );
    });
  };

  renderFilters = () => {
    const { filters, search_query, currentView } = this.props;

    switch (filters.currentFilter) {
      case FILTERS.ENFORCEMENT:
        return <EnforcementFilter searchQuery={search_query} />;
      case FILTERS.JA:
        return <JurisdictionAnalyzerFilter searchQuery={search_query} />;
      default:
        return <PrimaryFilter searchQuery={search_query} currentView={currentView} />;
    }
  };

  render() {
    return (
      <div className="filter-container redesigned-filter">
        <div className="filter-tabs">{this.renderFilterTabs()}</div>
        <div className="filters">{this.renderFilters()}</div>
      </div>
    );
  }
}

const mapStateToProps = ({ current_user, current_view, subscriptions, filters }) => {
  return {
    current_user,
    currentView: current_view,
    subscriptions,
    filters
  };
};

export default connect(mapStateToProps, { changeFilter, refreshOldDefaults })(
  withHistory(FilterContainer)
);
