import type { KeyDatesData } from './useKeyDatesData.types';
import type { KeyDatesProps } from '../KeyDates.types';

import * as helpers from './useKeyDatesData.helpers';

import { useMemo } from 'react';

export const useKeyDatesData = ({ document }: Pick<KeyDatesProps, 'document'>): KeyDatesData => {
  const formattedData: KeyDatesData['formattedData'] = useMemo(() => {
    const keyDates = helpers.getKeyDates(document);

    return {
      keyDates
    };
  }, [document]);

  return {
    formattedData
  };
};
