import lodash from 'lodash';
import { RELATED_DOCUMENT_KEYS, RELATED_DOCUMENT_RELATION_TYPES } from 'constants/RelatedDocuments';

export const getRelatedDocumentsByRelationType = (relatedDocuments = []) => {
  return relatedDocuments.reduce(
    (docsObj, docs) => {
      const relationType = docs[RELATED_DOCUMENT_KEYS.RELATION_TYPE].toLowerCase();

      if (relationType in docsObj) {
        if (!lodash.isNumber(docs[RELATED_DOCUMENT_KEYS.TOTAL_COUNT])) {
          docs = {
            ...docs,
            [RELATED_DOCUMENT_KEYS.TOTAL_COUNT]: docs[RELATED_DOCUMENT_KEYS.RELATED_DOC_IDS]?.length
          };
        }

        docsObj[relationType] = docs;
      }

      return docsObj;
    },
    {
      [RELATED_DOCUMENT_RELATION_TYPES.PREMIUM_CONTENT]: null,
      [RELATED_DOCUMENT_RELATION_TYPES.DOCUMENTS]: null,
      [RELATED_DOCUMENT_RELATION_TYPES.LANGUAGE]: null,
      [RELATED_DOCUMENT_RELATION_TYPES.TRANSLATION]: null,
      [RELATED_DOCUMENT_RELATION_TYPES.ORGANIZATION]: null,
      [RELATED_DOCUMENT_RELATION_TYPES.ORGANIZATION_ALERT]: null
    }
  );
};
