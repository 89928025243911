export const SORT_ORDERS = {
  ASC: 'asc',
  DESC: 'desc'
};

export const EXPECTED_ERROR_MESSAGES = {
  INVALID_FILE: 'Invalid format or column is empty'
};

export const MAX_LABELS_COUNT = 1000;
