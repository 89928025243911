import PropTypes from 'prop-types';
import './_widget-message.scss';

const WidgetMessage = ({ children }) => {
  return <div className="widget-message">{children}</div>;
};

export default WidgetMessage;

WidgetMessage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.string)
  ])
};
