import type { NotificationSwitchHandlers } from './useNotificationSwitchHandlers.types';
import type { NotificationSwitchData } from './useNotificationSwitchData.types';
import type { NotificationSwitchProps } from '../NotificationSwitch.types';

import type * as alertsApiTypes from 'shared/features/alerts/alerts.api.types';

import * as errorUtils from 'utils/errors';
import * as analytics from 'utils/analytics';

import { useLocation } from 'react-router-dom';
import { useViewReduxActions } from 'shared/features/view/hooks';
import { useErrorReduxActions } from 'shared/features/error/hooks';
import { useAlertsReduxActions } from 'shared/features/alerts/hooks';
import { useQueryParams } from '@compliance.ai/web-components';

export const useNotificationSwitchHandlers = ({
  props,
  localActions,
  formattedData
}: {
  props: Pick<NotificationSwitchProps, 'alertParams'>;
  localActions: NotificationSwitchData['localActions'];
  formattedData: NotificationSwitchData['formattedData'];
}): NotificationSwitchHandlers => {
  const location = useLocation();
  const queryParams = useQueryParams();

  const alertReduxActions = useAlertsReduxActions();
  const viewReduxActions = useViewReduxActions();
  const errorReduxActions = useErrorReduxActions();

  const handleAlertSave: NotificationSwitchHandlers['handleAlertSave'] = async name => {
    try {
      errorReduxActions.clearErrors('post_alert');

      analytics.safe_mixpanel_track('Alert', {
        eventCategory: 'Alert',
        eventAction: 'Save Alert',
        hitType: 'Event',
        eventLabel: JSON.stringify(queryParams)
      });

      const alertSearchParams = props.alertParams ? props.alertParams : queryParams;
      const { defaultView, ...filteredAlertSearchParams } = alertSearchParams;

      const newAlert = ((await alertReduxActions.addAlert({
        name: name,
        search_args: filteredAlertSearchParams,
        pathname: location.pathname
      })) as unknown) as alertsApiTypes.AlertFromResponse;

      if (newAlert?.id) {
        localActions.setIsSaveAlertModalOpen(false);

        await alertReduxActions.fetchAllAlerts();

        viewReduxActions.addViewAlert(newAlert.id, name);
        viewReduxActions.showSaveAlertSuccessModal('create', name);
      }
    } catch (e) {
      errorUtils.logError(e as Error, 'Error', {
        isSilent: true
      });
    }
  };

  const handleAlertEdit: NotificationSwitchHandlers['handleAlertEdit'] = () => {
    viewReduxActions.addEditAlert(
      formattedData.currentViewAlertId,
      formattedData.currentViewAlertName
    );
    viewReduxActions.openFilter();
    viewReduxActions.openAdvancedSearchFilter();
  };

  const handleAlertCreate: NotificationSwitchHandlers['handleAlertCreate'] = () => {
    localActions.setIsSaveAlertModalOpen(true);
  };

  const handleModalClose: NotificationSwitchHandlers['handleModalClose'] = () => {
    errorReduxActions.clearErrors('post_alert');
    localActions.setIsSaveAlertModalOpen(false);
  };

  return {
    handleAlertSave,
    handleAlertEdit,
    handleAlertCreate,
    handleModalClose
  };
};
