import type {
  AddRelatedDocumentAlertsModalData,
  AddRelatedDocumentAlertsModalLocalState
} from './useAddRelatedDocumentAlertsModalData.types';
import type { GlobalState } from 'shared/reducers';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAlertsFetchingState } from 'shared/features/alerts/alerts.selectors';
import { getDocumentDetailsFetchingState } from 'shared/features/documents/documents.selectors';

export const useAddRelatedDocumentAlertsModalData = (): AddRelatedDocumentAlertsModalData => {
  const [alertIds, setAlertIds] = useState<AddRelatedDocumentAlertsModalLocalState['alertIds']>([]);

  const localState: AddRelatedDocumentAlertsModalData['localState'] = {
    alertIds
  };

  const localActions: AddRelatedDocumentAlertsModalData['localActions'] = useMemo(
    () => ({
      setAlertIds
    }),
    []
  );

  const reduxState = useSelector<GlobalState, AddRelatedDocumentAlertsModalData['reduxState']>(
    state => ({
      isFetchingAlerts: getAlertsFetchingState(state),
      isAttachingAlerts: getDocumentDetailsFetchingState(state)
    })
  );

  return {
    localState,
    localActions,
    reduxState
  };
};
