import * as uiLib from '@compliance.ai/web-components';

import { FILTER_KEY, FILTERS_INITIAL_STATE } from '../LabelDetailsFilters.constants';
import { normalizeAddedTo } from './useLabelDetailsFiltersHandlers.helpers';

export const useLabelDetailsFiltersHandlers = ({ props, localState, localActions }) => {
  const handleDropdownOpen = () => {
    localActions.setDropdownOpenState(true);
  };

  const handleDropdownClose = () => {
    localActions.setDropdownOpenState(false);
  };

  const handleFilterChange = filterKey => filterValue => {
    localActions.setFilters({
      ...localState.filters,
      [filterKey]: filterValue
    });
  };

  const handleFiltersClear = () => {
    localActions.setFilters(FILTERS_INITIAL_STATE);
  };

  const handleFiltersSave = () => {
    localActions.setDropdownOpenState(false);

    props.onSave({
      [FILTER_KEY.DOC_TITLE]: localState.filters[FILTER_KEY.DOC_TITLE],
      [FILTER_KEY.ADDED_TO]: normalizeAddedTo(localState.filters[FILTER_KEY.ADDED_TO]),
      [FILTER_KEY.ADDED_BY]: localState.filters[FILTER_KEY.ADDED_BY]?.value,
      [FILTER_KEY.ADDED_START_DATE]: localState.filters[FILTER_KEY.ADDED_START_DATE]
        ? uiLib.formatDate(localState.filters[FILTER_KEY.ADDED_START_DATE])
        : null,
      [FILTER_KEY.ADDED_END_DATE]: localState.filters[FILTER_KEY.ADDED_END_DATE]
        ? uiLib.formatDate(localState.filters[FILTER_KEY.ADDED_END_DATE])
        : null
    });
  };

  return {
    handleDropdownOpen,
    handleDropdownClose,
    handleFilterChange,
    handleFiltersClear,
    handleFiltersSave
  };
};
