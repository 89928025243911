import type { LocationProps } from './Location.types';

import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';
import { LocationBreadcrumbs } from './elements';
import { useLocationHandlers } from './hooks';

export const Location = ({ document, shouldRenderForEmail }: LocationProps) => {
  const handlers = useLocationHandlers();

  if (shouldRenderForEmail || !document.document_location?.length) {
    return null;
  }

  return (
    <>
      <FieldName>Location</FieldName>
      <FieldValue>
        <LocationBreadcrumbs
          documentLocation={document.document_location}
          onBreadCrumbClick={handlers.handleLocationClick}
        />
      </FieldValue>
    </>
  );
};
