import type { RulesViolatedProps } from './RulesViolated.types';
import type { FC } from 'react';

import { RestrictedFieldName, RESTRICTION_TYPES } from '../RestrictedFieldName';
import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';

import { useRulesViolatedData } from './hooks';

export const RulesViolated: FC<RulesViolatedProps> = props => {
  const { formattedData } = useRulesViolatedData({ props });

  if (formattedData.isRestricted) {
    return (
      <>
        <FieldName>
          <RestrictedFieldName restrictionType={RESTRICTION_TYPES.ENFORCEMENTS}>
            Rules Violated
          </RestrictedFieldName>
        </FieldName>
        <FieldValue>Restricted</FieldValue>
      </>
    );
  }

  if (formattedData.violatedRules) {
    return (
      <>
        <FieldName>Rules Violated</FieldName>
        <FieldValue isExpandable>{formattedData.violatedRules}</FieldValue>
      </>
    );
  }

  return null;
};
