import { useSelector } from 'react-redux';
import {
  getDocumentDetailsDocuments,
  getSavedFoldersCounts,
  getDocumentsReducer
} from 'shared/features/documents/documents.selectors';
import { getRightPanelDocumentId } from 'shared/features/rightPanel/rightPanel.selectors';
import { getDocumentRestriction, userCanAccessDocument } from 'utils/documents/documentsAccess';
import { RESTRICTED_ACTIONS } from 'constants/Restrictions';

export const useDocumentActionsData = () => {
  const reduxState = useSelector(state => ({
    documentDetailsDocuments: getDocumentDetailsDocuments(state),
    documentSavedFoldersCounts: getSavedFoldersCounts(state),
    documents: getDocumentsReducer(state),
    rightPanelDocumentId: getRightPanelDocumentId(state)
  }));

  const document = reduxState.documentDetailsDocuments[reduxState.rightPanelDocumentId];
  const documentSavedFoldersCount =
    reduxState.documentSavedFoldersCounts[reduxState.rightPanelDocumentId];
  const userHasAccess = userCanAccessDocument({ document });
  const canPrint = getDocumentRestriction({ document, action: RESTRICTED_ACTIONS.PRINT });
  const canExport = getDocumentRestriction({ document, action: RESTRICTED_ACTIONS.EXPORT });

  return {
    reduxState,
    formattedData: {
      document,
      documentSavedFoldersCount,
      userHasAccess,
      canPrint,
      canExport
    }
  };
};
