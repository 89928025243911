import type { GlobalState } from 'shared/reducers';
import type { PlainTextProps } from '../PlainText.types';
import type { PlainTextData } from './usePlainTextData.types';

import { DATA_CONFIG } from '../PlainText.constants';
import { INITIAL_STATE } from './usePlainTextData.constants';

import { getParsedJsonConfig } from './usePlainTextData.helpers';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePreviousValue } from '@compliance.ai/web-components';

export const usePlainTextData = ({
  tree,
  config
}: Pick<PlainTextProps, 'tree' | 'config'>): PlainTextData => {
  const [asyncIdsToNamesMaps, setAsyncIdsToNamesMaps] = useState<
    PlainTextData['localState']['asyncIdsToNamesMaps']
  >(INITIAL_STATE.asyncIdsToNamesMaps);
  const [plainText, setPlainText] = useState<PlainTextData['localState']['plainText']>(
    INITIAL_STATE.plainText
  );
  const [isPlainTextSet, setIsPlainTextSet] = useState<
    PlainTextData['localState']['isPlainTextSet']
  >(INITIAL_STATE.isPlainTextSet);

  const localState: PlainTextData['localState'] = {
    asyncIdsToNamesMaps,
    plainText,
    isPlainTextSet
  };

  const localActions: PlainTextData['localActions'] = useMemo(
    () => ({
      setAsyncIdsToNamesMaps,
      setPlainText,
      setIsPlainTextSet
    }),
    []
  );

  const reduxState = useSelector<GlobalState, PlainTextData['reduxState']>(state => {
    return {
      syncIdsToNamesMaps: Object.entries(DATA_CONFIG).reduce(
        (reduxState, [advancedSearchKey, config]) => {
          return {
            ...reduxState,
            [advancedSearchKey]: config.onSelectFromRedux(state as never)
          };
        },
        {} as PlainTextData['reduxState']['syncIdsToNamesMaps']
      )
    };
  });

  const formattedData: PlainTextData['formattedData'] = useMemo(() => {
    const parsedQuery = getParsedJsonConfig({
      tree,
      config
    });

    const idsToNamesMaps = {
      ...localState.asyncIdsToNamesMaps,
      ...reduxState.syncIdsToNamesMaps
    };

    return {
      parsedQuery,
      idsToNamesMaps
    };
  }, [config, localState.asyncIdsToNamesMaps, reduxState.syncIdsToNamesMaps, tree]);

  const prevFormattedData = usePreviousValue(formattedData);

  return {
    localState,
    localActions,
    reduxState,
    formattedData,
    prevFormattedData
  };
};
