import type { DropdownProps } from '../Dropdown.types';
import type { DropdownData } from './useDropdownData.types';

import * as workflowHelpers from 'shared/features/workflow/workflow.helpers';
import * as workflowConstants from 'shared/features/workflow/workflow.constants';
import * as helpers from './useDropdownData.helpers';

import { useMemo } from 'react';

export const useDropdownData = ({ dropdown }: Pick<DropdownProps, 'dropdown'>): DropdownData => {
  const formattedData: DropdownData['formattedData'] = useMemo(() => {
    const label = workflowHelpers.formatAnnotationLabel({
      label:
        dropdown.label ||
        workflowConstants.DEFAULT_LABELS[workflowConstants.WORKFLOW_ANNOTATION_TYPES.DROPDOWNS],
      isRequired: dropdown.required
    });

    const options = helpers.formatOptions({
      currentLevelDropdownOptions: dropdown.dropdown_options,
      allDropdownOptions: dropdown.dropdown_options
    });

    const selectedOptionsString = helpers.formatSelectedOptionsString(dropdown.dropdown_options);

    const multiSelectValue = helpers.formatMultiSelectValue(dropdown.dropdown_options);

    const singleSelectValue = helpers.formatSingleSelectValue(dropdown.dropdown_options);

    return {
      label,
      selectedOptionsString,
      options,
      multiSelectValue,
      singleSelectValue
    };
  }, [dropdown.dropdown_options, dropdown.label, dropdown.required]);

  return {
    formattedData
  };
};
