import type {
  DocumentTaskAnnotationsData,
  DocumentTaskFromResponse,
  WorkflowTaskDropdownFromResponse
} from 'shared/features/workflow/workflow.types';

import lodash from 'lodash';
import { useSelector } from 'react-redux';

import { getUserId } from 'shared/features/user/user.selectors';

import {
  getApprovalAnnotationLabel,
  getAssigneesAnnotationLabel,
  getAttachmentsAnnotationLabel,
  getDatefieldAnnotationLabel,
  getTextAnnotationLabel,
  isAvailableAnnotationField,
  isRequiredAnnotationField
} from './useDocumentTaskAnnotations.helpers';

import {
  APPROVAL_ANNOTATION_STATUSES,
  WORKFLOW_ANNOTATION_TYPES
} from 'shared/features/workflow/workflow.constants';
import { normalizeApprovalStatus } from '../../workflow.helpers';

export const useDocumentTaskAnnotations = (
  documentTask: DocumentTaskFromResponse
): DocumentTaskAnnotationsData => {
  const currentUserId = useSelector(getUserId);

  const isAssignee = currentUserId === documentTask.assignee;

  return {
    [WORKFLOW_ANNOTATION_TYPES.ASSIGNEES]: {
      label: getAssigneesAnnotationLabel(),
      isAssignee,
      isAvailable: isAvailableAnnotationField({
        documentTask: documentTask,
        type: WORKFLOW_ANNOTATION_TYPES.ASSIGNEES
      }),
      isRequired: true // Assignees annotation should always be required
    },
    [WORKFLOW_ANNOTATION_TYPES.TEXT]: {
      label: getTextAnnotationLabel({
        documentTask: documentTask
      }),
      isAssignee,
      isAvailable: isAvailableAnnotationField({
        documentTask: documentTask,
        type: WORKFLOW_ANNOTATION_TYPES.TEXT
      }),
      isRequired: isRequiredAnnotationField({
        documentTask: documentTask,
        type: WORKFLOW_ANNOTATION_TYPES.TEXT
      })
    },
    [WORKFLOW_ANNOTATION_TYPES.ATTACHMENT]: {
      label: getAttachmentsAnnotationLabel({
        documentTask: documentTask
      }),
      isAssignee,
      isAvailable: isAvailableAnnotationField({
        documentTask: documentTask,
        type: WORKFLOW_ANNOTATION_TYPES.ATTACHMENT
      }),
      isRequired: isRequiredAnnotationField({
        documentTask: documentTask,
        type: WORKFLOW_ANNOTATION_TYPES.ATTACHMENT
      })
    },
    [WORKFLOW_ANNOTATION_TYPES.DROPDOWNS]: {
      isAssignee,
      isAvailable: true,
      dropdowns: lodash
        .get(documentTask, WORKFLOW_ANNOTATION_TYPES.DROPDOWNS, [])
        .sort((a: WorkflowTaskDropdownFromResponse, b: WorkflowTaskDropdownFromResponse) =>
          (a.order_id ?? a.id) > (b.order_id ?? b.id) ? 1 : -1
        )
    },
    [WORKFLOW_ANNOTATION_TYPES.DATEFIELD]: {
      label: getDatefieldAnnotationLabel({
        documentTask: documentTask
      }),
      isAvailable: isAvailableAnnotationField({
        documentTask: documentTask,
        type: WORKFLOW_ANNOTATION_TYPES.DATEFIELD
      }),
      isAssignee,
      isRequired: isRequiredAnnotationField({
        documentTask: documentTask,
        type: WORKFLOW_ANNOTATION_TYPES.DATEFIELD
      })
    },
    [WORKFLOW_ANNOTATION_TYPES.APPROVAL]: {
      label: getApprovalAnnotationLabel(),
      isAvailable: isAvailableAnnotationField({
        documentTask: documentTask,
        type: WORKFLOW_ANNOTATION_TYPES.APPROVAL
      }),
      isAssignee,
      isRequired: true, // Approval annotation should always be required,
      approversIds: documentTask.approvers,
      primaryApprover: documentTask.primary_approver,
      isPotentialApprover: documentTask.approvers.includes(currentUserId),
      status: normalizeApprovalStatus(documentTask.approval_status) as APPROVAL_ANNOTATION_STATUSES
    }
  };
};
