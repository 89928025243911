export const useAuthCheckHandlers = ({ localActions }) => {
  const handleSessionRefreshSuccess = () => {
    localActions.setLoadingState(false);
  };

  const handleSessionRefreshError = e => {
    localActions.setLoadingState(false);
  };

  return {
    handleSessionRefreshSuccess,
    handleSessionRefreshError
  };
};
