export const JA_VIEW = 'JA';
import { DOCUMENT_CATEGORIES, DOCUMENT_META_CATEGORIES } from 'constants/DocumentConstants';

export const FILTER_KEY = {
  SEARCH_QUERY: 'search_query',
  JURISDICTION: 'jurisdiction',
  TOPIC_ID: 'topic_id',
  CATEGORY: 'cai_category',
  REGULATION_ID: 'regulation_id',
  ACT_ID: 'act_id',
  ACTS: 'acts',
  LANGUAGE_ID: 'language_id',
  PUBLISHED_FROM: 'published_from',
  PUBLISHED_TO: 'published_to',
  HAS_UNOFFICIAL_PUBLICATION_DATE: 'has_unofficial_publication_date',
  ORDER: 'order',
  SEARCH_SORT: 'search_sort',
  SUMMARY_PAGE: 'summary_page',
  SKIP_JA_FIELDS: 'skip_jurisdiction_analyser_fields',
  CONCEPT_ID: 'concept_id'
};

export const EXPECTED_NON_EMPTY_FILTER_KEYS = [FILTER_KEY.HAS_UNOFFICIAL_PUBLICATION_DATE];

export const ERROR_KEY = {
  PUBLISHED_ORDER: 'published_order'
};

export const DATE_FILTERS = [FILTER_KEY.PUBLISHED_FROM, FILTER_KEY.PUBLISHED_TO];

export const DEFAULT_CATEGORIES = [
  DOCUMENT_CATEGORIES.CFR,
  DOCUMENT_CATEGORIES.STATUTE,
  DOCUMENT_CATEGORIES.STATE_CODE,
  DOCUMENT_CATEGORIES.US_CODE,
  DOCUMENT_CATEGORIES.LAW,
  DOCUMENT_CATEGORIES.PUBLIC_ACT,
  DOCUMENT_CATEGORIES.US_PUBLIC_LAW,
  DOCUMENT_CATEGORIES.ACT,
  DOCUMENT_CATEGORIES.RULE,
  DOCUMENT_CATEGORIES.DECISION_EU,
  DOCUMENT_CATEGORIES.DIRECTIVE,
  DOCUMENT_CATEGORIES.ORDINANCE,
  DOCUMENT_CATEGORIES.DECREE,
  DOCUMENT_CATEGORIES.DIRECTIVE_EU,
  DOCUMENT_CATEGORIES.ENROLLED_AND_ENACTED_BILL,
  DOCUMENT_CATEGORIES.STANDARD,
  DOCUMENT_CATEGORIES.INTERNATIONAL_TREATY,
  DOCUMENT_CATEGORIES.REGULATION_EU,
  DOCUMENT_CATEGORIES.EMERGENCY_RULE
];

export const IRRELEAVANT_META_CATEGORIES = [
  DOCUMENT_META_CATEGORIES.ENFORCEMENTS,
  DOCUMENT_META_CATEGORIES.REPORTS
];

export const CATEGORY_KEY = 'cai_category';

export const DEFAULT_JA_DOCUMENTS_EXCLUSIVE_FIELDS = ['id', 'official_id', 'title', 'created_at'];
export const MAX_JA_DOCUMENTS_LIMIT = 10000;
export const INITIAL_JA_DOCUMENTS_LIMIT = 40;
