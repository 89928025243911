import { REQUEST_BANKS, RECEIVE_BANKS } from './banks.actions';

export const getInitialState = () => ({
  isFetching: false,
  isReady: false,
  items: []
});

export const banks = (state = getInitialState(), action) => {
  switch (action.type) {
    case REQUEST_BANKS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_BANKS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.banks
      };
    default:
      return state;
  }
};
