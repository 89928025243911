import PropTypes from 'prop-types';

import { DocumentTaskItem } from '../../../DocumentTaskItem';

import * as taskUtils from 'utils/task-utils';
import * as uiLib from '@compliance.ai/web-components';
import * as constants from './WorkflowPreTaskContainer.constants';

import {
  useWorkflowPreTaskContainerData,
  useWorkflowPreTaskContainerHandlers,
  useWorkflowPreTaskLifecycle
} from './hooks';

import './_workflow-pre-task-container.scss';

export const WorkflowPreTaskContainer = ({
  document,
  workflowId,
  onWorkflowAddCancel,
  isObligationWorkflow
}) => {
  const { localState, localActions, formattedData } = useWorkflowPreTaskContainerData({
    document
  });

  const handlers = useWorkflowPreTaskContainerHandlers({
    props: {
      document,
      workflowId,
      onWorkflowAddCancel,
      isObligationWorkflow
    },
    localState,
    localActions
  });

  useWorkflowPreTaskLifecycle({
    onWorkflowFetch: handlers.handleWorkflowFetch
  });

  return (
    <div className="workflow-pre-task-container">
      {localState.isLoading ||
        (localState.isCreatingTasks && (
          <div className="workflow-pre-task-container__loader">
            <uiLib.Loader
              isContrast
              messages={localState.isCreatingTasks ? constants.LOADER_MESSAGES : []}
              classNames={{
                innerContainer: 'workflow-pre-task-container__loader-inner-container',
                messagesContainer: 'workflow-pre-task-container__loader-messages-container'
              }}
            />
          </div>
        ))}
      {!localState.isCreatingTasks && (
        <>
          <uiLib.LabeledField
            isContrast
            label="The following tasks are about to be created. Review and edit deadlines and assignees:"
            labelClassName="workflow-pre-task-container__label"
          >
            {localState.tasks.map(task => (
              <DocumentTaskItem
                key={task.id}
                task={task}
                workflow={localState.workflow}
                onTaskUpdate={handlers.handleTaskUpdate(task.id)}
                document={document}
                isContrast
                isInvalid={taskUtils.isPastDueTask(document)(task)}
              />
            ))}
          </uiLib.LabeledField>
          {formattedData.areTherePasDueTasks && (
            <div className="workflow-pre-task-container__past-due-warning">
              <uiLib.IconWarning />
              <span className="workflow-pre-task-container__warning-text">
                The deadline cannot be in the past.
              </span>
            </div>
          )}
          <div className="workflow-pre-task-container__footer">
            <uiLib.Button type={uiLib.BUTTON_TYPES.SECONDARY} onClick={onWorkflowAddCancel}>
              Cancel
            </uiLib.Button>
            <uiLib.Button
              type={uiLib.BUTTON_TYPES.PRIMARY}
              isDisabled={formattedData.isSubmitButtonDisabled}
              onClick={handlers.handleDocTasksCreation}
            >
              Create Tasks
            </uiLib.Button>
          </div>
        </>
      )}
    </div>
  );
};

WorkflowPreTaskContainer.propTypes = {
  document: PropTypes.object.isRequired,
  workflowId: PropTypes.number.isRequired,
  onWorkflowAddCancel: PropTypes.func.isRequired,
  isObligationWorkflow: PropTypes.bool
};
