/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'svg-classlist-polyfill'; // To provide better support for SVG in IE11.
import '@compliance.ai/web-components/dist/style.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import Bugsnag from '@bugsnag/js';
import { nodeEnv, bugsnagApiKey } from 'shared/config';
import { incrementDayUse } from 'utils/usageMonitoring';
import { Router } from './router';
import { reportWebVitals } from './reportWebVitals';
import { initializeInterceptors } from './fetch/fetch.interceptors';
import { initializeGoogleAnalytics, initializeGoogleTagManager } from './utils/analytics';

import 'styles/main.scss';

if (bugsnagApiKey) {
  Bugsnag.start({
    apiKey: bugsnagApiKey,
    releaseStage: nodeEnv,
    enabledReleaseStages: ['production']
  });
}

// monkey patch XHR so it always has the original URL (source http://stackoverflow.com/a/26725823)
const xhrProto = XMLHttpRequest.prototype;
const origOpen = xhrProto.open;

xhrProto.open = function (method, url, ...args) {
  this._url = url;
  return origOpen.apply(this, [method, url, ...args]);
};

incrementDayUse();

initializeGoogleTagManager();
initializeGoogleAnalytics();
initializeInterceptors();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
