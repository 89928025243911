import * as filtersConstants from '../filters.constants';

import lodash from 'lodash';

export const sortByLabels = (a, b) => {
  if (a.label && b.label) {
    return a.label.localeCompare(b.label);
  }

  return true;
};

export const formatDocTypeOptions = metaCategoriesObject => {
  if (lodash.isObject(metaCategoriesObject)) {
    return Object.keys(metaCategoriesObject)
      .map(formatDocTypeOption(metaCategoriesObject))
      .sort(sortByLabels);
  }

  return [];
};

export const formatDocTypeOption = metaCategoriesObject => metaCategory => {
  return {
    label: metaCategory,
    options: formatCategories(metaCategoriesObject[metaCategory]).sort(sortByLabels)
  };
};

export const formatCategories = categories => {
  if (Array.isArray(categories)) {
    return categories
      .map(category => {
        return { label: category, value: category };
      })
      .sort(sortByLabels);
  }

  return [];
};

export const formatTopicOptions = topics => {
  if (Array.isArray(topics)) {
    return topics
      .map(topic => {
        return {
          label: topic.name || topic.label,
          value: topic.id || topic.topic_id
        };
      })
      .sort(sortByLabels);
  }
  return [];
};

export const formatAgencyOptions = agencies => {
  if (Array.isArray(agencies)) {
    return agencies
      .filter(agency => !lodash.isNil(agency.short_name))
      .map(agency => {
        return {
          label: agency.short_name,
          fullName: agency?.name ?? agency.short_name,
          value: agency.id
        };
      })
      .sort(sortByLabels);
  }
  return [];
};

export const formatJurisdictionsOptions = jurisdictions => {
  if (Array.isArray(jurisdictions)) {
    return jurisdictions
      .filter(jurisdiction => jurisdiction.short_name && jurisdiction.name)
      .map(formatJurisdictionOption)
      .sort(sortByLabels);
  }
  return [];
};

export const formatJurisdictionOption = jurisdiction => {
  const jurisdictionLabel = `${jurisdiction.name} (${jurisdiction.short_name})`;
  return {
    label: jurisdictionLabel,
    value: jurisdiction.short_name,
    id: jurisdiction.id
  };
};

export const formatMainstreamNewsOptions = sources => {
  if (Array.isArray(sources)) {
    return sources
      .map(newsSource => {
        if (newsSource.entity) {
          return {
            name: newsSource.entity.name,
            label: newsSource.entity.name,
            value: newsSource.entity.id
          };
        }
        return {
          name: newsSource.name,
          label: newsSource.name,
          value: newsSource.id
        };
      })
      .sort(sortByLabels);
  }
  return [];
};

export const formatRegulationOptions = regulations => {
  if (Array.isArray(regulations)) {
    return regulations
      .map(regulation => {
        return { label: regulation.name, value: regulation.id };
      })
      .sort(sortByLabels);
  }
  return [];
};

export const formatDocumentPropertiesOptions = ({ isPowerOrTeamUser, hasBYOCSku }) => {
  let options = isPowerOrTeamUser
    ? Object.values(filtersConstants.ALL_DOC_PROPERTIES)
    : Object.values(filtersConstants.STANDARD_DOC_PROPERTIES);

  options = hasBYOCSku ? [...options, ...Object.values(filtersConstants.BYOC_PROPERTIES)] : options;

  return lodash.orderBy(options, 'label');
};

export const formatLanguageOptions = languages => {
  if (Array.isArray(languages)) {
    return languages
      .map(language => {
        return { label: language.name, value: language.id };
      })
      .sort(sortByLabels);
  }
  return [];
};
