import type { AddLabelsModalData } from './useAddLabelsModalData.types';

import { useMemo, useState } from 'react';

export const useAddLabelsModalData = (): AddLabelsModalData => {
  const [selectedLabelIds, setSelectedLabelIds] = useState<
    AddLabelsModalData['localState']['selectedLabelIds']
  >([]);

  const localState: AddLabelsModalData['localState'] = {
    selectedLabelIds
  };

  const localActions: AddLabelsModalData['localActions'] = useMemo(
    () => ({
      setSelectedLabelIds
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
