import type { SendByEmailHandlers } from './useSendByEmailHandlers.types';
import type { SendByEmailProps } from '../SendByEmail.types';

import * as downloadUtils from 'utils/downloads';

import { useViewReduxActions } from 'shared/features/view/hooks';

export const useSendByEmailHandlers = ({
  props
}: {
  props: Pick<SendByEmailProps, 'onActionStart' | 'documentIds'>;
}): SendByEmailHandlers => {
  const viewReduxActions = useViewReduxActions();
  const handleSendByEmail: SendByEmailHandlers['handleSendByEmail'] = () => {
    props.onActionStart?.();
    downloadUtils.emailDownload(props.documentIds);
  };

  const handleRestrictModalOpen: SendByEmailHandlers['handleRestrictModalOpen'] = () => {
    viewReduxActions.showRestrictModal(null, 'Email Document', null);
  };

  return {
    handleSendByEmail,
    handleRestrictModalOpen
  };
};
