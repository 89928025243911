import type { CFRsProps } from './CFRs.types';

import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';

import { useResourceDocumentCheck } from 'utils/hooks';
import { useCFRsData } from './hooks';

export const CFRs = ({ document, shouldRenderForEmail }: CFRsProps) => {
  const isResourceDocument = useResourceDocumentCheck(document);

  const { formattedData } = useCFRsData({ document });

  if (isResourceDocument || shouldRenderForEmail || !formattedData.CFRs.length) {
    return null;
  }

  return (
    <>
      <FieldName>CFR</FieldName>
      <FieldValue isExpandable>{formattedData.CFRs.join(', ')}</FieldValue>
    </>
  );
};
