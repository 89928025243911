import type { FC } from 'react';
import type { DocumentActionsProps } from './DocumentActions.types';

import * as common from 'common/index';
import * as constants from './DocumentActions.constants';

import './_document-actions.scss';

export const DocumentActions: FC<DocumentActionsProps> = ({
  documentIds,
  onTableActionStart,
  customActions
}) => {
  return (
    <div className="documents-table-document-actions__container">
      <common.Folder
        documentIds={documentIds}
        onActionStart={onTableActionStart?.('add to folder')}
      />
      <common.Email
        documentIds={documentIds}
        onActionStart={onTableActionStart?.('send email')}
        enabled
      />
      <common.Print
        documentIds={documentIds}
        enabled
        onActionStart={onTableActionStart?.('print')}
        queryOptions={{
          extraQueryKey: constants.EXTRA_QUERY_KEYS.PRINT
        }}
      />
      <common.Diff
        documentIds={documentIds}
        isEnabled
        onActionStart={onTableActionStart?.('diff')}
        queryOptions={{
          extraQueryKey: constants.EXTRA_QUERY_KEYS.DIFF
        }}
      />
      <common.Export
        isEnabled
        documentIds={documentIds}
        onActionStart={onTableActionStart?.('export')}
      />
      <common.Workflow
        documentIds={documentIds}
        onActionStart={onTableActionStart?.('add to workflow')}
      />
      <common.AddLabels
        documentIds={documentIds}
        onActionStart={onTableActionStart?.('add labels')}
      />
      {customActions}
    </div>
  );
};
