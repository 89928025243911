import { ValidatorItem } from './elements';
import { isValidEmailItem, isValidPasswordItem } from './SignUpDataValidator.helpers';
import {
  EMAIL_VALIDATOR_MESSAGES,
  PASSWORD_VALIDATOR_MESSAGES
} from './SignUpDataValidator.constants';
import './_sign-up-data-validator.scss';

export const TEST_IDS = {
  CONTAINER: 'sign-up-data-validator__container',
  ITEM: 'sign-up-data-validator__item'
};

export const SignUpDataValidator = ({ email, password, shouldValidateEmail }) => {
  return (
    <div className="sign-up-data-validator__container" data-testid={TEST_IDS.CONTAINER}>
      {shouldValidateEmail &&
        Object.entries(EMAIL_VALIDATOR_MESSAGES).map(([key, message]) => {
          const isValid = isValidEmailItem(key, email);

          return (
            <ValidatorItem
              key={key}
              isValid={isValid}
              dataTestId={`${TEST_IDS.ITEM}-${key}`}
              message={message}
            />
          );
        })}
      {Object.entries(PASSWORD_VALIDATOR_MESSAGES).map(([key, message]) => {
        const isValid = isValidPasswordItem(key, password);

        return (
          <ValidatorItem
            key={key}
            isValid={isValid}
            dataTestId={`${TEST_IDS.ITEM}-${key}`}
            message={message}
          />
        );
      })}
    </div>
  );
};
