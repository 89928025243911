import type { DocumentTypeProps } from './DocumentType.types';

import { useDocumentTypeData } from './hooks';
import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';

export const DocumentType = ({ document }: DocumentTypeProps) => {
  const { reduxState } = useDocumentTypeData({ document });

  return (
    <>
      <FieldName>Document Type</FieldName>
      <FieldValue>{reduxState.categoryName}</FieldValue>
    </>
  );
};
