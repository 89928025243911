import type * as storeTypes from 'store';
import type * as actionsTypes from '../../buckets.actions.types';
import type { PayloadAction } from '@reduxjs/toolkit';

import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import * as actions from '../../buckets.actions';

export const useBucketsReduxActions = () => {
  const dispatch = useDispatch<storeTypes.StoreDispatch>();

  const getBuckets = useCallback(
    async (...args: Parameters<typeof actions.getBuckets>) => {
      const action = (await dispatch(actions.getBuckets(...args))) as never;
      return (action as PayloadAction<actionsTypes.GetBucketsReturn>).payload;
    },
    [dispatch]
  );

  const getBucketById = useCallback(
    async (...args: Parameters<typeof actions.getBucketById>) => {
      const action = (await dispatch(actions.getBucketById(...args))) as never;
      return (action as PayloadAction<actionsTypes.GetBucketByIdReturn>).payload;
    },
    [dispatch]
  );

  const addBucket = useCallback(
    async (...args: Parameters<typeof actions.addBucket>) => {
      const action = (await dispatch(actions.addBucket(...args))) as never;
      return (action as PayloadAction<actionsTypes.AddBucketReturn>).payload;
    },
    [dispatch]
  );

  const modifyBucket = useCallback(
    async (...args: Parameters<typeof actions.modifyBucket>) => {
      const action = (await dispatch(actions.modifyBucket(...args))) as never;
      return (action as PayloadAction<actionsTypes.ModifyBucketReturn>).payload;
    },
    [dispatch]
  );

  const deleteBucket = useCallback(
    async (...args: Parameters<typeof actions.deleteBucket>) => {
      const action = (await dispatch(actions.deleteBucket(...args))) as never;
      return (action as PayloadAction<actionsTypes.DeleteBucketReturn>).payload;
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      getBuckets,
      getBucketById,
      addBucket,
      modifyBucket,
      deleteBucket
    }),
    [getBuckets, getBucketById, addBucket, modifyBucket, deleteBucket]
  );
};
