export enum DOCUMENT_METADATA_FIELDS {
  ID = 'id',
  TITLE = 'title',
  SUMMARY = 'summary_text',
  PUBLICATION_DATE = 'publication_date',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  COMMENTS_CLOSE_ON = 'comments_close_on',
  EFFECTIVE_ON = 'effective_on',
  DOC_TYPES = 'category',
  JURISDICTION = 'jurisdiction',
  CITATION = 'citation',
  AUTHOR = 'author',
  TOPICS = 'topics'
}

export const DOCUMENT_METADATA_LABELS = {
  [DOCUMENT_METADATA_FIELDS.ID]: 'ID: ',
  [DOCUMENT_METADATA_FIELDS.TITLE]: 'Doc Title',
  [DOCUMENT_METADATA_FIELDS.SUMMARY]: 'Summary',
  [DOCUMENT_METADATA_FIELDS.PUBLICATION_DATE]: 'Publication Date',
  [DOCUMENT_METADATA_FIELDS.CREATED_AT]: 'Added to Compliance.ai',
  [DOCUMENT_METADATA_FIELDS.UPDATED_AT]: 'Updated in Compliance.ai',
  [DOCUMENT_METADATA_FIELDS.COMMENTS_CLOSE_ON]: 'Comments Close Date',
  [DOCUMENT_METADATA_FIELDS.EFFECTIVE_ON]: 'Effective Date',
  [DOCUMENT_METADATA_FIELDS.DOC_TYPES]: 'Document Type',
  [DOCUMENT_METADATA_FIELDS.JURISDICTION]: 'Jurisdiction',
  [DOCUMENT_METADATA_FIELDS.CITATION]: 'Citation is',
  [DOCUMENT_METADATA_FIELDS.AUTHOR]: 'Author',
  [DOCUMENT_METADATA_FIELDS.TOPICS]: 'Topics'
};
