import type * as types from './ValidationStage.types';

export class ValidationStage implements types.ValidationStage {
  readonly validatorsMap: types.ValidationStage['validatorsMap'];
  readonly onValidationFail: types.ValidationStage['onValidationFail'];

  private hasFailedAtLeastOnce: types.ValidationStagePrivateFields['hasFailedAtLeastOnce'];

  constructor(args: {
    validatorsMap: types.ValidationStage['validatorsMap'];
    onValidationFail?: types.ValidationStage['onValidationFail'];
  }) {
    this.validatorsMap = args.validatorsMap ?? {};
    this.onValidationFail = args.onValidationFail;
    this.hasFailedAtLeastOnce = false;
  }

  readonly getValidatedValue: types.ValidationStage['getValidatedValue'] = ({
    stageCallCount,
    metadataKey,
    metadataValue
  }) => {
    if (metadataKey in this.validatorsMap) {
      const validatorConfig = this.validatorsMap[metadataKey];

      if (!validatorConfig) {
        return [[metadataKey, metadataValue]];
      }

      const { isValid, validValue } = validatorConfig.validator?.validate({
        metadataKey: metadataKey,
        metadataValue: metadataValue
      }) ?? {
        isValid: true,
        validValue: metadataValue
      };

      if (isValid) {
        return [[metadataKey, metadataValue]];
      }

      this.onValidationFail?.({
        metadataKey: metadataKey,
        metadataValue: metadataValue,
        stageCallCount: stageCallCount,
        hasFailedAtLeastOnce: this.hasFailedAtLeastOnce
      });

      this.hasFailedAtLeastOnce = true;

      if (validatorConfig.shouldKeepValidValues) {
        return [[metadataKey, validValue]];
      }

      return [];
    }

    return [[metadataKey, metadataValue]];
  };

  readonly run: types.ValidationStage['run'] = async args => {
    return args.metadata.flatMap(([metadataKey, metadataValue], index) => {
      return this.getValidatedValue({
        stageCallCount: args.stageCallCount,
        metadataKey: metadataKey,
        metadataValue: metadataValue
      });
    });
  };
}
