import TuneIcon from '@material-ui/icons/Tune';
import { useFilterCountData } from './hooks/useFilterCountData';
import { Tooltip, TOOLTIP_POSITION } from 'common';
import PropTypes from 'prop-types';
import './_filter-count.scss';

const FilterCount = ({ searchTermTooltipTrigger }) => {
  const {
    toggleFilter,
    isTooltipVisible,
    toggleTooltipVisibility,
    filterCount,
    formattedData
  } = useFilterCountData({ searchTermTooltipTrigger });

  const handleTooltipCloseButtonClick = e => {
    e.stopPropagation();
    toggleTooltipVisibility(false);
  };

  return (
    <div onClick={toggleFilter} className="filter-count__icon-outer-span">
      {formattedData.shouldDisplayContent && (
        <>
          <TuneIcon />
          <span className="filter-count__text">{'Filter'}</span>
          <Tooltip
            title="Search term added to filters"
            placement={TOOLTIP_POSITION.BOTTOM}
            isOpen={isTooltipVisible}
            shouldShowArrow
            shouldShowCloseButton
            onCloseButtonClick={handleTooltipCloseButtonClick}
          >
            {filterCount > 0 && <span className="filter-count__count">{filterCount}</span>}
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default FilterCount;

FilterCount.propTypes = {
  searchTermTooltipTrigger: PropTypes.bool.isRequired
};
