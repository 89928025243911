import { useQueryParams } from 'utils/hooks';
import { QUERY_PARAMS } from 'shared/features/auth/auth.constants';

export const useVerificationCodeEmailData = () => {
  const queryParams = useQueryParams();

  const verificationCode = queryParams[QUERY_PARAMS.VERIFICATION_CODE];
  const email = queryParams[QUERY_PARAMS.EMAIL];

  const shouldUseEmailPasswordResetFlow = Boolean(verificationCode) && Boolean(email);

  return {
    shouldUseEmailPasswordResetFlow,
    verificationCode,
    email
  };
};
