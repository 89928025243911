import { REQUEST_REGULATIONS, RECEIVE_REGULATIONS } from './regulations.actions';

export const getInitialRegulationsState = () => ({
  isFetching: false,
  isReady: false,
  regulations: []
});

export const regulations = (state = getInitialRegulationsState(), action) => {
  switch (action.type) {
    case REQUEST_REGULATIONS:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_REGULATIONS:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        regulations: action.payload.regulations
      };
    default:
      return state;
  }
};
