import type { DocumentFromResponse } from 'shared/features/documents/documents.types';
import type { DocumentTasksResponse } from 'shared/features/workflow/workflow.types';

import * as documentAccessUtils from 'utils/documents/documentsAccess';
import * as restrictions from 'constants/Restrictions';
import * as docTasksConstants from 'constants/DocTasks';
import * as taskUtils from 'utils/task-utils';
import * as uiLib from '@compliance.ai/web-components';

export const formatData = ({
  document
}: {
  document: DocumentFromResponse;
}): {
  document: DocumentFromResponse | null;
  canUserAccessDocument: boolean;
  areAnnotationsAvailable: boolean;
  areObligationsAvailable: boolean;
  isDiffAvailable: boolean;
} => {
  const canUserAccessDocument = documentAccessUtils.userCanAccessDocument({ document });

  const areAnnotationsAvailable = documentAccessUtils.getDocumentRestriction({
    document: document,
    action: restrictions.RESTRICTED_ACTIONS.ANNOTATIONS
  });

  const areObligationsAvailable = documentAccessUtils.getDocumentRestriction({
    document: document,
    action: restrictions.RESTRICTED_ACTIONS.OBLIGATIONS
  });

  const isDiffAvailable = documentAccessUtils.getDocumentRestriction({
    document: document,
    action: restrictions.RESTRICTED_ACTIONS.DIFF
  });

  return {
    document,
    canUserAccessDocument,
    areAnnotationsAvailable,
    areObligationsAvailable,
    isDiffAvailable
  };
};

export const formatDocumentTasksStatus = (
  tasks: DocumentTasksResponse['results']
): uiLib.STATUS_BADGE_TYPE | undefined => {
  const taskStatusesCount = {
    [docTasksConstants.TASK_STATUS.DONE]: 0,
    [docTasksConstants.TASK_STATUS.TO_DO]: 0,
    [docTasksConstants.TASK_STATUS.INCOMPLETE]: 0,
    [docTasksConstants.TASK_STATUS.PAST_DUE]: 0
  };

  const overdueTask = tasks.find(task => {
    taskStatusesCount[task.task_status as docTasksConstants.TASK_STATUS] =
      Number(taskStatusesCount[task.task_status as docTasksConstants.TASK_STATUS] || 0) + 1;

    return taskUtils.isOverdueTaskStatus(task);
  });

  if (overdueTask) {
    return uiLib.STATUS_BADGE_TYPE.OVERDUE;
  }
  if (taskStatusesCount[docTasksConstants.TASK_STATUS.INCOMPLETE]) {
    return uiLib.STATUS_BADGE_TYPE.TO_DO;
  }
  if (taskStatusesCount[docTasksConstants.TASK_STATUS.DONE]) {
    return uiLib.STATUS_BADGE_TYPE.DONE;
  }
  return;
};
