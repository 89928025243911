import type { GlobalState } from 'shared/reducers';
import type { DocumentDetailsContentTitleData } from './useDocumentDetailsContentTitleData.types';

import * as rightPanelSelectors from 'shared/features/rightPanel/rightPanel.selectors';

import { useSelector } from 'react-redux';

export const useDocumentDetailsContentTitleData = (): DocumentDetailsContentTitleData => {
  const reduxState = useSelector<GlobalState, DocumentDetailsContentTitleData['reduxState']>(
    state => ({
      documentDetailsActiveTabKey: rightPanelSelectors.getDocumentDetailsActiveTabKey(state)
    })
  );

  return {
    reduxState
  };
};
