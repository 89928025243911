import * as accessConstants from '../constants/Access';
import * as localStorageUtils from '../utils/localStorage-utils';

import * as uiLib from '@compliance.ai/web-components';

const setUsage = (key, value) =>
  localStorageUtils.safelySetLocalStorageData(key, JSON.stringify(value));
const getUsage = key => {
  localStorageUtils.safelyGetLocalStorageArray(key);
};

// remove anything older than 10 days and add any distinct day
const filterOlderThanTenDays = usage => {
  try {
    const dateTenDaysAgo = uiLib.getDateBeforeDate({
      date: new Date(),
      amount: accessConstants.ACCESS_TRACKING_DAYS,
      unitOfTime: uiLib.DATE_UNITS.DAY
    });
    return usage.filter(accessDate =>
      uiLib.isAfter(new Date(accessDate), dateTenDaysAgo, {
        isDateBIncluded: true
      })
    );
  } catch (Exception) {
    return [];
  }
};

const incrementDayUsage = () => {
  let usage = getUsage('complianceDayAccessometer');

  usage = filterOlderThanTenDays(usage);

  if (!usage.find(accessDate => uiLib.isToday(new Date(accessDate)))) {
    usage.push(new Date());
  }

  return usage;
};

const incrementActivityUsage = () => {
  let usage = getUsage('complianceActivityAccessometer');

  usage = filterOlderThanTenDays(usage);
  usage.push(new Date());

  return usage;
};

export const incrementDayUse = () => {
  const usage = incrementDayUsage();
  setUsage('complianceDayAccessometer', usage);
};

export const incrementActivityUse = () => {
  const usage = incrementActivityUsage();
  setUsage('complianceActivityAccessometer', usage);
};

export const hasDayUseExpired = () => {
  const accessometerStringified = localStorage.getItem('complianceDayAccessometer');
  try {
    const accessometer = JSON.parse(accessometerStringified);
    return (
      Array.isArray(accessometer) && accessometer.length >= accessConstants.ACCESS_TRACKING_DAYS
    );
  } catch (e) {
    return false;
  }
};

export const hasActivityUseExpired = () => {
  const accessometerStringified = localStorage.getItem('complianceActivityAccessometer');
  try {
    const accessometer = JSON.parse(accessometerStringified);
    return (
      Array.isArray(accessometer) && accessometer.length >= accessConstants.ACCESS_TRACKING_ACTIVITY
    );
  } catch (e) {
    return false;
  }
};
