import type { WorkflowProps } from './Workflow.types';

import {
  Button,
  BUTTON_TYPES,
  Checkbox,
  IconAdd,
  IconRemove,
  Loader,
  ErrorBoundary
} from '@compliance.ai/web-components';
import { WorkflowSummary, WorkflowPanelTask } from './elements';
import { DONE } from 'constants/DocTasks';
import {
  useWorkflowData,
  useWorkflowHandlers,
  useWorkflowQueries,
  useWorkflowQueryHandlers
} from './hooks';
import './_workflow.scss';

export const Workflow = ({
  workflow_name,
  workflow_id,
  workflow_task_order_ids,
  document,
  sentenceNoteId,
  onTabChange,
  shouldShowObligationsLink
}: WorkflowProps) => {
  const { localState, localActions } = useWorkflowData();

  const queryHandlers = useWorkflowQueryHandlers({ localActions });

  const queries = useWorkflowQueries({
    props: {
      document,
      workflow_id,
      sentenceNoteId,
      workflow_task_order_ids
    },
    localState,
    onDocTasksFetchSuccess: queryHandlers.handleDocumentTasksFetchSuccess
  });

  const handlers = useWorkflowHandlers({
    localActions,
    queries
  });

  return (
    <ErrorBoundary>
      <div className="workflow__tasks-container">
        {localState.isSummaryModalOpen && (
          <WorkflowSummary
            workflow_name={workflow_name}
            documentTasks={queries.fetchDocumentTasksQuery.data?.documentTasks ?? []}
            documentId={document.id}
            onClose={handlers.handleWorkflowSummaryToggle(false)}
          />
        )}
        <div className="workflow__workflow-and-tasks">
          <div className="workflow__workflow-header-wrapper">
            <h2>{workflow_name}</h2>
            {queries.fetchDocumentTasksQuery.data?.areAllTaskDetailsRevealed ? (
              <IconRemove
                onClick={handlers.handleExpandIconClick(false)}
                className="workflow__workflow-expand-icon"
              />
            ) : (
              <IconAdd
                onClick={handlers.handleExpandIconClick(true)}
                className="workflow__workflow-expand-icon"
              />
            )}
          </div>
          <div className="workflow__workflow-task-container">
            {queries.fetchDocumentTasksQuery.isFetching && (
              <Loader
                withoutBackground={false}
                isContrast
                classNames={{
                  container: 'workflow__loader'
                }}
              />
            )}
            {queries.fetchDocumentTasksQuery.data?.documentTasks?.map?.(documentTask => (
              <WorkflowPanelTask
                key={documentTask.id}
                document={document}
                areDetailsRevealed={Boolean(localState.tasksDetailsVisibility[documentTask.id])}
                onTabChange={onTabChange}
                onRevealIconClick={handlers.handleDetailsRevealIconClick(documentTask)}
                documentTask={documentTask}
                shouldShowObligationsLink={shouldShowObligationsLink}
              />
            ))}
          </div>
          {Boolean(queries.fetchDocumentTasksQuery.data?.documentTasks?.length) && (
            <div className="workflow__doc-status-container">
              <div className="workflow__doc-status-wrapper">
                <div className="workflow__doc-status">
                  <div className="workflow__task-status">
                    <Checkbox isChecked={queries.fetchDocumentTasksQuery.data?.areAllTasksDone} />
                  </div>
                  Done
                </div>
                <Button
                  type={BUTTON_TYPES.LINK_CONTRAST}
                  isBold
                  onClick={handlers.handleWorkflowSummaryToggle(!localState.isSummaryModalOpen)}
                >
                  View summary
                </Button>
              </div>
              <div className="workflow__doc-progress">
                {queries.fetchDocumentTasksQuery.data?.documentTasks?.map?.(
                  ({ id, task_status }) => {
                    return task_status === DONE ? (
                      <div className="workflow__progress-tick--is-active" key={`${id}-active`} />
                    ) : (
                      <div className="workflow__progress-tick" key={`${id}-inactive`} />
                    );
                  }
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};
