import { useRef } from 'react';
import lodash from 'lodash';

export const useDeepCompareMemoize = deps => {
  const ref = useRef([]);

  if (!lodash.isEqual(deps, ref.current)) {
    ref.current = deps;
  }

  return ref.current;
};
