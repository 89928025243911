import type { AlertPreviewBannerProps } from '../AlertPreviewBanner.types';
import type { AlertPreviewBannerHandlers } from './useAlertPreviewBannerHandlers.types';

import { SUPPORTED_QUERY_PARAMS } from 'constants/PrimaryFilter';

import { useViewReduxActions } from 'shared/features/view/hooks';
import { useLocation } from 'react-router-dom';
import { useCallback } from 'react';
import { usePreviousValue } from '@compliance.ai/web-components';

export const useAlertPreviewBannerHandlers = ({
  props
}: {
  props: Pick<AlertPreviewBannerProps, 'alertParams'>;
}): AlertPreviewBannerHandlers => {
  const viewReduxActions = useViewReduxActions();

  const location = useLocation();
  const prevLocation = usePreviousValue(location);

  const handleBackClick: AlertPreviewBannerHandlers['handleBackClick'] = () => {
    viewReduxActions.hidePreviewAlert();

    if (Boolean(props.alertParams[SUPPORTED_QUERY_PARAMS.ADVANCED_SEARCH])) {
      viewReduxActions.openAdvancedSearchFilter();
    } else {
      viewReduxActions.openFilter();
    }
  };

  const handleAlertCloseOnPathChange: AlertPreviewBannerHandlers['handleAlertCloseOnPathChange'] = useCallback(() => {
    if (prevLocation.pathname !== location.pathname) {
      viewReduxActions.removeViewAlert();
    }
  }, [location.pathname, prevLocation.pathname, viewReduxActions]);

  return {
    handleBackClick,
    handleAlertCloseOnPathChange
  };
};
