import type { DocumentItemProps } from '../DocumentItem.types';
import type { DocumentItemHandlers } from './useDocumentItemHandlers.types';
import type { TopicFromResponse } from 'shared/features/sources/sources.api.types';

import * as uiLib from '@compliance.ai/web-components';
import * as rightPanelConstants from 'shared/features/rightPanel/rightPanel.constants';
import * as routes from 'constants/Routes';
import * as errorUtils from 'utils/errors';
import * as documentAccess from 'utils/documents/documentsAccess';

import { DOCUMENT_SUMMARY_PAGE_KEY } from 'constants/DocumentConstants';

import { useDocumentsApi, useDocumentsReduxActions } from 'shared/features/documents/hooks';

export const useDocumentItemHandlers = ({
  props
}: {
  props: Pick<DocumentItemProps, 'cellValue'>;
}): DocumentItemHandlers => {
  const documentsReduxActions = useDocumentsReduxActions();
  const documentsApi = useDocumentsApi();

  const handleDocumentClick: DocumentItemHandlers['handleDocumentClick'] = () => {
    if (props.cellValue.isDocumentClickable) {
      props.cellValue.events?.onDocumentClick?.(props.cellValue.document);
    }
  };

  const handleTopicClick: DocumentItemHandlers['handleTopicClick'] = chiclet => {
    props.cellValue.events?.onTopicClick?.({
      topicId: chiclet.value as TopicFromResponse['id'],
      document: props.cellValue.document
    });
  };

  const handleRelatedDocsChicletClick: DocumentItemHandlers['handleRelatedDocsChicletClick'] = chiclet => {
    props.cellValue.events?.onRelatedDocumentsClick?.({
      section: chiclet.value as string,
      document: props.cellValue.document
    });
  };

  const handleBookmark: DocumentItemHandlers['handleBookmark'] = async () => {
    try {
      const isBookmarked = props.cellValue.document.bookmarked;
      const documentId = props.cellValue.document.id;

      /**
       * To update document in Timeline/News/Enforcements view
       */
      // TODO Remove once we fully deprecate legacy search views
      documentsReduxActions.markDocumentBookmarked([documentId], !isBookmarked);

      await documentsApi.bookmarkDocuments([documentId], !isBookmarked);

      props.cellValue.refreshTable();
    } catch (e) {
      errorUtils.logError(e as Error);
    }
  };

  const handleDocumentOpen: DocumentItemHandlers['handleDocumentOpen'] = shouldOpenInNewTab => () => {
    const url = uiLib.formatRedirectUrl({
      path: routes.dashboard,
      params: {
        [rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS.SUMMARY_PAGE]: DOCUMENT_SUMMARY_PAGE_KEY,
        [rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS.SUMMARY_ID]: props.cellValue.document.id,

        ...(documentAccess.checkPDFAvailability(props.cellValue.document) && {
          [rightPanelConstants.RIGHT_PANEL_QUERY_PARAMS.CLOSE_RIGHT_PANEL_ON_OVERLAY_CLOSE]: true,
          [rightPanelConstants.OVERLAY_QUERY_PARAMS.OVERLAY_TYPE]:
            rightPanelConstants.OVERLAY_TYPES.PDF
        })
      }
    });

    if (shouldOpenInNewTab) {
      window.open(url);
    } else {
      window.open(url, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,fullscreen=yes');
    }
  };

  return {
    handleDocumentClick,
    handleBookmark,
    handleTopicClick,
    handleRelatedDocsChicletClick,
    handleDocumentOpen
  };
};
