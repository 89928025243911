import queryString from 'query-string';
import {
  QUERY_STRING_OPTION_KEYS,
  QUERY_STRING_OPTION_VALUES,
  QUERY_STRING_OPTION_DEFAULT_VALUES,
  ARRAY_FORMATS
} from './query-string.constants';

/**
 * Parse additional options from params.
 *
 * @param params — The params object to parse.
 *
 * You can use any of option from https://github.com/sindresorhus/query-string/
 *
 */
const parseAdditionalOptions = params => {
  /**
   * Get arrayFormat option from params
   *
   * values: 'bracket' | 'index' | 'comma' | 'separator' | 'none'
   * default: 'none'
   */
  let arrayFormat =
    (params instanceof URLSearchParams
      ? params.get(QUERY_STRING_OPTION_KEYS.ARRAY_FORMAT)
      : params[QUERY_STRING_OPTION_KEYS.ARRAY_FORMAT]) ||
    QUERY_STRING_OPTION_DEFAULT_VALUES.ARRAY_FORMAT;

  if (!QUERY_STRING_OPTION_VALUES.ARRAY_FORMAT.includes(arrayFormat)) {
    arrayFormat = ARRAY_FORMATS.NONE;
  }

  /**
   * And get more parse options here
   *
   */

  return {
    arrayFormat
  };
};

/**
 * Override the parse function.
 *
 * @param query — The query string to parse.
 * @param options — The options object to parse.
 *
 */
const parse = (query, options = {}) => {
  const urlParams = new URLSearchParams(query);

  return queryString.parse(query, {
    ...options,
    ...parseAdditionalOptions(urlParams)
  });
};

/**
 * Override the stringify function.
 *
 * @param params — The params object to stringify.
 * @param options — The options object to stringify.
 *
 */
const stringify = (params, options = {}) => {
  return queryString.stringify(params, {
    ...options,
    ...parseAdditionalOptions(params)
  });
};

export default { ...queryString, parse, stringify };
