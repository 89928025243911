import { RECEIVE_TOPIC_THRESHOLD_OPTIONS } from './thresholds.actions';

export const getInitialState = () => ({
  topics: {
    topicThresholdOptions: [],
    defaultKey: null
  }
});

export const thresholds = (state = getInitialState(), action) => {
  switch (action.type) {
    case RECEIVE_TOPIC_THRESHOLD_OPTIONS: {
      return {
        ...state,
        topics: {
          topicThresholdOptions: action.topicThresholdOptions,
          defaultKey: action.defaultKey
        }
      };
    }
    default:
      return state;
  }
};
