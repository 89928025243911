import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';

import './_search-results.scss';

export const SearchResults: FC = () => {
  return (
    <uiLib.Typography className="search-results">
      Select a Document to view summary.
    </uiLib.Typography>
  );
};
