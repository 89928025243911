import type { GlobalState } from '../../reducers';
import type { DocumentFromResponse } from '../documents/documents.types';
import type {
  NormalizedSentenceNote,
  NormalizedSystemGeneratedSentenceNote
} from './sentenceNotes.types';

import * as reduxToolkit from '@reduxjs/toolkit';
import * as constants from './sentenceNotes.constants';

export const getSentenceNotesReducer = reduxToolkit.createSelector(
  (state: GlobalState) => state,
  state => state[constants.REDUCER_NAME]
);

export const getSentenceNotes = reduxToolkit.createSelector(
  getSentenceNotesReducer,
  sentenceNotesReducer => sentenceNotesReducer.sentenceNotes
);

export const getExpandedSentenceNoteIds = reduxToolkit.createSelector(
  getSentenceNotesReducer,
  sentenceNotesReducer => sentenceNotesReducer.expandedSentenceNotes
);

export const isSentenceNoteExpanded = reduxToolkit.createSelector(
  [
    (_, sentenceNoteId: (NormalizedSentenceNote | NormalizedSystemGeneratedSentenceNote)['id']) =>
      sentenceNoteId,
    getExpandedSentenceNoteIds
  ],
  (sentenceNoteId, expandedSentenceNotes) => {
    return Boolean(expandedSentenceNotes.find(id => id === sentenceNoteId));
  }
);

export const getAnnotationSentenceNotes = reduxToolkit.createSelector(
  getSentenceNotes,
  sentenceNotes =>
    sentenceNotes.filter(
      sentenceNote => !sentenceNote.isSystemObligation && !sentenceNote.isObligation
    )
);

export const getObligationSentenceNotes = reduxToolkit.createSelector(
  getSentenceNotes,
  sentenceNotes => sentenceNotes.filter(sentenceNote => sentenceNote.isObligation)
);

export const getAnnotationSentenceNotesByDocId = reduxToolkit.createSelector(
  [getAnnotationSentenceNotes, (_, documentId: DocumentFromResponse['id']) => documentId],
  (annotationSentenceNotes, documentId) =>
    annotationSentenceNotes.filter(sentenceNote => {
      if ('documentId' in sentenceNote && sentenceNote.documentId === documentId) {
        return true;
      }

      if ('document' in sentenceNote && sentenceNote.document.id === documentId) {
        return true;
      }

      return false;
    })
);

export const getObligationSentenceNotesByDocId = reduxToolkit.createSelector(
  [getObligationSentenceNotes, (_, documentId: DocumentFromResponse['id']) => documentId],
  (obligationSentenceNotes, documentId) =>
    obligationSentenceNotes.filter(sentenceNote => {
      if ('documentId' in sentenceNote && sentenceNote.documentId === documentId) {
        return true;
      }

      if ('document' in sentenceNote && sentenceNote.document.id === documentId) {
        return true;
      }

      return false;
    })
);
