import type { AppNavigationData } from './useAppNavigationData.types';
import type { AppNavigationHandlers } from './useAppNavigationHandlers.types';
import type { ComponentProps } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as rightPanelConstants from 'shared/features/rightPanel/rightPanel.constants';

import { useDocumentDetailsRightPanelConfig } from './useDocumentDetailsRightPanelConfig';
import { useWorkflowTaskDetailsRightPanelConfig } from './useWorkflowTaskDetailsRightPanelConfig';
import { useSearchResultsRightPanelConfig } from './useSearchResultsRightPanelConfig';

export const useAppNavigationRightPanelConfig = ({
  reduxState,
  events
}: {
  reduxState: AppNavigationData['reduxState'];
  events: {
    onRightPanelCollapse: AppNavigationHandlers['handleRightPanelCollapse'];
    onRightPanelClose: AppNavigationHandlers['handleRightPanelClose'];
    onHideAnimationComplete: AppNavigationHandlers['handleWindowResizeEventCall'];
    onToggleAnimationComplete: AppNavigationHandlers['handleWindowResizeEventCall'];
    onRightPanelDragStart: AppNavigationHandlers['handleRightPanelDragStart'];
    onRightPanelDragEnd: AppNavigationHandlers['handleRightPanelDragEnd'];
  };
}): Required<ComponentProps<typeof uiLib.AppNavigation>>['rightPanelConfig'] => {
  const documentDetailsConfig = useDocumentDetailsRightPanelConfig();
  const workflowTaskDetailsConfig = useWorkflowTaskDetailsRightPanelConfig();
  const searchResultsConfig = useSearchResultsRightPanelConfig();

  return {
    ...{
      [rightPanelConstants.RIGHT_PANEL_TYPES.DOCUMENT_DETAILS]: documentDetailsConfig,
      [rightPanelConstants.RIGHT_PANEL_TYPES.WORKFLOW_TASK_DETAILS]: workflowTaskDetailsConfig,
      [rightPanelConstants.RIGHT_PANEL_TYPES.SEARCH_RESULTS]: searchResultsConfig
    }[reduxState.rightPanel.type],

    isClosable: reduxState.rightPanel.isClosable,
    onCollapse: events.onRightPanelCollapse,
    onHide: events.onRightPanelClose,
    onHideAnimationComplete: events.onHideAnimationComplete,
    onToggleAnimationComplete: events.onToggleAnimationComplete,
    onDragStart: events.onRightPanelDragStart,
    onDragEnd: events.onRightPanelDragEnd
  };
};
