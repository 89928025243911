import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@material-ui/core';
import lodash from 'lodash';

const StyledPopover = styled(props => <Popover {...props} />)`
  .MuiPopover-paper {
    padding: 20px;
    margin-top: 10px;
  }
`;

const TriggerContentWrapper = styled.div`
  display: inline-flex;
`;

function DropdownSharedComponent({
  className,
  triggerContent,
  dropdownContent,
  containerClassName,
  dataTestId,
  anchorOrigin,
  transformOrigin,
  isOpen,
  onClose
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  const open = lodash.isUndefined(isOpen) ? Boolean(anchorEl) : isOpen;
  const id = open ? 'dropdown-popover-trigger' : undefined;

  return (
    <div data-testid={dataTestId} className={containerClassName}>
      <TriggerContentWrapper onClick={handleClick} aria-describedby={id}>
        {React.cloneElement(triggerContent, { handleClose })}
      </TriggerContentWrapper>
      <StyledPopover
        classes={{
          paper: className
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={
          anchorOrigin || {
            vertical: 'bottom',
            horizontal: 'center'
          }
        }
        transformOrigin={
          transformOrigin || {
            vertical: 'top',
            horizontal: 'center'
          }
        }
      >
        {React.cloneElement(dropdownContent, { handleClose })}
      </StyledPopover>
    </div>
  );
}

DropdownSharedComponent.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  triggerContent: PropTypes.element.isRequired,
  dropdownContent: PropTypes.element.isRequired,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string
  }),
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string
  }),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

DropdownSharedComponent.defaultProps = {
  isOpen: undefined,
  anchorOrigin: undefined,
  transformOrigin: undefined,
  onClose: () => {}
};

export default DropdownSharedComponent;
