import type { TaskApprovalAnnotationProps } from './TaskApprovalAnnotation.types';

import { ApprovalAnnotation } from 'common/index';
import { WORKFLOW_ANNOTATION_TYPES } from 'shared/features/workflow/workflow.constants';
import { useDocumentTaskAnnotations } from 'shared/features/workflow/hooks';
import { useTaskApprovalAnnotationData, useTaskApprovalAnnotationHandlers } from './hooks';
import './_task-approval-annotation.scss';

export const TaskApprovalAnnotation = ({
  document,
  documentTask,
  areAllAnnotationsCompleted,
  onChange
}: TaskApprovalAnnotationProps) => {
  const annotation = useDocumentTaskAnnotations(documentTask)[WORKFLOW_ANNOTATION_TYPES.APPROVAL];

  const { reduxState } = useTaskApprovalAnnotationData({
    document,
    documentTask
  });

  const handlers = useTaskApprovalAnnotationHandlers({
    props: {
      documentTask,
      onChange
    }
  });

  if (!annotation.isAvailable) {
    return null;
  }

  return (
    <div className="task-approval-annotation__container">
      <ApprovalAnnotation
        status={annotation.status}
        assigneeId={documentTask.assignee}
        approversIds={annotation.approversIds}
        primaryApprover={annotation.primaryApprover}
        backupApproversIds={reduxState.backupApproversIds}
        label={annotation.label}
        onApprove={handlers.handleApproval(true)}
        onReject={handlers.handleApproval(false)}
        onSubmitForApproval={handlers.handleSubmitForApproval}
        isSubmitDisabled={!areAllAnnotationsCompleted}
      />
    </div>
  );
};
