import type { NavBarItemSourceConfig } from './useAppNavigationHandlers.types';

import * as categoriesConstants from 'constants/Categories';
import * as documentConstants from 'constants/DocumentConstants';

export enum NAV_BAR_ITEM_LABELS {
  DEFAULT_FILTER = 'Default Filter',
  DASHBOARD = 'Dashboard',
  TIMELINE = 'Timeline',
  ENFORCEMENT = 'Enforcement',
  NEWS = 'News',
  INSIGHTS = 'Insights',
  FOLDERS = 'Folders',
  NOTIFICATIONS = 'Notifications',
  RESOURCES = 'Resources',
  TASKS = 'Tasks'
}

export enum NAV_BAR_ITEM_SECTIONS {
  DEFAULT_FILTER = 'sources',
  DASHBOARD = 'dashboard',
  TIMELINE = 'timeline',
  ENFORCEMENT = 'enforcementexplorer',
  NEWS = 'news',
  INSIGHTS = 'insights',
  FOLDERS = 'user_folders',
  NOTIFICATIONS = 'notifications',
  RESOURCES = 'resources',
  TASKS = 'tasks'
}

export const NAV_BAR_SECTIONS_TO_LABEL_MAP: {
  [key in NAV_BAR_ITEM_SECTIONS]: NAV_BAR_ITEM_LABELS;
} = {
  [NAV_BAR_ITEM_SECTIONS.DEFAULT_FILTER]: NAV_BAR_ITEM_LABELS.DEFAULT_FILTER,
  [NAV_BAR_ITEM_SECTIONS.DASHBOARD]: NAV_BAR_ITEM_LABELS.DASHBOARD,
  [NAV_BAR_ITEM_SECTIONS.TIMELINE]: NAV_BAR_ITEM_LABELS.TIMELINE,
  [NAV_BAR_ITEM_SECTIONS.ENFORCEMENT]: NAV_BAR_ITEM_LABELS.ENFORCEMENT,
  [NAV_BAR_ITEM_SECTIONS.NEWS]: NAV_BAR_ITEM_LABELS.NEWS,
  [NAV_BAR_ITEM_SECTIONS.INSIGHTS]: NAV_BAR_ITEM_LABELS.INSIGHTS,
  [NAV_BAR_ITEM_SECTIONS.FOLDERS]: NAV_BAR_ITEM_LABELS.FOLDERS,
  [NAV_BAR_ITEM_SECTIONS.NOTIFICATIONS]: NAV_BAR_ITEM_LABELS.NOTIFICATIONS,
  [NAV_BAR_ITEM_SECTIONS.RESOURCES]: NAV_BAR_ITEM_LABELS.RESOURCES,
  [NAV_BAR_ITEM_SECTIONS.TASKS]: NAV_BAR_ITEM_LABELS.TASKS
};

export const NAV_BAR_ITEMS_SOURCES_CONFIGS: {
  [key in
    | NAV_BAR_ITEM_LABELS.DEFAULT_FILTER
    | NAV_BAR_ITEM_LABELS.DASHBOARD
    | NAV_BAR_ITEM_LABELS.INSIGHTS
    | NAV_BAR_ITEM_LABELS.FOLDERS
    | NAV_BAR_ITEM_LABELS.NOTIFICATIONS
    | NAV_BAR_ITEM_LABELS.RESOURCES
    | NAV_BAR_ITEM_LABELS.TASKS]: NavBarItemSourceConfig;
} = {
  [NAV_BAR_ITEM_LABELS.DEFAULT_FILTER]: {
    label: NAV_BAR_ITEM_LABELS.DEFAULT_FILTER,
    source: 'Sources',
    query: {
      view: 'topics'
    }
  },
  [NAV_BAR_ITEM_LABELS.DASHBOARD]: {
    label: NAV_BAR_ITEM_LABELS.DASHBOARD,
    source: 'Dashboard'
  },
  [NAV_BAR_ITEM_LABELS.INSIGHTS]: {
    label: NAV_BAR_ITEM_LABELS.INSIGHTS,
    source: 'Insights'
  },
  [NAV_BAR_ITEM_LABELS.FOLDERS]: {
    label: NAV_BAR_ITEM_LABELS.FOLDERS,
    source: 'Folders',
    errorModalTitle: 'Folders'
  },
  [NAV_BAR_ITEM_LABELS.NOTIFICATIONS]: {
    label: NAV_BAR_ITEM_LABELS.NOTIFICATIONS,
    source: 'Notifications',
    errorModalTitle: 'Notifications'
  },
  [NAV_BAR_ITEM_LABELS.RESOURCES]: {
    label: NAV_BAR_ITEM_LABELS.RESOURCES,
    source: 'Resources'
  },
  [NAV_BAR_ITEM_LABELS.TASKS]: {
    label: NAV_BAR_ITEM_LABELS.TASKS,
    source: 'Tasks',
    errorModalTitle: 'Workflow Tasks'
  }
};

export const TIMELINE_CATEGORIES_TO_SKIP: string[] = [
  ...categoriesConstants.categories_skipped_on_timeline,
  documentConstants.DOCUMENT_CATEGORIES.MAINSTREAM_NEWS,
  documentConstants.DOCUMENT_CATEGORIES.NEWS,
  documentConstants.DOCUMENT_CATEGORIES.STATE_CODE,
  documentConstants.DOCUMENT_CATEGORIES.WHITEPAPER
];
