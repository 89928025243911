export const FORM_STATUSES = {
  EMPTY: 'empty',
  SUCCESS: 'success',
  ERROR: 'error'
};

export const FORM_TYPES = {
  TEAM: 'team',
  SCHEDULED_DEMO: 'demo',
  TOPICS: 'topics',
  PRO: 'requestPro',
  JURISDICTION: 'requestJurisdiction',
  AGENCY: 'requestAgency',
  NEWS_SOURCES: 'requestNewsSource',
  DEFAULT: 'default'
};

export const FORM_FIELDS = {
  EMAIL: 'email',
  COMPANY: 'company',
  TITLE: 'title',
  FEEDBACK: 'feedback'
};
