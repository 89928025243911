import type { LabelTypeSelectProps } from '../LabelTypeSelect.types';
import type {
  LabelTypeSelectData,
  LabelTypeSelectLocalState
} from './useLabelTypeSelectData.types';
import type { LabelTypeSelectHandlers } from './useLabelTypeSelectHandlers.types';
import * as labelTypeApi from 'shared/features/labelTypes/labelTypes.api';
import * as labelTypeApiHelpers from 'shared/features/labelTypes/labelTypes.api.helpers';
import * as errorUtils from 'utils/errors';

export const useLabelTypeSelectHandlers = ({
  props,
  localActions
}: {
  props: Pick<LabelTypeSelectProps, 'onChange'>;
  localActions: LabelTypeSelectData['localActions'];
}): LabelTypeSelectHandlers => {
  const handleOptionChange: LabelTypeSelectHandlers['handleOptionChange'] = value => {
    let option = value as LabelTypeSelectLocalState['selectedLabelTypeOption'];

    localActions.setSelectedLabelTypeOption(option);

    props.onChange?.(option);
  };

  const handleLabelTypesFetch: LabelTypeSelectHandlers['handleLabelTypesFetch'] = async () => {
    try {
      localActions.setIsLoading(true);

      const response = await labelTypeApi.getAllLabelTypes({
        limit: 100
      });

      return labelTypeApiHelpers.formatLabelTypeOption(response);
    } catch (e) {
      errorUtils.logError(e as Error);

      return [];
    } finally {
      localActions.setIsLoading(false);
    }
  };

  return {
    handleOptionChange,
    handleLabelTypesFetch
  };
};
