import type { DiffHandlers } from './useDiffHandlers.types';
import type { useDiffQueries } from './useDiffQueries';

import * as uiLib from '@compliance.ai/web-components';
import * as analytics from 'utils/analytics';
import * as rightPanelConstants from 'shared/features/rightPanel/rightPanel.constants';

import lodash from 'lodash';

import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';

export const useDiffHandlers = ({
  queries
}: {
  queries: ReturnType<typeof useDiffQueries>;
}): DiffHandlers => {
  const rightPanelReduxActions = useRightPanelReduxActions();

  const handleButtonClick: DiffHandlers['handleButtonClick'] = () => {
    /**
     * The oldest doc MUST have the lowest array index
     */
    const sortedDocs = lodash.orderBy(
      queries.fetchDocuments.data?.documents ?? [],
      doc => uiLib.getDateInMilliseconds(doc.publication_date),
      'asc'
    );

    const [doc1, doc2] = sortedDocs;

    analytics.safe_analytics(
      'Diffing-Doc Action',
      'Diffing',
      'Doc Action',
      `${doc1.title} & ${doc2.title}`
    );

    rightPanelReduxActions.openRightPanel({
      type: rightPanelConstants.RIGHT_PANEL_TYPES.DOCUMENT_DETAILS,
      isClosable: false,
      props: {
        documentId: doc1.id,
        shouldOpenRightPanelWithOverlay: true,
        activeTabKey: rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.DIFF,
        diffData: {
          docV1: doc1.id,
          docV2: doc2.id
        }
      }
    });
  };

  return {
    handleButtonClick
  };
};
