import {
  REQUEST_INSIGHTS_CSV,
  RECEIVE_INSIGHTS_CSV,
  REQUEST_INSIGHTS_GRAPH_DATA,
  RECEIVE_INSIGHTS_GRAPH_DATA,
  FETCH_ENFORCMENT_ACTIONS_DATA
} from './insights.actions';

export const getInitialInsightsGraphsState = () => ({
  isFetching: false,
  isReady: false,
  act_enforcement_matrix: {},
  rules_by_quarter: {},
  rules_by_agency: {}
});

export const insights_graphs = (state = getInitialInsightsGraphsState(), action) => {
  switch (action.type) {
    case REQUEST_INSIGHTS_GRAPH_DATA:
      return {
        ...state,
        isFetching: true,
        isReady: false
      };
    case RECEIVE_INSIGHTS_GRAPH_DATA: {
      const new_data = {};

      action.aggregations.forEach((agg, i) => {
        new_data[agg] = action.data[i];
      });

      return {
        ...state,
        ...new_data,
        isFetching: false,
        isReady: true
      };
    }
    default:
      return state;
  }
};

export const getInitialInsightsCSVState = () => ({
  isFetching: false,
  isReady: false,
  isUpdating: false,
  updated: false,
  insights_csv: []
});

export const insights_csv = (state = getInitialInsightsCSVState(), action) => {
  switch (action.type) {
    case REQUEST_INSIGHTS_CSV:
      return {
        ...state,
        isFetching: true,
        isReady: false,
        updated: false
      };
    case RECEIVE_INSIGHTS_CSV:
      return {
        ...state,
        isFetching: false,
        isReady: true,
        items: action.insights_csv,
        updated: false
      };
    default:
      return state;
  }
};

export const getInitialenforcementsDataState = () => ({
  enforcements: {}
});

export const enforcementsData = (state = getInitialenforcementsDataState(), action) => {
  switch (action.type) {
    case FETCH_ENFORCMENT_ACTIONS_DATA:
      return {
        ...state,
        enforcements: action.payload
      };
    default:
      return state;
  }
};
