import { useMemo } from 'react';
import { formatDependentTasks, formatTaskAssignee } from '../TaskNode.helpers';
import { useSelector } from 'react-redux';
import { getOrganizationMembers } from 'shared/features/organizations/organizations.selectors';

export const useTaskNodeData = ({ task, workflow }) => {
  const reduxState = useSelector(state => ({
    orgMembers: getOrganizationMembers(state)
  }));

  const name = task.name ? task.name : 'Unknown';

  const assignee = useMemo(
    () =>
      formatTaskAssignee({
        task,
        workflowTasks: workflow.tasks,
        orgMembers: reduxState.orgMembers
      }),
    [reduxState.orgMembers, task, workflow.tasks]
  );

  const isRelevant = Boolean(task.relevant_on_dependency_check);
  const isDependentOnAll = Boolean(task.dependent_on_all);
  const taskDateType = Object.keys(task.due_days_before_doc_date)[0];
  const taskDateDaysCount = Object.values(task.due_days_before_doc_date)[0];
  const hasDependencies = Array.isArray(task.dependencies) && Boolean(task.dependencies.length);

  const teams = useMemo(() => {
    if (Array.isArray(task.teams) && task.teams.length) {
      return task.teams.map(({ name }) => name).join(', ');
    }

    return 'n/a';
  }, [task.teams]);

  const dependentTasks = useMemo(() => {
    if (!hasDependencies) {
      return [];
    }

    return workflow.tasks.reduce(formatDependentTasks(task), []);
  }, [hasDependencies, task, workflow.tasks]);

  const formattedTask = useMemo(
    () => ({
      name: name,
      assignee: assignee,
      teams: teams,
      isRelevant: isRelevant,
      isDependentOnAll: isDependentOnAll,
      dateType: taskDateType,
      daysCount: taskDateDaysCount
    }),
    [name, assignee, teams, isRelevant, isDependentOnAll, taskDateType, taskDateDaysCount]
  );

  return {
    formattedData: {
      formattedTask,
      hasDependencies,
      dependentTasks
    }
  };
};
