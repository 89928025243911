import * as documentsApi from 'shared/features/documents/documents.apiV2';
import { QUERY_KEYS } from 'constants/QueryKeys';
import { useQuery } from '@tanstack/react-query';

export const useDocumentEditMetadataQueries = () => {
  const fetchAvailableDocumentUpdateFields = useQuery({
    queryFn: () => documentsApi.fetchAvailableDocumentUpdateFields(),
    queryKey: [QUERY_KEYS.DOCUMENT_UPDATE_FIELDS]
  });

  return {
    fetchAvailableDocumentUpdateFields
  };
};
