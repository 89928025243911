import { AddToWorkflowButton, WorkflowDetails } from 'common/index';
import * as routes from 'constants/Routes';
import { Navigate } from 'react-router-dom';
import { useWorkflowPanelData, useWorkflowPanelHandlers } from './hooks';
import './_workflow-panel.scss';

export const WorkflowPanel = ({ document }) => {
  const { localState, localActions, reduxState } = useWorkflowPanelData();

  const handlers = useWorkflowPanelHandlers();

  if (!reduxState.isAuthenticated) {
    return <Navigate to={routes.login} />;
  }

  return (
    <div className="workflow-panel">
      <AddToWorkflowButton
        documentId={document.id}
        classNames={{
          container: 'workflow-panel__add-workflow-button'
        }}
      />
      {localState.showObligationTaskMessage && (
        <h5>Obligation tasks are processing and will be available soon.</h5>
      )}
      <div className="workflow-panel__workflows-container">
        <WorkflowDetails document={document} onTabChange={handlers.handleTabChange} />
      </div>
      {reduxState.isMobileView && (
        <div className="switch-to-desktop">To edit tasks, switch to Desktop view.</div>
      )}
    </div>
  );
};
