import { useDispatch } from 'react-redux';
import { clearAutoComplete as reduxClearAutoComplete } from 'shared/features/search/search.actions';
import { updateDashboardLayout as reduxUpdateDashboardLayout } from 'shared/features/user/user.actions';
import { useCallback } from 'react';

export const useDashboardReduxActions = () => {
  const dispatch = useDispatch();

  const clearAutoComplete = useCallback(() => {
    dispatch(reduxClearAutoComplete());
  }, [dispatch]);

  const updateDashboardLayout = useCallback(
    async layout => {
      return await dispatch(reduxUpdateDashboardLayout(layout));
    },
    [dispatch]
  );

  return {
    clearAutoComplete,
    updateDashboardLayout
  };
};
