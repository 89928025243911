import type { RestrictedFieldNameProps } from '../RestrictedFieldName.types';
import type { RestrictedFieldNameHandlers } from './useRestrictedFieldNameHandlers.types';

import { useSKUs } from 'utils/hooks';
import { RESTRICTION_TYPES } from '../RestrictedFieldName.constants';
import { SKU_RESTRICTION_TYPES } from 'constants/SKUs';

export const useRestrictedFieldNameHandlers = ({
  props
}: {
  props: Pick<RestrictedFieldNameProps, 'restrictionType'>;
}): RestrictedFieldNameHandlers => {
  const { showSKURestrictModal } = useSKUs();

  const handleRestrictionIconClick: RestrictedFieldNameHandlers['handleRestrictionIconClick'] = () => {
    if (props.restrictionType === RESTRICTION_TYPES.ENFORCEMENTS) {
      showSKURestrictModal(SKU_RESTRICTION_TYPES.ENFORCEMENT_EXPLORER);
    }
  };

  return {
    handleRestrictionIconClick
  };
};
