import * as uiLib from '@compliance.ai/web-components';

export const formatRedirectUrl = uiLib.formatRedirectUrl;

export const filterNullishQueryParams = (
  queryParams: Record<string, unknown>
): Record<string, unknown> => {
  return Object.fromEntries(
    Object.entries({
      ...queryParams
    }).filter(([_, queryParamValue]) => Boolean(queryParamValue))
  );
};
