export const DISPLAY_SOURCES = {
  ALL_NEWS_SOURCES: 'allNewsSources',
  AGENCY_NEWS: 'agencyNews',
  MAINSTREAM_NEWS: 'mainstreamNews'
};
export const DISPLAY_SOURCE_LABELS = {
  ALL_NEWS_SOURCES: 'All News',
  AGENCY_NEWS: 'Agency News',
  MAINSTREAM_NEWS: 'Mainstream News'
};

export const DISPLAY_SOURCES_TABS = [
  {
    key: DISPLAY_SOURCES.ALL_NEWS_SOURCES,
    title: DISPLAY_SOURCE_LABELS.ALL_NEWS_SOURCES
  },
  {
    key: DISPLAY_SOURCES.AGENCY_NEWS,
    title: DISPLAY_SOURCE_LABELS.AGENCY_NEWS
  },
  {
    key: DISPLAY_SOURCES.MAINSTREAM_NEWS,
    title: DISPLAY_SOURCE_LABELS.MAINSTREAM_NEWS
  }
];

export const SEARCH_QUERY = 'search_query';
export const FREE_TRIAL = 'free_trial';

export const UNSUPPORTED_FILERMENU_PATHS = ['/dashboard', '/account', '/checkout'];
