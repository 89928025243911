import type * as reducerTypes from './filterGroups.reducer.types';

import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import * as actions from './filterGroups.actions';

export const INITIAL_STATE: reducerTypes.FilterGroupsState = {
  isLoading: false,
  items: []
};

export const reducer = createReducer(INITIAL_STATE, builder => {
  builder.addMatcher(isAnyOf(actions.fetchFilterGroups.pending), state => {
    state.isLoading = true;
  });

  builder.addMatcher(
    isAnyOf(actions.fetchFilterGroups.fulfilled, actions.fetchFilterGroups.rejected),
    (state, action) => {
      state.isLoading = false;

      if (actions.fetchFilterGroups.fulfilled.match(action)) {
        state.items = action.payload;
      }
    }
  );
});
