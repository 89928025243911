import type {
  JsonLogicTreeVariable,
  PlainTextFormattedData,
  SupportedAdvancedSearchKey
} from './usePlainTextData.types';
import type { ArrayItem } from '@compliance.ai/web-components';

import lodash from 'lodash';
import { DEFAULT_VALUE } from '../PlainText.constants';

export const shouldSetSelectOptions = (variable: JsonLogicTreeVariable): boolean => {
  return typeof variable[1] === 'object' && 'in' in variable[1] && Array.isArray(variable[1].in[1])
    ? variable[1].in[1].every(lodash.isObject)
    : false;
};

export const getValuesNames = ({
  variableValues,
  variableNamesMap
}: {
  variableValues: ArrayItem<PlainTextFormattedData['parsedQuery']['jsonLogicTreeVarsValues']>;
  variableNamesMap: PlainTextFormattedData['idsToNamesMaps'][keyof PlainTextFormattedData['idsToNamesMaps']];
}) => {
  if (Array.isArray(variableValues)) {
    return variableValues.map(id => variableNamesMap[id] ?? DEFAULT_VALUE);
  }

  if (typeof variableValues === 'number') {
    return [variableNamesMap[variableValues] ?? DEFAULT_VALUE];
  }

  return [DEFAULT_VALUE];
};

export const setSingleValueToConfig = ({
  jsonLogicTree,
  variablePath,
  variableKey,
  variableNames
}: {
  jsonLogicTree: PlainTextFormattedData['parsedQuery']['jsonLogicTree'];
  variablePath: string;
  variableKey: SupportedAdvancedSearchKey;
  variableNames: string[];
}): void => {
  if (jsonLogicTree) {
    lodash.set(jsonLogicTree, variablePath, [{ var: variableKey }, variableNames[0]]);
  }
};

export const setMultipleValuesToConfig = ({
  jsonLogicTree,
  variablePath,
  variableKey,
  variableNames,
  shouldConvertToSelectOptions
}: {
  jsonLogicTree: PlainTextFormattedData['parsedQuery']['jsonLogicTree'];
  variablePath: string;
  variableKey: SupportedAdvancedSearchKey;
  variableNames: string[];
  shouldConvertToSelectOptions: boolean;
}): void => {
  if (jsonLogicTree) {
    lodash.set(jsonLogicTree, variablePath, [
      { var: variableKey },
      {
        in: [
          { var: '' },
          shouldConvertToSelectOptions
            ? variableNames.map(name => ({
                label: name,
                value: name
              }))
            : variableNames
        ]
      }
    ]);
  }
};
