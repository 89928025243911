export const useDocumentsBulkExportHandlers = ({
  props,
  reduxState,
  formattedData,
  viewReduxActions
}) => {
  const handlePrepareBulkExport = () => {
    if (reduxState.isFetchingSimpleDocs) {
      return;
    }

    if (formattedData.selectedDocsCount > 0 && formattedData.hasSelectedOverUpperLimit) {
      return;
    }

    props.onExport();
  };

  const handleSelectionClear = () => {
    viewReduxActions.clearSelectedItems();
  };

  return {
    handlePrepareBulkExport,
    handleSelectionClear
  };
};
