import PropTypes from 'prop-types';
import { FORM_TYPES } from '../../Feedback.constants';

export const TEXT = {
  [FORM_TYPES.TEAM]: 'Thanks, your request has been sent.',
  [FORM_TYPES.PRO]: 'Thanks, your request has been sent.',
  [FORM_TYPES.SCHEDULED_DEMO]:
    'Thank you for your request! A member from our sales team will be in contact with you soon.',
  [FORM_TYPES.TOPICS]: 'Thanks, your feedback has been sent.',
  [FORM_TYPES.JURISDICTION]: 'Thanks, your feedback has been sent.',
  [FORM_TYPES.AGENCY]: 'Thanks, your feedback has been sent.',
  [FORM_TYPES.NEWS_SOURCES]: 'Thanks, your feedback has been sent.',
  [FORM_TYPES.DEFAULT]: 'Thanks, your feedback has been sent.'
};

export const FeedbackSuccess = ({ formType }) => {
  return (
    <form className="feedback__form">
      <p className="feedback__response-text">{TEXT[formType]}</p>
    </form>
  );
};

FeedbackSuccess.propTypes = {
  formType: PropTypes.string
};

FeedbackSuccess.defaultProps = {
  formType: FORM_TYPES.DEFAULT
};
