import type { SentenceNotesState } from './sentenceNotes.reducer.types';

import * as reduxToolkit from '@reduxjs/toolkit';
import * as constants from './sentenceNotes.constants';
import * as actions from './sentenceNotes.actions';
import lodash from 'lodash';
import * as helpers from './sentenceNotes.reducer.helpers';

export const INITIAL_STATE: SentenceNotesState = {
  sentenceNotes: [],
  expandedSentenceNotes: []
};

export const { reducer } = reduxToolkit.createSlice({
  name: constants.REDUCER_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(actions.clearSentenceNotesReducer.type, (state, action) => {
      if (actions.clearSentenceNotesReducer.match(action)) {
        state.sentenceNotes = INITIAL_STATE.sentenceNotes;
        state.expandedSentenceNotes = INITIAL_STATE.expandedSentenceNotes;
      }
    });

    builder.addCase(actions.toggleSentenceNote.type, (state, action) => {
      if (actions.toggleSentenceNote.match(action)) {
        if (action.payload.isExpanded) {
          state.expandedSentenceNotes = [...state.expandedSentenceNotes, action.payload.id];
        } else {
          state.expandedSentenceNotes = state.expandedSentenceNotes.filter(
            id => id !== action.payload.id
          );
        }
      }
    });

    builder.addCase(actions.createSentenceNote.fulfilled, (state, action) => {
      state.sentenceNotes = lodash
        .uniqWith([action.payload, ...state.sentenceNotes], helpers.uniqueBySentences)
        .sort(helpers.sortBySentences);
    });

    builder.addCase(actions.getSentenceNotes.fulfilled, (state, action) => {
      state.sentenceNotes = (action.meta.arg.options?.shouldOverwriteExistingSentenceNotes
        ? action.payload.sentenceNotes
        : lodash.uniqWith(
            [...action.payload.sentenceNotes, ...state.sentenceNotes],
            helpers.uniqueById
          )
      ).sort(helpers.sortBySentences);
    });

    builder.addCase(actions.fetchSystemGeneratedSentenceNotes.fulfilled, (state, action) => {
      state.sentenceNotes = (action.meta.arg.options?.shouldOverwriteExistingSentenceNotes
        ? action.payload.sentenceNotes
        : lodash.uniqWith(
            [...state.sentenceNotes, ...action.payload.sentenceNotes],
            helpers.uniqueBySentences
          )
      ).sort(helpers.sortBySentences);
    });

    builder.addCase(actions.modifySentenceNote.fulfilled, (state, action) => {
      state.sentenceNotes = helpers
        .replace({
          updatedSentenceNote: action.payload,
          sentenceNotes: state.sentenceNotes
        })
        .sort(helpers.sortBySentences);
    });

    builder.addCase(actions.deleteSentenceNote.fulfilled, (state, action) => {
      state.sentenceNotes = state.sentenceNotes.filter(sentenceNote => {
        return sentenceNote.id !== action.meta.arg;
      });
    });
  }
});
