import type { FC } from 'react';
import type { NotificationSwitchProps } from './NotificationSwitch.types';

import * as alertsConstants from 'shared/features/alerts/alerts.constants';
import * as permissionsConstants from 'constants/Permissions';
import * as uiLib from '@compliance.ai/web-components';
import * as restrict from 'components/Restrict';

import { SaveAlertModal } from 'components/SaveAlertModal';
import { SaveAlertSuccessModal } from 'components/SaveAlertSuccessModal';

import { useSavedSearchPermissions, usePermissions } from 'utils/hooks';
import { useNotificationSwitchData, useNotificationSwitchHandlers } from './hooks';

import './_notification-switch.scss';

export const NotificationSwitch: FC<NotificationSwitchProps> = ({ alertParams }) => {
  const { localState, localActions, reduxState, formattedData } = useNotificationSwitchData();

  const savedSearchPermissions = useSavedSearchPermissions({
    savedSearch: formattedData.savedSearch
  });

  const permissions = usePermissions();

  const handlers = useNotificationSwitchHandlers({
    props: {
      alertParams
    },
    localActions,
    formattedData
  });

  if (!permissions[permissionsConstants.EDIT_ALERTS] || reduxState.isEditingAlert) {
    return null;
  }

  return (
    <div className="notification-switch">
      {!formattedData.currentViewAlertId && (
        <uiLib.Button
          type={uiLib.BUTTON_TYPES.LINK}
          onClick={restrict.restrict(handlers.handleAlertCreate, this, 'Alerts')}
          className="notification-switch__button"
        >
          Create Alert
        </uiLib.Button>
      )}

      {formattedData.currentViewAlertId &&
        savedSearchPermissions[alertsConstants.SAVED_SEARCH_PERMISSIONS.EDIT] && (
          <uiLib.Button
            type={uiLib.BUTTON_TYPES.LINK}
            onClick={handlers.handleAlertEdit}
            className="notification-switch__button"
          >
            Edit Alert
          </uiLib.Button>
        )}

      {localState.isSaveAlertModalOpen && (
        <SaveAlertModal
          createBtnTitle="Create"
          modalTitle="Create an alert"
          saveAlertModalOpen={localState.isSaveAlertModalOpen}
          renderErrors={() => null}
          saveAlert={handlers.handleAlertSave}
          closeModal={handlers.handleModalClose}
        />
      )}
      <SaveAlertSuccessModal />
    </div>
  );
};
