import * as uiLib from '@compliance.ai/web-components';

import * as helpers from '../AutocompleteSearchField.helpers';
import * as organizationSelectors from 'shared/features/organizations/organizations.selectors';
import * as filterSelectors from 'shared/features/filters/filters.selectors';
import * as actionBarConstants from 'constants/ActionBar';
import * as config from 'shared/config';
import * as routes from 'constants/Routes';

import { FILTER_KEY as ACTION_BAR_FILTER_KEY } from 'constants/ActionBarFilter';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'utils/hooks';

export const useAutocompleteSearchFieldData = () => {
  const appNavigation = uiLib.useAppNavigation();
  const history = useHistory();

  const reduxState = useSelector(state => ({
    autocompletes: state.autocompletes,
    filtered_mention: state.filtered_mention,
    current_view: state.current_view,
    current_user: state.current_user,
    filters: state.filters,
    filterParams: state.filters.primaryFilter.filterParams,
    isFilterReady: state.filters.primaryFilter.isReady,
    organizationLabels: organizationSelectors.getOrganizationLabels(state),
    languages: state.languages,
    errors: state.errors,
    actionBarFilterValues: {
      [ACTION_BAR_FILTER_KEY.ROWS_COUNT]: filterSelectors.getActionBarFilterParamValue(
        ACTION_BAR_FILTER_KEY.ROWS_COUNT,
        actionBarConstants.DEFAULT_LIMIT
      )(state)
    }
  }));

  const [autocompletes, changeAutocompletes] = useState([]);
  const [isSuggestionsMenuVisible, toggleSuggestionsMenuVisibility] = useState(false);
  const [searchTermTooltipTrigger, toggleSearchTermTooltipTrigger] = useState(false);
  const [searchTerm, changeSearchTerm] = useState(
    helpers.getInitialSearchTerm({ history, reduxState })
  );
  const [currentQuery, changeCurrentQuery] = useState(
    helpers.parseQueryParams(history.location.search)
  );
  const [currentView, changeCurrentView] = useState(
    config.shouldShowNewSearch
      ? [routes.content, routes.news, routes.timeline].includes(location.pathname) &&
          location.pathname.slice(1)
      : helpers.parseQueryParams(history.location.search).view
  );

  const formattedData = useMemo(
    () => ({
      placeholder: appNavigation.isTopBarVisible ? 'Search' : '',
      parsedQuery: helpers.parseQueryParams(history.location.search)
    }),
    [appNavigation.isTopBarVisible, history.location.search]
  );

  return {
    history,
    localState: {
      autocompletes,
      searchTerm,
      currentQuery,
      currentView,
      isSuggestionsMenuVisible,
      searchTermTooltipTrigger
    },
    localActions: {
      changeAutocompletes,
      changeSearchTerm,
      changeCurrentQuery,
      changeCurrentView,
      toggleSuggestionsMenuVisibility,
      toggleSearchTermTooltipTrigger
    },
    reduxState,
    formattedData
  };
};
