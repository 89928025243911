import { api_getPremiumContentSources } from './premiumContent.api';

export const GET_PREMIUM_CONTENT_SOURCES = 'GET_PREMIUM_CONTENT_SOURCES';

export const fetchPremiumContentSources = () => async dispatch => {
  try {
    const res = await api_getPremiumContentSources();
    dispatch({ type: GET_PREMIUM_CONTENT_SOURCES, payload: res.premium_content_sources });
  } catch (error) {
    // pass
  }
};
