import type { WorkflowDiagramModalData } from './useWorkflowDiagramModalData.types';
import type { WorkflowDiagramModalLocalState } from './useWorkflowDiagramModalData.types';
import type { WorkflowDiagramModalProps } from '../WorkflowDiagramModalProps.types';

import { useMemo, useState } from 'react';

export const useWorkflowDiagramModalData = (
  props: Pick<WorkflowDiagramModalProps, 'workflow' | 'workflowId'>
): WorkflowDiagramModalData => {
  const [isLoading, setIsLoading] = useState<WorkflowDiagramModalLocalState['isLoading']>(false);
  const [workflow, setWorkflow] = useState<WorkflowDiagramModalLocalState['workflow']>(
    props.workflow ? props.workflow : null
  );

  const localState: WorkflowDiagramModalData['localState'] = {
    isLoading,
    workflow
  };

  const localActions: WorkflowDiagramModalData['localActions'] = useMemo(
    () => ({
      setIsLoading,
      setWorkflow
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
