import type { FC } from 'react';
import type { ExpandButtonProps } from './ExpandButton.types';

import * as uiLib from '@compliance.ai/web-components';

import './_expand-button.scss';

export const ExpandButton: FC<ExpandButtonProps> = ({ isExpanded, isDisabled, onClick }) => {
  return (
    <uiLib.ActionButton
      type={uiLib.ACTION_BUTTON_TYPE.TRANSPARENT_CONTRAST}
      isDisabled={isDisabled}
      onClick={onClick}
    >
      {isExpanded ? (
        <uiLib.IconMenu />
      ) : (
        <uiLib.IconMenu className="sentence-note-expand-button__icon--is-collapsed" />
      )}
    </uiLib.ActionButton>
  );
};
