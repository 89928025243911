import type { TaskCommentsHandlers } from './useTaskCommentsHandlers.types';
import type { TaskCommentsAnnotationProps } from '../TaskCommentsAnnotation.types';

export const useTaskCommentsHandlers = ({
  props
}: {
  props: Pick<
    TaskCommentsAnnotationProps,
    | 'documentTask'
    | 'onCommentThreadCreate'
    | 'onCommentCreate'
    | 'onCommentEdit'
    | 'onCommentDelete'
  >;
}): TaskCommentsHandlers => {
  const handleCommentThreadCreation: TaskCommentsHandlers['handleCommentThreadCreation'] = richtext => {
    props.onCommentThreadCreate([
      {
        richtext,
        doc_task_id: props.documentTask.id
      }
    ]);
  };

  const handleCommentEdit: TaskCommentsHandlers['handleCommentEdit'] = ({
    commentId,
    richtext
  }) => {
    props.onCommentEdit([
      commentId,
      {
        richtext
      }
    ]);
  };

  const handleCommentCreation: TaskCommentsHandlers['handleCommentCreation'] = ({
    commentThreadId,
    richtext
  }) => {
    props.onCommentCreate([
      {
        richtext,
        comment_thread_id: commentThreadId
      }
    ]);
  };

  const handleCommentDeletion: TaskCommentsHandlers['handleCommentDeletion'] = comment => {
    props.onCommentDelete([comment.id]);
  };

  return {
    handleCommentThreadCreation,
    handleCommentEdit,
    handleCommentCreation,
    handleCommentDeletion
  };
};
