import type { FC } from 'react';
import type { ObligationPanelProps } from './ObligationsPanel.types';

import * as common from 'common/index';
import * as sentenceNotesConstants from 'shared/features/sentenceNotes/sentenceNotes.constants';

export const ObligationsPanel: FC<ObligationPanelProps> = ({ document }) => {
  return (
    <common.AnnotationPanel
      type={sentenceNotesConstants.SENTENCE_NOTE_TYPE.OBLIGATIONS}
      document={document}
    />
  );
};
