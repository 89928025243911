import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserAuthenticatedState } from 'shared/features/auth/auth.selectors';
import { PLAN_LITE_TEAMS, PLANS, PLAN_PRO, PLAN_TEAMS } from 'constants/Plans';
import { getLatestSubscription } from 'shared/features/subscriptions/subscriptions.selectors';
import * as routes from 'constants/Routes';

export const usePlans = ({ mandatoryPlans, blacklistedPlans } = {}) => {
  const navigate = useNavigate();

  const reduxState = useSelector(state => ({
    isAuthenticated: getUserAuthenticatedState(state),
    latestSubscription: getLatestSubscription(state)
  }));

  const userPlans = reduxState.isAuthenticated
    ? {
        [PLANS.PRO]: PLAN_PRO.includes(reduxState.latestSubscription),
        [PLANS.TEAMS]: PLAN_TEAMS.includes(reduxState.latestSubscription),
        [PLANS.SERVICE_CONNECTOR]: PLAN_LITE_TEAMS.includes(reduxState.latestSubscription)
      }
    : Object.values(PLANS).reduce(
        (plans, planKey) => ({
          ...plans,
          [planKey]: false
        }),
        {}
      );

  if (mandatoryPlans) {
    for (const plan of mandatoryPlans) {
      if (!userPlans[plan]) {
        navigate(routes.dashboard, { replace: true });
        break;
      }
    }
  }

  if (blacklistedPlans) {
    for (const plan of blacklistedPlans) {
      if (userPlans[plan]) {
        navigate(routes.dashboard, { replace: true });
        break;
      }
    }
  }

  return userPlans;
};
