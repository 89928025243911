import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { ErrorBoundary } from '@compliance.ai/web-components';
import { FINANCE_REGULATORY, INSIGHTS } from 'constants/UserDashboardTypes';
import { DRAGGABLE_WIDGET_CLASSNAME } from 'constants/DashboardConstants';
import { GRID_BREAKPOINTS } from './Dashboard.constants';
import PropTypes from 'prop-types';
import { DashboardOptionsDropdown } from './elements';
import {
  useDashboardData,
  useDashboardLifecycle,
  useDashboardReduxActions,
  useDashboardLayoutHandlers
} from './hooks';

const ResponsiveGridLayout = WidthProvider(Responsive);

const Dashboard = React.memo(
  ({
    title,
    defaultLayout,
    defaultComponents,
    dashboardType,
    layoutComponentMapping,
    componentDimensions,
    gridCols,
    gridRowHeight
  }) => {
    const { reduxState, formattedData, localState, localActions } = useDashboardData({
      layoutComponentMapping,
      componentDimensions
    });

    const reduxActions = useDashboardReduxActions();

    const {
      handleSelectedComponentsChange,
      handleLayoutChange,
      handleDragStop,
      handleComponentsSelect
    } = useDashboardLayoutHandlers({
      props: {
        dashboardType,
        layoutComponentMapping,
        componentDimensions
      },
      localState,
      localActions,
      formattedData,
      reduxActions
    });

    useDashboardLifecycle({
      props: {
        defaultLayout,
        defaultComponents,
        dashboardType
      },
      layoutHandlers: {
        handleComponentsSelect
      },
      localState,
      localActions,
      reduxState,
      reduxActions
    });

    return (
      <div
        className="newDashboardContainer container-fluid loading-overlay-light"
        data-testid="dashboard-default-container"
      >
        <div className="dash-header">
          {title && (
            <div className="greeting">
              <h1>{title}</h1>
            </div>
          )}
          {!localState.isLoading && (
            <DashboardOptionsDropdown
              onChange={handleSelectedComponentsChange}
              selectionOptionValues={localState.components}
              allOptions={formattedData.sortedSelectedLayoutComponents}
            />
          )}
        </div>
        {localState.isLoading ? (
          <div>loading...</div>
        ) : (
          <ErrorBoundary>
            <ResponsiveGridLayout
              className="layout dash-grid"
              layouts={localState.layout}
              breakpoints={GRID_BREAKPOINTS}
              cols={gridCols}
              rowHeight={gridRowHeight}
              isResizable={false}
              measureBeforeMount
              useCSSTransforms={false}
              draggableHandle={`.${DRAGGABLE_WIDGET_CLASSNAME}`}
              onDragStop={handleDragStop}
              onLayoutChange={handleLayoutChange}
            >
              {formattedData.gridElements}
            </ResponsiveGridLayout>
          </ErrorBoundary>
        )}
      </div>
    );
  }
);

export default Dashboard;

const layoutPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    h: PropTypes.number.isRequired,
    w: PropTypes.number.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    i: PropTypes.string.isRequired
  })
).isRequired;

Dashboard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  defaultComponents: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultLayout: PropTypes.shape({
    lg: layoutPropTypes,
    sm: layoutPropTypes
  }).isRequired,
  componentDimensions: PropTypes.shape({}).isRequired,
  layoutComponentMapping: PropTypes.shape({}).isRequired,
  dashboardType: PropTypes.oneOf([FINANCE_REGULATORY, INSIGHTS]).isRequired,
  gridCols: PropTypes.shape({
    lg: PropTypes.number.isRequired,
    sm: PropTypes.number.isRequired
  }),
  gridRowHeight: PropTypes.number
};

Dashboard.defaultProps = {
  gridCols: { lg: 3, sm: 1 },
  gridRowHeight: 320
};
