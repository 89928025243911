import { createSelector } from '@reduxjs/toolkit';
import lodash from 'lodash';
import { alphabetically } from 'utils/sort';
import {
  isCountryJurisdiction,
  isNotCountryJurisdiction,
  toOption
} from 'utils/jurisdiction-utils';
import { SUBREGION_CATEGORIES, SUBREGION_MAPPING } from './jurisdictions.constants';

/**
 *
 * Jurisdictions reducer selectors
 */
export const getJurisdictionsReducer = state => state.jurisdictions;

/**
 *
 * Regular jurisdictions selectors
 */
export const getJurisdictionsItems = createSelector(
  getJurisdictionsReducer,
  jurisdictionsReducer => {
    return jurisdictionsReducer.items;
  }
);
export const getJurisdictionsItemsObj = createSelector(getJurisdictionsItems, jurisdictions => {
  return jurisdictions.reduce((obj, jurisdiction) => {
    obj[jurisdiction.id] = jurisdiction;
    return obj;
  }, {});
});
export const getJurisdictionShortNamesToIdsMap = createSelector(
  getJurisdictionsItems,
  jurisdictions => {
    return jurisdictions.reduce((jurisdictionNamesMap, jurisdiction) => {
      return {
        ...jurisdictionNamesMap,
        [jurisdiction.short_name]: jurisdiction.id
      };
    }, {});
  }
);
export const getJurisdictionIdsToShortNamesMap = createSelector(
  getJurisdictionsItems,
  jurisdictions => {
    return jurisdictions.reduce((jurisdictionNamesMap, jurisdiction) => {
      return {
        ...jurisdictionNamesMap,
        [jurisdiction.id]: jurisdiction.short_name
      };
    }, {});
  }
);
export const getJurisdictionsReadyStatus = state => state.jurisdictions.isReady;
export const filterAndSortCountries = jurisdictions => {
  return jurisdictions.filter(isCountryJurisdiction).sort(alphabetically);
};
export const getJurisdictionShortnames = createSelector(getJurisdictionsItems, jurisdictions => {
  return jurisdictions.map(({ short_name }) => short_name);
});
export const getAllCountries = createSelector(getJurisdictionsItems, filterAndSortCountries);
export const getAllCountriesOptions = createSelector(getAllCountries, countries => {
  return countries.map(toOption);
});
export const getAllJurisdictionsExceptCountries = createSelector(
  getJurisdictionsItems,
  jurisdictions => {
    return jurisdictions.filter(isNotCountryJurisdiction).sort(alphabetically);
  }
);

export const getAllJurisdictions = createSelector(getJurisdictionsItems, jurisdictions => {
  return [...jurisdictions].sort(alphabetically);
});

export const getAllJurisdictionsOptions = createSelector(getAllJurisdictions, jurisdictions => {
  return jurisdictions
    .filter(jurisdiction => jurisdiction.short_name && jurisdiction.name)
    .map(jurisdiction => {
      const jurisdictionLabel = `${jurisdiction.name} (${jurisdiction.short_name})`;
      return {
        id: jurisdiction.id,
        shortName: jurisdiction.short_name,
        label: jurisdictionLabel,
        value: jurisdiction.id
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
});

export const getJurisdictionsOptionsByKey = createSelector(
  [getAllJurisdictionsOptions, (state, valueKey) => valueKey],
  (options, valueKey) => {
    return options.map(option => {
      return {
        ...option,
        value: option[valueKey]
      };
    });
  }
);

export const getCountry = countryId =>
  createSelector(getJurisdictionsItemsObj, countriesObj => {
    return countriesObj[countryId];
  });

export const getSubregions = countryId =>
  createSelector(getJurisdictionsItems, jurisdictions => {
    return jurisdictions
      .filter(jurisdiction => {
        const existingSubregionConfig = SUBREGION_MAPPING[countryId];

        if (existingSubregionConfig) {
          return (
            jurisdiction.category === existingSubregionConfig.subregionsCategoryKey &&
            jurisdiction.parent_id === countryId
          );
        }

        return (
          SUBREGION_CATEGORIES.includes(jurisdiction.category) &&
          jurisdiction.parent_id === countryId
        );
      })
      .sort(alphabetically);
  });

export const getSubregionsMappedByCountry = createSelector(
  getAllJurisdictionsExceptCountries,
  subregions => {
    return lodash.groupBy(subregions, 'parent_id');
  }
);

export const getSubregionsIds = countryId =>
  createSelector(getSubregions(countryId), subregions => {
    return subregions.map(({ id }) => id);
  });
