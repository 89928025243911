import type { LabelInputProps } from './LabelInput.types';
import type { FC } from 'react';

import * as constants from './LabelInput.constants';
import * as elements from './elements';
import * as uiLib from '@compliance.ai/web-components';

import { CreateLabelModal } from '../CreateLabelModal';

import classnames from 'classnames';

import { useLabelInputData, useLabelInputHandlers } from './hooks';

import './_label-input.scss';

export const LabelInput: FC<LabelInputProps> = ({
  labels,
  createNewLabel,
  removeLabel,
  isLoading = false,
  isReadOnly = false,
  shouldRenderTitle = false,
  labelType = '',
  classNames
}) => {
  const { localState, localActions, formattedData } = useLabelInputData({
    props: {
      labels,
      isLoading
    }
  });

  const handlers = useLabelInputHandlers({
    props: {
      labels,
      createNewLabel,
      removeLabel
    },
    localActions
  });

  return (
    <div
      data-testid={constants.TEST_IDS.CONTAINER}
      className={classnames(classNames?.container, 'label-input')}
    >
      {shouldRenderTitle && <div className="label-input-title">{`${labelType} Labels`}</div>}
      <div className="label-input-content-wrapper">
        <div className="label-input-content">
          {formattedData.labels.map(({ label, value, bucket, isEditable }) => (
            <span key={value} data-testid={constants.TEST_IDS.LABEL} className="label-input-tag">
              <uiLib.Tooltip
                classNames={{
                  tooltip: 'label-input-content-tooltip'
                }}
                type={uiLib.TOOLTIP_TYPES.SECONDARY}
                title={<elements.LabelTooltipTitle bucketName={bucket.name} />}
              >
                {label}
              </uiLib.Tooltip>
              {!isReadOnly && isEditable && (
                <uiLib.IconClose
                  data-testid={constants.TEST_IDS.CLOSE_ICON}
                  onClick={handlers.handleLabelRemove(value)}
                />
              )}
            </span>
          ))}
          {!localState.isCreating && !isReadOnly && (
            <span
              data-testid={constants.TEST_IDS.ADD_BUTTON}
              className="label-input-create-new"
              onClick={() => localActions.setIsCreating(true)}
            >
              {`+ Add ${labelType} Label`}
            </span>
          )}
          {!localState.isCreating && isLoading && (
            <uiLib.Loader
              size={uiLib.LOADER_SIZES.SMALL}
              isContrast
              classNames={{
                innerContainer: 'label-input__loader'
              }}
            />
          )}
        </div>
        {localState.isCreating && !isReadOnly && (
          <span data-testid={constants.TEST_IDS.SELECT} className="label-input-create-wrapper">
            <uiLib.GroupSelect
              type={uiLib.SELECT_TYPES.DEFAULT}
              isOpen={localState.isGroupSelectOpened}
              onOpen={handlers.handleGroupSelectToggle(true)}
              onClose={handlers.handleGroupSelectToggle(false)}
              onCreateOption={handlers.handleCreateModalOpen}
              onChange={handlers.handleSubmit}
              isLoading={isLoading}
              inputValue={localState.searchValue}
              onInputChange={handlers.handleInputChange}
              loadOptions={handlers.handleLabelsFetch}
              shouldCacheFetchedOptions
              isGroupSelectable={false}
              isClearable={false}
              isMulti={false}
              shouldFetchDefaultOptions
              isCreatable
              isAsync
              shouldKeepInputValueOnOptionClick
              shouldKeepInputValue
            />
            <uiLib.IconClose onClick={handlers.handleClose} />
          </span>
        )}
        {localState.isCreateModalOpened && (
          <CreateLabelModal
            onClose={handlers.handleCreateModalClose}
            initialLabelName={localState.searchValue}
            onLabelCreate={handlers.handleSubmit}
          />
        )}
      </div>
    </div>
  );
};
