import type { AuthorData } from './useAuthorData.types';
import type { AuthorProps } from '../Author.types';

import { useMemo } from 'react';
import { getAuthors } from './useAuthorData.helpers';

export const useAuthorData = ({ document }: Pick<AuthorProps, 'document'>): AuthorData => {
  const formattedData: AuthorData['formattedData'] = useMemo(() => {
    const authors = getAuthors({
      document: document
    });

    return {
      authors
    };
  }, [document]);

  return {
    formattedData
  };
};
