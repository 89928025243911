import * as apiTypes from './authors.api.types';

import { fetch } from 'fetch';

export const fetchAuthors: apiTypes.FetchAuthors = () => {
  return fetch({
    url: '/authors',
    method: 'GET'
  });
};
