export const useEmailConfirmationHandlers = ({ props, localState }) => {
  const handleSubmit = () => {
    props.onSubmit({
      verificationCode: localState.verificationCode,
      newPassword: localState.newPassword,
      confirmationPassword: localState.confirmationPassword
    });
  };

  return {
    handleSubmit
  };
};
