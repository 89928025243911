import type { FC } from 'react';
import type { FilterLayoutProps } from './FilterLayout.types';

import classnames from 'classnames';

import './_filter-layout.scss';

export const TEST_ID = 'FilterLayout';

const FilterLayout: FC<FilterLayoutProps> = ({ className, children }) => {
  return (
    <div data-testid={TEST_ID} className={classnames(className, 'filter-layout')}>
      {children}
    </div>
  );
};

export default FilterLayout;
