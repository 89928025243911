import PropTypes from 'prop-types';
import { TextField, Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import { FORM_FIELDS, FORM_TYPES } from '../../Feedback.constants';

export const PLACEHOLDER_TEXT = {
  [FORM_TYPES.TEAM]: 'Describe your issue or share your ideas',
  [FORM_TYPES.PRO]: 'Describe your issue or share your ideas',
  [FORM_TYPES.SCHEDULED_DEMO]: 'Describe your issue or share your ideas',
  [FORM_TYPES.TOPICS]: 'Describe your issue or share your ideas',
  [FORM_TYPES.JURISDICTION]: "Type jurisdiction's name",
  [FORM_TYPES.AGENCY]: 'Describe your issue or share your ideas',
  [FORM_TYPES.NEWS_SOURCES]: 'Describe your issue or share your ideas',
  [FORM_TYPES.DEFAULT]: 'Describe your issue or share your ideas'
};

export const LABELS = {
  [FORM_TYPES.TEAM]: 'Request more information on Team Edition',
  [FORM_TYPES.SCHEDULED_DEMO]: 'Schedule Demo',
  [FORM_TYPES.TOPICS]: 'Send Us Topics Beta Feedback',
  [FORM_TYPES.PRO]: 'Request more information on Pro Edition',
  [FORM_TYPES.JURISDICTION]: null,
  [FORM_TYPES.AGENCY]: 'What agency do you wish to see?',
  [FORM_TYPES.NEWS_SOURCES]: 'Request more information on Pro Edition',
  [FORM_TYPES.DEFAULT]: 'Give feedback about your Compliance.ai experience'
};

export const INSTRUCTIONS = {
  [FORM_TYPES.TEAM]: 'Tell us about your inquiry.',
  [FORM_TYPES.SCHEDULED_DEMO]: null,
  [FORM_TYPES.TOPICS]:
    'Tell us about your experience with Compliance.ai Topics. What else would you like to see?',
  [FORM_TYPES.PRO]: 'Tell us about your inquiry.',
  [FORM_TYPES.JURISDICTION]: null,
  [FORM_TYPES.AGENCY]: null,
  [FORM_TYPES.NEWS_SOURCES]: 'Let us know what news sources you would like to see.',
  [FORM_TYPES.DEFAULT]: null
};

export const FeedbackEmpty = ({
  formType,
  onSubmit,
  onCancel,
  shouldShowCancelButton,
  shouldShowEmailInput,
  fieldValues,
  onFieldChange
}) => {
  return (
    <form className="feedback__form" onSubmit={onSubmit}>
      {LABELS[formType] && (
        <h3 className="feedback__header">
          <b>{LABELS[formType]}</b>
        </h3>
      )}
      {shouldShowEmailInput && (
        <TextField
          placeholder="Enter your email"
          value={fieldValues[FORM_FIELDS.EMAIL]}
          onChange={onFieldChange(FORM_FIELDS.EMAIL)}
          className="feedback__input"
        />
      )}
      {INSTRUCTIONS[formType] && <p>{INSTRUCTIONS[formType]}</p>}
      {formType === FORM_TYPES.SCHEDULED_DEMO && (
        <>
          <TextField
            placeholder="Company"
            value={fieldValues[FORM_FIELDS.COMPANY]}
            onChange={onFieldChange(FORM_FIELDS.COMPANY)}
            className="feedback__input"
          />
          <TextField
            placeholder="Title"
            value={fieldValues[FORM_FIELDS.TITLE]}
            onChange={onFieldChange(FORM_FIELDS.TITLE)}
            className="feedback__input"
          />
        </>
      )}
      {formType !== FORM_TYPES.SCHEDULED_DEMO && (
        <textarea
          placeholder={PLACEHOLDER_TEXT[formType]}
          value={fieldValues[FORM_FIELDS.FEEDBACK]}
          onChange={onFieldChange(FORM_FIELDS.FEEDBACK)}
          required
          className="feedback__textarea"
        />
      )}
      <div className="feedback__buttons-wrapper">
        {shouldShowCancelButton && (
          <Button type={BUTTON_TYPES.LINK} isBold onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button type={BUTTON_TYPES.PRIMARY} onClick={onSubmit}>
          Submit
        </Button>
      </div>
    </form>
  );
};

FeedbackEmpty.propTypes = {
  refs: PropTypes.shape({
    feedbackRef: PropTypes.shape({
      current: PropTypes.string
    })
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  shouldShowCancelButton: PropTypes.bool,
  shouldShowEmailInput: PropTypes.bool,
  formType: PropTypes.string,
  email: PropTypes.string,
  company: PropTypes.string,
  title: PropTypes.string,
  onEmailChange: PropTypes.func,
  onCompanyChange: PropTypes.func,
  onTitleChange: PropTypes.func
};

FeedbackEmpty.defaultProps = {
  refs: {
    emailInputRef: { current: null },
    companyInputRef: { current: null },
    jobTitleRef: { current: null },
    feedbackRef: { current: null }
  },
  formType: FORM_TYPES.DEFAULT,
  shouldShowCancelButton: true,
  shouldShowEmailInput: false,
  email: '',
  company: '',
  title: '',
  onEmailChange: undefined,
  onCompanyChange: undefined,
  onTitleChange: undefined
};
