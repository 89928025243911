import type { DocumentsTableData, DocumentsTableLocalState } from './useDocumentsTableData.types';
import type { DocumentsTableProps } from '../DocumentsTable.types';
import type { GlobalState } from 'shared/reducers';

import * as uiLib from '@compliance.ai/web-components';
import * as authSelectors from 'shared/features/auth/auth.selectors';
import * as userSelectors from 'shared/features/user/user.selectors';
import * as helpers from './useDocumentsTableData.helpers';
import * as constants from '../DocumentsTable.constants';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDeepCompareMemoize, useSKUs } from 'utils/hooks';
import { usePreviousValue } from '@compliance.ai/web-components';

export const useDocumentsTableData = ({
  requestParams,
  externalTableParams,
  tableSettingsKey,
  shouldUseSavedTableSettings,
  rowsCountOptions,
  shouldUseCustomOffsets
}: Pick<
  DocumentsTableProps,
  | 'requestParams'
  | 'tableSettingsKey'
  | 'shouldUseSavedTableSettings'
  | 'rowsCountOptions'
  | 'externalTableParams'
  | 'shouldUseCustomOffsets'
>): DocumentsTableData => {
  const skus = useSKUs();

  const reduxState = useSelector<GlobalState, DocumentsTableData['reduxState']>(state => ({
    isAuthenticated: authSelectors.getUserAuthenticatedState(state),
    currentUserTableSettings: userSelectors.getUserDocTableSettings(tableSettingsKey as string)(
      state as never
    ) as DocumentsTableData['reduxState']['currentUserTableSettings'],
    areCurrentUserTableSettingsReady: userSelectors.getUserPreferencesReadyFlag(state)
  }));

  const queryParams = uiLib.useQueryParams();

  const [shouldSkipRefetchLoading, setShouldSkipRefetchLoading] = useState<
    DocumentsTableLocalState['shouldSkipRefetchLoading']
  >(shouldUseCustomOffsets || false);
  const [isInitialRequest, setIsInitialRequest] = useState<
    DocumentsTableLocalState['isInitialRequest']
  >(shouldUseCustomOffsets || false);
  const [selectedDocsIds, setSelectedDocsIds] = useState<
    DocumentsTableLocalState['selectedDocsIds']
  >([]);
  const [allDocumentsCount, setAllDocumentsCount] = useState<
    DocumentsTableLocalState['allDocumentsCount']
  >(0);
  const [isLoading, setIsLoading] = useState<DocumentsTableLocalState['isLoading']>(false);
  const [tableRefreshTrigger, setTableRefreshTrigger] = useState<
    DocumentsTableLocalState['tableRefreshTrigger']
  >(false);
  const [tableDeselectTrigger, setTableDeselectTrigger] = useState<
    DocumentsTableLocalState['tableDeselectTrigger']
  >(false);
  const [limitToDisplay, setLimitToDisplay] = useState<DocumentsTableLocalState['limitToDisplay']>(
    null
  );

  const [sort, setSort] = useState<DocumentsTableLocalState['sort']>(
    helpers.getInitialSort(queryParams)
  );

  const [customOffsets, setCustomOffsets] = useState<DocumentsTableLocalState['customOffsets']>(
    null
  );

  const [visibleDocFields, setVisibleDocFields] = useState<
    DocumentsTableLocalState['visibleDocFields']
  >(Object.values(uiLib.DOCUMENT_TOGGLEABLE_FIELD));

  const [defaultOffset, setDefaultOffset] = useState<DocumentsTableLocalState['defaultOffset']>(
    null
  );

  const [tableParams, setTableParams] = useState<DocumentsTableLocalState['tableParams']>(
    helpers.getInitialTableParams({
      shouldUseSavedTableSettings: shouldUseSavedTableSettings,
      currentUserTableSettings: reduxState.currentUserTableSettings,
      rowsCountOptions: rowsCountOptions
    })
  );

  const localState: DocumentsTableData['localState'] = {
    selectedDocsIds,
    allDocumentsCount,
    isLoading,
    tableRefreshTrigger,
    defaultOffset,
    tableParams,
    visibleDocFields,
    customOffsets,
    limitToDisplay,
    sort,
    tableDeselectTrigger,
    shouldSkipRefetchLoading,
    isInitialRequest
  };

  const localActions: DocumentsTableData['localActions'] = useMemo(
    () => ({
      setSelectedDocsIds,
      setAllDocumentsCount,
      setIsLoading,
      setTableRefreshTrigger,
      setDefaultOffset,
      setTableParams,
      setVisibleDocFields,
      setCustomOffsets,
      setLimitToDisplay,
      setSort,
      setTableDeselectTrigger,
      setShouldSkipRefetchLoading,
      setIsInitialRequest
    }),
    []
  );

  const prevDocumentsRequestParams = usePreviousValue(requestParams);

  const prevProps: DocumentsTableData['prevProps'] = {
    requestParams: prevDocumentsRequestParams
  };

  const formattedData: DocumentsTableData['formattedData'] = useMemo(() => {
    return {
      externalParams: {
        [uiLib.TABLE_PARAMS.LIMIT]: localState.tableParams[uiLib.TABLE_PARAMS.LIMIT],
        [uiLib.TABLE_PARAMS.OFFSET]: localState.tableParams[uiLib.TABLE_PARAMS.OFFSET],
        [uiLib.EXTERNAL_TABLE_PARAMS.TRIGGER_DESELECT_ALL]: localState.tableDeselectTrigger,
        ...externalTableParams
      },
      columns: constants.COLUMNS(skus)
    };
    //eslint-disable-next-line
  }, useDeepCompareMemoize([externalTableParams, localState.tableParams, localState.tableDeselectTrigger, skus]));

  return {
    prevProps,
    localState,
    localActions,
    formattedData,
    reduxState
  };
};
