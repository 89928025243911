import { fetch } from 'utils/api';
import objectHelpers from 'utils/object-helpers';

export const authCognitoToken = data => {
  const url = '/auth_cognito_token';
  return fetch({
    url,
    method: 'POST',
    params: objectHelpers.convertObjectToFormData(data)
  });
};

export const getCognitoHostedUiURL = params => {
  const url = '/AuthN_strategy';
  return fetch({
    url,
    method: 'GET',
    params
  });
};
