import {
  DropdownIndicator,
  MultiValueRemove,
  ClearIndicator,
  Option,
  MultiValueLabel
} from '../elements';

export const useSelectV2CustomElements = ({
  withCheckboxes,
  withTooltips,
  tooltipTitleRenderer,
  customComponents
}) => {
  return {
    DropdownIndicator,
    MultiValueRemove,
    ClearIndicator,
    MultiValueLabel: optionProps => (
      <MultiValueLabel
        withTooltips={withTooltips}
        tooltipTitleRenderer={tooltipTitleRenderer}
        {...optionProps}
      />
    ),
    Option: optionProps => (
      <Option
        withCheckboxes={withCheckboxes}
        withTooltips={withTooltips}
        tooltipTitleRenderer={tooltipTitleRenderer}
        {...optionProps}
      />
    ),
    ...customComponents
  };
};
