import type { FC } from 'react';
import type { DocumentLabelsProps } from './DocumentLabels.types';

import * as common from 'common/index';
import * as permissionConstants from 'constants/Permissions';

import {
  useDocumentLabelsData,
  useDocumentLabelsMutations,
  useDocumentLabelsQueries
} from './hooks';
import { usePermissions } from 'utils/hooks';

import './_document-labels.scss';

export const DocumentLabels: FC<DocumentLabelsProps> = ({ docId }) => {
  const permissions = usePermissions();

  const { reduxState } = useDocumentLabelsData();

  const queries = useDocumentLabelsQueries({
    props: {
      docId
    },
    reduxState
  });

  const mutations = useDocumentLabelsMutations({
    props: {
      docId
    },
    queries
  });

  if (!reduxState.isAuthenticated) {
    return null;
  }

  return (
    <div className="document-labels__container">
      <common.LabelInput
        labels={queries.fetchDocumentNote.data?.labels ?? []}
        removeLabel={mutations.removeLabel.mutate}
        isReadOnly={!permissions[permissionConstants.CREATE_LABELS]}
        createNewLabel={mutations.createLabel.mutate}
        labelType="Document"
        classNames={{
          container: 'document-labels__input-container'
        }}
      />
    </div>
  );
};
