export const useSelectV2Handlers = ({ props, localActions }) => {
  const handleOnChange = changedValue => {
    if (props.isMulti) {
      // If select is multi - always return an array. Some of the components depend on this.
      changedValue ? props.onChange(changedValue) : props.onChange([]);
    } else {
      props.onChange(changedValue);
    }
  };

  const handleOnInputChange = (value, action) => {
    if (props.isMulti && !props.closeMenuOnSelect && action.action === 'input-change') {
      // only set the input when the action that caused the
      // change equals to "input-change" and ignore the other
      // ones like: "set-value", "input-blur", and "menu-close"
      localActions.setInput(value);
    } else if (props.onInputChange) {
      props.onInputChange(value, action);
    }
  };

  const handleOnMenuClose = () => {
    localActions.setInput(undefined);

    if (props.onMenuClose) {
      props.onMenuClose();
    }
  };

  return {
    handleOnChange,
    handleOnInputChange,
    handleOnMenuClose
  };
};
