import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';

import { useDocumentsReduxActions } from 'shared/features/documents/hooks';
import { useFoldersReduxActions } from 'shared/features/folders/hooks';
import {
  useAddToFolderModalData,
  useAddToFolderModalHandlers,
  useAddToFolderModalLifecyle
} from './hooks';

import './_add-to-folder-modal.scss';

export const TEST_ID = {
  CONTAINER: 'add-to-folder-container'
};

export const AddToFolderModal = ({ isOpen, onClose, documentIds, onActionStart }) => {
  const docReduxActions = useDocumentsReduxActions();
  const reduxActions = useFoldersReduxActions();
  const { localState, localActions, formattedData } = useAddToFolderModalData({
    props: { documentIds }
  });
  const handlers = useAddToFolderModalHandlers({
    props: {
      documentIds,
      onClose,
      onActionStart
    },
    localState,
    localActions,
    formattedData,
    reduxActions: {
      ...reduxActions,
      markDocumentBookmarked: docReduxActions.markDocumentBookmarked
    }
  });

  useAddToFolderModalLifecyle({ localState, handlers });

  return (
    <uiLib.Modal isOpen={isOpen} onClose={handlers.handleCancel} withoutDefaultContainer>
      <div className="add-to-folder-modal-container" data-testid={TEST_ID.CONTAINER}>
        <elements.AddToFolderModalHeader
          currentFolder={formattedData.currentFolder}
          isAddingFolder={localState.isAddingFolder}
          folderName={localState.folderName}
          onFolderBackClick={handlers.handleFolderBackClick}
          onFolderNameChange={handlers.handleFolderNameChange}
          onClose={handlers.handleCancel}
        />
        <elements.AddToFolderModalBody
          currentFolder={formattedData.currentFolder}
          isLoading={localState.isLoading}
          isAddingFolder={localState.isAddingFolder}
          folderPaths={localState.folderPaths}
          savedFolders={localState.savedFolders}
          folders={localState.folders}
          subFoldersCount={localState.subFoldersCount}
          docsCount={localState.docsCount}
          hasMoreFolderDocs={formattedData.hasMoreFolderDocs}
          onFolderClick={handlers.handleFolderClick}
          onFetchMoreClick={handlers.handleFetchMoreFolders}
        />
        <elements.AddToFolderModalFooter
          isLoading={localState.isLoading}
          isAddingFolder={localState.isAddingFolder}
          areDocumentsInCurrentFolder={formattedData.areDocumentsInCurrentFolder}
          currentFolder={formattedData.currentFolder}
          folderName={localState.folderName}
          onNewFolderClick={handlers.handleNewFolderClick}
          onSubmit={handlers.handleSubmit}
          onCancel={handlers.handleCancel}
        />
      </div>
    </uiLib.Modal>
  );
};
