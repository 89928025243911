import type { WorkflowModalHandlers } from './useWorkflowModalHandlers.types';

import { useEffect } from 'react';

export const useWorkflowModalLifecycle = ({
  onDataFetch
}: {
  onDataFetch: WorkflowModalHandlers['handleDataFetch'];
}) => {
  useEffect(() => {
    onDataFetch();
  }, [onDataFetch]);
};
