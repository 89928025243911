import * as uiLib from '@compliance.ai/web-components';
import * as routes from 'constants/Routes';

import { toggleFilter as reduxToggleFilter } from 'shared/features/view/view.actions';
import { getFilterCount } from 'utils/filter/getFilterCount';
import {
  areThereAnySupportedQueryParams as areThereAnySupportedPrimaryFilterQueryParams,
  getFilterFromUser
} from 'components/Filters/PrimaryFilter/PrimaryFilter.helpers';
import queryString from 'utils/query-string';
import { keepOnlySupported, mapToFilterKeys } from '../FilterCount.helpers';
import { shouldGetFiltersFromQueryParams as areThereAnySupportedEnforcementFilterQueryParams } from 'components/Filters/EnforcementFilter/EnforcementFilter.helpers';
import { SUPPORTED_QUERY_PARAMS_TO_FILTER_KEYS_MAP } from 'constants/PrimaryFilter';
import { EXPECTED_QUERY_PARAMS } from 'components/Filters/EnforcementFilter/EnforcementFilter.constants';
import { getOrganizationLabels } from 'shared/features/organizations/organizations.selectors';
import { isFilterOpen } from 'shared/features/view/view.selectors';
import {
  getPrimaryFilterParams,
  getJurisdictionFilterParams,
  getCurrentFilterType,
  getPrimaryFilterReadyState
} from 'shared/features/filters/filters.selectors';
import { getCurrentUserReducer } from 'shared/features/user/user.selectors';
import { getLanguages } from 'shared/features/languages/languages.selectors';
import { FILTERS } from 'constants/Filters';

import { useUpdateEffect } from 'utils/hooks';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState, useRef, useMemo } from 'react';

const TOOLTIP_CLOSE_TIMEOUT = 5000;

export const useFilterCountData = ({ searchTermTooltipTrigger }) => {
  const appNavigation = uiLib.useAppNavigation();
  const location = useLocation();
  const [isTooltipVisible, toggleTooltipVisibility] = useState(false);
  const tooltipTimeoutRef = useRef(null);

  const dispatch = useDispatch();
  const reduxState = useSelector(state => ({
    current_user: getCurrentUserReducer(state),
    currentFilterType: getCurrentFilterType(state),
    primaryFilterParams: getPrimaryFilterParams(state),
    jurisdictionFilterParams: getJurisdictionFilterParams(state),
    isFilterReady: getPrimaryFilterReadyState(state),
    organizationLabels: getOrganizationLabels(state),
    languages: getLanguages(state),
    isFilterOpen: isFilterOpen(state)
  }));

  const toggleFilter = useCallback(() => {
    return dispatch(reduxToggleFilter());
  }, [dispatch]);

  const clearTooltipTimeout = () => {
    if (tooltipTimeoutRef.current) {
      clearTimeout(tooltipTimeoutRef.current);
      tooltipTimeoutRef.current = null;
    }
  };

  const setTooltipTimeout = () => {
    tooltipTimeoutRef.current = setTimeout(() => {
      tooltipTimeoutRef.current = null;
      toggleTooltipVisibility(false);
    }, TOOLTIP_CLOSE_TIMEOUT);
  };

  useUpdateEffect(() => {
    clearTooltipTimeout();
    setTooltipTimeout();
    toggleTooltipVisibility(true);
  }, [searchTermTooltipTrigger]);

  const filterCurrentState = useMemo(() => {
    const queryParams = queryString.parse(location.search);

    // TODO There should be same function for Jurisdiction Analyzer. However, JA doesn't support query params now.
    const areThereAnySupportedParams =
      areThereAnySupportedPrimaryFilterQueryParams(queryParams) ||
      areThereAnySupportedEnforcementFilterQueryParams({ location });

    let supportedQueryParamsList = [];

    if (
      [routes.content, routes.resources, routes.news, routes.timeline].includes(location.pathname)
    ) {
      supportedQueryParamsList = Object.keys(SUPPORTED_QUERY_PARAMS_TO_FILTER_KEYS_MAP);
    }

    if (location.pathname === routes.enforcementExplorer) {
      supportedQueryParamsList = EXPECTED_QUERY_PARAMS;
    }

    const filterFromQueryParams = Object.entries(queryParams)
      .filter(keepOnlySupported(supportedQueryParamsList))
      .reduce(mapToFilterKeys, {});

    if (reduxState.isFilterOpen) {
      if (reduxState.currentFilterType === FILTERS.PRIMARY) {
        return reduxState.primaryFilterParams;
      }

      if (reduxState.currentFilterType === FILTERS.JA) {
        return reduxState.jurisdictionFilterParams;
      }
    }

    if (
      [
        routes.content,
        routes.enforcementExplorer,
        routes.resources,
        routes.news,
        routes.timeline
      ].includes(location.pathname) &&
      areThereAnySupportedParams
    ) {
      return filterFromQueryParams;
    } else {
      return getFilterFromUser({
        currentUser: reduxState.current_user,
        organizationLabels: reduxState.organizationLabels,
        languages: reduxState.languages
      });
    }
  }, [location, reduxState]);

  const filterCount = useMemo(() => {
    return getFilterCount(filterCurrentState);
  }, [filterCurrentState]);

  const formattedData = useMemo(
    () => ({
      shouldDisplayContent: appNavigation.isTopBarVisible
    }),
    [appNavigation.isTopBarVisible]
  );

  return {
    toggleFilter,
    isTooltipVisible,
    toggleTooltipVisibility,
    filterCount,
    formattedData
  };
};
