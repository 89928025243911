import type { AccountMenuHandlers, AccountMenuOption } from './hooks/useAccountMenuHandlers.types';
import type { usePermissions, useSKUs } from 'utils/hooks';
import type { useAccountMenuData } from './hooks';

import * as routes from 'constants/Routes';
import * as permissionsConstants from 'constants/Permissions';
import * as skusConstants from 'constants/SKUs';
import * as uiLib from '@compliance.ai/web-components';

export enum ACCOUNT_MENU_OPTION_KEY {
  ACCOUNT = 'account',
  ADMIN = 'admin',
  ORGANIZATION_DOCUMENTS = 'Organization Documents',
  MANAGE_ANNOTATIONS = 'Management Hub',
  DOWNLOADS = 'Downloads',
  GLASSDOOR = 'Glassdoor',
  LEGAL = 'Legal',
  LOG_OUT = 'Log Out',
  SERVICE_CONNECTORS = 'Service Connectors',
  SWAGGER = 'Swagger',
  MANAGE_LABELS = 'Manage Labels'
}

export const ACCOUNT_MENU_OPTIONS_CONFIG: Record<
  ACCOUNT_MENU_OPTION_KEY,
  ({
    permissions,
    skus,
    reduxState,
    events
  }: {
    permissions: ReturnType<typeof usePermissions>;
    skus: ReturnType<typeof useSKUs>;
    reduxState: ReturnType<typeof useAccountMenuData>['reduxState'];
    events: {
      onLogOut: AccountMenuHandlers['handleLogOut'];
    };
  }) => AccountMenuOption
> = {
  [ACCOUNT_MENU_OPTION_KEY.ACCOUNT]: ({ reduxState }) => ({
    label: (
      <>
        Account
        {reduxState.isAccountWithin10DaysOfExpiration && (
          <uiLib.IconLens className="account-menu__inner-notification" />
        )}
      </>
    ),
    route: routes.account
  }),
  [ACCOUNT_MENU_OPTION_KEY.ADMIN]: ({ permissions }) => ({
    label: 'Your organization',
    isHidden: !permissions[permissionsConstants.ACCESS_ADMIN],
    route: routes.admin
  }),
  [ACCOUNT_MENU_OPTION_KEY.SERVICE_CONNECTORS]: ({ skus }) => ({
    label: 'Service Connectors',
    isHidden: !skus.userHasSKU(skusConstants.SKU_RESTRICTION_TYPES.SERVICE_CONNECTOR),
    route: routes.serviceConnectors
  }),
  [ACCOUNT_MENU_OPTION_KEY.ORGANIZATION_DOCUMENTS]: ({ skus }) => ({
    label: 'Organization Documents',
    isHidden: !skus.userHasSKU(skusConstants.SKU_RESTRICTION_TYPES.BYOC),
    route: routes.organizationDocuments
  }),
  [ACCOUNT_MENU_OPTION_KEY.MANAGE_ANNOTATIONS]: ({ permissions }) => ({
    label: 'Management Hub',
    isHidden: !permissions[permissionsConstants.MANAGE_BUCKETS],
    route: routes.managementHub
  }),
  [ACCOUNT_MENU_OPTION_KEY.MANAGE_LABELS]: ({ permissions }) => ({
    label: 'Manage Labels',
    isHidden: !permissions[permissionsConstants.MANAGE_LABELS],
    route: routes.manageLabels
  }),
  [ACCOUNT_MENU_OPTION_KEY.DOWNLOADS]: () => ({
    label: 'Downloads',
    route: routes.downloads
  }),
  [ACCOUNT_MENU_OPTION_KEY.SWAGGER]: ({ skus }) => ({
    label: 'Swagger',
    isHidden: !skus.userHasSKU(skusConstants.SKU_RESTRICTION_TYPES.INTERNAL_DATA),
    route: routes.swagger
  }),
  [ACCOUNT_MENU_OPTION_KEY.GLASSDOOR]: ({ skus }) => ({
    label: 'Glassdoor',
    isHidden: !skus.userHasSKU(skusConstants.SKU_RESTRICTION_TYPES.GLASSDOOR),
    route: routes.glassDoor
  }),
  [ACCOUNT_MENU_OPTION_KEY.LEGAL]: () => ({
    label: 'Legal',
    route: routes.legal
  }),
  [ACCOUNT_MENU_OPTION_KEY.LOG_OUT]: ({ events }) => ({
    label: 'Log out',
    route: routes.logout,
    onClick: events.onLogOut
  })
};
