import { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  fetchFolderDetails as reduxFetchFolderDetails,
  resetFolderDetails as reduxResetFolderDetails,
  deleteFolders as reduxDeleteFolderDocuments
} from 'shared/features/folders/folders.actions';
import { fetchSearchResults as reduxFetchSearchResults } from 'shared/features/documents/documents.actions';
import { clearSelectedItems as reduxClearSelectedItems } from 'shared/features/view/view.actions';

export const useDocumentActionsRemoveUserFolderReduxActions = () => {
  const dispatch = useDispatch();

  const fetchFolderDetails = useCallback(
    async folderId => {
      return await dispatch(reduxFetchFolderDetails({ id: folderId }));
    },
    [dispatch]
  );

  const resetFolderDetails = useCallback(() => {
    return dispatch(reduxResetFolderDetails());
  }, [dispatch]);

  const deleteFolderDocuments = useCallback(
    async (folderId, params) => {
      return await dispatch(
        reduxDeleteFolderDocuments({
          parentId: folderId,
          params
        })
      );
    },
    [dispatch]
  );

  const fetchSearchResults = useCallback(
    async params => {
      return await dispatch(reduxFetchSearchResults(params));
    },
    [dispatch]
  );

  const clearSelectedItems = useCallback(() => {
    return dispatch(reduxClearSelectedItems());
  }, [dispatch]);

  return useMemo(
    () => ({
      fetchFolderDetails,
      resetFolderDetails,
      deleteFolderDocuments,
      fetchSearchResults,
      clearSelectedItems
    }),
    [
      fetchFolderDetails,
      resetFolderDetails,
      deleteFolderDocuments,
      fetchSearchResults,
      clearSelectedItems
    ]
  );
};
