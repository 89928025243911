import type { LabelsFilters } from '../LabelsTable.types';

import * as constants from '../LabelsTable.constants';
import * as uiLib from '@compliance.ai/web-components';

export const FILTERS_INITIAL_STATE: LabelsFilters = {
  [constants.LABELS_FILTER_KEY.DATE_CREATED]: '',
  [constants.LABELS_FILTER_KEY.CREATED_BY]: null,
  [constants.LABELS_FILTER_KEY.TYPE]: null,
  [constants.LABELS_FILTER_KEY.DESCRIPTION]: '',
  [constants.LABELS_FILTER_KEY.BUCKET_ID]: null,
  [constants.LABELS_FILTER_KEY.EXTERNAL_ID]: ''
};

export const LABELS_PER_PAGE = 50;

export const LABELS_TABLE_INITIAL_PARAMS: uiLib.Params = {
  [uiLib.TABLE_PARAMS.LIMIT]: LABELS_PER_PAGE,
  [uiLib.TABLE_PARAMS.OFFSET]: 0,
  [uiLib.TABLE_PARAMS.SORT]: null
};
