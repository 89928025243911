import { WIDGET_COMPONENT_NAMES } from './widgets.constants';

/**
 *
 * Returns data required to rerender the Dashboard widgets.
 *
 */
export const getComponentLayoutData = ({ params, widgetsResponse, maxYOfLg, maxYOfSm }) => {
  const componentName = params.properties.name;

  const isAlertWidget = params.component === WIDGET_COMPONENT_NAMES.ALERT;
  const isPolicyWidget = params.component === WIDGET_COMPONENT_NAMES.POLICY;

  let prevWidgetData = null;
  let newWidgetData = null;

  if (isAlertWidget || isPolicyWidget) {
    newWidgetData = getLastAddedUserWidgetByNameAndType({
      widgetsResponse,
      widgetType: params.component,
      widgetName: componentName
    });

    if (!newWidgetData) {
      return null;
    }
  } else {
    prevWidgetData = getWidgetById({
      widgetsResponse: widgetsResponse,
      widgetId: params.widget_id
    });

    if (!prevWidgetData) {
      return null;
    }

    newWidgetData = getLastAddedUserWidgetByNameAndType({
      widgetsResponse,
      widgetType: prevWidgetData.component,
      widgetName: componentName
    });

    if (!newWidgetData) {
      return null;
    }
  }

  return {
    component: `${newWidgetData.component}${newWidgetData.id}`,
    layout: {
      lg: {
        ...newWidgetData.layout.lg,
        y: maxYOfLg + 1
      },
      sm: {
        ...newWidgetData.layout.sm,
        y: maxYOfSm + 1
      }
    }
  };
};

/**
 *
 * Returns widget by ID (searches in both default and user widgets)
 *
 */
export const getWidgetById = ({ widgetsResponse, widgetId }) => {
  let widgetToReturn = null;

  Object.entries(widgetsResponse).forEach(([_, widgetGroups]) => {
    Object.entries(widgetGroups).forEach(([_, widgets]) => {
      // Convert to array if walking through default_widgets. Default widgets is always just an object (widget)
      const arrayfiedWidgets = Array.isArray(widgets) ? widgets : [widgets];
      const foundWidget = arrayfiedWidgets.find(widgetInGroup => widgetInGroup.id === widgetId);

      if (foundWidget) {
        widgetToReturn = foundWidget;
      }
    });
  });

  return widgetToReturn;
};

/**
 *
 * Returns last added user widget from response
 */
export const getLastAddedUserWidgetByNameAndType = ({
  widgetsResponse,
  widgetType,
  widgetName
}) => {
  return widgetsResponse.user_widgets[widgetType].filter(
    widget => widget.properties.name === widgetName
  )[0];
};

/**
 *
 * Combines all layouts of all widgets into one array
 *
 */
export const getWidgetsLayout = stateWidgets => {
  return Object.entries(stateWidgets).reduce(
    (layout, [widgetType, widgets]) => {
      // In case the default widgets were passed.
      if (!Array.isArray(widgets)) {
        return {
          lg: [...layout.lg, widgets.layout.lg],
          sm: [...layout.sm, widgets.layout.sm]
        };
      }

      const lgLayouts = widgets.reduce((widgetsLayout, widget) => {
        return [...widgetsLayout, widget.layout.lg];
      }, []);

      const smLayouts = widgets.reduce((widgetsLayout, widget) => {
        return [...widgetsLayout, widget.layout.sm];
      }, []);

      return {
        lg: [...layout.lg, ...lgLayouts],
        sm: [...layout.sm, ...smLayouts]
      };
    },
    {
      lg: [],
      sm: []
    }
  );
};
