import { RECEIVE_LANGUAGES } from './languages.actions';

export const getInitialLanguagesState = () => ({
  isReady: false,
  languages: []
});

export const languages = (state = getInitialLanguagesState(), action) => {
  switch (action.type) {
    case RECEIVE_LANGUAGES:
      const languages = action.payload;
      return {
        ...state,
        isReady: true,
        languages
      };

    default:
      return state;
  }
};
