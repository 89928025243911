import { useState } from 'react';
import PropTypes from 'prop-types';

import RichTextEditor from '../RichTextEditor';
import { CommentThread } from './elements';
import * as uiLib from '@compliance.ai/web-components';

import './_comments.scss';

export const Comments = ({
  commentThreads,
  onThreadCreation,
  onCommentDeletion,
  onCommentCreation,
  onCommentEdit,
  areCommentsEditableByAuthor,
  areRepliesEnabled,
  isThreadCreationEnabled,
  isLoading
}) => {
  const [isNewCommentEditorOpen, toggleNewCommentEditor] = useState(false);

  const handleThreadCreation = richtext => {
    onThreadCreation(JSON.stringify(richtext));
    toggleNewCommentEditor(false);
  };

  if (!commentThreads.length) {
    return (
      <div className="comments__container">
        {isLoading && <uiLib.Loader isContrast withoutBackground={false} />}
        <RichTextEditor
          onSave={handleThreadCreation}
          className="comment-thread__text-editor"
          isReadOnly={!isThreadCreationEnabled}
        />
      </div>
    );
  }

  const renderNewCommentEditor = () => {
    if (!isThreadCreationEnabled) {
      return null;
    }

    if (isNewCommentEditorOpen) {
      return (
        <div className="comments__thread">
          <RichTextEditor
            onSave={handleThreadCreation}
            className="comment-thread__text-editor"
            placeholder={'Add a comment...'}
            onCancel={() => toggleNewCommentEditor(false)}
          />
        </div>
      );
    }

    return (
      <uiLib.Button
        type={uiLib.BUTTON_TYPES.LINK_CONTRAST}
        isDisabled={isLoading}
        className="comments__add-comment-button"
        onClick={() => toggleNewCommentEditor(true)}
      >
        + Add Comment
      </uiLib.Button>
    );
  };

  return (
    <>
      <div className="comments__container">
        {isLoading && <uiLib.Loader isContrast withoutBackground={false} />}
        {commentThreads.map(commentThread => (
          <div key={commentThread.id} className="comments__thread">
            <CommentThread
              threadId={commentThread.id}
              comments={commentThread.comments}
              onCommentDeletion={onCommentDeletion}
              onCommentCreation={onCommentCreation}
              onCommentEdit={onCommentEdit}
              areRepliesEnabled={areRepliesEnabled}
              areCommentsEditableByAuthor={areCommentsEditableByAuthor}
            />
          </div>
        ))}
      </div>
      {renderNewCommentEditor()}
    </>
  );
};

Comments.propTypes = {
  commentThreads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      deleted: PropTypes.bool,
      orgId: PropTypes.number,
      comments: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          richtext: PropTypes.string, // Stringified richtext JSON
          userId: PropTypes.number,
          createdAt: PropTypes.string,
          updatedAt: PropTypes.string,
          isRootComment: PropTypes.bool,
          commentThreadId: PropTypes.number
        })
      )
    })
  ).isRequired,
  onThreadCreation: PropTypes.func.isRequired,
  onCommentDeletion: PropTypes.func.isRequired,
  onCommentCreation: PropTypes.func.isRequired,
  onCommentEdit: PropTypes.func.isRequired,
  areCommentsEditableByAuthor: PropTypes.bool,
  areRepliesEnabled: PropTypes.bool,
  isThreadCreationEnabled: PropTypes.bool,
  isLoading: PropTypes.bool
};

Comments.defaultProps = {
  areCommentsEditableByAuthor: true,
  areRepliesEnabled: false,
  isThreadCreationEnabled: false
};
