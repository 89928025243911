import WarningIcon from '@material-ui/icons/Warning';
import './_filter-error.scss';

export const TEST_ID = 'FilterError';

const FilterError = ({ children }) => {
  return (
    <div data-testid={TEST_ID} className="filter-error__container">
      <span className="filter-error__icon">
        <WarningIcon />
      </span>
      {children}
    </div>
  );
};

export default FilterError;
