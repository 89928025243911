import type { LastCommentProps } from './LastComment.types';

import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';
import { formatDate } from 'utils/date-utils';
import { useLastCommentData } from './hooks';

export const LastComment = ({ document }: LastCommentProps) => {
  const { reduxState } = useLastCommentData();

  if (!document.proposed_rule?.last_comment_date) {
    return null;
  }

  return (
    <>
      <FieldName>Last Comment</FieldName>
      <FieldValue>
        {formatDate(document.proposed_rule.last_comment_date, reduxState.userTimezone)}
      </FieldValue>
    </>
  );
};
