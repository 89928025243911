import type { AppNavigationData } from './useAppNavigationData.types';

import type * as uiLib from '@compliance.ai/web-components';
import type * as constants from './useAppNavigationNavBarConfig.constants';

export const markSelectedNavBarItems = ({
  selectedNavBarItemLabel
}: AppNavigationData['localState']) => (item: uiLib.NavBarItemConfig) => {
  if (item.label === selectedNavBarItemLabel) {
    return {
      ...item,
      isSelected: true
    };
  }

  return item;
};

export const markRestrictedNavBarItems = ({
  restrictedNavBarItems
}: AppNavigationData['formattedData']) => (item: uiLib.NavBarItemConfig) => {
  if (restrictedNavBarItems.includes(item.label as constants.NAV_BAR_ITEM_LABELS)) {
    return {
      ...item,
      isRestricted: true
    };
  }

  return item;
};
