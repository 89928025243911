import { LoadingOverlay, Modal as CommonModal } from 'common';
import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import './_modal.scss';

export const Modal = ({ onClose, isLoading, children, title, headingMessage }) => {
  return (
    <CommonModal open onClose={onClose} childContainerClassName="modal__container" maxWidth="425px">
      <LoadingOverlay isLoading={isLoading}>
        <p className="modal__text-top">
          <h4>{title}</h4>
          {headingMessage}
        </p>
        {children}
        <p className="modal__text-bottom">
          Having trouble?{' '}
          <a href="mailto:support@compliance.ai">
            <Button type={BUTTON_TYPES.LINK} isBold>
              Contact support@compliance.ai
            </Button>
          </a>
        </p>
      </LoadingOverlay>
    </CommonModal>
  );
};
