import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  FILTER_KEY,
  ENFORCEMENTS_CATEGORY,
  CUSTOM_FILTER_OPTIONS_KEYS,
  TEXT_MATCH_TYPES,
  TITLE_MATCH_TYPES
} from 'constants/PrimaryFilter';
import { isPowerOrTeamUser } from 'utils/authorization';
import {
  getDocTypesReducer,
  getMetaCategoryOptions
} from 'shared/features/documents/documents.selectors';
import {
  getActiveTopics,
  getDefaultMainstreamNewsSources
} from 'shared/features/sources/sources.selectors';
import { getAgenciesList } from 'shared/features/agencies/agencies.selectors';
import { getAllJurisdictions } from 'shared/features/jurisdictions/jurisdictions.selectors';
import { getRegulationsList } from 'shared/features/regulations/regulations.selectors';
import { getConceptsGroupedOptions } from 'shared/features/concepts/concepts.selectors';
import { getLanguages } from 'shared/features/languages/languages.selectors';
import { getAuthorsOptions } from 'shared/features/authors/authors.selectors';
import {
  formatDocumentPropertiesOptions,
  formatAgencyOptions,
  formatJurisdictionsOptions,
  formatMainstreamNewsOptions,
  formatRegulationOptions,
  formatTopicOptions,
  formatLanguageOptions
} from './useFilterOptions.helpers';
import { useSKUs } from 'utils/hooks';
import { SKU_RESTRICTION_TYPES } from 'constants/SKUs';

export const useFilterOptions = () => {
  const { userHasSKU } = useSKUs();

  const reduxState = useSelector(state => ({
    docTypes: getDocTypesReducer(state),
    activeTopics: getActiveTopics(state),
    agencies: getAgenciesList(state),
    jurisdictions: getAllJurisdictions(state),
    defaultMainstreamNewsSources: getDefaultMainstreamNewsSources(state),
    regulations: getRegulationsList(state),
    conceptGroupOptions: getConceptsGroupedOptions(state),
    languages: getLanguages(state),
    authorsOptions: getAuthorsOptions(state)
  }));

  const metaCategoryOptions = useSelector(getMetaCategoryOptions);

  const docTypesOptions = useMemo(() => {
    return metaCategoryOptions;
  }, [metaCategoryOptions]);

  const enforcementsDocTypesOptions = useMemo(() => {
    return metaCategoryOptions.filter(metacategory => metacategory.label === ENFORCEMENTS_CATEGORY);
  }, [metaCategoryOptions]);

  const topicOptions = useMemo(() => {
    return formatTopicOptions(reduxState.activeTopics);
  }, [reduxState.activeTopics]);

  const agenciesOptions = useMemo(() => {
    return formatAgencyOptions(reduxState.agencies);
  }, [reduxState.agencies]);

  const jurisdictionOptions = useMemo(() => {
    return formatJurisdictionsOptions(reduxState.jurisdictions);
  }, [reduxState.jurisdictions]);

  const mainstreamNewsOptions = useMemo(() => {
    return formatMainstreamNewsOptions(reduxState.defaultMainstreamNewsSources);
  }, [reduxState.defaultMainstreamNewsSources]);

  const regulationOptions = useMemo(() => {
    return formatRegulationOptions(reduxState.regulations);
  }, [reduxState.regulations]);

  const languageOptions = useMemo(() => {
    return formatLanguageOptions(reduxState.languages);
  }, [reduxState.languages]);

  const documentPropertiesOptions = formatDocumentPropertiesOptions({
    isPowerOrTeamUser: isPowerOrTeamUser(),
    hasBYOCSku: userHasSKU(SKU_RESTRICTION_TYPES.BYOC)
  });

  return {
    [FILTER_KEY.DOC_TYPES]: docTypesOptions,
    [FILTER_KEY.TOPICS]: topicOptions,
    [FILTER_KEY.AGENCIES]: agenciesOptions,
    [FILTER_KEY.JURISDICTIONS]: jurisdictionOptions,
    [FILTER_KEY.MAINSTREAM_NEWS_SOURCES]: mainstreamNewsOptions,
    [FILTER_KEY.REGULATIONS]: regulationOptions,
    [FILTER_KEY.LANGUAGE]: languageOptions,
    [FILTER_KEY.DOC_PROPERTIES]: documentPropertiesOptions,
    [FILTER_KEY.CONCEPTS]: reduxState.conceptGroupOptions,
    [CUSTOM_FILTER_OPTIONS_KEYS.ENFORCEMENTS_CATEGORY]: enforcementsDocTypesOptions,
    [FILTER_KEY.TEXT_MATCH_TYPES]: TEXT_MATCH_TYPES,
    [FILTER_KEY.TITLE_MATCH_TYPES]: TITLE_MATCH_TYPES,
    [FILTER_KEY.AUTHOR]: reduxState.authorsOptions
  };
};
