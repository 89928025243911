import type { WorkflowDetailsProps } from './WorkflowDetails.types';
import type { FC } from 'react';

import { Loader, LOADER_SIZES } from '@compliance.ai/web-components';
import { Workflow } from './elements';
import {
  useWorkflowDetailsData,
  useWorkflowDetailsHandlers,
  useWorkflowDetailsLifecycle,
  useWorkflowDetailsQueries
} from './hooks';
import './_workflow-details.scss';

export const WorkflowDetails: FC<WorkflowDetailsProps> = ({
  document,
  workflowId,
  sentenceNoteId,
  onTabChange,
  shouldShowObligationsLink,
  shouldShowFullscreenLoader,
  shouldHideLoaders
}) => {
  const { reduxState } = useWorkflowDetailsData();

  const queries = useWorkflowDetailsQueries({
    props: {
      document,
      workflowId,
      sentenceNoteId
    }
  });

  const handlers = useWorkflowDetailsHandlers({
    reduxState,
    queries
  });

  useWorkflowDetailsLifecycle({
    onDataRefetch: handlers.handleDataRefetch
  });

  if (queries.fetchWorkflowsByDocIDs.isFetching) {
    if (shouldHideLoaders) {
      return null;
    }

    if (shouldShowFullscreenLoader) {
      return <Loader size={LOADER_SIZES.MEDIUM} withoutBackground={false} isContrast />;
    }

    return (
      <div className="workflow-details__loader-wrapper">
        <Loader size={LOADER_SIZES.MEDIUM} isContrast />
      </div>
    );
  }

  if (!queries.fetchWorkflowsByDocIDs.data || !queries.fetchWorkflowsByDocIDs.data?.length) {
    return null;
  }

  return (
    <>
      {queries.fetchWorkflowsByDocIDs.data?.map(workflow => (
        <Workflow
          key={workflow.workflow_id}
          workflow_id={workflow.workflow_id}
          workflow_name={workflow.workflow_name}
          workflow_task_order_ids={workflow.task_order_ids}
          document={document}
          sentenceNoteId={sentenceNoteId}
          onTabChange={onTabChange}
          shouldShowObligationsLink={shouldShowObligationsLink}
        />
      ))}
    </>
  );
};
