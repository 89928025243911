import type {
  NormalizedSentenceNote,
  NormalizedSystemGeneratedSentenceNote
} from './sentenceNotes.types';

export const uniqueBySentences = (
  sentenceNoteA: NormalizedSentenceNote | NormalizedSystemGeneratedSentenceNote,
  sentenceNoteB: NormalizedSentenceNote | NormalizedSystemGeneratedSentenceNote
): boolean => {
  return (
    sentenceNoteA.firstSentenceId === sentenceNoteB.firstSentenceId &&
    sentenceNoteA.leftOffset === sentenceNoteB.leftOffset
  );
};

export const uniqueById = (
  sentenceNoteA: NormalizedSentenceNote | NormalizedSystemGeneratedSentenceNote,
  sentenceNoteB: NormalizedSentenceNote | NormalizedSystemGeneratedSentenceNote
): boolean => {
  if (!sentenceNoteA.id || !sentenceNoteB.id) {
    return false;
  }

  return sentenceNoteA.id === sentenceNoteB.id;
};

export const sortBySentences = (
  sentenceNoteA: NormalizedSentenceNote | NormalizedSystemGeneratedSentenceNote,
  sentenceNoteB: NormalizedSentenceNote | NormalizedSystemGeneratedSentenceNote
): number => {
  if (sentenceNoteA.firstSentenceId < sentenceNoteB.firstSentenceId) {
    return -1;
  }

  if (sentenceNoteA.firstSentenceId > sentenceNoteB.firstSentenceId) {
    return 1;
  }

  return sentenceNoteA.leftOffset < sentenceNoteB.leftOffset ? -1 : 1;
};

export const replace = ({
  updatedSentenceNote,
  sentenceNotes
}: {
  updatedSentenceNote: NormalizedSentenceNote;
  sentenceNotes: (NormalizedSentenceNote | NormalizedSystemGeneratedSentenceNote)[];
}) => {
  return sentenceNotes.map(sentenceNote => {
    if (sentenceNote.id === updatedSentenceNote.id) {
      return updatedSentenceNote;
    }

    return sentenceNote;
  });
};
