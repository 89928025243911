import { batch } from 'react-redux';
import { api_getWidgets, api_saveWidget, api_deleteWidget } from './widgets.api';
import { updateUserDashboardWidget } from 'shared/features/user/user.actions';
import { getComponentLayoutData } from './widgets.helpers';
import { getMaxYOfWidgetsLayoutsBySize } from './widgets.selectors';

export const GET_WIDGETS = 'GET_WIDGETS';

export const updateWidgetData = widgetData => ({
  type: GET_WIDGETS,
  payload: widgetData
});

export const fetchWidgets = () => async dispatch => {
  try {
    const res = await api_getWidgets();
    dispatch(updateWidgetData(res));
    return res;
  } catch (error) {
    return error;
  }
};

export const saveWidget = payload => async dispatch => {
  try {
    const res = await api_saveWidget(payload);
    dispatch(updateWidgetData(res));
  } catch (error) {
    // pass
  }
};

export const deleteWidget = ({ widgetId }) => async dispatch => {
  try {
    const res = await api_deleteWidget({ widgetId });
    dispatch(updateWidgetData(res));
  } catch (error) {
    // pass
  }
};

export const updateWidgets = params => async dispatch => {
  const res = await api_saveWidget(params);

  const maxYOfLg = getMaxYOfWidgetsLayoutsBySize('lg')({
    widgets: res
  });
  const maxYOfSm = getMaxYOfWidgetsLayoutsBySize('sm')({
    widgets: res
  });

  const componentData = getComponentLayoutData({
    params: params,
    widgetsResponse: res,
    maxYOfLg: maxYOfLg,
    maxYOfSm: maxYOfSm
  });

  batch(() => {
    dispatch(updateWidgetData(res));

    if (componentData) {
      dispatch(updateUserDashboardWidget(componentData));
    }
  });

  return res;
};
