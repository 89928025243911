import type { DATE_TYPES } from '../WorkflowDiagramTool.constants';

import { useMemo } from 'react';
import { DATE_LABELS } from '../WorkflowDiagramTool.constants';

export const useFormattedTaskDate = ({
  daysCount,
  dateType
}: {
  daysCount: number;
  dateType: DATE_TYPES;
}) => {
  const dateLabel = useMemo(() => DATE_LABELS[dateType], [dateType]);
  const daysClause = useMemo(() => (daysCount <= 0 ? 'after' : 'before'), [daysCount]);

  const date = useMemo(() => `Due ${Math.abs(daysCount)} days ${daysClause}`, [
    daysClause,
    daysCount
  ]);

  return {
    date,
    dateLabel
  };
};
