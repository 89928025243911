import type * as types from './DefaultMetadataStage.types';

export class DefaultMetadataStage implements types.DefaultFieldsStage {
  readonly defaultValuesMap: types.DefaultFieldsStage['defaultValuesMap'];
  readonly defaultValuesFormattersMap: types.DefaultFieldsStage['defaultValuesFormattersMap'];

  constructor(args: {
    defaultValuesMap: types.DefaultFieldsStage['defaultValuesMap'];
    defaultValuesFormattersMap?: types.DefaultFieldsStage['defaultValuesFormattersMap'];
  }) {
    this.defaultValuesMap = args.defaultValuesMap;
    this.defaultValuesFormattersMap = args.defaultValuesFormattersMap;
  }

  readonly run: types.DefaultFieldsStage['run'] = async args => {
    return Object.entries(
      Object.entries(this.defaultValuesMap).reduce(
        (metadataObj, [defaultMetadataKey, defaultMetadataValue]) => {
          const existingValue = metadataObj[defaultMetadataKey];

          if (!existingValue || (Array.isArray(existingValue) && !existingValue.length)) {
            const formatter = this.defaultValuesFormattersMap?.[defaultMetadataKey];

            return {
              ...metadataObj,
              [defaultMetadataKey]: formatter
                ? formatter(defaultMetadataValue)
                : defaultMetadataValue
            };
          }

          return metadataObj;
        },
        Object.fromEntries(args.metadata)
      )
    );
  };
}
