import { ORG_IP_ERROR, ORG_IP_ERROR_DESCRIPTION } from './fetch.constants';
import Bugsnag from '@bugsnag/js';
import { showSystemErrorNotification } from '../common/AlertsNotifications/AlertsNotifications.utils';
import * as routes from '../constants/Routes';
import { ERROR_MESSAGES } from '../constants/Errors';
import { convertAxiosErrorToObject, getErrorData, logError } from './fetch.errors.helpers';

/**
 * Redirects user on UnauthorizedAccess error
 */
export const onUnauthorizedAccessError = (e, dataType) => {
  const { errorMessage } = getErrorData(e.response);

  if (errorMessage.includes(ORG_IP_ERROR)) {
    Bugsnag.notify(ORG_IP_ERROR_DESCRIPTION);

    showSystemErrorNotification({
      dataType: dataType,
      message: ORG_IP_ERROR_DESCRIPTION
    });
  }

  window.location.href = `${window.location.origin}${routes.login}`;

  return Promise.resolve();
};

/**
 * Shows error notifications and sends Bugsnag repost
 */
export const onResponseError = (e, dataType, shouldHideErrorNotifications = false) => {
  if (import.meta.env.NODE_ENV !== 'test' && !shouldHideErrorNotifications) {
    logError({
      errorResponse: e.response,
      dataType
    });
  }

  return Promise.reject(convertAxiosErrorToObject(e));
};

/**
 * Shows error notification
 */
export const onRequestError = (e, dataType, shouldHideErrorNotifications = false) => {
  if (!shouldHideErrorNotifications) {
    showSystemErrorNotification({
      dataType,
      message: e.message || ERROR_MESSAGES.NETWORK
    });
  }

  return Promise.reject(convertAxiosErrorToObject(e));
};

/**
 * When something totally unexpected happened
 */
export const onUnexpectedError = (e, dataType, shouldHideErrorNotifications = false) => {
  if (!shouldHideErrorNotifications) {
    showSystemErrorNotification({
      dataType,
      message: e.message || ERROR_MESSAGES.UNKNOWN
    });
  }

  return Promise.reject(convertAxiosErrorToObject(e));
};
