import * as uiLib from '@compliance.ai/web-components';

export const GroupSelect = ({ value, setValue, fieldDefinition }) => {
  return (
    <uiLib.GroupSelect
      type={uiLib.SELECT_TYPES.DEFAULT}
      options={fieldDefinition.options}
      value={value ?? []}
      onChange={setValue}
    />
  );
};
