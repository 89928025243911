import type { LocationHandlers } from './useLocationHandlers.types';

import * as urlUtils from 'utils/url-utils';
import * as routes from 'constants/Routes';

import { QUERY_PARAMS as RESOURCES_QUERY_PARAMS } from 'shared/features/resources/resources.constants';

import { useNavigate } from 'react-router-dom';

export const useLocationHandlers = (): LocationHandlers => {
  const navigate = useNavigate();

  const handleLocationClick: LocationHandlers['handleLocationClick'] = docId => {
    navigate(
      urlUtils.formatRedirectUrl({
        path: routes.resources,
        params: {
          [RESOURCES_QUERY_PARAMS.CITATION_SELECTED_ID]: docId
        }
      })
    );
  };

  return {
    handleLocationClick
  };
};
