import type { PrimaryFilterHandlers } from './usePrimaryFilterHandlers.types';

import { useEffect } from 'react';
import { useWillUnmount } from 'utils/hooks';

export const usePrimaryFilterLifecycles = ({
  onInitialFiltersLoad,
  onRefsUpdated,
  onWillUnmount
}: {
  onInitialFiltersLoad: PrimaryFilterHandlers['handleInitialFiltersLoad'];
  onRefsUpdated: PrimaryFilterHandlers['handleRefsUpdated'];
  onWillUnmount: PrimaryFilterHandlers['handleWillUnmount'];
}) => {
  useEffect(() => {
    onInitialFiltersLoad();
  }, [onInitialFiltersLoad]);

  useEffect(() => {
    onRefsUpdated();
  }, [onRefsUpdated]);

  useWillUnmount(() => {
    onWillUnmount();
  });
};
