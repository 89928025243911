import type { MetadataProps } from './Metadata.types';
import type { FC } from 'react';

import * as constants from './Metadata.constants';
import * as documentConstants from 'shared/features/documents/documents.constants';

import './_metadata.scss';

export const Metadata: FC<MetadataProps> = ({
  path,
  document,
  shouldRenderForEmail,
  fieldsToRender,
  onResourceTreeBuild
}) => {
  return (
    <div className="document-summary-metadata__container">
      {Object.entries(constants.COMPONENTS)
        .filter(([key]) =>
          fieldsToRender
            ? fieldsToRender.includes(key as documentConstants.DOC_METADATA_FIELD)
            : true
        )
        .map(([key, Component]) => (
          <div key={key} className="document-summary-metadata__row">
            <Component
              path={path}
              document={document}
              shouldRenderForEmail={shouldRenderForEmail}
              onResourceTreeBuild={onResourceTreeBuild}
            />
          </div>
        ))}
    </div>
  );
};
