import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryParams, useUpdateEffect } from 'utils/hooks';
import queryString from 'utils/query-string';
import { SUPPORTED_QUERY_PARAMS } from 'constants/PrimaryFilter';

export const useActionBarLifecycle = ({
  localState,
  localActions,
  reduxState,
  userReduxActions
}) => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();

  useUpdateEffect(() => {
    userReduxActions.updateCurrentUser(reduxState.currentUserEmail, {
      ...reduxState.currentUser,
      properties: {
        ...reduxState.currentUserProperties,
        order: localState.currentOrder.value
      }
    });

    navigate(
      `${location.pathname}?${queryString.stringify({
        ...queryParams,
        [SUPPORTED_QUERY_PARAMS.ORDER]: localState.currentOrder.value
      })}`
    );
  }, [localState.currentOrder]);

  useUpdateEffect(() => {
    userReduxActions.updateCurrentUser(reduxState.currentUserEmail, {
      ...reduxState.currentUser,
      properties: {
        ...reduxState.currentUserProperties,
        search_sort: localState.currentSort.value
      }
    });

    navigate(
      `${location.pathname}?${queryString.stringify({
        ...queryParams,
        [SUPPORTED_QUERY_PARAMS.SEARCH_SORT]: localState.currentSort.value
      })}`
    );
  }, [localState.currentSort]);

  useEffect(() => {
    localActions.setLoading(
      reduxState.isLoadingSearchResultsRelevance || reduxState.documents.isFetching
    );
  }, [localActions, reduxState.documents.isFetching, reduxState.isLoadingSearchResultsRelevance]);
};
