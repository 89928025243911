import type { GlobalState } from '../../reducers';

import { EXPECTED_DOC_FIELDS, REDUCER_NAME } from './documentFields.constants';
import { createSelector } from '@reduxjs/toolkit';
import { alphabeticallyByLabel } from 'utils/sort';

export const getDocumentFieldsReducer = (state: GlobalState) => state[REDUCER_NAME];

export const getDocumentFields = createSelector(getDocumentFieldsReducer, documentFieldsReducer => {
  return [...(documentFieldsReducer.items ?? [])].sort(alphabeticallyByLabel);
});

export const getMandatoryDocumentFields = createSelector(getDocumentFields, docFields => {
  return docFields.filter(field => field.isMandatory);
});

export const getNonMandatoryDocumentFields = createSelector(getDocumentFields, docFields => {
  return docFields.filter(field => !field.isMandatory);
});

export const getDocumentFieldsReady = createSelector(
  getDocumentFieldsReducer,
  documentFieldsReducer => {
    return documentFieldsReducer.isReady;
  }
);

export const getDocFileNameFieldLabel = createSelector(getDocumentFields, docFields => {
  return (
    docFields.find(field => field.value === EXPECTED_DOC_FIELDS.FILE_NAME)?.label ?? 'File Name'
  );
});
