import { api_fetchAutoComplete, api_fetchIframeAutoComplete, api_postSearch } from './search.api';
import { recieveSearchCount } from '../user/user.actions';
import { getUserAuthenticatedState } from '../auth/auth.selectors';

export const REQUEST_AUTO_COMPLETE = 'REQUEST_AUTO_COMPLETE';
export const RECEIVE_AUTO_COMPLETE = 'RECEIVE_AUTO_COMPLETE';
export const CLEAR_AUTO_COMPLETE = 'CLEAR_AUTO_COMPLETE';

function requestAutoComplete() {
  return {
    type: REQUEST_AUTO_COMPLETE
  };
}

function receiveAutoComplete(json, currentAutoComplete) {
  return {
    type: RECEIVE_AUTO_COMPLETE,
    autocompletes: json.results,
    currentAutoComplete
  };
}

export function clearAutoComplete() {
  return {
    type: CLEAR_AUTO_COMPLETE,
    autocompletes: null
  };
}

export function fetchAutoComplete(partialValue, category) {
  return function dofetch(dispatch) {
    dispatch(requestAutoComplete());
    return api_fetchAutoComplete(partialValue, { category }).then(response => {
      dispatch(receiveAutoComplete(response, category));
      return response;
    });
  };
}

export function fetchIframeAutoComplete(data, useAPIKey) {
  return function dofetch(dispatch) {
    dispatch(requestAutoComplete());
    return api_fetchIframeAutoComplete(data, useAPIKey).then(response => {
      dispatch(receiveAutoComplete(response));
      return response;
    });
  };
}

export const postSearch = data => async (dispatch, getState) => {
  const isAuthenticated = getUserAuthenticatedState(getState());

  if (!isAuthenticated) {
    return Promise.resolve();
  }

  const response = await api_postSearch(data);

  dispatch(recieveSearchCount(response));

  return response;
};
