import queryString from 'utils/query-string';
import { DEFAULT_OFFSET, VIEW_TYPE_DATE } from 'constants/ActionBar';
import { useQueryParams } from 'utils/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { getExportLimit } from 'utils/exportLimit/exportLimit';
import { SUPPORTED_QUERY_PARAMS } from 'constants/PrimaryFilter';
import { logError } from 'utils/errors';
import { FILTER_KEY } from 'constants/ActionBarFilter';

import * as paginationUtils from 'utils/pagination-utils';

export const useActionBarHandlers = ({
  props,
  localState,
  localActions,
  reduxState,
  formattedData,
  documentsReduxActions,
  viewReduxActions,
  filterReduxActions
}) => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();

  const handleParamSelect = paramType => option => {
    if (paramType === SUPPORTED_QUERY_PARAMS.SEARCH_SORT) {
      localActions.setCurrentSort(option);
    } else if (paramType === SUPPORTED_QUERY_PARAMS.ORDER) {
      localActions.setCurrentOrder(option);
    }
  };

  const fetchDocuments = async ({ page, limit }) => {
    try {
      localActions.setLoading(true);

      const offsetParams = paginationUtils.getOffsetParams({
        page,
        limit,
        localStatePage: localState.page,
        localStateLimit: localState.limit,
        documentsOffsets: reduxState.documents.offsets,
        isPreviousDocs: page - localState.page < 0
      });

      const params = {
        ...reduxState.searchParams,
        [SUPPORTED_QUERY_PARAMS.LIMIT]: limit,
        [SUPPORTED_QUERY_PARAMS.OFFSET]: page * limit,
        [SUPPORTED_QUERY_PARAMS.TIMELINE_VIEW]: props.shouldShowWithCombinedDocs,
        ...offsetParams
      };

      await documentsReduxActions.fetchDocuments({
        params
      });

      localActions.setPage(page);
      localActions.setLimit(limit);
    } catch (e) {
      logError(e);
    } finally {
      localActions.setLoading(false);
    }
  };

  const handlePageSelect = (page, isEnabled) => async () => {
    try {
      if (!isEnabled) {
        return;
      }

      if (formattedData.viewType === VIEW_TYPE_DATE) {
        await fetchDocuments({
          page: page,
          limit: formattedData.limit
        });
      } else {
        const offset = reduxState.searchParams.limit * (page - 1);

        navigate(
          `${location.pathname}?${queryString.stringify({
            ...queryParams,
            [SUPPORTED_QUERY_PARAMS.OFFSET]: offset
          })}`
        );
      }
    } catch (e) {
      logError(e);
    }
  };

  const handleBulkExport = async () => {
    try {
      const exportLimit = getExportLimit();

      const offsetParams = paginationUtils.getOffsetParams({
        page: formattedData.page,
        limit: exportLimit,
        localStatePage: localState.page,
        localStateLimit: localState.limit,
        documentsOffsets: reduxState.documents.offsets,
        isPreviousDocs: page - localState.page < 0
      });

      const params = {
        ...reduxState.searchParams,
        [SUPPORTED_QUERY_PARAMS.LIMIT]: exportLimit,
        [SUPPORTED_QUERY_PARAMS.OFFSET]: 0,
        [SUPPORTED_QUERY_PARAMS.TIMELINE_VIEW]: props.shouldShowWithCombinedDocs,
        [SUPPORTED_QUERY_PARAMS.SORT]: 'publication_date',
        [SUPPORTED_QUERY_PARAMS.EXCLUSIVE_FIELDS]: [
          'id',
          'title',
          'rule.comments_close_on',
          'publication_date',
          'rule.effective_on'
        ],
        ...offsetParams
      };

      await viewReduxActions.fetchDocumentsToSelectedDocs(params);

      props.onExport();
    } catch (e) {
      logError(e);
    }
  };

  const handleRowCountChange = async option => {
    try {
      filterReduxActions.setActionBarFilter({ [FILTER_KEY.ROWS_COUNT]: option });

      if (formattedData.viewType === VIEW_TYPE_DATE) {
        await fetchDocuments({
          page: formattedData.page,
          limit: option.value
        });
      } else {
        navigate(
          `${location.pathname}?${queryString.stringify({
            ...queryParams,
            [SUPPORTED_QUERY_PARAMS.OFFSET]: DEFAULT_OFFSET,
            [SUPPORTED_QUERY_PARAMS.LIMIT]: option.value
          })}`
        );
      }
    } catch (e) {
      logError(e);
    }
  };

  return {
    handlePageSelect,
    handleParamSelect,
    handleBulkExport,
    handleRowCountChange
  };
};
