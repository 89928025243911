import type { FC } from 'react';
import type { LabelTooltipTitleProps } from './LabelTooltipTitle.types';

import * as uiLib from '@compliance.ai/web-components';

import './_label-tooltip-title.scss';

export const LabelTooltipTitle: FC<LabelTooltipTitleProps> = ({ bucketName }) => {
  return (
    <span className="label-tooltip-title">
      <uiLib.Typography type={uiLib.TYPOGRAPHY_TYPES.BODY2} className="label-tooltip-title__prefix">
        Label located in:&nbsp;
      </uiLib.Typography>
      <uiLib.Typography type={uiLib.TYPOGRAPHY_TYPES.BODY2}>{bucketName}</uiLib.Typography>
    </span>
  );
};
