import type { AddToWorkflowButtonHandlers } from './useAddToWorkflowButtonHandlers.types';
import type { AddToWorkflowButtonData } from './useAddToWorkflowButtonData.types';

export const useAddToWorkflowButtonHandlers = ({
  localActions
}: {
  localActions: AddToWorkflowButtonData['localActions'];
}): AddToWorkflowButtonHandlers => {
  const handleAddButtonClick: AddToWorkflowButtonHandlers['handleAddButtonClick'] = () => {
    localActions.setIsSelectingWorkflow(true);
  };

  const handleCancel: AddToWorkflowButtonHandlers['handleCancel'] = () => {
    localActions.setIsSelectingWorkflow(false);
    localActions.setIsViewingTasks(false);
    localActions.setWorkflowOption(null);
  };

  const handleWorkflowSelect: AddToWorkflowButtonHandlers['handleWorkflowSelect'] = () => {
    localActions.setIsSelectingWorkflow(false);
    localActions.setIsViewingTasks(true);
  };

  return {
    handleAddButtonClick,
    handleCancel,
    handleWorkflowSelect
  };
};
