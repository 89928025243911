import { createSelector } from '@reduxjs/toolkit';

/**
 *
 * Subscriptions reducer selector
 */
export const getSubscriptionsReducer = state => state.subscriptions;

/**
 *
 * Latest subscription selector
 *
 */
export const getLatestSubscriptionObject = createSelector(
  getSubscriptionsReducer,
  subscriptionsReducer => {
    const subscriptions = subscriptionsReducer.subscriptions || [];

    return subscriptions.filter(subscription => subscription.latest)[0];
  }
);

export const getLatestSubscription = createSelector(
  getLatestSubscriptionObject,
  latestSubscriptionObj => {
    return latestSubscriptionObj?.stripe_id;
  }
);

/**
 *
 * Subscriptions common selectors
 *
 */
export const isAccountWithin10DaysOfExpiration = createSelector(
  getLatestSubscriptionObject,
  latestSubscription => {
    return Boolean(latestSubscription?.within10dayofExpiration);
  }
);
