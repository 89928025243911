import type { FC } from 'react';
import type { ValidatorItemProps } from './ValidatorItem.types';

import classnames from 'classnames';
import { Check, Clear } from '@material-ui/icons';
import './_validator-item.scss';

export const ValidatorItem: FC<ValidatorItemProps> = ({ isValid, message, dataTestId }) => {
  return (
    <div
      className={classnames('validator-item', {
        'validator-item--is-valid': isValid
      })}
      data-testid={dataTestId}
    >
      {isValid ? <Check /> : <Clear />}
      {message}
    </div>
  );
};
