import type { AlertsTableData } from './useAlertsTableData.types';
import type { AlertsTableHandlers } from './useAlertsTableHandlers.types';
import type { AlertsTableProps } from '../AlertsTable.types';
import type { useAlertsApi } from 'shared/features/alerts/hooks';

import { useCallback } from 'react';
import { TABLE_PARAMS } from '@compliance.ai/web-components';
import lodash from 'lodash';
import { SUPPORTED_QUERY_PARAMS } from 'constants/PrimaryFilter';
import { logError } from 'utils/errors';
import {
  DEFAULT_TABLE_PARAMS,
  DEFAULT_OFFSET,
  DEFAULT_SORT_QUERY_PARAMS,
  SELECTED_ALERTS_MAX_COUNT,
  TABLE_KEYS
} from '../AlertsTable.constants';

export const useAlertsTableHandlers = ({
  props,
  prevProps,
  localState,
  localActions,
  alertsApi
}: {
  props: Pick<
    AlertsTableProps,
    'selectedAlertIds' | 'maxSelectableAlertsCount' | 'onSelectedAlertsChange'
  >;
  prevProps: AlertsTableData['prevProps'];
  localState: AlertsTableData['localState'];
  localActions: AlertsTableData['localActions'];
  alertsApi: ReturnType<typeof useAlertsApi>;
}): AlertsTableHandlers => {
  const handleAlertsFetch: AlertsTableHandlers['handleAlertsFetch'] = useCallback(
    async params => {
      try {
        const response = await alertsApi.fetchAlerts({
          ...localState.requestParams,
          ...DEFAULT_SORT_QUERY_PARAMS,
          [SUPPORTED_QUERY_PARAMS.EXCLUSIVE_IDS]: props.selectedAlertIds,
          [SUPPORTED_QUERY_PARAMS.LIMIT]: String(params[TABLE_PARAMS.LIMIT]),
          [SUPPORTED_QUERY_PARAMS.OFFSET]: String(params[TABLE_PARAMS.OFFSET])
        });

        localActions.setAllAlertsCount(response.count);

        return {
          results: response.results.map(alert => ({
            [TABLE_KEYS.ID]: String(alert.id),
            [TABLE_KEYS.NAME]: alert.name,
            [TABLE_KEYS.UPDATED_AT]: alert.updated_at,
            [TABLE_KEYS.USER_ID]: alert.user_id
          })),
          count: response.count
        };
      } catch (e) {
        logError(e as Error);

        return {
          results: [],
          count: 0
        };
      }
    },
    [props.selectedAlertIds, alertsApi, localActions, localState.requestParams]
  );

  const handleAlertsIdsFetch: AlertsTableHandlers['handleAlertsIdsFetch'] = useCallback(async () => {
    try {
      const response = await alertsApi.fetchAlertIds({
        ...localState.requestParams,
        ...DEFAULT_SORT_QUERY_PARAMS,
        [SUPPORTED_QUERY_PARAMS.LIMIT]: String(
          props.maxSelectableAlertsCount ?? SELECTED_ALERTS_MAX_COUNT
        ),
        [SUPPORTED_QUERY_PARAMS.OFFSET]: String(DEFAULT_OFFSET)
      });

      return response.map(String);
    } catch (e) {
      logError(e as Error);

      return [];
    }
  }, [alertsApi, props.maxSelectableAlertsCount, localState.requestParams]);

  const handleSelectedAlertsChange: AlertsTableHandlers['handleSelectedAlertsChange'] = ids => {
    localActions.setSelectedAlertsIds(ids.map(Number));

    props.onSelectedAlertsChange?.(ids.map(Number));
  };

  const handleRequestParamsChange: AlertsTableHandlers['handleRequestParamsChange'] = useCallback(() => {
    if (!lodash.isEqual(localState.requestParams, prevProps.requestParams)) {
      localActions.setTableParams(DEFAULT_TABLE_PARAMS);
      localActions.setTableRefreshTrigger(tableRefreshTrigger => !tableRefreshTrigger);
    }
  }, [localState.requestParams, localActions, prevProps.requestParams]);

  const handleFiltersChange: AlertsTableHandlers['handleFiltersChange'] = requestParams => {
    localActions.setRequestParams(requestParams);
  };

  return {
    handleAlertsFetch,
    handleAlertsIdsFetch,
    handleSelectedAlertsChange,
    handleRequestParamsChange,
    handleFiltersChange
  };
};
