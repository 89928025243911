import type { AuthorsSelectProps } from './AuthorsSelect.types';

import * as uiLib from '@compliance.ai/web-components';

import { useAuthorsSelectData, useAuthorsSelectHandlers } from './hooks';

export const AuthorsSelect = (props: AuthorsSelectProps) => {
  const { reduxState, formattedData } = useAuthorsSelectData(props);
  const handlers = useAuthorsSelectHandlers();

  return (
    <uiLib.Select
      isMulti
      {...props}
      type={props.type ?? uiLib.SELECT_TYPES.DEFAULT}
      onChange={props.onChange as uiLib.SelectProps['onChange']}
      isLoading={!reduxState.areAuthorsReady || props.isLoading}
      isDisabled={props.isDisabled}
      options={reduxState.options}
      value={formattedData.value}
      loadOptions={handlers.handleFetch}
      shouldFetchDefaultOptions
      shouldCacheFetchedOptions
      shouldKeepInputValue
      isAsync
    />
  );
};
