import { MY_TASKS, LIST_VIEW, HIDE_BLOCKED_TASKS } from 'constants/DocTasks';
import { DEFAULT_TABLE_PARAMS } from '@compliance.ai/web-components';
import {
  SET_CURRENT_TEAM,
  SET_VIEW_TYPE,
  SET_CURRENT_WORKFLOW,
  SET_BLOCKED_TASK_SELECTION,
  SET_CURRENT_CALENDAR_DATE,
  SET_TASKS_TABLE_PARAMS
} from './teamTasks.actions';

export const getInitialState = date => ({
  currentTeam: MY_TASKS,
  viewType: LIST_VIEW,
  currentWorkflow: null,
  blockedTaskSelection: HIDE_BLOCKED_TASKS,
  currentCalendarDate: date,
  tasksTableParams: DEFAULT_TABLE_PARAMS
});

export const teamTaskFilters = (state = getInitialState(new Date()), action) => {
  switch (action.type) {
    case SET_CURRENT_TEAM:
      return {
        ...state,
        currentTeam: action.currentTeam
      };
    case SET_VIEW_TYPE:
      return {
        ...state,
        viewType: action.viewType
      };
    case SET_CURRENT_WORKFLOW:
      return {
        ...state,
        currentWorkflow: action.currentWorkflow
      };
    case SET_BLOCKED_TASK_SELECTION:
      return {
        ...state,
        blockedTaskSelection: action.blockedTaskSelection
      };
    case SET_CURRENT_CALENDAR_DATE:
      return {
        ...state,
        currentCalendarDate: action.currentCalendarDate
      };
    case SET_TASKS_TABLE_PARAMS:
      return {
        ...state,
        tasksTableParams: action.tasksTableParams
      };
    default: {
      return state;
    }
  }
};
