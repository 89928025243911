import * as api from './languages.api';

export const RECEIVE_LANGUAGES = 'RECEIVE_LANGUAGES';

export const updateLanguages = languages => ({
  type: RECEIVE_LANGUAGES,
  payload: languages
});

export const fetchLanguages = () => async dispatch => {
  const languages = await api.getLanguages();
  dispatch(updateLanguages(languages));
};
