import type { DefaultFiltersViewSelectData } from './useDefaultFiltersViewSelectData.types';
import type { DefaultFiltersViewSelectProps } from '../DefaultFiltersViewSelect.types';
import type { GlobalState } from 'shared/reducers';
import type * as uiLib from '@compliance.ai/web-components';

import * as constants from '../DefaultFiltersViewSelect.constants';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDefaultFiltersViewSelectOptions } from './useDefaultFiltersViewSelectOptions';
import { getUserAuthenticatedState } from 'shared/features/auth/auth.selectors';
import { getUserTeams } from 'shared/features/user/user.selectors';

export const useDefaultFiltersViewSelectData = ({
  props
}: {
  props: DefaultFiltersViewSelectProps;
}): DefaultFiltersViewSelectData => {
  const reduxState = useSelector<GlobalState, DefaultFiltersViewSelectData['reduxState']>(state => {
    return {
      teams: getUserTeams(state as never) || [],
      isAuthenticated: getUserAuthenticatedState(state)
    };
  });

  const options = useDefaultFiltersViewSelectOptions({ reduxState });

  const flatOptions = useMemo(() => {
    return options.reduce((flattenOptions, option) => {
      return [...flattenOptions, option, ...((option.options || []) as uiLib.SelectOption[])];
    }, [] as uiLib.SelectOptions);
  }, [options]);

  const formattedData: DefaultFiltersViewSelectData['formattedData'] = useMemo(() => {
    const selectedView = (flatOptions.find(option => option.value === props.view) ||
      null) as uiLib.SelectValue;

    return {
      selectedView,
      options
    };
  }, [flatOptions, options, props.view]);

  return { formattedData, reduxState };
};
