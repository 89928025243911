import lodash from 'lodash';
import { safe_mixpanel_track } from 'utils/analytics';

export const registerDashboardAction = (actionStart, action) => {
  safe_mixpanel_track('Dashboard – ' + action + ' – ' + lodash.capitalize(action) + ' Document', {
    hitType: 'timing',
    timingCategory: 'Dashboard',
    timingVar: action,
    $duration: Date.now() - actionStart,
    timingLabel: lodash.capitalize(action) + ' Document'
  });
};
