import type { FiltersProps } from '@compliance.ai/web-components';

import { convertBasicFiltersToAdvancedSearch } from './useAdvancedSearchQuery.helpers';

import { useMemo } from 'react';

export const useAdvancedSearchQuery = (filterValues: Required<FiltersProps>['values']): string => {
  return useMemo(() => {
    return convertBasicFiltersToAdvancedSearch(filterValues);
  }, [filterValues]);
};
