import type { ConceptsProps } from '../Concepts.types';
import type { GlobalState } from 'shared/reducers';
import type { ConceptsData } from './useConceptsData.types';

import { useSelector } from 'react-redux';
import { getConceptsOptionsWithMappedMetaNamesByIds } from 'shared/features/concepts/concepts.selectors';
import { useMemo } from 'react';

export const useConceptsData = ({ document }: Pick<ConceptsProps, 'document'>): ConceptsData => {
  const conceptIds = useMemo(
    () => (Array.isArray(document.concept_tags) ? document.concept_tags : []),
    [document.concept_tags]
  );

  const reduxState = useSelector<GlobalState, ConceptsData['reduxState']>(state => ({
    conceptOptions: getConceptsOptionsWithMappedMetaNamesByIds(conceptIds)(state)
  }));

  const formattedData: ConceptsData['formattedData'] = useMemo(() => {
    return {
      conceptIds
    };
  }, [conceptIds]);

  return {
    reduxState,
    formattedData
  };
};
