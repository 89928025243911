import { useState } from 'react';

export const useFieldData = () => {
  const [isEditable, setIsEditable] = useState(false);

  const localState = {
    isEditable
  };

  const localActions = {
    setIsEditable
  };

  return {
    localState,
    localActions
  };
};
