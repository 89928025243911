import type { FC } from 'react';

import { Navigate } from 'react-router-dom';

import * as uiLib from '@compliance.ai/web-components';
import * as routes from 'constants/Routes';
import * as common from 'common/index';

import {
  useDiffTabData,
  useDiffTabLifecycle,
  useDiffTabHandlers,
  useDiffTabQueries
} from './hooks';

import './_diff-tab.scss';

export const DiffTab: FC = () => {
  const { reduxState } = useDiffTabData();

  const queries = useDiffTabQueries({
    reduxState
  });

  const handlers = useDiffTabHandlers();

  useDiffTabLifecycle({
    onOverlayOpenOnMount: handlers.handleOverlayOpenOnMount,
    onOverlayCloseOnUnmount: handlers.handleOverlayCloseOnUnmount
  });

  if (!reduxState.isAuthenticated) {
    return <Navigate to={routes.login} />;
  }

  return (
    <div className="diff-tab__container">
      {queries.fetchDiffDocs.isFetching && <uiLib.Loader isContrast withoutBackground={false} />}
      <div className="diff-tab__header">
        <common.DocDiffPrintButton classNames={{ button: 'diff-tab__header-button' }} />
      </div>
      <div className="diff-tab__list">
        {[...reduxState.sortedDocumentsToDiff].reverse().map((document, i) => (
          <common.DocumentTimelineItem
            key={document.id}
            document={document}
            date={document.publication_date}
            dateType="Publication Date"
            shouldShowDetails
            isDiffable
          />
        ))}
      </div>
    </div>
  );
};
