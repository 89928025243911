import * as widgetsConstants from 'shared/features/widgets/widgets.constants';

export const WIDGET_SOURCES = {
  USER: 'user',
  DEFAULT: 'default'
};

export const WIDGET_PROPERTIES = {
  WIDTH: widgetsConstants.WIDGET_PROPERTIES_KEYS.WIDTH,
  EDITABLE_WIDTH: widgetsConstants.WIDGET_PROPERTIES_KEYS.EDITABLE_WIDTH,
  COMPONENT: widgetsConstants.WIDGET_PROPERTIES_KEYS.COMPONENT,
  IS_HIDDEN_WHEN_WIDGET_IS_DEFAULT:
    widgetsConstants.WIDGET_PROPERTIES_KEYS.IS_HIDDEN_WHEN_WIDGET_IS_DEFAULT,
  ALERT_SEARCH_ARGS: widgetsConstants.WIDGET_PROPERTIES_KEYS.ALERT_SEARCH_ARGS,
  EDITABLE: widgetsConstants.WIDGET_PROPERTIES_KEYS.EDITABLE,
  CURRENT_ALERT_ID: widgetsConstants.WIDGET_PROPERTIES_KEYS.CURRENT_ALERT_ID,
  NAME: widgetsConstants.WIDGET_PROPERTIES_KEYS.NAME,
  AGENCIES: widgetsConstants.WIDGET_PROPERTIES_KEYS.AGENCIES,
  ACTS: widgetsConstants.WIDGET_PROPERTIES_KEYS.ACTS,
  DOC_TYPES: widgetsConstants.WIDGET_PROPERTIES_KEYS.DOC_TYPES,
  NEWS: widgetsConstants.WIDGET_PROPERTIES_KEYS.NEWS_AND_PREMIUM_CONTENT,
  JURISDICTIONS: widgetsConstants.WIDGET_PROPERTIES_KEYS.JURISDICTIONS,
  REGULATION: widgetsConstants.WIDGET_PROPERTIES_KEYS.REGULATION,
  REGULATION_TYPE: widgetsConstants.WIDGET_PROPERTIES_KEYS.REGULATION_TYPE,
  TIMEFRAME: widgetsConstants.WIDGET_PROPERTIES_KEYS.TIMEFRAME,
  TOPICS: widgetsConstants.WIDGET_PROPERTIES_KEYS.TOPICS
};

export const DRAGGABLE_WIDGET_CLASSNAME = 'dash-drag-handle';

// Backwards compatability
export const WIDGET_TIMEFRAME_UNITS_SELECT_OPTIONS =
  widgetsConstants.WIDGET_TIMEFRAME_UNITS_OPTIONS;
export const WIDGET_TIMEFRAME_UNITS = WIDGET_TIMEFRAME_UNITS_SELECT_OPTIONS.map(o => o.value);
export const WIDGET_UNITS_OF_TIME = widgetsConstants.WIDGET_UNITS_OF_TIME;
export const WIDGET_DEFAULT_TIMEFRAME =
  widgetsConstants.WIDGET_DEFAULT_PROPERTIES[widgetsConstants.WIDGET_PROPERTIES_KEYS.TIMEFRAME];
export const WIDGET_DEFAULT_UNIT_OF_TIME =
  widgetsConstants.WIDGET_DEFAULT_PROPERTIES[
    widgetsConstants.WIDGET_PROPERTIES_KEYS.TIMEFRAME_UNIT
  ];
