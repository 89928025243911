import type * as actionsTypes from './filterGroups.actions.types';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { logError } from 'utils/errors';

import * as api from './filterGroups.api';
import * as mocks from './filterGroups.mockData';
import * as helpers from './filterGroups.helpers';
import * as env from 'shared/config';

export const FETCH_FILTER_GROUPS = 'FETCH_FILTER_GROUPS';

export const fetchFilterGroups = createAsyncThunk<
  actionsTypes.FetchFilterGroupsReturn,
  actionsTypes.FetchFilterGroupsPayload
>(FETCH_FILTER_GROUPS, async () => {
  try {
    if (env.shouldMockFilterGroupsResponse) {
      return helpers.normalizeFilterGroups(mocks.MOCK_FILTER_GROUPS_RESPONSE);
    }

    const response = await api.fetchFilterGroups();

    return helpers.normalizeFilterGroups(response);
  } catch (e) {
    logError(e as Error, 'Error', { isSilent: true });
    return [];
  }
});
