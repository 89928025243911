export const TEST_IDS = {
  CONTAINER: 'workflow-diagram-container'
};

export const NODE_TYPES = {
  TASK: 'task'
};

export const NODE_INITIAL_POSITION = { x: 0, y: 0 };

export enum NODE_LINK_POINT_POSITIONS {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left'
}

export enum NODE_LINK_POINT_TYPES {
  SOURCE = 'source',
  TARGET = 'target'
}

export const NODE_GRAPH_WIDTH = 350;
export const NODE_GRAPH_HEIGHT = 250;

export const GRAPH_DIRECTIONS = {
  FROM_TOP_TO_BOTTOM: 'TB',
  FROM_BOTTOM_TO_TOP: 'BT',
  FROM_LEFT_TO_RIGHT: 'LR'
};

export enum DATE_TYPES {
  PUBLICATION_DATE = 'publication_date',
  EFFECTIVE_ON = 'effective_on',
  COMMENTS_CLOSE_ON = 'comments_close_on',
  TASK_CREATION = 'task_creation',
  TASK_DATE_DEPENDENCY = 'task_date_dependency'
}

export const DATE_LABELS = {
  [DATE_TYPES.PUBLICATION_DATE]: 'Publication Date',
  [DATE_TYPES.EFFECTIVE_ON]: 'Effective Date',
  [DATE_TYPES.COMMENTS_CLOSE_ON]: 'Comments Close',
  [DATE_TYPES.TASK_CREATION]: 'Task Creation',
  [DATE_TYPES.TASK_DATE_DEPENDENCY]: 'Task Completion'
};

export const MIN_ZOOM = -100;

export const DIAGRAM_VIEWPORT_SELECTOR = '.react-flow__viewport';
export const DIAGRAM_EDGES_ELEMENT_SELECTOR = '.react-flow__edges';
