import type { DocumentLabelsData } from './useDocumentLabelsData.types';
import type { GlobalState } from 'shared/reducers';

import * as authSelectors from 'shared/features/auth/auth.selectors';

import { useSelector } from 'react-redux';

export const useDocumentLabelsData = (): DocumentLabelsData => {
  const reduxState = useSelector<GlobalState, DocumentLabelsData['reduxState']>(state => ({
    isAuthenticated: authSelectors.getUserAuthenticatedState(state)
  }));

  return {
    reduxState
  };
};
