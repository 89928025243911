import type { TaskDropdownsAnnotationProps } from './TaskDropdownsAnnotation.types';
import type { FC } from 'react';

import * as workflowConstants from 'shared/features/workflow/workflow.constants';
import * as elements from './elements';

import { useDocumentTaskAnnotations } from 'shared/features/workflow/hooks';

import './_task-dropdowns-annotation.scss';

export const TaskDropdownsAnnotation: FC<TaskDropdownsAnnotationProps> = ({
  documentTask,
  isEditable,
  onChange
}) => {
  const annotation = useDocumentTaskAnnotations(documentTask)[
    workflowConstants.WORKFLOW_ANNOTATION_TYPES.DROPDOWNS
  ];

  if (!annotation.isAvailable && !annotation.dropdowns.length) {
    return null;
  }

  return (
    <div className="task-dropdowns-annotation__container">
      {annotation.dropdowns.map(dropdown => (
        <elements.Dropdown
          key={dropdown.id}
          isEditable={isEditable}
          dropdown={dropdown}
          onChange={onChange}
        />
      ))}
    </div>
  );
};
