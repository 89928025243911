import type { AssigneeCellProps } from '../AssigneeCell.types';
import type { GlobalState } from '../../../../../../../../../../../shared/reducers';
import type { AssigneeCellData } from './useAssigneeCellData.types';

import * as orgSelectors from 'shared/features/organizations/organizations.selectors';
import * as helpers from './useAssigneeCellData.helpers';

import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const useAssigneeCellData = ({
  cellValue
}: Pick<AssigneeCellProps, 'cellValue'>): AssigneeCellData => {
  const reduxState = useSelector<GlobalState, AssigneeCellData['reduxState']>(state => ({
    orgMembers: orgSelectors.getOrganizationMembers(state)
  }));

  const formattedData: AssigneeCellData['formattedData'] = useMemo(() => {
    return {
      assignee: helpers.formatAssignee({
        documentTask: cellValue,
        orgMembers: reduxState.orgMembers
      })
    };
  }, [cellValue, reduxState.orgMembers]);

  return {
    reduxState,
    formattedData
  };
};
