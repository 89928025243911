import type { EitlStatusProps } from '../EitlStatus.types';
import type { EitlStatusData } from './useEitlStatusData.types';

import * as eitlLabelsApiHelpers from 'shared/features/eitlLabels/eitlLabels.api.helpers';
import * as env from 'shared/config';

import { useMemo } from 'react';

export const useEitlStatusData = ({
  props
}: {
  props: Pick<EitlStatusProps, 'document'>;
}): EitlStatusData => {
  const formattedData: EitlStatusData['formattedData'] = useMemo(() => {
    const eitlLabelsOptions =
      props.document?.eitl_labels?.map(eitlLabelsApiHelpers.formatEitlLabelsOption) ?? [];

    return {
      eitlLabelsOptions,
      shouldRenderComponent: Boolean(eitlLabelsOptions.length) && Boolean(env.shouldShowEitlStatus)
    };
  }, [props.document]);

  return {
    formattedData
  };
};
