import * as helpers from '../AutocompleteSearchField.helpers';
import * as constants from '../AutocompleteSearchField.constants';
import * as viewConstants from 'shared/features/view/view.constants';

import { useViewReduxActions } from 'shared/features/view/hooks';
import { useAutocompleteSearchFieldReduxActions } from './useAutocompleteSearchFieldReduxActions';

export const useAutocompleteSearchFieldHandlers = ({
  localState,
  localActions,
  history,
  reduxState
}) => {
  const viewReduxActions = useViewReduxActions();

  const reduxActions = useAutocompleteSearchFieldReduxActions();

  const handleOutsideClick = () => {
    if (reduxState.current_view.displayFilter) {
      viewReduxActions.closeFilter();
    }
    if (reduxState.current_view.displayAdvancedSearchFilter) {
      viewReduxActions.closeAdvancedSearchFilter();
    }
  };

  const handleViewLeave = () => {
    localActions.changeCurrentView(viewConstants.VIEW_KEY.ALL);
  };

  const handleOnChange = newSearchTerm => {
    if (newSearchTerm && newSearchTerm.length > 2) {
      reduxActions.throttledFetchAutoComplete(newSearchTerm);
    } else {
      reduxActions.clearAutoComplete();
    }

    if (localState.searchTerm !== newSearchTerm && (localState.searchTerm || newSearchTerm)) {
      localActions.changeSearchTerm(newSearchTerm);
    }
  };

  const handleInputChange = (e, { newValue, method }) => {
    if (method === 'type' && newValue === '') {
      handleOnChange('');
    }
  };

  const handleEnterPress = e => {
    if (e.key === constants.ENTER_KEY) {
      handleViewLeave();
      helpers.setSearchTermToUrl({
        history,
        localState,
        localActions,
        reduxState,
        reduxActions
      });
    }
  };

  const handleInputFocus = () => {
    localActions.toggleSuggestionsMenuVisibility(true);
  };

  const handleSearchTermTrigger = () => {
    handleViewLeave();

    helpers.setSearchTermToUrl({
      history,
      localState,
      localActions,
      reduxState,
      reduxActions
    });
  };

  const handleAutosuggestItemClick = suggestion => {
    helpers.selectAutosuggestItem({
      history,
      localState,
      localActions,
      reduxState,
      reduxActions,
      suggestion
    });
  };

  const handleSuggestionsMenuOutsideClick = () => {
    localActions.toggleSuggestionsMenuVisibility(false);
  };

  return {
    handleOutsideClick,
    handleViewLeave,
    handleOnChange,
    handleInputChange,
    handleEnterPress,
    handleInputFocus,
    handleSearchTermTrigger,
    handleAutosuggestItemClick,
    handleSuggestionsMenuOutsideClick
  };
};
