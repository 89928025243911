import type { FC } from 'react';
import type { AccountMenuProps } from './AccountMenu.types';

import * as uiLib from '@compliance.ai/web-components';

import * as firstLoginConstants from 'constants/FirstLogin';

import { useAccountMenuData, useAccountMenuHandlers, useAccountMenuOptions } from './hooks';

import './_account-menu.scss';

export const AccountMenu: FC<AccountMenuProps> = ({ className }) => {
  const { localState, localActions, reduxState } = useAccountMenuData();

  const handlers = useAccountMenuHandlers({
    localActions
  });

  const options = useAccountMenuOptions({
    reduxState,
    events: {
      onLogOut: handlers.handleLogOut
    }
  });

  if (!reduxState.isAuthenticated) {
    return null;
  }

  return (
    <uiLib.Dropdown
      isOpen={localState.isOpen}
      onClose={handlers.handleMenuToggle(false)}
      triggerContent={
        <button
          className="account-menu__account-button"
          id={firstLoginConstants.JOYRIDE_TARGET_IDS.ACCOUNT_MENU}
          onClick={handlers.handleMenuToggle(true)}
        >
          <uiLib.IconAccountRounded className="account-menu__account-icon" />
          {reduxState.isAccountWithin10DaysOfExpiration && (
            <uiLib.IconLens className="account-menu__outer-notification" />
          )}
        </button>
      }
      dropdownContent={
        <uiLib.List
          options={options}
          selectedOptions={[]}
          onOptionClick={handlers.handleOptionClick}
          listClassName="account-menu__dropdown-list"
        />
      }
      className="account-menu__dropdown"
      containerClassName={className}
    />
  );
};
