import type { AlertsTableProps } from './AlertsTable.types';
import type { FC } from 'react';

import { SELECTED_ROWS_COUNT_PLACEMENT, Table, TABLE_PARAMS } from '@compliance.ai/web-components';
import { TABLE_KEYS } from './AlertsTable.constants';
import classnames from 'classnames';
import { useAlertsApi } from 'shared/features/alerts/hooks';
import {
  useAlertsTableColumns,
  useAlertsTableData,
  useAlertsTableHandlers,
  useAlertsTableLifecycle
} from './hooks';
import { AlertsTableFilters } from './elements';
import './_alerts-table.scss';

export const AlertsTable: FC<AlertsTableProps> = ({
  selectedAlertIds,
  maxSelectableAlertsCount,
  shouldShowSelectMaxRowsButton,
  onSelectedAlertsChange,
  classNames
}) => {
  const { prevProps, localState, localActions } = useAlertsTableData();

  const alertsApi = useAlertsApi();

  const handlers = useAlertsTableHandlers({
    props: {
      selectedAlertIds,
      maxSelectableAlertsCount,
      onSelectedAlertsChange
    },
    prevProps,
    localState,
    localActions,
    alertsApi
  });

  useAlertsTableLifecycle({
    onRequestParamsChange: handlers.handleRequestParamsChange
  });

  const columns = useAlertsTableColumns();

  return (
    <Table
      columns={columns}
      customActionsElements={
        <AlertsTableFilters
          onSubmit={handlers.handleFiltersChange}
          className="alerts-table__filters"
        />
      }
      reloadTrigger={localState.tableRefreshTrigger}
      isLoading={localState.isLoading}
      selectedRowsCountPlacement={SELECTED_ROWS_COUNT_PLACEMENT.TABLE}
      rowKey={TABLE_KEYS.ID}
      areRowsSelectable
      onDataFetch={handlers.handleAlertsFetch}
      onSelectedRowKeysChange={handlers.handleSelectedAlertsChange}
      onSelectAllClick={handlers.handleAlertsIdsFetch}
      externalParams={{
        [TABLE_PARAMS.LIMIT]: localState.tableParams[TABLE_PARAMS.LIMIT],
        [TABLE_PARAMS.OFFSET]: localState.tableParams[TABLE_PARAMS.OFFSET]
      }}
      maxAllowedRowsCount={maxSelectableAlertsCount}
      shouldShowSelectMaxRowsButton={shouldShowSelectMaxRowsButton}
      classNames={{
        container: classNames?.container,
        tableContainer: classNames?.tableContainer,
        table: classNames?.table,
        toolbar: classnames('alerts-table__toolbar', classNames?.toolbar),
        selectedRowsCount: classnames(
          'alerts-table__selected_rows_count',
          classNames?.selectedRowsCount
        ),
        row: classnames('alerts-table__row', classNames?.row),
        rowSelected: classnames('alerts-table__row--is-selected', classNames?.rowSelected),
        cell: classnames('alerts-table__cell', classNames?.cell),
        checkboxCell: classnames('alerts-table__cell--is-checkbox', classNames?.checkboxCell)
      }}
    />
  );
};

AlertsTable.defaultProps = {
  selectedAlertIds: [],
  maxSelectableAlertsCount: undefined,
  shouldShowSelectMaxRowsButton: true
};
