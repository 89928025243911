import type { AlertsTableFiltersHandlers } from './useAlertsTableFiltersHandlers.types';
import type { AlertsTableFiltersData } from './useAlertsTableFiltersData.types';
import type { AlertsTableFiltersProps } from '../AlertsTableFilters.types';

import { formatAlertsQueryParams } from './useAlertsTableFiltersHandlers.helpers';

export const useAlertsTableFiltersHandlers = ({
  props,
  localActions
}: {
  props: Pick<AlertsTableFiltersProps, 'onSubmit'>;
  localActions: AlertsTableFiltersData['localActions'];
}): AlertsTableFiltersHandlers => {
  const handleFiltersSubmit: AlertsTableFiltersHandlers['handleFiltersSubmit'] = (
    values,
    searchValue
  ) => {
    localActions.setValues(values);
    localActions.setSearchValue(searchValue);

    props.onSubmit?.(formatAlertsQueryParams(values, searchValue));
  };

  return {
    handleFiltersSubmit
  };
};
