import * as apiTypes from './rules.api.types';

import { fetch } from 'fetch';

export const fetchRules: apiTypes.FetchRules = params => {
  return fetch({
    url: '/rules',
    method: 'GET',
    params
  });
};

export const createRule: apiTypes.CreateRule = params => {
  return fetch({
    url: '/rules',
    method: 'POST',
    params
  });
};

export const updateRule: apiTypes.UpdateRule = (id, params) => {
  return fetch({
    url: `/rules/${id}`,
    method: 'PATCH',
    params
  });
};

export const deleteRule: apiTypes.DeleteRule = id => {
  return fetch({
    url: `/rules/${id}`,
    method: 'DELETE'
  });
};
