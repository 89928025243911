import PropTypes from 'prop-types';
import { Button, BUTTON_TYPES, Modal, SELECT_TYPES, Select } from '@compliance.ai/web-components';
import { useLabelsMoveModalHandlers } from './hooks';
import { useOrganizationLabelsAsyncSelectOptions } from 'shared/features/labels/hooks/useOrganizationLabelsAsyncSelectOptions';
import './_labels-move-modal.scss';

export const LabelsMoveModal = ({ onClose, updateLabel, label, onConfirm }) => {
  const { handlers: labelOptionsHandlers } = useOrganizationLabelsAsyncSelectOptions();

  const { handleOptionChange, handleInputChange } = useLabelsMoveModalHandlers({
    props: { updateLabel }
  });

  return (
    <Modal title="Move Documents" className="labels-move-modal" isOpen onClose={onClose}>
      <Select
        type={SELECT_TYPES.DEFAULT}
        isAsync
        inputValue={label.label}
        shouldCacheFetchedOptions
        shouldFetchDefaultOptions
        shouldKeepInputValue
        shouldKeepInputValueOnOptionClick
        isClearable={false}
        loadOptions={labelOptionsHandlers.handleFetch}
        onChange={handleOptionChange}
        onInputChange={handleInputChange}
      />
      <div className="labels-move-modal__footer">
        <Button type={BUTTON_TYPES.LINK} onClick={onClose}>
          Cancel
        </Button>
        <Button className="labels-move-modal__submit" disabled={!label.label} onClick={onConfirm}>
          Move Here
        </Button>
      </div>
    </Modal>
  );
};

LabelsMoveModal.propTypes = {
  label: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  updateLabel: PropTypes.func.isRequired
};
