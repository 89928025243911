import type { DocumentsFiltersAsyncLoaders } from './useDocumentsFiltersAsyncLoaders.types';
import type { AuthorFromResponse } from 'shared/features/authors/authors.api.types';

import * as common from 'common';
import * as labelsHelpers from 'shared/features/labels/labels.helpers';
import * as labelsConstants from 'shared/features/labels/labels.constants';
import * as editLabelsApi from 'shared/features/eitlLabels/eitlLabels.api';
import * as eitlLabelsApiHelpers from 'shared/features/eitlLabels/eitlLabels.api.helpers';
import * as errorUtils from 'utils/errors';
import * as sortUtils from 'utils/sort';

import { FILTER_KEY } from 'constants/PrimaryFilter';

import { useSearchReduxActions } from 'shared/features/search/hooks';
import { useOrganizationLabelsReduxActions } from 'shared/features/labels/hooks';
import { useAuthorsReduxActions } from 'shared/features/authors/hooks';

export const useDocumentsFiltersAsyncLoaders = (): DocumentsFiltersAsyncLoaders => {
  const searchReduxActions = useSearchReduxActions();
  const orgLabelsReduxActions = useOrganizationLabelsReduxActions();
  const authorsReduxActions = useAuthorsReduxActions();

  const handleAutocompleteFetch = (category: string) => async (inputValue: string) => {
    try {
      const response = await searchReduxActions.fetchAutoComplete(inputValue, category);

      return response.results.map(item => ({
        label: item.name,
        value: item.id
      }));
    } catch (e) {
      errorUtils.logError(e as Error);

      return [];
    }
  };

  const handleLabelsFetch = async (inputString: string) => {
    try {
      const { labels } = await orgLabelsReduxActions.fetchAllLabels({
        name: inputString,
        count_per_page: labelsConstants.MAX_LABELS_COUNT
      });

      return labelsHelpers.getLabelsGroupedOptions(labels);
    } catch (e) {
      /**
       * There is no need to use logError because action is already using it under the hood
       */
      console.error(e);
      return [];
    }
  };

  const handleEitlLabelsFetch = async (inputString: string) => {
    try {
      const response = await editLabelsApi.getEitlLabels({
        name: inputString.toLowerCase(),
        limit: common.EITL_LABELS_COUNT_PER_REQUEST
      });

      const options = eitlLabelsApiHelpers.formatEitlLabelsOptions(response);

      return common.addNoEitlLabelsOption({
        options,
        shouldShowNoLabelsOption: true
      });
    } catch (e) {
      errorUtils.logError(e as Error);

      return [];
    }
  };

  const handleAuthorsFetch = async (inputString: string) => {
    try {
      const response = (await authorsReduxActions.fetchAuthors({}))?.payload as AuthorFromResponse;

      if (response) {
        const options = response.authors
          ?.filter(author => author.name.toLowerCase().includes(inputString.toLowerCase()))
          .map(item => ({
            ...item,
            label: item.name,
            value: item.id
          }))
          .sort(sortUtils.alphabeticallyByLabel);

        return options || [];
      }
      return [];
    } catch (e) {
      errorUtils.logError(e as Error);
      return [];
    }
  };

  return {
    [FILTER_KEY.ACTS]: handleAutocompleteFetch(FILTER_KEY.ACTS),
    [FILTER_KEY.BANKS]: handleAutocompleteFetch(FILTER_KEY.BANKS),
    [FILTER_KEY.LABELS]: handleLabelsFetch,
    [FILTER_KEY.EITL_LABELS]: handleEitlLabelsFetch,
    [FILTER_KEY.AUTHOR]: handleAuthorsFetch
  };
};
