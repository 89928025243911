import type { FC } from 'react';
import type { TitleCellProps } from './TitleCell.types';

import classnames from 'classnames';

import * as constants from '../../ManageBucketAccess.constants';

import './_title-cell.scss';

export const TitleCell: FC<TitleCellProps> = ({ row }) => {
  return (
    <div
      className={classnames('title-cell__container', {
        'title-cell__container--is-group-header': row?.[constants.TABLE_KEYS.IS_GROUP_HEADER]
      })}
    >
      {row?.[constants.TABLE_KEYS.NAME] as string}
    </div>
  );
};
