import type { ConceptsData } from './useConceptsData.types';

import { useMemo, useState } from 'react';

export const useConceptsData = (): ConceptsData => {
  const [value, setValue] = useState<ConceptsData['localState']['value']>([]);
  const [query, setQuery] = useState<ConceptsData['localState']['query']>('');

  const localState: ConceptsData['localState'] = {
    value,
    query
  };

  const localActions: ConceptsData['localActions'] = useMemo(
    () => ({
      setValue,
      setQuery
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
