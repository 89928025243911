export const normalizeCompany = ({
  address_administrative_area = '',
  address_country = '',
  address_country_jurisdiction_id = null,
  address_locality = '',
  address_postal_code = '',
  address_premise = '',
  address_thoroughfare = '',
  annual_revenue = null,
  compliance_team_size = null,
  currency = '',
  id = null,
  jurisdiction_details = [],
  name = '',
  number_of_employees = null,
  website = '',
  year_started = ''
}) => {
  return {
    addressAdministrativeArea: address_administrative_area,
    addressCountry: address_country,
    addressCountryJurisdictionId: address_country_jurisdiction_id,
    addressLocality: address_locality,
    addressPostalCode: address_postal_code,
    addressPremise: address_premise,
    addressThoroughfare: address_thoroughfare,
    annualRevenue: annual_revenue,
    complianceTeamSize: compliance_team_size,
    currency: currency,
    id: id,
    jurisdictionDetails: jurisdiction_details.map(normalizeJurisdictionDetails),
    name: name,
    numberOfEmployees: number_of_employees,
    website: website,
    yearStarted: year_started
  };
};

export const normalizeJurisdictionDetails = ({
  charter_class = [],
  jurisdiction_id = null,
  product_service_ids = []
}) => {
  return {
    charterClass: charter_class,
    id: jurisdiction_id,
    productServiceIds: product_service_ids
  };
};

export const normalizeProductService = ({
  business_line = '',
  business_line_id = null,
  id = null,
  industry = '',
  industry_id = null,
  product_service = '',
  segments = []
}) => {
  return {
    businessLine: business_line,
    businessLineId: business_line_id,
    id: id,
    industry: industry,
    industryId: industry_id,
    productService: product_service,
    segments: segments.map(normalizeProductServiceSegment)
  };
};

export const normalizeProductServiceSegment = ({ criteria = {}, mappings = {} }) => {
  return {
    criteria: normalizeProductServiceSegmentCriteria(criteria),
    mappings: normalizeProductServiceSegmentMappings(mappings)
  };
};

export const normalizeProductServiceSegmentCriteria = ({ aum = [] }) => {
  return {
    aum
  };
};

export const normalizeProductServiceSegmentMappings = ({
  acts = [],
  agencies = [],
  news_sources = [],
  regulations = [],
  topics = [],
  concepts = []
}) => {
  return {
    acts: acts,
    agencies: agencies,
    newsSources: news_sources,
    regulations: regulations,
    topics: topics,
    concepts: concepts
  };
};

export const normalizeUsersOrgProfile = ({
  address_thoroughfare = '',
  address_premise = '',
  address_locality = '',
  address_administrative_area = '',
  address_postal_code = '',
  address_country = '',
  primary_jurisdiction = null,
  id = null,
  name = '',
  website = '',
  jurisdictions = [],
  proposed_businesses = {},
  businesses = {},
  regulators = {},
  organization_profile_saved = false
}) => ({
  orgProfile: {
    thoroughfare: address_thoroughfare || '',
    premise: address_premise || '',
    locality: address_locality || '',
    administrativeArea: address_administrative_area || '',
    postalCode: address_postal_code || '',
    country: address_country || '',
    primaryJurisdiction: primary_jurisdiction,
    id: id || null,
    name: name || '',
    website: website || '',
    jurisdictions: jurisdictions || [],
    proposedBusinesses: proposed_businesses || {},
    businesses: businesses || {},
    regulators: regulators || {}
  },
  orgProfileSaved: organization_profile_saved || false
});
