import type { AppSearchData } from './useAppSearchData.types';
import type { AppSearchHandlers } from './useAppSearchHandlers.types';

import * as routes from 'constants/Routes';
import * as primaryFilterConstants from 'constants/PrimaryFilter';

import { usePreviousValue } from '@compliance.ai/web-components';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useViewReduxActions } from 'shared/features/view/hooks';
import { useQueryParams } from 'utils/hooks';

export const useAppSearchHandlers = ({
  localActions
}: {
  localActions: AppSearchData['localActions'];
}): AppSearchHandlers => {
  const queryParams = useQueryParams();
  const viewReduxActions = useViewReduxActions();
  const location = useLocation();
  const prevLocation = usePreviousValue(location);

  const advancedSearchQueryParam =
    queryParams[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.ADVANCED_SEARCH];

  const handleSearchFieldTypeQueryParamChange: AppSearchHandlers['handleSearchFieldTypeQueryParamChange'] = useCallback(() => {
    localActions.setIsAdvancedSearch(Boolean(advancedSearchQueryParam));
  }, [advancedSearchQueryParam, localActions]);

  const handleLocationChange: AppSearchHandlers['handleLocationChange'] = useCallback(() => {
    if (
      location.pathname !== prevLocation.pathname &&
      ![
        routes.content,
        routes.resources,
        routes.news,
        routes.timeline,
        routes.enforcementExplorer
      ].includes(location.pathname)
    ) {
      viewReduxActions.removeEditAlert();
      viewReduxActions.removeViewAlert();
    }
  }, [location.pathname, prevLocation.pathname, viewReduxActions]);

  return {
    handleSearchFieldTypeQueryParamChange,
    handleLocationChange
  };
};
