import PropTypes from 'prop-types';
import { Select, SELECT_TYPES, LabeledField } from '@compliance.ai/web-components';
import helpers from './TaskAssigneeAnnotation.helpers';
import { useTaskAssigneeAnnotationData, useTaskAssigneeAnnotationHandlers } from './hooks';
import { useDocumentTaskAnnotations } from 'shared/features/workflow/hooks';
import { WORKFLOW_ANNOTATION_TYPES } from 'shared/features/workflow/workflow.constants';
import './_task-assignee-annotation.scss';

export const TaskAssigneeAnnotation = ({ documentTask, value, isEditable, onChange }) => {
  const annotation = useDocumentTaskAnnotations(documentTask)[WORKFLOW_ANNOTATION_TYPES.ASSIGNEES];

  const { formattedData } = useTaskAssigneeAnnotationData({ documentTask });

  const handlers = useTaskAssigneeAnnotationHandlers({ props: { documentTask, onChange } });

  if (!annotation.isAvailable) {
    return null;
  }

  if (!isEditable && value.length) {
    return (
      <LabeledField label={annotation.label} isContrast>
        <div className="task-assignee-annotation__non-editable-input-wrapper">
          <input
            type="text"
            className="task-assignee-annotation__input"
            value={helpers.convertOptionsToString(value, formattedData.potentialAssignees)}
            disabled
          />
        </div>
      </LabeledField>
    );
  }

  if (isEditable) {
    return (
      <LabeledField label={annotation.label} isContrast>
        <Select
          type={SELECT_TYPES.DEFAULT}
          className="task-assignee-annotation__assignee"
          options={helpers.assigneeOptionsToSelectOptions(formattedData.potentialAssignees)}
          value={value}
          isMulti
          withCheckboxes
          onChange={handlers.handleAssigneeSelections}
        />
      </LabeledField>
    );
  }

  return null;
};

TaskAssigneeAnnotation.propTypes = {
  documentTask: PropTypes.shape({}).isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isEditable: PropTypes.bool.isRequired
};
