import type { FC } from 'react';
import type { QueryProps } from './Query.types';

import React, { useCallback } from 'react';
import { Concepts, ReadableQuery } from './elements';
import { Button, BUTTON_TYPES, ErrorBoundary } from '@compliance.ai/web-components';

import * as queryBuilder from 'react-awesome-query-builder';
import * as advancedSearchConstants from 'constants/AdvancedSearch';

import { useQueryData, useQueryHandlers } from './hooks';

import './_query.scss';

export const Query: FC<QueryProps> = ({ value, onSubmit }) => {
  const { localState, localActions, reduxState, formattedData } = useQueryData({ value });

  const handlers = useQueryHandlers({
    props: {
      value,
      onSubmit
    },
    reduxState,
    localState,
    localActions,
    formattedData
  });

  return (
    <ErrorBoundary>
      <header className="advanced-search-query__header">
        <Concepts onChange={handlers.handleConceptsChange} />
      </header>
      <section className="advanced-search-query__main">
        <queryBuilder.Query
          conjunctions={formattedData.config.conjunctions}
          operators={formattedData.config.operators}
          widgets={formattedData.config.widgets}
          types={formattedData.config.types}
          settings={formattedData.config.settings}
          fields={formattedData.config.fields}
          value={localState.tree}
          onChange={localActions.setTree}
          // No, you can't pass it like renderBuilder={Builder} because this "awesome" library will break
          renderBuilder={useCallback(
            props => (
              // You must pass this classname to make drag'n'drop work
              // It looks like this classname is hardcoded on the library level
              <div className={advancedSearchConstants.DND_CLASSNAME}>
                <queryBuilder.Builder {...props} />
              </div>
            ),
            []
          )}
        />
      </section>
      <footer className="advanced-search-query__footer">
        <ReadableQuery config={formattedData.config} tree={localState.tree} />
        <div className="advanced-search-query__footer-buttons-container">
          <Button
            type={BUTTON_TYPES.LINK}
            className="advanced-search-query__footer-button"
            onClick={handlers.handleQueryClear}
          >
            Clear Filter
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            className="advanced-search-query__footer-button"
            onClick={handlers.handleQuerySubmit}
          >
            Search
          </Button>
        </div>
      </footer>
    </ErrorBoundary>
  );
};
