export const normalizeComment = ({
  id = null,
  richtext = null,
  user_id = null,
  created_at = null,
  updated_at = null,
  is_root_comment = false,
  comment_thread_id = null
}) => ({
  id,
  richtext,
  userId: user_id,
  createdAt: created_at,
  updatedAt: updated_at,
  isRootComment: is_root_comment,
  commentThreadId: comment_thread_id
});

export const normalizeCommentThread = ({
  id = null,
  deleted = false,
  org_id = null,
  comments = []
}) => {
  return {
    id,
    deleted,
    orgId: org_id,
    comments: Array.isArray(comments) ? comments.map(normalizeComment) : []
  };
};

export const normalizeCommentThreads = commentThreads => {
  if (Array.isArray(commentThreads)) {
    return commentThreads.map(normalizeCommentThread);
  }

  return [];
};
