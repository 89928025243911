import type { AddRelatedDocumentAlertsModalHandlers } from './useAddRelatedDocumentAlertsModalHandlers.types';
import type { AddRelatedDocumentAlertsModalLocalActions } from './useAddRelatedDocumentAlertsModalData.types';

export const useAddRelatedDocumentAlertsModalHandlers = ({
  localActions
}: {
  localActions: AddRelatedDocumentAlertsModalLocalActions;
}): AddRelatedDocumentAlertsModalHandlers => {
  const handleSelectedAlertsChange: AddRelatedDocumentAlertsModalHandlers['handleSelectedAlertsChange'] = ids => {
    localActions.setAlertIds(ids.map(Number));
  };

  return {
    handleSelectedAlertsChange
  };
};
