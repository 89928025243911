import type { AgenciesSelectHandlers } from './useAgenciesSelectHandlers.types';
import type { AgenciesSelectProps } from '../AgenciesSelect.types';

import { api_fetchAgencies } from 'shared/features/agencies/agencies.api';
import {
  AGENCIES_COUNT_PER_REQUEST,
  AGENCIES_SELECT_VALUE_KEYS
} from '../AgenciesSelect.constants';
import { formatAgenciesOptions } from 'shared/features/agencies/agencies.helpers';

export const useAgenciesSelectHandlers = ({
  props
}: {
  props: Pick<AgenciesSelectProps, 'valueKey'>;
}): AgenciesSelectHandlers => {
  const handleAgenciesFetch: AgenciesSelectHandlers['handleAgenciesFetch'] = async inputValue => {
    try {
      const response = await api_fetchAgencies({
        short_name: inputValue.toLowerCase(),
        limit: AGENCIES_COUNT_PER_REQUEST
      });

      return formatAgenciesOptions(response.agencies ?? []).map(option => ({
        ...option,
        value: option[props.valueKey ?? AGENCIES_SELECT_VALUE_KEYS.ID]
      }));
    } catch (e) {
      return [];
    }
  };

  return {
    handleAgenciesFetch
  };
};
