import type { GlobalState } from '../../reducers';
import type { CurrentUserFromResponse, UserFromResponse } from './user.types';
import type { NormalizedResourcesJurisdiction } from '../resources/resources.types';

import * as userHelpers from './user.helpers';
import lodash from 'lodash';

import { createSelector } from '@reduxjs/toolkit';

export const getCurrentUserReducer = (state: GlobalState): GlobalState['current_user'] =>
  state.current_user;

export const getUser = (state: GlobalState): CurrentUserFromResponse =>
  getCurrentUserReducer(state).user;
export const getUserEmail = createSelector(getUser, user => user?.email);
export const getUserId = createSelector(getUser, user => user?.id);
export const getCurrentUserOption = createSelector(getUser, user => {
  return user
    ? {
        value: user.id,
        label: user.email
      }
    : null;
});
export const getCurrentUserReadyStatus = createSelector(
  getCurrentUserReducer,
  currentUserReducer => currentUserReducer.isReady
);
export const isUserCurrentUser = (userId: UserFromResponse['id']) =>
  createSelector(getUserId, currentUserId => {
    return currentUserId === userId;
  });

export const getCurrentUserOrgRoles = createSelector(
  getUser,
  (user): CurrentUserFromResponse['organization']['org_user_roles'] => {
    if (!Array.isArray(user?.organization?.org_user_roles)) {
      return [];
    }

    return user.organization.org_user_roles;
  }
);

export const checkCurrentUserRole = createSelector(
  [getCurrentUserOrgRoles, (state, role: string) => role],
  (orgRoles, role): boolean => {
    return Boolean(orgRoles.find(({ name }) => name === role));
  }
);

export const getCurrentUserUpdatingStatus = createSelector(
  getCurrentUserReducer,
  currentUserReducer => currentUserReducer.isUpdating
);

export const getCurrentUserUpdatedStatus = createSelector(
  getCurrentUserReducer,
  currentUserReducer => {
    return currentUserReducer.updated;
  }
);

export const getCurrentUserProperties = createSelector(getUser, currentUser => {
  return currentUser?.properties;
});

export const getCurrentUserLastDocTypesUpdateNotification = createSelector(
  getCurrentUserProperties,
  properties => {
    return properties?.lastAcknowledgedDocTypesNotification;
  }
);

export const getCurrentUserLastViewedAgency = createSelector(
  getCurrentUserProperties,
  properties => {
    return properties?.lastViewedAgency;
  }
);

export const getCurrentUserViewedAgencies = createSelector(getCurrentUserProperties, properties => {
  return properties?.agencies_viewed ?? [];
});

export const getCurrentUserPermissions = createSelector(
  getCurrentUserReducer,
  (currentUserReducer): Record<string, boolean> => {
    return userHelpers.formatPermissions(currentUserReducer.user.organization_roles || []);
  }
);

export const getUpgradeBannerOpen = (state: GlobalState) =>
  getCurrentUserReducer(state).upgradeBannerOpen;
export const getCurrentUserOrganization = (state: GlobalState) =>
  getCurrentUserReducer(state).user?.organization;
export const getCurrentUserOrganizationId = createSelector(
  getCurrentUserOrganization,
  organization => organization?.id
);
export const getCurrentUserRoles = (state: GlobalState) => getCurrentUserReducer(state).user.roles;
export const getIsCurrentUserLoading = (state: GlobalState) =>
  getCurrentUserReducer(state).isFetching;
export const getIsFirstLogin = (state: GlobalState) => getUser(state).is_first_login;
export const hasModifiedDefaults = (state: GlobalState) => getUser(state).has_modified_defaults;
export const getSearchCount = (state: GlobalState) => getUser(state).search_count;

export const getUserDashboardLayouts = createSelector(getUser, user => user.dashboard_layouts);

export const getUserTimezone = createSelector(getUser, user => user.timezone);

export const getUserAutoSetTimezone = createSelector(getUser, user => user.autoSetTimezone);

export const getUserGlassdoorApplyUserDefaults = createSelector(
  getUser,
  user => user.glassdoorApplyUserDefaults
);

/**
 *
 * Note: It filters previous values because they may have a deprecated format.
 * All new jurisdictions have ids and optional parent ids
 *
 */
export const getUserSelectedJurisdictions = createSelector(
  getUser,
  (user: CurrentUserFromResponse): NormalizedResourcesJurisdiction[] =>
    (user.selectedJurisdictions?.filter(jurisdictionOrJurisdictionOption => {
      if ('id' in jurisdictionOrJurisdictionOption) {
        return !lodash.isNil(jurisdictionOrJurisdictionOption.id);
      }

      return false;
    }) ?? []) as NormalizedResourcesJurisdiction[]
);

export const getUserPreferencesLoadingState = createSelector(
  getCurrentUserReducer,
  currentUserReducer => {
    return currentUserReducer.isLoadingUserPreferences;
  }
);

export const getUserPreferencesReadyFlag = createSelector(
  getCurrentUserReducer,
  currentUserReducer => {
    return currentUserReducer.areUserPreferencesReady;
  }
);

export const getUserSelectedResources = createSelector(getUser, user => user.selectedResources);

export const getUserSelectedResource = createSelector(getUser, user => user.selectedResource);

export const getUserTaskReportsFields = createSelector(getUser, user => user.taskReportsFields);

export const getCurrentUserSKUs = createSelector(getCurrentUserReducer, currentUserReducer => {
  return userHelpers.formatSKUs(currentUserReducer.user.skus || []);
});

export const getUserAllDocsTablesSettings = createSelector(getUser, user => {
  return user.docsTablesSettings;
});

export const getUserDocTableSettings = (tableKey: string) =>
  createSelector(getUserAllDocsTablesSettings, docsTablesSettings => {
    if (!docsTablesSettings) {
      return null;
    }

    return docsTablesSettings[tableKey] ?? null;
  });

export const getCurrentUserOrganizationRoles = createSelector(getUser, user => {
  return user.organization_roles;
});

export const getCurrentUserOrganizationRoleNames = createSelector(
  getCurrentUserOrganizationRoles,
  currentUserOrganizationRoles => {
    return currentUserOrganizationRoles.map(
      (currentUserOrganizationRole: { name: string }) => currentUserOrganizationRole.name
    );
  }
);
export const doesCurrentUserHaveRole = createSelector(
  [
    (state: GlobalState) => getCurrentUserOrganizationRoleNames(state),
    (_, roles: string[]) => roles
  ],
  (currentUserOrganizationRoleNames, roles) => {
    return roles.some(organizationRoleName =>
      currentUserOrganizationRoleNames.some(
        (currentUserOrganizationRoleName: string) =>
          organizationRoleName === currentUserOrganizationRoleName
      )
    );
  }
);

export const getCurrentLanguageId = createSelector(
  getCurrentUserReducer,
  userReducer => (userReducer.user as { languageId: number | null }).languageId
);

export const getBulkExports = createSelector(getUser, user => user.properties.bulk_export ?? []);

export const getUserSelectedTasksView = createSelector(getUser, user => user.selectedTasksView);

export const getUserTeams = createSelector(getUser, user => user.teams);
