import * as docFieldConstants from 'shared/features/documentFields/documentFields.constants';

export const UNCONVENTIONAL_DATE_FORMAT = 'ddd, DD MMM YYYY HH:mm:ss';

export const INTERNAL_META_DATA_FIELDS = [
  docFieldConstants.INTERNAL_DOC_METADATA_FIELDS.HAS_OBLIGATIONS,
  docFieldConstants.INTERNAL_DOC_METADATA_FIELDS.HAS_SENTENCES,
  docFieldConstants.INTERNAL_DOC_METADATA_FIELDS.OWNER_ID,
  docFieldConstants.INTERNAL_DOC_METADATA_FIELDS.PERMISSIONS,
  docFieldConstants.INTERNAL_DOC_METADATA_FIELDS.SURFACE_CAI_CONCEPT,
  docFieldConstants.INTERNAL_DOC_METADATA_FIELDS.SURFACE_CAI_TOPIC
];
