import type { AdvancedSearchHandlers } from './useAdvancedSearchHandlers.types';

import { useEffect } from 'react';

export const useAdvancedSearchLifecycle = ({
  onValuePropChange
}: {
  onValuePropChange: AdvancedSearchHandlers['handleValuePropChange'];
}) => {
  useEffect(onValuePropChange, [onValuePropChange]);
};
