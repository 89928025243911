import { useRightPanelQueryParamsData } from './useRightPanelQueryParamsData';

export const useRightPanelQueryParams = (): {
  queryParams: ReturnType<typeof useRightPanelQueryParamsData>['formattedData']['queryParams'];
  allQueryParams: ReturnType<
    typeof useRightPanelQueryParamsData
  >['formattedData']['allQueryParams'];
  initialOpenArgs: ReturnType<
    typeof useRightPanelQueryParamsData
  >['formattedData']['initialOpenArgs'];
} => {
  const { formattedData } = useRightPanelQueryParamsData();

  return {
    queryParams: formattedData.queryParams,
    allQueryParams: formattedData.allQueryParams,
    initialOpenArgs: formattedData.initialOpenArgs
  };
};
