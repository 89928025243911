import type { FC } from 'react';
import type { ShareBucketFormProps } from './ShareBucketForm.types';

import * as uiLib from '@compliance.ai/web-components';

import {
  useShareBucketFormData,
  useShareBucketFormHandlers,
  useShareBucketFormQueries,
  useShareBucketFormMutations
} from './hooks';

import * as constants from '../../ShareBucketModal.constants';

import './_share-bucket-form.scss';

export const ShareBucketForm: FC<ShareBucketFormProps> = props => {
  const queries = useShareBucketFormQueries({ props });

  const { localState, localActions, formattedData } = useShareBucketFormData({ props, queries });

  const mutations = useShareBucketFormMutations({ props, localActions, formattedData });

  const handlers = useShareBucketFormHandlers({ localActions });

  return (
    <div className="share-bucket-form__container">
      <div className="share-bucket-form__body">
        <uiLib.LabeledField label="Select with member or team">
          <uiLib.Select
            type={uiLib.SELECT_TYPES.DEFAULT}
            options={formattedData.sharerOptions}
            value={localState.sharersOptions}
            onChange={handlers.handleSharersChange}
            isMulti
          />
        </uiLib.LabeledField>

        <uiLib.LabeledField label="Access level">
          <uiLib.Select
            type={uiLib.SELECT_TYPES.DEFAULT}
            options={constants.SHARE_BUCKET_ACCESS_LEVEL_OPTIONS}
            value={localState.accessLevel}
            onChange={handlers.handleAccessLevelChange}
            isClearable={false}
            isSearchable={false}
          />
        </uiLib.LabeledField>

        <uiLib.TextArea
          value={localState.note}
          onChange={handlers.handleNoteChange}
          placeholder="Add a note"
          minRows={5}
          isStretchable={false}
        />
      </div>

      <div className="share-bucket-form__footer">
        <uiLib.Button type={uiLib.BUTTON_TYPES.LINK} onClick={props.onClose}>
          Cancel
        </uiLib.Button>
        <uiLib.Button
          type={uiLib.BUTTON_TYPES.PRIMARY}
          isDisabled={formattedData.isSubmitButtonDisabled}
          onClick={() => mutations.shareBucket.mutate()}
        >
          Save
        </uiLib.Button>
      </div>
    </div>
  );
};
