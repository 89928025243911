import type { FC } from 'react';
import type { AssigneeCellProps } from './AssigneeCell.types';

import { useAssigneeCellData } from './hooks';

export const AssigneeCell: FC<AssigneeCellProps> = ({ cellValue }) => {
  const { formattedData } = useAssigneeCellData({ cellValue });

  return <>{formattedData.assignee}</>;
};
