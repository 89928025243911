import type { GlobalState } from 'shared/reducers';
import type { AddToWorkflowButtonData } from './useAddToWorkflowButtonData.types';
import type { AddToWorkflowButtonProps } from '../AddToWorkflowButton.types';

import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';

import * as documentsSelectors from 'shared/features/documents/documents.selectors';

export const useAddToWorkflowButtonData = ({
  documentId
}: Pick<AddToWorkflowButtonProps, 'documentId'>): AddToWorkflowButtonData => {
  const [isSelectingWorkflow, setIsSelectingWorkflow] = useState<
    AddToWorkflowButtonData['localState']['isSelectingWorkflow']
  >(false);
  const [isViewingTasks, setIsViewingTasks] = useState<
    AddToWorkflowButtonData['localState']['isViewingTasks']
  >(false);
  const [workflowOption, setWorkflowOption] = useState<
    AddToWorkflowButtonData['localState']['workflowOption']
  >(null);

  const localState: AddToWorkflowButtonData['localState'] = {
    isSelectingWorkflow,
    isViewingTasks,
    workflowOption
  };

  const localActions: AddToWorkflowButtonData['localActions'] = useMemo(
    () => ({
      setIsSelectingWorkflow,
      setIsViewingTasks,
      setWorkflowOption
    }),
    []
  );

  const reduxState = useSelector<GlobalState, AddToWorkflowButtonData['reduxState']>(state => ({
    document: documentsSelectors.getDocumentDetailsByDocumentId(documentId)(state as never)
  }));

  return {
    localState,
    localActions,
    reduxState
  };
};
