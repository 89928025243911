import { useSelector } from 'react-redux';
import { usePermissions } from 'utils/hooks/usePermissions';
import { usePlans } from '../usePlans';
import { EDIT_ALERTS, MANAGE_WORKFLOW_ALERTS } from 'constants/Permissions';
import { checkSavedSearchSharedPermissions } from './useSavedSearchPermissions.helpers';
import {
  SAVED_SEARCH_PERMISSIONS,
  SHARED_SAVED_SEARCH_PERMISSIONS
} from 'shared/features/alerts/alerts.constants';
import { getUserId } from 'shared/features/user/user.selectors';
import { hasRSSAccess } from 'utils/authorization';
import lodash from 'lodash';
import { logError } from 'utils/errors';
import { PLANS } from 'constants/Plans';

export const useSavedSearchPermissions = ({ savedSearch }) => {
  if (!lodash.isObject(savedSearch)) {
    logError('savedSearch passed to useSavedSearchPermissions is not an object');
  }

  const permissions = usePermissions();
  const plans = usePlans();

  const currentUserId = useSelector(getUserId);

  const isWorkflowSavedSearch = Boolean(savedSearch?.workflow_id || savedSearch.workflows?.length);
  const isWorkflowSavedSearchEditor = Boolean(permissions[MANAGE_WORKFLOW_ALERTS]);
  const isSavedSearchAuthor = Boolean(currentUserId && currentUserId === savedSearch?.user_id);
  const isSavedSearchEditor = checkSavedSearchSharedPermissions({
    currentUserId,
    savedSearch,
    permissionToCheck: SHARED_SAVED_SEARCH_PERMISSIONS.EDIT
  });

  const canEditAlert = !isWorkflowSavedSearch && (isSavedSearchAuthor || isSavedSearchEditor);
  const canEditWorkflowAlert =
    isWorkflowSavedSearch &&
    isWorkflowSavedSearchEditor &&
    (isSavedSearchAuthor || isSavedSearchEditor);

  const canEdit = permissions[EDIT_ALERTS] && (canEditAlert || canEditWorkflowAlert);

  return {
    [SAVED_SEARCH_PERMISSIONS.EDIT]: canEdit,
    [SAVED_SEARCH_PERMISSIONS.SHARE]: canEdit && !plans[PLANS.PRO],
    [SAVED_SEARCH_PERMISSIONS.DELETE]: canEdit,
    [SAVED_SEARCH_PERMISSIONS.RENAME]: canEdit,
    [SAVED_SEARCH_PERMISSIONS.RSS]: hasRSSAccess()
  };
};
