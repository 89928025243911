import type { WorkflowModalData } from './useWorkflowModalData.types';
import type { WorkflowOption } from 'shared/features/workflow/workflow.types';
import type { WorkflowModalHandlers } from './useWorkflowModalHandlers.types';
import type { WorkflowModalProps } from '../WorkflowModal.types';

import pluralize from 'pluralize';

import * as errorUtils from 'utils/errors';
import * as uiLibUtils from '@compliance.ai/web-components';
import * as helpers from './useWorkflowModalHandlers.helpers';
import * as numberUtils from 'utils/number';

import { useCallback } from 'react';
import { useWorkflowReduxActions, useWorkflowsApi } from 'shared/features/workflow/hooks';
import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';

export const useWorkflowModalHandlers = ({
  props,
  localState,
  localActions,
  formattedData
}: {
  props: Pick<WorkflowModalProps, 'documents' | 'onClose' | 'onActionStart'>;
  localState: WorkflowModalData['localState'];
  localActions: WorkflowModalData['localActions'];
  formattedData: WorkflowModalData['formattedData'];
}): WorkflowModalHandlers => {
  const workflowsApi = useWorkflowsApi();
  const workflowReduxActions = useWorkflowReduxActions();
  const rightPanelActions = useRightPanelReduxActions();

  const handleWorkflowPick: WorkflowModalHandlers['handleWorkflowPick'] = (
    workflowOption: WorkflowOption | null
  ) => {
    localActions.setSelectedWorkflowOption(workflowOption);
  };

  const handleDataFetch: WorkflowModalHandlers['handleDataFetch'] = useCallback(async () => {
    if (!formattedData.selectedWorkflowId) {
      localActions.setPreDocumentTasks({});
      return;
    }

    try {
      localActions.setIsLoading(true);

      const workflow = await workflowsApi.fetchWorkflow(formattedData.selectedWorkflowId);

      localActions.setPreDocumentTasks(
        helpers.formatPreDocTasksFromWorkflowTasks({
          tasks: workflow?.tasks ?? [],
          documents: props.documents
        })
      );
    } catch (e) {
      errorUtils.logError(e as Error, 'Error', { isSilent: true });
    } finally {
      localActions.setIsLoading(false);
    }
  }, [formattedData.selectedWorkflowId, localActions, props.documents, workflowsApi]);

  const handleDocTasksCreation: WorkflowModalHandlers['handleDocTasksCreation'] = async () => {
    props.onActionStart?.();
    props.onClose();

    uiLibUtils.showNotification({
      type: uiLibUtils.ALERT_TYPES.INFO,
      message: `We are processing workflow tasks for ${numberUtils.formatNumberWithCommas(
        props.documents.length
      )} ${pluralize('document', props.documents.length)}.`
    });

    const workflowId = formattedData.selectedWorkflowId;

    await workflowReduxActions.addDocumentToWorkflow({
      workflowId,
      docTasks: helpers.formatPreDocTasksForUpload({
        preDocumentTasks: localState.preDocumentTasks
      })
    });

    rightPanelActions.triggerRightPanelTasksRefresh();
  };

  return {
    handleWorkflowPick,
    handleDataFetch,
    handleDocTasksCreation
  };
};
