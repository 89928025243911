import type { LabelsSelectProps } from '../LabelsSelect.types';

export const getLabelIds = (value: LabelsSelectProps['value']) => {
  if (!value) {
    return [];
  }

  return value.map(valueOrOption => {
    if (typeof valueOrOption === 'object' && 'value' in valueOrOption) {
      return valueOrOption.value;
    }

    return valueOrOption;
  });
};
