import lodash from 'lodash';
import { api_fetchMention } from './entities.api';
import { api_updateUserDefaults, api_fetchUserDefaults } from '../user/user.api';
import { DEFAULTS_UPDATE_ACTIONS, DEFAULTS_GET_ACTIONS } from 'constants/Defaults';
import { getUserAuthenticatedState } from '../auth/auth.selectors';

export const FOLLOWING_ENTITIES = 'FOLLOWING_ENTITIES';
export const FOLLOWING_MAINSTREAM_NEWS = 'FOLLOWING_MAINSTREAM_NEWS';
export const RECEIVE_FOLLOWED_ENTITIES = 'RECEIVE_FOLLOWED_ENTITIES';
export const REQUEST_FOLLOWED_ENTITIES = 'REQUEST_FOLLOWED_ENTITIES';
export const REQUEST_MENTION = 'REQUEST_MENTION';
export const RECEIVE_MENTION = 'RECEIVE_MENTION';

function markingEntitiesFollowed(entities) {
  return {
    type: FOLLOWING_ENTITIES,
    entities
  };
}

function followingMainstreamNews(entities) {
  return {
    type: FOLLOWING_MAINSTREAM_NEWS,
    mainstreamNews: entities.filter(entity => entity.entity_type === 'news_sources')
  };
}

function requestFollowedEntities() {
  return {
    type: REQUEST_FOLLOWED_ENTITIES
  };
}

function requestMention(filter, id_or_name) {
  return {
    type: REQUEST_MENTION,
    filter,
    id_or_name
  };
}

function receiveMention(json) {
  return {
    type: RECEIVE_MENTION,
    mention: json
  };
}

export function recieveFollowedEntities(json) {
  return {
    type: RECEIVE_FOLLOWED_ENTITIES,
    followed_entities: json.followed_entities
  };
}

export const followEntities = entities => async (dispatch, getState) => {
  const isAuthenticated = getUserAuthenticatedState(getState());

  dispatch(markingEntitiesFollowed(entities));
  dispatch(followingMainstreamNews(entities));

  if (!isAuthenticated) {
    return Promise.resolve();
  }

  return await api_updateUserDefaults({
    entities,
    action: DEFAULTS_UPDATE_ACTIONS.UPDATE_FOLLOWED_ENTITY
  });
};

export const fetchFollowedEntities = (params = {}) => async (dispatch, getState) => {
  const isAuthenticated = getUserAuthenticatedState(getState());

  if (!isAuthenticated) {
    return Promise.resolve();
  }

  dispatch(requestFollowedEntities());

  const response = await api_fetchUserDefaults({
    action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_ENTITIES,
    ...params
  });

  dispatch(recieveFollowedEntities(response));

  return response;
};

export function fetchMention(type, id_or_name) {
  return function dofetch(dispatch) {
    dispatch(requestMention(type, id_or_name));
    if (lodash.isArray(id_or_name)) {
      return Promise.all(id_or_name.map(id => api_fetchMention(type, id))).then(response => {
        dispatch(receiveMention(response));
      });
    }
    return api_fetchMention(type, id_or_name).then(response => {
      dispatch(receiveMention(response));
    });
  };
}
