import type { GlobalState } from 'shared/reducers';

import { useSelector } from 'react-redux';
import { getCurrentUserPermissions } from 'shared/features/user/user.selectors';
import * as routes from 'constants/Routes';
import * as permissions from 'constants/Permissions';

export const usePermissions = ({
  mandatoryPermissions = []
}: {
  mandatoryPermissions?: string[];
} = {}): Record<string, boolean> => {
  const userPermissions = useSelector((state: GlobalState) => getCurrentUserPermissions(state));

  const hasPermissions: Record<string, boolean> = {};

  for (const permission of Object.values(permissions)) {
    hasPermissions[permission] = Boolean(userPermissions[permission]);
  }

  if (mandatoryPermissions) {
    for (const permission of mandatoryPermissions) {
      if (!userPermissions[permission]) {
        /**
         * 07/06/2022 Note:
         * useNavigate() didn't work, so we use this at the moment
         */
        window.location.href = `${window.location.origin}${routes.dashboard}`;
        break;
      }
    }
  }

  return hasPermissions;
};
