import { api_fetchDefaultSources, api_fetchPopularSources } from './sources.api';
import { receiveTopics } from '../topics/topics.actions';
import { recieveFollowedEntities } from '../entities/entities.actions';

export const REQUEST_POPULAR_SOURCES = 'REQUEST_POPULAR_SOURCES';
export const RECEIVE_POPULAR_SOURCES = 'RECEIVE_POPULAR_SOURCES';
export const REQUEST_SOURCES = 'REQUEST_SOURCES';
export const RECEIVE_SOURCES = 'RECEIVE_SOURCES';

function requestSources() {
  return {
    type: REQUEST_SOURCES
  };
}

function receiveSources(data) {
  return {
    type: RECEIVE_SOURCES,
    sources: data
  };
}

function requestPopularSources() {
  return {
    type: REQUEST_POPULAR_SOURCES
  };
}

function recievePopularSources(data) {
  return {
    type: RECEIVE_POPULAR_SOURCES,
    popular_sources: data.popular_sources
  };
}

export function fetchDefaultSources() {
  return function doFetch(dispatch) {
    dispatch(requestSources());
    return api_fetchDefaultSources().then(response => {
      dispatch(receiveSources(response));
      return response;
    });
  };
}

export function fetchPopularSources(data = { num_queries: 5 }) {
  return function doFetch(dispatch) {
    dispatch(requestPopularSources());
    return api_fetchPopularSources(data).then(response => {
      dispatch(recievePopularSources(response));
      return response;
    });
  };
}

export function followDefaultSources(sources) {
  return function (dispatch) {
    const topics = {
      topics: sources.defaultTopics.map(topic => ({ following: true, topic_id: topic.id }))
    };
    dispatch(receiveTopics(topics));

    const entities = {
      followed_entities: sources.defaultMainstreamNewsSources.map(source => ({
        entity: source,
        following: true,
        entity_type: 'news_sources',
        entity_id: source.id
      }))
    };

    dispatch(recieveFollowedEntities(entities));
  };
}
