import type { EnforcementFilterData } from './useEnforcementFilterData.types';
import type { GlobalState } from 'shared/reducers';
import type { EnforcementFilterProps } from '../EnforcementFilter.types';

import * as defaultsConstants from 'constants/DefaultSources';
import * as actionBarFiltersConstants from 'constants/ActionBarFilter';
import * as actionBarConstants from 'constants/ActionBar';
import * as helpers from './useEnforcementFilterData.helpers';
import * as enforcementFilterHelpers from '../EnforcementFilter.helpers';
import * as constants from '../EnforcementFilter.constants';
import * as defaultViewConstants from 'common/Filter/DefaultFiltersViewSelect/DefaultFiltersViewSelect.constants';
import * as filterOptioonsHelpers from 'shared/features/filters/hooks/useFilterOptions.helpers';

import queryString from 'utils/query-string';
import lodash from 'lodash';

import { getFollowedAgenciesIdsMap } from 'shared/features/defaults/defaults.selectors';
import { getJurisdictionsItems } from 'shared/features/jurisdictions/jurisdictions.selectors';
import { getActionBarFilterParamValue } from 'shared/features/filters/filters.selectors';
import { getInitialTopicThresholdKey } from 'shared/features/thresholds/thresholds.selectors';
import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getUserAuthenticatedState } from 'shared/features/auth/auth.selectors';
import {
  getConceptOptions,
  getConcepts,
  getConceptsGroupedOptions
} from 'shared/features/concepts/concepts.selectors';

export const useEnforcementFilterData = ({
  props
}: {
  props: EnforcementFilterProps;
}): EnforcementFilterData => {
  const location = useLocation();
  const parsedQuery = queryString.parse(location.search);

  const reduxState = useSelector<GlobalState, EnforcementFilterData['reduxState']>(state => ({
    followedAgencies: getFollowedAgenciesIdsMap(defaultsConstants.DEFAULT_TYPES.MY_DEFAULTS)(state),
    topics: state.topics,
    sources: state.sources,
    alerts: state.alerts,
    agencies: state.agencies,
    jurisdictions: getJurisdictionsItems(state),
    languages: state.languages.languages,
    current_user: { user: state.current_user.user },
    current_view: state.current_view,
    docTypes: state.docTypes as EnforcementFilterData['reduxState']['docTypes'],
    topicThresholdKey: getInitialTopicThresholdKey(parsedQuery)(state),
    actionBarFilterValues: {
      [actionBarFiltersConstants.FILTER_KEY.ROWS_COUNT]: getActionBarFilterParamValue(
        actionBarFiltersConstants.FILTER_KEY.ROWS_COUNT,
        actionBarConstants.DEFAULT_LIMIT
      )(state)
    },
    defaults: state.defaults,
    isAuthenticated: getUserAuthenticatedState(state),
    conceptOptions: getConceptsGroupedOptions(state),
    allConceptOptions: getConceptOptions(state),
    concepts: getConcepts(state)
  }));

  const [filterParams, setFilterParams] = useState<
    EnforcementFilterData['localState']['filterParams']
  >(helpers.getInitialFilterParams({ location, reduxState }));
  const [errors, setErrors] = useState<EnforcementFilterData['localState']['errors']>({});
  const [newAlertName, setNewAlertName] = useState<
    EnforcementFilterData['localState']['newAlertName']
  >('');
  const [defaultView, setDefaultView] = useState<
    EnforcementFilterData['localState']['defaultView']
  >(helpers.getInitialDefaultView({ parsedQuery, reduxState }));
  const [isLoading, setIsLoading] = useState<EnforcementFilterData['localState']['isLoading']>(
    false
  );

  const localState: EnforcementFilterData['localState'] = {
    filterParams,
    errors,
    newAlertName,
    defaultView,
    isLoading
  };
  const localActions: EnforcementFilterData['localActions'] = useMemo(
    () => ({
      setFilterParams,
      setErrors,
      setNewAlertName,
      setDefaultView,
      setIsLoading
    }),
    []
  );

  const { urlParams, analyticsData } = useMemo(() => {
    return helpers.getUrlParamsAndAnalyticsData({
      actionBarFilterValues: reduxState.actionBarFilterValues,
      docTypes: reduxState.docTypes,
      filterParams,
      searchQuery: props.searchQuery,
      topicThresholdKey: reduxState.topicThresholdKey,
      defaultView
    });
  }, [
    defaultView,
    filterParams,
    props.searchQuery,
    reduxState.actionBarFilterValues,
    reduxState.docTypes,
    reduxState.topicThresholdKey
  ]);

  const formattedData: EnforcementFilterData['formattedData'] = useMemo(() => {
    let limit;
    if (typeof localState.filterParams[constants.FILTER_KEY.PENALTY_LIMIT] === 'object') {
      limit = (localState.filterParams[constants.FILTER_KEY.PENALTY_LIMIT] as Record<
        string,
        number
      >).value;
    }

    const filterErrors = Object.values(errors).filter(error => error);

    return {
      isAlertView: enforcementFilterHelpers.shouldShowAlertView(
        reduxState.alerts,
        reduxState.current_view
      ),
      limit,
      urlParams,
      analyticsData,
      jurisdictionOptions: enforcementFilterHelpers.formatJurisdictionOptions(
        reduxState.jurisdictions
      ),
      topicOptions: enforcementFilterHelpers.formatTopicOptions(reduxState.sources),
      languageOptions: filterOptioonsHelpers.formatLanguageOptions(reduxState.languages),
      filterErrors: filterErrors?.join(', '),
      hasFilterErrors: !lodash.isEmpty(filterErrors),
      doOrgDefaultsExist:
        !reduxState.defaults[defaultViewConstants.DEFAULT_FILTERS_VIEW_OPTIONS.ORG_DEFAULTS]
          ?.isFetching &&
        !reduxState.defaults[defaultViewConstants.DEFAULT_FILTERS_VIEW_OPTIONS.ORG_DEFAULTS]
          ?.isUpdating
    };
  }, [
    analyticsData,
    errors,
    localState.filterParams,
    reduxState.alerts,
    reduxState.current_view,
    reduxState.defaults,
    reduxState.jurisdictions,
    reduxState.languages,
    reduxState.sources,
    urlParams
  ]);

  return { reduxState, formattedData, localActions, localState };
};
