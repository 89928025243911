import type { FC } from 'react';
import type { DocumentTimelineProps } from './DocumentTimeline.types';

import * as react from 'react';
import * as reactRouterDom from 'react-router-dom';
import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';
import * as common from 'common/index';
import * as routes from 'constants/Routes';
import * as constants from './DocumentTimeline.constants';

import {
  useDocumentTimelineData,
  useDocumentTimelineHandlers,
  useDocumentTimelineLifecycle,
  useDocumentTimelineQueries
} from './hooks';

import './_document-timeline.scss';

export const DocumentTimeline: FC<DocumentTimelineProps> = ({ type, document }) => {
  const { localState, localActions, reduxState } = useDocumentTimelineData();

  const handlers = useDocumentTimelineHandlers({
    localState,
    localActions,
    reduxState
  });

  useDocumentTimelineLifecycle({
    onOverlayOpenOnMount: handlers.handleOverlayOpenOnMount,
    onOverlayChange: handlers.handleOverlayChange,
    onUnmount: handlers.handleUnmount
  });

  const queries = useDocumentTimelineQueries({
    props: {
      type,
      document
    },
    reduxState,
    localActions
  });

  if (!reduxState.isAuthenticated) {
    return <reactRouterDom.Navigate to={routes.login} />;
  }

  return (
    <div className="document-timeline__container">
      {queries.fetchDocuments.isFetching ? (
        <div className="document-timeline__loader-container">
          <uiLib.Loader isContrast />
        </div>
      ) : (
        <>
          {queries.fetchDocuments.data?.isDiffingEnabled && (
            <div className="document-timeline__header">
              <uiLib.Typography
                type={uiLib.TYPOGRAPHY_TYPES.BODY1}
                isContrast
                alignment={uiLib.TYPOGRAPHY_ALIGNMENT.CENTER}
                shouldAddBottomMargin
                className="document-timeline__header-text"
              >
                Select any two documents to see changes
              </uiLib.Typography>
              <common.DocDiffPrintButton
                classNames={{ button: 'document-timeline__header-button' }}
              />
            </div>
          )}
          {Object.entries(queries.fetchDocuments.data?.groups ?? {}).map(
            ([timePassed, documentTimelineItems]) => (
              <react.Fragment key={timePassed}>
                <elements.Marker>{timePassed}</elements.Marker>
                <div className="document-timeline__list">
                  {documentTimelineItems.map(documentTimelineItem => (
                    <common.DocumentTimelineItem
                      key={documentTimelineItem.document.id}
                      document={documentTimelineItem.document}
                      date={documentTimelineItem.sortDate}
                      shouldShowCheckbox
                      dateType={
                        constants.DOCUMENT_TIMELINE_HUMANIZED_SORT_OPTION[
                          documentTimelineItem.sortedBy
                        ]
                      }
                      shouldShowDetails={
                        documentTimelineItem.sortedBy ===
                        constants.DOCUMENT_TIMELINE_SORT_OPTION.PUBLICATION_DATE
                      }
                      onDocumentDiff={handlers.handleDiffSelection(documentTimelineItem)}
                      isDiffable={
                        queries.fetchDocuments.data?.isDiffingEnabled &&
                        documentTimelineItem.isDiffable
                      }
                      classNames={{
                        rightSide: 'document-timeline__list-item-right-side'
                      }}
                    />
                  ))}
                </div>
              </react.Fragment>
            )
          )}
        </>
      )}
    </div>
  );
};
