import { GET_PREMIUM_CONTENT_SOURCES } from './premiumContent.actions';

export const PC_INITIAL_STATE = {
  pcSources: []
};

export const premiumContent = (state = PC_INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PREMIUM_CONTENT_SOURCES:
      return {
        ...state,
        pcSources: action.payload
      };
    default:
      return state;
  }
};
