import { useCallback, useState } from 'react';
import { logError } from 'utils/errors';
import { useOrganizationLabelsReduxActions } from './useOrganizationLabelsReduxActions';

export const useOrganizationLabelsAsyncSelectOptions = () => {
  const [isInitialFetch, setInitialFetchFlag] = useState(true);

  const reduxActions = useOrganizationLabelsReduxActions();

  const handleFetch = useCallback(
    async inputString => {
      try {
        const { labels } = await reduxActions.fetchAllLabels({
          name: isInitialFetch ? null : inputString
        });

        if (isInitialFetch) {
          setInitialFetchFlag(false);
        }

        return labels.map(item => ({
          label: item.name,
          value: item.id
        }));
      } catch (e) {
        logError(e);
        return [];
      }
    },
    [reduxActions, isInitialFetch]
  );

  return {
    flags: {
      isInitialFetch
    },
    handlers: {
      handleFetch
    }
  };
};
