import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentView } from 'shared/features/view/view.selectors';
import { getUserAuthenticatedState } from 'shared/features/auth/auth.selectors';

export const useSupportData = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isGivingFeedback, setIsGivingFeedback] = useState(false);

  const localState = {
    isMenuOpen,
    isGivingFeedback
  };

  const localActions = {
    setIsMenuOpen,
    setIsGivingFeedback
  };

  const reduxState = useSelector(state => {
    return {
      currentView: getCurrentView(state),
      isLoggedIn: Boolean(getUserAuthenticatedState(state))
    };
  });

  return { localState, localActions, reduxState };
};
