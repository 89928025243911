import type * as uiLib from '@compliance.ai/web-components';
import type { EnforcementFilterProps } from '../EnforcementFilter.types';
import type { EnforcementFilterData } from './useEnforcementFilterData.types';
import type { Location } from 'react-router-dom';

import { DOCUMENT_META_CATEGORIES } from 'constants/DocumentConstants';
import { getAllCategoriesIDsForMetacategoryName } from 'shared/features/documents/documents.selectors';
import { DEFAULT_ENFORCEMENT_EXCLUSIVE_FIELDS } from 'utils/enforcements/enforcements';
import { FILTER_KEY } from 'constants/ActionBarFilter';

import * as constants from '../EnforcementFilter.constants';
import * as env from 'shared/config';
import * as helpers from '../EnforcementFilter.helpers';

import queryString from 'utils/query-string';
import moment from 'moment';
import lodash from 'lodash';

export const getUrlParamsAndAnalyticsData = ({
  actionBarFilterValues,
  filterParams,
  docTypes,
  topicThresholdKey,
  searchQuery,
  defaultView
}: {
  actionBarFilterValues: EnforcementFilterData['reduxState']['actionBarFilterValues'];
  docTypes: EnforcementFilterData['reduxState']['docTypes'];
  topicThresholdKey: EnforcementFilterData['reduxState']['topicThresholdKey'];
  filterParams: EnforcementFilterData['localState']['filterParams'];
  defaultView: EnforcementFilterData['localState']['defaultView'];
  searchQuery: EnforcementFilterProps['searchQuery'];
}) => {
  let analyticsData = '';

  const publishedFrom = filterParams[constants.FILTER_KEY.PUBLISHED_FROM] as string;
  const publishedTo = filterParams[constants.FILTER_KEY.PUBLISHED_TO] as string;
  const hasUnofficialPublicationDate =
    filterParams[constants.FILTER_KEY.HAS_UNOFFICIAL_PUBLICATION_DATE];
  const federalSource = filterParams[constants.FILTER_KEY.FEDERAL_SOURCE] as uiLib.SelectOption[];
  const jurisdiction = filterParams[constants.FILTER_KEY.JURISDICTION] as uiLib.SelectOption[];
  const topicId = filterParams[constants.FILTER_KEY.TOPIC_ID] as uiLib.SelectOption[];
  const language = filterParams[constants.FILTER_KEY.LANGUAGE] as uiLib.SelectOption;
  const violation = filterParams[constants.FILTER_KEY.VIOLATION] as uiLib.SelectOption[];
  const respondent = filterParams[constants.FILTER_KEY.RESPONDENT] as uiLib.SelectOption[];
  const penaltyLimit = filterParams[constants.FILTER_KEY.PENALTY_LIMIT] as uiLib.SelectOption;
  const penaltyAmount = filterParams[constants.FILTER_KEY.PENALTY_AMOUNT];
  const respondentType = filterParams[constants.FILTER_KEY.RESPONDENT_TYPE] as uiLib.SelectOption;

  const { cai_metacategories: metacategories } = docTypes.docTypes;
  const caiCategoryParams = getAllCategoriesIDsForMetacategoryName(
    DOCUMENT_META_CATEGORIES.ENFORCEMENTS,
    metacategories
  );

  const params = {
    [constants.QUERY_PARAMS.CAI_CATEGORY]: caiCategoryParams,
    ...(env.shouldShowNewSearch
      ? {}
      : { [constants.QUERY_PARAMS.EXCLUSIVE_FIELDS]: DEFAULT_ENFORCEMENT_EXCLUSIVE_FIELDS }),
    [constants.QUERY_PARAMS.LIMIT]: actionBarFilterValues[FILTER_KEY.ROWS_COUNT],
    [constants.QUERY_PARAMS.TOPIC_THRESHOLD_KEY]: topicThresholdKey
  };

  if (defaultView) {
    params[constants.QUERY_PARAMS.DEFAULT_VIEW] = defaultView;
  }

  if (respondent) {
    params[constants.QUERY_PARAMS.RESPONDENT] = respondent;
  }

  if (penaltyLimit?.value) {
    params[penaltyLimit.value] = penaltyAmount;
  }
  if (!lodash.isNil(publishedFrom)) {
    params[constants.QUERY_PARAMS.PUBLISHED_FROM] = moment(publishedFrom).format('MM/DD/YYYY');
    analyticsData += 'Published From,';
  }
  if (!lodash.isNil(publishedTo)) {
    params[constants.QUERY_PARAMS.PUBLISHED_TO] = moment(publishedTo).format('MM/DD/YYYY');
    analyticsData += 'Published To,';
  }
  if (!hasUnofficialPublicationDate) {
    params[constants.QUERY_PARAMS.HAS_UNOFFICIAL_PUBLICATION_DATE] = hasUnofficialPublicationDate;
    analyticsData += 'Official Publication Date Only,';
  }
  if (federalSource?.length > 0) {
    params[constants.QUERY_PARAMS.AGENCY] = federalSource.map(agency => agency.value || agency);
    analyticsData += 'Agency,';
  }
  if (jurisdiction?.length > 0) {
    params[constants.QUERY_PARAMS.JURISDICTION] = jurisdiction.map(juris => juris.value || juris);
    analyticsData += 'Jurisdiction,';
  }
  if (topicId?.length > 0) {
    params[constants.QUERY_PARAMS.TOPIC_ID] = topicId.map(topic => topic.value || topic);
    analyticsData += 'Topic Id,';
  }
  if (language?.value) {
    params[constants.QUERY_PARAMS.LANGUAGE_ID] = language.value;
    analyticsData += 'Language,';
  }
  if (violation?.length > 0) {
    params[constants.QUERY_PARAMS.VIOLATION_QUERY] = violation;
    analyticsData += 'Violation,';
  }
  if (respondent?.length > 0) {
    params[constants.QUERY_PARAMS.RESPONDENT] = respondent;
    analyticsData += 'Respondent,';
  }
  if (respondentType?.value) {
    params[respondentType.value] = true;
    analyticsData += 'Respondent Type,';
  }
  if (searchQuery) {
    params[constants.QUERY_PARAMS.SEARCH_QUERY] = searchQuery;
  }

  return {
    urlParams: queryString.stringify(params),
    analyticsData
  };
};

export const getInitialFilterParams = ({
  location,
  reduxState
}: {
  location: Location;
  reduxState: EnforcementFilterData['reduxState'];
}) => {
  if (
    reduxState.current_view.editAlertId ||
    helpers.shouldGetFiltersFromQueryParams({ location })
  ) {
    return helpers.getFiltersFromQueryParams({
      agencies: reduxState.agencies,
      jurisdictions: reduxState.jurisdictions,
      languages: reduxState.languages,
      location,
      sources: reduxState.sources
    });
  } else {
    return helpers.getSavedFilters({
      languages: reduxState.languages,
      current_user: reduxState.current_user
    });
  }
};

export const getInitialDefaultView = ({
  parsedQuery,
  reduxState
}: {
  parsedQuery: Record<string, unknown>;
  reduxState: EnforcementFilterData['reduxState'];
}): string | null => {
  const defaultViewFromQuery = parsedQuery[constants.QUERY_PARAMS.DEFAULT_VIEW] as string;
  if (defaultViewFromQuery) {
    return defaultViewFromQuery;
  }
  return (
    (reduxState.current_user.user.properties?.enforcementFilter as Record<string, string>)
      ?.defaultView ?? null
  );
};
