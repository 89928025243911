import type { EditMetadataButtonData } from './useEditMetadataButtonData.types';
import type { EditMetadataButtonProps } from '../EditMetadataButton.types';
import type { GlobalState } from 'shared/reducers';

import * as organizationSelectors from 'shared/features/organizations/organizations.selectors';
import * as userSelectors from 'shared/features/user/user.selectors';
import * as skuConstants from 'constants/SKUs';

import { useSelector } from 'react-redux';
import { useSKUs } from 'utils/hooks';
import { useMemo, useState } from 'react';

export const useEditMetadataButtonData = ({
  document,
  onEditMetadataOpen
}: Pick<EditMetadataButtonProps, 'document' | 'onEditMetadataOpen'>): EditMetadataButtonData => {
  const { userHasSKU } = useSKUs();

  const [isDocumentBucketAccessible, setIsDocumentBucketAccessible] = useState<
    EditMetadataButtonData['localState']['isDocumentBucketAccessible']
  >(false);

  const localState = {
    isDocumentBucketAccessible
  };

  const localActions = useMemo(
    () => ({
      setIsDocumentBucketAccessible
    }),
    []
  );

  const reduxState = useSelector<GlobalState, EditMetadataButtonData['reduxState']>(state => {
    return {
      userId: userSelectors.getUserId(state),
      canUserEditDocument: organizationSelectors.canUserEditDocument({
        document,
        userId: userSelectors.getUserId(state)
      })(state)
    };
  });

  const formattedData: EditMetadataButtonData['formattedData'] = useMemo(() => {
    const isButtonShown = Boolean(
      onEditMetadataOpen &&
        userHasSKU(skuConstants.SKU_RESTRICTION_TYPES.BYOC) &&
        (reduxState.canUserEditDocument || localState.isDocumentBucketAccessible)
    );

    return {
      isButtonShown
    };
  }, [
    localState.isDocumentBucketAccessible,
    onEditMetadataOpen,
    reduxState.canUserEditDocument,
    userHasSKU
  ]);

  return {
    localState,
    localActions,
    reduxState,
    formattedData
  };
};
