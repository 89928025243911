import { safeGetAlertCategories, safeGetAlertCAICategories } from 'utils/alert-utils';
import {
  api_fetchAllAlerts,
  api_updateAlert,
  api_updateAlertEmailSettings,
  api_createAlert,
  api_removeAlert,
  api_markAlertAsViewed
} from './alerts.api';
import { getUserAuthenticatedState } from '../auth/auth.selectors';

export const REQUEST_ALL_ALERTS = 'REQUEST_ALL_ALERTS';
export const RECEIVE_ALL_ALERTS = 'RECEIVE_ALL_ALERTS';
export const REQUEST_UPDATE_ALERTS = 'REQUEST_UPDATE_ALERTS';
export const RECEIVE_UPDATE_ALERTS = 'RECEIVE_UPDATE_ALERTS';
export const REQUEST_ADD_ALERT = 'REQUEST_ADD_ALERT';
export const RECEIVE_ADD_ALERT = 'RECEIVE_ADD_ALERT';
export const REQUEST_DELETE_ALERTS = 'REQUEST_DELETE_ALERTS';
export const RECEIVE_DELETE_ALERTS = 'RECEIVE_DELETE_ALERTS';
export const NEW_NOTIFICATIONS_STATUS = 'NEW_NOTIFICATIONS_STATUS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_NOTIFICATIONS_PERMISSION = 'SET_NOTIFICATIONS_PERMISSION';

function requestAllAlerts() {
  return {
    type: REQUEST_ALL_ALERTS
  };
}

function recieveAllAlerts(data) {
  return {
    type: RECEIVE_ALL_ALERTS,
    user_alerts: data.user_alerts
  };
}

export const fetchAllAlerts = params => async (dispatch, getState) => {
  const isAuthenticated = getUserAuthenticatedState(getState());

  if (!isAuthenticated) {
    return Promise.resolve();
  }

  dispatch(requestAllAlerts());

  const response = await api_fetchAllAlerts(params);

  dispatch(recieveAllAlerts(response));

  return response;
};

export function markAlertAsViewed(alertId) {
  return function doFetch() {
    return api_markAlertAsViewed(alertId);
  };
}

function requestUpdateAlerts() {
  return {
    type: REQUEST_UPDATE_ALERTS
  };
}

function recieveUpdateAlerts() {
  return {
    type: RECEIVE_UPDATE_ALERTS
  };
}

export const buildAlertPayload = data => {
  if (!data || !('search_args' in data)) {
    return data;
  }
  delete data.search_args['?'];
  delete data.search_args.limit;
  delete data.search_args.offset;
  if (data.pathname) {
    data.search_args.pathname = data.pathname;
  }
  data.search_args.cai_category = safeGetAlertCAICategories(data.search_args);
  data.search_args.category = safeGetAlertCategories(data.search_args);
  return data;
};

export function updateAlert(alert_id, data) {
  return function doPost(dispatch) {
    dispatch(requestUpdateAlerts());
    return api_updateAlert(alert_id, data).then(response => {
      dispatch(recieveUpdateAlerts());
      return response;
    });
  };
}

export function updateAlertEmailSettings(alert_id, data) {
  return function dopost(dispatch) {
    return api_updateAlertEmailSettings(alert_id, data).then(response => {
      return response;
    });
  };
}

function requestAddAlert() {
  return {
    type: REQUEST_ADD_ALERT
  };
}

function receiveAddAlert() {
  return {
    type: RECEIVE_ADD_ALERT
  };
}

export function addAlert(data) {
  data = buildAlertPayload(data);
  return function doPost(dispatch) {
    dispatch(requestAddAlert());
    return api_createAlert(data).then(response => {
      dispatch(receiveAddAlert());
      return response;
    });
  };
}

function requestRemoveAlert() {
  return {
    type: REQUEST_DELETE_ALERTS
  };
}

function receiveRemoveAlert(data) {
  return {
    type: RECEIVE_DELETE_ALERTS,
    user_alerts: data.user_alerts
  };
}

export function removeAlert(alert_id) {
  return function doDelete(dispatch) {
    dispatch(requestRemoveAlert());
    return api_removeAlert(alert_id).then(response => {
      dispatch(receiveRemoveAlert(response));
      return response;
    });
  };
}

export function notificationsUpdate(latest_stats) {
  return {
    type: NEW_NOTIFICATIONS_STATUS,
    latest_stats
  };
}

export function setNotificationsPermission(permission) {
  return {
    type: SET_NOTIFICATIONS_PERMISSION,
    permission
  };
}

export function setNotifications(notifications) {
  return {
    type: SET_NOTIFICATIONS,
    notifications
  };
}
