import PropTypes from 'prop-types';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import './_support-menu-item.scss';

export const TEST_ID = {
  MENU_ITEM: 'menu-item'
};

export const SupportMenuItem = ({ title, content, onClick, icon }) => (
  <Paper className="support-menu-item__paper">
    <MenuItem
      onClick={onClick}
      className="support-menu-item__item"
      style={{ whiteSpace: 'normal' }}
      data-testid={TEST_ID.MENU_ITEM}
    >
      <img className="support-menu-item__icon" src={icon} alt="loading" />
      <div className="support-menu-item__inner">
        <h2 className="support-menu-item__title">{title}</h2>
        <section className="support-menu-item__content">{content}</section>
      </div>
      <ChevronRightIcon className="support-menu-item__arrow" />
    </MenuItem>
  </Paper>
);

SupportMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.shape({}).isRequired // SVG icon
};
