import type { DiffTabData } from './useDiffTabData.types';
import type { GlobalState } from 'shared/reducers';

import * as authSelectors from 'shared/features/auth/auth.selectors';
import * as rightPanelSelectors from 'shared/features/rightPanel/rightPanel.selectors';
import * as documentsSelectors from 'shared/features/documents/documents.selectors';

import { useSelector } from 'react-redux';

export const useDiffTabData = (): DiffTabData => {
  const reduxState = useSelector<GlobalState, DiffTabData['reduxState']>(state => ({
    isAuthenticated: authSelectors.getUserAuthenticatedState(state),
    sortedDocumentsToDiff: documentsSelectors.getSortedDocumentsToDiff(state as never),
    diffDocIds: rightPanelSelectors.getDiffOverlayDocs(
      state
    ) as DiffTabData['reduxState']['diffDocIds']
  }));

  return {
    reduxState
  };
};
