import lodash from 'lodash';

const mergeArraysOfObjectsIntoObjectAndSummarizeValues = array => {
  const result = {};

  array.forEach(object => {
    for (let [key, value] of Object.entries(object)) {
      if (result[key]) {
        result[key] += value;
      } else {
        result[key] = value;
      }
    }
  });

  return result;
};

const convertObjectToFormData = (object, formData, prefix) => {
  formData = formData || new FormData();

  if (lodash.isUndefined(object)) {
    return formData;
  } else if (lodash.isNull(object) || lodash.isNaN(object)) {
    formData.append(prefix, '');
  } else if (lodash.isArray(object)) {
    if (object.length) {
      object.forEach(value => {
        convertObjectToFormData(value, formData, prefix + '[]');
      });
    } else {
      formData.append(prefix + '[]', '');
    }
  } else if (lodash.isDate(object)) {
    formData.append(prefix, object.toISOString());
  } else if (lodash.isObject(object) && !(object instanceof File) && !(object instanceof Blob)) {
    Object.keys(object).forEach(prop => {
      const value = object[prop];

      if (lodash.isArray(value)) {
        while (prop.length > 2 && prop.lastIndexOf('[]') === prop.length - 2) {
          prop = prop.substring(0, prop.length - 2);
        }
      }

      const key = prefix ? prefix + '[' + prop + ']' : prop;

      convertObjectToFormData(value, formData, key);
    });
  } else {
    formData.append(prefix, object);
  }

  return formData;
};

export default {
  mergeArraysOfObjectsIntoObjectAndSummarizeValues,
  convertObjectToFormData
};
