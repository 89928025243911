import type { ObligationToolbarProps } from '../ObligationToolbar.types';

import * as errorUtils from 'utils/errors';

import { useMutation } from '@tanstack/react-query';
import { useObligationsReduxActions } from 'shared/features/obligations/hooks';
import { useSentenceNotesReduxActions } from 'shared/features/sentenceNotes/hooks';

export const useObligationToolbarMutations = ({
  props
}: {
  props: Pick<ObligationToolbarProps, 'sentenceNote' | 'documentId' | 'onRelevancyChange'>;
}) => {
  const obligationsReduxActions = useObligationsReduxActions();
  const sentenceNotesReduxActions = useSentenceNotesReduxActions();

  const hideSentenceNote = useMutation<void, Error, unknown>({
    mutationFn: async () => {
      obligationsReduxActions.deselectSentences();

      if (props.sentenceNote.id) {
        await sentenceNotesReduxActions.modifySentenceNote({
          id: props.sentenceNote.id,
          params: {
            hidden: true
          }
        });
      }
    },
    onError: errorUtils.logReactQueryError
  });

  const selectRelevance = useMutation<void, Error, boolean | null>({
    mutationFn: async obligationRelevance => {
      if (props.sentenceNote.id) {
        await sentenceNotesReduxActions.modifySentenceNote({
          id: props.sentenceNote.id,
          params: { relevance: obligationRelevance }
        });
      } else {
        await sentenceNotesReduxActions.createSentenceNote({
          params: {
            document_id: props.documentId,
            first_sentence_id: props.sentenceNote.firstSentenceId,
            last_sentence_id: props.sentenceNote.lastSentenceId,
            left_offset: props.sentenceNote.leftOffset,
            right_offset: props.sentenceNote.rightOffset,
            sentences_text: props.sentenceNote.sentencesText,
            is_obligation: true,
            is_system_obligation: true,
            relevance: obligationRelevance,
            obligation_probability:
              'obligationProbability' in props.sentenceNote
                ? props.sentenceNote.obligationProbability
                : null
          }
        });
      }

      props.onRelevancyChange(Boolean(obligationRelevance));
    },
    onError: errorUtils.logReactQueryError
  });

  return {
    isLoading: hideSentenceNote.isLoading || selectRelevance.isLoading,

    hideSentenceNote,
    selectRelevance
  };
};
