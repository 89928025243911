import type { FC } from 'react';
import type { AttachmentsCellProps } from './AttachmentsCell.types';

import { FileLink } from '../../../../../../../../FileLink';

import * as uiLib from '@compliance.ai/web-components';
import * as emptyValue from 'constants/EmptyValue';

import './attachment-cell.scss';

export const AttachmentsCell: FC<AttachmentsCellProps> = ({ cellValue }) => {
  if (!Array.isArray(cellValue)) {
    return <>{emptyValue.EMPTY_VALUE.DASH}</>;
  }

  return (
    <>
      {cellValue.length
        ? cellValue.map(attachment => (
            <FileLink href={attachment.url}>
              <uiLib.Button type={uiLib.BUTTON_TYPES.LINK} className="attachment-cell__button">
                {attachment.userFileName}
              </uiLib.Button>
            </FileLink>
          ))
        : emptyValue.EMPTY_VALUE.DASH}
    </>
  );
};
