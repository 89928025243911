import type { DocumentTimelineProps } from '../DocumentTimeline.types';
import type { DocumentTimelineData } from './useDocumentTimelineData.types';

import * as queryKeys from 'constants/QueryKeys';
import * as helpers from './useDocumentTimelineQueries.helpers';
import * as constants from '../DocumentTimeline.constants';
import * as errorUtils from 'utils/errors';
import * as rightPanelConstants from 'shared/features/rightPanel/rightPanel.constants';
import * as primaryFilterConstants from 'constants/PrimaryFilter';

import { useQuery } from '@tanstack/react-query';
import { useDocumentsApi, useDocumentsReduxActions } from 'shared/features/documents/hooks';

export const useDocumentTimelineQueries = ({
  props,
  localActions,
  reduxState
}: {
  props: Pick<DocumentTimelineProps, 'document' | 'type'>;
  localActions: DocumentTimelineData['localActions'];
  reduxState: DocumentTimelineData['reduxState'];
}) => {
  const documentsApi = useDocumentsApi();
  const documentsReduxActions = useDocumentsReduxActions();

  const fetchDocuments = useQuery({
    queryKey: queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.DOCUMENTS][
      queryKeys.QUERY_SECTIONS.DOCUMENT_TIMELINE
    ]({
      type: props.type,
      docketIds: props.document.dockets.map(docket => docket.docket_id),
      documentVersionDocketId: props.document.document_version_docket_id
    }),
    queryFn: () => {
      return documentsApi.fetchDocuments({
        ...constants.DEFAULT_QUERY_PARAMS,
        ...(props.type === constants.DOCUMENT_TIMELINE_TYPE.VERSION && {
          [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.DOCUMENT_VERSION_DOCKET_ID]:
            props.document.document_version_docket_id,
          [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.DOCUMENT_VERSION_LATEST]: false
        }),
        ...(props.type === constants.DOCUMENT_TIMELINE_TYPE.DOCKET && {
          [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.DOCKET_ID]: props.document.dockets.map(
            docket => docket.docket_id
          ),
          [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.DOCUMENT_VERSION_LATEST]: false
        })
      });
    },
    select: helpers.formatData({
      reduxState
    }),
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: data => {
      if (data.diffableItems.length > 1) {
        localActions.setOverlayType(rightPanelConstants.OVERLAY_TYPES.DIFF);

        documentsReduxActions.addDocsToDiff([
          data.diffableItems[0]?.document,
          data.diffableItems[1]?.document
        ]);
      }
    },
    onError: errorUtils.logReactQueryError
  });

  return {
    fetchDocuments
  };
};
