import type { AgencyFromResponse } from './agencies.types';

import { alphabeticallyByLabel } from 'utils/sort';

export const byName = (agency: AgencyFromResponse) =>
  (agency.short_name || agency.name || '').toLowerCase();

export const formatAgenciesOptions = (agencies: AgencyFromResponse[]) => {
  return agencies
    .filter(agency => agency.short_name)
    .map(agency => ({
      value: agency.id,
      label: agency.short_name,
      id: agency.id,
      shortName: agency.short_name,
      fullName: agency?.name ?? agency.short_name
    }))
    .sort(alphabeticallyByLabel);
};
