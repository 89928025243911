import type { PrimaryFilterData } from './usePrimaryFilterData.types';
import type { NormalizedDefaults } from 'shared/features/defaults/defaults.types';

import * as constants from 'constants/PrimaryFilter';
import * as defaultsHelpers from 'shared/features/defaults/defaults.helpers';
import * as filterDefaultOptionsHelpers from 'shared/features/filters/hooks/useFilterDefaultOptions.helpers';

export const getPrimaryFilterValues = ({
  filterDefaultOptions,
  filterParams,
  isNewsPage
}: {
  filterDefaultOptions: Record<string, unknown>;
  isNewsPage: PrimaryFilterData['formattedData']['isNewsPage'];
  filterParams: PrimaryFilterData['reduxState']['filterParams'];
}) => {
  return {
    [constants.FILTER_KEY.AGENCIES]: filterDefaultOptions[constants.FILTER_KEY.AGENCIES],
    [constants.FILTER_KEY.JURISDICTIONS]: [],
    [constants.FILTER_KEY.MAINSTREAM_NEWS_SOURCES]:
      filterDefaultOptions[constants.FILTER_KEY.MAINSTREAM_NEWS_SOURCES],
    [constants.FILTER_KEY.TOPICS]: filterDefaultOptions[constants.FILTER_KEY.TOPICS],
    [constants.FILTER_KEY.DOC_TYPES]: isNewsPage
      ? filterParams[constants.FILTER_KEY.DOC_TYPES]
      : filterDefaultOptions[constants.FILTER_KEY.DOC_TYPES],
    [constants.FILTER_KEY.CONCEPTS]: filterDefaultOptions[constants.FILTER_KEY.CONCEPTS]
  };
};

export const prepareDefaultFilterOptions = ({
  reduxState,
  defaultsData
}: {
  reduxState: PrimaryFilterData['reduxState'];
  defaultsData: NormalizedDefaults;
}) => {
  return filterDefaultOptionsHelpers.getFormattedDefaultOptions({
    allCategories: reduxState.docTypes.docTypes.cai_categories,
    allConceptOptions: reduxState.conceptsData.conceptOptions,
    defaultTopics: defaultsData.defaultTopics,
    followedAgencies: defaultsHelpers.getFilteredFollowedAgencies({
      agencies: reduxState.agencies.items,
      followedAgenciesIdsMap: defaultsData.followedAgencies
    }),
    followedCategories: defaultsData.followedCategories,
    followedConcepts: defaultsHelpers.getActiveFollowedConcepts({
      activeConcepts: reduxState.conceptsData.concepts,
      followedConcepts: defaultsData.followedConcepts
    }),
    followedJurisdictions: defaultsHelpers.getFilteredNonCountryJurisdictions(
      defaultsHelpers.getFilteredFollowedJurisdictions({
        followedJurisdictionsIdsMap: defaultsData.followedJurisdictions,
        jurisdictions: reduxState.jurisdictions.items
      })
    ),
    followedMainstreamNewsSources: filterDefaultOptionsHelpers.getFollowedMainstreamNewsSourcesFromEntities(
      defaultsData.followedEntities || []
    ),
    followedTopics: defaultsData.realFollowedTopics,
    metaCategoriesObject: reduxState.docTypes.cai_metacategories || {}
  });
};
