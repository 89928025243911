import type { PublicationDateProps } from './PublicationDate.types';
import type { DocumentFromResponse } from 'shared/features/documents/documents.types';

import { usePublicationDateData } from './hooks';
import { DOCUMENT_FIELDS } from 'constants/DocumentProperties';
import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';

export const PublicationDate = ({ document }: PublicationDateProps) => {
  const { formattedData } = usePublicationDateData({ document });

  if (document[DOCUMENT_FIELDS.hasUnofficialPublicationDate as keyof DocumentFromResponse]) {
    return null;
  }

  return (
    <>
      <FieldName>{formattedData.publicationLabel}</FieldName>
      <FieldValue>{formattedData.publicationValue}</FieldValue>
    </>
  );
};
