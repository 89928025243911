import naturalSort from 'javascript-natural-sort';
import { romanNumeralToInt, isStringRomanNumeral } from 'utils/string';
import { SORT_ORDERS } from 'shared/features/labels/labels.constants';

export const naturalSortWithRomanNumerals = (a, b) => {
  const docsToSort = [a, b].map(docToSort => {
    const title = docToSort.latestDoc?.title || docToSort.title;
    try {
      const parts = title.split(' ');
      const chapterPart = parts[0];
      const romanNumeralParts = parts[1].split('-');
      if (chapterPart.toLowerCase() === 'chapter' && isStringRomanNumeral(romanNumeralParts[0])) {
        romanNumeralParts[0] = romanNumeralToInt(romanNumeralParts[0]);
        parts[1] = romanNumeralParts.join('-');
      }
      return parts.join(' ');
    } catch (Exception) {
      // Do nothing and leave the title to sort as is if we cannot sort by roman numerals
      return title;
    }
  });
  return naturalSort(docsToSort[0], docsToSort[1]);
};

export const getSortField = tableSortString => {
  if (!tableSortString) {
    return undefined;
  }

  return tableSortString.replace(/(.*)-/, '');
};

export const getSortOrder = tableSortString => {
  if (!tableSortString) {
    return undefined;
  }

  const isDesc = Boolean(tableSortString.split('-')[1]);

  if (isDesc) {
    return SORT_ORDERS.DESC;
  }

  return SORT_ORDERS.ASC;
};

export const sortUsersByEmail = (a, b) => {
  return a.email > b.email ? 1 : -1;
};

export const alphabetically = (a, b) => a.name.localeCompare(b.name);

export const alphabeticallyByLabel = (a, b) => a.label.localeCompare(b.label);
