import styled from 'styled-components';

import PropTypes from 'prop-types';
import { COLORS } from 'constants/Style';

const StyledIconContainer = styled.span`
  background-color: ${COLORS.lightBackground};
  border-radius: 5px;
  width: 25px;
  height: 25px;
  display: inline-flex;
  cursor: pointer;

  > svg {
    font-size: 17px;
    position: relative;
    top: 3px;
    left: 3px;
    color: ${props => (props.disabled ? 'rgba(0,0,0,0.26)' : 'black')};
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.8;
  }
`;

const ActionIcon = ({ onClick, icon, disabled, className }) => {
  return (
    <StyledIconContainer
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      disabled={disabled}
      className={className}
    >
      {icon}
    </StyledIconContainer>
  );
};

ActionIcon.propTypes = {
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

ActionIcon.defaultProps = {
  onClick: () => {},
  disabled: false,
  className: ''
};

export default ActionIcon;
