import type { AutosuggestionSelectHandlers } from './useAutosuggestionSelectHandlers.types';
import type { AutosuggestionSelectProps } from '../AutosuggestionSelect.types';
import type { AutosuggestionSelectData } from './useAutosuggestionSelectData.types';
import type { BankFromResponse } from 'shared/features/banks/banks.api.types';
import type { ActFromResponse } from 'shared/features/acts/acts.api.types';

import { logError } from 'utils/errors';
import {
  OPTION_FETCHER_RESPONSE_FIELD_KEY,
  OPTION_FETCHERS
} from '../AutosuggestionSelect.constants';

import { useCallback } from 'react';
import { useSearchReduxActions } from 'shared/features/search/hooks';

export const useAutosuggestionSelectHandlers = ({
  props,
  localActions
}: {
  props: Pick<AutosuggestionSelectProps, 'value' | 'category'>;
  localActions: AutosuggestionSelectData['localActions'];
}): AutosuggestionSelectHandlers => {
  const searchReduxActions = useSearchReduxActions();

  const handleAutocompleteFetch: AutosuggestionSelectHandlers['handleAutocompleteFetch'] = async (
    inputValue: string
  ) => {
    try {
      const response = await searchReduxActions.fetchAutoComplete(inputValue, props.category);

      return response.results.map(item => ({
        label: item.name,
        value: item.id
      }));
    } catch (e) {
      logError(e as Error);

      return [];
    }
  };

  const handleValuePropChange: AutosuggestionSelectHandlers['handleValuePropChange'] = useCallback(async () => {
    try {
      if (!props.value?.length) {
        localActions.setValue([]);
        return;
      }

      localActions.setIsLoading(true);

      const dataKey = OPTION_FETCHER_RESPONSE_FIELD_KEY[props.category];
      const data = await OPTION_FETCHERS[props.category](props.value);

      const items = data[dataKey as keyof typeof data] as BankFromResponse[] | ActFromResponse[];

      localActions.setValue(
        items.map(item => ({
          value: item.id,
          label: item.name
        }))
      );
    } catch (e) {
      logError(e as Error);
    } finally {
      localActions.setIsLoading(false);
    }
  }, [localActions, props.category, props.value]);

  return {
    handleAutocompleteFetch,
    handleValuePropChange
  };
};
