import { triggerPremiumContentMixpanel } from 'utils/documents/documentsAccess';

export const usePremiumContentFormHandlers = ({ props, reduxState }) => {
  const handleOutboundLinkClick = () => {
    triggerPremiumContentMixpanel(reduxState.currentUserEmail, props.document);
  };

  return {
    handleOutboundLinkClick
  };
};
