import type { GlobalState } from '../../reducers';

import { createSelector } from '@reduxjs/toolkit';

export const getWorkflowMoldReducer = (state: GlobalState) => state.workflowMolds;

export const getWorkflowMoldReadyState = createSelector(
  getWorkflowMoldReducer,
  workflowMoldsReducer => {
    return workflowMoldsReducer.isReady;
  }
);

export const getWorkflowMolds = createSelector(getWorkflowMoldReducer, workflowMoldsReducer => {
  return workflowMoldsReducer.items;
});
