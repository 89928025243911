import type { SourceData } from './useSourceData.types';
import type { SourceProps } from '../Source.types';

import { useMemo } from 'react';
import { getSource } from './useSourceData.helpers';

export const useSourceData = ({ document }: Pick<SourceProps, 'document'>): SourceData => {
  const formattedData: SourceData['formattedData'] = useMemo(() => {
    const source = getSource({
      document: document
    });

    return {
      source
    };
  }, [document]);

  return {
    formattedData
  };
};
