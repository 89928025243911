import { Folder, FolderShared, Description } from '@material-ui/icons';

export const FOLDERS_MODE = {
  TABLE: 'table',
  ACCORDION: 'accordion'
};

export const DEFAULT_FOLDERS_MODE = FOLDERS_MODE.TABLE;

export const QUERY_PARAMS = {
  FOLDER_MODE: 'mode',
  FOLDER_ID: 'folder_id'
};

export const FOLDER_TYPE = {
  FOLDER: 'folder',
  BOOKMARKED_FOLDER: 'bookmarked_folder',
  SHARED_FOLDER: 'shared_folder',
  DOCUMENT: 'document'
};

export const FOLDER_PERMISSION = {
  OWNER: 'owner',
  EDITOR: 'editor',
  VIEWER: 'viewer'
};

export const FOLDER_KEY = {
  ID: 'id',
  NAME: 'name',
  DOCUMENTS_COUNT: 'documents_count',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
  OWNER: 'owner',
  TYPE: 'type',
  PERMISSION: 'permission',
  SHARED_USERS: 'shared_users'
};

export const API_KEYS = {
  SORT_FIELD: 'sort_field',
  SORT_ORDER: 'sort_order',
  NAME: 'name',
  CREATED_FROM: 'created_from',
  CREATED_TO: 'created_to',
  UPDATED_FROM: 'updated_from',
  UPDATED_TO: 'updated_to',
  OWNER: 'owner',
  PAGE: 'page',
  COUNT_PER_PAGE: 'count_per_page',
  DOCUMENT_IDS: 'document_ids'
};

export const SORT_ORDERS = {
  ASC: 'asc',
  DESC: 'desc'
};

export const FOLDER_DOC_ICONS = {
  [FOLDER_TYPE.FOLDER]: Folder,
  [FOLDER_TYPE.BOOKMARKED_FOLDER]: Folder,
  [FOLDER_TYPE.SHARED_FOLDER]: FolderShared,
  [FOLDER_TYPE.DOCUMENT]: Description
};
