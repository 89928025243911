import type { ExportProps } from '../Export.types';
import type { ExportData } from './useExportData.types';
import type { ExportHandlers } from './useExportHandlers.types';

import * as exportConstants from 'constants/Export';

import { useCallback } from 'react';
import { useExportMutations } from './useExportMutations';

export const useExportHandlers = ({
  props,
  localActions,
  mutations
}: {
  props: Pick<ExportProps, 'onActionStart'>;
  localActions: ExportData['localActions'];
  mutations: ReturnType<typeof useExportMutations>;
}): ExportHandlers => {
  const handleDownload: ExportHandlers['handleDownload'] = useCallback(() => {
    props.onActionStart?.();

    localActions.setDownloadStartTime(Date.now());
  }, [props, localActions]);

  const handleExportModalClose: ExportHandlers['handleExportModalClose'] = useCallback(() => {
    localActions.setIsExportModalOpen(false);
  }, [localActions]);

  const handleDownloadModalClose: ExportHandlers['handleDownloadModalClose'] = useCallback(() => {
    localActions.setIsDownloadModalOpen(false);
  }, [localActions]);

  const handleContentTypeSelect: ExportHandlers['handleContentTypeSelect'] = useCallback(
    contentType => {
      localActions.setContentType(contentType);

      switch (contentType) {
        case exportConstants.CONTENT_TYPES.PDF: {
          return mutations.exportPDFs.mutate();
        }

        case exportConstants.CONTENT_TYPES.HTML: {
          return mutations.exportPDFs.mutate();
        }

        case exportConstants.CONTENT_TYPES.XLSX:
        case exportConstants.CONTENT_TYPES.CSV: {
          return localActions.setIsExportModalOpen(true);
        }
      }
    },
    [localActions, mutations.exportPDFs]
  );

  return {
    handleDownload,
    handleExportModalClose,
    handleDownloadModalClose,
    handleContentTypeSelect
  };
};
