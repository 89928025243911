import type { FC } from 'react';
import type { ConceptsProps } from './Concepts.types';

import { Button, BUTTON_TYPES, LabeledField } from '@compliance.ai/web-components';
import { ConceptsSelect } from 'common';
import { useConceptsData, useConceptsHandlers } from './hooks';
import './_concepts.scss';

export const Concepts: FC<ConceptsProps> = ({ onChange }) => {
  const { localState, localActions } = useConceptsData();

  const handlers = useConceptsHandlers({
    props: {
      onChange
    },
    localState,
    localActions
  });

  return (
    <LabeledField label="Concepts" isInline className="advanced-search-concepts__container">
      <ConceptsSelect value={localState.value} onChange={handlers.handleConceptsChange} />
      <Button
        type={BUTTON_TYPES.PRIMARY}
        className="advanced-search-concepts__apply-button"
        onClick={handlers.handleConceptsApply}
      >
        Apply concepts
      </Button>
    </LabeledField>
  );
};
