import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getDocumentDetailsExpandedRelatedDocsType } from 'shared/features/rightPanel/rightPanel.selectors';
import { DEFAULT_RELATION_TYPES_EXPANDED } from '../RelatedDocumentsPanel.constants';

export const useRelatedDocumentsPanelData = () => {
  const reduxState = useSelector(state => ({
    expandedRelatedDocsType: getDocumentDetailsExpandedRelatedDocsType(state)
  }));

  const [expanded, setExpanded] = useState(DEFAULT_RELATION_TYPES_EXPANDED);
  const [addModal, setAddModal] = useState({ isOpen: false, relationType: undefined });
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, relationType: undefined });

  const localActions = useMemo(
    () => ({
      setExpanded,
      setAddModal,
      setDeleteModal
    }),
    [setExpanded, setAddModal, setDeleteModal]
  );

  return {
    localState: { expanded, addModal, deleteModal },
    localActions,
    reduxState
  };
};
