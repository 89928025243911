import type { AppNavigationHandlers } from './useAppNavigationHandlers.types';
import type { AppNavigationData } from './useAppNavigationData.types';

import * as uiLib from '@compliance.ai/web-components';
import * as helpers from './useAppNavigationHandlers.helpers';
import * as viewActions from 'shared/features/view/view.actions';
import * as documentSelectors from 'shared/features/documents/documents.selectors';
import * as filterUtils from 'utils/filter/filter';
import * as analyticsUtils from 'utils/analytics';
import * as documentConstants from 'constants/DocumentConstants';
import * as routes from 'constants/Routes';
import * as navBarConstants from './useAppNavigationNavBarConfig.constants';
import * as rightPanelConstants from 'shared/features/rightPanel/rightPanel.constants';
import * as enforcementFilterConstants from 'components/Filters/EnforcementFilter/EnforcementFilter.constants';
import * as primaryFilterConstants from 'constants/PrimaryFilter';
import * as restrict from 'components/Restrict';
import * as env from 'shared/config';
import * as viewConstants from 'shared/features/view/view.constants';

import queryString from 'utils/query-string';
import lodash from 'lodash';

import { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppNavigation, usePreviousValue } from '@compliance.ai/web-components';
import { useUserReduxActions } from 'shared/features/user/hooks';
import { useViewReduxActions } from 'shared/features/view/hooks';
import {
  useRightPanelReduxActions,
  useRightPanelQueryParams
} from 'shared/features/rightPanel/hooks';

export const useAppNavigationHandlers = ({
  reduxState,
  localActions,
  formattedData
}: {
  reduxState: AppNavigationData['reduxState'];
  localActions: AppNavigationData['localActions'];
  formattedData: AppNavigationData['formattedData'];
}): AppNavigationHandlers => {
  const appNavigation = useAppNavigation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const rightPanelQueryParams = useRightPanelQueryParams();

  const userReduxActions = useUserReduxActions();
  const viewReduxActions = useViewReduxActions();
  const rightPanelReduxActions = useRightPanelReduxActions();

  const prevRightPanelQueryParams = usePreviousValue(rightPanelQueryParams);
  const prevLocation = usePreviousValue(location);
  const prevReduxState = usePreviousValue(reduxState);

  const handleNavBarToggle: AppNavigationHandlers['handleNavBarToggle'] = isOpen => () => {
    localActions.setIsNavBarOpened(isOpen);
  };

  const handleNavBarItemClick: AppNavigationHandlers['handleNavBarItemClick'] = ({
    label,
    source,
    query,
    errorModalTitle
  }) => () => {
    if (formattedData.restrictedNavBarItems.includes(label)) {
      restrict.showModal(errorModalTitle);
      return;
    }

    localActions.setSelectedNavBarItemLabel(label);

    analyticsUtils.safe_analytics('default', 'Left Nav', source);

    navigate(helpers.formatNavBarItemRoute({ source, query }), {
      state: {
        fromLeftNav: true
      }
    });
  };

  const handleNavBarTimelineClick: AppNavigationHandlers['handleNavBarTimelineClick'] = () => {
    if (
      formattedData.restrictedNavBarItems.includes(navBarConstants.NAV_BAR_ITEM_LABELS.TIMELINE)
    ) {
      restrict.showModal();
      return;
    }

    const {
      currentUser,
      defaults,
      actionBarFilterValues,
      topicThresholdKey,
      categories
    } = reduxState;

    const params = helpers.getPrimaryFilterDefaultQueryParams({
      currentUser,
      defaults,
      actionBarFilterValues,
      topicThresholdKey
    });

    dispatch(viewActions.removeViewAlert());

    // default is display all sources
    analyticsUtils.safe_analytics('default', 'Left Nav', 'Timeline', 'All Sources');

    localActions.setSelectedNavBarItemLabel(navBarConstants.NAV_BAR_ITEM_LABELS.TIMELINE);

    dispatch(viewActions.setFirstTimelineView(true));

    const categoriesToSkip = navBarConstants.TIMELINE_CATEGORIES_TO_SKIP.reduce(
      (result, category) => {
        const categoryId = documentSelectors.getCategoryIDByName(categories, category);
        if (isNaN(categoryId)) {
          return result;
        }
        return [...result, categoryId];
      },
      [] as number[]
    );

    const paramsForSearch = {
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.AGENCY_ID]:
        params[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.AGENCY_ID],
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.JURISDICTION]:
        params[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.JURISDICTION],
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.CONCEPT_ID]:
        params[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.CONCEPT_ID],
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.LANGUAGE_ID]:
        params[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.LANGUAGE_ID],
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.HAS_UNOFFICIAL_PUBLICATION_DATE]:
        params[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.HAS_UNOFFICIAL_PUBLICATION_DATE],
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.TOPIC_THRESHOLD_KEY]:
        params[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.TOPIC_THRESHOLD_KEY],
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.SKIP_CAI_CATEGORY]: categoriesToSkip,
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.VIEW]: viewConstants.VIEW_KEY.TIMELINE,
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.TIMELINE_VIEW]: 'true'
    };

    if (env.shouldShowNewSearch) {
      return navigate(
        uiLib.formatRedirectUrl({
          path: routes.timeline,
          params: paramsForSearch
        })
      );
    }

    filterUtils.explicit_filter_function(
      { timelineView: true },
      location,
      navigate,
      {
        ...paramsForSearch,
        [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.LIMIT]:
          params[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.LIMIT]
      },
      {}
    );
  };

  const handleNavBarEnforcementClick: AppNavigationHandlers['handleNavBarEnforcementClick'] = async () => {
    if (
      formattedData.restrictedNavBarItems.includes(navBarConstants.NAV_BAR_ITEM_LABELS.ENFORCEMENT)
    ) {
      restrict.showModal();
      return;
    }

    const {
      currentUser,
      defaults,
      docTypes,
      actionBarFilterValues,
      topicThresholdKey
    } = reduxState;

    const { user } = currentUser;
    const { email, properties } = user;

    const parsedQuery = queryString.parse(location.search);

    const params = helpers.getEnforcementFilterDefaultQueryParams({
      defaults,
      user,
      query: parsedQuery,
      docTypes: docTypes.docTypes,
      actionBarFilterValues,
      topicThresholdKey,
      shouldShowNewSearch: env.shouldShowNewSearch
    });

    properties.enforcementFilter = {
      [enforcementFilterConstants.FILTER_KEY.JURISDICTION]:
        params[enforcementFilterConstants.QUERY_PARAMS.JURISDICTION],
      [enforcementFilterConstants.FILTER_KEY.TOPIC_ID]:
        params[enforcementFilterConstants.QUERY_PARAMS.TOPIC_ID],
      [enforcementFilterConstants.FILTER_KEY.FEDERAL_SOURCE]:
        params[enforcementFilterConstants.QUERY_PARAMS.AGENCY],
      [enforcementFilterConstants.FILTER_KEY.PUBLISHED_FROM]:
        params[enforcementFilterConstants.QUERY_PARAMS.PUBLISHED_FROM],
      [enforcementFilterConstants.FILTER_KEY.LANGUAGE]:
        params[enforcementFilterConstants.QUERY_PARAMS.LANGUAGE_ID],
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.TOPIC_THRESHOLD_KEY]:
        params[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.TOPIC_THRESHOLD_KEY]
    };

    localActions.setSelectedNavBarItemLabel(navBarConstants.NAV_BAR_ITEM_LABELS.ENFORCEMENT);

    await userReduxActions.updateCurrentUser(email, user);

    viewReduxActions.clearSelectedItems();

    navigate(
      uiLib.formatRedirectUrl({
        path: routes.enforcementExplorer,
        params: params
      })
    );
  };

  const handleNavBarNewsClick: AppNavigationHandlers['handleNavBarNewsClick'] = () => {
    if (formattedData.restrictedNavBarItems.includes(navBarConstants.NAV_BAR_ITEM_LABELS.NEWS)) {
      restrict.showModal();
      return;
    }

    const {
      actionBarFilterValues,
      defaults,
      categories,
      currentUser,
      topicThresholdKey
    } = reduxState;

    const params = helpers.getPrimaryFilterDefaultQueryParams({
      currentUser,
      defaults,
      actionBarFilterValues,
      topicThresholdKey
    });

    const NEWS_CATEGORIES = [
      documentSelectors.getCategoryIDByName(categories, documentConstants.DOCUMENT_CATEGORIES.NEWS),
      documentSelectors.getCategoryIDByName(
        categories,
        documentConstants.DOCUMENT_CATEGORIES.MAINSTREAM_NEWS
      )
    ];

    dispatch(viewActions.removeViewAlert());

    analyticsUtils.safe_analytics('default', 'Left Nav', 'News');

    localActions.setSelectedNavBarItemLabel(navBarConstants.NAV_BAR_ITEM_LABELS.NEWS);

    dispatch(viewActions.setFirstTimelineView(true));

    const paramsForSearch = {
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.JURISDICTION]:
        params[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.JURISDICTION],
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.AGENCY_ID]:
        params[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.AGENCY_ID],
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.NEWS_SOURCE_ID]:
        params[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.NEWS_SOURCE_ID],
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.CONCEPT_ID]:
        params[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.CONCEPT_ID],
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.LANGUAGE_ID]:
        params[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.LANGUAGE_ID],
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.HAS_UNOFFICIAL_PUBLICATION_DATE]:
        params[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.HAS_UNOFFICIAL_PUBLICATION_DATE],
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.TOPIC_THRESHOLD_KEY]:
        params[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.TOPIC_THRESHOLD_KEY],
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.CATEGORY]: NEWS_CATEGORIES,
      [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.VIEW]: viewConstants.VIEW_KEY.NEWS
    };

    if (env.shouldShowNewSearch) {
      return navigate(
        uiLib.formatRedirectUrl({
          path: routes.news,
          params: paramsForSearch
        })
      );
    }

    rightPanelReduxActions.openRightPanel({
      type: rightPanelConstants.RIGHT_PANEL_TYPES.SEARCH_RESULTS
    });

    filterUtils.explicit_filter_function(
      { newsSourcesView: true },
      location,
      navigate,
      {
        ...paramsForSearch,
        [primaryFilterConstants.SUPPORTED_QUERY_PARAMS.LIMIT]:
          params[primaryFilterConstants.SUPPORTED_QUERY_PARAMS.LIMIT]
      },
      {}
    );
  };

  const handleLocationChange: AppNavigationHandlers['handleLocationChange'] = useCallback(() => {
    localActions.setSelectedNavBarItemLabel(
      helpers.getSelectedNavBarItemLabelByLocation({ location })
    );
  }, [location, localActions]);

  const handleRightPanelToggleAfterReduxChange: AppNavigationHandlers['handleRightPanelToggleAfterReduxChange'] = useCallback(() => {
    if (reduxState.rightPanel.isOpen !== prevReduxState.rightPanel.isOpen) {
      appNavigation.setIsRightPanelVisible(reduxState.rightPanel.isOpen);
      appNavigation.setIsRightPanelOpen(isOpen => (reduxState.rightPanel.isOpen ? true : isOpen));
    }
  }, [appNavigation, prevReduxState.rightPanel.isOpen, reduxState.rightPanel.isOpen]);

  const handleRightPanelCollapse: AppNavigationHandlers['handleRightPanelCollapse'] = () => {
    appNavigation.setIsRightPanelOpen(false);
  };

  const handleRightPanelClose: AppNavigationHandlers['handleRightPanelClose'] = () => {
    rightPanelReduxActions.closeRightPanel();
  };

  const handleRightPanelOpenOnMount: AppNavigationHandlers['handleRightPanelOpenOnMount'] = () => {
    if (rightPanelQueryParams.initialOpenArgs.type) {
      rightPanelReduxActions.openRightPanel({
        type: rightPanelQueryParams.initialOpenArgs.type,
        isClosable: rightPanelQueryParams.initialOpenArgs.isClosable,
        overlayType: rightPanelQueryParams.initialOpenArgs.overlayType,
        props: {
          documentId: rightPanelQueryParams.initialOpenArgs.documentId,
          shouldOpenRightPanelWithOverlay:
            rightPanelQueryParams.initialOpenArgs.shouldOpenRightPanelWithOverlay,
          shouldCloseRightPanelOnOverlayClose:
            rightPanelQueryParams.initialOpenArgs.shouldCloseRightPanelOnOverlayClose,
          sentenceNoteId: rightPanelQueryParams.initialOpenArgs.sentenceNoteId,
          activeTabKey: rightPanelQueryParams.initialOpenArgs.activeTabKey,
          diffData: rightPanelQueryParams.initialOpenArgs.diffData
        }
      });
    } else if (rightPanelConstants.ROUTES_WITH_SEARCH_RESULTS.includes(location.pathname)) {
      rightPanelReduxActions.openRightPanel({
        type: rightPanelConstants.RIGHT_PANEL_TYPES.SEARCH_RESULTS
      });
    }
  };

  const handleRightPanelQueryParamsChange: AppNavigationHandlers['handleRightPanelQueryParamsChange'] = useCallback(() => {
    /**
     *
     * Need to call navigate only when right panel query params change.
     * Otherwise, we would end up with race conditions caused by
     * navigate call on mount in other components
     *
     */
    if (!lodash.isEqual(prevRightPanelQueryParams.queryParams, rightPanelQueryParams.queryParams)) {
      navigate(
        uiLib.formatRedirectUrl({
          path: location.pathname,
          params: rightPanelQueryParams.allQueryParams
        }),
        {
          replace: true
        }
      );
    }
  }, [
    location.pathname,
    navigate,
    prevRightPanelQueryParams.queryParams,
    rightPanelQueryParams.allQueryParams,
    rightPanelQueryParams.queryParams
  ]);

  const handleRightPanelCloseOnRouteChange: AppNavigationHandlers['handleRightPanelCloseOnRouteChange'] = useCallback(() => {
    if (prevLocation.pathname === location.pathname) {
      return;
    }

    if (rightPanelConstants.ROUTES_WITH_SEARCH_RESULTS.includes(location.pathname)) {
      rightPanelReduxActions.openRightPanel({
        type: rightPanelConstants.RIGHT_PANEL_TYPES.SEARCH_RESULTS
      });

      return;
    }

    if (!rightPanelConstants.ROUTES_WITH_PREVIOUS_RIGHT_PANEL.includes(location.pathname)) {
      rightPanelReduxActions.closeRightPanel();
    }
  }, [location.pathname, prevLocation.pathname, rightPanelReduxActions]);

  /**
   *
   * This handler is a workaround to fix the dashboard width after right panel toggle
   *
   */
  const handleWindowResizeEventCall: AppNavigationHandlers['handleWindowResizeEventCall'] = useCallback(() => {
    window.dispatchEvent(new Event('resize'));
  }, []);

  const handleRightPanelDragStart: AppNavigationHandlers['handleRightPanelDragStart'] = useCallback(() => {
    localActions.setIframeMouseEventsDisabled(true);
  }, [localActions]);

  const handleRightPanelDragEnd: AppNavigationHandlers['handleRightPanelDragEnd'] = useCallback(() => {
    localActions.setIframeMouseEventsDisabled(false);
  }, [localActions]);

  const handleRightPanelResetOnUnmount: AppNavigationHandlers['handleRightPanelResetOnUnmount'] = () => {
    appNavigation.setIsRightPanelOpen(false);
    appNavigation.setIsRightPanelVisible(false);
  };

  return {
    handleNavBarToggle,
    handleNavBarItemClick,
    handleNavBarTimelineClick,
    handleNavBarEnforcementClick,
    handleNavBarNewsClick,
    handleLocationChange,
    handleRightPanelOpenOnMount,
    handleRightPanelToggleAfterReduxChange,
    handleRightPanelCollapse,
    handleRightPanelClose,
    handleRightPanelQueryParamsChange,
    handleRightPanelCloseOnRouteChange,
    handleWindowResizeEventCall,
    handleRightPanelDragStart,
    handleRightPanelDragEnd,
    handleRightPanelResetOnUnmount
  };
};
