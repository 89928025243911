import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  fetchFolders as reduxFetchFolders,
  fetchDocumentsSavedFolders as reduxFetchDocumentsSavedFolders,
  fetchDocumentSavedFoldersCount as reduxFetchDocumentSavedFoldersCount,
  fetchFolderDetails as reduxFetchFolderDetails,
  resetFolderDetails as reduxResetFolderDetails,
  fetchFolderPaths as reduxFetchFolderPaths,
  resetFolderPaths as reduxResetFolderPaths,
  createFolder as reduxCreateFolder,
  modifyFolder as reduxModifyFolder,
  deleteFolders as reduxDeleteFolders,
  copyFolder as reduxCopyFolder,
  shareFolder as reduxShareFolder,
  addDocumentsToFolder as reduxAddDocumentsToFolder,
  triggerFoldersRefresh as reduxTriggerFoldersRefresh
} from 'shared/features/folders/folders.actions';
import { getUserAuthenticatedState } from 'shared/features/auth/auth.selectors';

export const useFoldersReduxActions = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getUserAuthenticatedState);

  const fetchFolders = useCallback(
    async (parentId, params) => {
      return await dispatch(reduxFetchFolders({ parentId, params })).then(unwrapResult);
    },
    [dispatch]
  );

  const fetchDocumentsSavedFolders = useCallback(
    async params => {
      return await dispatch(reduxFetchDocumentsSavedFolders({ params })).then(unwrapResult);
    },
    [dispatch]
  );

  const fetchDocumentSavedFoldersCount = useCallback(
    async documentId => {
      if (!isAuthenticated) {
        return Promise.resolve();
      }
      return await dispatch(reduxFetchDocumentSavedFoldersCount({ documentId })).then(unwrapResult);
    },
    [dispatch, isAuthenticated]
  );

  const fetchFolderDetails = useCallback(
    async id => {
      return await dispatch(reduxFetchFolderDetails({ id })).then(unwrapResult);
    },
    [dispatch]
  );

  const resetFolderDetails = useCallback(() => {
    dispatch(reduxResetFolderDetails());
  }, [dispatch]);

  const fetchFolderPaths = useCallback(
    async id => {
      return await dispatch(reduxFetchFolderPaths({ id })).then(unwrapResult);
    },
    [dispatch]
  );

  const resetFolderPaths = useCallback(() => {
    dispatch(reduxResetFolderPaths());
  }, [dispatch]);

  const createFolder = useCallback(
    async (parentId, params) => {
      await dispatch(reduxCreateFolder({ parentId, params })).then(unwrapResult);
    },
    [dispatch]
  );

  const modifyFolder = useCallback(
    async (parentId, id, params) => {
      await dispatch(reduxModifyFolder({ parentId, id, params })).then(unwrapResult);
    },
    [dispatch]
  );

  const deleteFolders = useCallback(
    async (parentId, params) => {
      await dispatch(reduxDeleteFolders({ parentId, params })).then(unwrapResult);
    },
    [dispatch]
  );

  const copyFolder = useCallback(
    async (id, params) => {
      await dispatch(reduxCopyFolder({ id, params })).then(unwrapResult);
    },
    [dispatch]
  );

  const shareFolder = useCallback(
    async (id, params) => {
      await dispatch(reduxShareFolder({ id, params })).then(unwrapResult);
    },
    [dispatch]
  );

  const addDocumentsToFolder = useCallback(
    async (id, params) => {
      await dispatch(reduxAddDocumentsToFolder({ id, params })).then(unwrapResult);
    },
    [dispatch]
  );

  const triggerFoldersRefresh = useCallback(() => {
    dispatch(reduxTriggerFoldersRefresh());
  }, [dispatch]);

  return useMemo(
    () => ({
      fetchFolders,
      fetchDocumentsSavedFolders,
      fetchDocumentSavedFoldersCount,
      fetchFolderDetails,
      resetFolderDetails,
      fetchFolderPaths,
      resetFolderPaths,
      createFolder,
      modifyFolder,
      deleteFolders,
      copyFolder,
      shareFolder,
      addDocumentsToFolder,
      triggerFoldersRefresh
    }),
    [
      fetchFolders,
      fetchDocumentsSavedFolders,
      fetchDocumentSavedFoldersCount,
      fetchFolderDetails,
      resetFolderDetails,
      fetchFolderPaths,
      resetFolderPaths,
      createFolder,
      modifyFolder,
      deleteFolders,
      copyFolder,
      shareFolder,
      addDocumentsToFolder,
      triggerFoldersRefresh
    ]
  );
};
