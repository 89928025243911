import type { GlobalState } from '../../reducers';

import type * as alertsApiTypes from './alerts.api.types';

import { createSelector } from '@reduxjs/toolkit';
import * as uiLib from '@compliance.ai/web-components';

/**
 *
 * Alerts reducer selectors
 */
export const getAlertsReducer = (state: GlobalState) => state.alerts;

export const getUserAlerts = (state: GlobalState) => state.alerts.user_alerts;

export const getUserAlertById = createSelector(
  [
    getUserAlerts,
    (state: GlobalState, alertId: alertsApiTypes.AlertFromResponse['id'] | null) => alertId
  ],
  (
    userAlerts: alertsApiTypes.AlertFromResponse[],
    alertId: alertsApiTypes.AlertFromResponse['id'] | null
  ) => {
    return userAlerts.find(alert => alert?.id === alertId) ?? null;
  }
);

export const getUserAlertLastViewedDate = createSelector([getUserAlertById], (alert):
  | string
  | null => {
  const date = alert?.docs_last_viewed_at;

  if (date) {
    return uiLib.formatDate(date, {
      format: uiLib.DATE_FORMATS.API_DATE_TIME,
      passedTimezone: uiLib.TIMEZONES.UTC,
      timezone: uiLib.TIMEZONES.UTC,
      shouldKeepLocalTime: true
    });
  }

  return null;
});

/**
 *
 *  Alerts common selectors
 *
 */

export const getAlertsFetchingState = createSelector(
  getAlertsReducer,
  alertsReducer => alertsReducer.isFetching
);
