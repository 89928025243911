import * as reducerTypes from './orgDocumentsMetadata.reducer.types';
import * as constantsTypes from './orgDocumentsMetadata.constants';

export const getInitialCAICapabilitiesMap = () => {
  return Object.values(constantsTypes.CAI_METADATA_CAPABILITIES_KEYS).reduce((obj, key) => {
    return {
      ...obj,
      [key]: false
    };
  }, {} as reducerTypes.OrgDocumentsMetadataState['selectedCAICapabilities']);
};
