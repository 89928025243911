import type { EditMetadataButtonProps } from '../EditMetadataButton.types';
import type { EditMetadataButtonData } from './useEditMetadataButtonData.types';

import * as documentsApiV2 from 'shared/features/documents/documents.apiV2';
import * as errorUtils from 'utils/errors';
import * as queryKeys from 'constants/QueryKeys';
import * as constants from './useEditMetadataButtonQueries.constants';
import * as documentConstants from 'shared/features/documents/documents.constants';

import { useQuery } from '@tanstack/react-query';
import { useBucketsReduxActions } from 'shared/features/buckets/hooks';

export const useEditMetadataButtonQueries = ({
  props,
  localActions,
  formattedData
}: {
  props: Pick<EditMetadataButtonProps, 'document'>;
  localActions: EditMetadataButtonData['localActions'];
  formattedData: EditMetadataButtonData['formattedData'];
}) => {
  const bucketReduxActions = useBucketsReduxActions();

  const fetchAccessibleBucketsCount = useQuery({
    enabled: Boolean(props.document.permissions?.bucket_id),
    queryKey: [queryKeys.QUERY_KEYS.BUCKETS],
    queryFn: async () => {
      const { total } = await bucketReduxActions.getBuckets({
        limit: 1,
        offset: 0,
        is_editable: true,
        id: props.document.permissions?.bucket_id as number
      });

      localActions.setIsDocumentBucketAccessible(Boolean(total));

      return total;
    },
    refetchOnWindowFocus: false
  });

  const syncUpdateRequestsStatus = useQuery({
    enabled: formattedData.isButtonShown,
    queryKey: queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.DOCUMENTS][
      queryKeys.QUERY_SECTIONS.DOC_SYNC_UPDATE_REQUESTS
    ](props.document.id),
    queryFn: () => documentsApiV2.syncAllDocumentUpdateRequests(props.document.id),
    retry: false,
    refetchOnWindowFocus: false,
    select: data => ({
      isUpdating: data.status === documentConstants.DOCUMENT_UPDATE_REQUEST_STATUSES.IN_PROGRESS
    }),
    onError: errorUtils.logReactQueryError,
    refetchInterval: constants.REFETCH_INTERVAL
  });

  return {
    fetchAccessibleBucketsCount,
    syncUpdateRequestsStatus
  };
};
