import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getExportLimit } from 'utils/exportLimit/exportLimit';
import { getUserAuthenticatedState } from 'shared/features/auth/auth.selectors';
import { getCurrentUserPermissions } from 'shared/features/user/user.selectors';
import { getDocumentDetailsByDocumentId } from 'shared/features/documents/documents.selectors';
import { getRightPanelDocumentId } from 'shared/features/rightPanel/rightPanel.selectors';
import { CREATE_FOLDERS } from 'constants/Permissions';

export const useFolderData = ({ props }) => {
  const [isAddToFolderModalOpen, setAddToFolderModalOpen] = useState(false);

  const reduxState = useSelector(state => ({
    isAuthenticated: getUserAuthenticatedState(state),
    userPermissions: getCurrentUserPermissions(state),
    rightPanelDocument: getDocumentDetailsByDocumentId(getRightPanelDocumentId(state))(state)
  }));

  const isRestricted = useMemo(() => {
    return !reduxState.isAuthenticated || !reduxState.userPermissions[CREATE_FOLDERS];
  }, [reduxState]);

  const isDisabled = useMemo(() => {
    const documentIdsCount = props.documentIds.length;
    const selectedOverUpperLimit = documentIdsCount > getExportLimit();

    return documentIdsCount === 0 || selectedOverUpperLimit;
  }, [props.documentIds]);

  const localActions = useMemo(
    () => ({
      setAddToFolderModalOpen
    }),
    [setAddToFolderModalOpen]
  );

  return {
    localState: {
      isAddToFolderModalOpen
    },
    localActions,
    reduxState,
    formattedData: {
      isRestricted,
      isDisabled
    }
  };
};
