import type { NavBarAuthSourceConfig } from './useNavBarAuthHandlers.types';

export enum NAV_BAR_AUTH_SOURCES {
  PLANS = 'Plans',
  LOGIN = 'Login'
}

export const NAV_BAR_AUTH_SOURCES_CONFIGS: {
  [key in NAV_BAR_AUTH_SOURCES]: NavBarAuthSourceConfig;
} = {
  [NAV_BAR_AUTH_SOURCES.LOGIN]: {
    source: NAV_BAR_AUTH_SOURCES.LOGIN
  },
  [NAV_BAR_AUTH_SOURCES.PLANS]: {
    source: NAV_BAR_AUTH_SOURCES.PLANS,
    query: { fromSource: 'pro' }
  }
};
