import { fetch } from 'utils/api';
import {
  api_createWorkflowMold,
  api_deleteWorkflowMold,
  api_updateWorkflowMold
} from './workflowMold.api';
import { getJWTToken } from '../auth/auth.selectors';
import { logError } from 'utils/errors';

export const CREATE_WORKFLOW_MOLD = 'CREATE_WORKFLOW_MOLD';
export const DELETE_WORKFLOW_MOLD = 'DELETE_WORKFLOW_MOLD';
export const RECEIVE_WORKFLOW_MOLDS = 'RECEIVE_WORKFLOW_MOLDS';
export const REQUEST_WORKFLOW_MOLDS = 'REQUEST_WORKFLOW_MOLDS';
export const UPDATE_WORKFLOW = 'UPDATE_WORKFLOW';

export const createWorkflowMold = ({
  workflow_id,
  workflow_tasks,
  workflow_name
}) => async dispatch => {
  try {
    const res = await api_createWorkflowMold({ workflow_id, workflow_tasks, workflow_name });
    dispatch({ type: CREATE_WORKFLOW_MOLD, payload: res.workflow_mold });
  } catch (error) {
    // pass
  }
};

export const deleteWorkflowMold = ({ workflow_mold_id }) => async dispatch => {
  try {
    const res = await api_deleteWorkflowMold({ workflow_mold_id });
    dispatch({ type: DELETE_WORKFLOW_MOLD, payload: res.workflow_molds });
  } catch (error) {
    // pass
  }
};

export const updateWorkflowMoldAC = payload => ({ type: UPDATE_WORKFLOW, payload });

export const updateWorkflowMold = params => dispatch => {
  return api_updateWorkflowMold(params.id, params.properties).then(
    res => {
      dispatch(updateWorkflowMoldAC(res));
    },
    err => Promise.reject(err)
  );
};

function requestWorkflowMolds() {
  return {
    type: REQUEST_WORKFLOW_MOLDS
  };
}

function receiveWorkflowMolds(data) {
  return {
    type: RECEIVE_WORKFLOW_MOLDS,
    payload: data.workflow_molds
  };
}

export const fetchWorkflowMolds = (params, rnToken = null) => async (dispatch, getState) => {
  try {
    const JWTToken = getJWTToken(getState());

    dispatch(requestWorkflowMolds());

    const response = await fetch({
      url: '/workflow_molds',
      method: 'GET',
      params,
      headers: {
        Authorization: rnToken || JWTToken
      }
    });

    dispatch(receiveWorkflowMolds(response));

    return response;
  } catch (e) {
    logError(e);
  }
};
