import type * as apiTypes from './buckets.api.types';
import type * as actionsTypes from './buckets.actions.types';

import type { UserFromResponse, TeamFromResponse } from '../user/user.types';

import * as uiLib from '@compliance.ai/web-components';
import * as dateUtils from 'utils/date-utils';

import { StatusCodes } from 'http-status-codes';

export const normalizeBucketsResponse = ({
  response,
  userTeamsIds,
  userId
}: {
  response: apiTypes.GetBucketsResponse;
  userTeamsIds: TeamFromResponse['id'][];
  userId: UserFromResponse['id'];
}): actionsTypes.NormalizedBucketsResponse => {
  return {
    count: response.count,
    offset: response.offset,
    total: response.total,
    buckets: response.buckets.map(bucket => normalizeBucket({ bucket, userTeamsIds, userId }))
  };
};

export const normalizeBucket = ({
  bucket,
  userTeamsIds,
  userId
}: {
  bucket: apiTypes.BucketFromResponse;
  userTeamsIds: TeamFromResponse['id'][];
  userId: UserFromResponse['id'];
}): actionsTypes.NormalizedBucket => {
  return {
    id: bucket.id,
    name: bucket.name,
    parent: bucket.parent,
    createdAt: dateUtils.isValidDate(bucket.created_at)
      ? dateUtils.formatDate(bucket.created_at)
      : '',
    lastUpdatedAt: dateUtils.isValidDate(bucket.last_updated_at)
      ? dateUtils.formatDate(bucket.last_updated_at)
      : '',
    annotationCount: bucket.annotation_count ?? 0,
    obligationCount: bucket.obligation_count ?? 0,
    workflowCount: bucket.workflow_count ?? 0,
    organizationDocumentCount: null,
    labelCount: bucket.label_count ?? 0,
    createdBy: bucket.created_by,
    permissions: normalizeBucketPermissions(bucket.permissions),
    isEditable: normalizeBucketIsEditable({
      bucket,
      userTeamsIds,
      userId
    })
  };
};

export const normalizeBucketPermissions = (
  permissions: apiTypes.BucketPermissionsFromResponse
): actionsTypes.NormalizedBucketPermissions => {
  return {
    groupPermissionWriteAccess: permissions.group_permission_write_access,
    groupReadAccess: permissions.group_read_access,
    groupWriteAccess: permissions.group_write_access,
    roleReadAccess: permissions.role_read_access,
    roleWriteAccess: permissions.role_write_access,
    userPermissionWriteAccess: permissions.user_permission_write_access,
    userReadAccess: permissions.user_read_access,
    userWriteAccess: permissions.user_write_access
  };
};

export const normalizeBucketIsEditable = ({
  bucket,
  userTeamsIds,
  userId
}: {
  bucket: apiTypes.BucketFromResponse;
  userTeamsIds: TeamFromResponse['id'][];
  userId: UserFromResponse['id'];
}): boolean => {
  if (bucket.created_by?.id === userId) {
    return true;
  }

  if (bucket.permissions?.user_write_access?.includes(userId)) {
    return true;
  }

  if (userTeamsIds.some(teamId => bucket.permissions?.group_write_access.includes(teamId))) {
    return true;
  }

  return false;
};

export const isValidationError = ({
  message,
  expectedMessage,
  statusCode
}: {
  message: Error['message'];
  expectedMessage: string;
  statusCode: StatusCodes;
}) => message.includes(expectedMessage) && statusCode === StatusCodes.CONFLICT;

export const showValidationErrorNotification = ({
  message,
  title = 'Validation error'
}: {
  message: string;
  title?: string;
}) =>
  uiLib.showNotification({
    type: uiLib.ALERT_TYPES.VALIDATION_ERROR,
    title,
    message
  });
