import type { DocumentFromResponse } from 'shared/features/documents/documents.types';

export const getAgencies = ({ document }: { document: DocumentFromResponse }) => {
  if (document.agencies) {
    return document.agencies
      .map(a => a.short_name || a.name)
      .sort()
      .join(', ');
  }

  return '';
};
