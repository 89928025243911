import moment from 'moment';
import {
  HAS_SENTENCES,
  HAS_ANNOTATIONS,
  IS_PREMIUM_CONTENT,
  OBLIGATIONS,
  MY_TASKS,
  MY_ORG_TASKS,
  IS_ORGANIZATION_DOCUMENT
} from 'shared/features/filters/filters.constants';
import {
  isPastNextRelativeDate,
  isPastRelativeDate,
  isRelativeDate,
  formatRelativeDate as relativeDateFomatter
} from 'utils/relativeDate-utils';
import { FILTER_KEY } from 'constants/PrimaryFilter';
import { ADVANCED_SEARCH_KEYS } from 'constants/AdvancedSearch';

export const GROUP = {
  OR: 'orGroup',
  AND: 'andGroup'
};
export const GROUP_OPERATOR = {
  [GROUP.OR]: ' OR ',
  [GROUP.AND]: ' AND '
};

export const inClause = (key, values) => {
  if (!values.length) return '';
  const formatedValues = values
    .map(value => {
      return `"${value}"`;
    })
    .join(', ');
  return `${key} IN [${formatedValues}]`;
};

export const exactClause = (key, value, format) => {
  if (!value) return '';
  if (format) value = format(value);
  return `${key} = "${value}"`;
};

const makeClause = (key, values, format) => {
  if (!values || !values.length) return '';
  if (values.length === 1) {
    return exactClause(key, values[0], format);
  }
  return inClause(key, values);
};

const makeRelativeDateClause = (key, value, format) => {
  if (!value) return '';
  if (format) value = format(value);
  if (isPastNextRelativeDate(value)) {
    return isPastRelativeDate(value) ? `${key} <= "${value.slice(1)}"` : `${key} >= "${value}"`;
  }
  return `${key} = "${value}"`;
};

export const booleanClause = key => `${key} = true`;

export const exactFalseBooleanClause = (key, value) => {
  if (Boolean(value)) return '';
  return `${key} = ${value}`;
};

export const booleanFalseClause = (key, value) => {
  return value === false ? `${key} = false` : '';
};

export const booleanExactClause = (key, value) => {
  if (isNaN(value)) {
    return '';
  }
  return value ? `${key} = true` : `${key} = false`;
};

const groupConjunctionTranslation = {
  [GROUP.OR]: [
    {
      conjunction: GROUP.AND,
      attributes: [FILTER_KEY.EFFECTIVE_START_DATE, FILTER_KEY.EFFECTIVE_END_DATE]
    },
    {
      conjunction: GROUP.AND,
      attributes: [FILTER_KEY.COMMENTS_CLOSE_START_DATE, FILTER_KEY.COMMENTS_CLOSE_END_DATE]
    }
  ]
};

const docPropertyTranslation = {
  [OBLIGATIONS]: {
    key: ADVANCED_SEARCH_KEYS.OBLIGATIONS,
    translation: booleanClause
  },
  [HAS_SENTENCES]: {
    key: ADVANCED_SEARCH_KEYS.DIFFING_AVAILABLE,
    translation: booleanClause
  },
  [HAS_ANNOTATIONS]: {
    key: ADVANCED_SEARCH_KEYS.DIFFING_AVAILABLE,
    translation: booleanClause
  },
  [IS_PREMIUM_CONTENT]: {
    key: ADVANCED_SEARCH_KEYS.PREMIUM_CONTENT,
    translation: booleanClause
  },
  [IS_ORGANIZATION_DOCUMENT]: {
    key: ADVANCED_SEARCH_KEYS.IS_ORGANIZATION_DOCUMENT,
    translation: booleanClause
  },
  [MY_ORG_TASKS]: {
    key: ADVANCED_SEARCH_KEYS.MY_ORG_TASKS,
    translation: booleanClause
  },
  [MY_TASKS]: {
    key: ADVANCED_SEARCH_KEYS.MY_TASKS,
    translation: booleanClause
  }
};

export const formatDate = date => {
  return moment(date).format('YYYY-MM-DD');
};

export const formatRelativeDate = value => {
  if (isRelativeDate(value)) return relativeDateFomatter(value);

  return formatDate(value);
};

export const setDocProperty = (key, value) => {
  const docPropertiesObject = docPropertyTranslation[value];
  if (docPropertiesObject) {
    return docPropertiesObject.translation(docPropertiesObject.key, value);
  }

  return '';
};

export const setEitlLabels = (key, value) => {
  const filteredValues = value.filter(labelId => labelId !== null);

  return makeClause(key, filteredValues);
};

const attributeTranslation = {
  [FILTER_KEY.AGENCIES]: {
    key: ADVANCED_SEARCH_KEYS.AGENCY,
    translation: makeClause,
    group: GROUP.OR
  },
  [FILTER_KEY.AUTHOR]: {
    key: ADVANCED_SEARCH_KEYS.AUTHOR,
    translation: makeClause,
    group: GROUP.OR
  },
  [FILTER_KEY.JURISDICTIONS]: {
    key: ADVANCED_SEARCH_KEYS.JURISDICTION,
    translation: makeClause,
    group: GROUP.OR
  },
  [FILTER_KEY.MAINSTREAM_NEWS_SOURCES]: {
    key: ADVANCED_SEARCH_KEYS.NEWS_SOURCE,
    translation: makeClause,
    group: GROUP.OR
  },
  [FILTER_KEY.TOPICS]: {
    key: ADVANCED_SEARCH_KEYS.TOPIC,
    translation: makeClause,
    group: GROUP.AND
  },
  [FILTER_KEY.TEXT]: {
    key: ADVANCED_SEARCH_KEYS.TEXT,
    translation: exactClause,
    group: GROUP.AND
  },
  [FILTER_KEY.TEXT_MATCH_TYPES]: {
    key: ADVANCED_SEARCH_KEYS.EXACT_MATCH_TEXT,
    translation: exactClause,
    group: GROUP.AND
  },
  [FILTER_KEY.TITLE_MATCH_TYPES]: {
    key: ADVANCED_SEARCH_KEYS.EXACT_MATCH_TITLE,
    translation: exactClause,
    group: GROUP.AND
  },
  [FILTER_KEY.PUB_DATE]: {
    key: ADVANCED_SEARCH_KEYS.PUBLICATION_DATE,
    translation: makeRelativeDateClause,
    format: formatRelativeDate,
    group: GROUP.AND
  },
  [FILTER_KEY.HAS_UNOFFICIAL_PUB_DATE]: {
    key: ADVANCED_SEARCH_KEYS.HAS_UNOFFICIAL_PUB_DATE,
    translation: exactFalseBooleanClause,
    group: GROUP.AND
  },
  [FILTER_KEY.PUB_START_DATE]: {
    key: ADVANCED_SEARCH_KEYS.PUBLISHED_FROM,
    translation: exactClause,
    format: formatDate,
    group: GROUP.AND
  },
  [FILTER_KEY.PUB_END_DATE]: {
    key: ADVANCED_SEARCH_KEYS.PUBLISHED_TO,
    translation: exactClause,
    format: formatDate,
    group: GROUP.AND
  },
  [FILTER_KEY.ADDED_DATE]: {
    key: ADVANCED_SEARCH_KEYS.ADDED_DATE,
    translation: makeRelativeDateClause,
    format: formatRelativeDate,
    group: GROUP.AND
  },
  [FILTER_KEY.ADDED_START_DATE]: {
    key: ADVANCED_SEARCH_KEYS.ADDED_FROM,
    translation: exactClause,
    format: formatDate,
    group: GROUP.AND
  },
  [FILTER_KEY.ADDED_END_DATE]: {
    key: ADVANCED_SEARCH_KEYS.ADDED_TO,
    translation: exactClause,
    format: formatDate,
    group: GROUP.AND
  },
  [FILTER_KEY.UPDATED_DATE]: {
    key: ADVANCED_SEARCH_KEYS.UPDATED_DATE,
    translation: makeRelativeDateClause,
    format: formatRelativeDate,
    group: GROUP.AND
  },
  [FILTER_KEY.UPDATED_START_DATE]: {
    key: ADVANCED_SEARCH_KEYS.UPDATED_FROM,
    translation: exactClause,
    format: formatDate,
    group: GROUP.AND
  },
  [FILTER_KEY.UPDATED_END_DATE]: {
    key: ADVANCED_SEARCH_KEYS.UPDATED_TO,
    translation: exactClause,
    format: formatDate,
    group: GROUP.AND
  },
  [FILTER_KEY.EFFECTIVE_DATE]: {
    key: ADVANCED_SEARCH_KEYS.EFFECTIVE_DATE,
    translation: makeRelativeDateClause,
    format: formatRelativeDate,
    group: GROUP.OR
  },
  [FILTER_KEY.EFFECTIVE_START_DATE]: {
    key: ADVANCED_SEARCH_KEYS.EFFECTIVE_FROM,
    translation: exactClause,
    format: formatDate,
    group: GROUP.OR
  },
  [FILTER_KEY.EFFECTIVE_END_DATE]: {
    key: ADVANCED_SEARCH_KEYS.EFFECTIVE_TO,
    translation: exactClause,
    format: formatDate,
    group: GROUP.OR
  },
  [FILTER_KEY.COMMENTS_CLOSE_DATE]: {
    key: ADVANCED_SEARCH_KEYS.COMMENTS_CLOSE_DATE,
    translation: makeRelativeDateClause,
    format: formatRelativeDate,
    group: GROUP.OR
  },
  [FILTER_KEY.COMMENTS_CLOSE_START_DATE]: {
    key: ADVANCED_SEARCH_KEYS.COMMENTS_CLOSE_FROM,
    translation: exactClause,
    format: formatDate,
    group: GROUP.OR
  },
  [FILTER_KEY.COMMENTS_CLOSE_END_DATE]: {
    key: ADVANCED_SEARCH_KEYS.COMMENTS_CLOSE_TO,
    translation: exactClause,
    format: formatDate,
    group: GROUP.OR
  },
  [FILTER_KEY.DOC_TYPES]: {
    key: ADVANCED_SEARCH_KEYS.CAI_CATEGORY,
    translation: makeClause,
    group: GROUP.AND
  },
  [FILTER_KEY.REGULATIONS]: {
    key: ADVANCED_SEARCH_KEYS.REGULATION,
    translation: makeClause,
    group: GROUP.AND
  },
  [FILTER_KEY.ACTS]: {
    key: ADVANCED_SEARCH_KEYS.ACT,
    translation: makeClause,
    group: GROUP.AND
  },
  [FILTER_KEY.BANKS]: {
    key: ADVANCED_SEARCH_KEYS.BANK,
    translation: makeClause,
    group: GROUP.AND
  },
  [FILTER_KEY.CITATION]: {
    key: ADVANCED_SEARCH_KEYS.CITATION,
    translation: exactClause,
    group: GROUP.AND
  },
  [FILTER_KEY.TITLE]: {
    key: ADVANCED_SEARCH_KEYS.TITLE,
    translation: exactClause,
    group: GROUP.AND
  },
  [FILTER_KEY.DOC_PROPERTIES]: {
    key: ADVANCED_SEARCH_KEYS.DOC_PROPERTY,
    translation: setDocProperty,
    group: GROUP.AND
  },
  [FILTER_KEY.LABELS]: {
    key: ADVANCED_SEARCH_KEYS.LABEL,
    translation: makeClause,
    group: GROUP.AND
  },
  [FILTER_KEY.LANGUAGE]: {
    key: ADVANCED_SEARCH_KEYS.LANGUAGE_ID,
    translation: exactClause,
    group: GROUP.AND
  },
  [FILTER_KEY.HAS_EITL_LABELS]: {
    key: ADVANCED_SEARCH_KEYS.HAS_EITL_LABELS,
    translation: booleanFalseClause,
    group: GROUP.OR
  },
  [FILTER_KEY.EITL_LABELS]: {
    key: ADVANCED_SEARCH_KEYS.EITL_LABEL_ID,
    translation: setEitlLabels,
    group: GROUP.OR
  },
  [FILTER_KEY.CONCEPTS]: {
    key: ADVANCED_SEARCH_KEYS.CONCEPT,
    translation: makeClause,
    group: GROUP.AND
  },
  [FILTER_KEY.EXCLUDE_FEDERAL_REGISTER]: {
    key: ADVANCED_SEARCH_KEYS.EXCLUDE_FEDERAL_REGISTER,
    translation: booleanExactClause,
    group: GROUP.AND
  },
  [FILTER_KEY.DOCUMENT_ID]: {
    key: ADVANCED_SEARCH_KEYS.DOC_ID,
    translation: exactClause,
    group: GROUP.AND
  }
};

export const getAttributeClause = (params, attribute) => {
  const attributeObject = attributeTranslation[attribute];

  if (attributeObject) {
    return attributeObject.translation(
      attributeObject.key,
      params[attribute],
      attributeObject.format
    );
  }

  return null;
};

export const getGroupConjunctions = params => {
  const result = {
    keys: [],
    [GROUP.AND]: [],
    [GROUP.OR]: []
  };

  for (const [group, conjunctions] of Object.entries(groupConjunctionTranslation)) {
    for (const { conjunction, attributes } of conjunctions) {
      const clauses = attributes.reduce((accur, attribute) => {
        const clause = getAttributeClause(params, attribute);
        if (clause) {
          return [...accur, clause];
        }
        return accur;
      }, []);

      if (clauses.length === attributes.length) {
        result[group].push(`(${clauses.join(GROUP_OPERATOR[conjunction])})`);
        result.keys = [...result.keys, ...attributes];
      }
    }
  }

  return result;
};

const formatResult = result => {
  const orGroup = result[GROUP.OR].join(GROUP_OPERATOR[GROUP.OR]);
  const andGroup = result[GROUP.AND].join(GROUP_OPERATOR[GROUP.AND]);

  if (orGroup.length && andGroup.length) {
    return `(${orGroup}) AND ${andGroup}`;
  }
  if (orGroup.length) {
    return orGroup;
  }
  if (andGroup.length) {
    return andGroup;
  }

  return '';
};

export const convertToAdvancedSearch = params => {
  const conjunctions = getGroupConjunctions(params);
  const result = {
    [GROUP.AND]: conjunctions[GROUP.AND],
    [GROUP.OR]: conjunctions[GROUP.OR]
  };

  Object.keys(params)
    .filter(key => !conjunctions.keys.includes(key))
    .forEach(key => {
      const clause = getAttributeClause(params, key);
      if (clause) {
        result[attributeTranslation[key].group].push(clause);
      }
    });

  return formatResult(result);
};
