import {
  documentIsPremiumContent,
  triggerPremiumContentMixpanel
} from 'utils/documents/documentsAccess';
import { safe_analytics } from 'utils/analytics';
import { apiKey, apiUrl } from 'shared/config';
import { OVERLAY_TYPES } from 'shared/features/rightPanel/rightPanel.constants';
import { isValidSKU } from 'utils/skus';
import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';

export const useLinksHandlers = ({ props, formattedData, reduxState }) => {
  const rightPanelReduxActions = useRightPanelReduxActions();

  const handleSourceOpen = url => {
    const isPremiumContent = documentIsPremiumContent({ document: props.document });

    if (isPremiumContent && reduxState.currentUserEmail) {
      triggerPremiumContentMixpanel(reduxState.currentUserEmail, props.document);
    }

    safe_analytics(
      'Doc Details – View Agency Website',
      'Doc Details',
      'View Agency Website',
      props.document.title
    );

    window.open(url, '_blank');
  };

  const handlePDFClick = () => {
    const id = reduxState.currentViewId || reduxState.rightPanelDocumentId;
    const url = `${apiUrl}/document_pdf/${id}?access_token=${
      reduxState.JWTToken || encodeURIComponent(apiKey)
    }`;

    if (reduxState.isMobileView) {
      window.open(url, '_blank');
    } else {
      if (reduxState.shouldCloseRightPanelOnOverlayClose && reduxState.isOverlayOpen) {
        rightPanelReduxActions.closeRightPanel();
      } else {
        rightPanelReduxActions.toggleOverlay(OVERLAY_TYPES.PDF);
      }
    }
  };

  const handleOptionClick = ({ value, isLink }) => {
    if (isValidSKU(formattedData.documentRestrictionType)) {
      return props.showSKURestrictModal(formattedData.documentRestrictionType, false);
    }

    if (isLink) {
      handleSourceOpen(value);
    } else {
      handlePDFClick();
    }
  };

  return {
    handleOptionClick,
    handlePDFClick
  };
};
