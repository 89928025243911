import type { CommenterProps } from '../Commenter.types';
import type { CommenterHandlers } from './useCommenterHandlers.types';

import { DOCUMENT_SUMMARY_PAGE_KEY } from 'constants/DocumentConstants';
import { SUPPORTED_QUERY_PARAMS } from 'constants/PrimaryFilter';
import { formatRedirectUrl } from 'utils/url-utils';

import * as routes from 'constants/Routes';
import * as viewConstants from 'shared/features/view/view.constants';

import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from 'utils/hooks';

export const useCommenterHandlers = ({
  props
}: {
  props: Pick<CommenterProps, 'document'>;
}): CommenterHandlers => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const rightPanelReduxActions = useRightPanelReduxActions();

  const handleClick: CommenterHandlers['handleClick'] = () => {
    let {
      [SUPPORTED_QUERY_PARAMS.SEARCH_SORT]: searchSort,
      [SUPPORTED_QUERY_PARAMS.ORDER]: order,
      [SUPPORTED_QUERY_PARAMS.VIEW]: view
    } = queryParams;

    if (!searchSort) {
      searchSort = 'publication_date';
      order = 'desc';
    }

    if (!view) {
      view = viewConstants.VIEW_KEY.ALL;
    }

    rightPanelReduxActions.closeOverlay();

    navigate(
      formatRedirectUrl({
        path: routes.content,
        params: {
          [SUPPORTED_QUERY_PARAMS.MORE_LIKE_DOC_ID]: props.document.id,
          [SUPPORTED_QUERY_PARAMS.SUMMARY_ID]: props.document.id,
          [SUPPORTED_QUERY_PARAMS.SUMMARY_PAGE]: DOCUMENT_SUMMARY_PAGE_KEY,
          [SUPPORTED_QUERY_PARAMS.SEARCH_SORT]: searchSort,
          [SUPPORTED_QUERY_PARAMS.ORDER]: order,
          [SUPPORTED_QUERY_PARAMS.VIEW]: view
        }
      })
    );
  };

  return {
    handleClick
  };
};
