import type * as apiTypes from './banks.api.types';

import { fetch } from 'fetch';

export const fetchBanks: apiTypes.FetchBanks = ids => {
  return fetch({
    url: `/banks`,
    params: {
      ids: ids.join(',')
    },
    dataType: 'banks',
    method: 'GET'
  });
};
