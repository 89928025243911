import * as searchConstants from 'shared/features/search/search.constants';
import * as actsApi from 'shared/features/acts/acts.api';
import * as banksApi from 'shared/features/banks/banks.api';

export const OPTION_FETCHERS = {
  [searchConstants.SEARCH_CATEGORIES.ACTS]: actsApi.fetchActs,
  [searchConstants.SEARCH_CATEGORIES.BANKS]: banksApi.fetchBanks
};

export const OPTION_FETCHER_RESPONSE_FIELD_KEY: Record<
  searchConstants.SEARCH_CATEGORIES,
  | keyof Awaited<ReturnType<typeof actsApi.fetchActs>>
  | keyof Awaited<ReturnType<typeof banksApi.fetchBanks>>
> = {
  [searchConstants.SEARCH_CATEGORIES.ACTS]: 'acts',
  [searchConstants.SEARCH_CATEGORIES.BANKS]: 'banks'
};
