import type * as types from './TranslateFieldsStage.types';

import * as helpers from './TranslateFieldsStage.helpers';
import * as errorUtils from 'utils/errors';

import lodash from 'lodash';

export class TranslateFieldsStage implements types.TranslateFieldsStage {
  readonly translationsMap: types.TranslateFieldsStage['translationsMap'];
  readonly asyncTranslationsMap: types.TranslateFieldsStage['asyncTranslationsMap'];
  readonly asyncTranslationsConfig: types.TranslateFieldsStage['asyncTranslationsConfig'];

  constructor(args: {
    translationsMap: types.TranslateFieldsStage['translationsMap'];
    asyncTranslationsConfig: types.TranslateFieldsStage['asyncTranslationsConfig'];
  }) {
    this.translationsMap = args.translationsMap;
    this.asyncTranslationsConfig = args.asyncTranslationsConfig;
    this.asyncTranslationsMap = {};
  }

  readonly getAsyncTranslationsMap: types.TranslateFieldsStage['getAsyncTranslationsMap'] = async ({
    allFilesInitialMetadata,
    stageCallCount
  }) => {
    try {
      /**
       *
       * Since this method will collect map for all the metadata possible, it makes
       * sense to call it only once
       *
       */
      if (stageCallCount > 0 && !lodash.isEmpty(this.asyncTranslationsMap)) {
        return this.asyncTranslationsMap;
      }

      const metadataUniqueValues = helpers.mergeAllMetadataUniqueValues(allFilesInitialMetadata);

      const responses = await helpers.makeAPICalls({
        metadataUniqueValues: metadataUniqueValues,
        asyncTranslationsConfig: this.asyncTranslationsConfig
      });

      return responses.reduce((translationsMap, [metadataKey, result]) => {
        if (!result?.metadataValue || !result?.translatedValue) {
          return translationsMap;
        }

        const mappedValues = translationsMap[metadataKey] ?? {};

        return {
          ...translationsMap,
          [metadataKey]: {
            ...mappedValues,
            [String(result.metadataValue)]: result.translatedValue
          }
        };
      }, {} as types.TranslationsMap);
    } catch (e) {
      errorUtils.logError(e as Error);
      return {};
    }
  };

  readonly getTranslatedValue: types.TranslateFieldsStage['getTranslatedValue'] = ({
    metadataKey,
    metadataValue,
    translationsMap
  }) => {
    if (metadataKey in translationsMap) {
      const translation = translationsMap[metadataKey as keyof typeof translationsMap] ?? {};

      if (Array.isArray(metadataValue)) {
        const translatedValue = metadataValue.map(value => {
          if (String(value) in translation) {
            return translation[value as keyof typeof translation];
          }

          return value;
        });

        return [[metadataKey, translatedValue]];
      }

      const translatedValue = translation[String(metadataValue)];

      if (translatedValue) {
        return [[metadataKey, translatedValue]];
      }

      return [];
    } else {
      return [];
    }
  };

  readonly run: types.TranslateFieldsStage['run'] = async args => {
    const asyncTranslationsMap = await this.getAsyncTranslationsMap({
      allFilesInitialMetadata: args.allFilesInitialMetadata,
      stageCallCount: args.stageCallCount
    });

    return args.metadata.flatMap(([metadataKey, metadataValue]) => {
      const hasAsyncTranslation = metadataKey in asyncTranslationsMap;

      const translatedValue = this.getTranslatedValue({
        metadataKey: metadataKey,
        metadataValue: metadataValue,
        translationsMap: hasAsyncTranslation ? asyncTranslationsMap : this.translationsMap
      });

      if (translatedValue.length) {
        return translatedValue;
      }

      return [[metadataKey, metadataValue]];
    });
  };
}
