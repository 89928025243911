import { useCallback, useMemo } from 'react';
import {
  fetchAllAlerts as reduxFetchAllAlerts,
  addAlert as reduxAddAlert,
  updateAlert as reduxUpdateAlert
} from '../alerts.actions';
import { useDispatch } from 'react-redux';

export const useAlertsReduxActions = () => {
  const dispatch = useDispatch();

  const fetchAllAlerts = useCallback(
    async params => {
      return await dispatch(reduxFetchAllAlerts(params));
    },
    [dispatch]
  );

  const addAlert = useCallback(
    async params => {
      return await dispatch(reduxAddAlert(params));
    },
    [dispatch]
  );

  const updateAlert = useCallback(
    async (alertId, params) => {
      return await dispatch(reduxUpdateAlert(alertId, params));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      fetchAllAlerts,
      addAlert,
      updateAlert
    }),
    [fetchAllAlerts, addAlert, updateAlert]
  );
};
