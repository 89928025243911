import type { AppNavigationContextProviderProps } from '@compliance.ai/web-components';

import * as routes from 'constants/Routes';

export const BUCKETS_PATHS = {
  ALL_BUCKETS: routes.managementHub,
  PUBLIC_BUCKET_LABELS: `${routes.managementHub}/labels`,
  PRIVATE_BUCKET_LABELS: `${routes.managementHub}/:bucketId/labels`,
  PUBLIC_BUCKET_LABEL_DETAILS: `${routes.managementHub}/labels/:labelId`,
  PRIVATE_BUCKET_LABEL_DETAILS: `${routes.managementHub}/:bucketId/labels/:labelId`,
  PUBLIC_BUCKET_ANNOTATIONS: `${routes.managementHub}/annotations`,
  PRIVATE_BUCKET_ANNOTATIONS: `${routes.managementHub}/:bucketId/annotations`,
  PUBLIC_BUCKET_OBLIGATIONS: `${routes.managementHub}/obligations`,
  PRIVATE_BUCKET_OBLIGATIONS: `${routes.managementHub}/:bucketId/obligations`,
  PUBLIC_BUCKET_WORKFLOWS: `${routes.managementHub}/workflows`,
  PRIVATE_BUCKET_WORKFLOWS: `${routes.managementHub}/:bucketId/workflows`,
  PUBLIC_BUCKET_DOCUMENTS: `${routes.managementHub}/:bucketId/documents`,
  PRIVATE_BUCKET_DOCUMENTS: `${routes.managementHub}/documents`
};

export const SERVICE_CONNECTORS_PATHS = {
  ALL_SERVICE_CONNECTORS: routes.serviceConnectors,
  SERVICE_CONNECTOR_BUILDER: `${routes.serviceConnectors}/builder`
};

export const APP_NAVIGATION_INITIAL_STATE: AppNavigationContextProviderProps['initialState'] = {
  isTopBarVisible: true,
  isNavBarVisible: true,
  isRightPanelOpen: false,
  isRightPanelVisible: false
};

export const MANAGE_LABELS_PATHS = {
  ALL_LABELS: routes.manageLabels,
  LABEL_DETAILS: `${routes.manageLabels}/:labelId`
};
