import PropTypes from 'prop-types';
import helpers from './helpers';
import { Select, SELECT_TYPES, LabeledField } from '@compliance.ai/web-components';
import './_team.scss';

export const Team = ({ stepData, modifyStep, teams }) => {
  const handleTeamChange = values => {
    const newTeams = values.map(value => {
      return value.value;
    });

    const newStep = {
      ...stepData,
      teams: newTeams
    };

    modifyStep(newStep);
  };

  if (!teams) {
    return null;
  }

  return (
    <LabeledField label={'Teams'}>
      <Select
        type={SELECT_TYPES.DEFAULT}
        className="workflow-step-team__select"
        options={helpers.formatTeams(teams)}
        value={helpers.formatTeams(stepData.teams)}
        onChange={handleTeamChange}
        isMulti
        isClearable
      />
    </LabeledField>
  );
};

Team.propTypes = {
  stepData: PropTypes.shape({}).isRequired,
  modifyStep: PropTypes.func.isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
