import { getUserAlerts } from '../alerts/alerts.selectors';
import { createSelector } from '@reduxjs/toolkit';

export const getCurrentView = state => state.current_view;

export const getCurrentViewId = createSelector(
  getCurrentView,
  currentViewReducer => currentViewReducer.id
);
export const getCurrentViewPage = createSelector(
  getCurrentView,
  currentViewReducer => currentViewReducer.page
);
export const getLastId = createSelector(
  getCurrentView,
  currentViewReducer => currentViewReducer.last_id
);
export const isMobileView = createSelector(
  getCurrentView,
  currentViewReducer => currentViewReducer.inMobile
);
export const getTaskDetails = createSelector(
  getCurrentView,
  currentViewReducer => currentViewReducer.taskDetails
);
export const getTaskDetailsDocId = createSelector(
  getTaskDetails,
  taskDetails => taskDetails?.doc_id
);
export const getBulkSelectedDocsFlag = createSelector(getCurrentView, currentViewReducer => {
  return currentViewReducer.bulk_docs_selected;
});
export const getFetchingSimpleDocsStatus = createSelector(getCurrentView, currentViewReducer => {
  return currentViewReducer.fetching_simple_docs;
});
/**
 * TODO All of current_view.search_params usages should be removed. There must be only one source of truth for query params: query params
 */
export const getCurrentViewSearchParams = createSelector(getCurrentView, currentViewReducer => {
  return currentViewReducer.search_params;
});

export const getViewAlertId = createSelector(getCurrentView, currentViewReducer => {
  return currentViewReducer.viewAlertId;
});

export const getEditAlertId = createSelector(getCurrentView, currentViewReducer => {
  return currentViewReducer.editAlertId;
});

export const getShowAlertPreviewFlag = createSelector(getCurrentView, currentViewReducer => {
  return currentViewReducer.showPreviewAlert;
});

export const shouldShowAlertPreview = createSelector(
  [getEditAlertId, getShowAlertPreviewFlag],
  (editAlertId, shouldShowAlertPreview) => {
    return Boolean(editAlertId && shouldShowAlertPreview);
  }
);

export const getAlertName = createSelector(getCurrentView, currentViewReducer => {
  return currentViewReducer.alertName;
});

export const isShowingAlert = createSelector(
  getViewAlertId,
  getEditAlertId,
  (viewAlertId, editAlertId) => {
    return Boolean(viewAlertId || editAlertId);
  }
);
export const isShowingWorkflowAlert = createSelector(
  getViewAlertId,
  getUserAlerts,
  (viewAlertId, userAlerts) => {
    const alert = userAlerts.find(({ id }) => id === viewAlertId);

    return Boolean(alert?.workflow_id);
  }
);

export const getAlertSuccessSaveModalData = createSelector(getCurrentView, currentViewReducer => {
  return currentViewReducer.saveAlertSuccessModal;
});

export const getRestrictModalData = createSelector(getCurrentView, currentViewReducer => {
  return currentViewReducer.restrictModal;
});

export const isFilterOpen = createSelector(getCurrentView, currentViewReducer => {
  return Boolean(currentViewReducer.displayFilter);
});

export const getActiveWorkflowId = createSelector(getCurrentView, currentViewReducer => {
  return currentViewReducer.activeWorkflow;
});
