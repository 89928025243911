import { fetch } from 'utils/api';
import { DEFAULTS_GET_ACTIONS } from 'constants/Defaults';
import * as config from 'shared/config';

export function api_fetchOrganizationLabelsOptions() {
  return fetch({
    url: '/organization_labels_options',
    method: 'GET'
  });
}

export function api_fetchOrganization(orgId) {
  return fetch({
    url: `/organizations/${orgId}`,
    method: 'GET',
    dataType: 'Organization',
    params: config.shouldFetchNewOrgRequest
      ? {
          get_minimal_data: true
        }
      : {}
  });
}

export function api_fetchAllOrganization() {
  return fetch({
    url: `/all_organizations`,
    method: 'GET',
    dataType: 'Organization'
  });
}

export function api_updateOrganization(orgId, params) {
  return fetch({
    url: `/organizations/${orgId}`,
    method: 'POST',
    dataType: 'Organization',
    params: { ...params, get_minimal_data: config.shouldFetchNewOrgRequest }
  });
}

export function api_addOrganizationMember(orgId, params) {
  return fetch({
    url: `/organizations/${orgId}/organization_members`,
    method: 'POST',
    dataType: 'OrganizationMember',
    params
  });
}

export function api_updateOrganizationMember(orgId, userId, params) {
  return fetch({
    url: `/organizations/${orgId}/organization_members/${userId}`,
    method: 'POST',
    dataType: 'OrganizationMember',
    params
  });
}

export function getOrgMembersV2(params) {
  return fetch({
    url: `/organizations/members`,
    method: 'GET',
    dataType: 'OrganizationMember',
    params
  });
}

export function api_fetchTeams() {
  return fetch({
    url: `/teams`,
    method: 'GET',
    dataType: 'Team'
  });
}

export function cloneTeam(teamId) {
  return fetch({
    url: `/v2/teams/clone/${teamId}`,
    method: 'POST',
    dataType: 'Team'
  });
}

export function getTeamsV2(params) {
  return fetch({
    url: `/v2/teams`,
    method: 'GET',
    dataType: 'Team',
    params
  });
}

export function getTeamV2(teamId) {
  return fetch({
    url: `/v2/teams/${teamId}`,
    method: 'GET',
    dataType: 'Team'
  });
}

export function getTeamMembersV2(teamId, params) {
  return fetch({
    url: `/v2/teams/${teamId}/members`,
    method: 'GET',
    dataType: 'TeamMember',
    params
  });
}

export function getAreasOfFocus(teamId, params) {
  return fetch({
    url: `/areas_of_focus`,
    method: 'GET',
    dataType: 'areaOfFocus',
    params: {
      ...params,
      team_id: teamId
    }
  });
}

export function getAreaOfFocus(id) {
  return fetch({
    url: `/areas_of_focus/${id}`,
    method: 'GET',
    dataType: 'areaOfFocus'
  });
}

export function createAreaOfFocus(data) {
  return fetch({
    url: `/areas_of_focus`,
    method: 'POST',
    dataType: 'areaOfFocus',
    params: data
  });
}

export function updateAreaOfFocus(id, data) {
  return fetch({
    url: `/areas_of_focus/${id}`,
    method: 'PATCH',
    dataType: 'areaOfFocus',
    params: data
  });
}

export function deleteAreaOfFocus(id) {
  return fetch({
    url: `/areas_of_focus/${id}`,
    method: 'DELETE',
    dataType: 'areaOfFocus'
  });
}

export function api_updateTeam(teamId, params) {
  return fetch({
    url: `/teams/${teamId}`,
    method: 'POST',
    dataType: 'Team',
    params
  });
}

export function api_deleteTeam(teamId) {
  return fetch({
    url: `/teams/${teamId}`,
    method: 'DELETE',
    dataType: 'Team'
  });
}

export function api_updateTeamMember(teamId, userId, params) {
  return fetch({
    url: `/teams/${teamId}/team_members/${userId}`,
    method: 'POST',
    dataType: 'Team',
    params
  });
}

export function api_addTeam(params) {
  return fetch({
    url: `/teams`,
    method: 'POST',
    dataType: 'Team',
    params
  });
}

export function api_addTeamMember(teamId, params) {
  return fetch({
    url: `/teams/${teamId}/team_members`,
    method: 'POST',
    dataType: 'TeamMember',
    params
  });
}

export function api_deleteTeamMember(teamId, userId) {
  return fetch({
    url: `/teams/${teamId}/team_members`,
    method: 'DELETE',
    dataType: 'TeamMember',
    params: { user_id: userId }
  });
}

export function api_fetchAllTeamMembers(teamId) {
  return fetch({
    url: `/teams/${teamId}/team_members`,
    method: 'GET',
    dataType: 'TeamMember'
  });
}

export function api_inviteToOrg(orgId, params, rnToken) {
  return fetch({
    url: `/organization_invitations/${orgId}`,
    method: 'POST',
    dataType: 'OrgInvitation',
    customToken: rnToken,
    params
  });
}

export function api_removeOrgInvitation(orgId, orgInvitationId, rnToken) {
  return fetch({
    url: `/organizations/${orgId}/organization_invitations/${orgInvitationId}`,
    method: 'DELETE',
    dataType: 'OrgInvitation',
    customToken: rnToken
  });
}

export function api_fetchOrgDefaults(orgId, params) {
  return fetch({
    url: `/org_defaults/${orgId}`,
    method: 'GET',
    params
  });
}

export function api_fetchAllOrgDefaults(orgId) {
  return Promise.all([
    api_fetchOrgDefaults(orgId, {
      action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_TOPICS
    }),
    api_fetchOrgDefaults(orgId, {
      action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_AGENCIES
    }),
    api_fetchOrgDefaults(orgId, {
      action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_ENTITIES
    }),
    api_fetchOrgDefaults(orgId, {
      action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_CATEGORIES
    }),
    api_fetchOrgDefaults(orgId, {
      action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_CONCEPTS
    }),
    api_fetchOrgDefaults(orgId, {
      action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_REGULATIONS
    })
  ]);
}

export function api_updateOrgDefaults(orgId, params) {
  return fetch({
    url: `/org_defaults/${orgId}`,
    method: 'POST',
    params
  });
}

export function api_fetchTeamDefaults(teamId, areaOfFocusId, params) {
  return fetch({
    url: `/team_defaults/${teamId}`,
    method: 'GET',
    params: {
      ...params,
      area_of_focus_id: areaOfFocusId
    }
  });
}

export function api_fetchAllTeamDefaults(teamId, areaOfFocusId) {
  return Promise.all([
    api_fetchTeamDefaults(teamId, areaOfFocusId, {
      action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_TOPICS
    }),
    api_fetchTeamDefaults(teamId, areaOfFocusId, {
      action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_AGENCIES
    }),
    api_fetchTeamDefaults(teamId, areaOfFocusId, {
      action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_ENTITIES
    }),
    api_fetchTeamDefaults(teamId, areaOfFocusId, {
      action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_CATEGORIES
    }),
    api_fetchTeamDefaults(teamId, areaOfFocusId, {
      action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_CONCEPTS
    }),
    api_fetchTeamDefaults(teamId, areaOfFocusId, {
      action: DEFAULTS_GET_ACTIONS.GET_FOLLOWED_REGULATIONS
    })
  ]);
}

export function api_updateTeamDefaults(teamId, areaOfFocusId, params) {
  return fetch({
    url: `/team_defaults/${teamId}`,
    method: 'POST',
    params: {
      ...params,
      area_of_focus_id: areaOfFocusId
    }
  });
}
