import type { AppSearchHandlers } from './useAppSearchHandlers.types';

import { useEffect } from 'react';

export const useAppSearchLifecycle = ({
  onSearchFieldTypeQueryParamChange,
  onLocationChange
}: {
  onSearchFieldTypeQueryParamChange: AppSearchHandlers['handleSearchFieldTypeQueryParamChange'];
  onLocationChange: AppSearchHandlers['handleLocationChange'];
}) => {
  useEffect(onSearchFieldTypeQueryParamChange, [onSearchFieldTypeQueryParamChange]);
  useEffect(onLocationChange, [onLocationChange]);
};
