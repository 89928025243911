import type { LegacyDocTypesSelectProps } from './LegacyDocTypesSelect.types';
import type { GroupSelectProps } from '@compliance.ai/web-components';
import type { FC } from 'react';

import { GroupSelect } from '@compliance.ai/web-components';
import { useLegacyDocTypesSelectData } from './hooks';

export const LegacyDocTypesSelect: FC<LegacyDocTypesSelectProps> = props => {
  const { reduxState, formattedData } = useLegacyDocTypesSelectData({
    value: props.value
  });

  return (
    <GroupSelect
      {...props}
      onChange={props.onChange as GroupSelectProps['onChange']}
      options={reduxState.options}
      value={formattedData.value}
    />
  );
};
