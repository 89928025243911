import type { DocumentFromResponse } from 'shared/features/documents/documents.types';
import type { AddLabelsModalData } from './useAddLabelsModalData.types';

export const formatLabelsForRequest = ({
  documentIds,
  selectedLabelIds
}: {
  documentIds: DocumentFromResponse['id'][];
  selectedLabelIds: AddLabelsModalData['localState']['selectedLabelIds'];
}) => {
  return documentIds.flatMap(documentId => {
    return selectedLabelIds.map(id => {
      return {
        document_id: documentId,
        new_document_note: true,
        label_id: id
      };
    });
  });
};
