import { useMemo } from 'react';
import {
  isDocument,
  isBookmarkedFolder,
  hasEditPermission
} from 'components/Folders/Folders.helpers';

export const useFolderListItemData = ({ props }) => {
  const isDisabled = useMemo(() => {
    const { disabled, folder } = props;

    return (
      disabled || isDocument(folder) || (!isBookmarkedFolder(folder) && !hasEditPermission(folder))
    );
  }, [props]);

  const showArrow = useMemo(() => {
    return !isDocument(props.folder);
  }, [props]);

  return {
    formattedData: {
      isDisabled,
      showArrow
    }
  };
};
