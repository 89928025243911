import { DOCUMENT_EXPORT_LIMIT, DOCUMENT_EXPORT_LIMIT_INTERNAL_USER } from 'constants/Export';
import { isPowerUser, isInternalUser } from 'utils/authorization';

export const getExportLimit = () => {
  if (isInternalUser()) {
    return DOCUMENT_EXPORT_LIMIT_INTERNAL_USER;
  } else {
    return DOCUMENT_EXPORT_LIMIT;
  }
};
