import type { SelectOption } from '@compliance.ai/web-components';
import type { AgencyOption } from './useAgenciesSelectData.types';
import type { AgenciesSelectProps } from '../AgenciesSelect.types';

import lodash from 'lodash';

export const formatAgencyValuesWithTooltips = ({
  values,
  options
}: {
  values: AgenciesSelectProps['value'];
  options: AgencyOption[];
}): AgencyOption[] => {
  if (!Array.isArray(values) || !Array.isArray(options)) {
    return [];
  }

  // TODO Fix types
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return values.reduce((formattedValues: AgencyOption[], value: AgencyOption | number) => {
    const extractedValue = lodash.isObject(value) ? (value as SelectOption).value : value;
    const foundOption = options.find(option => option.value === extractedValue);

    if (foundOption) {
      return [...formattedValues, foundOption];
    }

    return formattedValues;
  }, []);
};
