import { FORM_TYPES } from '../Feedback.constants';

export const getFeedbackFormType = ({
  requestTeam,
  scheduleDemo,
  topics,
  requestPro,
  requestJurisdiction,
  requestAgency,
  requestNewsSource
}) => {
  if (requestTeam) {
    return FORM_TYPES.TEAM;
  }

  if (scheduleDemo) {
    return FORM_TYPES.SCHEDULED_DEMO;
  }

  if (topics) {
    return FORM_TYPES.TOPICS;
  }

  if (requestPro) {
    return FORM_TYPES.PRO;
  }

  if (requestJurisdiction) {
    return FORM_TYPES.JURISDICTION;
  }

  if (requestAgency) {
    return FORM_TYPES.AGENCY;
  }

  if (requestNewsSource) {
    return FORM_TYPES.NEWS_SOURCES;
  }
};
