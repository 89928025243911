import { createSelector } from '@reduxjs/toolkit';

export const getObligationsReducer = createSelector(
  state => state,
  state => state.obligations
);

export const getFirstSelectedId = createSelector(getObligationsReducer, obligationsReducer => {
  return obligationsReducer.firstSelectedId;
});

export const getLastSelectedId = createSelector(getObligationsReducer, obligationsReducer => {
  return obligationsReducer.lastSelectedId;
});

export const getStartIndex = createSelector(getObligationsReducer, obligationsReducer => {
  return obligationsReducer.startIndex;
});

export const getEndIndex = createSelector(getObligationsReducer, obligationsReducer => {
  return obligationsReducer.endIndex;
});

export const getObligationHighThreshold = createSelector(
  getObligationsReducer,
  obligationsReducer => {
    return obligationsReducer.obligationHighThreshold;
  }
);
