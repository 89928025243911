import type { ReactQueryHandlers } from './useReactQueryHandlers.types';

import * as localStorageUtils from 'utils/localStorage-utils';
import { useCallback } from 'react';

export const useReactQueryHandlers = (): ReactQueryHandlers => {
  /**
   * TODO Remove this handler once there won't be any users with the old cache
   **/
  const handleClearDeprecatedQueryCache = useCallback(() => {
    localStorageUtils.removeDeprecatedQueryCache();
  }, []);

  return {
    handleClearDeprecatedQueryCache
  };
};
