import type { GlobalState } from 'shared/reducers';
import type { TaskApprovalAnnotationData } from './useTaskApprovalAnnotationData.types';
import type { TaskApprovalAnnotationProps } from '../TaskApprovalAnnotation.types';

import { useSelector } from 'react-redux';
import { getDocumentTaskPossibleAssigneeIds } from 'shared/features/workflow/workflow.selectors';

export const useTaskApprovalAnnotationData = ({
  document,
  documentTask
}: Pick<TaskApprovalAnnotationProps, 'document' | 'documentTask'>): TaskApprovalAnnotationData => {
  const reduxState = useSelector<GlobalState, TaskApprovalAnnotationData['reduxState']>(state => ({
    backupApproversIds: getDocumentTaskPossibleAssigneeIds(state, {
      document,
      documentTask
    })
  }));

  return {
    reduxState
  };
};
