import { FOLDER_KEY, FOLDER_TYPE, FOLDER_PERMISSION } from './Folders.constants';

export const isFolder = folder => {
  return (
    folder[FOLDER_KEY.TYPE] === FOLDER_TYPE.FOLDER ||
    folder[FOLDER_KEY.TYPE] === FOLDER_TYPE.BOOKMARKED_FOLDER ||
    folder[FOLDER_KEY.TYPE] === FOLDER_TYPE.SHARED_FOLDER
  );
};

export const isBookmarkedFolder = folder => {
  return folder[FOLDER_KEY.TYPE] === FOLDER_TYPE.BOOKMARKED_FOLDER;
};

export const isSharedFolder = folder => {
  return folder[FOLDER_KEY.TYPE] === FOLDER_TYPE.SHARED_FOLDER;
};

export const isDocument = folder => {
  return folder[FOLDER_KEY.TYPE] === FOLDER_TYPE.DOCUMENT;
};

export const hasEditPermission = folder => {
  return (
    !isBookmarkedFolder(folder) &&
    (folder[FOLDER_KEY.PERMISSION] === FOLDER_PERMISSION.OWNER ||
      folder[FOLDER_KEY.PERMISSION] === FOLDER_PERMISSION.EDITOR)
  );
};
