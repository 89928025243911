import type * as types from './TagsStage.types';
import type * as stage from '../Stage';

import * as helpers from './TagsStage.helpers';

export class TagsStage implements types.TagsStage {
  readonly metadataTagsMap: types.TagsStage['metadataTagsMap'];

  constructor(args: { metadataTagsMap: types.TagsStage['metadataTagsMap'] }) {
    this.metadataTagsMap = args.metadataTagsMap;
  }

  readonly getTagValue = (args: {
    metadataKey: stage.MetadataKey;
    metadataValue: stage.MetadataValue;
  }) => {
    if (!this.metadataTagsMap[args.metadataKey]) {
      return [];
    }

    const tagsToValuesMap = this.metadataTagsMap[args.metadataKey][String(args.metadataValue)];

    if (!tagsToValuesMap) {
      return [];
    }

    return Object.entries(tagsToValuesMap);
  };

  readonly run: types.TagsStage['run'] = async args => {
    const withTags = args.metadata.flatMap(([metadataKey, metadataValue]) => {
      const metadataTaggedValues = this.getTagValue({
        metadataKey: metadataKey,
        metadataValue: metadataValue
      });

      return [[metadataKey, metadataValue], ...metadataTaggedValues];
    });

    /**
     *
     * Concatenates initial metadata with the mapped tag values omitting duplicates
     *
     */
    return Object.entries(
      withTags.reduce((mergedMetadataWithTags, [metadataKey, metadataValue]) => {
        const metadataDuplicatedKeyValue =
          mergedMetadataWithTags[String(metadataKey) as keyof typeof mergedMetadataWithTags];
        const metadataDuplicatedValue = helpers.toArray(metadataDuplicatedKeyValue);
        const metadataArrayValue = helpers.toArray(metadataValue);

        return {
          ...mergedMetadataWithTags,
          [metadataKey as keyof typeof mergedMetadataWithTags]: [
            ...new Set([...metadataDuplicatedValue, ...metadataArrayValue])
          ]
        };
      }, {} as Record<string, unknown>)
    );
  };
}
