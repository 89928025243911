import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getActionBarFilterParams } from 'shared/features/filters/filters.selectors';
import { useQueryParams } from 'utils/hooks';
import { getCurrentViewSearchParams } from 'shared/features/view/view.selectors';
import {
  getCurrentUserProperties,
  getUser,
  getUserEmail
} from 'shared/features/user/user.selectors';
import {
  getDocumentsReducer,
  getSearchResultsRelevanceFetchingState,
  getSearchResultsRelevanceResults
} from 'shared/features/documents/documents.selectors';
import { getUserAuthenticatedState } from 'shared/features/auth/auth.selectors';
import { getThresholdsReducer } from 'shared/features/thresholds/thresholds.selectors';
import * as paginationUtils from 'utils/pagination-utils';

export const useActionBarData = ({ shouldShowWithCombinedDocs, section }) => {
  const queryParams = useQueryParams();

  const reduxState = useSelector(state => ({
    isLoadingSearchResultsRelevance: getSearchResultsRelevanceFetchingState(state),
    searchResultsRelevance: getSearchResultsRelevanceResults(state),
    isAuthenticated: getUserAuthenticatedState(state),
    documents: getDocumentsReducer(state),
    thresholds: getThresholdsReducer(state),
    actionBarFilterParams: getActionBarFilterParams(state),
    searchParams: getCurrentViewSearchParams(state),
    currentUser: getUser(state),
    currentUserProperties: getCurrentUserProperties(state),
    currentUserEmail: getUserEmail(state)
  }));

  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(paginationUtils.getInitialPage(queryParams));
  const [limit, setLimit] = useState(paginationUtils.getInitialLimit(queryParams));
  const [currentOrder, setCurrentOrder] = useState(
    paginationUtils.getInitialOrderOption({
      currentUserProperties: reduxState.currentUserProperties,
      queryParams: queryParams
    })
  );
  const [currentSort, setCurrentSort] = useState(
    paginationUtils.getInitialSortOption({
      currentUserProperties: reduxState.currentUserProperties,
      queryParams: queryParams
    })
  );

  const localState = useMemo(
    () => ({
      isLoading,
      limit,
      page,
      currentOrder,
      currentSort
    }),
    [currentOrder, currentSort, isLoading, page, limit]
  );

  const localActions = useMemo(
    () => ({
      setLoading,
      setPage,
      setLimit,
      setCurrentOrder,
      setCurrentSort
    }),
    []
  );

  const viewType = paginationUtils.getViewType(shouldShowWithCombinedDocs, section);
  const viewParams = paginationUtils.getViewParams({
    localLimit: localState.limit,
    localPage: localState.page,
    isLoading: localState.isLoading,
    documentsReducer: reduxState.documents,
    searchParams: reduxState.searchParams,
    searchResultsRelevance: reduxState.searchResultsRelevance,
    section,
    viewType
  });

  const decoratedCount = paginationUtils.getDecoratedCount({
    offset: viewParams.offset,
    limit: viewParams.limit,
    totalCount: viewParams.totalCount
  });

  return {
    localState,
    localActions,
    reduxState,
    formattedData: {
      viewType,
      decoratedCount,
      canGoBack: viewParams.canGoBack,
      canGoForward: viewParams.canGoForward,
      totalCount: viewParams.totalCount,
      page: viewParams.page,
      limit: viewParams.limit
    }
  };
};
