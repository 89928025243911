import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUserAuthenticatedState } from 'shared/features/auth/auth.selectors';
import { isLiteTeamUser } from 'utils/authorization';
import { getExportLimit } from 'utils/exportLimit/exportLimit';
import { CONTENT_TYPES } from 'constants/Export';
import {
  EXPORT_DROPDOWN_OPTIONS,
  ANNOTATION_EXPORT_DROPDOWN_OPTIONS
} from '../ExportDropdown.constants';

export const useExportDropdownData = ({ props }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [sentenceDocumentsCount, setSentenceDocumentsCount] = useState(0);

  const reduxState = useSelector(state => ({
    isAuthenticated: getUserAuthenticatedState(state)
  }));

  const formattedData = useMemo(() => {
    const { enabled, documentIds, isLoading, isAnnotationExport } = props;
    const documentsCount = documentIds.length;
    const documentExportLimit = getExportLimit();
    const isLiteTeam = isLiteTeamUser();

    const isRestricted = !reduxState.isAuthenticated || isLiteTeam;
    const isDisabled = !enabled || isLoading;

    const isContentTypeDisabled = {
      [CONTENT_TYPES.CSV]: isLoading,
      [CONTENT_TYPES.XLSX]: isLoading,
      [CONTENT_TYPES.HTML]:
        isLoading || sentenceDocumentsCount === 0 || documentsCount > documentExportLimit,
      [CONTENT_TYPES.CSV]: isLoading || documentsCount > documentExportLimit
    };

    const footerText = `${
      documentsCount - sentenceDocumentsCount
    }/${documentsCount} docs selected cannot be downloaded as HTML`;

    const dropdownOptions = isAnnotationExport
      ? ANNOTATION_EXPORT_DROPDOWN_OPTIONS
      : EXPORT_DROPDOWN_OPTIONS;

    return {
      isRestricted,
      isDisabled,
      isContentTypeDisabled,
      footerText,
      dropdownOptions
    };
  }, [props, reduxState.isAuthenticated, sentenceDocumentsCount]);

  const localActions = useMemo(
    () => ({
      setDropdownOpen,
      setSentenceDocumentsCount
    }),
    []
  );

  return {
    localState: { isDropdownOpen, sentenceDocumentsCount },
    localActions,
    formattedData
  };
};
