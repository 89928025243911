import type { DocumentDetailsRightPanelConfigData } from './useDocumentDetailsRightPanelConfigData.types';
import type { GlobalState } from 'shared/reducers';

import { useSelector } from 'react-redux';

import * as authSelectors from 'shared/features/auth/auth.selectors';
import * as rightPanelSelectors from 'shared/features/rightPanel/rightPanel.selectors';
import * as userSelectors from 'shared/features/user/user.selectors';

export const useDocumentDetailsRightPanelConfigData = (): DocumentDetailsRightPanelConfigData => {
  const reduxState = useSelector<GlobalState, DocumentDetailsRightPanelConfigData['reduxState']>(
    state => ({
      isAuthenticated: authSelectors.getUserAuthenticatedState(state),
      currentUserId: userSelectors.getUserId(state),

      rightPanel: {
        type: rightPanelSelectors.getRightPanelType(state as never),
        isClosable: rightPanelSelectors.getRightPanelIsClosable(state as never),
        documentId: rightPanelSelectors.getRightPanelDocumentId(state as never),
        documentDetailsActiveTabKey: rightPanelSelectors.getDocumentDetailsActiveTabKey(
          state as never
        )
      }
    })
  );

  return {
    reduxState
  };
};
