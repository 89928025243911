import { createSelector } from '@reduxjs/toolkit';

export const getPremiumContentReducer = state => state.premiumContent;

export const getPremiumContentSources = createSelector(
  getPremiumContentReducer,
  premiumContentReducer => {
    return premiumContentReducer.pcSources;
  }
);

export const getPremiumContentSourceById = sourceId =>
  createSelector(getPremiumContentSources, sources => {
    return sources.find(source => {
      return source.news_source_id === sourceId;
    });
  });
