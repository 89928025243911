import type { SelectOption } from '@compliance.ai/web-components';
import {
  EDIT_DOC_TASK_DUE_DATE_BY_ORG_ADMINS,
  EDIT_DOC_TASK_DUE_DATE_BY_ORG_AND_TEAM_ADMINS,
  EDIT_DOC_TASK_DUE_DATE_BY_ORG_AND_TEAM_AND_WORKFLOW_ADMINS
} from 'constants/Permissions';

export enum WORKFLOW_ANNOTATION_SETTINGS_KEYS {
  DATEFIELD_LABEL = 'datefield_label',
  REQUIREMENT = 'required_annotation_types',
  TYPES = 'types',
  ASSIGNEE_OPTIONS = 'assignee_options',
  APPROVERS_OPTIONS = 'approvers_options'
}

export enum WORKFLOW_ANNOTATION_TYPES {
  TEXT = 'text',
  ATTACHMENT = 'attachment',
  ASSIGNEES = 'assignees',
  DROPDOWNS = 'dropdowns',
  DATEFIELD = 'datefield',
  APPROVAL = 'approval'
}

export const DEFAULT_LABELS = {
  [WORKFLOW_ANNOTATION_TYPES.ATTACHMENT]: 'Attachments',
  [WORKFLOW_ANNOTATION_TYPES.TEXT]: 'Comments',
  [WORKFLOW_ANNOTATION_TYPES.ASSIGNEES]: 'Assignees',
  [WORKFLOW_ANNOTATION_TYPES.DROPDOWNS]: 'Dropdowns',
  [WORKFLOW_ANNOTATION_TYPES.DATEFIELD]: 'Date',
  [WORKFLOW_ANNOTATION_TYPES.APPROVAL]: 'Select Approver'
};

export enum APPROVAL_ANNOTATION_STATUSES {
  INITIAL = 'initial',
  PENDING_APPROVAL = 'pendingApproval',
  APPROVED = 'approved',
  REJECTED = 'rejected'
}

export const APPROVAL_ANNOTATION_API_STATUSES = {
  [APPROVAL_ANNOTATION_STATUSES.INITIAL]: null,
  [APPROVAL_ANNOTATION_STATUSES.PENDING_APPROVAL]: 1,
  [APPROVAL_ANNOTATION_STATUSES.APPROVED]: 2,
  [APPROVAL_ANNOTATION_STATUSES.REJECTED]: 3
};

export enum TASKS_PAGE_VIEWS {
  TASKS = 'tasks',
  MANAGE_TASKS = 'managetasks',
  MANAGE_WORKFLOW = 'manageworkflow',
  REPORTS = 'reports'
}

export enum WORKFLOWS_PAGE_VIEWS {
  WORKFLOW = 'workflow',
  WORKFLOWS = 'workflows',
  WORKFLOW_TEMPLATES = 'workflowTemplates'
}

export enum QUERY_PARAMS {
  TASKS_PAGE_VIEW = 'view',
  WORKFLOW_ID = 'workflow_id',
  WORKFLOW_MOLD_ID = 'workflow_mold_id',
  MANAGE_WORKFLOW_VIEW = 'manage_workflow_view'
}

export const WORKFLOW_USER_TYPE_OPTIONS: SelectOption<string>[] = [
  {
    value: EDIT_DOC_TASK_DUE_DATE_BY_ORG_ADMINS,
    label: 'Org Admins'
  },
  {
    value: EDIT_DOC_TASK_DUE_DATE_BY_ORG_AND_TEAM_ADMINS,
    label: 'Org & Team Admins'
  },
  {
    value: EDIT_DOC_TASK_DUE_DATE_BY_ORG_AND_TEAM_AND_WORKFLOW_ADMINS,
    label: 'Org, Team & Workflow Admin'
  }
];

export const WORKFLOW_PUB_DATE_MAX_DAYS_AGO = 30;

export enum WORKFLOW_TYPE {
  DOCUMENT = 'document',
  OBLIGATION = 'obligation'
}

export enum WORKFLOW_DOCUMENT_TASK_STATUS {
  INCOMPLETE = 'incomplete',
  IN_PROGRESS = 'in_progress',
  DONE = 'done'
}

export enum SORT_OPTIONS {
  NEAREST_DEADLINE = 'nearest_deadline',
  NEAREST_CREATED_AT = 'nearest_created_at'
}

export const SORT_METADATA = {
  [SORT_OPTIONS.NEAREST_CREATED_AT]: {
    label: 'Create Date',
    value: SORT_OPTIONS.NEAREST_CREATED_AT
  },
  [SORT_OPTIONS.NEAREST_DEADLINE]: {
    label: 'Task Due date',
    value: SORT_OPTIONS.NEAREST_DEADLINE
  }
};
