import { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import helpers from './helpers';
import RichTextEditor from '../../../RichTextEditor';
import * as uiLib from '@compliance.ai/web-components';
import { Comment } from './elements';
import './_comment-thread.scss';

const CommentThread = ({
  threadId,
  comments,
  onCommentDeletion,
  onCommentCreation,
  onCommentEdit,
  areRepliesEnabled,
  areCommentsEditableByAuthor
}) => {
  const [isReplyEditorOpen, toggleReplyEditor] = useState(false);
  const [editableComments, toggleEditableComments] = useState({});

  const rootComment = helpers.getRootComment(comments);
  const rootCommentReplies = helpers.getReplies(comments);
  const areThereAnyReplies = Boolean(rootCommentReplies.length);

  const toggleCommentEditMode = (comment, isEditable) => {
    toggleEditableComments({
      ...editableComments,
      [comment.id]: isEditable
    });
  };

  const handleCommentCreation = richtext => {
    toggleReplyEditor(false);
    onCommentCreation({
      richtext: JSON.stringify(richtext),
      commentThreadId: threadId
    });
  };

  const handleCommentEdit = (richtext, comment) => {
    toggleCommentEditMode(comment, false);
    onCommentEdit({
      commentId: comment.id,
      richtext: JSON.stringify(richtext)
    });
  };

  const handleCommentDeletion = comment => {
    onCommentDeletion(comment);
  };

  const renderReply = () => {
    if (!areRepliesEnabled) {
      return null;
    }

    if (isReplyEditorOpen) {
      return (
        <div className="comment-thread__reply-editor-wrapper">
          <RichTextEditor
            onSave={handleCommentCreation}
            onCancel={() => toggleReplyEditor(false)}
            isReadOnly={false}
            className={classnames('comment-thread__text-editor--bordered')}
          />
        </div>
      );
    }

    return (
      <uiLib.Button
        type={uiLib.BUTTON_TYPES.LINK}
        className="comment-thread__reply-button"
        onClick={toggleReplyEditor}
      >
        Reply
      </uiLib.Button>
    );
  };

  const renderRepliesCount = () => {
    if (rootCommentReplies.length) {
      const word = rootCommentReplies.length === 1 ? ' reply' : ' replies';

      return (
        <span className="comment-thread__replies">
          {rootCommentReplies.length}
          {word}
        </span>
      );
    }

    return null;
  };

  return (
    <div className="comment-thread__container">
      <Comment
        comment={rootComment}
        isReadOnly={!editableComments[rootComment.id]}
        onSave={handleCommentEdit}
        onCancelClick={() => toggleCommentEditMode(rootComment, false)}
        onEditClick={() => toggleCommentEditMode(rootComment, true)}
        onDeleteClick={() => handleCommentDeletion(rootComment)}
        isEditableByAuthor={areCommentsEditableByAuthor}
      />
      {areThereAnyReplies && (
        <div className="comment-thread__replies-container">
          {renderRepliesCount()}
          {rootCommentReplies.map(comment => (
            <Comment
              key={comment.id}
              comment={comment}
              isReadOnly={!editableComments[comment.id]}
              onSave={handleCommentEdit}
              onCancelClick={() => toggleCommentEditMode(comment, false)}
              onEditClick={() => toggleCommentEditMode(comment, true)}
              onDeleteClick={() => handleCommentDeletion(comment)}
              isEditableByAuthor={areCommentsEditableByAuthor}
            />
          ))}
        </div>
      )}
      {renderReply()}
    </div>
  );
};

export default CommentThread;

CommentThread.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      richtext: PropTypes.string,
      userId: PropTypes.number,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      repliedTo: PropTypes.number,
      commentThreadId: PropTypes.number
    })
  ).isRequired,
  onCommentDeletion: PropTypes.func.isRequired,
  onCommentCreation: PropTypes.func.isRequired,
  onCommentEdit: PropTypes.func.isRequired,
  areRepliesEnabled: PropTypes.bool,
  areCommentsEditableByAuthor: PropTypes.bool
};

CommentThread.defaultProps = {
  areRepliesEnabled: false,
  areCommentsEditableByAuthor: true
};
