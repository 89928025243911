import { DEFAULT_MIN_SCORE } from 'constants/ActionBar';
import { SUPPORTED_QUERY_PARAMS } from 'constants/PrimaryFilter';

export const getInitialMinScore = queryParams => {
  return (
    queryParams[SUPPORTED_QUERY_PARAMS.OVERRIDE_MIN_SCORE] ||
    queryParams[SUPPORTED_QUERY_PARAMS.MIN_SCORE] ||
    DEFAULT_MIN_SCORE
  );
};
