export const usePasswordResetHandlers = ({ props, localState }) => {
  const handleSubmit = () => {
    props.onSubmit({
      passwordResetEmail: localState.passwordResetEmail
    });
  };

  return {
    handleSubmit
  };
};
