import * as documentConstants from 'shared/features/documents/documents.constants';

export const METADATA_TO_RENDER = [
  documentConstants.DOC_METADATA_FIELD.AGENCY,
  documentConstants.DOC_METADATA_FIELD.SOURCE,
  documentConstants.DOC_METADATA_FIELD.AUTHOR,
  documentConstants.DOC_METADATA_FIELD.DOCUMENT_TYPE,
  documentConstants.DOC_METADATA_FIELD.PUBLICATION_DATE,
  documentConstants.DOC_METADATA_FIELD.CFRs,
  documentConstants.DOC_METADATA_FIELD.TOPICS
];
