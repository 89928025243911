import React from 'react';
import { connect } from 'react-redux';
import { withHistory } from 'utils/hooks';
import queryString from 'utils/query-string';
import { LoadingErrorIndicator } from 'common';
import { DocumentSearchListItem } from './elements';
import { setDocsToSelect, clearSelectedItems } from 'shared/features/view/view.actions';
import { sameDocsToSelect } from 'utils/getSelected';
import {
  getFullDocumentsReducer,
  getSearchResultsRelevanceReducer
} from 'shared/features/documents/documents.selectors';
import { getCurrentView } from 'shared/features/view/view.selectors';
import { getFilteredMentionReducer } from 'shared/features/entities/entities.selectors';

export class DocumentSearchList extends React.Component {
  componentDidMount() {
    this.props.clearSelectedItems();
  }

  componentDidUpdate(prevProps, prevState) {
    const parsedQuery = queryString.parse(this.props.location.search);

    // if the search results are not ready or if this is being filtered on a
    // mention and that mention is not ready
    if (
      !this.props.search_results_relevance.isReady ||
      (parsedQuery.autosuggest_filter && !this.props.filtered_mention.isReady)
    ) {
      return;
    }
    const docs = { items: this.props.search_results_relevance.results.documents };
    if (sameDocsToSelect(this.props.current_view.docs_to_select, docs)) {
      return;
    }
    this.props.setDocsToSelect(docs);
  }

  render() {
    const { isLoading, search_results_relevance, filtered_mention, location } = this.props;
    const parsedQuery = queryString.parse(location.search);

    if (isLoading || search_results_relevance.error) {
      return <LoadingErrorIndicator isLoading={isLoading} error={search_results_relevance.error} />;
    }

    // if the search results are not ready or if this is being filtered on a
    // mention and that mention is not ready
    if (
      !search_results_relevance.isReady ||
      (parsedQuery.autosuggest_filter && !filtered_mention.isReady)
    ) {
      return null;
    }

    const documents = { items: search_results_relevance.results.documents };
    const list_items = [];

    documents.items.forEach((document, i) => {
      list_items.push(
        <DocumentSearchListItem
          key={i}
          document={document}
          location={this.props.location}
          index={i}
        />
      );
    });

    return (
      <div className="document-search-list-container">
        <ul className="document-search-list">{list_items}</ul>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    documents_full: getFullDocumentsReducer(state),
    filtered_mention: getFilteredMentionReducer(state),
    current_view: getCurrentView(state),
    search_results_relevance: getSearchResultsRelevanceReducer(state)
  };
};

export default connect(mapStateToProps, { clearSelectedItems, setDocsToSelect })(
  withHistory(DocumentSearchList)
);
