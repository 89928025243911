import type {
  WorkflowTaskDetailsData,
  WorkflowTaskDetailsLocalState
} from './useWorkflowTaskDetailsData.types';
import type { GlobalState } from 'shared/reducers';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getRightPanelDocumentId } from 'shared/features/rightPanel/rightPanel.selectors';
import { getDocumentDetailsByDocumentId } from 'shared/features/documents/documents.selectors';
import { getActiveWorkflowId } from 'shared/features/view/view.selectors';
import { checkPDFAvailability } from 'utils/documents/documentsAccess';

export const useWorkflowTaskDetailsData = (): WorkflowTaskDetailsData => {
  const [isFetchingDocument, setIsFetchingDocument] = useState<
    WorkflowTaskDetailsLocalState['isFetchingDocument']
  >(false);
  const [isDetailsExpanded, setIsDetailsExpanded] = useState<
    WorkflowTaskDetailsLocalState['isDetailsExpanded']
  >(false);

  const localState: WorkflowTaskDetailsData['localState'] = {
    isFetchingDocument,
    isDetailsExpanded
  };

  const localActions: WorkflowTaskDetailsData['localActions'] = useMemo(
    () => ({
      setIsFetchingDocument,
      setIsDetailsExpanded
    }),
    []
  );

  const reduxState: WorkflowTaskDetailsData['reduxState'] = useSelector((state: GlobalState) => ({
    documentId: getRightPanelDocumentId(state as never),
    document: getDocumentDetailsByDocumentId(getRightPanelDocumentId(state as never))(
      state as never
    ),
    activeWorkflowId: getActiveWorkflowId(state)
  }));

  const formattedData: WorkflowTaskDetailsData['formattedData'] = useMemo(
    () => ({
      isPDFAvailable: checkPDFAvailability(reduxState.document)
    }),
    [reduxState.document]
  );

  return {
    localState,
    localActions,
    reduxState,
    formattedData
  };
};
