import type { CreateLabelModalProps } from '../CreateLabelModal.types';
import type { CreateLabelModalData } from './useCreateLabelModalData.types';
import type { LabelFromResponse } from 'shared/features/labels/labels.api.types';

import * as labelsHelpers from 'shared/features/labels/labels.helpers';
import * as errorUtils from 'utils/errors';

import { COMPLEX_QUERY_KEY_GETTERS, QUERY_KEYS, QUERY_SECTIONS } from 'constants/QueryKeys';

import { useMutation } from '@tanstack/react-query';
import { useOrganizationLabelsReduxActions } from 'shared/features/labels/hooks';

export const useCreateLabelModalMutations = ({
  props,
  localState,
  formattedData
}: {
  props: Pick<CreateLabelModalProps, 'onClose' | 'onLabelCreate'>;
  localState: CreateLabelModalData['localState'];
  formattedData: CreateLabelModalData['formattedData'];
}) => {
  const reduxActions = useOrganizationLabelsReduxActions();

  const createLabel = useMutation<LabelFromResponse, Error, void>({
    mutationKey: COMPLEX_QUERY_KEY_GETTERS[QUERY_KEYS.BUCKETS][
      QUERY_SECTIONS.LABEL_INPUT_CREATE_LABEL
    ](formattedData.bucketId, localState.labelName),
    mutationFn: async () => {
      await reduxActions.createLabel(
        formattedData.bucketId,
        localState.labelName,
        localState.labelDescription,
        formattedData.labelTypeId,
        localState.labelExternalId
      );

      const {
        labels: [createdLabel]
      } = await reduxActions.fetchAllLabels({
        bucket_id: formattedData.bucketId,
        name: localState.labelName
      });

      await props.onLabelCreate(labelsHelpers.formatLabelOption(createdLabel));

      return createdLabel;
    },
    onSettled: props.onClose,
    onError: errorUtils.logReactQueryError
  });

  return {
    createLabel
  };
};
