import type { AuthorsSelectHandlers } from 'common/AuthorsSelect/hooks/useAuthorsSelectHandlers.types';
import type { AuthorFromResponse } from 'shared/features/authors/authors.api.types';

import * as errorUtils from 'utils/errors';
import * as sortUtils from 'utils/sort';

import { useAuthorsReduxActions } from 'shared/features/authors/hooks';

export const useAuthorsSelectHandlers = (): AuthorsSelectHandlers => {
  const reduxActions = useAuthorsReduxActions();

  const handleFetch: AuthorsSelectHandlers['handleFetch'] = async inputString => {
    try {
      const response = (await reduxActions.fetchAuthors({}))?.payload as AuthorFromResponse;

      if (response) {
        const options = response.authors
          ?.filter(author => author.name.toLowerCase().includes(inputString.toLowerCase()))
          .map(item => ({
            ...item,
            label: item.name,
            value: item.id
          }))
          .sort(sortUtils.alphabeticallyByLabel);

        return options || [];
      }
      return [];
    } catch (e) {
      errorUtils.logError(e as Error);
      return [];
    }
  };

  return {
    handleFetch
  };
};
