import type { DocketsHandlers } from './useDocketsHandlers.types';

import { useNavigate } from 'react-router-dom';
import * as routes from 'constants/Routes';
import { formatRedirectUrl } from 'utils/url-utils';
import { SUPPORTED_QUERY_PARAMS } from 'constants/PrimaryFilter';

export const useDocketsHandlers = (): DocketsHandlers => {
  const navigate = useNavigate();

  const handleDocketClick: DocketsHandlers['handleDocketClick'] = docketId => () => {
    navigate(
      formatRedirectUrl({
        path: routes.content,
        params: {
          [SUPPORTED_QUERY_PARAMS.DOCKET_ID]: docketId,
          [SUPPORTED_QUERY_PARAMS.SEARCH_SORT]: 'publication_date'
        }
      })
    );
  };

  return {
    handleDocketClick
  };
};
