import type { SavedBulkExport } from 'shared/features/user/user.types';
import type { ExportData } from './useExportData.types';

import * as documentActionsHelper from 'common/DocumentActions/helper';
import * as uiLib from '@compliance.ai/web-components';
import * as exportConstants from 'constants/Export';

export const getUpdatedBulkExports = ({
  bulkExports,
  downloadStartTime,
  contentType,
  docCount
}: {
  bulkExports: SavedBulkExport[];
  downloadStartTime: ExportData['localState']['downloadStartTime'];
  docCount: number;
  contentType: exportConstants.CONTENT_TYPES;
}) => {
  documentActionsHelper.registerDashboardAction(
    downloadStartTime,
    `export ${exportConstants.CONTENT_DISPLAY_NAMES[contentType]}`
  );

  const yesterday = uiLib.getDateBeforeDate({
    date: new Date(),
    unitOfTime: 'days',
    amount: 1
  });

  const filteredBulkExports = bulkExports.filter(({ time }) =>
    uiLib.isBetween({
      date: time,
      dateA: yesterday,
      dateB: new Date()
    })
  );

  filteredBulkExports.push({
    time: new Date().toISOString(),
    doc_count: docCount
  });

  return filteredBulkExports;
};
