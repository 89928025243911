import { useState } from 'react';

export const useEmailConfirmationData = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');

  return {
    localState: {
      newPassword,
      confirmationPassword
    },
    localActions: {
      setNewPassword,
      setConfirmationPassword
    }
  };
};
