import classnames from 'classnames';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';

export const RestrictBubble = ({ className, onClick, ...props }) => {
  const styles = classnames(className, {
    restrictBubble: true,
    ...props
  });

  return props.noIcon ? (
    <span className={styles} onClick={onClick} />
  ) : (
    <span className={styles} onClick={onClick}>
      <GraphicEqIcon className="r-icon" />
    </span>
  );
};

export default RestrictBubble;
