import type { ActsSelectProps } from './ActsSelect.types';

import * as uiLib from '@compliance.ai/web-components';

import { useActsSelectHandlers } from './hooks';

export const ActsSelect = (props: ActsSelectProps) => {
  const handlers = useActsSelectHandlers();

  return (
    <uiLib.Select
      {...props}
      isAsync
      type={props.type ?? uiLib.SELECT_TYPES.DEFAULT}
      loadOptions={handlers.handleFetch}
      noOptionsMessage={inputValue => (inputValue ? 'No options' : 'Type to search')}
      value={props.value}
    />
  );
};
