import type { FirmData } from './useFirmData.types';
import type { GlobalState } from 'shared/reducers';
import type { FirmProps } from '../Firm.types';

import { useSelector } from 'react-redux';
import { getDocumentCategoryName } from 'shared/features/documents/documents.selectors';

export const useFirmData = ({ document }: Pick<FirmProps, 'document'>): FirmData => {
  const reduxState = useSelector<GlobalState, FirmData['reduxState']>(state => ({
    // TODO Uncomment when the selectors file will be moved to TS
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    categoryName: getDocumentCategoryName(state, document.cai_category_id)
  }));

  return {
    reduxState
  };
};
