/***
 * NOTICE: This file is deprecated in favor of utils/authorization.js where the logic
 * doesn't require scope to determine authorization.
 */

import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PieChartIcon from '@material-ui/icons/PieChart';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PeopleIcon from '@material-ui/icons/People';
import AddIcon from '@material-ui/icons/Add';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import * as uiLib from '@compliance.ai/web-components';

import * as routes from 'constants/Routes';
import * as corporateWebsiteLinksConstants from 'constants/CorporateWebsiteLinks';

import auth from 'utils/auth';
import * as hooksUtils from 'utils/hooks';
import * as exportLimitUtils from 'utils/exportLimit/exportLimit';
import * as localStorageUtils from 'utils/localStorage-utils';
import * as analyticsUtils from 'utils/analytics';
import * as usageMonitoringConstants from 'utils/usageMonitoring';

import * as viewActions from 'shared/features/view/view.actions';
import { store } from 'store';
import RestrictBubble from 'components/RestrictBubble';

const RESTRICT_DESCRIPTIONS = {
  'Workflow Tasks': {
    boxes: [
      { text: 'Manage Teams', icon: <PeopleIcon /> },
      { text: 'Create and Manage Workflows', icon: <ListAltIcon /> },
      { text: 'Automatically assigned Tasks', icon: <CheckBoxIcon /> }
    ],
    text: `Automated Workflow capabilities simplifies collaboration across stakeholders by
    creating automatic to-do lists based on regulatory changes.`,
    editions: ['TEAM']
  },
  Folders: {
    boxes: [
      { text: 'Save', icon: <BookmarkIcon /> },
      { text: 'Organize', icon: <CreateNewFolderIcon /> },
      { text: 'Share', icon: <PersonAddIcon /> }
    ],
    text: `With Folders, you can save and organize documents that are important to you. Increase
    collaboration by allowing team members to access and edit your shared folders.`,
    editions: ['PRO', 'TEAM']
  },
  Notifications: {
    boxes: [
      { text: 'Tracking', icon: <YoutubeSearchedForIcon />, iconRight: <AddIcon /> },
      { text: 'Monitoring', icon: <VisibilityIcon />, iconRight: <ChevronRightIcon /> },
      { text: 'Automated', icon: <NotificationsActiveIcon />, iconRight: true }
    ],
    text: `Automatically Track and Monitor changes in regulations by setting personalized
    notifications to update you when new content is published on a specific regulation, agency or
    topic area. Compliance.ai monitors changes and lets you know when something important happens.`,
    editions: ['PRO', 'TEAM']
  },
  'Reached Activity Limit': {
    boxes: [
      { text: 'Track and Monitor', icon: <NotificationsIcon className="track-and-monitor" /> },
      { text: 'Regulatory Dashboard', icon: <DashboardIcon className="regulatory-dashboard" /> },
      { text: 'Insights and Summaries', icon: <PieChartIcon className="insight-summaries" /> },
      { text: 'Automated Workflow', icon: <ListAltIcon className="automated-workflow" /> },
      {
        text: 'Prioritization & Classification',
        icon: <CreateNewFolderIcon className="prioritization-classification" />
      }
    ],
    text:
      'Sign Up or Login to have unlimited access to Compliance.ai with the following features as your usage limit has been reached.'
  }
};

const BASIC_EXPIRED_RESTRICTION_DISABLED_ROUTES = [routes.legal];

export const restrict = (func, context, title, text = '') => {
  return (...args) => {
    if (auth.loggedIn()) return func.apply(context, args);
    analyticsUtils.safe_analytics('default', 'Non-auth', 'Open restrict modal', title);
    return store.dispatch(viewActions.showRestrictModal('', title, text));
  };
};

export const showModal = (title, text = '') => {
  store.dispatch(viewActions.showRestrictModal('', title, text));
};

export const canCurrentUserExportDocs = (user, numDocs = 0) => {
  // in case user is not logged in
  if (!user) {
    return false;
  }

  if (user.email && user.email.indexOf('@compliance.ai') !== -1) {
    return true;
  }

  if (
    !{}.hasOwnProperty.call(user, 'properties') ||
    !{}.hasOwnProperty.call(user.properties, 'bulk_export')
  ) {
    return true;
  }

  const today = moment();
  const yesterday = moment().subtract(1, 'days');

  let downloadsInPast24Hours = 0;
  for (const exportLog of user.properties.bulk_export) {
    if (
      {}.hasOwnProperty.call(exportLog, 'time') &&
      {}.hasOwnProperty.call(exportLog, 'doc_count')
    ) {
      if (moment(exportLog.time).isBetween(yesterday, today)) {
        downloadsInPast24Hours += exportLog.doc_count;
      }
    }
  }
  const documentExportLimit = exportLimitUtils.getExportLimit();
  return downloadsInPast24Hours + numDocs <= documentExportLimit;
};

export const userBelongsToTeam = ({ teams, current_user }) => {
  if (!teams.length) return true;

  const userTeams = current_user.user.teams.reduce((acc, team) => {
    acc[team.id] = true;
    return acc;
  }, {});

  for (let i = 0; i < teams.length; i++) {
    if (userTeams[teams[i].id]) return true;
  }

  return false;
};

class Restrict extends React.Component {
  static isExpiredBasic() {
    if (auth.loggedIn()) {
      return false;
    }

    const expired =
      usageMonitoringConstants.hasDayUseExpired() ||
      usageMonitoringConstants.hasActivityUseExpired();
    this.sentExpiredBasicAnalytics(expired);
    return expired;
  }

  static showRestrictModal(type = '', title, text = '') {
    store.dispatch(viewActions.showRestrictModal(type, title, text));
  }

  static sentExpiredBasicAnalytics(expired) {
    try {
      const sentActivityAnalyticsStringified = localStorageUtils.getSentActivityAnalytics();
      const sentActivityAnalytics = JSON.parse(sentActivityAnalyticsStringified);

      if (expired && !sentActivityAnalytics) {
        localStorageUtils.setSentActivityAnalytics('true');
        analyticsUtils.safe_analytics(
          'default',
          'Non-auth',
          'Open restrict modal',
          'Activity Limit reached'
        );
      } else if (!expired && sentActivityAnalytics) {
        localStorageUtils.removeSentActivityAnalytics();
      }
    } catch (Exception) {
      localStorageUtils.removeSentActivityAnalytics();
    }
  }

  getModalDescription = () => {
    if (this.props.current_view.restrictModal.text) {
      return this.props.current_view.restrictModal.text;
    }

    let title = this.props.current_view.restrictModal.title;

    if (Restrict.isExpiredBasic()) {
      title = 'Reached Activity Limit';
    }

    const data = { text: '', boxes: [] };

    if (RESTRICT_DESCRIPTIONS[title]) {
      data.text = RESTRICT_DESCRIPTIONS[title].text;
      data.boxes = RESTRICT_DESCRIPTIONS[title].boxes.map(box => (
        <div key={box.text} className="restrict-modal__box-item">
          <div className="restrict-modal__box-item--icon">{box.icon}</div>
          <div className="restrict-modal__box-item--text">{box.text}</div>
        </div>
      ));
    }

    return data;
  };

  closeModal = () => {
    analyticsUtils.safe_analytics(
      'default',
      'Non-auth',
      'Close restrict modal',
      this.props.current_view.restrictModal.title
    );
    this.props.hideRestrictModal();
  };

  render() {
    let {
      title,
      restrictModal: { type, show }
    } = this.props.current_view;

    if (type) {
      // TODO: We are moving Restrict component to RestrictModal with the restrictModal -> type
      // This will be deprecated soon
      return null;
    }

    const { text, boxes } = this.getModalDescription();

    if (Restrict.isExpiredBasic()) {
      title = 'Reached Activity Limit';
      show = !BASIC_EXPIRED_RESTRICTION_DISABLED_ROUTES.includes(location.pathname);
    }

    return (
      <uiLib.Modal
        className="restrict-modal__container"
        isOpen={show}
        onClose={this.closeModal}
        childContainerClassName="restrict-modal-child__container"
      >
        <div className="restrict-modal__header">
          <RestrictBubble />
          <h4>{title || 'Upgrade to use this feature.'}</h4>
        </div>

        <div className="restrict-modal__body">
          {!!text && <div className="restrict-modal__text">{text}</div>}

          {!!boxes.length && <div className="restrict-modal__box-items">{boxes}</div>}
        </div>

        <div className="restrict-modal__footer">
          <uiLib.Button
            type={uiLib.BUTTON_TYPES.PRIMARY}
            href={corporateWebsiteLinksConstants.CORPORATE_WEBSITE_COMPARE_EDITIONS_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Compare Editions
          </uiLib.Button>
          <div className="restrict-modal__footer-login">
            Already have an account? <Link to={routes.login}>Login</Link>
          </div>
        </div>
      </uiLib.Modal>
    );
  }
}

const mapStateToProps = ({ current_view, subscriptions }) => {
  return { current_view, subscriptions };
};

export default connect(mapStateToProps, { hideRestrictModal: viewActions.hideRestrictModal })(
  hooksUtils.withHistory(Restrict)
);
