import type { ComponentType } from 'react';
import type { DocumentDetailsContentTitleComponentProps } from './DocumentDetailsContentTitle.types';

import * as rightPanelConstants from 'shared/features/rightPanel/rightPanel.constants';
import * as elements from './elements';

export const CONTENT_COMPONENTS: Partial<Record<
  rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY,
  ComponentType<DocumentDetailsContentTitleComponentProps>
>> = {
  [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.DIFF]: elements.DiffTabTitle,
  [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.EXPERT_CONNECTION]:
    elements.ExpertConnectionTabTitle
};
