import { useState, useMemo } from 'react';
import lodash from 'lodash';
import { FOLDER_KEY, FOLDER_TYPE } from 'components/Folders/Folders.constants';
import { DEFAULT_FOLDER_PARAMS } from '../AddToFolderModal.constants';

export const useAddToFolderModalData = ({ props }) => {
  const [isAddingFolder, setIsAddingFolder] = useState(false);
  const [folderName, setFolderName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(DEFAULT_FOLDER_PARAMS.PAGE);
  const [folderPaths, setFolderPaths] = useState([]);
  const [savedFolders, setSavedFolders] = useState([]);
  const [folders, setFolders] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [subFoldersCount, setSubFoldersCount] = useState(0);
  const [docsCount, setDocsCount] = useState(0);

  const localActions = useMemo(
    () => ({
      setIsAddingFolder,
      setFolderName,
      setIsLoading,
      setPage,
      setFolderPaths,
      setSavedFolders,
      setFolders,
      setTotalCount,
      setSubFoldersCount,
      setDocsCount
    }),
    []
  );

  const formattedData = useMemo(() => {
    const currentFolder = !lodash.isEmpty(folderPaths)
      ? folderPaths[folderPaths.length - 1]
      : undefined;
    const docIdsInCurremtnFolder = folders
      .filter(folder => folder[FOLDER_KEY.TYPE] === FOLDER_TYPE.DOCUMENT)
      .map(folder => parseInt(folder[FOLDER_KEY.ID], 10));
    const areDocumentsInCurrentFolder =
      !!currentFolder &&
      props.documentIds.every(docId => docIdsInCurremtnFolder.includes(parseInt(docId, 10)));
    const hasMoreFolderDocs = totalCount > page * DEFAULT_FOLDER_PARAMS.LIMIT;

    return {
      currentFolder,
      areDocumentsInCurrentFolder,
      hasMoreFolderDocs
    };
  }, [props.documentIds, folders, folderPaths, totalCount, page]);

  return {
    localState: {
      isAddingFolder,
      folderName,
      isLoading,
      page,
      folderPaths,
      savedFolders,
      folders,
      totalCount,
      subFoldersCount,
      docsCount
    },
    localActions,
    formattedData
  };
};
