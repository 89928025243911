import React from 'react';
import { connect } from 'react-redux';
import lodash from 'lodash';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import queryString from 'utils/query-string';
import { withHistory } from 'utils/hooks';
import { getCategoryNameByID } from 'shared/features/documents/documents.selectors';
import { LocalDateFormat } from 'utils/keyDates';
import { DocumentContextMenuOverlay } from 'common';
import KeyDates from 'components/KeyDates';
import {
  TopicsList,
  RelatedDocsItemsList
} from 'components/Content/elements/SearchResults/elements/DocumentSearchList/elements/DocumentSearchListItem/elements';
import {
  DOCUMENT_PROPERTY_LABEL,
  DOCUMENT_PROPERTY_VALUE,
  getDocumentProperty
} from 'utils/documents/documentProperties';
import { DOCUMENT_FIELDS } from 'constants/DocumentProperties';
import { APP_VIEW_SECTION_NEWS } from 'utils/search';

const MAX_TITLE_LENGTH = 330;

class DocumentListItemContent extends React.PureComponent {
  renderAgencyNames(document) {
    let text = null;
    if (document.agencies) {
      text = document.agencies
        .map(a => {
          return a.short_name || a.name;
        })
        .sort()
        .join(', ');
    }

    if (lodash.get(document, 'mainstream_news.news_source.name')) {
      text = document.mainstream_news.news_source.name;
    }
    return text;
  }

  render() {
    const {
      checkboxColumn,
      rowClassName,
      handleClick,
      location,
      document,
      app_view,
      isPremiumContent,
      categories,
      timezone
    } = this.props;
    const parsedQuery = queryString.parse(location.search);

    //if the document has NOT been read and the read filter is on return null
    if (!document.read && parsedQuery.read === true) {
      return null;
    }
    //if the document has been read and the unread filter is on return null
    if (document.read && parsedQuery.read === false) {
      return null;
    }

    let title = document.title;
    if (title.length > MAX_TITLE_LENGTH) {
      title = title.substr(0, MAX_TITLE_LENGTH) + '…';
    }

    const {
      [DOCUMENT_PROPERTY_LABEL]: publicationLabel,
      [DOCUMENT_PROPERTY_VALUE]: publicationValue
    } = getDocumentProperty[DOCUMENT_FIELDS.publicationDate]({
      document,
      timezoneName: timezone
    });

    return (
      <DocumentContextMenuOverlay tag="tr" document={document} className={rowClassName}>
        {checkboxColumn}
        <td onClick={handleClick}>
          <span className="mobile-headers">{`${publicationLabel}: `}</span>
          <span className="timeline-sources-category">{publicationValue}</span>
          <br />
          <span className="light-gray-date">Published</span>
        </td>
        {app_view.section !== APP_VIEW_SECTION_NEWS ? (
          <td onClick={handleClick}>
            <span className="mobile-effective-date">
              <span className="mobile-headers">Effective Date: </span>
              <KeyDates document={document} dateFormat={LocalDateFormat} />{' '}
            </span>
          </td>
        ) : null}
        <td className="title" onClick={handleClick}>
          <span className="mobile-headers mobile-title">Title: </span>
          <span className="mobile-title">{title}</span>
          <div className="document-topics-list">
            <TopicsList topics={document.topics} />
            <RelatedDocsItemsList
              documentId={document.id}
              relatedDocs={document.related_documents}
            />
          </div>
        </td>
        <td className="doctype" onClick={handleClick}>
          <div className="type label">
            <span className="mobile-headers">Doc Type: </span>
            <span className="timeline-sources-category category-dark">
              {getCategoryNameByID(categories, document.cai_category_id)}
            </span>
          </div>
        </td>
        <td className="source" onClick={handleClick}>
          <div className="agencies">
            <span className="mobile-headers">Source: </span>
            {isPremiumContent ? (
              <div className="premium-content-agency">
                <LabelImportantIcon className="premium-content-icon" />
                <span>{this.renderAgencyNames(document)}</span>
              </div>
            ) : (
              this.renderAgencyNames(document)
            )}
          </div>
        </td>
      </DocumentContextMenuOverlay>
    );
  }
}

const mapStateToProps = state => {
  return {
    categories: state.docTypes.docTypes.cai_categories,
    timezone: state.current_user.user.timezone
  };
};

const reduxDocumentListItemContent = connect(
  mapStateToProps,
  {}
)(withHistory(DocumentListItemContent));

export default reduxDocumentListItemContent;
