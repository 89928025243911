import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { postSearch } from 'shared/features/search/search.actions';
import queryString from 'utils/query-string';

export const useSearchButtonHandlers = ({ onClick, disabled, queryParams, searchType }) => {
  const dispatch = useDispatch();

  const onSearchClick = useCallback(() => {
    if (!disabled) {
      dispatch(
        postSearch({
          search_args: queryString.parse(queryParams),
          type: searchType
        })
      );
      onClick();
    }
  }, [onClick, disabled, dispatch, queryParams, searchType]);

  return {
    onSearchClick
  };
};
