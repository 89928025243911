import type { FC } from 'react';
import type { EitlLabelsSelectProps } from './EitlLabelsSelect.types';

import * as uiLib from '@compliance.ai/web-components';

import {
  useEitlLabelsSelectData,
  useEitlLabelsSelectHandlers,
  useEitlLabelsSelectQueries
} from './hooks';

export const EitlLabelsSelect: FC<EitlLabelsSelectProps> = props => {
  const { localState, localActions, formattedData } = useEitlLabelsSelectData({
    props
  });

  const handlers = useEitlLabelsSelectHandlers({
    props,
    localActions
  });

  useEitlLabelsSelectQueries({
    props,
    localActions,
    formattedData
  });

  return (
    <uiLib.Select
      {...props}
      type={props.type ?? uiLib.SELECT_TYPES.DEFAULT}
      value={localState.value}
      loadOptions={handlers.handleEitlLabelsFetch}
      isLoading={localState.isLoading}
      shouldCacheFetchedOptions
      shouldFetchDefaultOptions
      shouldKeepInputValue
      isAsync
      isMulti
    />
  );
};
