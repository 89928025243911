import type { DocumentEditMetadataProps } from '../DocumentEditMetadata.types';
import type { DocumentEditMetadataData } from './useDocumentEditMetadataData.types';
import type { DocumentEditMetadataHandlers } from './useDocumentEditMetadataHandler.types';

import { useMemo, useCallback } from 'react';
import { useDocumentsApi } from 'shared/features/documents/hooks';
import {
  formatMetadataValue,
  formatUpdatedMetadataValue
} from './useDocumentEditMetadataHandler.helpers';
import { showNotification } from '@compliance.ai/web-components';
import { DOCUMENT_METADATA_FIELDS } from 'constants/DocumentMetadata';
import {
  DOCUMENT_METADATA_UPDATE_STATUS,
  DOCUMENT_METADATA_UPDATE_STATUS_ALERTS
} from '../DocumentEditMetadata.constants';

import * as errorUtils from 'utils/errors';

export const useDocumentEditMetadataHandler = ({
  props,
  localState,
  localActions,
  reduxState,
  formattedData,
  documentsApi
}: {
  props: DocumentEditMetadataProps;
  localState: DocumentEditMetadataData['localState'];
  localActions: DocumentEditMetadataData['localActions'];
  reduxState: DocumentEditMetadataData['reduxState'];
  formattedData: DocumentEditMetadataData['formattedData'];
  documentsApi: ReturnType<typeof useDocumentsApi>;
}): DocumentEditMetadataHandlers => {
  const handleChangeMetadata: DocumentEditMetadataHandlers['handleChangeMetadata'] = useCallback(
    (field: DOCUMENT_METADATA_FIELDS) => value => {
      localActions.setMetadataValues(metadataValues => ({
        ...metadataValues,
        [field]: formatMetadataValue(field, value)
      }));
    },
    [localActions]
  );

  const handleSaveMetadata: DocumentEditMetadataHandlers['handleSaveMetadata'] = useCallback(async () => {
    const metadata = formatUpdatedMetadataValue(
      localState.metadataValues,
      reduxState.caiCategoriesIdsToNamesMap,
      formattedData.availableDocumentUpdateFields
    );

    try {
      localActions.setIsUpdating(true);
      const { status } = await documentsApi.updateDocumentMetadata(props.document.id, metadata);
      showNotification(
        DOCUMENT_METADATA_UPDATE_STATUS_ALERTS[status as DOCUMENT_METADATA_UPDATE_STATUS]
      );
      props.onClose();
    } catch (e) {
      errorUtils.logError(e as Error);
    } finally {
      localActions.setIsUpdating(false);
    }
  }, [
    props,
    documentsApi,
    localState.metadataValues,
    localActions,
    reduxState.caiCategoriesIdsToNamesMap,
    formattedData.availableDocumentUpdateFields
  ]);

  return useMemo(
    () => ({
      handleChangeMetadata,
      handleSaveMetadata
    }),
    [handleChangeMetadata, handleSaveMetadata]
  );
};
