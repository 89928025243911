import type { ActsSelectHandlers } from './useActsSelectHandlers.types';

import { logError } from 'utils/errors';
import * as searchConstants from 'shared/features/search/search.constants';
import { useSearchReduxActions } from 'shared/features/search/hooks';

export const useActsSelectHandlers = (): ActsSelectHandlers => {
  const searchReduxActions = useSearchReduxActions();

  const handleFetch: ActsSelectHandlers['handleFetch'] = async inputValue => {
    try {
      const response = await searchReduxActions.fetchAutoComplete(
        inputValue,
        searchConstants.SEARCH_CATEGORIES.ACTS
      );

      return response.results.map(item => ({
        label: item.name,
        value: item.id
      }));
    } catch (e) {
      logError(e as Error);

      return [];
    }
  };

  return {
    handleFetch
  };
};
