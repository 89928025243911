import PropTypes from 'prop-types';
import ReactAutosuggest from 'react-autosuggest';

const Autosuggest = ({
  suggestions,
  onSuggestionsFetchRequested,
  onSuggestionsClearRequested,
  onSuggestionSelected,
  getSuggestionValue,
  renderSuggestion,
  renderSuggestionsContainer,
  shouldRenderSuggestions,
  inputProps
}) => {
  return (
    <div data-testid="autosuggest">
      <ReactAutosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        shouldRenderSuggestions={shouldRenderSuggestions}
        inputProps={inputProps}
      />
    </div>
  );
};
export default Autosuggest;

Autosuggest.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSuggestionsFetchRequested: PropTypes.func.isRequired,
  onSuggestionsClearRequested: PropTypes.func.isRequired,
  onSuggestionSelected: PropTypes.func,
  getSuggestionValue: PropTypes.func.isRequired,
  renderSuggestion: PropTypes.func.isRequired,
  renderSuggestionsContainer: PropTypes.func,
  shouldRenderSuggestions: PropTypes.func,
  inputProps: PropTypes.shape({
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
  }).isRequired
};

Autosuggest.defaultProps = {
  onSuggestionSelected: () => {},
  renderSuggestionsContainer: undefined,
  shouldRenderSuggestions: undefined
};
