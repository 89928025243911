import {
  LOCATION_KEY,
  VIOLATION_KEY,
  PENALTY_KEY,
  DOCUMENT_TYPE_KEY,
  COMMENTS_CLOSED_DATE_KEY,
  EFFECTIVE_DATE_KEY,
  PDF_URL_KEY,
  SOURCE_KEY,
  DOCUMENT_ID_KEY,
  DOCKET_ID_KEY,
  SUMMARY_KEY,
  READ_KEY,
  AUTO_SUMMARY_KEY,
  JURISDICTION_KEY,
  RESPONDENT_KEY,
  PUBLICATION_DATE_KEY,
  CREATED_DATE_KEY,
  WEB_URL_KEY,
  WORKFLOW_STATUS_KEY,
  FULL_TEXT_KEY,
  TITLE_KEY,
  TOPICS_KEY,
  CFR_KEY,
  CITATION_KEY,
  DOC_LABELS_KEY,
  SENTENCE_TEXT_KEY,
  SENTENCE_ID_KEY,
  SENTENCE_TYPE_KEY,
  SENTENCE_GROUP_KEY,
  SYSTEM_GENERATED_OBLIGATIONS_KEY,
  USER_GENERATED_OBLIGATIONS_KEY,
  OBLIGATION_KEY,
  COMBINED_SENTENCES_KEY,
  ANNOTATION_GROUP_KEY,
  ANNOTATION_TEXT_KEY,
  ANNOTATION_ATTACHMENTS_KEY,
  ANNOTATION_LABELS_KEY,
  ANNOTATIONS_KEY,
  SENTENCES_KEY,
  HAS_UNOFFICIAL_PUBLICATION_DATE,
  OFFICIAL_ID,
  EITL_LABELS
} from 'constants/DocumentProperties';

/*
 * Common Document Export Columns
 */
export const BASIC_EXPORT_COLUMNS_ONE = [
  TITLE_KEY,
  DOCUMENT_TYPE_KEY,
  COMMENTS_CLOSED_DATE_KEY,
  EFFECTIVE_DATE_KEY,
  PDF_URL_KEY,
  SOURCE_KEY,
  DOCUMENT_ID_KEY,
  DOCKET_ID_KEY,
  SUMMARY_KEY
];
export const BASIC_EXPORT_COLUMNS_TWO = [
  READ_KEY,
  CFR_KEY,
  AUTO_SUMMARY_KEY,
  JURISDICTION_KEY,
  TOPICS_KEY,
  RESPONDENT_KEY,
  PENALTY_KEY,
  VIOLATION_KEY,
  PUBLICATION_DATE_KEY
];
export const BASIC_EXPORT_COLUMNS_THREE = [
  CREATED_DATE_KEY,
  WEB_URL_KEY,
  WORKFLOW_STATUS_KEY,
  FULL_TEXT_KEY,
  LOCATION_KEY,
  CITATION_KEY,
  DOC_LABELS_KEY,
  HAS_UNOFFICIAL_PUBLICATION_DATE,
  EITL_LABELS
];
export const BASIC_EXPORT_COLUMNS = [
  ...BASIC_EXPORT_COLUMNS_ONE,
  ...BASIC_EXPORT_COLUMNS_TWO,
  ...BASIC_EXPORT_COLUMNS_THREE
];
export const PERMISSION_BASED_COLUMNS = [OFFICIAL_ID];

/*
 * Sentences Export Columns
 */
export const SENTENCES_COLUMNS_ONE = [
  SENTENCE_TEXT_KEY,
  SENTENCE_ID_KEY,
  SENTENCE_TYPE_KEY,
  SENTENCE_GROUP_KEY
];
export const SENTENCES_COLUMNS_TWO = [
  SYSTEM_GENERATED_OBLIGATIONS_KEY,
  USER_GENERATED_OBLIGATIONS_KEY,
  OBLIGATION_KEY,
  ANNOTATIONS_KEY
];
export const SENTENCES_COLUMNS = [...SENTENCES_COLUMNS_ONE, ...SENTENCES_COLUMNS_TWO];

/*
 * Annotations Export Columns
 */
export const ANNOTATIONS_COLUMNS_ONE = [
  COMBINED_SENTENCES_KEY,
  ANNOTATION_GROUP_KEY,
  ANNOTATION_TEXT_KEY
];
export const ANNOTATIONS_COLUMNS_TWO = [ANNOTATION_ATTACHMENTS_KEY, ANNOTATION_LABELS_KEY];
export const ANNOTATIONS_COLUMNS = [...ANNOTATIONS_COLUMNS_ONE, ...ANNOTATIONS_COLUMNS_TWO];

export const ADDITIONAL_COLUMNS_CHECKS = [SENTENCES_KEY, ANNOTATIONS_KEY];
