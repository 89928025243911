import { createSlice } from '@reduxjs/toolkit';

export const reducerName = 'jurisdictions';

export const initialState = {
  isFetching: false,
  isReady: false,
  items: []
};

const jurisdictions = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    requestAllJurisdictions: (state, action) => {
      state.isFetching = true;
      state.isReady = false;
    },
    receiveJurisdictions: (state, action) => {
      const items = action.payload;
      state.isFetching = false;
      state.isReady = true;
      state.items = items;
    }
  }
});

export default jurisdictions.reducer;
export const { receiveJurisdictions, requestAllJurisdictions } = jurisdictions.actions;
