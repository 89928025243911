export const snapshotDocsHelper = (state, payload) => {
  const { id, documents } = payload;
  state[id] = documents;
  return state;
};

export const timeComparisonDocsHelper = (state, payload) => {
  const { id, firstPeriod, secondPeriod } = payload;
  state[id] = { firstPeriod, secondPeriod };
  return state;
};
