export const dashboard = '/dashboard';
export const content = '/content';
export const sources = '/sources';
export const insights = '/insights';
export const resources = '/resources';
export const enforcementExplorer = '/enforcementexplorer';
export const tasks = '/tasks';
export const folders = '/folders';
export const account = '/account';
export const settings = '/settings';
export const notifications = '/notifications';
export const topics = '/topics';
export const downloads = '/downloads';
export const swagger = '/swagger';
export const profileSetup = '/setup';
export const admin = '/admin';
export const organizationProfile = '/organization-profile';
export const agencyLandingPage = '/agency-landing-page';
export const search = '/search';
export const insightsFrame = '/insights-frame';
export const privacy = '/privacy';
export const terms = '/terms';
export const plans = '/plans';
export const legal = '/legal';
export const support = '/support';
export const checkout = '/checkout';
export const managementHub = '/management-hub';
export const organizationDocuments = '/organization-documents';
export const glassDoor = '/glass-door';
export const unsubscribe = '/unsubscribe';
export const urlShortener = '/s/:url';
export const news = '/news';
export const timeline = '/timeline';
export const serviceConnectors = '/service-connectors';
export const manageLabels = '/manage-labels';

export const team = '/team';

/**
 *
 * Auth routes
 *
 */
export const login = '/login';
export const logout = '/logout';
export const activate = '/activate';
export const authRedirect = '/auth-redirect';

/**
 *
 * Legacy auth routes
 *
 */
export const corporateLoginRedirect = '/corporateLogin';

/**
 *
 * Testing routes
 *
 */

export const sso = '/sso';
