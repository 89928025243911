import React from 'react';
import classNames from 'classnames';
import { withHistory } from 'utils/hooks';
import lodash from 'lodash';
import { connect } from 'react-redux';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import LockIcon from '@material-ui/icons/Lock';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import queryString from 'utils/query-string';
import { markDocumentAsBookmarked } from 'shared/features/documents/documents.actions';
import { changeSelectedItem } from 'shared/features/view/view.actions';
import { rateSearchResult } from 'shared/features/user/user.actions';
import { getCategoryNameByID } from 'shared/features/documents/documents.selectors';
import { safe_highlight_and_truncate, get_highlighted_string_fragments } from 'utils/string';
import { safe_analytics } from 'utils/analytics';
import KeyDates from 'components/KeyDates';
import { newsSourceS3BucketUrl } from 'shared/config';
import auth from 'utils/auth';
import { DocumentContextMenuOverlay } from 'common';
import { Button, BUTTON_TYPES } from '@compliance.ai/web-components';
import RestrictBubble from 'components/RestrictBubble';
import { restrict } from 'components/Restrict';
import { userCanAccessDocument, getDocumentRestriction } from 'utils/documents/documentsAccess';
import {
  DOCUMENT_PROPERTY_LABEL,
  DOCUMENT_PROPERTY_VALUE,
  getDocumentProperty
} from 'utils/documents/documentProperties';
import { DOCUMENT_FIELDS } from 'constants/DocumentProperties';
import { RESTRICTED_ACTIONS } from 'constants/Restrictions';
import { withRightPanelReduxActions } from 'shared/features/rightPanel/hooks';
import { RIGHT_PANEL_TYPES } from 'shared/features/rightPanel/rightPanel.constants';
import { getRightPanelDocumentId } from 'shared/features/rightPanel/rightPanel.selectors';
import { getUserTimezone } from 'shared/features/user/user.selectors';
import { TopicsList, RelatedDocsItemsList } from './elements';

const MAX_EXCERPT_LENGTH = 175;

export class DocumentSearchListItem extends React.Component {
  state = {
    relevant: null,
    checked: this.props.current_view.selected_items[this.props.document.id] !== undefined
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.current_view.selected_items[this.props.document.id] !==
      prevProps.current_view.selected_items[this.props.document.id]
    ) {
      this.setState({
        checked: this.props.current_view.selected_items[this.props.document.id] !== undefined
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    /*
      If the document has already been rated relevant by the user using the same
      search parameters, surface the last vote cast, otherwise show as if not voted.
    */
    const document = nextProps.document;
    if (nextProps.user_vote.voted_docs[document.id]) {
      if (
        lodash.isEqual(
          nextProps.user_vote.voted_docs[document.id].search_args,
          nextProps.user_vote.search_args
        )
      ) {
        if (nextProps.user_vote.voted_docs[document.id].relevance) {
          if (this.state.relevant !== true) {
            this.setState({ relevant: true });
          }
        } else if (nextProps.user_vote.voted_docs[document.id].relevance === false) {
          if (this.state.relevant !== false) {
            this.setState({ relevant: false });
          }
        }
      }
    }
  }

  getSearchTerms() {
    const search_terms = [];
    const filtered_item = this.props.filtered_mention.mention;

    // make sure the name, short_name, id for the filtered item all get highlighted
    if (filtered_item) {
      if (filtered_item.name) {
        // n.b. dockets have no name
        search_terms.push(filtered_item.name);
      }
      if (filtered_item.short_name) {
        search_terms.push(filtered_item.short_name);
      }
      if (filtered_item.nicknames && filtered_item.nicknames.constructor === Array) {
        for (const nickname of filtered_item.nicknames) {
          search_terms.push(nickname);
        }
      }
    }

    return search_terms;
  }

  handleVoting(is_relevant) {
    this.setState({ relevant: is_relevant });

    const vote = is_relevant ? 'Yes' : 'No';
    safe_analytics('default', 'Feedback', 'Relevant Result', vote);

    const document = this.props.document;
    const search_args = this.props.user_vote.search_args;

    this.props.rateSearchResult(document.id, is_relevant, search_args).then(() => {});
  }

  navigateSummary(id) {
    this.props.openRightPanel({
      type: RIGHT_PANEL_TYPES.DOCUMENT_DETAILS,
      props: {
        documentId: id
      }
    });
  }

  handleClick(event) {
    if (
      event.target &&
      ((event.target.classList && event.target.classList.contains('clickable')) ||
        event.target.tagName === 'INPUT')
    ) {
      return;
    }

    event.preventDefault();
    const document = this.props.document;

    if (event.type === 'click') {
      const search_query = this.props.current_view.search_params.search_query;
      safe_analytics(
        'Search – Search Results Click',
        'Search',
        'Search Results Click',
        search_query
      );

      this.navigateSummary(document.id);
    }
  }

  renderPublicationName(document) {
    let text = null;
    if (lodash.isArray(document.agencies) && !lodash.isEmpty(document.agencies)) {
      text =
        document.agencies
          .filter(doc => {
            return !(doc.name === 'test_test' || doc.short_name === 'test_test');
          })
          .map(a => {
            return a.short_name || a.name;
          })
          .sort()
          .join(', ') + ':';
    } else if (lodash.get(document, 'mainstream_news.news_source.name')) {
      text = document.mainstream_news.news_source.name + ':';
    }

    return text;
  }

  renderDiffIcon(document) {
    const userHasAccess = userCanAccessDocument({ document });
    const documentAccess =
      userHasAccess && getDocumentRestriction({ document, action: RESTRICTED_ACTIONS.DIFF });

    if (!documentAccess) {
      return <LockIcon className="lockIcon" />;
    }

    if (document.has_sentences) {
      return <VerticalSplitIcon className="diffIcon grey" />;
    }

    return null;
  }

  render() {
    const parsedQuery = queryString.parse(this.props.location.search);
    const { document, categories } = this.props;
    const title = document.title;
    const {
      [DOCUMENT_PROPERTY_LABEL]: publicationLabel,
      [DOCUMENT_PROPERTY_VALUE]: publicationValue
    } = getDocumentProperty[DOCUMENT_FIELDS.publicationDate]({
      document,
      timezoneName: this.props.userTimezone
    });

    const handleBookmarked = e => {
      if (document.bookmarked) {
        safe_analytics(
          'Doc Action – Unbookmark document',
          'Doc Action',
          'Unbookmark document',
          title
        );
      } else {
        safe_analytics('Doc Action – Bookmark document', 'Doc Action', 'Bookmark document', title);
      }

      this.props.markDocumentAsBookmarked(document.id, !document.bookmarked);
    };

    const handleCheck = () => {
      if (!this.props.current_view.selected_items[document.id]) {
        this.navigateSummary(document.id);
      }
      this.props.changeSelectedItem(document, this.state.checked);
    };

    const handleCheckClick = () => {
      this.setState(state => {
        return { checked: !state.checked };
      }, handleCheck);
    };

    const results_classes = {
      read_document: document.read,
      selected: this.props.rightPanelDocumentId === this.props.document.id,
      'document-result': true
    };

    const bookmark_icon = document.bookmarked ? <BookmarkIcon /> : <BookmarkBorderIcon />;

    // FIXME: we should extract the search terms once at the list level
    let search_terms = [];

    if (parsedQuery.search_sort === 'relevance') {
      search_terms.push(this.props.current_view.search_params.search_query);
    } else if (parsedQuery.search_sort === 'date') {
      const mentions_for_filter = document.mentions_for_filter || [];
      search_terms = this.getSearchTerms().concat(mentions_for_filter);
    }

    const summary_text = [];

    let full_texts;

    // FIXME: using the summary text here for highlighting doesn't make sense -
    // there are no search terms.
    if (parsedQuery.more_like_doc_id || parsedQuery.comments_for_id) {
      full_texts = [lodash.get(document, 'summary_text')];
    } else if (parsedQuery.search_sort === 'date') {
      // FIXME: this means we are running our highlighting code twice, we should allow it to skip
      // highlighting when we've already ran it once locally
      full_texts = lodash.get(document, 'full_text')
        ? get_highlighted_string_fragments(lodash.get(document, 'full_text'), search_terms, 'em')
        : '';
    } else {
      if (document.highlights) {
        if (document.highlights['full_text.exact_match']) {
          full_texts = document.highlights['full_text.exact_match'];
        } else {
          full_texts = lodash.get(document, 'highlights.full_text');
        }
      }
    }

    if (full_texts) {
      let i = 0;
      for (const full_text of full_texts) {
        if (full_text) {
          const highlighted = safe_highlight_and_truncate(
            full_text,
            search_terms,
            'em',
            MAX_EXCERPT_LENGTH
          );
          const key = `${document.id}-${i}`;

          const initial = i === 0 ? '…' : '';

          summary_text.push(
            <span
              key={key}
              dangerouslySetInnerHTML={{
                __html: `${initial} ${highlighted} …`
              }}
            />
          );

          i++;
        }
      }
    }
    // results look better if we do highlighting on our side:
    const highlighted_title = safe_highlight_and_truncate(
      title,
      search_terms,
      'em',
      MAX_EXCERPT_LENGTH
    );

    /*
      Check to make sure the save search button tooltip isn't displayed at the same time
      as the relevance voting tootip to avoid overlapping
    */
    const saved_search_tip_selected =
      this.props.current_user.user.properties &&
      this.props.current_user.user.properties.read_new_feature_tip
        ? this.props.current_user.user.properties.read_new_feature_tip['2']
        : false;

    const relevanceVoteClasses = {
      'relevant-vote': true,
      relevant: this.state.relevant === true
    };
    const notRelevanceVoteClasses = {
      'not-relevant-vote': true,
      'not-relevant': this.state.relevant === false
    };

    const image_present =
      !lodash.isNil(document.mainstream_news) &&
      !lodash.isNil(document.mainstream_news.news_source.logo_hash);

    return (
      <DocumentContextMenuOverlay
        tag="li"
        document={document}
        className={classNames(results_classes, this.props.extra_classes)}
      >
        <div
          className="document-result-content"
          onClick={e => this.handleClick(e)}
          onDoubleClick={e => this.handleClick(e)}
        >
          <div className="document-result-row header">
            <div className="title-topic-btns">
              <div
                className="document-result-title"
                dangerouslySetInnerHTML={{ __html: highlighted_title }}
              />
              <TopicsList topics={document.topics} />
              <RelatedDocsItemsList
                documentId={document.id}
                relatedDocs={document.related_documents}
              />
            </div>
            <div className="logo-vote-container">
              {image_present && (
                <img
                  className="mainstream-news-img"
                  src={
                    newsSourceS3BucketUrl +
                    this.props.document.mainstream_news.news_source.logo_hash
                  }
                  alt="news_img"
                />
              )}
              {auth.loggedIn() && (
                <div id="vote-container">
                  <h5>Relevant Result?</h5>
                  <div className="relevance-vote-icons">
                    <span
                      className={classNames(relevanceVoteClasses)}
                      title="Relevant"
                      onClick={() => this.handleVoting(true)}
                    >
                      Y
                    </span>
                    <span
                      className={classNames(notRelevanceVoteClasses)}
                      title="Not Relevant"
                      onClick={() => this.handleVoting(false)}
                    >
                      N
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="document-result-row dates">
            <span>{publicationLabel}:</span>
            <span>{publicationValue}</span>
            <KeyDates document={document} labelFirst labelSuffix={':'} />
          </div>
          <div className="document-result-row">
            <span className="document-result-agency">{this.renderPublicationName(document)}</span>
            <span className="document-result-category">
              {` ${getCategoryNameByID(categories, document.cai_category_id)}`}
            </span>
          </div>
          <div className="document-result-row">
            <div className="document-result-controls">
              <div>
                <input
                  type="checkbox"
                  checked={this.state.checked}
                  onChange={handleCheckClick}
                  inline="true"
                />
                <CheckBoxOutlineBlankIcon
                  className="unchecked clickable"
                  onClick={handleCheckClick}
                />
                <CheckBoxIcon className="checked clickable" onClick={handleCheckClick} />
              </div>
              <div className="bookmarkBubble">
                <Button
                  type={BUTTON_TYPES.LINK}
                  className="bookmark clickable"
                  onClick={restrict(handleBookmarked, this, 'Bookmark Document')}
                  aria-hidden="true"
                >
                  {bookmark_icon}
                  {!auth.loggedIn() && <RestrictBubble small docListItem />}
                </Button>
              </div>
              {this.renderDiffIcon(document)}
            </div>
            <div className="document-result-summary-text">{summary_text}</div>
          </div>
        </div>
      </DocumentContextMenuOverlay>
    );
  }
}

const mapStateToProps = state => {
  return {
    current_view: state.current_view,
    filtered_mention: state.filtered_mention,
    user_vote: state.user_vote,
    current_user: state.current_user,
    documents: state.documents,
    categories: state.docTypes.docTypes.cai_categories,
    rightPanelDocumentId: getRightPanelDocumentId(state),
    userTimezone: getUserTimezone(state)
  };
};

const ReduxDocumentSearchListItem = connect(mapStateToProps, {
  changeSelectedItem,
  markDocumentAsBookmarked,
  rateSearchResult
})(withHistory(withRightPanelReduxActions(DocumentSearchListItem)));

export default ReduxDocumentSearchListItem;
