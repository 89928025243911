import * as uiLib from '@compliance.ai/web-components';
import PropTypes from 'prop-types';

import * as constants from './QueryFieldSelect.constants';

export const QueryFieldSelect = ({
  items,
  setField,
  selectedKey,
  readonly,
  selectedLabel,
  withTooltips,
  withValueTooltips
}) => {
  return (
    <uiLib.Select
      type={uiLib.SELECT_TYPES.DEFAULT}
      options={items}
      onChange={value => setField(value.path)}
      value={{ label: selectedLabel, value: selectedKey }}
      isClearable={false}
      isDisabled={readonly}
      withTooltips={withTooltips}
      withValueTooltips={withValueTooltips}
      classNamePrefix={constants.ADVANCED_SEARCH_SINGLE_SELECT_PREFIX}
    />
  );
};

QueryFieldSelect.propTypes = {
  items: PropTypes.any,
  setField: PropTypes.func,
  selectedKey: PropTypes.any,
  readonly: PropTypes.bool,
  selectedLabel: PropTypes.string,
  withTooltips: PropTypes.bool,
  withValueTooltips: PropTypes.bool
};
