import type { AddRelatedDocumentsModalHandlers } from './useAddRelatedDocumentsModalHandlers.types';
import type { AddRelatedDocumentsModalLocalActions } from './useAddRelatedDocumentsModalData.types';

export const useAddRelatedDocumentsModalHandlers = ({
  localActions
}: {
  localActions: AddRelatedDocumentsModalLocalActions;
}): AddRelatedDocumentsModalHandlers => {
  const handleSelectedDocumentsChange: AddRelatedDocumentsModalHandlers['handleSelectedDocumentsChange'] = ids => {
    localActions.setRelatedDocIds(ids.map(Number));
  };

  const handleFiltersChange: AddRelatedDocumentsModalHandlers['handleFiltersChange'] = requestParams => {
    localActions.setRequestParams(requestParams);
  };

  return {
    handleSelectedDocumentsChange,
    handleFiltersChange
  };
};
