import type { DocumentsTableProps } from '../DocumentsTable.types';
import type { DocumentsTableHandlers } from './useDocumentsTableHandlers.types';
import type { DocumentsTableData } from 'common/DocumentsTable/hooks/useDocumentsTableData.types';

import type * as react from 'react';
import type * as elements from '../elements';
import type * as documentTypes from 'shared/features/documents/documents.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from 'common/DocumentsTable/DocumentsTable.constants';
import * as helpers from './useDocumentsTableSelector.helpers';

export const useDocumentsTableSelector = ({
  props,
  localState,
  events,
  reduxState
}: {
  props: Pick<
    DocumentsTableProps,
    | 'isDocumentClickable'
    | 'isDocumentChicletClickable'
    | 'activeDocumentId'
    | 'identityIterator'
    | 'onDocumentClick'
    | 'onRelatedDocumentsClick'
    | 'onTopicClick'
  >;
  localState: DocumentsTableData['localState'];
  reduxState: Pick<DocumentsTableData['reduxState'], 'isAuthenticated'>;
  events: {
    onTableRefresh: DocumentsTableHandlers['handleTableRefresh'];
  };
}) => {
  return (
    data: Awaited<ReturnType<DocumentsTableHandlers['handleDocumentsFetch']>>
  ): Awaited<ReturnType<Required<react.ComponentProps<typeof uiLib.Table>>['onDataFetch']>> => {
    const identityIterator = props.identityIterator || localState.sort.sortType;
    const groupedDocuments = uiLib.groupBy<documentTypes.DocumentFromResponse>(
      data.results as documentTypes.DocumentFromResponse[],
      identityIterator in helpers.GROUP_IDENTITY_ITERATORS
        ? helpers.GROUP_IDENTITY_ITERATORS[identityIterator]
        : () => null
    );

    return {
      count: data.count,
      results: Object.entries(groupedDocuments).flatMap(([group, documents]) => {
        return documents.map((document, i) => {
          const shouldShowGroup = i === 0 && identityIterator in helpers.GROUP_IDENTITY_ITERATORS;

          const cellValue: react.ComponentProps<typeof elements.DocumentItem>['cellValue'] = {
            document: document,
            visibleDocFields: localState.visibleDocFields,
            selectedDocsIds: localState.selectedDocsIds,
            isDocumentClickable: Boolean(props.isDocumentClickable),
            isDocumentChicletClickable: Boolean(props.isDocumentChicletClickable),
            isRestricted: !reduxState.isAuthenticated,
            activeDocumentId: props.activeDocumentId,
            refreshTable: events.onTableRefresh,
            events: {
              onDocumentClick: props.onDocumentClick,
              onRelatedDocumentsClick: props.onRelatedDocumentsClick,
              onTopicClick: props.onTopicClick
            }
          };

          const row: uiLib.Row = {
            rowCaption: shouldShowGroup ? group : null,

            [constants.TABLE_KEYS.ID]: document.id,
            [constants.TABLE_KEYS.DOCUMENT]: cellValue
          };

          return row;
        });
      })
    };
  };
};
