import type { SelectOption } from '@compliance.ai/web-components';
import type { DefaultFiltersViewSelectProps } from '../DefaultFiltersViewSelect.types';
import type { DefaultFiltersViewSelectData } from './useDefaultFiltersViewSelectData.types';
import type { DefaultFiltersViewSelectHandlers } from './useDefaultFiltersViewSelectHandlers.types';

export const useDefaultFiltersViewSelectHandlers = ({
  props
}: {
  props: DefaultFiltersViewSelectProps;
}): DefaultFiltersViewSelectHandlers => {
  const handleSelectChange: DefaultFiltersViewSelectHandlers['handleSelectChange'] = value => {
    props.onSelectChange?.((value as SelectOption)?.value || null);
  };

  return { handleSelectChange };
};
