import { PureComponent } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckIcon from '@material-ui/icons/Check';
import PropTypes from 'prop-types';
import { Tooltip, TOOLTIP_POSITION } from 'common/index';
import './_workflow-mold-options-dropdown.scss';

const CAN_NOT_DELETE_MOLD_WITH_THIS_SHARE_SCOPE_VALUE = 'global';

class WorkflowMoldOptionsDropdown extends PureComponent {
  state = { show: false };

  getButtonProps = () => {
    const initialProps = {
      className: 'workflow-mold-options-dropdown__more-icon',
      onClick: () => this.setState(state => ({ show: !state.show })),
      children: <MoreVertIcon />
    };
    const editModeProps = {
      className: 'workflow-mold-options-dropdown__check-icon',
      onClick: this.props.saveTemplateName,
      children: <CheckIcon />
    };

    return this.props.editNameMode ? editModeProps : initialProps;
  };

  render() {
    return (
      <Tooltip
        wrapperClassName="workflow-mold-options-dropdown"
        placement={TOOLTIP_POSITION.BOTTOM_END}
        title={
          <div className="workflow-mold-options-dropdown__title">
            <div
              className="workflow-mold-options-dropdown__dropdown-element"
              onClick={() => this.props.setEditNameMode(true)}
            >
              Rename Template
            </div>
            <div
              hidden={this.props.share_scope === CAN_NOT_DELETE_MOLD_WITH_THIS_SHARE_SCOPE_VALUE}
              className="workflow-mold-options-dropdown__dropdown-element"
              onClick={() => this.props.setShowDeleteModalStatus(true)}
            >
              Delete Template
            </div>
          </div>
        }
        isOpen={this.state.show}
        onClose={() => this.setState({ show: false })}
        leaveDelay={100}
        interactive
      >
        <button {...this.getButtonProps()} />
      </Tooltip>
    );
  }
}

WorkflowMoldOptionsDropdown.propTypes = {
  share_scope: PropTypes.string,
  setEditNameMode: PropTypes.func.isRequired,
  setShowDeleteModalStatus: PropTypes.func.isRequired,
  editNameMode: PropTypes.bool.isRequired
};

WorkflowMoldOptionsDropdown.defaultProps = {
  share_scope: ''
};

export default WorkflowMoldOptionsDropdown;
