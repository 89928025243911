import { FILTER_KEY, EXACT_MATCH, CLOSE_MATCH } from 'constants/PrimaryFilter';
import { convertToAdvancedSearch } from 'utils/filter/convertToAdvancedSearch';
import { safe_mixpanel_track } from 'utils/analytics';
import queryString from 'utils/query-string';

export const extractValue = object => {
  return object.value;
};

export const extractLabel = object => {
  return object.label;
};

export const mapConceptOptionToAdvancedSearch = concepts => conceptOption => {
  const concept = concepts.find(({ id }) => id === conceptOption.value);

  if (concept) {
    return concept.advanced_search;
  }
  return null;
};

export const getExactMatchForKey = (filterParams, matchTypeKey, paramKey) => {
  const isExactMatch =
    !filterParams[matchTypeKey] || filterParams[matchTypeKey].value === EXACT_MATCH;

  return isExactMatch ? filterParams[paramKey] : '';
};

export const getCloseMatchForKey = (filterParams, matchTypeKey, paramKey) => {
  const isCloseMatch = filterParams[matchTypeKey].value === CLOSE_MATCH;

  return isCloseMatch ? filterParams[paramKey] : '';
};

export const getLanguageValue = languageObject => {
  if (languageObject && languageObject.value) return languageObject.value;

  return null;
};

export const convertToPrimaryFilterToAdvancedSearch = ({
  reduxState,
  reduxActions,
  navigate,
  location
}) => {
  const params = {
    [FILTER_KEY.JURISDICTIONS]: reduxState.filterParams[FILTER_KEY.JURISDICTIONS].map(extractValue),
    [FILTER_KEY.AGENCIES]: reduxState.filterParams[FILTER_KEY.AGENCIES].map(extractLabel),
    [FILTER_KEY.AUTHOR]: reduxState.filterParams[FILTER_KEY.AUTHOR].map(extractLabel),
    [FILTER_KEY.MAINSTREAM_NEWS_SOURCES]: reduxState.filterParams[
      FILTER_KEY.MAINSTREAM_NEWS_SOURCES
    ].map(extractLabel),
    [FILTER_KEY.TOPICS]: reduxState.filterParams[FILTER_KEY.TOPICS].map(extractValue),
    [FILTER_KEY.DOC_TYPES]: reduxState.filterParams[FILTER_KEY.DOC_TYPES].map(extractValue),
    [FILTER_KEY.REGULATIONS]: reduxState.filterParams[FILTER_KEY.REGULATIONS].map(extractValue),
    [FILTER_KEY.ACTS]: reduxState.filterParams[FILTER_KEY.ACTS].map(extractValue),
    [FILTER_KEY.BANKS]: reduxState.filterParams[FILTER_KEY.BANKS].map(extractValue),
    [FILTER_KEY.DOC_PROPERTIES]: reduxState.filterParams[FILTER_KEY.DOC_PROPERTIES].map(
      extractLabel
    ),
    [FILTER_KEY.TEXT]: getCloseMatchForKey(
      reduxState.filterParams,
      FILTER_KEY.TEXT_MATCH_TYPES,
      FILTER_KEY.TEXT
    ),
    [FILTER_KEY.TEXT_MATCH_TYPES]: getExactMatchForKey(
      reduxState.filterParams,
      FILTER_KEY.TEXT_MATCH_TYPES,
      FILTER_KEY.TEXT
    ),
    [FILTER_KEY.TITLE]: getCloseMatchForKey(
      reduxState.filterParams,
      FILTER_KEY.TITLE_MATCH_TYPES,
      FILTER_KEY.TITLE
    ),
    [FILTER_KEY.TITLE_MATCH_TYPES]: getExactMatchForKey(
      reduxState.filterParams,
      FILTER_KEY.TITLE_MATCH_TYPES,
      FILTER_KEY.TITLE
    ),
    [FILTER_KEY.PUB_DATE]: reduxState.filterParams[FILTER_KEY.PUB_DATE],
    [FILTER_KEY.HAS_UNOFFICIAL_PUB_DATE]:
      reduxState.filterParams[FILTER_KEY.HAS_UNOFFICIAL_PUB_DATE],
    [FILTER_KEY.PUB_START_DATE]: reduxState.filterParams[FILTER_KEY.PUB_START_DATE],
    [FILTER_KEY.PUB_END_DATE]: reduxState.filterParams[FILTER_KEY.PUB_END_DATE],
    [FILTER_KEY.ADDED_DATE]: reduxState.filterParams[FILTER_KEY.ADDED_DATE],
    [FILTER_KEY.ADDED_START_DATE]: reduxState.filterParams[FILTER_KEY.ADDED_START_DATE],
    [FILTER_KEY.ADDED_END_DATE]: reduxState.filterParams[FILTER_KEY.ADDED_END_DATE],
    [FILTER_KEY.UPDATED_DATE]: reduxState.filterParams[FILTER_KEY.UPDATED_DATE],
    [FILTER_KEY.UPDATED_START_DATE]: reduxState.filterParams[FILTER_KEY.UPDATED_START_DATE],
    [FILTER_KEY.UPDATED_END_DATE]: reduxState.filterParams[FILTER_KEY.UPDATED_END_DATE],
    [FILTER_KEY.EFFECTIVE_DATE]: reduxState.filterParams[FILTER_KEY.EFFECTIVE_DATE],
    [FILTER_KEY.EFFECTIVE_START_DATE]: reduxState.filterParams[FILTER_KEY.EFFECTIVE_START_DATE],
    [FILTER_KEY.EFFECTIVE_END_DATE]: reduxState.filterParams[FILTER_KEY.EFFECTIVE_END_DATE],
    [FILTER_KEY.COMMENTS_CLOSE_DATE]: reduxState.filterParams[FILTER_KEY.COMMENTS_CLOSE_DATE],
    [FILTER_KEY.COMMENTS_CLOSE_START_DATE]:
      reduxState.filterParams[FILTER_KEY.COMMENTS_CLOSE_START_DATE],
    [FILTER_KEY.COMMENTS_CLOSE_END_DATE]:
      reduxState.filterParams[FILTER_KEY.COMMENTS_CLOSE_END_DATE],
    [FILTER_KEY.CITATION]: reduxState.filterParams[FILTER_KEY.CITATION],
    [FILTER_KEY.LABELS]: reduxState.filterParams[FILTER_KEY.LABELS].map(extractValue),
    [FILTER_KEY.LANGUAGE]: getLanguageValue(reduxState.filterParams[FILTER_KEY.LANGUAGE]),
    [FILTER_KEY.CONCEPTS]: reduxState.filterParams[FILTER_KEY.CONCEPTS].map(extractValue),
    [FILTER_KEY.HAS_EITL_LABELS]: reduxState.filterParams[FILTER_KEY.HAS_EITL_LABELS],
    [FILTER_KEY.EITL_LABELS]: reduxState.filterParams[FILTER_KEY.EITL_LABELS],
    [FILTER_KEY.EXCLUDE_FEDERAL_REGISTER]:
      reduxState.filterParams[FILTER_KEY.EXCLUDE_FEDERAL_REGISTER],
    [FILTER_KEY.DOCUMENT_ID]: reduxState.filterParams[FILTER_KEY.DOCUMENT_ID]
  };

  const advancedSearchQuery = convertToAdvancedSearch(params);

  safe_mixpanel_track(`Search - Advanced Search – Convert to Advanced`, {
    eventCategory: 'Search',
    eventAction: 'Advanced Search Click',
    eventLabel: 'Convert to Advanced'
  });

  navigate(
    `${location.pathname}?${queryString.stringify({
      advanced_search: advancedSearchQuery
    })}`
  );
  reduxActions.closeFilter();
};
