import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Tooltip, TOOLTIP_POSITION, LabeledField } from '@compliance.ai/web-components';
import { isDocTaskBlocked } from 'shared/features/workflow/workflow.helpers';
import './_task-name.scss';

export const TaskName = ({ documentTask }) => {
  return (
    <div className="task-name__container">
      <LabeledField label="Task" isContrast>
        <div className="task-name__tooltip-wrapper">
          <Tooltip
            id="task-name-tooltip"
            title={documentTask.name}
            placement={TOOLTIP_POSITION.BOTTOM}
            wrapperClassName={classnames('task-name__value', {
              'task-name__value--is-disabled': isDocTaskBlocked(documentTask)
            })}
          >
            {documentTask.name}
          </Tooltip>
        </div>
      </LabeledField>
    </div>
  );
};

TaskName.propTypes = {
  documentTask: PropTypes.shape({}).isRequired,
  isDisabled: PropTypes.bool
};
