import type { AddedToComplianceAiProps } from './AddedToComplianceAi.types';

import { useAddedToComplianceAiData } from './hooks';
import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';

export const AddedToComplianceAi = ({ document }: AddedToComplianceAiProps) => {
  const { formattedData } = useAddedToComplianceAiData({ document });

  return (
    <>
      <FieldName>{formattedData.addedLabel}</FieldName>
      <FieldValue>{formattedData.addedValue}</FieldValue>
    </>
  );
};
