import type {
  DocumentsTableReduxState,
  DocumentsTableLocalState
} from './useDocumentsTableData.types';
import type { DocumentsTableProps } from '../DocumentsTable.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from '../DocumentsTable.constants';
import * as sortConstants from 'constants/Sort';
import * as filterConstants from 'constants/PrimaryFilter';

export const getInitialTableParams = ({
  currentUserTableSettings,
  shouldUseSavedTableSettings,
  rowsCountOptions,
  offset = constants.DEFAULT_OFFSET
}: {
  currentUserTableSettings: DocumentsTableReduxState['currentUserTableSettings'];
  shouldUseSavedTableSettings?: DocumentsTableProps['shouldUseSavedTableSettings'];
  rowsCountOptions?: DocumentsTableProps['rowsCountOptions'];
  offset?: number;
}): DocumentsTableLocalState['tableParams'] => {
  const defaultRowsCount = rowsCountOptions?.[0]?.value ?? constants.DEFAULT_ROWS_COUNT;

  if (shouldUseSavedTableSettings && currentUserTableSettings) {
    return {
      [uiLib.TABLE_PARAMS.LIMIT]:
        currentUserTableSettings[uiLib.TABLE_PARAMS.LIMIT] ?? defaultRowsCount,
      [uiLib.TABLE_PARAMS.OFFSET]: offset
    };
  }

  return {
    [uiLib.TABLE_PARAMS.LIMIT]: defaultRowsCount,
    [uiLib.TABLE_PARAMS.OFFSET]: offset
  };
};

export const getInitialSort = (
  queryParams: Record<string, unknown>
): DocumentsTableLocalState['sort'] => {
  return {
    sortType:
      (queryParams[filterConstants.SUPPORTED_QUERY_PARAMS.SEARCH_SORT] as constants.SORT_BY) ||
      constants.SORT_OPTIONS[0].value,
    sortOrder:
      (queryParams[filterConstants.SUPPORTED_QUERY_PARAMS.ORDER] as sortConstants.SORT_ORDER) ||
      sortConstants.SORT_ORDER.DESC
  };
};

export const formatCustomOffsets = (
  customOffsets: Record<string, number | undefined>
): Record<string, string> => {
  const entries = Object.entries(customOffsets);

  return Object.fromEntries(entries.map(([key, value]) => [key, String(value || 0)]));
};
