import type { EitlLabelsSelectProps } from '../EitlLabelsSelect.types';
import type { EitlLabelsSelectData } from './useEitlLabelsSelectData.types';

import { useMemo, useState } from 'react';

export const useEitlLabelsSelectData = ({
  props
}: {
  props: Pick<EitlLabelsSelectProps, 'value'>;
}): EitlLabelsSelectData => {
  const [value, setValue] = useState<EitlLabelsSelectData['localState']['value']>(null);
  const [isLoading, setIsLoading] = useState<EitlLabelsSelectData['localState']['isLoading']>(
    false
  );

  const localState: EitlLabelsSelectData['localState'] = {
    value,
    isLoading
  };

  const localActions: EitlLabelsSelectData['localActions'] = useMemo(
    () => ({
      setValue,
      setIsLoading
    }),
    []
  );

  const formattedData: EitlLabelsSelectData['formattedData'] = useMemo(
    () => ({
      labelsToFetchIds: props.value?.filter(labelId => labelId !== null) ?? [],
      isNoLabelsOptionSelected: Boolean(props.value?.some(labelId => labelId === null))
    }),
    [props.value]
  );

  return {
    localState,
    localActions,
    formattedData
  };
};
