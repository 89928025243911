import type { AlertPreviewBannerHandlers } from './useAlertPreviewBannerHandlers.types';

import { useEffect } from 'react';

export const useAlertPreviewBannerLifecycle = ({
  onAlertCloseOnPathChange
}: {
  onAlertCloseOnPathChange: AlertPreviewBannerHandlers['handleAlertCloseOnPathChange'];
}) => {
  useEffect(onAlertCloseOnPathChange, [onAlertCloseOnPathChange]);
};
