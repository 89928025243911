import type { ExportData } from './useExportData.types';
import type { GlobalState } from 'shared/reducers';

import * as userSelectors from 'shared/features/user/user.selectors';

import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useExportData = (): ExportData => {
  const [documents, setDocuments] = useState<ExportData['localState']['documents']>([]);
  const [downloadStartTime, setDownloadStartTime] = useState<
    ExportData['localState']['downloadStartTime']
  >(null);
  const [isExportModalOpen, setIsExportModalOpen] = useState<
    ExportData['localState']['isExportModalOpen']
  >(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState<
    ExportData['localState']['isDownloadModalOpen']
  >(false);
  const [contentType, setContentType] = useState<ExportData['localState']['contentType']>(null);

  const localState: ExportData['localState'] = useMemo(
    () => ({
      documents,
      downloadStartTime,
      isExportModalOpen,
      isDownloadModalOpen,
      contentType
    }),
    [contentType, documents, downloadStartTime, isDownloadModalOpen, isExportModalOpen]
  );

  const localActions: ExportData['localActions'] = useMemo(
    () => ({
      setDocuments,
      setDownloadStartTime,
      setIsExportModalOpen,
      setIsDownloadModalOpen,
      setContentType
    }),
    []
  );

  const reduxState = useSelector<GlobalState, ExportData['reduxState']>(state => ({
    currentUser: userSelectors.getUser(state),
    bulkExports: userSelectors.getBulkExports(state)
  }));

  return {
    localState,
    localActions,
    reduxState
  };
};
