import type { WorkflowTaskDeadlineProps } from './WorkflowTaskDeadline.types';

import { DatePicker } from '@compliance.ai/web-components';
import { LocalDateFormat } from 'utils/keyDates';
import {
  useWorkflowTaskDeadlineData,
  useWorkflowTaskDeadlineHandlers,
  useWorkflowTaskDeadlineLifecycle
} from './hooks';
import './_workflow-task-deadline.scss';

export const WorkflowTaskDeadline = ({
  closeEditDeadline,
  deadline,
  minDate,
  handleSelectChange
}: WorkflowTaskDeadlineProps) => {
  const { localState, localActions } = useWorkflowTaskDeadlineData({ deadline });

  const handlers = useWorkflowTaskDeadlineHandlers({
    props: {
      deadline,
      handleSelectChange
    },
    localActions
  });

  useWorkflowTaskDeadlineLifecycle({
    onDeadlinePropChange: handlers.handleDeadlinePropChange
  });

  return (
    <DatePicker
      onChange={handlers.handleDeadlineChange}
      value={localState.selectedDeadline}
      format={LocalDateFormat}
      minDate={minDate}
      shouldShowCalendarIcon={false}
      onClickOutside={closeEditDeadline}
      className="workflow-task-deadline__container"
    />
  );
};
