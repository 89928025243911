import type * as apiTypes from './documentFields.api.types';

import { fetch } from 'fetch';

export const fetchOrgDocsMappingFields: apiTypes.FetchOrgDocsMappingFields = async () => {
  return fetch({
    url: '/organizations/organization_documents/fields',
    method: 'GET'
  });
};
