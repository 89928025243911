import type { DocDiffPrintButtonHandlers } from './useDocDiffPrintButtonHandlers.types';
import type { useDocDiff } from 'shared/features/documents/hooks';

import * as analytics from 'utils/analytics';
import * as helpers from './useDocDiffPrintButtonHandlers.helpers';

import { usePrint } from '@compliance.ai/web-components';

export const useDocDiffPrintButtonHandlers = ({
  docsDiff
}: {
  docsDiff: ReturnType<typeof useDocDiff>;
}): DocDiffPrintButtonHandlers => {
  const print = usePrint({
    bodyClass: 'document-workspace__pdf-body',
    copyStyles: true,
    documentTitle: `Document changes`,
    content: helpers.getPrintContent,
    pageStyle: `
      @page {
        margin: 0;
        padding: 0;
      }
    `
  });

  const handlePrint: DocDiffPrintButtonHandlers['handlePrint'] = () => {
    analytics.safe_analytics('Doc Details-Print Changes', 'Doc Details', 'Print Changes');
    if (!docsDiff.diff) {
      return;
    }
    print();
  };

  return {
    handlePrint
  };
};
