import { useMemo } from 'react';
import { components } from 'react-select-3';
import { Tooltip } from 'common';

export const MultiValueLabel = props => {
  const { withTooltips, tooltipTitleRenderer } = props;

  const content = useMemo(() => {
    if (withTooltips) {
      return (
        <Tooltip title={tooltipTitleRenderer ? tooltipTitleRenderer(props) : props.data.label}>
          {props.children}
        </Tooltip>
      );
    } else {
      return <>{props.children}</>;
    }
  }, [props, withTooltips, tooltipTitleRenderer]);

  return <components.MultiValueLabel {...props}>{content}</components.MultiValueLabel>;
};
