import { api_fetchTags, api_createTag } from './tags.api';
import { getUserAuthenticatedState } from '../auth/auth.selectors';

export const REQUEST_TAGS = 'REQUEST_TAGS';
export const RECEIVE_TAGS = 'RECEIVE_TAGS';
export const CREATE_NEW_TAG = 'CREATE_NEW_TAG';
export const CREATED_NEW_TAG = 'CREATED_NEW_TAG';

function requestTags() {
  return {
    type: REQUEST_TAGS
  };
}

function receiveTags(json) {
  return {
    type: RECEIVE_TAGS,
    response: json
  };
}

function createNewTag(tag_name) {
  return {
    type: CREATE_NEW_TAG,
    tag_name
  };
}

function createdNewTag(tag_name) {
  return {
    type: CREATED_NEW_TAG,
    tag_name
  };
}

export const fetchTags = () => async (dispatch, getState) => {
  const isAuthenticated = getUserAuthenticatedState(getState());

  if (!isAuthenticated) {
    return Promise.resolve();
  }

  dispatch(requestTags());

  const response = await api_fetchTags();

  dispatch(receiveTags(response));

  return response;
};

export function createTag(tag_name) {
  return function dopost(dispatch) {
    dispatch(createNewTag(tag_name));
    return api_createTag(tag_name).then(response => {
      dispatch(createdNewTag(tag_name, response));
      return response;
    });
  };
}
