import type { DeleteRelatedDocumentsModalProps } from '../DeleteRelatedDocumentsModal.types';
import type { DeleteRelatedDocumentsModalData } from './useDeleteRelatedDocumentsModalData.types';

import * as documentsApi from 'shared/features/documents/documents.api';
import * as relatedDocsConstants from 'constants/RelatedDocuments';
import * as queryKeys from 'constants/QueryKeys';
import * as errorUtils from 'utils/errors';

import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useDeleteRelatedDocumentsModalMutations = ({
  props,
  localState
}: {
  props: Pick<DeleteRelatedDocumentsModalProps, 'documentId' | 'onClose'>;
  localState: DeleteRelatedDocumentsModalData['localState'];
}) => {
  const queryClient = useQueryClient();

  const deleteRelatedDocument = useMutation<void, Error, void>({
    mutationFn: () =>
      documentsApi.api_deleteOrganizationRelatedDocuments(props.documentId, {
        [relatedDocsConstants.RELATED_DOCUMENT_REQUEST_KEYS.RELATED_DOC_IDS]:
          localState.relatedDocIds
      }),
    onError: errorUtils.logReactQueryError,
    onSettled: props.onClose,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.COMPLEX_QUERY_KEY_GETTERS[queryKeys.QUERY_KEYS.DOCUMENTS][
          queryKeys.QUERY_SECTIONS.DOCUMENT_DETAILS
        ](Number(props.documentId))
      });
    }
  });

  return {
    deleteRelatedDocument
  };
};
