import {
  Tooltip,
  TOOLTIP_POSITION,
  TOOLTIP_TYPES,
  IconAdd,
  IconRemove
} from '@compliance.ai/web-components';
import styled from 'styled-components';

const ButtonWrapper = styled.span`
  max-width: 20px;
  cursor: pointer;
`;

export const Button = ({ type, onClick }) => {
  const addButton = (
    <ButtonWrapper>
      <Tooltip
        type={TOOLTIP_TYPES.SECONDARY}
        placement={TOOLTIP_POSITION.TOP}
        title={`Add filter${type === 'addGroup' ? ' group' : ''}`}
      >
        <IconAdd className="advanced-search-builder-groupbutton" onClick={onClick} />
      </Tooltip>
    </ButtonWrapper>
  );

  const deleteButton = (
    <ButtonWrapper>
      <Tooltip
        type={TOOLTIP_TYPES.SECONDARY}
        placement={TOOLTIP_POSITION.TOP}
        title={`Remove filter${type === 'delGroup' ? ' group' : ''}`}
      >
        <IconRemove className="advanced-search-builder-trashcan" onClick={onClick} />
      </Tooltip>
    </ButtonWrapper>
  );

  const typeConverstion = {
    delRule: deleteButton,
    delGroup: deleteButton,
    addRule: addButton,
    addGroup: addButton
  };

  return typeConverstion[type];
};
