import type { DeleteRelatedDocumentsModalProps } from './DeleteRelatedDocumentsModal.types';
import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as common from 'common/index';
import * as constants from './DeleteRelatedDocumentsModal.constants';

import {
  useDeleteRelatedDocumentsModalData,
  useDeleteRelatedDocumentsModalHandlers,
  useDeleteRelatedDocumentsModalMutations
} from './hooks';

import './_delete-related-documents-modal.scss';

export const DeleteRelatedDocumentsModal: FC<DeleteRelatedDocumentsModalProps> = ({
  documentId,
  documentTitle,
  onClose
}) => {
  const {
    localState,
    localActions,
    formattedData,
    reduxState
  } = useDeleteRelatedDocumentsModalData({
    props: {
      documentId
    }
  });

  const handlers = useDeleteRelatedDocumentsModalHandlers({
    localActions
  });

  const mutations = useDeleteRelatedDocumentsModalMutations({
    props: {
      documentId,
      onClose
    },
    localState
  });

  return (
    <uiLib.Modal
      isOpen
      title={`Documents Related to "${documentTitle}"`}
      onClose={onClose}
      primaryButtonText="Remove Related Docs"
      withFooter
      onSubmit={mutations.deleteRelatedDocument.mutate}
      className="delete-related-documents-modal"
      childContainerClassName="delete-related-documents-modal__children-container"
      footerClassName="delete-related-documents-modal__footer"
      isPrimaryButtonDisabled={reduxState.isFetching || !localState.relatedDocIds.length}
    >
      <div className="delete-related-documents-modal__content">
        <uiLib.ErrorBoundary>
          <common.DocumentsFilters
            onSubmit={handlers.handleFiltersChange}
            shouldDisableDropdownsPortal
            classNames={{
              container: 'delete-related-documents-modal__filters'
            }}
          />
          <common.DocumentsTable
            shouldUseSavedTableSettings
            tableSettingsKey={constants.TABLE_SETTINGS_KEY}
            requestParams={formattedData.requestParams}
            onSelectedDocumentsChange={handlers.handleSelectedDocumentsChange}
            shouldDisableDropdownsPortal
            classNames={{
              container: 'delete-related-documents-modal__table-outer-container',
              tableContainer: 'delete-related-documents-modal__table-container',
              table: 'delete-related-documents-modal__table'
            }}
          />
        </uiLib.ErrorBoundary>
      </div>
    </uiLib.Modal>
  );
};
