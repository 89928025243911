import { safe_mixpanel_track } from 'utils/analytics';
import { store } from 'store';
import { DOCUMENT_CATEGORIES } from 'constants/DocumentConstants';
import lodash from 'lodash';
import { RESTRICTED_ACTIONS } from 'constants/Restrictions';
import { getDocumentUrl } from './documentProperties';

export const userHasDocumentAccess = ({ document }) => {
  const user = store.getState().current_user.user;
  return Boolean(user && user.pc_docs_access && lodash.get(user.pc_docs_access, document?.id));
};

const userHasProviderAccess = ({ document }) => {
  const user = store.getState().current_user.user;
  const news_source_id =
    document &&
    document.mainstream_news &&
    document.mainstream_news.news_source &&
    document.mainstream_news.news_source.id;

  if (!news_source_id) return false;

  const entry =
    user &&
    user.premium_provider_access &&
    user.premium_provider_access.filter(providerAccess => {
      return providerAccess.premium_content_source_news_source_id === news_source_id;
    });

  return Boolean(entry && entry.length);
};

const documentIsRestricted = ({ document }) => {
  return Boolean(
    document && document.content_authorization && document.content_authorization.privacy_restricted
  );
};

export const documentIsPremiumContent = ({ document }) => {
  return Boolean(
    document &&
      document.mainstream_news &&
      document.mainstream_news.news_source &&
      document.mainstream_news.news_source.premium_content_provider
  );
};

const getPcSource = ({ document }) => {
  const pcSources = store.getState().premiumContent.pcSources;

  const news_source_id =
    document &&
    document.mainstream_news &&
    document.mainstream_news.news_source &&
    document.mainstream_news.news_source.id;

  if (!news_source_id) return false;

  return pcSources.find(source => {
    return source.news_source_id === news_source_id;
  });
};

export const restrictedOrPremiumDocument = ({ document }) => {
  const premiumDocument = documentIsPremiumContent({ document });
  const restrictedDocument = documentIsRestricted({ document });

  return Boolean(premiumDocument || restrictedDocument);
};

export const checkPDFAvailability = document => {
  const documentCategory = lodash.get(document, 'category');
  const documentContentPercentage = lodash.get(
    document,
    'content_qualification.content_percentage'
  );

  const userHasAccess = userCanAccessDocument({ document });
  const canView = getPcSourceRestriction({ document, action: RESTRICTED_ACTIONS.VIEW });

  const isMainstreamNews =
    documentCategory === DOCUMENT_CATEGORIES.MAINSTREAM_NEWS || documentContentPercentage;

  return !isMainstreamNews && userHasAccess && canView;
};

export const checkSourceLinkAvailability = document => {
  const documentUrl = getDocumentUrl(document);
  const userHasAccess = userCanAccessDocument({ document });
  return userHasAccess && Boolean(documentUrl?.match(/^http/));
};

export const triggerPremiumContentMixpanel = (email, document) => {
  const documentUrl = getDocumentUrl(document);
  const baseUrl = new URL(documentUrl).hostname;
  safe_mixpanel_track(`Outbound Traffic ${baseUrl}`, {
    eventCategory: 'Outbound Traffic',
    eventLabel: baseUrl,
    user: email,
    full_url: documentUrl
  });
};

export const checkDocumentLevelUserAccess = ({ document }) => {
  return Boolean(document?.user_has_access);
};

export const getDocumentRestrictionType = ({ document }) => {
  return document?.restriction_type;
};

export const userCanAccessDocument = ({ document }) => {
  const documentLevelUserAccess = checkDocumentLevelUserAccess({ document });
  if (documentLevelUserAccess) return true;

  const documentAccess = userHasDocumentAccess({ document });
  const providerAccess = userHasProviderAccess({ document });

  return Boolean(documentAccess || providerAccess);
};

export const getPcSourceRestriction = ({ document, action }) => {
  const isPremiumContent = documentIsPremiumContent({ document });

  if (!isPremiumContent) return true;

  const pcSource = getPcSource({ document });

  if (!pcSource) return false;

  const userHasAccess = userCanAccessDocument({ document });

  const docIsChild = Boolean(document.parent_id || document.parent);
  const restriction = pcSource.restrictions.find(
    r => r.user_has_access === userHasAccess && r.child_restriction === docIsChild
  );

  if (!restriction) return true;
  if (!restriction.restrictions) return true;

  return Boolean(restriction.restrictions[action]);
};

export const getSkuRestriction = ({ document, action }) => {
  const userHasAccess = checkDocumentLevelUserAccess({ document });
  if (userHasAccess) return true;

  //At the time of implementation if a user can't access a document
  //any type of action is restricted, this might not be true in the future
  return false;
};

export const getDocumentRestriction = ({ document, action }) => {
  const pcSourceRestriction = getPcSourceRestriction({ document, action });
  const skuRestriction = getSkuRestriction({ document, action });

  return Boolean(pcSourceRestriction || skuRestriction);
};
