import type { GlobalState } from 'shared/reducers';
import type {
  FirstLoginJoyrideData,
  FirstLoginJoyrideLocalState
} from './useFirstLoginExperienceData.types';

import * as permissionConstants from 'constants/Permissions';
import * as userSelectors from 'shared/features/user/user.selectors';
import * as authSelectors from 'shared/features/auth/auth.selectors';
import * as organizationProfileSelectors from 'shared/features/organizationProfile/organizationProfile.selectors';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePermissions } from 'utils/hooks';

export const useFirstLoginJoyrideData = (): FirstLoginJoyrideData => {
  const [isInitialized, setIsInitialized] = useState<FirstLoginJoyrideLocalState['isInitialized']>(
    false
  );
  const [hasBeenRedirectedToAdmin, setHasBeenRedirectedToAdmin] = useState<
    FirstLoginJoyrideLocalState['hasBeenRedirectedToAdmin']
  >(false);

  const permissions = usePermissions();

  const localState: FirstLoginJoyrideData['localState'] = {
    isInitialized,
    hasBeenRedirectedToAdmin,
    hasManageOrgProfilePermission: permissions[permissionConstants.MANAGE_ORG_PROFILE]
  };

  const localActions: FirstLoginJoyrideData['localActions'] = useMemo(
    () => ({
      setIsInitialized,
      setHasBeenRedirectedToAdmin
    }),
    []
  );

  const reduxState: FirstLoginJoyrideData['reduxState'] = useSelector<
    GlobalState,
    FirstLoginJoyrideData['reduxState']
  >(state => ({
    currentUser: userSelectors.getUser(state),
    isAuthenticated: authSelectors.getUserAuthenticatedState(state),
    isFirstLogin: userSelectors.getIsFirstLogin(state),
    isOrgProfileSaved: organizationProfileSelectors.getIsOrgProfileSaved(state)
  }));

  return {
    localState,
    localActions,
    reduxState
  };
};
