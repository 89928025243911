import {
  SET_PRIMARY_FILTER,
  SET_ENFORCEMENT_FILTER,
  SET_JURISDICTION_FILTER,
  SET_ACTION_BAR_FILTER,
  RESET_PRIMARY_FILTER,
  SET_PRIMARY_FILTER_DEFAULT_VIEW,
  SET_PRIMARY_FILTER_ERRORS,
  TOGGLE_PRIMARY_FILTER_LOADING_STATE,
  TOGGLE_PRIMARY_FILTER_READY_STATE,
  CHANGE_FILTER,
  CLEAR_PRIMARY_FILTER,
  SET_JURISDICTION_FILTER_ERRORS,
  TOGGLE_JURISDICTION_FILTER_DEFAULT_FILTERS,
  RESET_JURISDICTION_FILTER,
  CLEAR_JURISDICTION_FILTER
} from './filters.actions';
import {
  FILTER_KEY as PRIMARY_FILTER_KEY,
  ERROR_KEY as PRIMARY_FILTER_ERROR_KEY,
  EXACT_MATCH_FILTER_OPTION
} from 'constants/PrimaryFilter';
import { FILTER_KEY as JA_FILTER_KEY } from 'constants/JurisdictionAnalyzer';
import { FILTER_KEY as ACTION_BAR_FILTER_KEY } from 'constants/ActionBarFilter';
import { FILTERS } from 'constants/Filters';
import { DOCUMENT_SUMMARY_PAGE_KEY } from 'constants/DocumentConstants';
import { ROW_COUNT_OPTIONS } from 'constants/ActionBar';

export const FILTER_INITIAL_STATE = {
  currentFilter: FILTERS.PRIMARY,
  primaryFilter: {
    filterUrl: null,
    isReady: false,
    filterParams: {
      [PRIMARY_FILTER_KEY.PUB_DATE]: null,
      [PRIMARY_FILTER_KEY.HAS_UNOFFICIAL_PUB_DATE]: false,
      [PRIMARY_FILTER_KEY.PUB_START_DATE]: null,
      [PRIMARY_FILTER_KEY.PUB_END_DATE]: null,
      [PRIMARY_FILTER_KEY.ADDED_DATE]: null,
      [PRIMARY_FILTER_KEY.ADDED_START_DATE]: null,
      [PRIMARY_FILTER_KEY.ADDED_END_DATE]: null,
      [PRIMARY_FILTER_KEY.UPDATED_DATE]: null,
      [PRIMARY_FILTER_KEY.UPDATED_START_DATE]: null,
      [PRIMARY_FILTER_KEY.UPDATED_END_DATE]: null,
      [PRIMARY_FILTER_KEY.EFFECTIVE_DATE]: null,
      [PRIMARY_FILTER_KEY.EFFECTIVE_START_DATE]: null,
      [PRIMARY_FILTER_KEY.EFFECTIVE_END_DATE]: null,
      [PRIMARY_FILTER_KEY.COMMENTS_CLOSE_DATE]: null,
      [PRIMARY_FILTER_KEY.COMMENTS_CLOSE_START_DATE]: null,
      [PRIMARY_FILTER_KEY.COMMENTS_CLOSE_END_DATE]: null,
      [PRIMARY_FILTER_KEY.AGENCIES]: [],
      [PRIMARY_FILTER_KEY.JURISDICTIONS]: [],
      [PRIMARY_FILTER_KEY.MAINSTREAM_NEWS_SOURCES]: [],
      [PRIMARY_FILTER_KEY.TOPICS]: [],
      [PRIMARY_FILTER_KEY.CONCEPTS]: [],
      [PRIMARY_FILTER_KEY.DOC_PROPERTIES]: [],
      [PRIMARY_FILTER_KEY.DOC_TYPES]: [],
      [PRIMARY_FILTER_KEY.REGULATIONS]: [],
      [PRIMARY_FILTER_KEY.ACTS]: [],
      [PRIMARY_FILTER_KEY.BANKS]: [],
      [PRIMARY_FILTER_KEY.LABELS]: [],
      [PRIMARY_FILTER_KEY.LANGUAGE]: null,
      [PRIMARY_FILTER_KEY.CITATION]: '',
      [PRIMARY_FILTER_KEY.TITLE]: '',
      [PRIMARY_FILTER_KEY.TEXT]: '',
      [PRIMARY_FILTER_KEY.TEXT_MATCH_TYPES]: EXACT_MATCH_FILTER_OPTION,
      [PRIMARY_FILTER_KEY.TITLE_MATCH_TYPES]: EXACT_MATCH_FILTER_OPTION,
      [PRIMARY_FILTER_KEY.AUTHOR]: [],
      [PRIMARY_FILTER_KEY.HAS_EITL_LABELS]: null,
      [PRIMARY_FILTER_KEY.EITL_LABELS]: [],
      [PRIMARY_FILTER_KEY.DOCUMENT_ID]: undefined
    },
    errors: {
      [PRIMARY_FILTER_ERROR_KEY.PUB_DATE]: null,
      [PRIMARY_FILTER_ERROR_KEY.PUB_START_DATE]: null,
      [PRIMARY_FILTER_ERROR_KEY.PUB_END_DATE]: null,
      [PRIMARY_FILTER_ERROR_KEY.PUB_DATE_ORDER]: null,
      [PRIMARY_FILTER_ERROR_KEY.ADDED_DATE]: null,
      [PRIMARY_FILTER_ERROR_KEY.ADDED_START_DATE]: null,
      [PRIMARY_FILTER_ERROR_KEY.ADDED_END_DATE]: null,
      [PRIMARY_FILTER_ERROR_KEY.ADDED_DATE_ORDER]: null,
      [PRIMARY_FILTER_ERROR_KEY.UPDATED_DATE]: null,
      [PRIMARY_FILTER_ERROR_KEY.UPDATED_START_DATE]: null,
      [PRIMARY_FILTER_ERROR_KEY.UPDATED_END_DATE]: null,
      [PRIMARY_FILTER_ERROR_KEY.UPDATED_DATE_ORDER]: null,
      [PRIMARY_FILTER_ERROR_KEY.EFFECTIVE_DATE]: null,
      [PRIMARY_FILTER_ERROR_KEY.EFFECTIVE_START_DATE]: null,
      [PRIMARY_FILTER_ERROR_KEY.EFFECTIVE_END_DATE]: null,
      [PRIMARY_FILTER_ERROR_KEY.EFFECTIVE_DATE_ORDER]: null,
      [PRIMARY_FILTER_ERROR_KEY.COMMENTS_CLOSE_DATE]: null,
      [PRIMARY_FILTER_ERROR_KEY.COMMENTS_CLOSE_START_DATE]: null,
      [PRIMARY_FILTER_ERROR_KEY.COMMENTS_CLOSE_END_DATE]: null,
      [PRIMARY_FILTER_ERROR_KEY.COMMENTS_CLOSE_DATE_ORDER]: null
    },
    loadingParams: {
      [PRIMARY_FILTER_KEY.ACTS]: false,
      [PRIMARY_FILTER_KEY.BANKS]: false
    },
    defaultView: null
  },
  enforcementFilter: {
    filterUrl: null,
    filterParams: null,
    defaultFilters: false
  },
  jurisdictionFilter: {
    filterParams: {
      [JA_FILTER_KEY.SEARCH_QUERY]: '',
      [JA_FILTER_KEY.JURISDICTION]: [],
      [JA_FILTER_KEY.TOPIC_ID]: [],
      [JA_FILTER_KEY.CATEGORY]: [],
      [JA_FILTER_KEY.REGULATION_ID]: [],
      [JA_FILTER_KEY.ACT_ID]: [],
      [JA_FILTER_KEY.LANGUAGE_ID]: null,
      [JA_FILTER_KEY.PUBLISHED_FROM]: null,
      [JA_FILTER_KEY.PUBLISHED_TO]: null,
      [JA_FILTER_KEY.HAS_UNOFFICIAL_PUBLICATION_DATE]: false,
      [JA_FILTER_KEY.ORDER]: 'desc',
      [JA_FILTER_KEY.SEARCH_SORT]: 'publication_date',
      [JA_FILTER_KEY.SUMMARY_PAGE]: DOCUMENT_SUMMARY_PAGE_KEY,
      [JA_FILTER_KEY.SKIP_JA_FIELDS]: true,
      [JA_FILTER_KEY.CONCEPT_ID]: null
    },
    errors: {
      [JA_FILTER_KEY.PUBLISHED_FROM]: null,
      [JA_FILTER_KEY.PUBLISHED_TO]: null
    },
    defaultFilters: false
  },
  actionBarFilter: {
    filterParams: {
      [ACTION_BAR_FILTER_KEY.ROWS_COUNT]: ROW_COUNT_OPTIONS[0]
    }
  }
};

export const filters = (state = FILTER_INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_FILTER:
      return {
        ...state,
        currentFilter: action.payload.currentFilter
      };

    case TOGGLE_PRIMARY_FILTER_READY_STATE:
      return {
        ...state,
        primaryFilter: {
          ...state.primaryFilter,
          isReady: action.payload.isReady
        }
      };

    case SET_PRIMARY_FILTER:
      return {
        ...state,
        primaryFilter: {
          ...state.primaryFilter,
          filterParams: {
            ...state.primaryFilter.filterParams,
            ...action.payload.filterParams
          }
        }
      };

    case RESET_PRIMARY_FILTER:
      return {
        ...state,
        primaryFilter: FILTER_INITIAL_STATE.primaryFilter
      };

    case SET_PRIMARY_FILTER_DEFAULT_VIEW:
      return {
        ...state,
        primaryFilter: {
          ...state.primaryFilter,
          defaultView: action.payload.view
        }
      };

    case SET_PRIMARY_FILTER_ERRORS:
      return {
        ...state,
        primaryFilter: {
          ...state.primaryFilter,
          errors: {
            ...state.primaryFilter.errors,
            ...action.payload.errors
          }
        }
      };

    case CLEAR_PRIMARY_FILTER:
      return {
        ...state,
        primaryFilter: {
          ...state.primaryFilter,
          filterParams: FILTER_INITIAL_STATE.primaryFilter.filterParams
        }
      };

    case TOGGLE_PRIMARY_FILTER_LOADING_STATE:
      return {
        ...state,
        primaryFilter: {
          ...state.primaryFilter,
          loadingParams: {
            ...state.primaryFilter.loadingParams,
            [action.payload.filterKey]: action.payload.isLoading
          }
        }
      };

    case SET_ENFORCEMENT_FILTER:
      return {
        ...state,
        enforcementFilter: action.payload
      };

    case SET_JURISDICTION_FILTER:
      return {
        ...state,
        jurisdictionFilter: {
          ...state.jurisdictionFilter,
          filterParams: {
            ...state.jurisdictionFilter.filterParams,
            ...action.payload.filterParams
          }
        }
      };

    case SET_JURISDICTION_FILTER_ERRORS:
      return {
        ...state,
        jurisdictionFilter: {
          ...state.jurisdictionFilter,
          errors: {
            ...state.jurisdictionFilter.errors,
            ...action.payload.errors
          }
        }
      };

    case TOGGLE_JURISDICTION_FILTER_DEFAULT_FILTERS:
      return {
        ...state,
        jurisdictionFilter: {
          ...state.jurisdictionFilter,
          defaultFilters: action.payload.isToggledOn
        }
      };

    case RESET_JURISDICTION_FILTER:
      return {
        ...state,
        jurisdictionFilter: FILTER_INITIAL_STATE.jurisdictionFilter
      };

    case CLEAR_JURISDICTION_FILTER:
      return {
        ...state,
        jurisdictionFilter: {
          ...state.jurisdictionFilter,
          filterParams: {
            ...FILTER_INITIAL_STATE.jurisdictionFilter.filterParams,
            [JA_FILTER_KEY.CATEGORY]: []
          }
        }
      };

    case SET_ACTION_BAR_FILTER:
      return {
        ...state,
        actionBarFilter: {
          ...state.actionBarFilter,
          filterParams: {
            ...state.actionBarFilter.filterParams,
            ...action.payload.filterParams
          }
        }
      };

    default:
      return state;
  }
};
