import axios from 'axios';
import {
  getParamsSerializer,
  getRequestHeaders,
  getRequestParams
} from './fetch.interceptors.helpers';

export const onRequestStart = ({ fetchConfig = {}, isPureAxiosRequest = true, ...axiosConfig }) => {
  if (isPureAxiosRequest) {
    return axiosConfig;
  }

  const {
    params,
    authenticationType,
    customToken,
    baseApi,
    contentType,
    headers,
    shouldDisableQueryParamsArrayFormatting,
    ...restConfig
  } = fetchConfig;

  const requestParams = getRequestParams(
    axiosConfig.method,
    params || axiosConfig.params,
    axiosConfig.url
  );
  const requestHeaders = getRequestHeaders({
    headers,
    contentType,
    baseApi,
    customToken,
    authenticationType,
    requestParams
  });

  const paramsSerializer = getParamsSerializer({
    shouldDisableQueryParamsArrayFormatting
  });

  const formattedAxiosConfig = {
    headers: requestHeaders,
    paramsSerializer,
    ...requestParams,
    ...restConfig
  };

  return {
    ...axiosConfig,
    ...formattedAxiosConfig
  };
};

export const onResponseSuccess = response => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data

  /**
    Response Schema
    The response for a request contains the following information.

    {
      // `data` is the response that was provided by the server
      data: {},

      // `status` is the HTTP status code from the server response
      status: 200,

      // `statusText` is the HTTP status message from the server response
      statusText: 'OK',

      // `headers` the HTTP headers that the server responded with
      // All header names are lower cased and can be accessed using the bracket notation.
      // Example: `response.headers['content-type']`
      headers: {},

      // `config` is the config that was provided to `axios` for the request
      config: {},

      // `request` is the request that generated this response
      // It is the last ClientRequest instance in node.js (in redirects)
      // and an XMLHttpRequest instance in the browser
      request: {}
    }
   */
  return response.data;
};

export const onResponseError = error => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
};

export const initializeInterceptors = () => {
  axios.interceptors.request.use(onRequestStart);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};
