import type { TextBoxProps } from '../TextBox.types';

import { logError } from 'utils/errors';
import { ALERT_TYPES, showNotification } from '@compliance.ai/web-components';
import { ALERT_DURATION } from '../TextBox.constants';

export const useTextBoxHandlers = ({ props }: { props: Pick<TextBoxProps, 'value'> }) => {
  const handleCopy = async () => {
    try {
      if (props.value) {
        await navigator.clipboard.writeText(props.value);

        showNotification({
          type: ALERT_TYPES.INFO,
          message: 'Text was copied to clipboard',
          duration: ALERT_DURATION
        });
      }
    } catch (e) {
      logError(e as Error, 'Error', { isSilent: true });
    }
  };

  return {
    handleCopy
  };
};
