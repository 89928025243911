import * as routeConstants from 'constants/Routes';
import * as firstLoginConstants from 'constants/FirstLogin';

export const FIRST_LOGIN_JOYRIDE_CARDS = {
  [routeConstants.admin]: {
    id: 'adminNav',
    target: `#${firstLoginConstants.JOYRIDE_TARGET_IDS.ACCOUNT_MENU}`,
    content: (
      <span>
        Edit your organization and teams settings using <strong>Admin</strong> page accessible
        through this menu.
      </span>
    ),
    placement: 'left',
    disableBeacon: true,
    order: 0
  },
  [routeConstants.sources]: {
    id: 'defaultFilters',
    target: `#${firstLoginConstants.JOYRIDE_TARGET_IDS.FILTERS_TAB}`,
    content: 'Configure your own default filters here.',
    placement: 'right',
    offset: '4',
    disableBeacon: true,
    order: 1
  },
  [routeConstants.account]: {
    id: 'userProfile',
    target: `#${firstLoginConstants.JOYRIDE_TARGET_IDS.ACCOUNT_MENU}`,
    content: (
      <span>
        Set up your Profile, including name and email preferences, using
        <strong>{' Account '}</strong>
        page accessible through this menu.
      </span>
    ),
    placement: 'left',
    disableBeacon: true,
    order: 2
  }
};

export const FIRST_LOGIN_JOYRIDE = {
  ADMIN: [
    FIRST_LOGIN_JOYRIDE_CARDS[routeConstants.admin],
    FIRST_LOGIN_JOYRIDE_CARDS[routeConstants.sources],
    FIRST_LOGIN_JOYRIDE_CARDS[routeConstants.account]
  ],
  BASE_ADMIN: [
    FIRST_LOGIN_JOYRIDE_CARDS[routeConstants.admin],
    FIRST_LOGIN_JOYRIDE_CARDS[routeConstants.account]
  ],
  ADMIN_NO_PROFILE: [FIRST_LOGIN_JOYRIDE_CARDS[routeConstants.account]],
  GENERIC_USER: [
    FIRST_LOGIN_JOYRIDE_CARDS[routeConstants.sources],
    FIRST_LOGIN_JOYRIDE_CARDS[routeConstants.account]
  ],
  EMPTY: []
};
