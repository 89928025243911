import DatePicker from 'react-datepicker';
import { FilterLabeledElement } from 'common';
import { LocalDateFormat } from 'utils/keyDates';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { useDidMount } from 'utils/hooks';
import './_date-filter.scss';

const DatePickers = ({
  startDate,
  endDate,
  onError,
  onChange,
  errors,
  areDatesRequired,
  startDateKey,
  endDateKey,
  dateOrderErrorKey,
  label,
  customLabelElements,
  subject
}) => {
  useDidMount(() => {
    changeStartDate(startDate);
    changeEndDate(endDate);
  });

  const startDateError = errors[startDateKey];
  const endDateError = errors[endDateKey];

  // String is invoked when user enters raw input
  const parseDateFromString = date => {
    const localDate = moment(date, LocalDateFormat, true);

    if (localDate.isValid()) {
      return localDate;
    }

    const shortFormat = LocalDateFormat === 'MM/DD/YYYY' ? 'M/D/YYYY' : 'D/M/YYYY';
    const shortDate = moment(date, shortFormat, true);
    if (shortDate.isValid()) {
      return shortDate;
    }

    return null;
  };

  const changeStartDate = value => {
    // Reset errors
    onError(startDateKey, undefined);
    onError(dateOrderErrorKey, undefined);

    let date = value;
    // If it's a string then try and parse into a date
    if (typeof value === 'string') {
      date = parseDateFromString(value);
      if (!date) {
        onChange(startDateKey, date);
        onError(startDateKey, `${subject} start date is invalid`);
        return;
      }
    }

    if (!date) {
      if (areDatesRequired) {
        onError(startDateKey, `${subject} start date is required to search`);
      }
    } else {
      // Compare the dates
      if (date.isAfter(endDate)) {
        onError(dateOrderErrorKey, `${subject} start date must be earlier than end date`);
      }
    }

    onChange(startDateKey, date);
  };

  const changeEndDate = value => {
    // Reset errors
    onError(endDateKey, undefined);
    onError(dateOrderErrorKey, undefined);

    let date = value;
    // If it's a string then try and parse into a date
    if (typeof value === 'string') {
      date = parseDateFromString(value);

      if (!date) {
        onChange(endDateKey, date);
        onError(endDateKey, `${subject} end date is invalid`);
        return;
      }
    }

    if (date && date.isBefore(startDate)) {
      onError(dateOrderErrorKey, `${subject} start date must be earlier than end date`);
    } else {
      onError(dateOrderErrorKey, undefined);
    }

    onError(endDateKey, undefined);
    onChange(endDateKey, date);
  };

  return (
    <FilterLabeledElement
      label={label}
      isBold
      labelClassName="date-filter__container-label"
      customLabelElements={customLabelElements}
    >
      <div className="date-filter__container">
        <div className="date-filter__picker-wrapper">
          <label className="date-filter__picker-label">From:</label>
          <DatePicker
            selected={startDate}
            dateFormat={LocalDateFormat}
            onChange={date => changeStartDate(date)}
            onChangeRaw={event => changeStartDate(event.target.value)}
            className={classnames('date-filter__picker', {
              'date-filter__picker--is-invalid': startDateError
            })}
            placeholder="from"
          />
        </div>
        <div className="date-filter__picker-wrapper">
          <label className="date-filter__picker-label">To:</label>
          <DatePicker
            selected={endDate}
            dateFormat={LocalDateFormat}
            onChange={date => changeEndDate(date)}
            onChangeRaw={event => changeEndDate(event.target.value)}
            className={classnames('date-filter__picker', {
              'date-filter__picker--is-invalid': endDateError
            })}
            placeholder="to"
          />
        </div>
      </div>
    </FilterLabeledElement>
  );
};

export default DatePickers;

DatePickers.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  areDatesRequired: PropTypes.bool,
  startDateKey: PropTypes.string.isRequired,
  endDateKey: PropTypes.string.isRequired,
  dateOrderErrorKey: PropTypes.string.isRequired,
  label: PropTypes.string,
  customLabelElements: PropTypes.element,
  subject: PropTypes.string
};

DatePickers.defaultProps = {
  areDatesRequired: true,
  label: 'Publication Date:',
  subject: ''
};
