import type { AddToWorkflowProps } from './AddToWorkflow.types';
import type { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './AddToWorkflow.constants';
import * as elements from './elements';

import { useAddToWorkflowData, useAddToWorkflowQueries, useAddToWorkflowHandlers } from './hooks';

export const AddToWorkflow: FC<AddToWorkflowProps> = ({ documentIds, onActionStart }) => {
  const { localState, localActions, formattedData } = useAddToWorkflowData({ documentIds });

  const queries = useAddToWorkflowQueries({
    props: {
      documentIds
    },
    localActions
  });

  const handlers = useAddToWorkflowHandlers({
    localActions,
    queries
  });

  return (
    <>
      <uiLib.ActionButton
        isDisabled={formattedData.isDisabled}
        isRestricted={formattedData.isRestricted}
        isLoading={queries.fetchDocuments.isFetching}
        onClick={handlers.handleClick}
        onRestrictedClick={handlers.handleRestrictedClick}
        tooltipPlacement={uiLib.TOOLTIP_POSITION.BOTTOM}
        tooltip={
          !formattedData.areAllDocumentsSelected
            ? 'Add to workflow'
            : `Add a workflow to up to ${constants.MAX_DOCUMENTS_COUNT} documents.`
        }
      >
        <uiLib.IconAddWorkflow />
      </uiLib.ActionButton>
      {queries.fetchDocuments.data && localState.isModalOpen && (
        <elements.WorkflowModal
          isOpen={localState.isModalOpen}
          documents={queries.fetchDocuments.data.documents}
          onClose={handlers.handleModalClose}
          onActionStart={onActionStart}
        />
      )}
    </>
  );
};
