import type { FilterValue } from '@compliance.ai/web-components';
import type { AutocompleteResultFromResponse } from 'shared/features/search/search.types';

import * as uiLib from '@compliance.ai/web-components';

import { FILTER_KEY } from 'constants/PrimaryFilter';

export const TEXT_MODIFIERS_TO_QUERY_PARAMS = {
  [uiLib.TEXT_MODIFIERS.CLOSE_MATCH]: 'close_match',
  [uiLib.TEXT_MODIFIERS.EXACT_MATCH]: 'exact_match'
} as Record<uiLib.TEXT_MODIFIERS, string>;

export const DOCUMENTS_FILTERS_DEFAULT_VALUES: Partial<Record<FILTER_KEY, FilterValue>> = {
  [FILTER_KEY.PUB_DATE]: null,
  [FILTER_KEY.EFFECTIVE_DATE]: null,
  [FILTER_KEY.COMMENTS_CLOSE_DATE]: null,
  [FILTER_KEY.ADDED_DATE]: null,
  [FILTER_KEY.UPDATED_DATE]: null,
  [FILTER_KEY.TEXT]: null,
  [FILTER_KEY.BANKS]: [],
  [FILTER_KEY.AGENCIES]: [],
  [FILTER_KEY.DOC_TYPES]: [],
  [FILTER_KEY.TOPICS]: [],
  [FILTER_KEY.ACTS]: [],
  [FILTER_KEY.JURISDICTIONS]: [],
  [FILTER_KEY.MAINSTREAM_NEWS_SOURCES]: [],
  [FILTER_KEY.TITLE]: null,
  [FILTER_KEY.CITATION]: null,
  [FILTER_KEY.LANGUAGE]: null,
  [FILTER_KEY.CONCEPTS]: [],
  [FILTER_KEY.DOC_PROPERTIES]: null,
  [FILTER_KEY.REGULATIONS]: [],
  [FILTER_KEY.DOCUMENT_ID]: null
};

export const SUGGESTION_TO_FILTER_OPTIONS_CONFIG: Partial<Record<
  string,
  {
    filterKey: FILTER_KEY;
    filterOptionLabelKey: keyof AutocompleteResultFromResponse;
    optionValueKey: keyof AutocompleteResultFromResponse;
    optionLabelKey: keyof AutocompleteResultFromResponse;
    optionType: string;
  }
>> = {
  agencies: {
    filterKey: FILTER_KEY.AGENCIES,
    filterOptionLabelKey: 'short_name',
    optionValueKey: 'id',
    optionLabelKey: 'short_name',
    optionType: 'agency'
  },
  acts: {
    filterKey: FILTER_KEY.ACTS,
    filterOptionLabelKey: 'name',
    optionValueKey: 'id',
    optionLabelKey: 'name',
    optionType: 'act'
  },
  concepts: {
    filterKey: FILTER_KEY.CONCEPTS,
    filterOptionLabelKey: 'name',
    optionValueKey: 'id',
    optionLabelKey: 'name',
    optionType: 'concept'
  },
  named_regulations: {
    filterKey: FILTER_KEY.REGULATIONS,
    filterOptionLabelKey: 'name',
    optionValueKey: 'id',
    optionLabelKey: 'name',
    optionType: 'regulation'
  },
  jurisdictions: {
    filterKey: FILTER_KEY.JURISDICTIONS,
    filterOptionLabelKey: 'short_name',
    optionValueKey: 'short_name',
    optionLabelKey: 'name',
    optionType: 'jurisdiction'
  },
  news_sources: {
    filterKey: FILTER_KEY.MAINSTREAM_NEWS_SOURCES,
    filterOptionLabelKey: 'name',
    optionValueKey: 'id',
    optionLabelKey: 'name',
    optionType: 'news & premium content'
  },
  banks: {
    filterKey: FILTER_KEY.BANKS,
    filterOptionLabelKey: 'name',
    optionValueKey: 'id',
    optionLabelKey: 'name',
    optionType: 'business'
  },
  topics: {
    filterKey: FILTER_KEY.TOPICS,
    filterOptionLabelKey: 'name',
    optionValueKey: 'id',
    optionLabelKey: 'name',
    optionType: 'topic'
  }
};
