import type { LanguagesSelectProps } from './LanguagesSelect.types';

import { Select, SELECT_TYPES } from '@compliance.ai/web-components';
import { useLanguagesSelectData, useLanguagesSelectHandlers } from './hooks';

export const LanguagesSelect = (props: LanguagesSelectProps) => {
  const { formattedData, reduxState } = useLanguagesSelectData(props);

  const handlers = useLanguagesSelectHandlers({
    props
  });

  return (
    <Select
      {...props}
      type={props?.type ?? SELECT_TYPES.DEFAULT}
      onChange={handlers.handleChange}
      options={reduxState.options}
      value={formattedData.value}
      isLoading={!reduxState.options.length || props.isLoading}
      isDisabled={!reduxState.options.length || props.isDisabled}
    />
  );
};
