import type * as apiTypes from './documentNotes.api.types';

import * as fetch from 'fetch';

export const fetchDocumentNotes: apiTypes.FetchDocumentNotes = params => {
  return fetch.fetch({
    url: `/document_note`,
    method: 'GET',
    params
  });
};

export const updateDocumentNoteLabel: apiTypes.UpdateDocumentNoteLabel = params => {
  return fetch.fetch({
    url: '/label',
    method: 'POST',
    params: params
  });
};

export const removeDocumentNoteLabel: apiTypes.RemoveDocumentNoteLabel = params => {
  return fetch.fetch({
    url: '/label',
    method: 'DELETE',
    params: params
  });
};
