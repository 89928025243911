import type { FC } from 'react';
import type { EnforcementFilterProps } from './EnforcementFilter.types';
import type { AgencyOption } from 'shared/features/filters/filters.types';

import * as common from 'common';
import * as uiLib from '@compliance.ai/web-components';
import * as searchTypeConstants from 'constants/SearchTypes';
import * as constants from './EnforcementFilter.constants';
import * as buttonConstants from 'common/Filter/DefaultFiltersSwitch/DefaultFiltersSwitch';

import { useEnforcementFilterData, useEnforcementFilterHandlers } from './hooks';

import './_enforcement-filter.scss';

export const EnforcementFilter: FC<EnforcementFilterProps> = props => {
  const { reduxState, formattedData, localActions, localState } = useEnforcementFilterData({
    props
  });

  const handlers = useEnforcementFilterHandlers({
    localActions,
    localState,
    reduxState,
    formattedData
  });

  if (!reduxState.agencies) {
    return null;
  }

  return (
    <div>
      {localState.isLoading && <uiLib.Loader withoutBackground={false} />}
      <common.FilterHeader className="enforcement-filter__header">
        <common.FilterColumn>
          <common.FilterLabeledElement label="Select View" isBold>
            <common.DefaultFiltersViewSelect
              onSelectChange={handlers.handleDefaultsViewSelect}
              view={localState.defaultView}
            />
          </common.FilterLabeledElement>
        </common.FilterColumn>
      </common.FilterHeader>
      <common.FilterLayout>
        {formattedData.isAlertView && (
          <common.AlertNameTextfield
            isDisabled={!reduxState.current_view.editAlertId}
            value={reduxState.current_view.alertName}
            onChange={handlers.handleAlertNameChange}
          />
        )}
        <common.FilterBody>
          <common.FilterColumn>
            <common.DateFilter
              startDate={localState.filterParams[constants.FILTER_KEY.PUBLISHED_FROM] as string}
              endDate={localState.filterParams[constants.FILTER_KEY.PUBLISHED_TO] as string}
              errors={localState.errors}
              onChange={handlers.handleDateFilterValueChange}
              onError={handlers.handleSetFilterValueError}
              startDateKey={constants.FILTER_KEY.PUBLISHED_FROM}
              endDateKey={constants.FILTER_KEY.PUBLISHED_TO}
              dateOrderErrorKey={'published_order'}
              subject="Publication"
              customLabelElements={
                <common.OfficialPubDateCheckbox
                  isChecked={
                    !Boolean(
                      localState.filterParams[constants.FILTER_KEY.HAS_UNOFFICIAL_PUBLICATION_DATE]
                    )
                  }
                  onChange={isChecked =>
                    handlers.handleFilterValueChange(
                      constants.FILTER_KEY.HAS_UNOFFICIAL_PUBLICATION_DATE
                    )(!isChecked)
                  }
                />
              }
            />
            <common.FilterLabeledElement label="Jurisdiction:">
              <common.SelectV2
                options={formattedData.jurisdictionOptions}
                onChange={handlers.handleFilterValueChange(constants.FILTER_KEY.JURISDICTION)}
                value={localState.filterParams[constants.FILTER_KEY.JURISDICTION]}
                styles={common.SELECT_STYLES.FILTER}
                closeMenuOnSelect={false}
                isMulti
              />
            </common.FilterLabeledElement>
            <common.FilterLabeledElement label="Topics:">
              <common.SelectV2
                options={formattedData.topicOptions}
                value={localState.filterParams[constants.FILTER_KEY.TOPIC_ID]}
                styles={common.SELECT_STYLES.FILTER}
                onChange={handlers.handleFilterValueChange(constants.FILTER_KEY.TOPIC_ID)}
                closeMenuOnSelect={false}
                isMulti
              />
            </common.FilterLabeledElement>
            <common.FilterLabeledElement label="Respondent:">
              <uiLib.TextField
                styleType={uiLib.TEXTFIELD_STYLE_TYPES.ALTER}
                onChange={handlers.handleFilterValueChange(constants.FILTER_KEY.RESPONDENT)}
                value={localState.filterParams[constants.FILTER_KEY.RESPONDENT] as string}
              />
            </common.FilterLabeledElement>
            <common.FilterLabeledElement label="Respondent Type:">
              <common.SelectV2
                options={constants.RESPONDENT_TYPE_OPTIONS}
                value={localState.filterParams[constants.FILTER_KEY.RESPONDENT_TYPE]}
                styles={common.SELECT_STYLES.FILTER}
                onChange={handlers.handleFilterValueChange(constants.FILTER_KEY.RESPONDENT_TYPE)}
              />
            </common.FilterLabeledElement>
          </common.FilterColumn>
          <common.FilterColumn>
            <common.FilterLabeledElement label="Agency:">
              <common.AgenciesSelect
                type={uiLib.SELECT_TYPES.ALTERNATIVE}
                value={
                  localState.filterParams[constants.FILTER_KEY.FEDERAL_SOURCE] as AgencyOption[]
                }
                onChange={handlers.handleFilterValueChange(constants.FILTER_KEY.FEDERAL_SOURCE)}
                shouldCloseMenuOnSelect={false}
                isMulti
              />
            </common.FilterLabeledElement>
            <common.FilterLabeledElement label="Concepts:">
              <uiLib.GroupSelect
                type={uiLib.SELECT_TYPES.ALTERNATIVE}
                value={localState.filterParams[constants.FILTER_KEY.CONCEPTS] as uiLib.SelectValue}
                onChange={handlers.handleFilterValueChange(constants.FILTER_KEY.CONCEPTS)}
                options={reduxState.conceptOptions}
              />
            </common.FilterLabeledElement>
            <common.FilterLabeledElement label="Violation:">
              <uiLib.TextField
                styleType={uiLib.TEXTFIELD_STYLE_TYPES.ALTER}
                onChange={handlers.handleFilterValueChange(constants.FILTER_KEY.VIOLATION)}
                value={localState.filterParams[constants.FILTER_KEY.VIOLATION] as string}
              />
            </common.FilterLabeledElement>
            <common.FilterLabeledElement label={<>Penalty&nbsp;Amount:</>}>
              <div className="enforcement-filter__inline-inputs-wrapper">
                <div className="enforcement-filter__inline-input">
                  <common.SelectV2
                    options={constants.PENALTY_OPTIONS}
                    value={formattedData.limit}
                    styles={common.SELECT_STYLES.FILTER}
                    onChange={handlers.handleFilterValueChange(constants.FILTER_KEY.PENALTY_LIMIT)}
                    isClearable={false}
                  />
                </div>
                <div className="enforcement-filter__inline-input">
                  <uiLib.TextField
                    styleType={uiLib.TEXTFIELD_STYLE_TYPES.ALTER}
                    onChange={handlers.handleFilterValueChange(constants.FILTER_KEY.PENALTY_AMOUNT)}
                    value={localState.filterParams[constants.FILTER_KEY.PENALTY_AMOUNT] as string}
                    type="number"
                  />
                </div>
              </div>
            </common.FilterLabeledElement>
            <common.FilterLabeledElement label="Language:">
              <common.SelectV2
                styles={common.SELECT_STYLES.FILTER}
                options={formattedData.languageOptions}
                value={localState.filterParams[constants.FILTER_KEY.LANGUAGE]}
                onChange={handlers.handleFilterValueChange(constants.FILTER_KEY.LANGUAGE)}
              />
            </common.FilterLabeledElement>
          </common.FilterColumn>
        </common.FilterBody>
        <common.FilterFooter
          error={formattedData.hasFilterErrors && formattedData.filterErrors}
          buttons={
            <>
              {formattedData.isAlertView && reduxState.current_view.editAlertId && (
                <uiLib.Button
                  type={uiLib.BUTTON_TYPES.SECONDARY}
                  onClick={handlers.handleUpdateAlert}
                  isDisabled={formattedData.hasFilterErrors}
                >
                  UPDATE THIS ALERT
                </uiLib.Button>
              )}
              <common.SearchButton
                type={uiLib.BUTTON_TYPES.PRIMARY}
                onClick={handlers.handleRouteWithParams}
                queryParams={formattedData.urlParams}
                disabled={formattedData.hasFilterErrors}
                text="Search Enforcements"
                className=""
                searchType={searchTypeConstants.SEARCH_TYPES.ENFORCEMENT_EXPLORER_SEARCH}
              />
            </>
          }
          additionalContent={
            <uiLib.Button
              type={uiLib.BUTTON_TYPES.LINK}
              onClick={handlers.handleClearAll}
              dataTestId={buttonConstants.TEST_IDS.BUTTON}
              className="enforcement-filter__clear-all-button"
            >
              Clear All
            </uiLib.Button>
          }
        />
      </common.FilterLayout>
    </div>
  );
};
