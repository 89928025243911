import type { DateCellProps } from '../DateCell.types';
import type { DateCellData } from './useDateCellData.types';

import * as uiLib from '@compliance.ai/web-components';
import * as emptyValue from 'constants/EmptyValue';

import { useMemo } from 'react';

export const useDateCellData = ({ cellValue }: Pick<DateCellProps, 'cellValue'>): DateCellData => {
  const formattedData: DateCellData['formattedData'] = useMemo(() => {
    const label = cellValue?.annotation_settings?.datefield_label ?? '';
    const value = cellValue?.annotation_date ?? null;

    const dateString = value
      ? uiLib.formatDate(value, {
          format: uiLib.getLocalDateFormat(),
          timezone: uiLib.TIMEZONES.UTC,
          passedTimezone: uiLib.TIMEZONES.UTC,
          shouldKeepLocalTime: true
        })
      : emptyValue.EMPTY_VALUE.DASH;

    return {
      label,
      dateString
    };
  }, [cellValue?.annotation_date, cellValue?.annotation_settings?.datefield_label]);

  return {
    formattedData
  };
};
