import { EDITABLE_FIELDS_KEYS } from 'constants/TaskConstants';
import { TASK_STATUS_INCOMPLETE } from 'constants/TaskStatuses';

export const DEFAULT_DOC_TASK_VALUES = {
  [EDITABLE_FIELDS_KEYS.STATUS]: TASK_STATUS_INCOMPLETE,
  [EDITABLE_FIELDS_KEYS.DEADLINE]: null,
  [EDITABLE_FIELDS_KEYS.ASSIGNEE]: null,
  [EDITABLE_FIELDS_KEYS.ATTACHMENTS]: [],
  [EDITABLE_FIELDS_KEYS.COMMENTS]: [],
  [EDITABLE_FIELDS_KEYS.ANNOTATION_DATE]: null,
  [EDITABLE_FIELDS_KEYS.ANNOTATIONS_ASSIGNEE]: []
};
