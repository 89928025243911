import lodash from 'lodash';
import { FILTER_KEY as PRIMARY_FILTER_KEY } from 'constants/PrimaryFilter';
import { FILTER_KEY as ENFORCEMENT_FILTER_KEY } from '../../components/Filters/EnforcementFilter/EnforcementFilter.constants';
import { FILTER_KEY as JA_FILTER_KEY } from 'constants/JurisdictionAnalyzer';

const FILTER_KEYS_TO_COUNT_AS_ONE = [
  [PRIMARY_FILTER_KEY.PUB_DATE, PRIMARY_FILTER_KEY.PUB_START_DATE, PRIMARY_FILTER_KEY.PUB_END_DATE],
  [
    PRIMARY_FILTER_KEY.ADDED_DATE,
    PRIMARY_FILTER_KEY.ADDED_START_DATE,
    PRIMARY_FILTER_KEY.ADDED_END_DATE
  ],
  [
    PRIMARY_FILTER_KEY.UPDATED_DATE,
    PRIMARY_FILTER_KEY.UPDATED_START_DATE,
    PRIMARY_FILTER_KEY.UPDATED_END_DATE
  ],
  [
    PRIMARY_FILTER_KEY.EFFECTIVE_DATE,
    PRIMARY_FILTER_KEY.EFFECTIVE_START_DATE,
    PRIMARY_FILTER_KEY.EFFECTIVE_END_DATE
  ],
  [
    PRIMARY_FILTER_KEY.COMMENTS_CLOSE_DATE,
    PRIMARY_FILTER_KEY.COMMENTS_CLOSE_START_DATE,
    PRIMARY_FILTER_KEY.COMMENTS_CLOSE_END_DATE
  ],
  [ENFORCEMENT_FILTER_KEY.PUBLISHED_TO, ENFORCEMENT_FILTER_KEY.PUBLISHED_FROM]
];

const FILTER_KEYS_TO_IGNORE = {
  [PRIMARY_FILTER_KEY.TEXT_MATCH_TYPES]: true,
  [PRIMARY_FILTER_KEY.TITLE_MATCH_TYPES]: true,
  [JA_FILTER_KEY.SKIP_JA_FIELDS]: true,
  [JA_FILTER_KEY.SEARCH_SORT]: true,
  [JA_FILTER_KEY.SUMMARY_PAGE]: true,
  [JA_FILTER_KEY.SEARCH_QUERY]: true
};

export const getFilterCount = filterState => {
  const [count] = Object.entries(filterState).reduce(
    ([currentCount, countedKeys], [filterKey, filterValue]) => {
      const shouldIgnoreKey = filterKey in FILTER_KEYS_TO_IGNORE;

      if (lodash.isNil(filterValue) || lodash.isEmpty(filterValue) || shouldIgnoreKey) {
        return [currentCount, countedKeys];
      }

      const filterKeysGroup = FILTER_KEYS_TO_COUNT_AS_ONE.find(groupOfKeys =>
        groupOfKeys.includes(filterKey)
      );

      if (filterKeysGroup) {
        const isFilterFromGroupAlreadyAdded = countedKeys.find(key =>
          filterKeysGroup.includes(key)
        );

        if (isFilterFromGroupAlreadyAdded) {
          return [currentCount, countedKeys];
        }
      }

      if (Array.isArray(filterValue)) {
        const updatedCheckedKeys = [...countedKeys, filterKey];
        const updatedCount = currentCount + filterValue.length;

        return [updatedCount, updatedCheckedKeys];
      }

      const updatedCheckedKeys = [...countedKeys, filterKey];
      const updatedCount = currentCount + 1;

      return [updatedCount, updatedCheckedKeys];
    },
    [0, []]
  );

  return count;
};
