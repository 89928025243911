import { createSelector } from '@reduxjs/toolkit';
import { getWidgetsLayout } from './widgets.helpers';
import { componentNames as WIDGET_COMPONENT_NAMES } from 'constants/ComponentsNames';

/**
 *
 * Widgets reducer
 */
export const getWidgetsReducer = state => state.widgets;

/**
 *
 * Default widgets selectors
 */
export const getDefaultWidgets = createSelector(getWidgetsReducer, widgets => {
  return widgets.default_widgets;
});
export const getDefaultTopicWidget = createSelector(getDefaultWidgets, defaultWidgets => {
  return defaultWidgets[WIDGET_COMPONENT_NAMES.TOPICS];
});
export const getDefaultApproachingDeadlinesWidget = createSelector(
  getDefaultWidgets,
  defaultWidgets => {
    return defaultWidgets[WIDGET_COMPONENT_NAMES.APPROACHING_DEADLINES];
  }
);
export const getDefaultWidgetsLayouts = createSelector(getDefaultWidgets, defaultWidgets => {
  return getWidgetsLayout(defaultWidgets);
});

/**
 *
 * User widgets selectors
 */
export const getUserWidgets = createSelector(getWidgetsReducer, widgets => {
  return widgets.user_widgets;
});
export const getUserTopicWidgets = createSelector(getUserWidgets, userWidgets => {
  return userWidgets[WIDGET_COMPONENT_NAMES.TOPICS];
});
export const getUserApproachingDeadlinesWidgets = createSelector(getUserWidgets, userWidgets => {
  return userWidgets[WIDGET_COMPONENT_NAMES.APPROACHING_DEADLINES];
});
export const getUserWidgetsLayouts = createSelector(getUserWidgets, userWidgets => {
  return getWidgetsLayout(userWidgets);
});

/**
 * All widgets selectors
 */
export const getAllWidgetsLayouts = state => {
  const userWidgetsLayout = getUserWidgetsLayouts(state);
  const defaultWidgetsLayout = getDefaultWidgetsLayouts(state);

  return {
    lg: [...userWidgetsLayout.lg, ...defaultWidgetsLayout.lg],
    sm: [...userWidgetsLayout.sm, ...defaultWidgetsLayout.sm]
  };
};
export const getMaxYOfWidgetsLayoutsBySize = size =>
  createSelector(getAllWidgetsLayouts, layout => {
    return Object.keys(layout).reduce((currentMaxY, sizeKey) => {
      if (sizeKey !== size) {
        return currentMaxY;
      }

      return Math.max(...layout[sizeKey].map(component => component.y || 0), currentMaxY);
    }, 0);
  });
export const getApproachingDeadlinesWidgetProperties = id => state => {
  const userApproachingDeadlinesWidgets = getUserApproachingDeadlinesWidgets(state);
  const defaultApproachingDeadlinesWidget = getDefaultApproachingDeadlinesWidget(state);

  const userApproachingDeadlinesWidgetProperties = userApproachingDeadlinesWidgets.filter(
    widget => widget.id === id
  )[0];

  return defaultApproachingDeadlinesWidget.id === id
    ? defaultApproachingDeadlinesWidget.properties
    : userApproachingDeadlinesWidgetProperties?.properties;
};
