import PropTypes from 'prop-types';
import { QueryFieldSelect } from '../QueryFieldSelect';

export const QueryFieldSelectWithTooltips = props => {
  return <QueryFieldSelect withValueTooltips {...props} />;
};

QueryFieldSelectWithTooltips.propTypes = {
  items: PropTypes.any,
  setField: PropTypes.func,
  selectedKey: PropTypes.any,
  readonly: PropTypes.bool,
  selectedLabel: PropTypes.string
};
