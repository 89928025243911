import type { AppSearchData } from './useAppSearchData.types';

import { useMemo, useState } from 'react';

export const useAppSearchData = (): AppSearchData => {
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);

  const localState: AppSearchData['localState'] = {
    isAdvancedSearch
  };

  const localActions: AppSearchData['localActions'] = useMemo(
    () => ({
      setIsAdvancedSearch
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
