import type { FC } from 'react';
import type { DocumentDetailsContentTitleProps } from './DocumentDetailsContentTitle.types';

import * as constants from './DocumentDetailsContentTitle.constants';

import { useDocumentDetailsContentTitleData } from './hooks';

export const DocumentDetailsContentTitle: FC<DocumentDetailsContentTitleProps> = ({ document }) => {
  const { reduxState } = useDocumentDetailsContentTitleData();

  const Component = constants.CONTENT_COMPONENTS[reduxState.documentDetailsActiveTabKey];

  return <>{Component ? <Component document={document} /> : null}</>;
};
