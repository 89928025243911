import { useMemo, useState } from 'react';
import { parseOperatorFromDates, parsePastNextRelativeDate } from '../CustomDatePicker.helpers';

export const useCustomDatePickerData = ({ date, startDate, endDate }) => {
  const defaultOperator = parseOperatorFromDates(date, startDate, endDate);
  const { amount: defaultPastAmount, duration: defaultPastDuration } = parsePastNextRelativeDate(
    date
  );

  const [operator, setOperator] = useState(defaultOperator);
  const [amount, setAmount] = useState(defaultPastAmount);
  const [duration, setDuration] = useState(defaultPastDuration);

  const localActions = useMemo(
    () => ({
      setOperator,
      setAmount,
      setDuration
    }),
    []
  );

  return {
    localState: {
      operator,
      amount,
      duration
    },
    localActions
  };
};
