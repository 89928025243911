import type { AuthorProps } from './Author.types';

import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';
import { useAuthorData } from './hooks';

export const Author = ({ document }: AuthorProps) => {
  const { formattedData } = useAuthorData({ document });

  if (formattedData.authors) {
    return (
      <>
        <FieldName>Author</FieldName>
        <FieldValue isExpandable>{formattedData.authors}</FieldValue>
      </>
    );
  }

  return null;
};
