import type { SendByEmailProps } from './SendByEmail.types';

import * as uiLib from '@compliance.ai/web-components';

import { useSendByEmailData, useSendByEmailHandlers } from './hooks';

export const SendByEmail = ({
  documentIds,
  enabled,
  onActionStart,
  actionButtonType
}: SendByEmailProps) => {
  const { formattedData } = useSendByEmailData({ documentIds, enabled });

  const handlers = useSendByEmailHandlers({
    props: {
      onActionStart,
      documentIds
    }
  });

  return (
    <uiLib.ActionButton
      isDisabled={formattedData.isDisabled}
      isRestricted={formattedData.isRestricted}
      onClick={handlers.handleSendByEmail}
      onRestrictedClick={handlers.handleRestrictModalOpen}
      tooltip="Send Email"
      tooltipPlacement={uiLib.TOOLTIP_POSITION.BOTTOM}
      type={actionButtonType}
    >
      <uiLib.IconEmail />
    </uiLib.ActionButton>
  );
};

SendByEmail.defaultProps = {
  enabled: true,
  documentIds: []
};
