import * as actionsTypes from './documentFields.actions.types';

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from './documentFields.api';
import { normalizeDocFieldsOptions } from './documentFields.helpers';
import { logError } from 'utils/errors';

export const SET_DOC_FIELDS_OPTIONS = 'SET_DOC_FIELDS_OPTIONS';
export const FETCH_DOC_FIELDS_OPTIONS = 'FETCH_DOC_FIELDS_OPTIONS';

export const setDocFieldsOptions: actionsTypes.SetDocFieldsOptions = createAction(
  SET_DOC_FIELDS_OPTIONS
);

export const fetchDocFieldsOptions = createAsyncThunk<
  actionsTypes.FetchDocFieldsOptionsReturn,
  actionsTypes.FetchDocFieldsOptionsPayload
>(FETCH_DOC_FIELDS_OPTIONS, async (_, { dispatch }) => {
  try {
    const response = await api.fetchOrgDocsMappingFields();

    dispatch(setDocFieldsOptions(normalizeDocFieldsOptions(response)));
  } catch (e) {
    logError(e as Error, 'Error', { isSilent: true });
  }
});
