import type { RuleFromResponse } from 'shared/features/rules/rules.types';
import type { ActionCellProps } from '../ActionCell.types';
import type { ActionCellData } from './useActionCellData.types';

import * as constants from '../../../ManageBucketAccess.constants';
import * as shareBucketConstants from '../../../../../ShareBucketModal.constants';

export const getInitialAccessLevel = (
  row: ActionCellProps['row']
): ActionCellData['localState']['accessLevel'] => {
  const rule = row?.[constants.TABLE_KEYS.RULE] as RuleFromResponse;

  if (rule?.edit_access) {
    return shareBucketConstants.SHARE_BUCKET_EDIT_ACCESS_OPTION;
  }

  return shareBucketConstants.SHARE_BUCKET_VIEW_ACCESS_OPTION;
};
