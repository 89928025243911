import { safe_analytics } from 'utils/analytics';
import {
  setCognitoPoolInformationId,
  setCorporateLoginApprovedAppUrl,
  setEnteredCorporateEmail
} from 'utils/localStorage-utils';
import { getCognitoHostedUiURL } from 'utils/api/activate_api_util';
import { buildCorporateLoginUrl } from './useCorporateLoginHandlers.helpers';
import { ERROR_KEYS, ERROR_MESSAGES } from '../CorporateLogin.constants';
import { useQueryParams } from 'utils/hooks';
import { QUERY_PARAMS } from 'shared/features/auth/auth.constants';

export const useCorporateLoginHandlers = ({ props, localState, localActions }) => {
  const queryParams = useQueryParams();

  const handleButtonClick = () => {
    localActions.setIsOpen(true);
  };

  const handleModalClose = () => {
    localActions.setIsOpen(false);
  };

  const handleEmailChange = email => {
    localActions.setEmail(email);
  };

  const handleCorporateLogin = async () => {
    try {
      safe_analytics('default', 'Login', 'Button click', 'Corporate Login');
      localActions.setLoadingState(true);

      /**
       *
       * Save user's entered email for further sessions
       *
       */
      setEnteredCorporateEmail(localState.email);

      const { hosted_ui_url: corporateLoginUrl } = await getCognitoHostedUiURL({
        email: localState.email,
        org_invitation_token: props.orgInvitationToken
      });

      props.showWindow(corporateLoginUrl);
    } catch (e) {
      props.onError(ERROR_MESSAGES[ERROR_KEYS.CORPORATE_LOGIN_NOT_ENABLED]);
    } finally {
      localActions.setIsOpen(false);
      localActions.setLoadingState(false);
    }
  };

  const handleLegacyCorporateLogin = async () => {
    try {
      safe_analytics('default', 'Login', 'Button click', 'Corporate Login');
      localActions.setLoadingState(true);

      /**
       *
       * Save user's entered email for further sessions
       *
       */
      setEnteredCorporateEmail(localState.email);

      const { id, hosted_ui_url: corporateLoginUrl } = await getCognitoHostedUiURL({
        email: localState.email,
        org_invitation_token: props.orgInvitationToken
      });

      setCognitoPoolInformationId(id);

      if (queryParams[QUERY_PARAMS.APPROVED_APP_KEY]) {
        setCorporateLoginApprovedAppUrl(queryParams[QUERY_PARAMS.APPROVED_APP_KEY]);
      }

      window.location.href = buildCorporateLoginUrl({
        activationUrl: props.activationUrl,
        corporateLoginUrl: corporateLoginUrl
      });
    } catch (e) {
      props.onError(ERROR_MESSAGES[ERROR_KEYS.CORPORATE_LOGIN_NOT_ENABLED]);
    } finally {
      localActions.setIsOpen(false);
      localActions.setLoadingState(false);
    }
  };

  return {
    handleModalClose,
    handleEmailChange,
    handleButtonClick,
    handleLegacyCorporateLogin,
    handleCorporateLogin
  };
};
