import type { KeyDatesProps } from './KeyDates.types';

import { FieldName } from '../FieldName';
import { FieldValue } from '../FieldValue';
import { useKeyDatesData } from './hooks';
import { useResourceDocumentCheck } from 'utils/hooks';

export const KeyDates = ({ document, shouldRenderForEmail }: KeyDatesProps) => {
  const isResourceDocument = useResourceDocumentCheck(document);

  const { formattedData } = useKeyDatesData({ document });

  if (isResourceDocument || shouldRenderForEmail || !formattedData.keyDates.length) {
    return null;
  }

  return (
    <>
      <FieldName>Key Dates</FieldName>
      <FieldValue>{formattedData.keyDates}</FieldValue>
    </>
  );
};
