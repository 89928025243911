import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getOrganizationMembers,
  getOrganizationReadyState
} from 'shared/features/organizations/organizations.selectors';
import { FILTER_KEY, FILTERS_INITIAL_STATE } from '../LabelDetailsFilters.constants';
import { isBefore } from 'utils/date-utils';

export const LABEL_PARENTS_LIST = [
  {
    value: 'Document',
    label: 'Document'
  },
  {
    value: 'Sentence',
    label: 'Sentence'
  },
  {
    value: 'Task',
    label: 'Task'
  }
];

export const useLabelDetailsFiltersData = () => {
  const [isDropdownOpen, setDropdownOpenState] = useState(false);
  const [filters, setFilters] = useState(FILTERS_INITIAL_STATE);

  const reduxState = useSelector(state => ({
    labelParentList: LABEL_PARENTS_LIST,
    organizationMembers: getOrganizationMembers(state),
    isLoading: !getOrganizationReadyState(state)
  }));

  const isSubmitButtonDisabled = isBefore({
    date: filters[FILTER_KEY.ADDED_END_DATE],
    beforeDate: filters[FILTER_KEY.ADDED_START_DATE]
  });

  return {
    reduxState,
    localState: {
      filters,
      isDropdownOpen
    },
    localActions: {
      setFilters,
      setDropdownOpenState
    },
    formattedData: {
      isSubmitButtonDisabled
    }
  };
};
