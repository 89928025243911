import type { DocumentDetailsRightPanelConfigHandlers } from './useDocumentDetailsRightPanelConfigHandlers.types';
import type { ComponentProps } from 'react';
import type { DocumentDetailsRightPanelConfigData } from './useDocumentDetailsRightPanelConfigData.types';

import type { useDocumentDetailsRightPanelConfigQueries } from './useDocumentDetailsRightPanelConfigQueries';

import * as rightPanelConstants from 'shared/features/rightPanel/rightPanel.constants';
import * as permissionConstants from 'constants/Permissions';
import * as uiLib from '@compliance.ai/web-components';
import * as constants from './useDocumentDetailsRightPanelConfigTabs.constants';

import { useDocumentDetailsTabsAvailability } from 'shared/features/rightPanel/hooks';
import { usePermissions } from 'utils/hooks';

export const useDocumentDetailsRightPanelConfigTabs = ({
  reduxState,
  queries,
  events
}: {
  reduxState: DocumentDetailsRightPanelConfigData['reduxState'];
  queries: ReturnType<typeof useDocumentDetailsRightPanelConfigQueries>;
  events: {
    onRestrictedTabClick: DocumentDetailsRightPanelConfigHandlers['handleRestrictedTabClick'];
    onTabClick: DocumentDetailsRightPanelConfigHandlers['handleTabClick'];
  };
}): Required<Required<ComponentProps<typeof uiLib.AppNavigation>>['rightPanelConfig']>['tabs'] => {
  const permissions = usePermissions();

  const tabsAvailability = useDocumentDetailsTabsAvailability({
    documentId: queries.fetchDocumentDetails.data?.document?.id ?? null,
    areAnnotationsAvailable: Boolean(queries.fetchDocumentDetails.data?.areAnnotationsAvailable),
    areObligationsAvailable: Boolean(queries.fetchDocumentDetails.data?.areObligationsAvailable),
    canUserAccessDocument: Boolean(queries.fetchDocumentDetails.data?.canUserAccessDocument),
    isDiffAvailable: Boolean(queries.fetchDocumentDetails.data?.isDiffAvailable),
    isUserAuthenticated: reduxState.isAuthenticated,
    documentHasVersions: (queries.fetchDocumentVersionsCount.data?.versionsCount ?? 0) >= 2,
    documentHasDockets: (queries.fetchDocumentVersionsCount.data?.docketsCount ?? 0) >= 2
  });

  return Object.entries({
    [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.DETAILS]: {
      label: 'Document Details',
      icon: <uiLib.IconDocument />
    },

    [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.DOCKET_TIMELINE]: {
      label: 'Docket',
      isRestricted: !reduxState.isAuthenticated,
      icon: <uiLib.IconFactCheck />
    },

    [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.VERSIONS]: {
      label: 'Versions',
      isRestricted: !reduxState.isAuthenticated,
      icon: <uiLib.IconHistory />
    },

    [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.EXPERT_CONNECTION]: {
      label: 'Expert Connection',
      icon: <uiLib.IconContactMail />
    },

    [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.RELATED_DOCUMENTS]: {
      label: 'Related Docs',
      icon: <uiLib.IconFileCopy />
    },

    [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.AUTO_SUMMARY]: {
      label: 'Auto Summary',
      icon: <uiLib.IconListEdit />
    },

    [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.TASKS]: {
      label: 'Tasks',
      isRestricted: !permissions[permissionConstants.ACCESS_TASKS],
      icon: <uiLib.IconChecklist />,
      statusBadgeTooltipText: queries.fetchDocumentTasksStatusBadgeType.data
        ? constants.TASK_STATUS_LABELS[queries.fetchDocumentTasksStatusBadgeType.data]
        : '',
      statusBadgeType: queries.fetchDocumentTasksStatusBadgeType.data
    },

    [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.ANNOTATIONS]: {
      label: 'Annotations',
      icon: <uiLib.IconEditText />
    },

    [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.OBLIGATIONS]: {
      label: 'Obligations',
      icon: <uiLib.IconProtected />
    }
  }).flatMap(([tabKey, tab]) => {
    const documentDetailsTabKey = tabKey as rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY;

    if (!tabsAvailability[documentDetailsTabKey]) {
      return [];
    }

    return {
      ...tab,
      isSelected: reduxState.rightPanel.documentDetailsActiveTabKey === documentDetailsTabKey,
      onClick: tab.isRestricted
        ? events.onRestrictedTabClick(tab.label)
        : events.onTabClick({
            tabKey: documentDetailsTabKey,
            tab: tab
          })
    };
  });
};
