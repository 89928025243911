export const TEAM_ADMIN = 'Team Admin';
export const ORG_ADMIN = 'Org Admin';
export const WORKFLOW_ADMIN = 'Workflow Admin';
export const ACTIVE_TEAM_USER = 'Active Team User';
export const LITE_TEAM_USER = 'Lite Team User';

/**
 *
 * Legacy roles. Do not use them.
 *
 */
export const ADMIN = 'admin';
export const RSS = 'rss';
export const CONTRIBUTOR = 'contributor';
export const POWER_USER = 'power_user';
