import { unwrapResult } from '@reduxjs/toolkit';

import * as labelsActions from 'shared/features/labels/labels.actions';
import * as organizationActions from 'shared/features/organizations/organizations.actions';

import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';

export const useOrganizationLabelsReduxActions = () => {
  const dispatch = useDispatch();

  const fetchAllLabels = useCallback(
    async params => {
      return await dispatch(labelsActions.fetchAllLabels(params)).then(unwrapResult);
    },
    [dispatch]
  );

  const fetchLabel = useCallback(
    async id => {
      return await dispatch(labelsActions.fetchLabel(id)).then(unwrapResult);
    },
    [dispatch]
  );

  const deleteLabel = useCallback(
    async id => {
      await dispatch(labelsActions.deleteLabel(id)).then(unwrapResult);
      await dispatch(organizationActions.fetchOrganizationLabelsOptions());
    },
    [dispatch]
  );

  const deleteMultipleLabels = useCallback(
    async ids => {
      await dispatch(labelsActions.deleteMultipleLabels(ids)).then(unwrapResult);
      await dispatch(organizationActions.fetchOrganizationLabelsOptions());
    },
    [dispatch]
  );

  const modifyLabel = useCallback(
    async (id, params) => {
      const response = await dispatch(labelsActions.modifyLabel({ id, params })).then(unwrapResult);

      await dispatch(organizationActions.fetchOrganizationLabelsOptions());

      return response;
    },
    [dispatch]
  );

  const fetchLabelDetails = useCallback(
    async (id, params) =>
      await dispatch(labelsActions.fetchLabelDetails({ id, params })).then(unwrapResult),
    [dispatch]
  );

  const removeLabelsFromDocuments = useCallback(
    async (id, params) =>
      await dispatch(labelsActions.removeLabelsFromDocuments({ id, params })).then(unwrapResult),
    [dispatch]
  );

  const triggerLabelsDetailsRefresh = useCallback(() => {
    dispatch(labelsActions.triggerLabelsDetailsRefresh());
  }, [dispatch]);

  const createLabel = useCallback(
    async (bucketId, labelName, labelDescription, labelType, labelExternalId) => {
      await dispatch(
        labelsActions.createLabel({
          bucketId,
          labelName,
          labelDescription,
          labelType,
          labelExternalId
        })
      );
    },
    [dispatch]
  );

  const importLabels = useCallback(
    async (bucketId, file) => {
      return await dispatch(labelsActions.importLabels({ bucketId, file })).then(unwrapResult);
    },
    [dispatch]
  );

  const addLabels = useCallback(
    async labels => {
      return await dispatch(labelsActions.addLabels(labels)).then(unwrapResult);
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      fetchAllLabels,
      fetchLabel,
      deleteLabel,
      deleteMultipleLabels,
      modifyLabel,
      fetchLabelDetails,
      removeLabelsFromDocuments,
      triggerLabelsDetailsRefresh,
      createLabel,
      importLabels,
      addLabels
    }),
    [
      fetchAllLabels,
      fetchLabel,
      deleteLabel,
      deleteMultipleLabels,
      modifyLabel,
      fetchLabelDetails,
      removeLabelsFromDocuments,
      triggerLabelsDetailsRefresh,
      createLabel,
      importLabels,
      addLabels
    ]
  );
};
