import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUserAuthenticatedState } from 'shared/features/auth/auth.selectors';
import { getRestrictModalData } from 'shared/features/view/view.selectors';
import { RESTRICTION_MODAL_OPTIONS } from '../RestrictModal.constants';

export const useRestrictModalData = () => {
  const reduxState = useSelector(state => ({
    isAuthenticated: getUserAuthenticatedState(state),
    restrictModalData: getRestrictModalData(state)
  }));

  const formattedData = useMemo(() => {
    const { show, type: restrictionType } = reduxState.restrictModalData;
    return {
      restrictionType,
      modalData:
        show && Boolean(restrictionType) ? RESTRICTION_MODAL_OPTIONS[restrictionType] : null
    };
  }, [reduxState]);

  return {
    formattedData,
    reduxState
  };
};
