import type { MetadataPipelineConfig } from './orgDocumentsMetadata.types';

import * as uiLib from '@compliance.ai/web-components';
import * as alertsApi from '../alerts/alerts.api';
import * as docFieldsConstants from '../documentFields/documentFields.constants';
import * as agenciesSelectors from '../agencies/agencies.selectors';
import * as jurisdictionsSelectors from '../jurisdictions/jurisdictions.selectors';
import * as sourcesSelectors from '../sources/sources.selectors';
import * as conceptsSelectors from '../concepts/concepts.selectors';
import * as languagesSelectors from '../languages/languages.selectors';
import * as metadataPipeline from './metadata-pipeline';
import * as emptyValue from 'constants/EmptyValue';
import * as labelsApi from '../labels/labels.api';

export const REDUCER_NAME = 'orgDocumentsMetadata';

export enum UPLOAD_STEPS_KEYS {
  UPLOAD_DATA = 'uploadData',
  MAP_DATA = 'mapData',
  ADD_TAGS = 'addTags',
  FOLDER = 'folder'
}

export const SKIPPABLE_UPLOAD_STEPS = [UPLOAD_STEPS_KEYS.UPLOAD_DATA, UPLOAD_STEPS_KEYS.ADD_TAGS];

export enum MAPPINGS_TAB_KEYS {
  DOCS_DEFAULT_VALUES = 'DocsDefaultValues',
  DOCS_FIELD_MAPPING = 'DocsFieldMapping'
}

export enum CAI_METADATA_CAPABILITIES_KEYS {
  SENTENCES = 'sentences',
  OBLIGATIONS = 'obligations'
}

export const CAI_METADATA_CAPABILITIES_QUERY_PARAMS: Record<
  CAI_METADATA_CAPABILITIES_KEYS,
  string
> = {
  [CAI_METADATA_CAPABILITIES_KEYS.SENTENCES]:
    docFieldsConstants.INTERNAL_DOC_METADATA_FIELDS.HAS_SENTENCES,
  [CAI_METADATA_CAPABILITIES_KEYS.OBLIGATIONS]:
    docFieldsConstants.INTERNAL_DOC_METADATA_FIELDS.HAS_OBLIGATIONS
};

export enum PERMITTED_ENTITY_TYPE {
  USER = 'user',
  TEAM = 'team'
}

export const NOTIFICATIONS: Record<string, uiLib.NotificationMessageArgs> = {
  MISMATCHED_FILE_NAMES: {
    type: uiLib.ALERT_TYPES.ERROR,
    message:
      'The file name information provided in your spreadsheet does not match the files included in this upload. Please ensure that file names match to continue.',
    duration: 7000
  },
  EMPTY_FILE: {
    type: uiLib.ALERT_TYPES.WARNING,
    message:
      'Your file does not include data necessary to complete an upload. You can either assign default values to all documents in the upload, or change your upload file. Note that your upload file must include document titles matching your documents.',
    duration: 7000
  },
  OMITTED_DATA: {
    type: uiLib.ALERT_TYPES.WARNING,
    message: 'Some of the uploaded data is invalid and was omitted'
  },
  NO_IMPORT_ID: {
    type: uiLib.ALERT_TYPES.WARNING,
    title: 'Internal error',
    message: 'Your metadata was uploaded, but no documents import was started'
  },
  METADATA_DRAFT_SAVED: {
    type: uiLib.ALERT_TYPES.SUCCESS,
    title: 'Success',
    message: 'Your metadata was successfully saved'
  },
  METADATA_UPLOAD_SUCCESS: {
    type: uiLib.ALERT_TYPES.SUCCESS,
    title: 'Success',
    message: 'Your metadata was successfully uploaded'
  }
};

export enum CAI_METADATA_TAG_VALUES_KEYS {
  CONCEPT = 'concept',
  DOCUMENT_LABELS = 'document_labels',
  CITATION = 'citation',
  TOPICS = 'topics',
  ALERT = 'alert'
}

export const METADATA_CONFIG: MetadataPipelineConfig = {
  [docFieldsConstants.EXPECTED_DOC_FIELDS.FILE_NAME]: {
    forUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    },

    fromUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.AUTHOR]: {
    forUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    },
    fromUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.TITLE]: {
    forUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    },
    fromUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.PDF_URL]: {
    forUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    },
    fromUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.WEB_URL]: {
    forUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    },
    fromUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.SUMMARY_TEXT]: {
    forUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    },
    fromUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.CFR]: {
    forUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    },
    fromUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.CITATION]: {
    forUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      },

      tags: {
        isMulti: false,
        key: CAI_METADATA_TAG_VALUES_KEYS.CITATION
      }
    },
    fromUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.FULL_TEXT]: {
    forUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    },
    fromUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.DOC_URL]: {
    forUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    },
    fromUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.DOCX_URL]: {
    forUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    },
    fromUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.RESPONDENT]: {
    forUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    },
    fromUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.MONETARY_PENALTY]: {
    forUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    },
    fromUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.VIOLATION]: {
    forUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    },
    fromUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.DOCKET]: {
    forUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    },
    fromUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.DOCKET_TYPE]: {
    forUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    },
    fromUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.DOCKET_DETAILS]: {
    forUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    },
    fromUpload: {
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.CAI_CATEGORY_ID]: {
    forUpload: {
      isMulti: true,
      validation: {
        validator: new metadataPipeline.StringValidator({
          isMulti: true
        })
      }
    },

    fromUpload: {
      isMulti: true,
      validation: {
        validator: new metadataPipeline.StringValidator({
          isMulti: true
        })
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.DOCUMENT_LABELS]: {
    forUpload: {
      isMulti: true,
      validation: {
        validator: new metadataPipeline.IntegerValidator({
          isMulti: true
        })
      },

      tags: {
        isMulti: true,
        key: CAI_METADATA_TAG_VALUES_KEYS.DOCUMENT_LABELS
      },

      defaultValue: {
        format: (options: uiLib.SelectOption[]) => {
          return options.map(option => option.label);
        }
      },

      asyncTranslation: {
        onFetch: labelsApi.getAllLabels,
        searchByParam: 'name',
        responseValuePath: ['result', '0', 'id']
      }
    },

    fromUpload: {
      isMulti: true,
      validation: {
        validator: new metadataPipeline.StringValidator({
          isMulti: true
        })
      },
      asyncTranslation: {
        onFetch: labelsApi.getAllLabels,
        searchByParam: 'id',
        responseValuePath: ['result', '0', 'name']
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.RELATED_DOC_IDS]: {
    forUpload: {
      isMulti: true,
      validation: {
        validator: new metadataPipeline.StringValidator({
          isMulti: true
        })
      },
      defaultValue: {
        format: (value: string) => {
          return value
            ?.split?.(',')
            ?.map(id => Number(id?.trim?.()))
            ?.filter(Boolean);
        }
      }
    },

    fromUpload: {
      isMulti: true,
      validation: {
        validator: new metadataPipeline.StringValidator({
          isMulti: true
        })
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.RELATED_PRO_DOC_IDS]: {
    forUpload: {
      isMulti: true,
      validation: {
        validator: new metadataPipeline.StringValidator({
          isMulti: true
        })
      },

      defaultValue: {
        format: (value: string) => {
          return value
            ?.split?.(',')
            ?.map(id => Number(id?.trim?.()))
            ?.filter(Boolean);
        }
      }
    },

    fromUpload: {
      isMulti: true,
      validation: {
        validator: new metadataPipeline.StringValidator({
          isMulti: true
        })
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.PUBLICATION_DATE]: {
    forUpload: {
      validation: {
        validator: new metadataPipeline.DateValidator()
      }
    },

    fromUpload: {
      validation: {
        validator: new metadataPipeline.DateValidator()
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.EFFECTIVE_DATE]: {
    forUpload: {
      validation: {
        validator: new metadataPipeline.DateValidator()
      }
    },

    fromUpload: {
      validation: {
        validator: new metadataPipeline.DateValidator()
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.COMMENTS_CLOSE_DATE]: {
    forUpload: {
      validation: {
        validator: new metadataPipeline.DateValidator()
      }
    },

    fromUpload: {
      validation: {
        validator: new metadataPipeline.DateValidator()
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.ALERT]: {
    forUpload: {
      isMulti: true,

      asyncTranslation: {
        onFetch: alertsApi.fetchAllAlerts,
        searchByParam: 'name',
        responseValuePath: ['results', '0', 'id']
      },

      validation: {
        shouldKeepValidValues: true,
        validator: new metadataPipeline.IntegerValidator({
          isMulti: true
        })
      },

      tags: {
        isMulti: true,
        key: CAI_METADATA_TAG_VALUES_KEYS.ALERT
      }
    },

    fromUpload: {
      isMulti: true,

      asyncTranslation: {
        onFetch: alertsApi.fetchAllAlerts,
        searchByParam: 'id',
        responseValuePath: ['results', '0', 'name']
      },

      validation: {
        validator: new metadataPipeline.StringValidator({
          isMulti: true
        })
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.AGENCY_IDS]: {
    forUpload: {
      isMulti: true,
      translation: {
        selector: agenciesSelectors.getAgenciesShortNamesToIdsMap
      },
      validation: {
        shouldKeepValidValues: true,
        validator: new metadataPipeline.IntegerValidator({
          isMulti: true
        })
      }
    },

    fromUpload: {
      isMulti: true,
      translation: {
        selector: agenciesSelectors.getAgenciesIdsToShortNamesMap
      },
      validation: {
        validator: new metadataPipeline.StringValidator({
          isMulti: true
        })
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.JURISDICTION]: {
    forUpload: {
      isMulti: true,
      translation: {
        selector: jurisdictionsSelectors.getJurisdictionShortNamesToIdsMap
      },
      validation: {
        shouldKeepValidValues: true,
        validator: new metadataPipeline.IntegerValidator({
          isMulti: true
        })
      }
    },

    fromUpload: {
      isMulti: true,
      translation: {
        selector: jurisdictionsSelectors.getJurisdictionIdsToShortNamesMap
      },
      validation: {
        validator: new metadataPipeline.StringValidator({
          isMulti: true
        })
      }
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.TOPICS]: {
    forUpload: {
      isMulti: true,
      translation: {
        selector: sourcesSelectors.getActiveTopicsNamesToIdsMap
      },
      validation: {
        shouldKeepValidValues: true,
        validator: new metadataPipeline.IntegerValidator({
          isMulti: true
        })
      },
      conditionalKey: 'surface_cai_topic',
      tags: {
        isMulti: true,
        key: CAI_METADATA_TAG_VALUES_KEYS.TOPICS
      }
    },

    fromUpload: {
      isMulti: true,
      translation: {
        selector: sourcesSelectors.getActiveTopicsIdsToNamesMap
      },
      validation: {
        validator: new metadataPipeline.StringValidator({
          isMulti: true,
          whitelistedValues: [emptyValue.EMPTY_VALUE.NA]
        })
      },
      conditionalKey: 'surface_cai_topic'
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.CONCEPT]: {
    forUpload: {
      isMulti: true,
      translation: {
        selector: conceptsSelectors.getConceptsNamesToIdsMap
      },
      validation: {
        shouldKeepValidValues: true,
        validator: new metadataPipeline.IntegerValidator({
          isMulti: true
        })
      },
      conditionalKey: 'surface_cai_concept',
      tags: {
        isMulti: true,
        key: CAI_METADATA_TAG_VALUES_KEYS.CONCEPT
      }
    },

    fromUpload: {
      isMulti: true,
      translation: {
        selector: conceptsSelectors.getConceptsIdsToNamesMap
      },
      validation: {
        validator: new metadataPipeline.StringValidator({
          isMulti: true,
          whitelistedValues: [emptyValue.EMPTY_VALUE.NA]
        })
      },
      conditionalKey: 'surface_cai_concept'
    }
  },

  [docFieldsConstants.EXPECTED_DOC_FIELDS.LANGUAGE]: {
    forUpload: {
      translation: {
        selector: languagesSelectors.getLanguagesNamesToIdsMap
      },
      validation: {
        shouldKeepValidValues: true,
        validator: new metadataPipeline.IntegerValidator()
      }
    },

    fromUpload: {
      translation: {
        selector: languagesSelectors.getLanguagesIdsToNamesMap
      },
      validation: {
        validator: new metadataPipeline.StringValidator()
      }
    }
  }
};
