// Disabled because of the legacy logic, that has been rewritten into hooks
/* eslint-disable react-hooks/exhaustive-deps */
import * as helpers from '../AutocompleteSearchField.helpers';
import * as searchUtils from 'utils/search';
import * as config from 'shared/config';
import * as routes from 'constants/Routes';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDeepCompareMemoize, useDidMount } from 'utils/hooks';
import { useAutocompleteSearchFieldReduxActions } from './useAutocompleteSearchFieldReduxActions';

export const useAutocompleteSearchFieldLifecycles = ({ localState, localActions, reduxState }) => {
  const location = useLocation();

  const reduxActions = useAutocompleteSearchFieldReduxActions();

  useDidMount(() => {
    reduxActions.setSearchTerm(localState.searchTerm);
  });

  useEffect(() => {
    localActions.changeAutocompletes(reduxState.autocompletes.items);
  }, [reduxState.autocompletes.items]);

  useEffect(() => {
    if (reduxState.filters.jurisdictionFilter.filterParams.search_query) {
      localActions.changeSearchTerm(
        reduxState.filters.jurisdictionFilter.filterParams.search_query
      );
    }
  }, [reduxState.filters.jurisdictionFilter.filterParams.search_query]);

  useEffect(() => {
    const parsedQueryParams = helpers.parseQueryParams(location.search);

    const newView = config.shouldShowNewSearch
      ? [routes.content, routes.news, routes.timeline].includes(location.pathname) &&
        location.pathname.slice(1)
      : parsedQueryParams.view;
    localActions.changeCurrentView(newView);
    localActions.changeSearchTerm(parsedQueryParams.search_query);
  }, [location.search]);

  useEffect(() => {
    const appView = searchUtils.get_search_view(reduxState.current_view.search_params, location);

    if (
      appView.section &&
      appView.section.ref !== 'all' &&
      appView.section.ref !== 'search' &&
      appView.section.ref !== undefined
    ) {
      localActions.changeSearchTerm('');
      localActions.changeAutocompletes([]);
    }
  }, useDeepCompareMemoize([reduxState.current_view, location]));

  // Populate the search-term if we are on a search with a filtered_mention and
  // we are receiving a new filtered_mention
  useEffect(() => {
    const parsedQueryParams = helpers.parseQueryParams(location.search);

    if (parsedQueryParams.search_sort === 'date' && reduxState.filtered_mention.isReady) {
      const entity = reduxState.filtered_mention.mention;
      localActions.changeSearchTerm(entity.name || entity.short_name);
    }
  }, useDeepCompareMemoize([reduxState.filtered_mention.isReady, reduxState.filtered_mention.mention, location]));
};
