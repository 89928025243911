import { useCallback } from 'react';

export const useTaskAssigneeAnnotationHandlers = ({ props }) => {
  const handleAssigneeSelections = useCallback(
    options => {
      const values = options.map(({ value }) => value);

      props.onChange([
        {
          task_id: props.documentTask.id,
          annotation_assignee_selection: values
        }
      ]);
    },
    [props]
  );

  return {
    handleAssigneeSelections
  };
};
