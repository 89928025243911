import { Alert, ALERT_TYPES, Button, BUTTON_TYPES, Loader } from '@compliance.ai/web-components';
import { useRestrictModalActionButtonData, useRestrictModalActionButtonHandlers } from './hooks';
import {
  ACTION_STATUSES,
  ACTION_SUCCESS_MESSAGE,
  ACTION_ERROR_MESSAGE
} from './RestrictModalActionButton.constants';
import './_request-modal-action-button.scss';

export const RestrictModalActionButton = ({ title, restrictionType }) => {
  const { localState, localActions, reduxState } = useRestrictModalActionButtonData();

  const handlers = useRestrictModalActionButtonHandlers({
    props: { restrictionType },
    localActions,
    reduxState
  });

  return (
    <div className="restrict-modal-action-button__container">
      {!localState.isLoading && localState.actionStatus === ACTION_STATUSES.EMPTY && (
        <Button
          type={BUTTON_TYPES.PRIMARY}
          onClick={handlers.handleSkuAccessRequest}
          disabled={localState.isLoading}
        >
          {title}
        </Button>
      )}

      {localState.isLoading && <Loader />}

      {localState.actionStatus === ACTION_STATUSES.SUCCESS && (
        <Alert type={ALERT_TYPES.SUCCESS} content={ACTION_SUCCESS_MESSAGE[restrictionType]} />
      )}

      {localState.actionStatus === ACTION_STATUSES.ERROR && (
        <Alert type={ALERT_TYPES.ERROR} content={ACTION_ERROR_MESSAGE} />
      )}
    </div>
  );
};
