import * as taskUtils from 'utils/task-utils';
import * as workflowHelpers from 'shared/features/workflow/workflow.helpers';
import * as uiLib from '@compliance.ai/web-components';
import * as rightPanelConstants from 'shared/features/rightPanel/rightPanel.constants';
import * as constants from '../WorkflowPreTaskContainer.constants';

import { useCallback } from 'react';
import { useWorkflowsApi, useWorkflowReduxActions } from 'shared/features/workflow/hooks';
import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';
import { useTabsHints } from '@compliance.ai/web-components';

export const useWorkflowPreTaskContainerHandlers = ({ props, localState, localActions }) => {
  const workflowsApi = useWorkflowsApi();
  const workflowsReduxActions = useWorkflowReduxActions();
  const rightPanelReduxActions = useRightPanelReduxActions();
  const tabsHints = useTabsHints();

  const handleTaskUpdate = useCallback(
    taskId => ({ field, val }) => {
      const updatedTasks = localState.tasks.map(
        taskUtils.updateTasks({
          taskId,
          field,
          val
        })
      );

      localActions.setTasks(updatedTasks);
    },
    [localActions, localState.tasks]
  );

  const handleDocTasksCreation = useCallback(async () => {
    try {
      localActions.setTasksCreationStatus(true);

      if (props.isObligationWorkflow) {
        tabsHints.showHint({
          type: uiLib.TOOLTIP_TYPES.SECONDARY,
          tabHintKey: rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.OBLIGATIONS,
          timeout: constants.OBLIGATION_TASKS_HINT_TIMEOUT,
          content: 'Obligation-level workflow tasks will appear within obligations'
        });
      }

      const tasksObj = localState.tasks.reduce((tasks, task) => {
        return {
          ...tasks,
          [task.id]: {
            assignee: task.assignee,
            deadline: taskUtils.getDeadline({ document, task })
          }
        };
      }, {});

      await workflowsReduxActions.addDocumentToWorkflow({
        workflowId: props.workflowId,
        docTasks: { [props.document.id]: tasksObj }
      });

      rightPanelReduxActions.triggerRightPanelTasksRefresh();

      props.onWorkflowAddCancel();

      if (localState.tasks.some(task => task.is_obligation_super_task)) {
        props.onObligationTaskFound?.();
      }
    } finally {
      localActions.setTasksCreationStatus(false);
    }
  }, [
    localActions,
    localState.tasks,
    props,
    rightPanelReduxActions,
    tabsHints,
    workflowsReduxActions
  ]);

  const handleWorkflowFetch = useCallback(async () => {
    try {
      localActions.setIsLoading(true);

      const workflow = await workflowsApi.fetchWorkflow(props.workflowId);

      if (workflow) {
        localActions.setWorkflow(workflow);
        localActions.setTasks(
          workflow.tasks
            .filter(workflowHelpers.isNotAssigneeDependentOnTaskOrDependencyTask)
            .map(task => ({
              ...task,
              document: props.document,
              deadline: taskUtils.getDeadline({ document, task })
            }))
        );
      }
    } finally {
      localActions.setIsLoading(false);
    }
  }, [localActions, props.document, props.workflowId, workflowsApi]);

  return {
    handleTaskUpdate,
    handleDocTasksCreation,
    handleWorkflowFetch
  };
};
