import type * as uiLib from '@compliance.ai/web-components';

import type { ManageBucketAccessProps } from '../ManageBucketAccess.types';

import * as rulesApi from 'shared/features/rules/rules.api';
import * as constants from '../ManageBucketAccess.constants';
import * as sortUtils from 'utils/sort';

export const formatRulesTeamsAccessRows = (
  response: Awaited<ReturnType<typeof rulesApi.fetchRules>>
) => {
  const teamsAccessRows = response?.rules
    .reduce((teamsRows: uiLib.Row[], rule) => {
      return [
        ...teamsRows,
        ...rule.teams
          .filter(
            team => !teamsRows.some(teamsRow => teamsRow[constants.TABLE_KEYS.ID] === team.id)
          )
          .map(
            team =>
              ({
                [constants.TABLE_KEYS.ID]: rule.id,
                [constants.TABLE_KEYS.NAME]: team.name,
                [constants.TABLE_KEYS.RULE]: rule
              } as uiLib.Row)
          )
      ];
    }, [])
    .sort(sortUtils.alphabetically);

  if (!teamsAccessRows?.length) {
    return [];
  }

  return [
    {
      [constants.TABLE_KEYS.ID]: constants.ACCESS_TYPES.TEAMS,
      [constants.TABLE_KEYS.NAME]: constants.ACCESS_TYPES.TEAMS,
      [constants.TABLE_KEYS.IS_GROUP_HEADER]: true
    },
    ...teamsAccessRows
  ];
};

export const formatMembersTeamsAccessRows = (
  response: Awaited<ReturnType<typeof rulesApi.fetchRules>>
) => {
  const membersAccessRows = response?.rules
    .reduce((membersRows: uiLib.Row[], rule) => {
      return [
        ...membersRows,
        ...rule.users
          .filter(
            user => !membersRows.some(membersRow => membersRow[constants.TABLE_KEYS.ID] === user.id)
          )
          .map(
            user =>
              ({
                [constants.TABLE_KEYS.ID]: rule.id,
                [constants.TABLE_KEYS.NAME]: user.email,
                [constants.TABLE_KEYS.RULE]: rule
              } as uiLib.Row)
          )
      ];
    }, [])
    .sort(sortUtils.alphabetically);

  if (!membersAccessRows?.length) {
    return [];
  }

  return [
    {
      [constants.TABLE_KEYS.ID]: constants.ACCESS_TYPES.MEMBERS,
      [constants.TABLE_KEYS.NAME]: constants.ACCESS_TYPES.MEMBERS,
      [constants.TABLE_KEYS.IS_GROUP_HEADER]: true
    },
    ...membersAccessRows
  ];
};

export const formatBucketAccessRulesRows = ({
  bucket
}: {
  bucket: ManageBucketAccessProps['bucket'];
}) => (
  response: Awaited<ReturnType<typeof rulesApi.fetchRules>>
): {
  rows: uiLib.TableProps['rows'];
} => {
  const ownerRow = {
    [constants.TABLE_KEYS.ID]: constants.ACCESS_TYPES.OWNER,
    [constants.TABLE_KEYS.NAME]: bucket?.createdBy.email,
    [constants.TABLE_KEYS.IS_OWNER]: true
  };
  const teamsRows = formatRulesTeamsAccessRows(response);
  const membersRows = formatMembersTeamsAccessRows(response);

  return {
    rows: [ownerRow, ...teamsRows, ...membersRows]
  };
};
