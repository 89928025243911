import * as defaultSelectors from 'shared/features/defaults/defaults.selectors';
import * as conceptSelectors from 'shared/features/concepts/concepts.selectors';
import * as documentSelectors from 'shared/features/documents/documents.selectors';
import * as helpers from '../JurisdictionAnalyzerFilter.helpers';
import * as defaultSourcesConstants from 'constants/DefaultSources';
import * as jurisdictionAnalyzerConstants from 'constants/JurisdictionAnalyzer';

import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const useJurisdictionFilterData = () => {
  const [alertName, changeAlertName] = useState('');

  const reduxState = useSelector(state => {
    const categories = documentSelectors.getCategoriesByName(state);
    const adjustedCategories = state.filters.jurisdictionFilter.filterParams[
      jurisdictionAnalyzerConstants.FILTER_KEY.CATEGORY
    ]?.length
      ? state.filters.jurisdictionFilter.filterParams[
          jurisdictionAnalyzerConstants.FILTER_KEY.CATEGORY
        ]
      : jurisdictionAnalyzerConstants.DEFAULT_CATEGORIES.map(categoryName =>
          documentSelectors.formatCategory(categories[categoryName])
        );
    const adjustedLanguage =
      state.filters.jurisdictionFilter.filterParams[
        jurisdictionAnalyzerConstants.FILTER_KEY.LANGUAGE_ID
      ] || state.current_user.user.languageId;

    return {
      current_user: state.current_user,
      jurisdictions: state.jurisdictions,
      sources: state.sources,
      regulations: state.regulations,
      current_view: state.current_view,
      docTypes: state.docTypes,
      alerts: state.alerts,
      filterParams: {
        ...state.filters.jurisdictionFilter.filterParams,
        [jurisdictionAnalyzerConstants.FILTER_KEY.CATEGORY]: adjustedCategories,
        [jurisdictionAnalyzerConstants.FILTER_KEY.LANGUAGE_ID]: adjustedLanguage
      },
      errors: state.filters.jurisdictionFilter.errors,
      defaultFilters: state.filters.jurisdictionFilter.defaultFilters,
      defaultsData: {
        followedTopicsObj: defaultSelectors.getFollowedTopicsObj(
          defaultSourcesConstants.DEFAULT_TYPES.MY_DEFAULTS
        )(state)
      },
      conceptsData: {
        conceptsList: conceptSelectors.getConceptsList(state),
        conceptGroupOptions: conceptSelectors.getConceptsGroupedOptions(state)
      },
      categories
    };
  });

  const filterErrors = useMemo(() => {
    return Object.values(reduxState.errors).filter(error => error);
  }, [reduxState.errors]);

  const isAlertEditMode = helpers.shouldShowAlert(reduxState);

  return {
    localState: {
      alertName
    },
    localActions: {
      changeAlertName
    },
    reduxState,
    isAlertEditMode,
    filterErrors
  };
};
