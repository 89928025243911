import type { PublisherData } from './usePublisherData.types';
import type { GlobalState } from 'shared/reducers';
import type { PublisherProps } from '../Publisher.types';

import { useSelector } from 'react-redux';
import { getDocumentCategoryName } from 'shared/features/documents/documents.selectors';

export const usePublisherData = ({ document }: Pick<PublisherProps, 'document'>): PublisherData => {
  const reduxState = useSelector<GlobalState, PublisherData['reduxState']>(state => ({
    // TODO Uncomment when the selectors file will be moved to TS
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    categoryName: getDocumentCategoryName(state, document.cai_category_id)
  }));

  return {
    reduxState
  };
};
