import type { NavBarAuthHandlers, NavBarAuthSourceConfig } from './useNavBarAuthHandlers.types';

import * as helpers from './useNavBarAuthHandlers.helpers';
import * as constants from './useNavBarAuthHandlers.constants';
import * as analyticsUtils from 'utils/analytics';

import { useNavigate } from 'react-router-dom';

export const useNavBarAuthHandlers = (): NavBarAuthHandlers => {
  const navigate = useNavigate();

  const handleSignIn: NavBarAuthHandlers['handleSignIn'] = () => {
    const source: NavBarAuthSourceConfig['source'] = constants.NAV_BAR_AUTH_SOURCES.LOGIN;

    analyticsUtils.safe_analytics('default', 'Left Nav', source);

    navigate(helpers.formatNavBarAuthRoute(constants.NAV_BAR_AUTH_SOURCES_CONFIGS[source]), {
      state: {
        fromLeftNav: true
      }
    });
  };

  const handleSignUp: NavBarAuthHandlers['handleSignUp'] = () => {
    const source: NavBarAuthSourceConfig['source'] = constants.NAV_BAR_AUTH_SOURCES.PLANS;

    analyticsUtils.safe_analytics('default', 'Left Nav', source);
    analyticsUtils.safe_ga('send', 'event', 'button', 'click', 'Sign up');
    analyticsUtils.safe_mixpanel_track('Sign up – Button click – Sign Up', {
      eventCategory: 'Sign up',
      eventAction: 'Button click',
      eventLabel: 'Sign Up'
    });

    navigate(helpers.formatNavBarAuthRoute(constants.NAV_BAR_AUTH_SOURCES_CONFIGS[source]), {
      state: {
        fromLeftNav: true
      }
    });
  };

  return {
    handleSignIn,
    handleSignUp
  };
};
