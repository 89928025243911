import type { WorkflowDiagramModalHandlers } from './useWorkflowDiagramModalHandlers.types';

import { useDidMount } from '@compliance.ai/web-components';

export const useWorkflowDiagramModalLifecycle = ({
  onWorkflowFetch
}: {
  onWorkflowFetch: WorkflowDiagramModalHandlers['handleWorkflowFetch'];
}) => {
  useDidMount(onWorkflowFetch);
};
