import { createSelector } from '@reduxjs/toolkit';

export const getRegulationsReducer = state => state.regulations;

export const getRegulationsList = createSelector(getRegulationsReducer, regulationsReducer => {
  return regulationsReducer.regulations ?? [];
});

export const getRegulationsOptions = createSelector(getRegulationsList, regulations => {
  return regulations
    .map(regulation => ({
      value: regulation.id,
      label: regulation.name
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
});

export const getRegulationsReadyState = createSelector(
  getRegulationsReducer,
  regulationsReducer => {
    return regulationsReducer.isReady;
  }
);

export const getRegulationsIdsToNamesMap = createSelector(getRegulationsList, regulations => {
  return regulations.reduce((regulations, regulation) => {
    return {
      ...regulations,
      [regulation.id]: regulation.name
    };
  }, {});
});
