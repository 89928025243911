import type { ApprovalAnnotationProps } from './ApprovalAnnotation.types';

import {
  Alert,
  ALERT_TYPES,
  Button,
  BUTTON_TYPES,
  IconSuccessRounded,
  LabeledField
} from '@compliance.ai/web-components';
import { APPROVAL_ANNOTATION_STATUSES } from 'shared/features/workflow/workflow.constants';
import { DATA_TEST_IDS, ERROR_MESSAGES } from './ApprovalAnnotation.constants';
import { ApproversSelect } from './elements';
import { useApprovalAnnotationData } from './hooks';
import './_approval-annotation.scss';

export const ApprovalAnnotation = ({
  status,
  label,
  assigneeId,
  approversIds,
  backupApproversIds,
  onApprove,
  onReject,
  onSubmitForApproval,
  isSubmitDisabled,
  primaryApprover
}: ApprovalAnnotationProps) => {
  const { reduxState, formattedData } = useApprovalAnnotationData({
    approversIds,
    backupApproversIds,
    assigneeId,
    isSubmitDisabled
  });

  switch (true) {
    /**
     *
     * Don't render anything if the task is approved
     *
     */
    case [APPROVAL_ANNOTATION_STATUSES.APPROVED].includes(status): {
      return null;
    }

    case [APPROVAL_ANNOTATION_STATUSES.INITIAL, APPROVAL_ANNOTATION_STATUSES.REJECTED].includes(
      status
    ): {
      switch (true) {
        /**
         *
         * Don't render anything if current user is assignee and there are no initial approvers
         *
         */
        case Boolean(reduxState.isAssignee && approversIds.length === 0): {
          return null;
        }

        /**
         *
         * Render approver selection inputs for current user if he is an assignee
         *
         * OR
         *
         * Render approver selection inputs for admin if all the initial approvers are expired
         *
         */
        case Boolean(
          reduxState.isAssignee || (reduxState.isAdmin && reduxState.areInitialApproversExpired)
        ): {
          return (
            <LabeledField label={label} isContrast dataTestId={DATA_TEST_IDS.CONTAINER}>
              <ApproversSelect
                approversIds={formattedData.approversIds}
                isSubmitDisabled={isSubmitDisabled}
                onSubmit={onSubmitForApproval}
                primaryApprover={primaryApprover}
                dataTestIds={{
                  container: DATA_TEST_IDS.INPUT_CONTAINER,
                  select: DATA_TEST_IDS.INPUT,
                  submitButton: DATA_TEST_IDS.SUBMIT_BUTTON
                }}
              />
              {reduxState.areInitialApproversExpired &&
                (reduxState.isAdmin ? (
                  <Alert
                    type={ALERT_TYPES.ERROR}
                    content={ERROR_MESSAGES.SELECT_ACTIVE_APPROVER}
                    className="common-approval-annotation__error"
                  />
                ) : (
                  <Alert
                    type={ALERT_TYPES.ERROR}
                    content={ERROR_MESSAGES.CONTACT_ADMIN}
                    className="common-approval-annotation__error"
                  />
                ))}
            </LabeledField>
          );
        }

        /**
         *
         * Don't render anything other types of users
         *
         */
        default:
          return null;
      }
    }

    case [APPROVAL_ANNOTATION_STATUSES.PENDING_APPROVAL].includes(status): {
      /**
       *
       * Render approver selection inputs for admin if current approver is expired
       *
       */
      switch (true) {
        case Boolean(
          reduxState.isAdmin &&
            (reduxState.areInitialApproversExpired || !reduxState.isAssigneeActive)
        ): {
          return (
            <LabeledField label={label} isContrast dataTestId={DATA_TEST_IDS.CONTAINER}>
              <ApproversSelect
                approversIds={formattedData.approversIds}
                primaryApprover={primaryApprover}
                isSubmitDisabled={isSubmitDisabled}
                onSubmit={onSubmitForApproval}
                dataTestIds={{
                  container: DATA_TEST_IDS.INPUT_CONTAINER,
                  select: DATA_TEST_IDS.INPUT,
                  submitButton: DATA_TEST_IDS.SUBMIT_BUTTON
                }}
              />
              <Alert
                type={ALERT_TYPES.ERROR}
                content={ERROR_MESSAGES.SELECT_ACTIVE_APPROVER}
                className="common-approval-annotation__error"
              />
            </LabeledField>
          );
        }

        /**
         *
         * Render submit/reject buttons if current user is approver
         *
         */
        case Boolean(formattedData.isApprover): {
          return (
            <LabeledField label={label} isContrast dataTestId={DATA_TEST_IDS.CONTAINER}>
              <div
                data-testid={DATA_TEST_IDS.APPROVER_BUTTONS}
                className="common-approval-annotation__buttons-wrapper"
              >
                <Button
                  dataTestId={DATA_TEST_IDS.REJECT_BUTTON}
                  type={BUTTON_TYPES.DELETE}
                  className="common-approval-annotation__button"
                  onClick={onReject}
                >
                  Reject
                </Button>
                <Button
                  dataTestId={DATA_TEST_IDS.APPROVE_BUTTON}
                  className="common-approval-annotation__button"
                  onClick={onApprove}
                >
                  Approve
                </Button>
              </div>
            </LabeledField>
          );
        }

        /**
         *
         * Render submitted status for other users.
         * Also show error if current assignee is expired
         *
         */
        default: {
          return (
            <LabeledField label={label} isContrast dataTestId={DATA_TEST_IDS.CONTAINER}>
              <p
                data-testid={DATA_TEST_IDS.PENDING_APPROVAL_TEXT}
                className="common-approval-annotation__text"
              >
                <IconSuccessRounded />
                Submitted for approval
              </p>
              {!reduxState.isAssigneeActive && (
                <Alert
                  type={ALERT_TYPES.ERROR}
                  content={ERROR_MESSAGES.CONTACT_ADMIN}
                  className="common-approval-annotation__error"
                />
              )}
            </LabeledField>
          );
        }
      }
    }

    /**
     *
     * Don't render the component if none of the conditions where met
     *
     */
    default:
      return null;
  }
};
