import type { GlobalState } from 'shared/reducers';
import type { DiffData } from './useDiffData.types';

import * as authSelectors from 'shared/features/auth/auth.selectors';
import * as authUtils from 'utils/authorization';

import { useSelector } from 'react-redux';

export const useDiffData = (): DiffData => {
  const reduxState = useSelector<GlobalState, DiffData['reduxState']>(state => ({
    isAuthenticated: authSelectors.getUserAuthenticatedState(state)
  }));

  const formattedData: DiffData['formattedData'] = {
    isRestricted: Boolean(!reduxState.isAuthenticated || authUtils.isLiteTeamUser())
  };

  return {
    reduxState,
    formattedData
  };
};
