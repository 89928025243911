import type { Validator } from '../Validator';

import lodash from 'lodash';

export class IntegerValidator implements Validator {
  readonly isMulti: Validator['isMulti'] = false;
  readonly whitelistedValues: Validator['whitelistedValues'] = [];

  constructor(args?: {
    isMulti?: Validator['isMulti'];
    whitelistedValues?: Validator['whitelistedValues'];
  }) {
    this.isMulti = args?.isMulti ?? false;
    this.whitelistedValues = args?.whitelistedValues ?? [];
  }

  readonly isValueValid: Validator['isValueValid'] = metadataValue => {
    return lodash.isInteger(metadataValue) || this.whitelistedValues.includes(metadataValue);
  };

  readonly validate: Validator['validate'] = args => {
    if (this.isMulti) {
      if (!Array.isArray(args.metadataValue)) {
        return {
          isValid: false,
          validValue: []
        };
      }

      const validValue = args.metadataValue.filter(this.isValueValid);

      return {
        isValid: validValue.length === args.metadataValue.length,
        validValue: validValue
      };
    }

    const isValid = this.isValueValid(args.metadataValue);

    return {
      isValid: isValid,
      validValue: isValid ? args.metadataValue : null
    };
  };
}
