import { useMemo, useCallback } from 'react';

export const useFolderHandlers = ({
  props,
  localActions,
  formattedData,
  reduxState,
  foldersReduxActions,
  viewReduxActions
}) => {
  const handleOnClick = useCallback(() => {
    if (!formattedData.isDisabled) {
      localActions.setAddToFolderModalOpen(true);
    }
  }, [formattedData, localActions]);

  const handleFetchDocumentSavedFoldersCount = useCallback(async () => {
    if (props.showSavedFoldersCount) {
      foldersReduxActions.fetchAndSaveDocumentSavedFoldersCount(reduxState.rightPanelDocument);
    }
  }, [props.showSavedFoldersCount, reduxState, foldersReduxActions]);

  const handleClose = useCallback(
    (shouldRefetchDocumentSavedFoldersCount = false) => {
      if (shouldRefetchDocumentSavedFoldersCount) {
        foldersReduxActions.fetchAndSaveDocumentSavedFoldersCount(reduxState.rightPanelDocument);
      }

      localActions.setAddToFolderModalOpen(false);
    },
    [localActions, reduxState, foldersReduxActions]
  );

  const handleRestrictModalOpen = useCallback(() => {
    viewReduxActions.showRestrictModal();
  }, [viewReduxActions]);

  return useMemo(
    () => ({
      handleOnClick,
      handleFetchDocumentSavedFoldersCount,
      handleClose,
      handleRestrictModalOpen
    }),
    [handleOnClick, handleFetchDocumentSavedFoldersCount, handleClose, handleRestrictModalOpen]
  );
};
