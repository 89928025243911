import { fetch } from 'fetch';
import { ORGANIZATION_DOCUMENTS } from 'constants/DataTypes';

import * as apiTypes from './organizationDocuments.api.types';

export const getOrganizationDocuments: apiTypes.GetOrganizationDocuments = params => {
  return fetch({
    url: '/organizations/organization_documents',
    dataType: ORGANIZATION_DOCUMENTS,
    method: 'GET',
    params
  });
};

export const postOrganizationDocuments: apiTypes.PostOrganizationDocuments = (
  files,
  importName
) => {
  const formData = new FormData();

  files.forEach((file, i) => {
    formData.append(`file${i + 1}`, file);
  });

  return fetch({
    url: `/organizations/organization_documents?import_name=${importName}`,
    dataType: ORGANIZATION_DOCUMENTS,
    method: 'POST',
    params: formData
  });
};

export const getOrganizationDocumentImports: apiTypes.GetOrganizationDocumentImports = params => {
  return fetch({
    url: '/organizations/organization_documents/imports',
    dataType: ORGANIZATION_DOCUMENTS,
    method: 'GET',
    params
  });
};

export const getOrganizationDocumentImport: apiTypes.GetOrganizationDocumentImport = importId => {
  return fetch({
    url: `/organizations/organization_documents/imports/${importId}`,
    dataType: ORGANIZATION_DOCUMENTS,
    method: 'GET'
  });
};

export const deleteOrganizationDocumentImport: apiTypes.DeleteOrganizationDocumentImport = importId => {
  return fetch({
    url: `/organizations/organization_documents/imports/${importId}`,
    dataType: ORGANIZATION_DOCUMENTS,
    method: 'DELETE'
  });
};

export const deleteOrganizationDocument: apiTypes.DeleteOrganizationDocument = orgDocId => {
  return fetch({
    url: `/organizations/organization_documents/${orgDocId}`,
    dataType: ORGANIZATION_DOCUMENTS,
    method: 'DELETE'
  });
};

export const startOrganizationDocumentsImport: apiTypes.StartOrganizationDocumentsImport = importId => {
  return fetch({
    url: `/organizations/organization_documents/imports/upload/${importId}`,
    dataType: ORGANIZATION_DOCUMENTS,
    method: 'POST'
  });
};

export const updateOrganizationDocumentsImportStatus: apiTypes.UpdateOrganizationDocumentsImportStatus = importId => {
  return fetch({
    url: `/organizations/organization_documents/imports/sync_status/${importId}`,
    dataType: ORGANIZATION_DOCUMENTS,
    method: 'POST'
  });
};
