import type * as apiTypes from './labelTypes.api.types';

import { fetch } from 'fetch';

export const getAllLabelTypes: apiTypes.GetAllLabelTypes = params => {
  return fetch({
    url: '/label_types',
    method: 'GET',
    params,
    shouldDisableQueryParamsArrayFormatting: true
  });
};
