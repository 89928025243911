import PropTypes from 'prop-types';
import { Dropdown, List } from 'common';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useWidgetHeaderOptionsMenuData, useWidgetHeaderOptionsMenuHandlers } from './hooks';
import './_widget-header-options-menu.scss';

export const WidgetHeaderOptionsMenu = ({ options, onOptionSelect }) => {
  const { localState, localActions } = useWidgetHeaderOptionsMenuData();

  const {
    handleOptionsDropdownOpen,
    handleOptionsDropdownClose,
    handleOptionSelect
  } = useWidgetHeaderOptionsMenuHandlers({
    props: {
      onOptionSelect
    },
    localActions
  });

  return (
    <Dropdown
      isOpen={localState.isOptionsDropdownOpen}
      onClose={handleOptionsDropdownClose}
      triggerContent={
        localState.isOptionsDropdownOpen ? (
          <ArrowDropUpIcon
            className="widget-header-options-menu__arrow--less"
            onClick={handleOptionsDropdownClose}
          />
        ) : (
          <ArrowDropDownIcon
            className="widget-header-options-menu__arrow--more"
            onClick={handleOptionsDropdownOpen}
          />
        )
      }
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      className="widget-header-options-menu__menu-wrapper"
      dropdownContent={<List options={options} onOptionClick={handleOptionSelect} />}
    />
  );
};

WidgetHeaderOptionsMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.arrayOf(PropTypes.string)
      ])
    })
  ),
  onOptionSelect: PropTypes.func
};

WidgetHeaderOptionsMenu.defaultProps = {
  options: [],
  onOptionSelect: () => {}
};
