import { createSelector } from '@reduxjs/toolkit';
import { getDocumentDetailsByDocumentId } from '../documents/documents.selectors';
import lodash from 'lodash';

export const getExpertConnectionReducer = state => state.expertConnection;
export const getAoeMap = createSelector(
  getExpertConnectionReducer,
  expertConnectionReducer => expertConnectionReducer.aoeMap
);
export const getExperts = createSelector(
  getExpertConnectionReducer,
  expertConnectionReducer => expertConnectionReducer.experts
);
export const getExpertNamesByDocumentId = documentId =>
  createSelector(
    getDocumentDetailsByDocumentId(documentId),
    getExperts,
    getAoeMap,
    (document, experts, aoeMap) => {
      const documentTopics = lodash.get(document, 'topics', []);
      const documentAuthorId = lodash.get(document, 'mainstream_news.news_source.id');
      const documentAuthorName = lodash.get(document, 'mainstream_news.news_source.name');

      let expertNames = [];

      documentTopics.forEach(topic => {
        if (aoeMap[topic.name]) {
          expertNames = expertNames.concat(aoeMap[topic.name]);
        }
      });

      experts.forEach(({ name, author_id, author_name }) => {
        if (documentAuthorName === author_name || documentAuthorId === author_id) {
          expertNames = expertNames.concat([name]);
        }
      });

      return expertNames;
    }
  );

export const areThereAnyExperts = createSelector(
  (state, documentId) => getExpertNamesByDocumentId(documentId)(state),
  expertNames => expertNames.length > 0
);
