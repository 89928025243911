import type { AlertPreviewBannerData } from './useAlertPreviewBannerData.types';
import type { GlobalState } from 'shared/reducers';

import * as viewSelectors from 'shared/features/view/view.selectors.js';

import { useSelector } from 'react-redux';

export const useAlertPreviewBannerData = (): AlertPreviewBannerData => {
  const reduxState = useSelector<GlobalState, AlertPreviewBannerData['reduxState']>(state => ({
    shouldShowAlertPreview: viewSelectors.shouldShowAlertPreview(state as never),
    editAlertId: viewSelectors.getEditAlertId(state as never),
    alertName: viewSelectors.getAlertName(state as never)
  }));

  return {
    reduxState
  };
};
