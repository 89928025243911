import { createSelector } from '@reduxjs/toolkit';
import { isCountryJurisdiction, toOption } from 'utils/jurisdiction-utils';
import { DEFAULT_TYPES } from 'constants/DefaultSources';
import {
  convertFollowedEntitiesToObject,
  getActiveFollowedConcepts,
  getFilteredFollowedAgencies,
  getFilteredFollowedJurisdictions,
  getFilteredNonCountryJurisdictions,
  toObject
} from './defaults.helpers';
import {
  getJurisdictionsItems,
  getJurisdictionsReadyStatus,
  getAllCountries
} from '../jurisdictions/jurisdictions.selectors';
import {
  getAgenciesList,
  getAgenciesReadyStatus,
  getNonPopularCountryAgencies
} from '../agencies/agencies.selectors';
import { alphabetically } from 'utils/sort';
import { WIDGET_REGULATION_TYPES } from 'shared/features/widgets/widgets.constants';
import { getCategoriesIdsByMetacategories } from '../documents/documents.selectors';
import { getConcepts } from 'shared/features/concepts/concepts.selectors';

/**
 *
 * Defaults reducer selector
 */
export const getDefaultsReducer = state => state.defaults;

/**
 *
 * Followed topics selectors
 */
export const getActiveTopics = defaultsType => state =>
  getDefaultsReducer(state)[defaultsType].activeTopics;
export const getActiveTopicsObj = defaultsType =>
  createSelector(getActiveTopics(defaultsType), activeTopics => {
    return activeTopics.reduce(toObject('id'), {});
  });
export const getDefaultTopics = defaultsType => state =>
  getDefaultsReducer(state)[defaultsType].defaultTopics;
export const getDefaultTopicsObj = defaultsType =>
  createSelector(getDefaultTopics(defaultsType), defaultTopics => {
    return defaultTopics.reduce(toObject('id'), {});
  });
export const getRealFollowedTopics = defaultsType => state =>
  getDefaultsReducer(state)[defaultsType].realFollowedTopics;
export const getRealFollowedTopicsObj = defaultsType =>
  createSelector(getRealFollowedTopics(defaultsType), realFollowedTopics => {
    return realFollowedTopics.reduce(toObject('topic_id'), {});
  });
export const getRealFollowedTopicsIds = defaultsType =>
  createSelector(getRealFollowedTopics(defaultsType), realFollowedTopics => {
    return realFollowedTopics.map(({ topic_id }) => topic_id);
  });
export const getFollowedTopics = defaultsType => state =>
  getDefaultsReducer(state)[defaultsType].followedTopics;
export const getFollowedTopicsObj = defaultsType =>
  createSelector(getFollowedTopics(defaultsType), followedTopics => {
    return followedTopics.reduce(toObject('topic_id'), {});
  });
export const getFollowedTopicsIds = defaultsType =>
  createSelector(getFollowedTopics(defaultsType), FollowedTopics => {
    return FollowedTopics.map(({ topic_id }) => topic_id);
  });
export const getFollowedDefaultTopics = defaultsType => state =>
  getDefaultsReducer(state)[defaultsType].followedDefaultTopics;
export const getFollowedActiveTopics = defaultsType => state =>
  getDefaultsReducer(state)[defaultsType].followedActiveTopics;

/**
 *
 * Followed entities selectors
 */
export const getDefaultMainstreamNewsSources = defaultsType => state =>
  getDefaultsReducer(state)[defaultsType].defaultMainstreamNewsSources;
export const getDefaultMainstreamNewsSourcesIds = defaultsType =>
  createSelector(getDefaultMainstreamNewsSources(defaultsType), defaultMainstreamNewsSources => {
    return defaultMainstreamNewsSources.map(({ id }) => id);
  });
export const getDefaultMainstreamNewsSourcesObj = defaultsType =>
  createSelector(getDefaultMainstreamNewsSources(defaultsType), defaultMainstreamNewsSources => {
    return defaultMainstreamNewsSources.reduce(toObject('id'), {});
  });
export const getRealFollowedDefaultMainstreamNewsSources = defaultsType =>
  createSelector(getDefaultMainstreamNewsSources(defaultsType), defaultMainstreamNewsSources => {
    return defaultMainstreamNewsSources.filter(({ following }) => following);
  });
export const getRealFollowedDefaultMainstreamNewsSourcesIds = defaultsType =>
  createSelector(
    getRealFollowedDefaultMainstreamNewsSources(defaultsType),
    defaultMainstreamNewsSources => {
      return defaultMainstreamNewsSources.map(({ id }) => id);
    }
  );
export const getRealFollowedDefaultMainstreamNewsSourcesObj = defaultsType =>
  createSelector(
    getRealFollowedDefaultMainstreamNewsSources(defaultsType),
    defaultMainstreamNewsSources => {
      return defaultMainstreamNewsSources.reduce(toObject('id'), {});
    }
  );
export const getFollowedEntities = defaultsType => state =>
  getDefaultsReducer(state)[defaultsType].followedEntities;
export const getFollowedEntitiesObj = defaultsType =>
  createSelector(getFollowedEntities(defaultsType), convertFollowedEntitiesToObject);
export const getFollowedNewsSources = defaultsType =>
  createSelector(getFollowedEntities(defaultsType), entities =>
    entities.filter(({ entity_type }) => entity_type === 'news_sources')
  );
export const getRealFollowedNewsSources = defaultsType =>
  createSelector(getFollowedNewsSources(defaultsType), newsSources =>
    newsSources.filter(({ following }) => following)
  );
export const getRealFollowedNewsSourcesIds = defaultsType =>
  createSelector(getRealFollowedNewsSources(defaultsType), newsSources =>
    newsSources.map(({ entity_id }) => entity_id)
  );

/**
 *
 * Followed categories (doc types) selectors
 */
export const getFollowedCategories = defaultsType => state =>
  getDefaultsReducer(state)[defaultsType].followedCategories;
export const getFollowedCategoriesIds = defaultsType =>
  createSelector(getFollowedCategories(defaultsType), followedCategories => {
    return Object.keys(
      Object.fromEntries(
        Object.entries(followedCategories).filter(([_, isFollowing]) => isFollowing)
      )
    ).map(Number);
  });
export const areThereAnyFollowedCategories = defaultsType =>
  createSelector(getFollowedCategoriesIds(defaultsType), followedCategoriesIds => {
    return Boolean(followedCategoriesIds.length);
  });
export const getFollowedMetacategories = defaultsType => state =>
  getDefaultsReducer(state)[defaultsType].followedMetacategories;

/**
 *
 * Followed concepts selectors
 */
export const getFollowedConcepts = defaultsType => state => {
  const activeConcepts = getConcepts(state);
  const followedConcepts = getDefaultsReducer(state)[defaultsType].followedConcepts;
  return getActiveFollowedConcepts({ activeConcepts, followedConcepts });
};
export const getFollowedConceptIds = defaultsType =>
  createSelector(getFollowedConcepts(defaultsType), followedConcepts => {
    return Object.keys(followedConcepts);
  });

/**
 *
 * Followed regulations selectors
 */
export const getFollowedRegulations = defaultsType => state =>
  getDefaultsReducer(state)[defaultsType].followedRegulations;
export const getFollowedRegulationsIds = defaultsType =>
  createSelector(getFollowedRegulations(defaultsType), followedRegulations => {
    return Object.keys(followedRegulations);
  });

/**
 *
 * Followed agencies selectors
 */
export const getFollowedAgenciesIdsMap = defaultsType => state =>
  getDefaultsReducer(state)[defaultsType].followedAgencies;
export const getFollowedAgenciesIds = defaultsType =>
  createSelector(getFollowedAgenciesIdsMap(defaultsType), followedAgencies => {
    return Object.keys(
      Object.fromEntries(Object.entries(followedAgencies).filter(([_, isFollowing]) => isFollowing))
    ).map(agencyId => parseInt(agencyId, 10));
  });
export const getFollowedAgencies = defaultsType => state => {
  const followedAgenciesIdsMap = getFollowedAgenciesIdsMap(defaultsType)(state);
  const agencies = getAgenciesReadyStatus(state) ? getAgenciesList(state) : [];

  return getFilteredFollowedAgencies({ agencies, followedAgenciesIdsMap });
};
export const getFollowedAgencyIds = defaultsType =>
  createSelector(getFollowedAgencies(defaultsType), followedAgencies => {
    return followedAgencies.map(agency => agency.id);
  });

export const getFollowedAgencyShortNames = defaultsType =>
  createSelector(getFollowedAgencies(defaultsType), followedAgencies => {
    return followedAgencies.map(agency => agency.short_name).filter(Boolean);
  });

/**
 *
 * Followed jurisdictions selectors
 */
export const getFollowedJurisdictionsIdsMap = defaultsType => state =>
  getDefaultsReducer(state)[defaultsType].followedJurisdictions;
export const getFollowedJurisdictionsIds = defaultsType =>
  createSelector(getFollowedJurisdictionsIdsMap(defaultsType), followedJurisidctions => {
    return Object.keys(
      Object.fromEntries(
        Object.entries(followedJurisidctions).filter(([_, isFollowing]) => isFollowing)
      )
    ).map(jurisdictionId => parseInt(jurisdictionId, 10));
  });
export const getFollowedJurisdictions = defaultsType => state => {
  const followedJurisdictionsIdsMap = getFollowedJurisdictionsIdsMap(defaultsType)(state);
  const jurisdictions = getJurisdictionsReadyStatus(state) ? getJurisdictionsItems(state) : [];

  return getFilteredFollowedJurisdictions({ jurisdictions, followedJurisdictionsIdsMap });
};
export const getFollowedCountryJurisdictions = defaultsType => {
  return createSelector(getFollowedJurisdictions(defaultsType), followedJurisdictions => {
    return followedJurisdictions.filter(isCountryJurisdiction);
  });
};
export const getFollowedNonCountryJurisdictions = defaultsType => {
  return createSelector(getFollowedJurisdictions(defaultsType), followedJurisdictions => {
    return getFilteredNonCountryJurisdictions(followedJurisdictions);
  });
};

/**
 *
 * Defaults loading state selector
 */
export const getDefaultsFetchingStatus = defaultsType => state =>
  getDefaultsReducer(state)[defaultsType].isFetching;

export const getDefaultsUpdatingStatus = defaultsType => state =>
  getDefaultsReducer(state)[defaultsType].isUpdating;

/**
 *
 * Other selectors
 */
export const getUserDefaultsRefreshTrigger = state =>
  getDefaultsReducer(state)[DEFAULT_TYPES.MY_DEFAULTS].refreshTrigger;

export const getUserDefaultsForWidget = (defaultsType, exclusiveMetaCategories = []) => state => {
  const defaultDocTypes = getFollowedCategoriesIds(defaultsType)(state);
  const exclusiveDocTypes = getCategoriesIdsByMetacategories(exclusiveMetaCategories)(state);
  const exclusiveDefaultDocTypes = exclusiveMetaCategories?.length
    ? defaultDocTypes.filter(id => exclusiveDocTypes.includes(id))
    : defaultDocTypes;

  return {
    defaultDocTypes: exclusiveDefaultDocTypes,
    defaultAgencies: getFollowedAgenciesIds(defaultsType)(state),
    defaultNewsSources: getRealFollowedNewsSourcesIds(defaultsType)(state),
    defaultTopics: getRealFollowedTopicsIds(defaultsType)(state),
    defaultRegulation: [],
    defaultRegulationType: WIDGET_REGULATION_TYPES.REGULATION
  };
};

/**
 * Returns jurisdictions that has either followed states, or followed agencies
 */
export const getFollowedCountriesBasedOnAgenciesAndSubregions = defaultsType =>
  createSelector(
    getFollowedJurisdictions(defaultsType),
    getFollowedAgencies(defaultsType),
    getAllCountries,
    (followedJurisdictions, followedAgencies, allCountries) => {
      const selectedCountriesByAgencies = allCountries.filter(country => {
        return followedAgencies.find(({ jurisdiction_id }) => jurisdiction_id === country.id);
      });

      const selectedCountriesByJurisdictions = allCountries.filter(country => {
        return followedJurisdictions.find(
          ({ id, parent_id }) => id === country.id || parent_id === country.id
        );
      });

      const selectedCountries = [
        ...selectedCountriesByAgencies,
        ...selectedCountriesByJurisdictions
      ];

      return [...new Map(selectedCountries.map(item => [item['id'], item])).values()];
    }
  );

export const getFollowedCountriesBasedOnAgenciesAndSubregionsOptions = defaultsType =>
  createSelector(
    getFollowedCountriesBasedOnAgenciesAndSubregions(defaultsType),
    followedJurisdictions => {
      return followedJurisdictions.sort(alphabetically).map(toOption);
    }
  );

export const getFollowedNonPopularCountryAgencies = (defaultsType, countryId) =>
  createSelector(
    getNonPopularCountryAgencies(countryId),
    getFollowedAgenciesIdsMap(defaultsType),
    (nonPopularCountryAgencies, followedAgencies) => {
      return nonPopularCountryAgencies.filter(agency => Boolean(followedAgencies[agency.id]));
    }
  );

export const getNotFollowedNonPopularCountryAgencies = (defaultsType, countryId) =>
  createSelector(
    getNonPopularCountryAgencies(countryId),
    getFollowedAgenciesIdsMap(defaultsType),
    (nonPopularCountryAgencies, followedAgencies) => {
      return nonPopularCountryAgencies.filter(agency => !followedAgencies[agency.id]);
    }
  );
