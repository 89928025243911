import type { NormalizedResourcesJurisdiction } from './resources.types';
import type { GlobalState } from 'shared/reducers';

import * as api from './resources.api';
import * as helpers from './resources.helpers';
import * as shelvesHelpers from './resourcesShelves.helpers';
import * as selectors from './resources.selectors';
import * as errorUtils from 'utils/errors';
import * as sort from 'utils/sort';

import lodash from 'lodash';

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

export const SET_SELECTED_JURISDICTIONS = 'SET_SELECTED_JURISDICTIONS';
export const FETCH_RESOURCES = 'FETCH_RESOURCES';
export const FETCH_RESOURCE = 'FETCH_RESOURCE';
export const FETCH_DOCUMENT_SHELVES = 'FETCH_DOCUMENT_SHELVES';
export const FETCH_RESOURCE_HIERARCHY = 'FETCH_RESOURCE_HIERARCHY';
export const FETCH_RESOURCE_HIERARCHY_V2 = 'FETCH_RESOURCE_HIERARCHY_V2';
export const UPDATE_BREADCRUMBS = 'UPDATE_BREADCRUMBS';
export const SET_ACTIVE_SHELF_ID = 'SET_ACTIVE_SHELF_ID';

export const setSelectedJurisdictions = createAction<NormalizedResourcesJurisdiction[]>(
  SET_SELECTED_JURISDICTIONS
);

export const fetchResources = createAsyncThunk(FETCH_RESOURCES, async (_, { getState }) => {
  try {
    const response = await api.getResources();

    return helpers.normalizeJurisdictions(response);
  } catch (e) {
    errorUtils.logError(e as Error);

    throw e;
  }
});

export const fetchResource = createAsyncThunk(
  FETCH_RESOURCE,
  async (
    {
      docId
    }: {
      docId: number;
    },
    { getState }
  ) => {
    try {
      const resources = selectors.getResourcesByParentId(docId)(getState() as GlobalState);

      /**
       * No need to fetch the same resources
       */
      if (resources.items.length) {
        return {
          items: resources.items
        };
      }

      const resource = await api.getResource({ docId });

      return {
        items: helpers.normalizeResourceChildren(resource.children)
      };
    } catch (e) {
      errorUtils.logError(e as Error);

      throw e;
    }
  }
);

export const fetchDocumentShelves = createAsyncThunk(
  FETCH_DOCUMENT_SHELVES,
  async (
    {
      shelfId
    }: {
      shelfId: number;
    },
    { getState }
  ) => {
    try {
      const shelves = selectors.getResourcesShelves(getState() as GlobalState);
      const existingShelf = shelvesHelpers.getShelfById({ currentShelf: shelves, id: shelfId });

      /**
       * No need to fetch the same resources or the leaves
       */
      if (
        existingShelf &&
        (existingShelf.children?.length ||
          (!existingShelf.childrenIds?.length && existingShelf.parentId))
      ) {
        return {
          id: shelfId,
          parentId: existingShelf.parentId
        };
      }

      const shelfChildren = await api.getDocumentShelves({ parent_id: shelfId, limit: 1000 });
      const normalizedChildren =
        shelfChildren?.document_shelves?.map(shelvesHelpers.normalizeDocumentShelf) || [];

      return {
        id: shelfId,
        parentId: existingShelf?.parentId,
        children: normalizedChildren.sort(sort.naturalSortWithRomanNumerals)
      };
    } catch (e) {
      errorUtils.logError(e as Error);

      throw e;
    }
  }
);

export const fetchResourceHierarchy = createAsyncThunk(
  FETCH_RESOURCE_HIERARCHY,
  async ({ docId }: { docId: number }) => {
    try {
      const response = await api.getResourceHierarchy({ docId });

      return helpers.normalizeResourceHierarchy(response);
    } catch (e) {
      errorUtils.logError(e as Error);

      throw e;
    }
  }
);

export const fetchResourceHierarchyV2 = createAsyncThunk(
  FETCH_RESOURCE_HIERARCHY_V2,
  async ({ docId }: { docId: number }) => {
    try {
      const response = await api.getResourceHierarchyV2({ docId: docId });
      return {
        shelves: shelvesHelpers.normalizeResourceHierarchyV2(response),
        breadcrumbs: response.resource_breadcrumb
      };
    } catch (e) {
      errorUtils.logError(e as Error);

      throw e;
    }
  }
);

export const updateBreadcrumbs = createAsyncThunk(
  UPDATE_BREADCRUMBS,
  ({ shelfId }: { shelfId?: number }, { getState }) => {
    try {
      if (!shelfId) return [];
      const shelves = selectors.getResourcesShelves(getState() as GlobalState);
      const updatedBreadcrumbs = shelvesHelpers.getBreadcrumbsById({ shelfId, shelves });

      return updatedBreadcrumbs ?? [];
    } catch (e) {
      errorUtils.logError(e as Error);

      throw e;
    }
  }
);

export const setActiveShelfId = createAction<number | null>(SET_ACTIVE_SHELF_ID);
