import type { ComponentType } from 'react';
import type { DocumentDetailsContentComponentProps } from './DocumentDetailsContent.types';

import * as rightPanelConstants from 'shared/features/rightPanel/rightPanel.constants';
import * as elements from './elements';

export const CONTENT_COMPONENTS: Record<
  rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY,
  ComponentType<DocumentDetailsContentComponentProps>
> = {
  [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.DETAILS]: elements.DocumentSummaryTab,
  [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.DOCKET_TIMELINE]: elements.DocketTimelineTab,
  [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.VERSIONS]: elements.VersionsTab,
  [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.EXPERT_CONNECTION]: elements.ExpertConnectionTab,
  [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.RELATED_DOCUMENTS]: elements.RelatedDocumentsTab,
  [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.AUTO_SUMMARY]: elements.AutoSummaryTab,
  [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.TASKS]: elements.WorkflowPanel,
  [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.ANNOTATIONS]: elements.AnnotationsPanel,
  [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.OBLIGATIONS]: elements.ObligationsPanel,
  [rightPanelConstants.DOCUMENT_DETAILS_TAB_KEY.DIFF]: elements.DiffTab
};
