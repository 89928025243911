import PropTypes from 'prop-types';
import { OVERLAY_TYPES } from 'shared/features/rightPanel/rightPanel.constants';
import classnames from 'classnames';
import { DashboardPDFOverlay, ObligationWorkspace, DocumentWorkspace } from './elements';
import { useOverlayData } from './hooks';
import './_overlay.scss';

const OVERLAYS = {
  [OVERLAY_TYPES.PDF]: DashboardPDFOverlay,
  [OVERLAY_TYPES.PDF_OVERLAY]: DashboardPDFOverlay,
  [OVERLAY_TYPES.HTML]: ObligationWorkspace,
  [OVERLAY_TYPES.DIFF]: DocumentWorkspace
};

export const Overlay = ({ shouldDisableIframeMouseEvents }) => {
  const { reduxState } = useOverlayData();

  if (reduxState.isMobileView || !reduxState.type || !reduxState.isOpen) {
    return null;
  }

  const OverlayComponent = OVERLAYS[reduxState.type];

  if (!OverlayComponent) {
    console.warn(`Wrong overlay type passed: ${reduxState.type}`);
    return null;
  }

  return (
    <div
      className={classnames('overlay__container', {
        'overlay__container--disabled-iframe-pointer-events': shouldDisableIframeMouseEvents
      })}
    >
      <OverlayComponent />
    </div>
  );
};

Overlay.propTypes = {
  shouldDisableIframeMouseEvents: PropTypes.bool
};
