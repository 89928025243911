import { useSelector } from 'react-redux';
import {
  getAlertSuccessSaveModalData,
  isShowingWorkflowAlert
} from 'shared/features/view/view.selectors';

export const useSaveSuccessModalData = () => {
  const reduxState = useSelector(state => ({
    alertSuccessSaveModalData: getAlertSuccessSaveModalData(state),
    isShowingWorkflowAlert: isShowingWorkflowAlert(state)
  }));

  return {
    reduxState
  };
};
