import PropTypes from 'prop-types';
import {
  useCognitoWindowedSignInFormHandlers,
  useCognitoWindowedSignInFormData,
  useCognitoWindowedSignInFormLifecycle
} from './hooks';

export const CognitoWindowedSignInForm = ({ onError, onSignIn, children }) => {
  const { refs } = useCognitoWindowedSignInFormData();

  const { handleWindowOpen, handleMount, handleUnmount } = useCognitoWindowedSignInFormHandlers({
    props: {
      onError,
      onSignIn
    },
    refs
  });

  useCognitoWindowedSignInFormLifecycle({
    handlers: {
      handleMount,
      handleUnmount
    }
  });

  return children({ showWindow: handleWindowOpen });
};

CognitoWindowedSignInForm.propTypes = {
  onError: PropTypes.func,
  onSignIn: PropTypes.func,
  children: PropTypes.func.isRequired
};

CognitoWindowedSignInForm.defaultProps = {
  onError: () => {},
  onSignIn: () => {}
};
