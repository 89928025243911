import type { ConceptsHandlers } from './useConceptsHandlers.types';

import { formatRedirectUrl } from 'utils/url-utils';
import * as routes from 'constants/Routes';
import { SUPPORTED_QUERY_PARAMS } from 'constants/PrimaryFilter';

import { useNavigate } from 'react-router-dom';
import { useRightPanelReduxActions } from 'shared/features/rightPanel/hooks';
import { useQueryParams } from 'utils/hooks';

export const useConceptsHandlers = (): ConceptsHandlers => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const rightPanelReduxActions = useRightPanelReduxActions();

  const handleConceptClick: ConceptsHandlers['handleConceptClick'] = option => {
    rightPanelReduxActions.closeOverlay();

    navigate(
      formatRedirectUrl({
        path: routes.content,
        params: {
          [SUPPORTED_QUERY_PARAMS.ALL_TOPICS]: 'true',
          [SUPPORTED_QUERY_PARAMS.ALL_AGENCIES]: 'true',
          [SUPPORTED_QUERY_PARAMS.CONCEPT_ID]: option.value,
          [SUPPORTED_QUERY_PARAMS.OFFSET]: 0,
          [SUPPORTED_QUERY_PARAMS.LIMIT]: queryParams[SUPPORTED_QUERY_PARAMS.LIMIT],
          [SUPPORTED_QUERY_PARAMS.SEARCH_SORT]: queryParams[SUPPORTED_QUERY_PARAMS.SEARCH_SORT]
        }
      })
    );
  };

  return {
    handleConceptClick
  };
};
